<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet Status - Summary</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

    <span tabindex="1"></span>
          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-3">
                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                    >Summary Type</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.summary_type" @change="changeBlocktype" >
                    <option value="1" selected>Department Wise</option>
                    <option value="2">Process Wise</option>
                  </select>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                    >Select Series/Kapan</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.issue_block" @change="changeBlocktype" >
                    <option value="1" selected>Kapan</option>
                    <option value="2">Series</option>
                  </select>
                </div>
                <div class="mt-3" v-if="screenData.issue_block == 2">
                <label for="update-profile-form-6" class="form-label"
                  >Select Series</label
                >
                 <TomSelect
                  v-model="screenData.selectedSeries"
                  :options="{
                    placeholder: 'Select Series',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Series'
                      }
                    }
                  }"
                class="w-full"
              >
              <option value="">Select Series</option>
              <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
                </div>
                <div class="mt-3" v-if="screenData.issue_block == 1">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Kapan</label
                  >
                 <TomSelect

                v-model="screenData.selectedKapan"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="">Select Kapan</option>
              <option v-for="kapans in screenData.kapans_ar" :key="kapans.id" :value="kapans.id">{{kapans.kapan_code}}</option>
              </TomSelect>
                </div>
              </div>

            <div class="col-span-12 xl:col-span-12 mt-5">
              <div>
                <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full sm:w-20"
                  tabindex="5"
                  @click="generateReport()"
                >
                Generate
                </button>

              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                tabindex="6"
                @click="onResetFilter"
              >
                Reset
              </button>

          </div>
          <span tabindex="7"></span>
          </div>
        </div>

      <div class="overflow-x-auto scrollbar-hidden" v-if="screenData.genreport == 'gen'">

        <div id="" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table" style="width:auto!important">
                  <thead>
                    <tr>

                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="header in screenData.report_header" :key="header"
                      >
                        {{header}}
                      </th>

                    </tr>
                  </thead>

                  <tbody>
                    <tr
                    v-for="record in screenData.report_fields" :key="record">
                      <td class="border-b border-l border-r text-center whitespace-nowrap" v-for="value in record" :key="value">{{value}}</td>
                    </tr>
                     <tr>
                      <td class="border-b border-l border-r bg-gray-200 font-bold text-center whitespace-nowrap" v-for="value_total in screenData.footer_total" :key="value_total">{{value_total}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      genreport : "no",
      issue_block:1,
      kapans_ar:[],
      series_ar:[],
      report_fields:[],
      report_header:[],
      footer_total:[],
      selectedKapan:'',
      selectedSeries:'',
      summary_type:1,

    })

    const getKapans = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans_ar = data.items;

        })
        .catch(_error => {

        });
    }

    const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;

        })
        .catch(_error => {

        });
    }

    //
    const generateReport = () => {
      if(screenData.selectedSeries == '' && screenData.selectedKapan == ''){
        return;
      }
      if(screenData.issue_block == 1){
        screenData.selectedSeries = '';
      }else{
        screenData.selectedKapan = '';
      }
      screenData.footer_total = [];
      screenData.report_fields = [];
      const params = {
        series_id: window.btoa(screenData.selectedSeries),
        kapan_id: window.btoa(screenData.selectedKapan),
        summary_type: screenData.summary_type,
      };

      let promise = axios({
        url: "api/list_xxx_packet_status_report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          screenData.footer_total = [];
          screenData.report_fields = data.report_details;
          screenData.footer_total = data.footer_total;
          screenData.report_header = data.report_headers;
          screenData.genreport = 'gen';
        })
        .catch(_error => {
        });
    }

    const focusFirst = () => {
        document.querySelector('[id="scan_me"]').focus();
    }
    const focusLast = () => {
        document.querySelector('[tabindex="6"]').focus();
    }

    const onResetFilter = () => {
      screenData.selectedbuyers = []
    }



    const changeBlocktype = () => {

    }


    onMounted(() => {
      //checkPermission()
      //reInitOnResizeWindow()
      getKapans()
      getSeries()
      focusFirst()
      //generateReport()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onResetFilter,
      generateReport,
      changeBlocktype,
      focusFirst, focusLast

    }
  }
})
</script>
