<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Issue/Receive Item</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="update-profile-form-6"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="scan_lot_no"
                    :class="{'form-control':true,'loading':scanload}"
                    :disabled = "scanload"
                    @change="scanAndAddPack"
                    autocomplete="off"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

 <div class="grid grid-cols-12 mt-5" v-if="showreturndetails">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Note :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.issue_note_no}}</label
                  >
                </div>
               

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue To :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{  return_item.issue_to_name}}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Group No :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{  return_item.issue_group_no}}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue At :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.issue_date}}</label
                  >
                </div>
              </div>
              <!-- <div class="col-span-12 xl:col-span-4">

                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Return Weight :&nbsp;</label
                  >
                 <input
                    id="update-profile-form-6"
                    type="text"
                    placeholder="Weight in Ct"
                    v-model="return_item.return_weight"
                    class="form-control"
                  />

                </div>



              </div> -->
             
            </div>


        
         <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary mr-auto mt-3" v-if="showreturndetails" @click="submitReturnRough">
                Take Return
              </button>
            </div>
        </div>
        </div>
        <!-- END: Input -->

      </div>

    </div>

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Item - Party Issue Notes</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
              <div
              id="tabulatorHisroty"
              ref="tabulatorHisroty"
              class="mt-2"
            ></div>
            </div>
            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tender_update_id : '',
      tabulatorHistory:[],
      tabulator:[],
      assorts:[],
      transaction_no: 'Auto',
      scanload:false,
      enable_return:false,
      scan_lot_no:'',
      status: '1',

      return_item:{
        issue_note_no:'',
        issue_note_id:'',
        issue_group_id:'',
        issue_group_no:'',
        issue_to_name:'',
        issue_date :'',
        return_weight:'',
      },
       
        return_progress:false,
        showreturndetails : false,
        loginVal: '',
        req: axios.create({
          baseUrl: BASE_URL
        })
    }
  },
  computed: {

  },
  mounted(){
  },

  methods: {

    async scanAndAddPack(){
      let _this = this;
      console.log(this.scan_lot_no);

      let codelength = this.scan_lot_no.trim().length;
      if(codelength > 11){
        this.scanload = true;

        //get lot details
        const data = {
        barcode: this.scan_lot_no,
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_scanned_group_details_for_return"
        }else{
          url_post = "api/get_scanned_group_details_for_return"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          
          let last_status = data.status;
          if(last_status == 'issued'){
              this.showreturndetails = true;
              let group_details = data.issue_details;
              this.return_item.issue_note_no = group_details.issue_no;
              this.return_item.issue_note_id = group_details.id;
              this.return_item.issue_group_id = data.group_details.id;
              this.return_item.issue_group_no = data.group_details.item_group_name;
              this.return_item.issue_to_name = group_details.buyer_name;
              this.return_item.issue_date = group_details.updated_at;

          }
          else{
          _this.historyPrepare();
          }
          
          this.scanload = false;
          })
          .catch((_error) => {
            this.scanload = false;


            return [];
          });

      }

    },

    historyPrepare(){
      let _this = this;
      this.tabulatorHistory = new Tabulator(this.$refs['tabulatorHisroty'], {
        ajaxURL: BASE_URL + '/api/list_party_issue_not_by_group',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{barcode:this.scan_lot_no},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Issue Note',
            field: 'issue_note_id',
            vertAlign: 'middle',
            print: false,
            download: true,
            
          },
          {
            title: 'Party',
            minWidth: 100,
            field: 'buyer_name',
            vertAlign: 'middle',
            print: false,
            download: true,
            

          },
          {
            title: 'Tender Items',
            minWidth: 100,
            field: 'unissued',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              if(cell.getData().status == 1){
              const a = cash(`<div class="flex lg:justify-center">
                <button class="btn btn-primary" type="button">
                  Issue Group
                </button>
              </div>`)

              cash(a).on('click', function(data_id) {
                // On click actions
                //router.push("/app/tender/update/" + window.btoa(cell.getData().id));

                _this.issueItem(cell.getData().issue_note_id,cell.getData().tender_item_group_id);
              })

              return a[0] 
              }
            }
          },


        ],
        renderComplete() {

        }
      })
    },

    async submitReturnRough(){
      let _this = this;
      const data = {
        group_id: this.return_item.issue_group_id,
        issue_note_no: this.return_item.issue_note_no
      };


    //  if(_this.$h.myFloat(this.return_item.return_weight) == 0){
    //     this.$swal("Warning!", "Can not return zero weight!", "warning");
    //     return;
    //   }

      this.return_progress = true;
      this.$swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/return_tender_packet_single",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                this.$swal("Success!", "Item Returned to Manager.", "success");
                this.$router.go();
              }else if(data.status == "error"){
                this.$swal("Error!", data.message , "error");
              }
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "Item Returned to Manager.", "success");
          this.$router.go();
        }
      });
    },

    async issueItem(issue_note,group_id){
      let _this = this;
      const data = {
        group_id: group_id,
        issue_note: issue_note
      };

      this.return_progress = true;
      this.$swal({
        title: "Are you sure?",
        text: "You are about to Issue Item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Issue",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/issue_tender_packet_single",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                this.$swal("Success!", "Item Returned to Manager.", "success");
                this.$router.go();
              }else if(data.status == "error"){
                this.$swal("Error!", data.message , "error");
              }
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "Item Returned to Manager.", "success");
          this.$router.go();
        }
      });
    },

    
   
  },

})
</script>
