import store from "@/store"
import { computed } from 'vue'

export const defaultMenuType = 'menu-sub-hidden' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = '/app/dashboards/default'
export const searchPath = '/app/pages/search'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

//export const BASE_URL = 'http://10.2.81.170:8080/Avalanche_Full_stack_CB/apis'
//export const BASE_URL = 'http://54.213.237.220/Avalanche_Full_Stack/apis'
//export const BASE_URL = 'http://localhost/bm_api/apis'
//export const BASE_URL = 'http://localhost/bluemoon_api/apis'
//export const BASE_URL = 'http://localhost/bluemoon_api/apis'
//export const BASE_URL_PRINT = 'http://localhost/label_print'
//export const BASE_URL = 'http://localhost/bm_api/apis'
//export const BASE_URL_PRINT = 'http://localhost/label_print'
//export const BASE_URL = 'http://localhost/bluemoon_api/apis'
//export const BASE_URL = 'http://localhost/bm_dk_apis/apis'
//export const BASE_URL_PRINT = 'http://localhost/label_print'
//export const BASE_URL_SELLING = 'http://localhost/bm_dk_apis_selling/apis/api/selling'

export const BUILD_ENV = 'factory'
//export const BUILD_ENV = 'selling'

//Live Server
//export const BASE_URL = 'http://192.168.0.147/dkback/apis'
//export const BASE_URL_PRINT = 'http://192.168.0.147/label_print'
//export const BASE_URL = 'https://dksons.bm.care/bm_back/apis'
//export const BASE_URL_PRINT = 'https://dksons.bm.care/label_print'

//Live Tender
export const BASE_URL = 'https://bluemoonerp.com/bm_vue/apis'
export const BASE_URL_PRINT = 'https://bluemoonerp.com/label_print'

export const BASE_URL_SELLING = 'https://dksons.bm.care/bm_back/apis/api/selling'
//export const BASE_URL = 'http://bmerp.cstestservers.in/bm_vue/apis'
//export const BASE_URL_PRINT = 'http://bmerp.cstestservers.in/label_print'
export const CS = 'hqqXVtm6BqCMSSM0xUv0mW6r3b0iVjvCZCibNs2Z'

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [{
  id: 'en',
  name: 'English LTR',
  direction: 'ltr'
},
{
  id: 'es',
  name: 'Español',
  direction: 'ltr'
},
{
  id: 'enrtl',
  name: 'English RTL',
  direction: 'rtl'
}
]

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563'
}

export const apiUrl = 'https://api.coloredstrategies.com'

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profile-pic-l.jpg',
  date: 'Last seen today 15:24'
}

export const defaultColor = 'light.blue'
export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red'
]
export let Auth_Token = localStorage.getItem('server_auth')
