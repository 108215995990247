<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
      <h1 class="text-xl font-medium mr-auto">Transactions</h1>
    </div>

    <div class="pos intro-y grid grid-cols-12 gap-5 mt-2">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
          <h2 class="text-lg font-medium mr-auto">Kapan Transactions</h2>
        </div>
       <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'issue-kapan-master' }" tag="div">
              <div class="font-medium text-base">Issue</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'receive-kapan-master' }" tag="div">
              <div class="font-medium text-base">Receive / Return</div>
            </router-link>
          </div>
          
        </div>

        <div
          class="
            intro-y
            flex flex-col
            sm:flex-row
            items-center
            mt-4
            border-t
            pt-3
          "
        >
          <h2 class="text-lg font-medium mr-auto">Packet Transactions</h2>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'issue-packet-master' }" tag="div">
              <div class="font-medium text-base">Issue</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'receive-packet-master' }" tag="div">
              <div class="font-medium text-base">Receive / Return</div>
            </router-link>
          </div>
        </div>

        
      </div>
      <!-- END: Item List -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref,onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)

    const printDetails = () => {
      console.log(userDetails.first_name);
    }
    onMounted(() => {
      printDetails()
    })
  
    return {
      userDetails,
    }
  }
})
</script>
