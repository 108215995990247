<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Final Polish Non-Certy Report</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
          <!-- <div class="border-b pb-5"> -->

          <div class="grid grid-cols-12 gap-x-2">

              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Series</label>
                      <TomSelect v-model="filters.series_id" :options="{
                      placeholder: 'Select Series',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Series'
                        }
                      }
                    }" class="w-full" @change="getKapans" multiple>
                          <option v-for="(series, index) in page_data.series_arr" :key="index" :value="series.id">{{ series.series_code }}</option>
                          <!-- <option value="3">PRINCE</option> -->
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                      <TomSelect v-model="filters.kapan_id" :options="{
                      placeholder: 'Select Kapan',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Kapan'
                        }
                      }
                    }" class="w-full" multiple>
                          <option v-for="(series, index) in page_data.kapan_arr" :key="index" :value="series.id">{{ series.kapan_code }}</option>
                      </TomSelect>
                  </div>
              </div>
              <!-- <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Stone</label>
                      <TomSelect v-model="filters.stone_type" :options="{
                      placeholder: 'Select Stone',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Stone'
                        }
                      }
                    }" class="w-full" multiple>
                          <option value="">All</option>
                          <option value="1">Natural</option>
                          <option value="2">CVD</option>
                          <option value="3">HPHT</option>
                          <option value="4">D_CVD</option>
                          <option value="5">D_HPHT</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Shape</label>
                      <TomSelect v-model="filters.shape" multiple :options="{
                      placeholder: 'Select Shape',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Shape'
                        }
                      }
                    }" class="w-full">
                          <option value="">All</option>
                          <option value="1">ROUND</option>
                          <option value="2">FANCY</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Merge Type</label>
                      <TomSelect v-model="filters.merge_type" :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Merge Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="2">Final Polish</option>
                          <option value="3">Rough To Polish</option>
                      </TomSelect>
                  </div>
              </div> -->
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                          Reset
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="get_records()" :disabled="page_data.loading == true">
                          Generate
                          <LoadingIcon icon="oval" class="w-8 h-8" v-if="page_data.loading == true" />
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="printReportDetails()">
                          Print
                      </button>
                  </div>
              </div>
          </div>
          <!-- </div> -->
          <div class="intro-y p-5">

              <div id="responsive-table" class="p-5" v-if="page_data.loading == true">
                  <div class="preview">
                      <div class="overflow-x-auto">
                          <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                              <LoadingIcon icon="bars" class="w-8 h-8" />
                              <div class="text-center text-xs mt-2">Getting Records</div>
                          </div>
                      </div>
                  </div>
              </div>

              <div v-else>
                  <div class="p-5" v-if="page_data.generate == 'gen'">
                      <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.series_details.length">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in page_data.report_items.filters_arr.series_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                              </div>
                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.kapan_details.length">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Kapans : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in page_data.report_items.filters_arr.kapan_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                              </div>

                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.total_round_wt">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Round Stock Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.filters_arr.total_round_wt ? $h.myFloat(page_data.report_items.filters_arr.total_round_wt) + ' Ct' : '0 Ct' }}</label>
                              </div>

                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.total_round_avg_rate">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Round Stock Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.filters_arr.total_round_avg_rate ? $h.myFloat(page_data.report_items.filters_arr.total_round_avg_rate) : '-' }}</label>
                              </div>

                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.total_fancy_wt">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Fancy Stock Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ page_data.report_items.filters_arr.total_fancy_wt ? $h.myFloat(page_data.report_items.filters_arr.total_fancy_wt) + ' Ct' : '0 Ct' }}</label>
                              </div>

                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.total_fancy_avg_rate">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Fancy Stock Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ page_data.report_items.filters_arr.total_fancy_avg_rate ? $h.myFloat(page_data.report_items.filters_arr.total_fancy_avg_rate) : '-' }}</label>
                              </div>

                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.total_wt">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.filters_arr.total_wt ? $h.myFloat(page_data.report_items.filters_arr.total_wt) + ' Ct' : '0 Ct' }}</label>
                              </div>

                          </div>
                          <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.filters_arr.total_avg_rate">
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.filters_arr.total_avg_rate ? $h.myFloat(page_data.report_items.filters_arr.total_avg_rate) : '-' }}</label>
                              </div>

                          </div>
                      </div>

                  </div>
                  <div class="py-5" v-if="page_data.generate == 'gen' && page_data.report_items.kapan_detail_arr.length > 0">
                      <h2 class="text-lg font-medium mr-auto">Series/ Kapan List</h2>
                      <div class="grid grid-cols-12 pt-5 text-center gap-2" v-if="page_data.report_items.kapan_detail_arr.length > 0">
                          <div class="col-span-6 md:col-span-4 xl:col-span-3 border w-full py-1 m-0" v-for="(item,index) in page_data.report_items.kapan_detail_arr" :key="index">
                              <div class="px-2">

                                  <table class="w-full">

                                      <tr>
                                          <td class="w-40 text-left">
                                              {{ item.kapan_code }}
                                          </td>
                                          <td class="w-36 text-center">
                                              {{ item.pieces }}
                                          </td>
                                          <td class="w-36 text-right">
                                              {{ $h.myFloat(item.weight) }} Ct
                                          </td>
                                          <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                      </tr>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-if="page_data.generate == 'gen'">
                      <div class="overflow-x-auto m-0 p-0 mt-5" v-for="(report_itm,report_index) in page_data.report_items.color_arr" :key="report_index">

                          <table class="text-xs text-center w-full">

                              <thead>
                                  <tr class="border bg-gray-200 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in report_itm.heading1_arr" :key="shape_index" :colspan="report_itm.heading1_size_arr[shape_index]">{{ shape_item }}</th>
                                  </tr>
                                  <tr class="border bg-gray-100 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in report_itm.heading2_arr" :key="size_index" :colspan="report_itm.size_arr_number[size_index]">{{ size_item }}</th>
                                  </tr>
                                  <tr class="border bg-gray-100 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in report_itm.heading3_arr" :key="size_index">{{ size_item }}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr class="border" v-for="(size_value_item,size_value_index) in report_itm.size_value_arr" :key="size_value_index">
                                      <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                                  </tr>
                                  <tr class="border">
                                      <th class="border py-2" v-for="(item,inx) in report_itm.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                                  </tr>
                              </tbody>
                          </table>

                      </div>

                  </div>
              </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
  </div>
  </template>


  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import Tabulator from 'tabulator-tables'
  import feather from 'feather-icons'
  import cash from 'cash-dom';
  import moment from 'moment';
  import {
      useRoute,
      useRouter
  } from 'vue-router';

  export default defineComponent({
      setup() {

          const swal = inject('$swal');

          const tableRef = ref()
          const tabulator = ref()
          const route = useRoute()
          const router = useRouter()
          const page_data = reactive({

              merge_items: [],
              report_items: [],
              series_arr: [],
              kapan_arr: [],
              generate: "no",
              loading: false,
          })

          const filters = reactive({
              s_date: moment().startOf('month').format('YYYY-MM-DD'),
              e_date: moment().format('YYYY-MM-DD'),
              series_id: [],
              kapan_id: [],
              shape: [],
              stone_type: [],
              merge_id: [],
              merge_type: '0',
          })
          const get_records = () => {

              const params = {
                  // s_date: filters.s_date,
                  // e_date: filters.e_date,
                  series_id: filters.series_id.length == 0 ? '' : filters.series_id,
                  kapan_id: filters.kapan_id.length == 0 ? '' : filters.kapan_id,
                  e_date: filters.e_date,
                  shape: filters.shape.length == 0 ? "" : filters.shape,
                  stone_type: filters.stone_type.length == 0 ? "" : filters.stone_type,
                  merge_id: filters.merge_id.length == 0 ? "" : filters.merge_id,
                  merge_type: filters.merge_type == '0' ? "" : filters.merge_type
              };

              page_data.loading = true

              let promise = axios({
                  url: "api/get_merge_records_by_series_old",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "YES") {
                          swal("Warning!", data.message, "warning");
                          page_data.generate = 'no';
                          page_data.loading = false

                          return;
                      }

                      page_data.report_items = data.items;
                      page_data.generate = 'gen';
                      page_data.loading = false

                  })
                  .catch(_error => {

                      page_data.generate = 'no';
                      page_data.loading = false
                  });
          }

          const onResetFilter = () => {
              filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
              filters.e_date = moment().format('YYYY-MM-DD')
              filters.shape = []
              filters.stone_type = []
              filters.series_id = []
              filters.kapan_id = []
              filters.merge_id = []
              filters.merge_type = '0'
          }

          function printReportDetails() {
              let report_type = 'final-polish-non-certy'

              let params = {
                  report_type: report_type,
                  filters: [],
                  items: page_data.report_items
              }
              let promise = axios({
                  url: "api/add_report_details",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      const report_id = data.report_id

                      let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
                      window.open(url, '_blank');

                  })
                  .catch(_error => {

                  });
              // add_report_details
          }

          const getSeries = () => {
              let params = {}
              let promise = axios({
                  url: "api/dp_rough_series",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      page_data.series_arr = data.items

                  })
                  .catch(_error => {
                      page_data.series_arr = []

                  });
          }
          const getKapans = () => {
              let params = {
                  series_id: (filters.series_id.length > 0) ? filters.series_id : '',
              }
              let url_kpn = ''

              if (filters.series_id.length > 0) {
                  url_kpn = 'api/dp_rough_kapan_by_multiple_series'
              } else {
                  url_kpn = 'api/dp_rough_kapan'

              }
              let promise = axios({
                  url: url_kpn,
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      page_data.kapan_arr = data.items
                      filters.kapan_id = []

                  })
                  .catch(_error => {
                      page_data.kapan_arr = []
                      filters.kapan_id = []

                  });
          }

          onMounted(() => {
              getSeries();
              getKapans();
          })

          return {
              filters,
              page_data,
              tableRef,
              onResetFilter,
              get_records,
              getKapans,
              printReportDetails
          }
      }
  })
  </script>
