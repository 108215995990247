<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Buyer Account</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Buyer Details</h2>
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >First Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="First name"
                    readonly
                    v-model="first_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Middle Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Middle name"
                  readonly
                    v-model="middle_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Last Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                    readonly
                    v-model="last_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Personal Email</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    readonly
                    v-model="contact_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Mobile No</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="+00 00000 00000"
                    readonly
                    v-model="contact_mobile_no"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reference 1 Name</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="ref_1_name"
                  />
                </div>
               <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reference 1 Contact</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="ref_1_contact"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reference 1 Email</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="ref_1_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reference 2 Name</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="ref_2_name"
                  />
                </div>
               <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reference 2 Contact</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="ref_2_contact"
                  />
                </div>
                 <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reference 2 Email</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="ref_2_email"
                  />
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-9" class="form-label"
                    >Company(Org.) Name</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="company_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-11" class="form-label"
                    >Company Email</label
                  >
                  <input
                    id="update-profile-form-11"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    readonly
                    v-model="company_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Phone No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="company_contact_no"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >WhatsApp No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="whatsapp_no"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Broker Name</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="broker_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Broker Mobile No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="broker_mobile_no"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Broker Email</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="broker_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >GST No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="gst_no"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >PAN No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    readonly
                    v-model="pan_no"
                  />
                </div>
                
              </div>
            </div>
            
            <div class="flex justify-end mt-4">
             
            </div>
         
        </div>
         </form>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'


export default defineComponent({
  components: {
    DarkModeSwitcher,
    
  },
  props: {
    user_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      
      first_name:"",
      last_name:"",
      middle_name:"",
      contact_email:"",
      contact_mobile_no:"",
      company_name:"",
      company_email:"",
      company_contact_no:"",
      whatsapp_no:"",
      broker_name:"",
      broker_mobile_no:"",
       ref_1_name:"",
      ref_2_name:"",
      ref_1_contact:"",
      ref_2_contact:"",
       ref_1_email:"",
      ref_2_email:"",
      broker_email:"",
      gst_no:"",
      pan_no:"",
      status: 'default',
      email: '',
      password: '',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  this.getuserDetails(this.user_id);
  },

  methods: {
    
  
    getuserDetails(user_id) {
      let _this = this;
      const params = {
        ud: user_id
      };
      let promise = axios({
        url: "api/rough_buyer_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.user_detail);
          let user_details = data.user_detail;

          this.first_name = user_details.first_name;
          this.last_name = user_details.last_name;
          this.middle_name = user_details.middle_name;
          this.contact_email = user_details.contact_person_email;
          this.contact_mobile_no = user_details.contact_person_mobile;
          this.company_name = user_details.company_name;
          this.company_email = user_details.company_email;
          this.whatsapp_no = user_details.whatsapp_no;
          this.company_contact_no = user_details.company_contact_no;
          this.broker_name = user_details.broker_name;
          this.broker_mobile_no = user_details.broker_contact;
          this.gst_no = user_details.gst_no;
          this.pan_no = user_details.pan_no;
          this.ref_1_name = user_details.ref_1_name;
          this.ref_2_name = user_details.ref_2_name;
          this.ref_1_contact = user_details.ref_1_contact;
          this.ref_2_contact = user_details.ref_2_contact;
           this.ref_1_email = user_details.ref_1_email;
          this.ref_2_email = user_details.ref_2_email;
          this.broker_email = user_details.broker_email;

        })
        .catch(_error => {
          
        });
    },
    
  },
  
})
</script>

