<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Manager Type</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      <!-- <button
          v-if="vendor_id != ''"
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="resetUser">
           <KeyIcon class="w-4 h-4 mr-1" />
           Reset & Resend Password
      </button> -->
      <button
          v-if="vendor_id != '' && status == 1"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-danger ' : status == 1}" @click="changeStatus">
           <LockIcon class="w-4 h-4 mr-1" /> Deactivate
      </button>
      <button
          v-if="vendor_id != '' && status == 0"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-success ' : status == 0}" @click="changeStatus">
           <UnlockIcon class="w-4 h-4 mr-1" /> Activate
      </button>
      <button
          v-if="vendor_id != ''"
          type="button"
           class="btn btn-danger shadow-md mr-2" @click="deleteUser">
           <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
      </button>

      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Role Details</h2>
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Manager Type Name<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Type name"
                    
                    v-model="role_name"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Report Order</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    v-model="report_order"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Department Order</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    v-model="department_order"
                  />
                </div>
               
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-13" class="form-label"
                    >Process</label
                  >
                  <TomSelect
                    v-model="process"
                    :options="{
                      placeholder: 'Select Process ',
                    }"
                    class="w-full"
                    multiple
                  >
                  <option value="">Select Process</option>
                  <option v-for="proces_item in process_ar" :key="proces_item.id" :value="proces_item.id">{{proces_item.process_name}}</option>
                  </TomSelect>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Roles to Transfer</label>
                  <TomSelect
                    v-model="dep_transfer"
                    :options="{
                      placeholder: 'Select Roles',
                    }"
                    class="w-full"
                    multiple
                  >
                  <option value="">Select Roles</option>
                  <option v-for="proces_item in roles_ar" :key="proces_item.id" :value="proces_item.id">{{proces_item.role_name}}</option>
                  </TomSelect>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>
            </div>
        </div>
         </form>
        </div>
         <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Party Created Successfuly!</div>
            <div class="text-gray-600 mt-1">
                Party added to user list.
            </div>
        </div>
         </div>
         <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Party Updated Successfuly!</div>
        </div>
         </div>
        <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>

         <div id="failed-user-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Party Creation Error!</div>
            <div class="text-gray-600 mt-1">
                Something went wrong. Kindly Refreash the page and try again.
            </div>
        </div>
         </div>
    </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'


export default defineComponent({
  components: {
    DarkModeSwitcher,
  },
  props: {
    user_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      vendor_id : '',
      total_items : 1,
      total_items_late : 1,
      role_name:"",
      report_order:"",
      department_order:"",
     
      password: '',
      loginVal: '',
      process:[],
      dep_transfer:[],
      process_ar : [],
      roles_ar: [],
      
      url:'',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  mounted(){
    this.getProcess();
    this.getManagerType();
      if(this.user_id != ''){
        this.vendor_id = this.user_id;
        this.getuserDetails(this.user_id);
      }
  },
  computed: {
    
  },

  methods: {
    
    formSubmit() {
      this.status = 'processing'

    },
    testMethod(){
      //check item already in list or not and push item
      var i = this.process.length;
      console.log(i);
      while(i--){
        var process_id = this.process[i];
        var item_flag = false;
        var j = this.process_salary_per.length;
        console.log(j);
        if(j > 0){
          while(j--){
              if(this.process_salary_per[j]['process_id'] == process_id){
                  item_flag = true;
              }
          }
        }else{
          item_flag = false;
        }
        if(item_flag == false){
          var k = this.process_ar.length;
          var process_name= '';
          while(k--){
            if(this.process_ar[k]['id'] == process_id){
                  process_name = this.process_ar[k]['process_name'];
                  break;
            }
          }
          this.process_salary_per.push({ id:this.total_items,process_name: process_name,process_id:process_id ,salary_per:''});
          this.total_items++;
        }    
      }
      
      //check item already in list deselected or not
      
      var j = this.process_salary_per.length;
      while(j--){
        item_flag = false;
        var i = this.process.length;
        console.log(i);
        while(i--){
          //console.log("I" + i);
          var process_id = this.process[i];
          if(this.process_salary_per[j]['process_id'] == process_id){
              item_flag = true;
          }
        }
        if(item_flag == false){
          this.process_salary_per.splice(j,1);
        }
      }
      
    },
    async getProcess() {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/dp_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.process_ar = data.items;
        })
        .catch(_error => {
          
        });
    },

    async getManagerType() {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/dp_manager_type",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.roles_ar = data.items;
        })
        .catch(_error => {
          
        });
    },
    
  
    onUserFormSubmit() {
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      if (!this.role_name) {
        this.errors.push("Role Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      
      if (!this.errors.length) {
        const data = {
          role_name: this.role_name,
          report_order: this.report_order,
          department_order: this.department_order,
          process:this.process,
          dep_transfer:this.dep_transfer,
          ud:this.user_id
        };
        if (this.user_id == "") {
          this.url = "api/add_manager_type";
        } else {
          this.url = "api/edit_manager_type";
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {

            if(this.user_id != ''){
        Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/operator-type");
        }, 500)
            }else{
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/operator-type");
        }, 500)
            }       
          })
          .catch(response => {
            Toastify({
              node: cash('#failed-user-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          });
      }
    },

    getuserDetails(user_id) {
      let _this = this;
      const params = {
        ud: user_id
      };
      let promise = axios({
        url: "api/details_manager_type",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.user_details);
          let user_details = data.user_details;

          this.role_name = user_details.role_name;
          
         
          if(user_details.process != null){
              this.process = user_details.process_ar;
          }
          if(user_details.dep_ar != null){
              this.dep_transfer = user_details.dep_ar;
          }

        })
        .catch(_error => {
          
        });
    },
    deleteUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a User!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/delete_account_polish_party",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Deleted.", "success");
          this.$router.push("/app/accounts/operator-type");
        }
      });
    },
    resetUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to reset User Password!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reset",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/reset_vendor_user",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "New Password Reset & Resend to User.", "success");
        }
      });
    },
    changeStatus(){
      let _this = this;
      console.log(this.user_id);
      console.log(this.status);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to change user status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id,
            user_status : window.btoa(this.status)
          };
          let promise = axios({
            url: "api/change_status_account_polish_party",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Status Changed.", "success");
          //this.$router.go();
          _this.getuserDetails(this.user_id);
        }
      });
    }
  },
  
})
</script>

