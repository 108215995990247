<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Stock Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <button @click="printReportDetails" class="btn btn-primary shadow-md mr-3">Print</button>

        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <a href="javascript:;" data-toggle="modal" data-target="#new-filter-modal" class="btn btn-primary shadow-md">Filter
                <ChevronsDownIcon class="h-4 w-4 ml-1" /></a>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Stock Report</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div v-if="pagedata.generate == 'gen'">
                <div class="border mt-5 p-0 shadow-md" v-for="(stone_item,stone_index) in pagedata.report_items" :key="stone_index">

                    <div class="overflow-x-auto m-0 p-0">

                        <table class="text-xs text-center w-full">

                            <thead>
                                <tr class="border bg-gray-200 dark:bg-dark-1">
                                    <th class="border py-2 px-2 dark:border-dark-5 whitespace-nowrap"></th>
                                    <th class="border py-2 px-2 dark:border-dark-5 whitespace-nowrap" :colspan="stone_item.stone_size - 1">{{ stone_item.stone_name }}</th>
                                </tr>
                                <tr class="border bg-gray-200 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in stone_item.shape_arr" :key="shape_index" :colspan="stone_item.shape_size_arr[shape_index]">{{ shape_item }}</th>
                                </tr>
                                <tr class="border bg-gray-100 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in stone_item.size_arr" :key="size_index" :colspan="stone_item.size_arr_number[size_index]">{{ size_item }}</th>
                                </tr>
                                <tr class="border bg-gray-100 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in stone_item.size_head_number" :key="size_index">{{ size_item }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border" v-for="(size_value_item,size_value_index) in stone_item.size_value_arr" :key="size_value_index">
                                    <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                                </tr>
                                <tr class="border">
                                    <th class="border py-2" v-for="(item,inx) in stone_item.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Charni modal-->

    <div id="new-filter-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Report Filters</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body overflow-x-auto">
                    <table class="table w-full">
                        <tr class="border-b">
                            <th>Stone</th>
                            <th>Shape</th>
                            <th>Size</th>
                            <th>Bhav</th>
                            <th>Charni</th>
                        </tr>
                        <tr v-for="(item,index) in form_data" :key="index">
                            <th class="border-b py-2 px-2">
                                <div class="flex items-center">
                                    <input type="checkbox" class="p-2 form-check" v-model="item.stone_selected" :disabled="item.select_duty == 0">
                                    <span class="ml-2"> {{ item.stone_name }}</span>
                                </div>
                            </th>
                            <th class="border-b py-2 px-2">
                                <!-- <div class="flex items-center">
                    <input type="checkbox" class="p-2 form-check" name="shape_select" v-model="item.shape_arr" value="0">
                    <span class="ml-2"> All</span>
                  </div> -->
                                <div class="flex items-center">
                                    <input type="checkbox" class="p-2 form-check" name="shape_select" v-model="item.shape_arr" value="1">
                                    <span class="ml-2"> ROUND</span>
                                </div>
                                <div class="flex items-center">
                                    <input type="checkbox" class="p-2 form-check" name="shape_select" v-model="item.shape_arr" value="2">
                                    <span class="ml-2"> FANCY</span>
                                </div>
                                <!-- <div class="flex items-center">
                    <input type="checkbox" class="p-2 form-check" name="shape_select" v-model="item.stone_selected" value="3">
                    <span class="ml-2"> {{ PRINCE }}</span>
                  </div> -->
                            </th>
                            <th class="border-b py-2 px-2">
                                <!-- <div class="flex items-center">
                    <input type="checkbox" class="p-2 form-check" name="size_select" v-model="item.size_arr" value="0">
                    <span class="ml-2"> All</span>
                  </div> -->
                                <div class="flex items-center" v-for="(itm,inx) in pagedata.segment_item_arr[index]" :key="inx">
                                    <input type="checkbox" class="p-2 form-check" name="size_select" v-model="item.size_arr" :value="itm.segment_code">
                                    <span class="ml-2"> {{ itm.segment_name }}</span>
                                </div>
                            </th>
                            <th class="border-b py-2 px-2">
                                <!-- <div class="flex items-center">
                    <input type="checkbox" class="p-2 form-check" name="bhav_select" v-model="item.bhav_arr" value="0">
                    <span class="ml-2"> All</span>
                  </div> -->
                                <div class="flex items-center">
                                    <input type="checkbox" class="p-2 form-check" name="bhav_select" v-model="item.bhav_arr" value="1">
                                    <span class="ml-2"> Stock Bhav</span>
                                </div>
                                <div class="flex items-center">
                                    <input type="checkbox" class="p-2 form-check" name="bhav_select" v-model="item.bhav_arr" value="2">
                                    <span class="ml-2"> Max Bhav</span>
                                </div>
                            </th>
                            <th class="border-b py-2 px-2">

                                <div class="grid grid-cols-12 gap-x-2">
                                    <!-- <div class="xl:col-span-2 md:col-span-3 col-span-6">

                        <div class="flex items-center">
                          <input type="checkbox" class="p-2 form-check" name="charni_select" v-model="item.charni_list" :value="'0'">
                          <span class="ml-2"> All</span>
                        </div>
                    </div> -->
                                    <div class="col-span-4" v-for="(itm,inx) in pagedata.charni_items[index]" :key="inx">

                                        <!-- <th class="border-b py-2 px-2"> -->
                                        <div class="flex items-center">
                                            <input type="checkbox" class="p-2 form-check" name="charni_select" v-model="item.charni_list" :value="itm.id">
                                            <span class="ml-2">{{itm.charni_name}}</span>
                                        </div>
                                        <!-- </th> -->
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer text-right">
                    <!-- <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button> -->
                    <div class="flex items-center justify-end">

                        <div>
                            <button type="button" class="btn btn-secondary mr-3" @click="onResetFilter">
                                Reset
                            </button>

                        </div>
                        <div>

                            <button type="button" class="btn btn-primary" @click="generateReport" :disabled="pagedata.loading == true">
                                Generate
                                <LoadingIcon icon="bars" class="w-8 h-8" v-if="pagedata.loading == true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import cash from 'cash-dom';

export default defineComponent({
    setup() {

        const swal = inject('$swal');
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))

        const pagedata = reactive({
            report_items: [],
            charni_items: {
                0: [],
                1: [],
                2: [],
                4: [],
                5: []
            },
            charni_arr: {
                0: [],
                1: [],
                2: [],
                4: [],
                5: []
            },
            segment_item_arr: {
                0: [],
                1: [],
                2: [],
                4: [],
                5: []
            },
            segment_arr: {
                0: [],
                1: [],
                2: [],
                4: [],
                5: []
            },
            generate: "no",
            loading: true,
        })
        const form_data = ref([{
                "stone_selected": true,
                "stone_type": 1,
                "stone_name": "NATURAL",
                "charni_list": pagedata.charni_arr[0],
                "shape_arr": [1, 2],
                "size_arr": pagedata.segment_arr[0],
                "bhav_arr": [1, 2]
            },
            {
                "stone_selected": true,
                "stone_type": 2,
                "stone_name": "CVD",
                "charni_list": pagedata.charni_arr[1],
                "shape_arr": [1, 2],
                "size_arr": pagedata.segment_arr[1],
                "bhav_arr": [1, 2]

            },
            {
                "stone_selected": true,
                "stone_type": 3,
                "stone_name": "HPHT",
                "charni_list": pagedata.charni_arr[2],
                "shape_arr": [1, 2],
                "size_arr": pagedata.segment_arr[2],
                "bhav_arr": [1, 2]

            },
            {
                "stone_selected": true,
                "stone_type": 4,
                "stone_name": "D_CVD",
                "charni_list": pagedata.charni_arr[3],
                "shape_arr": [1, 2],
                "size_arr": pagedata.segment_arr[3],
                "bhav_arr": [1, 2]

            },
            {
                "stone_selected": true,
                "stone_type": 5,
                "stone_name": "D_HPHT",
                "charni_list": pagedata.charni_arr[4],
                "shape_arr": [1, 2],
                "size_arr": pagedata.segment_arr[4],
                "bhav_arr": [1, 2]

            },
        ])

        const generateReport = () => {

            const params = {
                filters: form_data.value
            };

            pagedata.loading = true

            cash('#new-filter-modal').modal('hide')

            let promise = axios({
                url: "/final_polish_stock_report",
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        swal("Warning!", data.message, "warning");
                        pagedata.generate = 'no';
                        pagedata.loading = false

                        return;
                    }

                    pagedata.report_items = data.items;
                    pagedata.generate = 'gen';
                    pagedata.loading = false

                })
                .catch(_error => {

                    pagedata.generate = 'no';
                    pagedata.loading = false
                });
        }

        // Get Charni List
        const charniItems = (stone_type) => {
            let params = {
                stone_type: stone_type
            }
            let promise = axios({
                url: "/main_charni_list",
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.charni_items[stone_type - 1] = data.items;

                    pagedata.charni_items[stone_type - 1].forEach(element => {
                        pagedata.charni_arr[stone_type - 1].push(element.id)
                    });

                })
                .catch(_error => {
                    pagedata.charni_items[stone_type - 1] = [];
                });
        }
        // Get Charni List
        const charniSegmentItems = (stone_type) => {
            let params = {
                stone_type: stone_type
            }
            let promise = axios({
                url: "/dp_size_items",
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.segment_item_arr[stone_type - 1] = data.items;

                    pagedata.segment_item_arr[stone_type - 1].forEach(element => {
                        pagedata.segment_arr[stone_type - 1].push(element.segment_code)
                    });

                })
                .catch(_error => {
                    pagedata.segment_item_arr[stone_type - 1] = [];
                });
        }

        const onResetFilter = () => {
            form_data.value = [{
                    "stone_selected": true,
                    "stone_type": 1,
                    "stone_name": "NATURAL",
                    "charni_list": pagedata.charni_arr[0],
                    "shape_arr": [1, 2],
                    "size_arr": [1, 2, 3],
                    "bhav_arr": [1, 2],
                },
                {
                    "stone_selected": true,
                    "stone_type": 2,
                    "stone_name": "CVD",
                    "charni_list": pagedata.charni_arr[1],
                    "shape_arr": [1, 2],
                    "size_arr": [1, 2, 3],
                    "bhav_arr": [1, 2],
                },
                {
                    "stone_selected": true,
                    "stone_type": 3,
                    "stone_name": "HPHT",
                    "charni_list": pagedata.charni_arr[2],
                    "shape_arr": [1, 2],
                    "size_arr": [1, 2, 3],
                    "bhav_arr": [1, 2],

                },
                {
                    "stone_selected": true,
                    "stone_type": 4,
                    "stone_name": "D_CVD",
                    "charni_list": pagedata.charni_arr[3],
                    "shape_arr": [1, 2],
                    "size_arr": [1, 2, 3],
                    "bhav_arr": [1, 2],
                },
                {
                    "stone_selected": true,
                    "stone_type": 5,
                    "stone_name": "D_HPHT",
                    "charni_list": pagedata.charni_arr[4],
                    "shape_arr": [1, 2],
                    "size_arr": [1, 2, 3],
                    "bhav_arr": [1, 2],

                },
            ]
        }

        function printReportDetails() {
            let report_type = 'final-polish-stock-report'

            let params = {
                report_type: report_type,
                filters: form_data.value,
                items: pagedata.report_items
            }
            let promise = axios({
                url: "/add_report_details",
                method: "post",
                baseURL: BASE_URL_SELLING,
                data: params,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    const report_id = data.report_id

                    let url = BASE_URL_SELLING + '/' + data.report_type + '?id=' + window.btoa(report_id)
                    window.open(url, '_blank');

                })
                .catch(_error => {

                });
            // add_report_details
        }

        function updateShapeValue(item) {
            if (item.shape_arr.includes(0)) {
                item.shape_arr = [0, 1, 2]
            } else {
                item.shape_arr = []
            }
        }

        onMounted(() => {
            form_data.value.forEach(element => {
                charniItems(element.stone_type)
                charniSegmentItems(element.stone_type)
            });
            setTimeout(() => {

              generateReport()
            }, 1000);
        })

        return {
            form_data,
            pagedata,
            onResetFilter,
            generateReport,
            printReportDetails,
            updateShapeValue
        }
    }
})
</script>
