<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Purity Size (Polish Selling)</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

              <a href="javascript:;" data-toggle="modal" data-target="#new-color-segment-modal" @click="shade_segment_details" class="btn btn-primary shadow-md mr-2">New Color Range</a>

          </div>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

              <a href="javascript:;" data-toggle="modal" data-target="#new-sengment-modal" @click="segment_details" class="btn btn-primary shadow-md mr-2">New Purity Range</a>

          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">

          <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                  <div class="grid grid-cols-12 gap-x-2">
                      <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                          <div class="flex items-center sm:mr-2">

                              <label class="mr-2">Field</label>
                              <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select">
                                  <option value="segment_name">Size Name</option>
                                  <option value="segment_code">SizeCode</option>
                              </select>
                          </div>
                      </div>
                      <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
                          <div class="flex items-center sm:mr-2">

                              <label class="mr-2">Type</label>
                              <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-fullo">
                                  <option value="like" selected>like</option>
                                  <option value="=">=</option>
                                  <option value="<">&lt;</option>
                                  <option value="<=">&lt;=</option>
                                  <option value=">">></option>
                                  <option value=">=">>=</option>
                                  <option value="!=">!=</option>
                              </select>
                          </div>
                      </div>
                      <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                          <div class="flex items-center sm:mr-2">

                              <label class="mr-2">Value</label>
                              <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40" placeholder="Search..." />
                          </div>
                      </div>
                      <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                          <div class="flex items-center sm:mr-2">

                              <select v-model="screenData.selected_stone" @change="shade_size_item_details" class="form-select w-full">
                                  <option value="0">Select Stone</option>
                                  <option value="1">NATURAL</option>
                                  <option value="2">CVD</option>
                                  <option value="3">HPHT</option>
                                  <option value="4">D_CVD</option>
                                  <option value="5">D_HPHT</option>
                              </select>
                          </div>
                      </div>
                      <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                          <div class="flex items-center sm:mr-2">

                              <select v-model="screenData.color_size" @change="initTabulator" class="form-select w-full">
                                  <option value="0">Select Color</option>
                                  <option v-for="(item,index) in screenData.color_size_detail" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                          <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full" @click="onFilter">
                              Go
                          </button>
                      </div>
                      <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                          <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full" @click="onResetFilter">
                              Reset
                          </button>
                      </div>
                  </div>
              </form>
              <div class="flex mt-5 sm:mt-0">

                  <div class="dropdown w-1/2 sm:w-auto">
                      <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                          <FileTextIcon class="w-4 h-4 mr-2" /> Export
                          <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                      </button>
                      <div class="dropdown-menu w-40">
                          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                              <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportCsv">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                              </a>
                              <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportJson">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                              </a>
                              <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                              </a>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="overflow-x-auto scrollbar-hidden">

              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->

      <!-- Add Segment modal-->

      <div id="new-sengment-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-md">
              <div class="modal-content">
                  <div class="modal-header">
                      <h2 class="font-medium text-base mr-auto">Segments Details</h2>
                  </div>
                  <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                      <div class="col-span-6 mt-3">
                          <label for="pos-form-1" class="form-label">Select Stone</label>
                          <TomSelect v-model="segment_form.stone_type" :options="{
                    placeholder: 'Stone Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stone Type'
                      }
                    }
                  }" @change="purity_stone_change" class="w-full">
                              <option value="1">NATURAL</option>
                              <option value="2">CVD</option>
                              <option value="3">HPHT</option>
                              <option value="4">D_CVD</option>
                              <option value="5">D_HPHT</option>
                          </TomSelect>
                      </div>
                      <div class="col-span-6 mt-3">
                          <label for="pos-form-1" class="form-label">Select Color</label>
                          <TomSelect v-model="segment_form.color_size" :options="{
                    placeholder: 'Stone Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stone Type'
                      }
                    }
                  }" @change="segment_details" class="w-full">
                              <option v-for="(item,index) in screenData.color_size_items" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                          </TomSelect>
                      </div>
                      <div class="col-span-12 mt-3">Size Details</div>
                      <div class="col-span-12 mt-3">

                          <table class="w-full">
                              <thead>
                                  <tr>
                                      <th class="py-2 text-left">Size</th>
                                      <th class="py-2 text-left">Purity</th>
                                      <th class="py-2 text-left"></th>
                                  </tr>
                              </thead>
                              <tbody v-for="(item,index) in segment_form.values" :key="index">
                                  <tr>
                                      <td class="py-2 w-28">Size {{ item.size }}</td>
                                      <td class="py-2">
                                          <TomSelect v-model="item.purity" :options="{
                            placeholder: 'Select Purity',
                            loadingClass: 'loading',
                            plugins: {
                              dropdown_header: {
                                title: 'Size Purity'
                              }
                            }
                          }" multiple class="w-full" @change="validate_purity_list(index, item)">
                                              <option v-for="purity in screenData.purity_items" :key="purity.id" :value="purity.id">{{purity.purity_name}}</option>
                                          </TomSelect>
                                      </td>
                                      <td class="py-2 w-8">
                                          <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeSegment(item.id)">
                                              <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>

                          <div class="p-0">
                              <button type="button" @click="add_segment" class="btn btn-primary mr-auto">
                                  Add
                              </button>
                              <button type="button" @click="reset_segment" class="btn btn-secondary mr-auto">
                                  Reset
                              </button>

                          </div>
                      </div>
                  </div>
                  <div class="modal-footer text-right">
                      <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                          Cancel
                      </button>
                      <button type="button" class="btn btn-primary w-32" @click="submitSegmentForm">
                          Save Details
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <!-- Add Segment modal-->

      <div id="new-color-segment-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h2 class="font-medium text-base mr-auto">Color Size Details</h2>
                  </div>
                  <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                      <div class="col-span-12 mt-3">
                          <label for="pos-form-1" class="form-label">Select Stone</label>
                          <TomSelect v-model="color_segment_form.stone_type" :options="{
                    placeholder: 'Stone Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stone Type'
                      }
                    }
                  }" @change="shade_segment_details" class="w-full">
                              <option value="1">NATURAL</option>
                              <option value="2">CVD</option>
                              <option value="3">HPHT</option>
                              <option value="4">D_CVD</option>
                              <option value="5">D_HPHT</option>
                          </TomSelect>
                      </div>
                      <div class="col-span-12 mt-3">Color Size</div>
                      <div class="col-span-12 mt-3">

                          <table class="w-full">
                              <thead>
                                  <tr>
                                      <th class="py-2 text-center">NO</th>
                                      <th class="py-2 text-center">Color Name</th>
                                      <th class="py-2 text-center">Color</th>
                                      <th class="py-2 text-center"></th>
                                  </tr>
                              </thead>
                              <tbody v-for="(item,index) in color_segment_form.values" :key="index">
                                  <tr>
                                      <td class="py-2 text-center">{{ index + 1 }}</td>
                                      <td class="py-2 text-center"><input v-model="item.segment_name" type="text" class="form-control w-40" placeholder="Size Name" /></td>
                                      <td class="py-2 text-center">
                                          <TomSelect v-model="item.purity" :options="{
                            placeholder: 'Select Color',
                            loadingClass: 'loading',
                            plugins: {
                              dropdown_header: {
                                title: 'Size Color'
                              }
                            }
                          }" multiple class="w-40" @change="validate_color_list(index, item)">
                                              <option v-for="color in screenData.color_items" :key="color.id" :value="color.id">{{color.shade_name}}</option>
                                          </TomSelect>
                                      </td>
                                      <td class="py-2 w-8">
                                          <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="shade_removeSegment(item.id)">
                                              <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>

                          <div class="p-0">
                              <button type="button" @click="shade_add_segment" class="btn btn-primary mr-auto">
                                  Add
                              </button>
                              <button type="button" @click="shade_reset_segment" class="btn btn-secondary mr-auto">
                                  Reset
                              </button>

                          </div>
                      </div>
                  </div>
                  <div class="modal-footer text-right">
                      <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                          Cancel
                      </button>
                      <button type="button" class="btn btn-primary w-32" @click="submitShadeSegmentForm">
                          Save Details
                      </button>
                  </div>
              </div>
          </div>
      </div>

      <div id="success-notification-content" class="toastify-content hidden flex">
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
              <div class="font-medium">Details saved successfully!</div>
              <div class="text-gray-600 mt-1">

              </div>
          </div>
      </div>
      <!-- END: Success Notification Content -->
      <!-- BEGIN: Failed Notification Content -->
      <div id="failed-notification-content" class="toastify-content hidden flex">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
              <div class="font-medium">Something went wrong!</div>
              <div class="text-gray-600 mt-1">
                  Please check the fileld form.
              </div>
          </div>
      </div>
      <!-- END: Failed Notification Content -->
  </div>
  </template>

  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import xlsx from 'xlsx'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import axios from 'axios'
  import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'

  export default defineComponent({
      setup() {
          const tableRef = ref()
          const tabulator = ref()
          const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
          const filter = reactive({
              field: 'segment_name',
              type: 'like',
              value: ''
          })
          const swal = inject('$swal')

          const segment_form = reactive({
              stone_type: "1",
              color_size: "",
              size_item: 1,
              values: [{
                  size: 1,
                  purity: [],
                  id: "",
              }],
          })
          const color_segment_form = reactive({
              stone_type: "1",
              size_item: 1,
              values: [{
                  size: 1,
                  segment_name: "",
                  purity: [],
                  id: "",
              }],
          })

          const screenData = reactive({
              purity_items: [],
              color_items: [],
              color_size_items: [],
              color_size_detail: [],
              selected_stone: "0",
              color_size: "0"
          })

          const initTabulator = () => {

              let params = {
                  stone_type: screenData.selected_stone == "0" ? '' : screenData.selected_stone,
                  color_size: screenData.color_size == "0" ? '' : screenData.color_size
              }

              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + '/api/get_charni_segment_details',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  headerSort: false,
                  printStyled: true,
                  // pagination: 'remote',
                  // paginationSize: 20,
                  // paginationSizeSelector: [10, 20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: 'collapse',
                  placeholder: 'No matching records found',
                  columns: [{
                          formatter: 'responsiveCollapse',
                          width: 40,
                          minWidth: 30,
                          hozAlign: 'center',
                          resizable: false,
                          headerSort: false
                      },

                      // For HTML table
                      // {
                      //     title: 'NO.',
                      //     width: 50,
                      //     responsive: 0,
                      //     field: 'id',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     download: false,

                      // },
                      {
                          title: 'Size Name',
                          minWidth: 100,
                          responsive: 0,
                          field: 'segment_name',
                          vertAlign: 'middle',
                          hozAlign: 'left',
                          print: false,
                          download: false,

                      },
                      {
                          title: 'Size Code',
                          minWidth: 100,
                          responsive: 0,
                          field: 'segment_code',
                          vertAlign: 'middle',
                          hozAlign: 'center',
                          print: false,
                          download: false,

                      },
                      {
                          title: 'Color',
                          minWidth: 90,
                          responsive: 0,
                          field: 'color_segment',
                          vertAlign: 'middle',
                          hozAlign: 'center',
                          print: false,
                          download: false,

                      },
                      {
                          title: 'Stone',
                          minWidth: 90,
                          responsive: 0,
                          field: 'stone_type',
                          vertAlign: 'middle',
                          hozAlign: 'center',
                          print: false,
                          download: false,
                          formatter(cell) {
                            if(cell.getData().stone_type == 1) {
                              return "NATURAL"
                            } else if(cell.getData().stone_type == 2) {
                              return "CVD"
                            } else if(cell.getData().stone_type == 3) {
                              return "HPHT"
                            } else if(cell.getData().stone_type == 4) {
                              return "D_CVD"
                            } else if(cell.getData().stone_type == 5) {
                              return "D_HPHT"
                            }
                          }

                      },

                      {

                          title: 'Purity List',
                          field: 'purity',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }

          // Redraw table onresize
          const reInitOnResizeWindow = () => {
              window.addEventListener('resize', () => {
                  tabulator.value.redraw()
                  feather.replace({
                      'stroke-width': 1.5
                  })
              })
          }

          // Filter function
          const onFilter = () => {
              tabulator.value.setFilter(filter.field, filter.type, filter.value)
          }

          const submitSegmentForm = async () => {

              // Validate Segments

              let prt_length = segment_form.values.length

              for (let i = 0; i < prt_length; i++) {
                if (segment_form.values[i].purity.length == 0) {
                  swal('Warning', "Purity is Empty in Size" + segment_form.values[i].size, "warning")
                  return;

                }
              }
              let promise_url = 'api/add_charni_segment_details';

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: segment_form,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    if(data.error == "NO") {
                      let promise_url_selling = '/add_charni_segment_details';

                      let promise = axios({
                          url: promise_url_selling,
                          method: "POST",
                          data: segment_form,
                          baseURL: BASE_URL_SELLING,
                          headers: {
                              Authorization: "Bearer " + AUTH_TOKEN
                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {

                            if(data.error == "NO") {
                              swal('Success', "Purity Added Successfully", "success")
                              reset_segment()
                              initTabulator()

                              cash('#new-sengment-modal').modal('hide')

                              return
                            }
                            swal('Error', "Something went wrong" + ", On Server", "error")
                            return

                            // initTabulator()

                            // cash('#new-sengment-modal').modal('hide')
                          })
                          .catch(_error => {
                            swal("Error!", "Data not Saved" + ", On Server", "error")
                            return

                          });
                    }
                    swal('Error', "Something went wrong", "error")
                    return

                    // initTabulator()

                    // cash('#new-sengment-modal').modal('hide')
                  })
                  .catch(_error => {
                    swal('Error', "Something went wrong", "error")
                    return

                  });

          }
          const submitShadeSegmentForm = async () => {

              // Validate Segments

              let prt_length = color_segment_form.values.length

              for (let i = 0; i < prt_length; i++) {
                if (color_segment_form.values[i].purity.length == 0) {
                  swal('Warning', "Purity is Empty in Size" + color_segment_form.values[i].size, "warning")
                  return;

                }
              }
              let promise_url = 'api/add_charni_shade_segment_details';

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: color_segment_form,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    if(data.error == "NO") {
                      let promise_url_selling = '/add_charni_shade_segment_details';

                      let promise = axios({
                          url: promise_url_selling,
                          method: "POST",
                          data: segment_form,
                          baseURL: BASE_URL_SELLING,
                          headers: {
                              Authorization: "Bearer " + AUTH_TOKEN
                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {

                            if(data.error == "NO") {
                              swal('Success', "Purity Added Successfully", "success")
                              reset_segment()
                              initTabulator()

                              cash('#new-sengment-modal').modal('hide')

                              return
                            }
                            swal('Error', "Something went wrong" + ", On Server", "error")
                            return

                            // initTabulator()

                            // cash('#new-sengment-modal').modal('hide')
                          })
                          .catch(_error => {
                            swal("Error!", "Data not Saved" + ", On Server", "error")
                            return

                          });
                    }
                    swal('Error', "Something went wrong", "error")
                    return

                    // initTabulator()

                    // cash('#new-sengment-modal').modal('hide')
                  })
                  .catch(_error => {
                    swal('Error', "Something went wrong", "error")
                    return

                  });

          }

          // On reset filter
          const onResetFilter = () => {
              filter.field = 'segment_name'
              filter.type = 'like'
              filter.value = ''
              onFilter()
          }

          const add_segment = () => {
              segment_form.size_item += 1;
              segment_form.values.push({
                  id: segment_form.size_item,
                  purity: [],
                  size: segment_form.size_item,
              });

              // re_index_arr()
          }
          const reset_segment = () => {
            segment_form.size_item = 1;
            segment_form.values = []
              segment_form.values.push({
                  id: 1,
                  purity: [],
                  size: 1,
              });
          }
          const removeSegment = (id) => {
              swal({
                  title: "Are you sure?",
                  text: "You are about to Delete Item!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Delete",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",
                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {
                      var i = segment_form.values.length;
                      while (i--) {
                          if (segment_form.values[i]["id"] == id) {
                              segment_form.values.splice(i, 1);
                          }
                      }

                      // re_index_arr()
                  }
              });
          }
          const shade_add_segment = () => {
              color_segment_form.size_item += 1;
              color_segment_form.values.push({
                  id: color_segment_form.size_item,
                  purity: [],
                  segment_name: "",
                  size: color_segment_form.size_item,
              });

              // re_index_arr()
          }
          const shade_reset_segment = () => {
            color_segment_form.size_item = 1;
            color_segment_form.values = []
              color_segment_form.values.push({
                  id: 1,
                  purity: [],
                  segment_name: "",
                  size: 1,
              });
          }
          const shade_removeSegment = (id) => {
              swal({
                  title: "Are you sure?",
                  text: "You are about to Delete Item!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Delete",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",
                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {
                      var i = color_segment_form.values.length;
                      while (i--) {
                          if (color_segment_form.values[i]["id"] == id) {
                              color_segment_form.values.splice(i, 1);
                          }
                      }

                      // re_index_arr()
                  }
              });
          }

          const segment_details = async () => {

            let params = {
              stone_type: segment_form.stone_type,
              color_size: segment_form.color_size
            }

            // Get Segment Details
            let promise_url = 'api/dp_charni_selling_segment';

              let promise = axios({
                  url: promise_url,
                  data: params,
                  method: "POST",
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    let items = data.items

                    segment_form.values = []
                    segment_form.size_item = 0;
                    if(items.length > 0) {


                      items.forEach(element => {

                        segment_form.size_item += 1;
                        segment_form.values.push({
                            id: segment_form.size_item,
                            purity: element.purity,
                            size: element.size,
                        });
                      });

                      // re_index_arr()

                      return
                    }

                    segment_form.size_item = 1;
                        segment_form.values.push({
                            id: 1,
                            purity: [],
                            size: 1,
                        });

                        // re_index_arr()


                  })
                  .catch(_error => {

                  });
          }
          const shade_segment_details = async () => {

            let params = {
              stone_type: color_segment_form.stone_type
            }

            // Get Segment Details
            let promise_url = 'api/dp_charni_shade_selling_segment';

              let promise = axios({
                  url: promise_url,
                  data: params,
                  method: "POST",
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    let items = data.items

                    color_segment_form.values = []
                    color_segment_form.size_item = 0;
                    if(items.length > 0) {


                      items.forEach(element => {

                        color_segment_form.size_item += 1;
                        color_segment_form.values.push({
                            id: segment_form.size_item,
                            purity: element.color,
                            segment_name: element.segment_name,
                            size: element.size,
                        });
                      });

                      // re_index_arr()

                      return
                    }

                    color_segment_form.size_item = 1;
                        color_segment_form.values.push({
                            id: 1,
                            segment_name: "",
                            purity: [],
                            size: 1,
                        });

                        // re_index_arr()


                  })
                  .catch(_error => {

                  });
          }
          const shade_size_items = async () => {

            let params = {
              stone_type: segment_form.stone_type
            }

            // Get Segment Details
            let promise_url = 'api/dp_shade_size_items';

              let promise = axios({
                  url: promise_url,
                  data: params,
                  method: "POST",
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                    screenData.color_size_items = data.items
                    segment_form.color_size = screenData.color_size_items[0].segment_code
                    // setTimeout(() => {

                    //   segment_details()
                    // }, 50);
                  })
                  .catch(_error => {
                    screenData.color_size_items = []
                    segment_form.color_size = ""
                    // setTimeout(() => {

                    //   segment_details()
                    // }, 50);
                  });
          }
          const shade_size_item_details = async () => {

            let params = {
              stone_type: screenData.selected_stone
            }

            // Get Segment Details
            let promise_url = 'api/dp_shade_size_items';

              let promise = axios({
                  url: promise_url,
                  data: params,
                  method: "POST",
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                    screenData.color_size_detail = data.items
                    screenData.color_size = "0"

                    initTabulator()
                    // setTimeout(() => {

                      //   segment_details()
                      // }, 50);
                    })
                    .catch(_error => {
                      screenData.color_size_detail = []
                      screenData.color_size = "0"
                      initTabulator()
                    // setTimeout(() => {

                    //   segment_details()
                    // }, 50);
                  });
          }

          const validate_purity_list = (index, item_prt) => {

            let error_message = "";

            segment_form.values.forEach(function (item, index, object) {


              if(object[index]["id"] != item_prt.id) {
                let segment_message = "";

                if (item_prt.purity.length > 0) {
                  item_prt.purity.forEach(function (prt_item, prt_index, prt_object) {

                    if(item.purity.includes(prt_item)) {

                      screenData.purity_items.forEach(element => {
                          if (prt_object[index] == element.id) {
                              if (segment_message == '') {
                                  segment_message = element.purity_name
                              } else {
                                  segment_message = segment_message + ', ' + element.purity_name

                              }
                          }
                      });
                      prt_object.splice(prt_index, 1)

                    }
                  });
                }
                if(segment_message != "") {
                  segment_message += " Found in Size " + item.id + "! <br>"
                  error_message = error_message + segment_message
                }

              }

            });

            if (error_message!= "") {
              swal('Warning', error_message, "warning")
              return
            }
          }
          const validate_color_list = (index, item_prt) => {

            let error_message = "";

            color_segment_form.values.forEach(function (item, index, object) {


              if(object[index]["id"] != item_prt.id) {
                let segment_message = "";

                if (item_prt.purity.length > 0) {
                  item_prt.purity.forEach(function (prt_item, prt_index, prt_object) {

                    if(item.purity.includes(prt_item)) {

                      screenData.color_items.forEach(element => {
                          if (prt_object[index] == element.id) {
                              if (segment_message == '') {
                                  segment_message = element.shade_name
                              } else {
                                  segment_message = segment_message + ', ' + element.shade_name

                              }
                          }
                      });
                      prt_object.splice(prt_index, 1)

                    }
                  });
                }
                if(segment_message != "") {
                  segment_message += " Found in Other Size! <br>"
                  error_message = error_message + segment_message
                }

              }

            });

            if (error_message!= "") {
              swal('Warning', error_message, "warning")
              return
            }
          }

          const re_index_arr = () => {
            segment_form.values.forEach(function (item, index, object) {
              object[index]["id"] = index + 1;
              object[index]["size"] = index + 1;
            });
          }

          const get_purity_items = async () => {
              const params = {};

              let promise = axios({
                  url: "api/dp_purity",
                  method: "POST",
                  params: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      screenData.purity_items = data.items

                  })
                  .catch(_error => {

                      screenData.purity_items = []
                  });
          }
          const get_color_items = async () => {
              const params = {};

              let promise = axios({
                  url: "api/dp_color",
                  method: "POST",
                  params: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      screenData.color_items = data.items

                  })
                  .catch(_error => {

                      screenData.color_items = []
                  });
          }

          function purity_stone_change() {
            shade_size_items()
            // setTimeout(() => {
            //   segment_details()
            // }, 250);
          }

          // Export
          const onExportCsv = () => {
              tabulator.value.download('csv', 'data.csv')
          }

          const onExportJson = () => {
              tabulator.value.download('json', 'data.json')
          }

          const onExportXlsx = () => {
              const win = window
              win.XLSX = xlsx
              tabulator.value.download('xlsx', 'data.xlsx', {
                  sheetName: 'Products'
              })
          }

          const onExportHtml = () => {
              tabulator.value.download('html', 'data.html', {
                  style: true
              })
          }

          // Print
          const onPrint = () => {
              tabulator.value.print()
          }

          onMounted(() => {
              initTabulator()
              reInitOnResizeWindow()
              get_purity_items()
              get_color_items()
              shade_size_items()
          })

          return {
              tableRef,
              segment_form,
              filter,
              color_segment_form,
              screenData,
              submitSegmentForm,
              onFilter,
              onResetFilter,
              onExportCsv,
              onExportJson,
              onExportXlsx,
              onExportHtml,
              onPrint,
              initTabulator,
              add_segment,
              reset_segment,
              removeSegment,
              segment_details,
              shade_segment_details,
              validate_purity_list,
              validate_color_list,
              shade_add_segment,
              shade_reset_segment,
              shade_removeSegment,
              submitShadeSegmentForm,
              shade_size_items,
              purity_stone_change,
              shade_size_item_details
          }
      }
  })
  </script>
