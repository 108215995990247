<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Update Stocks</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-6 md:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="screenData.scan_lot_no"
                    :class="{'form-control':true,'loading':screenData.scanload}"
                    :disabled = "screenData.scanload"
                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <!-- <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <div class="hidden">
      <input type="file" name="newExcelFile" id="newExcelFile" @change="getExcelData"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
    </div>

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Scaned Packet History</h2>
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <button
                @click="inputFileClick" :disabled="screenData.excel_loading == true"
                class="btn btn-primary shadow-md mr-2">
                Excel/ CSV Stocks <LoadingIcon v-if="screenData.excel_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
           class="btn btn-primary shadow-md mr-2" @click="takeMultiReturn" :disabled="screenData.return_progress">
           Update Stocks
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress"/>
          </button>
          </div>
        </div>
        <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                </div>
              </div>
            <div class="col-span-4 xl:col-span-4">
               <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Pcs Weight. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}}  </label>
                </div>
            </div>
             <div class="col-span-4 xl:col-span-4 text-right">
               <div>
                <button
           class="btn btn-sm btn-primary shadow-md mr-2 mb-2" v-print="'#print-lab-barcode'" v-if="screenData.form_type == 'update'">
           Print All
          </button>
                <button
           class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="resetData">
           Reset Form
          </button>
                <button
           class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect" :disabled="screenData.delete_row">
           Remove Selected Row
          </button>
                </div>
            </div>
              <div class="col-span-12 ">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="width: 100%; height: 500px;"
              :columnDefs="columnDefs.value"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              :rowMultiSelectWithClick="true"
              animateRows="true"
              @grid-ready="onGridReady"
              @selection-changed="onGridRowSelect"
              :overlayNoRowsTemplate="overlayNoRowsTemplate"
            >
            </ag-grid-vue>
              </div>

            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div class="hidden">
      <div  id="print-lab-barcode" class="absolute left-0 top-0 p-0 m-0">

        <div style="page-break-before: always;" :class="{'mt-5': index != 0}" v-for="(print_params,index) in rowData.value" :key="index">
          <table class="p-0 m-0 w-full text-black" border="collapse">
          <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.lab_no }}</th>
            <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.pack_no }}</th>
            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.cr_wt }} Ct</th>
          </tr>
          <tr class="text-center m-0 p-0">
            <th colspan="7" class="text-center">
              <vue-barcode  :value="print_params.barcode" :options="{ displayValue: true,height:25,width:2 }"></vue-barcode>
            </th>
          </tr>
          <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
            <th class="border m-auto border-black">SHP</th>
            <th class="border m-auto border-black">CL</th>
            <th class="border m-auto border-black">PRT</th>
            <th class="border m-auto border-black">CT</th>
            <th class="border m-auto border-black">PL</th>
            <th class="border m-auto border-black">SYM</th>
            <th class="border m-auto border-black">Fl</th>
          </tr>
          <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
            <th class="border m-auto border-black">{{ print_params.shape ? print_params.shape : '-' }}</th>
            <th class="border m-auto border-black">{{ print_params.color ? print_params.color : '-' }}</th>
            <th class="border m-auto border-black">{{ print_params.purity ? print_params.purity : '-' }}</th>
            <th class="border m-auto border-black">{{ print_params.cut ? print_params.cut : '-' }}</th>
            <th class="border m-auto border-black">{{ print_params.polish ? print_params.polish : '-' }}</th>
            <th class="border m-auto border-black">{{ print_params.symmetry ? print_params.symmetry : '-' }}</th>
            <th class="border m-auto border-black">{{ print_params.fluerocent ? print_params.fluerocent : '-' }}</th>
          </tr>
        </table>
        <p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 4px;font-weight: bold;">DK & Sons</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted,inject } from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import json5 from 'json5';
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
  components: {
   AgGridVue,
   ShapeRenderer,
   VueBarcode
 },
  setup() {
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    class DatePicker {
      // gets called once before the renderer is used
      init(params) {
        // create the cell
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        this.eInput.classList.add('ag-input');
        this.eInput.style.height = 'var(--ag-row-height)';
        this.eInput.style.fontSize = 'calc(var(--ag-font-size) + 1px)';

        // https://jqueryui.com/datepicker/
        $(this.eInput).datepicker({
          dateFormat: 'dd/mm/yy',
          onSelect: () => {
            this.eInput.focus();
          },
        });
      }

        // gets called once when grid ready to insert the element
  getGui() {
    return this.eInput;
  }

  // focus and select can be done after the gui is attached
  afterGuiAttached() {
    this.eInput.focus();
    this.eInput.select();
  }

  // returns the new value after editing
  getValue() {
    return this.eInput.value;
  }

  // any cleanup we need to be done here
  destroy() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
  }

  // if true, then this editor will appear in a popup
  isPopup() {
    // and we could leave this method out also, false is the default
    return false;
  }
}

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      scanload: false,
      return_progress:false,
      delete_row:true,
      excel_loading:false,
      scan_lot_no:'',
      total_scan_pack:0,
      total_scan_pack_wt:0,
      shapes:[],
      colors:[],
      purity:[],
      lab_file:'',
      form_type:'create'

    })

    const onGridReady = (params) => {
     gridApi.value = params.api;
    };

   const rowData = reactive({
    value:[]
   });

   const overlayNoRowsTemplate = 'Scan packets for Stock Update!'

   const columnDefs = reactive({
     value: [
          { headerName: "#",field: 'sr_no',maxWidth: 60, },
          { headerName: "Packet No",field: 'lot_no',minWidth: 120 },
          { headerName: "Lab No",field: 'lab_no',minWidth: 120 },
          { headerName: "Weight",field: 'weight' , hide: true},
          { headerName: "Cr Weight",field: 'new_wt',editable: true ,minWidth: 120,
            valueGetter: (params) => {
              if (params.data.new_wt) {
                return params.data.new_wt;
              } else {
                return 0;
              }
            },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = parseFloat(params.newValue);

              if(isNaN(newValInt)){
                alert('Not a Valid Number');
                params.data.new_wt = params.data.new_wt ;
                return false;

              }else{
                var valueChanged = params.data.new_wt !== newValInt;
                  if (valueChanged) {
                    params.data.new_wt = newValInt;
                  }
                  return valueChanged;
              }
            },
          },
          { headerName: "Lab",field: 'lab',editable: true ,minWidth: 100,
            // valueGetter: (params) => {
            //   if (params.data.lab) {
            //     return params.data.lab;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.lab !== newValInt;
                if (valueChanged) {
                  params.data.lab = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Stone",field: 'stone',editable: false ,
            // valueGetter: (params) => {
            //   if (params.data.lab) {
            //     return params.data.lab;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.stone !== newValInt;
                if (valueChanged) {
                  params.data.stone = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Report No",field: 'report_no',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.report_no) {
            //     return params.data.report_no;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.report_no !== newValInt;
                if (valueChanged) {
                  params.data.report_no = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Shape",field: 'shape',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.shape) {
            //     return params.data.shape;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.shape !== newValInt;
                if (valueChanged) {
                  params.data.shape = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Color",field: 'color',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.color) {
            //     return params.data.color;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.color !== newValInt;
                if (valueChanged) {
                  params.data.color = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Purity",field: 'purity',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.purity) {
            //     return params.data.purity;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.purity !== newValInt;
                if (valueChanged) {
                  params.data.purity = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Cut",field: 'cut',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.cut) {
            //     return params.data.cut;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              if (params.newValue == '') {
                alert('Value cannot be empty');
                return false;
              }
              var newValInt = params.newValue;

              var valueChanged = params.data.cut !== newValInt;
                if (valueChanged) {
                  params.data.cut = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Polish",field: 'polish',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.polish) {
            //     return params.data.polish;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.polish !== newValInt;
                if (valueChanged) {
                  params.data.polish = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Symmetry",field: 'symmetry',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.symmetry) {
            //     return params.data.symmetry;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.symmetry !== newValInt;
                if (valueChanged) {
                  params.data.symmetry = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Fluerocent",field: 'fluerocent',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.fluerocent) {
            //     return params.data.fluerocent;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.fluerocent !== newValInt;
                if (valueChanged) {
                  params.data.fluerocent = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Discount",field: 'discount',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.discount) {
            //     return params.data.discount;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = parseFloat(params.newValue);

              if(isNaN(newValInt)){
                alert('Not a Valid Number');
                params.data.new_wt = params.data.new_wt ;
                return false;

              }else{
                var valueChanged = params.data.discount !== newValInt;
                if (valueChanged) {
                  params.data.discount = newValInt;
                }
                return valueChanged;
              }
            },
          },
          { headerName: "MEASUREMENT",field: 'measurement',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.measurement) {
            //     return params.data.measurement;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.measurement !== newValInt;
                if (valueChanged) {
                  params.data.measurement = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Table",field: 'table',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.table) {
            //     return params.data.table;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.table !== newValInt;
                if (valueChanged) {
                  params.data.table = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "T.Depth",field: 't_depth',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.t_depth) {
            //     return params.data.t_depth;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.t_depth !== newValInt;
                if (valueChanged) {
                  params.data.t_depth = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Comment",field: 'comment',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.comment) {
            //     return params.data.comment;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.comment !== newValInt;
                if (valueChanged) {
                  params.data.comment = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Rapo $",field: 'rapo',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.rapo) {
            //     return params.data.rapo;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.rapo !== newValInt;
                if (valueChanged) {
                  params.data.rapo = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Rapo $ / CT",field: 'rapo_per_ct',true: false ,
            // valueGetter: (params) => {
            //   if (params.data.rapo_per_ct) {
            //     return params.data.rapo_per_ct;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.rapo_per_ct !== newValInt;
                if (valueChanged) {
                  params.data.rapo_per_ct = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Total Price",field: 'total_price',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.total_price) {
            //     return params.data.total_price;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.total_price !== newValInt;
                if (valueChanged) {
                  params.data.total_price = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Certificate Link",field: 'certificate_link',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.certificate_link) {
            //     return params.data.certificate_link;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.certificate_link !== newValInt;
                if (valueChanged) {
                  params.data.certificate_link = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Video Link",field: 'video_link',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.video_link) {
            //     return params.data.video_link;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.video_link !== newValInt;
                if (valueChanged) {
                  params.data.video_link = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Image Link",field: 'image_link',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.image_link) {
            //     return params.data.image_link;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.image_link !== newValInt;
                if (valueChanged) {
                  params.data.image_link = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "BGM",field: 'bgm',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.bgm) {
            //     return params.data.bgm;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.bgm !== newValInt;
                if (valueChanged) {
                  params.data.bgm = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "EYE Clean",field: 'eye_clean',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.eye_clean) {
            //     return params.data.eye_clean;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.eye_clean !== newValInt;
                if (valueChanged) {
                  params.data.eye_clean = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Milky",field: 'milky',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.milky) {
            //     return params.data.milky;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.milky !== newValInt;
                if (valueChanged) {
                  params.data.milky = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Crown Height",field: 'crown_height',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.crown_height) {
            //     return params.data.crown_height;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.crown_height !== newValInt;
                if (valueChanged) {
                  params.data.crown_height = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Crown Angle",field: 'crown_angle',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.crown_angle) {
            //     return params.data.crown_angle;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.crown_angle !== newValInt;
                if (valueChanged) {
                  params.data.crown_angle = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Pavilion Depth",field: 'pavilion_depth',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.pavilion_depth) {
            //     return params.data.pavilion_depth;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.pavilion_depth !== newValInt;
                if (valueChanged) {
                  params.data.pavilion_depth = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Pavilion Angle",field: 'pavilion_angle',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.pavilion_angle) {
            //     return params.data.pavilion_angle;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.pavilion_angle !== newValInt;
                if (valueChanged) {
                  params.data.pavilion_angle = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Culet Size",field: 'culet_size',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.culet_size) {
            //     return params.data.culet_size;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.culet_size !== newValInt;
                if (valueChanged) {
                  params.data.culet_size = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Girdle Percent",field: 'girdle_percent',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.girdle_percent) {
            //     return params.data.girdle_percent;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.girdle_percent !== newValInt;
                if (valueChanged) {
                  params.data.girdle_percent = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Girdle Name",field: 'girdle_name',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.girdle_name) {
            //     return params.data.girdle_name;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.girdle_name !== newValInt;
                if (valueChanged) {
                  params.data.girdle_name = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Old Date",field: 'old_date',cellEditor: DatePicker,editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.old_date) {
            //     return params.data.old_date;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.old_date !== newValInt;
                if (valueChanged) {
                  params.data.old_date = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Cr Date",field: 'current_date',cellEditor: DatePicker,editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.current_date) {
            //     return params.data.current_date;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.current_date !== newValInt;
                if (valueChanged) {
                  params.data.current_date = newValInt;
                }
                return valueChanged;
            },
          },
          { headerName: "Days",field: 'days',editable: true ,
            // valueGetter: (params) => {
            //   if (params.data.days) {
            //     return params.data.days;
            //   } else {
            //     return 0;
            //   }
            // },
            valueSetter: (params) => {
              var newValInt = params.newValue;

              var valueChanged = params.data.days !== newValInt;
                if (valueChanged) {
                  params.data.days = newValInt;
                }setTimeout(focusScan,50);
                return valueChanged;
            },
          },
     ],
   });


   const defaultColDef = {
     sortable: true,
     filter: true,
     minWidth: 150,
    //  floatingFilter: true,
     flex: 1
   };

   const focusScan = () => {

    document.querySelector('[id="scan_lot_no"]').focus();
   }
    //Edit user
    const scanAndAddPack = () => {

      if(screenData.form_type == 'update') {
        swal("Warning!", "Please Reset The Data!", "warning");
        return;

      }
      let codelength = screenData.scan_lot_no.trim().length;
      if(codelength > 4){
        screenData.scanload = true;
        //Check already in array
        var i = rowData.value.length;
              while(i--){
              if(rowData.value[i]['barcode'] == screenData.scan_lot_no ){
                screenData.scan_lot_no = "";
                swal("Warning!", "Already in list, Scan other packet!", "warning");
                screenData.scanload = false;
                return;
              }
        }

        //get lot details
        const data = {
        barcode: screenData.scan_lot_no,
        };
        let url_post = '';
          if(screenData.scan_lot_no != ''){
          url_post = "api/get_scanned_packet_details_for_lab_update"
        }else{
          url_post = "api/get_scanned_packet_details_for_lab_update"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
            let pack_details = data.lab_details;
            let lab_packet = data.lab_packet;
              //screenData.total_scan_pack = screenData.total_scan_pack + 1;
              console.log('Push data');
              let stone_name = ''

              if(lab_packet.stone_type == 1) {
                stone_name = 'NATURAL';
              } else if(lab_packet.stone_type == 2) {
                stone_name = 'CVD';
              } else if(lab_packet.stone_type == 3) {
                stone_name = 'HPHT';
              } else if(lab_packet.stone_type == 4) {
                stone_name = 'D_CVD';
              } else if(lab_packet.stone_type == 5) {
                stone_name = 'D_HPHT';
              }

              rowData.value.push(
              {
                "sr_no":screenData.total_scan_pack + 1,
                "lot_no":lab_packet.packet_code,
                "lab_no":lab_packet.lab_code,
                "pack_no":lab_packet.pack_code,
                "barcode": pack_details.barcode_no,
                "lab_barcode": lab_packet.lab_barcode,
                "packet_id": lab_packet.packet_id,
                "lab_id": pack_details.lab_id,
                "weight" : lab_packet.cr_weight,
                "cr_wt" : lab_packet.cr_weight,
                "new_wt" : pack_details.final_weight,
                "lab" : pack_details.lab,
                "report_no" : pack_details.report_no,
                "s_no" : pack_details.s_no,
                "shape" : pack_details.shape,
                "color" : pack_details.color,
                "purity" : pack_details.clarity,
                "stone" : stone_name,
                "discount" : pack_details.discount,
                "cut" : pack_details.cut,
                "polish" : pack_details.polish,
                "symmetry" : pack_details.symmetry,
                "fluerocent" : pack_details.fluerocent,
                "measurement" : pack_details.measurement,
                "table" : pack_details.table,
                "t_depth" : pack_details.t_depth,
                "comment" : pack_details.comment,
                "rapo" : pack_details.rapo,
                "rapo_per_ct" : pack_details.rapo_per_ct,
                "total_price" : pack_details.total_price,
                "certificate_link" : pack_details.certificate_link,
                "video_link" : pack_details.video_link,
                "image_link" : pack_details.image_link,
                "bgm" : pack_details.bgm,
                "eye_clean" : pack_details.eye_clean,
                "milky" : pack_details.milky,
                "crown_height" : pack_details.crown_height,
                "crown_angle" : pack_details.crown_angle,
                "pavilion_depth" : pack_details.pavilion_depth,
                "pavilion_angle" : pack_details.pavilion_angle,
                "culet_size" : pack_details.culet_size,
                "girdle_percent" : pack_details.girdle_percent,
                "girdle_name" : pack_details.girdle_name,
                "old_date" : pack_details.old_date,
                "current_date" : pack_details.current_date,
                "days" : pack_details.days,
              });
              //gridApi.value.setRowData([]);

              gridApi.value.setRowData(rowData.value);
              screenData.scan_lot_no = "";
              //screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.cr_weight);

              reCaclulateTotal();
              screenData.scanload = false;
              //console.log(gridApi.value.refreshCells());
              if(rowData.value.length == 1){
                setTimeout(focusLstRow,50);
              }else{
                focusLstRow();
              }
          })
          .catch((_error) => {
            screenData.scanload = false;
            return [];
          });
      }
    }

    const focusLstRow = () => {
      gridApi.value.ensureIndexVisible(rowData.value.length - 1);
      gridApi.value.setFocusedCell(rowData.value.length - 1,'new_wt');
      gridApi.value.startEditingCell(
      {
        rowIndex: rowData.value.length - 1,
        colKey: 'new_wt',
      }
      );
    }

    const inputFileClick = () => {
      document.getElementById("newExcelFile").click()
    }

    const getExcelData = (e) => {
      console.log(e.target.files[0]);
      screenData.lab_file = e.target.files[0];

      e.preventDefault();

      if(screenData.lab_file) {

        screenData.excel_loading = true

        let formData = new FormData();
        formData.append('scanned_data', JSON.stringify(rowData.value));
        formData.append('lab_data', screenData.lab_file);

        let promise = axios({
          url: "api/get_lab_excel_read_data_update",
          method: "POST",
          data: formData,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
          'content-type': 'multipart/form-data'
        });
        return promise
          .then(result => result.data)
          .then(data => {
            if(data.error == "YES") {
              swal('Error!',data.message,"error");
              screenData.excel_loading = false
              return
            }

            let new_items = data.items

            new_items.forEach(element => {
              rowData.value.push(
              {
                "sr_no":screenData.total_scan_pack + 1,
                "lot_no":element.lot_no,
                "lab_no":element.lab_no,
                "pack_no":element.pack_code,
                "barcode": element.barcode,
                "lab_barcode": element.lab_barcode,
                "packet_id": element.packet_id,
                "lab_id": element.lab_id,
                "last_history_id": element.last_history_id,
                "weight" : element.weight,
                "cr_wt" : element.cr_wt,
                "new_wt" : element.new_wt,
                "lab" : element.lab,
                "last_process" : element.last_process,
                "l_p" : element.l_p,
                "report_no" : element.report_no,
                "s_no" : element.s_no,
                "shape" : element.shape,
                "color" : element.color,
                "purity" : element.purity,
                "discount" : element.discount,
                "cut" : element.cut,
                "polish" : element.polish,
                "symmetry" : element.symmetry,
                "fluerocent" : element.fluerocent,
                "measurement" : element.measurement,
                "stone" : element.stone,
                "table" : element.table,
                "t_depth" : element.t_depth,
                "comment" : element.comment,
                "rapo" : element.rapo,
                "rapo_per_ct" : element.rapo_per_ct,
                "total_price" : element.total_price,
                "certificate_link" : element.certificate_link,
                "video_link" : element.video_link,
                "image_link" : element.image_link,
                "bgm" : element.bgm,
                "eye_clean" : element.eye_clean,
                "milky" : element.milky,
                "crown_height" : element.crown_height,
                "crown_angle" : element.crown_angle,
                "pavilion_depth" : element.pavilion_depth,
                "pavilion_angle" : element.pavilion_angle,
                "culet_size" : element.culet_size,
                "girdle_percent" : element.girdle_percent,
                "girdle_name" : element.girdle_name,
                "old_date" : element.old_date,
                "current_date" : element.current_date,
                "days" : element.days,
              });

              reCaclulateTotal();
            });
            gridApi.value.setRowData(rowData.value);
            if(data.message != '') {

              swal('Warning!',data.message,"warning");
            }

            screenData.lab_file = ''
            document.querySelector('[id="newExcelFile"]').value = '';
            screenData.excel_loading = false
            focusScan()

          })
          .catch(_error => {

        screenData.excel_loading = false
          });
      }

    }


    const reCaclulateTotal = () => {
      var j = rowData.value.length;
      var total_pc = 0;
      var total_ct = 0;
      var sr = 1;
        while(j--){
          total_pc++;
          total_ct = total_ct + parseFloat(rowData.value[j]['cr_wt']);
          rowData.value[j]['sr_no'] = j+1;
          sr++;
        }

        screenData.total_scan_pack_wt = total_ct;
        screenData.total_scan_pack = total_pc;

    }

    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if(gridApi.value.getSelectedRows().length == 0){
        screenData.delete_row = true;
      }else{
        screenData.delete_row = false;
      }
    }

    const removeGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows());
      const sel = gridApi.value.getSelectedRows();
      console.log(sel);

      let remove_rows = [];
      var i = sel.length;
      while(i--){
        remove_rows.push(sel[i]['barcode']);
      }
      gridApi.value.applyTransaction({remove: sel});
      console.log('Removeing row');
      console.log(remove_rows);
      var j = rowData.value.length;
        while(j--){
              if(remove_rows.includes(rowData.value[j]['barcode'])){
                rowData.value.splice(j,1);
              }
        }

        if(rowData.value.length == 0) {
          screenData.form_type == 'create'
        }

      setTimeout(reCaclulateTotal,50);
    }

    const takeMultiReturn = () => {
      gridApi.value.stopEditing();
      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for update, Packet count is zero", "error");
        return;
      }

      let total_pcs = rowData.value.length;
      let i = 0;
      while(i < total_pcs){
        console.log(rowData.value[i].cr_wt);
        if(rowData.value[i].cr_wt == '' || rowData.value[i].cr_wt == undefined){
          swal("Error!", "Kindly Enter Update Weight to all packet and retry!", "error");
          return;
        }
        i++;
      }

      let api_url = "api/return_lab_packets_update"
      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to take update entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const data = {
            packet_details: rowData.value,
          };

          let promise = axios({
            url: api_url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if(data.status == "success"){
                if(data.type == "success") {

                  swal.fire({
                    icon:'success',
                    title:"Success!",
                    text:data.message,
                    showConfirmButton: false,
                    timer:1500
                  })
                  // swal("Success!", data.message, "success");
                } else {
                  swal("Warning!", data.message, "warning");
                  return;
                }
                document.querySelector('[id="scan_lot_no"]').focus();


                let new_items = data.items

                rowData.value = []

                new_items.forEach(element => {
                  rowData.value.push(
                  {
                    "sr_no":screenData.total_scan_pack + 1,
                    "lot_no":element.lot_no,
                    "lab_no":element.lab_no,
                    "pack_no":element.pack_code,
                    "barcode": element.barcode,
                    "lab_barcode": element.lab_barcode,
                    "packet_id": element.packet_id,
                    "lab_id": element.lab_id,
                    "last_history_id": element.last_history_id,
                    "weight" : element.weight,
                    "cr_wt" : element.cr_wt,
                    "new_wt" : element.new_wt,
                    "lab" : element.lab,
                    "last_process" : element.last_process,
                    "l_p" : element.l_p,
                    "report_no" : element.report_no,
                    "s_no" : element.s_no,
                    "shape" : element.shape,
                    "color" : element.color,
                    "purity" : element.purity,
                    "discount" : element.discount,
                    "cut" : element.cut,
                    "stone" : element.stone,
                    "polish" : element.polish,
                    "symmetry" : element.symmetry,
                    "fluerocent" : element.fluerocent,
                    "measurement" : element.measurement,
                    "table" : element.table,
                    "t_depth" : element.t_depth,
                    "comment" : element.comment,
                    "rapo" : element.rapo,
                    "rapo_per_ct" : element.rapo_per_ct,
                    "total_price" : element.total_price,
                    "certificate_link" : element.certificate_link,
                    "video_link" : element.video_link,
                    "image_link" : element.image_link,
                    "bgm" : element.bgm,
                    "eye_clean" : element.eye_clean,
                    "milky" : element.milky,
                    "crown_height" : element.crown_height,
                    "crown_angle" : element.crown_angle,
                    "pavilion_depth" : element.pavilion_depth,
                    "pavilion_angle" : element.pavilion_angle,
                    "culet_size" : element.culet_size,
                    "girdle_percent" : element.girdle_percent,
                    "girdle_name" : element.girdle_name,
                    "old_date" : element.old_date,
                    "current_date" : element.current_date,
                    "days" : element.days,
                  });

                  reCaclulateTotal();
                });

                screenData.form_type = 'update'

                gridApi.value.setRowData(rowData.value);

              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;
        }
      });
    }

    const resetData = () => {
      screenData.form_type = 'create'
      rowData.value = []
      gridApi.value.setRowData(rowData.value);
      screenData.total_scan_pack_wt = 0;
      screenData.total_scan_pack = 0;
    }

    onMounted(() => {
      rowData.value = []
      focusScan()

    })


    return {
      filter,
      screenData,
      defaultColDef,
      rowData,
      columnDefs,
      overlayNoRowsTemplate,
      inputFileClick,
      scanAndAddPack,
      onGridReady,
      resetData,
      takeMultiReturn,
      getRowId,
      focusScan,
      onGridRowSelect,
      removeGridRowSelect,
      getExcelData
    }

  }
})
</script>

