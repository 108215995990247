<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Planner Summary Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div v-if="userDetails.user_type == 1 || userDetails.user_type == 9" class="col-span-6 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Planner Type</label
            >
            <TomSelect
              v-model="screenData.planner_type"
              :options="{
                placeholder: 'Select Planner Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Type'
                  }
                }
              }"
              @change="statusChange"
              class="w-full"
            >
            <option value="0">All</option>
            <option value="10">Planner</option>
            <option value="11">Checker</option>
            <option value="12">Main Checker</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Series</label
            >
            <TomSelect
              v-model="screenData.series_id"
              :options="{
                placeholder: 'Select Series',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              @change="seriesChange"
              class="w-full"
            >
            <option value="">Select Series</option>
            <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Kapan</label
            >
            <TomSelect
              v-model="screenData.kapan_id"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan'
                  }
                }
              }"
              @change="kapanChange"
              class="w-full"
            >
            <option value="">Select Kapan</option>
            <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Packets</label
            >
            <TomSelect
              v-model="screenData.packets"
              :options="{
                placeholder: 'Select Packet',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Packet'
                  }
                }
              }"
              class="w-full"
              multiple
            >
            <option value="">Select Packet</option>
            <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-3" v-if="screenData.stone_id == 2">
            <label for="update-profile-form-6" class="form-label"
              >Report Type</label
            >
            <TomSelect
              v-model="screenData.report_type"
              :options="{
                placeholder: 'Report Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Report Type'
                  }
                }
              }"
              class="w-full"
            >
            <option value="1">Kapan Wise</option>
            <option value="2">Size Wise</option>
            </TomSelect>
          </div>
          <div class="mt-3" v-else>
            <label for="update-profile-form-6" class="form-label"
              >Report Type</label
            >
            <TomSelect
              v-model="screenData.report_type"
              :options="{
                placeholder: 'Report Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Report Type'
                  }
                }
              }"
              disabled
              class="w-full"
            >
            <option value="1">Kapan Wise</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-3" v-if="screenData.issue_pending == true">
            <label for="update-profile-form-6" class="form-label"
              >Status Type</label
            >
            <TomSelect
              v-model="screenData.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }"
              disabled
              class="w-full"
            >
            <!-- <option value="0">All</option> -->
            <option value="1">Issue</option>
            <option value="2">Received</option>
            </TomSelect>
          </div>
          <div class="mt-3" v-else>
            <label for="update-profile-form-6" class="form-label"
              >Status Type</label
            >
            <TomSelect
              v-model="screenData.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }"
              class="w-full"
            >
            <!-- <option value="0">All</option> -->
            <option value="1">Issue</option>
            <option value="2">Received</option>
            </TomSelect>
          </div>
        </div>
        <div v-if="userDetails.user_type == 1 || userDetails.user_type == 9" class="col-span-6 md:col-span-3">
          <div class="mt-3">
            <label for="update-profile-form-9" class="form-label"
              >Select Planner</label
            >
            <TomSelect
              v-model="screenData.planner_id"
              :options="{
                placeholder: 'Select Planner',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Planner, Checker Main Checker'
                  }
                }
              }"
              class="w-full"
            >
            <option value="">Select Planner</option>
            <option v-for="planner in pagedata.planners" :key="planner.id" :value="planner.id">{{planner.first_name}} {{planner.last_name}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
          <div class="mt-3">
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.issue_date"
              :disabled="screenData.issue_pending"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
          <div  class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
              :disabled="screenData.issue_pending"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Stone</label
            >
            <TomSelect
              v-model="screenData.stone_id"
              :options="{
                placeholder: 'Select Stone',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stone'
                  }
                }
              }"
              @change="getStoneChange"
              class="w-full"
            >
            <option value="0">Select Stone</option>
            <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-3 md:col-span-3 mt-8">
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-16 mt-2 mr-2 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-16 mt-2 mt-2 sm:ml-1"
            @click="getDetailsAll"
          >
            Generate
          </button>
          <!-- <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-16 mt-2 sm:ml-1"
            @click="printReportDetails"
          >
            Print
          </button> -->
          <button v-if="pagedata.generate == 'gen'"
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-16 mt-2 sm:ml-1"
            v-print="'#report-details'"
          >
            Print
          </button>
        </div>
      </div>

      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div id="report-details" class="scrollbar-hidden" v-if="pagedata.generate == 'gen'">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Planner</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapan</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="3" v-if="pagedata.status == 1"><div v-if="pagedata.issue_pending == false">Issue</div><div v-else>Pndding</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="10" v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Received</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="3" v-if="pagedata.status == 0">Total</th> -->
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="pagedata.status == 1"><div>Pcs</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 1"><div>Org. Wt</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="pagedata.status == 1"><div>Issue Wt</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Pcs</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Org. Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Issue Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Makeable Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Polish Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Plan Rate ($)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Plan Per Ct ($)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Penalty</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">Total Majuri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 1"><div>{{ pagedata.report_details_total.total_issue_packets ? pagedata.report_details_total.total_issue_packets : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 1"><div>{{ pagedata.report_details_total.total_issue_oriznal_weight ? $h.myFloat(pagedata.report_details_total.total_issue_oriznal_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 1"><div>{{ pagedata.report_details_total.total_issue_weight ? $h.myFloat(pagedata.report_details_total.total_issue_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_received_packets ? pagedata.report_details_total.total_received_packets : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_received_oriznal_weight ? $h.myFloat(pagedata.report_details_total.total_received_oriznal_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_received_weight ? $h.myFloat(pagedata.report_details_total.total_received_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_rough_weight ? $h.myFloat(pagedata.report_details_total.total_rough_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_polish_weight ? $h.myFloat(pagedata.report_details_total.total_polish_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_planning_value ? $h.myFloat(pagedata.report_details_total.total_planning_value) : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_planning_value ? $h.myFloat(pagedata.report_details_total.total_planning_value / pagedata.report_details_total.total_polish_weight) : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_planning_majuri ? $h.myFloat(pagedata.report_details_total.total_planning_majuri) : '0' }} </div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_penalty ? $h.myFloat(pagedata.report_details_total.total_penalty) : '0' }} </div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false"><div>{{ pagedata.report_details_total.total_planning_majuri ? $h.myFloat(pagedata.report_details_total.total_planning_majuri - pagedata.report_details_total.total_penalty) : '0' }} </div></td>
                    </tr>
                  </tbody>
                  <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                    <tr class="border-b" v-for="(single_item,ind) in item.packet_details" :key="ind">
                      <td v-if="ind == 0" class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="ind == 0 ? item.packet_details.length : 1">{{ ind == 0 ? index + 1 : '' }}</td>
                      <td v-if="ind == 0" class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="ind == 0 ? item.packet_details.length : 1">{{ ind == 0 ? (item.first_name + ' ' + item.last_name) : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.kapan_code ? single_item.kapan_code : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 1">{{ single_item.issue_packets ? single_item.issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 1">{{ single_item.issue_oriznal_weight ? $h.myFloat(single_item.issue_oriznal_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 1">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.received_packets ? single_item.received_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.received_oriznal_weight ? $h.myFloat(single_item.received_oriznal_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.received_weight ? $h.myFloat(single_item.received_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.rough_weight ? $h.myFloat(single_item.rough_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.planning_rate ? $h.myFloat(single_item.planning_rate) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.planning_rate ? $h.myFloat(single_item.planning_rate / single_item.polish_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ $h.myFloat(single_item.planning_majuri) }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ single_item.penalty ? $h.myFloat(single_item.penalty) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{  $h.myFloat(single_item.planning_majuri - single_item.penalty) }}</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="item.packet_details.length > 0">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 1">{{ item.issue_packets ? item.issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 1">{{ item.issue_oriznal_weight ? $h.myFloat(item.issue_oriznal_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 1">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.received_packets ? item.received_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.received_oriznal_weight ? $h.myFloat(item.received_oriznal_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.received_weight ? $h.myFloat(item.received_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.rough_weight ? $h.myFloat(item.rough_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.polish_weight ? $h.myFloat(item.polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.planning_value ? $h.myFloat(item.planning_value) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.planning_value ? $h.myFloat(item.planning_value / item.polish_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.planning_majuri ? $h.myFloat(item.planning_majuri) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.penalty ? $h.myFloat(item.penalty) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"  v-if="(pagedata.status == 2) && pagedata.issue_pending == false">{{ item.planning_majuri ? $h.myFloat((item.planning_majuri) - item.penalty) : '0' }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Closing Modal -->
  <div id="packet-plan-details-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-3 px-3">
            <div class="overflow-x-auto scrollbar-hidden">
              <div class="grid grid-cols-12 gap-4 mt-5">
                <div class="col-span-12 sm:col-span-6 lg:col-span-4 intro-y mt-2">
                    <div class="report-box zoom-in">
                      <div class="box p-2">
                        <div class="flex items-center justify-between">
                          <div class="text-lg font-medium leading-8">Total</div>
                          <div>
                            <div class="text-lg font-medium leading-8">{{ pagedata.planner_total.total_packets ? pagedata.planner_total.total_packets : 0 }} / {{ pagedata.planner_total.total_packets_weight ? $h.myFloat(pagedata.planner_total.total_packets_weight) + ' ct' : '0.00 ct' }}</div>
                            <div class="text-base text-gray-600 mt-1"></div>
                          </div>
                        </div>
                        <div class="flex items-center justify-between">
                          <div class="text-lg==md font-medium leading-8">Issue Weight</div>
                          <div>
                            <div class="text-base text-gray-600 mt-1">{{ pagedata.planner_total.issue_weight ? $h.myFloat(pagedata.planner_total.issue_weight) + ' ct' : '0.00 ct' }}</div>
                            <!-- <div class="text-base text-gray-600 mt-1"></div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-4 intro-y">
                    <div class="report-box zoom-in">
                      <div class="box p-2">
                        <div class="flex items-center justify-between">
                          <div>
                            <div class="font-medium leading-8">Est. MW</div>
                            <div class="font-medium leading-8">Est. PW</div>
                          </div>
                          <div>
                            <div class="font-medium leading-8">{{ pagedata.planner_total.total_packets_rough_weight ? $h.myFloat(pagedata.planner_total.total_packets_rough_weight) + ' ct' : '0.00 ct' }}</div>
                            <div class="font-medium leading-8">{{ pagedata.planner_total.total_packets_polish_weight ? $h.myFloat(pagedata.planner_total.total_packets_polish_weight) + ' ct' : '0.00 ct' }} </div>
                            <div class="text-base text-gray-600 mt-1">{{ pagedata.planner_total.total_packets_rough_weight ? parseFloat(pagedata.planner_total.total_packets_rough_weight * 100 / pagedata.planner_total.total_packets_weight).toFixed(2) + ' %' : '0 %' }} / {{ pagedata.planner_total.total_packets_polish_weight ? parseFloat(pagedata.planner_total.total_packets_polish_weight * 100 / pagedata.planner_total.total_packets_weight).toFixed(2) + ' %' : '0 %' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-4 intro-y mt-2">
                    <div class="report-box zoom-in">
                      <div class="box p-2 pb-8">
                        <div class="flex items-center justify-between">
                          <div>
                            <div class="font-medium leading-8">Majuri</div>
                            <div class="text-base text-gray-600 mt-1">Penalty</div>
                          </div>
                          <div>
                            <div class="font-medium leading-8">{{ pagedata.planner_total.planning_majuri ? $h.myFloat(pagedata.planner_total.planning_majuri) : '-' }}</div>
                            <div class="font-medium leading-8">{{ pagedata.planner_total.total_packets_panalty ? $h.myFloat(pagedata.planner_total.total_packets_panalty) : '0.00' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div
                id="tabulatorsingle"
                ref="tableRefsingle"
                class="mt-5"
              ></div>
            </div>

          </div>
        </div>
    </div>
</div>
  <!-- END: Closing Modal -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import axios from 'axios'
import cash from 'cash-dom'
import moment from 'moment'
import {helper as $h} from '@/utils/helper'
export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const tableRefplanner = ref()
    const tabulatorplanner = ref()
    const tableRefsingle = ref()
    const tabulatorsingle = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      udi: window.btoa(userDetails.value.id),
      udr: window.btoa(userDetails.value.user_type),
      total: [],
      planner_total: [],
      serieses: [],
      kapans: [],
      packets: [],
      planners: [],
      stones: [],
      series_id:'',
      packetsarr:[],
      kapan_id:'',
      planner_type:'0',
      planner_id : '',
      stone_id: '0',
      report_type: '1',
      status: '1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date: moment().format('YYYY-MM-DD'),
      loading: false,
      issue_pending: true,
      report_details: [],
      report_details_total: [],
      generate: 'no'
    })
    const screenData = reactive({
      series_id:'',
      status:'1',
      packets:[],
      kapan_id:'',
      planner_type:'0',
      planner_id : '',
      stone_id: '0',
      report_type: '1',
      issue_pending: true,
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date: moment().format('YYYY-MM-DD')
    })

    const getSeriesDetails = () => {
      let params = {
        planner_id : window.btoa(screenData.planner_id),
        planner_type : screenData.planner_type == '0' ? '' : window.btoa(screenData.planner_type)
      }
      let promise = axios({
        url: "api/get_planning_packet_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }
    const getKapanDetails = () => {
      let params = {
        planner_id : window.btoa(screenData.planner_id),
        series_id : window.btoa(screenData.series_id),
        planner_type : screenData.planner_type == '0' ? '' : window.btoa(screenData.planner_type)
      }
      let promise = axios({
        url: "api/get_planning_packet_kapan",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getPacketDetails = () => {
      let params = {
        planner_id : window.btoa(screenData.planner_id),
        series_id : window.btoa(screenData.series_id),
        kapan_id : window.btoa(screenData.kapan_id),
        planner_type : screenData.planner_type == '0' ? '' : window.btoa(screenData.planner_type)
      }
      let promise = axios({
        url: "api/get_planning_packet_packets",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.packets = data.items

        })
        .catch(_error => {

        });
    }
    const getPlannerDetails = () => {
      let params = {
        planner_id : window.btoa(screenData.planner_id),
        series_id : window.btoa(screenData.series_id),
        kapan_id : window.btoa(screenData.kapan_id),
        planner_type : screenData.planner_type == '0' ? '' : window.btoa(screenData.planner_type)
      }
      let promise = axios({
        url: "api/get_planning_packet_planner",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.planners = data.items

        })
        .catch(_error => {

        });
    }

    const initTabulator = () => {
      tabulatorplanner.value = new Tabulator(tableRefplanner.value, {
        ajaxURL: BASE_URL + '/api/get_planning_done_user_report',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          planner_id: screenData.planner_id,
          stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
          series_id: screenData.series_id,
          report_type: screenData.report_type,
          kapan_id: screenData.kapan_id,
          packets: screenData.packets,
          planner_type: screenData.planner_type == '0' ? '' : screenData.planner_type,
          s_date: screenData.issue_date,
          e_date: screenData.receive_date,
          status: screenData.status
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 30,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Planner Name',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth: 250,
            field: 'planner_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().first_name + ' ' + cell.getData().last_name + ' (' + cell.getData().emp_code + ' ) '
                }
                </div>

              </div>`
            },
            cellClick:function(e, cell){
              initTabulatorPlannerSingle(cell.getData().id, cell.getData().process)
              gettotalDataPlanner(cell.getData().id)
              cash('#packet-plan-details-modal').modal('show')
            }
          },
          {
            title: 'Packets',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:50,
            field: 'issue_packets',
             hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().issue_packets ? cell.getData().issue_packets : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Oriznal Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight ? parseFloat(cell.getData().weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Issue Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'issue_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().issue_weight ? parseFloat(cell.getData().issue_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },

          {
            title: 'Est. MK Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'rough_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().rough_weight ? parseFloat(cell.getData().rough_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },

          {
            title: 'Est. Polish Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'polish_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().polish_weight ? parseFloat(cell.getData().polish_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Plan Rate',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'planning_rate',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_rate ? parseFloat(cell.getData().planning_rate).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Plan Per Carat',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'planning_per_carat',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_rate ? parseFloat(cell.getData().planning_rate/cell.getData().polish_weight).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Majuri',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'majuri',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_majuri ? $h.myFloat(cell.getData().planning_majuri) : '-'
                  // cell.getData().process == 10 ? parseFloat(cell.getData().issue_weight * 200).toFixed(4) : (cell.getData().process == 11 ? parseFloat(cell.getData().issue_weight * 40).toFixed(4) : parseFloat(cell.getData().issue_weight * 20).toFixed(4))
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Penalty',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:50,
            field: 'penalty',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().penalty ? parseFloat(cell.getData().penalty).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Total',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:50,
            field: 'total',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process == 10 ? parseFloat((cell.getData().issue_weight * 200) - cell.getData().penalty).toFixed(4) : (cell.getData().process == 11 ? parseFloat((cell.getData().issue_weight * 40) - cell.getData().penalty).toFixed(4) : parseFloat((cell.getData().issue_weight * 20) - cell.getData().penalty).toFixed(4))
                }
                </div>

              </div>`
            },
          },




          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const gettotalData = () => {
      const params = {
        planner_id: screenData.planner_id,
        stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
        series_id: screenData.series_id,
        report_type: screenData.report_type,
        kapan_id: screenData.kapan_id,
        planner_type: screenData.planner_type == '0' ? '' : screenData.planner_type,
        packets: screenData.packets,
        s_data: screenData.issue_date,
        e_date: screenData.receive_date,
        size: 20,
      };
      let promise = axios({
        url: "api/get_planning_done_user_report_total",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.total = data.items

        })
        .catch(_error => {

        });
    }
    const initTabulatorPlanner = (planner_id,planner_role) => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/get_packet_planner_rates',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          udi: pagedata.udi,
          udr: pagedata.udr,
          planner_id: planner_id,
          stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
          report_type: screenData.report_type,
          series_id: screenData.series_id,
          kapan_id: screenData.kapan_id,
          packets: screenData.packets,
          s_date: screenData.issue_date,
          e_date: screenData.receive_date
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Packet Code',

            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth: 100,
            field: 'packet_code',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Pieces',

            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth: 50,
            field: 'pieces',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'Oriznal Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight ? parseFloat(cell.getData().weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Issue Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'issue_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().issue_weight ? parseFloat(cell.getData().issue_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },

          {
            title: 'Est. MK Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'rough_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().rough_weight ? parseFloat(cell.getData().rough_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },

          {
            title: 'Est. Polish Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'polish_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().polish_weight ? parseFloat(cell.getData().polish_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Planning Rate',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'planning_rate',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_rate ? parseFloat(cell.getData().planning_rate).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Majuri',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'majuri',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_majuri ? $h.myFloat(cell.getData().planning_majuri) : '-'
                  // planner_role == 10 ? parseFloat(cell.getData().issue_weight * 200).toFixed(4) : planner_role == 11 ?  parseFloat(cell.getData().issue_weight * 40).toFixed(4) : planner_role == 12 ?  parseFloat(cell.getData().issue_weight * 20).toFixed(4) :'-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Penalty',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'penalty',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().penalty ? parseFloat(cell.getData().penalty).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Total',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:50,
            field: 'total',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process == 10 ? parseFloat((cell.getData().issue_weight * 200) - cell.getData().penalty).toFixed(4) : (cell.getData().process == 11 ? parseFloat((cell.getData().issue_weight * 40) - cell.getData().penalty).toFixed(4) : parseFloat((cell.getData().issue_weight * 20) - cell.getData().penalty).toFixed(4))
                }
                </div>

              </div>`
            },
          },




          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const initTabulatorPlannerSingle = (planner_id,planner_role) => {
      tabulatorsingle.value = new Tabulator(tableRefsingle.value, {
        ajaxURL: BASE_URL + '/api/get_packet_planner_rates',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          udi: pagedata.udi,
          udr: pagedata.udr,
          planner_id: planner_id,
          stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
          series_id: screenData.series_id,
          report_type: screenData.report_type,
          kapan_id: screenData.kapan_id,
          packets: screenData.packets,
          s_date: screenData.issue_date,
          e_date: screenData.receive_date
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Packet Code',

            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth: 100,
            field: 'packet_code',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Pieces',

            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth: 50,
            field: 'pieces',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'Oriznal Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight ? parseFloat(cell.getData().weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Issue Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'issue_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().issue_weight ? parseFloat(cell.getData().issue_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },

          {
            title: 'Est. MK Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'rough_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().rough_weight ? parseFloat(cell.getData().rough_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },

          {
            title: 'Est. Polish Weight',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'polish_weight',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().polish_weight ? parseFloat(cell.getData().polish_weight).toFixed(4) + ' Ct' : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Planning Rate',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'planning_rate',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_rate ? parseFloat(cell.getData().planning_rate).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Majuri',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'majuri',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().planning_majuri ? $h.myFloat(cell.getData().planning_majuri) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Penalty',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:100,
            field: 'penalty',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().penalty ? parseFloat(cell.getData().penalty).toFixed(4) : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Total',
            // columnMinWidth:"10%",
            // columnMaxWidth:"10%",
            minWidth:50,
            field: 'total',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process == 10 ? parseFloat((cell.getData().issue_weight * 200) - cell.getData().penalty).toFixed(4) : (cell.getData().process == 11 ? parseFloat((cell.getData().issue_weight * 40) - cell.getData().penalty).toFixed(4) : parseFloat((cell.getData().issue_weight * 20) - cell.getData().penalty).toFixed(4))
                }
                </div>

              </div>`
            },
          },




          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const gettotalDataPlanner = (planner_id) => {
      const params = {
        udi: pagedata.udi,
        udr: pagedata.udr,
        series_id: screenData.series_id,
        kapan_id: screenData.kapan_id,
        planner_id: planner_id,
        stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
        packets: screenData.packets,
        report_type: screenData.report_type,
        s_data: screenData.issue_date,
        e_date: screenData.receive_date,
      };
      let promise = axios({
        url: "api/get_packet_planner_rates_total",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.planner_total = data.items

        })
        .catch(_error => {

        });
    }
    function getDetails(planner_id,planner_role) {
      initTabulatorPlanner(planner_id,planner_role)
      gettotalDataPlanner(planner_id)
    }
    function getAdminDetails() {
      initTabulator()
        gettotalData()
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.planner_id = ''
      screenData.stone_id = '0'
      screenData.report_type = '1'
      screenData.series_id = ''
      screenData.kapan_id = ''
      screenData.packets = []
      screenData.status = '1'
      screenData.planner_type = '0'
      pagedata.generate = 'no'
      // pagedata.issue_pending = true
      screenData.issue_pending = true
      // screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      // screenData.receive_date = moment().format('YYYY-MM-DD')
      // if(userDetails.value.user_type == 1 || userDetails.value.user_type == 9) {
      //   getAdminDetails()
      // } else {
      //   getDetails(userDetails.value.more_details.id,userDetails.value.user_type)
      // }

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      checkPermission()
      getSeriesDetails()
      getDPStoneType()
      getKapanDetails()
      getPlannerDetails()
      getPacketDetails()
      reInitOnResizeWindow()
      // getDetailsAll()
    })
    function getDetailsAll() {
      pagedata.series_id = screenData.series_id
      pagedata.packetsarr = screenData.packets
      pagedata.kapan_id = screenData.kapan_id
      pagedata.planner_type = screenData.planner_type
      pagedata.planner_id = screenData.planner_id
      pagedata.stone_id = screenData.stone_id
      pagedata.report_type = screenData.report_type
      pagedata.issue_date = screenData.issue_date
      pagedata.issue_pending = screenData.issue_pending
      pagedata.receive_date = screenData.receive_date
      pagedata.status = screenData.status

      pagedata.loading  = true


      let params = {
        planner_id: window.btoa(screenData.planner_id),
        stone_id: screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
        report_type: window.btoa(screenData.report_type),
        series_id: window.btoa(screenData.series_id),
        kapan_id: window.btoa(screenData.kapan_id),
        issue_pending: screenData.issue_pending,
        packets: screenData.packets,
        planner_type: screenData.planner_type == '0' ? '' : window.btoa(screenData.planner_type),
        s_date: window.btoa(screenData.issue_date),
        e_date: window.btoa(screenData.receive_date),
        status: window.btoa(screenData.status),
      }
        let promise = axios({
          url: "api/get_kapan_wise_planning_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            pagedata.report_details = data.items
            pagedata.report_details_total = data.total
            pagedata.loading  = false
            pagedata.generate = 'gen'
          })
          .catch(_error => {
            pagedata.loading  = false
          });
    }

    function seriesChange() {
      getKapanDetails()
      getPacketDetails()
      if(screenData.series_id) {
        if(screenData.kapan_id) {
          screenData.kapan_id = ''
        }
        if(screenData.packets.length > 0) {
          screenData.packets = []
        }
      }
    }
    // function printReportDetails(){
    //   let url = BASE_URL + '/planner-rate-report?series_id=' + pagedata.series_id + '&kapan_id=' + pagedata.kapan_id +'&packets=' + pagedata.packetsarr
    //          + '&planner_type=' + pagedata.planner_type + '&planner_id=' + pagedata.planner_id + '&stone_id=' + (pagedata.stone_id == '0' ? '' :pagedata.stone_id)+ '&issue_date=' +
    //          pagedata.issue_date+ '&receive_date=' + pagedata.receive_date + '&udi=' + pagedata.udi + '&udr=' + pagedata.udr
    //   window.open(url, '_blank');
    // }
    function kapanChange() {
      getPacketDetails()
      if(screenData.kapan_id) {
        if(screenData.packets.length > 0) {
          screenData.packets = []
        }
      }
    }
    function getStoneChange() {
      if(screenData.stone_id == 1 || screenData.stone_id == 0) {
        screenData.report_type = '1'
      }
    }
    function statusChange() {
      screenData.series_id = ''
      screenData.kapan_id = ''
      screenData.planner_id = ''
      screenData.packets = []
      pagedata.serieses = []
      pagedata.kapans = []
      pagedata.packets = []
      pagedata.planners = []
      getPlannerDetails()
      getSeriesDetails()
      getKapanDetails()

      getPacketDetails()
    }
    return {
      tableRef,
      tableRefplanner,
      tableRefsingle,
      pagedata,
      screenData,
      userDetails,
      getDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      getStoneChange,
      // printReportDetails,
      seriesChange,kapanChange,getDetailsAll,statusChange
    }
  }
})
</script>
