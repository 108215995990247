<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Out Process Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Process</label>
                    <TomSelect v-model="screenData.process_id" :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full" @change="processChange">
                        <option value="0">Select Process</option>
                        <option v-for="series in pagedata.processes" :key="series.id" :value="series.id">{{series.process_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Series</label>
                    <TomSelect v-model="screenData.series_id" :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }" multiple class="w-full" @change="seriesChange">
                        <!-- <option value="0">Select Series</option> -->
                        <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="screenData.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full" multiple @change="kapanChange">
                        <!-- <option value="0">Select Kapan</option> -->
                        <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Packets</label>
                    <TomSelect v-model="screenData.packets" :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }" class="w-full" multiple>
                        <!-- <option value="">Select Packets</option> -->
                        <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div v-if="screenData.report_type == '3' && screenData.issue_pending == true">
                    <label for="update-profile-form-6" class="form-label py-1">Status Type</label>
                    <TomSelect v-model="screenData.status" :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }" class="w-full" :disabled="screenData.report_type == '3' && screenData.issue_pending">
                        <option value="0">All</option>
                        <option value="1"><span v-if="screenData.issue_pending == true">Pending</span><span v-else>Issue</span></option>
                        <option value="2">Received</option>
                    </TomSelect>
                </div>
                <div v-else>
                    <label for="update-profile-form-6" class="form-label py-1">Status Type</label>
                    <TomSelect v-model="screenData.status" :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option value="1"><span v-if="screenData.issue_pending == true">Pending</span><span v-else>Issue</span></option>
                        <option value="2">Received</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Report Type</label>
                    <TomSelect v-model="screenData.report_type" :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }" class="w-full">
                        <option value="1">Kapan Wise</option>
                        <option value="2" v-if="pagedata.size_wise == true">Size Wise</option>
                        <option value="3">Lot Wise</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Party</label>
                    <TomSelect v-model="screenData.party_id" :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Party</option>
                        <option v-for="party in pagedata.parties" :key="party.id" :value="party.id">{{party.company_name ? party.company_name : party.first_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1">Select Start Date</label><input class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="screenData.issue_pending" /></div>
                    <input type="date" id="update-profile-form-13" :disabled="screenData.issue_pending" class="form-control" placeholder="" v-model="screenData.issue_date" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="screenData.issue_pending" v-model="screenData.receive_date" />
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Shape</label>
                    <TomSelect v-model="screenData.shape_group" :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option value="FANCY">FANCY</option>
                        <option v-for="shape in pagedata.shapes" :key="shape.id" :value="shape.id">{{shape.shape_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Color</label>
                    <TomSelect v-model="screenData.color_id" :options="{
                  placeholder: 'Select Color',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Color'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option v-for="color in pagedata.colors" :key="color.id" :value="color.id">{{color.shade_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Purity</label>
                    <TomSelect v-model="screenData.purity_id" :options="{
                  placeholder: 'Select Purity',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Purity'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option v-for="purity in pagedata.purities" :key="purity.id" :value="purity.id">{{purity.purity_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Stone</label>
                    <TomSelect v-model="screenData.stone_id" :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Stone</option>
                        <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Enter Size</label>
                    <input id="tabulator-html-filter-value" v-model="screenData.size_weight" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="0.001,0.002,..." />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="screenData.issue_pending == true && (((screenData.report_type == '1' || screenData.report_type == '2') && screenData.status == '1') || screenData.report_type == '3')">
                <div class="flex items-center mt-3">
                    <label for="update-profile-form-6" class="form-label">Show Day Wise</label>
                    <input v-if="screenData.report_type == '1' || screenData.report_type == '2'" class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="screenData.day_wise" />
                </div>
                <input v-if="((screenData.day_wise == true && (screenData.report_type == '1' || screenData.report_type == '2') && screenData.status == '1') || screenData.report_type == '3') && screenData.issue_pending == true" id="tabulator-html-filter-value" v-model="screenData.days" type="number" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="Days" />
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="((screenData.day_wise == true && (screenData.report_type == '1' || screenData.report_type == '2') && screenData.status == '1') || screenData.report_type == '3') && screenData.issue_pending == true">
                <div>
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1">Due</label><input class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="screenData.due" @change="dueChanged" />
                        <label for="update-profile-form-6" class="form-label py-1 ml-3">Over Due</label><input class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="screenData.over_due" @change="overDueChanged" /></div>
                    <div v-if="screenData.due">
                        <input type="text" id="update-profile-form-13" class="form-control" placeholder="Due Days" v-model="screenData.due_days" />
                    </div>
                    <div v-if="screenData.over_due">
                        <input type="text" id="update-profile-form-13" class="form-control" placeholder="Over Due Days" v-model="screenData.over_due_days" />
                    </div>
                </div>

            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" :disabled="(screenData.process_id == '0' || ((screenData.report_type == '1' || screenData.report_type == '2') && screenData.issue_pending == true && (screenData.series_id.length == 0 && screenData.kapan_id.length == 0) && (screenData.status == '0' || screenData.status == '2')))" class="btn btn-primary w-full ml-2" tabindex="5" @click="generateReport()">
                        Generate
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type != 3">
          <div class="mt-7 flex pt-3">

            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button> -->
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type">
          <div class="mt-7 flex pt-3">

            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button> -->
            <div class="flex mt-5 sm:mt-0">

              <div class="dropdown w-1/2 sm:w-auto">
                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                          </a>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        </div>
        <div id="generate-pdf">
            <div v-if="pagedata.report_type == 3">
                <div class="overflow-x-auto scrollbar-hidden">
                    <div id="tabulator" ref="tableRef" class="mt-5"></div>
                </div>
            </div>
            <div v-if="pagedata.report_type == 1 || pagedata.report_type == 2">
                <!-- BEgin: Process Report Start-->
                <div id="responsive-table" class="p-5" v-if="pagedata.loading">
                    <div class="preview">
                        <div class="overflow-x-auto">
                            <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                <LoadingIcon icon="bars" class="w-8 h-8" />
                                <div class="text-center text-xs mt-2">Getting Latest Price</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="scrollbar-hidden">
                        <div class="intro-y col-span-12 overflow-auto" v-if="pagedata.status == 1 && printData.day_wise == true && printData.issue_pending == true">
                            <table class="mt-3 w-full border-2">
                                <thead>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party Name</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ printData.report_type == 2 ? 'Size Name' : 'Kapan Name' }}</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="printData.report_type == 2">Stone</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap w-32 border-r">
                                            <div>Pnd. Pieces</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap w-32 border-r">
                                            <div>Pnd. Wt</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap w-32 border-r">
                                            <div>Pnd. Polish Wt</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap w-32 border-r">
                                            <div>Days</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap w-32 border-r">
                                            <div>Due Days</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap w-32 border-r">
                                            <div>Over Due Days</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                            <div>{{ pagedata.total.total_pending_pieces ? pagedata.total.total_pending_pieces : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                            <div>{{ pagedata.total.total_pending_weight ? $h.myFloat(pagedata.total.total_pending_weight) : '0' }} Ct</div>
                                        </td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                            <div>{{ pagedata.total.total_pending_polish_weight ? $h.myFloat(pagedata.total.total_pending_polish_weight) : '0' }} Ct</div>
                                        </td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                    </tr>
                                </tbody>
                                <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                                    <tr v-for="(single_item,ind) in item.packet_details" :key="ind">
                                        <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? (item.company_name ? item.company_name : (item.first_name + ' ' + (item.last_name ? item.last_name : ''))) : '' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.kapan_code ? single_item.kapan_code : '' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2">{{ single_item.stone_name ? single_item.stone_name : '' }}</td>
                                        <td colspan="6" class="p-0 m-0">
                                            <table class="border p-0 m-0">
                                                <tr v-for="(day_item,day_index) in single_item.day_details" :key="day_index">

                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ day_item.pending_packets ? day_item.pending_packets : '0' }}</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ day_item.pending_weight ? $h.myFloat(day_item.pending_weight) : '0' }} Ct</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ day_item.pending_polish_weight ? $h.myFloat(day_item.pending_polish_weight) : '0' }} Ct</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ day_item.days ? day_item.days : '0' }}</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ day_item.due_days ? day_item.due_days : '0' }}</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ day_item.over_due_days ? day_item.over_due_days : '0' }}</div>
                                                    </td>
                                                </tr>
                                                <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ single_item.pending_packets ? single_item.pending_packets : '0' }}</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ single_item.pending_weight ? $h.myFloat(single_item.pending_weight) : '0' }} Ct</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div>{{ single_item.pending_polish_weight ? $h.myFloat(single_item.pending_polish_weight) : '0' }} Ct</div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div></div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div></div>
                                                    </td>
                                                    <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                                        <div></div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="border-b" v-if="!item.packet_details">
                                        <td class="py-2 border-b text-xs lg:text-md text-center">{{ index + 1 }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind == 0 ? item.first_name + ' ' + item.last_name : '' }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">-</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2">-</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">0</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">0 Ct</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">0 Ct</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                            <div> {{ item.total_pending_pieces ? item.total_pending_pieces : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                            <div> {{ item.total_pending_weight ? $h.myFloat(item.total_pending_weight) : '0' }} Ct</div>
                                        </td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r">
                                            <div> {{ item.pending_polish_weight ? $h.myFloat(item.pending_polish_weight) : '0' }} Ct</div>
                                        </td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center w-32 border-r"></td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="intro-y col-span-12 overflow-auto" v-else>
                            <table class="mt-3 border-2 w-full" v-if="printData.process_id == 37">
                                <thead>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party Name</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ printData.report_type == 2 ? 'Size Name' : 'Kapan Name' }}</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="printData.report_type == 2">Stone</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == false">Issue Pieces</div>
                                            <div v-else>Pnd. Pieces</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == false">Issue Wt (Ct)</div>
                                            <div v-else>Pnd. Wt (Ct)</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == false">Issue Polish Wt (Ct)</div>
                                            <div v-else>Pnd. Polish Wt (Ct)</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Ghat (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Polish Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">New Polish Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Polish Ghat (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">Total Majuri</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Polish Wt (Ct)</th>
                                        <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="pagedata.status == 0 || pagedata.status == 2">Majuri</th> -->
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Polish Wt (Ct)</th>
                                        <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish Weight</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_pieces ? pagedata.total.total_pending_pieces : '0' }}</div>
                                            <div v-else>{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_weight ? $h.myFloat(pagedata.total.total_pending_weight) : '0' }}</div>
                                            <div v-else>{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_polish_weight ? $h.myFloat(pagedata.total.total_pending_polish_weight) : '0' }}</div>
                                            <div v-else>{{ pagedata.total.total_issue_polish_weight ? $h.myFloat(pagedata.total.total_issue_polish_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_pieces ? pagedata.total.total_received_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_weight ? $h.myFloat(pagedata.total.total_received_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_ghat ? $h.myFloat(pagedata.total.total_received_ghat) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_polish_weight ? $h.myFloat(pagedata.total.total_received_polish_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_new_polish_weight ? $h.myFloat(pagedata.total.total_received_new_polish_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2" :class="{'bg-red':pagedata.total.total_received_polish_ghat < 0,'bg-green':pagedata.total.total_received_polish_ghat > 0}">{{ pagedata.total.total_received_polish_ghat ? $h.myFloat(pagedata.total.total_received_polish_ghat) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_pieces ? pagedata.total.total_failed_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_weight ? $h.myFloat(pagedata.total.total_failed_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_polish_weight ? $h.myFloat(pagedata.total.total_failed_polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_pieces_majuri ? parseFloat(pagedata.total.total_received_pieces_majuri).toFixed(0) : '0' }}</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_pieces ? pagedata.total.total_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_weight ? $h.myFloat(pagedata.total.total_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_polish_weight ? $h.myFloat(pagedata.total.total_polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_pwt ? $h.myFloat(item.total_pwt) : '0' }} Ct</td> -->
                                    </tr>
                                </tbody>
                                <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                                    <tr v-for="(single_item,ind) in item.packet_details" :key="ind">
                                        <td class="py-2 text-xs lg:text-md text-center whitespace-nowrap border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center whitespace-nowrap border-r">{{ ind == 0 ? (item.company_name ? item.company_name : (item.first_name + ' ' + (item.last_name ? item.last_name : ''))) : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2">{{ single_item.kapan_code ? single_item.kapan_code : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2">{{ single_item.stone_name ? single_item.stone_name : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ single_item.pending_packets ? single_item.pending_packets : '0' }}</div>
                                            <div v-else>{{ single_item.total_issue_packets ? single_item.total_issue_packets : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ single_item.pending_weight ? $h.myFloat(single_item.pending_weight) : '0' }}</div>
                                            <div v-else>{{ single_item.total_issue_weight ? $h.myFloat(single_item.total_issue_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ single_item.pending_polish_weight ? $h.myFloat(single_item.pending_polish_weight) : '0' }}</div>
                                            <div v-else>{{ single_item.issue_polish_weight ? $h.myFloat(single_item.issue_polish_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_packets ? single_item.received_packets : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_weight ? $h.myFloat(single_item.received_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_ghat ? $h.myFloat(single_item.received_ghat) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_polish_weight ? $h.myFloat(single_item.received_polish_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_new_polish_weight ? $h.myFloat(single_item.received_new_polish_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'bg-red':single_item.received_polish_ghat < 0,'bg-green':single_item.received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_polish_ghat ? $h.myFloat(single_item.received_polish_ghat) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_packets ? single_item.failed_packets : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_weight ? $h.myFloat(single_item.failed_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_polish_weight ? $h.myFloat(single_item.failed_polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_packets_majuri ? parseFloat(single_item.received_packets_majuri).toFixed(0) : '0' }}</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.packets ? single_item.packets : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.ex_weight ? $h.myFloat(single_item.ex_weight) : '0' }} Ct</td> -->
                                    </tr>
                                    <tr class="border-b" v-if="!item.packet_details">
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center">{{ index + 1 }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? item.first_name + ' ' + item.last_name : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2">-</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2">-</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">0</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                                    </tr>
                                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ item.total_pending_pieces ? item.total_pending_pieces : '0' }}</div>
                                            <div v-else>{{ item.total_issue_packets ? item.total_issue_packets : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ item.total_pending_weight ? $h.myFloat(item.total_pending_weight) : '0' }}</div>
                                            <div v-else>{{ item.total_issue_weight ? $h.myFloat(item.total_issue_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ item.pending_polish_weight ? $h.myFloat(item.pending_polish_weight) : '0' }}</div>
                                            <div v-else>{{ item.total_polish_weight ? $h.myFloat(item.total_polish_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_pieces ? item.total_received_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_weight ? $h.myFloat(item.total_received_weight) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_ghat ? $h.myFloat(item.total_received_ghat) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.received_polish_weight ? $h.myFloat(item.received_polish_weight) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.received_new_polish_weight ? $h.myFloat(item.received_new_polish_weight) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'bg-red':item.received_polish_ghat < 0,'bg-green':item.received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.received_polish_ghat ? $h.myFloat(item.received_polish_ghat) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_majuri ? $h.myFloat(item.total_majuri) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_failed_pieces ? item.total_failed_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_failed_weight ? $h.myFloat(item.total_failed_weight) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.failed_polish_weight ? $h.myFloat(item.failed_polish_weight) : '0' }} </td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_pieces_majuri ? parseFloat(item.total_received_pieces_majuri).toFixed(0) : '0' }}</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.total_pieces ? item.total_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.total_weight ? $h.myFloat(item.total_weight) : '0' }} </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.polish_weight ? $h.myFloat(item.polish_weight) : '0' }} </td>
                                    </tr>

                                </tbody>
                            </table>
                            <table class="mt-3 border-2 w-full" v-else>
                                <thead>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party Name</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2">{{ printData.report_type == 2 ? 'Size Name' : 'Kapan Name' }}</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="printData.report_type == 2">Stone</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == false">Issue Pieces</div>
                                            <div v-else>Pnd. Pieces</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == false">Issue Wt (Ct)</div>
                                            <div v-else>Pnd. Wt (Ct)</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == false">Issue Polish Wt</div>
                                            <div v-else>Pnd. Polish Wt (Ct)</div>
                                        </th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Ghat (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Polish Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">Majuri</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Polish Wt (Ct)</th>
                                        <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="pagedata.status == 0 || pagedata.status == 2">Majuri</th> -->
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Wt (Ct)</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Polish Wt (Ct)</th>
                                        <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish Weight</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_pieces ? pagedata.total.total_pending_pieces : '0' }}</div>
                                            <div v-else>{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_weight ? $h.myFloat(pagedata.total.total_pending_weight) : '0' }}</div>
                                            <div v-else>{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_polish_weight ? $h.myFloat(pagedata.total.total_pending_polish_weight) : '0' }}</div>
                                            <div v-else>{{ pagedata.total.total_issue_polish_weight ? $h.myFloat(pagedata.total.total_issue_polish_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_pieces ? pagedata.total.total_received_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_weight ? $h.myFloat(pagedata.total.total_received_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_ghat ? $h.myFloat(pagedata.total.total_received_ghat) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_polish_weight ? $h.myFloat(pagedata.total.total_received_polish_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_pieces ? pagedata.total.total_failed_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_weight ? $h.myFloat(pagedata.total.total_failed_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_polish_weight ? $h.myFloat(pagedata.total.total_failed_polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_pieces_majuri ? parseFloat(pagedata.total.total_received_pieces_majuri).toFixed(0) : '0' }}</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_pieces ? pagedata.total.total_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_weight ? $h.myFloat(pagedata.total.total_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_polish_weight ? $h.myFloat(pagedata.total.total_polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_pwt ? $h.myFloat(item.total_pwt) : '0' }} Ct</td> -->
                                    </tr>
                                </tbody>
                                <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                                    <tr v-for="(single_item,ind) in item.packet_details" :key="ind">
                                        <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                        <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? (item.company_name ? item.company_name : (item.first_name + ' ' + (item.last_name ? item.last_name : ''))) : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2">{{ single_item.kapan_code ? single_item.kapan_code : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2">{{ single_item.stone_name ? single_item.stone_name : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ single_item.pending_packets ? single_item.pending_packets : '0' }}</div>
                                            <div v-else>{{ single_item.total_issue_packets ? single_item.total_issue_packets : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ single_item.pending_weight ? $h.myFloat(single_item.pending_weight) : '0' }}</div>
                                            <div v-else>{{ single_item.total_issue_weight ? $h.myFloat(single_item.total_issue_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ single_item.pending_polish_weight ? $h.myFloat(single_item.pending_polish_weight) : '0' }}</div>
                                            <div v-else>{{ single_item.issue_polish_weight ? $h.myFloat(single_item.issue_polish_weight) : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_packets ? single_item.received_packets : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_weight ? $h.myFloat(single_item.received_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_ghat ? $h.myFloat(single_item.received_ghat) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_polish_weight ? $h.myFloat(single_item.received_polish_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_packets ? single_item.failed_packets : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_weight ? $h.myFloat(single_item.failed_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_polish_weight ? $h.myFloat(single_item.failed_polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_packets_majuri ? parseFloat(single_item.received_packets_majuri).toFixed(0) : '0' }}</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.packets ? single_item.packets : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '0' }}</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.ex_weight ? $h.myFloat(single_item.ex_weight) : '0' }} Ct</td> -->
                                    </tr>
                                    <tr class="border-b" v-if="!item.packet_details">
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center">{{ index + 1 }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? item.first_name + ' ' + item.last_name : '' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2">-</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2">-</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">0</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                                    </tr>
                                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="printData.report_type == 2"></td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ item.total_pending_pieces ? item.total_pending_pieces : '0' }}</div>
                                            <div v-else>{{ item.total_issue_packets ? item.total_issue_packets : '0' }}</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ item.total_pending_weight ? $h.myFloat(item.total_pending_weight) : '0' }} Ct</div>
                                            <div v-else>{{ item.total_issue_weight ? $h.myFloat(item.total_issue_weight) : '0' }} Ct</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1">
                                            <div v-if="pagedata.issue_pending == true">{{ item.pending_polish_weight ? $h.myFloat(item.pending_polish_weight) : '0' }} Ct</div>
                                            <div v-else>{{ item.total_polish_weight ? $h.myFloat(item.total_polish_weight) : '0' }} Ct</div>
                                        </td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_pieces ? item.total_received_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_weight ? $h.myFloat(item.total_received_weight) : '0' }} Ct</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_ghat ? $h.myFloat(item.total_received_ghat) : '0' }} Ct</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.received_polish_weight ? $h.myFloat(item.received_polish_weight) : '0' }} Ct</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_majuri ? $h.myFloat(item.total_majuri) : '0' }} Ct</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_failed_pieces ? item.total_failed_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_failed_weight ? $h.myFloat(item.total_failed_weight) : '0' }} Ct</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.failed_polish_weight ? $h.myFloat(item.failed_polish_weight) : '0' }} Ct</td>
                                        <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_pieces_majuri ? parseFloat(item.total_received_pieces_majuri).toFixed(0) : '0' }}</td> -->
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.total_pieces ? item.total_pieces : '0' }}</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.total_weight ? $h.myFloat(item.total_weight) : '0' }} Ct</td>
                                        <td class="py-2 border-b text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.polish_weight ? $h.myFloat(item.polish_weight) : '0' }} Ct</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    provide,
    reactive,
    onMounted,
    toRefs
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import {
    useStore
} from '@/store'
import moment from 'moment'
import {
    stringify
} from 'json5'
import {
    helper as $h
} from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
    setup() {
        const router = useRouter()
        const tableRef = ref()
        const tabulator = ref()
        const store = useStore()
        const userDetails = computed(() => store.state.user.currentUser)
        const pagedata = reactive({
            serieses: [],
            processes: [],
            kapans: [],
            packets: [],
            stones: [],
            shapes: [],
            colors: [],
            purities: [],
            parties: [],
            report_details: [],
            total: [],
            report_type: 0,
            kapan_size: 0,
            loading: false,
            status: 1,
            issue_pending: false,
            size_wise: false,
        })
        const screenData = reactive({
            series_id: [],
            kapan_id: [],
            stone_id: '0',
            size_weight: '',
            packets: [],
            process_id: "0",
            party_id: '0',
            shape_group: '0',
            color_id: '0',
            purity_id: '0',
            status: '1',
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date: moment().format('YYYY-MM-DD'),
            issue_pending: true,
            report_type: '1',
            days: '',
            due: false,
            over_due: false,
            due_days: '',
            over_due_days: '',
            day_wise: false

        })
        const printData = reactive({
            series_id: [],
            kapan_id: [],
            stone_id: '0',
            size_weight: '',
            packets: [],
            process_id: "0",
            party_id: '0',
            shape_group: '0',
            color_id: '0',
            purity_id: '0',
            status: '1',
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date: moment().format('YYYY-MM-DD'),
            issue_pending: true,
            report_type: '1',
            days: '',
            day_wise: false,
            due: false,
            over_due: false,
            due_days: '',
            over_due_days: '',

        })

        const getDPStoneType = () => {
            let params = {}
            let promise = axios({
                url: "api/dp_stone",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.stones = data.items

                })
                .catch(_error => {

                });
        }

        const generateReport = () => {

            printData.series_id = screenData.series_id
            printData.kapan_id = screenData.kapan_id
            printData.stone_id = screenData.stone_id
            printData.size_weight = screenData.size_weight
            printData.packets = screenData.packets
            printData.process_id = screenData.process_id
            printData.party_id = screenData.party_id
            printData.shape_group = screenData.shape_group
            printData.color_id = screenData.color_id
            printData.purity_id = screenData.purity_id
            printData.status = screenData.status
            printData.issue_date = screenData.issue_date
            printData.receive_date = screenData.receive_date
            printData.issue_pending = screenData.issue_pending
            printData.report_type = screenData.report_type
            printData.days = screenData.days
            printData.day_wise = screenData.day_wise
            printData.due = screenData.due
            printData.over_due = screenData.over_due
            printData.due_days = screenData.due_days
            printData.over_due_days = screenData.over_due_days

            if (printData.report_type == '1' || printData.report_type == '2') {
                pagedata.report_type = printData.report_type
                pagedata.loading = true
                pagedata.status = printData.status
                pagedata.issue_pending = printData.issue_pending
                let params = {
                    series_id: (printData.series_id.length > 0) ? printData.series_id : '',
                    kapan_id: (printData.kapan_id.length > 0) ? printData.kapan_id : '',
                    packets: (printData.packets.length > 0) ? printData.packets : '',
                    process_id: printData.process_id != '0' ? window.btoa(printData.process_id) : '',
                    party_id: printData.party_id != '0' ? window.btoa(printData.party_id) : '',
                    stone_id: printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
                    size_weight: window.btoa(printData.size_weight),
                    shape_group: printData.shape_group != '0' ? window.btoa(printData.shape_group) : '',
                    color_id: printData.color_id != '0' ? window.btoa(printData.color_id) : '',
                    purity_id: printData.purity_id != '0' ? window.btoa(printData.purity_id) : '',
                    status: window.btoa(printData.status),
                    issue_date: window.btoa(printData.issue_date),
                    receive_date: window.btoa(printData.receive_date),
                    issue_pending: printData.issue_pending,
                    days: printData.days,
                    day_wise: printData.day_wise,
                    due: printData.due,
                    over_due: printData.over_due,
                    due_days: printData.due_days,
                    over_due_days: printData.over_due_days,
                    report_type: window.btoa(printData.report_type)
                }
                let promise = axios({
                    url: "api/add_out_report_details",
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {
                        pagedata.report_details = data.items
                        pagedata.total = data.total
                        pagedata.loading = false
                    })
                    .catch(_error => {

                    });
            }
            if (screenData.report_type == '3') {

              let params = {
                                series_id: (printData.series_id.length > 0) ? printData.series_id : '',
                                kapan_id: (printData.kapan_id.length > 0) ? printData.kapan_id : '',
                                packets: (printData.packets.length > 0) ? printData.packets : '',
                                process_id: printData.process_id != '0' ? printData.process_id : '',
                                party_id: printData.party_id != '0' ? printData.party_id : '',
                                stone_id: printData.stone_id != '0' ? printData.stone_id : '',
                                size_weight: printData.size_weight,
                                shape_group: printData.shape_group != '0' ? printData.shape_group : '',
                                color_id: printData.color_id != '0' ? printData.color_id : '',
                                purity_id: printData.purity_id != '0' ? printData.purity_id : '',
                                status: printData.status,
                                issue_date: printData.issue_date,
                                receive_date: printData.receive_date,
                                issue_pending: printData.issue_pending,
                                days: printData.days,
                                day_wise: printData.day_wise,
                                due: printData.due,
                                over_due: printData.over_due,
                                due_days: printData.due_days,
                                over_due_days: printData.over_due_days,
                                report_type: printData.report_type
                            }

                pagedata.report_type = 3
                setTimeout(() => {
                    if (printData.issue_pending == true) {
                        tabulator.value = new Tabulator(tableRef.value, {
                            ajaxURL: BASE_URL + '/api/get_out_polish_summary_lot_report',
                            ajaxConfig: {
                                method: "GET", //set request type to Position
                                headers: {
                                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                                    "Authorization": "Bearer " + localStorage.getItem("token")
                                },
                            },
                            ajaxParams: {
                                series_id: (printData.series_id.length > 0) ? printData.series_id : '',
                                kapan_id: (printData.kapan_id.length > 0) ? printData.kapan_id : '',
                                packets: (printData.packets.length > 0) ? printData.packets : '',
                                process_id: printData.process_id != '0' ? printData.process_id : '',
                                party_id: printData.party_id != '0' ? printData.party_id : '',
                                stone_id: printData.stone_id != '0' ? printData.stone_id : '',
                                size_weight: printData.size_weight,
                                shape_group: printData.shape_group != '0' ? printData.shape_group : '',
                                color_id: printData.color_id != '0' ? printData.color_id : '',
                                purity_id: printData.purity_id != '0' ? printData.purity_id : '',
                                status: printData.status,
                                issue_date: printData.issue_date,
                                receive_date: printData.receive_date,
                                issue_pending: printData.issue_pending,
                                days: printData.days,
                                day_wise: printData.day_wise,
                                due: printData.due,
                                over_due: printData.over_due,
                                due_days: printData.due_days,
                                over_due_days: printData.over_due_days,
                                report_type: printData.report_type
                            },
                            ajaxFiltering: true,
                            ajaxSorting: true,
                            printAsHtml: true,
                            printStyled: true,
                            pagination: 'remote',
                            paginationSize: 20,
                            paginationSizeSelector: [20, 30, 40],
                            layout: 'fitColumns',
                            responsiveLayout: false,
                            tooltipsHeader: true,
                            headerSort: false,
                            placeholder: 'No matching records found',
                            columns: [
                                // For HTML table
                                // For HTML table
                                {
                                    title: 'Packet No',
                                    field: 'packet_code',
                                    vertAlign: 'middle',
                                    print: false,
                                    minWidth: 160,
                                    download: true,
                                    cellClick: function (e, cell) {
                                        //e - the click event object
                                        //cell - cell component
                                        console.log(cell.getData().id);
                                        // router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                                    },
                                    formatter(cell) {
                                        if (cell.getData().status == 2) {
                                            return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>` + cell.getData().packet_code
                                        } else {
                                            return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().packet_code
                                        }
                                    }
                                },
                                {
                                    title: 'Process Party',
                                    field: 'company_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    minWidth: 160,
                                    download: true,
                                    formatter(cell) {
                                        return cell.getData().company_name ? cell.getData().company_name : (cell.getData().first_name + (cell.getData().last_name ? cell.getData().last_name : ''))
                                    }
                                },

                                {
                                    title: 'Weight',
                                    field: 'weight',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true,
                                },
                                {
                                    title: 'Cr. Weight',
                                    field: 'cr_weight',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Ex. Weight',
                                    field: 'ex_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Iss. Weight',
                                    field: 'issue_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Days',
                                    field: 'days',
                                    print: false,
                                    download: true,
                                    formatter(cell) {
                                        return cell.getData().days ? cell.getData().days : '-'
                                    }
                                },
                                {
                                    title: 'Due Days',
                                    field: 'due_days',
                                    print: false,
                                    download: true,
                                    formatter(cell) {
                                        return cell.getData().due_days ? cell.getData().due_days : '-'
                                    }
                                },
                                {
                                    title: 'Over Due Days',
                                    field: 'over_due_days',
                                    print: false,
                                    download: true,
                                    formatter(cell) {
                                        return cell.getData().over_due_days ? cell.getData().over_due_days : '-'
                                    }
                                },
                                {
                                    title: 'Shape',
                                    field: 'shape_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Color',
                                    field: 'color_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Purity',
                                    field: 'purity_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Status',
                                    field: 'status',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: false,
                                    formatter(cell) {

                                        if (cell.getData().status == 2) {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                                        } else {
                                            return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                                        }
                                    }
                                },

                            ],
                            renderComplete() {
                                feather.replace({
                                    'stroke-width': 1.5
                                })
                            }
                        })

                    } else {
                      if(printData.process_id == 37) {
                        tabulator.value = new Tabulator(tableRef.value, {
                            ajaxURL: BASE_URL + '/api/get_out_polish_summary_lot_report',
                            ajaxConfig: {
                                method: "GET", //set request type to Position
                                headers: {
                                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                                    "Authorization": "Bearer " + localStorage.getItem("token")
                                },
                            },
                            ajaxParams: params,
                            ajaxFiltering: true,
                            ajaxSorting: true,
                            printAsHtml: true,
                            printStyled: true,
                            pagination: 'remote',
                            paginationSize: 20,
                            paginationSizeSelector: [20, 30, 40],
                            layout: 'fitColumns',
                            responsiveLayout: false,
                            tooltipsHeader: true,
                            headerSort: false,
                            placeholder: 'No matching records found',
                            columns: [
                                // For HTML table
                                // For HTML table
                                {
                                    title: 'Packet No',
                                    field: 'packet_code',
                                    vertAlign: 'middle',
                                    print: false,
                                    minWidth: 160,
                                    download: true,
                                    cellClick: function (e, cell) {
                                        //e - the click event object
                                        //cell - cell component
                                        console.log(cell.getData().id);
                                        router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                                    },
                                    formatter(cell) {
                                        if (cell.getData().status == 2) {
                                            return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>` + cell.getData().packet_code
                                        } else {
                                            return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().packet_code
                                        }
                                    }
                                },
                                {
                                    title: 'Polish Party',
                                    field: 'company_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    minWidth: 160,
                                    download: true,
                                    formatter(cell) {
                                        return cell.getData().company_name ? cell.getData().company_name : (cell.getData().first_name + (cell.getData().last_name ? cell.getData().last_name : ''))
                                    }
                                },

                                {
                                    title: 'Weight',
                                    field: 'weight',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true,
                                },
                                {
                                    title: 'Cr. Weight',
                                    field: 'cr_weight',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Iss. Weight',
                                    field: 'issue_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Rec. Weight',
                                    field: 'received_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Ex. Weight',
                                    field: 'ex_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'New Ex. Wt',
                                    field: 'new_ex_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Diffrence',
                                    field: 'defference',
                                    print: false,
                                    download: true,
                                    formatter(cell) {
                                        if (cell.getData().new_ex_weight) {
                                            if (cell.getData().new_ex_weight > cell.getData().ex_weight) {
                                                return `<div class="font-bold text-theme-9 mr-1">` + $h.myFloat(cell.getData().new_ex_weight - cell.getData().ex_weight) + `</div>`
                                            } else if (cell.getData().new_ex_weight < cell.getData().ex_weight) {
                                                return `<div class="font-bold text-theme-6 mr-1">` + $h.myFloat(cell.getData().ex_weight - cell.getData().new_ex_weight) + `</div>`
                                            } else {
                                                return `<div class="font-bold mr-1">` + $h.myFloat(cell.getData().ex_weight - cell.getData().new_ex_weight) + `</div>`
                                            }
                                        } else {
                                            return `<div class="font-bold mr-1">` + '-' + `</div>`
                                        }
                                    }
                                },
                                // {
                                //   title: 'Days',
                                //   field: 'days',
                                //   print: false,
                                //   download: true,
                                //   formatter(cell) {
                                //     return cell.getData().status == 1 ? '-' : '-'
                                //   }
                                // },
                                {
                                    title: 'Shape',
                                    field: 'shape_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Color',
                                    field: 'color_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Purity',
                                    field: 'purity_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Majuri',
                                    field: 'majuri',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true,
                                    formatter(cell) {
                                        return $h.myFloat(cell.getData().total_majuri)
                                    }
                                },
                                {
                                    title: 'Status',
                                    field: 'status',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: false,
                                    formatter(cell) {

                                        if (cell.getData().status == 2) {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                                        } else {
                                            return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                                        }
                                    }
                                },

                            ],
                            renderComplete() {
                                feather.replace({
                                    'stroke-width': 1.5
                                })
                            }
                        })
                      } else {
                        tabulator.value = new Tabulator(tableRef.value, {
                            ajaxURL: BASE_URL + '/api/get_out_polish_summary_lot_report',
                            ajaxConfig: {
                                method: "GET", //set request type to Position
                                headers: {
                                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                                    "Authorization": "Bearer " + localStorage.getItem("token")
                                },
                            },
                            ajaxParams: params,
                            ajaxFiltering: true,
                            ajaxSorting: true,
                            printAsHtml: true,
                            printStyled: true,
                            pagination: 'remote',
                            paginationSize: 20,
                            paginationSizeSelector: [20, 30, 40],
                            layout: 'fitColumns',
                            responsiveLayout: false,
                            tooltipsHeader: true,
                            headerSort: false,
                            placeholder: 'No matching records found',
                            columns: [
                                // For HTML table
                                // For HTML table
                                {
                                    title: 'Packet No',
                                    field: 'packet_code',
                                    vertAlign: 'middle',
                                    print: false,
                                    minWidth: 160,
                                    download: true,
                                    cellClick: function (e, cell) {
                                        //e - the click event object
                                        //cell - cell component
                                        console.log(cell.getData().id);
                                        router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                                    },
                                    formatter(cell) {
                                        if (cell.getData().status == 2) {
                                            return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>` + cell.getData().packet_code
                                        } else {
                                            return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().packet_code
                                        }
                                    }
                                },
                                {
                                    title: 'Polish Party',
                                    field: 'company_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    minWidth: 160,
                                    download: true,
                                    formatter(cell) {
                                        return cell.getData().company_name ? cell.getData().company_name : (cell.getData().first_name + (cell.getData().last_name ? cell.getData().last_name : ''))
                                    }
                                },

                                {
                                    title: 'Weight',
                                    field: 'weight',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true,
                                },
                                {
                                    title: 'Cr. Weight',
                                    field: 'cr_weight',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Iss. Weight',
                                    field: 'issue_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Rec. Weight',
                                    field: 'received_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Ex. Weight',
                                    field: 'ex_weight',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Shape',
                                    field: 'shape_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Color',
                                    field: 'color_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Purity',
                                    field: 'purity_name',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true
                                },
                                {
                                    title: 'Majuri',
                                    field: 'majuri',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: true,
                                    formatter(cell) {
                                        return $h.myFloat(cell.getData().total_majuri)
                                    }
                                },
                                {
                                    title: 'Status',
                                    field: 'status',
                                    vertAlign: 'middle',
                                    print: false,
                                    download: false,
                                    formatter(cell) {

                                        if (cell.getData().status == 2) {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                                        } else {
                                            return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                                        }
                                    }
                                },

                            ],
                            renderComplete() {
                                feather.replace({
                                    'stroke-width': 1.5
                                })
                            }
                        })
                      }

                    }
                }, 40);
            }
        }
        const getSeries = () => {
            let params = {
                process_id: screenData.process_id,
            }
            let promise = axios({
                url: "api/get_process_packet_series",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.series_id = []
                    pagedata.serieses = data.items

                })
                .catch(_error => {

                });
        }
        const getProcesses = () => {
            let promise = axios({
                url: "api/add_out_report_processes",
                method: "post",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.processes = data.items

                })
                .catch(_error => {

                });
        }
        const getKapans = () => {
            let params = {
                series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
                process_id: screenData.process_id,
            }
            let promise = axios({
                url: "api/get_process_packet_kapan_multiple",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.kapan_id = []
                    pagedata.kapans = data.items

                  })
                  .catch(_error => {

                    pagedata.kapans = []
                    screenData.kapan_id = []
                });
        }
        const getPackets = () => {

            if (screenData.kapan_id.length > 0) {

                let params = {
                    series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
                    kapan_id: (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
                    process_id: screenData.process_id,
                }
                let promise = axios({
                    url: "api/get_process_packet_packets_multiple",
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        screenData.packets = []
                        pagedata.packets = data.items

                      })
                      .catch(_error => {
                        screenData.packets = []
                        pagedata.packets = []

                    });
            } else {
                screenData.packets = []
                pagedata.packets = []
            }
        }
        const getParties = () => {

            if (screenData.process_id != "0") {

                let params = {
                    // process: pagedata.process,
                }
                let new_url = ""

                if (screenData.process_id == "32") {
                    new_url = "api/dp_account_polish_party"
                } else {
                    new_url = "api/dp_account_process_party"
                }
                let promise = axios({
                    url: new_url,
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        pagedata.parties = data.items

                    })
                    .catch(_error => {

                    });
            }
        }
        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // On reset filter
        const onResetFilter = () => {

            screenData.series_id = []
            screenData.kapan_id = []
            screenData.packets = []
            screenData.party_id = '0'
            // screenData.process_id = "0"
            screenData.status = '1'
            screenData.issue_pending = true
            screenData.stone_id = '0'
            screenData.shape_group = '0'
            screenData.color_id = '0'
            screenData.purity_id = '0'
            screenData.size_weight = ''
            screenData.report_type = '1'

            // pagedata.report_type = 0
            // pagedata.status = 0
            // pagedata.issue_pending = false
            // if(pagedata.report_type == 1 || pagedata.report_type == 2) {
            //   pagedata.report_details = []
            //   pagedata.total = []
            // }

        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'tender_list.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'tender_list.json')
        }

        function onExportXlsx() {

            if (printData.report_type == 3) {
                let filename = "Out-process-lot-summary.xlsx";
                let params = {
                    series_id: (printData.series_id.length > 0) ? printData.series_id : '',
                    kapan_id: (printData.kapan_id.length > 0) ? printData.kapan_id : '',
                    packets: (printData.packets.length > 0) ? printData.packets : '',
                    process_id: printData.process_id != '0' ? printData.process_id : '',
                    party_id: printData.party_id != '0' ? printData.party_id : '',
                    stone_id: printData.stone_id != '0' ? printData.stone_id : '',
                    size_weight: printData.size_weight,
                    shape_group: printData.shape_group != '0' ? printData.shape_group : '',
                    color_id: printData.color_id != '0' ? printData.color_id : '',
                    purity_id: printData.purity_id != '0' ? printData.purity_id : '',
                    status: printData.status,
                    issue_date: printData.issue_date,
                    receive_date: printData.receive_date,
                    issue_pending: printData.issue_pending,
                    days: printData.days,
                    day_wise: printData.day_wise,
                    due: printData.due,
                    over_due: printData.over_due,
                    due_days: printData.due_days,
                    over_due_days: printData.over_due_days,
                    report_type: printData.report_type
                }

                let promise = axios({
                    url: "/api/download_out_process_lot_report",
                    method: "post",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    },
                    responseType: "blob",
                });

                return promise
                    .then((result) => result.data)
                    .then((data) => {
                        FileDownload(data, filename);
                    })
                    .catch((_error) => {
                        return [];
                    });
            } else {

                let report_type = 'get_out_process_summary_report'

                let params = {
                    report_type: report_type,
                    filters: printData,
                    items: {
                        'items': pagedata.report_details,
                        'total': pagedata.total
                    }
                }
                let promise = axios({
                    url: "api/add_report_details",
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        const report_id = data.report_id

                        export_exls(report_id)

                    })
                    .catch(_error => {

                    });
            }
        }

        function export_exls(id) {
            let filename = "Out-Process-Summary.xlsx";
            const params = {
                id: window.btoa(id)
            };
            let promise = axios({
                url: "/api/download_out_process_summary_report",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                })
                .catch((_error) => {
                    return [];
                });
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        const checkPermission = () => {
            if (userDetails.value.id == 8) {
                router.push("/app/app-masters");
            }
        }

        function getParameters() {
            getKapans()
            // getPackets()
        }

        function seriesChange() {
            screenData.kapan_id = []
            getKapans()
            screenData.packets = []
        }

        function kapanChange() {
            getPackets()
            screenData.packets = []
        }
        const getShapes = () => {
            let params = {
                // process: pagedata.process,
                // party_id: window.btoa(screenData.party_id),
            }
            let promise = axios({
                url: "api/dp_shape",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.shapes = data.items

                })
                .catch(_error => {

                });
        }
        const getColors = () => {
            let params = {
                // process: pagedata.process,
                // party_id: window.btoa(screenData.party_id),
            }
            let promise = axios({
                url: "api/dp_color",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.colors = data.items

                })
                .catch(_error => {

                });
        }
        const getPurity = () => {
            let params = {
                // process: pagedata.process,
                // party_id: window.btoa(screenData.party_id),
            }
            let promise = axios({
                url: "api/dp_purity",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.purities = data.items

                })
                .catch(_error => {

                });
        }
        onMounted(() => {
            getProcesses()
            checkPermission()
            // getSeries()
            getParties()
            getDPStoneType()
            getPurity()
            getColors()
            getShapes()
            // getParameters()
            // reInitOnResizeWindow()

        })

        function processChange() {

            if (screenData.process_id != '0') {
                let is_match = false
                pagedata.processes.forEach(element => {
                    if (element.id == screenData.process_id && [2, 3, 4].includes(element.majuri_type)) {
                        pagedata.size_wise = true
                        is_match = true
                    }
                });
                if (is_match == false) {
                    pagedata.size_wise = false
                    if (screenData.report_type == 3) {
                        screenData.report_type = '1'
                    }
                }
            } else {
                pagedata.size_wise = false
                if (screenData.report_type == 3) {
                    screenData.report_type = '1'
                }
            }
            getSeries()
            getParties()
            // getParameters()
        }

        function printReportDetails() {
            let report_type = ''
            if (printData.report_type == '3') {
                report_type = 'get_out_process_summary_lot_report'
            } else {
                report_type = 'get_out_process_summary_report'
            }

            let params = {
                report_type: report_type,
                filters: printData,
                items: {
                    'items': pagedata.report_details,
                    'total': pagedata.total
                }
            }
            let promise = axios({
                url: "api/add_report_details",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    const report_id = data.report_id

                    if (report_type == 'get_out_process_summary_lot_report') {

                    } else {
                        let url = BASE_URL + '/out-process-summary-report?id=' + window.btoa(report_id)
                        window.open(url, '_blank');
                    }

                })
                .catch(_error => {

                });
            // add_report_details
        }

        function dueChanged() {
            if (screenData.due == true) {
                screenData.over_due = false
            }
        }

        function overDueChanged() {
            if (screenData.over_due == true) {
                screenData.due = false
            }
        }

        return {
            tableRef,
            pagedata,
            screenData,
            userDetails,
            printData,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            seriesChange,
            kapanChange,
            generateReport,
            printReportDetails,
            processChange,
            overDueChanged,
            dueChanged
        }
    }
})
</script>
