const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "manager-dashboard",
        title: "Dashboard",
        roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 20, 21, 22, 23, 24],
        subMenu: [
          {
            icon: "",
            pageName: "manager-dashboard",
            title: "Manager Dashboard",
            roles: [1],
          }

          // , {
          //     icon: "",
          //     pageName: "menual-manager-dashboard",

          //     title: "Menual Manager Dashboard",
          //     roles:[6],
          // },
          // {
          //     icon: "",
          //     pageName: "menual-ghutan-manager-dashboard",
          //     title: "Menual Manager Dashboard",
          //     roles:[22],
          // },
          // {
          //     icon: "",
          //     pageName: "menual-manager-dashboard",
          //     title: "Menual Manager Dashboard",
          //     roles:[21],
          // },
          // {
          //     icon: "",
          //     pageName: "operater-dashboard",
          //     title: "Operater Dashboard",
          //     roles:[1],
          // },
          // {
          //     icon: "",
          //     pageName: "planner-dashboard",
          //     title: "Dashboard",
          //     roles:[10,11],
          // }
        ]
      },
      "devider",
      // {
      //     icon: "BoxIcon",
      //     pageName: "rough-master",
      //     title: "Rough",
      //     roles:[1,2,4,5,6,7,8,9,13,14,15,16,20,21,22,23,24],
      //     subMenu: [
      //         {
      //             icon: "",
      //             roles:[1],
      //             pageName: "rough-master",
      //             title: "Rough Purchase",
      //         },
      //         {
      //           icon: "",
      //           roles:[1,20],
      //           pageName: "rough-stock-manager",
      //           title: "Create Series",
      //       },{
      //           icon: "",
      //           roles:[1,6,7,21,22],
      //           pageName: "rough-my-series",
      //           title: "Rough Series",
      //       },{
      //           icon: "",
      //           roles:[1,6,8,7,9,14,15,16,21,22,23,24],
      //           pageName: "rough-galaxy-manager",
      //           title: "Rough Kapan",
      //       },
      //         {
      //           icon: "",
      //           roles:[1,6,8,9,7,13,14,15,16,21,22,23,24],
      //           pageName: "rough-packets-master",
      //           title: "Rough Packets",
      //       },{
      //         icon: "",
      //         roles:[10,11,12],
      //         pageName: "rough-packets-planner",
      //         title: "Rough Packets",
      //       },{
      //             icon: "",
      //             roles:[1,20],
      //             pageName: "vendors-master",
      //             title: "Vendors",

      //         }

      //     ]
      // },

      //  {
      //     icon: "BoxIcon",
      //     pageName: "rough-purchase",
      //     title: "Planning",
      //     roles:[10,11,12],
      //     subMenu: [{
      //         icon: "",
      //         roles:[10,11,12],
      //         pageName: "rough-packets-master",
      //         title: "Assigned Packets",
      //         ignore: true
      //     }, ]
      // },
      // {
      //     icon: "BoxIcon",
      //    // pageName: "app-masters",
      //     roles:[1,6,7,8,9,10,11,12,13,14,15,16,20,21,22,23,24],
      //     pageName: "app-process",
      //     title: "Process",
      //     subMenu: [
      //       {
      //       icon: "",
      //       roles:[1,6,7,8,9,10,11,12,13,14,15,16,20,21,22],
      //       pageName: "add-issue-transaction",
      //       title: "Issue(Sr/Kapan)",
      //     },{
      //       icon: "",
      //       roles:[1,6,7,8,9,10,11,12,13,14,15,16,20,21,22],
      //       pageName: "issue-kapan-master",
      //       title: "Issue Transactions",
      //     },{
      //       icon: "",
      //       roles:[1,6,7,8,9,10,11,12,13,14,15,16,21,22,23,24],
      //       pageName: "add-issue-packet",
      //       title: "Issue(Lot/Packet)",
      //     },
      //     {
      //       icon: "",
      //       roles:[23],
      //       pageName: "add-issue-packet-polish",
      //       title: "Issue Polish",
      //     },
      //     {
      //       icon: "",
      //       roles:[1,6,7,8,9,10,11,12,13,14,15,16,21,22,23,24],
      //       pageName: "issue-packet-master",
      //       title: "List Issue(Lot/Packet)",
      //     },
      //     {
      //       icon: "",
      //       roles:[1,6,7,8,9,10,11,12,13,14,15,16,21,22,23,24],
      //       pageName: "add-receive-packet",
      //       title: "Receive(Lot/Packet)",
      //     },
      //     {
      //       icon: "",
      //       roles:[7,9],
      //       //pageName: "issue-packet-master",
      //       pageName: "add-receive-recut-packet",
      //       title: "Receive Recut",
      //     },
      //     {
      //       icon: "",
      //       roles:[23],
      //       pageName: "add-receive-packet-blocking",
      //       title: "Blocking Receive",
      //     },{
      //       icon: "",
      //       roles:[24],
      //       pageName: "add-receive-packet-polish-party",
      //       title: "Polish Receive Party",
      //     },{
      //       icon: "",
      //       roles:[24],
      //       pageName: "add-receive-packet-polish-check",
      //       title: "Polish Receive Checker",
      //     }]
      // },
      {
        icon: "BoxIcon",
        pageName: "tenders",
        title: "Tender",
        roles: [1, 3],
        subMenu: [
          {
            icon: "",
            pageName: "tenders",
            title: "Tenders",
            roles: [1],
          },
          {
            icon: "",
            pageName: "tendersissuenote",
            title: "Issue Note",
            roles: [1, 3],
          },
          {
            icon: "",
            pageName: "tendersitemissue",
            title: "Issue/Receive",
            roles: [1, 3],
          },
          {
            icon: "",
            pageName: "tenderbuyers",
            title: "Tender Buyers",
            roles: [1, 3],
          }
        ]
      },
      {
        icon: "BoxIcon",
        roles: [1, 3, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24],
        pageName: "tender-packet-report",
        title: "Reports",
        subMenu: [
          {
            icon: "",
            roles: [1, 6],
            pageName: "tender-packet-report",
            title: "Tender Packet Report",
          },
          {
            icon: "",
            roles: [1, 6],
            pageName: "tender-top-bids",
            title: "Tender Top Bids",
          },

          {
            icon: "",
            roles: [1, 6],
            pageName: "tender-winners",
            title: "Tender Winners Report",
          },
          {
            icon: "",
            roles: [1, 6, 3],
            pageName: "tender-item-status",
            title: "Item Issue Status",
          },
          {
            icon: "",
            roles: [1, 6, 3],
            pageName: "tender-buyer-status",
            title: "Buyer Issue Status",
          },
          //   {
          //     icon: "",
          //     roles:[1,6,7,8,9,13,14,15,16,21,22,23,24],
          //     pageName: "process-report",
          //     title: "Process Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[1,6,7,8,9,10,11,12,13,14,15,16,21,22,23,24],
          //   pageName: "kapan-packet-history",
          //   title: "Packet History",
          //   },
          //   {
          //     icon: "",
          //     roles:[1,6,7,8,9,13,14,15,16,21,22,23,24],
          //     pageName: "manual-packet-user-report",
          //     title: "K/Pkt Owner Report",
          //     },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "tender-packet-report",
          //     title: "Ghutan/ PA Writer",
          //     subMenu: [
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "pa-ls-report",
          //         title: "PA LS Details",
          //         },
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "pa-ls-lot-report",
          //         title: "PA LS Lot Details",
          //         },
          //         {
          //           icon: "",
          //         roles:[1],
          //         pageName: "pa-ls-summary",
          //         title: "PA LS Summary",
          //         },
          //         {
          //         icon: "",
          //         roles:[1],
          //         pageName: "ghutan-report",
          //         title: "Ghutan Details",
          //       },
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "ghutan-lot-report",
          //         title: "Ghutan Lot Details",
          //         },
          //         {
          //           icon: "",
          //         roles:[1],
          //         pageName: "ghutan-summary-report",
          //         title: "Ghutan PA Summary",
          //       },
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "chabka-report",
          //         title: "Chabka Report",
          //         },
          //         {
          //           icon: "",
          //           roles:[1],
          //           pageName: "chabka-lot-report",
          //           title: "Chabka Lot Details",
          //           },
          //     ]
          //   },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "tender-packet-report",
          //     title: "Menual Writer",
          //     subMenu: [
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "laser-dasa-report",
          //         title: "Laser-DASA Report",
          //       },
          //       {
          //         icon: "",
          //         roles:[1,21],
          //         pageName: "ls-other-report",
          //         title: "LS Report",
          //         },
          //         {
          //         icon: "",
          //         roles:[1,21],
          //         pageName: "manual-kapan-report",
          //         title: "Manual Kapan Report",
          //         },
          //         {
          //         icon: "",
          //         roles:[1,21],
          //         pageName: "manual-summary-report",
          //         title: "Series Summary (M) Report",
          //         },
          //     ]
          //   },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "xxx-report",
          //     title: "XXX Manager",
          //     subMenu: [
          //       {
          //       icon: "",
          //       roles:[1],
          //       pageName: "recut-report",
          //       title: "Recut Report",
          //       },
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "packet-plan-rate",
          //         title: "Packet Plan Rate",
          //         },
          //         {
          //         icon: "",
          //         roles:[1],
          //         pageName: "planner-rate-report",
          //         title: "Planner Rate Report",
          //         },
          //     ]
          //   },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "blocking-report",
          //     title: "Blocking Manager",
          //     subMenu: [
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "blocking-report",
          //         title: "Blocking Report",
          //         },
          //         {
          //         icon: "",
          //         roles:[1],
          //         pageName: "blocking-summary-report",
          //         title: "Blocking Summary Report",
          //         },
          //     ]
          //   },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "polish-report",
          //     title: "Polish Report",
          //     subMenu: [
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "polish-report",
          //         title: "Polish Report",
          //         },
          //         {
          //         icon: "",
          //         roles:[1],
          //         pageName: "polish-summary-report",
          //         title: "Polish Summary Report",
          //         },
          //     ]
          //   },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "galaxy-report",
          //     title: "Galaxy Manager",
          //     subMenu: [
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "galaxy-transfer-report",
          //         title: "Galaxy Transfer Report",
          //       },
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "stock-manager-report",
          //         title: "Galaxy Manager Report",
          //       },
          //     ]
          //   },
          //   {
          //     icon: "BoxIcon",
          //     roles:[1],
          //     pageName: "4p-manager-report",
          //     title: "4P Manager",
          //     subMenu: [
          //       {
          //         icon: "",
          //         roles:[1],
          //         pageName: "4p-report",
          //         title: "4P Report",
          //         },
          //       // {
          //       //   icon: "",
          //       //   roles:[1],
          //       //   pageName: "stock-manager-report",
          //       //   title: "Galaxy Manager Report",
          //       // },
          //     ]
          //   },
          //   {
          //   icon: "",
          //   roles:[22],
          //   pageName: "pa-ls-report",
          //   title: "PA LS Details",
          //   },
          //   {
          //   icon: "",
          //   roles:[22],
          //   pageName: "pa-ls-lot-report",
          //   title: "PA LS Lot Details",
          //   },
          //   {
          //     icon: "",
          //   roles:[22],
          //   pageName: "pa-ls-summary",
          //   title: "PA LS Summary",
          //   },
          //   {
          //     icon: "",
          //     roles:[21,22],
          //     pageName: "laser-dasa-report",
          //     title: "Laser-DASA Report",
          //   },
          //   {
          //     icon: "",
          //     roles:[21,22],
          //   pageName: "ghutan-report",
          //   title: "Ghutan Details",
          //   },
          //   {
          //   icon: "",
          //   roles:[21,22],
          //   pageName: "ghutan-lot-report",
          //   title: "Ghutan Lot Details",
          //   },
          //   {
          //     icon: "",
          //     roles:[22],
          //     pageName: "ghutan-summary-report",
          //   title: "Ghutan PA Summary",
          // },
          //   {
          //   icon: "",
          //   roles:[22],
          //   pageName: "chabka-report",
          //   title: "Chabka Report",
          // },
          // {
          // icon: "",
          // roles:[22],
          // pageName: "chabka-lot-report",
          // title: "Chabka Lot Details",
          // },
          //   {
          //   icon: "",
          //   roles:[21],
          //   pageName: "ls-other-report",
          //   title: "LS Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[21],
          //   pageName: "manual-kapan-report",
          //   title: "Manual Kapan Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[21],
          //   pageName: "manual-summary-report",
          //   title: "Series Summary (M) Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[9,21],
          //   pageName: "recut-report",
          //   title: "Recut Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[23],
          //   pageName: "blocking-report",
          //   title: "Blocking Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[23],
          //   pageName: "blocking-summary-report",
          //   title: "Blocking Summary Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[6,9,21],
          //   pageName: "packet-plan-rate",
          //   title: "Packet Plan Rate",
          //   },
          //   {
          //   icon: "",
          //   roles:[9,10,11,12],
          //   pageName: "planner-rate-report",
          //   title: "Planner Rate Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[14],
          //   pageName: "4p-report",
          //   title: "4P Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[23,24],
          //   pageName: "polish-report",
          //   title: "Polish Report",
          //   },
          //   {
          //   icon: "",
          //   roles:[23,24],
          //   pageName: "polish-summary-report",
          //   title: "Polish Summary Report",
          //   },
          //   {
          //     icon: "",
          //     roles:[6,7,8,9,13,14,15,16],
          //     pageName: "galaxy-transfer-report",
          //     title: "Galaxy Transfer Report",
          //   },
          //   {
          //     icon: "",
          //     roles:[6,7,8,9,13,14,15,16],
          //     pageName: "stock-manager-report",
          //     title: "Galaxy Manager Report",
          //   },
          //   {
          //     icon: "",
          //     roles:[1,6,7,8,9,13,14,15,16],
          //     pageName: "xxx-summary-size-wise",
          //     title: "Size Wise - Summary All",
          //   },
          //   {
          //     icon: "",
          //     roles:[1,6,7,8,9,13,14,15,16],
          //     pageName: "xxx-summary-size-wise-polish",
          //     title: "Size Wise Polish Summary",
          //   },
          //   {
          //     icon: "",
          //     roles:[1,6,7,8,9,13,14,15,16],
          //     pageName: "xxx-summary-shape-polish",
          //     title: "Shape Polish Summary",
          //   },
          //   {
          //     icon: "",
          //     roles:[1,6,7,8,9,13,14,15,16],
          //     pageName: "xxx-summary-shape-group-polish",
          //     title: "Shape Group Polish Summary",
          //   },
          //     // {
          //     //   icon: "",
          //     //   roles:[1,6,7,8,9,10,11,12,13,14,15,16],
          //     //   pageName: "xxx-packet-status-summary",
          //     //   title: "Packet Status Summary",
          //     //   },
          //     {
          //     icon: "",
          //     roles:[1,6,9,20],
          //     pageName: "planning-summary-all",
          //     title: "Summary All",
          //     },
          //     {
          //     icon: "",
          //     roles:[1,6,7,8,9,10,11,12,13,14,15,16],
          //     pageName: "noaccess",
          //     title: "Summary All(Day wise)",
          //     },
          //     {
          //       icon: "",
          //       roles:[1,6,7,8,9,10,11,12,13,14,15,16],
          //       pageName: "noaccess",
          //       title: "Rough Size Group wise",
          //     }
        ]
      },
      {
        icon: "BoxIcon",
        roles: [1, 2, 3, 4, 20],
        pageName: "app-masters",
        //pageName: "noaccess",
        title: "Masters",
      },
      // {
      //     icon: "BoxIcon",
      //     roles:[1,20],
      //     //pageName: "app-masters",
      //     pageName: "noaccess",
      //     title: "User Management",
      // },
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
