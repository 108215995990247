/* eslint-disable */
//import firebase from 'firebase/app'
//import 'firebase/auth'
import { BASE_URL } from "@/constants/config.js";
import { currentUser } from '@/constants/config'

export default {
    state: {
        currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
        userPolicies: localStorage.getItem('policies') != null ? JSON.parse(localStorage.getItem('policies')) : null,
        loginError: null,
        access_token: null,
        processing: false,
        sideMenuview: true,
    },
    getters: {
        currentUser: state => state.currentUser,
        userPolicies: state => state.userPolicies,
        access_token: state => state.access_token,
        processing: state => state.processing,
        loginError: state => state.loginError,
        sideMenuview: state => state.sideMenuview,
    },
    mutations: {
        async setUser(state, payload) {
            state.currentUser = payload
            state.processing = false
            state.loginError = null
        },
        async setPolicies(state, payload) {
            state.userPolicies = payload
            state.processing = false
            state.loginError = null
        },
        async setAccessToken(state, payload) {
            state.access_token = payload
            state.processing = false
            state.loginError = null
        },
        async setLogout(state) {
            state.currentUser = null
            state.currentUser = null
            state.access_token = null
            state.processing = false
            state.loginError = null
        },
        async setProcessing(state, payload) {
            state.processing = payload
            state.loginError = null
        },
        async setError(state, payload) {
            state.loginError = payload
            state.currentUser = null
            state.access_token = null
            state.processing = false
        },
        async clearError(state) {
            state.loginError = null
        },
        async setsideMenuview(state, payload) {
            console.log(state.sideMenuview);
            if(state.sideMenuview){
                state.sideMenuview = false;
            }else{
                state.sideMenuview = true;
            }
        },
    },
    actions: {
        async login({
            commit
        }, payload) {
            commit('clearError')
            commit('setProcessing', true)
            const data = {
                username: payload.email,
                password: btoa(payload.password),
                hash :'Y738hjqo'
            };
            axios({
                //url: 'oauth/token',
                url: 'api/login',
                method: 'post',
                data: data,
                baseURL: BASE_URL,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            }).then(response => {
                localStorage.setItem('token', response.data.access_token)
                commit('setAccessToken', response.data.access_token)
                axios({
                    url: 'api/user_details',
                    method: 'get',
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then(response => {
                    localStorage.setItem('user', JSON.stringify(response.data.user))
                    localStorage.setItem('policies', JSON.stringify(response.data.policies))
                    localStorage.setItem('server_auth', response.data.user.server_auth)
                        //console.log(response);
                    commit('setUser', response.data.user)
                    commit('setPolicies', response.data.policies)

                }).catch(response => {
                    commit('setError', response.data.error)
                });
            }).catch(error => {
                console.log(error);
                if (error != null) {
                    if (error.response != undefined) {
                        commit('setError', error.response.data.error)
                    } else {
                        commit('setError', "License Error")
                    }
                }

            })

        },

        async signOut({
            commit
        }) {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('server_auth')
            commit('setLogout')
        },
        async sideMenuview({
            commit
        }) {
            commit('setsideMenuview')
        }
    }
}
