<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tender Buyer Status (Issue Note Report)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
         
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
   
          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Tender</label
                  >
                 <TomSelect
                v-model="screenData.selectedTenders"
                :options="{
                  placeholder: 'Select Tender',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Tenders'
                    }
                  }
                }"
                class="w-full"
                @change="getTenderItems"
              >
              <option value="">Select Tender</option>
              <option v-for="tender in screenData.tenders" :key="tender.id" :value="tender.id">{{tender.tender_name}}</option>
              </TomSelect>
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Select Party</label
                  >
                 <TomSelect
                v-model="screenData.selectedbuyers"
                :options="{
                  placeholder: 'Select Rough Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option v-for="buyer in screenData.buyers" :key="buyer.id" :value="buyer.id">{{buyer.company_name}}</option>
              </TomSelect>
                </div>
              
              </div>
              

              <div class="col-span-12 xl:col-span-12 mt-5">
                
                <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="onFilter"
            >
              Generate
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
              </div>
            </div>  
       

      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
           <h2 class="text-lg font-medium mr-auto mt-5">Buyer Status Report</h2>
          </div>
         
        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button> -->
          
        </div>
      </div>  
     
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {

   
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      buyers:[],
      selectedTenders:'',
      tenders:[],
      selectedTenderItems:[],
      tenderItems:[]
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_issue_notes_report',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          tender_id:screenData.selectedTenders,
          selected_buyer:screenData.selectedbuyers,
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Note No',
            
            field: 'issue_no',
            print: false,
            download: true
          },
          {
            title: 'Party Name',
            minWidth: 200,
            field: 'buyer_name',
            print: false,
            download: true
          },
          {
            title: 'Total Items',
            field: 'total_items',
            print: false,
            download: true
          },
         
          {
            title: 'Tender Name',
            responsive: 0,
            field: 'tender_name',
            print: false,
            download: true,
           
          },
           {
            title: 'Not Viewed',
            field: 'unissue',
            print: false,
            download: true
          },
          {
            title: 'Viewed',
            field: 'issued',
            print: false,
            download: true
          },
          {
            title: 'Cr Issued',
            field: 'crissued',
            print: false,
            download: true
          },
          
          {
            title: 'Status',
            field: 'status',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().status == 1){
                return `<div class="flex text-theme-9 font-bold">
                <i data-feather="activity" class="w-4 h-4 mr-2"></i> Active
              </div>`
              }else{
              return `<div class="flex text-theme-6 font-bold">
                <i data-feather="activity" class="w-4 h-4 mr-2"></i> Closed
              </div>`
              }
              
            }
          },
          
          // For print format
          
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      initTabulator()
      tabulator.value.setFilter(screenData.selectedTenders, screenData.selectedTenderItems, screenData.selectedbuyers)
    }

    // On reset filter
    const onResetFilter = () => {
      screenData.selectedTenders = ''
      screenData.selectedTenderItems = []
      screenData.selectedbuyers = []
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    const getTenders = () => {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tenders_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          screenData.tenders = data;

        })
        .catch(_error => {
          
        });
    }

    const getTenderItems = () => {
      let _this = this;
      console.log("Here");
      const params = {
        tender_id:screenData.selectedTenders
      };
      let promise = axios({
        url: "api/get_tenders_item_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          screenData.tenderItems = data;

        })
        .catch(_error => {
          
        });
    }
    const getTenderBuyers = () => {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tender_buyers_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          screenData.buyers = data;

        })
        .catch(_error => {
          
        });
    }

    const checkPermission = () => {
      console.log(userDetails.value.id);
      // if(userDetails.value.id == 8){
      //   router.push("/app/app-masters");
      // }
    }

    onMounted(() => {
      checkPermission()
      reInitOnResizeWindow()
      getTenderBuyers()
      getTenders()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      getTenderItems
    }
  }
})
</script>
