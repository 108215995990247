<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Certy Stock (All Branch)</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0" v-if="BUILD_ENV == 'selling'">
          <div class="flex items-center text-lg" v-if="screenData.server_record.server_status == 1"><span class="font-bold mr-2">Last Updated :</span> <WifiIcon class="h-6 w-6 mr-2 text-theme-9" /> {{ screenData.server_record.updated_at }}</div>
          <div class="flex items-center text-lg" v-if="screenData.server_record.server_status == 2"><span class="font-bold mr-2">Last Updated :</span> <GlobeIcon class="h-6 w-6 mr-2 text-theme-6" /> {{ screenData.server_record.updated_at }}</div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-4" v-if="userDetails.user_type != 26">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2" v-if="BUILD_ENV == 'factory'">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Pending</div>
                        <div class="text-xl font-medium leading-8">{{ (screenData.pending_packets.total_pending_packets ? screenData.pending_packets.total_pending_packets : '0') }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600"></div>
                        <div class="text-lg font-medium leading-8">{{ (screenData.pending_packets.total_pending_weight ? screenData.pending_packets.total_pending_weight : '0') }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Lab Issue</div>
                        <div class="text-xl font-medium leading-8">{{ (screenData.lab_packets.total_lab_packets ? screenData.lab_packets.total_lab_packets : '0') }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600"></div>
                        <div class="text-lg font-medium leading-8">{{ (screenData.lab_packets.total_lab_weight ? $h.myFloat(screenData.lab_packets.total_lab_weight) : '0') }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Certy Stock</div>
                        <div class="text-xl font-medium leading-8">{{ (screenData.stock_packets.final_stock_packets ? screenData.stock_packets.final_stock_packets : '0') }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600"></div>
                        <div class="text-lg font-medium leading-8">{{ (screenData.stock_packets.final_stock_weight ? $h.myFloat(screenData.stock_packets.final_stock_weight) : '0') }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Total Stock</div>
                        <div class="text-xl font-medium leading-8">{{ (screenData.total_stock.packets ? screenData.total_stock.packets : '0') }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600"></div>
                        <div class="text-lg font-medium leading-8">{{ (screenData.total_stock.weight ? $h.myFloat(screenData.total_stock.weight) : '0') }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-x-2" v-if="userDetails.user_type == 26">
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Branch <br> Packets</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.total_packets }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Branch  <br> Weight</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.total_weight ? $h.myFloat(screenData.total.total_weight) + ' ct' : '0.00 ct' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average  <br> Repo</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_repo ? screenData.total.average_repo : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average <br> Rate</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_rate ? screenData.total.average_rate : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average <br> Back</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_back ? screenData.total.average_back : '0 %' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Series</label>
                    <TomSelect v-model="filter_data.series_id" :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }" class="w-full" @change="seriesChange" multiple>
                        <option value="">Select Series</option>
                        <option v-for="series in screenData.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filter_data.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full" multiple @change="kapanChange">
                        <option value="">Select Kapan</option>
                        <option v-for="kapan in screenData.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Packets</label>
                    <TomSelect v-model="filter_data.packets" :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }" class="w-full" multiple>
                        <option value="">Select Packets</option>
                        <option v-for="packet in screenData.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Packet No</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.packet_code" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Packet No" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Stock No</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Stock No" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Stone</label>
                    <TomSelect v-model="filter_data.stone" :options="{
                      placeholder: 'Select Stone',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Stone'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Stone</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Shape</label>
                    <TomSelect v-model="filter_data.shape" :options="{
                      placeholder: 'Select Shape',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Stone'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Shape</option>
                        <option v-for="(item,index) in screenData.shapes" :key="index" :value="item.id">{{ item.shape_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Color</label>
                    <TomSelect v-model="filter_data.color" :options="{
                      placeholder: 'Select Color',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Color'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Color</option>
                        <option v-for="(item,index) in screenData.colors" :key="index" :value="item.id">{{ item.shade_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Clarity</label>
                    <TomSelect v-model="filter_data.clarity" :options="{
                      placeholder: 'Select Clarity',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Clarity'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Clarity</option>
                        <option v-for="(item,index) in screenData.purities" :key="index" :value="item.id">{{ item.purity_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select LAB</label>
                    <TomSelect v-model="filter_data.lab" :options="{
                      placeholder: 'Select LAB',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'LAB'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select LAB</option>
                        <option value="IGI">IGI</option>
                        <option value="GIA">GIA</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2" v-if="userDetails.user_type != 26">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Branch</label>
                    <TomSelect v-model="filter_data.branch" :options="{
                placeholder: 'Select Branch',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Branch'
                  }
                }
              }" class="w-40">
                        <option value="0">All Branch</option>
                        <option value="1">Home Branch</option>
                        <option v-for="item in screenData.branch_arr" :key="item.id" :value="item.id">{{ item.branch_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Weight Range</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.weight_range" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="0.001-0.10" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <div class="mt-5 sm:mt-7">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary" @click="initTabulator">
                            Go
                        </button>
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                            Reset
                        </button>

                    </div>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="mt-7">
                    <div class="dropdown w-1/2 sm:w-auto">
                        <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                            <FileTextIcon class="w-4 h-4 mr-2" /> Export
                            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                        </button>
                        <div class="dropdown-menu w-40">
                            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX All
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="grid grid-cols-12 gap-x-2" v-if="userDetails.user_type != 26">
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Branch <br> Packets</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.total_packets }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Branch  <br> Weight</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.total_weight ? $h.myFloat(screenData.total.total_weight) + ' ct' : '0.00 ct' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average  <br> Repo</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_repo ? screenData.total.average_repo : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average <br> Rate</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_rate ? screenData.total.average_rate : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average <br> Back</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_back ? screenData.total.average_back : '0 %' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <div class="p-0 m-0 hidden">
        <button id="print_btn" type="button" class="btn btn-primary w-full ml-2" v-print="'#print-lab-barcode'">
            Print
        </button>
        <div id="print-lab-barcode" class="absolute top-0 left-0 p-0 m-0">
            <table class="p-0 m-0 w-full text-black" border="collapse">
                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.lab_code }}</th>
                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.pack_code }}</th>
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.cr_weight }} Ct</th>
                </tr>
                <tr class="text-center m-0 p-0">
                    <th colspan="7" class="text-center">
                        <VueBarcode :value="print_params.barcode" :options="{ displayValue: true,height:25,width:2 }"></VueBarcode>
                    </th>
                </tr>
                <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th class="border m-auto border-black">SHP</th>
                    <th class="border m-auto border-black">CL</th>
                    <th class="border m-auto border-black">PRT</th>
                    <th class="border m-auto border-black">CT</th>
                    <th class="border m-auto border-black">PL</th>
                    <th class="border m-auto border-black">SYM</th>
                    <th class="border m-auto border-black">Fl</th>
                </tr>
                <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th class="border m-auto border-black">{{ print_params.shape ? print_params.shape : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.color ? print_params.color : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.purity ? print_params.purity : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.cut ? print_params.cut : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.polish ? print_params.polish : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.symmetry ? print_params.symmetry : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.fluerocent ? print_params.fluerocent : '-' }}</th>
                </tr>
            </table>
            <p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 4px;font-weight: bold;">DK & Sons</p>
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject,
    computed
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import VueBarcode from '@chenfengyuan/vue-barcode';
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import store from '@/store'
import FileDownload from "js-file-download";

export default defineComponent({
    components: {
        VueBarcode
    },
    setup() {
        const router = useRouter()
        const userDetails = computed(() => store.state.user.currentUser)
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')
        const filter = reactive({
            field: 'lab_code',
            type: 'like',
            value: ''
        })

        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))

        const print_params = reactive({
            barcode: '046000000000',
            color: '',
            cut: '',
            purity: '',
            polish: '',
            shape: '',
            symmetry: '',
            fluerocent: '',
            pack_code: '',
            lab_code: '',
            weight: '',
            cr_weight: ''

        })

        const screenData = reactive({
            scan_lot_no: '',
            branch_id: '0',
            branch_arr: [],
            arr_list: [],
            addornot: false,
            colors: [],
            shapes: [],
            stones: [],
            purities: [],
            total: [],
            new_lab: '',
            packet_id: '',
            submit_loading: false,
            pending_packets: [],
            lab_packets: [],
            stock_packets: [],
            total_stock: {
                packets: 0,
                weight: 0
            },
            serieses: [],
            kapans: [],
            packets: [],
            server_record: []
        })

        const filter_data = reactive({
            "stock_no": "",
            "lab": "0",
            "shape": "0",
            "weight_range": "",
            "stone": "0",
            "color": "0",
            "branch": "0",
            "clarity": "0",
            "series_id": [],
            "kapan_id": [],
            "packet_id": [],
            "packet_code": '',
        })

        const excel_filter = reactive({
            "stock_no": "",
            "weight_range": "",
            "lab": "0",
            "shape": "0",
            "stone": "0",
            "branch": "0",
            "color": "0",
            "clarity": "0",
            "series_id": [],
            "kapan_id": [],
            "packet_id": [],
            "packet_code": '',
        })

        const initTabulator = () => {

            excel_filter.stock_no = filter_data.stock_no
            excel_filter.lab = filter_data.lab
            excel_filter.shape = filter_data.shape
            excel_filter.weight_range = filter_data.weight_range
            excel_filter.stone = filter_data.stone
            excel_filter.color = filter_data.color
            excel_filter.clarity = filter_data.clarity
            excel_filter.series_id = filter_data.series_id
            excel_filter.kapan_id = filter_data.kapan_id
            excel_filter.packet_id = filter_data.packet_id
            excel_filter.packet_code = filter_data.packet_code
            excel_filter.branch = filter_data.branch

            let params = {
                stock_no: excel_filter.stock_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                branch: excel_filter.branch != '0' ? window.btoa(excel_filter.branch) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                purity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                packet_code: excel_filter.packet_code,
                weight_range: excel_filter.weight_range,
                series_id: excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id: excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id: excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + `/api/get_cr_certy_stock_list`,
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [{
                        title: 'Print',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="p-0 m-0">
                  <button class="py-1 px-1">
                    <i data-feather="printer" class="w-5 h-5"></i>
                    </button>

                    </div>`)
                                cash(a).on('click', function () {

                                    print_params.barcode = cell.getData().lab_barcode
                                    print_params.color = cell.getData().color
                                    print_params.cut = cell.getData().cut
                                    print_params.purity = cell.getData().purity
                                    print_params.polish = cell.getData().polish
                                    print_params.shape = cell.getData().shape
                                    print_params.symmetry = cell.getData().symmetry
                                    print_params.fluerocent = cell.getData().fluerocent
                                    print_params.pack_code = cell.getData().pack_code
                                    print_params.lab_code = cell.getData().lab_code
                                    print_params.weight = cell.getData().weight
                                    print_params.cr_weight = cell.getData().cr_weight
                                    setTimeout(() => {
                                        $("#print_btn").click();
                                    }, 100);
                                })

                                return a[0]
                            }
                        }
                    },
                    //       {
                    //           title: 'Edit',
                    //           field: 'actions',
                    //           responsive: 1,
                    //           width: 50,
                    //           hozAlign: 'left',
                    //           vertAlign: 'middle',
                    //           print: false,
                    //           download: false,
                    //           formatter(cell) {

                    //               if (cell.getData().active_status == 1) {

                    //                   const a = cash(`<div class="p-0 m-0">
                    // <button class="py-1 px-1">
                    //   <i data-feather="edit" class="w-5 h-5"></i>
                    //   </button>

                    //   </div>`)
                    //                   cash(a).on('click', function () {

                    //                       cash('#update-lab-modal').modal('show')
                    //                       screenData.new_lab = cell.getData().lab
                    //                       screenData.packet_id = cell.getData().lab_id
                    //                   })

                    //                   return a[0]
                    //               }
                    //           }
                    //       },
                    //       {
                    //           title: 'Non Certy',
                    //           field: 'actions',
                    //           responsive: 1,
                    //           width: 50,
                    //           hozAlign: 'left',
                    //           vertAlign: 'middle',
                    //           print: false,
                    //           download: false,
                    //           formatter(cell) {

                    //               if (cell.getData().active_status == 1) {

                    //                   const a = cash(`<div class="p-0 m-0">
                    // <button class="py-1 px-1">
                    //   <i data-feather="send" class="w-5 h-5"></i>
                    //   </button>

                    //   </div>`)
                    //                   cash(a).on('click', function () {

                    //                       assignSinglePacketToNonCerty(cell.getData().lab_id)
                    //                   })

                    //                   return a[0]
                    //               }
                    //           }
                    //       },

                    // For HTML table
                    {
                        title: 'Stock No',
                        field: 'lab_code',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        minWidth: 100,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().status == 1) {
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>` + cell.getData().lab_code
                            } else {
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().lab_code
                            }
                        }
                    },

                    {
                        title: 'Packet Code',
                        field: 'packet_code',
                        minWidth: 150,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Lab',
                        field: 'lab',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Report No',
                        field: 'report_no',
                        minWidth: 150,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'S No',
                        field: 's_no',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Shape',
                        field: 'shape',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Weight',
                        field: 'final_weight',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Color',
                        field: 'color',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Clarity ',
                        field: 'purity',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Discount ',
                        field: 'discount',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Cut ',
                        field: 'cut',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Polish ',
                        field: 'polish',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Sym ',
                        field: 'symmetry',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Fl ',
                        field: 'fluerocent',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Measurement ',
                        field: 'measurement',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Table ',
                        field: 'table',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'T Depth ',
                        field: 't_depth',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Comment ',
                        field: 'comment',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Repo ($) ',
                        field: 'rapo',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Repo ($)/Ct ',
                        field: 'rapo_per_ct',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Total Price ($) ',
                        field: 'total_price',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Certificate Link ',
                        field: 'certificate_link',
                        vertAlign: 'middle',
                        minWidth: 250,
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function (e, cell) {
                            newTabUrl(cell.getData().certificate_link);
                        }
                    },
                    {
                        title: 'Video Link ',
                        field: 'video_link',
                        vertAlign: 'middle',
                        minWidth: 250,
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function (e, cell) {
                            newTabUrl(cell.getData().video_link);
                        }
                    },
                    {
                        title: 'Image Link ',
                        field: 'image_link',
                        vertAlign: 'middle',
                        minWidth: 250,
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function (e, cell) {
                            newTabUrl(cell.getData().image_link);
                        }
                    },
                    {
                        title: 'BGM',
                        field: 'bgm',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Eye Clean',
                        field: 'eye_clean',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Milky',
                        field: 'milky',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Crown Height',
                        field: 'crown_height',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Crown Angle',
                        field: 'crown_angle',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Pavilion Depth',
                        field: 'pavilion_depth',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Pavilion Angle',
                        field: 'pavilion_angle',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Culet Size',
                        field: 'culet_size',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Girdle (%)',
                        field: 'girdle_percent',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Girdle Name',
                        field: 'girdle_name',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Old Date',
                        field: 'old_date',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Current Date',
                        field: 'current_date',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Days',
                        field: 'days',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })

            let promise = axios({
                url: "/api/get_cr_certy_stock_list_total",
                method: "POST",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.total = data.total;
                })
                .catch(_error => {

                });
        }
        const getColorsDetails = () => {
            let promise = axios({
                url: "/api/dp_color",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.colors = data.items;
                })
                .catch(_error => {

                });
        }
        const getShapeDetails = () => {
            let promise = axios({
                url: "/api/dp_shape",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.shapes = data.items;
                })
                .catch(_error => {

                });
        }
        const getPurityDetails = () => {
            let promise = axios({
                url: "/api/dp_purity",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.purities = data.items;
                })
                .catch(_error => {

                });
        }
        const getSeries = () => {
            let params = {}
            let promise = axios({
                url: "api/dp_rough_series",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.serieses = data.items

                })
                .catch(_error => {

                });
        }
        const getKapans = () => {
            let params = {
                series_id: (filter_data.series_id.length > 0) ? filter_data.series_id : '',
            }
            let promise = axios({
                url: "api/get_packet_kapan_multiple",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.kapans = data.items

                })
                .catch(_error => {

                });
        }
        const getPackets = () => {
            if (filter_data.kapan_id.length > 0) {
                let params = {
                    series_id: (filter_data.series_id.length > 0) ? filter_data.series_id : '',
                    kapan_id: (filter_data.kapan_id.length > 0) ? filter_data.kapan_id : '',
                }
                let promise = axios({
                    url: "api/get_packet_packets_multiple",
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        screenData.packets = data.items

                    })
                    .catch(_error => {

                    });
            } else {
                pagedata.packets = []
                screenData.packets = []
            }
        }
        const checkServerStatus = () => {
          let promise = axios({
              url: "api/get_last_updated_time",
              method: "post",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {

                  screenData.server_record = data.items

                })
                .catch(_error => {
                screenData.server_record = []

              });
        }
        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // On reset filter
        const onResetFilter = () => {
            filter_data.stock_no = ""
            filter_data.weight_range = ""
            filter_data.lab = "0"
            filter_data.shape = "0"
            filter_data.stone = "0"
            filter_data.branch = "0"
            filter_data.color = "0"
            filter_data.clarity = "0"
            filter_data.series_id = []
            filter_data.kapan_id = []
            filter_data.packet_id = []
            filter_data.packet_code = ""
            initTabulator()
        }

        const getBranchDetails = () => {
            const params = {};
            let promise = axios({
                url: "/api/dp_account_branch_office",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.branch_arr = data.items

                })
                .catch(_error => {
                    screenData.branch_arr = []

                });
        }
        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            // const win = window
            // win.XLSX = xlsx
            // tabulator.value.download('xlsx', 'buyer_list.xlsx', {
            //     sheetName: 'Buyers'
            // })

            let filename = "certy_stock_list_all.xlsx";
            const data = {
                stock_no: excel_filter.stock_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                branch: excel_filter.branch != '0' ? window.btoa(excel_filter.branch) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                purity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                packet_code: excel_filter.packet_code,
                series_id: excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id: excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id: excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            let promise = axios({
                url: "/api/download_certy_stock_all",
                method: "get",
                params: data,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                    this.status = "success";

                    setTimeout(() => {
                        this.status = "default";
                    }, 3000);
                })
                .catch((_error) => {
                    this.status = "fail";
                    setTimeout(() => {
                        this.status = "default";
                    }, 3000);
                    return [];
                });
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        function seriesChange() {
            getParameters()
            filter_data.kapan_id = []
            filter_data.packets = []
        }

        function kapanChange() {
            getPackets()
            filter_data.packets = []
        }

        const getParameters = () => {
            getKapans()
            getPackets()
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        function newTabUrl(url) {
            window.open(url, '_blank');
        }

        function getPendingDetails() {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/api/get_certy_pending_stock",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.pending_packets = data.pending_packets;
                    calculateTotal()
                })
                .catch(_error => {

                    screenData.pending_packets = []
                    calculateTotal()
                });
        }

        function get_certy_done_stock() {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/api/get_certy_done_stock",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                  screenData.stock_packets = data.stock_packets;
                  screenData.lab_packets = data.lab_packets;
                  calculateTotal()
                })
                .catch(_error => {
                    screenData.lab_packets = []
                    screenData.stock_packets = [];
                    calculateTotal()
                });
        }

        function calculateTotal() {

            screenData.total_stock.weight =
            (screenData.pending_packets.total_pending_weight ? parseFloat(screenData.pending_packets.total_pending_weight) : 0) +
                (screenData.lab_packets.total_lab_weight ? parseFloat(screenData.lab_packets.total_lab_weight) : 0) +
                (screenData.stock_packets.final_stock_weight ? parseFloat(screenData.stock_packets.final_stock_weight) : 0)

            screenData.total_stock.packets =
            (screenData.pending_packets.total_pending_packets ? parseFloat(screenData.pending_packets.total_pending_packets) : 0) +
                (screenData.lab_packets.total_lab_packets ? parseFloat(screenData.lab_packets.total_lab_packets) : 0) +
                (screenData.stock_packets.final_stock_packets ? parseFloat(screenData.stock_packets.final_stock_packets) : 0)

                console.log(screenData.total_stock.weight)
                console.log(screenData.total_stock.packets)
        }
        onMounted(() => {
            getBranchDetails()
            initTabulator()
            getColorsDetails()
            getShapeDetails()
            getPurityDetails()
            reInitOnResizeWindow()
            get_certy_done_stock()
            getSeries()
            getKapans()
            checkServerStatus()

            if (BUILD_ENV == "factory") {
                getPendingDetails()
            }

            // if (userDetails.value.user_type == 25) {
            //     setTimeout(() => {
            //         document.querySelector('[id="scan_lot"]').focus();

            //     }, 50);
            // }
        })

        return {
            tableRef,
            userDetails,
            filter_data,
            excel_filter,
            filter,
            screenData,
            print_params,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser,
            initTabulator,
            BUILD_ENV,
            seriesChange,
            kapanChange,
            getParameters,
            getKapans,
            getPackets,
            BUILD_ENV
        }

    }
})
</script>
