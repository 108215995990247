<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Tender Bids</h2>
       <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
          type="button" 
           class="btn btn-primary shadow-md mr-2" @click="downloadPDF">
           Download Bid
          </button>
        
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Tender Name : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ tender_name }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Email : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_email }}</label
                  >
                  
                </div>
                <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Mobile No : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_number }}</label
                  >
                  
                  
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Site Address : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ address }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Start Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ start_date_display }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >End Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ end_date_display }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid Start Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_start_date_display }}</label
                  >
                  
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid End Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_end_date_display }}</label>
                  
                </div>
              </div>
            </div>
            
        
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Details</h2>
        </div>
         <div v-if="bid_placed" class="ml-5 mt-5">
                  <label for="update-profile-form-6 mt-3" class="form-label font-bold text-theme-6"
                    >Buyer have already placed bid for this tender.</label
                  >
                </div>
        <div class="border">
        <div class="grid grid-cols-12 pl-5 pr-5 pb-2">
             
              <div class="col-span-2 xl:col-span-1">
                <p>Sr. No</p>
              </div>
              <div class="col-span-2 xl:col-span-1">
                <p>Cut</p>
              </div>
              <div class="col-span-2 xl:col-span-4">
                <p>Details</p>
              </div>
              <div class="col-span-2 xl:col-span-1">
                <p>Stone</p>
              </div>
              <div class="col-span-2 xl:col-span-2">
                <p>Carats</p>
              </div>
              <div class="col-span-2 xl:col-span-2">
                <p>Bid Price($) /carat</p>
              </div>
            </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              
              <div class="col-span-2 xl:col-span-1">
                <input v-model="field.lot_no" type="text"
                    class="form-control" placeholder="Sr. no" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1">
                <input v-model="field.cut" type="text"
                    class="form-control" placeholder="Cut" readonly>
              </div>
              <div class="col-span-2 xl:col-span-4">
                <input v-model="field.details" type="text"
                    class="form-control" placeholder="Details" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1">
              <input v-model="field.piece" type="text"
                    class="form-control" placeholder="Stone" readonly>
              </div>
              <div class="col-span-2 xl:col-span-2">
              <input v-model="field.carats" type="text"
                    class="form-control" placeholder="Carats" readonly>
              </div>
              <div class="col-span-2 xl:col-span-2">
             <input v-model="field.bid_price" type="text"
                    class="form-control" placeholder="Bid Price" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3">
            
              </div>
            </div>
          </div>
       
        </div>
        <div>
        <div class="p-5">
            <div class="flex justify-end mt-4">
              <a href="" class="text-theme-6 flex items-center" v-if ="tender_update_id != ''">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Tender
              </a>
            </div>
        </div>
          </div>
          
        </div>
        
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { useTimer } from 'vue-timer-hook';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    tender_id: {
      type: String,
      default: ""
    },
     buyer_id: {
      type: String,
      default: ""
    }
  },
  
  data() {
      let time = new Date();
    let timer = useTimer(time);

    return {
      timer,
      bid_placed:false,
      tender_update_id : '',
      tender_name : '',
      contact_number: '',
      contact_email: '',
      address: '',
      start_date_display: '',
      end_date_display: '',
      bid_start_date_display: '',
      bid_end_date_display: '',
      start_date: '',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      total_items:1,
      fields: [],
     
      status: 'default',
   
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
mounted(){
  console.log("Hi"+ this.tender_id);
  this.getTenderDetails(this.tender_id);
  },

  methods: {
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id,
        buyer_id : this.buyer_id
      };
      let promise = axios({
        url: "api/buyer_tender_details_admin",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.tender_detail);
          let tender_detail = data.tender_detail;

          let time = new Date(tender_detail.bid_end_time);
          console.log(time);
          this.timer.restart(time);

          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.rough_details_arr;
          this.contact_number= tender_detail.contact_number;
            this.contact_email= tender_detail.contact_email;
            this.address = tender_detail.address;
            this.start_date = tender_detail.start_time;
            this.end_date = tender_detail.end_time;
            this.bid_start_date = tender_detail.bid_start_time;
            this.bid_end_date = tender_detail.bid_end_time;

            this.start_date_display = tender_detail.start_time_display;
            this.end_date_display = tender_detail.end_time_display;
            this.bid_start_date_display = tender_detail.bid_start_time_display;
            this.bid_end_date_display = tender_detail.bid_end_time_display;
            this.status = tender_detail.status;
            if(tender_detail.bid_placed == 'yes'){
              this.bid_placed = true;
          }else{
            this.bid_placed = false;
          }

        })
        .catch(_error => {
          
        });
    },
    downloadPDF(){
      window.open("http://bluemoonerp.com/bm_vue/apis/storage/app/tenderPdf/DK.TENDER21.3.pdf");
    }
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>