<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">{{ this.$route.params.id == '' ? 'Merge Stock' : 'Update Stock' }}</h2>
    </div>

    <div class="grid grid-cols-12">

        <div class="intro-y col-span-12 mt-5 lg:col-span-12" v-if="this.$route.params.id != ''">
            <div class="intro-y box">

                <div class="grid grid-cols-12 gap-x-2 border-b p-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ transaction_head.transaction_code ? transaction_head.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ transaction_head.stone_type ? transaction_head.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Shape. :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ transaction_head.shape_type ? transaction_head.shape_type : 'All' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="transaction_head.s_date">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish Start Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_head.s_date }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="transaction_head.e_date">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish End Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_head.e_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_head.merge_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="transaction_head.series_details && transaction_head.series_details.length > 0">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Series : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in transaction_head.series_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="transaction_head.kapan_details && transaction_head.kapan_details.length > 0">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Kapans : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in transaction_head.kapan_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <!-- </div>
    <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Series/ Kapan Details</h2>
                    <div class="mt-3 mr-3">
                        <label for="update-profile-form-6" class="form-label font-bold">Total Ready Stone : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone }}</label>

                    </div>
                    <div class="mt-3">
                        <label for="update-profile-form-6" class="form-label font-bold">Total Ready Stone (ct) : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_ct }}</label>
                    </div>
                </div>
                <div class="p-5">
                    <div class="grid grid-cols-12 p-1 text-center gap-2" v-if="kapan_details_arr">
                        <div class="col-span-6 md:col-span-4 xl:col-span-3 border w-full py-1 m-0" v-for="(item,index) in kapan_details_arr" :key="index">
                            <div class="px-2">

                                <table class="w-full">

                                    <tr>
                                        <td class="w-40 text-left">
                                            {{ item.kapan_code }}
                                        </td>
                                        <td class="w-36 text-center">
                                            {{ item.pieces }}
                                        </td>
                                        <td class="w-36 text-right">
                                            {{ $h.myFloat(item.weight) }} Ct
                                        </td>
                                        <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- END: Input -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-12 mt-5">
            <!-- BEGIN: Input -->
            <div class="intro-y box">

                <div class="p-5">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Stock Details</h2>
                        <div class="ml-5 flex">

                            <div class="py-1 flex items-center">
                                <label for="update-profile-form-7" class="form-label px-2">Select Stone</label>
                                <TomSelect v-model="form_data.stone_type" @change="get_lot_details_update" :options="{
                    placeholder: 'Select Stone',
                    }" class="w-36">
                                    <option value="">Select Stone</option>
                                    <option value="1" v-if="form_data.stone_type == 1">Natural</option>
                                    <option value="2" v-if="form_data.stone_type == 2 || form_data.stone_type == 3">CVD</option>
                                    <option value="3" v-if="form_data.stone_type == 2 || form_data.stone_type == 3">HPHT</option>
                                    <option value="4" v-if="form_data.stone_type == 4 || form_data.stone_type == 5">D_CVD</option>
                                    <option value="5" v-if="form_data.stone_type == 4 || form_data.stone_type == 5">D_HPHT</option>
                                </TomSelect>
                                </div>
                            <div class="py-1 flex items-center">
                                <label for="update-profile-form-7" class="form-label px-2">Select Color</label>
                                <TomSelect v-model="form_data.color_size" :options="{
                            placeholder: 'Select Color',
                            }" class="w-36">
                                    <option value="">Select Color</option>
                                    <option v-for="(item,index) in size_purity_arr" :key="index" :value="item.color_size">{{ item.color_name }}</option>
                                  </TomSelect>
                                </div>
                            <div class="py-1 flex items-center">
                                <label for="update-profile-form-7" class="form-label px-2">Shape Type</label>
                                <TomSelect v-model="form_data.shape_type" :options="{
                                  placeholder: 'Select Shape',
                                }" class="w-36">
                                    <option value="">Select Shape</option>
                                    <option v-for="(item,index) in shape_arr" :key="index" :value="item.id">{{ item.shape_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="border mt-5">
                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center text-xs">
                                <thead>
                                    <template v-for="(size_item,index) in size_purity_arr" :key="index">
                                        <template v-if="size_item.color_size == form_data.color_size">
                                            <tr class="bg-theme-14" v-if="size_item.purity_items.length > 0">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Purity</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">All</th>
                                                <template v-for="(item,inx) in size_item.purity_items" :key="inx">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">{{ item.purity }}</th>
                                                </template>
                                            </tr>
                                            <tr class="bg-theme-14" v-if="size_item.purity_items.length > 0">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2"></th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">Total</th>
                                                <template v-for="(item,inx) in size_item.purity_items" :key="inx">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">{{ item.segment_name }}</th>
                                                </template>
                                            </tr>
                                            <tr class="bg-theme-14">
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Stock(Ct)</th>
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Pcs</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Rate</th>
                                                <template v-for="(item,inx) in size_item.purity_items" :key="inx">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock(Ct) </th>
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS </th>
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Rate </th>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-for="(size_item,shape_index) in fields" :key="shape_index">
                                        <template v-if="size_item.color_size == form_data.color_size && size_item.shape_type == form_data.shape_type">

                                            <tr class="bg-gray-200 text-center">
                                                <td class="border-b py-2 px-2 border-r" colspan="2">Total</td>
                                                <td class="border-b py-2 px-2">{{ size_item.total.charni_total_weight ? $h.myFloat(size_item.total.charni_total_weight) : "-" }}</td>
                                                <td class="border-b py-2 px-2">{{ size_item.total.charni_total_pieces ? size_item.total.charni_total_pieces : "-" }}</td>
                                                <td class="border-b py-2 px-2 border-r">{{ size_item.total.charni_total_bhav ? size_item.total.charni_total_bhav : "-" }}</td>
                                                <template v-for="(item,inx) in size_item.total.total_by_sizes" :key="inx">
                                                    <td class="border-b py-2 px-2">{{ item.size_weight ? $h.myFloat(item.size_weight) : "-" }}</td>
                                                    <td class="border-b py-2 px-2">{{ item.size_piece ? item.size_piece : "-" }}</td>
                                                    <td class="border-b py-2 px-2 border-r">{{ item.size_rate ? item.size_rate : "-" }}</td>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                </thead>
                                <tbody class="text-center" v-for="(size_item,shape_index) in fields" :key="shape_index">
                                    <template v-if="size_item.color_size == form_data.color_size && size_item.shape_type == form_data.shape_type">
                                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in size_item['charni_data']" :key="index">
                                            <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                            <td class="border-b py-2 px-2 border-r">{{ field.charni_name }}</td>
                                            <td class="border-b py-2 px-2">{{ field.charni_total_weight ? $h.myFloat(field.charni_total_weight) : "-" }}</td>
                                            <td class="border-b py-2 px-2">{{ field.charni_total_pieces ? field.charni_total_pieces : "-" }}</td>
                                            <td class="border-b py-2 px-2 border-r">{{ field.charni_total_bhav ? field.charni_total_bhav : "-" }}</td>
                                            <template v-for="(item,inx) in field.sub_charni_arr" :key="inx">
                                                <td class="border-b py-2 px-2">
                                                    <input v-model="item.size_weight" type="text" class="form-control w-24" placeholder="weight" @keypress="$h.preventInvalidInput" @input="calculateTotal(field)">
                                                </td>
                                                <td class="border-b py-2 px-2">
                                                    <input v-model="item.size_piece" type="text" class="form-control w-24" placeholder="pieces" @keypress="$h.preventInvalidInput" @input="calculateTotalPieces(field)">
                                                </td>
                                                <td class="border-b py-2 px-2 border-r">
                                                    <input v-model="item.stock_rate" type="text" class="form-control w-24" placeholder="Rate" @keypress="$h.preventInvalidInput" @input="calculateTotal(field)">
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="data_loading == true" class="text-md font-medium mr-auto text-center my-8">
                            Loading
                        </div>
                        <div v-if="fields.length == 0 && data_loading == false" class="text-md font-medium mr-auto text-center my-8">
                            Records Not Found
                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-2 xl:col-span-7">

                            </div>
                            <div class="col-span-2 xl:col-span-3 text-right">
                                <p class="font-bold">Total Added Carat (Ct) : </p>
                            </div>
                            <div class="col-span-2 xl:col-span-2 text-right" :class="{'text-theme-6': ((total_added_ct > total_ready_stone_ct) || (total_added_pieces > total_ready_stone))}">
                                <p>{{total_added_pieces}} ({{ total_added_ct ? $h.myFloat(total_added_ct) + " Ct" : "0 Ct" }})</p>
                            </div>

                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-2 xl:col-span-7">

                            </div>
                            <div class="col-span-2 xl:col-span-3 text-right">
                                <p class="font-bold">Est Stock Bhav : </p>
                            </div>
                            <div class="col-span-2 xl:col-span-2 text-right">
                                <p>{{total_added_bhav}}</p>
                            </div>

                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-2 xl:col-span-7">
                            </div>
                            <div class="col-span-2 xl:col-span-3 text-right">
                                <p class="font-bold">Total Avaialbe(Ct) : </p>
                            </div>
                            <div class="col-span-2 xl:col-span-2 text-right">
                                <p>{{total_ready_stone}} ({{ total_ready_stone_ct ? $h.myFloat(total_ready_stone_ct) + " Ct" : "0 Ct" }})</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-5">
                    <div>
                        <div class="flex justify-end mt-4">

                            <button class="btn btn-primary w-20 mr-auto" :disabled="form_status == true || data_loading == true" @click="onUserFormSubmit">
                                Save
                                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="form_status == false" />
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    props: {},

    data() {
        return {
            total_rough_carat: 0,
            kapan_details_arr: [],
            total_ready_stone: 0,
            total_ready_stone_ct: 0,
            total_ready_stone_certi: 0,
            total_ready_stone_ct_certi: 0,
            total_ready_stone_failed: 0,
            total_ready_stone_ct_failed: 0,
            total_ready_stone_lost: 0,
            total_ready_stone_ct_lost: 0,
            total_added_ct: 0,
            total_added_bhav: 0,
            total_added_pieces: 0,

            status: 'default',
            loginVal: '',
            req: axios.create({
                baseUrl: BASE_URL
            }),

            form_data: {
                color_size: "",
                shape_type: "1",
                stone_type: "",
            },

            kapan_arr: [],
            series_arr: [],
            shape_arr: [],
            size_purity_arr: [],
            form_status: false,
            data_loading: false,
            fields: [],
            transaction_head: [],
        }
    },
    computed: {

    },
    mounted() {
        this.get_lot_details_update()

    },

    methods: {
        alertVal() {
            alert(this.dob)
        },
        onUserFormSubmit() {

            //console.log(JSON.stringify(this.gridForm));
            this.errors = [];

            if (!this.errors.length) {

                this.$swal({
                    title: "Are you sure?",
                    text: "You Want to Update Merge Stock!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Accept",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        const data = {
                            "total_ready_stone_ct": this.total_ready_stone_ct,
                            "total_added_ct": this.total_added_ct,
                            "total_added_pieces": this.total_added_pieces,
                            "color_arr": this.fields,
                            "stone_type": this.form_data.stone_type,
                            "id": this.$route.params.id,

                        };
                        let new_url = "api/merge_lot_details_update"

                        this.form_status = true

                        let promise = axios({
                            url: new_url,
                            method: "POST",
                            data: data,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });

                        return promise
                            .then(result => result.data)
                            .then(data => {

                              this.form_status = false
                                if (data.error == "YES") {
                                    this.$swal("Error!", data.message, "error");
                                    return;
                                }
                                this.$router.push('/app/app-polish-ready/ready/merge/' + this.$route.params.id);

                                this.$swal.fire({
                                    icon: 'success',
                                    title: "Success!",
                                    text: data.message,
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                            .catch(_error => {
                                this.form_status = false
                                if (data.error == "YES") {
                                    this.$swal("Error!", "Something went Wrong", "error");

                                    return;
                                }

                            });
                    }
                })
            }
        },
        calculateTotal(item) {

            item.charni_total_weight = 0
            item.charni_total_bhav = 0
            item.sub_charni_arr.forEach(element => {
                element.size_rate = parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0)
                item.charni_total_weight = $h.myFloat(parseFloat(item.charni_total_weight) + (element.size_weight ? parseFloat(element.size_weight) : 0))
                item.charni_total_bhav = $h.myFloat(parseFloat(item.charni_total_bhav) + (element.size_rate ? parseFloat(element.size_rate) : 0))

            });

            this.calculateSizeTotal()

        },
        calculateTotalPieces(item) {
        item.charni_total_pieces = 0
        item.sub_charni_arr.forEach(element => {
            item.charni_total_pieces = $h.myFloat(parseFloat(item.charni_total_pieces) + (element.size_piece ? parseFloat(element.size_piece) : 0))

        });
        this.total_added_pieces = 0
        this.fields.forEach(element_f => {

            let t_total_pcs = 0

            element_f.total.total_by_sizes.forEach(element_s => {

                let s_total_pcs = 0

                element_f.charni_data.forEach(element_g => {

                    element_g.sub_charni_arr.forEach(element => {

                        if (element.size_type == element_s.size_type) {

                            s_total_pcs = $h.myFloat(s_total_pcs + (element.size_piece ? parseFloat(element.size_piece) : 0))
                            t_total_pcs = $h.myFloat(t_total_pcs + (element.size_piece ? parseFloat(element.size_piece) : 0))
                        }
                    });

                });

                element_s.size_piece = s_total_pcs
            });

            element_f.total.charni_total_pieces = t_total_pcs
            this.total_added_pieces += t_total_pcs
        });
        },
        calculateTotalPiecesAll() {
            this.total_added_pieces = 0
            this.fields.forEach(element_f => {

              if(element_f.charni_data.length > 0) {

                let t_total_pcs = 0

                element_f.total.total_by_sizes.forEach(element_s => {

                    let s_total_pcs = 0

                    element_f.charni_data.forEach(element_g => {

                      element_g.charni_total_weight = 0
                      element_g.charni_total_bhav = 0

                        element_g.sub_charni_arr.forEach(element => {


                          element.size_rate = parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0)
                          element_g.charni_total_weight = $h.myFloat(parseFloat(element_g.charni_total_weight) + (element.size_weight ? parseFloat(element.size_weight) : 0))
                          element_g.charni_total_bhav = $h.myFloat(parseFloat(element_g.charni_total_bhav) + (element.size_rate ? parseFloat(element.size_rate) : 0))

                            if (element.size_type == element_s.size_type) {

                                s_total_pcs = $h.myFloat(s_total_pcs + (element.size_piece ? parseFloat(element.size_piece) : 0))
                                t_total_pcs = $h.myFloat(t_total_pcs + (element.size_piece ? parseFloat(element.size_piece) : 0))
                            }

                        });

                    });

                    element_s.size_piece = s_total_pcs
                });

                element_f.total.charni_total_pieces = t_total_pcs
                this.total_added_pieces += t_total_pcs

              }
            });
        },
        calculateSizeTotal() {

          this.total_added_ct = 0

          this.fields.forEach(element_f => {

              let t_total_wt = 0
              let t_total_rate = 0

              element_f.total.total_by_sizes.forEach(element_s => {

                  let s_total_wt = 0
                  let s_total_rate = 0

                  element_f.charni_data.forEach(element_g => {

                      element_g.sub_charni_arr.forEach(element => {

                          if (element.size_type == element_s.size_type) {

                              s_total_wt = $h.myFloat(s_total_wt + (element.size_weight ? parseFloat(element.size_weight) : 0))
                              s_total_rate = $h.myFloat(s_total_rate + parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0))
                              t_total_wt = $h.myFloat(t_total_wt + (element.size_weight ? parseFloat(element.size_weight) : 0))
                              t_total_rate = $h.myFloat(t_total_rate + parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0))
                          }
                      });

                  });

                  element_s.size_weight = s_total_wt
                  element_s.size_rate = $h.myFloat(s_total_rate / s_total_wt)
              });

              element_f.total.charni_total_weight = t_total_wt
              this.total_added_ct = $h.myFloat(this.total_added_ct + t_total_wt)
              element_f.total.charni_total_bhav = $h.myFloat(t_total_rate / t_total_wt)
          });

        },
        async getSizePurityDetails() {
            let _this = this;
            const params = {
                stone_type: (this.transaction_head.stone_id)
            };
            let promise = axios({
                url: "api/list_all_merge_segment_all_color",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.size_purity_arr = data.items;
                    this.shape_arr = data.shape_items;
                    this.form_data.color_size = String(this.size_purity_arr[0].color_size)
                  })
                  .catch(_error => {
                    this.size_purity_arr = [];
                    this.shape_arr = [];
                    this.form_data.color_size = ""

                });
        },
        async get_lot_details_update() {

            this.data_loading = true
            this.fields = []
            this.kapan_details_arr = []
            this.total_ready_stone = 0;
            this.total_ready_stone_ct = 0

            let params = {
                "id": this.$route.params.id,
                "stone_type": this.form_data.stone_type ? window.btoa(this.form_data.stone_type) : ''
            }

            let api_url = "api/get_lot_details_merge_pending_update"

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                    if (data.error == "YES") {
                        this.kapan_details_arr = []
                        this.transaction_head = []
                        this.fields = []
                        this.total_ready_stone = 0
                        this.total_ready_stone_ct = 0
                        this.data_loading = false
                        this.data_loading = false

                        this.$swal("Warning", data.message, "warning");

                        return
                      }
                      this.kapan_details_arr = data.kapan_details
                      this.fields = data.packet_details
                      this.total_ready_stone = data.total_weights.packets
                      this.transaction_head = data.transaction_item

                      if(!this.form_data.stone_type) {
                        this.form_data.stone_type = String(data.transaction_item.stone_id)
                      }
                      this.total_ready_stone_ct = data.total_weights.weight
                      this.data_loading = false
                    this.getSizePurityDetails()

                    setTimeout(() => {

                      this.calculateSizeTotal()
                      this.calculateTotalPiecesAll()
                    }, 500);

                    // this.fields.forEach(element => {
                    //   this.calculateTotal(element)
                    //   this.calculateTotalPieces(element)
                    // });
                })
                .catch((_error) => {
                    this.kapan_details_arr = []
                    this.fields = []
                    this.transaction_head = []
                    this.total_ready_stone = 0
                    this.total_ready_stone_ct = 0
                    this.data_loading = false
                });

        },
    },

})
</script>
<style scoped>
/* .border {
  margin: 0 20px;
  padding: 3px;
} */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 500px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
