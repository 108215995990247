<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Bonus Rate</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rate Details</h2>
            
          </div>
         <form @submit.prevent="onUserFormSubmit">
          
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            
        </div>
        <div class="border">
          <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;">
              
               
                <div class="col-span-6 md:col-span-3 xl:col-span-3">
                  <p>Price From</p>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-3">
                  <p>Price To</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Bonus(%)</p>
                </div>
                
          </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
              
              <div class="grid grid-cols-12">
                
                <div class="col-span-6 md:col-span-3 xl:col-span-3">
                  <input v-model="field.price_from" type="text"
                  
                      class="form-control" placeholder="From" @keypress="$h.preventInvalidInput" onpaste="return false;">
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-3">
                  <input v-model="field.price_to" type="text"
                  
                      class="form-control" placeholder="To" @keypress="$h.preventInvalidInput" onpaste="return false;">
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.bonus_per" type="text"
                
                      class="form-control" placeholder="%" @keypress="$h.preventInvalidInput" onpaste="return false;" >
                </div>
                
                <div class="flex">
                  <button type="button" class="text-theme-6 flex items-center" >
                  <Trash2Icon class="w-4 h-4 mr-1" @click="removeField(field.id)"/> 
                </button>
              
                </div>
                
              
              </div>
          </div>
          
          <button type="button" @click="AddField" class="btn btn-primary ml-5 mt-5 mr-auto">
            New Item
          </button>
        
        </div>

        <div class="p-5">
            <div class="flex justify-end mt-4">
             
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>
             
            </div>
        </div>
        
        
          
          </form>
        </div>
        </div>
        
        <!-- END: Input -->
    </div>
      
    </div>
  
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  
  
  data() {
    return {
     
      total_items:1,
      auction_status : 'no',
      fields: [{ id:1,price_from: '',price_to: '',bonus_per: '' }],
      url:'',
      status: 'default',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){

  this.getBonusDetails();
  },

  methods: {
    alertVal () {
      alert(this.dob)
    },
    onUserFormSubmit() {
      //console.log(this.fields);
      
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      
      if (!this.errors.length) {
        const data = {
          rate_details:this.fields,
          
          td:this.tender_id
        };

        if(this.tender_id != ''){
          this.url = "api/update_bonus_rate"
        }else{
          this.url = "api/update_bonus_rate"
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {
            this.$notify(
              "success",
              "Record Added",
              "New User Added Successfully",
              {
                duration: 2000,
                permanent: false
              },
              setTimeout(() => {
                this.$router.push("/app/app-masters");
              }, 500)
            );
          })
          .catch(response => {
           
          });
      }
    },
    AddField() {
      this.total_items++;
      this.fields.push({ id:this.total_items,lot_no: '',cut: '',details: '',piece: '',carats: '',expect_price: '',attachment:'',attachment_link:'' });
      console.log(this.total_items);
    },
    removeField(id) {
     
       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields.splice(i,1);
              }
            }
          }
      });
    },
    addLinkField(id){
      console.log(id);
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields[i]['attachment'] = 'yes';
              }
            }
    },
    removeLinkField(id){
      console.log(id + "here");
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields[i]['attachment'] = '';
                  this.fields[i]['attachment_link'] = '';
              }
            }
    },
    getBonusDetails() {
      let _this = this;
     
     let params = {

     }
      let promise = axios({
        url: "api/get_bonus_rate",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.fields = data.details.bonus_json;
        })
        .catch(_error => {
          
        });
    },
   
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>