<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Broker Account (Purchase)</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button v-if="vendor_id != ''" type="button" class="btn btn-primary shadow-md mr-2" @click="resetUser">
                <KeyIcon class="w-4 h-4 mr-1" />
                Reset & Resend Password
            </button>
            <button v-if="vendor_id != '' && status == 1" type="button" :class="{'btn shadow-md mr-2': true,
          'btn-danger ' : status == 1}" @click="changeStatus">
                <LockIcon class="w-4 h-4 mr-1" /> Deactivate
            </button>
            <button v-if="vendor_id != '' && status == 0" type="button" :class="{'btn shadow-md mr-2': true,
          'btn-success ' : status == 0}" @click="changeStatus">
                <UnlockIcon class="w-4 h-4 mr-1" /> Activate
            </button>
            <button v-if="vendor_id != ''" type="button" class="btn btn-danger shadow-md mr-2" @click="deleteUser">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
            </button>

        </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Broker Details</h2>
                </div>
                <form @submit.prevent="onUserFormSubmit">
                    <div class="p-5">
                        <div class="grid grid-cols-12 gap-x-5">
                            <div class="col-span-12 md:col-span-6">
                                <div>
                                    <label for="first_name" class="form-label">First Name <span class="text-theme-6">*</span></label>
                                    <input id="first_name" type="text" class="form-control" placeholder="First name" v-model="first_name" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="middle_name" class="form-label">Middle Name </label>
                                    <input id="middle_name" type="text" class="form-control" placeholder="Middle name" v-model="middle_name" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="last_name" class="form-label">Last Name <span class="text-theme-6">*</span></label>
                                    <input id="last_name" type="text" class="form-control" placeholder="Last name" v-model="last_name" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="contact_email" class="form-label">Personal Email <span class="text-theme-6">*</span></label>
                                    <input id="contact_email" type="email" class="form-control" placeholder="example@domain.com" v-model="contact_email" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="contact_mobile_no" class="form-label">Mobile No <span class="text-theme-6">*</span></label>
                                    <input id="contact_mobile_no" type="text" class="form-control" placeholder="+00 00000 00000" v-model="contact_mobile_no" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="contact_mobile_no" class="form-label">Select Branch <span class="text-theme-6">*</span></label>
                                    <TomSelect v-model="branch_id" :options="{
                  placeholder: 'Select Branch',
                }" class="w-full">
                                        <option value="0">All</option>
                                        <option value="1">Home Branch</option>
                                        <option v-for="branch_item in branch_arr" :key="branch_item.id" :value="branch_item.id">{{branch_item.branch_name}} {{branch_item.branch_code ? ' (' + branch_item.branch_code+ ') ' : '' }}</option>
                                    </TomSelect>
                                </div>
                                <!-- Add Branch ID -->
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <div>
                                    <label for="company_name" class="form-label">Company(Org.) Name <span class="text-theme-6">*</span></label>
                                    <input id="company_name" type="text" class="form-control" placeholder="" v-model="company_name" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="company_email" class="form-label">Company Email <span class="text-theme-6">*</span></label>
                                    <input id="company_email" type="email" :disabled="vendor_id != ''" class="form-control" placeholder="example@domain.com" v-model="company_email" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="company_contact_no" class="form-label">Phone No <span class="text-theme-6">*</span></label>
                                    <input id="company_contact_no" type="text" class="form-control" :disabled="vendor_id != ''" placeholder="+00 00000 00000" v-model="company_contact_no" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="brokerage_value" class="form-label">Brokerage (%)</label>
                                    <input id="brokerage_value" type="text" class="form-control" placeholder="Brokerage IN (%)" v-model="brokerage_value" autocomplete="off" />
                                </div>
                                <div class="mt-3">
                                    <label for="gst_no" class="form-label">GST No</label>
                                    <input id="gst_no" type="text" class="form-control" placeholder="" v-model="gst_no" />
                                </div>
                                <div class="mt-3">
                                    <label for="pan_no" class="form-label">PAN No</label>
                                    <input id="pan_no" type="text" class="form-control" placeholder="" v-model="pan_no" />
                                </div>
                            </div>

                        </div>
                        <div class="flex items-center justify-between mt-2">
                            <div>

                            </div>
                            <div>

                                <button type="button" @click="cancelPurchase" class="btn btn-outline-secondary w-32 mr-1">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary w-32" :disabled="form_sumit">
                                    Save Details
                                    <LoadingIcon v-if="form_sumit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div id="success-notification-content" class="toastify-content hidden flex">
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">User Created Successfuly!</div>
                    <div class="text-gray-600 mt-1">
                        User added to user list.
                    </div>
                </div>
            </div>
            <div id="success-notification-content-update" class="toastify-content hidden flex">
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">User Updated Successfuly!</div>
                </div>
            </div>
            <div id="failed-notification-content" class="toastify-content hidden flex">
                <InfoIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Validation Error!</div>
                    <div class="text-gray-600 mt-1">
                        Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
                    </div>
                </div>

                <div id="failed-user-notification-content" class="toastify-content hidden flex">
                    <InfoIcon class="text-theme-6" />
                    <div class="ml-4 mr-4">
                        <div class="font-medium">User Creation Error!</div>
                        <div class="text-gray-600 mt-1">
                            Something went wrong. Kindly Refreash the page and try again.
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    onMounted
} from 'vue'
import {
    mapGetters,
    mapActions
} from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'

export default defineComponent({
    components: {
        DarkModeSwitcher,

    },
    props: {
        user_id: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            vendor_id: '',
            branch_arr: [],
            form_sumit: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            contact_email: "",
            contact_mobile_no: "",
            // whatsapp_no:"",
            company_name: "",
            company_email: "",
            company_contact_no: "",
            // broker_name:"",
            // broker_mobile_no:"",
            // ref_1_name:"",
            // ref_2_name:"",
            // ref_1_contact:"",
            // ref_2_contact:"",
            // ref_1_email:"",
            // ref_2_email:"",
            // broker_email:"",
            brokerage_value: "",
            branch_id: "0",
            gst_no: "",
            pan_no: "",
            status: '',
            email: '',
            password: '',
            loginVal: '',
            url: '',
            url_new: '',
            AUTH_TOKEN: (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token")),
            req: axios.create({
                baseUrl: BASE_URL
            })
        }
    },
    mounted() {
      this.getBranchList()
        if (this.user_id != '') {
            this.vendor_id = this.user_id;
            this.getuserDetails(this.user_id);
        }

    },

    methods: {

        formSubmit() {
            this.status = 'processing'
            console.log("HI");

        },
        onUserFormSubmit() {

            let is_empty = false

            if (this.company_name == "" || this.company_name == null) {
                $('#company_name').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#company_name').removeClass('border-theme-6')
            }
            if (this.company_email == "" || this.company_email == null) {
                $('#company_email').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#company_email').removeClass('border-theme-6')
            }
            if (this.company_contact_no == "" || this.company_contact_no == null) {
                $('#company_contact_no').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#company_contact_no').removeClass('border-theme-6')
            }
            if (this.first_name == "" || this.first_name == null) {
                $('#first_name').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#first_name').removeClass('border-theme-6')
            }
            if (this.last_name == "" || this.last_name == null) {
                $('#last_name').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#last_name').removeClass('border-theme-6')
            }
            if (this.contact_email == "" || this.contact_email == null) {
                $('#contact_email').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#contact_email').removeClass('border-theme-6')
            }
            if (this.contact_mobile_no == "" || this.contact_mobile_no == null) {
                $('#contact_mobile_no').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#contact_mobile_no').removeClass('border-theme-6')
            }
            // if (this.brokerage_value == "" || this.brokerage_value == null) {
            //     $('#brokerage_value').addClass('border-theme-6')
            //     is_empty = true

            // } else {
            //     $('#brokerage_value').removeClass('border-theme-6')
            // }

            if (is_empty == true) {
                this.$swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fileds Are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }

            const params = {
                first_name: this.first_name,
                last_name: this.last_name,
                middle_name: this.middle_name,
                contact_email: this.contact_email,
                contact_mobile_no: this.contact_mobile_no,
                company_name: this.company_name,
                company_email: this.company_email,
                company_contact_no: this.company_contact_no,
                // whatsapp_no:this.whatsapp_no,
                // broker_name:this.broker_name,
                // broker_mobile_no:this.broker_mobile_no,
                gst_no: this.gst_no,
                pan_no: this.pan_no,
                brokerage_value: this.brokerage_value,
                branch_id: (this.branch_id != "0" ? this.branch_id : ""),
                // ref_1_name : this.ref_1_name,
                // ref_2_name : this.ref_2_name,
                // ref_1_contact : this.ref_1_contact,
                // ref_2_contact : this.ref_2_contact,
                // ref_1_email : this.ref_1_email,
                // ref_2_email : this.ref_2_email,
                // broker_email : this.broker_email,
                ud: this.user_id
            };

            this.form_sumit = true
            if (this.user_id == "") {
                this.url = "api/add_account_purchase_broker";
            } else {
                this.url = "api/edit_account_purchase_broker";
            }
            let promise = axios({
                url: this.url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            })

            return promise
                .then(result => result.data)
                .then(data => {
                    if (data.error == "YES") {
                        this.$swal("Warning!", data.message, "warning")
                        this.form_sumit = false
                        return;
                    }
                    if (this.user_id == "") {
                this.new_url = "/add_account_purchase_broker";
            } else {
                this.new_url = "/edit_account_purchase_broker";
            }

                    let promise = axios({
                url: this.new_url,
                method: "post",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            })

            return promise
                .then(result => result.data)
                .then(data => {
                    if (data.error == "YES") {
                      this.$swal("Warning!", data.message + ", On Server", "warning")
                      this.$router.push("/purchase/broker-master");
                        this.form_sumit = false
                        return;
                    }

                    this.form_sumit = false
                    this.$swal("Success!", data.message, "success")
                    this.$router.push("/purchase/broker-master");
                    return
                  })
                  .catch(_error => {
                    this.$swal("Error!", "Data not Saved" + ", On Server", "error")
                    this.$router.push("/purchase/broker-master");
                    this.form_sumit = false
                });
                })
                .catch(_error => {
                    this.$swal("Error!", "Something went wrong.", "error")
                    this.form_sumit = false
                });
        },
        getuserDetails(user_id) {
            const params = {
                ud: user_id
            };
            let promise = axios({
                url: "api/details_account_purchase_broker",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    console.log(data.user_details);
                    let user_details = data.user_details;

                    this.first_name = user_details.first_name;
                    this.last_name = user_details.last_name;
                    this.middle_name = user_details.middle_name;
                    this.contact_email = user_details.contact_person_email;
                    this.contact_mobile_no = user_details.contact_person_mobile;
                    this.company_name = user_details.company_name;
                    this.whatsapp_no = user_details.whatsapp_no;
                    this.company_email = user_details.company_email;
                    this.company_contact_no = user_details.company_contact_no;
                    this.gst_no = user_details.gst_no;
                    this.pan_no = user_details.pan_no;
                    this.status = user_details.active_status;
                    if (user_details.sellable == 1) {
                        this.sellable = true;
                    } else {
                        this.sellable = false;
                    }

                })
                .catch(_error => {

                });
        },
        getBranchList() {
            const params = {
            };
            let promise = axios({
                url: "api/dp_account_branch_office",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    this.branch_arr = data.items
                  })
                  .catch(_error => {

                    this.branch_arr = []
                });
        },
        deleteUser() {

            this.$swal({
                title: "Are you sure?",
                text: "You are about to delete a User!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const params = {
                        user_id: this.user_id
                    };
                    let promise = axios({
                        url: "api/delete_account_purchase_broker",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.error == "YES") {
                                this.$swal("Warning!", data.message, "warning")
                                return;
                            }

                            let promise = axios({
                        url: "/delete_account_purchase_broker",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL_SELLING,
                        headers: {
                            Authorization: "Bearer " + this.AUTH_TOKEN
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.error == "YES") {
                                this.$swal("Warning!", data.message + ", On Server", "warning")
                                return;
                            }

                            this.$swal("Success!", data.message, "success")

                            this.$router.push("/purchase/broker-master");
                        })
                        .catch(_error => {
                            this.$swal("Error!", "Something went wrong" + ", On Server", "error")
                        });
                        })
                        .catch(_error => {
                            this.$swal("Error!", "Something went wrong.", "error")
                        });

                }
            });

        },
        resetUser() {

            this.$swal({
                title: "Are you sure?",
                text: "You are about to reset User Password!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Reset",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const params = {
                        user_id: this.user_id
                    };
                    let promise = axios({
                        url: "api/reset_broker_purchase_user",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.error == "YES") {
                                this.$swal("Warning!", data.message, "warning")
                                return;
                            }

                            let promise = axios({
                        url: "/reset_broker_purchase_user",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL_SELLING,
                        headers: {
                            Authorization: "Bearer " + this.Auth_Token
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.error == "YES") {
                                this.$swal("Warning!", data.message + ", On Server", "warning")
                                return;
                            }

                            this.$swal("Success!", data.message, "success")
                            this.getuserDetails(this.user_id);
                        })
                        .catch(_error => {
                            this.$swal("Error!", "Something went wrong" + ", On Server", "error")
                        });
                        })
                        .catch(_error => {
                            this.$swal("Error!", "Something went wrong.", "error")
                        });

                }
            });
        },
        changeStatus() {

            let _this = this;
            console.log(this.user_id);
            console.log(this.status);
            this.$swal({
                title: "Are you sure?",
                text: "You are about to change user status!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const params = {
                        user_id: _this.user_id
                    };
                    let promise = axios({
                        url: "api/change_status_account_purchase_broker",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.error == "YES") {
                                _this.$swal("Warning!", data.message, "warning")
                                return;
                            }

                            let promise = axios({
                        url: "/change_status_account_purchase_broker",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL_SELLING,
                        headers: {
                            Authorization: "Bearer " + this.AUTH_TOKEN
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.error == "YES") {
                                _this.$swal("Warning!", data.message + ", On Server", "warning")
                                return;
                            }

                            _this.$swal("Success!", data.message, "success")
                            _this.getuserDetails(_this.user_id);
                        })
                        .catch(_error => {
                            _this.$swal("Error!", "Something went wrong" + ", On Server", "error")
                        });
                        })
                        .catch(_error => {
                            _this.$swal("Error!", "Something went wrong.", "error")
                        });

                }
            });
        },
        cancelPurchase() {
            this.$router.go(-1)
        }
    },

})
</script>
