<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Charni Master (Polish Selling)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

          <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal"
          @click="resetModalFileds"
          class="btn btn-primary shadow-md mr-2"
          >New Charni</a
        >

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                  >Field</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filter.field"
                  class="form-select"
                >
                  <option value="charni_name">Charni Name</option>
                  <option value="charni_code">Code</option>
                  <option value="charni_type">Type</option>
                </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-fullo"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2">Value</label>
                <input
                  id="tabulator-html-filter-value"
                  v-model="filter.value"
                  type="text"
                  class="form-control sm:w-40"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

              <select
                v-model="screenData.selected_stone"
                @change="shade_size_item_details"
                class="form-select w-full"
              >
                <option value="0">Select Stone</option>
                <option value="1">NATURAL</option>
                <option value="2">CVD</option>
                <option value="3">HPHT</option>
                <option value="4">D_CVD</option>
                <option value="5">D_HPHT</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                          <div class="flex items-center sm:mr-2">

                              <select v-model="screenData.color_size" @change="initTabulator" class="form-select w-full">
                                  <option value="0">Select Color</option>
                                  <option v-for="(item,index) in screenData.color_size_detail" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                              </select>
                          </div>
                      </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="onFilter"
                >
                  Go
                </button>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-reset"
                  type="button"
                  class="btn btn-secondary w-full"
                  @click="onResetFilter"
                >
                  Reset
                </button>
            </div>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">

        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Charni modal-->

    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Charni</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <label for="pos-form-1" class="form-label">Charni Name</label>
              <input
                id="pos-form-1"
                v-model="charniForm.charni_name"
                name="charni_name"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_name.$error }"
                placeholder="Charni name"
              />
              <template v-if="validate.charni_name.$error">
                    <div
                      v-for="(error, index) in validate.charni_name.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Charni Code</label>
              <input
                id="pos-form-2"
                v-model="charniForm.charni_code"
                name="charni_code"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_code.$error }"
                placeholder="Code"
              />
              <template v-if="validate.charni_code.$error">
                    <div
                      v-for="(error, index) in validate.charni_code.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12 mt-3">
              <label for="pos-form-1" class="form-label">Stone Type</label>
              <TomSelect
                v-model="charniForm.stone_type"
                :options="{
                  placeholder: 'Stone Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">NATURAL</option>
              <option value="2">CVD</option>
              <option value="3">HPHT</option>
              <option value="4">D_CVD</option>
              <option value="5">D_HPHT</option>
              </TomSelect>
            </div>
            <div class="col-span-6">
              <label for="pos-form-3" class="form-label"
                >From Dia(ct)</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="charni_from_dia"
                v-model="charniForm.charni_from_dia"

                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_from_dia.$error }"
                placeholder=""
              />
              <template v-if="validate.charni_from_dia.$error">
                    <div
                      v-for="(error, index) in validate.charni_from_dia.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-6">
              <label for="pos-form-3" class="form-label"
                >From To(ct)</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="charni_to_dia"
                v-model="charniForm.charni_to_dia"

                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_to_dia.$error }"
                placeholder=""
              />
              <template v-if="validate.charni_to_dia.$error">
                    <div
                      v-for="(error, index) in validate.charni_to_dia.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitForm">
              Save Details
            </button>
            <button type="button" v-if="charniForm.id != ''" class="btn btn-danger w-32 ml-1" @click="deleteItem">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      id="success-notification-content"
      class="toastify-content hidden flex"
    >
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Details saved successfully!</div>
        <div class="text-gray-600 mt-1">

        </div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
      id="failed-notification-content"
      class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Something went wrong!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>

</template>

<script>
import { defineComponent, ref, reactive, toRefs,onMounted, inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'charni_name',
      type: 'like',
      value: ''
    })
    const swal = inject('$swal')
    const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))

    const charniForm = reactive({
      charni_name: '',
      charni_code: '',
      stone_type: '1',
      main_charni: '',
      charni_from_dia:'',
      charni_to_dia:'',
      id:''
    })

    const screenData = reactive({
      purity_items: [],
      color_size_detail: [],
      selected_stone: "0",
      color_size: "0"
    })

    const rules = {
      charni_name: {
        required
      },
      charni_code: {
        required,

      },
      charni_from_dia: {
        required
      },charni_to_dia: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(charniForm))

    const initTabulator = () => {

      let params = {
        stone_type: screenData.selected_stone == "0" ? '' : screenData.selected_stone,
        color_size: screenData.color_size == "0" ? '' : screenData.color_size,
      }

      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_charni_selling',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: params,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        headerSort: false,
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Charni Name',
            // minWidth: 10,
            responsive: 0,
            field: 'charni_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },

          {

            title: 'Charni Code',
            minWidth: 100,
            field: 'charni_code',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Charni Type',
            minWidth: 100,
            field: 'charni_type',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Charni From',
            minWidth: 100,
            field: 'charni_dia_from',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Charni To',
            minWidth: 100,
            field: 'charni_dia_to',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

            title: 'Stone',
            minWidth: 100,
            field: 'stone_type',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

            title: 'Color',
            minWidth: 100,
            field: 'color_segment',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class=" ${
                cell.getData().active_status == 'Active' ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 'Active' ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: 'Actions',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="">
                <a class="text-left" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                getDetails(cell.getData().id);
              })

              return a[0]
            }
          },

          // For print format
          {
            title: 'Charni Name',
            field: 'charni_name',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Charni Code',
            field: 'charni_code',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Charni From',
            field: 'charni_dia_from',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Charni To',
            field: 'charni_dia_to',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Stone',
            field: 'stone_type',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Status',
            field: 'status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    const getSellingCharni = async () => {
      let promise = axios({
        url: "api/dp_charni_selling",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.all_main_charni = data.items;
        })
        .catch(_error => {

        });

    }

    const getDetails = async (id) => {
      console.log(id);
      const params = {
      "id":  id,
      };

      let promise = axios({
        url: "api/details_charni_selling",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

        charniForm.charni_name = data.item_details.charni_name;
        charniForm.charni_code = data.item_details.charni_code;
        charniForm.stone_type = data.item_details.stone_type;
        charniForm.main_charni = data.item_details.main_charni;
        charniForm.charni_from_dia = data.item_details.charni_dia_from;
        charniForm.charni_to_dia = data.item_details.charni_dia_to;
        charniForm.id = data.item_details.id;
        cash('#new-order-modal').modal('show')

        })
        .catch(_error => {

        });
    }

    const submitForm = async () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {

        if(isNaN(charniForm.charni_from_dia) || isNaN(charniForm.charni_from_dia) || (charniForm.charni_from_dia <= 0) || (charniForm.charni_from_dia <= 0)) {
          swal('Warning',"Select range is Invalid!","warning")

          return;
        }

        // if(charniForm.charni_type == 1){
        //   charniForm.main_charni = '';
        // }

        const params = {
          "charni_name":  charniForm.charni_name,
          "charni_code":  charniForm.charni_code,
          "stone_type":  charniForm.stone_type,
          "main_charni":  charniForm.main_charni,
          "charni_dia_from":  charniForm.charni_from_dia,
          "charni_dia_to":  charniForm.charni_to_dia,
          "id" : charniForm.id
        };

        let promise_url = '';

        if(charniForm.id != '' && charniForm.id != null){
          promise_url = "api/edit_charni_selling";
        }else{
          promise_url = "api/add_charni_selling"
        }

        let promise = axios({
          url: promise_url,
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
        .then(result => result.data)
        .then(data => {

          cash('#new-order-modal').modal('hide')
        tabulator.value.setFilter(filter.field, filter.type, filter.value)

        validate.value.$reset()
          let promise_url_selling = '';

        if(charniForm.id != '' && charniForm.id != null){
          promise_url_selling = "/edit_charni_selling";
        }else{
          promise_url_selling = "/add_charni_selling"
        }

        let promise = axios({
          url: promise_url_selling,
          method: "POST",
          data: params,
          baseURL: BASE_URL_SELLING,
          headers: { Authorization: "Bearer " + AUTH_TOKEN },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
        .then(result => result.data)
        .then(data => {
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        charniForm.charni_name = '';
        charniForm.charni_code = '';
        charniForm.stone_type = '1';
        charniForm.main_charni = '';
        charniForm.charni_from_dia = '';
        charniForm.charni_to_dia = '';
        charniForm.id = '';
        })
        .catch(_error => {
          swal("Error!", "Data not Saved" + ", On Server", "error")
        });
      })
      .catch(_error => {
          swal("Error!", "Something went wrong", "error")

        });


      }
    }
    const deleteItem = () => {
      console.log(charniForm.id);
    }

    const shade_size_item_details = async () => {

      let params = {
        stone_type: screenData.selected_stone
      }

      // Get Segment Details
      let promise_url = 'api/dp_shade_size_items';

        let promise = axios({
            url: promise_url,
            data: params,
            method: "POST",
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
              screenData.color_size_detail = data.items
              screenData.color_size = "0"

              initTabulator()
              // setTimeout(() => {

                //   segment_details()
                // }, 50);
              })
              .catch(_error => {
                screenData.color_size_detail = []
                screenData.color_size = "0"
                initTabulator()
              // setTimeout(() => {

              //   segment_details()
              // }, 50);
            });
      }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'charni_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }
    const resetModalFileds = () => {
      charniForm.charni_name = '';
      charniForm.charni_code = '';
      charniForm.stone_type = '1';
      charniForm.main_charni = '';
      charniForm.charni_from_dia = '';
      charniForm.charni_to_dia = '';
      charniForm.id = '';
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
      getSellingCharni()
    })


    return {
      tableRef,
      filter,
      charniForm,
      screenData,
      validate,
      submitForm,
      onFilter,
      deleteItem,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      resetModalFileds,
      initTabulator,
      shade_size_item_details
    }
  }
})
</script>
