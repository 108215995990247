<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Merge Stock Transaction</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <button @click="clicknewMergeStock" class="btn btn-primary shadow-md mr-3">Final Polish Merge</button>
            <button @click="clicknewRTPMergeStock" class="btn btn-primary shadow-md mr-3">Rough To Polish Merge</button>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <!-- <div class="border-b pb-5"> -->

        <div class="grid grid-cols-12 gap-x-2">

            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Start Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" @change="getTransactionRecords" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" @change="getTransactionRecords" />
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Stone</label>
                    <TomSelect v-model="filters.stone_type" :options="{
                placeholder: 'Select Stone',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stone'
                  }
                }
              }" class="w-full" multiple @change="getTransactionRecords">
                        <option value="">All</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                        <!-- <option value="3">PRINCE</option> -->
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Shape</label>
                    <TomSelect v-model="filters.shape" multiple :options="{
                placeholder: 'Select Shape',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Shape'
                  }
                }
              }" class="w-full" @change="getTransactionRecords">
                        <option value="">All</option>
                        <option value="1">ROUND</option>
                        <option value="2">FANCY</option>
                        <!-- <option value="3">PRINCE</option> -->
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Merge Type</label>
                    <TomSelect v-model="filters.merge_type" :options="{
                placeholder: 'Select Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Merge Type'
                  }
                }
              }" class="w-full">
                        <option value="0">All</option>
                        <option value="2">Final Polish</option>
                        <option value="3">Rough To Polish</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Merge Transaction</label>
                    <TomSelect v-model="filters.merge_id" multiple :options="{
                placeholder: 'Select Select Merge Transaction',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Transaction Code'
                  }
                }
              }" class="w-full">
                        <option value="">Select Transaction</option>
                        <option v-for="(merge,index) in pagedata.merge_items" :key="index" :value="merge.id">{{ merge.merge_code }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()" :disabled="pagedata.loading == true">
                        Generate
                        <LoadingIcon icon="bars" class="w-8 h-8" v-if="pagedata.loading == true" />
                    </button>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
    BASE_URL
} from '@/constants/config.js'
import Tabulator from 'tabulator-tables'
import feather from 'feather-icons'
import cash from 'cash-dom';
import moment from 'moment';
import {
    useRoute,
    useRouter
} from 'vue-router';

export default defineComponent({
    setup() {

        const swal = inject('$swal');

        const tableRef = ref()
        const tabulator = ref()
        const route = useRoute()
        const router = useRouter()
        const pagedata = reactive({

            merge_items: [],
            report_items: [],
            generate: "no",
            loading: false,
        })

        const filters = reactive({
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            shape: [],
            stone_type: [],
            merge_id: [],
            merge_type: '0',
        })
        const initTabulator = () => {

            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                shape: filters.shape.length == 0 ? "" : filters.shape,
                stone_type: filters.stone_type.length == 0 ? "" : filters.stone_type,
                merge_id: filters.merge_id.length == 0 ? "" : filters.merge_id,
                merge_type: filters.merge_type == '0' ? "" : filters.merge_type
            };
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/get_merge_transaction_list',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxParams: params,
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Merge Code',
                        field: 'merge_code',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        cellClick: function(e, cell) {
                          router.push('/app/app-polish-ready/ready/merge/' + window.btoa(cell.getData().id))
                        }
                    },
                    {
                        title: 'Series',
                        minWidth: 100,
                        field: 'series_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea"
                    },
                    {
                        title: 'Kapan',
                        minWidth: 100,
                        field: 'kapan_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea"
                    },
                    {
                        title: 'Pieces',
                        minWidth: 100,
                        field: 'pieces',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Weight (Ct)',
                        minWidth: 100,
                        field: 'final_weight',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Stone',
                        field: 'stone_name',
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Shape',
                        field: 'shape_name',
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Merge Date',
                        field: 'merge_date',
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea"
                    },
                    {
                        title: 'Status',
                        field: 'active_status',
                        hozAlign: 'left',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == 1) {
                                return `<div class="flex items-center lg:justify-center text-theme-9">
                Merged
              </div>`
                            } else if (cell.getData().active_status == 2) {
                                return `<div class="flex items-center lg:justify-center text-theme-1">
                Transfered
              </div>`
                            } else if (cell.getData().active_status == 3) {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                Cancelled
              </div>`
                            } else if (cell.getData().active_status == 4) {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                Edit Requested
              </div>`
                            } else {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                  Unknown !
                  </div>`
                            }

                        }
                    },
                //     {
                //         title: 'View',
                //         field: 'actions',
                //         width: 70,
                //         responsive: 1,
                //         hozAlign: 'left',
                //         vertAlign: 'center',
                //         print: false,
                //         download: false,
                //         formatter(cell) {
                //             const a = cash(`<div class="m-0 p-0">
                //   <button class="btn btn-primary py-1 px-3">
                //     View
                //   </button>

                // </div>`)
                //             cash(a).on('click', function () {
                //                 router.push('/app/app-polish-ready/ready/merge/' + window.btoa(cell.getData().id))
                //             })

                //             return a[0]
                //         }
                //     },
                    {
                        title: 'Edit',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'center',
                        print: false,
                        download: false,
                        formatter(cell) {

                            // if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="p-0 m-0">
                <button class="btn bg-theme-2 py-1 px-2" title="Edit" data-te-toggle="tooltip">
                  <i data-feather="check-square" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {

                                  if(cell.getData().merge_type == 2) {

                                  router.push('/app/app-polish-ready/ready/merge-lot-new/' + window.btoa(cell.getData().id))
                                  } else {

                                  router.push('/app/app-process/packet/receive/final-non-certy/receive/' + window.btoa(cell.getData().id))
                                  }
                                })

                                return a[0]
                //             } else {
                //                 const a = cash(`<div class="p-0 m-0">
                // <button class="btn bg-theme-2 py-1 px-2" title="Edit" data-te-toggle="tooltip" disabled>
                //   <i data-feather="check-square" class="w-5 h-5 mr-1"></i>
                //   </button>

                //   </div>`)
                //                 // cash(a).on('click', function () {
                //                 //     router.push('/app/app-polish-ready/ready/merge-lot-new/' + window.btoa(cell.getData().id))
                //                 //     // getDetails(cell.getData().id);
                //                 // })

                //                 return a[0]

                //             }
                        }
                    },
                    {
                        title: 'Merge',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'center',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-6 py-1 px-2 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="pocket" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {
                                    mergeStockItems(cell.getData().id)
                                })

                                return a[0]
                            } else {
                                const a = cash(`<div class="p-0 m-0">
                <button class="btn bg-theme-6 py-1 px-2 text-white" disabled title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="pocket" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                // cash(a).on('click', function () {
                                //     router.push('/app/app-polish-ready/ready/merge-lot-new/' + window.btoa(cell.getData().id))
                                //     // getDetails(cell.getData().id);
                                // })

                                return a[0]

                            }
                        }
                    }

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const onResetFilter = () => {
            filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            filters.e_date = moment().format('YYYY-MM-DD')
            filters.shape = []
            filters.stone_type = []
            filters.merge_id = []
            filters.merge_type = '0'
        }

        const getTransactionRecords = () => {
            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                shape: filters.shape.length == 0 ? "" : filters.shape,
                stone_type: filters.stone_type.length == 0 ? "" : filters.stone_type,
                merge_type: filters.merge_type == '0' ? "" : filters.merge_type,
            };

            let promise = axios({
                url: "api/get_merge_transaction_items",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filters.merge_id = "0"
                    pagedata.merge_items = data.items;

                })
                .catch(_error => {
                    filters.merge_id = "0"
                    pagedata.merge_items = [];
                });
        }
        const clicknewMergeStock = () => {
            router.push('/app/app-polish-ready/ready/merge-lot-new');
        }
        const clicknewRTPMergeStock = () => {
            router.push('/app/app-process/packet/receive/final-non-certy/receive');
        }

        const mergeStockItems = (id) => {

            swal({
                title: "Are you sure?",
                text: "You want to Transfer to Final Stock!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Merge",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {



              pagedata.failed_loading = true

              const params = {
                  id: window.btoa(id),
              };

              let promise = axios({
                  url: "api/merge_lot_final_stock",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "NO") {
                          swal.fire({
                              icon: "success",
                              title: "Success!",
                              text: data.message,
                          })
                          initTabulator()
                      } else {
                          swal({
                              title: "Warning!",
                              icon: "warning",
                              text: data.message,
                              type: "warning",
                          })
                      }

                  })
                  .catch(_error => {

                  });
                }
            });
        }

        onMounted(() => {
            getTransactionRecords();
            initTabulator()
        })

        return {
            filters,
            pagedata,
            tableRef,
            onResetFilter,
            initTabulator,
            getTransactionRecords,
            clicknewMergeStock,
            clicknewRTPMergeStock
        }
    }
})
</script>
