<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Non-Certy Stock (Home Branch)</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="grid grid-cols-12 gap-x-2">
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Field</label>
                            <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select">
                                <option value="charni_name">Charni Name</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Type</label>
                            <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-fullo">
                                <option value="like" selected>like</option>
                                <option value="=">=</option>
                                <option value="<">&lt;</option>
                                <option value="<=">&lt;=</option>
                                <option value=">">></option>
                                <option value=">=">>=</option>
                                <option value="!=">!=</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Value</label>
                            <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40" placeholder="Search..." />
                        </div>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full" @click="initTabulator">
                            Go
                        </button>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 mt-2">
                      <label for="update-profile-form-7" class="form-label"
                      >Select Stone</label>
                        <TomSelect v-model="screenData.stone_id" :options="{
                placeholder: 'Select Stone',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stone'
                  }
                }
              }" class="w-full" @change="getColorDetails">
                            <option value="">Select Stone</option>
                            <option value="1">Natural</option>
                            <option value="2">CVD</option>
                            <option value="3">HPHT</option>
                            <option value="4">D_CVD</option>
                            <option value="5">D_HPHT</option>
                        </TomSelect>
                    </div>
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 mt-2">
                      <label for="update-profile-form-7" class="form-label"
                      >Select Color</label>
                        <TomSelect v-model="screenData.color_size" :options="{
                placeholder: 'Select Stone',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stone'
                  }
                }
              }" class="w-full" @change="initTabulator">
                            <option value="">Select Stone</option>
                            <option  v-for="(item,index) in screenData.color_arr" :value="item.segment_code" :key="index">{{ item.segment_name }}</option>
                          </TomSelect>
                        </div>
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 mt-2">
                        <label for="update-profile-form-7" class="form-label">Select Shape</label>
                        <TomSelect v-model="screenData.shape_id" :options="{
                          placeholder: 'Select Shape',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Shape'
                            }
                          }
                        }" class="w-full" @change="initTabulator">
                            <option value="">Select Shape</option>
                            <option  v-for="(item,index) in screenData.shape_arr" :value="item.id" :key="index">{{ item.shape_name }}</option>
                        </TomSelect>
                    </div>
                    <div class="col-span-6 md:col-span-3 lg:col-span-2">
                      <div class="mt-9">
                                  <div class="dropdown w-1/2 sm:w-auto">
                                    <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                                        <FileTextIcon class="w-4 h-4 mr-2" /> Export
                                        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                                    </button>
                                    <div class="dropdown-menu w-40">
                                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX All
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                    </div>
                </div>
              </div>
            <!-- <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div> -->
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="screenData.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Stock Report</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div v-if="screenData.generate == 'gen'">
                <div class="border mt-5 p-0 shadow-md">

                    <div class="overflow-x-auto m-0 p-0">

                        <table class="text-xs text-center w-full">

                            <thead>
                                <tr class="border bg-gray-100 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in screenData.report_items.size_arr" :key="size_index" :colspan="screenData.report_items.size_arr_number[size_index]">{{ size_item }}</th>
                                </tr>
                                <tr class="border bg-gray-100 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in screenData.report_items.size_head_number" :key="size_index">{{ size_item }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border" v-for="(size_value_item,size_value_index) in screenData.report_items.size_value_arr" :key="size_value_index">
                                    <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                                </tr>
                                <tr class="border">
                                    <th class="border py-2" v-for="(item,inx) in screenData.report_items.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL,BASE_URL_SELLING,Auth_Token,BUILD_ENV
} from '@/constants/config.js'
import FileDownload from "js-file-download";

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const filter = reactive({
            field: 'charni_name',
            type: 'like',
            value: ''
        })
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const screenData = reactive({
            report_items: [],
            shape_arr: [],
            color_arr: [],
            generate: 'no',
            loading: false,
            stones: [],
            stone_id: '1',
            color_size: '',
            shape_id: '1'
        })

        const initTabulator = () => {
            const params = {
                stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
                shape_id: screenData.shape_id != '0' ? screenData.shape_id : '',
                color_size: screenData.color_size != '0' ? screenData.color_size : '',
                filters: filter
            };

            screenData.loading = true


            let promise = axios({
                url: "/api/get_cr_stock_details_list",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.report_items = data.items;
                    screenData.generate = 'gen';
                    screenData.loading = false

                })
                .catch(_error => {

                    screenData.generate = 'no';
                    screenData.loading = false
                });
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        const getColorDetails = async (item) => {

if( screenData.stone_id == "0") {
  screenData.color_arr = [];
  screenData.color_size = ""
  // initTabulator()
}
const params = {
  stone_type: screenData.stone_id
};
const url = "/api/dp_shade_size_items"
let promise = axios({
    url: url,
    method: "POST",
    data: params,
    baseURL: BASE_URL,
    headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
    },
    config: {
        headers: {
            Accept: "application/json"
        }
    }
});
return promise
    .then(result => result.data)
    .then(data => {
      screenData.color_arr = data.items;
      screenData.color_size = String(screenData.color_arr[0].segment_code)
      // initTabulator()
    })
    .catch(_error => {
      screenData.color_arr = [];
      screenData.color_size = ""
      // initTabulator()
    });

}
        const getShapeDetails = async () => {

const params = {
};
const url = "/api/dp_shape"
let promise = axios({
    url: url,
    method: "POST",
    data: params,
    baseURL: BASE_URL,
    headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
    },
    config: {
        headers: {
            Accept: "application/json"
        }
    }
});
return promise
    .then(result => result.data)
    .then(data => {
      screenData.shape_arr = data.items;
      // initTabulator()
    })
    .catch(_error => {
      screenData.shape_arr = [];
      // initTabulator()
    });

}

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'charni_name'
            filter.type = 'like'
            filter.value = '',
                screenData.stone_id = '1'
            screenData.shape_id = '1'
            initTabulator()
        }
        const onExportXlsx = () => {
          let filename = "home-branch-stock.xlsx";
          const params = {
                stone_id: screenData.stone_id != '0' ? screenData.stone_id : '',
                shape_id: screenData.shape_id != '0' ? screenData.shape_id : '',
                color_size: screenData.color_size != '0' ? screenData.color_size : '',
                filters: filter
            };
          let promise = axios({
              url: "/api/download_cr_stock_details_list",
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              },
              responseType: "blob",
          });

          return promise
              .then((result) => result.data)
              .then((data) => {
                  FileDownload(data, filename);
              })
              .catch((_error) => {
                  return [];
              });
            }
        onMounted(() => {
          getColorDetails()
          getShapeDetails()
        })

        return {
            tableRef,
            screenData,
            filter,
            editUser,
            onResetFilter,
            initTabulator,
            getColorDetails,
            onExportXlsx
        }

    }
})
</script>
