<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tender Report - Top 3 Bids</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
         
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
   
          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Tender</label
                  >
                 <TomSelect
                v-model="screenData.selectedTenders"
                :options="{
                  placeholder: 'Select Tender',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Tenders'
                    }
                  }
                }"
                class="w-full"
                
              >
              <option value="">Select Tender</option>
              <option v-for="tender in screenData.tenders" :key="tender.id" :value="tender.id">{{tender.tender_name}}</option>
              </TomSelect>
                </div>
                
              
              
              </div>
           

              <div class="col-span-12 xl:col-span-12 mt-5">
                
                <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="onFilter"
            >
              Generate
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
              </div>
            </div>  
       

      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
           <h2 class="text-lg font-medium mr-auto mt-5">Tender Report</h2>
          </div>
         
        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
             
               
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export Table XLSX
                </a>

                 <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export All XLSX
                </a>
                 
              </div>
            </div>
          </div>
        </div>
      </div>  
     
      <div class="overflow-x-auto scrollbar-hidden">
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
           
        </div>
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
           <div class="border">
        <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;border-bottom:1px solid #f8f8f8;">
              <div class="col-span-2 xl:col-span-1 text-center">
                <p>#</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Sr. No</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Cut</p>
              </div>
              <div class="col-span-2 xl:col-span-4 font-bold">
                <p>Details</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Stone</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Carats</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
            <p>Expected Price($) /carat</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
            <p>Total Price($)</p>
              </div>
            </div>
      
          
          <div class="border mt-5" v-for="field in screenData.fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              <div class="col-span-2 xl:col-span-1 text-center">
                <p class="mt-2">{{ field.id }}</p>
              </div>
              <div class="col-span-2 xl:col-span-1">
                <input v-model="field.lot_no" type="text"
                    class="form-control" placeholder="Sr. no" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1">
                <input v-model="field.cut" type="text"
                    class="form-control" placeholder="Cut" readonly>
              </div>
              <div class="col-span-2 xl:col-span-4">
                <input v-model="field.details" type="text"
                    class="form-control" placeholder="Details" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1">
              <input v-model="field.piece" type="text"
                    class="form-control" placeholder="Piece" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1">
              <input v-model="field.carats" type="text"
                    class="form-control" placeholder="Carats" readonly>
              </div>
              <div class="col-span-2 xl:col-span-1">
              <input v-model="field.expect_price" type="text"
                    class="form-control" placeholder="Expected Price" readonly>
              </div>
              <div class="col-span-2 xl:col-span-2 pt-3 pl-3">
                {{field.expect_price * field.carats}}
              </div>
            </div>
            
            <div class="grid grid-cols-12 mt-3">
              <div class="col-span-2 xl:col-span-1 text-center">
              </div>
             
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Buyer Rank</p>
              </div>
              <div class="col-span-2 xl:col-span-4 font-bold">
                <p>Buyer Name</p>
              </div>
              <div class="col-span-2 xl:col-span-2 font-bold">
              <p>Bid Price($) /Carat</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
              <p>Total Price</p>
              </div>
               <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Up/Down(%)</p>
              </div>
            
              
            </div>

            <div class="grid grid-cols-12" v-for="(bids,index) in field.top_bids" :index="index" :key="bids.id">
              <div class="col-span-2 xl:col-span-1 text-center">
              </div>
             
              <div class="col-span-2 xl:col-span-1">
                <p>{{index+1}}</p>
              </div>
              <div class="col-span-2 xl:col-span-4">
                <p>{{bids.company_name}} ({{ bids.company_email}})</p>
              </div>
              <div :class="Number(bids.bid_price) >= Number(field.expect_price) ? 'col-span-2 xl:col-span-2 text-theme-9' : 'col-span-2 xl:col-span-2 text-theme-6'">
              <p>{{bids.bid_price}}</p>
              </div>
              
              <div class="col-span-2 xl:col-span-1">
              <p >{{Number((bids.bid_price * field.carats).toFixed(2))}}</p>
              </div>
              
              <div class="col-span-2 xl:col-span-2">
             <p>{{getPer(bids.bid_price,field.expect_price,field.carats)}}</p>
              </div>
              
            </div>

             

            <div class="grid grid-cols-12" v-if="field.top_bids.length == 0">
              <div class="col-span-2 xl:col-span-1 text-center">
                </div>
                <div class="col-span-2 xl:col-span-11 text-bold text-theme-6">
                  <p>No Bids Found !</p>
                </div>
            </div>
          </div>
        </div>
      
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {

   
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()
    

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      buyers:[],
      selectedTenders:'',
      tenders:[],
      selectedTenderItems:[],
      tenderItems:[],
      fields: [],
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_tenders_bid_packet',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          tender_id:screenData.selectedTenders,
          selected_buyer:screenData.selectedbuyers,
          tender_items:screenData.selectedTenderItems
          },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Tender Name',
            minWidth: 200,
            responsive: 0,
            field: 'tender_name',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              router.push("/app/tender/view/" + window.btoa(cell.getData().tender_id));
            },
            formatter(cell) {
             
              return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().tender_name
                }</div>
               
              </div>`
            }
          },
         
          {
            title: 'Party Name',
            minWidth: 200,
            field: 'company_name',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Lot No',
            minWidth: 100,
            field: 'lot_no',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Packet Details',
            minWidth: 200,
            field: 'item_details',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Expected Price',
            minWidth: 200,
            field: 'expected_price',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: true,
            
          },
          {
            title: 'Bid Price',
            minWidth: 100,
            field: 'bid_price',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
             
             if(cell.getData().expected_price != '' && cell.getData().expected_price != null){

               if(parseFloat(cell.getData().expected_price) > parseFloat(cell.getData().bid_price)){
                 return `<div>
                <div class="font-medium whitespace-nowrap text-theme-6">${
                  cell.getData().bid_price
                }</div>
               
              </div>`
               }else{
                 return `<div>
                <div class="font-medium whitespace-nowrap text-theme-9">${
                  cell.getData().bid_price
                }</div>
               
              </div>`
               }
             }else{
               
              return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().bid_price
                }</div>
               
              </div>`
             }
              
            }
          },
          
          

          // For print format
          
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      const params = {
        tender_id: window.btoa(screenData.selectedTenders)
      };
      let promise = axios({
        url: "api/get_tender_details_for_w",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          screenData.fields = data.tender_detail.rough_details_arr;

          console.log(screenData.fields);

        })
        .catch(_error => {
          
        });
    }

    // On reset filter
    const onResetFilter = () => {
      screenData.selectedTenders = ''
      screenData.selectedTenderItems = []
      screenData.selectedbuyers = []
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    const getTenders = () => {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tenders_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          screenData.tenders = data;

        })
        .catch(_error => {
          
        });
    }

   

    const checkPermission = () => {
      
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

     const getPer = (bid_price,expected_price,carate) => {
      let per = Number(bid_price)/Number(expected_price);
      per = per*100;
      return Number(per.toFixed(2))+"%";
    }

    onMounted(() => {
      checkPermission()
      reInitOnResizeWindow()
      getTenders()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      getPer
      
    }
  }
})
</script>
