<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Charni Selling Rates</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">

                <div class="p-5 grid grid-cols-12 gap-x-2">
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">

                        <div class="py-1">
                            <label for="update-profile-form-7" class="form-label">Stone Type</label>
                            <TomSelect v-model="form_data.stone_type" :options="{
                placeholder: 'Select Stone',
                }" class="w-full" @change="shade_size_item_details">
                                <option value="">Select Stone</option>
                                <option value="1">Natural</option>
                                <option value="2">CVD</option>
                                <option value="3">HPHT</option>
                                <option value="4">D_CVD</option>
                                <option value="5">D_HPHT</option>
                            </TomSelect>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">

                        <div class="py-1">
                            <label for="update-profile-form-7" class="form-label">Color</label>
                            <TomSelect v-model="form_data.color_size" :options="{
                placeholder: 'Select Color',
                }" class="w-full" @change="get_lot_details">
                                <option value="">Select Color</option>
                                <option v-for="(item,index) in color_arr" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                            </TomSelect>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">

                        <div class="py-1">
                            <label for="update-profile-form-7" class="form-label">Select Shape</label>
                            <TomSelect v-model="form_data.shape_type" :options="{
                placeholder: 'Select Shape',
                }" class="w-full" @change="get_lot_details">
                                <option value="">Select Shape</option>
                                <option v-for="charni in shape_arr" :key="charni.id" :value="charni.id">{{ charni.shape_name }}</option>
                            </TomSelect>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-12 mt-5">
            <!-- BEGIN: Input -->
            <div class="intro-y box">

                <div class="px-5 py-2 pb-10">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Rate Details</h2>
                        <div v-if="this.fields.length > 0">
                            <div>
                                <div class="flex justify-end">

                                    <button class="btn btn-primary mr-auto" :disabled="form_status == true || data_loading == true" @click="onUserFormSubmit">
                                        Save Rates
                                        <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="form_status == false" />
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border mt-2">
                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center text-xs">
                                <thead>
                                    <tr class="bg-theme-14" v-if="size_purity_arr.length > 0">
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Purity</th>
                                        <template v-for="(item,index) in size_purity_arr" :key="index">
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">{{ item.purity }}</th>
                                        </template>
                                    </tr>
                                    <tr class="bg-theme-14" v-if="size_purity_arr.length > 0">
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2"></th>
                                        <template v-for="(item,index) in size_purity_arr" :key="index">
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Size {{ item.size }}</th>
                                        </template>
                                    </tr>
                                    <tr class="bg-theme-14">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                        <template v-for="(item,index) in size_purity_arr" :key="index">
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate </th>
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5">Max Rate </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in fields" :key="index">
                                        <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                        <td class="border-b py-2 px-2 border-r">{{ field.charni_name }}</td>
                                        <template v-for="(item,inx) in field.sub_charni_arr" :key="inx">
                                            <td class="border-b py-2 px-2">
                                                <input v-model="item.stock_rate" type="text" class="form-control w-24" placeholder="" @keypress="$h.preventInvalidInput">
                                            </td>
                                            <td class="border-b border-r py-2 px-2">
                                                <input v-model="item.max_rate" type="text" class="form-control w-24" placeholder="" @keypress="$h.preventInvalidInput">
                                            </td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="data_loading == true" class="text-md font-medium mr-auto text-center my-8">
                            Loading
                        </div>
                        <div v-if="fields.length == 0 && data_loading == false" class="text-md font-medium mr-auto text-center my-8">
                            Records Not Found
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token,
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    props: {},

    data() {
        return {

            status: 'default',
            loginVal: '',
            req: axios.create({
                baseUrl: BASE_URL
            }),

            AUTH_TOKEN: (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token")),

            form_data: {
                stone_type: "1",
                shape_type: "1",
                color_size: "",
            },
            final_data: {
                stone_type: "1",
                shape_type: "1",
                color_size: "",
            },
            color_arr: [],
            size_purity_arr: [],
            form_status: false,
            data_loading: false,
            fields: [],
            shape_arr: ''
        }
    },
    computed: {

    },
    mounted() {

        this.shade_size_item_details()
        this.shape_list()
    },

    methods: {
        alertVal() {
            alert(this.dob)
        },
        changeSeries() {
            this.form_data.kapan_id = []
            this.getKapanDetails()
        },
        onUserFormSubmit() {

            //console.log(JSON.stringify(this.gridForm));
            this.errors = [];

            if (!this.errors.length) {

                this.$swal({
                    title: "Are you sure?",
                    text: "You Want to Add Rates!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Accept",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        const params = {
                            "rate_items": this.fields,
                            "shape_type": this.final_data.shape_type
                        };
                        let new_url = "api/update_stock_rate_details"
                        this.form_status = true

                        let promise = axios({
                            url: new_url,
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });

                        return promise
                            .then(result => result.data)
                            .then(data => {

                                if (data.error == "YES") {
                                    this.$swal("Error!", data.message, "error");

                                    return;
                                }

                                let new_url_selling = "/update_stock_rate_details"
                                this.form_status = true

                                let promise = axios({
                                    url: new_url_selling,
                                    method: "POST",
                                    data: params,
                                    baseURL: BASE_URL_SELLING,
                                    headers: {
                                        Authorization: "Bearer " + this.AUTH_TOKEN
                                    },
                                    config: {
                                        headers: {
                                            Accept: "application/json"
                                        }
                                    }
                                });

                                return promise
                                    .then(result => result.data)
                                    .then(data => {

                                        if (data.error == "YES") {
                                            this.$swal("Error!", data.message + ", On Server", "error");

                                            return;
                                        }

                                        this.$swal.fire({
                                            icon: 'success',
                                            title: "Success!",
                                            text: data.message,
                                            showConfirmButton: false,
                                            timer: 3000
                                        })
                                        this.form_status = false
                                        this.$router.push('/app/charni-selling-rate');
                                    })
                                    .catch(_error => {
                                        this.form_status = false
                                        this.$swal("Error!", "Something went Wrong" + ", On Server", "error");

                                    });
                            })
                            .catch(_error => {
                                this.form_status = false
                                this.$swal("Error!", "Something went Wrong", "error");

                            });
                    }
                })
            }
        },
        async getSizePurityDetails() {
            let _this = this;
            const params = {
                stone_type: this.final_data.stone_type,
                color_size: this.final_data.color_size
            };
            let promise = axios({
                url: "api/list_all_merge_segment",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.size_purity_arr = data.items;

                })
                .catch(_error => {
                    this.size_purity_arr = [];

                });
        },
        async get_lot_details() {

            if (this.form_data.stone_type == "" || this.form_data.shape_type == "" || this.form_data.color_size == "") {
                this.size_purity_arr = []
                this.fields = []
                return;
            }

            this.final_data.stone_type = this.form_data.stone_type
            this.final_data.shape_type = this.form_data.shape_type
            this.final_data.color_size = this.form_data.color_size

            this.getSizePurityDetails()
            this.data_loading = true
            this.fields = []

            let params = {
                "stone_type": this.form_data.stone_type == "" ? "" : window.btoa(this.form_data.stone_type),
                "shape_type": this.form_data.shape_type == "" ? "" : window.btoa(this.form_data.shape_type),
                "color_size": this.form_data.color_size == "" ? "" : window.btoa(this.form_data.color_size)
            }

            let api_url = "api/get_stock_rate_details"

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                    this.fields = data.items
                    this.data_loading = false
                })
                .catch((_error) => {
                    this.fields = []
                    this.data_loading = false
                });
        },
        async shade_size_item_details() {

            let params = {
                stone_type: this.form_data.stone_type
            }

            // Get Segment Details
            let promise_url = 'api/dp_shade_size_items';

            let promise = axios({
                url: promise_url,
                data: params,
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    this.color_arr = data.items
                    this.form_data.color_size = String(this.color_arr[0].segment_code)

                    this.get_lot_details()
                })
                .catch(_error => {
                    this.color_arr = []
                    this.form_data.color_size = ""
                    this.get_lot_details()
                });
        },
        async shape_list() {

          let params = {
              // stone_type: charniForm.stone_type
          }

          // Get Segment Details
          let promise_url = 'api/dp_shape';

          let promise = axios({
              url: promise_url,
              data: params,
              method: "POST",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {
                  this.shape_arr = data.items
                  // charniForm.shape = "0"
              })
              .catch(_error => {
                  this.shape_arr = []
                  // charniForm.shape = "0"
              });
          }
    },

})
</script>

<style scoped>
/* .border {
    margin: 0 20px;
    padding: 3px;
  } */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 500px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
