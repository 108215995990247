<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Polish Comparison Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Series</label>
                    <TomSelect v-model="screenData.series_id" :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }" class="w-full" @change="seriesChange" multiple>
                        <!-- <option value="0">Select Series</option> -->
                        <option v-for="series in page_data.series_details" :key="series.id" :value="series.id">{{series.series_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="screenData.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full" @change="kapanChange" multiple>
                        <!-- <option value="0">Select Kapan</option> -->
                        <option v-for="kapan in page_data.kapan_details" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Packets</label>
                    <TomSelect v-model="screenData.packets" :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }" class="w-full" multiple>
                        <!-- <option value="0">Select Packets</option> -->
                        <option v-for="packet in page_data.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Party</label>
                    <TomSelect v-model="screenData.party_id" :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Party</option>
                        <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.company_name ? party.company_name : party.first_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1">Select Start Date</label><input class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="screenData.issue_pending" /></div>
                    <input type="date" id="update-profile-form-13" :disabled="screenData.issue_pending" class="form-control" placeholder="" v-model="screenData.s_date" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="screenData.issue_pending" v-model="screenData.e_date" />
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Shape</label>
                    <TomSelect v-model="screenData.shape_group" :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option value="FANCY">FANCY</option>
                        <option v-for="shape in page_data.shapes" :key="shape.id" :value="shape.id">{{shape.shape_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Color</label>
                    <TomSelect v-model="screenData.color_id" :options="{
                  placeholder: 'Select Color',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Color'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option v-for="color in page_data.colors" :key="color.id" :value="color.id">{{color.shade_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Purity</label>
                    <TomSelect v-model="screenData.purity_id" :options="{
                  placeholder: 'Select Purity',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Purity'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option v-for="purity in page_data.purities" :key="purity.id" :value="purity.id">{{purity.purity_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Stock Type</label>
                    <TomSelect v-model="screenData.lab" :options="{
                  placeholder: 'Select Lab',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Lab'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option value="non_certy">Non Certy</option>
                        <option value="IGI">IGI</option>
                        <option value="GIA">GIA</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Stone</label>
                    <TomSelect v-model="screenData.stone_id" :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Stone</option>
                        <option v-for="stone in page_data.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Enter Size</label>
                    <input id="tabulator-html-filter-value" v-model="screenData.size_weight" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="0.001-1.26" />
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button type="button" class="btn btn-primary" @click="generateReport()" :disabled="page_data.form_status || (screenData.issue_pending == true && (screenData.series_id.length == 0 && screenData.kapan_id.length == 0))">
                        Generate
                        <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                    <!-- <button type="button" class="btn btn-primary" @click="generateReport()" :disabled="page_data.form_status || (screenData.issue_pending == true && (screenData.series_id.length == 0 && screenData.kapan_id.length == 0))">
                        Generate
                        <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button> -->
                    <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="generateReport()" :disabled="page_data.form_status || (screenData.issue_pending == true && (screenData.series_id.length == 0 && screenData.kapan_id.length == 0))"
            >
            <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-24 h-24 ml-2" />
            Generate
            </button> -->
                </div>
            </div>
            <!-- <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="page_data.total.issue_piece && page_data.total.issue_piece > 0">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" @click="printReportDetails">
                        Print
                    </button> -->
                    <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
              v-print="'#generate-pdf'"
            >
            Print
            </button> -->
                <!-- </div>
            </div> -->
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                  <div class="flex mt-5 sm:mt-0">

                    <div class="dropdown w-1/2 sm:w-auto">
                        <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                            <FileTextIcon class="w-4 h-4 mr-2" /> Export
                            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                        </button>
                        <div class="dropdown-menu w-40">
                            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <a id="tabulator-export-xlsx" href="javascript:;" :aria-disabled="page_data.excel_loading == true" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>

        </div>
    </div>
    <div id="generate-pdf" class="intro-y box p-5 mt-5">
        <!-- BEgin: Process Report Start-->
        <!-- <div id="responsive-table" class="p-5" v-if="page_data.loading">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Latest Price</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="scrollbar-hidden" v-if="page_data.generate == 'gen'">
                <div class="intro-y col-span-12 overflow-auto">
                    <table class="mt-3 w-full border-2 text-center">
                        <thead>
                            <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">No</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Packet Code</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Weight</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Shape</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Color</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Purity</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Lab</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Majuri</th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r">Party</th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r">Issue Date</th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r">Check Date</th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r">Lab Date</th>
                            </tr>
                            <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">Total</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">{{ (page_data.total.issue_piece ? page_data.total.issue_piece : "-") }}</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">{{ (page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) : " - ") }} / {{ (page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) : " - ") }} / {{ (page_data.total.lab_weight ? $h.myFloat(page_data.total.lab_weight) : " - ") }}</th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">{{ (page_data.total.total_majuri ? $h.myFloat(page_data.total.total_majuri) : "-") }}</th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r"></th>
                                <th class="py-2 px-2 text-xs lg:text-md border-r"></th>
                            </tr>
                        </thead>
                        <tbody v-for="(item,index) in page_data.report_details" :key="index">
                            <tr class="border-b">
                                <td class="py-2 text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                                <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.packet_code }}</td>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">
                                    <span>{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : " - ") }}</span> /
                                    <span :class="{'text-theme-6' : item.polish_weight_theme == 'bg-red','text-theme-9' : item.polish_weight_theme == 'bg-green'}">{{ (item.po_f_wt ? $h.myFloat(item.po_f_wt) : " - ") }}</span> /
                                    <span :class="{'text-theme-6' : item.lab_weight_theme == 'bg-red','text-theme-9' : item.lab_weight_theme == 'bg-green'}">{{ (item.lab_weight ? $h.myFloat(item.lab_weight) : " - ") }}</span>
                                </th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">
                                    <span>{{ (item.last_shape ? item.last_shape : " - ") }}</span> /
                                    <span>{{ (item.polish_shape_name ? item.polish_shape_name : " - ") }}</span> /
                                    <span>{{ (item.lab_shape_name ? item.lab_shape_name : " - ") }}</span>
                                </th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">
                                    <span>{{ (item.last_color ? item.last_color : " - ") }}</span> /
                                    <span :class="{'text-theme-6' : item.polish_color_theme == 'bg-red','text-theme-9' : item.polish_color_theme == 'bg-green'}">{{ (item.polish_shade_name ? item.polish_shade_name : " - ") }}</span> /
                                    <span :class="{'text-theme-6' : item.lab_color_theme == 'bg-red','text-theme-9' : item.lab_color_theme == 'bg-green'}">{{ (item.lab_shade_name ? item.lab_shade_name : " - ") }}</span>
                                </th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">
                                    <span>{{ (item.last_purity ? item.last_purity : " - ") }}</span> /
                                    <span :class="{'text-theme-6' : item.polish_purity_theme == 'bg-red','text-theme-9' : item.polish_purity_theme == 'bg-green'}">{{ (item.polish_purity_name ? item.polish_purity_name : " - ") }}</span> /
                                    <span :class="{'text-theme-6' : item.lab_purity_theme == 'bg-red','text-theme-9' : item.lab_purity_theme == 'bg-green'}">{{ (item.lab_purity_name ? item.lab_purity_name : " - ") }}</span>
                                </th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">
                                    <span>{{ (item.lab ? item.lab : " - ") }}</span> /
                                    <span>{{ (item.new_lab ? item.new_lab : " - ") }}</span>
                                </th>
                                <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r">
                                    <span>{{ (item.total_majuri ? item.total_majuri : " - ") }}</span>
                                </th>
                                <td class="py-2 text-xs lg:text-md text-center border-r">{{ (item.first_name ? item.first_name : "-") + ' ' + (item.last_name ? item.last_name : "") }}</td>
                                <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.created_at ? item.created_at : "-" }}</td>
                                <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.po_date ? item.po_date : "-" }}</td>
                                <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.lab_date ? item.lab_date : "-" }}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
        <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                            <div class="text-base text-gray-600">Issue Piecse</div>
                            <div class="text-xl font-medium leading-8">{{ page_data.total.issue_piece ? page_data.total.issue_piece : 0 }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Issue Weight</div>
                            <div class="text-xl font-medium leading-8">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' ct' : '0.00 ct' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Check Weight</div>
                            <div class="text-xl font-medium leading-8">{{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' ct' : '0.00 ct' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Total Majuri</div>
                            <div class="text-xl font-medium leading-8">{{ page_data.total.total_majuri ? $h.myFloat(page_data.total.total_majuri) : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    reactive,
    onMounted
} from 'vue'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import {
    useStore
} from '@/store'
import moment from 'moment'
import Tabulator from 'tabulator-tables'
import feather from 'feather-icons'
import FileDownload from "js-file-download";

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const tableRef = ref()
        const tabulator = ref()
        const userDetails = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            series_details: [],
            kapan_details: [],
            packets: [],
            stones: [],
            shapes: [],
            colors: [],
            purities: [],
            parties: [],
            report_details: [],
            total: [],
            report_type: 0,
            kapan_size: 0,
            loading: false,
            status: 1,
            form_status: false,
            issue_pending: false,
            generate: "no",
            excel_loading: false,

        })
        const screenData = reactive({
            "s_date": moment().startOf('month').format('YYYY-MM-DD'),
            "e_date": moment().format('YYYY-MM-DD'),
            "series_id": [],
            "kapan_id": [],
            "party_id": "0",
            "packets": [],
            "issue_pending": false,
            "stone_id": "0",
            "lab": "0",
            "shape_group": "0",
            "color_id": "0",
            "purity_id": "0",
            "size_weight": ""
        })
        const printData = reactive({
            "s_date": moment().startOf('month').format('YYYY-MM-DD'),
            "e_date": moment().format('YYYY-MM-DD'),
            "series_id": [],
            "kapan_id": [],
            "party_id": "0",
            "packets": [],
            "issue_pending": false,
            "stone_id": "0",
            "lab": "0",
            "shape_group": "0",
            "color_id": "0",
            "purity_id": "0",
            "size_weight": ""

        })

        const getDPStoneType = () => {
            let params = {}
            let promise = axios({
                url: "api/dp_stone",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.stones = data.items

                })
                .catch(_error => {

                });
        }

        const generateReport = () => {

            printData.s_date = screenData.s_date
            printData.e_date = screenData.e_date
            printData.series_id = screenData.series_id
            printData.kapan_id = screenData.kapan_id
            printData.party_id = screenData.party_id
            printData.packets = screenData.packets
            printData.issue_pending = screenData.issue_pending
            printData.stone_id = screenData.stone_id
            printData.lab = screenData.lab
            printData.shape_group = screenData.shape_group
            printData.color_id = screenData.color_id
            printData.purity_id = screenData.purity_id
            printData.size_weight = screenData.size_weight

            let params = {
                series_id: (printData.series_id.length > 0) ? printData.series_id : '',
                kapan_id: (printData.kapan_id.length > 0) ? printData.kapan_id : '',
                packets: (printData.packets.length > 0) ? printData.packets : '',
                party_id: printData.party_id != '0' ? printData.party_id : '',
                stone_id: printData.stone_id != '0' ? printData.stone_id : '',
                lab: printData.lab != '0' ? printData.lab : '',
                size_weight: printData.size_weight,
                shape_group: printData.shape_group != '0' ? printData.shape_group : '',
                color_id: printData.color_id != '0' ? printData.color_id : '',
                purity_id: printData.purity_id != '0' ? printData.purity_id : '',
                s_date: printData.s_date,
                e_date: printData.e_date,
                issue_pending: printData.issue_pending,
            }

            if(printData.lab == 'non_certy') {
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + `/api/final_polish_comaprison_report`,
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem('token')
                      },
                  },
                  ajaxParams: params,
                  pagination: 'remote',
                  paginationSize: 50,
                  paginationSizeSelector: [50, 100, 200],
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'Packet No',
                          field: 'packet_code',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter: "textarea"
                      },
                      // {
                      //     title: 'Stock No',
                      //     field: 'lab_code',
                      //     hozAlign: 'left',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     minWidth: 120,
                      //     download: true,
                      //     formatter: "textarea"
                      // },
                      {
                          title: 'Weight',
                          field: 'weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().issue_weight ? cell.getData().issue_weight : " - ") + `</span> /
                                      <span class="` + (cell.getData().polish_weight_theme == 'text-red' ? 'text-red' : (cell.getData().polish_weight_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().po_f_pw ? cell.getData().po_f_pw : " - ") + `</span>`
                                  //      +
                                  // `<span class="` + (cell.getData().lab_weight_theme == 'text-red' ? 'text-red' : (cell.getData().lab_weight_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().lab_weight ? cell.getData().lab_weight : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Shape',
                          field: 'payment_terms',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().last_shape ? cell.getData().last_shape : " - ") + `</span> /
                                      <span>` + (cell.getData().polish_shape_name ? cell.getData().polish_shape_name : " - ") + `</span>`
                                  //      +
                                  // `<span>` + (cell.getData().lab_shape_name ? cell.getData().lab_shape_name : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Color',
                          field: 'due_date',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().last_color ? cell.getData().last_color : " - ") + `</span> /
                                      <span class="` + (cell.getData().polish_color_theme == 'text-red' ? 'text-red' : (cell.getData().polish_color_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().polish_shade_name ? cell.getData().polish_shade_name : " - ") + `</span>`
                                  //      +
                                  // `<span class="` + (cell.getData().lab_color_theme == 'text-red' ? 'text-red' : (cell.getData().lab_color_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().lab_shade_name ? cell.getData().lab_shade_name : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Purity',
                          field: 'certificate_no',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 90,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().last_purity ? cell.getData().last_purity : " - ") + `</span> /
                                      <span class="` + (cell.getData().polish_purity_theme == 'text-red' ? 'text-red' : (cell.getData().polish_purity_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().polish_purity_name ? cell.getData().polish_purity_name : " - ") + `</span>`
                                  //      +
                                  // `<span class="` + (cell.getData().lab_purity_theme == 'text-red' ? 'text-red' : (cell.getData().lab_purity_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().lab_purity_name ? cell.getData().lab_purity_name : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Cut',
                          field: 'cut',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 90,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().planning_cut ? cell.getData().planning_cut : " - ") + `</span> /
                                      <span>` + (cell.getData().polish_cut ? cell.getData().polish_cut : " - ") + `</span>`
                                  //      +
                                  // `<span>` + (cell.getData().lab_cut ? cell.getData().lab_cut : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Symmetry',
                          field: 'symmetry',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 90,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().planning_symmetry ? cell.getData().planning_symmetry : " - ") + `</span> /
                                      <span>` + (cell.getData().polish_symmetry ? cell.getData().polish_symmetry : " - ") + `</span>`
                                  //      +
                                  // `<span>` + (cell.getData().lab_cut ? cell.getData().lab_cut : " - ") + `</span>`
                          }
                      },
                      // {
                      //     title: 'LAB',
                      //     field: 'lab',
                      //     hozAlign: 'left',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     minWidth: 100,
                      //     download: true,
                      //     formatter(cell) {
                      //         return `<span>` + (cell.getData().lab ? cell.getData().lab : " - ") + `</span> /
                      //                 <span>` + (cell.getData().new_lab ? cell.getData().new_lab : " - ") + `</span>`
                      //     }
                      // },
                      {
                          title: 'Majuri',
                          field: 'total_majuri',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return cell.getData().total_majuri ? cell.getData().total_majuri : "-"
                          },
                      },
                      {
                          title: 'Party',
                          field: 'party_id',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return cell.getData().first_name ? (cell.getData().first_name + " " + (cell.getData().last_name ? cell.getData().last_name : "")) : "-"
                          }
                      },
                      {
                          title: 'Issue Date',
                          field: 'created_at',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Check Date',
                          field: 'po_date',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter: "textarea"
                      },
                      // {
                      //     title: 'Lab Date',
                      //     field: 'lab_date',
                      //     hozAlign: 'left',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     minWidth: 100,
                      //     download: true,
                      //     formatter: "textarea"
                      // },
                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
            } else {

              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + `/api/final_polish_comaprison_report`,
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem('token')
                      },
                  },
                  ajaxParams: params,
                  pagination: 'remote',
                  paginationSize: 50,
                  paginationSizeSelector: [50, 100, 200],
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'Packet No',
                          field: 'packet_code',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Stock No',
                          field: 'lab_code',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Weight',
                          field: 'weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 150,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().issue_weight ? cell.getData().issue_weight : " - ") + `</span> /
                                      <span class="` + (cell.getData().polish_weight_theme == 'text-red' ? 'text-red' : (cell.getData().polish_weight_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().po_f_pw ? cell.getData().po_f_pw : " - ") + `</span> /` +
                                  `<span class="` + (cell.getData().lab_weight_theme == 'text-red' ? 'text-red' : (cell.getData().lab_weight_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().lab_weight ? cell.getData().lab_weight : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Shape',
                          field: 'payment_terms',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 150,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().last_shape ? cell.getData().last_shape : " - ") + `</span> /
                                      <span>` + (cell.getData().polish_shape_name ? cell.getData().polish_shape_name : " - ") + `</span> /` +
                                  `<span>` + (cell.getData().lab_shape_name ? cell.getData().lab_shape_name : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Color',
                          field: 'due_date',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().last_color ? cell.getData().last_color : " - ") + `</span> /
                                      <span class="` + (cell.getData().polish_color_theme == 'text-red' ? 'text-red' : (cell.getData().polish_color_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().polish_shade_name ? cell.getData().polish_shade_name : " - ") + `</span> /` +
                                  `<span class="` + (cell.getData().lab_color_theme == 'text-red' ? 'text-red' : (cell.getData().lab_color_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().lab_shade_name ? cell.getData().lab_shade_name : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Purity',
                          field: 'certificate_no',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().last_purity ? cell.getData().last_purity : " - ") + `</span> /
                                      <span class="` + (cell.getData().polish_purity_theme == 'text-red' ? 'text-red' : (cell.getData().polish_purity_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().polish_purity_name ? cell.getData().polish_purity_name : " - ") + `</span> /` +
                                  `<span class="` + (cell.getData().lab_purity_theme == 'text-red' ? 'text-red' : (cell.getData().lab_purity_theme == 'text-green' ? 'text-green' : '')) + `">` + (cell.getData().lab_purity_name ? cell.getData().lab_purity_name : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Cut',
                          field: 'cut',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().planning_cut ? cell.getData().planning_cut : " - ") + `</span> /
                                      <span>` + (cell.getData().polish_cut ? cell.getData().polish_cut : " - ") + `</span> /` +
                                  `<span>` + (cell.getData().lab_cut ? cell.getData().lab_cut : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Polish',
                          field: 'polish',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().planning_polish ? cell.getData().planning_polish : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Symmetry',
                          field: 'symmetry',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().planning_symmetry ? cell.getData().planning_symmetry : " - ") + `</span> /
                                      <span>` + (cell.getData().polish_symmetry ? cell.getData().polish_symmetry : " - ") + `</span> /` +
                                  `<span>` + (cell.getData().lab_symmetry ? cell.getData().lab_symmetry : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Fluerocent',
                          field: 'fluerocent',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().lab_fluerocent ? cell.getData().lab_fluerocent : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'LAB',
                          field: 'lab',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return `<span>` + (cell.getData().lab ? cell.getData().lab : " - ") + `</span> /
                                      <span>` + (cell.getData().new_lab ? cell.getData().new_lab : " - ") + `</span>`
                          }
                      },
                      {
                          title: 'Majuri',
                          field: 'total_majuri',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter(cell) {
                              return cell.getData().total_majuri ? cell.getData().total_majuri : "-"
                          },
                      },
                      {
                          title: 'Party',
                          field: 'party_id',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 120,
                          download: true,
                          formatter(cell) {
                              return cell.getData().first_name ? (cell.getData().first_name + " " + (cell.getData().last_name ? cell.getData().last_name : "")) : "-"
                          }
                      },
                      {
                          title: 'Issue Date',
                          field: 'created_at',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Check Date',
                          field: 'po_date',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Lab Date',
                          field: 'lab_date',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          minWidth: 100,
                          download: true,
                          formatter: "textarea"
                      },
                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
            }


            // page_data.form_status = true
            // page_data.loading = true

            let promise = axios({
                url: "api/final_polish_comaprison_report_total",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.total = data.total
                    page_data.loading = false
                    page_data.form_status = false
                    page_data.generate = "gen"
                })
                .catch(_error => {
                    page_data.form_status = false
                    page_data.generate = "gen"

                });
        }
        const getSeries = () => {
            let params = {
                process_id: screenData.process_id,
            }
            let promise = axios({
                url: "api/get_final_polish_series",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.series_details = data.items

                })
                .catch(_error => {

                });
        }
        const getKapans = () => {
            let params = {
                series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
            }
            let promise = axios({
                url: "api/get_final_polish_kapan",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.kapan_details = data.items

                })
                .catch(_error => {

                });
        }
        const getPackets = () => {
            if (screenData.kapan_id.length > 0) {
                // console.warn('ff')

                let params = {
                    series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
                    kapan_id: (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
                }
                let promise = axios({
                    url: "api/get_final_polish_lot_items",
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        page_data.packets = data.items

                    })
                    .catch(_error => {

                    });

            } else {
                page_data.packets = []
                screenData.packets = []
            }
        }
        const getParties = () => {
            let params = {
                // process: page_data.process,
            }
            let promise = axios({
                url: "api/dp_account_polish_party",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.parties = data.items

                })
                .catch(_error => {

                });
        }

        // On reset filter
        const onResetFilter = () => {

            screenData.s_date = moment().startOf('month').format('YYYY-MM-DD')
            screenData.e_date = moment().format('YYYY-MM-DD')
            screenData.series_id = []
            screenData.kapan_id = []
            screenData.party_id = "0"
            screenData.packets = []
            screenData.issue_pending = true
            screenData.stone_id = "0"
            screenData.lab = "0"
            screenData.shape_group = '0'
            screenData.color_id = '0'
            screenData.purity_id = '0'
            screenData.size_weight = ''

        }

        const checkPermission = () => {
            if (userDetails.value.id == 8) {
                router.push("/app/app-masters");
            }
        }

        function getParameters() {
            getKapans()
            getPackets()
        }

        function seriesChange() {
            getParameters()
            screenData.kapan_id = []
            screenData.packets = []
        }

        function kapanChange() {
            getPackets()
            screenData.packets = []
        }
        const getShapes = () => {
            let params = {
                // process: page_data.process,
                // party_id: window.btoa(screenData.party_id),
            }
            let promise = axios({
                url: "api/dp_shape",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.shapes = data.items

                })
                .catch(_error => {

                });
        }
        const getColors = () => {
            let params = {
                // process: page_data.process,
                // party_id: window.btoa(screenData.party_id),
            }
            let promise = axios({
                url: "api/dp_color",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.colors = data.items

                })
                .catch(_error => {

                });
        }
        const getPurity = () => {
            let params = {
                // process: page_data.process,
                // party_id: window.btoa(screenData.party_id),
            }
            let promise = axios({
                url: "api/dp_purity",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.purities = data.items

                })
                .catch(_error => {

                });
        }
        onMounted(() => {
            checkPermission()
            getSeries()
            getParties()
            getDPStoneType()
            getPurity()
            getColors()
            getShapes()
            getParameters()

        })

        function printReportDetails() {
            let report_type = ''
            report_type = 'polish-comparison-report'

            // let url = BASE_URL + '/' + report_type + '?filter=' + printData
            //         window.open(url, '_blank');
            //         return

            let params = {
                report_type: report_type,
                filters: printData,
                items: {
                }
            }
            let promise = axios({
                url: "api/add_report_polish_comparison_pagination",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    const report_id = data.report_id
                    let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
                    window.open(url, '_blank');

                })
                .catch(_error => {

                });
            // add_report_details
        }
        const onExportXlsx = () => {
            let filename = "polish-comparison.xlsx";
            let params = {
                series_id: (printData.series_id.length > 0) ? printData.series_id : '',
                kapan_id: (printData.kapan_id.length > 0) ? printData.kapan_id : '',
                packets: (printData.packets.length > 0) ? printData.packets : '',
                party_id: printData.party_id != '0' ? printData.party_id : '',
                stone_id: printData.stone_id != '0' ? printData.stone_id : '',
                lab: printData.lab != '0' ? printData.lab : '',
                size_weight: printData.size_weight,
                shape_group: printData.shape_group != '0' ? printData.shape_group : '',
                color_id: printData.color_id != '0' ? printData.color_id : '',
                purity_id: printData.purity_id != '0' ? printData.purity_id : '',
                s_date: printData.s_date,
                e_date: printData.e_date,
                issue_pending: printData.issue_pending,
            }
            let promise = axios({
                url: "/api/download_polish_comparison_stock",
                method: "post",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                    // this.status = "success";

                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                })
                .catch((_error) => {
                    // this.status = "fail";
                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                    return [];
                });
        }

        return {
            page_data,
            screenData,
            userDetails,
            printData,
            onResetFilter,
            seriesChange,
            kapanChange,
            generateReport,
            printReportDetails,
            tableRef,
            onExportXlsx
        }
    }
})
</script>
