<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Loose Edit Request (Non-Certy)</h2>
      <div>
        <div class="flex justify-between items-center">
          <button class="btn btn-danger mx-2" @click="reject_request(this.$route.params.trans_id)"
            v-if="page_data.report_items.active_status == 1">Reject</button>
          <button class="btn btn-primary mx-2" @click="accept_request(this.$route.params.trans_id)"
            v-if="page_data.report_items.active_status == 1 && (user_details.user_type == 1 || user_details.user_type == 28)">Accept</button>
        </div>
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">

      <div id="responsive-table" class="p-5" v-if="page_data.loading == true">
        <div class="preview">
          <div class="overflow-x-auto">
            <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Records</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="page_data.generate == 'gen'">
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
            <div class="col-span-12 sm:col-span-6">

              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Request Code. :&nbsp;</label>
                <label for="update-profile-form-6" class="form-label">{{ page_data.report_items.request_code ?
                  page_data.report_items.request_code : '-' }}</label>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Request Type :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.request_type_name }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.transaction_no">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Transaction No :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.transaction_no }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Issue By :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.issue_user }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.issue_date }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Status :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label"
                  :class="{ 'text-primary-1': page_data.report_items.active_status == 2, 'text-primary-7': page_data.report_items.active_status == 1, 'text-primary-3': page_data.report_items.active_status == 3 }">{{
                    page_data.report_items.status ? page_data.report_items.status : '-' }}</label>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="intro-y box p-5 col-span-12 lg:col-span-12 mt-5" v-if="page_data.report_items.request_type == 1">

      <div class="border-b p-5">
          <div class="flex flex-col sm:flex-row items-center border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base mr-auto">Merge Details</h2>
        </div>
        </div>
      <div class="p-5">
                <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.filters_arr.transaction_code ? page_data.report_items.details_arr.filters_arr.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.filters_arr.stone_type ? page_data.report_items.details_arr.filters_arr.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Type. :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.filters_arr.transaction_type ? page_data.report_items.details_arr.filters_arr.transaction_type : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ page_data.report_items.details_arr.filters_arr.merge_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.details_arr.filters_arr.series_details.length">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Series : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in page_data.report_items.details_arr.filters_arr.series_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.details_arr.filters_arr.kapan_details.length">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Kapans : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in page_data.report_items.details_arr.filters_arr.kapan_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>

                    </div>
                </div>

            </div>
            <div class="p-5" v-if="page_data.report_items.details_arr.kapan_detail_arr.length > 0">
                <h2 class="text-lg font-medium mr-auto">Series/ Kapan List</h2>
                <div class="grid grid-cols-12 pt-5 text-center gap-2" v-if="page_data.report_items.details_arr.kapan_detail_arr.length > 0">
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 border w-full py-1 m-0" v-for="(item,index) in page_data.report_items.details_arr.kapan_detail_arr" :key="index">
                        <div class="px-2">

                            <table class="w-full">

                                <tr>
                                    <td class="w-40 text-left">
                                        {{ item.kapan_code }}
                                    </td>
                                    <td class="w-36 text-center">
                                        {{ item.pieces }}
                                    </td>
                                    <td class="w-36 text-right">
                                        {{ $h.myFloat(item.weight) }} Ct
                                    </td>
                                    <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-5">
                <div class="overflow-x-auto m-0 p-0 mt-5" v-for="(report_itm,report_index) in page_data.report_items.details_arr.color_arr" :key="report_index">

                    <table class="text-xs text-center w-full">

                        <thead>
                            <tr class="border bg-gray-200 dark:bg-dark-1">
                                <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in report_itm.heading1_arr" :key="shape_index" :colspan="report_itm.heading1_size_arr[shape_index]">{{ shape_item }}</th>
                            </tr>
                            <tr class="border bg-gray-100 dark:bg-dark-1">
                                <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in report_itm.heading2_arr" :key="size_index" :colspan="report_itm.size_arr_number[size_index]">{{ size_item }}</th>
                            </tr>
                            <tr class="border bg-gray-100 dark:bg-dark-1">
                                <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in report_itm.heading3_arr" :key="size_index">{{ size_item }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border" v-for="(size_value_item,size_value_index) in report_itm.size_value_arr" :key="size_value_index">
                                <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                            </tr>
                            <tr class="border">
                                <th class="border py-2" v-for="(item,inx) in report_itm.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>

    </div>
    <div class="intro-y box p-5 col-span-12 lg:col-span-12 mt-5" v-if="page_data.report_items.request_type == 2">

        <div class="border-b p-5">
          <div class="flex flex-col sm:flex-row items-center border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base mr-auto">Purchase Details</h2>
        </div>
          <div class="">
            <div class="grid grid-cols-12 gap-x-2 pb-10">
              <div class="col-span-12 sm:col-span-6">

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Purchase Code. :&nbsp;</label>
                  <label for="update-profile-form-6" class="form-label">{{
                    page_data.report_items.details_arr.trans_code ?
                    page_data.report_items.details_arr.trans_code : '-' }}</label>
                </div>
              </div>
              <!-- <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Stock Type :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                      "Non Certy Stock" : 'Certy Stock' }}</label>
                </div>

              </div> -->
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stone_type ?
                      page_data.report_items.details_arr.stone_type : '-' }}</label>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Branch :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.branch_name ?
                      page_data.report_items.details_arr.branch_name : '-' }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Party :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.party_name }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.details_arr.broker_name">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Broker :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.broker_name
                    }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.purchase_date ?
                      page_data.report_items.details_arr.purchase_date : '-' }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                      page_data.report_items.details_arr.total_pcs : page_data.report_items.details_arr.total_pcs }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                      page_data.report_items.details_arr.total_wt : page_data.report_items.details_arr.total_wt }}
                    Ct</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                      page_data.report_items.details_arr.total_amount : page_data.report_items.details_arr.total_amount
                    }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Brokerage :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                      page_data.report_items.details_arr.brokerage_amount :
                      page_data.report_items.details_arr.brokerage_amount }}</label>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.details_arr.certy_type">
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Purchase Type :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.certy_type == 1 ?
                      "LAB Pending" : (page_data.report_items.details_arr.certy_type == 2 ? "LAB Done" : " - ") }}</label>
                </div>

              </div>
            </div>

          </div>
        </div>
      <!-- END: HTML Table Data -->
      <div class="intro-y p-5">
        <div>

          <!-- <div class="flex flex-col sm:flex-row items-center border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Final Purchase Stock</h2>
          </div> -->
          <div class="mt-5">

            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">MAX Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.bunch_details" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_type == "1" ? "NATURAL" : (field.stone_type
                        == "2" ? "CVD" : (field.stone_type == "3" ? "HPHT" : (field.stone_type == "4" ? "D_CVD" :
                          (field.stone_type == "5" ? "D_HPHT" : "")))) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ (field.shape ? field.shape : '-') }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.charni_name ? field.charni_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ "Size" + field.size_type }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.pieces }}</td>
                      <td class="border-b border-r py-2 px-2">{{ $h.myFloat(field.weight) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stock_rate ? field.stock_rate : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.max_rate ? field.max_rate : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_rate ? field.sell_rate : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_per ? field.brokerage_per : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "-" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="intro-y box p-5 col-span-12 lg:col-span-12 mt-5" v-if="page_data.report_items.request_type == 3">

      <div>

        <div class="flex flex-col sm:flex-row items-center border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base mr-auto">Certy Packet Details</h2>
          <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" v-print="'#multiple-barcode'"
            v-if="page_data.report_items.active_status == 2">
            Print All
          </button>

        </div>
        <div class="mt-5">
          <div class="border-b">
            <div class="px-5">
              <div class="grid grid-cols-12 gap-x-2">
                <div class="col-span-12 sm:col-span-3">

                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold">Stone Type. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{
                      page_data.report_items.details_arr.stone_type_name ?
                      page_data.report_items.details_arr.stone_type_name : '-' }}</label>
                  </div>
                </div>
                <div class="col-span-12 sm:col-span-3">

                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold">Color Size. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{
                      page_data.report_items.details_arr.color_size_name ?
                      page_data.report_items.details_arr.color_size_name : '-' }}</label>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="border">
            <div class="overflow-x-auto">
              <table class="w-full border mt-3">
                <thead>
                  <tr>
                    <th class="w-4">No</th>
                    <th class="w-36" v-if="page_data.report_items.active_status == 2">Packet No</th>
                    <th>Weight (Ct)</th>
                    <th>LAB</th>
                    <!-- <th>Stone</th> -->
                    <th>Shape</th>
                    <th>Color</th>
                    <th>Purity</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b" v-for="(plan, index) in page_data.report_items.details_arr.packet_details"
                    :key="index">
                    <td class="w-4 text-center">{{ index + 1 }}</td>
                    <td class="text-center" v-if="page_data.report_items.active_status == 2">{{ plan.packet_code }}</td>
                    <!-- <td class="w-36">
                                <input v-model="plan.packet_code" disabled type="number" :id="'weight_' + index" class="form-control w-full" placeholder="Auto">
                            </td> -->
                    <td class="w-36">
                      <input v-model="plan.weight" type="number" :id="'weight_' + index" @change="FiledValueChange()" disabled
                        class="form-control w-full" placeholder="weight">
                    </td>
                    <td :id="'lab_' + index" class="p-0 m-0 w-36">
                      <TomSelect v-model="plan.lab" :options="{
                        placeholder: 'Select Material'
                      }" class="w-full" name="LAB" id="pos-form-2" @change="FiledValueChange()" disabled>
                        <option value="0">Select LAB</option>
                        <option value="IGI">IGI</option>
                        <option value="GIA">GIA</option>
                      </TomSelect>
                    </td>
                    <td :id="'shape_' + index" class="p-0 m-0 w-36">
                      <TomSelect v-model="plan.shape" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Shape" id="pos-form-2" @change="FiledValueChange()" disabled>
                        <option value="0">Select Shape</option>
                        <option v-for="item in page_data.shape_arr" :key="item.id" :value="item.id" disabled>{{ item.shape_name }}
                        </option>
                      </TomSelect>
                    </td>
                    <td :id="'color_' + index" class="p-0 m-0 w-36">
                      <TomSelect v-model="plan.color" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Color" id="pos-form-2" @change="FiledValueChange()" disabled>
                        <option value="0">Select Color</option>
                        <option v-for="item in page_data.color_arr" :key="item.id" :value="item.id">{{ item.shade_name }}
                        </option>
                      </TomSelect>
                    </td>
                    <td :id="'purity_' + index" class="p-0 m-0 w-36">
                      <TomSelect v-model="plan.purity" :options="{
                        placeholder: 'Select Purity'
                      }" class="w-full" name="Purity" id="pos-form-2" @change="FiledValueChange()" disabled>
                        <option value="0">Select Purity</option>
                        <option v-for="item in page_data.purity_arr" :key="item.id" :value="item.id">{{ item.purity_name
                        }}
                        </option>
                      </TomSelect>
                    </td>
                    <td class="w-20">
                      <div class="flex items-center">

                        <button type="button" class="text-theme-1 flex items-center" tabindex="-1"
                          v-print="'#' + plan.packet_code" v-if="page_data.report_items.active_status == 2">
                          <PrinterIcon class="w-5 h-5 mr-1" />
                        </button>
                        <p v-else></p>
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table>
              <div class="hidden" v-if="page_data.report_items.details_arr.packet_details.length > 0">
                <div id="multiple-barcode" class="absolute top-0 p-0 m-0">
                  <table style="page-break-before: always;" class="p-0" border="collapse"
                    v-for="(packet, index) in page_data.report_items.details_arr.packet_details" :key="index">
                    <tr class="text-center">
                      <th colspan="3" class="text-center">
                        <vue-barcode class="w-full" :value="packet.barcode_no"
                          :options="{ displayValue: true, height: 30, fontSize: 15, width: 2 }" />
                      </th>
                    </tr>
                    <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                      <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{
                        packet.packet_code }}</td>
                      <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
                      <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
                    </tr>
                  </table>
                </div>
              </div>
              <div class="hidden" v-if="page_data.report_items.details_arr.packet_details.length > 0">
                <table style="page-break-before: always;" class="absolute top-0 p-0 m-0" border="collapse"
                  v-for="(packet, index) in page_data.report_items.details_arr.packet_details" :key="index"
                  :id="packet.packet_code">
                  <tr class="text-center">
                    <th colspan="3" class="text-center">
                      <vue-barcode class="w-full" :value="packet.barcode_no"
                        :options="{ displayValue: true, height: 30, fontSize: 17, width: 2 }" />
                    </th>
                  </tr>
                  <tr class="text-center border border-l-0 border-r-0 border-black m-0">
                    <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{
                      packet.packet_code }}</td>
                    <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
                    <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
                  </tr>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  inject,
  computed
} from 'vue'
import {
  BASE_URL,
  BASE_URL_SELLING,
  BUILD_ENV,
  Auth_Token
} from '@/constants/config.js'
import moment from 'moment';
import {
  helper as $h
} from '@/utils/helper';
import {
  useRoute,
  useRouter
} from 'vue-router';
import store from '@/store';
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
  components: {
        VueBarcode
    },
  setup() {

    const swal = inject('$swal');
    const route = useRoute()
    const router = useRouter()
    const user_details = computed(() => store.state.user.currentUser)
    const AUTH_TOKEN = localStorage.getItem("token")
    const page_data = reactive({

      report_items: [],
      color_arr: [],
      purity_arr: [],
      shape_arr: [],
      size_purity_arr: [],
      generate: "no",
      loading: false,
      total_added_ct: 0,
      total_added_pieces: 0,
      total_packets: 0,
      total_weight: 0
    })

    const editDetails = () => {
      router.push('/app/app-final-polish/loose-request/edit/' + route.params.id)
    }

    const generateReport = () => {
      const params = {
        trans_id: route.params.trans_id
      };

      page_data.loading = true

      let promise = axios({
        url: "/api/fserc_grd",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.error == "YES") {
            swal("Warning!", data.message, "warning");
            page_data.generate = 'no';
            page_data.loading = false

            return;
          }

          page_data.report_items = data.item;
          // getSizePurityDetails()

          // console.log(page_data.report_items.stock_history)
          page_data.generate = 'gen';
          page_data.loading = false

        })
        .catch(_error => {

          page_data.generate = 'no';
          page_data.loading = false
        });
    }

    const reject_request = (id) => {

      swal({
        title: "Are you sure?",
        text: "You want to Reject Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Reject",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          page_data.failed_loading = true

          const params = {
            req_id: id,
            action_type: window.btoa("3")
          };

          let promise = axios({
            url: "api/fserc_ur",
            method: "POST",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if (data.status == "success") {
                swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: data.message,
                })
                generateReport()
              } else {
                swal({
                  title: "Warning!",
                  icon: "warning",
                  text: data.message,
                  type: "warning",
                })
              }

            })
            .catch(_error => {

            });
        }
      });
    }

    const accept_request = (id) => {

      swal({
        title: "Are you sure?",
        text: "You want to Accept Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          page_data.failed_loading = true

          const params = {
            req_id: id,
            action_type: window.btoa("2")
          };

          let promise = axios({
            url: "api/fserc_ur",
            method: "POST",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if (data.status == "success") {
                swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: data.message,
                })
                generateReport()
              } else {
                swal({
                  title: "Warning!",
                  icon: "warning",
                  text: data.message,
                  type: "warning",
                })
              }

            })
            .catch(_error => {

            });
        }
      });
    }
    // Get Shape Details
    const get_shape_details = async () => {

      let promise_url = "/api/dp_shape";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.shape_arr = data.items
        })
        .catch(_error => {
          page_data.shape_arr = []

        });
    }

    // Get Shape Details
    const get_color_details = async () => {

      let promise_url = "/api/dp_color";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.color_arr = data.items
        })
        .catch(_error => {
          page_data.color_arr = []

        });
    }
    // Get Shape Details
    const get_purity_details = async () => {

      let promise_url = "/api/dp_purity";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.purity_arr = data.items
        })
        .catch(_error => {
          page_data.purity_arr = []

        });
    }

    function getSizePurityDetails() {

      let stone_name = ''

      if (page_data.report_items.stone_type == 'NATURAL') {
        stone_name = 1;
      } else if (page_data.report_items.stone_type == 'CVD') {
        stone_name = 2;
      } else if (page_data.report_items.stone_type == 'HPHT') {
        stone_name = 3;
      } else if (page_data.report_items.stone_type == 'D_CVD') {
        stone_name = 4;
      } else if (page_data.report_items.stone_type == 'D_HPHT') {
        stone_name = 5;
      }

      const params = {
        stone_type: stone_name
      };
      let promise = axios({
        url: "/api/list_all_merge_segment_all_color",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          page_data.size_purity_arr = data.items;

        })
        .catch(_error => {
          page_data.size_purity_arr = [];

        });
    }

    onMounted(() => {
      generateReport()
      get_shape_details()
      get_color_details()
      get_purity_details()
    })

    return {
      page_data,
      editDetails,
      user_details,
      reject_request,
      accept_request,
    }
  }
})
</script>
