<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Series Summary Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label my-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label my-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                multiple
                class="w-full"
              >
              <!-- <option value="0">Select Kapan</option> -->
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label my-1"
                >Select Process</label
              >
              <TomSelect
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
              </TomSelect>
            </div>
        </div> -->
        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label my-1"
                >Select Process</label
              >
              <TomSelect
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
              </TomSelect>
            </div>
        </div> -->
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label my-1"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                :disabled="screenData.issue_pending"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label my-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"
              :disabled="screenData.issue_pending"
              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="screenData.issue_pending == false">
          <div>
              <label for="update-profile-form-6" class="form-label my-1"
                >Report Type</label
              >
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Final Polish Wise</option>
              <option value="2">Date Wise</option>
            </TomSelect>
            </div>
        </div>

        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              :disabled="(screenData.series_id == '0' && screenData.kapan_id.length == 0 && screenData.issue_pending == true) || (pagedata.loading == true)"
              @click="generateReport()"
            >
            Generate
            </button> -->
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.generate == 'gen'">
          <div class="mt-7 flex">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              v-print="'#generate-pdf'"
            >
            Print
            </button>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden" v-if="pagedata.generate == 'gen'">
              <h2 class="text-lg font-medium mr-auto" v-if="screenData.issue_pending == true">Series / Kapan Summary</h2>
              <div class="intro-y col-span-12 overflow-auto" v-if="screenData.issue_pending == true">
                <table class="mt-3 border-2">
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Series /  Kapan Total Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ pagedata.total_weight ? $h.myFloat(pagedata.total_weight): 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Manager</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Available Weight</th>
                    </tr>
                    <tr class="border-b" v-for="(assort_item,index) in pagedata.manager_details" :key="index">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8">{{ index + 1 }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ assort_item.first_name + ' ' + assort_item.last_name}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ assort_item.manager_weight ? $h.myFloat(assort_item.manager_weight) : 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="pagedata.manager_details.length > 0">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Manager Available Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ pagedata.manager_total_available ? $h.myFloat(pagedata.manager_total_available): 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Assortment Details</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Assortment Weight</th>
                    </tr>
                    <tr class="border-b" v-for="(assort_item,index) in pagedata.assort_details" :key="index">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8">{{ index + 1 }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ assort_item.assortment_name }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ assort_item.assort_weight ? $h.myFloat(assort_item.assort_weight) : 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="pagedata.assort_details.length > 0">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Assortment Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ pagedata.assort_total_weight ? $h.myFloat(pagedata.assort_total_weight): 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="pagedata.total_lose">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Lose Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ pagedata.total_lose.total_lose ? $h.myFloat(pagedata.total_lose.total_lose): 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="pagedata.final_polish_weight > 0">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Final Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ pagedata.final_polish_weight ? $h.myFloat(pagedata.final_polish_weight): 0 }} Ct</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="pagedata.estimate_total_available > 0">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-8"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">Est Available Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-60">{{ pagedata.estimate_total_available ? $h.myFloat(pagedata.estimate_total_available): 0 }} Ct</th>
                    </tr>
                </table>
              </div>
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="10">Issue</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="3">Failed</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Pieces</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Ghat Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Assort Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">New Polish Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Difference (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Difference (%)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Pieces</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(process_item,index) in pagedata.process_items" :key="index">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.process_code ? process_item.process_code : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.issue_pieces ? process_item.issue_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.issue_weight ? $h.myFloat(process_item.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.receive_weight ? $h.myFloat(process_item.receive_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.total_lose ? $h.myFloat(process_item.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.total_assortment ? $h.myFloat(process_item.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.polish_weight ? $h.myFloat(process_item.polish_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.new_polish_weight ? $h.myFloat(process_item.new_polish_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':process_item.polish_ghat < 0,'bg-green':process_item.polish_ghat > 0}">{{ process_item.polish_ghat ? $h.myFloat(process_item.polish_ghat) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':process_item.polish_ghat < 0,'bg-green':process_item.polish_ghat > 0}">{{ process_item.polish_ghat && process_item.polish_weight ? $h.myFloat(process_item.polish_ghat * 100 / process_item.polish_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.total_majuri ? $h.myFloat(process_item.total_majuri) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.failed_packet ? process_item.failed_packet : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.failed_weight? $h.myFloat(process_item.failed_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ process_item.failed_polish_weight? $h.myFloat(process_item.failed_polish_weight) : '0' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      processes: [],
      total_weight: '',
      item_details: [],
      assort_details: [],
      total_lose: [],
      manager_details: [],
      manager_total_available: '',
      final_polish_weight: '',
      assort_total_weight: '',
      estimate_total_available: '',
      process_items: [],
      loading: false,
      generate: 'no'
    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:[],
      process_id:'0',
      report_type:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true

    })
    const printData = reactive({
      series_id:'0',
      kapan_id:[],
      process_id:'0',
      report_type:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })
    const generateReport = () => {

      printData.series_id = screenData.series_id,
      printData.kapan_id = screenData.kapan_id,
      printData.process_id = screenData.process_id,
      printData.issue_date =  screenData.issue_date,
      printData.receive_date = screenData.receive_date,

      pagedata.loading  = true

      let params = {
        series_id : screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id : screenData.kapan_id.length > 0 ? screenData.kapan_id : '',
        process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date,
        issue_pending : screenData.issue_pending,
        report_type : screenData.report_type
      }
      let promise = axios({
        url: "api/get_series_summary_report_details",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.total_weight = data.total_weight
          pagedata.item_details = data.item_details
          pagedata.assort_details = data.assort_details
          pagedata.total_lose = data.total_lose
          pagedata.manager_details = data.manager_details
          pagedata.manager_total_available = data.manager_total_available
          pagedata.assort_total_weight = data.assort_total_weight
          pagedata.estimate_total_available = data.estimate_total_available
          pagedata.final_polish_weight = data.final_polish_weight
          pagedata.process_items = data.process_items
          pagedata.loading  = false
          pagedata.generate = 'gen'
        })
        .catch(_error => {
          pagedata.loading  = false

        });
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_rough_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    // const getProcesses = () => {
    //   let promise = axios({
    //     url: "api/dp_process",
    //     method: "post",
    //     baseURL: BASE_URL,
    //     // data: params,
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     config: { headers: { Accept: "application/json" } }
    //   });
    //   return promise
    //     .then(result => result.data)
    //     .then(data => {

    //       pagedata.processes = data.items

    //     })
    //     .catch(_error => {

    //     });
    // }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
      }
      let url = 'api/dp_rough_kapan'
      if(screenData.series_id == '0') {

      } else {
        url = 'api/dp_rough_kapan_by_series'
      }
      let promise = axios({
        url: url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = []

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = []
      screenData.process_id = '0'
      screenData.report_type= '1'
      screenData.issue_pending = true

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function seriesChange() {
      getKapans()
      // screenData.kapan_id = []
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      // getProcesses()
      getKapans()
      reInitOnResizeWindow()

    })

    return {
      tableRef,
      pagedata,
      printData,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      generateReport
    }
  }
})
</script>
