/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VGrid from "@revolist/vue3-datagrid";
import utils from "./utils";
import "./libs";
import axios from "axios";
import { BASE_URL } from "@/constants/config.js";
import print from 'vue3-print-nb'
// SASS Theme
import "./assets/sass/app.scss";

import { helper as $h } from '@/utils/helper'

//const BASE_URL = 'http://localhost/Avalanche_Full_Stack_CB/apis'

const app = createApp(App)
    .use(store)
    .use(router)
    .use($h)
    .use(print)
    .use(VueSweetalert2);


window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = BASE_URL;

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

const http = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
})

globalComponents(app);
utils(app);

app.mount("#app");
