<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">4P Rate Summary Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 xl:col-span-3">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Process Type</label
            >
            <TomSelect
              v-model="screenData.process_id"
              :options="{
                placeholder: 'Select Process Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Process Type'
                  }
                }
              }"
              class="w-full"
              @change="processChange"
            >
            <option value="">Select Process Tyoe</option>
            <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-3">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Series</label
            >
            <TomSelect
              v-model="screenData.series_id"
              :options="{
                placeholder: 'Select Series',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              class="w-full"
              @change="seriesChange"
            >
            <option value="0">Select Series</option>
            <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-3">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Kapan</label
            >
            <TomSelect
              v-model="screenData.kapan_id"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan'
                  }
                }
              }"
              class="w-full"
              @change="kapanChange"
            >
            <option value="0">Select Kapan</option>
            <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-3">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Packets</label
            >
            <TomSelect
              v-model="screenData.packets"
              :options="{
                placeholder: 'Select Packet',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Packets'
                  }
                }
              }"
              class="w-full"
              multiple
            >
            <option value="">Select Packets</option>
            <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Karigar</label
            >
            <TomSelect
              v-model="screenData.karigar_id"
              :options="{
                placeholder: 'Select Karigar',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Karigar'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Karigar</option>
            <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{karigar.last_name}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Start Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.issue_date"
            />
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div  class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Shape</label
              >
              <TomSelect
                v-model="screenData.shape_group"
                :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">All</option>
              <option v-for="shape in pagedata.shapes" :key="shape.id" :value="shape.shape_group">{{shape.shape_group}}</option>
              </TomSelect>
            </div>
          </div>
        <div class="col-span-6 md:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-1">
          <div class="mt-10">
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div class="mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="initTabulator"
            >
            Generate
            </button>

          </div>
          </div>
          <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.generate == 'gen'">
          <div class="mt-7 flex pt-3">

            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button> -->
            <div class="flex mt-5 sm:mt-0">

              <div class="dropdown w-1/2 sm:w-auto">
                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                          </a>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      employees: [],
      stones: [],
      processes: [],
      shapes: [],
      generate: "no"
    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      packets:[],
      process_id:'10',
      karigar_id:'0',
      shape_group:'0',
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      stone_id:'0',
      receive_date:moment().format('YYYY-MM-DD'),
    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      packets:[],
      process_id:'10',
      karigar_id:'0',
      shape_group:'0',
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      stone_id:'0',
      receive_date:moment().format('YYYY-MM-DD'),
    })

    const initTabulator = () => {
      pagedata.generate = "gen"
      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.packets = screenData.packets
      printData.process_id = screenData.process_id
      printData.karigar_id = screenData.karigar_id
      printData.shape_group = screenData.shape_group
      printData.issue_date = screenData.issue_date
      printData.stone_id = screenData.stone_id
      printData.receive_date = screenData.receive_date
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/4p_report_data_list',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          series_id :printData.series_id,
          kapan_id :printData.kapan_id,
          packets : printData.packets,
          stone_id : printData.stone_id == '0' ? '' : printData.stone_id,
          shape_group : printData.shape_group == '0' ? '' : printData.shape_group,
          process_id : printData.process_id,
          karigar_id : printData.karigar_id,
          issue_date : printData.issue_date,
          receive_date : printData.receive_date
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        // pagination: 'remote',
        // paginationSize: 20,
        // paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:false,
        placeholder: 'No matching records found',
        columns: [


        {
            title: 'Karigar Name',
            field: 'karigar_first_name',
            vertAlign: 'middle',
            print: false,
            minWidth:160,
            download: true,
            formatter(cell) {
              return cell.getData().karigar_first_name + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : '')
            }
          },

          {
            title: 'Total Packet',
            field: 'total_packets',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Issue Weight',
            field: 'issue_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
                return cell.getData().issue_weight ? parseFloat(cell.getData().issue_weight).toFixed(4) + ' Ct' : '0 Ct'
            }
          },
          {
            title: 'Receive Weight',
            field: 'receive_weight',
            print: false,
            download: true,
            formatter(cell) {
                return cell.getData().receive_weight ? parseFloat(cell.getData().receive_weight).toFixed(4) + ' Ct' : '0 Ct'
            }
          },
          {
            title: 'Total Ghat',
            field: 'ghat_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
                return cell.getData().receive_weight ? parseFloat(parseFloat(cell.getData().issue_weight - cell.getData().receive_weight)).toFixed(4) + ' Ct' : '0 Ct'
            }
          },
          {
            title: 'Total Majuri',
            field: 'total_majuri',
            print: false,
            download: true,
            formatter(cell) {
                return cell.getData().child == 1 ? cell.getData().total_majuri ? parseFloat(cell.getData().total_majuri).toFixed(2) : '-' : (cell.getData().total_majuri ? parseFloat(cell.getData().total_majuri).toFixed(0) : '-')
            }
          },
          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getShapes = () => {
      let params = {
        // process: pagedata.process,
        // party_id: window.btoa(screenData.party_id),
      }
      let promise = axios({
        url: "api/dp_shape_group",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.shapes = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: window.btoa(screenData.series_id),
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_kapan",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = '0'

        })
        .catch(_error => {

        });
    }
    const getPackets = () => {
      let params = {
        series_id: window.btoa(screenData.series_id),
        kapan_id: window.btoa(screenData.kapan_id),
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_packets",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.packets = data.items
          screenData.packets = []

        })
        .catch(_error => {

        });
    }
    const getEmployees = () => {
      let params = {
        process: screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    const getProcess = () => {
      let params = {
        // process: pagedata.process,
      }
      let promise = axios({
        url: "api/4p_process_list",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.processes = data.items

        })
        .catch(_error => {

        });
    }
    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.packets = []
      screenData.karigar_id = '0'
      screenData.process_id = '10'
      screenData.stone_id = '0'
      screenData.shape_group = '0'

      initTabulator()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      let filename = "4p-karigar.xlsx";
      let params = {
          series_id :printData.series_id,
          kapan_id :printData.kapan_id,
          packets : printData.packets,
          stone_id : printData.stone_id == '0' ? '' : printData.stone_id,
          shape_group : printData.shape_group == '0' ? '' : printData.shape_group,
          process_id : printData.process_id,
          karigar_id : printData.karigar_id,
          issue_date : printData.issue_date,
          receive_date : printData.receive_date
        }
      let promise = axios({
          url: "/api/download_4p_karigar_report",
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
          .then((result) => result.data)
          .then((data) => {
              FileDownload(data, filename);
          })
          .catch((_error) => {
              return [];
          });
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      getPackets()
    }
    function seriesChange() {
      getParameters()
      if(screenData.kapan_id != '0') {
        screenData.kapan_id = ''
      }
      if(screenData.packets.length > 0) {
        screenData.packets = []
      }
    }
    function kapanChange() {
      getPackets()
        if(screenData.packets.length > 0) {
          screenData.packets = []
        }
    }
    function processChange() {
      getSeries()
      getKapans()
      getPackets()
      getEmployees()
      screenData.series_id = ''
      screenData.kapan_id = ''
      screenData.karigar_id = ''
      screenData.packets = []
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      getEmployees()
      getShapes()
      getDPStoneType()
      getProcess()
      getParameters()
      reInitOnResizeWindow()

    })

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      processChange,
      initTabulator,
      printData
    }
  }
})
</script>
