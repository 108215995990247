<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Transfer Details</h2>
    </div>
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Records</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="pagedata.generate == 'gen'">
            <div class="p-5">
                <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.transaction_code ? pagedata.report_items.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stock Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? "Non Certy Stock" : 'Certy Stock' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.stock_type == 1">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stone_type ? pagedata.report_items.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.stock_type == 1">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Shape :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.shape_name ? pagedata.report_items.shape_name : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">From Branch :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.from_branch_name ? pagedata.report_items.from_branch_name : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">To Branch :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.to_branch_name ? pagedata.report_items.to_branch_name : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.issue_date ? pagedata.report_items.issue_date : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_packets : pagedata.total_packets }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_wt : pagedata.total_wt }} Ct</label>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <div class="intro-y col-span-12 lg:col-span-12 mt-5">
        <!-- BEGIN: Input -->
        <div class="intro-y box">

            <div class="p-5">
                <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto ml-5">Final Polish Stock</h2>
                </div>
                <div class="border mt-5 p-0 shadow" v-if="pagedata.report_items.stock_type == 1">

                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Final Polish Details</h2>
                    </div>
                    <div class="border mt-5 p-0 shadow-md mt-5">
                        <div class="overflow-x-auto mt-5" v-for="(trans_item,trans_index) in pagedata.report_items.trans_bunch_details" :key="trans_index">
                            <template v-if="trans_item.charni_data.length > 0">
                                <h2 class="font-medium text-base mr-auto ml-5">{{ trans_item.color_name }}</h2>
                                <table class="w-full text-center text-xs">
                                    <thead>
                                        <template v-for="(purity_item,prt_inx) in pagedata.size_purity_arr" :key="prt_inx">
                                            <tr class="bg-theme-14" v-if="purity_item.color_size == trans_item.color_size && purity_item.purity_items.length > 0">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Purity</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">All</th>
                                                <template v-for="(item,index) in purity_item.purity_items" :key="index">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">{{ item.purity }}</th>
                                                </template>
                                            </tr>
                                            <tr class="bg-theme-14" v-if="purity_item.color_size == trans_item.color_size && purity_item.purity_items.length > 0">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2"></th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Total</th>
                                                <template v-for="(item,index) in purity_item.purity_items" :key="index">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Size {{ item.size }}</th>
                                                </template>
                                            </tr>
                                            <tr class="bg-theme-14" v-if="purity_item.color_size == trans_item.color_size && purity_item.purity_items.length > 0">
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Stock(Ct)</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Pcs</th>
                                                <template v-for="(item,index) in purity_item.purity_items" :key="index">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock(Ct) </th>
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS </th>
                                                </template>
                                            </tr>
                                        </template>
                                    </thead>
                                    <tbody class="text-center" v-for="(field,index) in trans_item['charni_data']" :key="index">
                                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-if="field.new_weight > 0">
                                            <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                            <td class="border-b border-r py-2 px-2">{{ field.charni_name }}</td>
                                            <td class="border-b py-2 px-2">{{ field.new_weight ? $h.myFloat(field.new_weight) : "0" }} Ct</td>
                                            <td class="border-b border-r py-2 px-2">{{ field.new_pcs ? field.new_pcs : "0" }}</td>
                                            <template v-for="(item,inx) in field.sub_charni_arr" :key="inx">
                                                <td class="border-b py-2 px-2">{{ item.new_weight ? $h.myFloat(item.new_weight) : "0" }} Ct</td>
                                                <td class="border-b border-r py-2 px-2">{{ item.new_pcs ? item.new_pcs : "0" }}</td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>

                            </template>
                        </div>
                        <div v-if="pagedata.loading == true" class="text-md font-medium mr-auto text-center my-8">
                            Loading
                        </div>
                        <div v-if="pagedata.report_items.trans_bunch_details.length == 0 && pagedata.loading == false" class="text-md font-medium mr-auto text-center my-8">
                            Records Not Found
                        </div>
                    </div>

                </div>
                <div class="border mt-5 p-0 shadow" v-if="pagedata.report_items.stock_type == 2">

                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base ml-3 mr-auto">Selected Stock</h2>
                    </div>
                    <div class="py-5 px-3">
                        <div class="border mt-2 p-0 shadow-">

                            <div class="overflow-x-auto tableFixHead">
                                <table class="w-full text-center text-xs">
                                    <thead>
                                        <tr class="bg-theme-14">
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Sr No.</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Stock No</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Weight</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">LAB</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Shape</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Color</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Purity</th>
                                        </tr>
                                        <tr class="bg-gray-200">
                                            <td class="border-b py-2 px-2">Total</td>
                                            <td class="border-b py-2 px-2">{{ pagedata.total_packets }}</td>
                                            <td class="border-b py-2 px-2">{{ $h.myFloat(pagedata.total_weight) }}</td>
                                            <td class="border-b py-2 px-2"></td>
                                            <td class="border-b py-2 px-2"></td>
                                            <td class="border-b py-2 px-2"></td>
                                            <td class="border-b py-2 px-2"></td>
                                        </tr>
                                    </thead>
                                    <tbody class="text-center">
                                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in pagedata.report_items.trans_certy_details" :key="index">
                                            <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                            <td class="border-b py-2 px-2">{{ field.pack_no }}</td>
                                            <td class="border-b py-2 px-2">{{ field.weight}}</td>
                                            <td class="border-b py-2 px-2">{{ field.lab }}</td>
                                            <td class="border-b py-2 px-2">{{ field.shape }}</td>
                                            <td class="border-b py-2 px-2">{{ field.color }}</td>
                                            <td class="border-b py-2 px-2">{{ field.purity }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="pagedata.report_items.trans_certy_details.length == 0" class="text-md font-medium mr-auto text-center my-8">
                                Records Not Found
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
  BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import moment from 'moment';
import {
    helper as $h
} from '@/utils/helper';
import {
    useRoute,
    useRouter
} from 'vue-router';

export default defineComponent({
    setup() {

        const swal = inject('$swal');
        const route = useRoute()
        const router = useRouter()
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const pagedata = reactive({

            report_items: [],
            size_purity_arr: [],
            generate: "no",
            loading: false,
            total_added_ct: 0,
            total_added_pieces: 0,
            total_packets: 0,
            total_weight: 0
        })

        const editDetails = () => {
            router.push('/app/app-polish-ready/ready/merge-lot-new/' + route.params.id)
        }

        const generateReport = () => {
            const params = {
                trans_id: route.params.trans_id
            };

            pagedata.loading = true

            let promise = axios({
                url: "/api/get_single_transaction_details",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        swal("Warning!", data.message, "warning");
                        pagedata.generate = 'no';
                        pagedata.loading = false

                        return;
                    }

                    pagedata.report_items = data.item;

                    if (pagedata.report_items.stock_type == 1) {

                        // pagedata.report_items.trans_bunch_details.forEach(element => {
                        //     calculateTotal(element)
                        //     calculateTotalPieces(element)

                        // });
                    } else {}
                    // calculateCertyTotal()
                    getSizePurityDetails()
                    pagedata.generate = 'gen';
                    pagedata.loading = false

                })
                .catch(_error => {

                    pagedata.generate = 'no';
                    pagedata.loading = false
                });
        }

        const calculateCertyTotal = () => {
            let total_weight = 0
            pagedata.total_packets = 0

            let item_length = pagedata.report_items.trans_certy_details.length
            let item_details = pagedata.report_items.trans_certy_details

            if (item_length > 0) {
                console.log(item_length)

                for (let i = 0; i < item_length; i++) {
                    console.log(item_details[i].weight)
                    total_weight = $h.myFloat(parseFloat(total_weight) + (item_details[i].weight ? parseFloat(item_details[i].weight) : 0))
                    pagedata.total_packets += 1
                }

                pagedata.total_weight = total_weight
            }
            console.warn(pagedata.report_items.trans_certy_details)

        }

        const calculateTotal = (item) => {
            item.new_weight = 0
            item.sub_charni_arr.forEach(element => {
                item.new_weight = $h.myFloat(parseFloat(item.new_weight) + (element.new_weight ? parseFloat(element.new_weight) : 0))
            });
            pagedata.total_added_ct = 0

            pagedata.report_items.trans_bunch_details.forEach(element => {

                pagedata.total_added_ct = $h.myFloat(pagedata.total_added_ct + (element.new_weight ? parseFloat(element.new_weight) : 0))
            });

        }
        const calculateTotalPieces = (item) => {
            let new_pcs = 0
            console.warn(item.sub_charni_arr)
            item.sub_charni_arr.forEach(element => {
                new_pcs = $h.myFloat(parseFloat(new_pcs) + (element.new_pcs ? parseFloat(element.new_pcs) : 0))
            });

            item.new_pcs = new_pcs
            pagedata.total_added_pieces = 0

            pagedata.report_items.trans_bunch_details.forEach(element => {

                pagedata.total_added_pieces = $h.myFloat(pagedata.total_added_pieces + (element.new_pcs ? parseFloat(element.new_pcs) : 0))
            });
        }

        function getSizePurityDetails() {

          let stone_name = ''

              if(pagedata.report_items.stone_type == 'NATURAL') {
                stone_name = 1;
              } else if(pagedata.report_items.stone_type == 'CVD') {
                stone_name = 2;
              } else if(pagedata.report_items.stone_type == 'HPHT') {
                stone_name = 3;
              } else if(pagedata.report_items.stone_type == 'D_CVD') {
                stone_name = 4;
              } else if(pagedata.report_items.stone_type == 'D_HPHT') {
                stone_name = 5;
              }

            const params = {
                stone_type: stone_name
            };
            let promise = axios({
                url: "/api/list_all_merge_segment_all_color",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.size_purity_arr = data.items;

                })
                .catch(_error => {
                    pagedata.size_purity_arr = [];

                });
        }

        function printReportDetails() {
            // let report_type = 'final-polish-stock-compatison'

            // let params = {
            //     report_type: report_type,
            //     filters: [],
            //     items: pagedata.report_items
            // }
            // let promise = axios({
            //     url: "/add_report_details",
            //     method: "post",
            //     baseURL: BASE_URL_SELLING,
            //     data: params,
            //     headers: {
            //         Authorization: "Bearer " + AUTH_TOKEN
            //     },
            //     config: {
            //         headers: {
            //             Accept: "application/json"
            //         }
            //     }
            // });
            // return promise
            //     .then(result => result.data)
            //     .then(data => {

            //         const report_id = data.report_id

            //         let url = BASE_URL_SELLING + '/' + data.report_type + '?id=' + window.btoa(report_id)
            //         window.open(url, '_blank');

            //     })
            //     .catch(_error => {

            //     });
            // add_report_details
        }

        onMounted(() => {
            generateReport()
        })

        return {
            pagedata,
            printReportDetails,
            editDetails
        }
    }
})
</script>
