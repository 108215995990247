<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
      <h2 class="text-lg font-medium mr-auto">Rough To Polish (Final Recieve)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a href="javascript:;" data-toggle="modal" data-target="#new-receive-modal" class="btn btn-primary shadow-md mr-2"
          @click="resetForm">New Receive</a>
        <div class="dropdown w-1/2 sm:w-auto">
          <button class="dropdown-toggle btn btn-outline-secondary bg-white w-full sm:w-auto" aria-expanded="false">
            <FileTextIcon class="w-4 h-4 mr-2" /> Export
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a id="tabulator-export-csv" href="javascript:;"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExportCsv">
                <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
              </a>
              <a id="tabulator-export-json" href="javascript:;"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExportJson">
                <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
              </a>
              <a id="tabulator-export-xlsx" href="javascript:;"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExportXlsx">
                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
              </a>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box px-5 py-3 mt-3">
      <div class="grid grid-cols-12 gap-x-2">

        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">Start Date</label>
            <input type="date" id="update-profile-form-13" class="form-control" v-model="filterData.s_date" />
          </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">End Date</label>
            <input type="date" id="update-profile-form-13" class="form-control" v-model="filterData.e_date" />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">Select Series</label>
            <TomSelect v-model="filterData.series_id" :options="{
              placeholder: 'Select Series',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Series Name'
                }
              }
            }" class="w-full" @change="RTPKapans">
              <option value="0">Select Series</option>
              <option v-for="(item, index) in page_data.series_list" :key="index" :value="item.id">{{ item.series_code }}
              </option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">Select Kapan</label>
            <TomSelect v-model="filterData.kapan_id" multiple :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Kapan Name'
                }
              }
            }" class="w-full">
              <option value="">Select Kapan</option>
              <option v-for="(item, index) in page_data.kapan_list" :key="index" :value="item.id">{{ item.kapan_code }}
              </option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">Kapan Status</label>
            <TomSelect v-model="filterData.status" :options="{
              placeholder: 'Select Status',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Select Status'
                }
              }
            }" class="w-full">
              <option value="0">Select Status</option>
              <option value="1">Active</option>
              <option value="2">Received</option>
              <option value="3">Cancelled</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-2">

            <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-2">

            <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5"
              @click="initTabulator()">
              Generate
              <LoadingIcon icon="bars" class="w-8 h-8" v-if="page_data.loading == true" />
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Color modal-->

    <div id="new-receive-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Receive Transaction : {{  kapanForm.transaction_code }}</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12 sm:col-span-4">
              <label for="pos-form-1" class="form-label required">Select Kapan</label>
              <TomSelect v-model="kapanForm.kapan_id" :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan Name'
                  }
                }
              }" class="w-full" @change="RTPTransaction">
                <option value="0">Select Kapan</option>
                <option v-for="(item, index) in page_data.kapan_form_list" :key="index" :value="item.id">{{
                  item.kapan_code }}
                </option>
              </TomSelect>
            </div>
            <div class="col-span-12 sm:col-span-8">
              <label for="pos-form-1" class="form-label required">Select Transaction</label>
              <div v-if="kapanForm.trans_id == ''">

                <TomSelect v-model="kapanForm.transaction_id" multiple :options="{
                  placeholder: 'Select Transactions',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Transactions'
                    }
                  }
                }" class="w-full" @change="getTransactionHistory">
                  <option value="">Select Transactions</option>
                  <option v-for="(item, index) in page_data.transaction_form_list" :key="index" :value="item.id">{{
                    item.transaction_code }} ({{ item.issue_pcs }} PCS | {{ $h.myFloat(item.issue_weight) }} CT) ({{
    item.company_name }})
                  </option>
                </TomSelect>
              </div>
              <div v-else>

                <TomSelect v-model="kapanForm.transaction_id" multiple disabled :options="{
                  placeholder: 'Select Transactions',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Transactions'
                    }
                  }
                }" class="w-full" @change="getTransactionHistory">
                  <option value="">Select Transactions</option>
                  <option v-for="(item, index) in page_data.transaction_form_list" :key="index" :value="item.id">{{
                    item.transaction_code }} ({{ item.issue_pcs }} PCS | {{ $h.myFloat(item.issue_weight) }} CT) ({{
    item.company_name }})
                  </option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-12 mt-3" v-if="kapanForm.transaction_arr.length > 0">
              <div class="overflow-auto">
                <table class="border text-center">
                  <tr class="border bg-gray-200">
                    <td class="p-2 border">#</td>
                    <td class="p-2 border">RECTIVE <br>TRANSACTION</td>
                    <td class="p-2 border">ISSUE <br>TRANSACTION</td>
                    <td class="p-2 border">PARTY</td>
                    <td class="p-2 border">NEW LOOSE (Ct)</td>
                    <td class="p-2 border">NEW CERTY (PCS)</td>
                    <td class="p-2 border">NEW CERTY (CT)</td>
                    <td class="p-2 border">TOTAL RECEIVED (CT)</td>
                    <td class="p-2 border">Receive Date</td>
                    <td class="p-2 border">Part Collection</td>
                    <td class="p-2 border">Status</td>
                  </tr>
                  <tr class="border" v-for="(item, index) in kapanForm.transaction_arr" :key="index">
                    <td class="w-8 border">{{ index + 1 }}</td>
                    <td class="w-20 border">{{ item.transaction_code }}</td>
                    <td class="w-20 border">{{ item.issue_code }}</td>
                    <td class="w-20 border">{{ item.company_name }}</td>
                    <td class="w-20 border">{{ (item.loose_weight ? $h.myFloat(item.loose_weight) : "0") }}</td>
                    <td class="w-20 border">{{ (item.certified_pcs ? $h.myFloat(item.certified_pcs) : "0") }}</td>
                    <td class="w-20 border">{{ (item.certified_weight ? $h.myFloat(item.certified_weight) : "0") }}</td>
                    <td class="w-20 border">{{ (item.total_weight ? $h.myFloat(item.total_weight) : "0") }}</td>
                    <td class="w-20 border">{{ (item.receive_date) }}</td>
                    <td class="w-20 border">
                      <span class="text-theme-9" v-if="item.part_collection == 1">Full</span>
                      <span class="text-theme-1" v-if="item.part_collection == 2">Part</span>
                    </td>
                    <td class="w-20 border">
                      <span class="text-theme-1" v-if="item.active_status == 1">Available</span>
                      <span class="text-theme-9" v-if="item.active_status == 2">Merged</span>
                      <span class="text-theme-6" v-if="item.active_status == 3">Cancelled</span>
                    </td>
                  </tr>
                </table>
              </div>

            </div>
            <div class="col-span-12">
              <h2 for="pos-form-1" class="font-medium text-base mr-auto form-label">New Receive Details</h2>
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="update-profile-form-7" class="form-label">Total Polish CTS <span class="text-primary-3">
                  *</span></label>
              <div class="" id="total_weight">

                <input v-model="kapanForm.total_weight" type="text" autocomplete="off" class="form-control py-1"
                  :class="{ 'border-theme-6': validate.total_weight.$error }" placeholder="Enter Weight"
                  @keypress="$h.preventInvalidInput" />
                <template v-if="validate.total_weight.$error">
                  <div v-for="(error, index) in validate.total_weight.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="update-profile-form-7" class="form-label">Total Loose CTS </label>
              <div class="" id="loose_weight">

                <input v-model="kapanForm.loose_weight" type="text" autocomplete="off" class="form-control py-1"
                  placeholder="Enter Weight" @keypress="$h.preventInvalidInput" />
              </div>
            </div>
            <div class="md:col-span-2">
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="update-profile-form-7" class="form-label">Total Certy CTS </label>
              <div class="" id="certified_weight">

                <input v-model="kapanForm.certified_weight" type="text" autocomplete="off" class="form-control py-1"
                  placeholder="Enter Weight" @keypress="$h.preventInvalidInput" />
              </div>
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="update-profile-form-7" class="form-label">Total Certy PCS </label>
              <div class="" id="certified_pcs">

                <input v-model="kapanForm.certified_pcs" type="text" autocomplete="off" class="form-control py-1"
                  placeholder="Enter Pieces" @keypress="$h.preventInvalidInputInt" />
              </div>
            </div>
            <div class="md:col-span-2">
            </div>
            <div class="col-span-12 md:col-span-4 font-bold">
              Total Polish CTS: {{ (kapanForm.total_weight ? kapanForm.total_weight : 0) }} CT
            </div>
            <div class="col-span-12 md:col-span-4 font-bold">
              Total Polish CTS: {{ (kapanForm.loose_weight ? kapanForm.loose_weight : 0) }} CT
            </div>
            <div class="md:col-span-2">
            </div>
            <div class="col-span-12 md:col-span-4 font-bold">
              Total Certy CTS: {{ (kapanForm.certified_weight ? kapanForm.certified_weight : 0) }} CT
            </div>
            <div class="col-span-12 md:col-span-4 font-bold">
              Total Certy CTS: {{ (kapanForm.certified_pcs ? kapanForm.certified_pcs : 0) }} PCS
            </div>
            <div class="md:col-span-2">
            </div>
            <div class="col-span-12">
              <div class="flex items-center">
                <input type="checkbox" class="form-check-input" v-model="kapanForm.part_collection">
                <label class="form-check-label" for="exampleCheck1">* Mark as Part Receive</label>
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32"
              :disabled="kapanForm.transaction_id.length == 0 || page_data.submit == true" @click="submitForm">
              Save Details
            </button>
            <button type="button" v-if="kapanForm.trans_id != '' || page_data.destroy == true"
              class="btn btn-danger w-32 ml-1" @click="deleteItem" :disabled="kapanForm.transaction_id.length == 0">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Details saved successfully!</div>
        <div class="text-gray-600 mt-1">

        </div>
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Something went wrong!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, inject, watch } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import {
  BASE_URL,
  BASE_URL_SELLING,
  BUILD_ENV,
  Auth_Token
} from '@/constants/config.js'
import moment from 'moment'
import {
  helper as $h
} from '@/utils/helper'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()

    const page_data = reactive({
      series_list: [],
      kapan_list: [],
      transaction_temp: [],
      submit: false,
      destroy: false,

      // Form
      kapan_form_list: [],
      transaction_form_list: [],
    })

    const AUTH_TOKEN = localStorage.getItem("token")

    const filterData = reactive({
      s_date: moment().startOf('month').format('YYYY-MM-DD'),
      e_date: moment().format('YYYY-MM-DD'),
      kapan_id: [],
      series_id: "0",
      status: "0",
    })

    const kapanForm = reactive({
      kapan_id: '0',
      trans_id: '',
      transaction_code: '',
      total_pcs: '',
      total_weight: '',
      loose_pcs: '',
      loose_weight: '',
      certified_pcs: '',
      certified_weight: '',
      transaction_id: [],
      transaction_arr: [],
      part_collection: false
    })
    const swal = inject('$swal')

    const rules = {
      total_weight: {
        required,
      },
    }

    const validate = useVuelidate(rules, toRefs(kapanForm))

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/rtprc_records',
        ajaxConfig: {
          method: "GET", //set request type to Position
          headers: {
            "Content-type": 'application/json; charset=utf-8', //set specific content type
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        },
        ajaxParams: {
          s_date: filterData.s_date,
          e_date: filterData.e_date,
          kapan_id: filterData.kapan_id,
          series_id: (filterData.series_id != "0" ? window.btoa(filterData.series_id) : ""),
          status: (filterData.status != "0" ? window.btoa(filterData.status) : "")
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Receive Transaction',
            minWidth: 150,
            responsive: 0,
            field: 'transaction_code',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Kapan Name',
            minWidth: 150,
            responsive: 0,
            field: 'kapan_code',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {

            title: 'Issue Transaction',
            minWidth: 200,
            field: 'transaction_list',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Final Weight',
            minWidth: 100,
            field: 'total_weight',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Loose Weight',
            minWidth: 100,
            field: 'loose_weight',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Certy PCS',
            minWidth: 100,
            field: 'certified_pcs',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Certy Weight',
            minWidth: 100,
            field: 'certified_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Receive Date',
            width: 100,
            field: 'receive_date',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false,
            formatter: "textarea"
          },
          {
            title: 'Part Collection',
            width: 100,
            field: 'part_collection',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false,
            formatter(cell) {

              if (cell.getData().part_collection == 1) {
                return `<div class="flex items-center lg:justify-center text-theme-9">
                   Full
                </div>`
              } else if (cell.getData().part_collection == 2) {
                return `<div class="flex items-center lg:justify-center text-theme-1">
                   Part
                </div>`
              }
            }
          },
          {
            title: 'Status',
            width: 100,
            field: 'active_status',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false,
            formatter(cell) {

              if (cell.getData().active_status == 1) {
                return `<div class="flex items-center lg:justify-center text-theme-1">
                   Available
                </div>`
              } else if (cell.getData().active_status == 2) {
                return `<div class="flex items-center lg:justify-center text-theme-9">
                  Merged
                </div>`
              } else if (cell.getData().active_status == 3) {
                return `<div class="flex items-center lg:justify-center text-theme-6">
                   Cancelled
                </div>`
              }
            }
          },
          {
            title: 'Actions',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false,
            formatter(cell) {

              if (cell.getData().active_status == 1) {

                const a = cash(`<div class="flex lg:justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                  </a>

                </div>`)
                cash(a).on('click', function () {
                  getDetails(cell.getData().id);
                })

                return a[0]
              }
            }
          },

          // For print format
          {
            title: 'Receive Transaction',
            field: 'transaction_code',
            visible: false,
            print: true,
            download: true,
          },
          {
            title: 'Kapan Name',
            field: 'kapan_code',
            visible: false,
            print: true,
            download: true,
          },
          {

            title: 'Issue Transaction',
            field: 'transaction_list',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Final Weight',
            field: 'total_weight',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Loose Weight',
            field: 'loose_weight',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Certy PCS',
            field: 'certified_pcs',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Certy Weight',
            field: 'certified_weight',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Receive Date',
            field: 'receive_date',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Part Collection',
            field: 'part_collection',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              if (cell.getValue() == 1) {
                return 'Full'
              } else if (cell.getValue() == 2) {
                return 'Part'
              }
            }
          },
          {
            title: 'Status',
            field: 'active_status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              if (cell.getValue() == 1) {
                return 'Active'
              } else if (cell.getValue() == 2) {
                return 'Received'
              } else if (cell.getValue() == 3) {
                return 'Cancelled'
              }
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      // tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    const getDetails = (id) => {
      console.log(id);
      const params = {
        "trans_id": window.btoa(id),
      };

      let promise = axios({
        url: "api/rtprc_edit",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.status == "success") {

          } else {
            swal("Warning", data.message, "warning");
          }

          kapanForm.kapan_id = String(data.items.kapan_id)
          kapanForm.trans_id = data.items.id
          kapanForm.transaction_code = data.items.transaction_code
          kapanForm.total_pcs = data.items.total_pcs
          kapanForm.total_weight = data.items.total_weight
          kapanForm.loose_pcs = data.items.loose_pcs
          kapanForm.loose_weight = data.items.loose_weight
          kapanForm.certified_pcs = data.items.certified_pcs
          kapanForm.certified_weight = data.items.certified_weight
          kapanForm.part_collection = (data.items.part_collection == 2 ? true : false)
          page_data.transaction_temp = data.items.transaction_id

          console.log(kapanForm.part_collection)


          cash('#new-receive-modal').modal('show')

        })
        .catch(_error => {
          swal("Warning", "Something went wrong.", "warning");
        });
    }

    const getTransactionHistory = () => {
      const params = {
        "transaction_id": kapanForm.transaction_id,
        "trans_id": window.btoa(kapanForm.trans_id ? kapanForm.trans_id : "")
      };

      let promise = axios({
        url: "api/rtprc_transaction_history",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.status == "success") {

          } else {
            // swal("Warning", data.message, "warning");
          }

          kapanForm.transaction_arr = data.items

        })
        .catch(_error => {
          kapanForm.transaction_arr = []
          // swal("Warning", "Something went wrong.", "warning");
        });
    }

    const submitForm = () => {

      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {

        page_data.submit = true

        // Validate Kapan Weights
        // let length = kapanForm.transaction_arr.length

        let is_filled = true;

        // for (let index = 0; index < length; index++) {


        // }
        if (!(kapanForm.loose_weight > 0) && !(kapanForm.certified_weight > 0)) {
          is_filled = false
          swal("Warning", "Loose OR Certy Stock weight required", "warning");
        }
        if ((kapanForm.certified_pcs > 0 && (kapanForm.certified_weight == "" || kapanForm.certified_weight == 0)) ||
          (kapanForm.certified_weight > 0 && (kapanForm.certified_pcs == "" || kapanForm.certified_pcs == 0))) {
          is_filled = false
          swal("Warning", "Certy Stock pieces and weight required", "warning");
        }

        if (parseFloat(kapanForm.total_weight) != $h.myFloat(parseFloat(kapanForm.loose_weight ? kapanForm.loose_weight : 0) + parseFloat(kapanForm.certified_weight ? kapanForm.certified_weight : 0))) {
          is_filled = false
          swal("Warning", "Total Received weight must be equal to Loose and Certy Weight.", "warning");

        }
        if (is_filled == false) {
          page_data.submit = false
          return;
        }
        const params = {
          "kapan_id": (kapanForm.kapan_id != "0" ? window.btoa(kapanForm.kapan_id) : ""),
          "trans_id": (kapanForm.trans_id ? window.btoa(kapanForm.trans_id) : ""),
          "total_pcs": (kapanForm.total_pcs ? window.btoa(kapanForm.total_pcs) : ""),
          "total_weight": (kapanForm.total_weight ? window.btoa(kapanForm.total_weight) : ""),
          "loose_pcs": (kapanForm.loose_pcs ? window.btoa(kapanForm.loose_pcs) : ""),
          "loose_weight": (kapanForm.loose_weight ? window.btoa(kapanForm.loose_weight) : ""),
          "certified_pcs": (kapanForm.certified_pcs ? window.btoa(kapanForm.certified_pcs) : ""),
          "certified_weight": (kapanForm.certified_weight ? window.btoa(kapanForm.certified_weight) : ""),
          "transaction_id": kapanForm.transaction_id,
          "part_collection": (kapanForm.part_collection == true ? 2 : 1),
          // "transaction_arr": kapanForm.transaction_arr,
        };
        let promise_url = '';
        if (kapanForm.trans_id != '' && kapanForm.trans_id != null) {
          promise_url = "api/rtprc_update"
        } else {
          promise_url = "api/rtprc_create";
        }

        let promise = axios({
          url: promise_url,
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            page_data.submit = false

            if (data.status == "success") {

              cash('#new-receive-modal').modal('hide')

              Toastify({
                node: cash('#success-notification-content')
                  .clone()
                  .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
              }).showToast()

              resetForm()
              initTabulator()
            } else {

              swal("Error!", data.message, "error")
            }
          })

          .catch(_error => {
            page_data.submit = false
            swal("Error!", "Something went wrong", "error")
          });
      }


    }

    const RTPKapans = async () => {

      const params = {
        series_id: (filterData.series_id == "0" ? "" : window.btoa(filterData.series_id))
      };

      let promise = axios({
        url: "/api/rtprc_kapan",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.kapan_list = data.items

        })
        .catch(_error => {
          page_data.kapan_list = []
        });

    }

    const RTPFormKapans = async () => {

      const params = {
      };

      let promise = axios({
        url: "/api/rtprc_kapan",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.kapan_form_list = data.items

        })
        .catch(_error => {
          page_data.kapan_form_list = []
        });

    }

    const RTPSeries = async () => {
      const params = {

      };

      let promise = axios({
        url: "/api/rtprc_series",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.series_list = data.items

        })
        .catch(_error => {
          page_data.series_list = []
        });
    }

    const RTPTransaction = async () => {

      if (kapanForm.kapan_id == "0") {
        kapanForm.transaction_id = []
      } else {

        const params = {
          kapan_id: (kapanForm.kapan_id == "0" ? "" : window.btoa(kapanForm.kapan_id)),
          trans_id: (kapanForm.trans_id == "" ? "" : window.btoa(kapanForm.trans_id)),
        };

        let promise = axios({
          url: "/api/rtprc_transaction",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
            headers: {
              Accept: "application/json"
            }
          }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            page_data.transaction_form_list = data.items
            kapanForm.transaction_id = []

            if (page_data.transaction_temp.length > 0) {
              kapanForm.transaction_id = page_data.transaction_temp
              page_data.transaction_temp = []
            }

          })
          .catch(_error => {
            page_data.transaction_form_list = []
            kapanForm.transaction_id = []
          });
      }
    }

    const calculate_total = async (item, type) => {

      let receive_limit = $h.myFloat((item.issue_weight ? item.issue_weight : 0) - (item.received_weight ? item.received_weight : 0))
      let total_weight = 0;

      if (item.loose_weight > 0) {
        total_weight += parseFloat(item.loose_weight);
      }

      if (item.certified_weight > 0) {
        total_weight += parseFloat(item.certified_weight);
      }

      if ($h.myFloat(total_weight) > receive_limit) {

        if (type == "loose") {
          item.loose_weight = ""
          calculate_total(item, type)
        } else if (type == "certy") {
          item.certified_weight = ""
          calculate_total(item, type)

        }
        swal("Warning", "Received weight must be less then Issued weight", "warning");
      } else {
        item.total_weight = $h.myFloat(total_weight);

        // Calculate Main Total
        kapanForm.total_pcs = 0
        kapanForm.total_weight = 0
        kapanForm.loose_pcs = 0
        kapanForm.loose_weight = 0
        kapanForm.certified_pcs = 0
        kapanForm.certified_weight = 0

        kapanForm.transaction_arr.forEach(element => {

          kapanForm.total_pcs += element.total_pcs;
          kapanForm.total_weight += element.total_weight;
          kapanForm.loose_pcs += element.loose_pcs;
          kapanForm.loose_weight += element.loose_weight;
          kapanForm.certified_pcs += element.certified_pcs;
          kapanForm.certified_weight += element.certified_weight;

        });

        kapanForm.total_pcs = $h.myFloat(kapanForm.total_pcs)
        kapanForm.total_weight = $h.myFloat(kapanForm.total_weight)
        kapanForm.loose_pcs = $h.myFloat(kapanForm.loose_pcs)
        kapanForm.loose_weight = $h.myFloat(kapanForm.loose_weight)
        kapanForm.certified_pcs = $h.myFloat(kapanForm.certified_pcs)
        kapanForm.certified_weight = $h.myFloat(kapanForm.certified_weight)
      }

    }

    const deleteItem = () => {

      page_data.destroy = true

      swal({
        title: "Are you sure?",
        text: "You Want to Reject Received Stock!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          const data = {
            "trans_id": window.btoa(kapanForm.trans_id)
          };
          let new_url = "api/rtprc_destroy"

          let promise = axios({
            url: new_url,
            method: "POST",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });

          return promise
            .then(result => result.data)
            .then(data => {

              page_data.destroy = false

              if (data.status == 'success') {
                cash('#new-receive-modal').modal('hide')
                swal("Success!", data.message, "success");
                resetForm()
              } else {
                swal("Warning!", data.message, "warning");
                // resetForm()
              }

            })
            .catch(_error => {
              page_data.destroy = false
              swal("Error!", "Something went Wrong", "error");

            });
        }
      }).catch(() => {
        page_data.destroy = false
      })
    }

    // On reset filter
    const onResetFilter = () => {
      filterData.s_date = moment().startOf('month').format('YYYY-MM-DD');
      filterData.e_date = moment().format('YYYY-MM-DD');
      filterData.kapan_id = [];
      filterData.series_id = "0";
      filterData.status = "0";

      RTPKapans();
    }

    const resetForm = () => {
      kapanForm.kapan_id = '0'
      kapanForm.trans_id = ''
      kapanForm.total_pcs = ''
      kapanForm.total_weight = ''
      kapanForm.loose_pcs = ''
      kapanForm.loose_weight = ''
      kapanForm.certified_pcs = ''
      kapanForm.certified_weight = ''
      kapanForm.transaction_id = []
      kapanForm.transaction_arr = []
      page_data.transaction_form_list = []
      validate.value.$reset()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      RTPKapans()
      RTPFormKapans()
      RTPSeries()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      page_data,
      kapanForm,
      filterData,
      submitForm,
      rules,
      validate,
      onFilter,
      deleteItem,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      RTPKapans,
      RTPTransaction,
      initTabulator,
      calculate_total,
      resetForm,
      getTransactionHistory
    }
  }
})
</script>
