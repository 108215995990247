<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Transfer Certy Lot</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Issue Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-12 xl:col-span-6">
                            <div>
                                <label for="scan_lot" class="form-label">Scan Lot Barcode</label>
                                <input id="scan_lot" type="text" :class="{'form-control':true,'loading':scanload}" placeholder="Scan Barcode / Stock No" v-model="scan_lot_no" @change="scanAndAddPack" autocomplete="off" />
                            </div>

                            <!-- <div class="mt-3">
                                <label for="scan_lot_by_no" class="form-label">Add by Lot No</label>
                                <input id="scan_lot_by_no" type="text" :class="{'form-control':true,'loading':scanload_by_no}" placeholder="20_1-20_10" v-model="by_lot_no" :readonly="scanload_by_no == true" @change="scanAndAddPackByNo" autocomplete="off" />
                            </div>
                            <div class="mt-3">
                                <label style="font-style:italic">E.g. 1,2,3,4,5...</label>
                            </div> -->

                        </div>
                        <div class="col-span-12 xl:col-span-6">

                            <div>
                                <label for="update-profile-form-7" class="form-label">Select Branch</label>
                                <TomSelect v-model="branch_id" :options="{
                      placeholder: 'Select Branch '
                    }" class="w-full">
                                    <option value="0">Select Branch</option>
                                    <option value="1" v-if="user_details.user_type == 26">Home Branch</option>
                                      <option v-for="item in branch_arr" :key="item.id" :value="item.id">{{ item.branch_name }} {{ item.branch_code ?  '( '+  item.branch_code + ' )' : "" }}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div class="col-span-12 xl:col-span-12">
                            <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                                <h2 class="font-medium text-base mr-auto">Selected Lot No</h2>
                            </div>
                            <div class="py-5 px-3">
                                <div class="border mt-2 p-0 shadow-">

                                    <div class="overflow-x-auto tableFixHead">
                                        <table class="w-full text-center text-xs">
                                            <thead>
                                                <tr class="bg-theme-14">
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Sr No.</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Packet No</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Weight</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">LAB</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Shape</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Color</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Purity</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5"></th>
                                                </tr>
                                                <tr class="bg-gray-200">
                                                    <td class="border-b py-2 px-2">Total</td>
                                                    <td class="border-b py-2 px-2">{{ total_packets }}</td>
                                                    <td class="border-b py-2 px-2">{{ $h.myFloat(total_weight) }}</td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in fields" :key="index">
                                                    <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.pack_no }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.weight}}</td>
                                                    <td class="border-b py-2 px-2">{{ field.lab }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.shape }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.color }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.purity }}</td>
                                                    <td class="border-b py-2 px-2">
                                                        <button type="button" class="text-theme-6 flex items-center" @click="removeField(field.id)">
                                                            <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-if="fields.length == 0" class="text-md font-medium mr-auto text-center my-8">
                                        Records Not Found
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end mt-4">
                        <button type="button" class="btn btn-primary w-20 mr-auto" @click="submitRoughForm" :disabled="form_status">
                            Save
                            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!form_status" />
                        </button>

                    </div>

                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    onMounted
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL,BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    props: {
        rough_id: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            branch_id: '0',
            branch_arr: [],
            user_details: this.$store.state.user.currentUser,
            scan_lot_no: '',
            by_lot_no: '',

            AUTH_TOKEN: BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"),

            form_status: false,

            scanload: false,
            scanload_by_no: false,
            total_scan_pack: 0,
            fields: [],

            total_weight: 0,
            total_packets: 0,

            loginVal: '',
            req: axios.create({
                baseUrl: BASE_URL_SELLING
            })
        }
    },
    computed: {

    },
    mounted() {
        this.getBranches();

    },

    methods: {
        changeIssueType() {
            this.fields = [];
            this.total_scan_pack = 0;
        },
        async getBranches() {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/transfer_branches",
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.branch_arr = data.items;

                })
                .catch(_error => {

                });
        },

        async submitRoughForm() {
            this.form_status = true;
            //let tag_ar = [this.vueSelectForm.multiple];
            var total_scanned_pack = this.fields.length;
            if (total_scanned_pack < 1) {
                this.scan_lot_no = "";
                this.$swal("Error!", "Packet List can not be empty, Scan barcode to add packet!", "error");
                this.form_status = false;
                return;
            }
            if (this.branch_id == '0') {
                this.$swal("Warning!", "Select Branch", "warning");
                this.form_status = false;
                return;
            }
            const data = {
                branch_id: (this.branch_id != "0" ? window.btoa(this.branch_id) : ""),
                transfer_stock: this.fields,
                total_weight: this.total_weight,
                total_packets: this.total_packets,
            };
            let promise = axios({
                url: "/transfer_certy_branch_stock",
                method: "post",
                data: data,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        this.$swal("Error!", data.message, "error");

                        return;
                    }
                    this.$swal.fire({
                        icon: 'success',
                        title: "Success!",
                        text: data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.form_status = false
                    let transaction_id = data.transaction_details.id
                    this.$router.push('/app/app-final-polish/branch-transfer/' + window.btoa(transaction_id));

                })
                .catch(_error => {
                    this.form_status = false
                    if (data.error == "YES") {
                        this.$swal("Error!", "Something went Wrong", "error");

                        return;
                    }

                });
        },
        async scanAndAddPack() {
            console.log(this.scan_lot_no);
            let code_length = this.scan_lot_no.trim().length;
            if (code_length > 1) {
                this.scanload = true;
                //Check already in array
                var i = this.fields.length;
                while (i--) {
                    if (this.fields[i]['barcode'] == this.scan_lot_no) {
                        this.scan_lot_no = "";
                        this.$swal("Warning!", "Already in list, Scan other code!", "warning");
                        this.scanload = false;
                        return;
                    }
                }
                //Check type selected

                //get lot details
                const data = {
                    barcode: this.scan_lot_no,
                };
                let url_post = '';
                if (this.scan_lot_no != '') {
                    url_post = "/get_scanned_certy_lot_details"
                } else {
                    url_post = "/get_scanned_certy_lot_details"
                }
                let promise = axios({
                    url: url_post,
                    method: "post",
                    data: data,
                    baseURL: BASE_URL_SELLING,
                    headers: {
                        Authorization: "Bearer " + this.AUTH_TOKEN
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    },
                });
                return promise
                    .then((result) => result.data)
                    .then((data) => {
                        console.log(data);
                        if (data.packet_details == 'No') {
                            this.$swal("Warning!", data.error_msg, "warning");
                            this.scanload = false;
                            document.querySelector('[id="scan_lot"]').focus();
                        } else if (data.packet_details == 'process') {
                            this.$swal("Warning!", "Scanned barcode is already in process", "warning");
                            this.scanload = false;
                            document.querySelector('[id="scan_lot"]').focus();
                        } else {
                            let pack_details = data.packet_details;

                            var i = this.fields.length;
                            while (i--) {
                                if (this.fields[i]['barcode'] == pack_details.lab_barcode) {
                                    this.scan_lot_no = "";
                                    this.$swal("Warning!", "Already in list, Scan other code!", "warning");
                                    this.scanload = false;
                                    return;
                                }
                            }
                            this.total_scan_pack = this.total_scan_pack + 1;
                            this.fields.push({
                                id: this.total_scan_pack,
                                weight: pack_details.cr_weight,
                                cr_weight: pack_details.cr_weight,
                                purity: pack_details.purity_name,
                                color: pack_details.color_name,
                                shape: pack_details.shape_name,
                                lab: pack_details.new_lab,
                                pack_no: pack_details.lab_code,
                                pack_id: pack_details.id,
                                barcode: pack_details.lab_barcode
                            });

                            console.warn(this.fields)
                            this.scan_lot_no = "";
                            this.scanload = false;
                            this.total_weight = parseFloat(pack_details.cr_weight) + parseFloat(this.total_weight)
                            this.total_packets += 1;
                            document.querySelector('[id="scan_lot"]').focus();
                        }
                    })
                    .catch((_error) => {
                        this.scanload = false;
                        document.querySelector('[id="scan_lot"]').focus();
                        return [];
                    });

            }
        },

        async scanAndAddPackByNo() {
            if (this.issue_type == 1) {
                if (this.process == '') {
                    this.$swal("Warning!", "Select Process First!", "warning");
                    this.scanload = false;
                    this.scan_lot_no = '';
                    return;
                }
            } else {
                this.process = '';
            }

            this.scanload_by_no = true;

            //get lot details
            const data = {
                by_lot_no: this.by_lot_no,
                process_id: this.process,
            };

            let url_post = '';
            if (this.by_lot_no != '') {
                url_post = "/get_scanned_packet_details_by_no"
            } else {
                url_post = "/get_scanned_packet_details_by_no"
            }
            let promise = axios({
                url: url_post,
                method: "post",
                data: data,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {
                    console.log(data);

                    if (data.size == 0) {
                        this.$swal("Warning!", "No packet available in selected range <br>Or Packet is Blocked!", "warning");
                        this.scanload_by_no = false;
                        document.querySelector('[id="scan_lot_by_no"]').focus();
                    } else {
                        let pack_details = data.items;

                        var j = pack_details.length;
                        if (j > 0) {
                            while (j--) {
                                var i = this.fields.length;
                                let already_in_list = false;
                                while (i--) {
                                    if (this.fields[i]['barcode'] == pack_details[j].barcode_no) {
                                        already_in_list = true;
                                    }
                                }
                                if (already_in_list == false) {
                                    this.total_scan_pack = this.total_scan_pack + 1;
                                    this.fields.push({
                                        id: this.total_scan_pack,
                                        weight: pack_details[j].cr_weight,
                                        cr_weight: pack_details[j].cr_weight,
                                        purity: pack_details[j].purity_name,
                                        color: pack_details[j].color_name,
                                        shape: pack_details[j].shape_name,
                                        lab: pack_details[j].new_lab,
                                        pack_no: pack_details[j].lab_code,
                                        pack_id: pack_details[j].id,
                                        barcode: pack_details[j].lab_barcode
                                    });
                                    this.total_weight = parseFloat(pack_details[j].cr_weight) + parseFloat(this.total_weight)
                                    this.total_packets += 1;
                                }
                            }
                        }
                        this.scanload_by_no = false;
                        document.querySelector('[id="scan_lot_by_no"]').focus();
                    }
                })
                .catch((_error) => {
                    this.scanload_by_no = false;
                    document.querySelector('[id="scan_lot_by_no"]').focus();
                    return [];
                });

        },
        removeField(id) {

            this.$swal({
                title: "Are you sure?",
                text: "You are about to remove a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.fields.length;
                    while (i--) {
                        if (this.fields[i]['id'] == id) {
                            this.total_weight = parseFloat(this.total_weight) - parseFloat(this.fields[i]['weight'])
                            this.total_packets -= 1;
                            this.fields.splice(i, 1);
                        }
                    }
                }
            });
        },
    },

})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
