<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Non Certy Pending Lot-Stock</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Avail. Packets</div>
                        <div class="text-xl font-medium leading-8">{{ screenData.total.total_packets }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                        <div class="text-base text-gray-600">Avail. Weight</div>
                        <div class="text-xl font-medium leading-8">{{ screenData.total.total_weight ? $h.myFloat(screenData.total.total_weight) + ' ct' : '0.00 ct' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col">
            <form id="tabulator-html-filter-form" class="flex flex-wrap sm:mr-2">
                <div class="sm:flex items-center sm:mr-2">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial">Field</label>
                    <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0">
                        <option value="packet_code">Packet No</option>
                        <option value="barcode_no">Barcode</option>
                        <option value="packet_code">Lab No</option>
                        <option value="lab_barcode">Lab Barcode</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-2 mt-0">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial">Type</label>
                    <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-full mt-2 sm:mt-0 sm:w-auto">
                        <option value="like" selected>like</option>
                        <option value="=">=</option>
                        <option value="<">&lt;</option>
                        <option value="<=">&lt;=</option>
                        <option value=">">></option>
                        <option value=">=">>=</option>
                        <option value="!=">!=</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial">Value</label>
                    <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search..." />
                </div>
                <!-- <div class="mt-2 xl:mt-0">
              <input
                id="scan_lot"
                class="xl:w-60 w-40"
                type="text"
                :class="{'form-control':true}"
                placeholder="Scan Barcode"
                v-model="screenData.scan_lot_no"
                @change="scanAndAddPack"
                autocomplete="off"
              />
            </div> -->
                <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full sm:w-16" @click="onFilter">
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                        Reset
                    </button>
                </div>
                <div class="mt-2 xl:mt-0 mx-3">
                    <input id="scan_lot" class="xl:w-60 w-40" type="text" :class="{'form-control':true}" placeholder="Scan Barcode" v-model="screenData.scan_lot_no" @change="scanAndAddPack" autocomplete="off" />
                </div>
            </form>
            <div class="flex flex-wrap items-center sm:mr-4 mt-2">
                <div class="mt-2 xl:mt-0">
                    <TomSelect v-model="screenData.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-40" @change="getPacketsandTotal">
                        <option value="0">Select Kapan</option>
                        <option v-for="kapan in screenData.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                    </TomSelect>
                </div>
                <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
                    <TomSelect v-model="screenData.color_id" :options="{
                  placeholder: 'Select Color',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Color'
                    }
                  }
                }" class="w-40" @change="getPacketsandTotal">
                        <option value="0">Select Color</option>
                        <option v-for="color in screenData.colors" :key="color.id" :value="color.id">{{color.shade_code}}</option>
                    </TomSelect>
                </div>
                <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
                    <TomSelect v-model="screenData.shape_id" :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-40" @change="getPacketsandTotal">
                        <option value="0">Select Shape</option>
                        <option v-for="shape in screenData.shapes" :key="shape.id" :value="shape.id">{{shape.shape_code}}</option>
                    </TomSelect>
                </div>
                <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
                    <TomSelect v-model="screenData.purity_id" :options="{
                  placeholder: 'Select Purity',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Purity'
                    }
                  }
                }" class="w-40" @change="getPacketsandTotal">
                        <option value="0">Select Purity</option>
                        <option v-for="purity in screenData.purities" :key="purity.id" :value="purity.id">{{purity.purity_code}}</option>
                    </TomSelect>
                </div>
                <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
                    <TomSelect v-model="screenData.stone_id" :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }" class="w-40" @change="getPacketsandTotal">
                        <option value="0">Select Stone</option>
                        <option v-for="stone in screenData.stones" :key="stone.id" :value="stone.id">{{stone.stone_code}}</option>
                    </TomSelect>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
            <div class="col-span-6 xl:col-span-3 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in screenData.arr_list" :key="index">
                <div class="flex justify-between">
                    <div class="font-medium text-base">{{ add }}</div>
                    <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)">
                        <Trash2Icon class="w-4 h-4 mr-1" /></button>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-between mt-5">
            <div></div>
            <div class="text-right">
                <button type="button" class="btn btn-primary shadow-md mr-2 text-right" @click="showUpdateAllModal" v-if="screenData.arr_list.length > 0">Transfer Certy</button>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <div class="p-0 m-0 hidden">
        <button id="print_btn" type="button" class="btn btn-primary w-full ml-2" v-print="'#print-lab-barcode'">
            Print
        </button>
        <div id="print-lab-barcode" class="absolute top-0 left-0 p-0 m-0">
            <table class="p-0 m-0 w-full text-black" border="collapse">
                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.packet_code }}</th>
                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.pack_code }}</th>
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.cr_weight }} Ct</th>
                </tr>
                <tr class="text-center m-0 p-0">
                    <th colspan="7" class="text-center">
                        <VueBarcode :value="print_params.barcode" :options="{ displayValue: true,height:25,width:2 }"></VueBarcode>
                    </th>
                </tr>
                <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th class="border m-auto border-black">SHP</th>
                    <th class="border m-auto border-black">CL</th>
                    <th class="border m-auto border-black">PRT</th>
                    <th class="border m-auto border-black">CT</th>
                    <th class="border m-auto border-black">PL</th>
                    <th class="border m-auto border-black">SYM</th>
                    <th class="border m-auto border-black">Fl</th>
                </tr>
                <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th class="border m-auto border-black">{{ print_params.shape ? print_params.shape : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.color ? print_params.color : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.purity ? print_params.purity : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.cut ? print_params.cut : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.polish ? print_params.polish : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.symmetry ? print_params.symmetry : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.fluerocent ? print_params.fluerocent : '-' }}</th>
                </tr>
            </table>
            <p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 4px;font-weight: bold;">DK & Sons</p>
        </div>
    </div>

    <!-- BEGIN: Modal Content -->
    <div id="update-lab-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm Lab
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            You Wan't To Update LAB! <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Lab</label>
                        <input id="modal-form-1" type="text" v-model="screenData.new_lab" class="form-control" placeholder="Lab" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the New LAB !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-28" :disabled="screenData.submit_loading" @click="submitRoughNewPolishWeight">
                        Update
                        <LoadingIcon v-if="screenData.submit_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
    <!-- BEGIN: Modal Content -->
    <div id="update-all-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm Lab
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            You Wan't To Update LAB! <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Lab</label>
                        <input id="modal-form-1" type="text" v-model="screenData.new_lab" class="form-control" placeholder="Lab" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the New LAB !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-28" :disabled="screenData.submit_loading" @click="assignMultiPacketsToNonCerty">
                        Update
                        <LoadingIcon v-if="screenData.submit_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
</div>
</template>


<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import VueBarcode from '@chenfengyuan/vue-barcode';
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'

export default defineComponent({
    components: {
        VueBarcode
    },
    setup() {
        const router = useRouter()
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')
        const filter = reactive({
            field: 'packet_code',
            type: 'like',
            value: ''
        })

        const print_params = reactive({
            barcode: '046000000000',
            color: '',
            cut: '',
            purity: '',
            polish: '',
            shape: '',
            symmetry: '',
            fluerocent: '',
            pack_code: '',
            packet_code: '',
            weight: '',
            cr_weight: ''

        })

        const screenData = reactive({
            scan_lot_no: '',
            arr_list: [],
            addornot: false,
            kapan_id: '0',
            stone_id: '0',
            color_id: '0',
            shape_id: '0',
            purity_id: '0',
            kapans: [],
            colors: [],
            shapes: [],
            stones: [],
            purities: [],
            total: [],
            new_lab: '',
            packet_id: '',
            submit_loading: false
        })

        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + `/api/non_certy_packets_list`,
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: {
                    kpn_id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
                    color_id: screenData.color_id != '0' ? window.btoa(screenData.color_id) : '',
                    shape_id: screenData.shape_id != '0' ? window.btoa(screenData.shape_id) : '',
                    stone_id: screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
                    purity_id: screenData.purity_id != '0' ? window.btoa(screenData.purity_id) : '',
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    {
                        title: 'Packet No',
                        field: 'packet_code',
                        vertAlign: 'middle',
                        print: false,
                        minWidth: 160,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().status == 1) {
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>` + cell.getData().packet_code
                            } else {
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().packet_code
                            }
                        }
                    },
                    {
                        title: 'Weight',
                        field: 'weight',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Shape',
                        field: 'shape',
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Color',
                        field: 'color',
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Purity ',
                        field: 'purity',
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    //     {
                    //         title: 'Edit',
                    //         field: 'actions',
                    //         responsive: 1,
                    //         width: 50,
                    //         hozAlign: 'left',
                    //         vertAlign: 'center',
                    //         print: false,
                    //         download: false,
                    //         formatter(cell) {

                    //                 const a = cash(`<div class="p-0 m-0">
                    // <button class="py-1 px-1">
                    //   <i data-feather="edit" class="w-5 h-5"></i>
                    //   </button>

                    //   </div>`)
                    //                 cash(a).on('click', function () {

                    //                     cash('#update-lab-modal').modal('show')
                    //                     screenData.new_lab = cell.getData().lab
                    //                     screenData.packet_id = cell.getData().packet_id
                    //                 })

                    //                 return a[0]
                    //         }
                    //     },
                    {
                        title: 'Certy',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'center',
                        print: false,
                        download: false,
                        formatter(cell) {

                            const a = cash(`<div class="p-0 m-0">
                  <button class="py-1 px-1">
                    <i data-feather="send" class="w-5 h-5"></i>
                    </button>

                    </div>`)
                            cash(a).on('click', function () {

                                cash('#update-lab-modal').modal('show')
                                screenData.new_lab = cell.getData().lab
                                screenData.packet_id = cell.getData().packet_id

                                // assignSinglePacketToNonCerty(cell.getData().packet_id)
                            })

                            return a[0]
                        }
                    },
                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const getKapanDetails = () => {
            let promise = axios({
                url: "api/dp_rough_kapan",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.kapans = data.items;
                })
                .catch(_error => {

                });
        }
        const getColorsDetails = () => {
            let promise = axios({
                url: "api/dp_color",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.colors = data.items;
                })
                .catch(_error => {

                });
        }
        const getStoneDetails = () => {
            let promise = axios({
                url: "api/dp_stone",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.stones = data.items;
                })
                .catch(_error => {

                });
        }
        const getShapeDetails = () => {
            let promise = axios({
                url: "api/dp_shape",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.shapes = data.items;
                })
                .catch(_error => {

                });
        }
        const getPurityDetails = () => {
            let promise = axios({
                url: "api/dp_purity",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.purities = data.items;
                })
                .catch(_error => {

                });
        }
        const total_packets = () => {
            let params = {
                kpn_id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
                color_id: screenData.color_id != '0' ? window.btoa(screenData.color_id) : '',
                shape_id: screenData.shape_id != '0' ? window.btoa(screenData.shape_id) : '',
                purity_id: screenData.purity_id != '0' ? window.btoa(screenData.purity_id) : '',
                stone_id: screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : ''
            }
            let promise = axios({
                url: "api/non_certy_packets_list_total",
                method: "POST",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.total = data.total;
                })
                .catch(_error => {

                });
        }

        const submitRoughNewPolishWeight = () => {

            if (screenData.new_lab == '') {
                swal("Error!", "Lab is Empty!", "error");
                return;
            }

            // screenData.submit_loading = true
            const params = {
                packet_id: window.btoa(screenData.packet_id),
                lab_code: window.btoa(screenData.new_lab),
            };
            let promise = axios({
                url: "api/update_non_certy_packet_to_non_certy",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    let message = data.message
                    if (data.error == 'YES') {
                        swal("Error!", message, "error");
                        screenData.submit_loading = false
                    } else {
                        // swal("Success!", message, "success").fire({
                        //   timer: 1500
                        // })

                        swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: message,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        cash('#update-lab-modal').modal('hide')
                        initTabulator()
                        total_packets()
                    }
                    screenData.submit_loading = false

                })
                .catch(_error => {
                    screenData.submit_loading = false
                });
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'packet_code'
            filter.type = 'like'
            filter.value = ''
            screenData.kapan_id = '0'
            screenData.color_id = '0'
            screenData.shape_id = '0'
            screenData.stone_id = '0'
            screenData.purity_id = '0'
            initTabulator()
            total_packets()
        }
        const assignMultiPacketsToNonCerty = () => {

            if (screenData.new_lab == '') {
                swal("Error!", "Lab is Empty!", "error");
                return;
            }

            screenData.submit_loading = true
            const params = {
                packet_list: screenData.arr_list,
                lab_code: window.btoa(screenData.new_lab),
            };
            let promise = axios({
                url: "api/update_multiple_non_certy_packet_to_non_certy",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == 'YES') {
                        swal("Warning!", data.message, "warning");
                        screenData.scan_lot_no = '';
                        document.querySelector('[id="scan_lot"]').focus();

                        return;
                    }

                    swal("Success!", data.message, "success");

                    document.querySelector('[id="scan_lot"]').focus();
                    screenData.arr_list = [];
                    initTabulator()
                    total_packets()
                    cash('#update-all-modal').modal('hide')
                    return

                })
                .catch(_error => {
                    if (data.error == "YES") {
                        this.$swal("Error!", "Something went Wrong", "error");

                        return;
                    }

                });
        }

        function showUpdateAllModal() {
            cash('#update-all-modal').modal('show')
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'buyer_list.xlsx', {
                sheetName: 'Buyers'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        const scanAndAddPack = () => {
            if (screenData.scan_lot_no != '') {
                if (screenData.arr_list.length <= 0) {
                    checkadd()
                } else {
                    for (let i = 0; i < screenData.arr_list.length; i++) {
                        if (screenData.arr_list[i] == screenData.scan_lot_no) {
                            // pagedata.checklist.splice(pagedata.addlist[i].id,1)
                            screenData.arr_list.splice(i, 1);
                            screenData.addornot = true
                            screenData.scan_lot_no = ''
                            break
                        } else {
                            screenData.addornot = false
                        }

                    }
                    if (screenData.addornot == false) {
                        checkadd()
                    }
                }
            }
        }

        function checkadd() {
            const data = {
                packet_code: screenData.scan_lot_no ? window.btoa(screenData.scan_lot_no) : '',
            };
            let promise = axios({
                url: "api/scan_non_certy_packet",
                method: "post",
                data: data,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {
                    console.log(data);
                    if (data.error == 'YES') {
                        swal("Warning!", data.msg, "warning");
                        screenData.scan_lot_no = '';
                        document.querySelector('[id="scan_lot"]').focus();
                    } else {
                        screenData.arr_list.push(data.items.packet_code)
                        screenData.scan_lot_no = '';
                        document.querySelector('[id="scan_lot"]').focus();
                    }
                })
                .catch((_error) => {
                    screenData.scan_lot_no = '';
                    document.querySelector('[id="scan_lot"]').focus();
                });
        }

        function getPacketsandTotal() {
            initTabulator()
            total_packets()
        }

        onMounted(() => {
            total_packets()
            initTabulator()
            getKapanDetails()
            getColorsDetails()
            getShapeDetails()
            getStoneDetails()
            getPurityDetails()
            reInitOnResizeWindow()
            document.querySelector('[id="scan_lot"]').focus();
        })

        return {
            tableRef,
            filter,
            screenData,
            print_params,
            scanAndAddPack,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser,
            initTabulator,
            getPacketsandTotal,
            assignMultiPacketsToNonCerty,
            submitRoughNewPolishWeight,
            showUpdateAllModal
        }

    }
})
</script>
