<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Size Wise - Summary All</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

    <span tabindex="1"></span>
          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                    >Select Series/Kapan</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.issue_block" @change="changeBlocktype" >
                    <option value="1" selected>Kapan</option>
                    <option value="2">Series</option>
                  </select>
                </div>

                <div class="mt-3" v-if="screenData.issue_block == 2">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Series</label
                  >
                 <TomSelect
                v-model="screenData.selectedSeries"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }

                }"
                multiple
                class="w-full"
              >
              <option value="">Select Series</option>
              <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
                </div>

                <div class="mt-3" v-if="screenData.issue_block == 1">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Kapan</label
                  >
                 <TomSelect

                v-model="screenData.selectedKapan"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Kapan</option>
              <option v-for="kapans in screenData.kapans_ar" :key="kapans.id" :value="kapans.id">{{kapans.kapan_code}}</option>
              </TomSelect>
                </div>
              </div>

           <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                    >Select Kapan Type</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.kapan_type">
                    <option value="2" selected>Galaxy</option>
                    <option value="1">Manual</option>
                    <option value="All">All</option>
                  </select>
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                    >Select Shape</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.shape_id">
                    <option value="All" selected>All</option>
                    <option value="Fancy" selected>Fancy</option>
                    <option v-for="shapes in screenData.shapes_ar" :key="shapes.id" :value="shapes.id">{{shapes.shape_name}}</option>

                  </select>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-12 mt-5">
            <div>
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-20"
                tabindex="5"
                @click="generateReport()"
              >
              Generate
              </button>

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              tabindex="6"
              @click="onResetFilter"
            >
              Reset
            </button>
            <button v-if="screenData.genreport == 'gen' && screenData.report_header.length > 0"
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20 ml-4"
              @click="printReportDetails"
            >
            Print
            </button>

          </div>
          <span tabindex="7"></span>
          </div>
        </div>

     <div id="responsive-table" class="p-5" v-if="screenData.load_price">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="bars" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
      </div>

      <div v-else>
      <div class="overflow-x-auto scrollbar-hidden" v-if="screenData.genreport == 'gen'">

        <div id="generate-pdf" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="w-full">
                  <thead>
                    <tr>

                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        rowspan="2"
                      >
                        Plan
                      </th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        rowspan="2"
                      >
                        CLA
                      </th>

                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="header in screenData.report_header" :key="header"
                        colspan="3"
                      >
                        {{header}}
                      </th>
                      <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap" colspan="13">
                        Total
                      </th>

                    </tr>

                    <tr>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="header in screenData.report_sub_headers" :key="header"
                      >
                        {{header}}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                    v-for="(record,index) in screenData.planning_report_fields" :key="record">
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" :rowspan="screenData.planning_report_fields.length" v-if="index<1">Planning</td>
                      <td class="border-b border-l border-r text-center whitespace-nowrap" v-for="(value,index) in record" :key="value">
                        <span class="font-bold" v-if="index<1">{{value}}</span>
                        <span v-else>{{$h.myFloat(value)}}</span>
                        </td>
                    </tr>

                    <tr
                    >
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" colspan="2">Planning Total</td>
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record) in screenData.planning_report_fields_total" :key="record">

                        {{$h.myFloat(record)}}
                      </td>
                    </tr>

                    <tr
                    v-for="(record,index) in screenData.make_report_fields" :key="record">
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" :rowspan="screenData.make_report_fields.length" v-if="index<1">Makable</td>
                      <td class="border-b border-l border-r text-center whitespace-nowrap" v-for="(value,index) in record" :key="value">
                        <span class="font-bold" v-if="index<1">{{value}}</span>
                        <span v-else>{{$h.myFloat(value)}}</span>
                        </td>
                    </tr>

                    <tr
                    >
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" colspan="2">Makable Total</td>
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record) in screenData.make_report_fields_total" :key="record">

                        {{$h.myFloat(record)}}
                      </td>
                    </tr>

                     <tr
                    v-for="(record,index) in screenData.blocking_report_fields" :key="record">
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" :rowspan="screenData.blocking_report_fields.length" v-if="index<1">Blocking</td>
                      <td class="border-b border-l border-r text-center whitespace-nowrap" v-for="(value,index) in record" :key="value">
                        <span class="font-bold" v-if="index<1">{{value}}</span>
                        <span v-else>{{$h.myFloat(value)}}</span>
                        </td>
                    </tr>

                    <tr
                    >
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" colspan="2">Blocking Total</td>
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record) in screenData.blocking_report_fields_total" :key="record">

                        {{$h.myFloat(record)}}
                      </td>
                    </tr>


                    <tr
                    v-for="(record,index) in screenData.polish_report_fields" :key="record">
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" :rowspan="screenData.polish_report_fields.length" v-if="index<1">Polish</td>
                      <td class="border-b border-l border-r text-center whitespace-nowrap" v-for="(value,index) in record" :key="value">
                        <span class="font-bold" v-if="index<1">{{value}}</span>
                        <span v-else>{{$h.myFloat(value)}}</span>
                        </td>
                    </tr>

                    <tr
                    >
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" colspan="2">Polish Total</td>
                    <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record) in screenData.polish_report_fields_total" :key="record">

                        {{$h.myFloat(record)}}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      genreport : "no",
      load_price:false,
      issue_block:2,
      kapans_ar:[],
      series_ar:[],
      shapes_ar:[],
      kapan_type:'2',
      shape_id: 'All',
      report_fields:[],
      report_header:[],
      report_sub_headers:[],
      planning_report_fields:[],
      planning_report_fields_total:[],
      blocking_report_fields:[],
      blocking_report_fields_total:[],
      make_report_fields:[],
      make_report_fields_total:[],
      polish_report_fields:[],
      polish_report_fields_total:[],
      footer_total:[],
      hide_show_data:[],
      selectedKapan:'',
      selectedSeries:'',
      totalRghrate:0,
      totalMajuri:0,

    })
    const printData = reactive({
      selectedSeries: '',
      selectedKapan: '',
      kapan_type: '',
      issue_block: 2,
      shape_id: 'All',
      start_date: '',
      end_date: '',
    })

    const getKapans = () => {
      let _this = this;
      const params = {
        kapan_type:screenData.kapan_type
      };
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans_ar = data.items;

        })
        .catch(_error => {

        });
    }

    const getShape = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_shape",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.shapes_ar = data.items;
        })
        .catch(_error => {

        });
    }

    const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;

        })
        .catch(_error => {

        });
    }

    //
    const generateReport = () => {

      printData.selectedSeries = screenData.selectedSeries
      printData.selectedKapan = screenData.selectedKapan
      printData.kapan_type = screenData.kapan_type
      printData.issue_block = screenData.issue_block
      printData.shape_id = screenData.shape_id
      if(screenData.selectedSeries == '' && screenData.selectedKapan == ''){
        return;
      }
      if(screenData.issue_block == 1){
        screenData.selectedSeries = '';
      }else{
        screenData.selectedKapan = '';
      }
      screenData.footer_total = [];
      screenData.report_fields = [];
      screenData.load_price = true;
      const params = {
        series_id: screenData.selectedSeries,
        kapan_id: screenData.selectedKapan,
        kapan_type :screenData.kapan_type,
        shape_id : screenData.shape_id
      };
      let promise = axios({
        url: "api/list_xxx_size_wise_purity_report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          screenData.footer_total = [];
          screenData.report_fields = data.report_details;
          screenData.footer_total = data.footer_total;
          screenData.report_header = data.report_headers;
          screenData.report_sub_headers = data.report_sub_headers;
          screenData.planning_report_fields = data.report_details_planning;
          screenData.make_report_fields = data.report_details_makeable;
          screenData.blocking_report_fields = data.report_details_blocking;
          screenData.polish_report_fields = data.report_details_polish;

          screenData.planning_report_fields_total = data.report_details_planning_total ;
          screenData.blocking_report_fields_total = data.report_details_blocking_total ;
          screenData.make_report_fields_total = data.report_details_makeable_total ;
          screenData.polish_report_fields_total = data.report_details_polish_total ;
          screenData.hide_show_data = data.hide_show_data ;

          screenData.totalRghrate = data.rgh_rate;
          screenData.totalMajuri = data.total_majuri;

          let removed_emems = 0;
          let array_length = screenData.report_header.length;
          for (let i = screenData.report_header.length; i > 0; i--) {
            if(screenData.hide_show_data[i - 1] == 0) {
              screenData.report_header.splice(i - 1,1)

              screenData.planning_report_fields_total.splice((i-1)*3,3)
              screenData.make_report_fields_total.splice((i-1)*3,3)
              screenData.blocking_report_fields_total.splice((i-1)*3,3)
              screenData.polish_report_fields_total.splice((i-1)*3,3)

              screenData.report_sub_headers.splice((i-1)*3 + 1,3)

              screenData.planning_report_fields.forEach(element => {
                element.splice((i-1)*3 + 1,3)
              });
              screenData.make_report_fields.forEach(element => {
                element.splice((i-1)*3 + 1,3)
              });
              screenData.blocking_report_fields.forEach(element => {
                element.splice((i-1)*3 + 1,3)
              });
              screenData.polish_report_fields.forEach(element => {
                element.splice((i-1)*3 + 1,3)
              });
              // screenData.planning_report_fields.splice(i - 11,3)
              // // screenData.make_report_fields.splice(i - 11,3)
              // // screenData.blocking_report_fields.splice(i - 11,3)
              // // screenData.polish_report_fields.splice(i - 11,3)
              // screenData.footer_total.splice(i - 11,3)
              // removed_emems += 1
            }
            console.warn(screenData.report_header)
          }
          screenData.genreport = 'gen';
          screenData.load_price = false;
        })
        .catch(_error => {

        });
    }

    const focusFirst = () => {
        document.querySelector('[tabindex="2"]').focus();
    }
    const focusLast = () => {
        document.querySelector('[tabindex="6"]').focus();
    }


    const onResetFilter = () => {
      screenData.selectedbuyers = []
    }



    const changeBlocktype = () => {

    }

    function printReportDetails(){
      let report_type = 'list_xxx_size_wise_purity_report'

      let params = {
          report_type: report_type,
          filters: printData,
          items: {
            "footer_total" : screenData.footer_total,
            "report_fields" : screenData.report_fields,
            "footer_total" : screenData.footer_total,
            "report_header" : screenData.report_header,
            "report_sub_headers" : screenData.report_sub_headers,
            "planning_report_fields" : screenData.planning_report_fields,
            "make_report_fields" : screenData.make_report_fields,
            "blocking_report_fields" : screenData.blocking_report_fields,
            "polish_report_fields" : screenData.polish_report_fields,
            "planning_report_fields_total" : screenData.planning_report_fields_total,
            "blocking_report_fields_total" : screenData.blocking_report_fields_total,
            "make_report_fields_total" : screenData.make_report_fields_total,
            "polish_report_fields_total" : screenData.polish_report_fields_total,
            "hide_show_data" : screenData.hide_show_data,
            "totalRghrate" : screenData.totalRghrate,
            "totalMajuri" : screenData.totalMajuri,
          }
      }
      let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
      });
      return promise
          .then(result => result.data)
          .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/list-xxx-size-wise-purity-report?id=' + window.btoa(report_id)
          window.open(url, '_blank');

          })
          .catch(_error => {

          });
      // add_report_details
      }


    onMounted(() => {
      //checkPermission()
      //reInitOnResizeWindow()
      getShape()
      getKapans()
      getSeries()
      focusFirst()
      //generateReport()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      printReportDetails,
      onResetFilter,
      generateReport,
      changeBlocktype,
      focusFirst, focusLast

    }
  }
})
</script>
