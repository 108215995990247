<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Receive / Return Kapan</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Transaction Details</h2>

          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                  >Transaction No.</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    v-model="transaction_no"
                    disabled
                  />
                </div>
                <div class="mt-3" v-if="issue_block == 1">
                  <label for="update-profile-form-8" class="form-label"
                  >Rough Kapan</label>
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Kapan',
                  }"
                             class="w-full" v-model="selected_kapan"
                             @change="checkKapanIssue">
                    <option>Select Kapan</option>
                    <option v-for="kapana_item in kapan_ar" :key="kapana_item.id" :value="kapana_item.id">
                      {{ kapana_item.kapan_code }}
                    </option>
                  </TomSelect>
                </div>

                <div class="mt-3" v-if="issue_block == 2">
                  <label for="update-profile-form-8" class="form-label"
                  >Rough Series</label
                  >
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Series',

                }"
                             class="w-full" v-model="selected_series"
                             @change="checkKapanIssue">
                    <option>Select Series</option>
                    <option v-for="series_item in series_ar" :key="series_item.id" :value="series_item.id">
                      {{ series_item.series_code }}
                    </option>
                  </TomSelect>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                  >Receive from</label
                  >
                  <select id="update-profile-form-8" class="form-select" v-model="issue_type">
                    <option value="1" selected>Process</option>
                    <option value="2">Department Manager</option>

                  </select>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                  >Receive Weight(Ct)</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    v-model="receive_weight"
                  />
                </div>


              </div>
              <div class="col-span-12 xl:col-span-6">

                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                  >Receive Series/Kapan</label
                  >
                  <select id="update-profile-form-8" class="form-select" v-model="issue_block">
                    <option value="1" selected>Kapan</option>
                    <option value="2">Series</option>

                  </select>
                </div>


                <div class="mt-3" v-if="issue_type == 1">
                  <label for="update-profile-form-7" class="form-label"
                  >Select Process</label
                  >
                  <select
                    v-model="process"
                    id="process_drop"
                    ref="process_drop"

                    class="form-select"
                    @change="getKarigar"
                  >
                    <option value="">Select Process</option>
                    <option v-for="proces_item in process_ar" :key="proces_item.id" :value="proces_item.id">
                      {{ proces_item.process_name }}
                    </option>
                  </select>
                </div>

                <div class="mt-3" v-if="issue_type == 1">
                  <label for="update-profile-form-7" class="form-label"
                  >Select Manager</label
                  >
                  <select
                    v-model="karigar"
                    :options="{
                  placeholder: 'Select Manager '
                }"
                    class="form-select"

                  >
                    <option value="">Select Manager</option>
                    <option v-for="karigar_item in karigar_ar" :key="karigar_item.id" :value="karigar_item.id">
                      {{ karigar_item.first_name }} {{ karigar_item.last_name }}
                    </option>
                  </select>
                </div>

                <div class="mt-3" v-if="issue_type == 2">
                  <label for="update-profile-form-7" class="form-label"
                  >Select Manager</label
                  >
                  <TomSelect
                    v-model="process"
                    :options="{
                  placeholder: 'Select Manager',
                }"
                    class="w-full"
                    multiple
                  >
                    <option value="">Select Manager</option>
                    <option v-for="manager_item in manager_ar" :key="manager_item.id" :value="manager_item.id">
                      {{ manager_item.first_name }}
                    </option>
                  </TomSelect>
                </div>


              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto" @click="submitRoughForm">
                Save
              </button>

            </div>

          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted} from 'vue'
import {mapGetters, mapActions} from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {BASE_URL} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tender_update_id: '',
      transaction_no: 'Auto',

      stone_type: '',
      issue_type: 1,
      issue_block: 1,

      process: '',
      process_ar: [],
      karigar: '',
      karigar_ar: [],
      kapan_ar: [],
      series_ar: [],
      selected_kapan: '',
      selected_series: '',
      managers_arr: [],
      vendors: [],
      stones: [],
      status: '1',
      receive_weight: 0,

      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {},
  mounted() {

    this.getRoughDetails(this.rough_id);
    this.getProcess();
    this.getKapans();
    this.getSeries();
  },

  methods: {
    getRoughDetails(rough_id) {
      let _this = this;
      const params = {
        rough_id: rough_id
      };
      let promise = axios({
        url: "api/get_rough_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}}
      });
      return promise
        .then(result => result.data)
        .then(data => {

          let rough_detail = data.item_details;
          this.tender_name = rough_detail.tender_name;
          this.lot_no = rough_detail.lot_no;
          this.price = rough_detail.price;
          this.stone_type = rough_detail.stone_type;
          this.brokerage_type = rough_detail.brokerage_type;
          this.brokerage_value = rough_detail.brokerage_value;
          this.billing_period = rough_detail.billing_period;
          this.est_makeable = rough_detail.est_makeable;
          this.rough_weight = rough_detail.rough_weight;
          this.total_pcs = rough_detail.total_pcs;
          this.rough_vendor = rough_detail.vendor_id;

        })
        .catch(_error => {

        });
    },

    async getKapans() {
      let _this = this;
      const params = {};
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}}
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.kapan_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getSeries() {
      let _this = this;
      const params = {};
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}}
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.series_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getProcess() {
      let _this = this;
      const params = {};
      let promise = axios({
        url: "api/dp_process_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}}
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.process_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getManagers() {
      let _this = this;
      const params = {};
      let promise = axios({
        url: "api/dp_account_operator",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}}
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.managers_arr = data.items;

        })
        .catch(_error => {

        });
    },
    async getKarigar() {
      let _this = this;
      const params = {
        process: this.process

      };
      let promise = axios({
        url: "api/dp_karigar_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}}
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.karigar_ar = data.items;

        })
        .catch(_error => {

        });
    },

    checkKapanIssue() {
      let _this = this;
      const data = {
        kapan_id: this.selected_kapan,
        series_id: this.selected_series,
        bunch_type: this.issue_block,
      };
      let url_post = '';
      if (this.rough_id != '') {
        url_post = "api/check_issue_kapan"
      } else {
        url_post = "api/check_issue_kapan"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}},
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
          console.log(data);
          if (data.items == 'no') {
            this.process = data.process;
            this.karigar = data.manager;

            _this.getKarigar();

          } else if (data.items == 'yes') {
            this.selected_kapan = '';
            this.process = '';
            this.karigar = '';
            this.$swal("Warning!", "Selected Kapan not issued to any process", "warning");
          }
        })
        .catch((_error) => {
          return [];
        });

    },
    submitRoughForm() {
      console.log("Submit");
      //let tag_ar = [this.vueSelectForm.multiple];
      const data = {
        kapan_id: this.selected_kapan,
        issue_type: this.issue_type,
        bunch_type: this.issue_block,
        series_id: this.selected_series,
        karigar: this.karigar,
        process_id: this.process,
        receive_weight: this.receive_weight,
        id: this.rough_id
      };
      let url_post = '';
      if (this.rough_id != '') {
        url_post = "api/edit_kapan_receive_trans"
      } else {
        url_post = "api/add_kapan_receive_trans"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        config: {headers: {Accept: "application/json"}},
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
          Toastify({
            node: cash('#success-notification-content-update')
              .clone()
              .removeClass('hidden')[0],
            duration: 5000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
          setTimeout(() => {
            this.$router.push("/app/app-process/kapan/issue");
          }, 500)
        })
        .catch((_error) => {
          return [];
        });

    }
  },

})
</script>
