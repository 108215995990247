<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Recut Summary Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-6 md:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Series</label
                  >
                 <TomSelect
                v-model="formData.series_id"
                @change="getKapan"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="0">Select Series</option>
              <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Kapan</label
                  >
                 <TomSelect
                v-model="formData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in screenData.kapans_ar" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Type</label
                  >
                 <TomSelect
                v-model="formData.type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                class="w-full"
                @change="changeType"

              >
              <option value="1">Recut</option>
              <option value="2">Planning</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-2">
                <div v-if="formData.kapan_id != '0'">
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Kapan Type</label
                  >
                 <TomSelect
                v-model="formData.kapan_type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                disabled
                class="w-full"

              >
              <option value="0">All</option>
              <option value="1">Galaxy</option>
              <option value="2" v-if="formData.type != '1'">Manual</option>
              </TomSelect>
                </div>
                <div v-else>
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Kapan Type</label
                  >
                 <TomSelect
                v-model="formData.kapan_type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="0">All</option>
              <option value="1">Galaxy</option>
              <option value="2" v-if="formData.type != '1'">Manual</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                    >Select Start Date</label
                  ><input
                    class="show-code form-check-switch mr-0 ml-3"
                    type="checkbox" v-model="formData.issue_pending"
                  /></div>
                  <input
                    type= "date"
                    id="update-profile-form-13"
                    :disabled="formData.issue_pending"
                    class="form-control"
                    placeholder=""
                    v-model="formData.issue_date"
                  />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
              <div>
                <label for="update-profile-form-6" class="form-label py-1"
                  >Select End Date</label
                >
                <input
                  type= "date"
                  id="update-profile-form-13"

                  class="form-control"
                  placeholder=""
                  :disabled="formData.issue_pending"
                  v-model="formData.receive_date"
                />
              </div>
            </div>
              <div class="col-span-4 md:col-span-1">

                <div class="mt-7">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    :disabled="formData.issue_pending == true && (formData.kapan_id == '0' && formData.series_id == '0')"
                    class="btn btn-primary w-full sm:w-20"
                    @click="generateReport"
                  >
                  Generate
                  </button>

                </div>
              </div>
              <div class="col-span-4 md:col-span-1">

                <div class="mt-7">

                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-20"
                    @click="onResetFilter"
                  >
                    Reset
                  </button>

                </div>
              </div>
              <div class="col-span-2 md:col-span-1" v-if="screenData.genreport == 'gen'">

                <div class="mt-7">
                  <a
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-primary w-full sm:w-20" @click="printReportDetails"
                  >
                    Print
                  </a>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1" v-if="screenData.genreport == 'gen'">

                <div class="mt-7">
                  <div class="flex mt-5 sm:mt-0">

                    <div class="dropdown w-1/2 sm:w-auto">
                        <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                            <FileTextIcon class="w-4 h-4 mr-2" /> Export
                            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                        </button>
                        <div class="dropdown-menu w-40">
                            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
      <div id="responsive-table" class="p-5" v-if="screenData.loading">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Records</div>
          </div>
          </div>
        </div>
      </div>
      <div v-else>

        <div id="report-details" class="grid grid-cols-12 gap-x-5" v-if="screenData.genreport == 'gen'">
          <div class="overflow-x-auto col-span-12 lg:col-span-8 md:col-span-8 sm:col-span-10">
            <div class="intro-y col-span-12 overflow-auto">
              <table class="mt-2 w-full border-2 text-center">
                <thead>
                  <tr class="border-b bg-gray-400 dark:bg-dark-1">
                    <th class="p-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Details</th>
                    <th class="p-2 text-xs lg:text-md whitespace-nowrap text-left border-r"></th>
                    <th class="p-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Piece</th>
                    <th class="p-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Est. MK Weight</th>
                    <th class="p-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Est. Polish Weight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Oriznal Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r"></th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.rough_details.total_packet ? screenData.rough_details.total_packet : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.rough_details.total_weight ? $h.myFloat(screenData.rough_details.total_weight) + ' Ct' : '-' }}</th>
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.rough_details.total_polish_weight ? $h.myFloat(screenData.rough_details.total_polish_weight) + ' Ct' : '-' }}</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Issue Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r"></th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_issue_packets ? screenData.recut_details.total_issue_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_issue_weight ? $h.myFloat(screenData.recut_details.total_issue_weight) + ' Ct' : '-' }}</th>
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_polish_weight ? $h.myFloat(screenData.recut_details.total_polish_weight) + ' Ct' : '-' }}</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Main Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_main_packets ? screenData.recut_details.total_recut_main_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_main_weight ? $h.myFloat(screenData.recut_details.total_recut_main_weight) + ' Ct' : '-' }} {{ screenData.recut_details.total_recut_main_weight ? ' ( ' + $h.myFloat(screenData.recut_details.total_recut_main_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_main_polish_weight ? $h.myFloat(screenData.recut_details.total_recut_main_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.total_recut_main_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.total_recut_main_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Sub Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_sub_packets ? screenData.recut_details.total_recut_sub_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_sub_weight ? $h.myFloat(screenData.recut_details.total_recut_sub_weight) + ' Ct' : '-' }} {{ screenData.recut_details.total_recut_sub_weight ? ' ( ' + $h.myFloat(screenData.recut_details.total_recut_sub_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_sub_polish_weight ? $h.myFloat(screenData.recut_details.total_recut_sub_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.total_recut_sub_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.total_recut_sub_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Total</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_packets ? screenData.recut_details.total_recut_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_weight ? $h.myFloat(screenData.recut_details.total_recut_weight) + ' Ct' : '-' }} {{ screenData.recut_details.total_recut_weight ? ' ( ' + $h.myFloat(screenData.recut_details.total_recut_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_recut_polish ? $h.myFloat(screenData.recut_details.total_recut_polish) + ' Ct' : '-' }} {{ screenData.recut_details.total_recut_polish ? ' ( ' + $h.myFloat(screenData.recut_details.total_recut_polish * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Lose (Ghat)</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_lose ? $h.myFloat(screenData.recut_details.total_lose) + ' Ct' : '-' }} {{ screenData.recut_details.total_lose ? ' ( ' + $h.myFloat(screenData.recut_details.total_lose * 100 / screenData.rough_details.total_weight) + ' % )' : '' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Rejection</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.total_assortment ? $h.myFloat(screenData.recut_details.total_assortment) + ' Ct' : '-' }} {{ screenData.recut_details.total_assortment ? ' ( ' + $h.myFloat(screenData.recut_details.total_assortment * 100 / screenData.rough_details.total_weight) + ' % )' : '' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r text-white">-</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"> </th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"> </th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"> </th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center"> </th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">ROUND (4P)</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Main Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_main_packets ? screenData.recut_details.round_main_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_main_makeable_weight ? $h.myFloat(screenData.recut_details.round_main_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.round_main_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.round_main_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_main_polish_weight ? $h.myFloat(screenData.recut_details.round_main_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.round_main_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.round_main_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Sub Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_sub_packets ? screenData.recut_details.round_sub_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_sub_makeable_weight ? $h.myFloat(screenData.recut_details.round_sub_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.round_sub_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.round_sub_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_sub_polish_weight ? $h.myFloat(screenData.recut_details.round_sub_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.round_sub_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.round_sub_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Total</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_packets ? screenData.recut_details.round_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_makeable_weight ? $h.myFloat(screenData.recut_details.round_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.round_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.round_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.round_polish_weight ? $h.myFloat(screenData.recut_details.round_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.round_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.round_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Prince</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Main Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_main_packets ? screenData.recut_details.prince_main_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_main_makeable_weight ? $h.myFloat(screenData.recut_details.prince_main_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.prince_main_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.prince_main_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_main_polish_weight ? $h.myFloat(screenData.recut_details.prince_main_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.prince_main_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.prince_main_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Sub Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_sub_packets ? screenData.recut_details.prince_sub_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_sub_makeable_weight ? $h.myFloat(screenData.recut_details.prince_sub_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.prince_sub_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.prince_sub_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_sub_polish_weight ? $h.myFloat(screenData.recut_details.prince_sub_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.prince_sub_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.prince_sub_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Total</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_packets ? screenData.recut_details.prince_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_makeable_weight ? $h.myFloat(screenData.recut_details.prince_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.prince_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.prince_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.prince_polish_weight ? $h.myFloat(screenData.recut_details.prince_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.prince_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.prince_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Fancy</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Main Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_main_packets ? screenData.recut_details.fancy_main_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_main_makeable_weight ? $h.myFloat(screenData.recut_details.fancy_main_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.fancy_main_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.fancy_main_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_main_polish_weight ? $h.myFloat(screenData.recut_details.fancy_main_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.fancy_main_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.fancy_main_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Sub Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_sub_packets ? screenData.recut_details.fancy_sub_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_sub_makeable_weight ? $h.myFloat(screenData.recut_details.fancy_sub_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.fancy_sub_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.fancy_sub_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_sub_polish_weight ? $h.myFloat(screenData.recut_details.fancy_sub_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.fancy_sub_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.fancy_sub_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r" rowspan="3">Makeable</th> -->
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Total</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_packets ? screenData.recut_details.fancy_packets : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_makeable_weight ? $h.myFloat(screenData.recut_details.fancy_makeable_weight) + ' Ct' : '-' }} {{ screenData.recut_details.fancy_makeable_weight ? ' ( ' + $h.myFloat(screenData.recut_details.fancy_makeable_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ screenData.recut_details.fancy_polish_weight ? $h.myFloat(screenData.recut_details.fancy_polish_weight) + ' Ct' : '-' }} {{ screenData.recut_details.fancy_polish_weight ? ' ( ' + $h.myFloat(screenData.recut_details.fancy_polish_weight * 100 / screenData.rough_details.total_weight) + ' % )' : '-' }}</th>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed ,reactive ,onMounted ,toRefs} from 'vue'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)
    const screenData = reactive({
      series_ar:[],
      kapans_ar:[],
      recut_details: [],
      rough_details: [],
      genreport : "no",
      loading: false
    })

    const formData = reactive({
      series_id: '0',
      kapan_id: '0',
      type: '1',
      kapan_type: '0',
      process_id: 6,
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })
    const printData = reactive({
      series_id: '0',
      kapan_id: '0',
      type: '1',
      process_id: 6,
      kapan_type: '0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })

    const generateReport = () => {

      printData.series_id = formData.series_id
      printData.kapan_id = formData.kapan_id
      printData.type = formData.type
      printData.kapan_type = formData.kapan_type
      printData.issue_date = formData.issue_date
      printData.receive_date = formData.receive_date
      printData.issue_pending = formData.issue_pending

      screenData.loading = true

      const params = {
        series_id: formData.series_id == '0' ? '' : window.btoa(formData.series_id),
        kapan_id: formData.kapan_id == '0' ? '' : window.btoa(formData.kapan_id),
        type: window.btoa(formData.type),
        kapan_type: window.btoa(formData.kapan_type),
        process_id: window.btoa(formData.process_id),
        issue_date: formData.issue_date,
        receive_date: formData.receive_date,
        issue_pending: formData.issue_pending,
      };

      let promise = axios({
        url: "api/get_recut_summary_report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          screenData.rough_details = data.rough_details;
          screenData.recut_details = data.recut_details;
          screenData.genreport = 'gen';
          screenData.loading = false
        })
        .catch(_error => {
          screenData.loading = false

        });
    }

    const onResetFilter = () => {
      // screenData.genreport = 'no';
      formData.series_id = '0'
      formData.kapan_id = '0'
      formData.type = '1'
      formData.kapan_type = '0'
      formData.issue_pending = false
      getKapan()
    }

    const getSeries = () => {
      let _this = this;
      const params = {
      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;
        })
        .catch(_error => {
          screenData.series_ar = [];
        });
    }

    const changeType = () => {
      if(formData.type == '1') {
        if(formData.kapan_type == '2') {
          formData.kapan_type = '0'
        }
      }
    }

    const getKapan = () => {
      screenData.kapans_ar = []
      formData.kapan_id = '0'
      let _this = this;
      const params = {
        series_id: window.btoa(formData.series_id),
      };
      let url = ''
      if(formData.series_id != '0')  {
        url = "api/dp_rough_kapan_by_series"
      } else {
        url = "api/dp_rough_kapan"
      }
      let promise = axios({
        url: url,
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans_ar = data.items;
        })
        .catch(_error => {
          screenData.kapans_ar = [];
        });
    }

    // function printReportDetails(){
    //   let url = BASE_URL + '/recut-summary-report?series_id='  + printData.series_id +
    //   '&kapan_id=' + printData.kapan_id +'&process_id=' + printData.process_id + '&type=' + printData.type
    //   + '&kapan_type=' + printData.kapan_type + '&issue_pending=' + printData.issue_pending + '&issue_date=' + printData.issue_date + '&receive_date=' + printData.receive_date
    //   window.open(url, '_blank');
    // }
    function printReportDetails(){
      let report_type = ''
      report_type = 'recut_summary_report'

      let params = {
        report_type: report_type,
        filters: printData,
        items: {'rough_details': screenData.rough_details,'recut_details': screenData.recut_details }
      }
      let promise = axios({
        url: "api/add_report_details",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/recut-summary-report-pl?id=' + window.btoa(report_id)
            window.open(url, '_blank');

        })
        .catch(_error => {

        });
    }

    onMounted(() => {
      getKapan()
      getSeries()
    })

    const onExportXlsx = () => {
      let filename = "recut-summary.xlsx";
      const params = {
        series_id: formData.series_id == '0' ? '' : window.btoa(formData.series_id),
        kapan_id: formData.kapan_id == '0' ? '' : window.btoa(formData.kapan_id),
        type: window.btoa(formData.type),
        kapan_type: window.btoa(formData.kapan_type),
        process_id: window.btoa(formData.process_id),
        issue_date: formData.issue_date,
        receive_date: formData.receive_date,
        issue_pending: formData.issue_pending,
      };
      let promise = axios({
          url: "/api/download_recut_summary_pa_report",
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
          .then((result) => result.data)
          .then((data) => {
              FileDownload(data, filename);
          })
          .catch((_error) => {
              return [];
          });
        }

    return {
      screenData,userDetails,formData,printData,
      changeType,onResetFilter,generateReport,getKapan,printReportDetails,
      onExportXlsx
    }
  }
})
</script>
