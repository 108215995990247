<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Non Certy Stock (Manual Export)</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Stone</label>
          <TomSelect v-model="screenData.stone_type" :options="{
            placeholder: 'Select Stone',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Stone'
              }
            }
          }" class="w-full" @change="getColorDetails">
            <option value="">Select Stone</option>
            <option value="1">Natural</option>
            <option value="2">CVD</option>
            <option value="3">HPHT</option>
            <option value="4">D_CVD</option>
            <option value="5">D_HPHT</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Color</label>
          <TomSelect v-model="screenData.color_size" :options="{
            placeholder: 'Select Stone',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Stone'
              }
            }
          }" class="w-full" @change="getStoneParams">
            <option value="">Select Stone</option>
            <option v-for="(item, index) in screenData.color_arr" :value="item.segment_code" :key="index">{{
              item.segment_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Shape</label>
          <TomSelect v-model="screenData.shape_type" :options="{
            placeholder: 'Select Shape',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Shape'
              }
            }
          }" class="w-full">
            <option value="">Select Shape</option>
            <option v-for="(item, index) in screenData.shape_arr" :value="item.id" :key="index">{{ item.shape_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Size</label>
          <TomSelect v-model="screenData.size_type" :options="{
            placeholder: 'Select Size',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Size'
              }
            }
          }" class="w-full" multiple>
            <option value="">Select Size</option>
            <option v-for="item in screenData.size_arr" :key="item.segment_code" :value="item.segment_code">{{
              item.segment_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Charni</label>
          <TomSelect v-model="screenData.charni_id" :options="{
            placeholder: 'Select Charni',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Charni'
              }
            }
          }" class="w-full" multiple>
            <option v-for="item in screenData.charni_arr" :key="item.id" :value="item.id">{{ item.charni_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Stock Type</label>
          <TomSelect v-model="screenData.stock_type" :options="{
            placeholder: 'Select Stock Type',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Stock Type'
              }
            }
          }" class="w-full">
            <option value="1">Current Stock</option>
            <option value="2">Add Custome Stock</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2">
          <label for="update-profile-form-7" class="form-label">Select Rate Type</label>
          <TomSelect v-model="screenData.rate_type" :options="{
            placeholder: 'Select Stock Type',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Stock Type'
              }
            }
          }" class="w-full">
            <option value="1">Stock Rate</option>
            <option value="2">MAX Rate</option>
            <option value="3">Custom Rate</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2 mt-2" v-if="userDetails.user_type == 25">
          <label for="update-profile-form-7" class="form-label">Select Branch</label>
          <TomSelect v-model="screenData.branch_id" :options="{
            placeholder: 'Select Branch',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Branch'
              }
            }
          }" class="w-full">
            <option value="0">All</option>
            <option value="1">Home Branch</option>
            <option v-for="item in screenData.branch_arr" :key="item.id" :value="item.id">{{ item.branch_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-12 md:col-span-12 xl:col-span-8 mt-2">
          <div class="mt-7 flex justify-end">
            <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-16 mx-1"
              @click="initTabulator">
              Go
            </button>
            <button id="tabulator-html-filter-reset" type="button"
              class="btn btn-secondary w-16 mt-0 ml-1 mx-1" @click="onResetFilter">
              Reset
            </button>
            <div class="dropdown w-auto mx-1">
              <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a id="tabulator-export-csv" href="javascript:;"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="export_excel('csv')">
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                  </a>
                  <a id="tabulator-export-xlsx" href="javascript:;"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="export_excel('xlsx')">
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div id="responsive-table" class="p-5" v-if="screenData.loading == true">
        <div class="preview">
          <div class="overflow-x-auto">
            <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Stock Report</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="screenData.generate == 'gen'">
          <div class="border mt-5 p-0 shadow-md">

            <div class="overflow-x-auto m-0 p-0">

              <table class="text-xs text-center w-full">

                <thead>
                  <tr class="border bg-gray-100 dark:bg-dark-1">
                    <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap"
                      v-for="(size_item, size_index) in screenData.report_items.size_arr" :key="size_index"
                      :colspan="screenData.report_items.size_arr_number[size_index]">{{ size_item }}</th>
                  </tr>
                  <tr class="border bg-gray-100 dark:bg-dark-1">
                    <th class="border py-2 px-1 dark:border-dark-5"
                      v-for="(size_item, size_index) in screenData.report_items.size_head_number" :key="size_index">{{
                        size_item }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border" v-for="(size_value_item, size_value_index) in screenData.report_items.size_value_arr"
                    :key="size_value_index">
                    <td class="border py-2" v-for="(item, inx) in size_value_item" :key="inx">
                      <span v-if='excelData.stock_type == 2'>
                        <input type="text" name="" id="" v-model="size_value_item[inx]" @keypress="$h.preventInvalidInput" class="form-control w-28">
                      </span>
                      <span v-else>
                        {{ item }}
                      </span>
                    </td>
                  </tr>
                  <!-- <tr class="border">
                              <th class="border py-2" v-for="(item,inx) in screenData.report_items.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                          </tr> -->
                </tbody>
              </table>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  BASE_URL, BASE_URL_SELLING, BUILD_ENV, Auth_Token
} from '@/constants/config.js'
import store from '@/store'
import moment from 'moment'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const userDetails = computed(() => store.state.user.currentUser)
    const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
    const screenData = reactive({
      report_items: [],
      shape_arr: [],
      generate: 'no',
      loading: false,
      stones: [],
      branch_arr: [],
      size_arr: [],
      charni_arr: [],
      color_arr: [],

      size_type: [],
      shape_type: '1',
      color_size: '',
      stone_type: '1',
      charni_id: [],
      rate_type: '1',
      stock_type: '1',
      branch_id: '0',
    })

    const excelData = reactive({
      size_type: [],
      shape_type: '1',
      stone_type: '1',
      charni_id: [],
      rate_type: '1',
      stock_type: '1',
      branch_id: '0',
    })

    const initTabulator = () => {

      excelData.size_type = screenData.size_type
      excelData.shape_type = screenData.shape_type
      excelData.stone_type = screenData.stone_type
      excelData.charni_id = screenData.charni_id
      excelData.rate_type = screenData.rate_type
      excelData.stock_type = screenData.stock_type
      excelData.branch_id = screenData.branch_id
      excelData.color_size = screenData.color_size
      const params = {
        stone_type: screenData.stone_type != '0' ? screenData.stone_type : '',
        shape_type: screenData.shape_type != '0' ? screenData.shape_type : '',
        branch_id: screenData.branch_id != '0' ? screenData.branch_id : '',
        rate_type: screenData.rate_type != '0' ? screenData.rate_type : '',
        color_size: screenData.color_size != '0' ? screenData.color_size : '',
        stock_type: screenData.stock_type != '0' ? screenData.stock_type : '',
        size_type: screenData.size_type,
        charni_id: screenData.charni_id,
      };

      screenData.loading = true

      let promise = axios({
        url: "/get_selling_non_certy_stock",
        method: "POST",
        data: params,
        baseURL: BASE_URL_SELLING,
        headers: {
          Authorization: "Bearer " + AUTH_TOKEN
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.report_items = data.items;
          screenData.generate = 'gen';
          screenData.loading = false

        })
        .catch(_error => {

          screenData.generate = 'no';
          screenData.loading = false
        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    const getBranchDetails = () => {
      const params = {};
      let promise = axios({
        url: "/dp_account_branch_office",
        method: "POST",
        params: params,
        baseURL: BASE_URL_SELLING,
        headers: {
          Authorization: "Bearer " + AUTH_TOKEN
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.branch_arr = data.items

        })
        .catch(_error => {
          screenData.branch_arr = []

        });
    }

    const getCharniDetails = () => {
      const params = {
        stone_type: screenData.stone_type
      };
      let promise = axios({
        url: "/main_charni_list",
        method: "POST",
        data: params,
        baseURL: BASE_URL_SELLING,
        headers: {
          Authorization: "Bearer " + AUTH_TOKEN
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.charni_arr = data.items

        })
        .catch(_error => {
          screenData.charni_arr = []

        });
    }

    const getColorDetails = async (item) => {

      if (screenData.stone_id == "0") {
        screenData.color_arr = [];
        screenData.color_size = ""
        initTabulator()
      }
      const params = {
        stone_type: screenData.stone_type
      };
      const url = "/dp_shade_size_items"
      let promise = axios({
        url: url,
        method: "POST",
        data: params,
        baseURL: BASE_URL_SELLING,
        headers: {
          Authorization: "Bearer " + AUTH_TOKEN
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.color_arr = data.items;
          screenData.color_size = String(screenData.color_arr[0].segment_code)
          // initTabulator()
        })
        .catch(_error => {
          screenData.color_arr = [];
          screenData.color_size = ""
          // initTabulator()
        });

    }

    const getShapeDetails = async () => {

      const params = {
      };
      const url = "/dp_shape"
      let promise = axios({
        url: url,
        method: "POST",
        data: params,
        baseURL: BASE_URL_SELLING,
        headers: {
          Authorization: "Bearer " + AUTH_TOKEN
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.shape_arr = data.items
        })
        .catch(_error => {
          screenData.shape_arr = []
        });

    }

    const export_excel = (type) => {

      let stone_name = ""
      if (excelData.stone_type == 1) {
        stone_name = "NATURAL"
      } else if (excelData.stone_type == 2) {
        stone_name = "CVD"

      } else if (excelData.stone_type == 3) {
        stone_name = "HPHT"

      } else if (excelData.stone_type == 4) {
        stone_name = "D_CVD"

      } else if (excelData.stone_type == 5) {
        stone_name = "D_HPHT"

      }
      // let filename = 'DKStock.xlsx';
      let filename = 'DKStock' + stone_name + moment().format('Mth DD, YYYY') + '.' + type;
      let params = {
        report_details: screenData.report_items,
        type: type,
        stone_type: excelData.stone_type
      }
      let promise = axios({
        url: "/dawnload_selling_stock",
        method: "post",
        data: params,
        baseURL: BASE_URL_SELLING,
        headers: { Authorization: "Bearer " + AUTH_TOKEN },
        config: { headers: { Accept: "application/json" } },
        responseType: "blob",
      });

      return promise
        .then((result) => result.data)
        .then((data) => {
          FileDownload(data, filename);
        })
        .catch((_error) => {
          return [];
        });

      // let params = {
      //   report_details: screenData.report_items,
      //   type: type,
      //   stone_type: excelData.stone_type
      // }
      // let promise = axios({
      //     url: "api/dawnload_selling_stock",
      //     method: "POST",
      //     baseURL: BASE_URL,
      //     data: params,
      //     headers: {
      //         Authorization: "Bearer " + localStorage.getItem("token")
      //     },
      //     config: {
      //         headers: {
      //             Accept: "application/json"
      //         }
      //     },
      //     responseType: "blob",
      // });
      // return promise
      //     .then(result => result.data)
      //     .then(data => {
      //         // screenData.total = data.total;
      //     })
      //     .catch(_error => {

      //     });
    }

    const getSizeDetails = () => {
      const params = {
        stone_type: screenData.stone_type
      };
      let promise = axios({
        url: "/dp_size_items",
        method: "POST",
        data: params,
        baseURL: BASE_URL_SELLING,
        headers: {
          Authorization: "Bearer " + AUTH_TOKEN
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.size_arr = data.items

        })
        .catch(_error => {
          screenData.size_arr = []

        });
    }

    const onResetFilter = () => {
      screenData.size_type = []
      screenData.shape_type = "1"
      screenData.stone_type = "1"
      screenData.charni_id = []
      screenData.rate_type = "1"
      screenData.stock_type = "1"
      screenData.branch_id = "0"
    }

    const getStoneParams = () => {
      // setTimeout(() => {
      // }, 300);
      getCharniDetails()
      getSizeDetails()
    }

    onMounted(() => {
      getStoneParams()
      // initTabulator()
      getShapeDetails()
      getBranchDetails()
      getColorDetails()
    })

    return {
      tableRef,
      excelData,
      screenData,
      initTabulator,
      getShapeDetails,
      userDetails,
      getStoneParams,
      onResetFilter,
      export_excel, getColorDetails
    }

  }
})
</script>
