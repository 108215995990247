<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">New Invoice Payment</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Bill Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Party <span class="text-primary-3"> *</span></label>
                                <div class="" id="seller_id">

                                    <TomSelect v-model="form_data.seller_id" :options="{
                            placeholder: 'Select Party',
                            }" class="w-full" @change="get_transaction_records">
                                        <option value="0">Select Party</option>
                                        <option v-for="(item,index) in page_data.selling_parties" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">PAN NO</label>
                                <div class="" id="pan_no">

                                    <input v-model="form_data.pan_no" type="text" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">GST NO</label>
                                <div class="" id="gst_no">

                                    <input v-model="form_data.gst_no" type="text" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Bank Charges (any)</label>
                                <div class="" id="bank_charges">

                                    <input v-model="form_data.bank_charges" type="text" autocomplete="off" @input="$h.preventInvalidInput" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Payment Date <span class="text-primary-3"> *</span></label>
                                <div class="" id="payment_date">

                                    <input v-model="form_data.payment_date" type="date" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Payment Type <span class="text-primary-3"> *</span></label>
                                <div class="" id="payment_type">

                                    <TomSelect v-model="form_data.payment_type" :options="{
                            placeholder: 'Select Sell Type ',
                            }" class="w-full">
                                        <option value="0">Sell Type</option>
                                        <option value="1">Bank Remittance</option>
                                        <option value="2">Bank Transfer</option>
                                        <option value="3">Cash</option>
                                        <option value="4">Check</option>
                                        <option value="5">Credit Card</option>
                                        <option value="6">NEFT</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Reference</label>
                                <div class="" id="reference">

                                    <input v-model="form_data.reference" type="text" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Note</label>
                                <div class="" id="note">

                                    <input v-model="form_data.note" type="text" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>

                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Weight</label>
                                <div class="" id="total_pcs">

                                    <input v-model="form_data.total_weight" disabled type="text" autocomplete="off" placeholder="Total Pieces" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Amount</label>
                                <div class="" id="total_wt">

                                    <input v-model="form_data.total_amount" disabled type="text" placeholder="Total Weight" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Settlement Amount</label>
                                <div class="" id="total_wt">

                                    <input v-model="form_data.total_statement_amount" disabled type="text" placeholder="Total Weight" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="flex items-center justify-between">
                        <div>
                        </div>
                        <div>

                            <button type="button" @click="cancel_sell" class="btn btn-outline-secondary w-32 mr-1">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary w-32" :disabled="page_data.form_status" @click="add_new_record">
                                Save Details
                                <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-4 h-4 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
        <!-- </div>
          <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="p-5">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mx-5">Final sell Details</h2>

                        <div class="w-100">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Selling Transactions <span class="text-primary-3"> *</span></label>
                                <div class="" id="selling_transactions">

                                    <TomSelect v-model="form_data.invoice_list" :options="{
                            placeholder: 'Select Transaction',
                            }" multiple class="w-96" @change="add_record_to_list">
                                        <option v-for="(item,index) in page_data.transaction_list" :key="index" :value="item.id">{{ item.transaction_code }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="border mt-5 p-0 shadow-md mt-5">
                            <div class="overflow-x-auto"> -->
                    <table class="border mt-3 w-full">
                        <thead>
                            <tr class="border-b">
                                <th class="py-2" rowspan="2">Sr No</th>
                                <th class="py-2">Invoice No</th>
                                <!-- <th class="py-2">Broker Name</th> -->
                                <th class="py-2">Sell Type</th>
                                <th class="py-2">Total Pieces</th>
                                <th class="py-2">Total Weight</th>
                                <th class="py-2">Branch Name</th>
                                <th class="py-2">Sell Date</th>
                                <th class="py-2">Total Amount</th>
                                <th class="py-2">Paid Amount</th>
                                <th class="py-2">Pending Amount</th>
                                <th class="py-2">Settlement Type</th>
                                <th class="py-2">Settlement Amount</th>
                                <th class="py-2">Payable Amount</th>
                                <th class="py-2">Payment Type</th>
                                <!-- <th class="py-2"></th> -->
                            </tr>
                        </thead>
                        <tbody class="border" v-for="(plan,index) in form_data.invoice_detail_list" :key="index">
                            <tr>
                                <td class="w-8" rowspan="2">
                                    <div class="font-bold text-center">{{ index + 1 }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.transaction_code }}</div>
                                </td>
                                <!-- <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.broker_first_name ? plan.broker_first_name : '-' }} {{ plan.broker_last_name ? plan.broker_last_name : '' }}</div>
                                </td> -->
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.stock_type }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.total_pcs }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.total_wt }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.branch_name }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.sell_date }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.total_amount ? plan.total_amount : 0 }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.paid_amount ? plan.paid_amount : 0 }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.pending_amount ? plan.pending_amount : 0 }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'settlement_type_'+ index">
                                        <input v-model="plan.settlement_type" placeholder="Settlement Type" type="text" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'settlement_amount_'+ index">
                                        <input v-model="plan.settlement_amount" placeholder="Settlement AMT" type="text" @keypress="$h.preventInvalidInput" @input="validate_singe_amount(plan)" autocomplete="off" class="form-control" :disabled="plan.payment_type == '1'" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'payable_amount_'+ index">
                                        <input v-model="plan.payable_amount" placeholder="Voucher AMT" type="text" @keypress="$h.preventInvalidInput" @change="validate_singe_amount(plan)" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'payment_type_'+ index">
                                      <select v-model="plan.payment_type" @change="validate_singe_amount(plan)" class="form-select w-full">
                                        <option value="1">Full</option>
                                        <option value="2">Manual</option>
                                      </select>

                                    </div>
                                </td>
                                <!-- <td class="w-16">
                                    <button type="button" class="text-theme-6 flex items-center" tabindex="-1" @click="remove_record(plan.id)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- END: Input -->
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent,
    inject,
    onMounted,
    reactive,
    watch
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL_SELLING,
    BASE_URL,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'
import {
    integer
} from '@vuelidate/validators'
import router from '@/router'
import moment from 'moment'

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    setup() {
        const swal = inject('$swal')
        const page_data = reactive({
            form_status: false,
            selling_parties: [],
            transaction_list: [],
            stone_natural: false,
            scan_lot_no: '',
            scan_load: false,
            stone_disable: false

        })
        const AUTH_TOKEN = localStorage.getItem("token")
        // const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const form_data = reactive({

            seller_id: "0",
            pan_no: "",
            gst_no: "",
            bank_charges: "",
            payment_date: moment().format('YYYY-MM-DD'),
            payment_type: "0",
            reference: "",
            note: "",
            total_pieces: 0,
            total_weight: 0,
            total_amount: 0,
            total_statement_amount: 0,
            invoice_list: [],
            invoice_detail_list: [],
        })

        const add_new_record = async () => {

            const sell_items = form_data.invoice_detail_list

            // return;
            if (sell_items.length > 0) {

                // Validate Fields

                let is_empty = false

                if (form_data.payment_type == "0" || form_data.payment_type == null) {
                    $('#payment_type').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#payment_type').removeClass('border border-theme-6')
                }
                if (form_data.seller_id == "0" || form_data.seller_id == null) {
                    $('#seller_id').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#seller_id').removeClass('border border-theme-6')
                }

                for (let i = 0; i < sell_items.length; i++) {

                    // if (sell_items[i].settlement_amount == '' || sell_items[i].settlement_amount == null) {
                    //     $('#settlement_amount_' + i).addClass('border border-theme-6');
                    //     is_empty = true;
                    // } else {
                    //     $('#settlement_amount_' + i).removeClass('border border-theme-6');
                    // }
                    if (sell_items[i].payable_amount == '' || sell_items[i].payable_amount == null) {
                        $('#payable_amount_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#payable_amount_' + i).removeClass('border border-theme-6');
                    }
                }

                if (is_empty == true) {
                    swal({
                        title: "Warning!",
                        icon: "warning",
                        text: "Fields Are Empty!",
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                    })
                    return
                }

                page_data.form_status = true

                const url = "/api/add_new_voucher"
                let promise = axios({
                    url: url,
                    method: "POST",
                    data: form_data,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + AUTH_TOKEN
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.status == "error") {
                            page_data.form_status = false
                            swal("Error!", data.message, "error");

                            return;
                        }
                        swal.fire({
                            icon: 'success',
                            title: "Success!",
                            text: data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                        page_data.form_status = false
                        // let transaction_id = data.transaction_details.id
                        router.push('/app/accounts/chalan/list');
                    })
                    .catch(_error => {
                        page_data.form_status = false
                        swal("Error!", "Something went Wrong", "error");

                        return;
                    });

            } else {

                page_data.form_status = false
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are empty!",
                    type: "warning",
                })
                return
            }
        }

        // Get Seller Party
        const get_seller_party = async () => {
            const params = {};
            const url = "/api/dp_account_selling_party"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.selling_parties = data.items;
                })
                .catch(_error => {
                    page_date.selling_parties = [];
                });
        }
        // Get Customer wise Transactions
        const get_transaction_records = async () => {

          if(form_data.seller_id != '0') {

            const params = {
              party_id: window.btoa(form_data.seller_id)
            };
            const url = "/api/get_customer_selling_list"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                  form_data.invoice_list = []
                  page_data.transaction_list = data.items;
                })
                .catch(_error => {
                  page_data.transaction_list = [];
                  form_data.invoice_list = []
                });
              } else {
            form_data.invoice_list = []
            page_data.transaction_list = [];



          }

        }

        // Add Record to List
        const add_record_to_list = () => {


            if (form_data.invoice_detail_list.length > form_data.invoice_list.length) {

                // Remove Records from List
                let records_length = form_data.invoice_detail_list.length

                let short_arr = []

                for (let index = 0; index < records_length; index++) {

                    if (form_data.invoice_list.includes(String(form_data.invoice_detail_list[index].id))) {
                    } else {
                      short_arr.push(index)
                    }

                }
                short_arr.forEach(element => {
                  form_data.invoice_detail_list.splice(element, 1);
                });
                calculate_amount();

            } else if (form_data.invoice_detail_list.length < form_data.invoice_list.length) {

                  var i = page_data.transaction_list.length;
                  while (i--) {

                    let last_element = form_data.invoice_list[form_data.invoice_list.length - 1];
                      if (page_data.transaction_list[i]["id"] == last_element) {

                        console.log(page_data.transaction_list[i]);

                        let new_arr = {

                          id : page_data.transaction_list[i]["id"],
                          stock_type : page_data.transaction_list[i]["stock_type"],
                          transaction_code : page_data.transaction_list[i]["transaction_code"],
                          branch_id : page_data.transaction_list[i]["branch_id"],
                          issue_by : page_data.transaction_list[i]["issue_by"],
                          seller_id : page_data.transaction_list[i]["seller_id"],
                          broker_id : page_data.transaction_list[i]["broker_id"],
                          total_pcs : page_data.transaction_list[i]["total_pcs"],
                          branch_name : page_data.transaction_list[i]["branch_name"],
                          total_wt : page_data.transaction_list[i]["total_wt"],
                          selling_bunch_details : page_data.transaction_list[i]["selling_bunch_details"],
                          selling_certy_details : page_data.transaction_list[i]["selling_certy_details"],
                          total_amount : page_data.transaction_list[i]["total_amount"],
                          brokerage_amount : page_data.transaction_list[i]["brokerage_amount"],
                          sell_date : page_data.transaction_list[i]["sell_date"],
                          payment_terms : page_data.transaction_list[i]["payment_terms"],
                          pending_amount : page_data.transaction_list[i]["pending_amount"],
                          paid_amount : page_data.transaction_list[i]["paid_amount"],
                          payment_status : page_data.transaction_list[i]["payment_status"],
                          due_date : page_data.transaction_list[i]["due_date"],
                          // payment_type : page_data.transaction_list[i]["payment_type"],
                          party_first_name : page_data.transaction_list[i]["party_first_name"],
                          party_last_name : page_data.transaction_list[i]["party_last_name"],
                          broker_first_name : page_data.transaction_list[i]["broker_first_name"],
                          broker_last_name : page_data.transaction_list[i]["broker_last_name"],
                          issue_date : page_data.transaction_list[i]["issue_date"],
                          payable_amount : "",
                          settlement_amount : "",
                          settlement_type : "",
                          payment_type : '1',
                        }

                        form_data.invoice_detail_list.push(new_arr)

                        calculate_amount()

                      }
                  }


            }
        }

        // Remove Bunch Details
        const remove_record = (id) => {
            swal({
                title: "Are you sure?",
                text: "You are about to Delete Item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.invoice_detail_list.length;
                    while (i--) {
                        if (form_data.invoice_detail_list[i]["id"] == id) {

                            form_data.invoice_detail_list.splice(i, 1);

                            // calculate_amount();

                        }
                    }
                    var ind = form_data.invoice_list.length;
                    while (ind--) {
                      console.log(form_data.invoice_list[ind])
                      console.log(id)
                      if (form_data.invoice_list[ind] == String(id)) {
                        console.log(true)


                            form_data.invoice_list.splice(ind, 1);

                            console.log(form_data.invoice_list)
                            // calculate_amount();

                            // return;

                          }
                        }
                        // calculate_amount();
                }
            });
        }

        const validate_singe_amount = (item) => {

            let payment_type = item.payment_type
            let total_amount = item.total_amount ? parseFloat(item.total_amount) : 0
            let paid_amount = item.paid_amount ? parseFloat(item.paid_amount) : 0
            let pending_amount = item.pending_amount ? parseFloat(item.pending_amount) : 0
            let payable_amount = item.payable_amount ? parseFloat(item.payable_amount) : 0
            let settlement_amount = item.settlement_amount ? parseFloat(item.settlement_amount) : 0

            // Max Payable Amount

            const max_payable = $h.myFloat(parseFloat(total_amount) + parseFloat(total_amount * 5 / 100))
            const min_payable = $h.myFloat(parseFloat(total_amount) - parseFloat(total_amount * 1.5 / 100))

            // Validate Payable Amount
            if((paid_amount + payable_amount) > max_payable) {
              swal("Warning", "Amount must be less then maximum payable amount :- " + ($h.myFloat(max_payable - paid_amount)), "warning");
              item.payable_amount = "";
              item.settlement_amount = "";
              calculate_amount();

              return;
            }

            if(payable_amount < (min_payable - paid_amount)) {
              swal("Warning", "Amount must be less then minimum payable amount :- " + ($h.myFloat(min_payable - paid_amount)), "warning");
              item.payable_amount = "";
              item.settlement_amount = "";
              calculate_amount();

              return;

            }
            if(payment_type == '1') {

              // Auto Calculate the settlement Amount
              settlement_amount = $h.myFloat(total_amount - paid_amount - payable_amount)

              // Assign Value to Main Table
              item.settlement_amount = settlement_amount

              calculate_amount();
              return;

            } else if(payment_type == '2') {

              if (settlement_amount > 0) {

                if((paid_amount + payable_amount + settlement_amount) > max_payable) {
                  swal("Warning", "Amount must be less then maximum payable amount :- " + ($h.myFloat(max_payable - paid_amount)), "warning");
                  item.payable_amount = "";
                  item.settlement_amount = "";
                  calculate_amount()

                  return;
                } else {
                  calculate_amount()
                  return;
                }
              } else {
                calculate_amount();
                return;
              }
            }


        }

        // Calculate Total Amount
        const calculate_amount = () => {

            let total_pcs = 0;
            let total_wt = 0;
            let payable_amount = 0;
            let settlement_amount = 0;

            form_data.invoice_detail_list.forEach(element => {

                total_pcs += parseFloat(element.total_pcs ? element.total_pcs : 0)
                total_wt += parseFloat(element.total_wt ? element.total_wt : 0)
                payable_amount += parseFloat(element.payable_amount ? element.payable_amount : 0)
                settlement_amount += parseFloat(element.settlement_amount ? element.settlement_amount : 0)
            });

            form_data.total_pieces = $h.myFloat(total_pcs)
            form_data.total_weight = $h.myFloat(total_wt)
            form_data.total_amount = $h.myFloat(payable_amount)
            form_data.total_statement_amount = $h.myFloat(settlement_amount)
        }

        function cancel_sell() {
            router.go(-1)
        }

        // On Mount
        onMounted(() => {
            // document.querySelector('[id="scan_lot"]').focus();
            get_seller_party();
        })

        return {
            page_data,
            form_data,
            get_transaction_records,
            remove_record,
            add_record_to_list,
            cancel_sell,
            validate_singe_amount,
            add_new_record
        }
    },

})
</script>
