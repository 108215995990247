<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish - Selling (Certy)</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Issue Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1" v-if="page_data.stone_disable == true">
                                <label for="update-profile-form-7" class="form-label">Select Stone <span class="text-primary-3"> *</span></label>
                                <div class="" id="stone_type">

                                    <TomSelect v-model="form_data.stone_type"
                                    disabled :options="{
                          placeholder: 'Select Stone',
                          }" class="w-full">
                                        <option value="0">Select Stone</option>
                                        <option value="1">NATURAL</option>
                                        <option value="2">CVD</option>
                                        <option value="4">D_CVD</option>
                                    </TomSelect>
                                </div>
                            </div>
                            <div class="py-1" v-else>
                                <label for="update-profile-form-7" class="form-label">Select Stone <span class="text-primary-3"> *</span></label>
                                <div class="" id="stone_type">

                                    <TomSelect v-model="form_data.stone_type"
                                    :options="{
                          placeholder: 'Select Stone',
                          }" class="w-full">
                                        <option value="0">Select Stone</option>
                                        <option value="1">NATURAL</option>
                                        <option value="2">CVD</option>
                                        <option value="4">D_CVD</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Party <span class="text-primary-3"> *</span></label>
                                <div class="" id="seller_id">

                                    <TomSelect v-model="form_data.seller_id" :options="{
                          placeholder: 'Select Party',
                          }" class="w-full">
                                        <option value="0">Select Party</option>
                                        <option v-for="(item,index) in page_data.selling_parties" :key="index" :value="item.id">{{ (item.seller_name ? item.seller_name : "-") }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                          <div class="py-1">
                              <label for="update-profile-form-7" class="form-label">Select Branch</label>
                              <div class="" id="broker_id">

                                  <TomSelect v-model="form_data.branch_id" :options="{
                          placeholder: 'Select Broker ',
                          }" class="w-full">
                                      <option value="">Select Branch</option>
                                      <option value="1">Home Branch</option>
                                      <option v-for="(item,index) in page_data.branch_arr" :key="index" :value="item.id">{{ item.branch_name }}</option>
                                  </TomSelect>
                              </div>
                          </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Broker</label>
                                <div class="" id="broker_id">

                                    <TomSelect v-model="form_data.broker_id" :options="{
                          placeholder: 'Select Broker ',
                          }" class="w-full">
                                        <option value="0">Select Broker</option>
                                        <option v-for="(item,index) in page_data.selling_broker" :key="index" :value="item.id">{{ (item.broker_name ? item.broker_name : "-") }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Sell Type <span class="text-primary-3"> *</span></label>
                                <div class="" id="payment_type">

                                    <TomSelect v-model="form_data.payment_type" :options="{
                          placeholder: 'Select Sell Type ',
                          }" class="w-full">
                                        <option value="">Sell Type</option>
                                        <option value="1">Cash</option>
                                        <option value="2">Bill</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Sell Date <span class="text-primary-3"> *</span></label>
                                <div class="" id="sell_date">

                                    <input v-model="form_data.sell_date" type="date" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Payment Terms <span class="text-primary-3"> *</span></label>
                                <div class="" id="payment_terms">

                                    <input v-model="form_data.payment_terms" type="number" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Pieces</label>
                                <div class="" id="total_pcs">

                                    <input v-model="form_data.total_pcs" disabled type="text" autocomplete="off" placeholder="Total Pieces" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Weight</label>
                                <div class="" id="total_wt">

                                    <input v-model="form_data.total_wt" disabled type="text" placeholder="Total Weight" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Amount</label>
                                <div class="" id="total_amount">

                                    <input v-model="form_data.total_amount" disabled type="text" autocomplete="off" placeholder="Total Amount" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Brokerage</label>
                                <div class="" id="brokerage_amount">

                                    <input v-model="form_data.brokerage_amount" disabled type="text" placeholder="Brokerage" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="flex items-center justify-between">
                        <div>
                        </div>
                        <div>

                            <button type="button" @click="cancel_sell" class="btn btn-outline-secondary w-32 mr-1">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary w-32" :disabled="page_data.form_status" @click="sell_stock">
                                Save Details
                                <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-4 h-4 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
        <!-- </div>
        <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5 mb-10">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="p-5">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mx-5">Final sell Details</h2>

                        <div>
                            <!-- <label for="scan_lot" class="form-label">Scan Barcode</label> -->
                            <input id="scan_lot" type="text" class="w-56" :class="{'form-control':true,'loading':page_data.scan_load}" placeholder="Scan Barcode / Stock No" v-model="page_data.scan_lot_no" @change="scanAndAddPack" autocomplete="off" />
                        </div>

                    </div>
                    <!-- <div class="border mt-5 p-0 shadow-md mt-5">
                          <div class="overflow-x-auto"> -->
                    <table class="border mt-3 w-full">
                        <thead>
                            <tr class="border-b">
                                <th class="py-2" rowspan="2">Sr No</th>
                                <th class="py-2">Stock No</th>
                                <th class="py-2">Certy No</th>
                                <th class="py-2">Weight (Ct)</th>
                                <th class="py-2">LAB</th>
                                <th class="py-2">Stone</th>
                                <th class="py-2">Shape</th>
                                <th class="py-2">Color</th>
                                <th class="py-2">Clarity</th>
                                <th class="py-2">Repo <span class="text-primary-3"> *</span></th>
                              </tr>
                              <tr>
                              <th class="py-2">Exp Back (%) <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Cr Back (%)</th>
                                <th class="py-2">Sell Back (%)</th>
                                <th class="py-2">Currency</th>
                                <!-- <th class="py-2">CNBC Type</th> -->
                                <th class="py-2">CNBC Rate</th>
                                <th class="py-2">Less</th>
                                <th class="py-2">Amount</th>
                                <th class="py-2">Brokerage (%)</th>
                                <th class="py-2">Brokerage</th>
                                <th class="py-2"></th>
                            </tr>
                        </thead>
                        <tbody class="border" v-for="(plan,index) in form_data.bunch_details" :key="index">
                            <tr>
                                <td class="w-8" rowspan="2">
                                    <div class="font-bold text-center">{{ index + 1 }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.pack_no }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.certificate_no }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.weight }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.lab }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.stone_name }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.shape_name }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.color_name }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="font-bold text-center">{{ plan.clarity_name }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'repo_rate_'+ index">
                                        <input v-model="plan.repo_rate" @change="calculate_sell_rate(plan)" placeholder="Repo Rate" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                              </tr>
                              <tr>
                              <td class="w-36">
                                  <div class="" :id="'exp_back_'+ index">
                                      <input v-model="plan.exp_back" placeholder="Exp Back (%)" type="number" autocomplete="off" class="form-control" />

                                  </div>
                              </td>
                                <td class="w-36">
                                    <div class="" :id="'cr_back_'+ index">
                                        <input v-model="plan.cr_back" placeholder="Cr Back (%)" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'sell_back_'+ index">
                                        <input v-model="plan.sell_back" @change="calculate_sell_rate(plan)" placeholder="Sell Back (%)" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'currency_'+ index">
                                        <input v-model="plan.currency" placeholder="Currency" type="text" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <!-- <td class="w-36">
                                    <div class="" :id="'cnbc_type_'+ index">
                                        <select v-model="plan.cnbc_type" class="w-full form-select" @change="calculate_sell_rate(plan)">
                                            <option value="1">Per Ct</option>
                                            <option value="2">Fixed</option>
                                        </select>

                                    </div>
                                </td> -->
                                <td class="w-36">
                                    <div class="" :id="'cnbc_rate_'+ index">
                                        <input v-model="plan.cnbc_rate" @change="calculate_sell_rate(plan)" placeholder="CNBC Rate" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'sell_less_'+ index">
                                        <input v-model="plan.sell_less" @change="calculate_sell_rate(plan)" placeholder="Sell Less" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'total_amount_'+ index">
                                        <input v-model="plan.total_amount" disabled @change="calculate_broker_rate(plan)" placeholder="Total Amount" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'broker_per_'+ index">
                                        <input v-model="plan.broker_per" @change="calculate_broker_rate(plan)" placeholder="Brokerage (%)" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'brokerage_amount_'+ index">
                                        <input v-model="plan.brokerage_amount" disabled placeholder="Brokerage" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-16">
                                    <button type="button" class="text-theme-6 flex items-center" tabindex="-1" @click="remove_bunch(plan.id)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- END: Input -->
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent,
    inject,
    onMounted,
    reactive,
    watch
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'
import {
    integer
} from '@vuelidate/validators'
import router from '@/router'
import { useRoute } from 'vue-router';

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    setup() {
        const swal = inject('$swal')
        const page_data = reactive({
            form_status: false,
            selling_parties: [],
            selling_broker: [],
            charni_items: [],
            stone_natural: false,
            scan_lot_no: '',
            scan_load: false,
            stone_disable: false

        })
        const route = useRoute()
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const form_data = reactive({

            trans_id: "",
            seller_id: "0",
            stone_type: "0",
            broker_id: "0",
            branch_id: "1",
            total_pcs: "",
            total_wt: "",
            total_amount: "",
            brokerage_amount: "",
            sell_date: "",
            payment_terms: "",
            payment_type: "",
            ref_no: "",
            sell_items: 1,
            bunch_details: []
        })

        // Get Seller Party
        const get_sell_details = async () => {
            const params = {
              trans_id: route.params.trans_id
            };
            const url = "/sell_edit_details"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                  form_data.trans_id = route.params.trans_id
                  form_data.seller_id = String(data.item.seller_id)
                  form_data.broker_id = String(data.item.broker_id)
                  form_data.branch_id = String(data.item.branch_id)
                  form_data.total_pcs = data.item.total_pcs
                  form_data.total_wt = data.item.total_wt
                  form_data.total_amount = data.item.total_amount
                  form_data.brokerage_amount = data.item.brokerage_amount
                  form_data.sell_date = data.item.sell_date
                  form_data.payment_terms = data.item.payment_terms
                  form_data.payment_type = data.item.payment_type
                  form_data.ref_no = data.item.ref_no
                  form_data.sell_items = data.item.stock_items.length
                  form_data.bunch_details = data.item.stock_items
                  form_data.stone_type = String(form_data.bunch_details[0].stone_type)
                })
                .catch(_error => {
                });
        }

        // Get Seller Party
        const get_seller_party = async () => {
            const params = {};
            const url = "/dp_account_selling_party"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.selling_parties = data.items;
                })
                .catch(_error => {
                    page_date.selling_parties = [];
                });
        }

        // Get Seller Party
        const get_selling_broker = async () => {
            const params = {};
            const url = "/dp_account_selling_broker"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.selling_broker = data.items;
                })
                .catch(_error => {
                    page_date.selling_broker = [];
                });
        }

        const scanAndAddPack = async () => {

            page_data.scan_load = true;

            if(form_data.bunch_details.length > 0 && form_data.stone_type == '') {
              swal("Warning!", "Please, select stone first!", "warning");
              return
            }

            const data = {
                barcode: page_data.scan_lot_no,
                stone_type: form_data.stone_type,
                branch_id: form_data.branch_id,
            };
            let url_post = '';
            if (page_data.scan_lot_no != '') {
                url_post = "/scan_and_add_certy_sell_packet"
            } else {
                url_post = "/scan_and_add_certy_sell_packet"
            }
            let promise = axios({
                url: url_post,
                method: "post",
                data: data,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {
                    console.log(data);
                    if (data.packet_details == 'No') {

                      page_data.scan_lot_no = "";
                        swal("Warning!", data.error_msg, "warning");
                        page_data.scan_load = false;
                        document.querySelector('[id="scan_lot"]').focus();
                    } else if (data.packet_details == 'process') {
                      page_data.scan_lot_no = "";
                        swal("Warning!", "Scanned barcode is already in process", "warning");
                        page_data.scan_load = false;
                        document.querySelector('[id="scan_lot"]').focus();
                    } else {
                        let pack_details = data.packet_details;

                        var i = form_data.bunch_details.length;
                        while (i--) {
                            if (form_data.bunch_details[i]['certy_stock_id'] == pack_details.id) {
                                page_data.scan_lot_no = "";
                                swal("Warning!", "Already in list, Scan other code!", "warning");
                                page_data.scan_load = false;
                                document.querySelector('[id="scan_lot"]').focus();
                                return;
                            }
                        }
                        let n_stone_name = ''

                        if(pack_details.stone_type == 1) {
                          n_stone_name = 'NATURAL'
                        } else if(pack_details.stone_type == 2) {
                          n_stone_name = 'CVD'
                        } else if(pack_details.stone_type == 3) {
                          n_stone_name = 'HPHT'
                        } else if(pack_details.stone_type == 4) {
                          n_stone_name = 'D_CVD'
                        } else if(pack_details.stone_type == 5) {
                          n_stone_name = 'D_HPHT'
                        }

                        form_data.bunch_details.push({
                            id: pack_details.id,
                            certy_stock_id: pack_details.id,
                            pack_no: pack_details.lab_code,
                            barcode: pack_details.lab_barcode,
                            certificate_no: pack_details.report_no,
                            lab: pack_details.new_lab,
                            shape_name: pack_details.shape_name,
                            shape: pack_details.shape,
                            color: pack_details.color,
                            stone_type: pack_details.stone_type,
                            stone_name: n_stone_name,
                            color_name: pack_details.color_name,
                            clarity: pack_details.purity,
                            clarity_name: pack_details.purity_name,
                            weight: pack_details.cr_weight,
                            repo_rate: pack_details.repo_rate ? pack_details.repo_rate : "",
                            exp_back: "",
                            cr_back: "",
                            sell_back: "",
                            currency: "INR",
                            cnbc_rate: "",
                            sell_less: "",
                            total_amount: "",
                            broker_per: "",
                            brokerage_amount: "",
                        });

                        page_data.scan_lot_no = "";
                        form_data.total_pcs = (form_data.total_pcs > 0 ? parseFloat(form_data.total_pcs) : 0) + parseFloat(1);
                        if (form_data.stone_type == "0") {

                          let n_stone_type = ''

                          if(form_data.stone_type == 1) {
                            n_stone_type = 1
                          } else if(form_data.stone_type == 2 || form_data.stone_type == 3) {
                            n_stone_type = 2
                          } else if(form_data.stone_type == 4 || form_data.stone_type == 5) {
                            n_stone_type = 4
                          }
                          form_data.stone_type = String(n_stone_type)
                        }
                        console.warn(form_data)
                        form_data.total_wt = $h.myFloat( (form_data.total_wt ? parseFloat(form_data.total_wt) : 0) + parseFloat(pack_details.cr_weight));

                        page_data.stone_disable = true
                        page_data.scan_load = false;
                        document.querySelector('[id="scan_lot"]').focus();
                    }
                })
                .catch((_error) => {
                  page_data.scan_lot_no = "";
                    page_data.scan_load = false;
                    document.querySelector('[id="scan_lot"]').focus();
                    return [];
                });

        }

        // Remove Bunch Details
        const remove_bunch = (id) => {
            swal({
                title: "Are you sure?",
                text: "You are about to Delete Item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.bunch_details.length;
                    while (i--) {
                        if (form_data.bunch_details[i]["id"] == id) {

                            form_data.total_pcs -= 1
                            // form_data.total_wt -= form_data.bunch_details[i]["weight"]

                            form_data.total_wt = $h.myFloat(parseFloat(form_data.total_wt) - parseFloat(form_data.bunch_details[i]["weight"]));

                            form_data.bunch_details.splice(i, 1);
                        }
                    }
                    let total_amount = 0;

                    form_data.bunch_details.forEach(element => {
                        total_amount = $h.myFloat(parseFloat(total_amount) + parseFloat(element.total_amount > 0 && !isNaN(element.total_amount) ? element.total_amount : 0))
                    });

                    form_data.total_amount = total_amount;
                }
            });
        }

        // Calculate Amount
        const calculate_sell_rate = async (item) => {
            if (item.repo_rate > 0 && !isNaN(item.repo_rate) && item.weight > 0 && !isNaN(item.weight)) {

                if (item.sell_back > 0 && !isNaN(item.sell_back)) {

                    item.total_amount = $h.myFloat(parseFloat(item.repo_rate * item.weight) - parseFloat((item.repo_rate * item.weight * item.sell_back) / 100));
                } else {
                    item.total_amount = $h.myFloat(item.repo_rate * item.weight);
                }
                if (item.cnbc_rate > 0 && !isNaN(item.cnbc_rate)) {
                    item.total_amount = $h.myFloat(item.total_amount * item.cnbc_rate)
                  }

                    if (item.sell_less > 0 && !isNaN(item.sell_less)) {

                      item.total_amount = $h.myFloat(parseFloat(item.total_amount) - parseFloat((item.total_amount * item.sell_less) / 100));
                      calculate_broker_rate(item)
                    } else {
                      item.total_amount = $h.myFloat(item.total_amount);
                      calculate_broker_rate(item)
                }

            } else {
                item.total_amount = "";
                calculate_broker_rate(item)
            }

            let total_amount = 0;

            form_data.bunch_details.forEach(element => {
                total_amount = $h.myFloat(parseFloat(total_amount) + parseFloat(element.total_amount > 0 && !isNaN(element.total_amount) ? element.total_amount : 0))
            });

            form_data.total_amount = total_amount;
        }
        // Calculate Broker Rate
        const calculate_broker_rate = async (item) => {
            if (item.total_amount > 0 && !isNaN(item.total_amount)) {

                if (item.broker_per > 0 && !isNaN(item.broker_per)) {

                    item.brokerage_amount = $h.myFloat((item.total_amount * item.broker_per) / 100);
                } else {

                    item.brokerage_amount = 0;
                }
            } else {
                item.brokerage_amount = 0;
            }

            let brokerage_amount = 0;

            form_data.bunch_details.forEach(element => {
                brokerage_amount = $h.myFloat(parseFloat(brokerage_amount) + parseFloat(element.brokerage_amount > 0 && !isNaN(element.brokerage_amount) ? element.brokerage_amount : 0))
            });

            form_data.brokerage_amount = brokerage_amount;
        }
        // Sell Stock To Party
        const sell_stock = async () => {

            const sell_items = form_data.bunch_details

            console.log(sell_items)

            // return;
            if (sell_items.length > 0) {

                // Validate Fields

                let is_empty = false

                if (form_data.stone_type == "0" || form_data.stone_type == null) {
                    $('#stone_type').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#stone_type').removeClass('border border-theme-6')
                }
                if (form_data.seller_id == "0" || form_data.seller_id == null) {
                    $('#seller_id').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#seller_id').removeClass('border border-theme-6')
                }
                if (form_data.total_pcs == "" || form_data.total_pcs == null) {
                    $('#total_pcs').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_pcs').removeClass('border border-theme-6')
                }
                if (form_data.total_wt == "" || form_data.total_wt == null) {
                    $('#total_wt').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_wt').removeClass('border border-theme-6')
                }
                if (form_data.total_amount == "" || form_data.total_amount == null) {
                    $('#total_amount').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_amount').removeClass('border border-theme-6')
                }
                if (form_data.sell_date == "" || form_data.sell_date == null) {
                    $('#sell_date').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#sell_date').removeClass('border border-theme-6')
                }
                if (form_data.payment_terms == "" || form_data.payment_terms == null) {
                    $('#payment_terms').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#payment_terms').removeClass('border border-theme-6')
                }
                if (form_data.payment_type == "" || form_data.payment_type == null) {
                    $('#payment_type').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#payment_type').removeClass('border border-theme-6')
                }

                for (let i = 0; i < sell_items.length; i++) {

                    if (sell_items[i].repo_rate == '' || sell_items[i].repo_rate == null) {
                        $('#repo_rate_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#repo_rate_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].sell_back == '' || sell_items[i].sell_back == null) {
                        $('#sell_back_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#sell_back_' + i).removeClass('border border-theme-6');
                    }
                }

                if (is_empty == true) {
                    swal({
                        title: "Warning!",
                        icon: "warning",
                        text: "Fields Are Empty!",
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                    })
                    return
                }

                page_data.form_status = true

                let url = ""

                if(form_data.trans_id != "") {
                  url = "/edit_sell_stock"
                } else {
                  url = "/sell_branch_stock"
                }
                let promise = axios({
                    url: url,
                    method: "POST",
                    data: form_data,
                    baseURL: BASE_URL_SELLING,
                    headers: {
                        Authorization: "Bearer " + AUTH_TOKEN
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.error == "YES") {
                            page_data.form_status = false
                            swal("Error!", data.message, "error");

                            return;
                        }
                        swal.fire({
                            icon: 'success',
                            title: "Success!",
                            text: data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                        page_data.form_status = false
                        let transaction_id = data.transaction_details.id
                        router.push('/app/app-final-polish/sell-transfer/' + window.btoa(transaction_id));
                    })
                    .catch(_error => {
                      page_data.form_status = false
                      swal("Error!", "Something went Wrong", "error");

                      return;
                    });

                  } else {

                    page_data.form_status = false
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are empty!",
                    type: "warning",
                })
                return
            }
        }
        const getBranchDetails = async () => {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/transfer_branches",
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.branch_arr = data.items;

                })
                .catch(_error => {
                  page_data.branch_arr = [];

                });
        }

        function cancel_sell() {
            router.go(-1)
        }

        // On Mount
        onMounted(() => {
            document.querySelector('[id="scan_lot"]').focus();
            get_seller_party();
            getBranchDetails();
            get_selling_broker();

            if(route.params.trans_id) {
              get_sell_details()
            }
        })

        return {
            page_data,
            form_data,
            remove_bunch,
            sell_stock,
            calculate_sell_rate,
            calculate_broker_rate,
            cancel_sell,
            scanAndAddPack
        }
    },

})
</script>

<style scoped>
/* .border {
      margin: 0 20px;
      padding: 3px;
    } */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
