<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tenders Issue Notes</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
         <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-issue-note"
          class="btn btn-primary shadow-md mr-2"
          >New Issue Note</a
        >
        
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
       <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="tender_name">Tender Name</option>
              <option value="start_time">Start Date</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>

    <div id="new-issue-note" class="modal modal-lg" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Issue Note</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-4">
              <label for="pos-form-1" class="form-label">Select Tender</label>
              <TomSelect
                v-model="issueNoteForm.selected_tender"
                :options="{
                  placeholder: 'Select Tender',
                  plugins: {
                    dropdown_header: {
                      title: 'Tender'
                    }
                  }
                }"
                class="w-full"
                @change="changeTender"
                
              >
              <option value="">Select Tender</option>
              <option v-for="tender in screenData.tender_ar" :key="tender.id" :value="tender.id">{{tender.tender_name}}</option>
              </TomSelect>
            </div>
            <div class="col-span-4">
              <label for="pos-form-2" class="form-label">Select Buyer</label>
              <TomSelect
                v-model="issueNoteForm.selected_buyer"
                :options="{
                  placeholder: 'Select Rough Buyer',
                  plugins: {
                    dropdown_header: {
                      title: 'Buyers'
                    }
                  }
                }"
                class="w-full"
                
              >
              <option value="">Select Buyer</option>
              <option v-for="buyer in screenData.buyer_ar" :key="buyer.id" :value="buyer.id">{{buyer.company_name}} ({{ buyer.company_email}})</option>
              </TomSelect>
            </div>
            
            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Select Tender Item</label
              >
               <button class="btn-link text-theme-9 float-right" @click="selectAll"
                >Select All</button
              >
              <TomSelect
                v-model="issueNoteForm.selected_packets"
                :options="{
                  placeholder: 'Select Tender Items',
                  plugins: {
                    dropdown_header: {
                      title: 'Items'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Tender Item</option>
              <option v-for="items in screenData.tender_pack_ar" :key="items.id" :value="items.id">{{items.lot_no}} - {{items.item_details}}</option> 
              </TomSelect>
            </div>

            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Select Tender Item</label
              >
              <TomSelect
                v-model="issueNoteForm.selected_packets_for_issue"
                :options="{
                  placeholder: 'Select Tender Items',
                  plugins: {
                    dropdown_header: {
                      title: 'Items'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Item For Issue</option>
              <option v-for="items in screenData.issue_tender_pack_ar" :key="items.id" :value="items.id">{{items.lot_no}} {{items.item_details}}</option> 
              </TomSelect>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitForm">
              Save Details
            </button>
          
          </div>
        </div>
      </div>
    </div>

    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">
                    
                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
              <!-- END: Failed Notification Content -->
            </div>
  
</template>

<script>
import { defineComponent, ref, reactive, onMounted,toRefs,inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
   
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: '',
      type: '',
      value: ''
    })

    const screenData = reactive({
      tender_ar: [],
      buyer_ar: [],
      tender_pack_ar: [],
      issue_tender_pack_ar: []
    })
    const issueNoteForm = reactive({
      selected_tender: '',
      selected_buyer: '',
      selected_packets: [],
      selected_packets_for_issue: [],
      id:''
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_issue_notes',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Note No',
            
            field: 'issue_no',
            print: false,
            download: true
          },
          {
            title: 'Party Name',
            minWidth: 200,
            field: 'buyer_name',
            print: false,
            download: true
          },
          {
            title: 'Total Items',
            field: 'total_items',
            print: false,
            download: true
          },
         
          {
            title: 'Tender Name',
            responsive: 0,
            field: 'tender_name',
            print: false,
            download: true,
           
          },
           {
            title: 'Unissued',
            field: 'unissue',
            print: false,
            download: true
          },
          {
            title: 'Returned',
            field: 'issued',
            print: false,
            download: true
          },
          {
            title: 'Cr Issued',
            field: 'crissued',
            print: false,
            download: true
          },
          
          {
            title: 'Status',
            field: 'status',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().status == 1){
                return `<div class="flex text-theme-9 font-bold">
                <i data-feather="activity" class="w-4 h-4 mr-2"></i> Active
              </div>`
              }else{
              return `<div class="flex text-theme-6 font-bold">
                <i data-feather="activity" class="w-4 h-4 mr-2"></i> Closed
              </div>`
              }
              
            }
          },
          {
            title: '',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              if(cell.getData().status == 1){
              const a = cash(`<div class="flex lg:justify-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Close
                </a>
              </div>`)

              cash(a).on('click', function(data_id) {
                // On click actions
                //router.push("/app/tender/update/" + window.btoa(cell.getData().id));

                closeNote(cell.getData().id);
              })

              return a[0] 
              }
            }
          },

          // For print format
          
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    const selectAll = () =>{
      let selected = []
      screenData.tender_pack_ar.forEach(function(value){
        selected.push(''+value.id)
      });
      issueNoteForm.selected_packets = selected;
    }

    const closeNote = (issue_id) => {

    swal({
        title: "Are you sure?",
        text: "You are about to close Issue Note!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const data = {
            issue_id: issue_id,
          };

          let promise = axios({
            url: "api/close_issue_notes",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                swal("Success!", "Issue Note Closed.", "success");
                //screenData.return_progress = false;
                router.go();
              }else if(data.status == "error"){
                swal("Error!", data.msg , "error");
                return;
              }
            })
            .catch(_error => {
              
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {

        }
      });
    }

    const getTenders = () => {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tenders_active_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          screenData.tender_ar = data;

        })
        .catch(_error => {
          
        });
    }

    const getTenderItems = () => {
      let _this = this;
      console.log("Here");
      const params = {
        tender_id:issueNoteForm.selected_tender
      };
      let promise = axios({
        url: "api/get_tenders_item_dp_new",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.tender_pack_ar = data;
          screenData.issue_tender_pack_ar = data;
        })
        .catch(_error => {
          
        });
    }
    const getTenderBuyers = () => {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tender_buyers_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.buyer_ar = data;
        })
        .catch(_error => {
          
        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    const changeTender = () => {
      getTenderItems()
    }

    const submitForm = () => {
      let validation = true;
      if(issueNoteForm.selected_tender == ''){
        validation = false;

      }
      if(issueNoteForm.selected_buyer == ''){
        validation = false;
        
      }

      var total_selected = issueNoteForm.selected_packets.length;
      if(total_selected > 0){

        var j = issueNoteForm.selected_packets_for_issue.length;
        if(total_selected > 0){
          while(j--){
            if(issueNoteForm.selected_packets.includes(issueNoteForm.selected_packets_for_issue[j])){

            }else{
              validation = false;
            }
          ;
        }
      }
      }else{
          validation = false;
      }

      console.log(issueNoteForm.selected_packets);
      console.log(issueNoteForm.selected_packets_for_issue);

      if(validation == false){
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();
        return;
      }
      const params = {
      "tender_id": issueNoteForm.selected_tender,
      "buyer_id": issueNoteForm.selected_buyer,
      "selected_items":  issueNoteForm.selected_packets,
      "selected_items_for_issue":  issueNoteForm.selected_packets_for_issue,
      "id" : issueNoteForm.id
      };
      let promise_url = '';
      if(issueNoteForm.id != '' && issueNoteForm.id != null){
        promise_url = "api/edit_issue_note";
      }else{
        promise_url = "api/create_issue_note"
      }
      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          let status = data.status;
          let msg = data.msg;
          
          if(status == 'success'){
            swal("Success!", "Issue Note Created.", "success");
          }else if(status == 'warning'){
            swal("Warning!", msg, "warning");
          }else{
            swal("Error!", msg, "error");
          }

          //router.go();
          cash('#new-issue-note').modal('hide')
          tabulator.value.replaceData()

          issueNoteForm.selected_tender = '';
          issueNoteForm.selected_buyer = '';
          issueNoteForm.selected_packets = [];
          issueNoteForm.selected_packets_for_issue = [];
          issueNoteForm.id = '';
        })
        .catch(_error => {
          
        });
    }


    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'tender_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
      getTenders()
      getTenderBuyers()
    })

    return {
      tableRef,
      filter,
      onFilter,
      selectAll,
      changeTender,
      issueNoteForm,
      screenData,
      onResetFilter,
      submitForm
    }
  }
})
</script>
