<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Process Lose Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Process</label
              >
              <TomSelect
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                :disabled="screenData.issue_pending"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label mt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"
              :disabled="screenData.issue_pending"
              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              :disabled="screenData.series_id == '0' && screenData.kapan_id == '0'"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.generate == 'gen'">
          <div class="flex mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="w-full ml-2 btn btn-primary"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden" v-if="pagedata.generate == 'gen'">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapan Code</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Total Lose (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Total Assortment (Ct)</th>
                    </tr>
                  </thead>
                  <!-- <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody> -->
                  <tbody v-for="(kapan,index) in pagedata.kapan_details" :key="index">
                    <tr class="border-b" v-for="(single_item,ind) in kapan.process_details" :key="ind">
                      <td v-if="ind == 0" class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="ind == 0 ? kapan.total_process : 1">{{ ind == 0 ? kapan.kapan_code : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.process_code }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packets ? single_item.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.received_weight ? $h.myFloat(single_item.received_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.lose ? $h.myFloat(single_item.lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.assortment ? $h.myFloat(single_item.assortment) : '0' }}</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="kapan.total_process > 0">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ kapan.total_packets ? kapan.total_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ kapan.total_issue_weight ? $h.myFloat(kapan.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ kapan.total_received_weight ? $h.myFloat(kapan.total_received_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ kapan.total_lose ? $h.myFloat(kapan.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ kapan.total_assortment ? $h.myFloat(kapan.total_assortment) : '0' }}</td>
                    </tr>

                  </tbody>
                  <tbody>
                    <tr class="border-b" v-for="(single_item,ind) in pagedata.series_details" :key="ind">
                      <td v-if="ind == 0" class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="ind == 0 ? pagedata.series_details.length : 1">Series ({{ single_item.series_code }})</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.process_code }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packets ? single_item.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.received_weight ? $h.myFloat(single_item.received_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.lose ? $h.myFloat(single_item.lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.assortment ? $h.myFloat(single_item.assortment) : '0' }}</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="pagedata.series_details.length > 0">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.series_total.total_packets ? pagedata.series_total.total_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.series_total.total_issue_weight ? $h.myFloat(pagedata.series_total.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.series_total.total_received_weight ? $h.myFloat(pagedata.series_total.total_received_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.series_total.total_lose ? $h.myFloat(pagedata.series_total.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.series_total.total_assortment ? $h.myFloat(pagedata.series_total.total_assortment) : '0' }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      processes: [],
      kapan_details: [],
      series_details: [],
      series_total: [],
      total: [],
      loading: false,
      generate: 'no'
    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      process_id:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true

    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      process_id:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })
    const generateReport = () => {

      printData.series_id = screenData.series_id,
      printData.kapan_id = screenData.kapan_id,
      printData.process_id = screenData.process_id,
      printData.issue_date =  screenData.issue_date,
      printData.receive_date = screenData.receive_date,

      pagedata.loading  = true

      let params = {
        series_id : screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
        issue_date : window.btoa(screenData.issue_date),
        receive_date : window.btoa(screenData.receive_date),
        issue_pending : screenData.issue_pending
      }
      let promise = axios({
        url: "api/lite_items_by_process_lose",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.kapan_details = data.kapan_items
          pagedata.series_details = data.series_details
          pagedata.series_total = data.series_total
          pagedata.total = data.total
          pagedata.loading  = false
          pagedata.generate = 'gen'
        })
        .catch(_error => {
          pagedata.loading  = false

        });
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_rough_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getProcesses = () => {
      // let params = {
      //   process_id : screenData.process_id,
      // }
      let promise = axios({
        url: "api/dp_process",
        method: "post",
        baseURL: BASE_URL,
        // data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.processes = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
      }
      let url = 'api/dp_rough_kapan'
      if(screenData.series_id == '0') {

      } else {
        url = 'api/dp_rough_kapan_by_series'
      }
      let promise = axios({
        url: url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = '0'

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.process_id = '0'
      screenData.issue_pending = true

      pagedata.kapan_details = []
      pagedata.series_details = []
      pagedata.series_total = []
      pagedata.total = []

      printData.series_id = '0'
      printData.kapan_id = '0'
      printData.process_id = '0'
      printData.issue_pending = true

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function seriesChange() {
      getKapans()
      screenData.kapan_id = ''
      screenData.packets = []
    }
    function printReportDetails(){
      let report_type = 'lite_items_by_process_lose'

      let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.items,'total': pagedata.total }
      }
      let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
      });
      return promise
          .then(result => result.data)
          .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/process-lose-report?id=' + window.btoa(report_id)
          window.open(url, '_blank');

          })
          .catch(_error => {

          });
      // add_report_details
      }
    onMounted(() => {
      checkPermission()
      getSeries()
      getProcesses()
      getKapans()
      reInitOnResizeWindow()

    })

    return {
      tableRef,
      pagedata,
      printData,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      generateReport,
      printReportDetails
    }
  }
})
</script>
