<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Selling Report (Non - Certy)</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3">
                <label for="update-profile-form-7" class="form-label">Select Stone</label>
                <TomSelect v-model="screenData.stone_type" :options="{
                    placeholder: 'Select Stone',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stone'
                      }
                    }
                  }" class="w-full" @change="getStoneParams">
                    <option value="">Select Stone</option>
                    <option value="1">Natural</option>
                    <option value="2">CVD</option>
                    <option value="3">HPHT</option>
                    <option value="4">D_CVD</option>
                    <option value="5">D_HPHT</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3">
                <label for="update-profile-form-7" class="form-label">Select Shape</label>
                <TomSelect v-model="screenData.shape_type" :options="{
                    placeholder: 'Select Shape',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Shape'
                      }
                    }
                  }" class="w-full">
                    <option value="0">Select Shape</option>
                    <option v-for="item in screenData.shape_arr" :key="item.shape_name" :value="item.id">{{ item.shape_name }}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3">
                <label for="update-profile-form-7" class="form-label">Select Size</label>
                <TomSelect v-model="screenData.size_type" :options="{
                    placeholder: 'Select Size',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Size'
                      }
                    }
                  }" class="w-full" multiple>
                    <option value="">Select Size</option>
                    <option v-for="item in screenData.size_arr" :key="item.segment_code" :value="item.segment_code">{{ item.segment_name }}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3">
                <label for="update-profile-form-7" class="form-label">Select Charni</label>
                <TomSelect v-model="screenData.charni_id" :options="{
                    placeholder: 'Select Charni',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Charni'
                      }
                    }
                  }" class="w-full" multiple>
                    <option v-for="item in screenData.charni_arr" :key="item.id" :value="item.id">{{ item.charni_name }}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                  <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-1"
                  >Select Start Date</label
                ><input
                  class="show-code form-check-switch mr-0 ml-3"
                  type="checkbox" v-model="screenData.issue_pending"
                /></div>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="screenData.issue_pending" v-model="screenData.s_date" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label pt-1">Select End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="screenData.issue_pending" v-model="screenData.e_date" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">

                <div class="py-1">
                    <label for="update-profile-form-7" class="form-label">Select Party</label>
                    <div class="" id="seller_id">

                        <TomSelect v-model="screenData.seller_id" :options="{
placeholder: 'Select Party',
}" class="w-full">
                            <option value="0">Select Party</option>
                            <option v-for="(item,index) in screenData.selling_parties" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                        </TomSelect>
                    </div>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">

                <div class="py-1">
                    <label for="update-profile-form-7" class="form-label">Select Broker</label>
                    <div class="" id="broker_id">

                        <TomSelect v-model="screenData.broker_id" :options="{
placeholder: 'Select Broker ',
}" class="w-full">
                            <option value="0">Select Broker</option>
                            <option v-for="(item,index) in screenData.selling_broker" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                        </TomSelect>
                    </div>
                </div>
            </div>
            <div class="col-span-6 md:col-span-2" v-if="userDetails.user_type != 26">
                <label for="update-profile-form-7" class="form-label">Select Branch</label>
                <TomSelect v-model="screenData.branch_id" :options="{
                    placeholder: 'Select Branch',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Branch'
                      }
                    }
                  }" class="w-full">
                    <option value="0">All</option>
                    <option value="1">Home Branch</option>
                    <option v-for="item in screenData.branch_arr" :key="item.id" :value="item.id">{{ item.branch_name }}</option>
                </TomSelect>
            </div>
            <div class="col-span-6 md:col-span-4">
                <div class="mt-5 sm:mt-7">
                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary" @click="initTabulator">
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                        Reset
                    </button>
                    <button v-if="screenData.report_items.length > 0"
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary ml-2"
                      tabindex="5"
                      @click="printReportDetails"
                    >
                    Print
                    </button>
                </div>
            </div>
            <!-- <div class="col-span-6 md:col-span-3">
                <div class="dropdown w-1/2 sm:w-auto">
                    <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export
                        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                    </button>
                    <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                            <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="export_excel('csv')">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                            </a>
                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="export_excel('xlsx')">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                            </a>

                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="screenData.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Stock Report</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div v-if="screenData.generate == 'gen'">
              <div class="scrollbar-hidden" v-if="screenData.report_items.length > 0">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2 text-center">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">NO.</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Transaction</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stone</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Shape</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Charni</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Clarity</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Branch</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Sell Date</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Due Days</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Due Date</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PCS</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Shape</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Weight (Ct)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stock Rate</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">MAX Rate</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Sell Rate</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Less (%)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Amount (Rs)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Dalali (%)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Dalali (Rs)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r-2">Party</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r-2">Broker</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r-2">Payment Type</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 text-xs lg:text-md text-center border-r" colspan="2">Total</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ screenData.total.total_pcs ? screenData.total.total_pcs : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ screenData.total.total_wt ? $h.myFloat(screenData.total.total_wt) : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ screenData.total.total_amount ? $h.myFloat(screenData.total.total_amount) : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ screenData.total.brokerage_amount ? $h.myFloat(screenData.total.brokerage_amount) : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                    </tr>
                  </thead>
                  <tbody v-for="(item,index) in screenData.report_items" :key="index">
                    <tr>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ index + 1 }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.transaction_code }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.stone_type }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.shape_name }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.charni_name }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap"> Size {{ item.clarity }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.branch_name }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.sell_date }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.payment_terms }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.due_date }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.total_pcs }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.shape }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.total_wt }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.stock_rate ? item.stock_rate : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.max_rate ? item.max_rate : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.sell_rate ? item.sell_rate : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.sell_less ? item.sell_less : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.total_amount ? item.total_amount : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.broker_per ? item.broker_per : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.brokerage_amount ? item.brokerage_amount : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.seller_first_name ? item.seller_first_name + ' ' + item.seller_last_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.broker_first_name ? item.broker_first_name + ' ' + item.broker_last_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap">{{ item.payment_type ? item.payment_type : "-" }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    computed
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import store from '@/store'
import moment from 'moment'
import {
    BASE_URL_SELLING,BUILD_ENV,Auth_Token, BASE_URL
} from '@/constants/config.js'
import FileDownload from "js-file-download";

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const userDetails = computed(() => store.state.user.currentUser)
        const AUTH_TOKEN = localStorage.getItem("token")
        const screenData = reactive({
            report_items: [],
            total: [],
            generate: 'no',
            loading: false,
            stones: [],
            branch_arr: [],
            size_arr: [],
            shape_arr: [],
            charni_arr: [],

            size_type: [],
            shape_type: '0',
            stone_type: '1',
            charni_id: [],
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date:moment().format('YYYY-MM-DD'),
            seller_id: '0',
            broker_id: '0',
            branch_id: '0',
            issue_pending: false
        })

        const excelData = reactive({
          size_type: [],
          shape_type: '0',
          stone_type: '1',
          charni_id: [],
          s_date: moment().startOf('month').format('YYYY-MM-DD'),
          e_date:moment().format('YYYY-MM-DD'),
          seller_id: '0',
          broker_id: '0',
          branch_id: '0',
          issue_pending: false
        })

        // Get Seller Party
        const get_seller_party = async () => {
            const params = {};
            const url = "/api/dp_account_selling_party"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.selling_parties = data.items;
                })
                .catch(_error => {
                    screenData.selling_parties = [];
                });
        }

        // Get Seller Party
        const get_selling_broker = async () => {
            const params = {};
            const url = "/api/dp_account_selling_broker"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.selling_broker = data.items;
                })
                .catch(_error => {
                    screenData.selling_broker = [];
                });
        }

        const initTabulator = () => {

            excelData.size_type = screenData.size_type
            excelData.shape_type = screenData.shape_type
            excelData.stone_type = screenData.stone_type
            excelData.charni_id = screenData.charni_id
            excelData.seller_id = screenData.seller_id
            excelData.broker_id = screenData.broker_id
            excelData.branch_id = screenData.branch_id
            excelData.s_date = screenData.s_date
            excelData.e_date = screenData.e_date
            excelData.issue_pending = screenData.issue_pending
            const params = {
              size_type: screenData.size_type,
              shape_type: screenData.shape_type != '0' ? window.btoa(screenData.shape_type) : '',
              stone_type: screenData.stone_type != '0' ? window.btoa(screenData.stone_type) : '',
              charni_id: screenData.charni_id,
              seller_id: screenData.seller_id != '0' ? window.btoa(screenData.seller_id) : '',
              broker_id: screenData.broker_id != '0' ? window.btoa(screenData.broker_id) : '',
              branch_id: screenData.branch_id != '0' ? window.btoa(screenData.branch_id) : '',
              s_date: window.btoa(screenData.s_date),
              e_date: window.btoa(screenData.e_date),
              issue_pending: screenData.issue_pending,
            };

            screenData.loading = true

            let promise = axios({
                url: "/api/get_selling_stock_report",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.report_items = data.items;
                    screenData.total = data.total;
                    screenData.generate = 'gen';
                    screenData.loading = false

                  })
                  .catch(_error => {

                    screenData.report_items = [];
                    screenData.total = [];
                    screenData.generate = 'no';
                    screenData.loading = false
                });
        }


        const getBranchDetails = () => {
            const params = {};
            let promise = axios({
                url: "/api/dp_account_branch_office",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.branch_arr = data.items

                })
                .catch(_error => {
                    screenData.branch_arr = []

                });
        }

        const getCharniDetails = () => {
            const params = {
                stone_type: screenData.stone_type
            };
            let promise = axios({
                url: "/api/main_charni_list",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.charni_arr = data.items

                })
                .catch(_error => {
                    screenData.charni_arr = []

                });
        }

        const export_excel = (type) => {

            let stone_name = ""
            if (excelData.stone_type == 1) {
                stone_name = "NATURAL"
            } else if (excelData.stone_type == 2) {
                stone_name = "CVD"

            } else if (excelData.stone_type == 3) {
                stone_name = "HPHT"

            } else if (excel_filter.stone == 4) {
                stone_name = "D_CVD"

            } else if (excel_filter.stone == 5) {
                stone_name = "D_HPHT"

            }
            // let filename = 'DKStock.xlsx';
            let filename = 'DKStock' + stone_name + moment().format('Mth DD, YYYY') + '.' + type;
            let params = {
                report_items: screenData.report_items,
                type: type,
                stone_type: excelData.stone_type
            }
            let promise = axios({
                url: "/api/dawnload_selling_stock",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                })
                .catch((_error) => {
                    return [];
                });
        }

        const getSizeDetails = () => {
            const params = {
                stone_type: screenData.stone_type
            };
            let promise = axios({
                url: "/api/dp_size_items",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.size_arr = data.items

                })
                .catch(_error => {
                    screenData.size_arr = []

                });
        }

        const getShapeDetails = () => {
            const params = {
            };
            let promise = axios({
                url: "/api/dp_shape",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.shape_arr = data.items

                })
                .catch(_error => {
                    screenData.shape_arr = []

                });
        }

        const onResetFilter = () => {
          screenData.size_type = []
          screenData.shape_type = '0'
          screenData.stone_type = '1'
          screenData.charni_id = []
          screenData.s_date = moment().startOf('month').format('YYYY-MM-DD')
          screenData.e_date =moment().format('YYYY-MM-DD')
          screenData.seller_id = '0'
          screenData.broker_id = '0'
          screenData.branch_id = '0'
          screenData.issue_pending = false
        }

        function printReportDetails(){
        let report_type = 'non-certy-selling-report'
        let params = {
          report_type: report_type,
          filters: excelData,
          items: {'items': screenData.report_items,'total':screenData.total }
        }
        let promise = axios({
          url: "/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + AUTH_TOKEN },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });

      // add_report_details
    }

        const getStoneParams = () => {
            getCharniDetails()
            getSizeDetails()
        }

        onMounted(() => {
            getStoneParams()
            getShapeDetails()
            getBranchDetails()
            get_seller_party()
            get_selling_broker()
        })

        return {
            tableRef,
            excelData,
            screenData,
            initTabulator,
            userDetails,
            getStoneParams,
            onResetFilter,
            export_excel,
            printReportDetails
        }

    }
})
</script>
