<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Series Manual Lot Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Series</label
                  >
                 <TomSelect
                v-model="screenData.selectedSeries"
                @change="getKapan"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="">Select Series</option>
              <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Kapan</label
                  >
                 <TomSelect
                v-model="screenData.selectedKapan"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="">Select Kapan</option>
              <option v-for="kapan in screenData.kapans_ar" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-12 mt-5">

            <div>
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-20"
                @click="generateReport"
              >
              Generate
              </button>

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
              </div>
            </div>

      <div class="overflow-x-auto scrollbar-hidden" v-if="screenData.genreport == 'gen'">
        <div class="grid grid-cols-12 gap-6 mt-5 p-5">
            <div class="col-span-12 sm:col-span-6 md:col-span-4 intro-y" v-for="(typet,index) in screenData.type_total" :key="index">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-3xl font-medium leading-8">{{ typet.manual_type }}</div>
                  </div>
                  <div class="text-xl font-medium leading-8 mt-4">{{typet.weight ? $h.myFloat(typet.weight) + ' Ct' : '0 Ct'}} {{ typet.weight ? ' ( ' +  parseFloat(typet.weight * 100 / typet.series_weight).toFixed(2) + ' % ) ' : '' }} / {{typet.pieces}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Weight / Pcs </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-3xl font-medium leading-8">SM</div>
                  </div>
                  <div class="text-2xl font-medium leading-8 mt-4">{{screenData.total_sm_weight}} / {{screenData.sm_total_pc}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Weight / Pcs </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-3xl font-medium leading-8">4P</div>
                  </div>
                  <div class="text-2xl font-medium leading-8 mt-4">{{screenData.total_4p_weight}} / {{screenData.total_4p_pc}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Weight / Pcs </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-3xl font-medium leading-8">Round</div>
                  </div>
                  <div class="text-2xl font-medium leading-8 mt-4">{{screenData.total_round_weight}} / {{screenData.round_total_pc}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Weight / Pcs </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-3xl font-medium leading-8">Prince</div>
                  </div>
                  <div class="text-2xl font-medium leading-8 mt-4">{{screenData.total_prince_weight}} / {{screenData.prince_total_pc}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Weight / Pcs </div>
                </div>
              </div>
            </div> -->
          </div>
        <div id="responsive-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border-t-2 bg-gray-200 border-b-2 border-l-2 border-r-2 dark:border-dark-5 whitespace-nowrap"
                      >

                      </th>
                      <th
                      colspan="2"
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="header in screenData.report_header" :key="header"
                      >
                        {{header}}
                      </th>

                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th
                        class="border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                      >
                        Purity
                      </th>
                      <th
                        class="border-b-2  border-l-2 border-r-2  text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="header in screenData.report_sub_header" :key="header"
                      >
                        {{header}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    v-for="record in screenData.report_fields" :key="record">
                      <td class="border-b border-l border-r text-center whitespace-nowrap" v-for="value in record" :key="value">{{value}}</td>
                    </tr>
                     <tr>
                       <td class="border-b border-l border-r bg-gray-200 font-bold text-center whitespace-nowrap">Total</td>

                      <td class="border-b border-l border-r bg-gray-200 font-bold text-center whitespace-nowrap" v-for="value in screenData.footer_total" :key="value">{{value}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      genreport : "no",
      kapans_ar:[],
      type_total:[],
      series_ar:[],
      report_fields:[],
      report_header:[],
      report_sub_header:[],
      footer_total:[],
      selectedKapan:'',
      selectedSeries:'',
      selectedKapan:'',
    })

    //
    const generateReport = () => {
      if(screenData.selectedSeries == ''){
        return;
      }
      const params = {
        series_id: window.btoa(screenData.selectedSeries),
        kapan_id: window.btoa(screenData.selectedKapan)
      };
      let promise = axios({
        url: "api/list_kapan_maual_pcs_report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          screenData.report_fields = data.report_details;
          screenData.report_header = data.report_headers;
          screenData.report_sub_header = data.report_sub_headers;
          screenData.footer_total = data.footer_total;
          screenData.type_total = data.type_total;
          screenData.genreport = 'gen';
        })
        .catch(_error => {

        });
    }

    // On reset filter
    const onResetFilter = () => {
      screenData.selectedSeries = ''
      screenData.selectedKapan = ''
      screenData.kapans_ar = []
    }

    const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;
        })
        .catch(_error => {

        });
    }
    const getKapan = () => {
      let _this = this;
      const params = {
        series_id: window.btoa(screenData.selectedSeries),
        type: 1
      };
      let promise = axios({
        url: "api/dp_rough_kapan_by_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans_ar = data.items;
          screenData.selectedKapan = ''
        })
        .catch(_error => {

        });
    }

    onMounted(() => {
      //checkPermission()
      //reInitOnResizeWindow()
      // getKapan()
      getSeries()
      //generateReport()
    })

    return {
      filter,
      screenData,userDetails,
      onResetFilter,
      generateReport,
      getKapan
    }
  }
})
</script>
