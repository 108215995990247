<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Size Wise - Shape Group Summary</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

    <span tabindex="1"></span>
      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div class="">
            <label for="update-profile-form-8" class="form-label py-1"
            >Select Series/Kapan</label>
            <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.issue_block" @change="changeBlocktype" >
              <option value="1">Kapan</option>
              <option value="2" selected>Series</option>
            </select>
          </div>

        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div v-if="screenData.issue_block == 2">
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Series</label
            >
            <TomSelect
              v-model="screenData.selectedSeries"
              :options="{
                placeholder: 'Select Series',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              multiple
              @change="getParty"
              class="w-full"
            >
            <option value="">Select Series</option>
            <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
            </TomSelect>
              </div>

              <div v-if="screenData.issue_block == 1">
                <label for="update-profile-form-6" class="form-label py-1"
                  >Select Kapan</label
                >
                <TomSelect

              v-model="screenData.selectedKapan"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              multiple
              @change="getParty"
              class="w-full"

            >
            <option value="">Select Kapan</option>
            <option v-for="kapans in screenData.kapans_ar" :key="kapans.id" :value="kapans.id">{{kapans.kapan_code}}</option>
            </TomSelect>
              </div>
        </div>
        <div class="col-span-12 sm:col-span-3 md:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Kapan Type</label
            >
            <TomSelect
              v-model="screenData.kapan_type"
              :options="{
                placeholder: 'Select Kapan Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan Type'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">All</option>
            <option value="2">Galaxy</option>
            <option value="1">Manual</option>
            <!-- <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option> -->
            </TomSelect>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
          <div>
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
              >Start Date</label
            ><input
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox" v-model="screenData.issue_pending" @change="getParty"
            /></div>
            <input
              type= "date"
              id="update-profile-form-13"
              :disabled="screenData.issue_pending"
              class="form-control"
              placeholder=""
              v-model="screenData.issue_date"
            />
          </div>

        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
            <div>
              <label for="update-profile-form-8" class="form-label py-1"
                >Select Shape</label>
                <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.selectedShape">
                <option value="All" selected>All</option>
                <option value="ROUND" selected>Round</option>
                <option value="PRINCE" selected>Prince</option>
                <option value="FANCY" selected>Fancy</option>

              </select>
            </div>
          </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Lab</label
            >
            <TomSelect
              v-model="screenData.selectedLab"
              :options="{
                placeholder: 'Select Lab',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">All</option>
            <option value="N">Non-Certy</option>
            <option value="I">IGI</option>
            <option value="G">GIA</option>
            </TomSelect>
              </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-3">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 p-0"
                type="checkbox" v-model="screenData.charni_type"
              /></div>
              <TomSelect
                v-model="screenData.stone_id"
                @change="stoneChange"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <!-- <option value="0">Select Stone</option> -->
              <option value="1">Natural</option>
              <option value="2">CVD</option>
              <option value="4">D_CVD</option>
              <!-- <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option> -->
              </TomSelect>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Party</label
            >
            <TomSelect
              v-model="screenData.party_id"
              :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Party</option>
            <option v-for="party in screenData.party_ar" :key="party.id" :value="party.id">{{party.company_name ? party.company_name : (party.first_name + ' ' + (party.last_name ? party.last_name : ''))}}</option>
            </TomSelect>
              </div>

        </div>
        <div class="col-span-1">
          <div class="mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              tabindex="5" :disabled="(screenData.issue_pending == true && ((screenData.issue_block == 1 && screenData.selectedKapan.length == 0) || (screenData.issue_block == 2 && screenData.selectedSeries.length == 0)))"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-1">
          <div class="mt-10">
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              tabindex="6"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-1" v-if="screenData.genreport == 'gen' && screenData.report_header.length > 0">
          <div class="mt-10">
            <button v-if="screenData.genreport == 'gen' && screenData.report_header.length > 0"
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20 ml-4"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>
      </div>

      <div id="responsive-table" class="p-5" v-if="screenData.load_price">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Price</div>
          </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="overflow-x-auto scrollbar-hidden" v-if="screenData.genreport == 'gen'">

          <div id="generate-pdf" class="p-5">
              <div class="preview">
                <div class="overflow-x-auto">
                  <table class="w-full">
                    <thead>
                      <tr>

                        <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap">
                          SIZE
                        </th>
                        <th
                          class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                          v-for="header in screenData.report_header" :key="header"
                          colspan="3"
                        >
                          {{header}}
                        </th>
                        <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap" colspan="3">
                          Total
                        </th>

                      </tr>

                      <tr>
                        <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"></th>
                        <th
                          class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                          v-for="header in screenData.report_sub_headers" :key="header"
                        >
                          {{header}}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                      v-for="record in screenData.report_details_polish" :key="record">
                      <!-- <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" :rowspan="screenData.planning_report_fields.length" v-if="index<1">Planning</td> -->
                        <td :class="{'bg-gray-100  dark:border-dark-5 whitespace-nowrap' : record[0] == 'Size 1' || record[0] == 'Size 2' || record[0] == 'Size 3'}" class="border-b border-l border-r text-center whitespace-nowrap" v-for="(value,index) in record" :key="value">
                          <span class="font-bold" v-if="index==0">{{value}}</span>
                          <span v-else>{{value > 0 ? $h.myFloat(value) : '0'}}</span>
                          </td>
                      </tr>
                      <tr class="bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                      >
                      <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record,index) in screenData.report_details_polish_first" :key="index">
                        <span class="font-bold" v-if="index==0">{{record}}</span>
                          <span v-else>{{record > 0 ? $h.myFloat(record) : '0'}}</span>
                        </td>
                      </tr>
                      <tr class="bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                      >
                      <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record,index) in screenData.report_details_polish_second" :key="index">
                        <span class="font-bold" v-if="index==0">{{record}}</span>
                          <span v-else>{{record > 0 ? $h.myFloat(record) : '0'}}</span>
                        </td>
                      </tr>
                      <tr class="bg-gray-300  dark:border-dark-5 whitespace-nowrap"
                      >
                      <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record,index) in screenData.report_details_polish_total" :key="index">
                        <span class="font-bold" v-if="index==0">{{record}}</span>
                          <span v-else>{{record > 0 ? $h.myFloat(record) : '0'}}</span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      genreport : "no",
      issue_block:2,
      kapans_ar:[],
      series_ar:[],
      party_ar:[],
      report_fields:[],
      report_header:[],
      report_sub_headers:[],
      report_details_polish:[],
      report_details_polish_first:[],
      report_details_polish_second:[],
      report_details_polish_total:[],
      selectedKapan:[],
      selectedLab:'0',
      kapan_type:'0',
      party_id: '0',
      stone_id:'1',
      charni_type:false,
      selectedShape:'All',
      selectedSeries:[],
      load_price: false,
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })

    const printData = reactive({
      kapan_id :[],
      kapan_type :'0',
      lab_cert :'0',
      party_id : '0',
      stone_id:'1',
      charni_type:false,
      issue_block :1,
      shape_id :'All',
      series_id :[],
      issue_date :moment().startOf('month').format('YYYY-MM-DD'),
      receive_date :moment().format('YYYY-MM-DD'),
      issue_pending : true
    })

    const getKapans = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans_ar = data.items;

        })
        .catch(_error => {

        });
    }

    const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;

        })
        .catch(_error => {

        });
    }
    const getParty = () => {

      if(!(screenData.issue_pending == true && ((screenData.issue_block == 1 && screenData.selectedKapan.length == 0) || (screenData.issue_block == 2 && screenData.selectedSeries.length == 0)))) {

        let _this = this;
        const params = {
          series_id: screenData.selectedSeries,
          kapan_id: screenData.selectedKapan,
          lab_cert: screenData.selectedLab == '0' ? '' : window.btoa(screenData.selectedLab),
          kapan_type: screenData.kapan_type == '0' ? '' : window.btoa(screenData.kapan_type),
          party_id: screenData.party_id == '0' ? '' : window.btoa(screenData.party_id),
          stone_id: screenData.stone_id == '0' ? '' : window.btoa(screenData.stone_id),
          shape_id: screenData.selectedShape == 'All' ? '' : window.btoa(screenData.selectedShape),
          issue_date : screenData.issue_date,
          charni_type : screenData.charni_type,
          receive_date : screenData.receive_date,
          issue_pending : screenData.issue_pending,
          issue_block : screenData.issue_block,
        };
        let promise = axios({
          url: "api/get_party_details_by_final_polish",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            screenData.party_id = '0'
            screenData.party_ar = data.items;

          })
          .catch(_error => {
            screenData.party_id = '0'
            screenData.party_ar = [];

          });
      } else {
        screenData.party_id = '0'
        screenData.party_ar = [];
      }
    }

    //
    const generateReport = () => {
      // if(screenData.selectedSeries == '' && screenData.selectedKapan == ''){
      //   return;
      // }

      printData.kapan_id = screenData.selectedKapan
      printData.lab_cert = screenData.selectedLab
      printData.kapan_type = screenData.kapan_type
      printData.stone_id = screenData.stone_id
      printData.charni_type = screenData.charni_type
      printData.party_id = screenData.party_id
      printData.issue_block = screenData.issue_block
      printData.shape_id = screenData.selectedShape
      printData.series_id = screenData.selectedSeries
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending

      if(screenData.issue_block == 1){
        screenData.selectedSeries = '';
      }else{
        screenData.selectedKapan = '';
      }
      screenData.load_price = true;
      screenData.genreport = 'no';
      screenData.report_details_polish = [];
      const params = {
        series_id: screenData.selectedSeries,
        kapan_id: screenData.selectedKapan,
        lab_cert: screenData.selectedLab == '0' ? '' : window.btoa(screenData.selectedLab),
        kapan_type: screenData.kapan_type == '0' ? '' : window.btoa(screenData.kapan_type),
        stone_id: screenData.stone_id == '0' ? '' : window.btoa(screenData.stone_id),
        party_id: screenData.party_id == '0' ? '' : window.btoa(screenData.party_id),
        shape_id: screenData.selectedShape == 'All' ? '' : window.btoa(screenData.selectedShape),
        issue_date : screenData.issue_date,
        charni_type : screenData.charni_type,
        receive_date : screenData.receive_date,
        issue_pending : screenData.issue_pending,
        issue_block : screenData.issue_block,
      };
      let promise = axios({
        url: "api/list_shape_and_size_wise_polish_summary",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          screenData.report_fields = data.report_details;
          screenData.report_header = data.report_headers;
          screenData.report_sub_headers = data.report_sub_headers;
          screenData.report_details_polish = data.report_details_polish;
          screenData.report_details_polish_first = data.report_details_polish_first;
          screenData.report_details_polish_second = data.report_details_polish_second;
          screenData.report_details_polish_total = data.report_details_polish_total;
          screenData.genreport = 'gen';
          screenData.load_price = false;
        })
        .catch(_error => {
          screenData.genreport = 'no';
          screenData.load_price = false;

        });
    }

    function printReportDetails(){
      let report_type = 'shape-group-wise-polish-summary'

      let params = {
          report_type: report_type,
          filters: printData,
          items: {
            "report_fields" : screenData.report_fields,
            "report_header" : screenData.report_header,
            "report_sub_headers" : screenData.report_sub_headers,
            "report_details_polish" : screenData.report_details_polish,
            "report_details_polish_first" : screenData.report_details_polish_first,
            "report_details_polish_second" : screenData.report_details_polish_second,
            "report_details_polish_total" : screenData.report_details_polish_total
          }
      }
      let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
      });
      return promise
          .then(result => result.data)
          .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/'+ data.report_type + '?id=' + window.btoa(report_id)
          window.open(url, '_blank');

          })
          .catch(_error => {

          });
      // add_report_details
      }

    const focusFirst = () => {
        document.querySelector('[tabindex="2"]').focus();
    }
    const focusLast = () => {
        document.querySelector('[tabindex="6"]').focus();
    }

    const stoneChange = () => {
        if(screenData.stone_id == 1) {
          screenData.charni_type = false
        } else if(screenData.stone_id == 2 || screenData.stone_id == 4) {
          screenData.charni_type = true
        }
      }


    const onResetFilter = () => {
      screenData.selectedSeries = []
      screenData.selectedKapan = []
      screenData.selectedLab = '0'
      screenData.stone_id = '1'
      screenData.charni_type = false
      screenData.party_id = '0'

      screenData.selectedShape = 'All'
      // screenData.genreport = 'no';
        // screenData.load_price = false;
    }



    const changeBlocktype = () => {
      screenData.selectedSeries = []
      screenData.selectedKapan = []
      getParty()
    }


    onMounted(() => {
      //checkPermission()
      //reInitOnResizeWindow()
      getKapans()
      getSeries()
      focusFirst()
      //generateReport()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onResetFilter,
      generateReport,
      changeBlocktype,
      stoneChange,
      focusFirst, focusLast,getParty,printReportDetails

    }
  }
})
</script>
