<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough View - {{lot_no}}</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
            
         <!-- <button
          type="button"
          class="btn btn-primary shadow-md mr-2"
          v-if="cr_status == 2"
          @click="submitReturnRough"
          >Return to Manager</button
        > -->
            </div>
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Lot No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ lot_no }}</label
                  >
                </div>
                
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Stone Type : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ stone_name }}</label
                  >
                </div>
                

                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ Number(rough_cleaver_weight) }}(Ct)</label
                  >
                </div>

              </div>
              <div class="col-span-4 xl:col-span-4">

                 
              </div>
              <div class="col-span-4 xl:col-span-4">

                <div id="graph1" ref="graph1_wrap" style="width:100%; height:240px;"></div>
              </div>
            </div>
            
         
        </div>
        </div>

        

         <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Series Details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0" v-if="cr_status == 2">
              <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#new-order-modal-kapan"
            class="btn btn-primary shadow-md mr-2"
            v-if="this.unissued_rough_weight > 0"
            >Create New Series</a
          >
            </div>
          </div>
         
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
                <div
          id="tabulatorSeries"
          ref="tableRefSeries"
          class="mt-5"
        ></div>
              </div>
             
            </div>
        </div>
        </div>

        <!-- END: Input -->
      </div>
    </div>

    <div id="new-order-modal-kapan" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Series</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            
              
              <div class="col-span-12 xl:col-span-12">
                <div class="">
                  <label for="update-profile-form-9" class="form-label"
                    >Series Weight(Ct)</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    v-model="seriesForm.series_weight"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Makeable(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    v-model="seriesForm.exp_mackable"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Polish(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    v-model="seriesForm.est_polish"
                  />
                </div>
                
              </div>
            
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormSeries"  :disabled="form_status">
              Save Details
               <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!form_status"/>
            </button>
           
          </div>
        </div>
      </div>
    </div>


    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">
                    
                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
import datetime from 'vuejs-datetimepicker';
import feather from 'feather-icons'
import { useVuelidate } from '@vuelidate/core'

import * as echarts from 'echarts/lib/echarts.js'

import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/dataZoomInside";
import "echarts/lib/component/dataZoomSlider";
// const router = useRouter();
// const route = useRoute();

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    },
    cleaver_issue_id:{
      type: String,
      default: ""
    }
  },
  
  data() {
    return {
      tabulator:[],
      tabulatorkapan:[],
      form_status: false,
      programmaticallyModal:true,
      tender_update_id : '',
      tender_name : '',
      rough_vendor : '',
      stone_name : '',
      company_name:'',
      lot_no: 'Auto',
      
      est_makeable: '',
      rough_weight: '',
      rough_cleaver_weight: 0,
      total_series_weight: 0,
      issue_weight: 0,
      unissued_rough_weight: 0,
      total_pcs: '',
      cr_status:'',
      
      brokerage_type_str:["% (Percentage)", "Fixed (Amount)"],
      status: '1',
      issueForm:{
        cleaver_id:'',
        cleaver_name:'',
        issue_weight:''
      },
      seriesForm : {
        form_status:false,
        series_weight:'',
        series_weight_old:'',
        series_total_pcs:'',
        exp_mackable:'',
        est_polish:"",
        series_id:''
      },
      kapanForm:{
        kapan_code:'',
        kapan_name:'',
        kapan_weight:'',
        kapan_total_pcs:'',
        cleaver_id:'',
        cleaver_name:'',
        cleaver_issue_date:'',
        exp_mackable:'',
        kapan_id:''
      },
      assortForm:{
        assort_type:'',
        assort_id:'',
        assort_name:'',
        total_pcs:'',
        weight:'',
        weight_per:'',
        result_weight:'',
        result_per:''
      },
   
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  // validations () {
  //   return {
  //     firstName: { required }, // Matches this.firstName
  //     lastName: { required }, // Matches this.lastName
  //     contact: {
  //       email: { required, email } // Matches this.contact.email
  //     }
  //   }
  // },
    computed: {
      
    },
    mounted(){
      this.getRoughDetails(this.rough_id);
      this.getRoughCleaverDetails(this.cleaver_issue_id);
      this.kapanPrepare();
    },
    methods: {
    async getRoughDetails(rough_id) {
      let _this = this;
      const params = {
        rough_id: rough_id
      };
      let promise = axios({
        url: "api/get_rough_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          let rough_detail = data.item_details;

          this.tender_name = rough_detail.tender_name;
          this.company_name = rough_detail.company_name;
          this.lot_no = rough_detail.lot_no;
          this.price= rough_detail.price;
          this.stone_type= rough_detail.stone_type;
          this.stone_name= rough_detail.stone_name;
          this.brokerage_type = rough_detail.brokerage_type;
          this.brokerage_value = rough_detail.brokerage_value;
          this.billing_period = rough_detail.billing_period;
          this.est_makeable = rough_detail.est_makeable;
          this.rough_weight = Number(rough_detail.rough_weight);
          this.issue_weight = Number(rough_detail.issue_weight);
          //this.unissued_rough_weight =rough_detail.rough_weight - rough_detail.issue_weight;
  
          this.issueForm.issue_weight = this.unissued_rough_weight;
          this.total_pcs = rough_detail.total_pcs;
          this.rough_vendor = rough_detail.vendor_id;
          this.cr_status = rough_detail.cr_status;
          _this.pieWeight();

        })
        .catch(_error => {
          
        });
    },

    getRoughCleaverDetails(cleaver_issue_id) {
      let _this = this;
      
      const params= {
        rough_cleaver_id: cleaver_issue_id
      };
        let promise = axios({
        url: "api/get_rough_details_cleaver",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
       promise
        .then(result => result.data)
        .then(data => {
          
          let rough_detail = data.item_details;

          this.rough_cleaver_weight = rough_detail.issue_weight;
          this.total_series_weight = Number(rough_detail.series_weight);

          this.unissued_rough_weight = this.$h.myFloat(this.rough_cleaver_weight - this.total_series_weight,4);
          console.log(this.unissued_rough_weight);
         
          _this.pieWeight();

        })
        .catch(_error => {
        });
    },

    
    pieWeight(){
    let myChart = echarts.init(this.$refs.graph1_wrap);
    myChart.showLoading({
        text: "Gathering Information",
        color: "#145388"
      });
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          top: '20%',
          left: 'right',
          orient: 'vertical',
        },
        series: [
        {
          name: 'Rough Weight',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '10',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: this.unissued_rough_weight, name: 'Not Sorted',itemStyle:{
              color:"#34baeb"
            } },
            { value: this.total_series_weight, name: 'Series Created' ,itemStyle:{
              color:"#34eb7a"
            }},
             ]
            }
          ]
        };
        myChart.hideLoading();
        myChart.setOption(option, true);
        myChart.resize();
    },
    async submitRoughForm(){
      //let tag_ar = [this.vueSelectForm.multiple];

      this.form_status = true;
      const data = {
        vendor_id: this.rough_vendor,
        stone_type: this.stone_type,
        price: this.price,
        brokerage_type: this.brokerage_type,
        brokerage_value: this.brokerage_value,
        billing_period: this.billing_period,
        est_makeable: this.est_makeable,
        rough_weight: this.rough_weight,
        total_pcs: this.total_pcs,
        id:this.rough_id
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/edit_rough_lot"
      }else{
         url_post = "api/add_rough_lot"
      }
  
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.form_status = false;
          this.$router.push("/purchase/rough-master");
        }, 500)
        })
        .catch((_error) => {
          this.form_status = false;
          return [];
        });

    },
    async submitIssueRough(){
    const data = {
        rough_lot_id: this.rough_id,
        rough_weight: this.issueForm.issue_weight,
        cleaver_id: this.issueForm.cleaver_id,
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/issue_cleaver"
      }else{
         url_post = "api/issue_cleaver"
      }
  
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.go();
        }, 500)
        })
        .catch((_error) => {
          return [];
        });

    },
    submitReturnRough(){
     const data = {
        rough_lot_id: this.rough_id,
        rough_weight: this.issueForm.issue_weight,
        cleaver_id: this.issueForm.cleaver_id,
      };

      this.$swal({
        title: "Are you sure?",
        text: "You are about to return Rough lot to manager!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          
          let promise = axios({
            url: "api/return_lot_manager",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "Lot Returned to Manager.", "success");
          this.$router.go();
        }
      });
    },

    assortPrepare(){
      
      this.tabulator = new Tabulator(this.$refs['tableRef'], {
        ajaxURL: BASE_URL + '/api/list_rough_assort',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{rough_id:this.rough_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Transaction No',
            minWidth: 200,
            responsive: 0,
            field: 'assort_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              router.push("/purchase/rough-view/" + window.btoa(cell.getData().id));
          }
           
          },
          {
            title: 'Assort Type',
            minWidth: 200,
            responsive: 0,
            field: 'assortment_name',
            vertAlign: 'middle',
            print: false,
            download: true,
            
           
          },
          {
            title: 'Purchase No',
            minWidth: 200,
            responsive: 0,
            field: 'lot_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            
           
          },
         
          {
            title: 'Weight',
            
            field: 'weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Weight(%)',
            
            field: 'weight_per',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Result Weight',
            
            field: 'result_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Result Weight(%)',
            
            field: 'result_per',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().active_status == "Active"){
              return `<div class="flex items-center lg:justify-center text-theme-9">
                Active
              </div>`
              }else{
                return `<div class="flex items-center lg:justify-center text-theme-6">
                Inactive
              </div>`
              }
              
            }
          },
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              const a = cash(`<div class="flex lg:justify-center items-center">
                <span class="flex items-center" >
                  <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                </span>
              </div>`)

            
              cash(a).on('click', function(data_id) {
                // On click actions
                router.push("/purchase/update-rough-purchase/" + window.btoa(cell.getData().id));
              })

              return a[0] 
            }
          },
          
          
        ],
        renderComplete() {
         
        }
      })
    },
    kapanPrepare(){
      let _this = this;
      this.tabulatorkapan = new Tabulator(this.$refs['tableRefSeries'], {
        ajaxURL: BASE_URL + '/api/list_rough_series',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{rough_cleaver_id:this.cleaver_issue_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          
          // For HTML table
          {
            title: 'Series No',
            minWidth: 200,
            responsive: 0,
            field: 'series_code',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              _this.$router.push("/purchase/rough-view/series-detail/" + window.btoa(cell.getData().id));
              
          }
           
          },
        
          {
            title: 'Cleaver Name ',
            minWidth: 100,
            field: 'first_name',
            
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return cell.getData().first_name + " "+ cell.getData().last_name;
              
            }
          },
          ,
          {
            title: 'Original Weight',
            field: 'series_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return Number(cell.getData().series_weight) + " Ct";
              
            }
          },
          {
            title: 'Avl Weight',
            field: 'cr_avl_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().cr_avl_weight) + " Ct";
            }
          },
          {
            title: 'In Process',
            field: 'weight_in_process',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().weight_in_process) + " Ct";
            }
          },{
            title: 'Est. Makeable ',
            field: 'exp_mackable',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: '',
            field: '',

            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              const a = cash(`<div class="flex lg:justify-center items-center">
                <span class="flex items-center" >
                  <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                </span>
              </div>`)


              cash(a).on('click', function(data_id) {
                // On click actions
                _this.openEditSeries(cell.getData());
              })

              return a[0]
            }
          },
        ],
        renderComplete() {
         
        }
      })
    },

    openEditSeries(data){
      if(data != null){
        
        //this.cleaver_issue_id = data.cleaver_id;
        this.seriesForm.series_weight = data.series_weight,
        this.seriesForm.series_weight_old = data.series_weight,
        this.seriesForm.series_total_pcs = 0;
        this.seriesForm.exp_mackable = data.exp_mackable;
        this.seriesForm.est_polish = data.est_polish;
        this.seriesForm.series_id = data.id;

        cash('#new-order-modal-kapan').modal('show');
      }
    },
   
    async submitFormSeries(){
      let _this = this;
      this.form_status = true;
      console.log(this.unissued_rough_weight);
      if(this.seriesForm.series_id != ''){
        let available_new = parseFloat(this.unissued_rough_weight) + parseFloat(this.seriesForm.series_weight_old);
        console.log(available_new);
        if(this.seriesForm.series_weight > available_new){
        this.$swal("Warning!", "Can not edit more weight than remaining rough weight", "warning");
        this.form_status = false;
        return;
      }

      }else{
        if(this.seriesForm.series_weight > this.unissued_rough_weight ){
        this.$swal("Warning!", "Can not issue more weight than remaining rough weight", "warning");
        this.form_status = false;
        return;
        }

      }
      
      if(this.seriesForm.series_weight <= 0 ){
      this.$swal("Warning!", "Can not issue Zero weight.", "warning");
      this.form_status = false;
      return;
      }
      else{
      const data = {
        rough_lot_id: this.rough_id,
        rough_cleaver_id: this.cleaver_issue_id,
        series_weight: this.seriesForm.series_weight,
        series_total_pcs: this.seriesForm.series_total_pcs,
        exp_mackable: this.seriesForm.exp_mackable,
        est_polish: this.seriesForm.est_polish,
        series_id: this.seriesForm.series_id,
      };
      let url_post = '';
      if(this.seriesForm.series_id != ''){
        url_post = "api/edit_rough_series"
      }else{
         url_post = "api/add_rough_series"
      }

      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
          this.$router.go();
        })
        .catch((_error) => {
          this.form_status = false;
          alert('Can not Edit This Series. Series has process History')
          return [];
        });
        }
    },
    async submitFormAssort(){
      let _this = this;
      const data = {
        rough_lot_id: this.rough_id,
        assort_type: this.assortForm.assort_type,
        total_pcs: this.assortForm.total_pcs,
        weight: this.assortForm.weight,
        weight_per: this.assortForm.weight_per,
        result_weight: this.assortForm.result_weight,
        result_per: this.assortForm.result_per,
        id:this.assortForm.assort_id,
      };
      let url_post = '';
      if(this.assortForm.assort_id != ''){
        url_post = "api/edit_rough_assort"
      }else{
         url_post = "api/add_rough_assort"
      }
  
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          _this.assortPrepare();
        }, 500)
        })
        .catch((_error) => {
          return [];
        });
    },
    preventInvalidInput(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
  },
  
})
</script>
