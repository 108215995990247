<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-5">
          <div class="flex flex-wrap justify-end items-center">
            <div>
              <label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                v-model="pagedata.start_date"
                @change="getDashboardDetails"
              />
            </div>
            <div class="ml-3">
              <label for="update-profile-form-6" class="form-label"
                >Select End Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                v-model="pagedata.end_date"
                @change="getDashboardDetails"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4 mt-5">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <ActivityIcon class="report-box__icon text-theme-10" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.total_packets }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Total Packets</div>
                    <div class="text-base text-gray-600 mt-1">{{ pagedata.total_issue_weight ? parseFloat(pagedata.total_issue_weight).toFixed(2) + ' ct' : '0.00' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <CheckCircleIcon class="report-box__icon text-theme-11" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.planning_polish_pieces }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Total Plans</div>
                    <div class="text-base text-gray-600 mt-1">{{ pagedata.planning_polish_main_weight ? parseFloat(pagedata.planning_polish_main_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <InboxIcon class="report-box__icon text-theme-12" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.planning_accepted_pieces }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Accepted Plans</div>
                    <div class="text-base text-gray-600 mt-1">{{ pagedata.planning_accepted_main_weight ? parseFloat(pagedata.planning_accepted_main_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <MailIcon class="report-box__icon text-theme-9" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.planning_rejected_pieces }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Rejected Plans</div>
                    <div class="text-base text-gray-600 mt-1">{{ pagedata.planning_rejected_main_weight ? parseFloat(pagedata.planning_rejected_main_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <ActivityIcon class="report-box__icon text-theme-10" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.planning_rough_weight ? parseFloat(pagedata.planning_rough_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Est. Makeable Weight</div>
                    <!-- <div class="text-base text-gray-600 mt-1">{{ pagedata.planning_rough_weight ? parseFloat(pagedata.planning_rough_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <CheckCircleIcon class="report-box__icon text-theme-11" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.planning_polish_weight ? parseFloat(pagedata.planning_polish_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Est. Polish Weight</div>
                    <!-- <div class="text-base text-gray-600 mt-1">{{ pagedata.planning_polish_weight ? parseFloat(pagedata.planning_polish_weight).toFixed(2) + ' ct' : '0.00 ct' }}</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <InboxIcon class="report-box__icon text-theme-12" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.total_panalty ? parseFloat(pagedata.total_panalty).toFixed(2)  : '0'  }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Est. Panalty</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
              <div class="report-box zoom-in">
                <div class="box p-4">
                  <div class="flex items-center justify-between">
                    <MailIcon class="report-box__icon text-theme-9" />
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.planning_majuri ? parseFloat(pagedata.planning_majuri).toFixed(2) : '0.00' }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Est. Majuri</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-12 mt-8">
          <div class="intro-y box">
            <div
              class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Packet Plans</h2>
              <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                <div class="mt-3 flex items-center">
                  <label for="update-profile-form-8" class="form-label mr-3"
                    >Rough Packet</label
                  >
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Packet',

                }"
                @change="getPlanDetails"
                class="w-60" v-model="pagedata.packet_id">
                <option value="">Select Packet</option>
                    <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                  </TomSelect>
                </div>
              </div>
            </div>

            <div class="px-5">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12" v-if="pagedata.plans.planner">
                  <div class="intro-y my-2">
                    <div class="py-3">
                      <h2 class="font-medium text-base mr-auto">Planner Name : {{ pagedata.planner_name }}</h2>
                    </div>
                    <div class="overflow-x-auto scrollbar-hidden">
                      <div
                        id="plannertabulator"
                        ref="plannertableRef"
                        class="mt-5"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-12" v-if="pagedata.plans.checker">
                  <div class="intro-y my-2">
                    <div class="py-3">
                      <h2 class="font-medium text-base mr-auto">Checker Name : {{ pagedata.checker_name }}</h2>
                    </div>
                    <div class="overflow-x-auto scrollbar-hidden">
                      <div
                        id="checkertabulator"
                        ref="checkertableRef"
                        class="mt-5"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-12" v-if="pagedata.plans.main_checker">
                  <div class="intro-y my-2">
                    <div class="py-3">
                      <h2 class="font-medium text-base mr-auto">Main Checker Name : {{ pagedata.mainchecker_name }}</h2>
                    </div>
                    <div class="overflow-x-auto scrollbar-hidden">
                      <div
                        id="maincheckertabulator"
                        ref="maincheckertableRef"
                        class="mt-5"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 pt-36 text-md font-bold text-center" v-if="!pagedata.plans.planner && !pagedata.plans.checker && !pagedata.plans.main_checker && pagedata.packet_id">Plans Not Found</div>
                <div class="col-span-12 pt-36 p-6 text-md font-bold text-center" v-if="!pagedata.packet_id">Please Select Packet</div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- END: Sales Report -->

        <div class="col-span-12 lg:col-span-12">
          <!-- BEGIN: HTML Table Data -->
          <div class="intro-y box p-5 mt-5">
            <div class="py-3">
              <h2 class="font-medium text-base mr-auto">Available Packets</h2>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5"
              ></div>
            </div>
          </div>
          <!-- END: HTML Table Data -->
        </div>


      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted, watch } from 'vue'
import { BASE_URL } from '@/constants/config.js'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

import * as echarts from 'echarts/lib/echarts.js'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const importantNotesRef = ref()
    const store = useStore()
    const router = useRouter()

    const userDetails = computed(() => store.state.user.currentUser)


    const pagedata = reactive({
      total_issue_weight: '',
      total_packets: '',
      planning_polish_weight: '',
      planning_polish_pieces: '',
      planning_accepted_weight: '',
      planning_accepted_pieces: '',
      planning_rejected_pieces: '',
      planning_rejected_rough_weight: '',
      planning_rough_weight: '',
      planning_accepted_rough_weight: '',
      planning_accepted_main_weight: '',
      planning_rejected_main_weight: '',
      planning_polish_main_weight: '',
      total_panalty: '',
      planning_majuri: '',
      available_packets: [],
      packets: [],
      packet_id: '',
      plans: {
        planner: false,
        checker: false,
        main_checker: false,
      },
      planner_name : '',
      checker_name : '',
      mainchecker_name : '',
      start_date: '',
      end_date: '',
    })
    const tableRef = ref()
    const tabulator = ref()
    const plannertableRef = ref()
    const plannertabulator = ref()
    const checkertableRef = ref()
    const checkertabulator = ref()
    const maincheckertableRef = ref()
    const maincheckertabulator = ref()
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })
    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }
    const checkPermission = () => {
      console.log(userDetails.value.id);
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    const availablePackets = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/get_available_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Packet No',
            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

            },
            formatter(cell) {
              if(cell.getData().status == 1){
              return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().packet_code
              }else{
                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().packet_code
              }
            }
          },

          {
            title: 'Weight(Ct)',
            field: 'weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Ex. Weight',
            field: 'ex_weight',
            print: false,
            download: true
          },
          {
            title: 'Color',
            field: 'color_name',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Purity ',
            field: 'purity_name',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            field: 'active_status',

            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().active_status == "Active"){
              return `<div class="flex items-center lg:justify-center text-theme-9">
                N/A
              </div>`
              }else{
                return `<div class="flex items-center lg:justify-center text-theme-6">
                N/A
              </div>`
              }
            }
          },
          {
            title: 'Planning',
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              let plan_user = ``;

              if(cell.getData().planning_file == "" || cell.getData().planning_file == null){
              plan_user =  `
                <i data-feather="user" class="w-6 h-6 font-bold text-theme-7 mr-1"></i>
              `
              }else if(cell.getData().planning_file != "" && cell.getData().planning_file != null && cell.getData().c_change != 1){
                plan_user =  `
                <i data-feather="user-check" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
              `
              }else{
                plan_user =  `
                <i data-feather="user-x" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
              `
              }
              let check_user = ``;

              if(cell.getData().c_planning_file == "" || cell.getData().c_planning_file == null){
              check_user =  `
                <i data-feather="user" class="w-6 h-6 font-bold text-theme-7 mr-1"></i>
              `
              }else if(cell.getData().c_planning_file != "" && cell.getData().c_planning_file != null && cell.getData().mc_change != 1){
                check_user =  `
                <i data-feather="user-check" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
              `
              }else{
                check_user =  `
                <i data-feather="user-x" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
              `
              }
              let main_check = ``;

              if(cell.getData().mc_planning_file == "" || cell.getData().mc_planning_file == null){
              main_check =  `
                <i data-feather="user" class="w-6 h-6 font-bold text-theme-7 mr-1"></i>
              `
              }else if(cell.getData().mc_planning_file != "" && cell.getData().mc_planning_file != null){
                main_check =  `
                <i data-feather="user-check" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
              `
              }
              return `<div class="flex items-center lg:justify-center">` + plan_user + check_user + main_check +`</div>`;
            }
          },
          {
            title: '',
            field: '',
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {

            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const plannerPrepare = (id) => {
      plannertabulator.value = new Tabulator(plannertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: pagedata.packet_id, planning_type: 10,p_id: id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'RW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const checkerPrepare = (id) => {
      checkertabulator.value = new Tabulator(checkertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: pagedata.packet_id, planning_type: 11,p_id: id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'RW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const maincheckerPrepare = (id) => {
      maincheckertabulator.value = new Tabulator(maincheckertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: pagedata.packet_id, planning_type: 12,p_id: id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'RW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const PlannerPackets = () => {
      let promise = axios({
        url: "api/planner_packets",
        method: "get",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.packets = data.items
          pagedata.packet_id = stringify(pagedata.packets[0].id)
        })
        .catch(_error => {

        });
    }
    const getDashboardDetails = () => {
      if(pagedata.start_date > pagedata.end_date) {
        pagedata.start_date = pagedata.end_date
      }
      let promise = axios({
        url: "api/planner_dashboard_data",
        method: "post",
        baseURL: BASE_URL,
        data: { s_date: pagedata.start_date, e_date: pagedata.end_date },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.total_issue_weight = data.total_packets.total_issue_weight
          pagedata.total_packets = data.total_packets.total_packets
          pagedata.planning_polish_weight = data.planning_packets.planning_polish_weight
          pagedata.planning_accepted_weight = data.planning_packets.planning_accepted_weight
          // pagedata.planning_rejected_weight = data.planning_packets.planning_rejected_weight
          pagedata.planning_polish_pieces = data.planning_packets.planning_polish_pieces
          pagedata.planning_accepted_pieces = data.planning_packets.planning_accepted_pieces
          pagedata.planning_rejected_pieces = data.planning_packets.planning_rejected_pieces
          pagedata.planning_majuri = data.planner_majuri.planning_majuri
          pagedata.planning_rejected_rough_weight = data.planning_packets.planning_rejected_rough_weight
          pagedata.planning_accepted_rough_weight = data.planning_packets.planning_accepted_rough_weight
          pagedata.planning_rough_weight = data.planning_packets.planning_rough_weight
          pagedata.total_panalty = data.planner_panalty.total_panalty
          pagedata.planning_accepted_main_weight = data.planning_packets.planning_accepted_main_weight
          pagedata.planning_rejected_main_weight = data.planning_packets.planning_rejected_main_weight
          pagedata.planning_polish_main_weight = data.planning_packets.planning_polish_main_weight
        })
        .catch(_error => {

        });
    }
    function getPlanDetails() {
      const index = pagedata.packets.findIndex(object => {
        return object.id == pagedata.packet_id;
      });
      console.warn(index)
      if(pagedata.packets[index].planning_file != null) {
        pagedata.plans.planner = true
        pagedata.planner_name = pagedata.packets[index].planner_name
        setTimeout(() => {
          plannerPrepare(pagedata.packets[index].last_planner)
        }, 10);
      }
      if(pagedata.packets[index].c_change) {
        pagedata.plans.checker = true
        pagedata.checker_name = pagedata.packets[index].checker_name
        setTimeout(() => {
        checkerPrepare(pagedata.packets[index].c_last_planner)
        }, 10);
      }
      if(pagedata.packets[index].mc_change) {
        pagedata.plans.main_checker = true
        pagedata.mainchecker_name = pagedata.packets[index].mainchecker_name
        setTimeout(() => {
        maincheckerPrepare(pagedata.packets[index].mc_last_planner)
        }, 10);
      }
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      checkPermission()
      reInitOnResizeWindow()
      pagedata.end_date = new Date();
      pagedata.end_date = pagedata.end_date.toISOString().substr(0, 10);
      let date = new Date();
      pagedata.start_date = new Date(date.setMonth(date.getMonth() - 1)).toISOString().substr(0, 10);
      getDashboardDetails()
      PlannerPackets()
      availablePackets()
    })

    return {
      pagedata,
      userDetails,
      tableRef,
      plannertableRef,
      checkertableRef,
      maincheckertableRef,
      filter,
      prevImportantNotes,
      nextImportantNotes,
      getDashboardDetails,
      getPlanDetails

    }
  }
})
</script>
<style scoped>
.border {
  margin: 0;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>
