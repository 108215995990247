<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Loose Request List</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0 mr-3" v-if="BUILD_ENV == 'factory'">
          <div class="flex items-center text-lg" v-if="page_data.server_record.server_status == 1"><span class="font-bold mr-2">Last Updated :</span> <WifiIcon class="h-6 w-6 mr-2 text-theme-9" /> {{ page_data.server_record.updated_at }}</div>
          <div class="flex items-center text-lg" v-if="page_data.server_record.server_status == 2"><span class="font-bold mr-2">Last Updated :</span> <GlobeIcon class="h-6 w-6 mr-2 text-theme-6" /> {{ page_data.server_record.updated_at }}</div>
        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <button @click="create_records" class="btn btn-primary shadow-md mr-3">Create Request</button>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <!-- <div class="border-b pb-5"> -->

        <div class="grid grid-cols-12 gap-x-2">

            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Start Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" />
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Stone</label>
                    <TomSelect v-model="filters.stone_type" :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Shape</label>
                    <TomSelect v-model="filters.shape_type" :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-full">
                        <option value="0">All</option>
                        <option v-for="(item,index) in page_data.shape_items" :key="index" :value="item.id">{{ item.shape_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()" :disabled="page_data.loading == true">
                        Generate
                        <LoadingIcon icon="bars" class="w-8 h-8" v-if="page_data.loading == true" />
                    </button>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject,
    computed
} from 'vue'
import {
  BASE_URL,
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import Tabulator from 'tabulator-tables'
import feather from 'feather-icons'
import cash from 'cash-dom';
import moment from 'moment';
import {
    useRoute,
    useRouter
} from 'vue-router';
import store from '@/store';

export default defineComponent({
    setup() {

        const swal = inject('$swal');

        const tableRef = ref()
        const tabulator = ref()
        const route = useRoute()
        const router = useRouter()
        const user_details = computed(() => store.state.user.currentUser)
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const page_data = reactive({

            trans_items: [],
            shape_items: [],
            report_items: [],
            generate: "no",
            loading: false,
            server_record: []
        })

        const filters = reactive({
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            request_no: "",
            branch_id: "0",
            stone_type: "0",
            shape_type: "0",
        })
        const initTabulator = () => {

            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                request_no: filters.request_no == "" ? "" : window.btoa(filters.request_no),
                branch_id: filters.branch_id == "0" ? "" : window.btoa(filters.branch_id),
                stone_type: filters.stone_type == "0" ? "" : window.btoa(filters.stone_type),
                shape_type: filters.shape_type == "0" ? "" : window.btoa(filters.shape_type),
            };
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/lutc_rl',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxParams: params,
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Request No',
                        field: 'transaction_code',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        cellClick: function (e, cell) {
                            //e - the click event object
                            //cell - cell component
                            router.push("/app/app-final-polish/loose-request/" + window.btoa(cell.getData().id));
                        },
                        formatter(cell) {
                            if (cell.getData().created_by == user_details.value.id) {
                                return `<i data-feather="arrow-up-circle" class="w-6 h-6 font-bold text-theme-4 mr-1"></i>` + cell.getData().transaction_code
                            } else {
                                return `<i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().transaction_code
                            }
                        }
                    },
                    {
                        title: 'Branch',
                        minWidth: 100,
                        field: 'branch_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Stone',
                        minWidth: 100,
                        field: 'stone_name',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Shape',
                        minWidth: 100,
                        field: 'shape_name',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Pieces',
                        minWidth: 100,
                        field: 'total_pcs',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Weight (Ct)',
                        minWidth: 100,
                        field: 'total_weight',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Issue Date',
                        field: 'issue_date',
                        width: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea"
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().active_status == 1) {
                                return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                            } else if (cell.getData().active_status == 3) {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                            } else if (cell.getData().active_status == 2) {
                                return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                </div>`
                            } else {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                  Unknown !
                </div>`
                            }
                        }
                    },
                    {
                        title: 'Action',
                        hozAlign: 'center',
                        field: 'status',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        responsive: 0,
                        download: false,
                        formatter(cell) {

                            const dropdown = document.createElement("div");
                            dropdown.classList.add("dropdown");

                            const button = document.createElement("button");
                            button.classList.add("btn", "btn-secondary", "btn-sm", "dropdown-toggle");
                            button.setAttribute("id", "dropdownMenuButton");
                            button.setAttribute("type", "button");
                            button.setAttribute("data-toggle", "dropdown");
                            button.setAttribute("aria-haspopup", "true");
                            button.setAttribute("aria-expanded", "false");
                            button.innerText = "Action";

                            const menu_icon = document.createElement("i");
                            menu_icon.classList.add("w-4", "h-4", "ml-2");
                            menu_icon.setAttribute("data-feather", "chevron-down");
                            button.appendChild(menu_icon);

                            const menu = document.createElement("div");
                            menu.setAttribute("aria-labelledby", "dropdownMenuButton");
                            menu.classList.add("dropdown-menu")

                            const sub_menue = document.createElement("div")
                            sub_menue.classList.add("dropdown-menu__content", "box", "bg-theme-26", "dark:bg-dark-6", "text-white");

                            const main_view_details = document.createElement("div");
                            main_view_details.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                            const view_details = document.createElement("div");
                            view_details.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                            view_details.setAttribute("data-dismiss", "dropdown");
                            view_details.innerText = "View Details";

                            view_details.addEventListener('click', function handleClick(event) {
                                router.push("/app/app-final-polish/loose-request/" + window.btoa(cell.getData().id));
                            });
                            main_view_details.appendChild(view_details);
                            sub_menue.appendChild(main_view_details);

                            // if(user_details.value.id == cell.getData().created_by && cell.getData().active_status == 1) {

                            //   const main_edit = document.createElement("div");
                            //     main_edit.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                            //     const edit = document.createElement("div");
                            //     edit.setAttribute("data-dismiss", "dropdown");
                            //     edit.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                            //     edit.innerText = "Edit Details";

                            //     edit.addEventListener('click', function handleClick(event) {

                            //       // Edit Event
                            //       router.push("/app/app-final-polish/loose-request/edit/" + window.btoa(cell.getData().id));
                            //     });
                            //     main_edit.appendChild(edit);
                            //     sub_menue.appendChild(main_edit);

                            // }

                            if(cell.getData().active_status == 1) {

                              const main_reject_purchase = document.createElement("div");
                              main_reject_purchase.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                              const reject_purchase = document.createElement("div");
                              reject_purchase.setAttribute("data-dismiss", "dropdown");
                              reject_purchase.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                              reject_purchase.innerText = "Withdraw";

                              reject_purchase.addEventListener('click', function handleClick(event) {
                                  reject_request(cell.getData().id)
                              });
                              main_reject_purchase.appendChild(reject_purchase);
                              sub_menue.appendChild(main_reject_purchase);
                            }

                            if(user_details.value.user_type == 1 && cell.getData().active_status == 1) {

                              const main_issue_process = document.createElement("div");
                              main_issue_process.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                              const issue_process = document.createElement("div");
                              issue_process.setAttribute("data-dismiss", "dropdown");
                              issue_process.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                              issue_process.innerText = "Accept";

                              issue_process.addEventListener('click', function handleClick(event) {
                                  // Accept Request
                                  accept_request(cell.getData().id)
                              });
                              main_issue_process.appendChild(issue_process);
                              sub_menue.appendChild(main_issue_process);

                            }


                            menu.appendChild(sub_menue);
                            dropdown.appendChild(button);
                            dropdown.appendChild(menu);

                            const b = cash(dropdown);
                            return b[0]

                        }
                    }

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const reject_request = (id) => {

          swal({
                title: "Are you sure?",
                text: "You want to Reject Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Reject",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {



              page_data.failed_loading = true

              const params = {
                  id: window.btoa(id),
              };

              let promise = axios({
                  url: "api/lutc_rr",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "NO") {
                          swal.fire({
                              icon: "success",
                              title: "Success!",
                              text: data.message,
                          })
                          initTabulator()
                      } else {
                          swal({
                              title: "Warning!",
                              icon: "warning",
                              text: data.message,
                              type: "warning",
                          })
                      }

                  })
                  .catch(_error => {

                  });
                }
            });
        }

        const accept_request = (id) => {

          swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {



                  page_data.failed_loading = true

              const params = {
                  id: window.btoa(id),
              };

              let promise = axios({
                  url: "api/lutc_ar",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "NO") {
                          swal.fire({
                              icon: "success",
                              title: "Success!",
                              text: data.message,
                          })
                          initTabulator()
                      } else {
                          swal({
                              title: "Warning!",
                              icon: "warning",
                              text: data.message,
                              type: "warning",
                          })
                      }

                  })
                  .catch(_error => {

                  });
                }
            });
        }

        const onResetFilter = () => {
            filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            filters.e_date = moment().format('YYYY-MM-DD')
            filters.request_no = ""
            filters.branch_id = "0"
            filters.stone_type = "0"
            filters.shape_type = "0"

            initTabulator()
        }

        const getShapeDetails = () => {
            const params = {
            };

            let promise = axios({
                url: "/api/dp_shape",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.shape_items = data.items;

                })
                .catch(_error => {
                    page_data.shape_items = [];
                });
        }
        const create_records = () => {
            router.push('/app/app-final-polish/loose-request/create');
        }

        //Edit user
        const checkServerStatus = () => {
          let promise = axios({
              url: "api/get_last_updated_time",
              method: "post",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {

                  page_data.server_record = data.items

                })
                .catch(_error => {
                page_data.server_record = []

              });
        }

        onMounted(() => {
            initTabulator()
            getShapeDetails()
            checkServerStatus()
        })

        return {
            filters,
            page_data,
            tableRef,
            onResetFilter,
            initTabulator,
            create_records,
            BUILD_ENV
        }
    }
})
</script>
