<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Merge Details</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <a @click="printReportDetails" class="btn btn-primary shadow-md mr-2">Print</a>

        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <a @click="editDetails" class="btn btn-primary shadow-md mr-2">Edit</a>

        </div>
    </div>
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Records</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="p-5" v-if="pagedata.generate == 'gen'">
                <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.transaction_code ? pagedata.report_items.filters_arr.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.stone_type ? pagedata.report_items.filters_arr.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Shape. :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.shape_type != 0 ? pagedata.report_items.filters_arr.shape_type : 'All' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Type. :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.transaction_type ? pagedata.report_items.filters_arr.transaction_type : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.filters_arr.s_date">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish Start Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ pagedata.report_items.filters_arr.s_date }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.filters_arr.e_date">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish End Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ pagedata.report_items.filters_arr.e_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ pagedata.report_items.filters_arr.merge_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.filters_arr.series_details.length">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Series : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in pagedata.report_items.filters_arr.series_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.filters_arr.kapan_details.length">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Kapans : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in pagedata.report_items.filters_arr.kapan_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>

                    </div>
                </div>

            </div>
            <div class="py-5" v-if="pagedata.generate == 'gen' && pagedata.report_items.kapan_detail_arr.length > 0">
                <h2 class="text-lg font-medium mr-auto">Series/ Kapan List</h2>
                <div class="grid grid-cols-12 pt-5 text-center gap-2" v-if="pagedata.report_items.kapan_detail_arr.length > 0">
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 border w-full py-1 m-0" v-for="(item,index) in pagedata.report_items.kapan_detail_arr" :key="index">
                        <div class="px-2">

                            <table class="w-full">

                                <tr>
                                    <td class="w-40 text-left">
                                        {{ item.kapan_code }}
                                    </td>
                                    <td class="w-36 text-center">
                                        {{ item.pieces }}
                                    </td>
                                    <td class="w-36 text-right">
                                        {{ $h.myFloat(item.weight) }} Ct
                                    </td>
                                    <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="pagedata.generate == 'gen'">
                <div class="overflow-x-auto m-0 p-0 mt-5" v-for="(report_itm,report_index) in pagedata.report_items.color_arr" :key="report_index">

                    <table class="text-xs text-center w-full">

                        <thead>
                            <tr class="border bg-gray-200 dark:bg-dark-1">
                                <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in report_itm.heading1_arr" :key="shape_index" :colspan="report_itm.heading1_size_arr[shape_index]">{{ shape_item }}</th>
                            </tr>
                            <tr class="border bg-gray-100 dark:bg-dark-1">
                                <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in report_itm.heading2_arr" :key="size_index" :colspan="report_itm.size_arr_number[size_index]">{{ size_item }}</th>
                            </tr>
                            <tr class="border bg-gray-100 dark:bg-dark-1">
                                <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in report_itm.heading3_arr" :key="size_index">{{ size_item }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border" v-for="(size_value_item,size_value_index) in report_itm.size_value_arr" :key="size_value_index">
                                <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                            </tr>
                            <tr class="border">
                                <th class="border py-2" v-for="(item,inx) in report_itm.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
    BASE_URL
} from '@/constants/config.js'
import moment from 'moment';
import {
    useRoute,
    useRouter
} from 'vue-router';

export default defineComponent({
    setup() {

        const swal = inject('$swal');
        const route = useRoute()
        const router = useRouter()
        const pagedata = reactive({

            report_items: [],
            generate: "no",
            loading: false,
        })

        const editDetails = () => {

          if(pagedata.report_items.merge_type == 2) {

            router.push('/app/app-polish-ready/ready/merge-lot-new/' + route.params.id)
          } else {

            router.push('/app/app-process/packet/receive/final-non-certy/receive/' + route.params.id)
          }
        }

        const generateReport = () => {
            const params = {
                merge_id: route.params.id
            };

            pagedata.loading = true

            let promise = axios({
                url: "api/get_merge_single_transaction",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        swal("Warning!", data.message, "warning");
                        pagedata.generate = 'no';
                        pagedata.loading = false

                        return;
                    }

                    pagedata.report_items = data.items;
                    pagedata.generate = 'gen';
                    pagedata.loading = false

                })
                .catch(_error => {

                    pagedata.generate = 'no';
                    pagedata.loading = false
                });
        }

        function printReportDetails() {
            let report_type = 'final-polish-stock-comparison'

            let params = {
                report_type: report_type,
                filters: [],
                items: pagedata.report_items
            }
            let promise = axios({
                url: "api/add_report_details",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    const report_id = data.report_id

                    let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
                    window.open(url, '_blank');

                })
                .catch(_error => {

                });
            // add_report_details
        }

        onMounted(() => {
            generateReport()
        })

        return {
            pagedata,
            printReportDetails,
            editDetails
        }
    }
})
</script>
