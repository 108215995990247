<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish - Stock Transfer (Non-Certy)</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12" v-if="final_data.id == ''">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Issue Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Stone Type</label>
                                <TomSelect v-model="form_data.stone" :options="{
                      placeholder: 'Select Stone',
                      }" class="w-full">
                                    <option value="0">Select Stone</option>
                                    <option value="1">Natural</option>
                                    <option value="2">CVD</option>
                                    <option value="3">HPHT</option>
                                    <option value="4">D_CVD</option>
                                    <option value="5">D_HPHT</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Shape Type</label>
                                <TomSelect v-model="form_data.shape" :options="{
                      placeholder: 'Select Shape',
                      }" class="w-full">
                                    <option value="0">Select Shape</option>
                                    <option v-for="item in shape_arr" :key="item.id" :value="item.id">{{ item.shape_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Branch</label>
                                <TomSelect v-model="form_data.branch_id" :options="{
                      placeholder: 'Select Shape',
                      }" class="w-full">
                                    <option value="0">Select Branch</option>
                                    <option value="1" v-if="user_details.user_type == 26">Home Branch</option>
                                    <option v-for="item in branch_arr" :key="item.id" :value="item.id">{{ item.branch_name }} {{ item.branch_code ?  '( '+  item.branch_code + ' )' : "" }}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div class="col-span-3 md:col-span-2 xl:col-span-1">
                            <button class="btn btn-secondary px-3 py-2 mt-8 w-full" @click="reset_details">
                                Reset
                            </button>
                        </div>
                        <div class="col-span-3 md:col-span-2 xl:col-span-2">
                            <button class="btn btn-primary py-2 mt-8" @click="get_final_polish_ready_stock" :disabled="data_loading == true || form_status == true">
                                Get Ready Stock
                                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="data_loading == false" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
        <!-- </div>
      <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5">
            <!-- BEGIN: Input -->
            <div class="intro-y box">

                <div class="p-5">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Non-Certy Stock</h2>
                        <div class="ml-5">

                            <div class="py-1 flex items-center">
                                <label for="update-profile-form-7" class="form-label px-2">Select Color</label>
                                <TomSelect v-model="form_data.color_size" :options="{
                            placeholder: 'Select Color',
                            }" class="w-36">
                                    <option value="">Select Color</option>
                                    <option v-for="(item,index) in size_purity_arr" :key="index" :value="item.color_size">{{ item.color_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="border mt-5">
                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center text-xs">
                                <thead>
                                    <template v-for="(size_item,index) in size_purity_arr" :key="index">
                                        <template v-if="size_item.color_size == form_data.color_size">
                                          <tr class="bg-theme-14" v-if="size_item.purity_items.length > 0">
                                              <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Purity</th>
                                              <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">All</th>
                                              <template v-for="(item,index) in size_item.purity_items" :key="index">
                                                  <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">{{ item.purity }}</th>
                                              </template>
                                          </tr>
                                          <tr class="bg-theme-14" v-if="size_item.purity_items.length > 0">
                                              <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2"></th>
                                              <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Total</th>
                                              <template v-for="(item,index) in size_item.purity_items" :key="index">
                                                  <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Size {{ item.size }}</th>
                                              </template>
                                          </tr>
                                          <tr class="bg-theme-14">
                                              <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                              <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                              <th class="border-b py-2 px-2 dark:border-dark-5">Stock(Ct)</th>
                                              <th class="border-b border-r py-2 px-2 dark:border-dark-5">Pcs</th>
                                              <template v-for="(item,index) in size_item.purity_items" :key="index">
                                                  <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock(Ct) </th>
                                                  <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS </th>
                                              </template>
                                          </tr>
                                        </template>
                                    </template>
                                </thead>
                                <tbody class="text-center" v-for="(size_item,shape_index) in fields" :key="shape_index">
                                    <template v-if="size_item.color_size == form_data.color_size">
                                      <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in size_item['charni_data']" :key="index">
                                        <td class="border-b py-1 px-2">{{ index + 1 }}</td>
                                        <td class="border-b border-r py-1 px-2">{{ field.charni_name }}</td>
                                        <td class="border-b py-1 px-1">{{ field.new_weight ? $h.myFloat(field.new_weight) : "0" }} Ct</td>
                                        <td class="border-b border-r py-1 px-2">{{ field.new_pcs ? field.new_pcs : "0" }}</td>
                                        <template v-for="(item,inx) in field.sub_charni_arr" :key="inx">
                                            <td class="border-b py-1 px-2">
                                                <input v-model="item.new_weight" type="text" class="form-control w-20 text-center" placeholder="weight" @keypress="$h.preventInvalidInput" @change="calculateTotal(field)">
                                                <div class="mt-1">
                                                    <span :class="{'text-theme-6' : item.new_weight > 0 && $h.myFloat(item.cr_weight ? item.cr_weight : 0) < item.new_weight}">{{ item.cr_weight ? $h.myFloat(item.cr_weight) : 0 }} Ct</span>
                                                </div>
                                            </td>
                                            <td class="border-b py-1 px-2">
                                                <input v-model="item.new_pcs" type="text" class="form-control w-20 text-center" placeholder="pieces" @keypress="$h.preventInvalidInput" @change="calculateTotalPieces(field)">
                                                <div class="mt-1">
                                                    <span :class="{'text-theme-6' : item.new_pcs > 0 && (item.cr_pcs ? item.cr_pcs : 0) < item.new_pcs}">{{ item.cr_pcs ? item.cr_pcs : 0 }}</span>
                                                </div>
                                            </td>
                                        </template>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="data_loading == true" class="text-md font-medium mr-auto text-center my-8">
                            Loading
                        </div>
                        <div v-if="fields.length == 0 && data_loading == false" class="text-md font-medium mr-auto text-center my-8">
                            Records Not Found
                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-12 sm:col-span-7">

                            </div>
                            <div class="col-span-10 sm:col-span-3 text-right">
                                <p class="font-bold">Total Added Carat (Ct) : </p>
                            </div>
                            <div class="col-span-2 sm:col-span-2 text-right" :class="{'text-theme-6': ((total_added_ct > total_ready_stone_ct) || (total_added_pieces > total_ready_stone))}">
                                <p>{{total_added_pieces}} ({{ total_added_ct ? $h.myFloat(total_added_ct) + " Ct" : "0 Ct" }})</p>
                            </div>

                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-12 sm:col-span-7">
                            </div>
                            <div class="col-span-10 sm:col-span-3 text-right">
                                <p class="font-bold">Total Avaialbe(Ct) : </p>
                            </div>
                            <div class="col-span-2 sm:col-span-2 text-right">
                                <p>{{total_ready_stone}} ({{ total_ready_stone_ct ? $h.myFloat(total_ready_stone_ct) + " Ct" : "0 Ct" }})</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-5">
                    <div>
                        <div class="flex justify-end mt-4">

                            <button class="btn btn-primary w-20 mr-auto" :disabled="form_status == true || data_loading == true" @click="onUserFormSubmit">
                                Save
                                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="form_status == false" />
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>


<script>
import {
    defineComponent,
    onMounted
} from 'vue'
import {
    mapGetters,
    mapActions
} from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL,BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    props: {},

    data() {
        return {
            total_ready_stone: 0,
            total_ready_stone_ct: 0,
            total_added_ct: 0,
            total_added_pieces: 0,
            user_details: this.$store.state.user.currentUser,
            fields: [],
            size_purity_arr: [],
            branch_arr: [],
            shape_arr: [],
            form_status: false,
            data_loading: false,

            AUTH_TOKEN: BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"),

            loginVal: '',
            req: axios.create({
                baseUrl: BASE_URL_SELLING
            }),
            form_data: {
                stone: "0",
                shape: "0",
                branch_id: "0",
                branch_name: "",
                id: "",
                color_size: ""
            },
            final_data: {
                stone: "0",
                shape: "0",
                branch_id: "0",
                branch_name: "",
                id: ""
            }
        }
    },
    computed: {

    },
    mounted() {
        this.getBranchDetails();
        this.getShapeDetails();
    },

    methods: {
        alertVal() {
            alert(this.dob)
        },
        onUserFormSubmit() {
            //console.log(this.fields);
            if (this.total_added_ct <= 0) {
                this.$swal("Warning!", "First, Add Stock Data!", "warning");
                return
            }

            if (this.total_ready_stone < this.total_added_pieces) {}
            if (this.total_ready_stone_ct < this.total_added_ct) {
                this.$swal("Warning!", "Added Weight grater then Final Stock!", "warning");
                return
            }
            if (this.form_data.branch_id == "0" || this.form_data.branch_id == "") {
                this.$swal("Warning!", "Select Branch First!", "warning");
                return
            }
            this.branch_arr.forEach(element => {
                if (element.id == this.form_data.branch_id) {
                    this.form_data.branch_name = element.branch_name;
                }
            });

            let is_failed = false

            this.fields.forEach(element_clr => {

                if(element_clr.charni_data.length > 0) {
                    element_clr.charni_data.forEach(element_chrni => {


                        element_chrni.sub_charni_arr.forEach(element => {

                            if (element.new_weight > 0 && (element.cr_weight ? $h.myFloat(element.cr_weight) : 0) < (element.new_weight ? $h.myFloat(element.new_weight) : 0)) {
                                is_failed = true
                            }
                        });
                    });
                }


            });

            if (is_failed == true) {
                this.$swal("Warning!", "Please Check Entered Weight!", "warning");
                return
            }

            this.$swal({
                title: "Are you sure?",
                text: "You Want to Transfer To " + this.form_data.branch_name + " !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const data = {
                        "total_added_ct": this.total_added_ct,
                        "total_added_pieces": this.total_added_pieces,
                        "transfer_stock": this.fields,
                        "id": this.final_data.id,
                        "stone": this.final_data.stone == "0" ? "" : window.btoa(this.final_data.stone),
                        "shape": this.final_data.shape == "0" ? "" : window.btoa(this.final_data.shape),
                        "branch_id": this.form_data.branch_id == "0" ? "" : window.btoa(this.form_data.branch_id)

                    };
                    let new_url = "/transfer_final_charni_branch_stock"

                    this.form_status = true

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: data,
                        baseURL: BASE_URL_SELLING,
                        headers: {
                            Authorization: "Bearer " + this.AUTH_TOKEN
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });

                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {
                                this.$swal("Error!", data.message, "error");

                                return;
                            }
                            this.$swal.fire({
                                icon: 'success',
                                title: "Success!",
                                text: data.message,
                                showConfirmButton: false,
                                timer: 3000
                            })
                            this.form_status = false
                            let transaction_id = data.transaction_details.id
                            this.$router.push('/app/app-final-polish/branch-transfer/' + window.btoa(transaction_id));

                        })
                        .catch(_error => {
                            this.form_status = false
                            // if (data.error == "YES") {
                            this.$swal("Error!", "Something went Wrong", "error");

                            return;
                            // }

                        });
                }
            })
        },
        calculateTotal(item) {
            item.new_weight = 0
            item.sub_charni_arr.forEach(element => {
                item.new_weight = $h.myFloat(parseFloat(item.new_weight) + (element.new_weight ? parseFloat(element.new_weight) : 0))
            });
            this.total_added_ct = 0


            this.calculateSizeTotal()
        },
        calculateTotalPieces(item) {
            let new_pcs = 0
            item.sub_charni_arr.forEach(element => {
                new_pcs = $h.myFloat(parseFloat(new_pcs) + (element.new_pcs ? parseFloat(element.new_pcs) : 0))
            });

            item.new_pcs = new_pcs
            this.calculateSizeTotal()
        },
        calculateSizeTotal() {

          this.total_added_ct = 0
          this.total_added_pieces = 0

          this.fields.forEach(element_f => {

              let t_total_wt = 0
              let t_total_pieces = 0

              if(element_f.charni_data.length > 0) {


                element_f.charni_data.forEach(element_g => {
                  element_g.sub_charni_arr.forEach(element => {

                    t_total_wt = $h.myFloat(t_total_wt + (element.new_weight ? parseFloat(element.new_weight) : 0))
                    t_total_pieces = $h.myFloat(t_total_pieces + parseFloat(element.new_pcs > 0 ? parseFloat(element.new_pcs) : 0))
                  });
                });
              }
              this.total_added_ct = $h.myFloat(this.total_added_ct + t_total_wt)
              this.total_added_pieces = $h.myFloat(this.total_added_pieces + t_total_pieces)
              element_f.total.added_pcs = t_total_pieces
              element_f.total.added_weight = t_total_wt
          });

          },
        async getBranchDetails() {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/transfer_branches",
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.branch_arr = data.items;

                })
                .catch(_error => {
                    this.branch_arr = [];

                });
        },
        async getSizePurityDetails() {
            let _this = this;
            const params = {
                stone_type: this.final_data.stone
            };
            let promise = axios({
                url: "/list_all_merge_segment_all_color",
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.size_purity_arr = data.items;
                    this.form_data.color_size = String(this.size_purity_arr[0].color_size)

                  })
                  .catch(_error => {
                    this.size_purity_arr = [];
                    this.form_data.color_size = ""

                });
        },
        async get_final_polish_ready_stock() {

            if (this.form_data.stone == "" || this.form_data.stone == "0") {
                this.$swal("Warning!", "Select Stone Type First!", "warning");
                return
            }
            if (this.form_data.shape == "" || this.form_data.shape == "0") {
                this.$swal("Warning!", "Select Shape Type First!", "warning");
                return
            }

            this.final_data.stone = this.form_data.stone
            this.final_data.shape = this.form_data.shape
            this.final_data.branch_name = this.form_data.branch_name
            this.final_data.branch_id = this.form_data.branch_id
            this.final_data.id = this.form_data.id

            this.data_loading = true
            this.fields = []
            this.total_ready_stone = 0;
            this.total_ready_stone_ct = 0
            this.getSizePurityDetails();

            let params = {
                "stone": this.form_data.stone == "0" ? "" : window.btoa(this.form_data.stone),
                "shape": this.form_data.shape == "0" ? "" : window.btoa(this.form_data.shape),
            }

            let api_url = "/get_final_charni_branch_stock"

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + this.AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                    if (data.error == "YES") {

                        this.data_loading = false

                        this.$swal("Warning", data.message, "warning");

                        return
                    }

                    this.fields = data.items
                    this.total_ready_stone_ct = data.total.total_weight
                    this.total_ready_stone = data.total.total_pcs
                    this.data_loading = false

                })
                .catch((_error) => {
                    this.fields = []
                    this.total_ready_stone = 0
                    this.total_ready_stone_ct = 0
                    this.data_loading = false
                });
        },
        async getShapeDetails() {

        const params = {
        };
        const url = "/api/dp_shape"
        let promise = axios({
            url: url,
            method: "POST",
            data: params,
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
              this.shape_arr = data.items;
              // initTabulator()
            })
            .catch(_error => {
              this.shape_arr = [];
              // initTabulator()
            });

        },
        reset_details() {
            this.form_data.stone = "0"
            this.form_data.shape = "0"
            this.form_data.branch_id = "0"
        }
    },

})
</script>


<style scoped>
/* .border {
    margin: 0 20px;
    padding: 3px;
  } */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 500px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
