<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Series View -  {{ kapanForm.kapan_code }}</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Series Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

            </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">
                  <div class="">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Purchase No : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.lot_no }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Series No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_code }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Cleaver Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.cleaver_name }}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Cleaver Issue Date : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.cleaver_issue_date }}</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Est. Makeable : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ Number(exp_mackable) }} &nbsp;(%)</label
                  >
                </div>

              </div>
              <div class="col-span-12 xl:col-span-4">

                <div>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Weight (Original): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.series_weight) }} (Ct) / {{ $h.myFloat(kapanForm.series_weight - kapanForm.series_lose_weight - total_rejection)}} (Ct)</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Weight (Received): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(total_received)}} (Ct)</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Total Rejection (Ct) (%) : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(total_rejection) }} (Ct)  ({{ $h.myFloat(total_rejection_per)}} %)</label
                  >
                </div>
                <div class="mt-3" v-if="total_rejection">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Lot Total Rejection (Ct) (%) : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(total_lot_rejection) }} (Ct)  ({{ $h.myFloat(total_lot_rejection_per)}} %)</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Weight (in Process): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.series_weight_in_process) }} (Ct)</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Weight (in Hand): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(in_hand_weight) }} (Ct) [PA : {{ $h.myFloat(pa_rough_weight) }}] (Ct)</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Kapan Weight: &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(issued_rough_weight) }} (Ct) {{ lot_orinnal_weight ? ' / ' + $h.myFloat(lot_orinnal_weight) + ' (Ct)' : ''  }}</label
                  >
                </div>
                 <div class="mt-3" v-if="total_cr_weight">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Lot Available: &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(total_cr_weight) }} (Ct)</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Lose Weight: &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.series_lose_weight) }} (Ct)</label
                  >
                </div>
                 <div class="mt-3" v-if="kapanForm.series_lot_lose_weight">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Lot Lose Weight: &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.series_lot_lose_weight) }} (Ct)</label
                  >
                </div>


              </div>
              <div class="col-span-4 xl:col-span-4">
                <div id="graph1" ref="graph1_wrap" style="width:100%; height:240px;"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Series Manager wise details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
            </div>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
                <div
                id="tabulatorseriesmanager"
                ref="tableRefSeriesManager"
                class="mt-2"
              ></div>
              </div>
            </div>
        </div>
        </div>

        <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Kapan Details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
              <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#new-order-modal-kapan"
            class="btn btn-primary shadow-md mr-2"
            v-if="unissued_rough_weight > 0"
            >Create New Kapan</a
          >
            </div>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
                <div
                id="tabulatorkapan"
                ref="tableRefKapan"
                class="mt-2"
              ></div>
              </div>
            </div>
        </div>
        </div>

         <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">PA-LS Bunch Details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
              <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#new-order-modal-bunch"
            class="btn btn-primary shadow-md mr-2"
            v-if="unissued_pa_rough_weight > 0"
            >Create New Bunch</a
          >
            </div>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
                <div
          id="tabulatorPaBunch"
          ref="tableRefPaBunch"
          class="mt-2"
        ></div>
              </div>

            </div>


        </div>
        </div>
    </div>
    </div>


    <div id="new-order-modal-kapan" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Kapan</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">

              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Kapan Type</label
                  >
                  <TomSelect
                v-model="kapanForm.kapan_type"
                :options="{
                  placeholder: 'Select Kapan Type',


                }"
                class="w-full"

              >
             <option value="1" selected>Manual</option>
             <option value="2">Galaxy</option>
              </TomSelect>
                </div>

              <div class="mt-3" v-if="kapanForm.kapan_type == 2">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Purity</label
                  >
                  <TomSelect
                v-model="kapanForm.kapan_purity"
                :options="{
                  placeholder: 'Select Purity',
                }"
                class="w-full"
              >
             <option value="">Select Purity </option>
             <option value="AAA">AAA</option>
             <option value="A">A</option>
             <option value="B">B</option>
             <option value="FANCY">FANCY</option>
             <option value="NoPurity">No Purity</option>
              </TomSelect>
                </div>

            <div class="mt-3" v-if="kapanForm.kapan_type == 3">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Type</label
                  >
                  <TomSelect
                v-model="kapanForm.kapan_sub_type"
                :options="{
                  placeholder: 'Select Type',
                }"
                class="w-full"
              >
             <option value="">Select Type</option>
             <option value="Round">Round</option>
             <option value="Prince">Prince</option>
             <option value="SM">SM</option>
             <option value="4P">4P</option>
              </TomSelect>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Kapan Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="kapanForm.kapan_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="kapanForm.kapan_total_pcs"
                    @keypress="$h.preventInvalidInputInt" onpaste="return false;"
                  />
                </div>



              </div>
              <div class="col-span-12 xl:col-span-6">

                <div class="">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Makeable(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    v-model="kapanForm.exp_mackable"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Polish(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    v-model="kapanForm.exp_polish"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Value($)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="kapanForm.exp_value"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>

              </div>

          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormKapan" :disabled="kapanForm.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!kapanForm.form_status"/>
            </button>

          </div>
        </div>
      </div>
    </div>

     <div id="new-order-modal-bunch" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New PA-LS Bunch</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">

              <div class="col-span-12 xl:col-span-12">

                <div class="">
                  <label for="update-profile-form-13" class="form-label"
                    >Bunch Weight</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Ct"
                    v-model="paForm.pa_bunch_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="paForm.pa_total_pcs"
                    @keypress="$h.preventInvalidInputInt" onpaste="return false;"
                  />
                </div>

              </div>

          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormPaBunch" :disabled="paForm.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!paForm.form_status"/>
            </button>

          </div>
        </div>
      </div>
    </div>

    <div id="merge-order-modal-bunch" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Merge PA-LS Bunch</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12 xl:col-span-12">
                <div class="">
                  <label for="update-profile-form-13" class="form-label"
                    >Merge Weight</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Ct"
                    v-model="paFormMerge.pa_bunch_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="paFormMerge.pa_total_pcs"
                    @keypress="$h.preventInvalidInputInt" onpaste="return false;"
                  />
                </div>
              </div>

          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
              @click="clearPaFormMerge"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormPaBunchMerge" :disabled="paFormMerge.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!paFormMerge.form_status"/>
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Assortment Details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
            <button
                class="btn btn-primary shadow-md mr-2"
                v-if="in_hand_weight > 0"
                @click="openAddAssort"
                >Add Assortment</button
              >
            </div>
          </div>

          <!-- <div class="p-4">

            <div class="grid grid-cols-12 mt-3 mb-3">

                  <div class=" col-span-6 xl:col-span-6">
                    <div class="form-inline">
                      <label class="form-label w-60 font-bold">Assortment Type</label>
                      <label class="w-60 font-bold">New Weight</label>
                      <label class="w-60 ml-10 font-bold">Old Weight</label>
                  </div>
                </div>
            </div>
            <hr>
            <div class="grid grid-cols-12 mt-3" v-for="assort_item in assorts" :key="assort_item.id" :value="assort_item.id">

                  <div class=" col-span-6 xl:col-span-6">
                    <div class="form-inline">
                      <label class="form-label w-60">{{ assort_item.assortment_name}}</label>
                      <input
                      id="update-profile-form-13"
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="assort_item.weight"
                    />
                    <label class="ml-5 w-60">{{ assort_item.old_weight}}</label>
                  </div>
                </div>
            </div>
        </div> -->
        <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <div
                  id="tableRef"
                  ref="tableRef"
                  class="mt-5"
                ></div>
              </div>
            </div>
        </div>
      </div>

    <div id="new-order-modal-assort" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Assortment</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">

              <div class="col-span-12 xl:col-span-12">

                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Assort Type</label
                  >
                  <TomSelect
                v-model="assortForm.assort_type"
                :options="{
                  placeholder: 'Select Assort Type',

                }"
                class="w-full"
                :disabled="assortForm.assort_type_lock"

              >
             <option value="">Select Assort Type</option>
            <option v-for="assort in assorts" :key="assort.id" :value="assort.id">{{assort.assortment_name}}</option>
              </TomSelect>
                </div>

                 <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="assortForm.weight"
                    @change="calculatePer"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Weight(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    readonly
                    v-model="assortForm.weight_per"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="assortForm.total_pcs"
                  />
                </div>
              </div>
              <!-- <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-9" class="form-label"
                    >Result Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="assortForm.result_weight"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Result Weight(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    v-model="assortForm.result_per"
                  />
                </div>
              </div> -->
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormAssort" :disabled="assortForm.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!assortForm.form_status"/>
            </button>
            </div>
          </div>
        </div>
      </div>
      <div id="edit-order-modal-assort" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Assortment</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">

              <div class="col-span-12 xl:col-span-12">

                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Assort Type</label
                  >
                  <TomSelect
                v-model="assortForm.assort_type"
                :options="{
                  placeholder: 'Select Assort Type',

                }"
                class="w-full"
                disabled

              >
             <option value="">Select Assort Type</option>
            <option v-for="assort in assorts" :key="assort.id" :value="assort.id">{{assort.assortment_name}}</option>
              </TomSelect>
                </div>

                 <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="assortForm.weight"
                    @change="calculatePer"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Weight(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    readonly
                    v-model="assortForm.weight_per"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="assortForm.total_pcs"
                  />
                </div>
              </div>
              <!-- <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-9" class="form-label"
                    >Result Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="assortForm.result_weight"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Result Weight(%)</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    v-model="assortForm.result_per"
                  />
                </div>
              </div> -->
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormAssort" :disabled="assortForm.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!assortForm.form_status"/>
            </button>
            </div>
          </div>
        </div>
      </div>
              <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">

                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
import datetime from 'vuejs-datetimepicker';
import feather from 'feather-icons'
import { useVuelidate } from '@vuelidate/core'

import * as echarts from 'echarts/lib/echarts.js'

import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/dataZoomInside";
import "echarts/lib/component/dataZoomSlider";

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    series_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      rough_id:'',
      tabulator:[],
      tabulatorkapan:[],
      unissued_rough_weight:0,
      unissued_rough_weight_buff:0,
      unissued_pa_rough_weight:0,
      in_hand_weight:0,
      total_cr_weight:0,
      issued_rough_weight:0,
      total_pcs: '',
      packet_status:'',
      total_received: 0,
      total_rejection: 0,
      total_rejection_per:0,
      total_lot_rejection_per:0,
      lot_orinnal_weight:0,
      cleavers:[],
      operators:[],
      assorts:[],
      process_ar:[],

      colors:[],
      status: '1',

      total_items:1,
      issueForm:{
        operator_id:'',
        process_id:'',
      },
      exp_mackable:'',
      kapanForm:{
        lot_no:'',
        form_status:false,
        kapan_code:'',
        kapan_name:1,
        kapan_type:1,
        kapan_weight:'',
        kapan_weight_old:'',
        series_weight:0,
        series_weight_buffer:0,
        series_avl_weight:0,
        series_lose_weight:0,
        kapan_total_pcs:'',
        cleaver_id:'',
        cleaver_name:'',
        cleaver_issue_date:'',
        exp_mackable:'',
        exp_polish:'',
        exp_value:'',
        kapan_purity:'',
        kapan_sub_type:'',
        series_weight_in_process:'',
        kapan_id:'',
        series_lot_lose_weight:''
      },
      paForm:{
        form_status:false,
        pa_code:'',
        pa_name:1,
        pa_bunch_weight:'',
        pa_total_pcs:'',
        bunch_id:''
      },
      paFormMerge:{
        form_status:false,
        pa_bunch_weight:'',
        pa_total_pcs:'',
        bunch_id:''
      },
      in_hand_weight:0,
      pa_rough_weight: 0,
      pa_rough_bunch_weight: 0,
      assortForm:{
        form_status:false,
        assort_type:'',
        assort_id:'',
        assort_name:'',
        total_pcs:'',
        weight:'',
        old_weight:'',
        weight_per:'',
        result_weight:'',
        result_per:'',
        assort_type_lock:false
      },

      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },

    computed: {
       ...mapGetters({
      currentUser: "currentUser",
    }),
    },
    mounted(){
    this.getAssortType();
    this.getProcess();
    this.kapanPrepare();
    this.seriesManagerPrepare();
    this.paBunchPrepare();
    this.assortPrepare();
    this.getOperators();
    this.getRoughDetails(this.series_id);
    },

    methods: {
    async getProcess() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.process_ar = data.items;
        })
        .catch(_error => {

        });
    },
    async getOperators() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_account_operator",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.operators = data.items;

        })
        .catch(_error => {

        });
    },
    async getAssortType() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_assortment",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          if(data.items != null && data.items != ''){
            this.assorts = data.items;
            var i = this.assorts.length;
              while(i--){
              this.assorts[i]['old_weight'] = 0;
            }
          }
        })
        .catch(_error => {

        });
    },
    calculatePer(){
      var assort_weight = this.assortForm.weight;
      var total_series_w = this.kapanForm.series_weight;
      var assort_per = 0;
      console.log(assort_weight);
      if(assort_weight != '' && assort_weight != undefined){
         assort_per = (parseFloat(assort_weight)*100) / parseFloat(total_series_w);
      }
      this.assortForm.weight_per = Number(assort_per.toFixed(2));
    },

    pieWeight(){
    let myChart = echarts.init(this.$refs.graph1_wrap);
    myChart.showLoading({
        text: "Gathering Information",
        color: "#145388"
      });
      let option = {
      tooltip: {
        trigger: 'item',
        formatter: '<b>{b}</b> <br/><b>{c}({d}%)</b>'
      },
      legend: {
        top: '20%',
        left: 'right',
        orient: 'vertical',
      },
      series: [
        {
          name: 'Series Rough',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '10',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: this.$h.myFloat(this.kapanForm.series_weight - this.kapanForm.series_lose_weight - this.total_rejection - (this.total_cr_weight ? this.total_cr_weight : 0) - (this.kapanForm.series_lot_lose_weight ? this.kapanForm.series_lot_lose_weight : 0) - (this.total_lot_rejection ? this.total_lot_rejection : 0)), name: 'Series Weight',itemStyle:{
              color:"#34baeb"
            } },
            { value: this.total_cr_weight, name: 'Kapan Weight' ,itemStyle:{
              color:"#34eb7a"
            }},
            { value: parseFloat(this.kapanForm.series_lose_weight) + parseFloat(this.kapanForm.series_lot_lose_weight ? this.kapanForm.series_lot_lose_weight : 0), name: 'Ghat' ,itemStyle:{
              color:"#ff0000"
            }},
            { value: parseFloat(this.total_rejection) + parseFloat(this.total_lot_rejection ? this.total_lot_rejection : 0), name: 'Rejection' ,itemStyle:{
              color:"#f5b942"
            }}
          ]
        }
      ]
    };
    myChart.hideLoading();
        myChart.setOption(option, true);
        myChart.resize();
    },

    async getRoughDetails(series_id) {
      let _this = this;
      const params = {
        series_id: series_id
      };
      let promise = axios({
        url: "api/get_my_series_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          let rough_detail = data.item_details;
          this.kapanForm.kapan_code = rough_detail.series_code;
          this.rough_id = rough_detail.rough_lot_id;
          //this.kapanForm.kapan_name = rough_detail.kapan_name;
          this.kapanForm.series_weight = this.$h.myFloat(rough_detail.series_weight);
          this.kapanForm.series_avl_weight = rough_detail.cr_avl_weight;
          this.kapanForm.series_lose_weight = rough_detail.total_lose;
          this.kapanForm.series_lot_lose_weight = rough_detail.total_lot_lose;
          this.issued_rough_weight = rough_detail.total_kapan_weight;
          this.total_cr_weight = rough_detail.total_cr_weight;
          this.lot_orinnal_weight = rough_detail.lot_orinnal_weight;
          this.pa_rough_weight = rough_detail.mn_pa_rough;
          this.total_received = rough_detail.total_received;
          this.pa_rough_bunch_weight = rough_detail.mn_pa_bunch_weight;
          this.in_hand_weight = rough_detail.in_hand;
          console.log(rough_detail.in_hand);
          this.unissued_rough_weight = rough_detail.cr_avl_weight - rough_detail.total_kapan_weight;
          this.unissued_pa_rough_weight = this.$h.myFloat(this.pa_rough_weight - this.pa_rough_bunch_weight);
          this.paForm.pa_bunch_weight = this.unissued_pa_rough_weight;
          this.in_hand_weight = this.$h.myFloat((rough_detail.in_hand),4);
          this.kapanForm.kapan_total_pcs = rough_detail.kapan_total_pcs;
          this.kapanForm.cleaver_issue_date = rough_detail.cleaver_issue_date;
          this.exp_mackable = rough_detail.exp_mackable;
          this.kapanForm.series_weight_in_process = rough_detail.weight_in_process;
          this.kapanForm.lot_no = rough_detail.lot_no;
          this.kapanForm.cleaver_name = rough_detail.first_name + " "+rough_detail.last_name;
          this.kapanForm.cleaver_id = rough_detail.cleaver_id;
          this.packet_status = rough_detail.pack_created;
          this.kapanForm.kapan_weight = this.$h.myFloat(this.$h.myFloat(rough_detail.in_hand) - this.$h.myFloat(rough_detail.mn_pa_rough));

          this.total_rejection = rough_detail.total_rejection;
          this.total_lot_rejection = rough_detail.total_lot_rejection;
          console.log(this.unissued_rough_weight);
          let extra_buffer = 0;
          let remaining_rough = 0;
          let extra_remaning_buffer = 0;
          if(this.kapanForm.series_weight > 0){
            remaining_rough = this.$h.myFloat(rough_detail.in_hand) - this.$h.myFloat(rough_detail.mn_pa_rough);
            this.unissued_rough_weight = remaining_rough;
            extra_remaning_buffer = this.$h.myFloat(remaining_rough/100);
            this.unissued_rough_weight_buff = this.$h.myFloat(this.$h.myFloat(remaining_rough + extra_remaning_buffer));
            console.log(this.unissued_rough_weight_buff);
          }

          if(this.total_rejection > 0){
            this.total_rejection_per = Number(parseFloat(this.total_rejection)*100) / Number(parseFloat(this.kapanForm.series_weight)) ;
          }

          if(rough_detail.assortment_details != "Fresh" && rough_detail.assortment_details != null){
            this.assorts = rough_detail.assortment_details;
          }else{
            //
          }
          _this.pieWeight();
        })
        .catch(_error => {

        });
    },

    async submitFormAssort(){
      let _this = this;
      if(this.assortForm.assort_id != ''){
        if(this.$h.myFloat(this.assortForm.weight) > this.$h.myFloat(this.assortForm.old_weight)){
          this.$swal("Error!", "Assortment Weight can not be greater than old assortment weight, To increase assortment weight add new record.", "error");
          return;
        }
      }else{
         if(this.$h.myFloat(this.assortForm.weight) > this.$h.myFloat(this.in_hand_weight)){
          this.$swal("Error!", "Assortment Weight can not be greater than in hand weight", "error");
          return;
        }
      }

      this.assortForm.form_status = true;
      const data = {
        series_id: this.series_id,
        assort_type: this.assortForm.assort_type,
        total_pcs: this.assortForm.total_pcs,
        weight: this.assortForm.weight,
        old_weight: this.assortForm.old_weight,
        weight_per: this.assortForm.weight_per,
        result_weight: this.assortForm.result_weight,
        result_per: this.assortForm.result_per,
        id:this.assortForm.assort_id,
      };
      let url_post = '';

      if(this.assortForm.assort_id != ''){
        url_post = "api/edit_rough_assort"
        console.log(data);
        //return;
      }else{
         url_post = "api/add_rough_assort"
      }

      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {

          this.$router.go();
          this.assortForm.form_status = true;
        }, 500)
        })
        .catch((_error) => {
          return [];
        });
    },

    updateAssortment(){
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];

       if(this.assorts != null && this.assorts != ''){
                var i = this.assorts.length;
                while(i--){
                  var old_weight = 0;
                  var weight = 0;
                  if(this.assorts[i]['old_weight'] != ''){
                    old_weight = parseFloat(this.assorts[i]['old_weight']);
                    if(isNaN(old_weight)){
                      old_weight = 0;
                    }
                  }
                  if(this.assorts[i]['weight'] != ''){
                    weight = parseFloat(this.assorts[i]['weight']);
                    if(isNaN(weight)){
                      weight = 0;
                    }
                  }
                this.assorts[i]['old_weight'] = old_weight + weight;
                this.assorts[i]['weight'] = '';
              }
      }


      if (!this.errors.length) {
        const data = {
          series_id: this.series_id,
          assortment_details: this.assorts
        };

        if(this.series_id != ''){
          this.url = "api/update_series_assort"
        }else{
          this.url = "api/update_series_assort"
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {
            this.$swal("Success!", "Assortment Details updated", "success");
          })
          .catch(response => {

          });
      }

    },

    async submitFormKapan(){

      if(this.kapanForm.kapan_id != ''){
        let available_wt = parseFloat(this.kapanForm.kapan_weight_old) + this.$h.myFloat(this.unissued_rough_weight_buff);
         if(available_wt < this.$h.myFloat(this.kapanForm.kapan_weight)){
         this.$swal("Warning!", "Can not edit more weight than remaining rough weight", "warning");
          return;
       }
      }else{
        if(this.$h.myFloat(this.unissued_rough_weight_buff) < this.$h.myFloat(this.kapanForm.kapan_weight)){
         this.$swal("Warning!", "Can not issue more weight than remaining rough weight", "warning");
        return;
       }

      }
       

      if(this.$h.myFloat(this.kapanForm.kapan_weight) <= 0){
      this.$swal("Warning!", "Can not issue Zero weight", "warning");
      return;
      }

      if(this.kapanForm.kapan_type == ''){
          this.$swal("Warning!", "Please Select Kapan Type", "warning");
          return;
      }

      if(this.kapanForm.kapan_type == 2){
        if(this.kapanForm.kapan_purity == ''){
          this.$swal("Warning!", "Please Select Purity", "warning");
          return;
        }
      }

      if(this.kapanForm.kapan_total_pcs <= 0){
      this.$swal("Warning!", "Please Enter Total Pcs", "warning");
      return;
      }

      this.kapanForm.form_status = true;
      let _this = this;
      const data = {
        rough_lot_id: this.series_id,
        series_id: this.series_id,
        kapan_name: this.kapanForm.kapan_name,
        kapan_type: this.kapanForm.kapan_type,
        kapan_weight: this.kapanForm.kapan_weight,
        kapan_total_pcs: this.kapanForm.kapan_total_pcs,
        cleaver_id: this.kapanForm.cleaver_id,
        cleaver_issue_date: this.kapanForm.cleaver_issue_date,
        exp_mackable: this.kapanForm.exp_mackable,
        exp_polish: this.kapanForm.exp_polish,
        exp_value: this.kapanForm.exp_value,
        kapan_purity: this.kapanForm.kapan_purity,
        //kapan_sub_type: this.kapanForm.kapan_sub_type,
        id:this.kapanForm.kapan_id,
      };
      let url_post = '';
      if(this.kapanForm.kapan_id != ''){
        url_post = "api/edit_rough_kapan"
      }else{
         url_post = "api/add_rough_kapan"
      }

      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         this.$router.go();
        })
        .catch((_error) => {
          this.kapanForm.form_status = false;
          alert('Kapan Can not be edited! Kindly contact technical support.')
          return [];
        });
    },

    async submitFormPaBunch(){
      this.paForm.form_status = true;
       if(this.$h.myFloat(this.unissued_pa_rough_weight) < this.$h.myFloat(this.paForm.pa_bunch_weight)){
         this.$swal("Warning!", "Can not add more weight than remaining rough weight", "warning");
         this.paForm.form_status = false;
          return;
       }

      if(this.$h.myFloat(this.paForm.pa_bunch_weight) <= 0){
        this.$swal("Warning!", "Can not Create Bunch with zero weight", "warning");
        this.paForm.form_status = false;
        return;
      }

      if(this.paForm.pa_total_pcs <= 0){
        this.$swal("Warning!", "Please Enter Total Pcs", "warning");
        this.paForm.form_status = false;
        return;
      }

      let _this = this;
      const data = {
        series_id: this.series_id,
        bunch_weight: this.paForm.pa_bunch_weight,
        total_pcs: this.paForm.pa_total_pcs,
        id:this.paForm.bunch_id,
      };
      let url_post = '';
      if(this.paForm.bunch_id != ''){
        url_post = "api/edit_series_pa_bunch"
      }else{
         url_post = "api/add_series_pa_bunch"
      }

      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         this.$router.go();
         //this.paForm.form_status = false;
        })
        .catch((_error) => {
          this.paForm.form_status = false;
          return [];
        });
    },
    seriesManagerPrepare(){
      let _this = this;
      this.tabulatorkapan = new Tabulator(this.$refs['tableRefSeriesManager'], {
        ajaxURL: BASE_URL + '/api/list_rough_manager_wise_series',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{series_id:this.series_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Series No',
            field: 'series_code',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Manager Name',
            minWidth: 100,
            field: 'first_name',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().first_name + " " + cell.getData().last_name;

            }

          },

          {
            title: 'Weight(Original)',
            minWidth: 100,
            field: 'issue_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return Number(cell.getData().issue_weight);
            }
          },{
            title: 'Weight(Received)',
            field: 'total_received',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().total_received) + " Ct";
            }
          },{
            title: 'Weight(In Hand)',
            minWidth: 100,
            field: 'in_hand',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().in_hand) + " Ct";

            }
          },{
            title: 'Weight(In Process)',
            minWidth: 100,
            field: 'weight_in_process',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().weight_in_process) + " Ct";

            }
          },{
            title: 'Total Ghat',
            minWidth: 100,
            field: 'total_ghat',
            hozAlign: 'ledt',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            field: '',
            responsive: 1,
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().status == 1){
                return `<div class="flex ">
                    <p class="text-theme-9">Active</p>
                  </div>`;
              }else{
                return `<div class="flex">
                    <p class="text-theme-6">Transfered</p>
                  </div>`;
              }
            }
          },


        ],
        renderComplete() {

        }
      })
    },
    kapanPrepare(){
      let _this = this;
      this.tabulatorkapan = new Tabulator(this.$refs['tableRefKapan'], {
        ajaxURL: BASE_URL + '/api/list_rough_kapan',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{series_id:this.series_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Kapan No',
            field: 'kapan_code',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              _this.$router.push("/purchase/rough-view/kapan-detail/" + window.btoa(cell.getData().id));

          }
          },
          {
            title: 'Kapan Type',
            minWidth: 100,
            field: 'kapan_name',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
           {
            title: 'Pcs',
            minWidth: 100,
            field: 'kapan_total_pcs',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Weight(Original)',
            minWidth: 100,
            field: 'kapan_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              //return `<div>`+ Number(cell.getData().kapan_weight) + ` Ct<br>` + Number(cell.getData().total_lot_weight) + `Ct </div>`;
              return Number(cell.getData().kapan_weight);
            }
          },{
            title: 'Weight(Avl)',
            field: 'cr_avl_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().cr_avl_weight) + " Ct";
            }
          },{
            title: 'Weight(In Process)',
            minWidth: 100,
            field: 'weight_in_process',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().weight_in_process) + " Ct";

            }
          },{
            title: 'Est. Makeable ',
            minWidth: 100,
            field: 'exp_mackable',
            hozAlign: 'ledt',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Est. Polish ',
            minWidth: 100,
            field: 'exp_polish',
            hozAlign: 'ledt',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value($)',
            minWidth: 100,
            field: 'exp_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().exp_value) + " Ct";
            }
          },
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              const a = cash(`<div class="flex lg:justify-center items-center">
                <span class="flex items-center" >
                  <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                </span>
              </div>`)

              cash(a).on('click', function(data_id) {
                // On click actions
                _this.openEditKapan(cell.getData());
              })

              return a[0]
            }
          },


        ],
        renderComplete() {

        }
      })
    },

    openEditKapan(data){
      if(data != null){
        
        //this.cleaver_issue_id = data.cleaver_id;
        
        this.kapanForm.kapan_type = data.kapan_type + "";
        this.kapanForm.kapan_weight = data.kapan_weight;
        this.kapanForm.kapan_weight_old = data.kapan_weight;
        this.kapanForm.kapan_total_pcs = data.kapan_total_pcs;
        this.kapanForm.cleaver_id = data.cleaver_id;
        this.kapanForm.exp_mackable = data.exp_mackable;
        this.kapanForm.exp_polish = data.exp_polish;
        this.kapanForm.exp_value = data.exp_value;
        this.kapanForm.kapan_purity = data.kapan_purity + "";
        this.kapanForm.kapan_id = data.id;

        cash('#new-order-modal-kapan').modal('show');
      }
    },

    paBunchPrepare(){
      let _this = this;
      this.tabulatorkapan = new Tabulator(this.$refs['tableRefPaBunch'], {
        ajaxURL: BASE_URL + '/api/list_pa_bunch',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{series_id:this.series_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Bunch No',
            field: 'bunch_code',
            vertAlign: 'middle',
            print: false,
            download: true,

          }, {
            title: 'Weight(Original)',
            minWidth: 100,
            field: 'bunch_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().bunch_weight) + " Ct";
            }
          },
          {
            title: 'Weight(Avl)',
            minWidth: 100,
            field: 'cr_avl_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().cr_avl_weight) + " Ct";
            }
          },

           {
            title: 'Pcs',
            minWidth: 100,
            field: 'total_pcs',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Ghat',
            minWidth: 100,
            field: 'total_lose',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().total_lose) + " Ct";
            }
          },
          {
            title: 'Padiya',
            field: 'total_padiya',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().total_padiya) + " Ct";
            }
          },
          {
            title: 'Status',
            field: '',
            responsive: 1,
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().cr_status == 1){
                return `<div class="flex ">
                    <p class="text-theme-9">Active</p>
                  </div>`;
              }else{
                return `<div class="flex">
                    <p class="text-theme-6">Merged</p>
                  </div>`;
              }
            }
          },
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              if(_this.currentUser.id == cell.getData().created_by){
              if(cell.getData().cr_status == 1){
                const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-success btn-sm flex items-center mr-3">
                      Merge with series
                    </button>
                  </div>`)
                  cash(a).on('click', function() {
                    _this.openMergeForm(cell.getData());
                  })
                  return a[0]
              }
              }

            }
          },
        ],
        renderComplete() {

        }
      })
    },

    openMergeForm(data){
      if(data != null){
        console.log(data.id);
        this.paFormMerge.bunch_id = data.id;
        this.paFormMerge.pa_bunch_weight = data.cr_avl_weight;
        this.paFormMerge.pa_total_pcs = data.total_pcs;
        cash('#merge-order-modal-bunch').modal('show');
      }
    },

    clearPaFormMerge(){
      console.log("Clear");
      this.paFormMerge.bunch_id = "";
      this.paFormMerge.pa_bunch_weight = "";
      this.paFormMerge.pa_total_pcs = "";
    },

    submitFormPaBunchMerge(){
      let _this = this;
      this.$swal({
        title: "Are you sure?",
        text: "You are about to merge a bunch with main series weight!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Merge",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            const data = {
              bunch_id: this.paFormMerge.bunch_id,
              merge_weight: this.paFormMerge.pa_bunch_weight,
              total_pcs: this.paFormMerge.pa_total_pcs,
            };
          let url_post = '';
          if(this.paFormMerge.bunch_id != ''){
            url_post = "api/merge_series_pa_bunch"
          }else{
            url_post = "api/merge_series_pa_bunch"
          }

          let promise = axios({
            url: url_post,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } },
          });
          return promise
            .then((result) => result.data)
            .then((data) => {
              _this.paBunchPrepare();
              this.$router.go();
            })
            .catch((_error) => {
              this.paForm.form_status = false;
              return [];
            });

            }
        });

    },

    assortPrepare(){
      let _this = this;
      this.tabulator = new Tabulator(this.$refs['tableRef'], {
        ajaxURL: BASE_URL + '/api/list_rough_assort',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{series_id:this.series_id},
        dataTree:true,
        //dataTreeStartExpanded:true,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Assort Type',
            minWidth: 200,
            responsive: 0,
            field: 'assortment_name',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Assort No',
            field: 'assort_no',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Series No',
            field: 'series_no',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Weight',
            field: 'total_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().total_weight);
            }
          },
          {
            title: 'Weight(%)',
            field: 'weight_per',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Added At',
            field: 'created_at',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().assort_no != '-'){
                const data_id = cell.getData().id;
                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm flex items-center mr-3">
                    Edit
                  </button>
                </div>`)

                cash(a).on('click', function(data_id) {
                  // On click actions
                  _this.openEditAssort(cell.getData());

                })

                return a[0]

              }

            }
          },
        ],
        renderComplete() {

        }
      })
    },

    openEditAssort(data){
      if(data != null){
        this.assortForm.assort_type = ""+data.assort_type;
        this.assortForm.total_pcs = data.total_pcs;
        this.assortForm.weight = data.weight;
        this.assortForm.old_weight = data.weight;
        this.assortForm.weight_per = data.weight_per;
        this.assortForm.result_weight = data.result_weight;
        this.assortForm.result_per = data.result_per;
        this.assortForm.assort_id = data.id;
        this.assortForm.assort_type_lock = true;
        cash('#edit-order-modal-assort').modal('show');
      }
    },
    openAddAssort(){
        this.assortForm.assort_type = "";
        this.assortForm.assort_type_lock = false;
        this.assortForm.total_pcs = '';
        this.assortForm.weight = '';
        this.assortForm.old_weight = '';
        this.assortForm.weight_per = '';
        this.assortForm.result_weight = '';
        this.assortForm.result_per = '';
        this.assortForm.assort_id = '';
        cash('#new-order-modal-assort').modal('show');
    },

  },

})
</script>
