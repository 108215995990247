/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import NoAccess from "../views/errors/NoAccess.vue";
import ComingSoon from "../views/errors/ComingSoon.vue";
import SelectMasters from "../views/masters/SelectMasters.vue";
import ShapeMasters from "../views/masters/shape/ShapeMaster.vue";
import ColorMasters from "../views/masters/color/ColorMaster.vue";
import PurityMasters from "../views/masters/purity/PurityMaster.vue";
import StoneMasters from "../views/masters/stone/StoneMaster.vue";
import CutMasters from "../views/masters/cut/CutMaster.vue";
import SizeMasters from "../views/masters/size/SizeMaster.vue";
import FlourocentMasters from "../views/masters/flourocent/FlourocentMaster.vue";
import CharniMasters from "../views/masters/charni/CharniMaster.vue";
import CharniMastersPlanning from "../views/masters/charni-planning/CharniMaster.vue";
import CharniMastersSelling from "../views/masters/charni-selling/CharniMaster.vue";
import PuritySizeMaster from "../views/masters/charni-selling/PuritySize.vue";
import NaturalMasterSizePolish from "../views/masters/size-polish/Natural.vue";
import CVDMasterSizePolish from "../views/masters/size-polish/CVD.vue";
import AssortMasters from "../views/masters/assort/AssortMaster.vue";
import RapaportRate from "../views/masters/rate-masters/rapaport-rate/RapaportRate.vue";
import StanderedRates from "../views/masters/rate-masters/standered-rate/StandaredRate.vue";
import StanderedRatesCVD from "../views/masters/rate-masters/standered-rate/StandaredRateCVD.vue";
import StanderedRatesCerti from "../views/masters/rate-masters/standered-rate/StanderedRatesCerti.vue";
import BonusRateCard from "../views/masters/rate-masters/bonus/BonusRateCard.vue";
import PenaltyRateCard from "../views/masters/rate-masters/penalty/PenaltyRateCard.vue";
import GhatMasters from "../views/masters/other-masters/ghat-master/GhatMaster.vue";
import ReasonMasters from "../views/masters/other-masters/reason-master/ReasonMaster.vue";
import ProcessMasters from "../views/masters/other-masters/process-master/ProcessMaster.vue";
import CreateProcess from "../views/masters/other-masters/process-master/CreateProcess.vue";

import PacketList from "../views/rough-purchase/PacketList.vue";
import PacketListPlanner from "../views/rough-purchase/PacketListPlanner.vue";

import BuyerAccountMasters from "../views/masters/accounts-master/buyer-account/BuyerAccountMaster.vue";
import CreateBuyerAccount from "../views/masters/accounts-master/buyer-account/CreateBuyerAccount.vue";
import ViewBuyerAccount from "../views/masters/accounts-master/buyer-account/ViewBuyerAccount.vue";

import PartyAccountMasters from "../views/masters/accounts-master/party-account/PartyAccountMaster.vue";
import CreatePartyAccount from "../views/masters/accounts-master/party-account/CreatePartyAccount.vue";
import BrokerAccountMasters from "../views/masters/accounts-master/broker-account/BrokerAccountMaster.vue";
import CreateBrokerAccount from "../views/masters/accounts-master/broker-account/CreateBrokerAccount.vue";
import PolishPartyAccountMasters from "../views/masters/accounts-master/polish-party-account/PartyAccountMaster.vue";
import CreatePolishPartyAccount from "../views/masters/accounts-master/polish-party-account/CreatePartyAccount.vue";
import ProcessPartyAccountMasters from "../views/masters/accounts-master/process-party-account/PartyAccountMaster.vue";
import CreateProcessPartyAccount from "../views/masters/accounts-master/process-party-account/CreatePartyAccount.vue";

import TenderAdminDashboard from "../views/dashboards/tender-dashboards/admin-dashboard/Main.vue";
import TenderPacketReport from "../views/reports/tender/tender-packet/Main.vue";
import TenderTopBidReport from "../views/reports/tender/tender-top-bid/Main.vue";
import TenderWinnerReport from "../views/reports/tender/tender-winners/Main.vue";
import TenderItemIssueStatus from "../views/reports/tender/tender-item-status/Main.vue";
import TenderBuyerIssueStatus from "../views/reports/tender/tender-buyer-status/Main.vue";

import ManagerDashboard from "../views/dashboards/tender-dashboards/admin-dashboard/Main.vue";
import MenualManagerDashboard from "../views/dashboards/tender-dashboards/menual-manager-dashboard/Main.vue";
import MenualGhutanManagerDashboard from "../views/dashboards/tender-dashboards/ghutan-manager-dashboard/Main.vue";
import PlannerDashboard from "../views/dashboards/tender-dashboards/planner-dashboard/Main.vue";

import GhutanReport from "../views/reports/process/ghutan/Main.vue";
import GhutanSummaryReport from "../views/reports/process/ghutan-summary/Main.vue";
import PALSReport from "../views/reports/process/pa-ls-report/Main.vue";
import PAOperatorReport from "../views/reports/process/ls-operator-report/Main.vue";
import PALSLotReport from "../views/reports/process/pals-lot-report/Main.vue";
import GhutanLotReport from "../views/reports/process/ghutan-lot-report/Main.vue";
import ChabkaLotReport from "../views/reports/process/chabka-lot-report/Main.vue";
import ChabkaReport from "../views/reports/process/chabka-report/Main.vue";
import LSOtherReport from "../views/reports/process/ls_other_report/Main.vue";
import ProcessReport from "../views/reports/process/process-report/Main.vue";
import RecutReport from "../views/reports/process/recut-report/Main.vue";
import PALSSummaryReport from "../views/reports/process/pa-ls-summary/Main.vue";
import ManualSummaryReport from "../views/reports/kapan/manual-summary-report/Main.vue";
import KapanPacketHistoryReport from "../views/reports/kapan/packet-history/Main.vue";
import PacketPlanningRate from "../views/reports/kapan/packet-planning-rate/Main.vue";
import PacketUserReport from "../views/reports/kapan/packet-user-report/Main.vue";
import ManualKapanPcsReport from "../views/reports/kapan/manual-kapan-pcs/Main.vue";
import GalaxyTransferReport from "../views/reports/kapan/galaxy-transfer-kapan/Main.vue";
import StockManagerReport from "../views/reports/kapan/stock-manager-process-report/Main.vue";
import LaserDasaSeriesReport from "../views/reports/kapan/laser-dasa-series/Main.vue";
import ProcessHistory from "../views/reports/process/history-summary/Main.vue";
import ProcessLoseReport from "../views/reports/process/process-lose-report/Main.vue";
import XxxSummarySizeWise from "../views/reports/kapan/xxx-size-wise/Main.vue";
import XxxSummarySizeWisePolish from "../views/reports/kapan/xxx-size-wise-polish-summary/Main.vue";
import XxxSummarySizeShapeWisePolish from "../views/reports/kapan/xxx-size-shape-polish-summary/Main.vue";
import XxxSummarySizeShapeGroupWisePolish from "../views/reports/kapan/xxx-size-shape-group-polish-summary/Main.vue";
import XxxSummarySizePurityWise from "../views/reports/kapan/xxx-size-purity-wise/Main.vue";
import XxxSummarySizePurityWiseNew from "../views/reports/kapan/xxx-size-purity-wise-new/Main.vue";
import FullDetailSummary from "../views/reports/full-detail-report/Main.vue";
import XxxSummaryPolishSizePurityWise from "../views/reports/kapan/xxx-polish-size-purity-wise/Main.vue";
import XxxSummaryPurityPolishSizePurityWise from "../views/reports/kapan/xxx-size-purity-polish-summary-new/Main.vue";
import PacketProcessCompare from "../views/reports/kapan/packet-process-compare/Main.vue";
import RoughCurrentStatus from "../views/reports/kapan/rough-current-status/Main.vue";
import XxxSummaryPacketStatus from "../views/reports/kapan/xxx-packet-status-summary/Main.vue";
import PlannerRateReport from "../views/reports/planner/planner-rate-report/Main.vue";
import PlannerRateSummaryReport from "../views/reports/planner/planner-rate-report/Summary.vue";
import BlockingReport from "../views/reports/process/blocking-report/Main.vue";
import BlockingSummaryReport from "../views/reports/process/blocking-summary-report/Main.vue";
import LABReport from "../views/reports/process/lab/stock.vue";
import LABSummaryReport from "../views/reports/process/lab/summary.vue";
import FinalPolishNonCertySellingReport from "../views/reports/polish-merge/selling-report/non-certy-selling.vue";
import FinalPolishCertySellingReport from "../views/reports/polish-merge/selling-report/certy-selling.vue";
import FinalCertyStockReport from "../views/reports/polish-merge/certy-stock-report/Main.vue";
import FinalPolishNonCertyReport from "../views/reports/polish-merge/non-certy-report/Main.vue";
import FinalPolishNonCertyReportNew from "../views/reports/polish-merge/non-certy-report/MainNew.vue";
import FinalPolishNonCertyReportOld from "../views/reports/polish-merge/non-certy-report/MainOld.vue";
import RecutSummaryReport from "../views/reports/process/recut-summary-report/Main.vue";
import ProcessMajuriReport from "../views/reports/process/process-majuri-report/Main.vue";
import PolishReport from "../views/reports/process/polish-report/Main.vue";
import PolishSummaryReport from "../views/reports/process/polish-summary-report/Main.vue";
import PolishComparisonReport from "../views/reports/process/polish-comparison-report/Main.vue";
import PolishRateReport from "../views/reports/process/polish-rate-report/Main.vue";
import PolishCheckSummaryReport from "../views/reports/process/polish_check_summary/Main.vue";
import FinalPolishSummaryReport from "../views/reports/process/final_polish_summary/Main.vue";
import Report4P from "../views/reports/process/4p-report/Main.vue";
import Report4PSummary from "../views/reports/process/4p-summary-report/Main.vue";
import ManagerStockReport from "../views/reports/process/rough-manager-stock-report/Main.vue";
import OutProcessReport from "../views/reports/process/out-process-report/Main.vue";
import DepartmentJobDone from "../views/reports/process/department-job-done-report/Main.vue";
import ManagerLatePacketReport from "../views/reports/process/process-late-days-report/Main.vue";
import AdminLatePacketReport from "../views/reports/process/process-late-days-report/Admin.vue";
import ProcessStockReport from "../views/reports/process/process-stock-report/Main.vue";
import GalaxyOperatorStockReport from "../views/reports/process/galaxy-operator-summary-report/Main.vue";
import SeriesSummaryReport from "../views/reports/process/series-summary-report/Main.vue";
import PolishReadyMergeStock from "../views/reports/polish-merge/stock-report/Main.vue";
import MergeStockReport from "../views/reports/polish-merge/merge-stock-report/Main.vue";
import FinalPolishStockComparison from "../views/reports/polish-merge/comparison-report/Main.vue";
import FinalPolishCertyComparison from "../views/reports/polish-merge/certy-comparison/Main.vue";

//Selling Module
import ReadyStockSeriesWise from "../views/polish-ready/ready-stock/SeriesWiseList.vue";
import AvailableStock from "../views/polish-ready/ready-stock/AvailableStock.vue";
import SellingMaster from "../views/polish-ready/ready-stock/sellingMaster.vue";
import CreateChalan from "../views/party-chalan/create.vue";
import CreateChalanDetail from "../views/party-chalan/detail.vue";
import ChalanList from "../views/party-chalan/Main.vue";
import MergeStock from "../views/polish-ready/ready-stock/MergeStock.vue";
import MergeStockNew from "../views/polish-ready/ready-stock-new/MergeStockNew.vue";
import MergeStockNewUpdate from "../views/polish-ready/ready-stock-new/MergeStockNewUpdate.vue";
import MergeTransactionView from "../views/polish-ready/ready-stock-new/MergeTransaction.vue";
import MergeTransactionSingleView from "../views/polish-ready/ready-stock-new/MergeTransactionSingle.vue";
import MCharniSellingRate from "../views/masters/rate-masters/charni-selling-rate/Main.vue";
import MCharniSellingRateCreate from "../views/masters/rate-masters/charni-selling-rate/create.vue";
import CertyPacketList from "../views/polish-ready/certy-packet-list/Main.vue";
import CertyMultiIssue from "../views/polish-ready/issue/MultiIssue.vue";
import CertyMultiReceive from "../views/polish-ready/receive/MultiReceive.vue";
import CertyExcelReceive from "../views/polish-ready/receive/ExcelReceive.vue";
import LabStock from "../views/polish-ready/ready-stock/LabStock.vue";
import CertyLotList from "../views/polish-ready/certy-packet-list/Main.vue";
import CertyLotListPending from "../views/polish-ready/pending-stock/Certy.vue";
import NonCertyLotListPending from "../views/polish-ready/pending-stock/NonCerty.vue";
import MergeToCertyLotList from "../views/polish-ready/add-certy-merge/Main.vue";


import TenderMasters from "../views/masters/tenders/TenderMaster.vue";
import TendersIssuenoteMaster from "../views/masters/tenders/TendersIssuenoteMaster.vue";
import TendersIssueReceive from "../views/masters/tenders/TendersIssueReceive.vue";
import InviteBuyer from "../views/masters/tenders/InviteBuyer.vue";
import SelectBuyers from "../views/masters/tenders/SelectBuyers.vue";
import WinnerList from "../views/masters/tenders/WinnerList.vue";
import CreateTender from "../views/masters/tenders/CreateTender.vue";
import ViewTender from "../views/masters/tenders/ViewTender.vue";
import ListInvitedBuyers from "../views/masters/tenders/ListInvitedBuyers.vue";
import InvitedBuyerBid from "../views/masters/tenders/InvitedBuyerBid.vue";

import TenderBuyerAccountMaster from "../views/masters/tender-buyer-account/TenderBuyerAccountMaster.vue";
import TenderCreateBuyerAccount from "../views/masters/tender-buyer-account/TenderCreateBuyerAccount.vue";
import TenderBuyerAccountView from "../views/masters/tender-buyer-account/TenderBuyerAccountView.vue";

import ManufacturerAccountMasters from "../views/masters/accounts-master/manufacturer-account/ManufacturerAccountMaster.vue";
import CreateManufacturerAccount from "../views/masters/accounts-master/manufacturer-account/CreateManufacturerAccount.vue";

import OperatorAccountMasters from "../views/masters/accounts-master/operator-account/OperatorAccountMaster.vue";
import CreateOperatorAccount from "../views/masters/accounts-master/operator-account/CreateOperatorAccount.vue";
import ViewOperatorAccount from "../views/masters/accounts-master/operator-account/ViewOperatorAccount.vue";

import OperatorTypeMasters from "../views/masters/accounts-master/manager-type/ManagerTypeMaster.vue";
import CreateOperatorType from "../views/masters/accounts-master/manager-type/CreateManagerType.vue";

import PlannerAccountMasters from "../views/masters/accounts-master/planner-account/PlannerAccountMaster.vue";
import CreatePlannerAccount from "../views/masters/accounts-master/planner-account/CreatePlannerAccount.vue";
import ViewPlannerAccount from "../views/masters/accounts-master/planner-account/ViewPlannerAccount.vue";

import CleaverAccountMasters from "../views/masters/accounts-master/cleaver-account/CleaverAccountMaster.vue";
import CreateCleaverAccount from "../views/masters/accounts-master/cleaver-account/CreateCleaverAccount.vue";
import ViewCleaverAccount from "../views/masters/accounts-master/cleaver-account/ViewCleaverAccount.vue";

import EmployeAccountMasters from "../views/masters/accounts-master/employe-account/EmployeAccountMaster.vue";
import CreateEmployeAccount from "../views/masters/accounts-master/employe-account/CreateEmployeAccount.vue";
import ViewEmployeAccount from "../views/masters/accounts-master/employe-account/ViewEmployeAccount.vue";

//PRocess
import SelectProcess from "../views/processes/SelectProcess.vue";

import KapanIssueMaster from "../views/processes/kapan/issue/IssueMaster.vue";
import CreateKapanIssue from "../views/processes/kapan/issue/CreateIssue.vue";
import TransactionView from "../views/processes/kapan/issue/TransactionView.vue";

import KapanReceiveMaster from "../views/processes/kapan/receive/ReceiveMaster.vue";
import CreateKapanReceive from "../views/processes/kapan/receive/CreateReceive.vue";


import PacketIssueMaster from "../views/processes/packets/issue/IssueMaster.vue";
import LotKapanIssue from "../views/processes/packets/issue/CreateIssue.vue";
import LotTransactionView from "../views/processes/packets/issue/TransactionView.vue";
import LotKapanIssuePolish from "../views/processes/packets/issue/IssuePolish.vue";
import LotKapanIssueRadiation from "../views/processes/packets/issue/IssueRadiation.vue";
import LotKapanIssueLab from "../views/processes/packets/issue/IssueLab.vue";
import LotKapanReceiveLab from "../views/processes/packets/receive/LabReceive.vue";
import LotKapanReceiveLabUpdate from "../views/processes/packets/receive/LabReceiveUpdate.vue";
import LotBulkTransfer from "../views/processes/packets/issue/BulkTransfer.vue";
import NonCertyFinalReceive from "../views/processes/packets/direct-polish/NonCertyReceive.vue";
import NonCertyFinalReceiveUpdate from "../views/processes/packets/direct-polish/NonCertyReceiveUpdate.vue";
import CertyFinalReceive from "../views/processes/packets/direct-polish/CertyReceive.vue";

import PacketReceiveMaster from "../views/processes/packets/receive/ReceiveMaster.vue";
import LotKapanReceive from "../views/processes/packets/receive/CreateReceive.vue";
import LotReceiveRecut from "../views/processes/packets/receive/CreateReceiveRecut.vue";
import BlockingReceive from "../views/processes/packets/receive/BlockingReceive.vue";
import MultiReceive from "../views/processes/packets/receive/MultiReceive.vue";
import PolishReceiveParty from "../views/processes/packets/receive/PolishReceiveParty.vue";
import PolishReceiveChecker from "../views/processes/packets/receive/PolishReceiveCheck.vue";
import PolishReceiveRadiation from "../views/processes/packets/receive/PolishReceiveRadiation.vue";
import RoughToPolishFullReceive from "../views/processes/packets/receive/RoughToPolish.vue";
import UpdateCertyRate from "../views/processes/packets/receive/UpdateCertyRate.vue";


import SimpleMenu from "../layouts/simple-menu/Main.vue";
import TopMenu from "../layouts/top-menu/Main.vue";
import RoughMaster from "../views/rough-purchase/RoughList.vue";

import RoughMasterStockManager from "../views/rough-purchase/RoughListStockManager.vue";
import RoughListGalaxyManager from "../views/rough-purchase/RoughListGalaxyManager.vue";

import RoughView from "../views/rough-purchase/RoughView.vue";
import RoughViewStockManager from "../views/rough-purchase/RoughViewStockManager.vue";
import RoughSeriesList from "../views/rough-purchase/RoughSeriesList.vue";
import RoughViewGalaxyManager from "../views/rough-purchase/RoughViewGalaxyManager.vue";

import KapanView from "../views/rough-purchase/KapanView.vue";
import SeriesView from "../views/rough-purchase/SeriesView.vue";
import PacketView from "../views/rough-purchase/PacketView.vue";

// Blocked Packet View
import BlockedPacketView from "../views/blocked-packet-view/Main.vue";


import RoughPurchase from "../views/rough-purchase/Main.vue";
import VendorList from "../views/masters/accounts-master/rough-vendors/VendorList.vue";
import RoughVendors from "../views/masters/accounts-master/rough-vendors/Main.vue";
import RoughVendorsView from "../views/masters/accounts-master/rough-vendors/VendorView.vue";

import SellingPartyList from "../views/masters/accounts-master/selling-party/PartyList.vue";
import RoughSellingParty from "../views/masters/accounts-master/selling-party/Main.vue";
import RoughSellingPartyView from "../views/masters/accounts-master/selling-party/PartyView.vue";
import PurchasePartyList from "../views/masters/accounts-master/purchase-polish-party/PartyList.vue";
import RoughPurchaseParty from "../views/masters/accounts-master/purchase-polish-party/Main.vue";
import RoughPurchasePartyView from "../views/masters/accounts-master/selling-party/PartyView.vue";
import BranchOfficeList from "../views/masters/accounts-master/branch-account/PartyList.vue";
import RoughBranchOffice from "../views/masters/accounts-master/branch-account/Main.vue";
import RoughBranchOfficeView from "../views/masters/accounts-master/branch-account/PartyView.vue";
import BrokerList from "../views/masters/accounts-master/broker-account/PartyList.vue";
import RoughBroker from "../views/masters/accounts-master/broker-account/Main.vue";
import RoughBrokerView from "../views/masters/accounts-master/broker-account/PartyView.vue";
import PurchaseBrokerList from "../views/masters/accounts-master/broker-account-purchase/PartyList.vue";
import RoughPurchaseBroker from "../views/masters/accounts-master/broker-account-purchase/Main.vue";
import RoughPurchaseBrokerView from "../views/masters/accounts-master/broker-account-purchase/PartyView.vue";

// Final Polish Transfer Btanch
import NonCertyBranchTransfer from "../views/polish-ready/branch-transfer/NonCertyTransfer.vue";
import CertyBranchTransfer from "../views/polish-ready/branch-transfer/CertyTransfer.vue";
import TransferTransactions from "../views/polish-ready/branch-transfer/TransferList.vue";
import TransferSingleTransaction from "../views/polish-ready/branch-transfer/SingleTransaction.vue";
import CertyBranchStock from "../views/polish-ready/branch-stock/Certy.vue";
import NonCertyBranchStock from "../views/polish-ready/branch-stock/NonCerty.vue";

// Reset Certy Stock
import CertyResetRequestList from "../views/polish-ready/certy-reset/list.vue";
import CertyResetRequestSingle from "../views/polish-ready/certy-reset/single.vue";
import CertyResetRequest from "../views/polish-ready/certy-reset/Main.vue";


// Final Polish Transfer Btanch
import NonCertySaleTransfer from "../views/polish-ready/sell-transfer/NonCertyTransfer.vue";
import CertySaleTransfer from "../views/polish-ready/sell-transfer/CertyTransfer.vue";
import EditNonCertySaleTransfer from "../views/polish-ready/sell-transfer/NonCertyTransfer.vue";
import EditCertySaleTransfer from "../views/polish-ready/sell-transfer/CertyTransfer.vue";
import SaleTransactions from "../views/polish-ready/sell-transfer/TransferList.vue";
import RoughSellingNonCertyStock from "../views/polish-ready/sell-transfer/TransferListStock.vue";
import SaleSingleTransaction from "../views/polish-ready/sell-transfer/SingleTransaction.vue";
import FinalPolishSellingReport from "../views/reports/selling-module/selling_report.vue";

// Edit Stock
import NewLooseEdit from "../views/polish-ready/edit-stocks/create.vue";
import LooseEditDetail from "../views/polish-ready/edit-stocks/detail.vue";
import LooseEditList from "../views/polish-ready/edit-stocks/Main.vue";
import FinalPolishEditStock from "../views/requests/stock-request.vue";
import FinalPolishEditStockView from "../views/requests/stock-request-detail.vue";
import SellPolishEditStock from "../views/requests/sell-request.vue";
import SellPolishEditStockView from "../views/requests/sell-request-detail.vue";

// Final Polish Transfer Btanch
import NonCertyPolishPurchase from "../views/polish-ready/polish-purchase/NonCertyTransfer.vue";
import NonCertyUpdate from "../views/polish-ready/polish-purchase/NonCertyUpdate.vue";
import CertyPolishPurchase from "../views/polish-ready/polish-purchase/CertyTransfer.vue";
import PolishPurchaseTransactions from "../views/polish-ready/polish-purchase/TransferList.vue";
import PolishPurchaseSingleTransaction from "../views/polish-ready/polish-purchase/SingleTransaction.vue";

//Sitefront stock module
import NaturalStockDk from "../views/dksonss-stock/natural/NaturalStock.vue";
import CVDStockDk from "../views/dksonss-stock/cvd/CVDStock.vue";

import RoughSorting from "../views/rough-sorting/Main.vue";
import DashboardOverview1 from "../views/dashboard-overview-1/Main.vue";
import DashboardOverview2 from "../views/dashboard-overview-2/Main.vue";
import Inbox from "../views/inbox/Main.vue";
import FileManager from "../views/file-manager/Main.vue";
import PointOfSale from "../views/point-of-sale/Main.vue";
import Chat from "../views/chat/Main.vue";
import Post from "../views/post/Main.vue";
import Calendar from "../views/calendar/Main.vue";
import CrudDataList from "../views/crud-data-list/Main.vue";
import CrudForm from "../views/crud-form/Main.vue";
import UsersLayout1 from "../views/users-layout-1/Main.vue";
import UsersLayout2 from "../views/users-layout-2/Main.vue";
import UsersLayout3 from "../views/users-layout-3/Main.vue";
import ProfileOverview1 from "../views/profile-overview-1/Main.vue";
import ProfileOverview2 from "../views/profile-overview-2/Main.vue";
import ProfileOverview3 from "../views/profile-overview-3/Main.vue";
import WizardLayout1 from "../views/wizard-layout-1/Main.vue";
import WizardLayout2 from "../views/wizard-layout-2/Main.vue";
import WizardLayout3 from "../views/wizard-layout-3/Main.vue";
import BlogLayout1 from "../views/blog-layout-1/Main.vue";
import BlogLayout2 from "../views/blog-layout-2/Main.vue";
import BlogLayout3 from "../views/blog-layout-3/Main.vue";
import PricingLayout1 from "../views/pricing-layout-1/Main.vue";
import PricingLayout2 from "../views/pricing-layout-2/Main.vue";
import InvoiceLayout1 from "../views/invoice-layout-1/Main.vue";
import InvoiceLayout2 from "../views/invoice-layout-2/Main.vue";
import FaqLayout1 from "../views/faq-layout-1/Main.vue";
import FaqLayout2 from "../views/faq-layout-2/Main.vue";
import FaqLayout3 from "../views/faq-layout-3/Main.vue";
import Login from "../views/login/Main.vue";
import Register from "../views/register/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import UpdateProfile from "../views/update-profile/Main.vue";
import ChangePassword from "../views/change-password/Main.vue";
import RegularTable from "../views/regular-table/Main.vue";
import Tabulator from "../views/tabulator/Main.vue";
import Accordion from "../views/accordion/Main.vue";
import Button from "../views/button/Main.vue";
import Modal from "../views/modal/Main.vue";
import SlideOver from "../views/slide-over/Main.vue";
import Notification from "../views/notification/Main.vue";
import Alert from "../views/alert/Main.vue";
import ProgressBar from "../views/progress-bar/Main.vue";
import Tooltip from "../views/tooltip/Main.vue";
import Dropdown from "../views/dropdown/Main.vue";
import Typography from "../views/typography/Main.vue";
import Icon from "../views/icon/Main.vue";
import LoadingIcon from "../views/loading-icon/Main.vue";
import RegularForm from "../views/regular-form/Main.vue";
import Datepicker from "../views/datepicker/Main.vue";
import FileUpload from "../views/file-upload/Main.vue";
import WysiwygEditor from "../views/wysiwyg-editor/Main.vue";
import Validation from "../views/validation/Main.vue";
import Chart from "../views/chart/Main.vue";
import Slider from "../views/slider/Main.vue";
import ImageZoom from "../views/image-zoom/Main.vue";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
  {
    path: "/",
    redirect: '/app/dashboards',
    beforeEnter: AuthRequired,
    component: SideMenu,
    children: [
    {
      path: "/app/dashboards",
      name: "manager-dashboard",
      component: ManagerDashboard
    },
    {
      path: "/app/menual-manager-dashboards",
      name: "menual-manager-dashboard",
      component: MenualManagerDashboard
    },
    {
      path: "/app/menual-ghutan-manager-dashboards",
      name: "menual-ghutan-manager-dashboard",
      component: MenualGhutanManagerDashboard
    },
    {
      path: "/app/dashboard",
      name: "planner-dashboard",
      component: PlannerDashboard
    },
    {
      path: "/app/operater-dashboards",
      name: "operater-dashboard",
      component: ComingSoon
    },
    {
      path: "inbox",
      name: "side-menu-inbox",
      component: Inbox
    },
    {
      path: "file-manager",
      name: "side-menu-file-manager",
      component: FileManager
    },
    {
      path: "point-of-sale",
      name: "side-menu-point-of-sale",
      component: PointOfSale
    },
    {
      path: "chat",
      name: "side-menu-chat",
      component: Chat
    },
    {
      path: "post",
      name: "side-menu-post",
      component: Post
    },
    {
      path: "calendar",
      name: "side-menu-calendar",
      component: Calendar
    },
    {
      path: "crud-data-list",
      name: "side-menu-crud-data-list",
      component: CrudDataList
    },
    {
      path: "crud-form",
      name: "side-menu-crud-form",
      component: CrudForm
    },
    {
      path: "users-layout-1",
      name: "side-menu-users-layout-1",
      component: UsersLayout1
    },
    {
      path: "users-layout-2",
      name: "side-menu-users-layout-2",
      component: UsersLayout2
    },
    {
      path: "users-layout-3",
      name: "side-menu-users-layout-3",
      component: UsersLayout3
    },
    {
      path: "profile-overview-1",
      name: "side-menu-profile-overview-1",
      component: ProfileOverview1
    },
    {
      path: "profile-overview-2",
      name: "side-menu-profile-overview-2",
      component: ProfileOverview2
    },
    {
      path: "profile-overview-3",
      name: "side-menu-profile-overview-3",
      component: ProfileOverview3
    },
    {
      path: "wizard-layout-1",
      name: "side-menu-wizard-layout-1",
      component: WizardLayout1
    },
    {
      path: "wizard-layout-2",
      name: "side-menu-wizard-layout-2",
      component: WizardLayout2
    },
    {
      path: "wizard-layout-3",
      name: "side-menu-wizard-layout-3",
      component: WizardLayout3
    },
    {
      path: "blog-layout-1",
      name: "side-menu-blog-layout-1",
      component: BlogLayout1
    },
    {
      path: "blog-layout-2",
      name: "side-menu-blog-layout-2",
      component: BlogLayout2
    },
    {
      path: "blog-layout-3",
      name: "side-menu-blog-layout-3",
      component: BlogLayout3
    },
    {
      path: "pricing-layout-1",
      name: "side-menu-pricing-layout-1",
      component: PricingLayout1
    },
    {
      path: "pricing-layout-2",
      name: "side-menu-pricing-layout-2",
      component: PricingLayout2
    },
    {
      path: "invoice-layout-1",
      name: "side-menu-invoice-layout-1",
      component: InvoiceLayout1
    },
    {
      path: "invoice-layout-2",
      name: "side-menu-invoice-layout-2",
      component: InvoiceLayout2
    },
    {
      path: "faq-layout-1",
      name: "side-menu-faq-layout-1",
      component: FaqLayout1
    },
    {
      path: "faq-layout-2",
      name: "side-menu-faq-layout-2",
      component: FaqLayout2
    },
    {
      path: "faq-layout-3",
      name: "side-menu-faq-layout-3",
      component: FaqLayout3
    },
    {
      path: "update-profile",
      name: "side-menu-update-profile",
      component: UpdateProfile
    },
    {
      path: "change-password",
      name: "side-menu-change-password",
      component: ChangePassword
    },
    {
      path: "regular-table",
      name: "side-menu-regular-table",
      component: RegularTable
    },
    {
      path: "tabulator",
      name: "side-menu-tabulator",
      component: Tabulator
    },
    {
      path: "accordion",
      name: "side-menu-accordion",
      component: Accordion
    },
    {
      path: "button",
      name: "side-menu-button",
      component: Button
    },
    {
      path: "modal",
      name: "side-menu-modal",
      component: Modal
    },
    {
      path: "slide-over",
      name: "side-menu-slide-over",
      component: SlideOver
    },
    {
      path: "notification",
      name: "side-menu-notification",
      component: Notification
    },
    {
      path: "alert",
      name: "side-menu-alert",
      component: Alert
    },
    {
      path: "progress-bar",
      name: "side-menu-progress-bar",
      component: ProgressBar
    },
    {
      path: "tooltip",
      name: "side-menu-tooltip",
      component: Tooltip
    },
    {
      path: "dropdown",
      name: "side-menu-dropdown",
      component: Dropdown
    },
    {
      path: "typography",
      name: "side-menu-typography",
      component: Typography
    },
    {
      path: "icon",
      name: "side-menu-icon",
      component: Icon
    },
    {
      path: "loading-icon",
      name: "side-menu-loading-icon",
      component: LoadingIcon
    },
    {
      path: "regular-form",
      name: "side-menu-regular-form",
      component: RegularForm
    },
    {
      path: "datepicker",
      name: "side-menu-datepicker",
      component: Datepicker
    },

    {
      path: "file-upload",
      name: "side-menu-file-upload",
      component: FileUpload
    },
    {
      path: "wysiwyg-editor",
      name: "side-menu-wysiwyg-editor",
      component: WysiwygEditor
    },
    {
      path: "validation",
      name: "side-menu-validation",
      component: Validation
    },
    {
      path: "chart",
      name: "side-menu-chart",
      component: Chart
    },
    {
      path: "slider",
      name: "side-menu-slider",
      component: Slider
    },
    {
      path: "image-zoom",
      name: "side-menu-image-zoom",
      component: ImageZoom
    }
    ]
  },
  {
    path: "/masters",
    redirect: '/app/masters',
    beforeEnter: AuthRequired,
    component: SideMenu,
    children: [
      {
        path: "/app/errors/noaccess",
        name: "noaccess",
        component: NoAccess
      },
      {
        path: "/app/app-masters",
        name: "app-masters",
        component: SelectMasters
      },{
        path: "/app/app-process",
        name: "app-process",
        component: SelectProcess
      },
      {
        path: "/app/app-process/kapan/issue",
        name: "issue-kapan-master",
        component: KapanIssueMaster
      },{
        path: "/app/app-process/kapan/issue/add",
        name: "add-issue-transaction",
        component: CreateKapanIssue
      },{
        path: "/app/app-process/kapan/issuetransaction/:trans_id",
        name: "view-issue-transaction",
        props: true,
        component: TransactionView
      },
      {
        path: "/app/app-process/kapan/receive/add",
        name: "add-receive-transaction",
        component: CreateKapanReceive
      },
      {
        path: "/app/app-process/kapan/receive",
        name: "receive-kapan-master",
        component: KapanReceiveMaster
      },
      {
        path: "/app/app-process/packet/issue",
        name: "issue-packet-master",
        component: PacketIssueMaster
      },
      {
        path: "/app/app-process/packet/issue/add",
        name: "add-issue-packet",
        component: LotKapanIssue
      },
      {
        path: "/app/app-process/packet/bulk/issue/add",
        name: "packet-bulk-transfer",
        component: LotBulkTransfer
      },
      {
        path: "/app/app-process/packet/issue/polish-issue",
        name: "add-issue-packet-polish",
        component: LotKapanIssuePolish
      },
      {
        path: "/app/app-process/packet/issue/radiation-issue",
        name: "add-issue-packet-radiation",
        component: LotKapanIssueRadiation
      },
      {
        path: "/app/app-process/packet/issue/lab-issue",
        name: "add-issue-packet-lab",
        component: LotKapanIssueLab
      },
      {
        path: "/app/app-process/packet/receive/lab-receive",
        name: "add-receive-packet-lab",
        component: LotKapanReceiveLab
      },
      {
        path: "/app/app-process/packet/receive/lab-receive/update",
        name: "add-receive-packet-lab-update",
        component: LotKapanReceiveLabUpdate
      },
      {
        path: "/app/app-process/packet/receive/final-non-certy/receive",
        name: "final-polish-non-certy-direct-receive",
        component: NonCertyFinalReceive
      },
      {
        path: "/app/app-process/packet/receive/final-non-certy/receive/:id",
        name: "final-polish-non-certy-direct-receive-update",
        component: NonCertyFinalReceiveUpdate
      },
      {
        path: "/app/app-process/packet/receive/final-certy/receive",
        name: "final-polish-certy-direct-receive",
        component: CertyFinalReceive
      },


      {
        path: "/app/app-process/packet/receive/add",
        name: "add-receive-packet",
        component: LotKapanReceive
      },{
        path: "/app/app-process/packet/receive/recut",
        name: "add-receive-recut-packet",
        component: LotReceiveRecut
      }, {
        path: "/app/app-process/packet/receive/blocking-est",
        name: "add-receive-packet-blocking",
        component: BlockingReceive
      },
      {
        path: "/app/app-process/packet/receive/receive-multi",
        name: "add-receive-packet-multi",
        component: MultiReceive
      },
      {
        path: "/app/app-process/packet/receive/polish-out",
        name: "add-receive-packet-polish-party",
        component: PolishReceiveParty
      },
      {
        path: "/app/app-process/packet/receive/polish-checker",
        name: "add-receive-packet-polish-check",
        component: PolishReceiveChecker
      },
      {
        path: "/app/app-process/packet/receive/radiation",
        name: "add-receive-packet-radiation-process",
        component: PolishReceiveRadiation
      },
      {
        path: "/app/app-process/receive/rough-to-polish",
        name: "rough-to-polish-full-receive",
        component: RoughToPolishFullReceive
      },
      {
        path: "/app/app-process/receive/certy-stock",
        name: "certy-resulted-update-rates",
        component: UpdateCertyRate
      },
      {
        path: "/app/app-process/packet/issuetransaction/:trans_id",
        name: "view-packet-issue-transaction",
        props: true,
        component: LotTransactionView
      },{
        path: "/app/app-process/packet/receive",
        name: "receive-packet-master",
        component: PacketReceiveMaster
      },{
        path: "/app/app-polish-ready/ready/serieswisestock",
        name: "view-series-ready",
        component: ReadyStockSeriesWise
      },{
        path: "/app/app-polish-ready/ready/cr-stock",
        name: "view-cr-stock",
        component: AvailableStock
      },{
        path: "/app/app-polish-ready/ready/selling-master",
        name: "selling-master",
        component: SellingMaster
      },{
        path: "/app/accounts/chalan/create",
        name: "create-chalan",
        component: CreateChalan
      },{
        path: "/app/accounts/chalan/list",
        name: "chalan-list",
        component: ChalanList
      },{
        path: "/app/accounts/chalan/:chalan_id",
        name: "chalan-detail",
        component: CreateChalanDetail
      },{
        path: "/app/app-polish-ready/ready/serieswisestock/merge-series/:series_id",
        name: "merge-series-ready",
        props: true,
        component: MergeStock
      },
      {
        path: "/app/app-polish-ready/ready/merge-lot-new",
        name: "merge-series-ready-new",
        props: true,
        component: MergeStockNew
      },
      {
        path: "/app/app-polish-ready/ready/lab-stock",
        name: "lab-ready-stock",
        props: true,
        component: LabStock
      },
      {
        path: "/app/app-polish-ready/ready/lab-done-stock",
        name: "lab-done-stock",
        props: true,
        component: CertyLotList
      },
      {
        path: "/app/app-polish-ready/ready/lab-pending-stock",
        name: "lab-pending-stock",
        props: true,
        component: CertyLotListPending
      },
      {
        path: "/app/app-polish-ready/ready/merge-to-lab",
        name: "merge-stock-to-certy-transfer",
        props: true,
        component: MergeToCertyLotList
      },
      {
        path: "/app/app-polish-ready/ready/non-certy-stock",
        name: "non-certy-stock",
        props: true,
        component: NonCertyLotListPending
      },
      {
        path: "/app/app-polish-ready/ready/merge-lot-new/:id",
        name: "merge-series-ready-new-update",
        props: true,
        component: MergeStockNewUpdate
      },
      {
        path: "/app/app-polish-ready/ready/merge/:id",
        name: "merge-transaction-single-view",
        props: true,
        component: MergeTransactionSingleView
      },
      {
        path: "/app/app-polish-ready/ready/merge/list",
        name: "merge-transaction-view",
        props: true,
        component: MergeTransactionView
      },
      {
        path: "/app/app-polish-ready/ready/certy/list",
        name: "certy-packet-list-view",
        props: true,
        component: CertyPacketList
      },
      {
        path: "/app/app-polish-ready/ready/certy/issue",
        name: "certy-multiple-issue-view",
        props: true,
        component: CertyMultiIssue
      },
      {
        path: "/app/app-polish-ready/ready/certy/receive",
        name: "certy-multiple-receive-view",
        props: true,
        component: CertyMultiReceive
      },
      {
        path: "/app/app-polish-ready/ready/certy/receive/excel",
        name: "certy-excel-receive-view",
        props: true,
        component: CertyExcelReceive
      },
      {
        path: "/app/app-final-polish/branch-transfer/non-certy",
        name: "non-certy-bunch-transfer",
        props: true,
        component: NonCertyBranchTransfer
      },
      {
        path: "/app/app-final-polish/branch-transfer/non-certy/:trans_id",
        name: "non-certy-bunch-transfer-update",
        props: true,
        component: NonCertyBranchTransfer
      },
      {
        path: "/app/app-final-polish/stock/certy",
        name: "certy-branch-stock",
        props: true,
        component: CertyBranchStock
      },
      {
        path: "/app/app-final-polish/stock/non-certy",
        name: "non-certy-branch-stock",
        props: true,
        component: NonCertyBranchStock
      },
      {
        path: "/app/app-final-polish/reset-stock/certy/list",
        name: "certy-reset-request-list",
        props: true,
        component: CertyResetRequestList
      },
      {
        path: "/app/app-final-polish/reset-stock/certy/:request_id",
        name: "certy-reset-request-single",
        props: true,
        component: CertyResetRequestSingle
      },
      {
        path: "/app/app-final-polish/reset-stock/certy/create",
        name: "certy-reset-request",
        props: true,
        component: CertyResetRequest
      },
      {
        path: "/app/app-final-polish/branch-transfer/certy",
        name: "certy-bunch-transfer",
        props: true,
        component: CertyBranchTransfer
      },
      {
        path: "/app/app-final-polish/branch-transfer/list",
        name: "branch-transfer-transaction-list",
        props: true,
        component: TransferTransactions
      },
      {
        path: "/app/app-final-polish/branch-transfer/:trans_id",
        name: "single-transaction-list",
        props: true,
        component: TransferSingleTransaction
      },
      {
        path: "/app/app-final-polish/sell-transfer/non-certy",
        name: "non-certy-sell-transfer",
        props: true,
        component: NonCertySaleTransfer
      },
      {
        path: "/app/app-final-polish/sell-transfer/certy",
        name: "certy-sell-transfer",
        props: true,
        component: CertySaleTransfer
      },
      {
        path: "/app/app-final-polish/sell-transfer/non-certy/:trans_id",
        name: "edit-non-certy-sell-transfer",
        props: true,
        component: EditNonCertySaleTransfer
      },
      {
        path: "/app/app-final-polish/sell-transfer/certy/:trans_id",
        name: "edit-certy-sell-transfer",
        props: true,
        component: EditCertySaleTransfer
      },
      {
        path: "/app/app-final-polish/sell-transfer/list",
        name: "sell-transfer-transaction-list",
        props: true,
        component: SaleTransactions
      },
      {
        path: "/app/app-final-polish/sell-transfer/stock",
        name: "view-sell-non-certy-stock",
        props:true,
        component: RoughSellingNonCertyStock
      },
      {
        path: "/app/app-final-polish/selling-report",
        name: "final-polish-selling-report",
        props:true,
        component: FinalPolishSellingReport
      },
      {
        path: "/app/app-final-polish/sell-transfer/:trans_id",
        name: "single-sell-transaction-list",
        props: true,
        component: SaleSingleTransaction
      },
      {
        path: "/app/app-final-polish/polish-purchase/non-certy",
        name: "non-certy-polish-purchase",
        props: true,
        component: NonCertyPolishPurchase
      },
      {
        path: "/app/app-final-polish/polish-purchase/non-certy/:trans_id",
        name: "update-non-certy-purchase",
        props: true,
        component: NonCertyUpdate
      },
      {
        path: "/app/app-final-polish/polish-purchase/certy",
        name: "certy-polish-purchase",
        props: true,
        component: CertyPolishPurchase
      },
      {
        path: "/app/app-final-polish/polish-purchase/list",
        name: "polish-purchase-transaction-list",
        props: true,
        component: PolishPurchaseTransactions
      },
      {
        path: "/app/app-final-polish/polish-purchase/:trans_id",
        name: "single-polish-purchase-list",
        props: true,
        component: PolishPurchaseSingleTransaction
      },
      {
        path: "/app/app-final-polish/loose-request/create",
        name: "loose-edit-request-new",
        props: true,
        component: NewLooseEdit
      },
      {
        path: "/app/app-final-polish/loose-request/edit/:trans_id",
        name: "loose-edit-request-update",
        props: true,
        component: NewLooseEdit
      },
      {
        path: "/app/app-final-polish/loose-request/:trans_id",
        name: "loose-edit-request-detail",
        props: true,
        component: LooseEditDetail
      },
      {
        path: "/app/app-final-polish/polish-loose-request",
        name: "loose-edit-request-list",
        props: true,
        component: LooseEditList
      },
      {
        path: "/app/app-final-polish/edit/list",
        name: "final-polish-stock-edit",
        props: true,
        component: FinalPolishEditStock
      },
      {
        path: "/app/app-final-polish/edit/stocks/:trans_id",
        name: "final-polish-stock-edit-view",
        props: true,
        component: FinalPolishEditStockView
      },
      {
        path: "/app/app-final-polish/sell-edit/list",
        name: "sell-polish-stock-edit",
        props: true,
        component: SellPolishEditStock
      },
      {
        path: "/app/app-final-polish/sell-edit/stocks/:trans_id",
        name: "sell-polish-stock-edit-view",
        props: true,
        component: SellPolishEditStockView
      },
      {
        path: "/app/shape-masters",
        name: "shape-master",
        component: ShapeMasters
      }, {
        path: "/app/add-shape",
        name: "add-shape",
        component: ShapeMasters
      },{
        path: "/app/stone-master",
        name: "stone-master",
        component: StoneMasters
      },
      {
        path: "/app/color-masters",
        name: "color-master",
        component: ColorMasters
      }, {
        path: "/app/purity-masters",
        name: "purity-master",
        component: PurityMasters
      }, {
        path: "/app/cut-masters",
        name: "cut-master",
        component: CutMasters
      }, {
        path: "/app/size-masters",
        name: "size-master",
        component: SizeMasters
      }, {
        path: "/app/flourocent-masters",
        name: "flourocent-master",
        component: FlourocentMasters
      }, {
        path: "/app/charni-masters",
        name: "charni-master",
        component: CharniMasters
      },{
        path: "/app/charni-master-planning",
        name: "charni-master-planning",
        component: CharniMastersPlanning
      },{
        path: "/app/purity-size-selling",
        name: "purity-size-master-selling",
        component: PuritySizeMaster
      },{
        path: "/app/charni-master-selling",
        name: "charni-master-selling",
        component: CharniMastersSelling
      },{
        path: "/app/natural-master-size-polish",
        name: "natural-master-size-polish",
        component: NaturalMasterSizePolish
      },{
        path: "/app/cvd-master-size-polish",
        name: "cvd-master-size-polish",
        component: CVDMasterSizePolish
      },{
        path: "/app/assort-masters",
        name: "assort-master",
        component: AssortMasters
      },
      {
        path: "/app/rapaport-rate",
        name: "rapaport-rate",
        component: RapaportRate
      }, {
        path: "/app/bonus-rate",
        name: "bonus-rate",
        component: BonusRateCard
      },{
        path: "/app/penalty-rate",
        name: "penalty-rate",
        component: PenaltyRateCard
      },
      {
        path: "/app/standered-rate",
        name: "standered-rate",
        component: StanderedRates
      },
      {
        path: "/app/standered-rate-cvd",
        name: "standered-rate-cvd",
        component: StanderedRatesCVD
      },
      {
        path: "/app/charni-selling-rate",
        name: "charni-selling-rate",
        component: MCharniSellingRate
      },
      {
        path: "/app/add-charni-selling-rate",
        name: "add-charni-selling-rate",
        component: MCharniSellingRateCreate
      },

      {
        path: "/app/standered-rate-certi",
        name: "standered-rate-certi",
        component: StanderedRatesCerti
      },
      {
        path: "/app/tender/create",
        name: "createtender",
        component: CreateTender
      },
      {
        path: "/app/tender/view/:tender_id",
        name: "viewtender",
        props: true,
        component: ViewTender
      },
      {
        path: "app/update-profile",
        name: "update-profile",
        component: ChangePassword
      },
      {
        path: "/app/tender/invite/:tender_id",
        name: "invitebuyer",
        props: true,
        component: InviteBuyer
      },{
        path: "/app/tender/list-invite/:tender_id",
        name: "listinvitedbuyers",
        props: true,
        component: ListInvitedBuyers
      },{
        path: "/app/tender/invite-bid/:tender_id/:buyer_id",
        name: "invitedbuyerbid",
        props: true,
        component: InvitedBuyerBid
      },{
        path: "/app/tender/winners/:tender_id",
        name: "selectfinalbuyer",
        props: true,
        component: SelectBuyers
      },
      {
        path: "/app/tender/winnerlist/:tender_id",
        name: "viewwinners",
        props: true,
        component: WinnerList
      },

      {
        path: "/app/tender/update/:tender_id",
        name: "updatetender",
        props: true,
        component: CreateTender
      },
      {
        path: "/app/tender/list",
        name: "tenders",
        component: TenderMasters
      },
      {
        path: "/app/tender/issuenotes",
        name: "tendersissuenote",
        component: TendersIssuenoteMaster
      },
      {
        path: "/app/tender/issue_receive",
        name: "tendersitemissue",
        component: TendersIssueReceive
      },

      {
        path: "/app/tender/user/create",
        name: "createtenderbuyer",
        component: TenderCreateBuyerAccount
      },
      {
        path: "/app/tender/user/view/:user_id",
        name: "viewtenderbuyer",
        props: true,
        component: TenderBuyerAccountView
      },
      {
        path: "/app/tender/user/update/:user_id",
        name: "updatetenderbuyer",
        props: true,
        component: TenderCreateBuyerAccount
      },
      {
        path: "/app/tender/user/list",
        name: "tenderbuyers",
        component: TenderBuyerAccountMaster
      },

      {
        path: "/app/process-master",
        name: "process-master",
        component: ProcessMasters
      },{
        path: "/app/process-master/create",
        name: "process-create",
        component: CreateProcess
      },{
        path: "/app/process-master/edit/:process_id",
        name: "process-edit",
        props:true,
        component: CreateProcess
      },{
        path: "/app/ghat-master",
        name: "ghat-master",
        component: GhatMasters
      },{
        path: "/app/reason-master",
        name: "reason-master",
        component: ReasonMasters
      },{
        path: "/app/accounts/buyer-master",
        name: "buyer-master",
        component: BuyerAccountMasters
      },{
        path: "/app/accounts/create-buyer-account",
        name: "create-buyer-account",
        component: CreateBuyerAccount
      },{
        path: "/app/accounts/update-buyer-account/:user_id",
        name: "update-buyer-account",
        props:true,
        component: CreateBuyerAccount
      },{
        path: "/app/accounts/view-buyer-account/:user_id",
        name: "view-buyer-account",
        props:true,
        component: ViewBuyerAccount
      },{
        path: "/app/accounts/party-master",
        name: "party-master",
        component: PartyAccountMasters
      },

      {
        path: "/app/accounts/create-party-account",
        name: "create-party-account",
        component: CreatePartyAccount
      },
      {
        path: "/app/accounts/polish-party-master",
        name: "polish-party-master",
        component: PolishPartyAccountMasters
      },
      {
        path: "/app/accounts/create-polish-party-account",
        name: "create-polish-party-account",
        component: CreatePolishPartyAccount
      },
      {
        path: "/app/accounts/update-polish-party-account/:user_id",
        name: "update-polish-party-account",
        props:true,
        component: CreatePolishPartyAccount
      },
      {
        path: "/app/accounts/process-party-master",
        name: "process-party-master",
        component: ProcessPartyAccountMasters
      },
      {
        path: "/app/accounts/create-process-party-account",
        name: "create-process-party-account",
        component: CreateProcessPartyAccount
      },
      {
        path: "/app/accounts/update-process-party-account/:user_id",
        name: "update-process-party-account",
        props:true,
        component: CreateProcessPartyAccount
      },
      {
        path: "/app/accounts/broker-master",
        name: "broker-master",
        component: BrokerAccountMasters
      },{
        path: "/app/accounts/create-broker-account",
        name: "create-broker-account",
        component: CreateBrokerAccount
      },{
        path: "/app/accounts/manufacturer-master",
        name: "manufacturer-master",
        component: ManufacturerAccountMasters
      },{
        path: "/app/accounts/create-manufacturer-account",
        name: "create-manufacturer-account",
        component: CreateManufacturerAccount
      },
      {
        path: "/app/accounts/operator-master",
        name: "operator-master",
        component: OperatorAccountMasters
      },{
        path: "/app/accounts/create-operator-account",
        name: "create-operator-account",
        component: CreateOperatorAccount
      },
      {
        path: "/app/accounts/update-operator-account/:user_id",
        name: "update-operator-account",
        props: true,
        component: CreateOperatorAccount
      },
      {
        path: "/app/accounts/view-operator-account/:user_id",
        name: "view-operator-account",
        props: true,
        component: ViewOperatorAccount
      },
      {
        path: "/app/accounts/operator-type",
        name: "operator-type",
        component: OperatorTypeMasters
      },{
        path: "/app/accounts/create-operator-type",
        name: "create-operator-type",
        component: CreateOperatorType
      },
      {
        path: "/app/accounts/update-operator-type/:user_id",
        name: "update-operator-type",
        props: true,
        component: CreateOperatorType
      },

      {
        path: "/app/accounts/planner-master",
        name: "planner-master",
        component: PlannerAccountMasters
      },{
        path: "/app/accounts/create-planner-account",
        name: "create-planner-account",
        component: CreatePlannerAccount
      },
      {
        path: "/app/accounts/update-planner-account/:user_id",
        name: "update-planner-account",
        props: true,
        component: CreatePlannerAccount
      },
      {
        path: "/app/accounts/view-planner-account/:user_id",
        name: "view-planner-account",
        props: true,
        component: ViewPlannerAccount
      },
      {
        path: "/app/accounts/cleaver-master",
        name: "cleaver-master",
        component: CleaverAccountMasters
      },{
        path: "/app/accounts/create-cleaver-account",
        name: "create-cleaver-account",
        component: CreateCleaverAccount
      },
      {
        path: "/app/accounts/update-cleaver-account/:user_id",
        name: "update-cleaver-account",
        props: true,
        component: CreateCleaverAccount
      },
      {
        path: "/app/accounts/view-cleaver-account/:user_id",
        name: "view-cleaver-account",
        props: true,
        component: ViewCleaverAccount
      },

      {
        path: "/app/accounts/employe-master",
        name: "employe-master",
        component: EmployeAccountMasters
      },{
        path: "/app/accounts/create-employe-account",
        name: "create-employe-account",
        component: CreateEmployeAccount
      },
      {
        path: "/app/accounts/update-employe-account/:user_id",
        name: "update-employe-account",
        props: true,
        component: CreateEmployeAccount
      },
      {
        path: "/app/accounts/view-employe-account/:user_id",
        name: "view-employe-account",
        props: true,
        component: ViewEmployeAccount
      },
    ]
  },
  {
    path: "/reports",
    redirect: '/app/reports',
    beforeEnter: AuthRequired,
    component: SideMenu,
    children: [
      {
        path: "/app/errors/noaccess",
        name: "noaccess",
        component: NoAccess
      },{
        path: "/app/reports/process/ghutan-report",
        name: "ghutan-report",
        component: GhutanReport
      },
      {
        path: "/app/reports/process/ghutan-summary-report",
        name: "ghutan-summary-report",
        component: GhutanSummaryReport
      },
      {
        path: "/app/reports/process/chabka-report",
        name: "chabka-report",
        component: ChabkaReport
      },
      {
        path: "/app/reports/process/ls-other-report",
        name: "ls-other-report",
        component: LSOtherReport
      },
      {
        path: "/app/reports/process/process-report",
        name: "process-report",
        component: ProcessReport
      },
      {
        path: "/app/reports/process/recut-report",
        name: "recut-report",
        component: RecutReport
      },
      {
        path: "/app/reports/process/pa-ls-report",
        name: "pa-ls-report",
        component: PALSReport
      },
      {
        path: "/app/reports/process/pa-operator-report",
        name: "ls-operator-summary",
        component: PAOperatorReport
      },
      {
        path: "/app/reports/process/pa-ls-lot-report",
        name: "pa-ls-lot-report",
        component: PALSLotReport
      },
      {
        path: "/app/reports/process/ghutan-lot-report",
        name: "ghutan-lot-report",
        component: GhutanLotReport
      },
      {
        path: "/app/reports/process/chabka-lot-report",
        name: "chabka-lot-report",
        component: ChabkaLotReport
      },
      {
        path: "/app/reports/process/pa-ls-summary-report",
        name: "pa-ls-summary",
        component: PALSSummaryReport
      },
      {
        path: "/app/reports/xxx-manager/summary-size-wise",
        name: "xxx-summary-size-wise",
        component: XxxSummarySizeWise
      },
      {
        path: "/app/reports/xxx-manager/summary-size-wise-polish-summary",
        name: "xxx-summary-size-wise-polish",
        component: XxxSummarySizeWisePolish
      },
      {
        path: "/app/reports/xxx-manager/summary-shape-polish-summary",
        name: "xxx-summary-shape-polish",
        component: XxxSummarySizeShapeWisePolish
      },
      {
        path: "/app/reports/xxx-manager/summary-shape-group-polish-summary",
        name: "xxx-summary-shape-group-polish",
        component: XxxSummarySizeShapeGroupWisePolish
      },
      {
        path: "/app/reports/xxx-manager/packet-status-summary",
        name: "xxx-packet-status-summary",
        component: XxxSummaryPacketStatus
      },
      {
        path: "/app/reports/xxx-manager/purity-polish-summary-new",
        name: "xxx-size-purity-polish-summary-new",
        component: XxxSummaryPurityPolishSizePurityWise
      },
      {
        path: "/app/reports/xxx-manager/manual-summary-report",
        name: "manual-summary-report",
        component: ManualSummaryReport
      },
      {
        path: "/app/reports/kapan-packet-history",
        name: "kapan-packet-history",
        component: KapanPacketHistoryReport
      },
      {
        path: "/app/reports/packet-user-report",
        name: "manual-packet-user-report",
        component: PacketUserReport
      },
      {
        path: "/app/reports/packet-plan-rate",
        name: "packet-plan-rate",
        component: PacketPlanningRate
      },
      {
        path: "/app/reports/kapan/manual-kapan-report",
        name: "manual-kapan-report",
        component: ManualKapanPcsReport
      },{
        path: "/app/reports/kapan/galaxy-transfer-report",
        name: "galaxy-transfer-report",
        component: GalaxyTransferReport
      },{
        path: "/app/reports/kapan/stock-manager-report",
        name: "stock-manager-report",
        component: StockManagerReport
      },
      {
        path: "/app/reports/kapan/laser-dasa-report",
        name: "laser-dasa-report",
        component: LaserDasaSeriesReport
      },
      {
        path: "/app/reports/tender/tender-packet-report",
        name: "tender-packet-report",
        component: TenderPacketReport
      },
      {
        path: "/app/reports/tender/tender-topbid-report",
        name: "tender-top-bids",
        component: TenderTopBidReport
      },{
        path: "/app/reports/tender/tender-winners-report",
        name: "tender-winners",
        component: TenderWinnerReport
      },
      {
        path: "/app/reports/tender/tender-item-status-report",
        name: "tender-item-status",
        component: TenderItemIssueStatus
      },
      {
        path: "/app/reports/tender/tender-buyer-status-report",
        name: "tender-buyer-status",
        component: TenderBuyerIssueStatus
      },
      {
        path: "/app/reports/xxx-manager/planning-summary-all",
        name: "planning-summary-all",
        component: XxxSummarySizePurityWise
      },
      {
        path: "/app/reports/xxx-manager/planning-summary-all-new",
        name: "planning-summary-all-new",
        component: XxxSummarySizePurityWiseNew
      },
      {
        path: "/app/reports/full-detail/summary",
        name: "full-detail-summary",
        component: FullDetailSummary
      },
      {
        path: "/app/reports/process/process-history-all",
        name: "process-history-all",
        component: ProcessHistory
      },
      {
        path: "/app/reports/process/process-lose-report",
        name: "process-lose-report",
        component: ProcessLoseReport
      },

      {
        path: "/app/reports/xxx-manager/only-planning-summary-all",
        name: "only-planning-summary-all",
        component: XxxSummaryPolishSizePurityWise
      },
      {
        path: "/app/reports/xxx-manager/packet-process-compare-all",
        name: "packet-process-compare-all",
        component: PacketProcessCompare
      },
      {
        path: "/app/reports/xxx-manager/rough-cr-status-all",
        name: "rough-cr-status-all",
        component: RoughCurrentStatus
      },

      {
        path: "/app/planner/planner-rate-report",
        name: "planner-rate-report",
        component: PlannerRateReport
      },
      {
        path: "/app/planner/planner-rate-summary-report",
        name: "planner-rate-summary-report",
        component: PlannerRateSummaryReport
      },
      {
        path: "/app/reports/blocking-report",
        name: "blocking-report",
        component: BlockingReport
      },
      {
        path: "/app/reports/blocking-summary-report",
        name: "blocking-summary-report",
        component: BlockingSummaryReport
      },
      {
        path: "/app/reports/lab-report",
        name: "lab-report",
        component: LABReport
      },
      {
        path: "/app/reports/lab-summary-report",
        name: "lab-summary-report",
        component: LABSummaryReport
      },
      {
        path: "/app/reports/recut-summary-report",
        name: "recut-summary-report",
        component: RecutSummaryReport
      },
      {
        path: "/app/reports/process-majuri-report",
        name: "process-majuri-report",
        component: ProcessMajuriReport
      },
      {
        path: "/app/reports/polish-report",
        name: "polish-report",
        component: PolishReport
      },
      {
        path: "/app/reports/polish-summary-report",
        name: "polish-summary-report",
        component: PolishSummaryReport
      },
      {
        path: "/app/reports/polish-comparison-report",
        name: "polish-comparison-report",
        component: PolishComparisonReport
      },
      {
        path: "/app/reports/polish-rate-report",
        name: "polish-rate-report",
        component: PolishRateReport
      },
      {
        path: "/app/reports/polish-check-summary-report",
        name: "polish-check-summary-report",
        component: PolishCheckSummaryReport
      },
      {
        path: "/app/reports/final-polish-summary-report",
        name: "final-polish-summary-report",
        component: FinalPolishSummaryReport
      },
      {
        path: "/app/reports/4p-report",
        name: "4p-report",
        component: Report4P
      },
      {
        path: "/app/reports/4p-summary-report",
        name: "4p-summary-report",
        component: Report4PSummary
      },
      {
        path: "/app/reports/manager-stock-report",
        name: "manager-stock-report",
        component: ManagerStockReport
      },
      {
        path: "/app/reports/out-porcess-report",
        name: "out-process-report",
        component: OutProcessReport
      },
      {
        path: "/app/reports/department-job-done",
        name: "department-job-done-report",
        component: DepartmentJobDone
      },
      {
        path: "/app/reports/manager-late-packet",
        name: "manager-late-packet-report",
        component: ManagerLatePacketReport
      },
      {
        path: "/app/reports/late-packet",
        name: "admin-late-packet-report",
        component: AdminLatePacketReport
      },
      {
        path: "/app/reports/stock-report",
        name: "process-stock-report",
        component: ProcessStockReport
      },
      {
        path: "/app/reports/galaxy-stock-report",
        name: "galaxy-operator-stock-report",
        component: GalaxyOperatorStockReport
      },
      {
        path: "/app/reports/series-summary",
        name: "series-summary-report",
        component: SeriesSummaryReport
      },
      {
        path: "/app/reports/polish-ready/stock",
        name: "polish-ready-merge-stock-report",
        component: PolishReadyMergeStock
      },
      {
        path: "/app/reports/final-polish-comparison",
        name: "final-polish-stock-comparison",
        component: FinalPolishStockComparison
      },
      {
        path: "/app/reports/final-polish-certy-comparison",
        name: "final-polish-certy-comparison",
        component: FinalPolishCertyComparison
      },
      {
        path: "/app/reports/final-polish-non-certy-selling",
        name: "final-polish-non-certy-selling-report",
        component: FinalPolishNonCertySellingReport
      },
      {
        path: "/app/reports/final-polish-certy-selling",
        name: "final-polish-certy-selling-report",
        component: FinalPolishCertySellingReport
      },
      {
        path: "/app/reports/final-polish-non-certy",
        name: "final-polish-non-certy-report",
        component: FinalPolishNonCertyReport
      },
      {
        path: "/app/reports/final-polish-non-certy-new",
        name: "final-polish-non-certy-report-new",
        component: FinalPolishNonCertyReportNew
      },
      {
        path: "/app/reports/final-polish-non-certy",
        name: "final-polish-non-certy-report-old",
        component: FinalPolishNonCertyReportOld
      },
      {
        path: "/app/reports/certy-stock-report",
        name: "certy-stock-report",
        component: FinalCertyStockReport
      },
      {
        path: "/app/reports/merge-stock-report",
        name: "merge-stock-report",
        component: MergeStockReport
      },

    ]
  },
  {
    path: "/purchase",
    redirect: '/app/rough-purchase',
    beforeEnter: AuthRequired,
    component: SideMenu,
    children: [{
      path: "/purchase/rough-master",
      name: "rough-master",
      component: RoughMaster
    },{
      path: "/purchase/rough-stock-manager",
      name: "rough-stock-manager",
      component: RoughMasterStockManager
    },{
      path: "/purchase/rough-galaxy-manager",
      name: "rough-galaxy-manager",
      component: RoughListGalaxyManager
    } ,
    {
      path: "/purchase/rough-my-series",
      name: "rough-my-series",
      component: RoughSeriesList
    }
    ,{
      path: "/purchase/rough-purchase",
      name: "rough-purchase",
      component: RoughPurchase
    },{
      path: "/purchase/update-rough-purchase/:rough_id",
      name: "update-rough-purchase",
      props:true,
      component: RoughPurchase
    },{
      path: "/purchase/rough-view/:rough_id",
      name: "view-rough-purchase",
      props:true,
      component: RoughView
    },{
      path: "/purchase/rough-view-stock-manager/:rough_id/:cleaver_issue_id",
      name: "rough-view-stock-manager",
      props:true,
      component: RoughViewStockManager
    },{
      path: "/purchase/rough-view/kapan-detail/:kapan_id",
      name: "view-rough-kapan",
      props:true,
      component: KapanView
    },{
      path: "/purchase/rough-view/series-detail/:series_id",
      name: "view-rough-series",
      props:true,
      component: SeriesView
    },{
      path: "/purchase/rough-view/packet-detail/:packet_id",
      name: "view-rough-packet",
      props:true,
      component: PacketView
    },{
      path: "/purchase/rough-view/blocked-rough-packet/",
      name: "blocked-rough-packet",
      props:true,
      component: BlockedPacketView
    }
    ,
    {
      path: "/rough/rough-packets-master",
      name: "rough-packets-master",
      component: PacketList
    },
    {
      path: "/rough/rough-packets-planner",
      name: "rough-packets-planner",
      component: PacketListPlanner
    },
     {
      path: "/purchase/vendors-master",
      name: "vendors-master",
      component: VendorList
    }, {
      path: "/purchase/rough-vendors",
      name: "create-vendors-account",
      component: RoughVendors
    }, {
      path: "/purchase/update-rough-vendors/:user_id",
      name: "update-vendors-account",
      props:true,
      component: RoughVendors
    },
    {
      path: "/purchase/view-rough-vendors/:user_id",
      name: "view-vendors-account",
      props:true,
      component: RoughVendorsView
    },

    {
      path: "/purchase/selling-party-master",
      name: "selling-party-master",
      component: SellingPartyList
    }, {
      path: "/purchase/rough-selling-party",
      name: "create-selling-party-account",
      component: RoughSellingParty
    }, {
      path: "/purchase/update-rough-selling-party/:user_id",
      name: "update-selling-party-account",
      props:true,
      component: RoughSellingParty
    },
    {
      path: "/purchase/view-rough-selling-party/:user_id",
      name: "view-selling-party-account",
      props:true,
      component: RoughSellingPartyView
    },
    {
      path: "/purchase/branch-office-master",
      name: "branch-office-master",
      component: BranchOfficeList
    }, {
      path: "/purchase/rough-branch-office",
      name: "create-branch-office-account",
      component: RoughBranchOffice
    }, {
      path: "/purchase/update-rough-branch-office/:user_id",
      name: "update-branch-office-account",
      props:true,
      component: RoughBranchOffice
    },
    {
      path: "/purchase/view-rough-branch-office/:user_id",
      name: "view-branch-office-account",
      props:true,
      component: RoughBranchOfficeView
    },
    {
      path: "/selling/broker-master",
      name: "broker-master",
      component: BrokerList
    }, {
      path: "/selling/rough-broker",
      name: "create-broker-account",
      component: RoughBroker
    }, {
      path: "/selling/update-rough-broker/:user_id",
      name: "update-broker-account",
      props:true,
      component: RoughBroker
    },
    {
      path: "/selling/view-rough-broker/:user_id",
      name: "view-broker-account",
      props:true,
      component: RoughBrokerView
    },
    {
      path: "/purchase/broker-master",
      name: "broker-purchase-master",
      component: PurchaseBrokerList
    }, {
      path: "/purchase/rough-broker",
      name: "create-broker-purchase-account",
      component: RoughPurchaseBroker
    }, {
      path: "/purchase/update-rough-broker/:user_id",
      name: "update-broker-purchase-account",
      props:true,
      component: RoughPurchaseBroker
    },
    {
      path: "/purchase/view-rough-broker/:user_id",
      name: "view-broker-purchase-account",
      props:true,
      component: RoughPurchaseBrokerView
    },
    {
      path: "/purchase/rough-sorting",
      name: "rough-sorting",
      component: RoughSorting
    },]
  },
  {
    path: "/polish",
    redirect: '/polish/view-series-ready',
    beforeEnter: AuthRequired,
    component: SideMenu,
    children: [
    {
      path: "/polish/selling-party-master",
      name: "selling-party-master",
      component: SellingPartyList
    }, {
      path: "/polish/rough-selling-party",
      name: "create-selling-party-account",
      component: RoughSellingParty
    }, {
      path: "/polish/update-rough-selling-party/:user_id",
      name: "update-selling-party-account",
      props:true,
      component: RoughSellingParty
    },
    {
      path: "/polish/view-rough-selling-party/:user_id",
      name: "view-selling-party-account",
      props:true,
      component: RoughSellingPartyView
    },
    {
      path: "/polish/purchase-party-master",
      name: "purchase-party-master",
      component: PurchasePartyList
    }, {
      path: "/polish/rough-purchase-party",
      name: "create-purchase-party-account",
      component: RoughPurchaseParty
    }, {
      path: "/polish/update-rough-purchase-party/:user_id",
      name: "update-purchase-party-account",
      props:true,
      component: RoughPurchaseParty
    },
    {
      path: "/polish/view-rough-purchase-party/:user_id",
      name: "view-purchase-party-account",
      props:true,
      component: RoughPurchasePartyView
    },
   ]
  },
  {
    path: "/dkstock",
    redirect: '/dkstock/natural-certy',
    beforeEnter: AuthRequired,
    component: SideMenu,
    children: [
    {
      path: "/dkstock/natural-certy",
      name: "dkstock-natural",
      component: NaturalStockDk
    },
    {
      path: "/dkstock/cvd-certy",
      name: "dkstock-cvd",
      component: CVDStockDk
    },
   ]
  },
  {
    path: '/user',
    redirect: '/user/login',
    component: Login,
    children: [{
      path: "login",
      name: "login",
      component: Login
    }]
  },
  {
    path: "/simple-menu",
    component: SimpleMenu,
    children: [{
      path: "/",
      name: "simple-menu-dashboard-overview-1",
      component: DashboardOverview1
    },
    {
      path: "dashboard-overview-2",
      name: "simple-menu-dashboard-overview-2",
      component: DashboardOverview2
    },
    {
      path: "inbox",
      name: "simple-menu-inbox",
      component: Inbox
    },
    {
      path: "file-manager",
      name: "simple-menu-file-manager",
      component: FileManager
    },
    {
      path: "point-of-sale",
      name: "simple-menu-point-of-sale",
      component: PointOfSale
    },
    {
      path: "chat",
      name: "simple-menu-chat",
      component: Chat
    },
    {
      path: "post",
      name: "simple-menu-post",
      component: Post
    },
    {
      path: "crud-data-list",
      name: "simple-menu-crud-data-list",
      component: CrudDataList
    },
    {
      path: "crud-form",
      name: "simple-menu-crud-form",
      component: CrudForm
    },
    {
      path: "users-layout-1",
      name: "simple-menu-users-layout-1",
      component: UsersLayout1
    },
    {
      path: "users-layout-2",
      name: "simple-menu-users-layout-2",
      component: UsersLayout2
    },
    {
      path: "users-layout-3",
      name: "simple-menu-users-layout-3",
      component: UsersLayout3
    },
    {
      path: "profile-overview-1",
      name: "simple-menu-profile-overview-1",
      component: ProfileOverview1
    },
    {
      path: "profile-overview-2",
      name: "simple-menu-profile-overview-2",
      component: ProfileOverview2
    },
    {
      path: "profile-overview-3",
      name: "simple-menu-profile-overview-3",
      component: ProfileOverview3
    },
    {
      path: "wizard-layout-1",
      name: "simple-menu-wizard-layout-1",
      component: WizardLayout1
    },
    {
      path: "wizard-layout-2",
      name: "simple-menu-wizard-layout-2",
      component: WizardLayout2
    },
    {
      path: "wizard-layout-3",
      name: "simple-menu-wizard-layout-3",
      component: WizardLayout3
    },
    {
      path: "blog-layout-1",
      name: "simple-menu-blog-layout-1",
      component: BlogLayout1
    },
    {
      path: "blog-layout-2",
      name: "simple-menu-blog-layout-2",
      component: BlogLayout2
    },
    {
      path: "blog-layout-3",
      name: "simple-menu-blog-layout-3",
      component: BlogLayout3
    },
    {
      path: "pricing-layout-1",
      name: "simple-menu-pricing-layout-1",
      component: PricingLayout1
    },
    {
      path: "pricing-layout-2",
      name: "simple-menu-pricing-layout-2",
      component: PricingLayout2
    },
    {
      path: "invoice-layout-1",
      name: "simple-menu-invoice-layout-1",
      component: InvoiceLayout1
    },
    {
      path: "invoice-layout-2",
      name: "simple-menu-invoice-layout-2",
      component: InvoiceLayout2
    },
    {
      path: "faq-layout-1",
      name: "simple-menu-faq-layout-1",
      component: FaqLayout1
    },
    {
      path: "faq-layout-2",
      name: "simple-menu-faq-layout-2",
      component: FaqLayout2
    },
    {
      path: "faq-layout-3",
      name: "simple-menu-faq-layout-3",
      component: FaqLayout3
    },
    {
      path: "update-profile",
      name: "simple-menu-update-profile",
      component: UpdateProfile
    },
    {
      path: "change-password",
      name: "simple-menu-change-password",
      component: ChangePassword
    },
    {
      path: "regular-table",
      name: "simple-menu-regular-table",
      component: RegularTable
    },
    {
      path: "tabulator",
      name: "simple-menu-tabulator",
      component: Tabulator
    },
    {
      path: "accordion",
      name: "simple-menu-accordion",
      component: Accordion
    },
    {
      path: "button",
      name: "simple-menu-button",
      component: Button
    },
    {
      path: "modal",
      name: "simple-menu-modal",
      component: Modal
    },
    {
      path: "slide-over",
      name: "simple-menu-slide-over",
      component: SlideOver
    },
    {
      path: "notification",
      name: "simple-menu-notification",
      component: Notification
    },
    {
      path: "alert",
      name: "simple-menu-alert",
      component: Alert
    },
    {
      path: "progress-bar",
      name: "simple-menu-progress-bar",
      component: ProgressBar
    },
    {
      path: "tooltip",
      name: "simple-menu-tooltip",
      component: Tooltip
    },
    {
      path: "dropdown",
      name: "simple-menu-dropdown",
      component: Dropdown
    },
    {
      path: "typography",
      name: "simple-menu-typography",
      component: Typography
    },
    {
      path: "icon",
      name: "simple-menu-icon",
      component: Icon
    },
    {
      path: "loading-icon",
      name: "simple-menu-loading-icon",
      component: LoadingIcon
    },
    {
      path: "regular-form",
      name: "simple-menu-regular-form",
      component: RegularForm
    },
    {
      path: "datepicker",
      name: "simple-menu-datepicker",
      component: Datepicker
    },

    {
      path: "file-upload",
      name: "simple-menu-file-upload",
      component: FileUpload
    },
    {
      path: "wysiwyg-editor",
      name: "simple-menu-wysiwyg-editor",
      component: WysiwygEditor
    },
    {
      path: "validation",
      name: "simple-menu-validation",
      component: Validation
    },
    {
      path: "chart",
      name: "simple-menu-chart",
      component: Chart
    },
    {
      path: "slider",
      name: "simple-menu-slider",
      component: Slider
    },
    {
      path: "image-zoom",
      name: "simple-menu-image-zoom",
      component: ImageZoom
    }
    ]
  },
  {
    path: "/top-menu",
    component: TopMenu,
    children: [{
      path: "/",
      name: "top-menu-dashboard-overview-1",
      component: DashboardOverview1
    },
    {
      path: "dashboard-overview-2",
      name: "top-menu-dashboard-overview-2",
      component: DashboardOverview2
    },
    {
      path: "inbox",
      name: "top-menu-inbox",
      component: Inbox
    },
    {
      path: "file-manager",
      name: "top-menu-file-manager",
      component: FileManager
    },
    {
      path: "point-of-sale",
      name: "top-menu-point-of-sale",
      component: PointOfSale
    },
    {
      path: "chat",
      name: "top-menu-chat",
      component: Chat
    },
    {
      path: "post",
      name: "top-menu-post",
      component: Post
    },
    {
      path: "crud-data-list",
      name: "top-menu-crud-data-list",
      component: CrudDataList
    },
    {
      path: "crud-form",
      name: "top-menu-crud-form",
      component: CrudForm
    },
    {
      path: "users-layout-1",
      name: "top-menu-users-layout-1",
      component: UsersLayout1
    },
    {
      path: "users-layout-2",
      name: "top-menu-users-layout-2",
      component: UsersLayout2
    },
    {
      path: "users-layout-3",
      name: "top-menu-users-layout-3",
      component: UsersLayout3
    },
    {
      path: "profile-overview-1",
      name: "top-menu-profile-overview-1",
      component: ProfileOverview1
    },
    {
      path: "profile-overview-2",
      name: "top-menu-profile-overview-2",
      component: ProfileOverview2
    },
    {
      path: "profile-overview-3",
      name: "top-menu-profile-overview-3",
      component: ProfileOverview3
    },
    {
      path: "wizard-layout-1",
      name: "top-menu-wizard-layout-1",
      component: WizardLayout1
    },
    {
      path: "wizard-layout-2",
      name: "top-menu-wizard-layout-2",
      component: WizardLayout2
    },
    {
      path: "wizard-layout-3",
      name: "top-menu-wizard-layout-3",
      component: WizardLayout3
    },
    {
      path: "blog-layout-1",
      name: "top-menu-blog-layout-1",
      component: BlogLayout1
    },
    {
      path: "blog-layout-2",
      name: "top-menu-blog-layout-2",
      component: BlogLayout2
    },
    {
      path: "blog-layout-3",
      name: "top-menu-blog-layout-3",
      component: BlogLayout3
    },
    {
      path: "pricing-layout-1",
      name: "top-menu-pricing-layout-1",
      component: PricingLayout1
    },
    {
      path: "pricing-layout-2",
      name: "top-menu-pricing-layout-2",
      component: PricingLayout2
    },
    {
      path: "invoice-layout-1",
      name: "top-menu-invoice-layout-1",
      component: InvoiceLayout1
    },
    {
      path: "invoice-layout-2",
      name: "top-menu-invoice-layout-2",
      component: InvoiceLayout2
    },
    {
      path: "faq-layout-1",
      name: "top-menu-faq-layout-1",
      component: FaqLayout1
    },
    {
      path: "faq-layout-2",
      name: "top-menu-faq-layout-2",
      component: FaqLayout2
    },
    {
      path: "faq-layout-3",
      name: "top-menu-faq-layout-3",
      component: FaqLayout3
    },
    {
      path: "update-profile",
      name: "top-menu-update-profile",
      component: UpdateProfile
    },
    {
      path: "change-password",
      name: "top-menu-change-password",
      component: ChangePassword
    },
    {
      path: "regular-table",
      name: "top-menu-regular-table",
      component: RegularTable
    },
    {
      path: "tabulator",
      name: "top-menu-tabulator",
      component: Tabulator
    },
    {
      path: "accordion",
      name: "top-menu-accordion",
      component: Accordion
    },
    {
      path: "button",
      name: "top-menu-button",
      component: Button
    },
    {
      path: "modal",
      name: "top-menu-modal",
      component: Modal
    },
    {
      path: "slide-over",
      name: "top-menu-slide-over",
      component: SlideOver
    },
    {
      path: "notification",
      name: "top-menu-notification",
      component: Notification
    },
    {
      path: "alert",
      name: "top-menu-alert",
      component: Alert
    },
    {
      path: "progress-bar",
      name: "top-menu-progress-bar",
      component: ProgressBar
    },
    {
      path: "tooltip",
      name: "top-menu-tooltip",
      component: Tooltip
    },
    {
      path: "dropdown",
      name: "top-menu-dropdown",
      component: Dropdown
    },
    {
      path: "typography",
      name: "top-menu-typography",
      component: Typography
    },
    {
      path: "icon",
      name: "top-menu-icon",
      component: Icon
    },
    {
      path: "loading-icon",
      name: "top-menu-loading-icon",
      component: LoadingIcon
    },
    {
      path: "regular-form",
      name: "top-menu-regular-form",
      component: RegularForm
    },
    {
      path: "datepicker",
      name: "top-menu-datepicker",
      component: Datepicker
    },

    {
      path: "file-upload",
      name: "top-menu-file-upload",
      component: FileUpload
    },
    {
      path: "wysiwyg-editor",
      name: "top-menu-wysiwyg-editor",
      component: WysiwygEditor
    },
    {
      path: "validation",
      name: "top-menu-validation",
      component: Validation
    },
    {
      path: "chart",
      name: "top-menu-chart",
      component: Chart
    },
    {
      path: "slider",
      name: "top-menu-slider",
      component: Slider
    },
    {
      path: "image-zoom",
      name: "top-menu-image-zoom",
      component: ImageZoom
    }
    ]
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

export default router;
