<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto text-t">Series Summary (M) Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 xl:col-span-6">
          <div class="flex items-center">
            <label for="update-profile-form-6" class="font-medium mr-3"
              >Select Series</label
            >
            <TomSelect
          v-model="pagedata.series_id"
          :options="{
            placeholder: 'Select Series',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Series'
              }
            }
          }"
          class="w-60"
          @change="generateSummaryReport"
        >
        <option value="">Select Series</option>
        <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
        </TomSelect>
          </div>
        </div>
      </div>
      <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Sr No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label" v-if="pagedata.series_details"> {{ pagedata.series_details.series_code }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label" v-if="pagedata.series_details"> {{ pagedata.series_details.series_weight }}</label
                  >
                </div>

              </div>
            </div>
          </div>
      <!-- BEgin: Process Report Start-->
      <div class="grid grid-cols-12 gap-x-5">
        <div class="overflow-x-auto col-span-12 lg:col-span-8 md:col-span-8 sm:col-span-10">
          <div class="intro-y col-span-12 overflow-auto">
            <table class="mt-2 w-full border-2">
              <thead>
                <tr class="border-b bg-gray-400 dark:bg-dark-1">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Details</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Weight</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Piece</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">Persentage (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b" v-for="(typed,index) in pagedata.type_total" :key="index">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">{{ typed.manual_type }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ typed.weight ? $h.myFloat(typed.weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ typed.pieces ? $h.myFloat(typed.pieces) : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ typed.weight ? $h.myFloat(typed.weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ typed.weight ? ' &nbsp; %' : '' }}</th>
                </tr>
                <tr class="border-b">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">G = 4P</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.round_planning.weight ? $h.myFloat(pagedata.round_planning.weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.round_planning.pieces ? $h.myFloat(pagedata.round_planning.pieces) : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.round_planning.weight ? $h.myFloat(pagedata.round_planning.weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ pagedata.round_planning.weight ? ' &nbsp; %' : '' }}</th>
                </tr>
                <tr class="border-b">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">G = PR</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.prince_planning.weight ? $h.myFloat(pagedata.prince_planning.weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.prince_planning.pieces ? $h.myFloat(pagedata.prince_planning.pieces) : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.prince_planning.weight ? $h.myFloat(pagedata.prince_planning.weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ pagedata.prince_planning.weight ? ' &nbsp; %' : '' }}</th>
                </tr>
                <tr class="border-b">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">G = FN</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.fancy_planning.weight ? $h.myFloat(pagedata.fancy_planning.weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.fancy_planning.pieces ? $h.myFloat(pagedata.fancy_planning.pieces) : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.fancy_planning.weight ? $h.myFloat(pagedata.fancy_planning.weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ pagedata.fancy_planning.weight ? ' &nbsp; %' : '' }}</th>
                </tr>
                <tr class="border-b" v-for="(rejection,index) in pagedata.rejections" :key="index">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">{{ rejection.name }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ rejection.assort_weight ? $h.myFloat(rejection.assort_weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ rejection.assort_pieces ? $h.myFloat(rejection.assort_pieces) : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ rejection.assort_weight ? $h.myFloat(rejection.assort_weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ rejection.assort_weight ? ' &nbsp; %' : '' }}</th>
                </tr>

                <tr class="border-b" v-if="pagedata.transection_data.issue_weight">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Ghutan Weight</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.issue_weight ? $h.myFloat(pagedata.transection_data.issue_weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.pieces ? pagedata.transection_data.pieces : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</th>
                </tr>
                <tr class="border-b" v-if="pagedata.transection_data.issue_weight">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Ghutan GHAT</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.issue_weight ? $h.myFloat(pagedata.transection_data.issue_weight - pagedata.transection_data.receive_weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.transection_data.receive_weight ? $h.myFloat((pagedata.transection_data.issue_weight -pagedata.transection_data.receive_weight) * 100 / pagedata.series_details.series_weight) : '-' }}  {{ pagedata.transection_data.receive_weight ? ' &nbsp; %' : '' }}</th>
                </tr>
                </tbody>
              <tbody v-for="(process,index) in pagedata.process_data" :key="index">
                <tr class="border-b">
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">{{ process.process_code }} GHAT </th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ process.issue_weight ? $h.myFloat(process.issue_weight - process.receive_weight) + ' Ct' : '-' }}</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                  <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ process.receive_weight ? $h.myFloat((process.issue_weight -process.receive_weight) * 100 / pagedata.series_details.series_weight) : '-' }}  {{ process.receive_weight ? ' &nbsp; %' : '' }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, reactive,onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import store from '@/store'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      series_id: '',
      series_details: [],
      rejections: [],
      round_planning: [],
      prince_planning: [],
      fancy_planning: [],
      process_data: [],
      type_total: [],
      transection_data: [],
    })
    const getRoughDetails = () => {
      let promise = axios({
        url: "api/dp_rough_series_desc",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items
          pagedata.series_id = stringify(pagedata.serieses[0].id)
        })
        .catch(_error => {

        });
    }
    const getSummaryReport = () => {
      let _this = this;
      const params = {
        series_id: window.btoa(pagedata.series_id)
      };
      let promise = axios({
        url: "api/list_kapan_maual_summary_report",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.series_details = data.series
          pagedata.rejections = data.rejections
          pagedata.round_planning = data.round_planning
          pagedata.prince_planning = data.prince_planning
          pagedata.fancy_planning = data.Fansy_planning
          pagedata.process_data = data.process_data
          pagedata.type_total = data.type_total
          pagedata.transection_data = data.transection_data
        })
        .catch(_error => {

        });
    }
    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function generateSummaryReport() {
      getSummaryReport()
    }

    onMounted(() => {
      checkPermission()
      getRoughDetails()

    })

    return {
      pagedata,
      getSummaryReport,
      generateSummaryReport
    }
  }
})
</script>
