<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Rate</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <a href="javascript:;" data-toggle="modal" data-target="#new-order-modal" @click="resetModalFileds" class="btn btn-primary shadow-md mr-2">New Charni Rate</a>

        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <a href="javascript:;" data-toggle="modal" data-target="#new-order-modal" @click="this.$router.push({name: 'add-charni-selling-rate'})" class="btn btn-primary shadow-md mr-2">New Charni Rate (All)</a>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="grid grid-cols-12 gap-x-2">
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Charni Name</label>
                            <input id="tabulator-html-filter-value" v-model="filter.charni_code" type="text" class="form-control w-full" placeholder="Charni Name" />
                        </div>
                    </div>
                    <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Size Type</label>
                            <TomSelect v-model="filter.m_charni_size" :options="{
                    placeholder: 'Select Size',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Select Size'
                      }
                    }
                  }" class="w-full">
                                <option value="0">Select Size</option>
                                <option value="1">Size 1</option>
                                <option value="2">Size 2</option>
                                <option value="3">Size 3</option>
                            </TomSelect>
                        </div>
                    </div> -->
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Stone Type</label>
                            <TomSelect v-model="filter.stone_type" :options="{
                    placeholder: 'Select Stone',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Select Stone'
                      }
                    }
                  }" class="w-full" @change="shade_size_item_filter_details">
                                <option value="0">Select Stone</option>
                                <option value="1">Natural</option>
                                <option value="2">CVD</option>
                                <option value="3">HPHT</option>
                                <option value="4">D_CVD</option>
                                <option value="5">D_HPHT</option>
                            </TomSelect>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Color</label>
                            <TomSelect v-model="filter.color_size" :options="{
                    placeholder: 'Select Color',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Select Color'
                      }
                    }
                  }" class="w-full">
                                <option value="0">Select Color</option>
                                <option v-for="(item,index) in screenData.color_filter_arr" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                            </TomSelect>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Shape Type</label>
                            <TomSelect v-model="filter.shape" :options="{
                    placeholder: 'Select Shape',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Select Shape'
                      }
                    }
                  }" class="w-full">
                                <option value="0">Select Shape</option>
                                <option v-for="charni in screenData.shape_arr" :key="charni.id" :value="charni.id">{{ charni.shape_name }}</option>
                                <!-- <option value="3">Prince</option> -->
                            </TomSelect>
                        </div>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full mt-8" @click="initTabulator">
                            Go
                        </button>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-8" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
            <div class="flex mt-5 sm:mt-0">

                <div class="dropdown w-1/2 sm:w-auto">
                    <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto mt-8" aria-expanded="false">
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export
                        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                    </button>
                    <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                            <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportCsv">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                            </a>
                            <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportJson">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                            </a>
                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">

            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Charni modal-->

    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">New Charni</h2>
                </div>
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div v-if="charniForm.id != ''">
                            <label for="update-profile-form-6" class="form-label py-1">Stone Type</label>
                            <div class="" id="stone_type">

                                <TomSelect v-model="charniForm.stone_type" :options="{
                      placeholder: 'Select Stone',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Stone'
                        }
                      }
                    }" disabled class="w-full" @change="get_sub_charni_list">
                                    <option value="0">Select Stone</option>
                                    <option value="1">Natural</option>
                                    <option value="2">CVD</option>
                                    <option value="3">HPHT</option>
                                    <option value="4">D_CVD</option>
                                    <option value="5">D_HPHT</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div v-else>
                            <label for="update-profile-form-6" class="form-label py-1">Stone Type</label>
                            <div class="" id="stone_type">

                                <TomSelect v-model="charniForm.stone_type" :options="{
                      placeholder: 'Select Stone',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Stone'
                        }
                      }
                    }" class="w-full" @change="shade_size_item_details">
                                    <option value="0">Select Stone</option>
                                    <option value="1">Natural</option>
                                    <option value="2">CVD</option>
                                    <option value="3">HPHT</option>
                                    <option value="4">D_CVD</option>
                                    <option value="5">D_HPHT</option>
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12">
                        <div v-if="charniForm.id != ''">
                            <label for="update-profile-form-6" class="form-label py-1">Color</label>
                            <div class="" id="color-size">

                                <TomSelect v-model="charniForm.stone_type" :options="{
                      placeholder: 'Select Color',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Color'
                        }
                      }
                    }" disabled class="w-full" @change="get_sub_charni_list">
                                    <option value="0">Select Color</option>
                                    <option v-for="(item,index) in screenData.color_arr" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div v-else>
                            <label for="update-profile-form-6" class="form-label py-1">Color</label>
                            <div class="" id="color-size">

                                <TomSelect v-model="charniForm.color_size" :options="{
                      placeholder: 'Select Color',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Color'
                        }
                      }
                    }" class="w-full" @change="get_sub_charni_list">
                                    <option value="0">Select Color</option>
                                    <option v-for="(item,index) in screenData.color_arr" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Size Type</label>
                            <div class="" id="charni_id">
                                <TomSelect v-model="charniForm.charni_id" :options="{
                      placeholder: 'Select Charni',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Charni'
                        }
                      }
                    }" class="w-full" @change="getCharniType">
                                    <option value="0">Select Size</option>
                                    <option v-for="charni in screenData.sub_charni_list" :key="charni.id" :value="charni.id">{{ charni.charni_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12">
                        <div>
                            <label for="update-profile-form-6" class="form-label py-1">Shape Type</label>
                            <div class="" id="shape">

                                <TomSelect v-model="charniForm.shape" :options="{
                      placeholder: 'Select Shape',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Shape'
                        }
                      }
                    }" class="w-full">
                                    <option value="0">Select Shape</option>
                                    <option v-for="charni in screenData.shape_arr" :key="charni.id" :value="charni.id">{{ charni.shape_name }}</option>
                                    <!-- <option value="3">Prince</option> -->
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <label for="pos-form-3" class="form-label">Stock Bhav</label>
                        <input id="stock_rate" type="number" name="stock_rate" v-model="charniForm.stock_rate" class="form-control flex-1" placeholder="" />
                    </div>
                    <div class="col-span-6">
                        <label for="pos-form-3" class="form-label">Max Bhav</label>
                        <input id="max_rate" type="number" name="max_rate" v-model="charniForm.max_rate" class="form-control flex-1" placeholder="" />
                    </div>
                </div>
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-32" @click="submitForm">
                        Save Details
                    </button>
                    <button type="button" v-if="charniForm.id != ''" class="btn btn-danger w-32 ml-1" @click="deleteItem">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Details saved successfully!</div>
            <div class="text-gray-600 mt-1">

            </div>
        </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Something went wrong!</div>
            <div class="text-gray-600 mt-1">
                Please check the fileld form.
            </div>
        </div>
    </div>
    <!-- END: Failed Notification Content -->
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
    required,
    minLength,
    maxLength,
    email,
    url,
    integer
} from '@vuelidate/validators'
import {
    useVuelidate
} from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'

export default defineComponent({
    setup() {
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const filter = reactive({
            charni_code: '',
            m_charni_size: '0',
            stone_type: '0',
            color_size: '0',
            shape: '0'
        })
        const charniForm = reactive({
            charni_id: '0',
            m_charni_size: '0',
            stone_type: '0',
            color_size: '0',
            shape: '0',
            stock_rate: '',
            max_rate: '',
            id: ''
        })
        const screenData = reactive({
            sub_charni_list: [],
            color_arr: [],
            shape_arr: []
        })
        const initTabulator = () => {

            let params = {
                charni_code: filter.charni_code ? window.btoa(filter.charni_code) : "",
                m_charni_size: filter.m_charni_size != "0" ? window.btoa(filter.m_charni_size) : "",
                stone_type: filter.stone_type != "0" ? window.btoa(filter.stone_type) : "",
                color_size: filter.color_size != "0" ? window.btoa(filter.color_size) : "",
                shape: filter.shape != "0" ? window.btoa(filter.shape) : "",
            }
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/details_charni_selling_rate',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxFiltering: true,
                headerSort: false,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [10, 20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: 'No matching records found',
                columns: [{
                        formatter: 'responsiveCollapse',
                        width: 40,
                        minWidth: 30,
                        hozAlign: 'center',
                        resizable: false,
                        headerSort: false
                    },

                    // For HTML table
                    {
                        title: 'Charni Name',
                        // minWidth: 10,
                        responsive: 0,
                        field: 'charni_name',
                        vertAlign: 'middle',
                        print: false,
                        download: false,

                    },
                    {
                      title: 'Stone Type',
                      minWidth: 100,
                        field: 'charni_type',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().stone_type == 1) {
                                return "Natural"
                            } else if (cell.getData().stone_type == 2) {
                                return "CVD"
                            } else if (cell.getData().stone_type == 3) {
                                return "HPHT"
                            } else if (cell.getData().stone_type == 4) {
                                return "D_CVD"
                            } else if (cell.getData().stone_type == 5) {
                                return "D_HPHT"
                              }
                            }
                          },
                          {
                              title: 'Color',
                              // minWidth: 10,
                              responsive: 0,
                              field: 'color_segment',
                              vertAlign: 'middle',
                              hozAlign: 'center',
                              print: false,
                              download: false,

                          },
                    {
                        title: 'Shape',
                        minWidth: 100,
                        field: 'shape_name',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        // formatter(cell) {
                        //     if (cell.getData().shape == 1) {
                        //         return "ROUND"
                        //     } else if (cell.getData().shape == 2) {
                        //         return "FANCY"
                        //     } else if (cell.getData().shape == 3) {
                        //         return "PRINCE"
                        //     }
                        // }
                    },
                    {
                        title: 'Stock Bhav',
                        minWidth: 100,
                        field: 'stock_rate',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false
                    },
                    {
                        title: 'Max Bhav',
                        minWidth: 100,
                        field: 'max_rate',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false
                    },
                    {
                        title: 'Status',
                        minWidth: 100,
                        field: 'active_status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            return `<div class=" ${
                cell.getData().active_status == 'Active' ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 'Active' ? 'Active' : 'Inactive'
                }
              </div>`
                        }
                    },
                    {
                        title: 'Actions',
                        minWidth: 100,
                        field: 'actions',
                        responsive: 1,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
                            cash(a).on('click', function () {
                                getDetails(cell.getData().id);
                            })

                            return a[0]
                        }
                    },

                    // For print format
                    {
                        title: 'Charni Name',
                        field: 'charni_name',
                        visible: false,
                        print: true,
                        download: true

                    },
                    {
                        title: 'Stone Type',
                        field: 'charni_type',
                        visible: false,
                        print: true,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().stone_type == 1) {
                                return "Natural"
                            } else if (cell.getData().stone_type == 2) {
                                return "CVD"
                            } else if (cell.getData().stone_type == 3) {
                                return "HPHT"
                            } else if (cell.getData().stone_type == 4) {
                                return "D_CVD"
                            } else if (cell.getData().stone_type == 5) {
                                return "D_HPHT"
                            }
                        }
                    },
                    {
                        title: 'Shape',
                        field: 'shape',
                        visible: false,
                        print: true,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().shape == 1) {
                                return "ROUND"
                            } else if (cell.getData().shape == 2) {
                                return "FANCY"
                            } else if (cell.getData().shape == 3) {
                                return "PRINCE"
                            }
                        }
                    },
                    {
                        title: 'Stock Bhav',
                        field: 'stock_rate',
                        visible: false,
                        print: true,
                        download: true
                    },
                    {
                        title: 'Max Bhav',
                        field: 'max_rate',
                        visible: false,
                        print: true,
                        download: true
                    },
                    {
                        title: 'Status',
                        field: 'active_status',
                        visible: false,
                        print: true,
                        download: true,
                        formatterPrint(cell) {
                            return cell.getValue() ? 'Active' : 'Inactive'
                        }
                    },

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        const get_sub_charni_list = async () => {

            if (charniForm.stone_type == "0") {
                screenData.sub_charni_list = [];
                charniForm.charni_id = "0";

                return;
            }

            let params = {
                stone_type: charniForm.stone_type,
                color_size: charniForm.color_size,
            }
            let promise = axios({
                url: "api/sub_charni_list",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.sub_charni_list = data.items;
                    charniForm.charni_id = "0";
                })
                .catch(_error => {
                    screenData.sub_charni_list = [];
                    charniForm.charni_id = "0";
                });

        }
        const get_sub_charni_lists = async () => {

            if (charniForm.stone_type == "0") {
                screenData.sub_charni_list = [];
                charniForm.charni_id = "0";

                return;
            }

            let params = {
                stone_type: charniForm.stone_type,
            }
            let promise = axios({
                url: "api/sub_charni_list",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.sub_charni_list = data.items;
                })
                .catch(_error => {
                    screenData.sub_charni_list = [];
                });

        }

        const shade_size_item_details = () => {

        let params = {
            stone_type: charniForm.stone_type
        }

        // Get Segment Details
        let promise_url = 'api/dp_shade_size_items';

        let promise = axios({
            url: promise_url,
            data: params,
            method: "POST",
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
                screenData.color_arr = data.items
                charniForm.color_size = "0"
            })
            .catch(_error => {
                screenData.color_arr = []
                charniForm.color_size = "0"
            });
        }
        const shape_list = () => {

        let params = {
            // stone_type: charniForm.stone_type
        }

        // Get Segment Details
        let promise_url = 'api/dp_shape';

        let promise = axios({
            url: promise_url,
            data: params,
            method: "POST",
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
                screenData.shape_arr = data.items
                charniForm.shape = "0"
            })
            .catch(_error => {
                screenData.shape_arr = []
                charniForm.shape = "0"
            });
        }
        const shade_size_item_filter_details = () => {

        let params = {
            stone_type: filter.stone_type
        }

        // Get Segment Details
        let promise_url = 'api/dp_shade_size_items';

        let promise = axios({
            url: promise_url,
            data: params,
            method: "POST",
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
                screenData.color_filter_arr = data.items
                charniForm.color_size = "0"
            })
            .catch(_error => {
                screenData.color_filter_arr = []
                charniForm.color_size = "0"
            });
        }

        const getDetails = async (id) => {
            console.log(id);
            const params = {
                "id": window.btoa(id),
            };

            let promise = axios({
                url: "api/list_charni_selling_rate",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    charniForm.charni_id = data.item_details.m_charni_id;
                    charniForm.m_charni_size = data.item_details.m_charni_size;
                    charniForm.stone_type = data.item_details.stone_type;
                    charniForm.shape = data.item_details.shape;
                    charniForm.stock_rate = data.item_details.stock_rate;
                    charniForm.max_rate = data.item_details.max_rate;
                    charniForm.id = data.item_details.id;
                    cash('#new-order-modal').modal('show')

                    get_sub_charni_lists()

                })
                .catch(_error => {

                });
        }

        const submitForm = async () => {

            let is_empty = false
            console.warn(is_empty)

            if (charniForm.charni_id == "0") {
                $('#charni_id').addClass('border border-theme-6')
                is_empty = true

            } else {
                $('#charni_id').removeClass('border border-theme-6')
            }
            if (charniForm.stone_type == "0") {
                $('#stone_type').addClass('border border-theme-6')
                is_empty = true

            } else {
                $('#stone_type').removeClass('border border-theme-6')
            }
            if (charniForm.color_size == "0") {
                $('#color_size').addClass('border border-theme-6')
                is_empty = true

            } else {
                $('#color_size').removeClass('border border-theme-6')
            }
            if (charniForm.shape == "0") {
                $('#shape').addClass('border border-theme-6')
                is_empty = true

            } else {
                $('#shape').removeClass('border border-theme-6')
            }
            if (charniForm.stock_rate == "") {
                $('#stock_rate').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#stock_rate').removeClass('border-theme-6')
            }
            if (charniForm.max_rate == "") {
                $('#max_rate').addClass('border-theme-6')
                is_empty = true

            } else {
                $('#max_rate').removeClass('border-theme-6')
            }
            console.warn(is_empty)

            if (is_empty == true) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fileds Are Empty!",
                    type: "warning",
                })
                return
            }

            const params = {
                "charni_id": charniForm.charni_id != "0" ? charniForm.charni_id : "",
                "m_charni_size": charniForm.m_charni_size != "0" ? charniForm.m_charni_size : "",
                "stone_type": charniForm.stone_type != "0" ? charniForm.stone_type : "",
                "shape": charniForm.shape != "0" ? charniForm.shape : "",
                "stock_rate": charniForm.stock_rate ? charniForm.stock_rate : "",
                "max_rate": charniForm.max_rate ? charniForm.max_rate : "",
                "id": charniForm.id ? charniForm.id : "",
            };

            let promise_url = '';

            if (charniForm.id != '' && charniForm.id != null) {
                promise_url = "api/edit_charni_selling_rate";
            } else {
                promise_url = "api/add_charni_selling_rate"
            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "NO") {
                      cash('#new-order-modal').modal('hide')
                                    initTabulator()
                        let promise_url_selling = '';

                        if (charniForm.id != '' && charniForm.id != null) {
                            promise_url_selling = "/edit_charni_selling_rate";
                        } else {
                            promise_url_selling = "/add_charni_selling_rate"
                        }

                        let promise = axios({
                            url: promise_url_selling,
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL_SELLING,
                            headers: {
                                Authorization: "Bearer " + AUTH_TOKEN
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });
                        return promise
                            .then(result => result.data)
                            .then(data => {

                                if (data.error == "NO") {
                                    swal("Success!", data.message, "success")

                                } else {
                                    swal("Warning!", data.message + ', On Server', "warning")

                                }
                                charniForm.charni_id = '0';
                                    charniForm.m_charni_size = '0';
                                    charniForm.stone_type = '0';
                                    charniForm.shape = '0';
                                    charniForm.stock_rate = '';
                                    charniForm.max_rate = '';
                                    charniForm.id = '';

                            })
                            .catch(_error => {
                              swal("Error!", "Data not Saved" + ", On Server", "error")
                            });
                    } else {
                        swal("Warning!", data.message, "warning")

                    }

                })
                .catch(_error => {
                    swal("Error!", "Something went wrong", "error")
                });
        }

        const deleteItem = async () => {
            const params = {
                "id": window.btoa(charniForm.id),
            };
            let promise = axios({
                url: "api/delete_charni_selling_rate",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    if (data.error == "NO") {
                        let promise = axios({
                            url: "/delete_charni_selling_rate",
                            method: "POST",
                            data: params,
                            baseURL: BASE_URL_SELLING,
                            headers: {
                                Authorization: "Bearer " + AUTH_TOKEN
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });
                        return promise
                            .then(result => result.data)
                            .then(data => {
                                if (data.error == "NO") {
                                    swal("Success!", data.message, "success")

                                    cash('#new-order-modal').modal('hide')
                                    initTabulator()
                                    charniForm.charni_id = '0';
                                    charniForm.m_charni_size = '0';
                                    charniForm.stone_type = '0';
                                    charniForm.shape = '0';
                                    charniForm.stock_rate = '';
                                    charniForm.max_rate = '';
                                    charniForm.id = '';
                                } else {
                                    swal("Warning!", data.message + ', On Server', "warning")

                                }

                            })
                            .catch(_error => {
                                swal("Error!", "Something went wrong" + ', On Server', "error")
                            });
                    } else {
                        swal("Warning!", data.message, "warning")

                    }

                })
                .catch(_error => {
                    swal("Error!", "Something went wrong.", "error")
                });
        }

        // On reset filter
        const onResetFilter = () => {
            filter.charni_code = ''
            filter.m_charni_size = '0'
            filter.stone_type = '0'
            filter.shape = '0'
            filter.color_size = '0'
            initTabulator()
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }
        const resetModalFileds = () => {
            charniForm.charni_id = '0';
            charniForm.m_charni_size = '0';
            charniForm.stone_type = '0';
            charniForm.shape = '0';
            charniForm.stock_rate = '';
            charniForm.max_rate = '';
            charniForm.id = '';
        }

        function getCharniType() {

            screenData.sub_charni_list.forEach(element => {
                if (element.id == charniForm.charni_id) {
                    charniForm.m_charni_size = element.size_type
                }
            });
        }

        onMounted(() => {
            initTabulator()
            reInitOnResizeWindow()
            shape_list()
        })

        return {
            tableRef,
            filter,
            charniForm,
            screenData,
            initTabulator,
            submitForm,
            deleteItem,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            resetModalFileds,
            getCharniType,
            get_sub_charni_list,
            shade_size_item_details,
            shade_size_item_filter_details
        }
    }
})
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
