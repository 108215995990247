<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Packets (Lots)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600">Avail. Packets</div>
                <div class="text-xl font-medium leading-8">{{ screenData.total.available_packets }}</div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-2">
            <div class="flex items-center justify-between">
              <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
              <div class="text-base text-gray-600">Avail. Weight</div>
              <div class="text-xl font-medium leading-8">{{ screenData.total.total_available_weight ? $h.myFloat(screenData.total.total_available_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-2">
            <div class="flex items-center justify-between">
              <div class="text-base text-gray-600">Total Packets</div>
              <div class="text-xl font-medium leading-8">{{ screenData.total.total_packets }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-2">
            <div class="flex items-center justify-between">
              <div class="text-base text-gray-600">Total Weight</div>
              <div class="text-xl font-medium leading-8">{{ screenData.total.total_packet_weight ? $h.myFloat(screenData.total.total_packet_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div> -->
        </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col">
        <form id="tabulator-html-filter-form" class="flex flex-wrap sm:mr-2">
          <div class="sm:flex items-center sm:mr-2">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0"
            >
              <option value="packet_code">Packet No</option>
              <option value="barcode">Barcode</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-2 mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <input
              id="scan_lot"
              class="xl:w-60 w-40"
              type="text"
              :class="{'form-control':true}"
              placeholder="Scan Barcode"
              v-model="screenData.scan_lot_no"
              @change="scanAndAddPack"
              autocomplete="off"
            />
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>
        <div class="flex flex-wrap items-center sm:mr-4 mt-2">
          <div class="mt-2 xl:mt-0">
            <TomSelect
              v-model="screenData.kapan_id"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan'
                  }
                }
              }"
              class="w-40"
              @change="getPacketsandTotal"
            >
            <option value="0">Select Kapan</option>
            <option v-for="kapan in screenData.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
            </TomSelect>
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <TomSelect
              v-model="screenData.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }"
              class="w-40"
              @change="getPacketsandTotal"
            >
            <option value="0">Select Status</option>
            <option value="1">On Table </option>
            <option value="2">In Process</option>
            </TomSelect>
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <TomSelect
              v-model="screenData.color_id"
              :options="{
                placeholder: 'Select Color',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Color'
                  }
                }
              }"
              class="w-40"
              @change="getPacketsandTotal"
            >
            <option value="0">Select Color</option>
            <option v-for="color in screenData.colors" :key="color.id" :value="color.id">{{color.shade_code}}</option>
            </TomSelect>
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <TomSelect
              v-model="screenData.shape_id"
              :options="{
                placeholder: 'Select Shape',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Shape'
                  }
                }
              }"
              class="w-40"
              @change="getPacketsandTotal"
            >
            <option value="0">Select Shape</option>
            <option v-for="shape in screenData.shapes" :key="shape.id" :value="shape.id">{{shape.shape_code}}</option>
            </TomSelect>
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <TomSelect
              v-model="screenData.purity_id"
              :options="{
                placeholder: 'Select Purity',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Purity'
                  }
                }
              }"
              class="w-40"
              @change="getPacketsandTotal"
            >
            <option value="0">Select Purity</option>
            <option v-for="purity in screenData.purities" :key="purity.id" :value="purity.id">{{purity.purity_code}}</option>
            </TomSelect>
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <TomSelect
              v-model="screenData.stone_id"
              :options="{
                placeholder: 'Select Stone',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stone'
                  }
                }
              }"
              class="w-40"
              @change="getPacketsandTotal"
            >
            <option value="0">Select Stone</option>
            <option v-for="stone in screenData.stones" :key="stone.id" :value="stone.id">{{stone.stone_code}}</option>
            </TomSelect>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'packet_code',
      type: 'like',
      value: ''
    })

    const screenData = reactive({
      scan_lot_no: '',
      kapan_id : '0',
      stone_id : '0',
      color_id : '0',
      shape_id : '0',
      purity_id : '0',
      status : '0',
      kapans : [],
      colors : [],
      shapes : [],
      stones : [],
      purities : [],
      total : []
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/api/list_packets`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          kpn_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
          color_id : screenData.color_id != '0' ? window.btoa(screenData.color_id) : '',
          shape_id : screenData.shape_id != '0' ? window.btoa(screenData.shape_id) : '',
          stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
          purity_id : screenData.purity_id != '0' ? window.btoa(screenData.purity_id) : '',
          status : screenData.status != '0' ? window.btoa(screenData.status) : ''
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Packet No',
            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            minWidth:160,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

            },
            formatter(cell) {
              if(cell.getData().status == 1){
              return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().packet_code
              }else{
                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().packet_code
              }
            }
          },

          {
            title: 'Weight',
            field: 'weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          }, {
            title: 'Cr. Weight',
            field: 'cr_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Ex. Weight',
            field: 'ex_weight',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape_name',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Color',
            field: 'color_name',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Purity ',
            field: 'purity_name',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            field: 'active_status',

            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().active_status == "Active"){
              return `<div class="flex items-center lg:justify-center text-theme-9">
                N/A
              </div>`
              }else{
                return `<div class="flex items-center lg:justify-center text-theme-6">
                N/A
              </div>`
              }
            }
          },
          {
            title: 'Planning',
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              let plan_user = ``;

              if(cell.getData().planning_file == "" || cell.getData().planning_file == null){
              plan_user =  `
                <i data-feather="user" class="w-6 h-6 font-bold text-theme-7 mr-1"></i>
              `
              }else if(cell.getData().planning_file != "" && cell.getData().planning_file != null && cell.getData().c_change != 1){
                plan_user =  `
                <i data-feather="user-check" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
              `
              }else{
                plan_user =  `
                <i data-feather="user-x" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
              `
              }
              let check_user = ``;

              if(cell.getData().c_planning_file == "" || cell.getData().c_planning_file == null){
              check_user =  `
                <i data-feather="user" class="w-6 h-6 font-bold text-theme-7 mr-1"></i>
              `
              }else if(cell.getData().c_planning_file != "" && cell.getData().c_planning_file != null && cell.getData().mc_change != 1){
                check_user =  `
                <i data-feather="user-check" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
              `
              }else{
                check_user =  `
                <i data-feather="user-x" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
              `
              }
              let main_check = ``;

              if(cell.getData().mc_planning_file == "" || cell.getData().mc_planning_file == null){
              main_check =  `
                <i data-feather="user" class="w-6 h-6 font-bold text-theme-7 mr-1"></i>
              `
              }else if(cell.getData().mc_planning_file != "" && cell.getData().mc_planning_file != null){
                main_check =  `
                <i data-feather="user-check" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
              `
              }

              let error_icon = ``;
              if(cell.getData().planning_error != "" && cell.getData().planning_error != null){
              error_icon =  `
                <i data-feather="codesandbox" class="w-6 h-6 font-bold text-theme-6 mr-1" title="`+ cell.getData().planning_error +`"></i>
              `
              }

              return `<div class="flex items-center lg:justify-center">` + plan_user + check_user + main_check + error_icon +`</div>`;
            }
          },
          {
            title: '',
            field: '',
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {

            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    const getKapanDetails = () => {
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans = data.items;
        })
        .catch(_error => {

        });
    }
    const getColorsDetails = () => {
      let promise = axios({
        url: "api/dp_color",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.colors = data.items;
        })
        .catch(_error => {

        });
    }
    const getStoneDetails = () => {
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.stones = data.items;
        })
        .catch(_error => {

        });
    }
    const getShapeDetails = () => {
      let promise = axios({
        url: "api/dp_shape",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.shapes = data.items;
        })
        .catch(_error => {

        });
    }
    const getPurityDetails = () => {
      let promise = axios({
        url: "api/dp_purity",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.purities = data.items;
        })
        .catch(_error => {

        });
    }
    const total_packets = () => {
      let params = {
        kpn_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        color_id : screenData.color_id != '0' ? window.btoa(screenData.color_id) : '',
        shape_id : screenData.shape_id != '0' ? window.btoa(screenData.shape_id) : '',
        purity_id : screenData.purity_id != '0' ? window.btoa(screenData.purity_id) : '',
        status : screenData.status != '0' ? window.btoa(screenData.status) : '',
        stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : ''
      }
      let promise = axios({
        url: "api/total_nd_available_packets_by_user",
        method: "POST",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.total = data.total;
        })
        .catch(_error => {

        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    //Edit user
    const editUser = (id) => {
      console.log(id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'first_name'
      filter.type = 'like'
      filter.value = ''
      screenData.kapan_id = '0'
      screenData.color_id = '0'
      screenData.shape_id = '0'
      screenData.stone_id = '0'
      screenData.purity_id = '0'
      screenData.status = '0'
      initTabulator()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }



    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'buyer_list.xlsx', {
        sheetName: 'Buyers'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
      if(codelength > 11){
        const data = {
        barcode: screenData.scan_lot_no,
        };
        let url_post = '';
          if(screenData.scan_lot_no != ''){
          url_post = "api/get_packet_details_by_barcode"
        }else{
          url_post = "api/get_packet_details_by_barcode"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          if(data.status == 'error'){
            swal("Warning!", data.msg, "warning");
            screenData.scan_lot_no = '';
            document.querySelector('[id="scan_lot"]').focus();
          }else {
            let pack_details = data.packet_details;
            screenData.scan_lot_no = '';
            router.push("/purchase/rough-view/packet-detail/" + window.btoa(pack_details.id));
          }
          })
          .catch((_error) => {
            screenData.scan_lot_no = '';
            document.querySelector('[id="scan_lot"]').focus();
            return [];
          });
      }
    }
    function getPacketsandTotal() {
      initTabulator()
      total_packets()
    }

    onMounted(() => {
      total_packets()
      initTabulator()
      getKapanDetails()
      getColorsDetails()
      getShapeDetails()
      getStoneDetails()
      getPurityDetails()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      screenData,
      scanAndAddPack,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      editUser,
      initTabulator,
      getPacketsandTotal
    }

  }
})
</script>

