<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Tender Dashboard</h2>
            <!-- <a
              href=""
              class="ml-auto flex items-center text-theme-1 dark:text-theme-10"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </a> -->
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <ActivityIcon class="report-box__icon text-theme-10" />
                    <div class="ml-auto"></div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">
                    {{ dashboard_details.live_tenders }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">Live Tenders</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CheckCircleIcon class="report-box__icon text-theme-11" />
                    <div class="ml-auto"></div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">
                    {{ dashboard_details.active_tenders }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">Active Tender</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <InboxIcon class="report-box__icon text-theme-12" />
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="12% Higher than last month"
                      >
                        12% <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">
                    {{ dashboard_details.total_tenders }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">Total Tenders</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <MailIcon class="report-box__icon text-theme-9" />
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="22% Higher than last month"
                      >
                        22% <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">
                    {{ dashboard_details.total_bids }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">Total Bids</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-12 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Recent Bids</h2>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div class="flex flex-col xl:flex-row xl:items-center">
              <div class="flex"></div>
            </div>
            <div class="">
              <div class="grid grid-cols-12">
                <div class="col-span-6 md:col-span-3 xl:col-span-3 font-bold">
                  Tender Name
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-3 font-bold">
                  Company Name
                </div>

                <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold">
                  Packet No.
                </div>

                <div class="col-span-6 md:col-span-2 xl:col-span-2 font-bold">
                  Bid Price
                </div>

                <div class="col-span-6 md:col-span-3 xl:col-span-3 font-bold">
                  Bid Timestamp
                </div>
              </div>
              <div
                class="border mt-5"
                v-for="recent_item in recent_bids.bids"
                :key="recent_item.id"
              >
                <div class="grid grid-cols-12">
                  <div class="col-span-6 md:col-span-3 xl:col-span-3">
                    {{ recent_item.tender_name }}
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-3">
                    {{ recent_item.company_name }}
                  </div>

                  <div class="col-span-6 md:col-span-1 xl:col-span-1">
                    {{ recent_item.tender_item_id }}
                  </div>

                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    {{ recent_item.bid_price }}
                  </div>

                  <div class="col-span-6 md:col-span-3 xl:col-span-3">
                    {{ recent_item.bid_date_time }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  provide,
  reactive,
  onMounted
} from 'vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue'
import { BASE_URL } from '@/constants/config.js'

import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const userDetails = computed(() => store.state.user.currentUser)

    const dashboard_details = reactive({
      live_tenders: '-',
      active_tenders: '-',
      total_tenders: '-',
      total_bids: '-'
    })

    const recent_bids = reactive({
      bids: []
    })

    provide('bind[importantNotesRef]', (el) => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    const getTendersDetails = () => {
      const params = {}
      let promise = axios({
        url: 'api/get_dashboard_details',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then((result) => result.data)
        .then((data) => {
          dashboard_details.live_tenders = data.live_tenders
          dashboard_details.total_tenders = data.total_tenders
          dashboard_details.active_tenders = data.active_tenders
          dashboard_details.total_bids = data.total_bids
        })
        .catch((_error) => {})
    }

    const getRecentBids = () => {
      const params = {}
      let promise = axios({
        url: 'api/get_dashboard_recent_bid',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then((result) => result.data)
        .then((data) => {
          recent_bids.bids = data.recent_bids
        })
        .catch((_error) => {})
    }

    const checkPermission = () => {
      console.log(userDetails.value.id)
      if (userDetails.value.id == 8) {
        router.push('/app/app-masters')
      }
    }

    onMounted(() => {
      checkPermission()
      getTendersDetails()
      getRecentBids()
      setInterval(getRecentBids, 30000)
    })

    return {
      salesReportFilter,
      dashboard_details,
      getTendersDetails,
      userDetails,
      recent_bids,
      getRecentBids,
      prevImportantNotes,
      nextImportantNotes
    }
  }
})
</script>
<style scoped>
.border {
  margin: 0;
  padding: 3px;
}
#tj-datetime-input input {
  border: 0;
}
.datetime-picker input:active {
  border: 0;
}
.datetime-picker input:focus {
  border: 0;
}
</style>