<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Issue Lot</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Issue Details</h2>

          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-8" class="form-label"
                    >Issue to</label
                  >
                  <select id="update-profile-form-8" class="form-select" v-model="issue_type" @change="changeIssueType">
                    <option value="1" selected>Process</option>
                    <option value="2">Department Manager</option>
                    <option value="3">Outside Party</option>
                  </select>
                </div>

                <div class="mt-3">
                  <label for="scan_lot" class="form-label"
                    >Scan Lot Barcode</label
                  >
                  <input
                    id="scan_lot"
                    type="text"
                    :class="{'form-control':true,'loading':scanload}"
                    placeholder="Scan Barcode"
                    v-model="scan_lot_no"

                    @change="scanAndAddPack"
                    autocomplete="off"
                  />
                </div>

                <div class="mt-3">
                  <label for="scan_lot_by_no" class="form-label"
                    >Add by Lot No</label
                  >
                  <input
                    id="scan_lot_by_no"
                    type="text"
                    :class="{'form-control':true,'loading':scanload_by_no}"
                    placeholder="20_1-20_10"
                    v-model="by_lot_no"
                    :readonly="scanload_by_no == true"
                    @change="scanAndAddPackByNo"
                    autocomplete="off"
                  />
                </div>
                <div class="mt-3">
                  <label style="font-style:italic">E.g. DK1_A_1_1-DK1_A_1_10,DK1_A_1_17 or 1_10-1_30</label>
                </div>

              </div>
              <div class="col-span-12 xl:col-span-6">
                <div class="" v-if="issue_type == 1 || issue_type == 3">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Process</label
                  >
                  <TomSelect
                v-model="process"
                :options="{
                  placeholder: 'Select Process '
                }"
                class="w-full"
                @change="changeProcess"
              >
              <option value="">Select Process</option>
              <option v-for="proces_item in process_ar" :key="proces_item.id" :value="proces_item.id">{{proces_item.process_name}}</option>
              </TomSelect>
                </div>

                <div class="mt-3" v-if="issue_type == 1">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Karigar</label
                  >
                  <TomSelect
                    v-model="karigar"
                    :options="{
                      placeholder: 'Select Karigar '
                    }"
                    class="w-full"
                  >
                  <option value="">Select Karigar</option>
                  <option v-for="karigar_item in karigar_ar" :key="karigar_item.id" :value="karigar_item.id">{{karigar_item.first_name}} {{karigar_item.last_name}}</option>
                  </TomSelect>
                </div>

                <div class="" v-if="issue_type == 2">
                  <label for="update-profile-form-7" class="form-label"
                      >Select Manager</label>
                    <TomSelect
                    v-model="selected_managers"
                    :options="{
                    placeholder: 'Select Manager',
                    }"
                    class="w-full">
                  <option value="">Select Manager</option>
                  <option v-for="manager_item in managers_arr" :key="manager_item.id" :value="manager_item.id">{{manager_item.first_name}} ({{manager_item.role_name}})</option>
                  </TomSelect>
                </div>

                <div class="mt-3" v-if="issue_type == 3">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Party</label
                  >
                  <TomSelect
                    v-model="process_party"
                    :options="{
                      placeholder: 'Select Party '
                    }"
                    class="w-full"
                  >
                  <option value="">Select Party</option>
                  <option v-for="party_item in party_ar" :key="party_item.id" :value="party_item.id">{{party_item.first_name}} {{party_item.last_name}} ({{party_item.company_name}})</option>
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-12">
                <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Selected Lot No</h2>
                </div>
                <div class="p-5">
                  <div class="grid grid-cols-12 gap-x-5">
                    <div class="col-span-12">
                      <div class="border">
                        <div class="grid grid-cols-12 pl-5 pr-5 pb-2 mt-3 sticky top-0" style="background-color:#fff;">

                          <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                            <p>Sr. No</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>Lot No</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>Weight(Carat)</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>Purity</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>Ex. Weight</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>Color</p>
                          </div>

                        </div>
                        <div class="grid grid-cols-12 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;" v-if="total_packets > 0">

                          <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                            <p>Total</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>{{ total_packets }}</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>{{ $h.myFloat(total_weight) }}</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p></p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>{{ $h.myFloat(total_ex_weight) }}</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p></p>
                          </div>

                        </div>
                        <div class="border" v-for="field in fields" :key="field.id">

                          <div class="grid grid-cols-12">

                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                              <label class="p-2 mt-2">{{field.id}}</label>
                            </div>
                            <div class="col-span-6 md:col-span-2 xl:col-span-2">
                              <label class="p-2 mt-2">{{field.pack_no}}</label>
                            </div>
                            <div class="col-span-6 md:col-span-2 xl:col-span-2">
                              <label class="p-2 mt-2">{{field.weight}}</label>
                            </div>
                            <div class="col-span-6 md:col-span-2 xl:col-span-2">
                              <label class="p-2 mt-2">{{field.purity_name}}</label>
                            </div>
                            <div class="col-span-6 md:col-span-2 xl:col-span-2">
                              <label class="p-2 mt-2">{{field.ex_weight}}</label>
                            </div>
                            <div class="col-span-6 md:col-span-2 xl:col-span-2">
                                <label class="p-2 mt-2">{{field.color_name}}</label>
                            </div>

                            <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3">
                            <button type="button" class="text-theme-6 flex items-center" @click="removeField(field.id)" >
                              <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                            </button>
                            </div>

                          </div>
                        </div>
                        <div class="grid grid-cols-12 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;" v-if="total_packets > 0">

                          <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                            <p>Total</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>{{ total_packets }}</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>{{ $h.myFloat(total_weight) }}</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p></p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p>{{ $h.myFloat(total_ex_weight) }}</p>
                          </div>
                          <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                            <p></p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto" @click="submitRoughForm" :disabled="form_status">
                Save
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!form_status"/>
              </button>

            </div>

        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tender_update_id : '',
      transaction_no: 'Auto',

      stone_type: '',
      issue_type: 1,
      issue_block: 3,
      form_status:false,
      kapan_id: "",

      process:'',
      process_ar:[],
      karigar:'',
      process_party:'',
      scan_lot_no:'',
      by_lot_no:'',
      karigar_ar:[],
      kapan_ar:[],
      party_ar:[],
      series_ar:[],

      selected_series:'',
      managers_arr:[],
      selected_managers:'',
      vendors:[],
      stones:[],
      status: '1',
      scanload:false,
      scanload_by_no:false,
      total_scan_pack:0,
      fields: [],

      total_weight: 0,
      total_ex_weight: 0,
      total_packets: 0,

      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {

  },
  mounted(){
  this.getRoughDetails(this.rough_id);
  this.getRoughVendor();
  this.getProcess();
  this.getManagers();

  },

  methods: {
    changeIssueType(){
      this.fields = [];
      this.total_scan_pack = 0;
    },
    changeProcess(){
      let _this = this;
      _this.getKarigar();
      _this.getProcessParty();
    },
    async getRoughDetails(rough_id) {
      let _this = this;
      const params = {
        rough_id: rough_id
      };
      let promise = axios({
        url: "api/get_rough_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          let rough_detail = data.item_details;
          this.tender_name = rough_detail.tender_name;
          this.lot_no = rough_detail.lot_no;
          this.price= rough_detail.price;
          this.stone_type= rough_detail.stone_type;
          this.brokerage_type = rough_detail.brokerage_type;
          this.brokerage_value = rough_detail.brokerage_value;
          this.billing_period = rough_detail.billing_period;
          this.est_makeable = rough_detail.est_makeable;
          this.rough_weight = rough_detail.rough_weight;
          this.total_pcs = rough_detail.total_pcs;
          this.rough_vendor = rough_detail.vendor_id;

        })
        .catch(_error => {

        });
    },
    async getRoughVendor() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_account_vendor",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.vendors = data.items;

        })
        .catch(_error => {

        });
    },
    async getProcess() {
      let _this = this;
      const params = {
        req_from: 'packets'
      }
      let promise = axios({
        url: "api/dp_process_by_user_packets",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.process_ar = data.items;
        })
        .catch(_error => {

        });
    },
    async getManagers() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_managers_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.managers_arr = data.items;

        })
        .catch(_error => {

        });
    },
    async getKarigar() {
      let _this = this;
      const params = {
        process: this.process

      };
      let promise = axios({
        url: "api/dp_karigar_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.karigar_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getProcessParty() {
      let _this = this;
      const params = {
        process: this.process
      };
      let promise = axios({
        url: "api/dp_party_by_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.party_ar = data.items;

        })
        .catch(_error => {

        });
    },

    async submitRoughForm(){
      this.form_status = true;
      //let tag_ar = [this.vueSelectForm.multiple];
      var total_scanned_pack = this.fields.length;
      if(total_scanned_pack < 1 ){
          this.scan_lot_no = "";
          this.$swal("Error!", "Packet List can not be empty, Scan barcode to add packet!", "error");
          this.form_status = false;
          return;
      }
      if(this.issue_type == 1){
        this.selected_managers = '';
        if(this.process == ''){
          this.$swal("Warning!", "Select Process", "warning");
          this.form_status = false;
          return;
        }
        if(this.karigar == ''){
          this.$swal("Warning!", "Select Karigar", "warning");
          this.form_status = false;
          return;
        }
      }
      if(this.issue_type == 2){
        this.process = '';
        if(this.selected_managers == ''){
          this.$swal("Warning!", "Select Manager", "warning");
          this.form_status = false;
          return;
        }
      }

      if(this.issue_type == 3){
        this.selected_managers = '';
        this.karigar = '';
        if(this.process == ''){
          this.$swal("Warning!", "Select Process", "warning");
          this.form_status = false;
          return;
        }
        if(this.process_party == ''){
          this.$swal("Warning!", "Select Party", "warning");
          this.form_status = false;
          return;
        }
      }

      const data = {
        issue_type: this.issue_type,
        bunch_type: this.issue_block,
        karigar: this.karigar,
        process_party: this.process_party,
        process_id: this.process,
        manager_id: this.selected_managers,
        packet_details: this.fields,
        id:this.rough_id
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/edit_packet_issue_trans"
      }else{
         url_post = "api/add_packet_issue_trans"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/app-process/packet/issue");
        }, 500)
        })
        .catch((_error) => {
          return [];
        });
    },
    async scanAndAddPack(){
      console.log(this.scan_lot_no);
      if(this.issue_type == 1 || this.issue_type == 3){
        if(this.process == ''){
          this.$swal("Warning!", "Select Process First!", "warning");
          this.scanload = false;
          this.scan_lot_no = '';
          return;
        }
      }else{
        this.process = '';
      }
      let codelength = this.scan_lot_no.trim().length;
      if(codelength > 11){
        this.scanload = true;
        //Check already in array
        var i = this.fields.length;
              while(i--){
              if(this.fields[i]['barcode'] == this.scan_lot_no ){
                this.scan_lot_no = "";
                  this.$swal("Warning!", "Already in list, Scan other code!", "warning");
                  this.scanload = false;
                  return;
              }
        }
        //Check type selected

        //get lot details
        const data = {
        barcode: this.scan_lot_no,
        process_id: this.process,
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_scanned_packet_details"
        }else{
          url_post = "api/get_scanned_packet_details"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          if(data.packet_details == 'No'){
              this.$swal("Warning!", data.error_msg, "warning");
              this.scanload = false;
              document.querySelector('[id="scan_lot"]').focus();
          }else if(data.packet_details == 'process'){
              this.$swal("Warning!", "Scanned barcode is already in process", "warning");
              this.scanload = false;
              document.querySelector('[id="scan_lot"]').focus();
          }else {
            let pack_details = data.packet_details;
            let is_add = true
            if(this.fields.length == 0) {
              this.kapan_id = pack_details.kapan_id
            } else {

              // Validate Kapan Scanned Packet
              if(pack_details.kapan_id != this.kapan_id && this.process == 41) {
                is_add = false
                this.$swal("Warning!", "Scanned barcode is not in same kapan", "warning");
              }
            }

            if(is_add == true) {

              this.total_scan_pack = this.total_scan_pack + 1;
              this.fields.push({ id:this.total_scan_pack,weight: pack_details.cr_weight,purity: pack_details.purity,purity_name: pack_details.purity_name,ex_weight: pack_details.ex_weight,color: pack_details.color,color_name: pack_details.color_name,pack_no: pack_details.packet_code,barcode: pack_details.barcode_no});
              this.scanload = false;
              this.total_weight = parseFloat(pack_details.cr_weight) + parseFloat(this.total_weight)
              this.total_ex_weight = parseFloat(pack_details.ex_weight) + parseFloat(this.total_ex_weight)
              this.total_packets += 1;
              document.querySelector('[id="scan_lot"]').focus();
            } else {
              this.scanload = false;
              this.scan_lot_no = "";
            }
          }
          })
          .catch((_error) => {
            this.scanload = false;
            document.querySelector('[id="scan_lot"]').focus();
            return [];
          });

      }
    },

    async scanAndAddPackByNo(){
      if(this.issue_type == 1){
        if(this.process == ''){
          this.$swal("Warning!", "Select Process First!", "warning");
          this.scanload = false;
          this.scan_lot_no = '';
          return;
        }
      }else{
        this.process = '';
      }

      this.scanload_by_no = true;

      //get lot details
      const data = {
        by_lot_no: this.by_lot_no,
        process_id: this.process,
      };

        let url_post = '';
        if(this.by_lot_no != ''){
          url_post = "api/get_scanned_packet_details_by_no"
        }else{
          url_post = "api/get_scanned_packet_details_by_no"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);

          if(data.size == 0){
              this.$swal("Warning!", "No packet available in selected range <br>Or Packet is Blocked!", "warning");
              this.scanload_by_no = false;
              document.querySelector('[id="scan_lot_by_no"]').focus();
          }else {
            let pack_details = data.items;

            var j = pack_details.length;
            if(j > 0 ){
              while(j--){
                  var i = this.fields.length;
                  let already_in_list = false;
                  let different_kapan = false;

                  if(i == 0) {
                    this.kapan_id = pack_details[j].kapan_id
                  } else {

                    while(i--){
                      if(this.fields[i]['barcode'] == pack_details[j].barcode_no ){
                          already_in_list = true;
                      }

                      if(pack_details.kapan_id != this.kapan_id && this.process == 41) {
                        different_kapan = true
                        }
                    }
                  }
                  if(already_in_list == false && different_kapan == false){
                      this.total_scan_pack = this.total_scan_pack + 1;
                      this.fields.push({ id:this.total_scan_pack,
                      weight: pack_details[j].cr_weight,
                      purity: pack_details[j].purity,
                      purity_name: pack_details[j].purity_name,
                      ex_weight: pack_details[j].ex_weight,
                      color: pack_details[j].color,
                      color_name: pack_details[j].color_name,
                      pack_no: pack_details[j].packet_code,
                      barcode: pack_details[j].barcode_no});
                      this.total_weight = parseFloat(pack_details[j].cr_weight) + parseFloat(this.total_weight)
                      this.total_ex_weight = parseFloat(pack_details[j].ex_weight) + parseFloat(this.total_ex_weight)
                      this.total_packets += 1;
                  }
              }
            }
            this.scanload_by_no = false;
            document.querySelector('[id="scan_lot_by_no"]').focus();
          }
          })
          .catch((_error) => {
            this.scanload_by_no = false;
            document.querySelector('[id="scan_lot_by_no"]').focus();
            return [];
          });



    },
    removeField(id) {

       this.$swal({
        title: "Are you sure?",
        text: "You are about to remove a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){
                this.total_weight = parseFloat(this.total_weight) - parseFloat(this.fields[i]['cr_weight'])
                this.total_ex_weight = parseFloat(this.total_ex_weight) - parseFloat(this.fields[i]['ex_weight'])
                this.total_packets -= 1;
                this.fields.splice(i,1);
              }
            }

            if(this.fields.length == 0) {
              this.kapan_id = ""
            }
          }
      });
    },
  },

})
</script>
