<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
      <h1 class="text-xl font-medium mr-auto">Masters</h1>
    </div>

    <div class="pos intro-y grid grid-cols-12 gap-5 mt-2">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
          <h2 class="text-lg font-medium mr-auto">Diamond Properties</h2>
        </div>
       <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'stone-master' }" tag="div">
              <div class="font-medium text-base">Stone Type</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'shape-master' }" tag="div">
              <div class="font-medium text-base">Shape</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'color-master' }" tag="div">
              <div class="font-medium text-base">Color</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'purity-master' }" tag="div">
              <div class="font-medium text-base">Purity</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'cut-master' }" tag="div">
              <div class="font-medium text-base">Cut</div>
            </router-link>
          </div>

          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'flourocent-master' }" tag="div">
              <div class="font-medium text-base">Flourocent</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'size-master' }" tag="div">
              <div class="font-medium text-base">Size</div>
            </router-link>
          </div> 
        </div>

        <div
          class="
            intro-y
            flex flex-col
            sm:flex-row
            items-center
            mt-4
            border-t
            pt-3
          "
        >
          <h2 class="text-lg font-medium mr-auto">Assort Masters</h2>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'assort-master' }" tag="div">
              <div class="font-medium text-base">Assortment Type</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'charni-master' }" tag="div">
              <div class="font-medium text-base">Charni Size (Manual Makable)</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'charni-master-planning' }" tag="div">
              <div class="font-medium text-base">Charni Size (Planning)</div>
            </router-link>
          </div>
        </div>

        <div
          class="
            intro-y
            flex flex-col
            sm:flex-row
            items-center
            mt-4
            border-t
            pt-3
          "
        >
          <h2 class="text-lg font-medium mr-auto">Rate Masters</h2>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'rapaport-rate' }" tag="div">
              <div class="font-medium text-base">Rapaport Rate</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'standered-rate' }" tag="div">
              <div class="font-medium text-base">Standered Rate</div>
            </router-link>
          </div>
           
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
         
            <div class="font-medium text-base">Discount</div>
          </div>
          
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
             <router-link :to="{ name: 'bonus-rate' }" tag="div">
              <div class="font-medium text-base">Bonus</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
           <router-link :to="{ name: 'penalty-rate' }" tag="div">
              <div class="font-medium text-base">Penalty</div>
            </router-link>
            
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <div class="font-medium text-base">Operator rate</div>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <div class="font-medium text-base">Cleaver Rate</div>
          </div>

          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <div class="font-medium text-base">Category Rate</div>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <div class="font-medium text-base">Labour Rate</div>
          </div>
        </div>

        <div
          class="
            intro-y
            flex flex-col
            sm:flex-row
            items-center
            mt-4
            border-t
            pt-3
          "
        >
          <h2 class="text-lg font-medium mr-auto">Account Masters</h2>
        </div> 
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
          <router-link :to="{ name: 'operator-master' }" tag="div">
              <div class="font-medium text-base">Manager Accounts</div>
            </router-link>
            
          </div>
           <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
          <router-link :to="{ name: 'planner-master' }" tag="div">
              <div class="font-medium text-base">Planner Accounts</div>
            </router-link>
            
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
          <router-link :to="{ name: 'cleaver-master' }" tag="div">
              <div class="font-medium text-base">Cleaver Accounts</div>
            </router-link>
            
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
          <router-link :to="{ name: 'employe-master' }" tag="div">
            <div class="font-medium text-base">Karigar Accounts</div>
          </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
             <router-link :to="{ name: 'buyer-master' }" tag="div">
              <div class="font-medium text-base">Buyer Accounts</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
             <router-link :to="{ name: 'vendors-master' }" tag="div">
              <div class="font-medium text-base">Vendor Accounts</div>
            </router-link>
          </div>

          
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'party-master' }" tag="div">
              <div class="font-medium text-base">Party Accounts</div>
            </router-link>
          </div> 
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'broker-master' }" tag="div">
              <div class="font-medium text-base">Broker Accounts</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
          <router-link :to="{ name: 'manufacturer-master' }" tag="div">
              <div class="font-medium text-base">Manufacturer Accounts</div>
            </router-link>
            
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'polish-party-master' }" tag="div">
              <div class="font-medium text-base">Polish Party</div>
            </router-link>
          </div> 
        </div>  -->

        <div
          class="intro-y flex flex-col sm:flex-row items-center mt-4 border-t pt-3"
        >
          <h2 class="text-lg font-medium mr-auto">Tender Masters</h2>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <router-link
            tag="div"
            v-if="userDetails.id != 8"
            :to="{ name: 'tenders' }"
            class="col-span-6 sm:col-span-3 2xl:col-span-3 box p-5 cursor-pointer zoom-in"
          >
            <div class="font-medium text-base">Tender</div>
          </router-link>

          <router-link
            tag="div"
            :to="{ name: 'tenderbuyers' }"
            class="col-span-6 sm:col-span-3 2xl:col-span-3 box p-5 cursor-pointer zoom-in"
          >
            <div class="font-medium text-base">Tender Client</div>
          </router-link>
        </div>

        <!-- <div
          class="
            intro-y
            flex flex-col
            sm:flex-row
            items-center
            mt-4
            border-t
            pt-3
          "
        >
          <h2 class="text-lg font-medium mr-auto">Other Masters</h2>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'process-master' }" tag="div">
              <div class="font-medium text-base">Process</div>
            </router-link>
          </div>
          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'ghat-master' }" tag="div">
              <div class="font-medium text-base">Ghat Type</div>
            </router-link>
          </div>

          <div
            class="
              col-span-6
              sm:col-span-3
              2xl:col-span-3
              box
              p-5
              cursor-pointer
              zoom-in
            "
          >
            <router-link :to="{ name: 'reason-master' }" tag="div">
              <div class="font-medium text-base">Reason</div>
            </router-link>
          </div> 
        </div> -->
      </div>
      <!-- END: Item List -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)

    const printDetails = () => {
      console.log(userDetails.first_name)
    }
    onMounted(() => {
      printDetails()
    })

    return {
      userDetails
    }
  }
})
</script>
