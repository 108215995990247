<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Polish Packet Receive (Party)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="screenData.scan_lot_no"
                    :class="{'form-control':true,'loading':screenData.scanload}"
                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Scaned Packets</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <label for="scan_lot_no" class="form-label mt-2 mr-2"
                    >Select Polish Type</label
                  >
              <TomSelect
                v-model="screenData.pc_polish_type"
                :options="{
                  placeholder: 'Select Polish Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Polish Type'
                    }
                  }
                }"
                class="w-full mr-5"
                style="width:200px"
              >
              <option value="">Select Polish Type</option>
              <option value="1">Manual</option>
              <option value="2">Auto</option>
              </TomSelect>

              <label for="scan_lot_no" class="form-label mt-2 mr-2"
                    >Select Polish Checker</label
                  >
              <TomSelect
                v-model="screenData.pc_karigar_id"
                :options="{
                  placeholder: 'Select Polish Checker',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Polish Checkers'
                    }
                  }
                }"
                class="w-full mr-5"
                style="width:200px"
              >
              <option value="">Select Polish Checker</option>
              <option v-for="party in screenData.karigar_ar" :key="party.id" :value="party.id">{{party.first_name}} {{party.last_name}}</option>
              </TomSelect>
          <button
           class="btn btn-primary shadow-md mr-2" @click="takePolishReturn" :disabled="screenData.return_progress">
           Take Return
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress"/>
          </button>
      </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                </div>
               
              </div>
            <div class="col-span-4 xl:col-span-4">
               <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Weight (Ex PW). :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}}  </label>
                </div>
            </div>
            <div class="col-span-4 xl:col-span-4 text-right">
               <div>
                <button
           class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect" :disabled="screenData.delet_row">
           Remove Selected Row
          </button>
                </div>
            </div>

              <div class="col-span-12 ">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 500px"
              :columnDefs="columnDefs.value"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @grid-ready="onGridReady"
              :rowMultiSelectWithClick="true"
              @selection-changed="onGridRowSelect"
              :overlayNoRowsTemplate="overlayNoRowsTemplate"
            >
            </ag-grid-vue>
              </div>

            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted,inject } from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default defineComponent({
  components: {
   AgGridVue,
   ShapeRenderer
 },
  setup() {
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      scanload: false,
      pc_karigar_id:'',
      pc_polish_type:'',
      karigar_ar:[],
      return_progress:false,
      delet_row:true,
      scan_lot_no:'',
      total_scan_pack:0,
      total_scan_pack_wt:0,
      shapes:['ROUND','PRINCE',"EMERALD","CUSHION","RADIANT","MARQUISE","PEAR","OVEL","HEART","ROSE","CHOKI","TRIANGLE","CHAKRI","ROSE-PAN",
      "ROSE-TRIANGLE","ROSE-OVEL","ROSE-EMERALD","ROSE-MARQUISE","ROSE-HEART","ROSE-CUSHION"],
      colors:['D','E','F','G','H','I','J','K','L','M'],
      purity:['IF','VVS1','VVS2','VS1','VS2','SI1','SI2','SI3','I1','I1-','I2','I2-','I3','I3-']

    })

    const onGridReady = (params) => {
     gridApi.value = params.api;
    };

   const rowData = reactive({
    value:[]
   });

   const overlayNoRowsTemplate = 'Scan packets for polish receive!'

   const columnDefs = reactive({
     value: [
          { headerName: "#",field: 'sr_no',maxWidth: 60, },
          { headerName: "Packet No",field: 'lot_no',minWidth: 160 },
          { headerName: "Cr Wt",field: 'cr_wt' },
          { headerName: "Shape",
            field: 'shape',
            },
            { headerName: "Color",field: 'color',
            
            },
          { headerName: "Purity",field: 'purity',
            },
          { headerName: "Exp PW",field: 'ex_pw'},
          {
            headerName: "Lost",
            field: "lost_pc",
            cellRenderer: function(params) { 
                var input = document.createElement('input');
                input.type="checkbox";
                input.checked=params.value;
                input.addEventListener('click', function (event) {
                    params.value=!params.value;
                    params.node.data.lost_pc = params.value;
                });
                return input;
            }
          },{
              headerName: "Reject",
              field: "reject_pc",
              cellRenderer: function(params) { 
                  var input = document.createElement('input');
                  input.type="checkbox";
                  input.checked=params.value;
                  input.addEventListener('click', function (event) {
                      params.value=!params.value;
                      params.node.data.reject_pc = params.value;
                  });
                  return input;
              }
          }
     ],
   });


   const defaultColDef = {
     sortable: true,
     filter: true,
     flex: 1
   };

  const getKarigar = () => {
      let params = {
        process: 33
      }
      let promise = axios({
        url: "api/dp_karigar_by_user",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.karigar_ar = data.items

        })
        .catch(_error => {

        });
    }
    //Edit user
    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
      if(codelength > 11){
        screenData.scanload = true;
        //Check already in array
        var i = rowData.value.length;
              while(i--){
              if(rowData.value[i]['barcode'] == screenData.scan_lot_no ){
                screenData.scan_lot_no = "";
                swal("Warning!", "Already in list, Scan other packet!", "warning");
                screenData.scanload = false;
                return;
              }
        }

        //get lot details
        const data = {
        barcode: screenData.scan_lot_no,
        };
        let url_post = '';
          if(screenData.scan_lot_no != ''){
          url_post = "api/get_packet_details_for_polish_recive"
        }else{
          url_post = "api/get_packet_details_for_polish_recive"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          if(data.packet_details == 'No'){
              swal("Warning!", data.error_msg, "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot"]').focus();
          }else if(data.packet_details == 'process'){
              swal("Warning!", "Scanned barcode is already in process", "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot"]').focus();
          }else {
            let pack_details = data.packet_details;
            let plan_details = data.last_plan;
            let last_history = data.last_history;
            if(plan_details == null){
              swal("Warning!", "Packet does not have main piece blocking details", "warning");
              screenData.scanload = false;
              return;
            }
              //screenData.total_scan_pack = screenData.total_scan_pack + 1;
              
              rowData.value.push(
              {
                "sr_no":screenData.total_scan_pack + 1,
                "lot_no":pack_details.packet_code,
                "barcode": pack_details.barcode_no,
                "packet_id": pack_details.id,
                "last_history_id": last_history.id,
                "cr_wt" : pack_details.cr_weight,
                "shape" : plan_details.shape,
                "purity" : plan_details.clarity,
                "color" : plan_details.color,
                "ex_pw" : plan_details.polish_weight,
                "final_pw" : '',
                "lost_pc" : false,
                "reject_pc" : false,
                
              });
              //gridApi.value.setRowData([]);
              gridApi.value.setRowData(rowData.value);
              screenData.scan_lot_no = "";
              //screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(plan_details.polish_weight);
             reCaclulateTotal();
             screenData.scanload = false;
              
              focusLstRow();
              console.log(rowData.value);
              //document.querySelector('[id="scan_lot"]').focus();
          }
          })
          .catch((_error) => {
            screenData.scanload = false;
            return [];
          });
      }
    }

    const focusLstRow = () => {
      document.querySelector('[id="scan_lot_no"]').focus();
    }

    const reCaclulateTotal = () => {
      var j = rowData.value.length;
      var total_pc = 0;
      var total_ct = 0;
      var sr = 1;
        while(j--){
          total_pc++;
          total_ct = total_ct + parseFloat(rowData.value[j]['ex_pw']);
          rowData.value[j]['sr_no'] = j+1;
          sr++;
        }

        screenData.total_scan_pack_wt = total_ct;
        screenData.total_scan_pack = total_pc;

    }
    
    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if(gridApi.value.getSelectedRows().length == 0){
        screenData.delet_row = true;
      }else{
        screenData.delet_row = false;
      }
    }
    
    const removeGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows());
      const sel = gridApi.value.getSelectedRows();
      console.log(sel);

      let remove_rows = [];
      var i = sel.length;
      while(i--){
        remove_rows.push(sel[i]['barcode']);
      }
      gridApi.value.applyTransaction({remove: sel});
      console.log('Removeing row');
      console.log(remove_rows);
      var j = rowData.value.length;
        while(j--){
              if(remove_rows.includes(rowData.value[j]['barcode'])){
                rowData.value.splice(j,1);
              }
        }
      
      setTimeout(reCaclulateTotal,50);
    }


    const takePolishReturn = () => {
      console.log(rowData.value);
      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }
      if(screenData.pc_karigar_id == ''){
        swal("Error!", "Kindly select polish checker for return.", "error");
        return;
      }
      if(screenData.pc_polish_type == ''){
        swal("Error!", "Kindly select polish type for return.", "error");
        return;
      }
      
      const data = {
        packet_details: rowData.value,
        pc_karigar_id: screenData.pc_karigar_id,
        pc_polish_type: screenData.pc_polish_type,
      };

      console.log(data);

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/return_polish_party_packets",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                swal("Success!", "Item Returned to Manager.", "success");
                //screenData.return_progress = false;
                router.go();
              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;

        }
      });
    }

    onMounted(() => {
     rowData.value = []
     getKarigar()
    })


    return {
      filter,
      screenData,
      scanAndAddPack,
      onGridReady,
      takePolishReturn,
      overlayNoRowsTemplate,
      getRowId,
      columnDefs,
      rowData,
      defaultColDef,
      onGridRowSelect,
      removeGridRowSelect,
      // cellWasClicked: (event) => { // Example of consuming Grid Event
      //   console.log("cell was clicked", event);
      // },
      deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }

  }
})
</script>

