<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Sell Edit Request (Non-Certy)</h2>
      <div>
        <div class="flex justify-between items-center">
          <button class="btn btn-danger mx-2" @click="reject_request(this.$route.params.trans_id)"
            v-if="page_data.report_items.active_status == 1">Reject</button>
          <button class="btn btn-primary mx-2" @click="accept_request(this.$route.params.trans_id)"
            v-if="page_data.report_items.active_status == 1 && (user_details.user_type == 1 || user_details.user_type == 28)">Accept</button>
        </div>
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">

      <div id="responsive-table" class="p-5" v-if="page_data.loading == true">
        <div class="preview">
          <div class="overflow-x-auto">
            <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Records</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="page_data.generate == 'gen'">
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
            <div class="col-span-12 sm:col-span-6">

              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Request Code. :&nbsp;</label>
                <label for="update-profile-form-6" class="form-label">{{ page_data.report_items.request_code ?
                  page_data.report_items.request_code : '-' }}</label>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Request Type :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.request_type_name }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Branch Name :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.branch_name }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6" v-if="page_data.report_items.transaction_no">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Transaction No :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.transaction_no }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Issue By :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.issue_user }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.issue_date }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Status :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label"
                  :class="{ 'text-primary-1': page_data.report_items.active_status == 2, 'text-primary-7': page_data.report_items.active_status == 1, 'text-primary-3': page_data.report_items.active_status == 3 }">{{
                    page_data.report_items.status ? page_data.report_items.status : '-' }}</label>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="intro-y box p-5 col-span-12 lg:col-span-12 mt-5" v-if="page_data.report_items.request_type == 1">

      <div class="border-b p-5">
        <div class="flex flex-col sm:flex-row items-center border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base mr-auto">Sell Details</h2>
        </div>
      </div>
      <div class="intro-y mt-5">

        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-2 pb-10">
            <div class="col-span-12 sm:col-span-6">

              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Sell Code. :&nbsp;</label>
                <label for="update-profile-form-6" class="form-label">{{
                  page_data.report_items.details_arr.transaction_code ?
                  page_data.report_items.details_arr.transaction_code : '-' }}</label>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Stock Type :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    "Non Certy Stock" : 'Certy Stock' }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stone_type ?
                    page_data.report_items.details_arr.stone_type : '-' }}</label>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Branch :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.branch_name ?
                    page_data.report_items.details_arr.branch_name : '-' }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Party :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.party_first_name +
                    ' ' + page_data.report_items.details_arr.party_last_name }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Broker :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.broker_first_name ?
                    (page_data.report_items.details_arr.broker_first_name + ' ' +
                      page_data.report_items.details_arr.broker_last_name) : "-" }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.issue_date ?
                    page_data.report_items.details_arr.issue_date : '-' }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.total_pcs : 0 }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.total_wt : page_data.report_items.details_arr.total_wt }} Ct</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.total_amount : page_data.report_items.details_arr.total_amount
                  }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Brokerage :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.brokerage_amount :
                    page_data.report_items.details_arr.brokerage_amount }}</label>
              </div>

            </div>
          </div>


        </div>
      </div>
      <!-- END: HTML Table Data -->
      <div class="intro-y p-5 ">
        <div>

          <div class="flex flex-col sm:flex-row items-center py-2 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Final Selling Stock</h2>
          </div>
          <div class="mt-5" v-if="page_data.report_items.details_arr.stock_type == 1">

            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">MAX Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Less(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Status</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.selling_bunch_details" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b border-r py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_type == "1" ? "NATURAL" : (field.stone_type
                        == "2" ? "CVD" : (field.stone_type == "3" ? "HPHT" : (field.stone_type == "4" ? "D_CVD" :
                          (field.stone_type == "5" ? "D_HPHT" : "")))) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.shape_name }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.charni_name ? field.charni_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.size_type }}</td>
                      <td class="border-b border-r py-2 px-2">{{ (field.total_pcs ? field.total_pcs : 0) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ $h.myFloat(field.total_wt) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stock_rate ? field.stock_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.max_rate ? field.max_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_rate ? field.sell_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_per ? field.brokerage_per : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}
                      </td>
                      <td class="border-b border-r py-2 px-2">
                        <span v-if="field.active_status == 1" class="text-theme-9">Sold</span>
                        <span v-if="field.active_status == 4" class="text-theme-6">Return Requested</span>
                        <span v-if="field.active_status == 3" class="text-theme-6">Returned</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div v-if="page_data.report_items.details_arr.stock_type == 2">
            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stock No</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Certificate No</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">LAB</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo Rate</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Exp. Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Cr. Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sell Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Currency</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">CNBC Rate</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Status</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.selling_certy_details" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b border-r py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.pack_no ? field.pack_no : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.certificate_no ? field.certificate_no : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.weight ? field.weight : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.lab ? field.lab : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_name ? field.stone_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.shape_name ? field.shape_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.clarity_name ? field.clarity_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.repo_rate ? field.repo_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.exp_back ? field.exp_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.cr_back ? field.cr_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_back ? field.sell_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.currency ? field.currency : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.cnbc_rate ? field.cnbc_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.broker_per ? field.broker_per : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}
                      </td>
                      <td class="border-b border-r py-2 px-2">
                        <span v-if="field.active_status == 1" class="text-theme-9">Sold</span>
                        <span v-if="field.active_status == 4" class="text-theme-6">Return Requested</span>
                        <span v-if="field.active_status == 3" class="text-theme-6">Returned</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="intro-y box p-5 col-span-12 lg:col-span-12 mt-5" v-if="page_data.report_items.request_type == 2">

      <div class="border-b p-5">
        <div class="flex flex-col sm:flex-row items-center border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base mr-auto">Sell Details</h2>
        </div>
      </div>
      <div class="intro-y mt-5">

        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-2 pb-10">
            <div class="col-span-12 sm:col-span-6">

              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Sell Code. :&nbsp;</label>
                <label for="update-profile-form-6" class="form-label">{{
                  page_data.report_items.details_arr.trans_code ?
                  page_data.report_items.details_arr.trans_code : '-' }}</label>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Stock Type :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    "Non Certy Stock" : 'Certy Stock' }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Branch :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.branch_name ?
                    page_data.report_items.details_arr.branch_name : '-' }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Party :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.seller_name ? page_data.report_items.details_arr.seller_name : "" }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Broker :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.broker_name ?
                    (page_data.report_items.details_arr.broker_name) : "-" }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.total_pcs : 0 }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.total_wt : page_data.report_items.details_arr.total_wt }} Ct</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.total_amount : page_data.report_items.details_arr.total_amount
                  }}</label>
              </div>

            </div>
            <div class="col-span-12 sm:col-span-6">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label font-bold">Total Brokerage :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">{{ page_data.report_items.details_arr.stock_type == 1 ?
                    page_data.report_items.details_arr.brokerage_amount :
                    page_data.report_items.details_arr.brokerage_amount }}</label>
              </div>

            </div>
          </div>


        </div>
      </div>
      <!-- END: HTML Table Data -->
      <div class="intro-y p-5 ">
        <div clsss="border-b">

          <div class="flex flex-col sm:flex-row items-center py-2 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Final Selling Stock (New)</h2>
          </div>
          <div class="mt-5" v-if="page_data.report_items.details_arr.stock_type == 1">

            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">MAX Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Less(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.stock_details" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b border-r py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_type == "1" ? "NATURAL" : (field.stone_type
                        == "2" ? "CVD" : (field.stone_type == "3" ? "HPHT" : (field.stone_type == "4" ? "D_CVD" :
                          (field.stone_type == "5" ? "D_HPHT" : "")))) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.shape_name }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.charni_name ? field.charni_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ 'Size ' + field.size_type }}</td>
                      <td class="border-b border-r py-2 px-2">{{ (field.pieces ? field.pieces : 0) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ $h.myFloat(field.weight) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stock_rate ? field.stock_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.max_rate ? field.max_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_rate ? field.sell_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_per ? field.brokerage_per : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div v-if="page_data.report_items.details_arr.stock_type == 2">
            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stock No</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Certificate No</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">LAB</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo Rate</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Exp. Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Cr. Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sell Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Currency</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">CNBC Rate</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.stock_details" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b border-r py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.pack_no ? field.pack_no : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.certificate_no ? field.certificate_no : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.weight ? field.weight : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.lab ? field.lab : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_name ? field.stone_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.shape_name ? field.shape_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.clarity_name ? field.clarity_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.repo_rate ? field.repo_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.exp_back ? field.exp_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.cr_back ? field.cr_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_back ? field.sell_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.currency ? field.currency : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.cnbc_rate ? field.cnbc_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.broker_per ? field.broker_per : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
        <div clsss="border-b">

          <div class="flex flex-col sm:flex-row items-center py-2 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Final Selling Stock (Old)</h2>
          </div>
          <div class="mt-5" v-if="page_data.report_items.details_arr.stock_type == 1">

            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">MAX Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Rate</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Less(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.old_stock" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b border-r py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_type == "1" ? "NATURAL" : (field.stone_type
                        == "2" ? "CVD" : (field.stone_type == "3" ? "HPHT" : (field.stone_type == "4" ? "D_CVD" :
                          (field.stone_type == "5" ? "D_HPHT" : "")))) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.shape_name }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.charni_name ? field.charni_name : '-' }}</td>
                      <td class="border-b border-r py-2 px-2">{{ 'Size ' + field.size_type }}</td>
                      <td class="border-b border-r py-2 px-2">{{ (field.pieces ? field.pieces : 0) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ $h.myFloat(field.weight) }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stock_rate ? field.stock_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.max_rate ? field.max_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_rate ? field.sell_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_per ? field.brokerage_per : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div v-if="page_data.report_items.details_arr.stock_type == 2">
            <div class="border">
              <div class="overflow-x-auto">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stock No</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Certificate No</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">LAB</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stone</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Shape</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Color</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo Rate</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Exp. Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Cr. Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sell Back(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Currency</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">CNBC Rate</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Amount</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                      <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                    </tr>
                  </thead>
                  <tbody class="text-center"
                    v-for="(field, index) in page_data.report_items.details_arr.old_stock" :key="index">
                    <tr class="hover:bg-gray-200" :class="{ 'bg-gray-100': (index % 2) == 0 }">
                      <td class="border-b border-r py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.pack_no ? field.pack_no : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.certificate_no ? field.certificate_no : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.weight ? field.weight : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.lab ? field.lab : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.stone_name ? field.stone_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.shape_name ? field.shape_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.clarity_name ? field.clarity_name : "-" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.repo_rate ? field.repo_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.exp_back ? field.exp_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.cr_back ? field.cr_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_back ? field.sell_back : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.currency ? field.currency : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.cnbc_rate ? field.cnbc_rate : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.broker_per ? field.broker_per : "0" }}</td>
                      <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  inject,
  computed
} from 'vue'
import {
  BASE_URL,
  BASE_URL_SELLING,
  BUILD_ENV,
  Auth_Token
} from '@/constants/config.js'
import moment from 'moment';
import {
  helper as $h
} from '@/utils/helper';
import {
  useRoute,
  useRouter
} from 'vue-router';
import store from '@/store';
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
  components: {
    VueBarcode
  },
  setup() {

    const swal = inject('$swal');
    const route = useRoute()
    const router = useRouter()
    const user_details = computed(() => store.state.user.currentUser)
    const AUTH_TOKEN = localStorage.getItem("token")
    const page_data = reactive({

      report_items: [],
      color_arr: [],
      purity_arr: [],
      shape_arr: [],
      size_purity_arr: [],
      generate: "no",
      loading: false,
      total_added_ct: 0,
      total_added_pieces: 0,
      total_packets: 0,
      total_weight: 0
    })

    const editDetails = () => {
      router.push('/app/app-final-polish/loose-request/edit/' + route.params.id)
    }

    const generateReport = () => {
      const params = {
        trans_id: route.params.trans_id
      };

      page_data.loading = true

      let promise = axios({
        url: "/api/fserc_sell_grd",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.error == "YES") {
            swal("Warning!", data.message, "warning");
            page_data.generate = 'no';
            page_data.loading = false

            return;
          }

          page_data.report_items = data.item;
          // getSizePurityDetails()

          // console.log(page_data.report_items.stock_history)
          page_data.generate = 'gen';
          page_data.loading = false

        })
        .catch(_error => {

          page_data.generate = 'no';
          page_data.loading = false
        });
    }

    const reject_request = (id) => {

      swal({
        title: "Are you sure?",
        text: "You want to Reject Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Reject",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          page_data.failed_loading = true

          const params = {
            req_id: id,
            action_type: window.btoa("3")
          };

          let promise = axios({
            url: "/fserc_sell_ur",
            method: "POST",
            data: params,
            baseURL: BASE_URL_SELLLING,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("server_auth")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if (data.status == "success") {
                swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: data.message,
                })
                generateReport()
              } else {
                swal({
                  title: "Warning!",
                  icon: "warning",
                  text: data.message,
                  type: "warning",
                })
              }

            })
            .catch(_error => {

            });
        }
      });
    }

    const accept_request = (id) => {

      swal({
        title: "Are you sure?",
        text: "You want to Accept Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          page_data.failed_loading = true

          const params = {
            req_id: id,
            action_type: window.btoa("2")
          };

          let promise = axios({
            url: "/fserc_sell_ur",
            method: "POST",
            data: params,
            baseURL: BASE_URL_SELLING,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("server_auth")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if (data.status == "success") {
                swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: data.message,
                })
                generateReport()
              } else {
                swal({
                  title: "Warning!",
                  icon: "warning",
                  text: data.message,
                  type: "warning",
                })
              }

            })
            .catch(_error => {

            });
        }
      });
    }
    // Get Shape Details
    const get_shape_details = async () => {

      let promise_url = "/api/dp_shape";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.shape_arr = data.items
        })
        .catch(_error => {
          page_data.shape_arr = []

        });
    }

    // Get Shape Details
    const get_color_details = async () => {

      let promise_url = "/api/dp_color";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.color_arr = data.items
        })
        .catch(_error => {
          page_data.color_arr = []

        });
    }
    // Get Shape Details
    const get_purity_details = async () => {

      let promise_url = "/api/dp_purity";
      let params = {

      }

      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.purity_arr = data.items
        })
        .catch(_error => {
          page_data.purity_arr = []

        });
    }

    function getSizePurityDetails() {

      let stone_name = ''

      if (page_data.report_items.stone_type == 'NATURAL') {
        stone_name = 1;
      } else if (page_data.report_items.stone_type == 'CVD') {
        stone_name = 2;
      } else if (page_data.report_items.stone_type == 'HPHT') {
        stone_name = 3;
      } else if (page_data.report_items.stone_type == 'D_CVD') {
        stone_name = 4;
      } else if (page_data.report_items.stone_type == 'D_HPHT') {
        stone_name = 5;
      }

      const params = {
        stone_type: stone_name
      };
      let promise = axios({
        url: "/api/list_all_merge_segment_all_color",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          page_data.size_purity_arr = data.items;

        })
        .catch(_error => {
          page_data.size_purity_arr = [];

        });
    }

    onMounted(() => {
      generateReport()
      get_shape_details()
      get_color_details()
      get_purity_details()
    })

    return {
      page_data,
      editDetails,
      user_details,
      reject_request,
      accept_request,
    }
  }
})
</script>
