<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Invite Buyer</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="selectAll">
           Select All Buyer
          </button>
        
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Tender Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ tender_name }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Email : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_email }}</label
                  >
                  
                </div>
                <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Mobile No : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_number }}</label
                  >
                  
                  
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Site Address : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ address }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Start Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ start_date_display }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >End Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ end_date_display }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid Start Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_start_date_display }}</label
                  >
                  
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid End Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_end_date_display }}</label
                  >
                  
                </div>
              </div>
            </div>
            
        
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Select Buyers<span class="text-theme-6">*</span></h2>
        </div>
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
          <TomSelect
                v-model="selectedbuyers"
                :options="{
                  placeholder: 'Select Rough Buyer',
                  plugins: {
                    dropdown_header: {
                      title: 'Buyers'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option v-for="buyer in buyers" :key="buyer.id" :value="buyer.id">{{buyer.company_name}}</option>
              </TomSelect>
        </div>
        <div>
        <div class="p-5">
            <div class="flex justify-end mt-4">
           <button type="button" class="btn btn-primary w-20 mr-auto" @click="inviteBuyers">
                Save
              </button>
            </div>
        </div>
          </div>
          
        </div>
        <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Buyers Invited Successfuly!</div>
        </div>
         </div>

         <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>
         </div>
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    tender_id: {
      type: String,
      default: ""
    }
  },
  
  data() {
    return {
      tender_update_id : '',
      tender_name : '',
      selectedbuyers : [],
      contact_number: '',
      contact_email: '',
      address: '',
      start_date: '',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      start_date_display: '',
      end_date_display: '',
      bid_start_date_display: '',
      bid_end_date_display: '',
      total_items:1,
      fields: [],
      buyers:[],
      status: 'default',
   
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  
  this.getTenderDetails(this.tender_id);
  this.getTenderBuyers();
  },

  methods: {
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id
      };
      let promise = axios({
        url: "api/get_tender_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          let tender_detail = data.tender_detail;

          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.rough_details_arr;
          this.contact_number= tender_detail.contact_number;
          this.contact_email= tender_detail.contact_email;
          this.address = tender_detail.address;
          this.start_date = tender_detail.start_time;
          this.end_date = tender_detail.end_time;
          this.bid_start_date = tender_detail.bid_start_time;
          this.bid_end_date = tender_detail.bid_end_time;
           this.start_date_display = tender_detail.start_time_display;
            this.end_date_display = tender_detail.end_time_display;
            this.bid_start_date_display = tender_detail.bid_start_time_display;
            this.bid_end_date_display = tender_detail.bid_end_time_display;

        })
        .catch(_error => {
          
        });
    },

    getTenderBuyers(tender_id) {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tender_buyers_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          this.buyers = data;

        })
        .catch(_error => {
          
        });
    },
    selectAll(){
      let selected = []
      this.buyers.forEach(function(value){
        selected.push(value.id)
      });
      this.selectedbuyers = selected;
    },
    inviteBuyers(){
      let buyer_ar = [];
      buyer_ar = JSON.stringify(this.selectedbuyers);

      console.log(buyer_ar);

      if (buyer_ar == "[]") {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
        return false;
      }
     
      //let tag_ar = [this.vueSelectForm.multiple];
      const data = {
        buyers: buyer_ar,
        td: this.tender_id,
      };
  
      let promise = axios({
        url: "api/invite_buyers",
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/tender/list-invite/" + this.tender_id);
        }, 500)
        })
        .catch((_error) => {
          return [];
        });

    }
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>