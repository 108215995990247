<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Process History Summary</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
          <div class="col-span-6 lg:col-span-4 xl:col-span-4">
            <div>
              <label for="update-profile-form-6" class="form-label"
                >Manager Type</label
              >
              <TomSelect
                v-model="screenData.manager_type_id"
                :options="{
                  placeholder: 'Select Manager Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Manager'
                    }
                  }
                }"
                class="w-full"
              @change="getManagers"
              >
              <option value="">Select manager Type</option>
              <option v-for="manager in pagedata.manager_type" :key="manager.id" :value="manager.id">{{manager.role_name}}</option>
              </TomSelect>
            </div>
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Manager</label
              >
              <TomSelect
                v-model="screenData.manager_id"
                :options="{
                  placeholder: 'Select Manager',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Manager'
                    }
                  }
                }"
                class="w-full"
                @change="initTabulator"
                multiple
              >
              <option value="">Select manager</option>
              <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.id">{{manager.first_name}} {{manager.last_name}}</option>
              </TomSelect>
            </div>

            <div class="mt-3">
              <label for="update-profile-form-9" class="form-label"
                >Select Process</label
              >
              <TomSelect
              @change="initTabulator"
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Ghutan Manager',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Processes'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
              </TomSelect>
            </div>
            

          </div>
          <div class="col-span-6 lg:col-span-4 xl:col-span-4">
            <div>
              <label for="update-profile-form-6" class="form-label"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="initTabulator"
                multiple
              >
              <option value="">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>

            <div class="mt-3">
              <label for="update-profile-form-9" class="form-label"
                >Select Employee</label
              >
              <TomSelect
              @change="initTabulator"
                v-model="screenData.employee_id"
                :options="{
                  placeholder: 'Select Employee',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employees'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option v-for="employee in pagedata.employees" :key="employee.id" :value="employee.id">{{employee.first_name}}</option>
              </TomSelect>
            </div>

            
          </div>
          <div class="col-span-6 lg:col-span-4 xl:col-span-4">
            <div>
              <label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
                @change="initTabulator"
              />
            </div>
            <div  class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select End Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                v-model="screenData.receive_date"
                @change="initTabulator"
              />
            </div>
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
                @change="initTabulator"
              >
              <option value="">Select Status</option>
              <option value="1">Accepted</option>
              <option value="2">In Process</option>
              <option value="3">Received</option>
              </TomSelect>
            </div>
            <div class="mt-10">

              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>

          </div>

        </div>

      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      manager_type: [],
      managers: [],
      processes: [],
      employees: [],
      process: 22,
      serieses: [],
    })
    const screenData = reactive({
      manager_type_id: '',
      manager_id : [],
      series_id:[],
      employee_id:[],
      process_id:[],
      status:'',
      issue_date:'',
      receive_date:'',
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/get_process_data_list',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          manager_id :screenData.manager_id,
          series_id :screenData.series_id,
          employee_id : screenData.employee_id,
          process_id : screenData.process_id,
          status : screenData.status,
          issue_date : screenData.issue_date,
          receive_date : screenData.receive_date
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Transaction No',
            field: 'transaction_code',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component

              router.push("/app/app-process/kapan/issuetransaction/" + window.btoa(cell.getData().id));
          },
          formatter(cell) {
              if(cell.getData().issue_by == userDetails.value.id){
              return `<i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().transaction_code
              }else{
                return `<i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().transaction_code
              }
            }
          },
          {
            title: 'Kapan/Series No',
            field: 'kapan_code',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().bunch_type == 1){
              return cell.getData().kapan_code
              }else{
                return cell.getData().series_code
              }
            }
          },
          {
            title: 'Manager/Worker',
            field: 'karigar_first_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().issue_type == 1){
                if(cell.getData().process_id == 2 || cell.getData().process_id == 3 ||  cell.getData().process_id == 27 || cell.getData().process_id == 28){
                      return  `<div class="">`+cell.getData().karigar_manager_first_name+`<br>
                  <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
                </div>` ;
                }if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                      return  `<div class="">`+cell.getData().planner_first_name +`<br>
                  <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
                </div>` ;
                }else if(cell.getData().process_id == 1){
                  if(cell.getData().sawing_type == 2){
                  return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                                  <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name +` (CS)</p>
                                </div>` ;
                  }else{
                    return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                                  <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name +` (S)</p>
                                </div>` ;
                  }
                }else{
                  return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                  <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
                </div>` ;

                }
              }else if(cell.getData().issue_type == 2){

              if(cell.getData().issue_by == userDetails.value.id){
                 return  `<div class="">`+cell.getData().manager_first_name +`<br>
                <p class="text-theme-1 font-12 font-bold">`+cell.getData().manager_type_name+`</p>
              </div>` ;
              }else{
                 return  `<div class="">`+cell.getData().issue_manager_first_name +`<br>
                <p class="text-theme-1 font-12 font-bold">`+cell.getData().issue_manager_type_name+`</p>
              </div>` ;
              }

              }
            }
          },
          {
            title: 'Weight',
            minWidth: 100,
            field: 'issue_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().receive_weight != null){
                return Number(cell.getData().issue_weight) + " Ct<br>" + Number(cell.getData().receive_weight) + " Ct";
              }else{
                return Number(cell.getData().issue_weight) + " Ct<br>" + " N/A";
              }
            }
          },

          {
            title: 'Issue Date',
            field: 'created_at',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().receive_date != null){
                return cell.getData().created_at + "<br>" + cell.getData().receive_date + "";
              }else{
                return cell.getData().created_at + "<br>" + " N/A";
              }

            }
          },
          {
            title: 'Status',
            field: 'status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
            if(cell.getData().manager_id == null || cell.getData().manager_id == ''){

              if(cell.getData().planner_id != null && cell.getData().planner_id != ''){
                if(cell.getData().trans_status == "1"){
                  return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                }else if(cell.getData().trans_status == "3"){
                  return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                }else if(cell.getData().trans_status == "2"){
                  return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                </div>`
                }
                else{
                  return `<div class="flex items-center lg:justify-center text-theme-6">
                  Unknown !
                </div>`
                }
              }
              else{
                if(cell.getData().status == "1"){
                return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                  In Process
                </div>`
                }else if(cell.getData().status == "3"){
                return `<div class="flex items-center lg:justify-center font-bold text-theme-7">
                  Merged
                </div>`
                }else{
                  return `<div class="flex items-center lg:justify-center text-theme-6">
                  Received
                </div>`
                }

              }
              }
              else if(cell.getData().manager_id != null && cell.getData().manager_id != ''){
                if(cell.getData().trans_status == "1"){
                  return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                }else if(cell.getData().trans_status == "3"){
                  return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                }else if(cell.getData().trans_status == "2"){
                  return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                </div>`
                }
                else{
                  return `<div class="flex items-center lg:justify-center text-theme-6">
                  Unknown !
                </div>`
                }
              }
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const getSeries = () => {
      let promise = axios({
        url: "api/dp_rough_series",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getProcesses = () => {
      let promise = axios({
        url: "api/dp_process",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.processes = data.items

        })
        .catch(_error => {

        });
    }
    const getManagersType = () => {
      let promise = axios({
        url: "api/dp_manager_role",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.manager_type = data.items

        })
        .catch(_error => {

        });
    }
    const getManagers = () => {

      let data = {
        manager_type : screenData.manager_type_id
      }
      let promise = axios({
        url: "api/dp_account_operator",
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.managers = data.items

        })
        .catch(_error => {

        });
    }
    const getEmployees = () => {
      let promise = axios({
        url: "api/dp_account_employe",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.manager_type_id = ''
      screenData.manager_id = []
      screenData.series_id = []
      screenData.employee_id = []
      screenData.process_id = []
      screenData.status = ''
      screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      screenData.receive_date = moment().format('YYYY-MM-DD')

      initTabulator()

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      checkPermission()
      getSeries()
      getManagersType()
      getManagers()
      getProcesses()
      getEmployees()
      reInitOnResizeWindow()
      screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      screenData.receive_date = moment().format('YYYY-MM-DD')
      initTabulator()
    })

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      initTabulator,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      getManagers,
    }
  }
})
</script>
