<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tender Winner List</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="downloadWinners">
           <DownloadIcon class="w-4 h-4 mr-1" />Download List
          </button>
         
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         
          <div class="p-5">
             <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Tender</label
                  >
                 <TomSelect
                v-model="selectedTenders"
                :options="{
                  placeholder: 'Select Tender',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Tenders'
                    }
                  }
                }"
                class="w-full"
                
                
              >
              <option value="">Select Tender</option>
              <option v-for="tender in tenders" :key="tender.id" :value="tender.id">{{tender.tender_name}}</option>
              </TomSelect>
                </div>
                
              
              
              </div>
           

              <div class="col-span-12 xl:col-span-12 mt-5">
                
                <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="onFilter"
            >
              Generate
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
              </div>
            </div>  
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5" v-if="report_loaded"
          >
            <h2 class="font-medium text-base mr-auto">Final Selected Winners</h2>
        </div>
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5" v-if="report_loaded">
           <div class="border">
          <div class="grid grid-cols-12 pl-5 pr-5 pb-2">
              <div class="col-span-2 xl:col-span-1 text-center">
                <p>#</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Sr. No</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Cut</p>
              </div>
              <div class="col-span-2 xl:col-span-4 font-bold">
                <p>Details</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Stone</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Carats</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
            <p>Expected Price($) /carat</p>
              </div>
              <div class="col-span-2 xl:col-span-1 font-bold">
            <p>Total Price($)</p>
              </div>
          </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              <div class="col-span-2 xl:col-span-1 text-center pt-3 pl-3">
                <p>{{ field.id }}</p>
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3">
                <p>{{field.lot_no}}</p>
               
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3">
                <p>{{field.cut}}</p>
                
              </div>
              <div class="col-span-2 xl:col-span-4 pt-3 pl-3">
                <p>{{field.details}}</p>
               
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3">
                <p>{{field.piece}}</p>
              
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3">
                <p>{{field.carats}}</p>
              
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3">
                <p>{{field.expect_price}}</p>
              </div>
              <div class="col-span-2 xl:col-span-2 pt-3 pl-3">
                {{field.expect_price * field.carats}}
              </div>
            </div>
            
            <div class="grid grid-cols-12 mt-3">
              <div class="col-span-2 xl:col-span-1 text-center">
              </div>
             
              <div class="col-span-2 xl:col-span-1 font-bold">
                <p>#</p>
              </div>
              <div class="col-span-2 xl:col-span-4 font-bold">
                <p>Buyer Name</p>
              </div>
              <div class="col-span-2 xl:col-span-2 font-bold">
              <p>Bid Price($) /Carat</p>
              </div>
              
              <div class="col-span-2 xl:col-span-1 font-bold">
              <p>Total Price</p>
              </div>
               <div class="col-span-2 xl:col-span-1 font-bold">
                <p>Up/Down(%)</p>
              </div>
            
              
            </div>

            <div class="grid grid-cols-12" v-for="(bids,index) in field.top_bids" :index="index" :key="bids.id">
              
              <div class="col-span-2 xl:col-span-1 text-center">
              </div>
              
              <div class="col-span-2 xl:col-span-1">
                <p>{{index+1}}</p>
              </div>
              <div class="col-span-2 xl:col-span-4">
                <p>{{bids.company_name}} ({{ bids.company_email}})</p>
              </div>
              <div :class="Number(bids.bid_price) >= Number(field.expect_price) ? 'col-span-2 xl:col-span-2 text-theme-9' : 'col-span-2 xl:col-span-2 text-theme-6'">
              <p>{{bids.bid_price}}</p>
              </div>
              
              <div class="col-span-2 xl:col-span-1">
              <p >{{Number((bids.bid_price * field.carats).toFixed(2))}}</p>
              </div>
              
              <div class="col-span-2 xl:col-span-2">
             <p>{{getPer(bids.bid_price,field.expect_price,field.carats)}}</p>
              </div>
              
            </div>

          <div class="grid grid-cols-12" v-if="field.top_bids.length == 0">
            <div class="col-span-2 xl:col-span-1 text-center">
              </div>
              <div class="col-span-2 xl:col-span-11 text-bold text-theme-6">
                <p>No Winner Found !</p>
              </div>
          </div>

          
          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Packets : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_pack}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total weight(Carat) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_rough_carat}}</p>
              </div>

          </div>
           <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Expected Price /Carat ($) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_expected_amount}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Expected Price ($) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_expected_amount_overall}}</p>
              </div>

          </div>

          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right pt-3" style="border-top:1px solid #000000;">
                <p class="font-bold">Total Selected Packets : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right pt-3" style="border-top:1px solid #000000;">
                <p>{{selected_pack}}</p>
              </div>

          </div>

          <div class="grid grid-cols-12 mt-3 pr-5 mb-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right" >
                <p class="font-bold">Selected Packets valuation ($): </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right" >
                <p>{{selected_pack_total_amount}}</p>
              </div>

          </div>
        </div>
        </div>
        <div>
        
          </div>
          
        </div>
        <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Tender Declared Successfuly!</div>
        </div>
         </div>

         <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>
         </div>
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import FileDownload from "js-file-download";

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
 
  
  data() {
    return {
      tender_update_id : '',
      total_rough_carat:0,
      total_pack:0,
      selected_pack:0,
      selected_pack_total_amount:0,
      total_expected_amount:0,
      total_expected_amount_overall:0,
      tender_name : '',
      selectedbuyers : [],
      contact_number: '',
      contact_email: '',
      address: '',
      start_date: '',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      start_date_display: '',
      end_date_display: '',
      bid_start_date_display: '',
      bid_end_date_display: '',
      total_items:1,
      fields: [],
      buyers:[],
      selectedTenders:'',
      report_loaded:false,

      tenders:[],
      status: 'default',
   
      loginVal: '',
      screenData:{
        selectedbuyers : [],
        status:'default',
        buyers:[],
        selectedTenders:'',
        
        selectedTenderItems:[],
        tenderItems:[],
        fields: [],
      },
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  
  this.getTenderList();
  
  },

  methods: {
    getTenderList(){
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/get_tenders_dp",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          this.tenders = data;

        })
        .catch(_error => {
          
        });
    },
    onFilter(){
      let  _this = this;
      const params = {
        tender_id: window.btoa(this.selectedTenders)
      };
      let promise = axios({
        url: "api/get_tender_winners",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          this.fields = data.tender_detail.rough_details_arr;
          _this.calculateTotal();
          this.report_loaded = true;

        })
        .catch(_error => {
          
        });
    },
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id
      };
      let promise = axios({
        url: "api/get_tender_winners",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          let tender_detail = data.tender_detail;
          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.rough_details_arr;
          this.contact_number= tender_detail.contact_number;
          this.contact_email= tender_detail.contact_email;
          this.address = tender_detail.address;
          this.start_date = tender_detail.start_time;
          this.end_date = tender_detail.end_time;
          this.bid_start_date = tender_detail.bid_start_time;
          this.bid_end_date = tender_detail.bid_end_time;
          this.start_date_display = tender_detail.start_time_display;
          this.end_date_display = tender_detail.end_time_display;
          this.bid_start_date_display = tender_detail.bid_start_time_display;
          this.bid_end_date_display = tender_detail.bid_end_time_display;

          _this.calculateTotal();

        })
        .catch(_error => {
          
        });
    },
    getPer(bid_price,expected_price,carate){
      let per = Number(bid_price)/Number(expected_price);
      per = per*100;
      return Number(per.toFixed(2))+"%";
    },
   
    downloadWinners(){
      let filename = this.selectedTenders + "_winner_list.xlsx";
          const data = {
            td: window.btoa(this.selectedTenders)
          };
           let promise = axios({
        url: "api/download_winners_new",
        method: "get",
        params: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
        responseType: "blob",
      });

      return promise
        .then((result) => result.data)
        .then((data) => {
          FileDownload(data, filename);
          this.status = "success";

          setTimeout(() => {
            this.status = "default";
          }, 3000);
        })
        .catch((_error) => {
          this.status = "fail";
          setTimeout(() => {
            this.status = "default";
          }, 3000);
          return [];
        });
    },
    calculateTotal(){
      this.total_rough_carat = 0;
      this.total_expected_amount = 0;
      this.total_expected_amount = 0;
      this.total_expected_amount_overall = 0;
      this.selected_pack_total_amount = 0;
      var i = this.fields.length;
      this.selected_pack = 0;
      this.total_pack = i;
              while(i--){
                
              if(this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                  this.total_rough_carat = this.total_rough_carat + parseFloat(this.fields[i]['carats']);
              }

              if(this.fields[i]['expect_price'] != '' && !isNaN(this.fields[i]['expect_price'])){
                  this.total_expected_amount = this.total_expected_amount + parseFloat(this.fields[i]['expect_price']);
              }

              if(this.fields[i]['expect_price'] != '' && !isNaN(this.fields[i]['expect_price']) && this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                
                  this.total_expected_amount_overall = this.total_expected_amount_overall + (parseFloat(this.fields[i]['carats']) * parseFloat(this.fields[i]['expect_price']));
              }

              
                
                var j = this.fields[i].top_bids.length;
                console.log("hi Total Bid in top : " + j);
                console.log("hi Total Bid in top : " + this.fields[i]['winning_bid']);
                
                if(j>0){
                while(j--){
                  if(this.fields[i].top_bids[j].tender_item_bids_id != ''){
                    if(this.fields[i].top_bids[j].bid_price != '' && !isNaN(this.fields[i].top_bids[j].bid_price) && this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                        this.selected_pack_total_amount = this.selected_pack_total_amount + (parseFloat(this.fields[i]['carats']) *  parseFloat(this.fields[i].top_bids[j].bid_price));
                    }
                  }
                }
                this.selected_pack += 1;
                }
                
              
            }
    }

  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>