<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Blocking Packet Receive</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'add-receive-packet' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           New Receive
          </router-link>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="screenData.scan_lot_no"
                    :class="{'form-control':true,'loading':screenData.scanload}"
                    :disabled = "screenData.scanload"
                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <!-- <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Scaned Packet History</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
           class="btn btn-primary shadow-md mr-2" @click="takeBlockingReturn" :disabled="screenData.return_progress">
           Take Return
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress"/>
          </button>
      </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                </div>

              </div>
            <div class="col-span-4 xl:col-span-4">
               <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Pcs Weight. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}}  </label>
                </div>
            </div>
            <div class="col-span-4 xl:col-span-4 text-right">
               <div>
                <button
           class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect" :disabled="screenData.delet_row">
           Remove Selected Row
          </button>
                </div>
            </div>
              <div class="col-span-12 ">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 500px"
              :columnDefs="columnDefs.value"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              :rowMultiSelectWithClick="true"
              animateRows="true"
              @grid-ready="onGridReady"
              @selection-changed="onGridRowSelect"
              :overlayNoRowsTemplate="overlayNoRowsTemplate"
            >
            </ag-grid-vue>
              </div>

            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted,inject } from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default defineComponent({
  components: {
   AgGridVue,
   ShapeRenderer
 },
  setup() {
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      scanload: false,
      return_progress:false,
      delet_row:true,
      scan_lot_no:'',
      total_scan_pack:0,
      total_scan_pack_wt:0,
      shapes:[],
      colors:[],
      purity:[]

    })

    const onGridReady = (params) => {
     gridApi.value = params.api;
    };

   const rowData = reactive({
    value:[]
   });

   const overlayNoRowsTemplate = 'Scan packets for blocking receive!'

   const columnDefs = reactive({
     value: [
          { headerName: "#",field: 'sr_no',maxWidth: 60, },
          { headerName: "Packet No",field: 'lot_no',minWidth: 160 },
          { headerName: "org_cr_wt",field: 'org_cr_wt' , hide: true},
          { headerName: "Cr Wt",field: 'cr_wt',editable: true ,
          valueGetter: (params) => {
            if (params.data.cr_wt) {
              return params.data.cr_wt;
            } else {
              return 0;
            }
          },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = parseFloat(params.newValue);

            if(isNaN(newValInt)){
              alert('Not a Valid Number');
              params.data.cr_wt = params.data.cr_wt ;
              return false;

            }else if (newValInt > params.data.org_cr_wt){
              alert('Cr Wt can not greater than original issue weight');
              params.data.cr_wt = params.data.org_cr_wt;
              return params.data.org_cr_wt;
            }else if (newValInt < params.data.ex_pw){
              alert('Cr Wt can not less than Ex Polish Weight');
              params.data.cr_wt = params.data.org_cr_wt;
              return params.data.org_cr_wt;
            }else{
               var valueChanged = params.data.cr_wt !== newValInt;
                if (valueChanged) {
                  params.data.cr_wt = newValInt;
                }
                return valueChanged;
            }
          },
          },
          { headerName: "Shape",field: 'shape',editable: true ,
          valueGetter: (params,) => {
            if (params.data.shape) {
              return params.data.shape;
            } else {
              return 'ROUND';
            }
          },
          valueSetter: (params) => {
            console.log(screenData.shapes);
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }

            var newValShape = params.newValue.toUpperCase();
            if(screenData.shapes.some(e => e.shape_sk === newValShape)){
              var finddata = screenData.shapes.find(function(ele) {
                  return ele.shape_sk === newValShape;
              });
               var get_shape = finddata.shape_name;
                params.data.shape = get_shape;
                return get_shape;
            }
            else if(screenData.shapes.some(e => e.shape_name === newValShape)){
               var valueChanged = params.data.shape !== newValShape;
                if (valueChanged) {
                  params.data.shape = newValShape;
                }
                return valueChanged;
            }else{
                 alert('Not a Valid Shape!');
                params.data.shape = params.data.shape ;
                return false;
            }
          },
          },
          { headerName: "Color",field: 'color',editable: true,
          valueGetter: (params,) => {
            if (params.data.color) {
              return params.data.color;
            } else {
              return 'D';
            }
          },
          valueSetter: (params) => {
            console.log(screenData.shapes);
            if (params.newValue == '') {
              alert('Color cannot be empty');
              return false;
            }

            var newValColor = params.newValue.toUpperCase();
            if(screenData.colors.some(e => e.shade_name === newValColor)){
               var valueChanged = params.data.color !== newValColor;
                if (valueChanged) {
                  params.data.color = newValColor;
                }
                return valueChanged;
            }else{
                 alert('Not a Valid Color (Shade)!');
                params.data.color = params.data.color;
                return false;
            }
          } },
          { headerName: "Purity",field: 'purity',editable: true ,
          valueGetter: (params,) => {
            if (params.data.purity) {
              return params.data.purity;
            } else {
              return 'IF';
            }
          },
          valueSetter: (params) => {
            console.log(screenData.purity);
            if (params.newValue == '') {
              alert('Purity cannot be empty');
              return false;
            }
            var newValShape = params.newValue.toUpperCase();
            if(screenData.purity.some(e => e.purity_sk === newValShape)){
              var finddata = screenData.purity.find(function(ele) {
                  return ele.purity_sk === newValShape;
              });
               var get_purity = finddata.purity_name;
                params.data.purity = get_purity;
                return get_purity;
            }

            else if(screenData.purity.some(e => e.purity_name === newValShape)){
               var valueChanged = params.data.purity !== newValShape;
                if (valueChanged) {
                  params.data.purity = newValShape;
                }
                return valueChanged;
            }else{
                 alert('Not a Valid Purity!');
                params.data.purity = params.data.purity ;
                return false;
            }
          },
          },
          { headerName: "Exp PW",field: 'ex_pw',editable: true ,
            valueGetter: (params) => {
            if (params.data.ex_pw) {
              return params.data.ex_pw;
            } else {
              return '';
            }
          },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = parseFloat(params.newValue);

            if(isNaN(newValInt)){
              alert('Not a Valid Number');
              params.data.ex_pw = params.data.ex_pw ;
              return false;

            }else if (newValInt > params.data.org_cr_wt){
              alert('Ex Pt can not greater than original issue weight');
              params.data.ex_pw = params.data.org_cr_wt;
              return params.data.org_cr_wt;
            }else if (newValInt > params.data.ct_pw){
              alert('Ex PW can not greater than Cr Weight');
              params.data.ex_pw = params.data.ex_pw;
              return params.data.ex_pw;
            }else{
               var valueChanged = params.data.ex_pw !== newValInt;
                if (valueChanged) {
                  params.data.ex_pw = newValInt;
                }
                setTimeout(focusScan,50);
                return valueChanged;
            }
          }},
          {
            headerName: "Lost",
            field: "lost_pc",
            cellRenderer: function(params) {
                var input = document.createElement('input');
                input.type="checkbox";
                input.checked=params.value;
                input.addEventListener('click', function (event) {
                    params.value=!params.value;
                    params.node.data.lost_pc = params.value;
                });
                return input;
            }
        },{
            headerName: "Reject",
            field: "reject_pc",
            cellRenderer: function(params) {
                var input = document.createElement('input');
                input.type="checkbox";
                input.checked=params.value;
                input.addEventListener('click', function (event) {
                    params.value=!params.value;
                    params.node.data.reject_pc = params.value;
                });
                return input;
            }
        }
     ],
   });


   const defaultColDef = {
     sortable: true,
     filter: true,
     flex: 1
   };

   const focusScan = () => {

    document.querySelector('[id="scan_lot_no"]').focus();
   }

    const getShapes = () => {

      const params = {

      };
      let promise = axios({
        url: "api/dp_shape",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.shapes = data.items;
        })
        .catch(_error => {

        });
    }

    const getPurities = () => {

      const params = {

      };
      let promise = axios({
        url: "api/dp_purity",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.purity = data.items;
        })
        .catch(_error => {

        });
    }

    const getColors = () => {

      const params = {

      };
      let promise = axios({
        url: "api/dp_color",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.colors = data.items;
        })
        .catch(_error => {

        });
    }
    //Edit user
    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
      if(codelength > 11){
        screenData.scanload = true;
        //Check already in array
        var i = rowData.value.length;
              while(i--){
              if(rowData.value[i]['barcode'] == screenData.scan_lot_no ){
                screenData.scan_lot_no = "";
                swal("Warning!", "Already in list, Scan other packet!", "warning");
                screenData.scanload = false;
                return;
              }
        }

        //get lot details
        const data = {
        barcode: screenData.scan_lot_no,
        };
        let url_post = '';
          if(screenData.scan_lot_no != ''){
          url_post = "api/get_packet_details_for_blocking_est"
        }else{
          url_post = "api/get_packet_details_for_blocking_est"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          if(data.packet_details == 'No'){
              swal("Warning!", data.error_msg, "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot"]').focus();
          }else if(data.packet_details == 'process'){
              swal("Warning!", "Scanned barcode is already in process", "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot"]').focus();
          }else {
            let pack_details = data.packet_details;
            let plan_details = data.last_plan;
            let last_history = data.last_history;
            if(plan_details == null){
              swal("Warning!", "Packet does not have main piece plan", "warning");
              screenData.scanload = false;
              return;
            }
              //screenData.total_scan_pack = screenData.total_scan_pack + 1;
              console.log('Push data');
              rowData.value.push(
              {
                "sr_no":screenData.total_scan_pack + 1,
                "lot_no":pack_details.packet_code,
                "barcode": pack_details.barcode_no,
                "packet_id": pack_details.id,
                "last_history_id": last_history.id,
                "org_cr_wt" :pack_details.cr_weight,
                "cr_wt" : pack_details.cr_weight,
                "shape" : plan_details.shape,
                "purity" : plan_details.clarity,
                "color" : plan_details.color,
                "ex_pw" : plan_details.ex_weight,
                "lost_pc" : false,
                "reject_pc" : false,
              });
              //gridApi.value.setRowData([]);

              gridApi.value.setRowData(rowData.value);
              screenData.scan_lot_no = "";
              //screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.cr_weight);
              reCaclulateTotal();
              screenData.scanload = false;
              //console.log(gridApi.value.refreshCells());
              if(rowData.value.length == 1){
                setTimeout(focusLstRow,50);
              }else{
                focusLstRow();
              }
              //document.querySelector('[id="scan_lot"]').focus();
          }
          })
          .catch((_error) => {
            screenData.scanload = false;
            return [];
          });
      }
    }

    const reCaclulateTotal = () => {
      var j = rowData.value.length;
      var total_pc = 0;
      var total_ct = 0;
      var sr = 1;
        while(j--){
          total_pc++;
          total_ct = total_ct + parseFloat(rowData.value[j]['org_cr_wt']);
          rowData.value[j]['sr_no'] = j+1;
          sr++;
        }

        screenData.total_scan_pack_wt = total_ct;
        screenData.total_scan_pack = total_pc;

    }
    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if(gridApi.value.getSelectedRows().length == 0){
        screenData.delet_row = true;
      }else{
        screenData.delet_row = false;
      }
    }
    const removeGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows());
      const sel = gridApi.value.getSelectedRows();
      console.log(sel);

      let remove_rows = [];
      var i = sel.length;
      while(i--){
        remove_rows.push(sel[i]['barcode']);
      }
      gridApi.value.applyTransaction({remove: sel});
      console.log('Removeing row');
      console.log(remove_rows);
      var j = rowData.value.length;
        while(j--){
              if(remove_rows.includes(rowData.value[j]['barcode'])){
                rowData.value.splice(j,1);
              }
        }

      setTimeout(reCaclulateTotal,50);
    }

    const focusLstRow = () => {
      gridApi.value.ensureIndexVisible(rowData.value.length - 1);
      gridApi.value.setFocusedCell(rowData.value.length - 1,'cr_wt');
      gridApi.value.startEditingCell(
      {
        rowIndex: rowData.value.length - 1,
        colKey: 'cr_wt',
      }
      );
    }

    const takeBlockingReturn = () => {
      gridApi.value.stopEditing();
      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }

      let total_pcs = rowData.value.length;
      let i = 0;
      while(i < total_pcs){
        console.log(rowData.value[i].ex_pw);
        if(rowData.value[i].ex_pw == '' || rowData.value[i].ex_pw == undefined){
          swal("Error!", "Kindly Enter Polish Weight to all packet and retry!", "error");
          return;
        }
        i++;
      }

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const data = {
            packet_details: rowData.value,
          };

          let promise = axios({
            url: "api/return_blocking_packets",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                if(data.type == "success") {

                swal.fire({
                  icon:'success',
                  title:"Success!",
                  text:data.message,
                  showConfirmButton: false,
                  timer:1500
                })

                  // swal("Success!", data.message, "success");
                } else {
                  swal("Warning!", data.message, "warning");
                }
                document.querySelector('[id="scan_lot_no"]').focus();
                rowData.value = []
                reCaclulateTotal()
                //screenData.return_progress = false;
                // router.go();
              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;

        }
      });
    }

    onMounted(() => {
      getShapes()
      getPurities()
      getColors()
      rowData.value = []

    })


    return {
      filter,
      screenData,
      scanAndAddPack,
      onGridReady,
      takeBlockingReturn,
      overlayNoRowsTemplate,
      getRowId,
      columnDefs,
      rowData,
      onGridRowSelect,
      removeGridRowSelect,
      defaultColDef,

      // cellWasClicked: (event) => { // Example of consuming Grid Event
      //   console.log("cell was clicked", event);
      // },
      deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }

  }
})
</script>

