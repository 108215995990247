<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Department Job Done</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                @change="kapanChange"
              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Packets</option>
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Report Type</label
              >
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Summary</option>
              <option value="2">Kapan Wise</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1"
              >Select Start Date</label
            >
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type">
          <div class="mt-7 flex pt-3">

            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button> -->
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
              v-print="'#generate-pdf'"
            >
            Print
            </button>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2" v-if="printData.report_type == 2">
                  <tr v-for="(item,index) in pagedata.department" :key="index">
                    <td>
                      <table class="w-full p-0 m-0">
                        <tr class="border-b bg-gray-300 dark:bg-dark-1" v-if="index == 0">
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-8 border-r">#</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r">JobDone Department</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r">Manager</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r">Kapan</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r">Pieces</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r"> Job Done Weight (Ct)</th>
                          <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r">Polish Weight (Ct)</th>
                        </tr>
                        <tbody v-for="(emp_item,emp_ind) in pagedata.report_details[item.ref]" :key="emp_ind">
                          <tr v-for="(kapan_item,kapan_index) in emp_item.kapan_details" :key="kapan_index">
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-8 border-r">{{ (emp_ind == 0 && kapan_index == 0) ? (index + 1) : '' }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r">{{ (emp_ind == 0 && kapan_index == 0) ? (item.dept) : '' }}</th>
                            <th class="py-2 text-xs lg:text-md w-36 border-r">{{ kapan_index == 0 ? (emp_item.company_name ? emp_item.company_name : (emp_item.first_name + ' ' + (emp_item.last_name ? emp_item.last_name : ''))) : '' }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b">{{ kapan_item.kapan_code}}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b">{{ kapan_item.issue_pieces}}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b">{{ kapan_item.issue_weight ? $h.myFloat(kapan_item.issue_weight ): '-'}}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b">{{ kapan_item.polish_weight ? $h.myFloat(kapan_item.polish_weight) : '-'}}</th>
                          </tr>
                          <tr>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-8 b-rder-r"></th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r"></th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b border-t bg-gray-100 dark:bg-dark-1">Total</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b  bg-gray-100 dark:bg-dark-1"></th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b  bg-gray-100 dark:bg-dark-1">{{ emp_item.issue_pieces}}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b  bg-gray-100 dark:bg-dark-1">{{ emp_item.issue_weight ? $h.myFloat(emp_item.issue_weight) : '-' }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b  bg-gray-100 dark:bg-dark-1">{{ emp_item.polish_weight ? $h.myFloat(emp_item.polish_weight) : '-' }}</th>
                          </tr>
                        </tbody>
                          <tr class="border bg-gray-200 dark:bg-dark-1" v-if="pagedata.report_details[item.ref].length > 0">
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-8 border-r"></th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r"></th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r"></th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b ">Total</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b ">{{ pagedata.total[item.ref].issue_pieces}}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b ">{{ pagedata.total[item.ref].issue_weight ? $h.myFloat(pagedata.total[item.ref].issue_weight ): '-'}}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap w-36 border-r border-b ">{{ pagedata.total[item.ref].polish_weight ? $h.myFloat(pagedata.total[item.ref].polish_weight) : '-'}}</th>
                          </tr>
                      </table>
                    </td>

                  </tr>
                </table>
                <table class="mt-3 w-full border-2" v-else>
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">JobDone Department</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Manager</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Pieces</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"> Job Done Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Weight (Ct)</th>
                    </tr>
                  </thead>
                  <tbody v-for="(item,index) in pagedata.department" :key="index">
                    <tr v-for="(emp_item,emp_ind) in pagedata.report_details[item.ref]" :key="emp_ind">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ (emp_ind == 0) ? (index + 1) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ (emp_ind == 0) ? (item.dept) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.company_name ? emp_item.company_name : (emp_item.first_name + ' ' + (emp_item.last_name ? emp_item.last_name : '')) }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.issue_pieces}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.issue_weight ? $h.myFloat(emp_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.polish_weight ? $h.myFloat(emp_item.polish_weight) : '-'}}</th>
                    </tr>
                    <tr class="border bg-gray-100 dark:bg-dark-1" v-if="pagedata.report_details[item.ref].length > 0">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ pagedata.total[item.ref].issue_pieces}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ pagedata.total[item.ref].issue_weight ? $h.myFloat(pagedata.total[item.ref].issue_weight) : '-' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ pagedata.total[item.ref].polish_weight ? $h.myFloat(pagedata.total[item.ref].polish_weight) : '-' }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      stones: [],
      report_details : [],
      department : [],
      total : [],
      loading: false,

    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      packets:[],
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: '1',

    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      packets:[],
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: '0',

    })

    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }

    const generateReport = () => {
      pagedata.loading = true

      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.stone_id = screenData.stone_id
      printData.packets = screenData.packets
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.report_type = screenData.report_type

      let params = {
        series_id : screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        packets : screenData.packets != '0' ? screenData.packets : '',
        stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
        issue_date : window.btoa(screenData.issue_date),
        receive_date : window.btoa(screenData.receive_date),
        report_type : window.btoa(screenData.report_type)
      }
      let promise = axios({
        url: "api/get_job_done_details",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.department= data.department
          pagedata.report_details = data.items
          pagedata.total = data.total
          pagedata.loading  = false
        })
        .catch(_error => {

        });
    }
    const getSeries = () => {
      let params = {
        // process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_rough_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        // process_id : screenData.process_id,
      }
      let api_url = ''

      if(screenData.series_id != "0") {
        api_url = "api/dp_rough_kapan_by_series"
      } else {
        api_url = "api/dp_rough_kapan"
      }
      let promise = axios({
        url: api_url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          // screenData.kapan_id = "0"
          pagedata.kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getPackets = () => {

      if(screenData.series_id != "0" || screenData.kapan_id != "0") {

        let api_url = ""
        let params = ''
        if(screenData.kapan_id != "0") {
          params = {
            id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
          }
          api_url = 'api/get_packet_by_kapan'
        } else if(screenData.series_id != "0") {
          api_url = 'api/get_packet_by_series'
          params = {
            id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
          }
        }
        let promise = axios({
          url: api_url,
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            // screenData.packets = []
            pagedata.packets = data.items

          })
          .catch(_error => {

          });
      }
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.packets = []
      screenData.stone_id = '0'
      screenData.report_type = '1'

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      getPackets()
    }
    function seriesChange() {
      getParameters()
      screenData.kapan_id = ''
      screenData.packets = []
    }
    function kapanChange() {
      getPackets()
      screenData.packets = []
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      getDPStoneType()
      getParameters()
      // reInitOnResizeWindow()

    })

    function printReportDetails(){
      let report_type = ''
      if(printData.report_type == '2') {
        report_type = 'get_polish_summary_lot_report'
      } else {
        report_type = 'get_polish_summary_report'
      }

      let params = {
        report_type: report_type,
        filters: printData,
        items: {'items': pagedata.report_details,'total': pagedata.total }
      }
      let promise = axios({
        url: "api/add_report_details",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id

          if(report_type == 'get_polish_summary_lot_report') {

          } else {
            let url = BASE_URL + '/polish-summary-report?id=' + window.btoa(report_id)
            window.open(url, '_blank');
          }

        })
        .catch(_error => {

        });
      // add_report_details
    }

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      printData,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      generateReport,
      printReportDetails
    }
  }
})
</script>
