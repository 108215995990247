<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Karigar Account</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      <button
          v-if="account_id != ''"
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="resetUser">
           <KeyIcon class="w-4 h-4 mr-1" />
           Reset & Resend Password
      </button>
      <button
          v-if="account_id != '' && status == 1"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-danger ' : status == 1}" @click="changeStatus">
           <LockIcon class="w-4 h-4 mr-1" /> Deactivate
      </button>
      <button
          v-if="account_id != '' && status == 0"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-success ' : status == 0}" @click="changeStatus">
           <UnlockIcon class="w-4 h-4 mr-1" /> Activate
      </button>
      <button
          v-if="account_id != ''"
          type="button"
           class="btn btn-danger shadow-md mr-2" @click="deleteUser">
           <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
      </button>

      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Karigar Details</h2>
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >First Name<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="First name"
                    
                    v-model="first_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Middle Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Middle name"
                  
                    v-model="middle_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Last Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                    
                    v-model="last_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Personal Email<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    :disabled ="user_id != ''"
                    v-model="contact_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Mobile No<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="+00 00000 00000"
                    
                    v-model="contact_mobile_no"
                  />
                </div>


              <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Reporting Manager<span class="text-theme-6">*</span></label
                  >
                  <TomSelect
                v-model="karigar_manager"
                :options="{
                  placeholder: 'Select Manager',
                }"
                class="w-full"
                
              >
              <option value="">Select Manager</option>
              <option v-for="manager_item in managers_arr" :key="manager_item.id" :value="manager_item.id" :selected="manager_item.id == karigar_manager">{{manager_item.first_name}} {{manager_item.last_name}}</option>
              </TomSelect>
                </div>

                

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Prcess</label
                  >
                  <TomSelect
                v-model="process"
                :options="{
                  placeholder: 'Select Process ',
                }"
                class="w-full"
                multiple
                @change="testMethod"
              >
              <option value="">Select Process</option>
              <option v-for="proces_item in process_ar" :key="proces_item.id" :value="proces_item.id">{{proces_item.process_name}}</option>
              </TomSelect>
                </div>
<div class="mt-3" >
                <input
                              id="vertical-form-3"
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              v-model="on_fixed"
                              @change="fixsalarychange"
                            />
                            <label class="form-check-label" for="vertical-form-3"
                              >Fix Salary Karigar</label
                            >
</div>
            
                <div class="mt-3" v-if="on_fixed">
                  <label for="update-profile-form-9" class="form-label"
                    >Fixed Salary</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    v-model="fixed_salary"
                  />
                </div>
              </div>

            </div>
            
                        

            <div class="p-5" v-if="fixed_salary != '' && fixed_salary > 0 && on_fixed == true">
            <div class="grid grid-cols-6 gap-x-5">
              <div class="col-span-6">
                <h4 class="font-medium text-base mr-auto">Process Details</h4>
               <div class="border">
               <div class="grid grid-cols-6 sticky top-0 z-20 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
           
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Process Name</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Salary(%)</p>
                </div>
             
               </div>
       
               <div class="border" v-for="process_item in process_salary_per" :key="process_item.id">
            
                <div class="grid grid-cols-6">

               <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                 <label class="p-2 mt-2 font-bold">{{process_item.process_name}}</label>
                
              </div>
              
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="process_item.salary_per" type="text"
                
                    class="form-control" placeholder="Salary in percentage" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
               
              
                </div>
            </div>
          </div>
         </div>
            </div>
          
          </div>
              
            
            <div class="flex justify-end mt-4">
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>
             
            </div>
         
        </div>
         </form>
        </div>
         <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Created Successfuly!</div>
            <div class="text-gray-600 mt-1">
                User added to user list.
            </div>
        </div>
         </div>
         <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Updated Successfuly!</div>
        </div>
         </div>
        <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>

         <div id="failed-user-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Creation Error!</div>
            <div class="text-gray-600 mt-1">
                Something went wrong. Kindly Refreash the page and try again.
            </div>
        </div>
         </div>
    </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    
  },
  props: {
    user_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      account_id : '',
      total_items:1,
      first_name:"",
      last_name:"",
      middle_name:"",
      contact_email:"",
      contact_mobile_no:"",
      process:[],
      karigar_manager:"",
      fixed_salary:"",
      on_fixed:false,
      process_ar:[],
      process_salary_per:[],
      managers_arr:[],
      status: '',
      email: '',
      password: '',
      loginVal: '',
      url:'',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  created(){
      
  },
  mounted(){
      if(this.user_id != ''){
        this.account_id = this.user_id;
        this.getuserDetails(this.user_id);
      }
      this.getProcess();
      this.getManagers();
      
  
  },
  computed: {
    
  },

  methods: {
    
    formSubmit() {
      this.status = 'processing'

    },
    fixsalarychange(){
      if(this.on_fixed == false)
        {
          this.fixed_salary = '';
          this.process_salary_per = [];
          this.process = [];

        }    
},
    testMethod(){
      //check item already in list or not and push item
      var i = this.process.length;
      console.log(i);
      while(i--){
        var process_id = this.process[i];
        var item_flag = false;
        var j = this.process_salary_per.length;
        console.log(j);
        if(j > 0){
          while(j--){
              if(this.process_salary_per[j]['process_id'] == process_id){
                  item_flag = true;
              }
            }
        }else{
          item_flag = false;
        }
        if(item_flag == false){
          var k = this.process_ar.length;
          var process_name= '';
          while(k--){
            if(this.process_ar[k]['id'] == process_id){
                  process_name = this.process_ar[k]['process_name'];
                  break;
            }
          }
          this.process_salary_per.push({ id:this.total_items,process_name: process_name,process_id:process_id ,salary_per:''});
          this.total_items++;
        }    
      }
      
      //check item already in list deselected or not
      
      var j = this.process_salary_per.length;
      while(j--){
        item_flag = false;
        var i = this.process.length;
        console.log(i);
        while(i--){
          //console.log("I" + i);
          var process_id = this.process[i];
          if(this.process_salary_per[j]['process_id'] == process_id){
              item_flag = true;
          }
        }
        if(item_flag == false){
          this.process_salary_per.splice(j,1);
        }
      }
      
    },
    
    async getProcess() {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/dp_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          this.process_ar = data.items;

        })
        .catch(_error => {
          
        });
    },
    async getManagers() {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/dp_account_operator",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          this.managers_arr = data.items;

        })
        .catch(_error => {
          
        });
    },
    onUserFormSubmit() {
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      if (!this.first_name) {
        this.errors.push("First Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (!this.contact_email) {
        this.errors.push("Email required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (this.contact_mobile_no == '') {
        this.errors.push("First Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (this.karigar_manager == '') {
        this.errors.push("First Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      
   
      if (!this.errors.length) {
        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name:this.middle_name,
          contact_email :this.contact_email,
          contact_mobile_no : this.contact_mobile_no,
          process:this.process,
          manager:this.karigar_manager,
          fixed_salary:this.fixed_salary,
          process_salary_per:this.process_salary_per,
          ud:this.user_id
        };
        if (this.user_id == "") {
          this.url = "api/add_account_employe";
        } else {
          this.url = "api/edit_account_employe";
        }

        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {

            if(this.user_id != ''){
        Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/employe-master");
        }, 500)
            }else{
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/employe-master");
        }, 500)
            }
                    
          })
          .catch(response => {
            Toastify({
              node: cash('#failed-user-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          });
      }
    },
    getuserDetails(user_id) {
      let _this = this;
      const params = {
        ud: user_id
      };
      let promise = axios({
        url: "api/details_account_employe",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.user_details);
          let user_details = data.user_details;

          this.first_name = user_details.first_name;
          this.last_name = user_details.last_name;
          this.middle_name = user_details.middle_name;
          this.contact_email = user_details.contact_person_email;
          this.contact_mobile_no = user_details.contact_person_mobile;
          this.status = user_details.active_status;
          if(user_details.process_salary_per != null && user_details.process_salary_per != ''){
              this.process_salary_per = user_details.process_salary_per;
          }
          
          this.fixed_salary = user_details.fixed_salary;
          if(this.fixed_salary > 0){
            this.on_fixed = true;
          }
          console.log(user_details.manager_id);
          console.log(user_details.process);
          this.karigar_manager = '' + user_details.manager_id;
          
          this.process = user_details.process;
    

        })
        .catch(_error => {
          
        });
    },
    deleteUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a User!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/delete_account_employe",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Deleted.", "success");
          this.$router.push("/app/accounts/employe-master");
        }
      });
    },
    resetUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to reset User Password!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reset",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/reset_buyer_user",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "New Password Reset & Resend to User.", "success");
        }
      });
    },
    changeStatus(){
      let _this = this;
      console.log(this.user_id);
      console.log(this.status);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to change user status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id,
            user_status : window.btoa(this.status)
          };
          let promise = axios({
            url: "api/change_status_account_employe",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Status Changed.", "success");
          //this.$router.go();
          _this.getuserDetails(this.user_id);
        }
      });
    }
  },
  
})
</script>

