<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish - Selling (Non-Certy)</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Issue Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Party <span class="text-primary-3"> *</span></label>
                                <div class="" id="seller_id">

                                    <TomSelect v-model="form_data.seller_id" :options="{
                        placeholder: 'Select Party',
                        }" class="w-full">
                                        <option value="0">Select Party</option>
                                        <option v-for="(item,index) in page_data.selling_parties" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Branch</label>
                                <div class="" id="broker_id">

                                    <TomSelect v-model="form_data.branch_id" :options="{
                        placeholder: 'Select Broker ',
                        }" class="w-full">
                                        <option value="">Select Branch</option>
                                        <option value="1">Home Branch</option>
                                        <option v-for="(item,index) in page_data.branch_arr" :key="index" :value="item.id">{{ item.branch_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Broker</label>
                                <div class="" id="broker_id">

                                    <TomSelect v-model="form_data.broker_id" :options="{
                        placeholder: 'Select Broker ',
                        }" class="w-full">
                                        <option value="0">Select Broker</option>
                                        <option v-for="(item,index) in page_data.selling_broker" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Sell Type <span class="text-primary-3"> *</span></label>
                                <div class="" id="payment_type">

                                    <TomSelect v-model="form_data.payment_type" :options="{
                        placeholder: 'Select Sell Type ',
                        }" class="w-full">
                                        <option value="">Sell Type</option>
                                        <option value="1">Cash</option>
                                        <option value="2">Bill</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Sell Date <span class="text-primary-3"> *</span></label>
                                <div class="" id="sell_date">

                                    <input v-model="form_data.sell_date" type="date" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Payment Terms <span class="text-primary-3"> *</span></label>
                                <div class="" id="payment_terms">

                                    <input v-model="form_data.payment_terms" type="text" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Pieces</label>
                                <div class="" id="total_pcs">

                                    <input v-model="form_data.total_pcs" disabled type="text" autocomplete="off" placeholder="Total Pieces" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Weight</label>
                                <div class="" id="total_wt">

                                    <input v-model="form_data.total_wt" disabled type="text" placeholder="Total Weight" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Amount</label>
                                <div class="" id="total_amount">

                                    <input v-model="form_data.total_amount" disabled type="text" autocomplete="off" placeholder="Total Amount" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Brokerage</label>
                                <div class="" id="brokerage_amount">

                                    <input v-model="form_data.brokerage_amount" disabled type="text" placeholder="Brokerage" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="flex items-center justify-between">
                        <div>
                        </div>
                        <div>

                            <button type="button" @click="cancel_sell" class="btn btn-outline-secondary w-32 mr-1">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary w-32" :disabled="page_data.form_status" @click="sell_stock">
                                Save Details
                                <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-4 h-4 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
        <!-- </div>
      <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5 mb-10">
            <!-- BEGIN: Input -->
            <div class="intro-y box w-full">
                <div class="p-5">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Final Sell Details</h2>
                    </div>
                    <!-- <div class="border mt-5 p-0 shadow-md mt-5">
                        <div class="overflow-x-auto"> -->
                    <table class="border mt-3 w-full">
                        <thead>
                            <tr class="border-b">
                                <th class="py-2" rowspan="2">Sr No</th>
                                <th class="py-2">Stone <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Color <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Shape <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Charni <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Clarity <span class="text-primary-3"> *</span></th>
                                <th class="py-2">PCS</th>
                                <th class="py-2">Weight (Ct) <span class="text-primary-3"> *</span></th>
                              </tr>
                              <tr>
                                <th class="py-2">Stock Rate</th>
                                <th class="py-2">Max Rate</th>
                                <th class="py-2">Sell Rate <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Sell Less</th>
                                <th class="py-2">Amount</th>
                                <th class="py-2">Broker (%)</th>
                                <th class="py-2">Broker Amount</th>
                                <th class="py-2 w-4"></th>
                            </tr>
                        </thead>
                        <tbody class="border" v-for="(plan,index) in form_data.bunch_details" :key="index">
                            <tr>
                                <td class="w-8" rowspan="2">
                                    <div class="font-bold text-center">{{ index + 1 }}</div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'stone_type_'+ index">
                                        <select v-model="plan.stone_type" class="w-full form-select"
                                                @change="change_stone_status(plan)"
                                                :disabled="page_data.stone_natural == true && index != 0">
                                            <option value="">Stone Type</option>
                                            <option value="1" v-if="(form_data.bunch_details[0].stone_type == 1 && index != 0) || (index == 0)">NATURAL</option>
                                            <option value="2">CVD</option>
                                            <option value="3">HPHT</option>
                                            <option value="4">D_CVD</option>
                                            <option value="5">D_HPHT</option>
                                        </select>

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'color_size_'+ index">
                                        <select v-model="plan.color_size" class="w-full form-select"  @change="get_charni_list(plan,index)">
                                            <option value="">Select Color</option>
                                            <option v-for="(item,index) in plan.color_list" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                                          </select>

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'shape_'+ index">
                                        <select v-model="plan.shape" class="w-full form-select" @change="get_min_max_rate(plan)">
                                            <option value="">Select Shape</option>
                                            <option v-for="(item,index) in page_data.shape_list" :key="index" :value="item.id">{{ item.shape_name }}</option>
                                        </select>

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'charni_id_'+ index">
                                        <select v-model="plan.charni_id" class="w-full form-select" @change="get_charni_name(plan)">
                                            <option value="">Select Charni</option>
                                            <option v-for="(item,index) in plan.charni_list" :key="index" :value="item.id">{{ item.charni_name }}</option>
                                          </select>

                                    </div>
                                </td>
                                <td class="w-36">
                                  <div class="" :id="'size_type_'+ index">
                                    <select v-model="plan.size_type"
                                    class="w-full form-select" @change="get_min_max_rate(plan)">
                                    <option value="">Select Clarity</option>
                                    <option v-for="(item,index) in plan.size_list" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                                        </select>

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'pieces_'+ index">
                                        <input v-model="plan.pieces" @change="calculate_total_pcs" placeholder="pieces" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'weight_'+ index">
                                        <input v-model="plan.weight" @change="calculate_total_weight_single(plan)" placeholder="weight" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                              </tr>
                              <tr>
                              <td class="w-36">
                                  <div class="" :id="'stock_rate_'+ index">
                                      <input v-model="plan.stock_rate" disabled type="number" autocomplete="off" class="form-control" placeholder="Stock Rate" />

                                  </div>
                              </td>
                                <td class="w-28">
                                    <div class="" :id="'max_rate_'+ index">
                                        <input v-model="plan.max_rate" disabled type="number" autocomplete="off" class="form-control" placeholder="MAX Rate" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'sell_rate_'+ index">
                                        <input v-model="plan.sell_rate" @change="calculate_sell_rate(plan)" type="number" autocomplete="off" class="form-control" placeholder="Sell Rate" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'sell_less_'+ index">
                                        <input v-model="plan.sell_less" @change="calculate_sell_rate(plan)" type="number" autocomplete="off" class="form-control" placeholder="Less" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'total_amount_'+ index">
                                        <input v-model="plan.total_amount" disabled type="number" autocomplete="off" class="form-control" placeholder="Amount" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'broker_per_'+ index">
                                        <input v-model="plan.broker_per" @change="calculate_broker_rate(plan)" type="number" autocomplete="off" class="form-control" placeholder="Brokerage (%)" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'brokerage_amount_'+ index">
                                        <input v-model="plan.brokerage_amount" disabled type="number" autocomplete="off" class="form-control" placeholder="Brokerage" />

                                    </div>
                                </td>
                                <td>
                                    <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="remove_bunch(plan.id)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="flex items-center justify-between">
                        <div>
                            <button type="button" @click="add_bunch" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                                Add Packet
                            </button>

                        </div>
                        <div>
                        </div>
                    </div>
                    <!-- </div>
                    </div> -->
                </div>
            </div>

            <!-- END: Input -->
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent,
    inject,
    onMounted,
    reactive,
    watch
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'
import {
    integer
} from '@vuelidate/validators'
import router from '@/router'
import { useRoute } from 'vue-router';

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    setup() {
        const swal = inject('$swal')
        const page_data = reactive({
            form_status: false,
            selling_parties: [],
            selling_broker: [],
            charni_items: [],
            shape_list: [],
            branch_arr: [],
            stone_natural: false

          })
          const route = useRoute()
          const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
          const form_data = reactive({

            trans_id: "",
            seller_id: "0",
            broker_id: "0",
            branch_id: "1",
            total_pcs: "",
            total_wt: "",
            total_amount: "",
            brokerage_amount: "",
            sell_date: "",
            payment_terms: "",
            payment_type: "",
            ref_no: "",
            sell_items: 1,
            bunch_details: [{
              id: 1,
              charni_id: "",
              charni_name: "",
              size_type: "",
              stone_type: "",
              color_size: "",
              color_name: "",
              shape: "",
              pieces: "",
              weight: "",
              stock_rate: "",
              max_rate: "",
              sell_rate: "",
              sell_less: "",
              total_amount: "",
              broker_per: "",
              stone_natural: false,
              brokerage_amount: "",
              charni_list: [],
              size_list: [],
              color_list: []
            }]
        })

        // Get Seller Party
        const get_sell_details = async () => {
            const params = {
              trans_id: route.params.trans_id
            };
            const url = "/sell_edit_details"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                  form_data.trans_id = route.params.trans_id
                  form_data.seller_id = String(data.item.seller_id)
                  form_data.broker_id = String(data.item.broker_id)
                  form_data.branch_id = String(data.item.branch_id)
                  form_data.total_pcs = data.item.total_pcs
                  form_data.total_wt = data.item.total_wt
                  form_data.total_amount = data.item.total_amount
                  form_data.brokerage_amount = data.item.brokerage_amount
                  form_data.sell_date = data.item.sell_date
                  form_data.payment_terms = data.item.payment_terms
                  form_data.payment_type = data.item.payment_type
                  form_data.ref_no = data.item.ref_no
                  form_data.sell_items = data.item.stock_items.length
                  form_data.bunch_details = data.item.stock_items
                })
                .catch(_error => {
                });
        }

        // Get Seller Party
        const get_seller_party = async () => {
            const params = {};
            const url = "/dp_account_selling_party"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.selling_parties = data.items;
                })
                .catch(_error => {
                    page_date.selling_parties = [];
                });
        }

        // Get Seller Party
        const get_selling_broker = async () => {
            const params = {};
            const url = "/dp_account_selling_broker"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.selling_broker = data.items;
                })
                .catch(_error => {
                    page_date.selling_broker = [];
                });
        }

        const getBranchDetails = async () => {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/transfer_branches",
                method: "POST",
                params: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.branch_arr = data.items;

                })
                .catch(_error => {
                  page_data.branch_arr = [];

                });
        }
        // Get Seller Party
        const get_charni_list = async (item,index) => {

          if(item.stone_type != "" && item.color_size != "") {

              item.color_list.forEach(element => {
                if(element.segment_code == item.color_size) {
                  item.color_name = element.segment_name
                }
            });

            get_size_list(item,index)
              const params = {
                stone_type: item.stone_type,
                color_size: item.color_size
              };
              const url = "/main_charni_list"
              let promise = axios({
                  url: url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL_SELLING,
                  headers: {
                      Authorization: "Bearer " + AUTH_TOKEN
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                    item.charni_list = data.items;
                    item.charni_id = ""
                  })
                  .catch(_error => {
                    item.charni_list = [];
                    item.charni_id = ""
                  });
                } else {
            item.charni_list = [];
            item.charni_id = ""
            item.size_list = [];
                  item.size_type = ""

          }
        }
        const get_color_list = async (item) => {

          if( item.stone_type == "") {
            item.color_list = [];
                  item.color_size = ""
                  item.charni_list = [];
                  item.charni_id = ""
                  item.size_list = [];
                  item.size_type = ""
                  return
          }
            const params = {
              stone_type: item.stone_type
            };
            const url = "/dp_shade_size_items"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                  item.color_list = data.items;
                  item.color_size = ""
                  item.charni_list = [];
                  item.charni_id = ""
                  item.size_list = [];
                  item.size_type = ""
                })
                .catch(_error => {
                  item.color_list = [];
                  item.color_size = ""
                  item.charni_list = [];
                  item.charni_id = ""
                  item.size_list = [];
                  item.size_type = ""
                });
        }
        // Get Seller Party
        const get_size_list = async (item,index) => {
            const params = {
              stone_type: item.stone_type,
              color_size: item.color_size
            };
            const url = "/dp_size_items"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                  item.size_list = data.items;
                  item.size_type = ""
                })
                .catch(_error => {
                  item.size_list = [];
                  item.size_type = ""
                });
        }

        // Add Bunch details
        const add_bunch = () => {
            form_data.sell_items += 1;
            form_data.bunch_details.push({
                id: form_data.sell_items,
                charni_id: "",
                charni_name: "",
                size_type: "",
                stone_type: (form_data.bunch_details[0].stone_type == "1" ? "1" : ""),
                color_size: "",
                color_name: "",
                shape: "",
                pieces: "",
                weight: "",
                stock_rate: "",
                max_rate: "",
                sell_rate: "",
                sell_less: "",
                total_amount: "",
                broker_per: "",
                stone_natural: false,
                brokerage_amount: "",
                charni_list: [],
                size_list: [],
                color_list: []
            });
        }

        // Get item Min MAX Rate
        const get_min_max_rate = async (item) => {

            if (item.charni_id != "" && item.shape != "" && item.stone_type != "" && item.size_type != "" && item.color_size != "") {
                const params = {
                    stone_type: item.stone_type,
                    charni_id: item.charni_id,
                    shape: item.shape,
                    charni_size: item.size_type,
                    color_size: item.color_size
                };
                const url = "/get_non_certy_stock_rates"
                let promise = axios({
                    url: url,
                    method: "POST",
                    data: params,
                    baseURL: BASE_URL_SELLING,
                    headers: {
                        Authorization: "Bearer " + AUTH_TOKEN
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.error == "NO") {
                            item.stock_rate = data.items.stock_rate;
                            item.max_rate = data.items.max_rate;
                        }
                    })
                    .catch(_error => {});
            } else {
                item.stock_rate = "";
                item.max_rate = "";

            }
        }

        // Get Seller Party
        const get_shape_list = async (item,index) => {
            const params = {
            };
            const url = "/dp_shape"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                  page_data.shape_list = data.items;
                })
                .catch(_error => {
                  page_data.shape_list = [];
                });
        }

        // Remove Bunch Details
        const remove_bunch = (id) => {
            swal({
                title: "Are you sure?",
                text: "You are about to Delete Item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.bunch_details.length;
                    while (i--) {
                        if (form_data.bunch_details[i]["id"] == id) {
                            form_data.bunch_details.splice(i, 1);
                        }
                    }

                    let total_amount = 0;

                    form_data.bunch_details.forEach(element => {
                      total_amount = $h.myFloat(parseFloat(total_amount) + parseFloat(parseFloat(element.total_amount ? element.total_amount : 0) > 0 && (element.total_amount) ? element.total_amount : 0))
                    });

                    form_data.total_amount = total_amount;
                }
            });
        }

        // calculate Total Pieces
        const calculate_total_pcs = () => {

            let total_pcs = 0;

            form_data.bunch_details.forEach(element => {

                total_pcs = total_pcs + element.pieces
            });

            form_data.total_pcs = total_pcs;

        }

        // calculate Total Weight
        const calculate_total_weight = () => {
            let total_wt = 0;

            form_data.bunch_details.forEach(element => {

                total_wt = parseFloat(total_wt) + parseFloat(((element.weight) && parseFloat(element.weight ? element.weight : 0) > 0 ? element.weight : 0))
            });

            form_data.total_wt = total_wt;

        }
        // calculate Total Weight
        const calculate_total_weight_single = (plan) => {
            calculate_total_weight()
            calculate_sell_rate(plan)

        }

        // Calculate Amount
        const calculate_sell_rate = async (item) => {
            if (parseFloat(item.sell_rate ? item.sell_rate : 0) > 0 && (item.sell_rate) && parseFloat(item.weight ? item.weight : 0) > 0 && (item.weight)) {

                if (parseFloat(item.sell_less ? item.sell_less : 0) > 0 && (item.sell_less)) {

                    item.total_amount = $h.myFloat(parseFloat(item.sell_rate * item.weight) - parseFloat((item.sell_rate * item.weight * item.sell_less) / 100));
                } else {
                  item.total_amount = $h.myFloat(parseFloat(item.sell_rate ? item.sell_rate : 0) * parseFloat(item.weight ? item.weight : 0));
                  calculate_broker_rate(item)
                }
              } else {
                item.total_amount = "";
                calculate_broker_rate(item)
              }

            let total_amount = 0;

            form_data.bunch_details.forEach(element => {
                total_amount = $h.myFloat(parseFloat(total_amount) + parseFloat(parseFloat(element.total_amount ? element.total_amount : 0) > 0 && (element.total_amount) ? element.total_amount : 0))
            });

            form_data.total_amount = total_amount;
        }
        // Calculate Broker Rate
        const calculate_broker_rate = async (item) => {
            if (parseFloat(item.total_amount ? item.total_amount : 0) > 0 && (item.total_amount)) {

                if (parseFloat(item.broker_per ? item.broker_per : 0) > 0 && (item.broker_per)) {

                    item.brokerage_amount =  $h.myFloat((item.total_amount * item.broker_per) / 100);
                } else {

                  item.brokerage_amount = 0;
                }
            } else {
                item.brokerage_amount = 0;
            }

            let brokerage_amount = 0;

            form_data.bunch_details.forEach(element => {
                brokerage_amount = $h.myFloat(parseFloat(brokerage_amount) + parseFloat(parseFloat(element.brokerage_amount ? element.brokerage_amount : 0) > 0 && (element.brokerage_amount) ? element.brokerage_amount : 0))
            });

            form_data.brokerage_amount = brokerage_amount;
        }
        // Sell Stock To Party
        const sell_stock = async () => {


          const sell_items = form_data.bunch_details

          console.log(sell_items)

          // return;
            if (sell_items.length > 0) {

                // Validate Fields

                let is_empty = false

                if (form_data.seller_id == "0" || form_data.seller_id == null) {
                    $('#seller_id').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#seller_id').removeClass('border border-theme-6')
                }
                // if (form_data.total_pcs == "" || form_data.total_pcs == null) {
                //     $('#total_pcs').addClass('border border-theme-6')
                //     is_empty = true

                // } else {
                //     $('#total_pcs').removeClass('border border-theme-6')
                // }
                if (form_data.total_wt == "" || form_data.total_wt == null) {
                    $('#total_wt').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_wt').removeClass('border border-theme-6')
                }
                if (form_data.total_amount == "" || form_data.total_amount == null) {
                    $('#total_amount').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_amount').removeClass('border border-theme-6')
                }
                if (form_data.sell_date == "" || form_data.sell_date == null) {
                    $('#sell_date').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#sell_date').removeClass('border border-theme-6')
                }
                if (form_data.payment_terms == "" || form_data.payment_terms == null) {
                    $('#payment_terms').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#payment_terms').removeClass('border border-theme-6')
                }
                if (form_data.payment_type == "" || form_data.payment_type == null) {
                    $('#payment_type').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#payment_type').removeClass('border border-theme-6')
                }

                for (let i = 0; i < sell_items.length; i++) {

                    if (sell_items[i].charni_id == '' || sell_items[i].charni_id == null) {
                        $('#charni_id_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#charni_id_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].stone_type == '' || sell_items[i].stone_type == null) {
                        $('#stone_type_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#stone_type_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].color_size == '' || sell_items[i].color_size == null) {
                        $('#color_size_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#color_size_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].size_type == '' || sell_items[i].size_type == null) {
                        $('#size_type_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#size_type_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].shape == '' || sell_items[i].shape == null) {
                        $('#shape_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#shape_' + i).removeClass('border border-theme-6');
                    }
                    // if (sell_items[i].pieces == '' || sell_items[i].pieces == null) {
                    //     $('#pieces_' + i).addClass('border border-theme-6');
                    //     is_empty = true;
                    // } else {
                    //     $('#pieces_' + i).removeClass('border border-theme-6');
                    // }
                    if (sell_items[i].weight == '' || sell_items[i].weight == null) {
                        $('#weight_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#weight_' + i).removeClass('border border-theme-6');
                    }
                    // if (sell_items[i].stock_rate == '' || sell_items[i].stock_rate == null) {
                    //     $('#stock_rate_' + i).addClass('border border-theme-6');
                    //     is_empty = true;
                    // } else {
                    //     $('#stock_rate_' + i).removeClass('border border-theme-6');
                    // }
                    // if (sell_items[i].max_rate == '' || sell_items[i].max_rate == null) {
                    //     $('#max_rate_' + i).addClass('border border-theme-6');
                    //     is_empty = true;
                    // } else {
                    //     $('#max_rate_' + i).removeClass('border border-theme-6');
                    // }
                    if (sell_items[i].sell_rate == '' || sell_items[i].sell_rate == null) {
                        $('#sell_rate_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#sell_rate_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].total_amount == '' || sell_items[i].total_amount == null) {
                        $('#total_amount_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#total_amount_' + i).removeClass('border border-theme-6');
                    }
                }

                if (is_empty == true) {
                    swal({
                        title: "Warning!",
                        icon: "warning",
                        text: "Fields Are Empty!",
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                    })
                    return
                }

                page_data.form_status = true

                let url = ""

                if(form_data.trans_id != "") {
                  url = "/edit_sell_stock"
                } else {
                  url = "/sell_branch_stock"
                }
                let promise = axios({
                    url: url,
                    method: "POST",
                    data: form_data,
                    baseURL: BASE_URL_SELLING,
                    headers: {
                        Authorization: "Bearer " + AUTH_TOKEN
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.error == "YES") {
                            page_data.form_status = false
                            swal("Error!", data.message, "error");

                            return;
                        } else {

                          swal.fire({
                              icon: 'success',
                              title: "Success!",
                              text: data.message,
                              showConfirmButton: false,
                              timer: 3000
                          })
                          page_data.form_status = false
                          let transaction_id = data.transaction_details.id
                          router.push('/app/app-final-polish/sell-transfer/' + window.btoa(transaction_id));
                        }
                    })
                    .catch(_error => {
                        page_data.form_status = false
                        swal("Error!", "Something went Wrong", "error");

                        return;
                    });

            } else {

                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are empty!",
                    type: "warning",
                })
                return
            }
        }

        function cancel_sell() {
            router.go(-1)
        }

        function change_stone_status(plan) {
            if (plan.id == 1) {

              const length = form_data.bunch_details.length;
                get_min_max_rate(plan)
                if (plan.stone_type == "1") {
                    page_data.stone_natural = true
                    plan.stone_natural = true

                    for (let i = 1; i < length; i++) {
                      form_data.bunch_details[i].stone_type = "1"
                      form_data.bunch_details[i].stone_natural = true

                      get_color_list(form_data.bunch_details[i])
                      get_min_max_rate(form_data.bunch_details[i])

                    }
                } else if (plan.stone_type != '1') {
                  for (let i = 1; i < length; i++) {
                    if (form_data.bunch_details[i].stone_type == "1") {
                      form_data.bunch_details[i].stone_type = ""
                      form_data.bunch_details[i].stone_natural = false
                          get_color_list(form_data.bunch_details[i])
                          get_min_max_rate(form_data.bunch_details[i])
                        }

                    }
                    page_data.stone_natural = false

                } else {
                    page_data.stone_natural = false
                }
                console.log(page_data.stone_natural)
            }
            get_color_list(plan)
        }

        function get_charni_name(plan) {

          plan.charni_list.forEach(element => {
              if(element.id == plan.charni_id) {
                plan.charni_name = element.charni_name
              }
          });
          get_min_max_rate(plan)
        }

        // On Mount
        onMounted(() => {
            get_seller_party();
            getBranchDetails();
            get_selling_broker();
            get_shape_list();

            if(route.params.trans_id) {
              get_sell_details()
            }
        })

        return {
            page_data,
            form_data,
            add_bunch,
            remove_bunch,
            get_min_max_rate,
            sell_stock,
            calculate_total_pcs,
            calculate_total_weight,
            calculate_sell_rate,
            calculate_broker_rate,
            calculate_broker_rate,
            cancel_sell,get_charni_list,
            change_stone_status,calculate_total_weight_single,get_charni_name,
            get_color_list
        }
    },

})
</script>

<style scoped>
/* .border {
    margin: 0 20px;
    padding: 3px;
  } */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
