<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Standered Rate - CVD</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <!-- <router-link
          :to="{ name: 'add-shape' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
        
        Add New Shape
          </router-link> -->
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">
        <div id="single-file-upload" class="p-5">
            <div class="preview">
              <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: page_data.base_url + '/api/upload_standard_rate_cvd',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  maxFiles: 1,
                  headers: { Authorization: 'Bearer ' + page_data.token }
                }"
                class="dropzone mb-4"
              >
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload Excel file with latest standared rate with provided formate. (.xlsx, .xls)
                </div>
              </Dropzone>
              <span class="mt-5">Latest Rates upadted on : {{page_data.last_sync_date}}</span>
            </div>
            
          </div>
    </div>

    <div class="intro-y box p-5 mt-5">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2"
              >Select Shape</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="page_data.price_cut"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              @change="getOldRates()"
            >
              <option value="ROUND" selected>ROUND</option>
              <option value="PRINCE">PRINCE</option>
              <option value="EMERALD">EMERALD</option>
              <option value="CUSHION">CUSHION</option>
              <option value="RADIANT">RADIANT</option>
              <option value="MARQUISE">MARQUISE</option>
              <option value="PEAR">PEAR</option>
              <option value="OVEL">OVEL</option>
              <option value="HEART">HEART</option>
              <option value="ROSE">ROSE</option>
              <option value="CHOKI">CHOKI</option>
              <option value="TRIANGLE">TRIANGLE</option>
              <option value="CHAKRI">CHAKRI</option>
              <option value="ROSE-PAN">ROSE-PAN</option>
              <option value="ROSE-TRIANGLE">ROSE-TRIANGLE</option>
              <option value="ROSE-OVEL">ROSE-OVEL</option>
            </select>

     

      <div id="responsive-table" class="p-5" v-if="page_data.load_price">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="bars" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">Getting Latest Price</div>
      </div>
              </div>
            </div>
      </div>
      <div id="responsive-table" class="p-5" v-else>
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                       <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Size From
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Size To
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Clr From
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Clr To
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        IF
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        VVS1
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        VVS2
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        VS1
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        VS2
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        SI1
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        SI2
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        SI3
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        I1
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        I1-
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        I2
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        I2-
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        I3
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        I3-
                      </th>
                       <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        PK-1
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        PK-2
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        PK-3
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(arrayresult ,key ,id) in page_data.rate_round" :key="key" :id="id" >
                      <td class="border-b whitespace-nowrap" v-if="key > 0">{{key}}</td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[0] != null">{{arrayresult[0].toFixed(4)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[1] != null">{{arrayresult[1].toFixed(4)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[2] != null">{{arrayresult[2]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[3] != null">{{arrayresult[3]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[5] != null">{{arrayresult[5].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[6] != null">{{arrayresult[6].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[7] != null">{{arrayresult[7].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[8] != null">{{arrayresult[8].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[9] != null">{{arrayresult[9].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[10] != null">{{arrayresult[10].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[11] != null">{{arrayresult[11].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[12] != null">{{arrayresult[12].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[13] != null">{{arrayresult[13].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[14] != null">{{arrayresult[14].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[15] != null">{{arrayresult[15].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[16] != null">{{arrayresult[16].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[17] != null">{{arrayresult[17].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[18] != null">{{arrayresult[18].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                       <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[19] != null">{{arrayresult[19].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[20] != null">{{arrayresult[20].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key > 0 && arrayresult[21] != null">{{arrayresult[21].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key > 0">N/A</span></td>
                      
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
         
      </div>
     

    <div>
    </div>
      
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, provide,reactive, onMounted } from 'vue'
import { apiUrl, BASE_URL } from "@/constants/config";

export default defineComponent({
  setup() {
    const dropzoneSingleRef = ref()
   
    const page_data = reactive({
      base_url : BASE_URL,
      last_sync_date : '',
      rate_round : [],
      load_price:false,
      price_cut:'ROUND',
      rate_fancy : [],
      token: localStorage.getItem("token"),
      showRoundPrice:true,
      showFancyPrice:false,
    })

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    const getOldRates = () => {
      page_data.load_price = true;
      const params = {
        "rate_id":  'TY736488',
        "cut": page_data.price_cut
      };
      
      let promise = axios({
        url: "api/get_std_rate_cvd",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
        
        if(data.details){
        page_data.load_price = false;
        page_data.rate_round = [];
        }else{
        page_data.last_sync_date = data.round_rate.created_at;
        page_data.rate_round = data.round_rate.price_all;
        page_data.load_price = false;
        console.log(page_data.load_price);
        }
        
        
        })
        .catch(_error => {
          
        });
    }
    const changeTab = (type) => {
      if(type == 1){
        page_data.showRoundPrice = true;
        page_data.showFancyPrice = false;
      }else {
        page_data.showRoundPrice = false;
        page_data.showFancyPrice = true;
      }
      
    }
  
  
    onMounted(() => {
      const elDropzoneSingleRef = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneSingleRef.dropzone.on('error', () => {
        alert('No more files please!')
      })
      getOldRates();
    })
   return {
      page_data,
      changeTab,
      getOldRates
    }
  }
})
</script>

