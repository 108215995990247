<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Kapan View - {{ kapanForm.kapan_code }}</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Kapan Details </h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
            </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Series No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ series_code }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Kapan No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_code }}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Kapan Name (Type) : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_name }}</label
                  >
                </div>

                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Pcs : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_total_pcs }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4">
                <div class="">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Kapan Weight (Original) : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.kapan_weight) }} (Ct) / {{ $h.myFloat(kapanForm.unissued_kapan_weight) }} (Ct)</label>
                </div>
                <!-- <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Kapan Weight (Available) : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.cr_avl_weight) }} (Ct)</label>
                </div> -->
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Lot Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lot_weight) }} (Ct)</label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Lot Available Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.lot_current_weight) }} (Ct)</label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Lost Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lot_lose) }} (Ct)</label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Assort Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lot_assort) }} (Ct)</label>
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Est. Makeable : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.exp_mackable }} &nbsp;(%)</label>
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Est. Polish : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ kapanForm.exp_polish }} &nbsp;(%)</label>
                </div>



              </div>
              <div class="col-span-4 xl:col-span-4">
                <div id="graph1" ref="graph1_wrap" style="width:100%; height:240px;"></div>
              </div>
            </div>
        </div>
        </div>

         <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Lot Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

          <button
            type="button"
            class="btn btn-primary shadow-md mr-2"
            v-print="'#multiple-barcode'"
            >Print All</button
          >
         <button
          type="button"
          class="btn btn-primary shadow-md mr-2"
          @click="printAllPackage"
          >Print All PDF</button
        >
            </div>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-6 xl:col-span-3">

                <div class="mt-3 mb-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Total Lot Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Total Lot Ct"
                    v-model="total_lot_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
              </div>

              <div class="col-span-12">
               <div class="border">
                <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="packet_status != 'yes'">
                      <p>Sr. No</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="packet_status == 'yes'">
                      <p>Lot No</p>
                    </div>
                    <div class="col-span-6 md:col-span-2 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Type</p>
                    </div>
                    <div class="col-span-6 md:col-span-2 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Shape</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Color</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Purity</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                      <p>Lot<br/>Weight</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Charni</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Polish<br> Weight</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Rap</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                      <p>Value</p>
                    </div>
              </div>

            <div class="border" v-for="field in fields" :key="field.id">
              <div class="grid grid-cols-12">
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2" v-if="packet_status != 'yes'">
                <label class="p-2 mt-2">{{field.id}}</label>
               </div>
               <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 p-2" v-if="packet_status == 'yes'">
                  <p>{{field.pack_no}}</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                <select class="form-control" tabindex="-1" v-model="field.type">
                    <option value="">Type</option>
                    <option value="Prince">Prince</option>
                    <option value="SM">SM</option>
                    <option value="4P">4P</option>
                </select>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                <select class="form-control" v-model="field.shape" tabindex="-1">
                  <option value="">Shape</option>
                   <option v-for="shape_item in shapes" :key="shape_item.id" :value="shape_item.id" >{{shape_item.shape_name}}</option>
                </select>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                 <select class="form-control" v-model="field.color" tabindex="-1">
                  <option value="">Color</option>
                   <option v-for="color in colors" :key="color.id" :value="color.id">{{color.shade_name}}</option>
                </select>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                <select class="form-control" v-model="field.purity" tabindex="-1">
                  <option value="">Purity</option>
                  <option v-for="purity_item in purity" :key="purity_item.id" :value="purity_item.id">{{purity_item.purity_name}}</option>
                </select>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.weight" type="text"
                    class="form-control" placeholder="Weight(Carat)" @keypress="$h.preventInvalidInput" @change="changeWeight(field.id)" onpaste="return false;">
              </div>

               <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                 <select class="form-control" v-model="field.charni" tabindex="-1">
                  <option value="">Charni</option>
                   <option v-for="charni_item in charni_ar" :key="charni_item.id" :value="charni_item.id" >{{charni_item.charni_name}}</option>
                </select>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                <input v-model="field.ex_weight" type="text"
                    class="form-control" placeholder="Expected Weight" @keypress="$h.preventInvalidInput"  @change="totalPackweight" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                <input v-model="field.rap" type="text"
                    class="form-control" placeholder="Rap Price" tabindex="-1" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1"  v-if="kapanForm.kapan_type == 1">
                <input v-model="field.value" type="text"
                    class="form-control" placeholder="Value" tabindex="-1" onpaste="return false;">
              </div>

               <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3" v-if="packet_status == 'yes'">
              <button type="button" class="text-theme-9 flex items-center" tabindex="-1" v-print="'#'+field.barcode" >
                <PrinterIcon class="w-4 h-4 mr-1" /> Print Label
              </button>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3">
              <button type="button" class="text-theme-6 flex items-center" tabindex="-1" @click="removeField(field.id)">
                <Trash2Icon class="w-4 h-4 mr-1" tabindex="-1" /> Delete
              </button>
              </div>
            </div>
              </div>
              <div class="grid grid-cols-12 top-0 z-50 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">
                  <div :class="{'col-span-6 md:col-span-1 font-bold xl:col-span-1':true}">
                    <p>Total :</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                    <p></p>
                  </div>
                   <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                    <p></p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                    <p></p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                    <p></p>
                  </div>
                  <div :class="{'col-span-6 md:col-span-1 font-bold xl:col-span-1':true,'text-theme-6': $h.myFloat(total_Packweight) > $h.myFloat(kapanForm.cr_avl_weight)}">
                    <p>{{$h.myFloat(total_Packweight)}} Ct</p>
                  </div>

                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" v-if="kapanForm.kapan_type == 1">
                    <p></p>
                  </div>
                  <div :class="{'col-span-6 md:col-span-1 font-bold xl:col-span-1':true,'text-theme-6': $h.myFloat(total_ex_weight_all) > $h.myFloat(total_Packweight)}" v-if="kapanForm.kapan_type == 1">
                    <p>{{$h.myFloat(total_ex_weight_all)}} Ct</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 xl:col-span-1">
                    <p></p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                    <p></p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                    <p></p>
                  </div>
              </div>
              <button type="button" @click="AddField" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                New Lot
              </button>
           </div>
          </div>
        </div>
        </div>
        <div class="p-5">
            <div class="flex justify-end ">
              <button type="button" class="btn btn-primary mr-auto" @click="savePacketDetails" :disabled="form_status">
                Save Lot Details <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!form_status"/>
              </button>
              <a href="" class="text-theme-6 flex items-center" v-if ="kapan_id != ''">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Kapan
              </a>
            </div>
        </div>

        <!-- END: Input -->
      </div>
    </div>
    </div>

    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">

                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
      <div  class="hidden" v-if="packet_fields.length > 0">
        <div id="multiple-barcode"  class="absolute top-0 p-0 m-0">
          <table style="page-break-before: always;" class="p-0" border="collapse" v-for="(packet,index) in packet_fields" :key="index">
            <tr class="text-center">
              <th colspan="3" class="text-center">
                <vue-barcode class="w-full"
                :value="packet.barcode_no"
                :options="{ displayValue: true,height:30,fontSize:15,width:2 }"

              />
                </th>
            </tr>
            <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
              <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{ packet.packet_code }}</td>
            <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
              <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
            </tr>
          </table>
          </div>
      </div>
      <div  class="hidden" v-if="packet_fields.length > 0">
        <table style="page-break-before: always;" class="absolute top-0 p-0 m-0" border="collapse" v-for="(packet,index) in packet_fields" :key="index" :id="packet.barcode_no">
          <tr class="text-center">
            <th colspan="3" class="text-center">
              <vue-barcode class="w-full"
              :value="packet.barcode_no"
              :options="{ displayValue: true,height:30,fontSize:17,width:2 }"

            />
              </th>
          </tr>
          <tr class="text-center border border-l-0 border-r-0 border-black m-0">
            <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{ packet.packet_code }}</td>
            <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
            <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import { BASE_URL,BASE_URL_PRINT } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
// import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';

import * as echarts from 'echarts/lib/echarts.js'

import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/dataZoomInside";
import "echarts/lib/component/dataZoomSlider";

export default defineComponent({
    components: {
      VueBarcode
    },
    props: {
      kapan_id: {
        type: String,
        default: ""
      }
    },

    data() {
      return {
        form_status:false,
        rough_id:'',
        tabulator:[],
        tabulatorkapan:[],
        total_lot_weight:'',
        packet_status:'',

        series_weight:0,
        series_code:'',
        series_weight_buffer:0,
        series_avl_weight:0,
        series_lose_weight:0,
        series_pa_rough:0,
        series_pa_bunch_weight:0,
        series_cr_avl_weight:0,
        series_total_kapan_weight:0,
        series_total_rejection:0,
        series_weight_in_process:0,
        unissued_rough_weight_buff:0,
        series_in_hand:0,

        operators:[],
        assorts:[],
        process_ar:[],
        charni_ar:[],
        charni:'',
        purity:[],
        colors:[],
        shapes:[],

        status: '1',

        total_items:1,

        fields: [
          {
            id:1,
            shape:'',
            weight: '',
            type:'',
            purity: '',
            ex_weight: '',
            color: '',
            charni:'',
            pack_no: '',
            barcode: '' ,
            rap:'',
            value:''
          }
        ],
        packet_fields: [],
        kapanForm:{
          lot_no:'',
          kapan_code:'',
          kapan_name:'',
          kapan_type:'',
          kapan_sub_type:'',
          kapan_purity:'',
          kapan_weight:0,
          cr_avl_weight:0,
          total_lose:0,
          total_lot_lose:0,
          total_lot_assort:0,
          total_lot_weight:0,
          lot_current_weight:0,
          unissued_kapan_weight:0,
          kapan_total_pcs:'',
          cleaver_id:'',
          cleaver_name:'',
          cleaver_issue_date:'',
          exp_mackable:'',
          exp_polish:'',
          kapan_id:''
        },
        assortForm:{
          assort_type:'',
          assort_id:'',
          assort_name:'',
          total_pcs:'',
          weight:'',
          weight_per:'',
          result_weight:'',
          result_per:''
        },
        total_Packweight:0,
        total_ex_weight_all:0,
        loginVal: '',
        req: axios.create({
          baseUrl: BASE_URL
        })
      }
    },

    mounted(){
    this.getRoughDetails(this.kapan_id);
    this.getRoughPacketDetails(this.kapan_id);
    this.getColors();
    this.getPurity();
    this.getShape();
    this.getCharni();
    this.getProcess();
    this.createEmptySlot();
    },

    methods: {
    async getProcess() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.process_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getShape() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_shape",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.shapes = data.items;

        })
        .catch(_error => {

        });
    },
    async getCharni() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_charni",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.charni_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getColors() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_color",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.colors = data.items;
        })
        .catch(_error => {

        });
    },
    async getPurity() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_purity",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.purity = data.items;
        })
        .catch(_error => {

        });
    },
    createEmptySlot(){
      let _this = this;
       let total_pc = this.kapanForm.kapan_total_pcs;
       let i = 1;
       for(i = 1;i<total_pc;i++){
         this.total_items += 1;
         this.fields.push({ id:this.total_items,weight: '',shape:'',charni:'',purity: '',type:'',rap:'',
          value:'',ex_weight: '',color: '',pack_no: '',barcode: '' })
       }
      //_this.fillValue();
    },
    async fillValue(){
      let _this = this;
      const data = {
        number: this.kapanForm.kapan_total_pcs,
        total_weight: this.kapanForm.cr_avl_weight
      };
      let promise = axios({
        url: "api/get_packet_dummy_data",
        method: "POST",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let result_data = data.arr;
          let total_pc = this.kapanForm.kapan_total_pcs;
          let i = 0;
          for(i = 0;i<total_pc;i++){
            this.fields[i]['weight'] = parseFloat(result_data[i]).toFixed(4);
            this.fields[i]['ex_weight'] = parseFloat(result_data[i]).toFixed(4);
            //this.fields[i]['purity'] = Math.floor(Math.random() * 13);
            //this.fields[i]['color'] = Math.floor(Math.random() * 9);
          }

          _this.totalPackweight();
        })
        .catch(_error => {

        });
    },
    changeWeight(id){
      let _this = this;
      //console.log(id);

      var l = this.fields.length;
      var i = 0;
      while(i < l){
        if(i > 0){
          if(this.fields[i]['id'] == id){
            this.fields[i]['purity'] = this.fields[i-1]['purity'];
            this.fields[i]['color'] = this.fields[i-1]['color'];
            this.fields[i]['shape'] = this.fields[i-1]['shape'];
            this.fields[i]['type'] = this.fields[i-1]['type'];
            this.fields[i]['charni'] = this.fields[i-1]['charni'];
          }
        }
        i++;
      }
      _this.totalPackweight();

    },
    totalPackweight(){
      let total_pack_weight = 0;
      let total_ex_weight = 0;
      var i = this.fields.length;
        while(i--){
          if(this.fields[i]['weight'] != '' && this.fields[i]['weight'] != null){
              total_pack_weight += parseFloat(this.fields[i]['weight'])*100/100;
          }
          if(this.fields[i]['ex_weight'] != ''){
              total_ex_weight += parseFloat(this.fields[i]['ex_weight'])*100/100;
          }
        }
        if(!isNaN(total_pack_weight)){
          this.total_Packweight = total_pack_weight;
        }if(!isNaN(total_ex_weight)){
          this.total_ex_weight_all = total_ex_weight;
        }
    },
    printAllPackage(){
      let url = BASE_URL_PRINT + '/print-all-label/' + window.atob(this.kapan_id);
      window.open(url, '_blank');
    },
    async getRoughDetails(kapan_id) {
      let _this = this;
      const params = {
        kapan_id: kapan_id
      };
      let promise = axios({
        url: "api/get_kapan_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          let rough_detail = data.item_details;
          this.kapanForm.kapan_code = rough_detail.kapan_code;
          this.rough_id = rough_detail.rough_lot_id;
          this.kapanForm.kapan_name = rough_detail.kapan_name;
          this.kapanForm.kapan_purity = rough_detail.kapan_purity;
          this.kapanForm.kapan_type = rough_detail.kapan_type;
          this.kapanForm.kapan_sub_type = rough_detail.kapan_sub_type;
          this.kapanForm.kapan_weight = rough_detail.kapan_weight;
          this.kapanForm.cr_avl_weight = this.$h.myFloat(rough_detail.cr_avl_weight);
          this.kapanForm.total_lose = rough_detail.total_lose;
          this.kapanForm.total_lot_lose = rough_detail.total_lot_lose;
          this.kapanForm.total_lot_assort = rough_detail.total_lot_assort;
          this.kapanForm.total_lot_weight = rough_detail.total_lot_weight;
          this.kapanForm.lot_current_weight = rough_detail.lot_current_weight;
          this.kapanForm.kapan_total_pcs = rough_detail.kapan_total_pcs;
          this.kapanForm.cleaver_issue_date = rough_detail.cleaver_issue_date;
          this.kapanForm.exp_mackable = rough_detail.exp_mackable;
          this.kapanForm.exp_polish = rough_detail.exp_polish;
          this.kapanForm.lot_no = rough_detail.lot_no;
          this.kapanForm.cleaver_name = rough_detail.first_name + " " + rough_detail.last_name;
          this.packet_status = rough_detail.pack_created;
          this.total_lot_weight = rough_detail.total_lot_weight_series;
          //console.log(this.packet_status);

          this.kapanForm.unissued_kapan_weight = this.kapanForm.cr_avl_weight - this.kapanForm.total_lot_weight;

          //Series Details

          this.series_weight = this.$h.myFloat(rough_detail.series_weight);
          this.series_code = rough_detail.series_code;
          this.series_lose_weight = this.$h.myFloat(rough_detail.series_lose_weight);
          this.series_pa_rough = this.$h.myFloat(rough_detail.series_pa_rough);
          this.series_pa_bunch_weight = this.$h.myFloat(rough_detail.series_pa_bunch_weight);
          this.series_cr_avl_weight = this.$h.myFloat(rough_detail.series_cr_avl_weight);
          this.series_total_kapan_weight = this.$h.myFloat(rough_detail.series_total_kapan_weight);
          this.series_total_rejection = this.$h.myFloat(rough_detail.series_total_rejection);
          this.series_weight_in_process = this.$h.myFloat(rough_detail.series_weight_in_process);

          this.series_in_hand = this.$h.myFloat(this.$h.myFloat(rough_detail.series_cr_avl_weight) - this.$h.myFloat(rough_detail.total_kapan_lot_weight) - this.$h.myFloat(rough_detail.series_weight_in_process));
          // console.log(this.$h.myFloat(rough_detail.series_cr_avl_weight));
          // console.log(this.$h.myFloat(rough_detail.series_total_kapan_weight));
          // console.log(this.$h.myFloat(rough_detail.series_weight_in_process));
          // console.log(this.series_in_hand);

          let remaining_rough = 0;
          let extra_remaning_buffer = 0;
          if(this.series_cr_avl_weight > 0){
            remaining_rough = this.$h.myFloat(rough_detail.series_cr_avl_weight) - this.$h.myFloat(rough_detail.series_pa_rough);
            extra_remaning_buffer = this.$h.myFloat(remaining_rough/100);
            this.unissued_rough_weight_buff = this.$h.myFloat(this.$h.myFloat(remaining_rough + extra_remaning_buffer) - this.$h.myFloat(rough_detail.total_kapan_lot_weight) - this.$h.myFloat(rough_detail.series_weight_in_process) + this.$h.myFloat(rough_detail.total_lot_weight_series));
            //console.log("Unissued kapan" + this.unissued_rough_weight_buff);
          }

          if(this.packet_status == 'yes'){
            this.fields = rough_detail.packet_details;
            //console.log(this.fields);
            _this.totalPackweight();
          }
          if(rough_detail.pack_created != 'yes'){
            _this.createEmptySlot();
          }
          _this.pieWeight();

        })
        .catch(_error => {

        });
    },
    async getRoughPacketDetails(kapan_id) {
      let _this = this;
      const params = {
        id: kapan_id
      };
      let promise = axios({
        url: "api/get_packet_by_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.packet_fields = data.items


        })
        .catch(_error => {

        });
    },
    savePacketDetails() {
      this.errors = [];
      let total_lot_weight_on_update = 0 ;
      var i = this.fields.length ;
      while(i--){
        if(this.fields[i]['weight'] != '' && this.fields[i]['weight'] != null){
            total_lot_weight_on_update = this.$h.myFloat(this.$h.myFloat(total_lot_weight_on_update) + this.$h.myFloat(this.fields[i]['weight']));
        }
      }
      //console.log(total_lot_weight_on_update);
      //console.log(this.$h.myFloat(this.kapanForm.kapan_weight));
      if(this.$h.myFloat(total_lot_weight_on_update) > this.$h.myFloat(this.total_lot_weight)){
        this.$swal("Warning!", "Total Lot Weight can not be greater than Kapan Lot weight.", "warning");
        return;
      }
      //console.log(total_lot_weight_on_update);
      //console.log(this.unissued_rough_weight_buff);
      if(this.$h.myFloat(total_lot_weight_on_update) > this.$h.myFloat(this.unissued_rough_weight_buff)){
        this.$swal("Warning!", "Total Lot Weight can not be greater than available series weight.", "warning");
        return;
      }

      if (!this.errors.length) {
        this.form_status = true;

        const data = {
          kapan_id: this.kapan_id,
          packet_details: this.fields,
          total_lot_wt : this.$h.myFloat(this.total_lot_weight),
          total_lot_weight : this.$h.myFloat(total_lot_weight_on_update)
        };

        if(this.packet_status == 'yes'){
          this.url = "api/re_update_kapan_packet"
        }else{
          this.url = "api/update_kapan_packet"
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {
            this.$notify(
              "success",
              "Record Added",
              "New Packet Added Successfully",
              {
                duration: 2000,
                permanent: false
              },
              setTimeout(() => {
                this.form_status = false;
                this.$router.push("/rough/rough-packets-master");
              }, 500)
            );
          })
          .catch(response => {
            this.form_status = false;
          });
      }
    },
    preventInvalidInput(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    AddField() {
      this.total_items++;
      this.fields.push({ id:this.total_items,weight: '',purity: '',ex_weight: '',color: '',pack_no: '',barcode: '' });
      //console.log(this.total_items);

    },
    removeField(id) {
        this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.fields.length;
            let packet_item_id = '';
              while(i--){
              if(this.fields[i]['id'] == id ){
                  this.fields.splice(i,1);
                  if(this.packet_status == 'yes'){
                    packet_item_id = this.fields[i]['packet_item_id'];
                  }
              }
            }
            //Call delete api if packet is saved
              if(this.packet_status == 'yes'){
              const data = {
                kapan_id: this.kapan_id,
                packet_details: this.fields,
                packet_id: packet_item_id
              };

              this.url = "api/delete_item_packet"

              axios({
                url: this.url,
                method: "post",
                data: data,
                baseURL: BASE_URL,
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                config: { headers: { Accept: "application/json" } }
              })
                .then(response => {
                  this.$router.go();
                })
                .catch(response => {
                  this.form_status = false;
                });
               }
          }
      });
    },
    pieWeight(){
    let myChart = echarts.init(this.$refs.graph1_wrap);
    myChart.showLoading({
        text: "Gathering Information",
        color: "#145388"
      });
      let option = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '20%',
    left: 'right',
     orient: 'vertical',
  },
  series: [
    {
      name: 'Rough Allotment',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,

        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '10',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: parseFloat(this.kapanForm.unissued_kapan_weight).toFixed(4), name: 'Unsorted',itemStyle:{
          color:"#34baeb"
        } },
        { value: this.kapanForm.lot_current_weight, name: 'Lot Available' ,itemStyle:{
          color:"#34eb7a"
        }},
        { value: this.kapanForm.total_lot_lose, name: 'Lot Ghat' ,itemStyle:{
          color:"#ff0000"
        }},
        { value: this.kapanForm.total_lot_assort, name: 'Lot Rejection' ,itemStyle:{
          color:"#f5b942"
        }}

      ]
    }
  ]
};
myChart.hideLoading();
          myChart.setOption(option, true);
          myChart.resize();
    },
  },

})
</script>
