<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet Block List</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
              <div>
                <label for="update-profile-form-6" class="form-label py-1"
                  >Search Packet</label
                >
                <input
                  v-model="filter.packet_code"
                  type="text"
                  id="packet-scan-here"
                  class="form-control w-full mt-2 sm:mt-0"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3">
              <div>
                <label for="update-profile-form-6" class="form-label py-1"
                  >Select Kapan</label
                >
                <TomSelect
                  v-model="filter.kapan_id"
                  :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Kapan'
                      }
                    }
                  }"
                  class="w-full"
                  multiple
                >
                <!-- <option value="0">Select Kapan</option> -->
                <option v-for="kapan in pagedata.kapan_arr" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3">
              <div>
                <label for="update-profile-form-6" class="form-label py-1"
                  >Select Process</label
                >
                <TomSelect
                  v-model="filter.process_id"
                  :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }"
                  class="w-full"
                  multiple
                >
                <!-- <option value="0">Select Process</option> -->
                <option v-for="process in pagedata.process_arr" :key="process.id" :value="process.id">{{process.process_code}}</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3" v-if="user_details.user_type == 1 || user_details.user_type == 20">
              <div>
                <label for="update-profile-form-6" class="form-label py-1"
                  >Select Manager</label
                >
                <TomSelect
                  v-model="filter.manager_id"
                  :options="{
                    placeholder: 'Select Manager',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Manager'
                      }
                    }
                  }"
                  class="w-full"
                >
                <option value="0">Select Manager</option>
                <option v-for="manager in pagedata.manager_arr" :key="manager.id" :value="manager.id">{{manager.first_name}} {{manager.last_name ? manager.last_name : ''}}</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-1">
              <div class="mt-7 flex pt-2">

                <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="generateDetails"
                >
                  Go
                </button>
              </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
              <div class="mt-7 flex pt-2">
                <button
                  id="tabulator-html-filter-reset"
                  type="button"
                  class="btn btn-secondary w-full"
                  @click="onResetFilter"
                >
                  Reset
                </button>
              </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
              <div class="mt-7 flex pt-2">

                <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="generateDetails"
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="flex mt-7 ml-3 sm:mt-0">
          <div>

            <button class="btn btn-primary w-24 mt-9" v-if="pagedata.total.total_packets && pagedata.total.total_packets > 0 && (user_details.user_type == 1 || user_details.user_type == 20)" @click="acceptAllPackets">Accept All</button>
          </div>
       </div>
      </div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-2">
                <div class="flex items-center justify-between">
                  <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                  <div class="text-base text-gray-600">Total Packets</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '0' }}</div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Modal Content -->
    <div
      id="blocked-accept-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Confirm Polish Weight
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="text-3xl mt-5 text-center">Are you sure?</div>
              <div class="text-gray-600 mt-2 text-center">
                Please note that this will automatically <br>
                update Packets Polish Weight, <br>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label"
                >Polish Weight</label
              >
              <input
                id="modal-form-1"
                type="text" :disabled="pagedata.is_editable == false"
                v-model="pagedata.new_polish_weight"
                class="form-control"
                placeholder="Polish eight"
              />
            </div>
            <div class="col-span-12">
              <div class="text-black mt-2"><b>Please confirm the New Polish Weight !</b></div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-28 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-28" :disabled="pagedata.submit_loading" @click="submitRoughNewPolishWeight">
              Accept <LoadingIcon v-if="pagedata.submit_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <!-- Start: Single Packet History -->
    <div
      id="single-packet-details-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content"><!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Packet Details
            </h2>
            <button class="btn btn-primary" v-print="'#print-packet-details'">
              <!-- <FileIcon class="w-4 h-4 mr-2" /> -->
              Print
            </button>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <div class="py-5 px-1">
              <div class="grid grid-cols-12 gap-x-5" id="print-packet-details">
                <div class="col-span-12 ">
                  <h3 class="text-lg mr-auto" v-if="pagedata.packet_code">Packet Number :- {{ pagedata.packet_code }}</h3>
                  <div class="overflow-x-auto scrollbar-hidden">
                    <div
                      id="tabulatorHistory"
                      ref="tableRefHistory"
                      class="mt-5 table-report table-report--tabulator"
                    >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!-- End: Single Packet History -->

  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, inject, computed } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import cash from 'cash-dom'
import { helper as $h } from '@/utils/helper'
import store from '@/store'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRefHistory = ref()
    const tabulatorHistory = ref()
    const tableRef = ref()
    const tabulator = ref()
    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)

    const pagedata = reactive({
      kapan_arr: [],
      manager_arr: [],
      process_arr: [],
      packet_id: '',
      packet_code: '',
      new_polish_weight: '',
      process_id: '',
      is_editable: false,
      submit_loading: false,
      total: []
    })
    const filter = reactive({
      packet_code: '',
      kapan_id: [],
      process_id: [],
      manager_id: '0'
    })

    const initTabulator = () => {

      let params = {
        "kapan_id": filter.kapan_id.length > 0 ? filter.kapan_id : '',
        "packet_code": filter.packet_code != '' ? window.btoa(filter.packet_code) : '',
        "manager_id": filter.manager_id != '0' ? window.btoa(filter.manager_id) : '',
        "process_id": filter.process_id.length > 0 ? filter.process_id : ''
      }
      if(user_details.value.user_type == 1 || user_details.value.user_type == 20) {
        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + '/api/get_blocked_packet_details',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams: params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            {
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'left',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Packet',
              minWidth: 130,
              responsive: 0,
              field: 'packet_code',
              vertAlign: 'middle',
              print: false,
              download: true,
              cellClick:function(e, cell){
                pagedata.packet_code = cell.getData().packet_code
                getPacketTransactionDetails(cell.getData().id)
              }
            },

            {
              title: 'Process',
              // minWidth: 100,
              field: 'process_code',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Manager',
              minWidth: 100,
              field: 'packet_manager_first_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            },
            {
              title: 'Employee',
              minWidth: 100,
              field: 'accounts_employee_first_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            },
            {
              title: 'MK Weight',
              // minWidth: 100,
              field: 'mk_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Oriznal Ex Wt',
              // minWidth: 100,
              field: 'main_pw',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Min Ex Wt',
              // minWidth: 100,
              field: 'block_polish_history',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Cr Weight',
              // minWidth: 100,
              field: 'cr_weight',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                if(cell.getData().process_id == 33) {
                  if(cell.getData().pack_final_pw < cell.getData().ex_weight){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                       + cell.getData().pack_final_pw +
                    `</div>`
                  }else{
                    return cell.getData().pack_final_pw
                  }
                } else {

                  if(cell.getData().cr_weight < cell.getData().ex_weight){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                       + cell.getData().cr_weight +
                    `</div>`
                  }else{
                    return cell.getData().cr_weight
                  }
                }
              }
            },
            {
              title: 'Polish Wt',
              // minWidth: 100,
              field: 'ex_weight',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                if(cell.getData().process_id == 33) {

                  if(cell.getData().pack_final_pw < cell.getData().min_pw){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                      + cell.getData().pack_final_pw +
                      `</div>`
                    }else{
                    return cell.getData().pack_final_pw
                  }
                } else {

                  if(cell.getData().ex_weight < cell.getData().min_pw){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                      + cell.getData().ex_weight +
                      `</div>`
                    }else{
                    return cell.getData().ex_weight
                  }
                }
              }
            },
            {
              title: 'Date',
              minWidth: 90,
              field: 'created_at',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            },
            {
              title: 'Action',
              minWidth: 80,
              field: 'action',
              responsive: 1,
              hozAlign: 'left',
              vertAlign: 'left',
              headerSort:false,
              print: false,
              download: false,
              formatter(cell) {
                const a = cash(`<div><button class="btn btn-primary p-2 lg:justify-center items-center">
                  <span class="flex items-center" >
                    <i data-feather="edit" class="w-4 h-4 mr-1"></i> Accept
                  </span>
                </button></div>`)

                return a[0]
              },cellClick:function(e, cell){

                if(cell.getData().process_id == 33) {

                  if(cell.getData().pack_final_pw < cell.getData().min_pw) {
                    pagedata.new_polish_weight = cell.getData().pack_final_pw
                    pagedata.is_editable = false
                  } else if(cell.getData().cr_weight < cell.getData().ex_weight) {
                    pagedata.new_polish_weight = cell.getData().cr_weight
                    pagedata.is_editable = false
                  }
                } else {

                  if(cell.getData().ex_weight < cell.getData().min_pw) {
                    pagedata.new_polish_weight = cell.getData().ex_weight
                    pagedata.is_editable = false
                  } else if(cell.getData().cr_weight < cell.getData().ex_weight) {
                    pagedata.new_polish_weight = cell.getData().cr_weight
                    pagedata.is_editable = true
                  }
                }
                pagedata.process_id = cell.getData().process_id
                pagedata.packet_id = cell.getData().id


                // On click actions
                cash('#blocked-accept-modal').modal('show')
            },
            },
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      } else {
        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + '/api/get_blocked_packet_details_by_manager',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams: params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            {
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'left',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Packet',
              minWidth: 130,
              responsive: 0,
              field: 'packet_code',
              vertAlign: 'middle',
              print: false,
              download: true,
              cellClick:function(e, cell){
                pagedata.packet_code = cell.getData().packet_code
                getPacketTransactionDetails(cell.getData().id)
              }
            },

            {
              title: 'Process',
              // minWidth: 100,
              field: 'process_code',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Employee',
              minWidth: 100,
              field: 'accounts_employee_first_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            },
            {
              title: 'MK Weight',
              // minWidth: 100,
              field: 'mk_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Oriznal Ex Wt',
              // minWidth: 100,
              field: 'main_pw',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Min Ex Wt',
              // minWidth: 100,
              field: 'block_polish_history',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true
            },
            {
              title: 'Cr Weight',
              // minWidth: 100,
              field: 'cr_weight',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                if(cell.getData().process_id == 33) {
                  if(cell.getData().pack_final_pw < cell.getData().ex_weight){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                       + cell.getData().pack_final_pw +
                    `</div>`
                  }else{
                    return cell.getData().pack_final_pw
                  }
                } else {

                  if(cell.getData().cr_weight < cell.getData().ex_weight){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                       + cell.getData().cr_weight +
                    `</div>`
                  }else{
                    return cell.getData().cr_weight
                  }
                }
              }
            },
            {
              title: 'Polish Wt',
              // minWidth: 100,
              field: 'ex_weight',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                if(cell.getData().process_id == 33) {

                  if(cell.getData().pack_final_pw < cell.getData().min_pw){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                      + cell.getData().pack_final_pw +
                      `</div>`
                    }else{
                    return cell.getData().pack_final_pw
                  }
                } else {

                  if(cell.getData().ex_weight < cell.getData().min_pw){
                    return `<div class="flex items-center lg:justify-center text-theme-6">`
                      + cell.getData().ex_weight +
                      `</div>`
                    }else{
                    return cell.getData().ex_weight
                  }
                }
              }
            },
            {
              title: 'Date',
              minWidth: 90,
              field: 'created_at',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            }
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      }
    }

    const getKapan = () => {
      const params = {
      };
      let promise = axios({
        url: "api/get_blocked_packet_kapan_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapan_arr = data.items

        })
        .catch(_error => {
          pagedata.kapan_arr = []
        });
    }
    const getTotalAvailable = () => {

      let new_url = ""

      if(user_details.value.user_type == 1 || user_details.value.user_type == 20) {
        new_url = "api/get_blocked_packet_details_total";
      } else {
        new_url = "api/get_blocked_packet_details_by_manager_total";
      }
      const params = {

        "kapan_id": filter.kapan_id.length > 0 ? filter.kapan_id : '',
        "packet_code": filter.packet_code != '' ? window.btoa(filter.packet_code) : '',
        "manager_id": filter.manager_id != '0' ? window.btoa(filter.manager_id) : '',
        "process_id": filter.process_id.length > 0 ? filter.process_id : ''
      };
      let promise = axios({
        url: new_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.total = data.item

        })
        .catch(_error => {
          pagedata.kapan_arr = []
        });
    }
    const getProcesses = () => {
      const params = {
      };
      let new_url = ""
      if(user_details.value.user_type == 1 || user_details.value.user_type == 20) {
        new_url = "api/dp_process"
      } else {
        new_url = "api/dp_process_by_user_packets"
      }
      let promise = axios({
        url: new_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.process_arr = data.items

        })
        .catch(_error => {
          pagedata.process_arr = []
        });
    }
    const getManagers = () => {
      const params = {
      };
      let promise = axios({
        url: "api/get_block_manager_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.manager_arr = data.items

        })
        .catch(_error => {
          pagedata.manager_arr = []
        });
    }

    const submitRoughNewPolishWeight = () => {

      pagedata.submit_loading = true
      const params = {
        packet_id: window.btoa(pagedata.packet_id),
        new_polish_weight: window.btoa(pagedata.new_polish_weight),
        process_id: window.btoa(pagedata.process_id),
        editable: pagedata.is_editable,
      };
      let promise = axios({
        url: "api/accept_blocked_packet_item",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {
          let message = data.message
          if(data.error == 'YES') {
            swal("Error!", message, "error");
          } else {
            // swal("Success!", message, "success").fire({
            //   timer: 1500
            // })

            swal.fire({
              icon: 'success',
              title: 'Success!',
              text: message,
              showConfirmButton: false,
              timer: 1000
            })
            cash('#blocked-accept-modal').modal('hide')
            generateDetails()
          }
          pagedata.submit_loading = false

        })
        .catch(_error => {
          pagedata.submit_loading = false
        });
    }

    function getPacketTransactionDetails(id) {

      cash('#single-packet-details-modal').modal('show')
      setTimeout(() => {

        tabulatorHistory.value = new Tabulator(tableRefHistory.value, {
          ajaxURL: BASE_URL + '/api/list_packet_process_history_block',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams:{id:id},
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          // pagination: 'remote',
          // paginationSize: 20,
          // paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: false,
          tooltipsHeader:true,
          headerSort: false,
          placeholder: 'No matching records found',
          columns: [
            // For HTML table
            {
              title: 'Transaction No',
              field: 'transaction_code_kapan',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //e - the click event object
                //cell - cell component
                if(cell.getData().transaction_type == 1){
                  return cell.getData().transaction_code_kapan;
                }else if(cell.getData().transaction_type == 2){
                  return cell.getData().transaction_code_packet;
                }else if(cell.getData().transaction_type == 3){
                  return cell.getData().transaction_code_packet_received;
                }else{
                  return "-";
                }
            }
            },
            {
              title: 'Process',
              minWidth: 100,
              field: 'process_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //e - the click event object
                //cell - cell component
                if(cell.getData().return_record == 1){
                  return "<div class='text-theme-6'>Received</div>";
                }else if((cell.getData().transaction_id == null || cell.getData().transaction_id == '') && ( cell.getData().process_id == null)){
                  return "<div class='text-theme-1'>Created</div>";
                }else if(cell.getData().process_id != '' && cell.getData().process_id != null){
                  return  `<div class='text-theme-9'>${cell.getData().process_name}</div>`;
                }else{
                  return "<div class='text-theme-1'>Transfered</div>";
                }
            }

            },
            {
              title: 'Issue To',
              minWidth: 100,
              field: 'process_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //e - the click event object
                //cell - cell component
                if((cell.getData().transaction_id == null || cell.getData().transaction_id == '') && ( cell.getData().process_id == null)){
                  return cell.getData().manager_first_name;
                }
                else if(cell.getData().process_id != '' && cell.getData().process_id != null){
                  if(cell.getData().karigar_type == 4){
                    return cell.getData().prop_first_name +`(`+ cell.getData().prop_company_name  +`)`;
                  }
                  else if(cell.getData().process_id == 29 || cell.getData().process_id == 1 || cell.getData().process_id == 27 || cell.getData().process_id == 28 || cell.getData().process_id == 3 || cell.getData().process_id == 31){
                    return cell.getData().karigar_manager_first_name;
                  }else if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id ==  20){
                    return cell.getData().planner_first_name;
                  }else if(cell.getData().process_id == 32){
                    return cell.getData().pp_first_name;
                  }else{
                    return cell.getData().karigar_first_name;
                  }
                }else{
                  return cell.getData().manager_first_name;
                }
            }

            },
            {
              title: 'Weight(I)',
              minWidth: 100,
              field: 'issue_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //return `<div>`+ Number(cell.getData().kapan_weight) + ` Ct<br>` + Number(cell.getData().total_lot_weight) + `Ct </div>`;
                return $h.myFloat(cell.getData().issue_weight);
              }
            },{
              title: 'Weight(R)',
              field: 'received_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                return $h.myFloat(cell.getData().received_weight);
              }
            },{
              title: 'Ex Wt',
              field: 'polish_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                return cell.getData().polish_weight != null ? $h.myFloat(cell.getData().polish_weight) : '-';
              }
            },{
              title: 'New Ex Wt',
              field: 'polish_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                return (cell.getData().new_polish_weight != null ? $h.myFloat(cell.getData().new_polish_weight) : '-');
              }
            },
            {
              title: 'Issue Date',
              minWidth: 80,
              field: 'created_at',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            },
            {
              title: 'Return Date',
              minWidth: 80,
              field: 'return_date',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter: "textarea"
            },


          ],
          renderComplete() {

          }
        })
      }, 10);
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {
      filter.packet_code = ''
      filter.kapan_id = []
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }



    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'buyer_list.xlsx', {
        sheetName: 'Buyers'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    function acceptAllPackets() {

      swal({
        title: "Are you sure?",
        text: "You Want to Accept All Packets <br> <span>Expected Polish weight will change according to Current Makeable and Expected Polish Weight!</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            console.warn("Hii")
              const params = {

              "kapan_id": filter.kapan_id.length > 0 ? filter.kapan_id : '',
              "packet_code": filter.packet_code != '' ? window.btoa(filter.packet_code) : '',
              "manager_id": filter.manager_id != '0' ? window.btoa(filter.manager_id) : '',
              "process_id": filter.process_id.length > 0 ? filter.process_id : ''
              };

            let promise_url_next = "api/accept_all_blocked_packets";
            let promise = axios({
              url: promise_url_next,
              method: "POST",
              data:params,
              baseURL: BASE_URL,
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
            .then(result => result.data)
            .then(data => {
              swal.fire({
                icon:'success',
                title:"Success!",
                text:"All Blocked Packets Accepted",
                showConfirmButton: false,
                timer:1500
              })
              generateDetails()
            })
            .catch(_error => {
              swal("Warning!","Somthing Wents Wrong!","warning")
            });

          }
      })

    }

    function generateDetails() {
      initTabulator()
      getTotalAvailable()
    }

    onMounted(() => {
      initTabulator()
      getTotalAvailable()
      getKapan()
      getProcesses()

      if(user_details.value.user_type == 1 || user_details.value.user_type == 20) {
        getManagers()
      }
      reInitOnResizeWindow()
      document.querySelector('[id="packet-scan-here"]').focus();
    })


    return {
      tableRef,
      tableRefHistory,
      filter,
      user_details,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      initTabulator,
      generateDetails,
      submitRoughNewPolishWeight,
      acceptAllPackets,
      pagedata
    }

  }
})
</script>

