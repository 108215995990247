<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Blocking Stock Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Series</label
            >
            <TomSelect
              v-model="screenData.series_id"
              :options="{
                placeholder: 'Select Series',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              class="w-full"
              @change="seriesChange"
            >
            <option value="0">Select Series</option>
            <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Kapan</label
            >
            <TomSelect
              v-model="screenData.kapan_id"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan'
                  }
                }
              }"
              class="w-full"
              @change="kapanChange"
            >
            <option value="0">Select Kapan</option>
            <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Packets</label
            >
            <TomSelect
              v-model="screenData.packets"
              :options="{
                placeholder: 'Select Packet',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Packets'
                  }
                }
              }"
              class="w-full"
              multiple
            >
            <option value="">Select Packets</option>
            <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-3" v-if="userDetails.user_type == 1 || userDetails.user_type == 27">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Manager</label
            >
            <TomSelect
              v-model="screenData.issue_id"
              :options="{
                placeholder: 'Select Karigar',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Karigar'
                  }
                }
              }"
              class="w-full"
              @change="managerChange"
            >
            <option value="0">Select Manager</option>
            <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.user_id">{{manager.first_name}} {{manager.last_name}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-3">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Karigar</label
            >
            <TomSelect
              v-model="screenData.karigar_id"
              :options="{
                placeholder: 'Select Karigar',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Karigar'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Karigar</option>
            <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{karigar.last_name}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Start Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.issue_date"
            />
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div  class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Select Status</label
            >
            <TomSelect
              v-model="screenData.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }"
              class="w-full"
            >
            <option value="">Select Status</option>
            <option value="0">All</option>
            <option value="1">In Porcess</option>
            <option value="2">Received</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 xl:col-span-1">
          <div class="mt-10">

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
          </div>
        </div>
        <div class="col-span-2 xl:col-span-1">
          <div class="mt-10 flex">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport"
            >
            Generate
            </button>
          </div>
        </div>
        <!-- <div class="col-span-2 xl:col-span-1" v-if="pagedata.generate == 'gen'">
          <div class="mt-10 flex">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              v-print="'#generate-pdf'"
            >
            Print
            </button>
          </div>
        </div> -->
        <div class="col-span-2 xl:col-span-1" v-if="pagedata.generate == 'gen'">
          <div class="mt-10 flex">

            <div class="flex mt-5 sm:mt-0">

              <div class="dropdown w-1/2 sm:w-auto">
                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <a id="tabulator-export-xlsx" href="javascript:;" :aria-disabled="pagedata.excel_loading == true" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                          </a>
                      </div>
                  </div>
              </div>
              </div>
          </div>
        </div>

      </div>
      <div id="generate-pdf" class="overflow-x-auto scrollbar-hidden" v-if="pagedata.generate == 'gen'">
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Total</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '0' }} Pkts</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Iss.Wt</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.total_packets_weight ? $h.myFloat(pagedata.total.total_packets_weight) + ' ct' : '0.00 ct' }} </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Processing</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.processing_packets ? pagedata.total.processing_packets : '0' }} Pkts</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Iss.Wt</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.processing_weight ? $h.myFloat(pagedata.total.processing_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-1">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Received</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.received_packets ? pagedata.total.received_packets : '0' }} Pkts</div>
                </div>
                <div class="flex items-start justify-between text-right">
                  <div class="text-gray-600 text-sm"><div class="text-base text-gray-600">Iss.Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.received_issue_weight ? $h.myFloat(pagedata.total.received_issue_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
                <div class="flex items-start justify-between text-right">
                  <div class="text-gray-600 text-sm"><div class="text-base text-gray-600">Rec.Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.received_receive_weight ? $h.myFloat(pagedata.total.received_receive_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Ghat</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.ghat_weight ? $h.myFloat(pagedata.total.ghat_weight) + ' ct' : '0.00 ct' }}</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-white mt-1 text-sm">.</div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import { helper as $h } from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      employees: [],
      managers: [],
      process: 31,
      todayDate: '',
      total: [],
      stones: [],
      generate: 'no'
    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      packets:[],
      issue_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      stone_id: '0'
    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      packets:[],
      issue_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      stone_id: '0'
    })

    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }

    const initTabulator = () => {
      pagedata.generate = 'gen'

      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.packets = screenData.packets
      printData.issue_id = screenData.issue_id
      printData.karigar_id = screenData.karigar_id
      printData.status = screenData.status
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.stone_id = screenData.stone_id

      let params = {
        series_id :printData.series_id != '0' ? window.btoa(printData.series_id) : '',
        kapan_id :printData.kapan_id != '0' ? window.btoa(printData.kapan_id) : '',
        packets : printData.packets,
        karigar_id : printData.kapan_id != '0' ?window.btoa(printData.karigar_id) : '',
        stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
        issue_id : printData.issue_id != '0' ? window.btoa(printData.issue_id) : '',
        status : printData.status != '0' ? window.btoa(printData.status) : '',
        issue_date : printData.issue_date,
        receive_date : printData.receive_date
      }
      setTimeout(() => {

        if(printData.status == '1') {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + '/api/blocking_packets_list',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams:params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: false,
            tooltipsHeader:true,
            headerSort: false,
            placeholder: 'No matching records found',
            columns: [
              // For HTML table
              // For HTML table
              {
                  title: 'Packet No',
                  field: 'packet_code',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  cellClick:function(e, cell){
                    //e - the click event object
                    //cell - cell component
                    console.log(cell.getData().id);
                    // router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                  },
                  formatter(cell) {
                    if(cell.getData().status == 2){
                    return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().packet_code
                    }else{
                      return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().packet_code
                    }
                  }
                },
                {
                  title: 'Karigar',
                  field: 'karigan_name',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  formatter(cell) {
                      return cell.getData().karigar_first_name + '<br>' + cell.getData().karigar_last_name
                  }
                },

                {
                  title: 'Weight',
                  field: 'mk_weight',
                  vertAlign: 'middle',
                  print: false,
                  download: true,
                },
                {
                  title: 'Iss. Weight',
                  field: 'issue_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Ex. Weight',
                  field: 'ex_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Shape',
                  field: 'shape_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Color',
                  field: 'color_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },{
                  title: 'Purity ',
                  field: 'purity_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Status',
                  field: 'status',
                  vertAlign: 'middle',
                  print: false,
                  download: false,
                  formatter(cell) {

                    if(cell.getData().status == 2){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                    }else{
                      return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                    }
                  }
                },

            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        } else {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + '/api/blocking_packets_list',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams:params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: false,
            tooltipsHeader:true,
            headerSort: false,
            placeholder: 'No matching records found',
            columns: [
              // For HTML table
              // For HTML table
              {
                  title: 'Packet No',
                  field: 'packet_code',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  cellClick:function(e, cell){
                    //e - the click event object
                    //cell - cell component
                    console.log(cell.getData().id);
                    // router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                  },
                  formatter(cell) {
                    if(cell.getData().status == 2){
                    return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().packet_code
                    }else{
                      return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().packet_code
                    }
                  }
                },
                {
                  title: 'Karigar',
                  field: 'karigan_name',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  formatter(cell) {
                      return cell.getData().karigar_first_name + '<br>' + cell.getData().karigar_last_name
                  }
                },

                {
                  title: 'Weight',
                  field: 'mk_weight',
                  vertAlign: 'middle',
                  print: false,
                  download: true,
                },
                {
                  title: 'Iss. Weight',
                  field: 'issue_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Rec. Weight',
                  field: 'received_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Lose Weight',
                  field: 'total_lose',
                  print: false,
                  download: true
                },
                {
                  title: 'Ex. Weight',
                  field: 'ex_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'New Ex. Wt',
                  field: 'new_ex_weight',
                  print: false,
                  download: true
                },
                {
                    title: 'Diffrence',
                    field: 'defference',
                    print: false,
                    download: true,
                    formatter(cell) {
                      if(cell.getData().new_ex_weight != null && cell.getData().ex_weight != null) {
                        if(cell.getData().new_ex_weight > cell.getData().ex_weight){
                        return `<div class="font-bold text-theme-9 mr-1">`+ $h.myFloat(cell.getData().new_ex_weight - cell.getData().ex_weight) + `</div>`
                        }else if(cell.getData().new_ex_weight < cell.getData().ex_weight){
                          return `<div class="font-bold text-theme-6 mr-1">`+ $h.myFloat(cell.getData().ex_weight - cell.getData().new_ex_weight) + `</div>`
                        } else {
                          return `<div class="font-bold mr-1">`+ $h.myFloat(cell.getData().ex_weight - cell.getData().new_ex_weight) + `</div>`
                        }
                      } else {
                        return `<div class="font-bold mr-1">`+ '-' + `</div>`
                      }
                  }
                },
                {
                  title: 'Shape',
                  field: 'shape_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Color',
                  field: 'color_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Purity ',
                  field: 'purity_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Majuri ',
                  field: 'total_majuri',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Status',
                  field: 'status',
                  vertAlign: 'middle',
                  print: false,
                  download: false,
                  formatter(cell) {

                    if(cell.getData().status == 2){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                    }else{
                      return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                    }
                  }
                },

            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        }
      }, 50);

      let promise = axios({
        url: "api/blocking_packets_list_total",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.total = data.total

        })
        .catch(_error => {
          pagedata.total = []

        });
    }
    const blockingTotal = () => {
      let params = {
        series_id :screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id :screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        packets : screenData.packets,
        karigar_id : screenData.kapan_id != '0' ?window.btoa(screenData.karigar_id) : '',
        stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
        issue_id : screenData.issue_id != '0' ? window.btoa(screenData.issue_id) : '',
        status : screenData.status != '0' ? window.btoa(screenData.status) : '',
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date
      }
    }
    const getSeries = () => {
      let params = {
        process_id : pagedata.process,
        issue_id : screenData.issue_id != '0' ? window.btoa(screenData.issue_id) : ''
      }
      let promise = axios({
        url: "api/get_process_packet_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        issue_id: screenData.issue_id != '0' ? window.btoa(screenData.issue_id) : '',
        process_id : pagedata.process
      }
      let promise = axios({
        url: "api/get_process_packet_kapan",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = '0'
          screenData.packets = []

        })
        .catch(_error => {
          screenData.kapan_id = '0'
          screenData.packets = []

        });
    }
    const getPackets = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        issue_id: screenData.issue_id != '0' ? window.btoa(screenData.issue_id) : '',
        process_id : pagedata.process
      }
      let promise = axios({
        url: "api/get_process_packet_packets",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.packets = data.items
          screenData.packets = []

        })
        .catch(_error => {

        });
    }
    const getEmployees = () => {
      let params = {
        process: pagedata.process,
        issue_id: screenData.issue_id != '0' ? window.btoa(screenData.issue_id) : '',
      }
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items
          screenData.karigar_id = '0'

        })
        .catch(_error => {

        });
    }
    const getManagers = () => {
      let params = {
        process: pagedata.process,
      }
      let promise = axios({
        url: "api/dp_manager_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.managers = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.stone_id = '0'
      screenData.karigar_id = '0'
      screenData.issue_id = '0'
      screenData.status = '0'
      screenData.packets = []
      pagedata.generate = 'no'

    }

    function printReportDetails(){
      let report_type = ''
      if(printData.report_type == '2') {
        report_type = 'blocking-summary-lot-report'
        let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.report_details,'total': pagedata.total,"direct_polish_details": pagedata.direct_polish_details,"direct_polish_details_total": pagedata.direct_polish_details_total }
        }
        let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });
      } else {
        report_type = 'blocking-summary-report'
        let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.report_details,'total': pagedata.total,"direct_polish_details": pagedata.direct_polish_details,"direct_polish_details_total": pagedata.direct_polish_details_total }
        }
        let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });
      }

      // add_report_details
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    // const onExportXlsx = () => {
    //   const win = window
    //   win.XLSX = xlsx
    //   tabulator.value.download('xlsx', 'tender_list.xlsx', {
    //     sheetName: 'Tenders'
    //   })
    // }
    const onExportXlsx = () => {
      let filename = "blocking-lot.xlsx";
      let params = {
        series_id :printData.series_id != '0' ? window.btoa(printData.series_id) : '',
        kapan_id :printData.kapan_id != '0' ? window.btoa(printData.kapan_id) : '',
        packets : printData.packets,
        karigar_id : printData.kapan_id != '0' ?window.btoa(printData.karigar_id) : '',
        stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
        issue_id : printData.issue_id != '0' ? window.btoa(printData.issue_id) : '',
        status : printData.status != '0' ? window.btoa(printData.status) : '',
        issue_date : printData.issue_date,
        receive_date : printData.receive_date
      }
      let promise = axios({
          url: "/api/download_blocking_lot_report",
          method: "post",
          params: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
          .then((result) => result.data)
          .then((data) => {
              FileDownload(data, filename);
          })
          .catch((_error) => {
              return [];
          });
        }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      getPackets()
    }
    function seriesChange() {
      getKapans()
    }
    function kapanChange() {
      getPackets()
    }
    function managerChange() {
      getEmployees()
    }
    function generateReport() {
      initTabulator()
      // blockingTotal()
    }

    onMounted(() => {
      checkPermission()
      getSeries()
      getEmployees()
      getManagers()
      getDPStoneType()
      getKapans()
      reInitOnResizeWindow()
    })

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      managerChange,
      generateReport
    }
  }
})
</script>
