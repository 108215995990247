<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Stock Transfer Transaction</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0 mr-3" v-if="BUILD_ENV == 'factory'">
          <div class="flex items-center text-lg" v-if="pagedata.server_record.server_status == 1"><span class="font-bold mr-2">Last Updated :</span> <WifiIcon class="h-6 w-6 mr-2 text-theme-9" /> {{ pagedata.server_record.updated_at }}</div>
          <div class="flex items-center text-lg" v-if="pagedata.server_record.server_status == 2"><span class="font-bold mr-2">Last Updated :</span> <GlobeIcon class="h-6 w-6 mr-2 text-theme-6" /> {{ pagedata.server_record.updated_at }}</div>
        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

            <button @click="clicknewNonCertyTransfer" class="btn btn-primary shadow-md mr-3">Non-Certy Transfer</button>
            <button @click="clicknewCertyTransfer" class="btn btn-primary shadow-md mr-3">Certy Transfer</button>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <!-- <div class="border-b pb-5"> -->

        <div class="grid grid-cols-12 gap-x-2">

            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Start Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" @change="getTransactionRecords" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" @change="getTransactionRecords" />
                </div>
            </div>
            <!-- <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Stone</label>
                    <TomSelect v-model="filters.stone_type" :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }" class="w-full" multiple @change="getTransactionRecords">
                        <option value="">All</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Shape</label>
                    <TomSelect v-model="filters.shape" multiple :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-full" @change="getTransactionRecords">
                        <option value="">All</option>
                        <option value="1">ROUND</option>
                        <option value="2">FANCY</option>
                    </TomSelect>
                </div>
            </div> -->
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Stock Type</label>
                    <TomSelect v-model="filters.stock_type" :options="{
                  placeholder: 'Select Select Stock Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stock Type'
                    }
                  }
                }" class="w-full">
                        <option value="0">Stock Type</option>
                        <option value="1">Non-Certy</option>
                        <option value="2">Certy</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Transaction No</label>
                    <TomSelect v-model="filters.trans_id" multiple :options="{
                  placeholder: 'Select Select Branch Transfer Transaction',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Transaction Code'
                    }
                  }
                }" class="w-full">
                        <option value="">Select Transaction</option>
                        <option v-for="(item,index) in pagedata.trans_items" :key="index" :value="item.id">{{ item.transaction_code }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()" :disabled="pagedata.loading == true">
                        Generate
                        <LoadingIcon icon="bars" class="w-8 h-8" v-if="pagedata.loading == true" />
                    </button>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
  BASE_URL,
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
import Tabulator from 'tabulator-tables'
import feather from 'feather-icons'
import cash from 'cash-dom';
import moment from 'moment';
import {
    useRoute,
    useRouter
} from 'vue-router';

export default defineComponent({
    setup() {

        const swal = inject('$swal');

        const tableRef = ref()
        const tabulator = ref()
        const route = useRoute()
        const router = useRouter()
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const pagedata = reactive({

            trans_items: [],
            report_items: [],
            generate: "no",
            loading: false,
            server_record: []
        })

        const filters = reactive({
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            stock_type: "0",
            trans_id: [],
        })
        const initTabulator = () => {

            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                stock_type: (filters.stock_type != "0" ? filters.stock_type : ""),
                trans_id: filters.trans_id.length == 0 ? "" : filters.trans_id
            };
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/get_final_polish_branch_transaction_items',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxParams: params,
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Trans Code',
                        field: 'transaction_code',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        cellClick: function (e, cell) {
                            //e - the click event object
                            //cell - cell component
                            console.log(cell.getData().id);
                            router.push('/app/app-final-polish/branch-transfer/' + window.btoa(cell.getData().id))
                        },
                        formatter(cell) {
                            if (cell.getData().btn_type == 1) {
                                return `<i data-feather="arrow-up-circle" class="w-6 h-6 font-bold text-theme-4 mr-1"></i>` + cell.getData().transaction_code
                            } else {
                                return `<i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().transaction_code
                            }
                        }
                    },
                    {
                        title: 'Stock Type',
                        minWidth: 100,
                        field: 'stock_type',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Pieces',
                        minWidth: 100,
                        field: 'total_pcs',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Weight (Ct)',
                        minWidth: 100,
                        field: 'total_wt',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'From Branch',
                        field: 'from_branch_name',
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'To Branch',
                        field: 'to_branch_name',
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                    },
                    {
                        title: 'Issue Date',
                        field: 'issue_date',
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea"
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().trans_status == 1) {
                                return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                            } else if (cell.getData().trans_status == 3) {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                            } else if (cell.getData().trans_status == 2) {
                                return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                </div>`
                            } else if (cell.getData().trans_status == 4) {
                                return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepting
                </div>`
                            } else {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                  Unknown !
                </div>`
                            }
                        }
                    },
                    {
                        title: 'Action',
                        minWidth: 80,
                        field: '',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().btn_type == 1 && cell.getData().trans_status == 1) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-danger btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Withdraw
                    </button>
                  </div>`)
                                cash(a).on('click', function () {
                                    editTransactionStatus(cell.getData().id, 'withdraw');
                                })
                                return a[0]
                            } else if (cell.getData().btn_type == 2 && cell.getData().trans_status == 1) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                    </button>
                  </div>`)
                                cash(a).on('click', function () {
                                    editTransactionStatus(cell.getData().id, 'accept');
                                })

                                return a[0]
                            }
                        }
                    },

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const onResetFilter = () => {
            filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            filters.e_date = moment().format('YYYY-MM-DD')
            filters.trans_id = []
        }

        const getTransactionRecords = () => {
            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                stock_type: filters.stock_type == "0" ? "" : filters.stock_type,
            };

            let promise = axios({
                url: "/api/get_final_polish_branch_transaction_list",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filters.trans_id = "0"
                    pagedata.trans_items = data.items;

                })
                .catch(_error => {
                    filters.trans_id = "0"
                    pagedata.trans_items = [];
                });
        }
        const clicknewCertyTransfer = () => {
            router.push('/app/app-final-polish/branch-transfer/certy');
        }
        const clicknewNonCertyTransfer = () => {
            router.push('/app/app-final-polish/branch-transfer/non-certy');
        }

        //Edit user
        const editTransactionStatus = (id, action) => {

            const params = {
                "trans_id": id,
                'action': action
            };

            let text = ""
            let btn = ""

            if(action == "accept") {
              text = 'You are about to Accept Transferred Date!'
              btn = 'Confirm & Accept'
            } else {
              text = 'You are about to Reject Transferred Date!'
              btn = 'Confirm & Reject'
            }

            swal({
                title: "Are you sure?",
                text: text,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: btn,
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let api_url = "/get_final_polish_update_transaction"

                    let promise = axios({
                        url: api_url,
                        method: "post",
                        data: params,
                        baseURL: BASE_URL_SELLING,
                        headers: {
                            Authorization: "Bearer " + AUTH_TOKEN
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                })
                                initTabulator()
                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                })
                            }

                        })
                        .catch(_error => {
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });
        }
        const checkServerStatus = () => {
          let promise = axios({
              url: "api/get_last_updated_time",
              method: "post",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {

                  pagedata.server_record = data.items

                })
                .catch(_error => {
                pagedata.server_record = []

              });
        }

        onMounted(() => {
            getTransactionRecords();
            initTabulator()
            checkServerStatus()
        })

        return {
            filters,
            pagedata,
            tableRef,
            onResetFilter,
            initTabulator,
            getTransactionRecords,
            clicknewCertyTransfer,
            clicknewNonCertyTransfer,
            BUILD_ENV
        }
    }
})
</script>
