<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Final Polish Edit Requests</h2>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
          <!-- <div class="border-b pb-5"> -->

          <div class="grid grid-cols-12 gap-x-2">

              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Start Date</label>
                      <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" />
                  </div>

              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">End Date</label>
                      <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" />
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Request Type</label>
                      <TomSelect v-model="filters.request_type" :options="{
                    placeholder: 'Select Request Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Request Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All Requests</option>
                          <option value="1">Loose Merge</option>
                          <option value="2">Polish Purchase</option>
                          <option value="3">Non-Certy To Certy</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Status</label>
                      <TomSelect v-model="filters.status" :options="{
                    placeholder: 'Select Status',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Status'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="1">Hold</option>
                          <option value="2">Accepted</option>
                          <option value="3">Withdrawn</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                          Reset
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()" :disabled="page_data.loading == true">
                          Generate
                          <LoadingIcon icon="bars" class="w-8 h-8" v-if="page_data.loading == true" />
                      </button>
                  </div>
              </div>
          </div>
          <!-- </div> -->
          <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
  </div>
  </template>

  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject,
      computed
  } from 'vue'
  import {
    BASE_URL
  } from '@/constants/config.js'
  import Tabulator from 'tabulator-tables'
  import feather from 'feather-icons'
  import cash from 'cash-dom';
  import moment from 'moment';
  import {
      useRoute,
      useRouter
  } from 'vue-router';
  import store from '@/store';

  export default defineComponent({
      setup() {

          const swal = inject('$swal');

          const tableRef = ref()
          const tabulator = ref()
          const route = useRoute()
          const router = useRouter()
          const user_details = computed(() => store.state.user.currentUser)
          const page_data = reactive({

              trans_items: [],
              shape_items: [],
              report_items: [],
              generate: "no",
              loading: false,
              server_record: []
          })

          const filters = reactive({
              s_date: moment().startOf('month').format('YYYY-MM-DD'),
              e_date: moment().format('YYYY-MM-DD'),
              request_type: "0",
              status: "0",
              request_no: "",
          })
          const initTabulator = () => {

              const params = {
                  s_date: filters.s_date,
                  e_date: filters.e_date,
                  request_no: filters.request_no == "" ? "" : window.btoa(filters.request_no),
                  request_type: filters.request_type == "0" ? "" : window.btoa(filters.request_type),
                  status: filters.status == "0" ? "" : window.btoa(filters.status),
              };
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + '/api/fserc_gr',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'Request No',
                          field: 'request_code',
                          minWidth: 100,
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          cellClick: function (e, cell) {
                              //e - the click event object
                              //cell - cell component
                              router.push("/app/app-final-polish/edit/stocks/" + window.btoa(cell.getData().id));
                          },
                          formatter(cell) {
                              if (cell.getData().created_by == user_details.value.id) {
                                  return cell.getData().request_code
                              } else {
                                  return cell.getData().request_code
                              }
                          }
                      },
                      {
                          title: 'Request Type',
                          minWidth: 100,
                          field: 'request_type_name',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter: "textarea"

                      },
                      {
                          title: 'Transaction No',
                          minWidth: 100,
                          field: 'transaction_no',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter: "textarea"

                      },
                      {
                          title: 'Issue By',
                          minWidth: 100,
                          field: 'issue_user',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter: "textarea"

                      },
                      {
                          title: 'Issue Date',
                          width: 100,
                          field: 'issue_date',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter: "textarea"

                      },
                      {
                          title: 'Status',
                          field: 'status',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          width: 100,
                          print: false,
                          download: false,
                          formatter(cell) {
                            return `<div class="flex items-center lg:justify-center `+ cell.getData().statusColor +`">
                `+ cell.getData().status +`
              </div>`
                          }
                      },
                      {
                          title: 'Action',
                          hozAlign: 'center',
                          field: 'status',
                          width: 100,
                          vertAlign: 'middle',
                          print: false,
                          responsive: 0,
                          download: false,
                          formatter(cell) {

                              const dropdown = document.createElement("div");
                              dropdown.classList.add("dropdown");

                              const button = document.createElement("button");
                              button.classList.add("btn", "btn-secondary", "btn-sm", "dropdown-toggle");
                              button.setAttribute("id", "dropdownMenuButton");
                              button.setAttribute("type", "button");
                              button.setAttribute("data-toggle", "dropdown");
                              button.setAttribute("aria-haspopup", "true");
                              button.setAttribute("aria-expanded", "false");
                              button.innerText = "Action";

                              const menu_icon = document.createElement("i");
                              menu_icon.classList.add("w-4", "h-4", "ml-2");
                              menu_icon.setAttribute("data-feather", "chevron-down");
                              button.appendChild(menu_icon);

                              const menu = document.createElement("div");
                              menu.setAttribute("aria-labelledby", "dropdownMenuButton");
                              menu.classList.add("dropdown-menu")

                              const sub_menue = document.createElement("div")
                              sub_menue.classList.add("dropdown-menu__content", "box");

                              const main_view_details = document.createElement("div");
                              main_view_details.classList.add("p-1", "border-b", "dark:border-dark-3");
                              const view_details = document.createElement("div");
                              view_details.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "text-center", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "hover:text-white", "dark:hover:bg-dark-3");
                              view_details.setAttribute("data-dismiss", "dropdown");
                              view_details.innerText = "View Details";

                              view_details.addEventListener('click', function handleClick(event) {
                                  router.push("/app/app-final-polish/edit/stocks/" + window.btoa(cell.getData().id));
                              });
                              main_view_details.appendChild(view_details);
                              sub_menue.appendChild(main_view_details);

                              if([1,28].includes(user_details.value.user_type) && cell.getData().active_status == 1) {

                                const main_issue_process = document.createElement("div");
                                main_issue_process.classList.add("p-1", "border-b", "dark:border-dark-3");
                                const issue_process = document.createElement("div");
                                issue_process.setAttribute("data-dismiss", "dropdown");
                                issue_process.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "text-center", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "hover:text-white", "dark:hover:bg-dark-3");
                                issue_process.innerText = "Accept";

                                issue_process.addEventListener('click', function handleClick(event) {
                                    // Accept Request
                                    accept_request(cell.getData().id)
                                });
                                main_issue_process.appendChild(issue_process);
                                sub_menue.appendChild(main_issue_process);

                              }

                              if(cell.getData().active_status == 1 && (user_details.value.id == cell.getData().created_by || [1,28].includes(user_details.value.user_type))) {

                                const main_reject_purchase = document.createElement("div");
                                main_reject_purchase.classList.add("p-1", "border-b", "dark:border-dark-3");
                                const reject_purchase = document.createElement("div");
                                reject_purchase.setAttribute("data-dismiss", "dropdown");
                                reject_purchase.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "text-center", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "hover:text-white", "dark:hover:bg-dark-3");
                                reject_purchase.innerText = "Withdraw";

                                reject_purchase.addEventListener('click', function handleClick(event) {
                                    reject_request(cell.getData().id)
                                });
                                main_reject_purchase.appendChild(reject_purchase);
                                sub_menue.appendChild(main_reject_purchase);
                              }


                              menu.appendChild(sub_menue);
                              dropdown.appendChild(button);
                              dropdown.appendChild(menu);

                              const b = cash(dropdown);
                              return b[0]

                          }
                      }

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }

          const reject_request = (id) => {

            swal({
                  title: "Are you sure?",
                  text: "You are about to Withdraw Request!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Reject",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {



                page_data.failed_loading = true

                const params = {
                    req_id: window.btoa(id),
                    action_type: window.btoa("3")
                };

                let promise = axios({
                    url: "api/fserc_ur",
                    method: "POST",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.status == "success") {
                            swal.fire({
                                icon: "success",
                                title: "Success!",
                                text: data.message,
                            })
                            initTabulator()
                        } else {
                            swal({
                                title: "Warning!",
                                icon: "warning",
                                text: data.message,
                                type: "warning",
                            })
                        }

                    })
                    .catch(_error => {
                      swal({
                          title: "Warning!",
                          icon: "warning",
                          text: "Something went wrong.",
                          type: "warning",
                      })
                    });
                  }
              });
          }

          const accept_request = (id) => {

            swal({
                  title: "Are you sure?",
                  text: "You are about to Accept Request!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Accept",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {



                    page_data.failed_loading = true

                const params = {
                  req_id: window.btoa(id),
                  action_type: window.btoa("2")
                };

                let promise = axios({
                    url: "api/fserc_ur",
                    method: "POST",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.status == "success") {
                            swal.fire({
                                icon: "success",
                                title: "Success!",
                                text: data.message,
                            })
                            initTabulator()
                        } else {
                            swal({
                                title: "Warning!",
                                icon: "warning",
                                text: data.message,
                                type: "warning",
                            })
                        }

                    })
                    .catch(_error => {
                      swal({
                          title: "Warning!",
                          icon: "warning",
                          text: "Something went wrong.",
                          type: "warning",
                      })
                    });
                  }
              });
          }

          const onResetFilter = () => {
              filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
              filters.e_date = moment().format('YYYY-MM-DD')
              filters.request_no = ""
              filters.branch_id = "0"
              filters.stone_type = "0"
              filters.shape_type = "0"

              initTabulator()
          }

          const getShapeDetails = () => {
              const params = {
              };

              let promise = axios({
                  url: "/api/dp_shape",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.shape_items = data.items;

                  })
                  .catch(_error => {
                      page_data.shape_items = [];
                  });
          }
          const create_records = () => {
              router.push('/app/app-final-polish/edit/create');
          }

          //Edit user
          const checkServerStatus = () => {
            let promise = axios({
                url: "api/get_last_updated_time",
                method: "post",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.server_record = data.items

                  })
                  .catch(_error => {
                  page_data.server_record = []

                });
          }

          onMounted(() => {
              initTabulator()
              getShapeDetails()
              checkServerStatus()
          })

          return {
              filters,
              page_data,
              tableRef,
              onResetFilter,
              initTabulator,
              create_records
          }
      }
  })
  </script>
