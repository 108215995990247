<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Party Selling Report</h2>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
          <!-- <div class="border-b pb-5"> -->

          <div class="grid grid-cols-12 gap-x-2">

              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Transaction No</label>
                      <input type="text" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.transaction_no" />
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Party</label>
                      <TomSelect v-model="filters.party_id" :options="{
                    placeholder: 'Select Party',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Party'
                      }
                    }
                  }" class="w-full">
                          <option value="">Select Party</option>
                          <option v-for="(item,index) in page_data.party_list" :key="index" :value="item.id">{{ (item.first_name ? item.first_name : '-') + ' ' + (item.last_name ? item.last_name : '') }}</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Payment Status</label>
                      <TomSelect v-model="filters.payment_status" :options="{
                    placeholder: 'Payment Status',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Payment Status'
                      }
                    }
                  }" class="w-full">
                          <option value="0">All</option>
                          <option value="1">Pending</option>
                          <option value="2">Paid</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Date Type</label>
                      <TomSelect v-model="filters.date_type" :options="{
                    placeholder: 'Select Date Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Date Type'
                      }
                    }
                  }" class="w-full">
                          <option value="1">Sell Date</option>
                          <option value="2">Due Date</option>
                          <option value="3">Issue Date</option>
                          <option value="4">Payment Date</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Start Date</label>
                      <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" />
                  </div>

              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">End Date</label>
                      <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" />
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Stock Type</label>
                      <TomSelect v-model="filters.stock_type" :options="{
                    placeholder: 'Select Select Stock Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Stock Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Stock Type</option>
                          <option value="1">Non-Certy</option>
                          <option value="2">Certy</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Select Branch</label>
                      <TomSelect v-model="filters.branch_id" multiple :options="{
                    placeholder: 'Select Branch',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Branch Code'
                      }
                    }
                  }" class="w-full">
                          <option value="">Select Branch</option>
                          <option value="1">Home Branch</option>
                          <option v-for="(item,index) in page_data.branch_list" :key="index" :value="item.id">{{ item.branch_name }}</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                    <div class="mt-3">
                        <label for="update-profile-form-6" class="form-label">Due Days</label>
                        <input type="text" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.due_days" />
                    </div>
                </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                          Reset
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-3">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()" :disabled="page_data.loading == true">
                          Generate
                          <!-- <LoadingIcon icon="" class="w-8 h-8" v-if="page_data.loading == true" /> -->
                      </button>
                  </div>
              </div>
          </div>
          <!-- </div> -->
          <div class="overflow-x-auto scrollbar-hidden">
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                    <div class="preview">
                        <div class="overflow-x-auto">
                            <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                <LoadingIcon icon="bars" class="w-8 h-8" />
                                <div class="text-center text-xs mt-2">Getting Latest Records</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                  <div class="intro-y col-span-12 overflow-auto">
                            <table class="mt-3 w-full border-2">
                                <thead>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party Name</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Branch Name</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Transaction No</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Stock Type</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Stone Types</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Pieces</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Weight</th>
                                        <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Broker</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Brokerage</th> -->
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Days</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Sell Date</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">OverDue Days</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Date</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Payment Date</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Date</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Total Amount</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Paid Amount</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Pending Amount</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Settlement Amount</th>
                                        <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Payment Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_transactions ? page_data.total.total_transactions : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : '-' }}</th>
                                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th> -->
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_amount ? $h.myFloat(page_data.total.total_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_paid_amount ? $h.myFloat(page_data.total.total_paid_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_pending_amount ? $h.myFloat(page_data.total.total_pending_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_settlement_amount ? $h.myFloat(page_data.total.total_settlement_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr v-for="(item,index) in page_data.report_items" :key="index" class="border">
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ index + 1 }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ (item.party_first_name + ' ' + item.party_last_name) }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.branch_name ? item.branch_name : '-' }}</td>
                                        <th class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1 cursor-pointer" @click="get_transaction_details(item.id)">{{ item.transaction_code ? item.transaction_code : '-' }}</th>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.stock_type ? item.stock_type : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.stone_type ? item.stone_type : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.total_pcs ? item.total_pcs : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.total_wt ? item.total_wt : '-' }}</td>
                                        <!-- <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.broker_first_name ? (item.broker_first_name + ' ' + item.broker_last_name) : "-" }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.brokerage_amount ? item.brokerage_amount : '-' }}</td> -->
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.payment_terms ? item.payment_terms : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.sell_date ? item.sell_date : '-' }}</td>
                                        <th class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1" :class="{'text-red' : item.color_status == 'red', 'text-green' : item.color_status == 'green'}">{{ item.over_due_days ? item.over_due_days : '-' }}</th>
                                        <th class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1" :class="{'text-red' : item.color_status == 'red', 'text-green' : item.color_status == 'green'}">{{ item.due_date ? item.due_date : '-' }}</th>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.payment_date ? item.payment_date : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.issue_date ? item.issue_date : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.total_amount ? item.total_amount : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.paid_amount ? item.paid_amount : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.pending_amount ? item.pending_amount : '-' }}</td>
                                        <td class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1">{{ item.satelment_amount ? item.satelment_amount : '-' }}</td>
                                        <th class="py-2 text-xs lg:text-md text-center border-r whitespace-nowrap p-1" :class="{'text-red' : item.payment_status == null, 'text-green' : item.payment_status == 1}">{{ item.payment_status ? 'Paid' : 'Pending' }}</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_transactions ? page_data.total.total_transactions : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : '-' }}</th>
                                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th> -->
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_amount ? $h.myFloat(page_data.total.total_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_paid_amount ? $h.myFloat(page_data.total.total_paid_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_pending_amount ? $h.myFloat(page_data.total.total_pending_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ page_data.total.total_settlement_amount ? $h.myFloat(page_data.total.total_settlement_amount) : '-' }}</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
  </div>
  </template>

  <script>
  import { defineComponent, reactive, onMounted } from 'vue'
  import { BASE_URL } from '@/constants/config.js'
  import moment from 'moment';

  export default defineComponent({
      setup() {

          const page_data = reactive({

              trans_items: [],
              branch_list: [],
              party_list: [],
              report_items: [],
              total: [],
              generate: "no",
              loading: false,
              server_record: []
          })

          const filters = reactive({
            transaction_no: "",
            party_id: "0",
            stock_type: "0",
            branch_id: [],
            payment_status: "0",
            date_type: "1",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            due_days: ""
          })

          const get_transaction_details = (id) => {
            // router.push('/app/app-final-polish/sell-transfer/' + window.btoa(id))
          }

          const initTabulator = () => {

            const params = {
              transaction_no: (filters.transaction_no != "" ? window.btoa(filters.transaction_no) : ""),
              party_id: (filters.party_id != "0" ? window.btoa(filters.party_id) : ""),
              stock_type: (filters.stock_type != "0" ? window.btoa(filters.stock_type) : ""),
              branch_id: (filters.branch_id),
              payment_status: (filters.payment_status != "0" ? window.btoa(filters.payment_status) : ""),
              date_type: (filters.date_type != "" ? window.btoa(filters.date_type) : ""),
              s_date: (filters.s_date != "" ? window.btoa(filters.s_date) : ""),
              e_date: (filters.e_date != "" ? window.btoa(filters.e_date) : ""),
              due_days: (filters.due_days != "" ? window.btoa(filters.due_days) : ""),
            };

            page_data.loading = true;
            let promise = axios({
                  url: "/api/get_selling_report_details",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                    page_data.loading = false;
                    page_data.report_items = data.items;
                    page_data.total = data.total;

                  })
                  .catch(_error => {
                    page_data.loading = false;
                    page_data.report_items = [];
                    page_data.total = data.total;
                  });

          }

          const onResetFilter = () => {
              filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
              filters.e_date = moment().format('YYYY-MM-DD')
              filters.transaction_no = ""
              filters.party_id = "0"
              filters.stock_type = "0"
              filters.branch_id = []
              filters.payment_status = "0"
              filters.date_type = "1"
              filters.due_days = ""
          }

          const getPartyList = () => {

              let promise = axios({
                  url: "/api/dp_account_selling_party",
                  method: "POST",
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.party_list = data.items;

                  })
                  .catch(_error => {
                      page_data.party_list = [];
                  });
          }

          const getBranchList = () => {

              let promise = axios({
                  url: "/api/dp_account_branch_office",
                  method: "POST",
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.branch_list = data.items;

                  })
                  .catch(_error => {
                      page_data.branch_list = [];
                  });
          }

          //Edit user

          onMounted(() => {
            getPartyList();
            getBranchList();
            initTabulator()
          })

          return {
              filters,
              page_data,
              onResetFilter,
              initTabulator,
              getPartyList,
              get_transaction_details
          }
      }
  })
  </script>
