<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Certy Reset Request</h2>
      </div>
      <div class="intro-y box p-5 mt-5">

          <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
              <div class="preview">
                  <div class="overflow-x-auto">
                      <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                          <LoadingIcon icon="bars" class="w-8 h-8" />
                          <div class="text-center text-xs mt-2">Getting Records</div>
                      </div>
                  </div>
              </div>
          </div>

          <div v-else-if="pagedata.generate == 'gen'">
              <div class="p-5">
                  <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
                      <div class="col-span-12 sm:col-span-6">

                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Request Code. :&nbsp;</label>
                              <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.request_code ? pagedata.report_items.request_code : '-' }}</label>
                          </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Branch :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.branch_name ? pagedata.report_items.branch_name : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Manager :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.first_name ? pagedata.report_items.first_name + pagedata.report_items.last_name : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Request Date :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.issue_date ? pagedata.report_items.issue_date : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.total_pcs }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.total_ct }} Ct</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Status :&nbsp;</label>
                              <label for="update-profile-form-6" class="form-label text-theme-1" v-if="pagedata.report_items.status == 1">
                                On Hold
                              </label>
                              <label for="update-profile-form-6" class="form-label text-theme-9" v-if="pagedata.report_items.status == 2">
                                Accepted
                              </label>
                              <label for="update-profile-form-6" class="form-label text-theme-6" v-if="pagedata.report_items.status == 3">
                                Cancelled
                              </label>
                          </div>

                      </div>
                  </div>

              </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
      <!-- BEGIN: Input -->
      <div class="intro-y box p-5 mt-5" v-if="pagedata.generate == 'gen'">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Reset Stock Details</h2>

                </div>

                <div class="py-5 px-3">
                                <div class="border mt-2 p-0 shadow">

                                    <div class="overflow-x-auto tableFixHead">
                                        <table class="w-full text-center text-xs">
                                            <thead>
                                                <tr class="bg-theme-14">
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Sr No.</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Stock No</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Packet No</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Weight</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Cr Weight</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">LAB</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Color</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Purity</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Shape</th>
                                                    <th class="border-b py-2 px-2 dark:border-dark-5">Stone</th>
                                                </tr>
                                                <tr class="bg-gray-200">
                                                    <td class="border-b py-2 px-2">Total</td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2">{{ pagedata.report_items.total_pcs }}</td>
                                                    <td class="border-b py-2 px-2">{{ $h.myFloat(pagedata.report_items.total_ct) }}</td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                    <td class="border-b py-2 px-2"></td>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in pagedata.report_items.certy_details" :key="index">
                                                    <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.certy_no }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.pack_no }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.weight }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.cr_weight }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.lab }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.color }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.purity }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.shape }}</td>
                                                    <td class="border-b py-2 px-2">{{ field.stone_type == 1 ? "NATURAL" : (field.stone_type == 2 ? "CVD" : (field.stone_type == 3 ? "HPHT" : (field.stone_type == 4 ? "D_CVD" : (field.stone_type == 5 ? "D_HPHT" : "")))) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
            </div>
            <!-- END: Input -->

  </div>
  </template>


  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import moment from 'moment';
  import {
      helper as $h
  } from '@/utils/helper';
  import {
      useRoute,
      useRouter
  } from 'vue-router';

  export default defineComponent({
      setup() {

          const swal = inject('$swal');
          const route = useRoute()
          const router = useRouter()
          const pagedata = reactive({

              report_items: [],
              size_purity_arr: [],
              generate: "no",
              loading: false,
          })

          const editDetails = () => {
              router.push('/app/app-polish-ready/ready/merge-lot-new/' + route.params.id)
          }

          const generateReport = () => {
              const params = {
                  id: route.params.request_id
              };


              pagedata.loading = true

              let promise = axios({
                  url: "api/get_request_transaction_single",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "YES") {
                          swal("Warning!", data.message, "warning");
                          pagedata.generate = 'no';
                          pagedata.loading = false

                          return;
                      }

                      pagedata.report_items = data.item;

                      pagedata.generate = 'gen';
                      pagedata.loading = false

                  })
                  .catch(_error => {

                      pagedata.generate = 'no';
                      pagedata.loading = false
                  });
          }

          function printReportDetails() {
              // let report_type = 'final-polish-stock-compatison'

              // let params = {
              //     report_type: report_type,
              //     filters: [],
              //     items: pagedata.report_items
              // }
              // let promise = axios({
              //     url: "api/add_report_details",
              //     method: "post",
              //     baseURL: BASE_URL,
              //     data: params,
              //     headers: {
              //         Authorization: "Bearer " + localStorage.getItem("token")
              //     },
              //     config: {
              //         headers: {
              //             Accept: "application/json"
              //         }
              //     }
              // });
              // return promise
              //     .then(result => result.data)
              //     .then(data => {

              //         const report_id = data.report_id

              //         let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              //         window.open(url, '_blank');

              //     })
              //     .catch(_error => {

              //     });
              // add_report_details
          }

          onMounted(() => {
              generateReport()
          })

          return {
              pagedata,
              printReportDetails,
              editDetails
          }
      }
  })
  </script>
