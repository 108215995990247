<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Receive Lot Recut</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-8" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="scan_lot_no"
                    :class="{'form-control':true,'loading':scanload}"
                    :disabled = "scanload"
                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')" style="border-radius: 0!important">
                    Clear
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5" v-if="showreturndetails">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Packet No :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.packet_no}}</label
                  >
                </div>
                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Current Process :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.process_name}}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Lot weight :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.issue_weight}} Ct</label
                  >
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue To :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{  return_item.issue_to_name}}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue At :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.issue_date}}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4">

                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Return Weight :&nbsp;</label
                  >
                 <input
                    id="update-profile-form-6"
                    type="text"
                    placeholder="Weight in Ct"
                    v-model="return_item.return_weight"
                    :class="{'form-control':true,'loading':scanload}"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    @change="calculateGhat"
                  />

                </div>

                 <div class="mt-2">

                  <label for="update-profile-form-6" class="form-label"
                    >Process Ghat :&nbsp;</label
                  >
                 <input
                    id="update-profile-form-6"
                    type="text"
                    placeholder="Weight in Ct"
                    v-model="return_item.total_ghat"
                    :class="{'form-control':true}"
                    readonly
                  />
                </div>
              </div>
              <div class="col-span-6 xl:col-span-4">

              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->

      </div>

    </div>
     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Packet Last Plan Details</h2>
             <div class="w-full sm:w-auto flex mt-4 sm:mt-0" v-if="print_barcoads.length > 0">
          <!-- <button
          type="button"
          class="btn btn-primary shadow-md mr-2"
          @click="printAllSubPackage"
          >Print All</button> -->
          <!-- <button
          type="button"
          class="btn btn-primary shadow-md mr-2"
          v-print = "'#new-packets-barcoads'"
          >Print All</button> -->
          <button
          type="button"
          class="btn btn-primary shadow-md mr-2"
          v-print = "'#new-packets-barcoads'" id="print-all-barcode" @click="clickedPrintBtn"
          >Print All</button>
      </div>
          </div>

          <div class="p-5">
            <div class="col-span-12">
              <div class="border">
                <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" >
                    <p>Sr. No</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" >
                    <p>Part No</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2" >
                    <p>Shape</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1" >
                    <p>Color</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                    <p>Purity</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                    <p>MW</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2" >
                    <p>PW</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                    <p>New Part Weight</p>
                  </div>
                </div>

              </div>

              <div class="border" v-for="(field,index) in lotPlans" :key="index">
                <div class="grid grid-cols-12">
                  <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                    <label class="p-2 mt-2">{{field.sr_no}}</label>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 p-2">
                      <p>{{field.part_no}} <span v-if="field.main_piece != null" class="text-theme-9">- C</span></p>

                  </div>

                  <div class="col-span-6 md:col-span-2  xl:col-span-2 p-2">
                    <p>{{field.shape}}</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                    <p>{{field.color}}</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                    <p>{{field.purity}}</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                    <p>{{field.rough_weight}}</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                    <p>{{field.polish_weight}}</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    <input v-model="field.new_packet_weight" type="text" :id="'input_focus_' + (index + 1)"
                      class="form-control" placeholder="Value" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateTotalWeight(index)">
                  </div>


                </div>
              </div>
              <div class="border" v-if="enable_return">
                  <div class="grid grid-cols-12">
                  <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                    <label class="p-2 mt-2"></label>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 p-2">
                      <p></p>

                    </div>

                    <div class="col-span-6 md:col-span-2  xl:col-span-2 p-2">
                      <p></p>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                      <p></p>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                      <p></p>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                      <p></p>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 font-bold">
                      <p>Extra Top</p>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2">
                      <input v-model="return_item.total_assortment_weight" type="text"
                          class="form-control" placeholder="Value" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateWeightTotal">
                    </div>


                  </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary mt-3" v-if="enable_return" @click="submitReturnRough">
                Take Return
              </button>
            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <div  class="hidden" v-if="print_barcoads.length > 0">
        <div id="new-packets-barcoads"  class="absolute top-0 p-0 m-0">
          <table style="page-break-before: always;" class="p-0" border="collapse" v-for="(packet,index) in print_barcoads" :key="index">
            <tr class="text-center">
              <th colspan="3" class="text-center">
                <vue-barcode class="w-full"
                :value="packet.barcode_no"
                :options="{ displayValue: true,height:30,width:2 }"

              />
                </th>
            </tr>
            <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
              <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{ packet.packet_code }}</td>
            <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
              <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
            </tr>
          </table>
          </div>
      </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL,BASE_URL_PRINT } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'
// import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime,
    VueBarcode
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      submit_url :'',
      tender_update_id : '',
      tabulatorHistory:[],
      tabulator:[],
      assorts:[],
      transaction_no: 'Auto',
      scanload:false,
      enable_return:false,
      lotPlans:[],
      scan_lot_no:'',
      status: '1',
      show_print_label : 1,
      print_barcoads: [],
      set_edit:1,
      return_item:
      {
        packet_no:'',
        packet_id:'',
        process_id:'',
        trans_id:'',
        trans_type:'',
        sub_trans_id:'',
        last_packet_history_id:'',
        issue_weight:0,
        issue_date:'',
        process_name:'',
        issue_to_name:'',
        return_weight:'',
        total_ghat:0,
        total_assortment_weight:0,

      },
      totalassortments : 1,

      assortData:[],
      return_progress:false,
      showreturndetails : false,
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {

  },
  mounted(){
    //this.assortPrepare();
    document.querySelector('[id="scan_lot_no"]').focus();
  },

  methods: {
    calculateGhat(){
      let _this = this;
      let total_assortment_weight_return = _this.$h.myFloat(_this.$h.myFloat(this.return_item.return_weight) + _this.$h.myFloat(this.return_item.total_assortment_weight));
      this.return_item.total_ghat = _this.$h.myFloat(this.return_item.issue_weight - total_assortment_weight_return);
    },
    printAllSubPackage(){
      let url = BASE_URL_PRINT + '/print-all-sub-label/' + this.return_item.packet_id;
      window.open(url, '_blank');

    },
    calculateTotalWeight(index){
      let _this = this;
      this.return_item.return_weight = 0;
      let total_pack_weight = 0;

        var i = this.lotPlans.length;
        while(i--){
          if(this.lotPlans[i]['new_packet_weight'] != ''){
              total_pack_weight += parseFloat(this.lotPlans[i]['new_packet_weight'])*100/100;
          }
        }
        if(!isNaN(total_pack_weight)){
          this.return_item.return_weight = this.$h.myFloat(total_pack_weight);
        }else{
          this.return_item.return_weight = 0;
        }
        _this.calculateGhat();

        if(this.return_item.return_weight > this.return_item.issue_weight){
          this.$swal("Warning!", "Sub Packet Weight is more than issue weight", "warning");

          var i = this.lotPlans.length;
          // while(i--){
            this.return_item.return_weight = this.$h.myFloat(this.return_item.return_weight) - this.$h.myFloat(this.lotPlans[index]['new_packet_weight']);
            this.lotPlans[index]['new_packet_weight'] = '';
          // }
          // this.return_item.return_weight = 0
          this.return_item.total_ghat = 0
          setTimeout(() => {
            _this.calculateGhat();
          },500)
        }
        if((parseFloat(this.return_item.return_weight) + parseFloat(this.return_item.total_assortment_weight)) > this.return_item.issue_weight){
          this.$swal("Warning!", "Assortment Weight is more than issue weight", "warning");

          this.return_item.total_assortment_weight = 0
          this.return_item.total_ghat = 0
          _this.calculateGhat();
        }
    },
    calculateWeightTotal(){
      let _this = this;

        if((parseFloat(this.return_item.return_weight) + parseFloat(this.return_item.total_assortment_weight)) > this.return_item.issue_weight){
          this.$swal("Warning!", "Assortment Weight is more than issue weight", "warning");

          this.return_item.total_assortment_weight = 0
          this.return_item.total_ghat = 0
        }
          _this.calculateGhat();
    },

    async scanAndAddPack(){
      let _this = this;
      _this.clearSearch(this.scan_lot_no);
      let codelength = this.scan_lot_no.trim().length;
      if(codelength > 11){
        this.scanload = true;

        //get lot details
        const data = {
        barcode: this.scan_lot_no,
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_scanned_packet_details_for_return_recut"
        }else{
          url_post = "api/get_scanned_packet_details_for_return_recut"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          //this.clearSearch()
          console.log(data);
          if(data.packet_details != 'No'){
            this.showreturndetails = true;
          let last_history_details = data.last_issue;
          let packet_details = data.packet_details;
          if(last_history_details.process_id != null){
            if(last_history_details.process_id == 6){
              this.return_item.packet_no = packet_details.packet_code;
                this.return_item.packet_id = last_history_details.packet_id;
                this.return_item.process_id = last_history_details.process_id;
                this.return_item.trans_id = last_history_details.transaction_id;
                this.return_item.trans_type = last_history_details.transaction_type;
                this.return_item.sub_trans_id = last_history_details.sub_trans_id;
                this.return_item.last_packet_history_id = last_history_details.id;
                this.return_item.issue_weight = _this.$h.myFloat(last_history_details.issue_weight);
                this.return_item.issue_date = last_history_details.created_at;
                this.return_item.process_name = last_history_details.process_name;

                if((last_history_details.transaction_id == null || last_history_details.transaction_id == '') && ( last_history_details.process_id == null)){
                this.return_item.issue_to_name =  last_history_details.manager_first_name;
                }else if(last_history_details.process_id != '' && last_history_details.process_id != null){
                  if( last_history_details.process_id == 29 || last_history_details.process_id == 1  || last_history_details.process_id == 28 || last_history_details.process_id == 3){
                    this.return_item.issue_to_name = last_history_details.karigar_manager_first_name;
                  }if( last_history_details.process_id == 18 || last_history_details.process_id == 19  || last_history_details.process_id == 20){
                    this.return_item.issue_to_name = last_history_details.planner_first_name;
                    this.return_item.return_weight = _this.$h.myFloat(last_history_details.issue_weight);
                  }else{
                    this.return_item.issue_to_name =  last_history_details.karigar_first_name;
                  }
                }else{
                  this.return_item.issue_to_name =  last_history_details.manager_first_name;
                }

                if(last_history_details.status == 2){
                  this.set_edit = 2;
                  this.return_item.return_weight = last_history_details.received_weight;
                  this.return_item.total_assortment_weight = last_history_details.total_assortment;
                  _this.calculateGhat();
                }
                this.enable_return = true;
                this.showreturndetails = true;
                if(packet_details.part_ready == 2){
                  this.show_print_label = 2;
                  _this.getPacketList()
                } else {
                  this.print_barcoads = []
                }
                _this.getLastPlannerPlan();

            }
            else{

              this.return_item.packet_no = '';
              this.return_item.packet_id = '';
              this.return_item.process_id = '';
              this.return_item.sub_trans_id = '';
              this.return_item.last_packet_history_id ='';
              this.return_item.issue_weight = '';
              this.return_item.issue_date = '';
              this.return_item.process_name = '';
              this.return_item.issue_to_name = '';
              this.enable_return = false;
              this.showreturndetails = false;
              this.$swal("Warning!", "Can not take return here. Assigned Process is not Recut!", "warning");
              _this.getLastPlannerPlan();
              this.scanload = false;
            }

          }else if(packet_details.part_ready == 2){
              this.lotPlans = [];
              this.return_item.packet_id = packet_details.id;
              this.show_print_label = 2;
              _this.getPacketList()
              this.showreturndetails = false;
              this.enable_return = false;
              this.return_item.packet_no = '';
              this.return_item.process_id = '';
              this.return_item.sub_trans_id = '';
              this.return_item.last_packet_history_id ='';
              this.return_item.issue_weight = '';
              this.return_item.issue_date = '';
              this.return_item.process_name = '';
              this.return_item.issue_to_name = '';
              this.enable_return = false;
              this.showreturndetails = false;
              this.scanload = false;
            }else{
            this.return_item.packet_no = '';
            this.return_item.packet_id = '';
            this.return_item.process_id = '';
            this.return_item.sub_trans_id = '';
            this.return_item.last_packet_history_id ='';
            this.return_item.issue_weight = '';
            this.return_item.issue_date = '';
            this.return_item.process_name = '';
            this.return_item.issue_to_name = '';
            this.enable_return = false;
            this.showreturndetails = false;
            this.scanload = false;
            this.print_barcoads = []
          }

          }else{

            _this.clearSearch('');
            this.$swal("Warning!", "Packet Details Not Found!", "warning");

          }

          this.scanload = false;

          })
          .catch((_error) => {
            this.scanload = false;
            return [];
          });

      }
    },
    clearSearch(lot_no){
      this.scan_lot_no = lot_no;
      this.lotPlans = [];
      this.set_edit = 1;
      this.return_item.packet_no = '';
      this.return_item.packet_id = '';
      this.show_print_label = 1;
      this.return_item.process_id = '';
      this.return_item.trans_type = '';
      this.return_item.trans_id = '';
      this.return_item.sub_trans_id = '';
      this.return_item.last_packet_history_id ='';
      this.return_item.issue_weight = '';
      this.return_item.issue_date = '';
      this.return_item.process_name = '';
      this.return_item.issue_to_name = '';
      this.return_item.return_weight = '',
      this.return_item.total_assortment_weight = 0;
      this.return_item.total_ghat = 0;
      this.return_item.total_ghat = 0;
      this.return_item.total_assortment_weight = 0;
      this.enable_return = false;
      this.showreturndetails = false;
      this.scanload = false;
      this.print_barcoads = [];
    },

    async getLastPlannerPlan(){
      let packet_id = this.return_item.packet_id;
      if(packet_id != ''){
        //get lot details
        const data = {
        packet_id: this.return_item.packet_id,
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_scanned_packet_plan_for_recut"
        }else{
          url_post = "api/get_scanned_packet_plan_for_recut"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          this.lotPlans = [];
          this.showreturndetails = true;
          let plans = data.latest_plan;
          console.log(plans);
          if(plans != null && plans != ''){

            let total_plans = plans.length;
            let i = 0;
            let alpha = 'B';
            for(i = 0;i<total_plans;i++){
              if(plans[i]['sub_packet_status'] == 2 && plans[i]['sub_packet_id'] != null){
                if(plans[i]['main_piece'] == 1){
                this.lotPlans.unshift({ sr_no:"-",id:plans[i]['id'],part_no:plans[i]['part_no'],color: plans[i]['color'],shape: plans[i]['shape'],rough_weight: plans[i]['rough_weight'],
                polish_weight: plans[i]['polish_weight'],new_packet_weight: plans[i]['mk_weight'],
                barcode: '',packet_id: plans[i]['rough_packet'],purity: plans[i]['clarity'],
                main_piece:plans[i]['main_piece'],
                sub_packet_id : plans[i]['sub_packet_id']
                });

                }else{
                  console.log(plans[i]['id']);
                  this.lotPlans.push({ sr_no: alpha,id:plans[i]['id'],part_no:plans[i]['part_no'],color: plans[i]['color'],shape: plans[i]['shape'],rough_weight: plans[i]['rough_weight'],
                  polish_weight: plans[i]['polish_weight'],new_packet_weight: plans[i]['mk_weight'],
                  barcode: '',packet_id: plans[i]['rough_packet'],purity: plans[i]['clarity'],
                  main_piece:plans[i]['main_piece'],
                  sub_packet_id : plans[i]['sub_packet_id']
                  });
                  alpha = String.fromCharCode(alpha.charCodeAt() + 1);

                }

              }else{
                if(plans[i]['main_piece'] == 1){
                this.lotPlans.unshift({ sr_no:"-",id:plans[i]['id'],part_no:plans[i]['part_no'],color: plans[i]['color'],shape: plans[i]['shape'],rough_weight: plans[i]['rough_weight'],
                polish_weight: plans[i]['polish_weight'],new_packet_weight: '',
                barcode: '',packet_id: plans[i]['rough_packet'],purity: plans[i]['clarity'],
                main_piece:plans[i]['main_piece'],
                sub_packet_id :''
                });

              }else{
                console.log(plans[i]['id']);
                this.lotPlans.push({ sr_no: alpha,id:plans[i]['id'],part_no:plans[i]['part_no'],color: plans[i]['color'],shape: plans[i]['shape'],rough_weight: plans[i]['rough_weight'],
                polish_weight: plans[i]['polish_weight'],new_packet_weight: '',
                barcode: '',packet_id: plans[i]['rough_packet'],purity: plans[i]['clarity'],
                main_piece:plans[i]['main_piece'],
                sub_packet_id :''});
                alpha = String.fromCharCode(alpha.charCodeAt() + 1);

              }

              setTimeout(() => {
              document.querySelector('[id="input_focus_1"]').focus();
              }, 70);

              }
            }
            console.log(this.lotPlans);
          }else{
            this.lotPlans = [];
          }
          this.scanload = false;

          })
          .catch((_error) => {
            this.scanload = false;
            return [];
          });

      }

    },

    async submitReturnRough(){
      let _this = this;
      const data = {
        packet_details: this.return_item,
        assortment_details: this.assortData,
        new_part: this.lotPlans,
      };

      if( _this.$h.myFloat(this.return_item.return_weight) > _this.$h.myFloat(this.return_item.issue_weight)){
        this.$swal("Warning!", "Can not return more weight than issue rough weight", "warning");
        return;
      }
       if(_this.$h.myFloat(this.return_item.return_weight) == 0){
        this.$swal("Warning!", "Can not return zero weight!", "warning");
        return;
      }

      let total_assortment_weight_return = _this.$h.myFloat(_this.$h.myFloat(this.return_item.return_weight) + _this.$h.myFloat(this.return_item.total_assortment_weight));

      this.return_item.total_ghat = _this.$h.myFloat(this.return_item.issue_weight - total_assortment_weight_return);
      console.log("Ghat:" + this.return_item.total_ghat);
      if( total_assortment_weight_return > _this.$h.myFloat(this.return_item.issue_weight)){
        this.$swal("Warning!", "Total return weight and assortment weight is more than original issue weight!", "warning");
        return;
      }

      var i = this.lotPlans.length;
        while(i--){
          if(this.lotPlans[i]['new_packet_weight'] == ''){
            this.$swal("Warning!", "Sub Packet Weight is Empty!", "warning");
            return;

          }
        }
      //this.return_progress = true;

      if (this.set_edit == 2) {
          this.submit_url = "api/return_transaction_packet_single_recut_edit";
      } else {
          this.submit_url = "api/return_transaction_packet_single_recut";
      }

      console.log(data);
      console.log(this.submit_url);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: this.submit_url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                this.$swal.fire({
                  icon:'success',
                  title:"Success!",
                  text:"Item Returned to Manager.",
                  showConfirmButton: false,
                  timer:1500
                })
                // this.$swal("Success!", "Item Returned to Manager.", "success");
                //this.$router.go();
              }else if(data.status == "error"){
                this.$swal("Error!", data.message , "error");
              }
              this.print_barcoads = []
              setTimeout(() => {
                this.print_barcoads = data.packets
              }, 200);
              _this.scanAndAddPack();
              if(this.print_barcoads.length > 0) {

                setTimeout(() => {
                  document.querySelector(['[id="print-all-barcode"]']).focus()
                }, 500);
              }
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          //this.$swal("Success!", "Item Returned to Manager.", "success");
          //this.$router.go();
        }
      });
    },
    clickedPrintBtn() {

      setTimeout(() => {
        this.scan_lot_no = ''
        document.querySelector('[id="scan_lot_no"]').focus();
      }, 500);

    },
    async getPacketList(){
      let packet_id = this.return_item.packet_id;
      if(packet_id != ''){

        //get lot details
        const data = {
        packet_id: window.btoa(this.return_item.packet_id),
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_recut_sub_packet"
        }else{
          url_post = "api/get_recut_sub_packet"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          let plans = data.items;
          if(plans != null && plans != ''){
            this.print_barcoads = []
            // },
            setTimeout(() => {
              this.print_barcoads = data.items

              setTimeout(() => {
                document.querySelector('[id="print-all-barcode"]').focus();
              }, 100);
            },100)
          }else{
            plans = [];
          }
          // if(this.print_barcoads.length > 0) {

            // setTimeout(() => {
            //     document.querySelector(['[id="print-all-barcode"]']).focus()
            //   }, 500);
          // }
          })

      }

    },

    totalPackweight(){
      let total_weight = 0;
      let _this = this;
      var i = this.assortData.length;
        while(i--){
          if(this.assortData[i]['weight'] != ''){
              total_weight += _this.$h.myFloat(this.assortData[i]['weight']);
          }
        }
        if(!isNaN(total_weight)){
          this.return_item.total_assortment_weight = total_weight;
        }
    },
  },

})
</script>
