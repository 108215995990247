<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Polish Party Account</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      <!-- <button
          v-if="vendor_id != ''"
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="resetUser">
           <KeyIcon class="w-4 h-4 mr-1" />
           Reset & Resend Password
      </button> -->
      <button
          v-if="vendor_id != '' && status == 1"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-danger ' : status == 1}" @click="changeStatus">
           <LockIcon class="w-4 h-4 mr-1" /> Deactivate
      </button>
      <button
          v-if="vendor_id != '' && status == 0"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-success ' : status == 0}" @click="changeStatus">
           <UnlockIcon class="w-4 h-4 mr-1" /> Activate
      </button>
      <button
          v-if="vendor_id != ''"
          type="button"
           class="btn btn-danger shadow-md mr-2" @click="deleteUser">
           <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
      </button>

      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Party Details</h2>
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >First Name<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="First name"

                    v-model="first_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Middle Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Middle name"

                    v-model="middle_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Last Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Last name"

                    v-model="last_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Personal Email</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"

                    v-model="contact_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Mobile No</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="+00 00000 00000"

                    v-model="contact_mobile_no"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Address</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="company_address"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >City</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="company_city"
                  />
                </div>



              </div>
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-9" class="form-label"
                    >Company(Org.) Name<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""

                    v-model="company_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-11" class="form-label"
                    >Company Email<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-11"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    :disabled ="vendor_id != ''"
                    v-model="company_email"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Phone No<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    :disabled ="vendor_id != ''"
                    v-model="company_contact_no"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >WhatsApp No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="whatsapp_no"
                  />
                </div>


                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >GST No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""

                    v-model="gst_no"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >PAN No</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""

                    v-model="pan_no"
                  />
                </div>

              </div>


            </div>

              <div class="grid grid-cols-12 gap-x-5 mt-5">
              <div class="col-span-12">
                <h4 class="font-medium text-base mr-auto">Price Details</h4>
               <div class="border">
               <div class="grid grid-cols-12 sticky top-0 z-20 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">

                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Sr. No</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Stone</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>P Type</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Size Name</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>From</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>To</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Type</p>
                </div>

                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Majuri(INR)</p>
                </div>

               </div>

               <div class="border" v-for="majuri_item in polish_rate" :key="majuri_item.id">

                <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-2">{{majuri_item.id}}</label>
               </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <select class="form-control" v-model="majuri_item.stone_type">
                  <option value="1">Natural</option>
                  <option value="2">CVD</option>
                </select>

              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <select class="form-control" v-model="majuri_item.polish_type">
                  <option value="1">Manual</option>
                  <option value="2">Auto</option>
                </select>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="majuri_item.name" type="text"

                    class="form-control" placeholder="Name" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="majuri_item.from" type="text"

                    class="form-control" placeholder="From" @keypress="$h.preventInvalidInput" onpaste="return false;">

              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="majuri_item.to" type="text"
                    class="form-control" placeholder="To" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <select class="form-control" v-model="majuri_item.type">
                  <option value="1">By Size (per/Ct)</option>
                  <option value="2">Per Piece</option>
                </select>

              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                 <input v-model="majuri_item.majuri" type="text"

                    class="form-control" placeholder="Majuri" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>

              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3">
              <button type="button" class="text-theme-6 flex items-center" >
                <Trash2Icon class="w-4 h-4 mr-1" @click="removeField(majuri_item.id)"/> Delete
              </button>
              </div>
                </div>
            </div>
          </div>

          <button type="button" @click="AddField" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
            New item
          </button>
         </div>

            </div>

            <div class="grid grid-cols-12 gap-x-5 mt-5">
              <div class="col-span-12">
                <h4 class="font-medium text-base mr-auto">Late Days Details</h4>
               <div class="border">
               <div class="grid grid-cols-12 sticky top-0 z-20 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">

                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Sr. No</p>
                </div>

                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Process</p>
                </div>

                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>First Late(N)</p>
                </div>

                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Second Late(N)</p>
                </div>

                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>First Late(CVD)</p>
                </div>

                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Second Late(CVD)</p>
                </div>
               </div>

               <div class="border" v-for="late_item in process_late" :key="late_item.id">

                <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-2">{{late_item.id}}</label>
               </div>

              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <select class="form-control" v-model="late_item.process">
                  <option value="32" selected>Polish Out</option>
                </select>

              </div>

               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="late_item.fl_days_n" type="text"
                    class="form-control" placeholder="Days" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="late_item.sl_days_n" type="text"
                    class="form-control" placeholder="Days" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="late_item.fl_days_cvd" type="text"
                    class="form-control" placeholder="Days" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="late_item.sl_days_cvd" type="text"
                    class="form-control" placeholder="Days" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>

              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3">
              <button type="button" class="text-theme-6 flex items-center" >
                <Trash2Icon class="w-4 h-4 mr-1" @click="removeFieldLate(late_item.id)"/> Delete
              </button>
              </div>
                </div>
            </div>
          </div>

          <button type="button" @click="AddFieldLate" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
            New item
          </button>
              </div>

            </div>

            <div class="flex justify-end mt-4">
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>

            </div>

        </div>
         </form>
        </div>
         <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Created Successfuly!</div>
            <div class="text-gray-600 mt-1">
                User added to user list.
            </div>
        </div>
         </div>
         <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Updated Successfuly!</div>
        </div>
         </div>
        <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>

         <div id="failed-user-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Creation Error!</div>
            <div class="text-gray-600 mt-1">
                Something went wrong. Kindly Refreash the page and try again.
            </div>
        </div>
         </div>
    </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'


export default defineComponent({
  components: {
    DarkModeSwitcher,

  },
  props: {
    user_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      vendor_id : '',
      total_items : 1,
      total_items_late: 1,
      first_name:"",
      last_name:"",
      middle_name:"",
      contact_email:"",
      contact_mobile_no:"",
      whatsapp_no:"",
      company_city:'',
      company_address:'',
      company_name:"",
      company_email:"",
      company_contact_no:"",
      broker_name:"",
      broker_mobile_no:"",
      ref_1_name:"",
      ref_2_name:"",
      ref_1_contact:"",
      ref_2_contact:"",
      ref_1_email:"",
      ref_2_email:"",
      broker_email:"",
      gst_no:"",
      pan_no:"",
      status: '',
      email: '',
      password: '',
      loginVal: '',
      process:[],
      process_ar : [],
      polish_rate:[{ id:1 ,from: '',to: '',name: '',type: '1',polish_type: '1', stone_type : '1',majuri: ''}],
      process_late:[{ id:1 ,process:32, fl_days_n:'',sl_days_n:'',fl_days_cvd: '',sl_days_cvd: ''}],
      url:'',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
    mounted(){
      if(this.user_id != ''){
        this.vendor_id = this.user_id;
      this.getuserDetails(this.user_id);
      }

  },
  computed: {

  },

  methods: {

    formSubmit() {
      this.status = 'processing'
      console.log("HI");

    },
    AddField() {
      this.total_items++;
      this.polish_rate.push({ id:this.total_items,from: '',to: '',name: '',type: '1', polish_type: '1',stone_type: '1',majuri: '' });

    },
     AddFieldLate() {
      this.total_items_late++;
      this.process_late.push({ id:this.total_items_late, process:32, fl_days_n:'',sl_days_n:'',fl_days_cvd: '',sl_days_cvd: '',process:''});
    },
    removeField(id) {

       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.polish_rate.length;
              while(i--){
              if(this.polish_rate[i]['id'] == id ){
                  this.polish_rate.splice(i,1);
              }
            }
          }
      });
    },
     removeFieldLate(id) {
       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.process_late.length;
              while(i--){
              if(this.process_late[i]['id'] == id ){
                  this.process_late.splice(i,1);
              }
            }
          }
      });
    },

    onUserFormSubmit() {
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      if (!this.first_name) {
        this.errors.push("First Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (!this.company_email) {
        this.errors.push("Company Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
      }
      if (!this.company_contact_no) {
        this.errors.push("Company Contact required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
      }

      if (!this.errors.length) {
        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name:this.middle_name,
          contact_email :this.contact_email,
          contact_mobile_no : this.contact_mobile_no,
          company_name:this.company_name,
          company_email:this.company_email,
          company_contact_no:this.company_contact_no,
          whatsapp_no:this.whatsapp_no,
          company_city:this.company_city,
          company_address:this.company_address,
          broker_name:this.broker_name,
          broker_mobile_no:this.broker_mobile_no,
          gst_no:this.gst_no,
          pan_no:this.pan_no,
          ref_1_name : this.ref_1_name,
          ref_2_name : this.ref_2_name,
          ref_1_contact : this.ref_1_contact,
          ref_2_contact : this.ref_2_contact,
          ref_1_email : this.ref_1_email,
          ref_2_email : this.ref_2_email,
          broker_email : this.broker_email,
          polish_rate:this.polish_rate,
          process_late:this.process_late,
          ud:this.user_id
        };
        if (this.user_id == "") {
          this.url = "api/add_account_polish_party";
        } else {
          this.url = "api/edit_account_polish_party";
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {

            if(this.user_id != ''){
        Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/polish-party-master");
        }, 500)
            }else{
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/polish-party-master");
        }, 500)
            }

          })
          .catch(response => {
            Toastify({
              node: cash('#failed-user-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          });
      }
    },
    getuserDetails(user_id) {
      let _this = this;
      const params = {
        ud: user_id
      };
      let promise = axios({
        url: "api/details_account_polish_party",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.user_details);
          let user_details = data.user_details;

          this.first_name = user_details.first_name;
          this.last_name = user_details.last_name;
          this.middle_name = user_details.middle_name;
          this.contact_email = user_details.contact_person_email;
          this.contact_mobile_no = user_details.contact_person_mobile;
          this.company_name = user_details.company_name;
          this.whatsapp_no = user_details.whatsapp_no;
          this.company_address = user_details.company_address;
          this.company_city = user_details.company_city;
          this.company_email = user_details.company_email;
          this.company_contact_no = user_details.company_contact_no;
          this.gst_no = user_details.gst_no;
          this.pan_no = user_details.pan_no;
          this.status = user_details.active_status;

          if(user_details.polish_rate != null){
              this.polish_rate = user_details.polish_rate;
              this.total_items = this.polish_rate.length;
          }
          if(user_details.late_days != null){
              this.process_late = user_details.late_days;
              this.total_items_late = this.process_late.length;
          }


        })
        .catch(_error => {

        });
    },
    deleteUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a User!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/delete_account_polish_party",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {

            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Deleted.", "success");
          this.$router.push("/app/accounts/polish-party-master");
        }
      });
    },
    resetUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to reset User Password!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reset",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/reset_vendor_user",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {

            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "New Password Reset & Resend to User.", "success");
        }
      });
    },
    changeStatus(){
      let _this = this;
      console.log(this.user_id);
      console.log(this.status);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to change user status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id,
            user_status : window.btoa(this.status)
          };
          let promise = axios({
            url: "api/change_status_account_polish_party",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {

            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Status Changed.", "success");
          //this.$router.go();
          _this.getuserDetails(this.user_id);
        }
      });
    }
  },

})
</script>

