<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Lot Issue Transactions</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'add-issue-packet' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           New Issue
          </router-link>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="transaction_code">Transaction No</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="flex mt-5 sm:mt-0">

        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent,computed, ref, reactive, onMounted,inject } from 'vue'
import xlsx from 'xlsx'
import { useStore } from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'transaction_code',
      type: 'like',
      value: ''
    })

    const swal = inject('$swal')
    const userDetails = computed(() => store.state.user.currentUser)

    const screenData = reactive({
      issuer: '',
    })

    const initTabulator = () => {
      let _this = this;
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_packet_issue_trans',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Transaction No',
            minWidth: 200,
            responsive: 0,
            field: 'transaction_code',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              router.push("/app/app-process/packet/issuetransaction/" + window.btoa(cell.getData().id));
            },
            formatter(cell) {
              if(cell.getData().issue_by == userDetails.value.id){
                if(cell.getData().issue_type == 3 ){
                  return `<i data-feather="arrow-up-circle" class="w-6 h-6 font-bold text-theme-4 mr-1"></i>`+cell.getData().transaction_code
                }else{
                  return `<i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().transaction_code
                }

              }else{
                return `<i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().transaction_code
              }
            }
          },
          {
            title: 'Piece / Weight',
            field: 'piece_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return  `<div class="text-md"> Pcs : `+cell.getData().total_packets +`<br>
                <p class="font-12 font-bold text-md">`+ Number(cell.getData().total_weight).toFixed(4) +` ct </p>
              </div>` ;
            }
          },
          {
            title: 'Manager/Worker/Party',
            field: 'karigar_first_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().issue_type == 1){
              if(cell.getData().process_id == 2 ||cell.getData().process_id == 29 || cell.getData().process_id == 3 || cell.getData().process_id == 26 || cell.getData().process_id == 27 || cell.getData().process_id == 28 || cell.getData().process_id == 31){
                    return  `<div class="">`+cell.getData().karigar_manager_first_name +`<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;
              }if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                    return  `<div class="">`+cell.getData().planner_first_name +` (`+ cell.getData().emp_code+`)<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;
              }if(cell.getData().process_id == 32){
                    return  `<div class="">`+cell.getData().pp_first_name +` (Party)<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;
              }else if(cell.getData().process_id == 1){
                if(cell.getData().sawing_type == 2){
                return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name +` (CS)</p>
                              </div>`;
                }else{
                  return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name +` (S)</p>
                              </div>`;
                }
              }else{
                return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;

              }
              }
              if(cell.getData().issue_type == 3){
              return  `<div class="">`+cell.getData().prop_first_name +`(`+cell.getData().prop_company_name +`)<br>
                  <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
                </div>` ;
              }
              else if(cell.getData().issue_type == 2){
                if(cell.getData().issue_by == userDetails.value.id){
                  return `<div class="">`+cell.getData().manager_first_name +`<br>
                <p class="text-theme-1 font-12 font-bold">`+cell.getData().manager_type_name+`</p>
              </div>`;
              }else{
                if(cell.getData().issue_user_type == 10 || cell.getData().issue_user_type == 11 || cell.getData().issue_user_type == 12){
                   return  `<div class="">`+cell.getData().issue_planner_first_name +`<br>
                <p class="text-theme-1 font-12 font-bold">`+cell.getData().issue_planner_type_name+`</p>
              </div>` ;

                }else{
                   return  `<div class="">`+cell.getData().issue_manager_first_name +`<br>
                <p class="text-theme-1 font-12 font-bold">`+cell.getData().issue_manager_type_name+`</p>
              </div>` ;

                }

              }

              }
            }
          },

          {
            title: 'Issue / Return Date',
            field: 'created_at',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().receive_date != null){
                return cell.getData().created_at + "<br>" + cell.getData().receive_date;
              }else{
                return cell.getData().created_at + "<br>" + " N/A";
              }

            }
          },
          {
            title: 'Status',
            field: 'status',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().trans_type == 2){
                return `<div class="flex items-center lg:justify-center text-theme-7">
                  Merged
                </div>`
              } else {

                if(cell.getData().issue_type == 3){
                    if(cell.getData().status == "1"){
                  return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                    In Process<br>Outside
                  </div>`
                  }else{
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Received
                    </div>`
                  }
                  }
                if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                  if(cell.getData().trans_status == "1"){
                    return `<div class="flex items-center lg:justify-center text-theme-1">
                      On Hold
                    </div>`
                  }else if(cell.getData().trans_status == "3"){
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                      Cancelled
                    </div>`
                  }else if(cell.getData().trans_status == "2"){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepted
                  </div>`
                  }else if(cell.getData().trans_status == "4"){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepting
                  </div>`
                  }
                  else{
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Unknown !
                  </div>`
                  }
                }
                else if(cell.getData().issue_by != userDetails.value.id){
                 if(cell.getData().trans_status == "1"){
                    return `<div class="flex items-center lg:justify-center text-theme-1">
                      On Hold
                    </div>`
                  }else if(cell.getData().trans_status == "3"){
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                      Cancelled
                    </div>`
                  }else if(cell.getData().trans_status == "2"){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepted
                  </div>`
                  }else if(cell.getData().trans_status == "4"){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepting
                  </div>`
                  }
                  else{
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Unknown !
                  </div>`
                  }
                }
                if(cell.getData().manager_id == null || cell.getData().manager_id == ''){
                  if(cell.getData().status == "1"){
                  return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                    In Process
                  </div>`
                  }else{
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Received
                    </div>`
                  }
                }
                else if(cell.getData().manager_id != null && cell.getData().manager_id != ''){
                  if(cell.getData().trans_status == "1"){
                    return `<div class="flex items-center lg:justify-center text-theme-1">
                      On Hold
                    </div>`
                  }else if(cell.getData().trans_status == "3"){
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                      Cancelled
                    </div>`
                  }else if(cell.getData().trans_status == "2"){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepted
                    </div>`
                  }else if(cell.getData().trans_status == "4"){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepting
                  </div>`
                  }
                  else{
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Unknown !
                    </div>`
                  }
                }
              }
            }
          },
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;

              if(cell.getData().status == 1 && cell.getData().issue_type == 2){
                if(cell.getData().issue_by == userDetails.value.id){
                if(cell.getData().trans_status == 1){
                  const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Withdraw
                    </button>
                  </div>`)
                  cash(a).on('click', function() {
                    editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                  })
                  return a[0]
                  }
                }
                else if(cell.getData().issue_by != userDetails.value.id){

                if(cell.getData().trans_status == 1){

                  const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                    </button>
                  </div>`)
                  cash(a).on('click', function() {
                    editTransactionStatus(cell.getData().id,cell.getData().issue_by,'accept');
                  })

                  return a[0]

                  }

               }
              }
              if(cell.getData().status == 1 && cell.getData().issue_type == 1){
                if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                if(cell.getData().issue_by == userDetails.value.id){
                if(cell.getData().trans_status == 1){
                  const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Withdraw
                    </button>
                  </div>`)
                  cash(a).on('click', function() {
                    editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                  })
                  return a[0]
                  }
                }
                else if(cell.getData().issue_by != userDetails.value.id){

                if(cell.getData().trans_status == 1){

                  const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                    </button>
                  </div>`)
                  cash(a).on('click', function() {
                    editTransactionStatus(cell.getData().id,cell.getData().issue_by,'accept');
                  })

                  return a[0]

                  }

               }
                }

              }
            }
          },


        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    //Edit user
    const editUser = (id) => {
      console.log(id);
    }

     //Edit user
    const editTransactionStatus = (id,user_id,action) => {
      console.log(id);
      console.log(user_id);
      let trans_id = id;
      let sender_type='';
      if(user_id == userDetails.value.id){
        sender_type = "sender";
      }else{
        sender_type = "receiver";
      }

      const params = {
      "trans_id":  trans_id,
      'sender_type' :sender_type,
      'action':action
      };

      swal({
        title: "Are you sure?",
        text: "You are about to Change transaction status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let api_url = ""

          if(userDetails.value.user_type == 25) {
            api_url = "api/hold_action_packet_trans"

          } else {
            api_url = "api/hold_action_packet_trans"

          }

          let promise = axios({
            url: api_url,
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              console.log(data.message);
              if(data.status == "error"){
                swal("Error!", data.message, "error");
              }else{
                router.go();
              }
            })
            .catch(_error => {
              return [];
          });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'transaction_code'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'buyer_list.xlsx', {
        sheetName: 'Buyers'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      editUser
    }

  }
})
</script>

