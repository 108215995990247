<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Current Status Summary</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

    <span tabindex="1"></span>
          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                    >Select Series/Kapan</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.issue_block" @change="changeBlocktype" >
                    <option value="1" selected>Kapan</option>
                    <option value="2">Series</option>
                  </select>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                    >Summary Type</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.summary_type">
                    <option value="1" selected>Only Manager</option>
                    <option value="2">Manager wise Series</option>
                    <option value="3">Manager wise Series & Process</option>
                  </select>
                </div>

              </div>

           <div class="col-span-12 xl:col-span-6">

                <div v-if="screenData.issue_block == 2">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Series</label
                  >
                 <TomSelect
                v-model="screenData.selectedSeries"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="">Select Series</option>
              <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
                </div>

                <div v-if="screenData.issue_block == 1">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Kapan</label
                  >
                 <TomSelect

                v-model="screenData.selectedKapan"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"

              >
              <option value="">Select Kapan</option>
              <option v-for="kapans in screenData.kapans_ar" :key="kapans.id" :value="kapans.id">{{kapans.kapan_code}}</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-12 mt-5">
                 <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                    >Rough Type</label>
                    <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.rough_type">
                    <option value="All" selected>All</option>
                    <option value="1">Natural</option>
                    <option value="2">CVD</option>
                    <option value="4">D_CVD</option>
                  </select>
                </div>
            <div>
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-20"
                tabindex="5"
                @click="generateReport()"
              >
              Generate
              </button>

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              tabindex="6"
              @click="onResetFilter"
            >
              Reset
            </button>
            <button v-if="screenData.genreport == 'gen'"
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20 ml-4"
              v-print="'#generate-pdf'"
            >
            Print
            </button>

          </div>
          <span tabindex="7"></span>
          </div>
        </div>

     <div id="responsive-table" class="p-5" v-if="screenData.load_price">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="bars" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
      </div>

      <div v-else>
      <div class="overflow-x-auto scrollbar-hidden" v-if="screenData.genreport == 'gen'">

        <div id="generate-pdf" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="w-full">
                  <thead>
                    <tr>

                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"

                      >
                        Manage Name
                      </th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"

                      >
                        Series No
                      </th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"

                      >
                        Kapan No
                      </th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"

                      >
                        Process Name
                      </th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"

                      >
                        Total Pcs
                      </th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"

                      >
                        Total Ct
                      </th>


                    </tr>



                  </thead>

                  <tbody>
                    <tr
                    v-for="record in screenData.report_fields" :key="record">
                    <td class="border-b border-l border-r  whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1 }" :rowspan="record.srowspan" v-if="record.m_r == 1">{{record.manger_name}}</td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold" v-else></td>

                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }"
                    v-if="record.s_r == 1">{{record.series_code}}</td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1 }" v-else></td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    v-if="record.k_r == 1">{{record.kapan_code}}</td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1 ,
                    'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }" v-else></td>
                     <td class="border-b border-l border-r whitespace-nowrap font-bold"
                      v-if="record.p_r == 1">{{record.process_name}}</td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1,
                    'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }" v-else></td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1,
                    'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }" v-if="$h.myInt(record.total_pc) != 0">{{ $h.myInt(record.total_pc) }}</td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1,
                    'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }" v-else></td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1,
                    'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }" v-if="$h.myFloat(record.total_ct) != 0">{{ $h.myFloat(record.total_ct)}}</td>
                    <td class="border-b border-l border-r whitespace-nowrap font-bold"
                    :class="{ 'dark:border-dark-5 bg-gray-300': record.m_r == 1 && screenData.summary_type != 1,
                    'dark:border-dark-5 bg-gray-100': record.s_r == 1 && screenData.summary_type != 1 }" v-else></td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      genreport : "gen",
      load_price:false,
      issue_block:2,
      summary_type : 1,
      rough_type: 'All',
      kapans_ar:[],
      series_ar:[],
      kapan_type:'2',
      report_fields:[],
      report_header:[],
      report_sub_headers:[],
      planning_report_fields:[],
      planning_report_fields_total:[],
      footer_total:[],
      hide_show_data:[],
      selectedKapan:'',
      selectedSeries:'',
      totalRghrate:0,
      totalMajuri:0,

    })

    const getKapans = () => {
      let _this = this;
      const params = {
        kapan_type:screenData.kapan_type
      };
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapans_ar = data.items;

        })
        .catch(_error => {

        });
    }

    const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;
        })
        .catch(_error => {

        });
    }

    //
    const generateReport = () => {

      if(screenData.issue_block == 1){
        screenData.selectedSeries = '';
      }else{
        screenData.selectedKapan = '';
      }
      screenData.report_fields = [];
      screenData.load_price = true;
      const params = {
        series_id: window.btoa(screenData.selectedSeries),
        kapan_id: window.btoa(screenData.selectedKapan),
        kapan_type :screenData.kapan_type
      };
      let post_url = '';
      if(screenData.summary_type == 1){
        post_url = "api/current_stock_by_manager_short";
      }
      else if(screenData.summary_type == 2){
        post_url = "api/current_stock_by_manager";
      }
      else if(screenData.summary_type == 3){
        post_url = "api/current_stock_by_manager_details";
      }
      let promise = axios({
        url: post_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);

          screenData.report_fields = data.report_details;
          console.log(screenData.report_fields);
          screenData.genreport = 'gen';
          screenData.load_price = false;
        })
        .catch(_error => {

        });
    }

    const focusFirst = () => {
        document.querySelector('[tabindex="2"]').focus();
    }
    const focusLast = () => {
        document.querySelector('[tabindex="6"]').focus();
    }


    const onResetFilter = () => {
      screenData.selectedbuyers = []
    }



    const changeBlocktype = () => {

    }


    onMounted(() => {
      //checkPermission()
      //reInitOnResizeWindow()
      getKapans()
      getSeries()
      focusFirst()
      //generateReport()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onResetFilter,
      generateReport,
      changeBlocktype,
      focusFirst, focusLast

    }
  }
})
</script>
