<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Kapan/Pkt Owner Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
          <div class="col-span-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="getSeriesPacketDetails"
              >
              <option value="">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
            <div class="col-span-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Kapan</label
              >
              <TomSelect
            v-model="screenData.kapan_id"
            :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Kapan'
                }
              }
            }"
            class="w-full"
            @change="getPacketDetails"
          >
          <option value="">Select Kapan</option>
          <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
          </TomSelect>
            </div>

            <div class="col-span-3">
              <label for="update-profile-form-9" class="form-label"
                >Select Packet</label
              >
              <TomSelect
              v-model="screenData.packet_id"
              :options="{
                placeholder: 'Select Packet',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Select Packet'
                  }
                }
            }"
            class="w-full"
            multiple
          >
          <option value="">Select Packets</option>
          <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
          </TomSelect>
            </div>
          <div class="col-span-1 mt-5">
            <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
          </div>
          <div class="col-span-1 mt-5">
            <button
                id="tabulator-html-filter-reset"
                type="button" :disabled="screenData.series_id == '' && screenData.kapan_id == ''"
                class="btn btn-primary w-full sm:w-16 mt-2 sm:ml-1"
                @click="initTabulator"
              >
                Genarate
              </button>
          </div>
          <div class="col-span-1 mt-5" v-if="pagedata.genarate == true">

            <button
              id="tabulator-html-filter-go"
              type="button"
              @click="printReportDetails"
              class="btn btn-primary w-full sm:w-16 mt-2 sm:ml-1"

            >
            Print
            </button>
          </div>
        </div>

      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs, watch} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      kapans: [],
      serieses: [],
      packets: [],
      data_status : false,
      genarate: false
    })
    const screenData = reactive({
      packet_id : [],
      kapan_id:'',
      series_id:'',
    })
    const printData = reactive({
      packet_id : [],
      kapan_id:'',
      series_id:'',
    })

    const initTabulator = () => {

      printData.packet_id = screenData.packet_id
      printData.kapan_id = screenData.kapan_id
      printData.series_id = screenData.series_id
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/lis_kapan_packet_report',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          kapan_id: screenData.kapan_id,
          series_id: screenData.series_id,
          packet_id: screenData.packet_id
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        // pagination: 'remote',
        // paginationSize: 20,
        // paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {

            title: 'Manager/Worker',
            field: 'karigar_first_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().process_id != null){
                if(cell.getData().process_id == 2 ||cell.getData().process_id == 29 || cell.getData().process_id == 3 || cell.getData().process_id == 26 || cell.getData().process_id == 28 || cell.getData().process_id == 31){
                      return  `<div class="">`+(cell.getData().manager_name ? cell.getData().manager_name : '-') +`<br>
                  <p class="text-theme-9 font-12 font-bold">`+(cell.getData().process_code ? cell.getData().process_code : '')+`</p>
                </div>` ;
                } else if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                    return  `<div class="">`+(cell.getData().planner_name ? cell.getData().planner_name : "-")+ (cell.getData().planner_name ?  ` (` + cell.getData().emp_code + `) ` : "") + `
                <p class="text-theme-9 font-12 font-bold">`+(cell.getData().process_code)+`</p>
              </div>` ;
              } else if(cell.getData().process_id == 32){
                return  `<div class="">`+(cell.getData().party_name ? cell.getData().party_name : '-') +`<br>
                  <p class="text-theme-9 font-12 font-bold">`+(cell.getData().process_code ? cell.getData().process_code : '')+`</p>
              </div>` ;
              } else if([37,38].includes(cell.getData().process_id)){
                return  `<div class="">`+(cell.getData().process_party_name ? cell.getData().process_party_name : '-') +`<br>
                  <p class="text-theme-9 font-12 font-bold">`+(cell.getData().process_code ? cell.getData().process_code : '')+`</p>
              </div>` ;
              }else{
                  return  `<div class="">`+(cell.getData().employee_name ? cell.getData().employee_name : "-") +`<br>
                  <p class="text-theme-9 font-12 font-bold">`+(cell.getData().process_code ? cell.getData().process_code : "")+`</p>
                </div>` ;

                }
              } else {
                return  `<div class="">`+(cell.getData().manager_name ? cell.getData().manager_name : "-") +`<br>
                  <p class="text-theme-1 font-12 font-bold">`+(cell.getData().role_name ? cell.getData().role_name : "") +`</p>
                </div>` ;
              }
            }
          },
          {
            title: 'Packet No',
            minWidth: 200,
            responsive: 0,
            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().packet_code ? cell.getData().packet_code : '-'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Total Pack',
            minWidth: 200,
            responsive: 0,
            field: 'total_packets',
            vertAlign: 'middle',
            print: false,
            download: true,
            topCalc:"sum",
            bottomCalc:"sum",
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_packets ? cell.getData().total_packets : '1'
                }
                </div>

              </div>`
            },
          },
          {
            title: 'Weight',
            field: 'issue_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            topCalc:"sum",
            bottomCalc:"sum",
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().issue_weight ? cell.getData().issue_weight + ' Ct' : ''
                }
                </div>

              </div>`
            },
          },




          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
      pagedata.genarate = true
    }

    const getKapanDetails = () => {
      let _this = this;
      const params = {
        series_id: ''
      };
      let api = ''
      if(screenData.series_id) {
        api = "api/dp_rough_kapan_by_series"
        params.series_id = window.btoa(screenData.series_id)
      } else {
        api = "api/dp_rough_kapan"
      }
      let promise = axios({
        url: api,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.kapans = data.items;
        })
        .catch(_error => {

        });
    }
    const getRoughDetails = () => {
      let promise = axios({
        url: "api/dp_rough_series_desc",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items
        })
        .catch(_error => {

        });
    }
    const getPackets = () => {
      let _this = this;
      const params = {
        id: ''
      };
      let api = ''
      if(screenData.kapan_id) {
        params.id = window.btoa(screenData.kapan_id)
        api = "api/get_packet_by_kapan"
      } else {
        params.id = window.btoa(screenData.series_id)
        api = "api/get_packet_by_series"
      }
      let promise = axios({
        url: api,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.packets = data.items
        })
        .catch(_error => {

        });
    }
    function getPacketDetails() {
      screenData.packet_id = []
      getPackets()
    }
    function getSeriesPacketDetails() {
      screenData.kapan_id = ''
      getKapanDetails()
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    const onResetFilter = () => {
      screenData.packet_id = []
      screenData.kapan_id = ''
      screenData.series_id = ''

    }

    function printReportDetails(){
      let report_type = 'packet-owner'

      let params = {
        series_id:printData.series_id,
        kapan_id:printData.kapan_id,
        packet_id:printData.packet_id.length > 0 ? printData.packet_id : '' ,
        print_report_name: report_type
      }
      let promise = axios({
        url: "api/set_lis_kapan_packet_report",
        method: "post",
        baseURL: BASE_URL,
        params: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id
          const report_name = data.report_name

          let url = BASE_URL + '/' + report_name + '?id=' + window.btoa(report_id)
          window.open(url, '_blank');

        })
        .catch(_error => {

        });
      // add_report_details
    }
    watch(() => screenData.kapan_id, (currentValue) => {
      if(currentValue) {
        getPackets()
      }
    })

    onMounted(() => {
      checkPermission()
      getRoughDetails()
      getKapanDetails()
      reInitOnResizeWindow()
      // initTabulator()
    })

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      initTabulator,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onResetFilter,
      getPacketDetails,
      printReportDetails,
      getSeriesPacketDetails
    }
  }
})
</script>
