<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Process Majuri Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                :disabled="screenData.issue_pending"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label mt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"
              :disabled="screenData.issue_pending"
              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Process</label
              >
              <TomSelect
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                @change="getEmployees"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="screenData.process_id != '0'">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Employee</label
              >
              <TomSelect
                v-model="screenData.employee_id"
                :options="{
                  placeholder: 'Select Employee',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Employee</option>
              <option v-for="employee in pagedata.employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Report Type</label
              >
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Select Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Employee</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="flex items-center mt-3">
              <label for="update-profile-form-6" class="form-label"
                >In Details</label
              >
              <input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.in_details"
              />
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              :disabled="(screenData.series_id == '0' && screenData.kapan_id == '0' && screenData.process_id == '0' && screenData.issue_pending == true)"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.generate == 'gen'">
          <div class="mt-10 flex">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
              v-print="'#generate-pdf'"
            >
            Print
            </button> -->
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden" v-if="pagedata.generate == 'gen'">
              <div class="intro-y col-span-12 overflow-auto" v-if="printData.report_type == '0'">
                <table class="mt-3 w-full border-2" v-if="printData.in_details == true">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Employee</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Kapan</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Issue Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Issue Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Receive Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Total Lose (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Total Assort (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Total Majuri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_lose ? $h.myFloat(pagedata.total.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_assortment ? $h.myFloat(pagedata.total.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody>
                  <tbody v-for="(item,index) in pagedata.items" :key="index">
                    <tr v-for="(single_item,ind) in item.employee_majuri" :key="ind">
                      <td class="py-2 text-xs lg:text-md text-center border-b-0 border-r">{{ ind == 0 ? (index + 1) : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-b-0 border-r">{{ ind == 0 ? item.process_code : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.first_name ? single_item.first_name : '-' }} {{ single_item.last_name ? single_item.last_name : '' }}</td>
                      <td class="border-b text-xs lg:text-md text-center border-r" colspan="7">
                        <table class="w-full">
                          <!-- <thead>
                            <tr class="border-b bg-gray-200 dark:bg-dark-1" v-if="index == 0 && ind == 0">
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Kapan</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Issue Packets</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Issue Weight (Ct)</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Receive Weight (Ct)</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Total Lose (Ct)</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Total Assort (Ct)</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r w-28">Total Majuri</th>
                            </tr>
                          </thead> -->
                          <tbody>

                            <tr v-for="(kapan_item,kapan_index) in single_item.kapan_details" :key="kapan_index">
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.kapan_code ? kapan_item.kapan_code : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_packets ? kapan_item.total_packets : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.issue_weight ? $h.myFloat(kapan_item.issue_weight) : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.receive_weight ? $h.myFloat(kapan_item.receive_weight) : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_ghat ? $h.myFloat(kapan_item.total_ghat) : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_assortment ? $h.myFloat(kapan_item.total_assortment) : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_majuri ? $h.myFloat(kapan_item.total_majuri) : '-' }}</td>
                              <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.recut_issue_weight) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_issue_weight) :  $h.myFloat(kapan_item.rough_issue_weight)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.recut_issue_weight) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_receive_weight) :  $h.myFloat(kapan_item.rough_receive_weight)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.rough_total_ghat) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_total_ghat) :  $h.myFloat(kapan_item.rough_total_ghat)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.rough_total_assortment) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_total_assortment) :  $h.myFloat(kapan_item.rough_total_assortment)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ (kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_total_majuri) :  (kapan_item.process_id == 18 ? $h.myFloat(kapan_item.planner_majuri) : (kapan_item.process_id == 19 ? $h.myFloat(kapan_item.checker_majuri) : (kapan_item.process_id == 20 ? $h.myFloat(kapan_item.main_checker_majuri) : $h.myFloat(kapan_item.rough_total_majuri)))) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.recut_issue_weight) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_issue_weight) :  $h.myFloat(kapan_item.rough_issue_weight)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.recut_issue_weight) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_receive_weight) :  $h.myFloat(kapan_item.rough_receive_weight)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.rough_total_ghat) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_total_ghat) :  $h.myFloat(kapan_item.rough_total_ghat)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.process_id == 35 ? $h.myFloat(kapan_item.rough_total_assortment) : ((kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_total_assortment) :  $h.myFloat(kapan_item.rough_total_assortment)) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ (kapan_item.process_id == 34 || kapan_item.process_id == 36) ? $h.myFloat(kapan_item.ls_total_majuri) :  (kapan_item.process_id == 18 ? $h.myFloat(kapan_item.planner_majuri) : (kapan_item.process_id == 19 ? $h.myFloat(kapan_item.checker_majuri) : (kapan_item.process_id == 20 ? $h.myFloat(kapan_item.main_checker_majuri) : $h.myFloat(kapan_item.rough_total_majuri)))) }}</td> -->
                              <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.receive_weight ? $h.myFloat(kapan_item.receive_weight) : '0' }}</td> -->
                              <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_ghat ? $h.myFloat(kapan_item.total_ghat) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_assortment ? $h.myFloat(kapan_item.total_assortment) : '0' }}</td> -->
                              <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ kapan_item.total_majuri ? $h.myFloat(kapan_item.total_majuri) : '0' }}</td> -->
                            </tr>
                            <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="(single_item.kapan_details)">
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">Kapna Total</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.total_packets ? single_item.total_packets : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.receive_weight ? $h.myFloat(single_item.receive_weight) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.total_ghat ? $h.myFloat(single_item.total_ghat) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.total_assortment ? $h.myFloat(single_item.total_assortment) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : '0' }}</td>
                            </tr>
                            <tr v-for="(series_item,series_index) in single_item.series_details" :key="series_index">
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ series_item.series_code ? series_item.series_code : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ series_item.total_packets ? series_item.total_packets : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ series_item.issue_weight ? $h.myFloat(series_item.issue_weight) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ (series_item.process_id == 2 || series_item.process_id == 26) ? $h.myFloat(series_item.ghutan_receive_weight) : $h.myFloat(series_item.receive_weight) }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ (series_item.process_id == 2 || series_item.process_id == 26) ? $h.myFloat(series_item.ghutan_total_ghat) : $h.myFloat(series_item.total_ghat) }}</td>
                              <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ series_item.total_ghat ? $h.myFloat(series_item.total_ghat) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ series_item.total_assortment ? $h.myFloat(series_item.total_assortment) : '0' }}</td> -->
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ series_item.total_majuri ? $h.myFloat(series_item.total_majuri) : '0' }}</td>
                            </tr>
                            <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="(single_item.series_details)">
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">Series Total</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.kapan_series_total_packets ? single_item.kapan_series_total_packets : '-' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.kapan_series_issue_weight ? $h.myFloat(single_item.kapan_series_issue_weight) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.kapan_series_receive_weight ? $h.myFloat(single_item.kapan_series_receive_weight) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.kapan_series_total_ghat ? $h.myFloat(single_item.kapan_series_total_ghat) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.kapan_series_total_assortment ? $h.myFloat(single_item.kapan_series_total_assortment) : '0' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ single_item.kapan_series_total_majuri ? $h.myFloat(single_item.kapan_series_total_majuri) : '0' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="border-b border-t bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ item.kapan_series_total_packets ? item.kapan_series_total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ item.kapan_series_issue_weight ? $h.myFloat(item.kapan_series_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ item.kapan_series_receive_weight ? $h.myFloat(item.kapan_series_receive_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ item.kapan_series_total_lose ? $h.myFloat(item.kapan_series_total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ item.kapan_series_total_assortment ? $h.myFloat(item.kapan_series_total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ item.kapan_series_total_majuri ? $h.myFloat(item.kapan_series_total_majuri) : '0' }}</td>
                    </tr>

                  </tbody>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.receive_weight ? $h.myFloat(pagedata.total.receive_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_lose ? $h.myFloat(pagedata.total.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_assortment ? $h.myFloat(pagedata.total.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r w-28">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="mt-3 w-full border-2" v-else>
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Employee</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Total Lose (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Total Assort (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Total Majuri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_lose ? $h.myFloat(pagedata.total.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_assortment ? $h.myFloat(pagedata.total.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody>
                  <tbody v-for="(item,index) in pagedata.items" :key="index">
                    <tr v-for="(single_item,ind) in item.employee_majuri" :key="ind">
                      <td class="py-2 text-xs lg:text-md text-center border-b-0 border-r">{{ ind == 0 ? (index + 1) : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-b-0 border-r">{{ ind == 0 ? item.process_code : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.first_name ? single_item.first_name : '-' }} {{ single_item.last_name ? single_item.last_name : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.total_packets ? single_item.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.receive_weight ? $h.myFloat(single_item.receive_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.total_ghat ? $h.myFloat(single_item.total_ghat) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.total_assortment ? $h.myFloat(single_item.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : '0' }}</td>
                    </tr>
                    <tr class="border-b border-t bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.total_packets ? item.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.receive_weight ? $h.myFloat(item.receive_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.total_lose ? $h.myFloat(item.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.total_assortment ? $h.myFloat(item.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.total_majuri ? $h.myFloat(item.total_majuri) : '0' }}</td>
                    </tr>

                  </tbody>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.receive_weight ? $h.myFloat(pagedata.total.receive_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_lose ? $h.myFloat(pagedata.total.total_lose) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_assortment ? $h.myFloat(pagedata.total.total_assortment) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { apiUrl, BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      processes: [],
      employees : [],
      kapan_details: [],
      series_details: [],
      series_total: [],
      total: [],
      items : [],
      loading: false,
      generate: 'no'
    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      process_id:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true,
      employee_id : '0',
      report_type : '0',
      in_details : false,

    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      process_id:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true,
      employee_id : '0',
      report_type : '0',
      in_details : false,
    })
    const generateReport = () => {

      printData.series_id = screenData.series_id,
      printData.kapan_id = screenData.kapan_id,
      printData.process_id = screenData.process_id,
      printData.issue_date =  screenData.issue_date,
      printData.receive_date = screenData.receive_date,
      printData.employee_id = screenData.employee_id,
      printData.report_type = screenData.report_type,
      printData.in_details = screenData.in_details,


      pagedata.loading  = true

      let params = {
        series_id : screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
        employee_id : screenData.employee_id != '0' ? window.btoa(screenData.employee_id) : '',
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date,
        issue_pending : screenData.issue_pending,
        in_details : screenData.in_details,
      }

      let api_url = ''

      if(printData.report_type == '0') {
        api_url = 'api/list_item_by_process_employee_majiri_details'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.items = data.items
          pagedata.total = data.total
          pagedata.loading  = false
          pagedata.generate = 'gen'
        })
        .catch(_error => {
          pagedata.loading  = false

        });
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_rough_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getProcesses = () => {
      // let params = {
      //   process_id : screenData.process_id,
      // }
      let promise = axios({
        url: "api/dp_process",
        method: "post",
        baseURL: BASE_URL,
        // data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.processes = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
      }
      let url = 'api/dp_rough_kapan'
      if(screenData.series_id == '0') {

      } else {
        url = 'api/dp_rough_kapan_by_series'
      }
      let promise = axios({
        url: url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = '0'

        })
        .catch(_error => {

        });
    }
    const getEmployees = () => {
      if(screenData.process_id == '0') {
        screenData.employee_id == '0'
      } else {
        let params = {
          process: screenData.process_id,
        }
        let promise = axios({
          url: "api/dp_karigar_by_process",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            screenData.employee_id == '0'
            pagedata.employees = data.items

          })
          .catch(_error => {

          });
      }
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.process_id = '0'
      screenData.issue_pending = true

      pagedata.kapan_details = []
      pagedata.series_details = []
      pagedata.series_total = []
      pagedata.total = []

      printData.series_id = '0'
      printData.kapan_id = '0'
      printData.process_id = '0'
      printData.issue_pending = true

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function seriesChange() {
      getKapans()
      screenData.kapan_id = ''
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      getProcesses()
      getKapans()
      reInitOnResizeWindow()

    })

    function printReportDetails(){
      let report_type = 'list_item_by_process_employee_majiri_details'

      let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.items,'total': pagedata.total }
      }
      let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
      });
      return promise
          .then(result => result.data)
          .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/list-item-by-process-employee-majiri-details?id=' + window.btoa(report_id)
          window.open(url, '_blank');

          })
          .catch(_error => {

          });
      // add_report_details
      }

    return {
      tableRef,
      pagedata,
      printData,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      generateReport,
      getEmployees,
      printReportDetails
    }
  }
})
</script>
