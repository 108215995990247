<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Shape Master</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <!-- <router-link
          :to="{ name: 'add-shape' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">

        Add New Shape
          </router-link> -->

          <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal"
          class="btn btn-primary shadow-md mr-2"
          >New Shape</a
        >

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="shape_name">Shape Name</option>
              <option value="shape_code">Code</option>
              <option value="shape_order">Order</option>
              <option value="advisory_shape_name">Advisory</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Shape modal-->

    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Shape</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <label for="pos-form-1" class="form-label">Shape Name</label>
              <input
                id="pos-form-1"
                v-model="shapeForm.shape_name"
                name="shape_name"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.shape_name.$error }"
                placeholder="Shape name"
              />
              <template v-if="validate.shape_name.$error">
                    <div
                      v-for="(error, index) in validate.shape_name.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Code</label>
              <input
                id="pos-form-2"
                v-model="shapeForm.shape_code"
                name="shape_code"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.shape_code.$error }"
                placeholder="Code"
              />
              <template v-if="validate.shape_code.$error">
                    <div
                      v-for="(error, index) in validate.shape_code.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Advisory Shape</label>
              <input
                id="pos-form-2"
                v-model="shapeForm.advisory_shape_name"
                name="shape_code"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.advisory_shape_name.$error }"
                placeholder="Advisory Shape"
              />
              <template v-if="validate.advisory_shape_name.$error">
                    <div
                      v-for="(error, index) in validate.advisory_shape_name.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Group</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="shape_group"
                v-model="shapeForm.shape_group"
                :class="{ 'border-theme-6': validate.shape_group.$error }"
                class="form-control flex-1"
                placeholder=""
              />

              <template v-if="validate.shape_group.$error">
                    <div
                      v-for="(error, index) in validate.shape_group.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Order</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="order"
                v-model="shapeForm.order"

                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.order.$error }"
                placeholder=""
              />
              <template v-if="validate.order.$error">
                    <div
                      v-for="(error, index) in validate.order.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Shortcut</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="order"
                v-model="shapeForm.shape_sk"

                class="form-control flex-1"

                placeholder=""
              />

            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitForm">
              Save Details
            </button>
            <button type="button" v-if="shapeForm.id != ''" class="btn btn-danger w-32 ml-1" @click="deleteItem">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

              <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">

                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
              <!-- END: Failed Notification Content -->
            </div>

</template>

<script>
import { defineComponent, ref, reactive, toRefs,onMounted,inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'shape_name',
      type: 'like',
      value: ''
    })

    const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))

    const shapeForm = reactive({
      shape_name: '',
      advisory_shape_name: '',
      shape_code: '',
      shape_group: '',
      shape_sk:'',
      order:'',
      id:''
    })

    const rules = {
      shape_name: {
        required,
        minLength: minLength(2)
      },
      shape_code: {
        required,

      },
      advisory_shape_name: {
        required,

      },
      shape_group: {
        required,
      },
      order: {
        integer
      }
    }
    const validate = useVuelidate(rules, toRefs(shapeForm))

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_shape',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Shape Name',
            minWidth: 200,
            responsive: 0,
            field: 'shape_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },

          {

            title: 'Shape Code',
            minWidth: 200,
            field: 'shape_code',

            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

            title: 'Advisory Shape',
            minWidth: 300,
            field: 'advisory_shape_name',

            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Shape Group',
            minWidth: 200,
            field: 'shape_group',

            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Shortcut',

            field: 'shape_sk',

            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',

            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().active_status == 'Active' ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 'Active' ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: '-',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                getDetails(cell.getData().id);
              })

              return a[0]
            }
          },

          // For print format
          {
            title: 'Shape Name',
            field: 'shape_name',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Shape Code',
            field: 'shape_code',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Status',
            field: 'status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    const getDetails = (id) => {
      console.log(id);
      const params = {
      "id":  id,
      };

      let promise = axios({
        url: "api/details_shape",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          shapeForm.shape_name = data.item_details.shape_name;
          shapeForm.advisory_shape_name = data.item_details.advisory_shape_name;
          shapeForm.shape_code = data.item_details.shape_code;
          shapeForm.shape_group = data.item_details.shape_group;
          shapeForm.shape_sk = data.item_details.shape_sk;
          shapeForm.order = data.item_details.shape_order;
          shapeForm.id = data.item_details.id;
          cash('#new-order-modal').modal('show')
        })
        .catch(_error => {

        });
    }

    const submitForm = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {


      const params = {
      "shape_name":  shapeForm.shape_name,
      "advisory_shape_name":  shapeForm.advisory_shape_name,
      "shape_code":  shapeForm.shape_code,
      "shape_group":  shapeForm.shape_group,
      "shape_sk":  shapeForm.shape_sk,
      "shape_order":  shapeForm.order,
      "id" : shapeForm.id
      };
      let promise_url = '';
      if(shapeForm.id != '' && shapeForm.id != null){
        promise_url = "api/edit_shape";
      }else{
        promise_url = "api/add_shape"
      }
      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          if(data.status == 'error'){
            swal("Warning!", data.msg, "warning");
            return;
          }else{

            cash('#new-order-modal').modal('hide')
                tabulator.value.setFilter(filter.field, filter.type, filter.value)

            let promise_url_selling = '';
            if(shapeForm.id != '' && shapeForm.id != null){
              promise_url_selling = "/edit_shape";
            }else{
              promise_url_selling = "/add_shape"
            }
            let promise = axios({
              url: promise_url_selling,
              method: "POST",
              data: params,
              baseURL: BASE_URL_SELLING,
              headers: { Authorization: "Bearer " + AUTH_TOKEN },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {
                if(data.status == 'error'){
                  swal("Warning!", data.msg, "warning");

                  return;
                }else{
                  Toastify({
                node: cash('#success-notification-content')
                  .clone()
                  .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
              }).showToast()
              shapeForm.shape_name = '';
                shapeForm.advisory_shape_name = '';
                shapeForm.shape_code = '';
                shapeForm.shape_group = '';
                shapeForm.shape_sk = '';
                shapeForm.order = '';
                shapeForm.id = '';

                }

              })
              .catch(_error => {
                swal("Error!", "Data not Saved" + ", On Server!", "error")
              });

          }

        })
        .catch(_error => {
          swal("Error!", "Something went wrong", "error")
        });


      }

    }

    const deleteItem = () => {
      console.log(shapeForm.id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'shape_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      shapeForm,
      validate,
      submitForm,
      onFilter,
      deleteItem,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  }
})
</script>
