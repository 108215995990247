<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">LS Operator Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Process Type</label
              >
              <TomSelect
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Process Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process Type'
                    }
                  }
                }"
                class="w-full"
                @change="processChange"
              >
              <option value="">Select Process Tyoe</option>
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                @change="kapanChange"
              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Packets</option>
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div v-if="screenData.issue_pending">
            <label for="update-profile-form-6" class="form-label py-1"
              >Status Type</label
            >
            <TomSelect
              v-model="screenData.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }"
              class="w-full"
              disabled
            >
            <!-- <option value="0">All</option> -->
            <option value="1"><span v-if="screenData.issue_pending == true">Pending</span><span v-else>Issue</span></option>
            <option value="2">Received</option>
            </TomSelect>
          </div>
          <div v-else>
            <label for="update-profile-form-6" class="form-label py-1"
                >Status Type</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <!-- <option value="0">All</option> -->
              <option value="1"><span v-if="screenData.issue_pending == true">Pending</span><span v-else>Issue</span></option>
              <option value="2">Received</option>
              </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Report Type</label
              >
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Employee Wise</option>
              <option value="2">Summary</option>
              <option value="3">Details</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label pt-3"
                >Select Karigar</label
              >
              <TomSelect
                v-model="screenData.karigar_id"
                :options="{
                  placeholder: 'Select Karigar',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Karigar'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Karigar</option>
              <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{karigar.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="screenData.issue_pending"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReport" :disabled="printData.report_type == 3"

            >
            Print
            </button>
          </div>
        </div>

      </div>
      <div v-if="pagedata.report_type == '3'">
        <div class="overflow-x-auto scrollbar-hidden">
          <div>

            <h2 class="font-medium text-base mr-auto mt-10">Lot Details</h2>
            <div
              id="tabulator"
              ref="tableRef"
              class="mt-5"
            ></div>

          </div>
          <div v-if="pagedata.process_id == '34' || pagedata.process_id == '36'">
            <h2 class="font-medium text-base mr-auto mt-10" v-if="pagedata.process_id == '34'">Bunch Details</h2>
            <h2 class="font-medium text-base mr-auto mt-10" v-if="pagedata.process_id == '36'">Series Details</h2>
            <div
              id="tabulatorLot"
              ref="tableRefLot"
              class="mt-5"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="pagedata.report_type == '1'">
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulatorEmployee"
            ref="tableRefEmployee"
            class="mt-5"
          ></div>
        </div>
      </div>
      <div v-if="pagedata.report_type == '2'">
        <!-- BEgin: Process Report Start-->
        <div id="responsive-table" class="p-5" v-if="pagedata.loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
            </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div id="generate-pdf" class="scrollbar-hidden">
            <div>
              <div class="intro-y col-span-12 overflow-auto" v-if="(pagedata.process_id == 35 || pagedata.process_id == 36)">
                <table class="mt-3 w-full border-2 text-center">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap bprder-r-2">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap bprder-r-2">Karigar Name</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Details</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish Weight</th> -->
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="text-xs lg:text-md whitespace-nowrap  p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tbody>
                            <tr class="p-0 m-0">
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">Kapan</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">Issue Pieces</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">Issue Weight (Ct)</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2"  v-if="pagedata.issue_pending == false && pagedata.status == 2">Ls Issue Pieces</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2"  v-if="pagedata.issue_pending == false && pagedata.status == 2">Ls Issue Weight (Ct)</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">Total Majuri</th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="text-xs lg:text-md whitespace-nowrap p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tbody>
                            <tr class="p-0 m-0">
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2"></th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ pagedata.total.total_issue_packets }}</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ $h.myFloat(pagedata.total.total_issue_weight) }}</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ pagedata.total.ls_issue_packets }}</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ $h.myFloat(pagedata.total.ls_issue_weight) }}</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ $h.myFloat(pagedata.total.total_majuri) }}</th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </tbody>
                  <tbody v-for="(items,index) in pagedata.report_details" :key="index">
                    <tr v-if="items.total_kapans > 0">
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2">{{ index + 1 }}</td>
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2">{{ (items.first_name ? items.first_name : "-") + ' ' + (items.last_name ? items.last_name : "") }}</td>
                      <td class="text-xs lg:text-md whitespace-nowrap  p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tr class="p-0 m-0 border-b" v-for="(value,inx) in items.packet_details" :key="inx">
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.kapan_code }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.total_issue_packets }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ $h.myFloat(value.total_issue_weight) }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ value.ls_issue_packets }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ $h.myFloat(value.ls_issue_weight) }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ $h.myFloat(pagedata.total.total_majuri) }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1" v-if="items.total_kapans > 0">
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></td>
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></td>
                      <td class="text-xs lg:text-md whitespace-nowrap  p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tr class="p-0 m-0">
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.total_kapans }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.total_issue_packets }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ $h.myFloat(items.total_issue_weight) }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ items.ls_issue_packets }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ $h.myFloat(items.ls_issue_weight) }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ $h.myFloat(items.total_majuri) }}</th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="intro-y col-span-12 overflow-auto" v-else>
                <table class="mt-3 w-full border-2 text-center">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap bprder-r-2">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap bprder-r-2">Karigar Name</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Details</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish Weight</th> -->
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="text-xs lg:text-md whitespace-nowrap  p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tbody>
                            <tr class="p-0 m-0">
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">Kapan / <span v-if="pagedata.process_id == '34'">Bunch</span></th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">Issue Pieces</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">Issue Weight (Ct)</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">Total Majuri</th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="text-xs lg:text-md whitespace-nowrap p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tbody>
                            <tr class="p-0 m-0">
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2"></th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : "-" }}</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ pagedata.total.total_issue_weigh ? $h.myFloat(pagedata.total.total_issue_weight) : "-" }}</th>
                              <th class="py-2 m-0 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : "-" }}</th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </tbody>
                  <tbody v-for="(items,index) in pagedata.report_details" :key="index">
                    <tr v-if="items.total_kapans > 0">
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2">{{ index + 1 }}</td>
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2">{{ items.first_name + ' ' + items.last_name }}</td>
                      <td class="text-xs lg:text-md whitespace-nowrap  p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tr class="p-0 m-0 border-b" v-for="(value,index) in items.packet_details" :key="index">
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.kapan_code }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.total_issue_packets ? value.total_issue_packets : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.total_issue_weight ? $h.myFloat(value.total_issue_weight) : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ value.total_majuri ? $h.myFloat(value.total_majuri) : "-" }}</td>
                          </tr>
                          <tr class="border-b bg-gray-200 dark:bg-dark-1" v-if="items.packet_details.length > 0 && pagedata.process_id != '35'">
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2"></td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.kapan_total_issue_pieces ? items.kapan_total_issue_pieces : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.kapan_total_issue_weight ? $h.myFloat(items.kapan_total_issue_weight) : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ items.kapan_total_issue_majuri ? $h.myFloat(items.kapan_total_issue_majuri) : "-" }}</td>
                          </tr>
                          <tr class="p-0 m-0 border-b" v-for="(value,index) in items.series_details" :key="index">
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.series_code }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.total_issue_packets ? value.total_issue_packets : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ value.total_issue_weight ? $h.myFloat(value.total_issue_weight) : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ value.total_majuri ? $h.myFloat(value.total_majuri) : "-" }}</td>
                          </tr>
                          <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="items.series_details.length > 0 && pagedata.process_id != '35'">
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2"></td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.series_total_issue_pieces ? items.series_total_issue_pieces : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.series_total_issue_weight ? $h.myFloat(items.series_total_issue_weight) : "-" }}</td>
                            <td class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ items.series_total_issue_majuri ? $h.myFloat(items.series_total_issue_majuri) : "-" }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1" v-if="items.total_kapans > 0">
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></td>
                      <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2"></td>
                      <td class="text-xs lg:text-md whitespace-nowrap  p-0 m-0">
                        <table class="w-full p-0 m-0">
                          <tr class="p-0 m-0">
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.total_kapans }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.total_issue_packets }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24 border-r-2">{{ items.total_issue_weight ?$h.myFloat(items.total_issue_weight) : "-" }}</th>
                            <th class="py-2 text-xs lg:text-md whitespace-nowrap lg:w-38 md:w-32 sm:w-28 w-24" v-if="pagedata.issue_pending == false && pagedata.status == 2">{{ items.total_majuri ? $h.myFloat(items.total_majuri) : "-" }}</th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div v-else>

            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const tableRefLot = ref()
    const tabulatorLot = ref()
    const tableRefEmployee = ref()
    const tabulatorEmployee = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      stones: [],
      shapes: [],
      employees: [],
      processes: [],
      report_details : [],
      total : [],
      report_type: 0,
      loading: false,
      status: 1,
      issue_pending: false,
      process_id : ''

    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      packets:[],
      process_id:'34',
      karigar_id:'0',
      status:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true,
      report_type: '1'

    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      packets:[],
      process_id:'34',
      karigar_id:'0',
      status:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true,
      report_type: '1'

    })

    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }

    const generateReport = () => {

      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.stone_id = screenData.stone_id
      printData.packets = screenData.packets
      printData.process_id = screenData.process_id
      printData.karigar_id = screenData.karigar_id
      printData.status = screenData.status
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending
      printData.report_type = screenData.report_type
      pagedata.process_id = screenData.process_id
      if(screenData.report_type == '1') {
        pagedata.report_type = 1
        pagedata.process_id = screenData.process_id
        pagedata.status = screenData.status
        pagedata.issue_pending = screenData.issue_pending
        setTimeout(() => {
          if(pagedata.issue_pending == true || (pagedata.issue_pending == false && pagedata.status == '1')) {
            if(pagedata.process_id == 35 || pagedata.process_id == 36) {
              tabulatorEmployee.value = new Tabulator(tableRefEmployee.value, {
                ajaxURL: BASE_URL + '/api/get_ls_operator_emp_wise',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                // pagination: 'remote',
                // paginationSize: 20,
                // paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return cell.getData().first_name + ' ' + cell.getData().last_name
                    }
                  },

                  {
                    title: 'Oriznal Issue Weight',
                    field: 'oriznal_issue_weight',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },
                  {
                    title: 'Oriznal Issue Pieces',
                    field: 'oriznal_issue_piece',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })

            } else {
              tabulatorEmployee.value = new Tabulator(tableRefEmployee.value, {
                ajaxURL: BASE_URL + '/api/get_ls_operator_emp_wise',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                // pagination: 'remote',
                // paginationSize: 20,
                // paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return cell.getData().first_name + ' ' + cell.getData().last_name
                    }
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_piece',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })

            }
          } else {
            if(pagedata.process_id == 35 || pagedata.process_id == 36) {
              tabulatorEmployee.value = new Tabulator(tableRefEmployee.value, {
                ajaxURL: BASE_URL + '/api/get_ls_operator_emp_wise',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                // pagination: 'remote',
                // paginationSize: 20,
                // paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigar_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().first_name ? cell.getData().first_name : "-") + ' ' + (cell.getData().last_name ? cell.getData().last_name : "")
                    }
                  },

                  {
                    title: 'Issue Weight',
                    field: 'oriznal_issue_weight',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'oriznal_issue_piece',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                  },
                  {
                    title: 'LS Issue Weight',
                    field: 'issue_received_weight',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },
                  {
                    title: 'LS Issue Pieces',
                    field: 'issue_piece',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                  },
                  {
                    title: 'Total Majuri',
                    field: 'total_majuri',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            } else {

              tabulatorEmployee.value = new Tabulator(tableRefEmployee.value, {
                ajaxURL: BASE_URL + '/api/get_ls_operator_emp_wise',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                // pagination: 'remote',
                // paginationSize: 20,
                // paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().first_name ? cell.getData().first_name : "-") + ' ' + (cell.getData().last_name ? cell.getData().last_name : "")
                    }
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_piece',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                  },
                  {
                    title: 'Total Majuri',
                    field: 'total_majuri',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            }
          }
        }, 40);
      }
      if(screenData.report_type == '2') {
        pagedata.report_type = 2
        pagedata.status = screenData.status
        pagedata.issue_pending = screenData.issue_pending
        pagedata.loading  = true
          let params = {
            series_id : screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
            kapan_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
            packets : screenData.packets != '0' ? screenData.packets : '',
            process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
            karigar_id : screenData.karigar_id != '0' ? window.btoa(screenData.karigar_id) : '',
            stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
            status : window.btoa(screenData.status),
            issue_date : window.btoa(screenData.issue_date),
            receive_date : window.btoa(screenData.receive_date),
            issue_pending : screenData.issue_pending,
            report_type : window.btoa(screenData.report_type)
          }
          let promise = axios({
            url: "api/get_ls_operator_summary_by_kapan",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              pagedata.report_details = data.items
              pagedata.total = data.total
              pagedata.loading  = false
            })
            .catch(_error => {

            });
      }
      if(screenData.report_type == '3') {

        pagedata.process_id = screenData.process_id
        pagedata.status = screenData.status
        pagedata.issue_pending = screenData.issue_pending
        pagedata.report_type = 3
        setTimeout(() => {
          if(pagedata.issue_pending == true || (pagedata.issue_pending == false && pagedata.status == '1')) {
            if(screenData.process_id == '35' || screenData.process_id == '36') {
              tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_lot_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Packet No',
                    field: 'packet_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    // bottomCalc:"count",
                    // topCalc:"count",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            } else {
              tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_lot_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Packet No',
                    field: 'packet_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    // bottomCalc:"count",
                    // topCalc:"count",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_pieces',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })

            }

            if(screenData.process_id == '34') {
              tabulatorLot.value = new Tabulator(tableRefLot.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_bunch_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Bunch Code',
                    field: 'bunch_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    // bottomCalc:"count",
                    // topCalc:"count",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_pieces',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            }
            if(screenData.process_id == '36') {
              tabulatorLot.value = new Tabulator(tableRefLot.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_bunch_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Series',
                    field: 'series_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    bottomCalc:"count",
                    topCalc:"count",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_pieces',
                    print: false,
                    download: true,
                    bottomCalc:"sum",
                    topCalc:"sum",
                    bottomCalcParams:{precision:4},
                    topCalcParams:{precision:4}
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            }
          } else {
            if(screenData.process_id == '35' || screenData.process_id == '36') {
              tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_lot_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Packet No',
                    field: 'packet_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    // bottomCalc:"count",
                    // topCalc:"count",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'LS Issue Weight',
                    field: 'ls_issue_weight',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'LS Issue Pieces',
                    field: 'ls_issue_packets',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },
                  {
                    title: 'Total Majuri',
                    field: 'total_majuri',
                    print: false,
                    download: true,
                    // bottomCalc:"sum",
                    // topCalc:"sum",
                    // bottomCalcParams:{precision:4},
                    // topCalcParams:{precision:4}
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })

            } else {
              tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_lot_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Packet No',
                    field: 'packet_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true,
                    formatter(cell) {
                      return cell.getData().issue_weight
                    }
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_pieces',
                    print: false,
                    download: true
                  },
                  {
                    title: 'Total Majuri',
                    field: 'total_majuri',
                    print: false,
                    download: true
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })

            }

            if(screenData.process_id == '34') {
              tabulatorLot.value = new Tabulator(tableRefLot.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_bunch_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Bunch Code',
                    field: 'bunch_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true
                  },
                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_pieces',
                    print: false,
                    download: true
                  },
                  {
                    title: 'Total Majuri',
                    field: 'total_majuri',
                    print: false,
                    download: true
                  },
                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            }
            if(screenData.process_id == '36') {
              tabulatorLot.value = new Tabulator(tableRefLot.value, {
                ajaxURL: BASE_URL + '/api/get_ls_orperator_bunch_summary',
                ajaxConfig:{
                    method:"GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams:{
                  series_id : screenData.series_id != '0' ? screenData.series_id : '',
                  kapan_id : screenData.kapan_id != '0' ? screenData.kapan_id : '',
                  packets : screenData.packets != '0' ? screenData.packets : '',
                  process_id : screenData.process_id != '0' ? screenData.process_id : '',
                  karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                  stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
                  status : screenData.status,
                  issue_date : screenData.issue_date,
                  receive_date : screenData.receive_date,
                  issue_pending : screenData.issue_pending,
                  report_type : screenData.report_type
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader:true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [
                  // For HTML table
                  // For HTML table
                  {
                    title: 'Karigar',
                    field: 'karigan_name',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true,
                    formatter(cell) {
                        return (cell.getData().karigar_first_name ? cell.getData().karigar_first_name : "-") + ' ' + (cell.getData().karigar_last_name ? cell.getData().karigar_last_name : "")
                    }
                  },
                  {
                    title: 'Series',
                    field: 'series_code',
                    vertAlign: 'middle',
                    print: false,
                    minWidth:160,
                    download: true
                  },

                  {
                    title: 'Issue Weight',
                    field: 'issue_weight',
                    print: false,
                    download: true
                  },
                  {
                    title: 'Issue Pieces',
                    field: 'issue_pieces',
                    print: false,
                    download: true
                  },
                  {
                    title: 'Total Majuri',
                    field: 'total_majuri',
                    print: false,
                    download: true
                  },

                ],
                renderComplete() {
                  feather.replace({
                    'stroke-width': 1.5
                  })
                }
              })
            }
          }
        }, 40);
      }
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_rough_series_desc",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        process_id : screenData.process_id,
      }
      let url = ''

      if(screenData.series_id != '0') {
        url = "api/dp_rough_kapan_by_series"
      } else {
        url = 'api/dp_rough_kapan'
      }
      let promise = axios({
        url: url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getPackets = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        process_id : screenData.process_id,
      }
      if(screenData.kapan_id != '0' || screenData.series_id != '0') {
        let url = ''
        let params = {}
        if(screenData.kapan_id != '0') {
          url ="api/get_packet_by_kapan"
          params = {
          // id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
          id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
          process_id : screenData.process_id,
      }
        } else if(screenData.series_id != '0') {
          url ="api/get_packet_by_series"
          params = {
        id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        // kapan_id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        // process_id : screenData.process_id,
      }
        }
        let promise = axios({
          url: url,
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            pagedata.packets = data.items

          })
          .catch(_error => {

          });
      }
    }
    const getEmployees = () => {
      let params = {
        process: screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    const getProcess = () => {
      let params = {
        // process: pagedata.process,
      }
      let promise = axios({
        url: "api/ls_operator_process_list",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.processes = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.packets = []
      screenData.karigar_id = '0'
      screenData.process_id = '34'
      screenData.status ='1'
      screenData.issue_pending = true
      screenData.stone_id = '0'
      screenData.report_type = '1'

      pagedata.report_type = 0
      pagedata.status = 1
      pagedata.issue_pending = false
      if(pagedata.report_type == 1) {
        pagedata.report_details = []
        pagedata.total = []
      }

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      getPackets()
    }
    function seriesChange() {
      getParameters()
      screenData.kapan_id = ''
      screenData.packets = []
    }
    function kapanChange() {
      getPackets()
      screenData.packets = []
    }
    function processChange() {
      getSeries()
      getKapans()
      getPackets()
      getEmployees()
      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.karigar_id = '0'
      screenData.packets = []
    }
    const getShapes = () => {
      let params = {
        // process: pagedata.process,
        // party_id: window.btoa(screenData.party_id),
      }
      let promise = axios({
        url: "api/dp_shape_group",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.shapes = data.items

        })
        .catch(_error => {

        });
    }
    const printReport = () => {

      let items = {
        'items': [],
        'total': []
      }

      if(printData.report_type == 1) {
        items.items = tabulatorEmployee.value.getData();
      } else if(printData.report_type == 2) {
        items.items = pagedata.report_details;
        items.total = pagedata.total;
      } else {
        return;
      }

      let report_type = 'ls-operator-summary-lot-report'
        let params = {
          report_type: report_type,
          filters: printData,
          items: items
        }
        let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });
    }

    onMounted(() => {
      checkPermission()
      getSeries()
      getEmployees()
      getDPStoneType()
      getShapes()
      getProcess()
      getParameters()
      reInitOnResizeWindow()

    })

    return {
      tableRef,
      tableRefEmployee,
      printData,
      tableRefLot,
      pagedata,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      processChange,
      generateReport,
      printReport
    }
  }
})
</script>
