<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Tender</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Tender Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Tender name"
                    v-model="tender_name"
                    
                  />
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Contact Email</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    v-model="contact_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Contact Mobile No</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="+00 00000 00000"
                    v-model="contact_number"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Site Address</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="address"
                  />
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Start Date & Time</label
                  >
                   <input
                   type= "datetime-local"
                    id="update-profile-form-13"
                    class="form-control"
                    placeholder=""
                    v-model="start_date"
                    @input="clearOtherDate"
                  />
                 
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label"
                    >End Date & Time</label
                  >
                  <input
                    type= "datetime-local"
                    id="update-profile-form-13"
                    :min ="start_date"
                    class="form-control"
                    placeholder=""
                    v-model="end_date"
                  />
                 
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Bid Start Date & Time</label
                  >
                    <input
                   type= "datetime-local"
                    id="update-profile-form-13"
                    :min ="start_date"
                    class="form-control"
                    placeholder=""
                    v-model="bid_start_date"
                    @input="clearBidEndDate"
                  />
                 
                
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label"
                    >Bid End Date & Time</label
                  >
                    <input
                   type= "datetime-local"
                    id="update-profile-form-13"
                    :min ="bid_start_date"
                    class="form-control"
                    placeholder=""
                    v-model="bid_end_date"
                  />
                 
                  
                </div>
              </div>
            </div>
            
        
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Details</h2>
        </div>
        <div class="border">
        <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;">
             
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Sr. No</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Group</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Cut</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Details</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Stone</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Carats</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
            <p>Exp. Price($)/Carat</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
            <p>Total Price($)</p>
              </div>
            </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.lot_no" type="text"
                :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Sr. no">
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.group" type="text"
                :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Group">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.cut" type="text"
                :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Cut">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.details" type="text"
                :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Details" >
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
              <input v-model="field.piece" type="text"
              :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Stone" @keypress="$h.preventInvalidInput" onpaste="return false;" >
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
              <input v-model="field.carats" type="text"
              :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Carats" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateTotal">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
             <input v-model="field.expect_price" type="text"
             :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="Expected Price" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateTotal">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 pt-2">
                <span class="pl-1 pt-3">{{parseFloat(field.expect_price * field.carats).toFixed(2) }}</span>
            
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3" v-if ="(auction_status != 'yes' && status != 2)">
               <div class="flex">
                 <button type="button" class="text-theme-6 flex items-center" >
                <Trash2Icon class="w-4 h-4 mr-1" @click="removeField(field.id)"/> 
              </button>
              <button type="button" class="text-theme-9 flex items-center ml-5" v-if ="(auction_status != 'yes' && status != 2 && field.attachment != 'yes')">
                <ExternalLinkIcon class="w-4 h-4 mr-1" @click="addLinkField(field.id)"/>
              </button>
              </div>
             
              </div>
               
              <div class="col-span-10 md:col-span-10 xl:col-span-10 pt-3" v-if ="(field.attachment == 'yes')">
                <input v-model="field.attachment_link" type="text"
                :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="File Link" >
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-2 pt-3" v-if ="(auction_status != 'yes' && status != 2 && field.attachment == 'yes')">
                 <button type="button" class="text-theme-6 flex items-center ml-5 mt-2" @click="removeLinkField(field.id)" >
                <AlertCircleIcon class="w-4 h-4 mr-1" /> Remove
              </button>
              </div>
            
            </div>
          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total weight(Carat) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_rough_carat.toFixed(2)}}</p>
              </div>

          </div>
           <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Expected/Carat ($) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_expected_amount.toFixed(2)}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Expected Price ($) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_expected_amount_overall.toFixed(2)}}</p>
              </div>

          </div>
          <button type="button" @click="AddField" class="btn btn-primary ml-5 mt-5 mr-auto">
            New Item
          </button>
        </div>
        <div>
        <div class="p-5">
            <div class="flex justify-end mt-4">
             
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>
              <button type="button" class="text-theme-6 flex items-center" v-if ="tender_update_id != ''" @click="deleteTender">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Tender
              </button>
            </div>
        </div>
          </div>
          </form>
        </div>
        
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    tender_id: {
      type: String,
      default: ""
    }
  },
  
  data() {
    return {
      tender_update_id : '',
      total_rough_carat:0,
      total_expected_amount:0,
      total_expected_amount_overall:0,
      tender_name : '',
      contact_number: '',
      contact_email: '',
      address: '',
      start_date: '01-01-2021 00:00:00',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      total_items:1,
      auction_status : 'no',
      fields: [{ id:1,lot_no: '',group: '',cut: '',details: '',piece: '',carats: '',expect_price: '',attachment:'',attachment_link:'' }],
      delete_items : [],
      url:'',
      status: 'default',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  this.tender_update_id = this.tender_id;
  if(this.tender_id != ''){
    this.getTenderDetails(this.tender_id);
  }
  },

  methods: {
    alertVal () {
      alert(this.dob)
    },
    onUserFormSubmit() {
      //console.log(this.fields);
      
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      
      if (!this.errors.length) {
        const data = {
          tender_name: this.tender_name,
          contact_number: this.contact_number,
          contact_email:this.contact_email,
          start_date :this.start_date,
          end_date : this.end_date,
          bid_start_date:this.bid_start_date,
          bid_end_date:this.bid_end_date,
          rough_details:this.fields,
          delete_items: this.delete_items,
          address:this.address,
          td:this.tender_id
        };

        if(this.tender_id != ''){
          this.url = "api/update_tender"
        }else{
          this.url = "api/create_tender"
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {
            this.$notify(
              "success",
              "Record Added",
              "New User Added Successfully",
              {
                duration: 2000,
                permanent: false
              },
              setTimeout(() => {
                this.$router.push("/app/tender/list");
              }, 500)
            );
          })
          .catch(response => {
           
          });
      }
    },
    AddField() {
      this.total_items++;
      this.fields.push({ id:this.total_items,lot_no: '',group:'',cut: '',details: '',piece: '',carats: '',expect_price: '',attachment:'',attachment_link:'' });
      console.log(this.total_items);
    },
    removeField(id) {
     
       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields.splice(i,1);
              }
            }
            this.delete_items.push(id);
          }
      });
    },
    addLinkField(id){
      console.log(id);
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields[i]['attachment'] = 'yes';
              }
            }
    },
    removeLinkField(id){
      console.log(id + "here");
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields[i]['attachment'] = '';
                  this.fields[i]['attachment_link'] = '';
              }
            }
    },

    deleteTender(){

      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete Tender!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Agree & Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const params = {
            tender_id: this.tender_id
          };
          let promise = axios({
            url: "api/delete_tender",
            method: "POST",
            params: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              this.$notify(
                  "success",
                  "Record Deleted",
                  "Tender Deleted Successfully",
                  {
                    duration: 2000,
                    permanent: false
                  },
                  setTimeout(() => {
                    this.$router.push("/app/tender/list");
                  }, 500)
                );

            })
            .catch(_error => {
              
            });
          
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          
        }
      });

    },
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id
      };
      let promise = axios({
        url: "api/get_tender_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let tender_detail = data.tender_detail;
          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.rough_details_arr;
          this.contact_number= tender_detail.contact_number;
          this.contact_email= tender_detail.contact_email;
          this.address = tender_detail.address;
          this.auction_status = tender_detail.live_for_bid;
          this.start_date = tender_detail.start_time_dtp;
          this.end_date = tender_detail.end_time_dtp;
          this.bid_start_date = tender_detail.bid_start_time_dtp;
          this.bid_end_date = tender_detail.bid_end_time_dtp;
          this.status = tender_detail.status;
          let last_item = tender_detail.rough_details_arr.slice(-1);
          this.total_items = last_item[0]['id'];
          

        })
        .catch(_error => {
          
        });
    },
    
    clearOtherDate(){
      this.end_date = '';
      this.bid_start_date = '';
      this.bid_end_date = '';
    }
    ,clearBidDate(){
      this.bid_start_date = '';
      this.bid_end_date = '';
    },clearBidEndDate(){
      this.bid_end_date = '';
    },
    calculateTotal(){
      this.total_rough_carat = 0;
      this.total_expected_amount = 0;
      this.total_expected_amount_overall = 0;
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                  this.total_rough_carat = this.total_rough_carat + parseFloat(this.fields[i]['carats']);
              }

              if(this.fields[i]['expect_price'] != '' && !isNaN(this.fields[i]['expect_price'])){
                  this.total_expected_amount = this.total_expected_amount + parseFloat(this.fields[i]['expect_price']);
              }

              if(this.fields[i]['expect_price'] != '' && !isNaN(this.fields[i]['expect_price']) && this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                
                  this.total_expected_amount_overall = this.total_expected_amount_overall + (parseFloat(this.fields[i]['carats']) * parseFloat(this.fields[i]['expect_price']));
              }
            }
    }
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>