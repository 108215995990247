<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet View - {{packetdetails.barcode}}</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Packet Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#new-part-modal"
                class="btn btn-primary shadow-md mr-2"
                >Create Part</a
              >
            </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">

                <div class="">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Kapan No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">{{ packetdetails.kapan_no }}</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Kapan Type : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.kapan_name }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Lot No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.pack_no }}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Rough Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.pack_weight }} (Ct)</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Rough Weight (Available): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.pack_cr_weight }} (Ct)</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Rough Weight (Galaxy): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.pack_galaxy_weight }} (Ct)</label
                  >
                </div>

              </div>
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Planner Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.planner_name }}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Planning Date : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.planning_date}}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Checker Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.checker_name}}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Checking Date : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.checker_date}}</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Main Checker Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ packetdetails.main_checker_name}}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Checking Date (Final): &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">{{ packetdetails.main_checker_date}} </label
                  >
                </div>

              </div>
            </div>

        </div>
        </div>

        <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Lot & Sub Packet Details</h2>
            <button class="btn btn-primary p-1" v-print="'#multiple-barcode'" v-if="sub_packet_print_data.length > 0">
              Print Sub Packets
            </button>


          </div>

          <div class="p-5">

            <div class="col-span-12">
              <div
                id="tabulatorlotsubpacket"
                ref="tableRefLotSubPacket"
                class="mt-5 mb-5"
              ></div>
              </div>
        </div>

        <!-- END: Input -->
      </div>
      <div  class="hidden" v-if="sub_packet_print_data.length > 0">
        <div id="multiple-barcode"  class="absolute top-0 p-0 m-0">
          <table style="page-break-before: always;" class="p-0" border="collapse" v-for="(packet,index) in sub_packet_print_data" :key="index">
            <tr class="text-center">
              <th colspan="3" class="text-center">
                <vue-barcode class="w-full"
                :value="packet.barcode_no"
                :options="{ displayValue: true,height:30,fontSize:15,width:2 }"

              />
                </th>
            </tr>
            <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
              <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{ packet.packet_code }}</td>
            <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
              <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
            </tr>
          </table>
          </div>
      </div>

         <div class="intro-y box mt-10">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Packet Planning Details</h2>
           <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

         <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-manual-planning"
          class="btn btn-primary shadow-md mr-2"
          v-if="packetdetails.part_ready != 2"
          >Add Planning (Manually)</a
        >
            </div>
          </div>

          <div class="p-5">
            <h2 class="font-medium text-base mr-auto mt-5">Plan Details (Main Checker)</h2>
            <div class="col-span-12">
              <div
                id="tabulatormainchecker"
                ref="tableRefMainChecker"
                class="mt-5 mb-5"
              ></div>
              </div>
              <hr>
              <h2 class="font-medium text-base mr-auto mt-5">Plan Details (Checker)</h2>
              <div class="col-span-12">
                <div
                id="tabulatorchecker"
                ref="tableRefChecker"
                class="mt-5 mb-5"
              ></div>
              </div>
              <hr>
              <h2 class="font-medium text-base mr-auto mt-5">Plan Details (Planner)</h2>
              <div class="col-span-12">
                <div
                class="alert alert-danger show flex items-center mb-2"
                role="alert"
                v-if="packetdetails.planning_error != '' && packetdetails.planning_error != null "
              >
                <AlertOctagonIcon class="w-6 h-6 mr-2" />
                Your Planning file contains error.<br>
                {{packetdetails.planning_error}}
              </div>
                <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 mb-5"
              ></div>
              </div>
        </div>

        <!-- END: Input -->
      </div>
    </div>
    </div>

     <div id="new-pack-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Create New Packet</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12 xl:col-span-12">
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >New Packet Weight</label
                  >
                  <input class="form-control" v-model="newPartForm.part_weight">
                </div>
                <div class="form-check mt-5">
                  <input id="main_pc" class="form-check-input" type="checkbox" v-model="newPartForm.main_pcs" />
                  <label class="form-check-label" for="main_pc">Main Crown Piece</label>
              </div>

              </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormNewPacket">
              Save Details
            </button>

          </div>
        </div>
      </div>
    </div>

    <div id="new-part-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Create New Part</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12 xl:col-span-12">
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >New Part Weight</label
                  >
                  <input class="form-control" v-model="newMainPartForm.part_weight">
                </div>

              </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormNewPart">
              Save Details
            </button>

          </div>
        </div>
      </div>
    </div>

    <div id="new-manual-planning" ref="new-manual-planning" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Add Planning Details</h2>
          </div>
          <div class="modal-body">

            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-6 xl:col-span-3">

                <div class="mt-3 mb-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Select Planner</label
                  >
                  <TomSelect
                v-model="selected_planner"
                :options="{
                  placeholder: 'Select Planner'
                }"
                class="w-full"
              >
              <option value="">Select Planner</option>
              <option v-for="planner_item in planners_ar" :key="planner_item.id" :value="planner_item.id">{{planner_item.first_name}} {{planner_item.last_name}} - ({{planner_item.emp_code}})</option>
              </TomSelect>
                </div>
              </div>
              <div class="col-span-12">
               <div class="border">
                <div class="grid grid-cols-12 top-0 z-50 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">

              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>#</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Prt no</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Grd</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>RW</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>PW</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Clr</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Clarity</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Shape</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Cut</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Symmetry</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Yield(%)</p>
              </div>

               </div>
              <div class="border" v-for="plan in manualPlanning" :key="plan.id">

              <div class="grid grid-cols-12">
               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label>{{plan.id}}</label>
               </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
               <input v-model="plan.part_no" type="text"
                    class="form-control" placeholder="Part No" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
              <input v-model="plan.grading" type="text"
                    class="form-control" placeholder="Grd" onpaste="return false;" >
               </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="plan.rough_weight" type="text"
                    class="form-control" placeholder="RW" @keypress="$h.preventInvalidInput" onpaste="return false;" >
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
              <input v-model="plan.polish_weight" type="text"
                    class="form-control" placeholder="PW" @keypress="$h.preventInvalidInput" onpaste="return false;" >
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">

              <input v-model="plan.color" type="text"
                    class="form-control" placeholder="Clr">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="plan.clarity" type="text"
                    class="form-control" placeholder="Clarity"  >
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="plan.shape" type="text"
                    class="form-control" placeholder="Shape">
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="plan.cut" type="text"
                    class="form-control" placeholder="Cut">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="plan.symmetry" type="text"
                    class="form-control" placeholder="symmetry">
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="plan.yield_per" type="text"
                    class="form-control" placeholder="Yeild(%)" @keypress="$h.preventInvalidInput" onpaste="return false;" >
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <button type="button" class="text-theme-6 flex items-center" tabindex="-1"  @click="removeField(plan.id)">
                <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
              </button>
            </div>
            </div>
              </div>
              <button type="button" @click="AddFieldPlan" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                New Part Planning
              </button>
          </div>
        </div>
      </div>

      </div>

          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitManualPlanning">
              Save Details
            </button>
          </div>
        </div>
      </div>
  </div>
    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">

                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL,BASE_URL_PRINT } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
import datetime from 'vuejs-datetimepicker';
import VueBarcode from '@chenfengyuan/vue-barcode';
import feather from 'feather-icons'
import { useVuelidate } from '@vuelidate/core'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime,
    VueBarcode
  },
  props: {
    packet_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      rough_id:'',
      tabulator:[],
      sub_packet_print_data: [],
      packet_status:'',
      assorts:[],
      planners_ar:[],
      status: '1',
      total_items:1,
      issueForm:{
        operator_id:'',
        process_id:'',
      },

      packetdetails:{
        kapan_name:'',
        kapan_no:'',
        barcode:'',
        pack_no:'',
        pack_weight:'',
        pack_cr_weight:'',
        pack_galaxy_weight:'',
        pack_galaxy_price:0,
        planner_name:'',
        planning_date:'',
        checker_name:'',
        checker_date:'',
        part_ready:1,
        main_checker_name:'',
        main_checker_date:'',
        planning_error:'',
      },
      total_items:1,
      selected_planner:'',
      manualPlanning:[{
        id:1,
        part_no :'',
        grading:'',
        rough_weight:'',
        polish_weight: '',
        color:'',
        clarity:'',
        shape:'',
        cut:'',
        yield_per:'',
        value:'',
        symmetry:'',
        discount:''
      }],

      assortForm:{
        assort_type:'',
        assort_id:'',
        assort_name:'',
        total_pcs:'',
        weight:'',
        weight_per:'',
        result_weight:'',
        result_per:''
      },
      newPartForm : {
        part_weight:0,
        main_pack_id:'',
        plan_id:'',
        part_no:'',
        main_pcs:0
      },
      newMainPartForm:{
        part_weight:0,
        main_pack_id:'',
      },
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },

  computed: {

  },
  mounted(){

  this.getPacketDetails(this.packet_id);
  this.plannerPrepare();
  this.lotandSubPackPrepare();
  this.getKarigar();
  this.checkerPrepare();
  this.mainCheckerPrepare();
  this.getSubPacketDetails(this.packet_id)
  },

    methods: {
      async getKarigar() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_account_planner",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.planners_ar = data.items;
        })
        .catch(_error => {

        });
    },

     AddFieldPlan() {
      this.total_items++;
      this.manualPlanning.push(
        {
        id:this.total_items,
        part_no :'',
        grading:'',
        rough_weight:'',
        polish_weight: '',
        color:'',
        clarity:'',
        shape:'',
        cut:'',
        yield_per:'',
        value:'',
        symmetry:'',
        discount:''
      }
      );
      console.log(this.total_items);

    },
    removeField(id) {
        this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.manualPlanning.length;
              while(i--){
              if(this.manualPlanning[i]['id'] == id ){
                  this.manualPlanning.splice(i,1);
              }
            }
          }
      });
    },

    printPackage(id){

    let url = BASE_URL_PRINT + '/print-label/' + id;
    window.open(url, '_blank');

    },
    async getPacketDetails(packet_id) {
      let _this = this;
      const params = {
        packet_id: packet_id
      };
      let promise = axios({
        url: "api/get_packet_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          console.log(data.packet_details);

          let packet_detail = data.packet_details;

          this.packetdetails.kapan_no = packet_detail.kapan_code;
          this.packetdetails.part_ready = packet_detail.part_ready;
          this.packetdetails.kapan_name = packet_detail.kapan_name;
          this.packetdetails.pack_no = packet_detail.packet_code;
          this.packetdetails.barcode = packet_detail.barcode_no;
          this.packetdetails.pack_weight = packet_detail.weight;
          this.packetdetails.pack_cr_weight = packet_detail.cr_weight;
          this.packetdetails.pack_galaxy_weight = packet_detail.galaxy_rought_weight;
          this.packetdetails.pack_galaxy_price = packet_detail.galaxy_rought_price;
          this.packetdetails.main_checker_date = packet_detail.mc_planning_last_mod;
          this.packetdetails.checker_date = packet_detail.c_planning_last_mod;
          this.packetdetails.planning_date = packet_detail.planning_last_mod;
          this.packetdetails.planning_error = packet_detail.planning_error;


          if(packet_detail.p_first_name){
            this.packetdetails.planner_name = packet_detail.p_first_name + " ";
          }if(packet_detail.p_last_name){
            this.packetdetails.planner_name += packet_detail.p_last_name + " ";
          }if(packet_detail.p_emp_code){
            this.packetdetails.planner_name += "(" +packet_detail.p_emp_code + ")";
          }

          this.packetdetails.checker_name = "";
          if(packet_detail.c_first_name){
            this.packetdetails.checker_name = packet_detail.c_first_name + " ";
          }if(packet_detail.c_last_name){
            this.packetdetails.checker_name += packet_detail.c_last_name + " ";
          }if(packet_detail.c_emp_code){
            this.packetdetails.checker_name += "(" +packet_detail.c_emp_code + ")";
          }

          this.packetdetails.main_checker_name = "";
          if(packet_detail.mc_first_name){
            this.packetdetails.main_checker_name = packet_detail.mc_first_name + " ";
          }if(packet_detail.c_last_name){
            this.packetdetails.main_checker_name += packet_detail.mc_last_name + " ";
          }if(packet_detail.c_emp_code){
            this.packetdetails.main_checker_name += "(" +packet_detail.mc_emp_code + ")";
          }

          this.packetdetails.planning_date = packet_detail.updated_at;


        })
        .catch(_error => {

        });
    },

    plannerPrepare(){
      let _this = this;
      this.tabulator = new Tabulator(this.$refs['tableRef'], {
        ajaxURL: BASE_URL + '/api/list_rough_planner_plan',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id:this.packet_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'MW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
             formatter(cell) {
              return _this.$h.myFloat(cell.getData().rough_weight);
            }

          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
             formatter(cell) {
              return _this.$h.myFloat(cell.getData().polish_weight);
            }
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true,
             formatter(cell) {
              return _this.$h.myFloat(cell.getData().yield_per);
            }
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().value);
            }
          },
          {
            title: 'Planner',
            field: 'planner_name',
            minWidth: 150,
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name;
            }
          }

        ],
        renderComplete() {

        }
      })
    },

    getplanDetails(obj){
      console.log(obj);
      if(obj != null){
        this.newPartForm.part_weight = obj.rough_weight;
        this.newPartForm.main_pack_id = obj.rough_packet;
        this.newPartForm.plan_id = obj.id;
        this.newPartForm.part_no = obj.part_no;

        if(obj.main_piece == 1){
          this.newPartForm.main_pcs = true;
        }
      }
      cash('#new-pack-modal').modal('show');
    },

    submitFormNewPacket(){
      if(this.newPartForm.part_weight == "" || this.newPartForm.part_weight <= 0 ){
        this.$swal("Error!", "New packet weight is not valid!", "error");
        return;
      }
      if(parseFloat(this.newPartForm.part_weight) > parseFloat(this.packetdetails.pack_weight)){
        this.$swal("Error!", "New packet weight can not be greater than main lot weight!", "error");
        return;
      }
      let main_pc = 1;
      if(this.newPartForm.main_pcs == true){
        main_pc = 2;
      }else{
        main_pc = 1;
      }
      const data = {
        main_pack_id: this.newPartForm.main_pack_id,
        part_weight: this.newPartForm.part_weight,
        plan_id: this.newPartForm.plan_id,
        part_no: this.newPartForm.part_no,
        main_pcs: main_pc
      };

      console.log(data);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to create sub packet from lot!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/create_lot_sub_packet",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              this.$swal("Success!", "New Packet Created!", "success");
              this.$router.go();
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          //this.$swal("Success!", "Item Returned to Manager.", "success");
          //this.$router.go();
        }
      });

    },

    submitFormNewPart(){
      if(this.newMainPartForm.part_weight == "" || this.newMainPartForm.part_weight <= 0 ){
        this.$swal("Error!", "New packet weight is not valid!", "error");
        return;
      }
      if(parseFloat(this.newMainPartForm.part_weight) > parseFloat(this.packetdetails.pack_cr_weight)){
        this.$swal("Error!", "New packet weight can not be greater than main lot available weight!", "error");
        return;
      }

      const data = {
        main_pack_id: this.packet_id,
        part_weight: this.newMainPartForm.part_weight,
      };

      this.$swal({
        title: "Are you sure?",
        text: "You are about to create sub part from lot!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/create_lot_sub_part",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              this.$swal("Success!", "New Part Created!", "success");
              this.$router.go();
            })
            .catch(_error => {
              //this.$router.go();
              this.$swal("Error!", "New Part Not Created! Packet Might be in process!", "error");
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {

        }
      });

    },

    lotandSubPackPrepare(){
      let _this = this;
      this.tabulator = new Tabulator(this.$refs['tableRefLotSubPacket'], {
        ajaxURL: BASE_URL + '/api/list_packet_subpacket',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        dataTree:true,
        dataTreeStartExpanded:true,
        ajaxParams:{packet_id:this.packet_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        tooltipsHeader:true,
        pagination: 'remote',
        paginationSize: 40,
        layout: 'fitColumns',
        responsiveLayout: false,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Packet Name',
            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Lot Weight',
            field: 'weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Cr Weight',
            field: 'cr_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Shape',
            field: 'shape_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Color',
            field: 'color_name',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Purity',
            field: 'purity_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: '',
            field: 'actions',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              const a = cash(`<div class="flex lg:justify-center items-center">
                <button class="btn btn-primary btn-sm flex items-center mr-3">
                      Print Label
                    </button>
              </div>`)
              cash(a).on('click', function() {
                _this.printPackage(cell.getData().id);
              })
              return a[0]

            }
          },


        ],
        renderComplete() {

        }
      })
    },

    checkerPrepare(){
      let _this = this;
      this.tabulator = new Tabulator(this.$refs['tableRefChecker'], {
        ajaxURL: BASE_URL + '/api/list_rough_checker_plan',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id:this.packet_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        tooltipsHeader:true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }

          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'MW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().rough_weight);
            }
          },

          {
            title: 'PW',
            field: 'polish_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().polish_weight);
            }
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().yield_per);
            }
          },
          {
            title: 'Value',
            field: 'value',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().value);
            }
          },
          {
            title: 'Checker',
            field: 'planner_name',
            minWidth: 120,
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name;
            }
          },
          {
            title: '',
            field: 'actions',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().sub_packet_status != 2){
                const a = cash(`<div class="flex lg:justify-center items-center">
                <button class="btn btn-success btn-sm flex items-center mr-3">
                      New Packet
                    </button>
              </div>`)
              cash(a).on('click', function() {
                _this.getplanDetails(cell.getData());
              })
              return a[0]

              }

            }
          },

        ],
        renderComplete() {

        }
      })
    },

    mainCheckerPrepare(){
      let _this = this;
      this.tabulator = new Tabulator(this.$refs['tableRefMainChecker'], {
        ajaxURL: BASE_URL + '/api/list_rough_main_checker_plan',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id:this.packet_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        headerSort: false,
        tooltipsHeader:true,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',

            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }

          },
          {
            title: 'Grd',
            responsive: 0,
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'MW',
            responsive: 0,
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().rough_weight);
            }


          },

          {
            title: 'PW',
            field: 'polish_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
             formatter(cell) {
              return _this.$h.myFloat(cell.getData().polish_weight);
            }
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
             formatter(cell) {
              return _this.$h.myFloat(cell.getData().yield_per);
            }
          },
          {
            title: 'Value',
            field: 'value',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
             formatter(cell) {
              return _this.$h.myFloat(cell.getData().value);
            }
          },
          {
            title: 'Main Checker',
            field: 'planner_name',
            minWidth: 120,
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name;
            }
          },
          {
            title: '',
            field: 'actions',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().sub_packet_status != 2){
                const a = cash(`<div class="flex lg:justify-center items-center">
                <button class="btn btn-success btn-sm flex items-center mr-3">
                      New Packet
                    </button>
              </div>`)
              cash(a).on('click', function() {
                _this.getplanDetails(cell.getData());
              })
              return a[0]

              }

            }
          },

        ],
        renderComplete() {

        }
      })
    },

    submitManualPlanning(){

      const data = {
        packet_id: this.packet_id,
        planner_id: this.selected_planner,
        planning_details: this.manualPlanning,
      };

      console.log(data);


      this.$swal({
        title: "Are you sure?",
        text: "You are about to enter planning manually!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/update_manual_planning",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              this.$swal("Success!", "Planning Updated.", "success");
              this.$router.go();
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          //this.$swal("Success!", "Item Returned to Manager.", "success");
          //this.$router.go();
        }
      });
    },
    getSubPacketDetails(id){
      const data = {
        id: id,
      };

      let promise = axios({
        url: "api/get_sub_packet_details",
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.sub_packet_print_data = data.items
        })
        .catch(_error => {
          this.sub_packet_print_data = []
        });
    }

  },

})
</script>
