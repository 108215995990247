<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Blocking Summary Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
                multiple
              >
              <option value="">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                multiple
                @change="kapanChange"
              >
              <option value="">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Packets</option>
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div v-if="screenData.report_type == '2' && screenData.issue_pending">
            <label for="update-profile-form-6" class="form-label py-1"
              >Status Type</label
            >
            <TomSelect
              v-model="screenData.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Status'
                  }
                }
              }"
              class="w-full"
              :disabled="screenData.report_type == '2' && screenData.issue_pending"
            >
            <option value="0">All</option>
            <option value="1"><span v-if="screenData.issue_pending == true">Pending</span><span v-else>Issue</span></option>
            <option value="2">Received</option>
            </TomSelect>
          </div>
          <div v-else>
            <label for="update-profile-form-6" class="form-label py-1"
                >Status Type</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">All</option>
              <option value="1"><span v-if="screenData.issue_pending == true">Pending</span><span v-else>Issue</span></option>
              <option value="2">Received</option>
              </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Report Type</label
              >
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Kapan Wise</option>
              <option value="2">Lot Wise</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Karigar</label
              >
              <TomSelect
                v-model="screenData.karigar_id"
                :options="{
                  placeholder: 'Select Karigar',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Karigar'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Karigar</option>
              <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{karigar.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="screenData.issue_pending"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Shape</label
              >
              <TomSelect
                v-model="screenData.shape_group"
                :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">All</option>
              <option v-for="shape in pagedata.shapes" :key="shape.id" :value="shape.shape_group">{{shape.shape_group}}</option>
              </TomSelect>
            </div>
          </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5" :disabled="(screenData.report_type == '1' && screenData.issue_pending == true && (screenData.series_id.length == 0 && screenData.kapan_id.length == 0) && (screenData.status == '0' || screenData.status == '2'))"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type == 1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>
        <div class="col-span-2 xl:col-span-1" v-if="pagedata.report_type">
          <div class="mt-10 flex">

            <div class="flex mt-5 sm:mt-0">

              <div class="dropdown w-1/2 sm:w-auto">
                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <a id="tabulator-export-xlsx" href="javascript:;" :aria-disabled="pagedata.excel_loading == true" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                          </a>
                      </div>
                  </div>
              </div>
              </div>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div v-if="pagedata.report_type == 2">
          <div class="overflow-x-auto scrollbar-hidden">
            <div
              id="tabulator"
              ref="tableRef"
              class="mt-5"
            ></div>
          </div>
        </div>
        <div v-if="pagedata.report_type == 1">
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Karigar Name</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2">Kapan Name</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 1" colspan="3"><div v-if="pagedata.issue_pending == false">Issue</div><div v-else>Pending</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2" colspan="8">Receive</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r-2" v-if="pagedata.status == 0 || pagedata.status == 2" colspan="3">Failed Pieces</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="pagedata.status == 0 || pagedata.status == 2">Majuri</th> -->
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0" colspan="3">Total</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish Weight</th> -->
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == false">Issue Pieces</div><div v-else>Pnd. Pieces</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == false">Issue Wt</div><div v-else>Pnd. Wt</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == false">Issue Polish Wt</div><div v-else>Pnd. Polish Wt</div></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Pieces</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Ghat Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Polish Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Rec. Polish Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Different Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Different (%)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Total Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Pieces</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.status == 0 || pagedata.status == 2">Failed Polish Wt</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="pagedata.status == 0 || pagedata.status == 2">Majuri</th> -->
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Pieces</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">Total Polish Wt</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish Weight</th> -->
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_pieces ? pagedata.total.total_pending_pieces : '0' }}</div><div v-else>{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_weight ? $h.myFloat(pagedata.total.total_pending_weight) : '0' }} Ct</div><div v-else>{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ pagedata.total.total_pending_polish_weight ? $h.myFloat(pagedata.total.total_pending_polish_weight) : '0' }} Ct</div><div v-else>{{ pagedata.total.total_issue_polish_weight ? $h.myFloat(pagedata.total.total_issue_polish_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_pieces ? pagedata.total.total_received_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_weight ? $h.myFloat(pagedata.total.total_received_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_ghat ? $h.myFloat(pagedata.total.total_received_ghat) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_polish_weight ? $h.myFloat(pagedata.total.total_received_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_new_polish_weight ? $h.myFloat(pagedata.total.total_received_new_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':pagedata.total.total_received_polish_ghat < 0,'bg-green':pagedata.total.total_received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_polish_ghat ? $h.myFloat(pagedata.total.total_received_polish_ghat) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':pagedata.total.total_received_polish_ghat < 0,'bg-green':pagedata.total.total_received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_polish_ghat ? $h.myFloat(pagedata.total.total_received_polish_ghat * 100 / pagedata.total.total_received_polish_weight) + ' %' : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_pieces ? pagedata.total.total_failed_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_weight ? $h.myFloat(pagedata.total.total_failed_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_failed_polish_weight ? $h.myFloat(pagedata.total.total_failed_polish_weight) : '0' }} Ct</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.total.total_received_pieces_majuri ? parseFloat(pagedata.total.total_received_pieces_majuri).toFixed(0) : '0' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_pieces ? pagedata.total.total_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_weight ? $h.myFloat(pagedata.total.total_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.total.total_polish_weight ? $h.myFloat(pagedata.total.total_polish_weight) : '0' }} Ct</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_pwt ? $h.myFloat(item.total_pwt) : '0' }} Ct</td> -->
                    </tr>
                  </thead>
                  <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                    <tr v-for="(single_item,ind) in item.packet_details" :key="ind">
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? item.first_name + ' ' + item.last_name : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.kapan_code ? single_item.kapan_code : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ single_item.pending_packets ? single_item.pending_packets : '0' }}</div><div v-else>{{ single_item.total_issue_packets ? single_item.total_issue_packets : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ single_item.pending_weight ? $h.myFloat(single_item.pending_weight) : '0' }} Ct</div><div v-else>{{ single_item.total_issue_weight ? $h.myFloat(single_item.total_issue_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ single_item.pending_polish_weight ? $h.myFloat(single_item.pending_polish_weight) : '0' }} Ct</div><div v-else>{{ single_item.total_issue_polish_weight ? $h.myFloat(single_item.total_issue_polish_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_packets ? single_item.received_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_weight ? $h.myFloat(single_item.received_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_ghat ? $h.myFloat(single_item.received_ghat) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_polish_weight ? $h.myFloat(single_item.received_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_new_polish_weight ? $h.myFloat(single_item.received_new_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':single_item.received_polish_ghat < 0,'bg-green':single_item.received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_polish_ghat ? $h.myFloat(single_item.received_polish_ghat) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':single_item.received_polish_ghat < 0,'bg-green':single_item.received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_polish_ghat ? $h.myFloat(single_item.received_polish_ghat * 100 / single_item.received_polish_weight) + ' %' : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_packets ? single_item.failed_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_weight ? $h.myFloat(single_item.failed_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.failed_polish_weight ? $h.myFloat(single_item.failed_polish_weight) : '0' }} Ct</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.received_packets_majuri ? parseFloat(single_item.received_packets_majuri).toFixed(0) : '0' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.packets ? single_item.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '0' }} Ct</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.ex_weight ? $h.myFloat(single_item.ex_weight) : '0' }} Ct</td> -->
                    </tr>
                    <tr class="border-b" v-if="!item.packet_details">
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ index + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind == 0 ? item.first_name + ' ' + item.last_name : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">-</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">0 Ct</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">0</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0 Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">0 Ct</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ item.total_pending_pieces ? item.total_pending_pieces : '0' }}</div><div v-else>{{ item.total_issue_packets ? item.total_issue_packets : '0' }}</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ item.total_pending_weight ? $h.myFloat(item.total_pending_weight) : '0' }} Ct</div><div v-else>{{ item.total_issue_weight ? $h.myFloat(item.total_issue_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"><div v-if="pagedata.issue_pending == true">{{ item.pending_polish_weight ? $h.myFloat(item.pending_polish_weight) : '0' }} Ct</div><div v-else>{{ item.total_issue_polish_weight ? $h.myFloat(item.total_issue_polish_weight) : '0' }} Ct</div></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_pieces ? item.total_received_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_weight ? $h.myFloat(item.total_received_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_ghat ? $h.myFloat(item.total_received_ghat) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.received_polish_weight ? $h.myFloat(item.received_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.received_new_polish_weight ? $h.myFloat(item.received_new_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':item.total_received_polish_ghat < 0,'bg-green':item.total_received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_polish_ghat ? $h.myFloat(item.total_received_polish_ghat) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':item.total_received_polish_ghat < 0,'bg-green':item.total_received_polish_ghat > 0}" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_polish_ghat ? $h.myFloat(item.total_received_polish_ghat * 100 / item.received_polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_majuri ? $h.myFloat(item.total_majuri) : '0' }} </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_failed_pieces ? item.total_failed_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_failed_weight ? $h.myFloat(item.total_failed_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.failed_polish_weight ? $h.myFloat(item.failed_polish_weight) : '0' }} Ct</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center" v-if="pagedata.status == 0 || pagedata.status == 2">{{ item.total_received_pieces_majuri ? parseFloat(item.total_received_pieces_majuri).toFixed(0) : '0' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.total_pieces ? item.total_pieces : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.total_weight ? $h.myFloat(item.total_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ item.polish_weight ? $h.myFloat(item.polish_weight) : '0' }} Ct</td>
                    </tr>

                  </tbody>
                  <tbody v-if="pagedata.direct_polish_details.length > 0">
                    <tr v-for="(single_item,ind) in pagedata.direct_polish_details" :key="ind">
                      <td class="py-2 text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? 'Direct Polish' : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.kapan_code ? single_item.kapan_code : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.packets ? single_item.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.packets ? single_item.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '0' }} Ct</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Total</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.direct_polish_details_total.packets ? pagedata.direct_polish_details_total.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.direct_polish_details_total.issue_weight ? $h.myFloat(pagedata.direct_polish_details_total.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.direct_polish_details_total.polish_weight ? $h.myFloat(pagedata.direct_polish_details_total.polish_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.direct_polish_details_total.packets ? pagedata.direct_polish_details_total.packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.direct_polish_details_total.issue_weight ? $h.myFloat(pagedata.direct_polish_details_total.issue_weight) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.direct_polish_details_total.polish_weight ? $h.myFloat(pagedata.direct_polish_details_total.polish_weight) : '0' }} Ct</td>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">Total</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 1"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.direct_polish_details_total.packets ? (pagedata.direct_polish_details_total.packets + pagedata.total.total_received_pieces) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.direct_polish_details_total.issue_weight ? $h.myFloat($h.myFloat(pagedata.direct_polish_details_total.issue_weight) + (pagedata.total.total_received_weight ? $h.myFloat(pagedata.total.total_received_weight) : 0)) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2">{{ pagedata.direct_polish_details_total.polish_weight ? $h.myFloat($h.myFloat(pagedata.direct_polish_details_total.polish_weight) + (pagedata.total.total_received_new_polish_weight ? $h.myFloat(pagedata.total.total_received_new_polish_weight) : 0)) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.status == 0 || pagedata.status == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.direct_polish_details_total.packets ? (pagedata.direct_polish_details_total.packets + pagedata.total.total_pieces) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.direct_polish_details_total.issue_weight ? $h.myFloat($h.myFloat(pagedata.direct_polish_details_total.issue_weight) + (pagedata.total.total_weight ? $h.myFloat(pagedata.total.total_weight) : 0)) : '0' }} Ct</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="pagedata.issue_pending == true && pagedata.status == 0">{{ pagedata.direct_polish_details_total.polish_weight ? $h.myFloat($h.myFloat(pagedata.direct_polish_details_total.polish_weight) + (pagedata.total.total_polish_weight ? $h.myFloat(pagedata.total.total_polish_weight) : 0)) : '0' }} Ct</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import { helper as $h} from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      stones: [],
      shapes: [],
      employees: [],
      report_details : [],
      direct_polish_details : [],
      direct_polish_details_total : [],
      total : [],
      report_type: 0,
      loading: false,
      status:'1',
      issue_pending: true,
      report_type: ''

    })
    const screenData = reactive({
      series_id:[],
      kapan_id:[],
      stone_id:'0',
      packets:[],
      process_id:31,
      karigar_id:'0',
      shape_group:'0',
      status:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true,
      report_type: '1'

    })
    const printData = reactive({
      series_id:[],
      kapan_id:[],
      stone_id:'0',
      packets:[],
      process_id:31,
      karigar_id:'0',
      shape_group:'0',
      status:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true,
      report_type: '1'

    })

    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }

    const generateReport = () => {

      pagedata.status = screenData.status,
      pagedata.issue_pending = screenData.issue_pending,
      pagedata.report_type = screenData.report_type

      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.stone_id = screenData.stone_id
      printData.packets = screenData.packets
      printData.process_id = screenData.process_id
      printData.karigar_id = screenData.karigar_id
      printData.shape_group = screenData.shape_group
      printData.status = screenData.status
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending
      printData.report_type = screenData.report_type

      if(screenData.report_type == '1') {
        pagedata.loading  = true


          let params = {
            series_id : printData.series_id.length != 0 ? printData.series_id : '',
            kapan_id : printData.kapan_id.length != 0 ? printData.kapan_id : '',
            packets : printData.packets.length != 0 ? printData.packets : '',
            process_id : printData.process_id != '0' ? window.btoa(printData.process_id) : '',
            karigar_id : printData.karigar_id != '0' ? window.btoa(printData.karigar_id) : '',
            stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
            shape_group : printData.shape_group != '0' ? window.btoa(printData.shape_group) : '',
            status : window.btoa(printData.status),
            issue_date : window.btoa(printData.issue_date),
            receive_date : window.btoa(printData.receive_date),
            issue_pending : printData.issue_pending,
            report_type : printData.report_type
          }
          let promise = axios({
            url: "api/get_blocking_summary_report",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              pagedata.report_details = data.items
              pagedata.direct_polish_details = data.direct_polish_details
              pagedata.direct_polish_details_total = data.direct_polish_details_total
              pagedata.total = data.total
              pagedata.loading  = false
            })
            .catch(_error => {
              pagedata.loading  = false
            });
      }
      if(screenData.report_type == '2') {

        let params = {
          series_id : screenData.series_id.length != 0 ? screenData.series_id : '',
            kapan_id : screenData.kapan_id.length != 0 ? screenData.kapan_id : '',
            packets : screenData.packets.length != 0 ? screenData.packets : '',
              process_id : screenData.process_id != '0' ? screenData.process_id : '',
              karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
              stone_id : screenData.stone_id != '0' ? screenData.stone_id : '',
              shape_group : screenData.shape_group != '0' ? screenData.shape_group : '',
              status : screenData.status,
              issue_date : screenData.issue_date,
              receive_date : screenData.receive_date,
              issue_pending : screenData.issue_pending,
              report_type : screenData.report_type
            }
        pagedata.report_type = 2
        setTimeout(() => {
          if(printData.issue_pending == true) {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + '/api/get_blocking_summary_lot_report',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams:params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: false,
            tooltipsHeader:true,
            headerSort: false,
            placeholder: 'No matching records found',
            columns: [
              // For HTML table
              // For HTML table
              {
                  title: 'Packet No',
                  field: 'packet_code',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  cellClick:function(e, cell){
                    //e - the click event object
                    //cell - cell component
                    console.log(cell.getData().id);
                    // router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                  },
                  formatter(cell) {
                    if(cell.getData().status == 2){
                    return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().packet_code
                    }else{
                      return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().packet_code
                    }
                  }
                },
                {
                  title: 'Karigar',
                  field: 'karigan_name',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  formatter(cell) {
                      return cell.getData().karigar_first_name + '<br>' + cell.getData().karigar_last_name
                  }
                },

                {
                  title: 'Weight',
                  field: 'mk_weight',
                  vertAlign: 'middle',
                  print: false,
                  download: true,
                },
                {
                  title: 'Iss. Weight',
                  field: 'issue_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Ex. Weight',
                  field: 'ex_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Shape',
                  field: 'shape_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Color',
                  field: 'color_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },{
                  title: 'Purity ',
                  field: 'purity_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Status',
                  field: 'status',
                  vertAlign: 'middle',
                  print: false,
                  download: false,
                  formatter(cell) {

                    if(cell.getData().status == 2){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                    }else{
                      return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                    }
                  }
                },

            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
          } else {

          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + '/api/get_blocking_summary_lot_report',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams:params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: false,
            tooltipsHeader:true,
            headerSort: false,
            placeholder: 'No matching records found',
            columns: [
              // For HTML table
              // For HTML table
              {
                  title: 'Packet No',
                  field: 'packet_code',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  cellClick:function(e, cell){
                    //e - the click event object
                    //cell - cell component
                    console.log(cell.getData().id);
                    // router.push("/purchase/rough-view/packet-detail/" + window.btoa(cell.getData().id));

                  },
                  formatter(cell) {
                    if(cell.getData().status == 2){
                    return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().packet_code
                    }else{
                      return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().packet_code
                    }
                  }
                },
                {
                  title: 'Karigar',
                  field: 'karigan_name',
                  vertAlign: 'middle',
                  print: false,
                  minWidth:160,
                  download: true,
                  formatter(cell) {
                      return cell.getData().karigar_first_name + '<br>' + cell.getData().karigar_last_name
                  }
                },

                {
                  title: 'Weight',
                  field: 'mk_weight',
                  vertAlign: 'middle',
                  print: false,
                  download: true,
                },
                {
                  title: 'Iss. Weight',
                  field: 'issue_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Rec. Weight',
                  field: 'received_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Lose Weight',
                  field: 'total_lose',
                  print: false,
                  download: true,
                  formatter(cell) {
                      if(cell.getData().total_lose) {
                        return cell.getData().total_lose
                      } else {
                        return '-'
                      }
                  }
                },
                {
                  title: 'Ex. Weight',
                  field: 'ex_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'New Ex. Wt',
                  field: 'new_ex_weight',
                  print: false,
                  download: true
                },
                {
                    title: 'Diffrence',
                    field: 'defference',
                    print: false,
                    download: true,
                    formatter(cell) {
                      if(cell.getData().new_ex_weight) {
                        if(cell.getData().new_ex_weight > cell.getData().ex_weight){
                        return `<div class="font-bold text-theme-9 mr-1">`+ $h.myFloat(cell.getData().new_ex_weight - cell.getData().ex_weight) + `</div>`
                        }else if(cell.getData().new_ex_weight < cell.getData().ex_weight){
                          return `<div class="font-bold text-theme-6 mr-1">`+ $h.myFloat(cell.getData().ex_weight - cell.getData().new_ex_weight) + `</div>`
                        } else {
                          return `<div class="font-bold mr-1">`+ $h.myFloat(cell.getData().ex_weight - cell.getData().new_ex_weight) + `</div>`
                        }
                      } else {
                        return `<div class="font-bold mr-1">`+ '-' + `</div>`
                      }
                  }
                },
                {
                  title: 'Shape',
                  field: 'shape_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Color',
                  field: 'color_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Purity ',
                  field: 'purity_name',
                  vertAlign: 'middle',
                  print: false,
                  download: true
                },
                {
                  title: 'Majuri ',
                  field: 'total_majuri',
                  vertAlign: 'middle',
                  print: false,
                  download: true,
                  formatter(cell) {
                      if(cell.getData().total_majuri) {
                        return cell.getData().total_majuri
                      } else {
                        return '-'
                      }
                  }
                },
                {
                  title: 'Status',
                  field: 'status',
                  vertAlign: 'middle',
                  print: false,
                  download: false,
                  formatter(cell) {

                    if(cell.getData().status == 2){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                    }else{
                      return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                    }
                  }
                },

            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
          }
        }, 40);
      }
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_kapan_multiple",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getPackets = () => {
      if(screenData.kapan_id.length > 0) {
        let params = {
          series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
          kapan_id: (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
          process_id : screenData.process_id,
        }
        let promise = axios({
          url: "api/get_process_packet_packets_multiple",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            pagedata.packets = data.items

          })
          .catch(_error => {

          });
        }  else {
        pagedata.packets = []
        screenData.packets = []
      }
    }
    const getEmployees = () => {
      let params = {
        process: screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = []
      screenData.kapan_id = []
      screenData.packets = []
      screenData.karigar_id = '0'
      screenData.process_id = 31
      screenData.status ='1'
      screenData.issue_pending = true
      screenData.stone_id = '0'
      screenData.shape_group = '0'
      screenData.report_type = '1'

      pagedata.report_type = 0
      pagedata.status = 1
      pagedata.issue_pending = false
      if(pagedata.report_type == 1) {
        pagedata.report_details = []
        pagedata.total = []
      }

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      let filename = "blocking-summary.xlsx";

      if(printData.report_type == 2) {

        filename = "blocking-lot-summary.xlsx";
        let params = {
              series_id : printData.series_id.length != 0 ? printData.series_id : '',
              kapan_id : printData.kapan_id.length != 0 ? printData.kapan_id : '',
              packets : printData.packets.length != 0 ? printData.packets : '',
              process_id : printData.process_id != '0' ? window.btoa(printData.process_id) : '',
              karigar_id : printData.karigar_id != '0' ? window.btoa(printData.karigar_id) : '',
              stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
              shape_group : printData.shape_group != '0' ? window.btoa(printData.shape_group) : '',
              status : window.btoa(printData.status),
              issue_date : window.btoa(printData.issue_date),
              receive_date : window.btoa(printData.receive_date),
              issue_pending : printData.issue_pending,
              report_type : printData.report_type
            }
        let promise = axios({
            url: "/api/download_blocking_summary_report",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            },
            responseType: "blob",
        });

        return promise
            .then((result) => result.data)
            .then((data) => {
                FileDownload(data, filename);
            })
            .catch((_error) => {
                return [];
            });
          } else {
            let report_type = 'blocking-summary-report'

        let params = {
            report_type: report_type,
            filters: printData,
            items: {
                'items': pagedata.report_details,
                'total': pagedata.total
            }
        }
        let promise = axios({
            url: "api/add_report_details",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
          .then(result => result.data)
          .then(data => {

              const report_id = data.report_id

              export_exls(report_id)

          })
          .catch(_error => {

        });
          }
        }

    function export_exls(id) {
      let filename = "Blocking-Summary.xlsx";
      const params = {
          id: window.btoa(id)
      };
      let promise = axios({
          url: "/api/download_blocking_summary_report_all",
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
            FileDownload(data, filename);
        })
        .catch((_error) => {
            return [];
        });
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      getPackets()
    }
    function seriesChange() {
      getParameters()
      screenData.kapan_id = []
      screenData.packets = []
    }
    function kapanChange() {
      getPackets()
      screenData.packets = []
    }
    function processChange() {
      getSeries()
      getKapans()
      getPackets()
      getEmployees()
      screenData.series_id = []
      screenData.kapan_id = []
      screenData.karigar_id = '0'
      screenData.packets = []
    }
    const getShapes = () => {
      let params = {
        // process: pagedata.process,
        // party_id: window.btoa(screenData.party_id),
      }
      let promise = axios({
        url: "api/dp_shape_group",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.shapes = data.items

        })
        .catch(_error => {

        });
    }
    function printReportDetails(){
      let report_type = ''
      if(printData.report_type == '2') {
        report_type = 'blocking-summary-lot-report'
        let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.report_details,'total': pagedata.total,"direct_polish_details": pagedata.direct_polish_details,"direct_polish_details_total": pagedata.direct_polish_details_total }
        }
        let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });
      } else {
        report_type = 'blocking-summary-report'
        let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.report_details,'total': pagedata.total,"direct_polish_details": pagedata.direct_polish_details,"direct_polish_details_total": pagedata.direct_polish_details_total }
        }
        let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });
      }

      // add_report_details
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      getEmployees()
      getDPStoneType()
      getShapes()
      getParameters()
      reInitOnResizeWindow()

    })

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      processChange,
      generateReport,
      printReportDetails
    }
  }
})
</script>
