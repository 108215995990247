<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
      <h2 class="text-lg font-medium mr-auto">LAB Stock (Received Results)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="w-1/2 sm:w-auto mx-1">
          <button class="btn btn-outline-secondary bg-white w-full sm:w-auto" @click="exportCurrentStocks">
            <FileTextIcon class="w-4 h-4 mr-2" /> Export
            <ChevronUpIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
        </div>
        <div class="w-1/2 sm:w-auto mx-1">
          <button class="btn btn-primary w-full sm:w-auto" @click="inputFileClick"
            :disabled="page_data.excel_loading == true">
            <FileTextIcon class="w-4 h-4 mr-2" /> Update Rates(Excel)
            <LoadingIcon v-if="page_data.excel_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>


        </div>
        <div class="w-1/2 sm:w-auto mx-1">

          <button
           class="btn btn-primary w-full sm:w-auto mx-2" @click="takeMultiReturn" v-if="page_data.form_status == true">
           Take Return
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!page_data.return_progress"/>
          </button>
        </div>

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box px-5 py-3 mt-3">
      <div v-if="page_data.excel_loading == true">

      </div>
      <div v-else>

        <div class="overflow-x-auto scrollbar-hidden" v-if="page_data.form_status == false">
          <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
        <div v-if="page_data.form_status == true">

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                  <label for="update-profile-form-6" class="form-label">{{ page_data.total_scan_pack }} </label>
                </div>
              </div>
              <div class="col-span-4 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Pcs Weight. :&nbsp;</label>
                  <label for="update-profile-form-6" class="form-label">{{ $h.myFloat(page_data.total_scan_pack_wt) }}
                  </label>
                </div>
              </div>
              <div class="col-span-4 xl:col-span-4 text-right">
                <div>
                  <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="resetForm">
                    Reset Form
                  </button>
                  <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect"
                    :disabled="page_data.delete_row">
                    Remove Selected Row
                  </button>
                </div>
              </div>
              <div class="col-span-12 ">
                <ag-grid-vue class="ag-theme-alpine" style="width: 100%; height: 500px;" :columnDefs="columnDefs.value"
                  :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple"
                  :rowMultiSelectWithClick="true" animateRows="true" @grid-ready="onGridReady"
                  @selection-changed="onGridRowSelect" :overlayNoRowsTemplate="overlayNoRowsTemplate">
                </ag-grid-vue>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Color modal-->

    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Details saved successfully!</div>
        <div class="text-gray-600 mt-1">

        </div>
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Something went wrong!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
    <div class="hidden">
      <input type="file" name="newExcelFile" id="newExcelFile" @change="getExcelData"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, inject, watch } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { AgGridVue } from "ag-grid-vue3";
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import {
  BASE_URL,
  BASE_URL_SELLING,
  BUILD_ENV,
  Auth_Token
} from '@/constants/config.js'
import moment from 'moment'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import json5 from 'json5';
import VueBarcode from '@chenfengyuan/vue-barcode';
import {
  helper as $h
} from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
  components: {
    AgGridVue,
    ShapeRenderer
  },
  setup() {
    const gridApi = ref(null);
    const tableRef = ref()
    const tabulator = ref()

    class DatePicker {
      // gets called once before the renderer is used
      init(params) {
        // create the cell
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        this.eInput.classList.add('ag-input');
        this.eInput.style.height = 'var(--ag-row-height)';
        this.eInput.style.fontSize = 'calc(var(--ag-font-size) + 1px)';

        // https://jqueryui.com/datepicker/
        $(this.eInput).datepicker({
          dateFormat: 'dd/mm/yy',
          onSelect: () => {
            this.eInput.focus();
          },
        });
      }

      // gets called once when grid ready to insert the element
      getGui() {
        return this.eInput;
      }

      // focus and select can be done after the gui is attached
      afterGuiAttached() {
        this.eInput.focus();
        this.eInput.select();
      }

      // returns the new value after editing
      getValue() {
        return this.eInput.value;
      }

      // any cleanup we need to be done here
      destroy() {
        // but this example is simple, no cleanup, we could
        // even leave this method out as it's optional
      }

      // if true, then this editor will appear in a popup
      isPopup() {
        // and we could leave this method out also, false is the default
        return false;
      }
    }

    const page_data = reactive({
      series_list: [],
      kapan_list: [],
      transaction_temp: [],
      form_status: false,
      excel_loading: false,
      submit: false,
      destroy: false,
      delete_row: false,
      lab_file: '',
      total_scan_pack: 0,
      total_scan_pack_wt: 0,
      return_progress:false,

      // Form
      kapan_form_list: [],
      transaction_form_list: [],
    })

    const AUTH_TOKEN = localStorage.getItem("token")

    const filterData = reactive({
      s_date: moment().startOf('month').format('YYYY-MM-DD'),
      e_date: moment().format('YYYY-MM-DD'),
      kapan_id: [],
      series_id: "0",
      status: "0",
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }

    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const rowData = reactive({
      value: []
    });

    const overlayNoRowsTemplate = 'Scan packets for process receive!'

    const columnDefs = reactive({
      value: [
        { headerName: "#", field: 'sr_no', maxWidth: 60, },
        { headerName: "Packet No", field: 'lot_no', minWidth: 120 },
        { headerName: "Lab No", field: 'lab_no', minWidth: 120 },
        { headerName: "Weight", field: 'weight', hide: true },
        {
          headerName: "Cr Weight", field: 'new_wt', editable: true, minWidth: 120,
          valueGetter: (params) => {
            if (params.data.new_wt) {
              return params.data.new_wt;
            } else {
              return 0;
            }
          },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = parseFloat(params.newValue);

            if (isNaN(newValInt)) {
              alert('Not a Valid Number');
              params.data.new_wt = params.data.new_wt;
              return false;

            } else if (newValInt > params.data.cr_wt) {
              alert('Cr Wt can not greater than original issue weight');
              params.data.new_wt = params.data.cr_wt;
              return params.data.cr_wt;
            } else {
              var valueChanged = params.data.new_wt !== newValInt;
              if (valueChanged) {
                params.data.new_wt = newValInt;
              }
              return valueChanged;
            }
          },
        },
        {
          headerName: "Lab", field: 'lab', editable: true, minWidth: 100,
          // valueGetter: (params) => {
          //   if (params.data.lab) {
          //     return params.data.lab;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.lab !== newValInt;
            if (valueChanged) {
              params.data.lab = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Stone", field: 'stone', editable: false,
          // valueGetter: (params) => {
          //   if (params.data.lab) {
          //     return params.data.lab;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.stone !== newValInt;
            if (valueChanged) {
              params.data.stone = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Report No", field: 'report_no', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.report_no) {
          //     return params.data.report_no;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.report_no !== newValInt;
            if (valueChanged) {
              params.data.report_no = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Shape", field: 'shape', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.shape) {
          //     return params.data.shape;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.shape !== newValInt;
            if (valueChanged) {
              params.data.shape = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Color", field: 'color', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.color) {
          //     return params.data.color;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.color !== newValInt;
            if (valueChanged) {
              params.data.color = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Purity", field: 'purity', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.purity) {
          //     return params.data.purity;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.purity !== newValInt;
            if (valueChanged) {
              params.data.purity = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Cut", field: 'cut', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.cut) {
          //     return params.data.cut;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            if (params.newValue == '') {
              alert('Value cannot be empty');
              return false;
            }
            var newValInt = params.newValue;

            var valueChanged = params.data.cut !== newValInt;
            if (valueChanged) {
              params.data.cut = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Polish", field: 'polish', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.polish) {
          //     return params.data.polish;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.polish !== newValInt;
            if (valueChanged) {
              params.data.polish = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Symmetry", field: 'symmetry', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.symmetry) {
          //     return params.data.symmetry;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.symmetry !== newValInt;
            if (valueChanged) {
              params.data.symmetry = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Fluerocent", field: 'fluerocent', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.fluerocent) {
          //     return params.data.fluerocent;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.fluerocent !== newValInt;
            if (valueChanged) {
              params.data.fluerocent = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Discount", field: 'discount', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.discount) {
          //     return params.data.discount;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = parseFloat(params.newValue);

            if (isNaN(newValInt)) {
              alert('Not a Valid Number');
              params.data.new_wt = params.data.new_wt;
              return false;

            } else {
              var valueChanged = params.data.discount !== newValInt;
              if (valueChanged) {
                params.data.discount = newValInt;
              }
              return valueChanged;
            }
          },
        },
        {
          headerName: "MEASUREMENT", field: 'measurement', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.measurement) {
          //     return params.data.measurement;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.measurement !== newValInt;
            if (valueChanged) {
              params.data.measurement = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Table", field: 'table', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.table) {
          //     return params.data.table;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.table !== newValInt;
            if (valueChanged) {
              params.data.table = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "T.Depth", field: 't_depth', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.t_depth) {
          //     return params.data.t_depth;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.t_depth !== newValInt;
            if (valueChanged) {
              params.data.t_depth = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Comment", field: 'comment', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.comment) {
          //     return params.data.comment;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.comment !== newValInt;
            if (valueChanged) {
              params.data.comment = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Rapo $", field: 'rapo', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.rapo) {
          //     return params.data.rapo;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.rapo !== newValInt;
            if (valueChanged) {
              params.data.rapo = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Rapo $ / CT", field: 'rapo_per_ct', true: false,
          // valueGetter: (params) => {
          //   if (params.data.rapo_per_ct) {
          //     return params.data.rapo_per_ct;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.rapo_per_ct !== newValInt;
            if (valueChanged) {
              params.data.rapo_per_ct = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Total Price", field: 'total_price', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.total_price) {
          //     return params.data.total_price;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.total_price !== newValInt;
            if (valueChanged) {
              params.data.total_price = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Certificate Link", field: 'certificate_link', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.certificate_link) {
          //     return params.data.certificate_link;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.certificate_link !== newValInt;
            if (valueChanged) {
              params.data.certificate_link = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Video Link", field: 'video_link', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.video_link) {
          //     return params.data.video_link;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.video_link !== newValInt;
            if (valueChanged) {
              params.data.video_link = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Image Link", field: 'image_link', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.image_link) {
          //     return params.data.image_link;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.image_link !== newValInt;
            if (valueChanged) {
              params.data.image_link = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "BGM", field: 'bgm', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.bgm) {
          //     return params.data.bgm;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.bgm !== newValInt;
            if (valueChanged) {
              params.data.bgm = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "EYE Clean", field: 'eye_clean', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.eye_clean) {
          //     return params.data.eye_clean;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.eye_clean !== newValInt;
            if (valueChanged) {
              params.data.eye_clean = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Milky", field: 'milky', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.milky) {
          //     return params.data.milky;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.milky !== newValInt;
            if (valueChanged) {
              params.data.milky = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Crown Height", field: 'crown_height', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.crown_height) {
          //     return params.data.crown_height;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.crown_height !== newValInt;
            if (valueChanged) {
              params.data.crown_height = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Crown Angle", field: 'crown_angle', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.crown_angle) {
          //     return params.data.crown_angle;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.crown_angle !== newValInt;
            if (valueChanged) {
              params.data.crown_angle = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Pavilion Depth", field: 'pavilion_depth', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.pavilion_depth) {
          //     return params.data.pavilion_depth;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.pavilion_depth !== newValInt;
            if (valueChanged) {
              params.data.pavilion_depth = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Pavilion Angle", field: 'pavilion_angle', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.pavilion_angle) {
          //     return params.data.pavilion_angle;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.pavilion_angle !== newValInt;
            if (valueChanged) {
              params.data.pavilion_angle = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Culet Size", field: 'culet_size', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.culet_size) {
          //     return params.data.culet_size;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.culet_size !== newValInt;
            if (valueChanged) {
              params.data.culet_size = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Girdle Percent", field: 'girdle_percent', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.girdle_percent) {
          //     return params.data.girdle_percent;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.girdle_percent !== newValInt;
            if (valueChanged) {
              params.data.girdle_percent = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Girdle Name", field: 'girdle_name', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.girdle_name) {
          //     return params.data.girdle_name;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.girdle_name !== newValInt;
            if (valueChanged) {
              params.data.girdle_name = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Old Date", field: 'old_date', cellEditor: DatePicker, editable: true,
          // valueGetter: (params) => {
          //   if (params.data.old_date) {
          //     return params.data.old_date;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.old_date !== newValInt;
            if (valueChanged) {
              params.data.old_date = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Cr Date", field: 'current_date', cellEditor: DatePicker, editable: true,
          // valueGetter: (params) => {
          //   if (params.data.current_date) {
          //     return params.data.current_date;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.current_date !== newValInt;
            if (valueChanged) {
              params.data.current_date = newValInt;
            }
            return valueChanged;
          },
        },
        {
          headerName: "Days", field: 'days', editable: true,
          // valueGetter: (params) => {
          //   if (params.data.days) {
          //     return params.data.days;
          //   } else {
          //     return 0;
          //   }
          // },
          valueSetter: (params) => {
            var newValInt = params.newValue;

            var valueChanged = params.data.days !== newValInt;
            if (valueChanged) {
              params.data.days = newValInt;
            } setTimeout(focusScan, 50);
            return valueChanged;
          },
        },
      ],
    });

    const defaultColDef = {
      sortable: true,
      filter: true,
      minWidth: 150,
      //  floatingFilter: true,
      flex: 1
    };

    const kapanForm = reactive({
      kapan_id: '0',
      trans_id: '',
      transaction_code: '',
      total_pcs: '',
      total_weight: '',
      loose_pcs: '',
      loose_weight: '',
      certified_pcs: '',
      certified_weight: '',
      transaction_id: [],
      transaction_arr: [],
      part_collection: false
    })
    const swal = inject('$swal')

    const rules = {
      total_weight: {
        required,
      },
    }

    const validate = useVuelidate(rules, toRefs(kapanForm))

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/lsuc_get_stock',
        ajaxConfig: {
          method: "GET", //set request type to Position
          headers: {
            "Content-type": 'application/json; charset=utf-8', //set specific content type
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        },
        ajaxParams: {
          s_date: filterData.s_date,
          e_date: filterData.e_date,
          kapan_id: filterData.kapan_id,
          series_id: (filterData.series_id != "0" ? window.btoa(filterData.series_id) : ""),
          status: (filterData.status != "0" ? window.btoa(filterData.status) : "")
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Stock No',
            minWidth: 150,
            responsive: 0,
            field: 'lab_code',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Packet No',
            minWidth: 150,
            responsive: 0,
            field: 'packet_code',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Report No',
            minWidth: 150,
            responsive: 0,
            field: 'report_no',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'LAB',
            width: 100,
            responsive: 0,
            field: 'lab',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Shape',
            width: 100,
            responsive: 0,
            field: 'shape',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Weight',
            width: 100,
            responsive: 0,
            field: 'cr_weight',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Color',
            width: 100,
            responsive: 0,
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Clarity',
            width: 100,
            responsive: 0,
            field: 'clarity',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Cut',
            width: 100,
            responsive: 0,
            field: 'cut',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Polish',
            width: 100,
            responsive: 0,
            field: 'polish',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },
          {
            title: 'Symmetry',
            width: 100,
            responsive: 0,
            field: 'symmetry',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            headerSort: false
          },

          // For print format
          {
            title: 'Receive Transaction',
            field: 'transaction_code',
            visible: false,
            print: true,
            download: true,
          },
          {
            title: 'Kapan Name',
            field: 'kapan_code',
            visible: false,
            print: true,
            download: true,
          },
          {

            title: 'Issue Transaction',
            field: 'transaction_list',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Final Weight',
            field: 'total_weight',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Loose Weight',
            field: 'loose_weight',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Certy PCS',
            field: 'certified_pcs',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Certy Weight',
            field: 'certified_weight',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Receive Date',
            field: 'receive_date',
            visible: false,
            print: true,
            download: true
          }

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      // tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    const getDetails = (id) => {
      console.log(id);
      const params = {
        "trans_id": window.btoa(id),
      };

      let promise = axios({
        url: "api/rtprc_edit",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.status == "success") {

          } else {
            swal("Warning", data.message, "warning");
          }

          kapanForm.kapan_id = String(data.items.kapan_id)
          kapanForm.trans_id = data.items.id
          kapanForm.transaction_code = data.items.transaction_code
          kapanForm.total_pcs = data.items.total_pcs
          kapanForm.total_weight = data.items.total_weight
          kapanForm.loose_pcs = data.items.loose_pcs
          kapanForm.loose_weight = data.items.loose_weight
          kapanForm.certified_pcs = data.items.certified_pcs
          kapanForm.certified_weight = data.items.certified_weight
          kapanForm.part_collection = (data.items.part_collection == 2 ? true : false)
          page_data.transaction_temp = data.items.transaction_id

          console.log(kapanForm.part_collection)


          cash('#new-receive-modal').modal('show')

        })
        .catch(_error => {
          swal("Warning", "Something went wrong.", "warning");
        });
    }

    const getTransactionHistory = () => {
      const params = {
        "transaction_id": kapanForm.transaction_id,
        "trans_id": window.btoa(kapanForm.trans_id ? kapanForm.trans_id : "")
      };

      let promise = axios({
        url: "api/rtprc_transaction_history",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.status == "success") {

          } else {
            // swal("Warning", data.message, "warning");
          }

          kapanForm.transaction_arr = data.items

        })
        .catch(_error => {
          kapanForm.transaction_arr = []
          // swal("Warning", "Something went wrong.", "warning");
        });
    }

    const submitForm = () => {

      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {

        page_data.submit = true

        // Validate Kapan Weights
        // let length = kapanForm.transaction_arr.length

        let is_filled = true;

        // for (let index = 0; index < length; index++) {


        // }
        if (!(kapanForm.loose_weight > 0) && !(kapanForm.certified_weight > 0)) {
          is_filled = false
          swal("Warning", "Loose OR Certy Stock weight required", "warning");
        }
        if ((kapanForm.certified_pcs > 0 && (kapanForm.certified_weight == "" || kapanForm.certified_weight == 0)) ||
          (kapanForm.certified_weight > 0 && (kapanForm.certified_pcs == "" || kapanForm.certified_pcs == 0))) {
          is_filled = false
          swal("Warning", "Certy Stock pieces and weight required", "warning");
        }

        if (parseFloat(kapanForm.total_weight) != $h.myFloat(parseFloat(kapanForm.loose_weight ? kapanForm.loose_weight : 0) + parseFloat(kapanForm.certified_weight ? kapanForm.certified_weight : 0))) {
          is_filled = false
          swal("Warning", "Total Received weight must be equal to Loose and Certy Weight.", "warning");

        }
        if (is_filled == false) {
          page_data.submit = false
          return;
        }
        const params = {
          "kapan_id": (kapanForm.kapan_id != "0" ? window.btoa(kapanForm.kapan_id) : ""),
          "trans_id": (kapanForm.trans_id ? window.btoa(kapanForm.trans_id) : ""),
          "total_pcs": (kapanForm.total_pcs ? window.btoa(kapanForm.total_pcs) : ""),
          "total_weight": (kapanForm.total_weight ? window.btoa(kapanForm.total_weight) : ""),
          "loose_pcs": (kapanForm.loose_pcs ? window.btoa(kapanForm.loose_pcs) : ""),
          "loose_weight": (kapanForm.loose_weight ? window.btoa(kapanForm.loose_weight) : ""),
          "certified_pcs": (kapanForm.certified_pcs ? window.btoa(kapanForm.certified_pcs) : ""),
          "certified_weight": (kapanForm.certified_weight ? window.btoa(kapanForm.certified_weight) : ""),
          "transaction_id": kapanForm.transaction_id,
          "part_collection": (kapanForm.part_collection == true ? 2 : 1),
          // "transaction_arr": kapanForm.transaction_arr,
        };
        let promise_url = '';
        if (kapanForm.trans_id != '' && kapanForm.trans_id != null) {
          promise_url = "api/rtprc_update"
        } else {
          promise_url = "api/rtprc_create";
        }

        let promise = axios({
          url: promise_url,
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            page_data.submit = false

            if (data.status == "success") {

              cash('#new-receive-modal').modal('hide')

              Toastify({
                node: cash('#success-notification-content')
                  .clone()
                  .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
              }).showToast()

              resetForm()
              initTabulator()
            } else {

              swal("Error!", data.message, "error")
            }
          })

          .catch(_error => {
            page_data.submit = false
            swal("Error!", "Something went wrong", "error")
          });
      }


    }

    const takeMultiReturn = () => {
      gridApi.value.stopEditing();
      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }

      let total_pcs = rowData.value.length;
      let i = 0;
      while(i < total_pcs){
        console.log(rowData.value[i].cr_wt);
        if(rowData.value[i].cr_wt == '' || rowData.value[i].cr_wt == undefined){
          swal("Error!", "Kindly Enter Return Weight to all packet and retry!", "error");
          return;
        }
        i++;
      }

      console.log(rowData.value);

      let api_url = "api/return_lab_rate_packets"


      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to take update entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const data = {
            packet_details: rowData.value,
          };

          let promise = axios({
            url: api_url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if(data.status == "success"){
                if(data.type == "success") {

                  swal.fire({
                    icon:'success',
                    title:"Success!",
                    text:data.message,
                    showConfirmButton: false,
                    timer:1500
                  })

                  resetData()
                  // swal("Success!", data.message, "success");
                } else {
                  swal("Warning!", data.message, "warning");
                }

              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;
        }
      });
    }

    const RTPKapans = async () => {

      const params = {
        series_id: (filterData.series_id == "0" ? "" : window.btoa(filterData.series_id))
      };

      let promise = axios({
        url: "/api/rtprc_kapan",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.kapan_list = data.items

        })
        .catch(_error => {
          page_data.kapan_list = []
        });

    }

    const RTPFormKapans = async () => {

      const params = {
      };

      let promise = axios({
        url: "/api/rtprc_kapan",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.kapan_form_list = data.items

        })
        .catch(_error => {
          page_data.kapan_form_list = []
        });

    }

    const RTPSeries = async () => {
      const params = {

      };

      let promise = axios({
        url: "/api/rtprc_series",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.series_list = data.items

        })
        .catch(_error => {
          page_data.series_list = []
        });
    }

    const RTPTransaction = async () => {

      if (kapanForm.kapan_id == "0") {
        kapanForm.transaction_id = []
      } else {

        const params = {
          kapan_id: (kapanForm.kapan_id == "0" ? "" : window.btoa(kapanForm.kapan_id)),
          trans_id: (kapanForm.trans_id == "" ? "" : window.btoa(kapanForm.trans_id)),
        };

        let promise = axios({
          url: "/api/rtprc_transaction",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
            headers: {
              Accept: "application/json"
            }
          }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            page_data.transaction_form_list = data.items
            kapanForm.transaction_id = []

            if (page_data.transaction_temp.length > 0) {
              kapanForm.transaction_id = page_data.transaction_temp
              page_data.transaction_temp = []
            }

          })
          .catch(_error => {
            page_data.transaction_form_list = []
            kapanForm.transaction_id = []
          });
      }
    }

    const calculate_total = async (item, type) => {

      let receive_limit = $h.myFloat((item.issue_weight ? item.issue_weight : 0) - (item.received_weight ? item.received_weight : 0))
      let total_weight = 0;

      if (item.loose_weight > 0) {
        total_weight += parseFloat(item.loose_weight);
      }

      if (item.certified_weight > 0) {
        total_weight += parseFloat(item.certified_weight);
      }

      if ($h.myFloat(total_weight) > receive_limit) {

        if (type == "loose") {
          item.loose_weight = ""
          calculate_total(item, type)
        } else if (type == "certy") {
          item.certified_weight = ""
          calculate_total(item, type)

        }
        swal("Warning", "Received weight must be less then Issued weight", "warning");
      } else {
        item.total_weight = $h.myFloat(total_weight);

        // Calculate Main Total
        kapanForm.total_pcs = 0
        kapanForm.total_weight = 0
        kapanForm.loose_pcs = 0
        kapanForm.loose_weight = 0
        kapanForm.certified_pcs = 0
        kapanForm.certified_weight = 0

        kapanForm.transaction_arr.forEach(element => {

          kapanForm.total_pcs += element.total_pcs;
          kapanForm.total_weight += element.total_weight;
          kapanForm.loose_pcs += element.loose_pcs;
          kapanForm.loose_weight += element.loose_weight;
          kapanForm.certified_pcs += element.certified_pcs;
          kapanForm.certified_weight += element.certified_weight;

        });

        kapanForm.total_pcs = $h.myFloat(kapanForm.total_pcs)
        kapanForm.total_weight = $h.myFloat(kapanForm.total_weight)
        kapanForm.loose_pcs = $h.myFloat(kapanForm.loose_pcs)
        kapanForm.loose_weight = $h.myFloat(kapanForm.loose_weight)
        kapanForm.certified_pcs = $h.myFloat(kapanForm.certified_pcs)
        kapanForm.certified_weight = $h.myFloat(kapanForm.certified_weight)
      }

    }

    const deleteItem = () => {

      page_data.destroy = true

      swal({
        title: "Are you sure?",
        text: "You Want to Reject Received Stock!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          const data = {
            "trans_id": window.btoa(kapanForm.trans_id)
          };
          let new_url = "api/rtprc_destroy"

          let promise = axios({
            url: new_url,
            method: "POST",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });

          return promise
            .then(result => result.data)
            .then(data => {

              page_data.destroy = false

              if (data.status == 'success') {
                cash('#new-receive-modal').modal('hide')
                swal("Success!", data.message, "success");
                resetForm()
              } else {
                swal("Warning!", data.message, "warning");
                // resetForm()
              }

            })
            .catch(_error => {
              page_data.destroy = false
              swal("Error!", "Something went Wrong", "error");

            });
        }
      }).catch(() => {
        page_data.destroy = false
      })
    }

    // On reset filter
    const onResetFilter = () => {
      filterData.s_date = moment().startOf('month').format('YYYY-MM-DD');
      filterData.e_date = moment().format('YYYY-MM-DD');
      filterData.kapan_id = [];
      filterData.series_id = "0";
      filterData.status = "0";

      RTPKapans();
    }

    const resetForm = () => {

      rowData.value = []
      gridApi.value.setRowData(rowData.value);
      page_data.total_scan_pack_wt = 0;
      page_data.form_status = false;
      page_data.excel_loading = false;
      page_data.total_scan_pack = 0;

      setTimeout(() => {
        initTabulator()
      }, 200);
    }

    const exportCurrentStocks = () => {

      // export stocks

      // lsuc_export
      let filename = "lab-stock-rate.xlsx";
      let params = {}
      let promise = axios({
        url: "/api/lsuc_export",
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        },
        responseType: "blob",
      });

      return promise
        .then((result) => result.data)
        .then((data) => {
          FileDownload(data, filename);
        })
        .catch((_error) => {
          return [];
        });
    }
    const inputFileClick = () => {
      document.getElementById("newExcelFile").click()
    }


    const getExcelData = (e) => {
      console.log(e.target.files[0]);
      page_data.lab_file = e.target.files[0];

      e.preventDefault();

      if (page_data.lab_file) {

        page_data.excel_loading = true

        let formData = new FormData();
        formData.append('scanned_data', JSON.stringify(rowData.value));
        formData.append('lab_data', page_data.lab_file);

        let promise = axios({
          url: "api/get_lab_excel_read_data_rate",
          method: "POST",
          data: formData,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
          'content-type': 'multipart/form-data'
        });
        return promise
          .then(result => result.data)
          .then(data => {
            if (data.error == "YES") {
              page_data.form_status = false
              page_data.excel_loading = false
              swal('Error!', data.message, "error");
              return
            } else {

              page_data.form_status = true
              page_data.excel_loading = false

              let new_items = data.items


              if(new_items.length > 0) {


                new_items.forEach(element => {
                  rowData.value.push(
                    {
                      "sr_no": page_data.total_scan_pack + 1,
                      "lot_no": element.lot_no,
                      "lab_no": element.lab_no,
                      "pack_no": element.pack_code,
                      "barcode": element.barcode,
                      "lab_barcode": element.lab_barcode,
                      "packet_id": element.packet_id,
                      "lab_id": element.lab_id,
                      "last_history_id": element.last_history_id,
                      "weight": element.weight,
                      "cr_wt": element.cr_wt,
                      "new_wt": element.new_wt,
                      "lab": element.lab,
                      "last_process": element.last_process,
                      "l_p": element.l_p,
                      "report_no": element.report_no,
                      "s_no": element.s_no,
                      "shape": element.shape,
                      "color": element.color,
                      "purity": element.purity,
                      "discount": element.discount,
                      "cut": element.cut,
                      "polish": element.polish,
                      "symmetry": element.symmetry,
                      "fluerocent": element.fluerocent,
                      "measurement": element.measurement,
                      "stone": element.stone,
                      "table": element.table,
                      "t_depth": element.t_depth,
                      "comment": element.comment,
                      "rapo": element.rapo,
                      "rapo_per_ct": element.rapo_per_ct,
                      "total_price": element.total_price,
                      "certificate_link": element.certificate_link,
                      "video_link": element.video_link,
                      "image_link": element.image_link,
                      "bgm": element.bgm,
                      "eye_clean": element.eye_clean,
                      "milky": element.milky,
                      "crown_height": element.crown_height,
                      "crown_angle": element.crown_angle,
                      "pavilion_depth": element.pavilion_depth,
                      "pavilion_angle": element.pavilion_angle,
                      "culet_size": element.culet_size,
                      "girdle_percent": element.girdle_percent,
                      "girdle_name": element.girdle_name,
                      "old_date": element.old_date,
                      "current_date": element.current_date,
                      "days": element.days,
                    });

                  reCaclulateTotal();
                });
                gridApi.value.setRowData(rowData.value);
              }
              if (data.message != '') {

                swal('Warning!', data.message, "warning");
              }

              page_data.lab_file = ''
              document.querySelector('[id="newExcelFile"]').value = '';
            }


          })
          .catch(_error => {

            page_data.excel_loading = false
          });
      }

    }
    const reCaclulateTotal = () => {
      var j = rowData.value.length;
      var total_pc = 0;
      var total_ct = 0;
      var sr = 1;
      while (j--) {
        total_pc++;
        total_ct = total_ct + parseFloat(rowData.value[j]['cr_wt']);
        rowData.value[j]['sr_no'] = j + 1;
        sr++;
      }

      page_data.total_scan_pack_wt = total_ct;
      page_data.total_scan_pack = total_pc;

    }
    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if (gridApi.value.getSelectedRows().length == 0) {
        page_data.delete_row = true;
      } else {
        page_data.delete_row = false;
      }
    }
    const resetData = () => {
      page_data.form_status = false
      page_data.excel_loading = false
      rowData.value = []
      gridApi.value.setRowData(rowData.value);
      // page_data.total_scan_pack_wt = 0;
      // page_data.total_scan_pack = 0;
    }
    const importCurrentStocks = () => {

      // import stocks
    }

    onMounted(() => {
      initTabulator()
      RTPKapans()
      RTPFormKapans()
      RTPSeries()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      page_data,
      kapanForm,
      filterData,
      submitForm,
      rules,
      validate,
      onFilter,
      deleteItem,
      onResetFilter,
      RTPKapans,
      RTPTransaction,
      initTabulator,
      calculate_total,
      resetForm,
      getTransactionHistory,
      exportCurrentStocks,
      importCurrentStocks,
      getExcelData,
      inputFileClick,
      defaultColDef,
      onGridReady,
      onGridRowSelect,
      overlayNoRowsTemplate,
      columnDefs,
      rowData,
      gridApi,
      takeMultiReturn
    }
  }
})
</script>
