<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Voucher Details</h2>
      </div>
      <div class="intro-y box p-5 mt-5">

          <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
              <div class="preview">
                  <div class="overflow-x-auto">
                      <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                          <LoadingIcon icon="bars" class="w-8 h-8" />
                          <div class="text-center text-xs mt-2">Getting Records</div>
                      </div>
                  </div>
              </div>
          </div>

          <div v-else-if="pagedata.generate == 'gen'">
              <div class="p-5">
                  <div class="grid grid-cols-12 gap-x-2 pb-10">
                      <div class="col-span-12 sm:col-span-6">

                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Voucher Code. :&nbsp;</label>
                              <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.voucher_code ? pagedata.report_items.voucher_code : '-' }}</label>
                          </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">PAN No :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.pan_no ? pagedata.report_items.pan_no : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">GST No : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.gst_no ? pagedata.report_items.gst_no : '-' }}</label>
                          </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Bank Charges :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.bank_charges ? pagedata.report_items.bank_charges : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Payment Date :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.voucher_date ? pagedata.report_items.voucher_date : "-" }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Payment Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.payment_type_name ? pagedata.report_items.payment_type_name : "-" }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Reference :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.reference ? pagedata.report_items.reference : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Note :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.note ? pagedata.report_items.note : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                          <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.total_weight ? $h.myFloat(pagedata.report_items.total_weight) : '-' }}</label>
                        </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.total_amount ? $h.myFloat(pagedata.report_items.total_amount) : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                          <label for="update-profile-form-6" class="form-label font-bold">Settlement Amount :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.settlement_amount ? $h.myFloat(pagedata.report_items.settlement_amount) : '-' }}</label>
                        </div>

                      </div>
                  </div>


                </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
      <div class="intro-y box p-5 mt-5" v-if="pagedata.generate == 'gen'">
          <div v-if="pagedata.generate == 'gen'">

            <div class="flex flex-col sm:flex-row items-center py-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Voucher Details</h2>
            </div>
            <div class="border">
                <div class="overflow-x-auto">
                    <table class="border mt-3 w-full">
                      <thead>
                          <tr class="border-b">
                              <th class="py-2" rowspan="2">Sr No</th>
                              <th class="py-2">Invoice No</th>
                              <!-- <th class="py-2">Broker Name</th> -->
                              <th class="py-2">Sell Type</th>
                              <th class="py-2">Total Pieces</th>
                              <th class="py-2">Total Weight</th>
                              <th class="py-2">Branch Name</th>
                              <th class="py-2">Sell Date</th>
                              <th class="py-2">Total Amount</th>
                              <th class="py-2">Paid Amount</th>
                              <th class="py-2">Pending Amount</th>
                              <th class="py-2">Settlement Type</th>
                              <th class="py-2">Settlement Amount</th>
                              <th class="py-2">Payable Amount</th>
                              <th class="py-2">Payment Type</th>
                              <!-- <th class="py-2"></th> -->
                          </tr>
                      </thead>
                      <tbody class="border" v-for="(plan,index) in pagedata.report_items.invoice_details" :key="index">
                          <tr>
                              <td class="w-8 py-2" rowspan="2">
                                  <div class="font-bold text-center">{{ index + 1 }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.transaction_code }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.stock_type }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.total_pcs }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.total_wt }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.branch_name }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.sell_date }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.total_amount ? plan.total_amount : 0 }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.paid_amount ? plan.paid_amount : 0 }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.pending_amount ? plan.pending_amount : 0 }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.settlement_type ? plan.settlement_type : '-' }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.settlement_amount ? plan.settlement_amount : '-' }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.payable_amount ? plan.payable_amount : '-' }}</div>
                              </td>
                              <td class="w-36 py-2">
                                  <div class="font-bold text-center">{{ plan.payment_type ? plan.payment_type : '-' }}</div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>

  </div>
  </template>

  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import {
    BASE_URL,
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
  import moment from 'moment';
  import { helper as $h} from '@/utils/helper';
  import {
      useRoute,
      useRouter
  } from 'vue-router';

  export default defineComponent({
      setup() {

          const swal = inject('$swal');
          const route = useRoute()
          const router = useRouter()
          const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
          const pagedata = reactive({

              report_items: [],
              generate: "no",
              loading: false,
          })

          const generateReport = () => {
              const params = {
                  id: route.params.chalan_id
              };

              pagedata.loading = true

              let promise = axios({
                  url: "/api/get_voucher_detail",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "YES") {
                          swal("Warning!", data.message, "warning");
                          pagedata.generate = 'no';
                          pagedata.loading = false

                          return;
                      }

                      pagedata.report_items = data.item;
                      pagedata.generate = 'gen';
                      pagedata.loading = false

                  })
                  .catch(_error => {

                      pagedata.generate = 'no';
                      pagedata.loading = false
                  });
          }

          onMounted(() => {
              generateReport()
          })

          return {
              pagedata
          }
      }
  })
  </script>
