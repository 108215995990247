<template>
<div>
    <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 pb-3">

            <h2 class="text-lg font-medium mr-auto">Non Certy To Certy Transfer</h2>

            <div class="intro-y box mt-5 p-3">
                <div class="flex items-center justify-between">
                    <div>
                        <h2 class="text-lg font-medium mr-auto py-3">Packet List</h2>
                    </div>
                    <div>
                        <!-- <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" v-print="'#multiple-barcode'" v-if="pagedata.form_type == 'update'">
                            Print All
                        </button> -->
                        <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="resetDetails">
                            Reset Data
                        </button>
                    </div>
                </div>
                <div>
                    <div class="flex items-center">

                        <div class="sm:flex items-center sm:mr-4">
                            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Enter Pieces</label>
                            <input v-model="form_data.quantity" type="number" id="packets-quantity" :disabled="form_data.qty_edit == false" class="form-control w-40" placeholder="Pieces">
                        </div>
                        <div>
                            <button class="btn btn-primary" @click="add_new_request_field_by_quantity" :disabled="form_data.qty_edit == false">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12">
                    <div class="intro-y col-span-12 mt-5 lg:col-span-12">
                        <!-- BEGIN: Input -->
                        <div class="intro-y box">

                            <div class="p-5 grid grid-cols-12 gap-x-2">
                                <div class="col-span-6 md:col-span-3 xl:col-span-2">

                                    <div class="py-1">
                                        <label for="update-profile-form-7" class="form-label">Stone Type</label>
                                        <div id="stone_type" class="p-0 m-0">

                                          <TomSelect v-model="form_data.stone_type" :options="{
                  placeholder: 'Select Stone',
                  }" class="w-full" @change="shade_size_item_details">
                                              <option value="0">Select Stone</option>
                                              <option value="1">Natural</option>
                                              <option value="2">CVD</option>
                                              <option value="3">HPHT</option>
                                              <option value="4">D_CVD</option>
                                              <option value="5">D_HPHT</option>
                                          </TomSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-6 md:col-span-3 xl:col-span-2">

                                    <div class="py-1">
                                        <label for="update-profile-form-7" class="form-label">Color</label>
                                        <div id="color_size" class="p-0 m-0 w-36">

                                          <TomSelect v-model="form_data.color_size" :options="{
                  placeholder: 'Select Color',
                  }" class="w-full">
                                              <option value="0">Select Color</option>
                                              <option v-for="(item,index) in pagedata.color_size_arr" :key="index" :value="item.segment_code">{{ item.segment_name }}</option>
                                          </TomSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END: Input -->
                    </div>

                </div>
                <table class="w-full border mt-3">
                    <thead>
                        <tr>
                            <th class="w-4">No</th>
                            <!-- <th class="w-36">Packet No</th> -->
                            <th>Weight (Ct)</th>
                            <th>LAB</th>
                            <!-- <th>Stone</th> -->
                            <th>Shape</th>
                            <th>Color</th>
                            <th>Purity</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b" v-for="(plan,index) in form_data.packet_items" :key="index">
                            <td class="w-4">{{ index + 1 }}</td>
                            <!-- <td class="text-center">{{ plan.packet_no }}</td> -->
                            <!-- <td class="w-36">
                                <input v-model="plan.packet_no" disabled type="number" :id="'weight_' + index" class="form-control w-full" placeholder="Auto">
                            </td> -->
                            <td class="w-36">
                                <input v-model="plan.weight" type="number" :id="'weight_' + index" @change="FiledValueChange()" class="form-control w-full" placeholder="weight">
                            </td>
                            <td :id="'lab_' + index" class="p-0 m-0 w-36">
                                <TomSelect v-model="plan.lab" :options="{
                        placeholder: 'Select Material'
                      }" class="w-full" name="LAB" id="pos-form-2" @change="FiledValueChange()">
                                    <option value="0">Select LAB</option>
                                    <option value="IGI">IGI</option>
                                    <option value="GIA">GIA</option>
                                </TomSelect>
                            </td>
                            <td :id="'shape_' + index" class="p-0 m-0 w-36">
                                <TomSelect v-model="plan.shape" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Shape" id="pos-form-2" @change="FiledValueChange()">
                                    <option value="0">Select Shape</option>
                                    <option v-for="item in pagedata.shape_arr" :key="item.id" :value="item.id">{{ item.shape_name}}</option>
                                </TomSelect>
                            </td>
                            <td :id="'color_' + index" class="p-0 m-0 w-36">
                                <TomSelect v-model="plan.color" :options="{
                        placeholder: 'Select Watt'
                      }" class="w-full" name="Color" id="pos-form-2" @change="FiledValueChange()">
                                    <option value="0">Select Color</option>
                                    <option v-for="item in pagedata.color_arr" :key="item.id" :value="item.id">{{ item.shade_name}}</option>
                                </TomSelect>
                            </td>
                            <td :id="'purity_' + index" class="p-0 m-0 w-36">
                                <TomSelect v-model="plan.purity" :options="{
                        placeholder: 'Select Purity'
                      }" class="w-full" name="Purity" id="pos-form-2" @change="FiledValueChange()">
                                    <option value="0">Select Purity</option>
                                    <option v-for="item in pagedata.purity_arr" :key="item.id" :value="item.id">{{ item.purity_name}}</option>
                                </TomSelect>
                            </td>
                            <!-- <td class="w-20">
                                <div class="flex items-center">

                                    <button type="button" class="text-theme-1 flex items-center" tabindex="-1" v-print="'#'+plan.packet_no" v-if="pagedata.form_type == 'update'">
                                        <PrinterIcon class="w-5 h-5 mr-1" />
                                    </button>
                                    <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="remove_request_field(plan)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                    <p v-else></p>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>

                </table>
                <div class="flex items-center justify-between">
                    <div>
                        <button type="button" @click="add_new_request_field" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto" :disabled="pagedata.form_type == 'update'">Add Packet</button>
                    </div>
                    <div>

                        <button type="button" @click="$router.go(-1)" class="btn btn-outline-secondary w-32 mr-1">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary w-32" :disabled="pagedata.submit || pagedata.form_type == 'update'" @click="add_new_packets">
                          Save Details
                            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="hidden" v-if="pagedata.packet_details.length > 0">
        <div id="multiple-barcode" class="absolute top-0 p-0 m-0">
            <table style="page-break-before: always;" class="p-0" border="collapse" v-for="(packet,index) in pagedata.packet_details" :key="index">
                <tr class="text-center">
                    <th colspan="3" class="text-center">
                        <vue-barcode class="w-full" :value="packet.barcode_no" :options="{ displayValue: true,height:30,fontSize:15,width:2 }" />
                    </th>
                </tr>
                <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                    <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{ packet.packet_code }}</td>
                    <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
                    <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
                </tr>
            </table>
        </div>
    </div>
    <div class="hidden" v-if="pagedata.packet_details.length > 0">
        <table style="page-break-before: always;" class="absolute top-0 p-0 m-0" border="collapse" v-for="(packet,index) in pagedata.packet_details" :key="index" :id="packet.packet_code">
            <tr class="text-center">
                <th colspan="3" class="text-center">
                    <vue-barcode class="w-full" :value="packet.barcode_no" :options="{ displayValue: true,height:30,fontSize:17,width:2 }" />
                </th>
            </tr>
            <tr class="text-center border border-l-0 border-r-0 border-black m-0">
                <td colspan="2" class="border-r border-black" style="font-size: 6px;line-height: 6px;">Lot : {{ packet.packet_code }}</td>
                <td style="font-size: 6px;line-height: 6px;">RW : {{ packet.weight }}</td>
                <!-- <td style="font-size: 5px;line-height: 6px;">PW : {{ packet.ex_weight }}</td> -->
            </tr>
        </table>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config'
import router from '@/router'
import {
    useRoute
} from 'vue-router'
import axios from 'axios'
import {
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    watch
} from 'vue'
import {
    stringify
} from 'json5'
import Toastify from 'toastify-js'
import {
    helper as $h
} from '@/utils/helper'
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
    components: {
        VueBarcode
    },
    setup() {
        const swal = inject('$swal')
        const route = useRoute()
        const pagedata = reactive({
            shape_arr: [],
            color_arr: [],
            color_size_arr: [],
            purity_arr: [],
            packet_details: [],
            form_type: 'create',
            submit: false,
            count_ids: 1,
        })

        const form_data = reactive({
            quantity: 1,
            qty_edit: true,
            stone_type: '0',
            color_size: '0',
            packet_items: [{
                id: 1,
                update_id: '',
                packet_no: '',
                weight: '',
                stone_type: '0',
                shape: '0',
                color: '0',
                purity: '0',
                lab: '0'
            }]
        })

        // Get Shape Details
        const get_shape_details = async () => {

            let promise_url = "/api/dp_shape";
            let params = {

            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.shape_arr = data.items
                })
                .catch(_error => {
                    pagedata.shape_arr = []

                });
        }

        // Get Shape Details
        const get_color_details = async () => {

            let promise_url = "/api/dp_color";
            let params = {

            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.color_arr = data.items
                })
                .catch(_error => {
                    pagedata.color_arr = []

                });
        }

        const shade_size_item_details = async () => {

          let params = {
              stone_type: form_data.stone_type
          }

          // Get Segment Details
          let promise_url = 'api/dp_shade_size_items';

          let promise = axios({
              url: promise_url,
              data: params,
              method: "POST",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {
                console.warn(data)
                pagedata.color_size_arr = data.items
                  form_data.color_size = String(pagedata.color_size_arr[0].segment_code)
                  console.warn(pagedata.color_size_arr)
                })
                .catch(_error => {
                  pagedata.color_size_arr = []
                  form_data.color_size = ""
                  console.warn(pagedata.color_size_arr)
              });
          }
        // Get Shape Details
        const get_purity_details = async () => {

            let promise_url = "/api/dp_purity";
            let params = {

            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    pagedata.purity_arr = data.items
                })
                .catch(_error => {
                    pagedata.purity_arr = []

                });
        }

        // On Quantity Change
        const add_new_request_field_by_quantity = async () => {

            let quantity = form_data.quantity

            const new_quantity = quantity

            if (new_quantity > 0) {
                pagedata.count_ids = 0;
                form_data.packet_items = []
                for (let index = 0; index < new_quantity; index++) {
                    pagedata.count_ids += 1;
                    form_data.packet_items.push({
                        id: pagedata.count_ids,
                        update_id: '',
                        packet_no: '',
                        weight: '',
                        stone_type: '0',
                        shape: '0',
                        color: '0',
                        purity: '0',
                        lab: '0'
                    });

                }

            }

        }

        //
        const add_new_packets = () => {

            if (form_data.quantity <= 0) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Enter a Valid Quantity!",
                    type: "warning",
                })
                return
            }
            // Validate Fileds
            let purchase_detail_length = form_data.packet_items.length

            let is_empty = false

            if (form_data.stone_type == '' || form_data.stone_type == '0') {
                    $('#stone_type').addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#stone_type').removeClass('border border-theme-6');
                }
            if (form_data.color_size == '' || form_data.color_size == '0') {
                    $('#color_size').addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#color_size').removeClass('border border-theme-6');
                }

            for (let i = 0; i < purchase_detail_length; i++) {

                // if(form_data.packet_items[i].packet_no == '') {
                //   $('#packet_no_' + i).addClass('border border-theme-6');
                //   is_empty = true;
                // } else {
                //   $('#packet_no_' + i).removeClass('border border-theme-6');
                // }
                if (form_data.packet_items[i].weight == '' || isNaN(form_data.packet_items[i].weight)) {
                    $('#weight_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#weight_' + i).removeClass('border border-theme-6');
                }
                // if (form_data.packet_items[i].stone_type == '0') {
                //     $('#stone_type_' + i).addClass('border border-theme-6');
                //     is_empty = true;
                // } else {
                //     $('#stone_type_' + i).removeClass('border border-theme-6');
                // }
                if (form_data.packet_items[i].shape == '0') {
                    $('#shape_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#shape_' + i).removeClass('border border-theme-6');
                }
                if (form_data.packet_items[i].color == '0') {
                    $('#color_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#color_' + i).removeClass('border border-theme-6');
                }
                if (form_data.packet_items[i].purity == '0') {
                    $('#purity_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#purity_' + i).removeClass('border border-theme-6');
                }
                if (form_data.packet_items[i].lab == '0') {
                    $('#lab_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#lab_' + i).removeClass('border border-theme-6');
                }
            }

            if (is_empty == true) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fileds Are Empty!",
                    type: "warning",
                })
                return
            }

            swal({
                title: "Are you sure?",
                text: "You want to Transfer Packets To Certy!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Transfer",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    pagedata.submit = true

                    let api_url = ""

                    if (pagedata.form_type == 'update') {
                        api_url = "api/update_details_merge_packet_to_certy"
                    } else {
                        api_url = "api/update_merge_packet_to_certy"
                    }

                    const params = {
                        "packet_items": form_data.packet_items,
                        "quantity": form_data.quantity,
                        "stone_type": form_data.stone_type,
                        "color_size": form_data.color_size
                    };

                    let promise = axios({
                        url: "api/update_merge_packet_to_certy",
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "YES") {

                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                })
                                pagedata.submit = false

                                return;

                            } else {

                              swal.fire({
                                  icon: "success",
                                  title: "Success!",
                                  text: data.message,
                                  type: "success",
                                  // showConfirmButton: false,
                                  // timer: 3000
                              })
                              pagedata.submit = false
                              pagedata.form_type = 'update'
                              resetDetails();
                            }

                            // pagedata.packet_details = data.items
                            // form_data.packet_items = []
                            // pagedata.count_ids = 0

                            // data.items.forEach(element => {

                            //     pagedata.count_ids += 1
                            //     form_data.packet_items.push({
                            //         id: pagedata.count_ids,
                            //         update_id: element.id,
                            //         packet_no: element.packet_code,
                            //         weight: element.weight,
                            //         stone_type: element.stone_type,
                            //         shape: element.shape,
                            //         color: element.color,
                            //         purity: element.purity,
                            //         lab: element.lab
                            //     })

                            // });

                        })
                        .catch(_error => {
                            pagedata.submit = false

                        });

                }
            });
        }

        // Add Product Field
        const add_new_request_field = () => {

            pagedata.count_ids += 1;
            form_data.packet_items.push({
                id: pagedata.count_ids,
                update_id: '',
                packet_no: '',
                weight: '',
                stone_type: '0',
                shape: '0',
                color: '0',
                purity: '0',
                lab: '0'
            });

            form_data.quantity = form_data.packet_items.length

        }

        // Remove Request From Pannel
        const remove_request_field = (item) => {

            swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.packet_items.length;
                    while (i--) {
                        if (form_data.packet_items[i]["id"] == item.id) {
                            form_data.packet_items.splice(i, 1);
                            form_data.packet_items = form_data.packet_items.filter(function () {
                                return true;
                            });
                            console.warn(form_data.packet_items)
                            break
                        }
                    }
                    form_data.packet_items = form_data.packet_items.filter(function () {
                        return true;
                    });
                    form_data.quantity = form_data.packet_items.length
                }
            });

        }

        const resetDetails = (item) => {
            pagedata.form_type = 'create'

            pagedata.packet_details = []
            form_data.packet_items = [{
                id: 1,
                update_id: '',
                packet_no: '',
                weight: '',
                stone_type: '0',
                shape: '0',
                color: '0',
                purity: '0',
                lab: '0'
            }]
            pagedata.count_ids = 1
            form_data.quantity = 1
            form_data.qty_edit = true
        }

        // On Filed Value Change
        const FiledValueChange = () => {

            const new_quantity = form_data.packet_items.length

            if (form_data.qty_edit == true) {

                for (let index = 0; index < new_quantity; index++) {

                    if (form_data.packet_items[index].packet_no != "" || form_data.packet_items[index].weight != "" ||
                        form_data.packet_items[index].stone_type != "0" ||
                        form_data.packet_items[index].shape != "0" || form_data.packet_items[index].color != "0" ||
                        form_data.packet_items[index].purity != "0" || form_data.packet_items[index].lab != "0"
                    ) {
                        form_data.qty_edit = false
                        break;
                    }

                }

            }
        }

        onMounted(() => {
            get_shape_details()
            get_color_details()
            get_purity_details()
        })
        return {
            form_data,
            pagedata,
            add_new_request_field_by_quantity,
            add_new_request_field,
            remove_request_field,
            add_new_packets,
            FiledValueChange,
            resetDetails,
            shade_size_item_details

        }
    }
})
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
