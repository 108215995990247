<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Branch Offices (Polish)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'create-branch-office-account' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           Add Branch
          </router-link>

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                  >Field</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filter.field"
                  class="form-select"
                >
                <option value="branch_name">Branch Name</option>
              <option value="branch_code">Branch Code</option>
              <option value="branch_email">Branch Email</option>
              <option value="branch_mobile_no">Contact No</option>
              <option value="branch_location">Broker Location</option>
              <option value="first_name">User Name</option>
              <option value="contact_personal_email">User Email</option>
              <option value="contact_personal_mobile">User Mobile</option>
                </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-fullo"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2">Value</label>
                <input
                  id="tabulator-html-filter-value"
                  v-model="filter.value"
                  type="text"
                  class="form-control sm:w-40"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="onFilter"
                >
                  Go
                </button>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-reset"
                  type="button"
                  class="btn btn-secondary w-full"
                  @click="onResetFilter"
                >
                  Reset
                </button>
            </div>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'branch_name',
      type: 'like',
      value: ''
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_account_branch_office',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Branch Name',
            width: 120,
            responsive: 0,
            field: 'branch_name',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            cellClick:function(e, cell){
              router.push("/purchase/view-rough-branch-office/" + window.btoa(cell.getData().id));
            }
          },
          {
            title: 'Branch Code',
            minWidth: 120,
            responsive: 0,
            field: 'branch_code',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            formatter:'textarea',
          },
          {
            title: 'Email',
            minWidth: 200,
            responsive: 0,
            field: 'branch_email',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            formatter:'textarea',
          },
          {
            title: 'Contact No',
            minWidth: 150,
            responsive: 0,
            field: 'branch_contact_no',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            formatter:'textarea',
          },
          {
            title: 'Location',
            minWidth: 150,
            responsive: 0,
            field: 'branch_location',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            formatter:'textarea',
          },
          {
            title: 'User Name',
            minWidth: 100,
            responsive: 0,
            field: 'first_name',
            vertAlign: 'middle',
            hozAlign: 'left',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().last_name != null){
              return `
              <div class="font-medium whitespace-nowrap" >${
                  cell.getData().first_name
                } ${
                  cell.getData().last_name
                }</div>`
              }else{
                return `<div class="font-medium whitespace-nowrap" >${
                  cell.getData().first_name
                }</div>`
              }

            }
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().active_status == "Active"){
              return `<div class="flex items-center lg:justify-center text-theme-9">
                Active
              </div>`
              }else{
                return `<div class="flex items-center lg:justify-center text-theme-6">
                Inactive
              </div>`
              }

            }
          },
          {
            title: 'Action',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'left',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              const a = cash(`<div class="flex lg:justify-center items-center">
                <span class="flex items-start" >
                  <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                </span>
              </div>`)


              cash(a).on('click', function(data_id) {
                // On click actions
                router.push("/purchase/update-rough-branch-office/" + window.btoa(cell.getData().id));
              })

              return a[0]
            }
          },


        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    //Edit user
    const editUser = (id) => {
      console.log(id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'branch_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }



    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'vendor_list.xlsx', {
        sheetName: 'Vendors'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      editUser
    }

  }
})
</script>

