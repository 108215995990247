<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Loose Edit Request (Non-Certy)</h2>
        <div>
          <div class="flex justify-between items-center">
            <button class="btn btn-danger mx-2" @click="reject_request(this.$route.params.trans_id)" v-if="page_data.report_items.active_status == 1">Reject</button>
            <button class="btn btn-primary mx-2" @click="accept_request(this.$route.params.trans_id)" v-if="page_data.report_items.active_status == 1 && user_details.user_type == 1">Accept</button>
          </div>
        </div>
    </div>
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="page_data.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Records</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="page_data.generate == 'gen'">
            <div class="p-5">
                <div class="grid grid-cols-12 gap-x-2 border-b pb-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Request Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ page_data.report_items.transaction_code ? page_data.report_items.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.stone_type }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Shape Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.shape_name }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total PCS :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.total_pcs }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.total_weight }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ page_data.report_items.issue_date ? page_data.report_items.issue_date : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Status :&nbsp;</label><label for="update-profile-form-6" class="form-label" :class="{'text-primary-1' : page_data.report_items.active_status == 2,'text-primary-7' : page_data.report_items.active_status == 1,'text-primary-3' : page_data.report_items.active_status == 3}">{{ page_data.report_items.cr_status ? page_data.report_items.cr_status : '-' }}</label>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <div class="intro-y col-span-12 lg:col-span-12 mt-5">
        <!-- BEGIN: Input -->
        <div class="intro-y box">

            <div class="p-5">
                <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto ml-5">Final Polish Stock</h2>
                </div>
                <div class="border mt-5 p-0 shadow">

                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Final Polish Details</h2>
                    </div>
                    <div class="border mt-5 p-0 shadow-md mt-5">
                        <div class="overflow-x-auto mt-5" v-for="(trans_item,trans_index) in page_data.report_items.stock_history" :key="trans_index">
                          <template v-if="trans_item.charni_data.length > 0">
                            <h2 class="font-medium text-base mr-auto ml-5">{{ trans_item.color_name }}</h2>
                            <table class="w-full text-center text-xs">
                              <thead>
                                    <template v-for="(purity_item,prt_inx) in page_data.size_purity_arr" :key="prt_inx">
                                        <tr class="bg-theme-14" v-if="purity_item.color_size == trans_item.color_size && purity_item.purity_items.length > 0">
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Purity</th>
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">All</th>
                                            <template v-for="(item,index) in purity_item.purity_items" :key="index">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">{{ item.purity }}</th>
                                            </template>
                                        </tr>
                                        <tr class="bg-theme-14" v-if="purity_item.color_size == trans_item.color_size && purity_item.purity_items.length > 0">
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2"></th>
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Total</th>
                                            <template v-for="(item,index) in purity_item.purity_items" :key="index">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Size {{ item.size }}</th>
                                            </template>
                                        </tr>
                                        <tr class="bg-theme-14" v-if="purity_item.color_size == trans_item.color_size && purity_item.purity_items.length > 0">
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                            <th class="border-b py-2 px-2 dark:border-dark-5">Stock(Ct)</th>
                                            <th class="border-b border-r py-2 px-2 dark:border-dark-5">Pcs</th>
                                            <template v-for="(item,index) in purity_item.purity_items" :key="index">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock(Ct) </th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS </th>
                                            </template>
                                        </tr>
                                    </template>
                                </thead>
                                <tbody class="text-center" v-for="(field,index) in trans_item['charni_data']" :key="index">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-if="field.new_weight > 0">
                                        <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.charni_name }}</td>
                                        <td class="border-b py-2 px-2">{{ field.new_weight ? $h.myFloat(field.new_weight) : "0" }} Ct</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.new_pcs ? field.new_pcs : "0" }}</td>
                                        <template v-for="(item,inx) in field.sub_charni_arr" :key="inx">
                                            <td class="border-b py-2 px-2">{{ item.new_weight ? $h.myFloat(item.new_weight) : "0" }} Ct</td>
                                            <td class="border-b border-r py-2 px-2">{{ item.new_pcs ? item.new_pcs : "0" }}</td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                            </template>
                        </div>
                        <div v-if="page_data.loading == true" class="text-md font-medium mr-auto text-center my-8">
                            Loading
                        </div>
                    </div>

                </div>
            </div>
            <div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject,
    computed
} from 'vue'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import moment from 'moment';
import {
    helper as $h
} from '@/utils/helper';
import {
    useRoute,
    useRouter
} from 'vue-router';
import store from '@/store';

export default defineComponent({
    setup() {

        const swal = inject('$swal');
        const route = useRoute()
        const router = useRouter()
        const user_details = computed(() => store.state.user.currentUser)
        const AUTH_TOKEN = localStorage.getItem("token")
        const page_data = reactive({

            report_items: [],
            size_purity_arr: [],
            generate: "no",
            loading: false,
            total_added_ct: 0,
            total_added_pieces: 0,
            total_packets: 0,
            total_weight: 0
        })

        const editDetails = () => {
            router.push('/app/app-final-polish/loose-request/edit/' + route.params.id)
        }

        const generateReport = () => {
            const params = {
                trans_id: route.params.trans_id
            };

            page_data.loading = true

            let promise = axios({
                url: "/api/lutc_dr",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        swal("Warning!", data.message, "warning");
                        page_data.generate = 'no';
                        page_data.loading = false

                        return;
                    }

                    page_data.report_items = data.item;
                    getSizePurityDetails()

                    console.log(page_data.report_items.stock_history)
                    page_data.generate = 'gen';
                    page_data.loading = false

                })
                .catch(_error => {

                    page_data.generate = 'no';
                    page_data.loading = false
                });
        }

        const reject_request = (id) => {

            swal({
                title: "Are you sure?",
                text: "You want to Reject Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Reject",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    page_data.failed_loading = true

                    const params = {
                        id: id,
                    };

                    let promise = axios({
                        url: "api/lutc_rr",
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                })
                                generateReport()
                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                })
                            }

                        })
                        .catch(_error => {

                        });
                }
            });
        }

        const accept_request = (id) => {

            swal({
                title: "Are you sure?",
                text: "You want to Accept Request!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    page_data.failed_loading = true

                    const params = {
                        id: id,
                    };

                    let promise = axios({
                        url: "api/lutc_ar",
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                })
                                generateReport()
                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                })
                            }

                        })
                        .catch(_error => {

                        });
                }
            });
        }

        function getSizePurityDetails() {

            let stone_name = ''

            if (page_data.report_items.stone_type == 'NATURAL') {
                stone_name = 1;
            } else if (page_data.report_items.stone_type == 'CVD') {
                stone_name = 2;
            } else if (page_data.report_items.stone_type == 'HPHT') {
                stone_name = 3;
            } else if (page_data.report_items.stone_type == 'D_CVD') {
                stone_name = 4;
            } else if (page_data.report_items.stone_type == 'D_HPHT') {
                stone_name = 5;
            }

            const params = {
                stone_type: stone_name
            };
            let promise = axios({
                url: "/api/list_all_merge_segment_all_color",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.size_purity_arr = data.items;

                })
                .catch(_error => {
                    page_data.size_purity_arr = [];

                });
        }

        onMounted(() => {
            generateReport()
        })

        return {
            page_data,
            editDetails,
            user_details,
            reject_request,
            accept_request,
        }
    }
})
</script>
