<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">LS Other Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Series</label
            >
            <TomSelect
              v-model="screenData.series_id"
              :options="{
                placeholder: 'Select Series',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Series</option>
            <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3" v-if="userDetails.user_type == 1 || userDetails.user_type == 27">
          <div>
            <label for="update-profile-form-9" class="form-label py-1"
              >Select Manager</label
            >
            <TomSelect
              v-model="screenData.manager_id"
              :options="{
                placeholder: 'Select LS Other Manager',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'LS Other Managers'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select manager</option>
            <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.user_id">{{manager.first_name}}</option>
            </TomSelect>
          </div>


        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-9" class="form-label py-1"
              >Select Karigar</label
            >
            <TomSelect
              v-model="screenData.karigar_id"
              :options="{
                placeholder: 'Select LS Other karigar',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'LS Other Karigar'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Karigar</option>
            <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{ karigar.last_name }}</option>
            </TomSelect>
          </div>


        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div v-if="screenData.issue_pending">
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full" disabled
              >
              <option value="0">Issue</option>
              <option value="1">In Process</option>
              <option value="2">Received</option>
              </TomSelect>
            </div>
          <div v-else>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Issue</option>
              <option value="1">In Process</option>
              <option value="2">Received</option>
              </TomSelect>
            </div>


        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
        <div>
          <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
            >Select Start Date</label
          ><input
            class="show-code form-check-switch mr-0 ml-3"
            type="checkbox" v-model="screenData.issue_pending"
          /></div>
          <input
            type= "date"
            id="update-profile-form-13"
            :disabled="screenData.issue_pending"
            class="form-control"
            placeholder=""
            v-model="screenData.issue_date"
          />
        </div>

        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div class="mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="getDetails"
            >
            Generate
            </button>

          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

        <div class="mt-10">

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-20"
            @click="onResetFilter"
          >
            Reset
          </button>

        </div>
        </div>
        <div class="col-span-2 md:col-span-1">

        <div class="mt-10">

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-20"
            v-print="'#report-details'"
          >
            Print
          </button>
        </div>
        </div>
      </div>

      <div class="overflow-x-auto scrollbar-hidden" id="report-details" v-if="pagedata.genreport == 'gen'">
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-12 sm:col-span-6 lg:col-span-4 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Issue</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.issue_pieces ? pagedata.total.issue_pieces : '0' }} Pieces</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Iss.Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) + ' ct' : '0.00 ct' }} </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-4 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Received</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.receive_pieces ? pagedata.total.receive_pieces: '0' }} Pieces</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Rec.Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.receive_weight ? $h.myFloat(pagedata.total.receive_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-4 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Ghat</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.ghat ? $h.myFloat(pagedata.total.ghat) + ' ct' : '0.00 ct' }}</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Assort. Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.extra_ghat_weight ? $h.myFloat(pagedata.total.extra_ghat_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      managers: [],
      process: 28,
      access: 7,
      serieses: [],
      total: [],
      genreport: 'no'
    })
    const screenData = reactive({
      manager_id : '0',
      series_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending :true
    })

    const initTabulator = () => {
      pagedata.genreport = 'gen'
      setTimeout(() => {
        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + '/api/get_ls_other_data_list',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams:{
            manager_id : screenData.manager_id == '0' ? '' : window.btoa(screenData.manager_id),
            karigar_id : screenData.karigar_id == '0' ? '' : window.btoa(screenData.karigar_id),
            series_id : screenData.series_id == '0' ? '' : window.btoa(screenData.series_id),
            status : window.btoa(screenData.status),
            issue_date : screenData.issue_date,
            receive_date : screenData.receive_date,
            issue_pending : screenData.issue_pending,
            process_id: window.btoa(pagedata.process)
          },
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: false,
          tooltipsHeader:true,
          headerSort: false,
          dataTree:true,
          dataTreeStartExpanded:false,
          placeholder: 'No matching records found',
          columns: [


            // For HTML table
            {
              title: 'Transaction No',

              // columnMinWidth:"10%",
              // columnMaxWidth:"10%",
              field: 'transaction_code',
               hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,

            },

            {
              title: 'Series Name',
              // columnMinWidth:"10%",
              // columnMaxWidth:"10%",
              field: 'series_code',
               hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,

            },

            {
              title: 'Weight',

              // columnMinWidth:"15%",
              // columnMaxWidth:"15%",
              field: 'weight',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {

                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().issue_weight ? cell.getData().issue_weight + ' Ct' : ''
                  }<br>${
                    cell.getData().receive_weight ? cell.getData().receive_weight + ' Ct'  : ''
                  }
                  </div>

                </div>`
              },
            },
            {
              title: 'Pieces',

              // columnMinWidth:"15%",
              // columnMaxWidth:"15%",
              field: 'weight',
              hozAlign: 'venter',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {

                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().issue_pieces ? cell.getData().issue_pieces : 'N/A'
                  }<br>${
                    cell.getData().receive_pieces ? cell.getData().receive_pieces : ''
                  }
                  </div>

                </div>`
              },
            },
            {
              title: 'Ghat',

              // columnMinWidth:"15%",
              // columnMaxWidth:"15%",
              field: 'ghat',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {

                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().receive_weight ? parseFloat(cell.getData().issue_weight - cell.getData().receive_weight).toFixed(2) + ' Ct' : '-'
                  }
                  </div>

                </div>`
              },
            },
            {
              title: 'Exter Ghat',

              // columnMinWidth:"15%",
              // columnMaxWidth:"15%",
              field: 'exter_ghat_weight',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {

                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().exter_ghat_weight ? parseFloat(cell.getData().exter_ghat_weight).toFixed(2) + ' Ct' : '-'
                  }
                  </div>

                </div>`
              },
            },
            {
              title: 'Status',

              // columnMinWidth:"15%",
              // columnMaxWidth:"15%",
              field: 'status1',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {

                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().trans_type == 2 ? '<div class="text-primary-4">Merged</div>' : (cell.getData().status == 1 ? '<div class="text-primary-1">Active</div>' : '<div class="text-primary-3">Received</div>')
                  }
                  </div>

                </div>`
              },


            },
            {
              title: 'Date',
              minWidth:150,
              // columnMinWidth:"20%",
              // columnMaxWidth:"20%",
              field: 'created_at',
              hozAlign: 'left',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {

                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().created_at
                  }<br>${
                    cell.getData().receive_date ? cell.getData().receive_date : ''
                  }
                  </div>

                </div>`
              }
            },




            // For print format

          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      }, 50);
    }

    const getEmployees = () => {
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: { process: pagedata.process },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    const getManagers = () => {
      let promise = axios({
        url: "api/dp_manager_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: { process: pagedata.process },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.managers = data.items

        })
        .catch(_error => {

        });
    }
     const getSeries = () => {
      let promise = axios({
        url: "api/get_ghutan_summarty_series",
        method: "post",
        baseURL: BASE_URL,
        data: { processes: [pagedata.process] },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.series

        })
        .catch(_error => {

        });
    }
     const gettotalData = () => {
      const params = {
        manager_id : screenData.manager_id == '0' ? '' : window.btoa(screenData.manager_id),
        karigar_id : screenData.karigar_id == '0' ? '' : window.btoa(screenData.karigar_id),
        series_id : screenData.series_id == '0' ? '' : window.btoa(screenData.series_id),
        status : window.btoa(screenData.status),
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date,
        issue_pending : screenData.issue_pending,
        process_id: window.btoa(pagedata.process)
      };
      let promise = axios({
        url: "api/get_ls_other_data_total",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.total = data
          pagedata.genreport = 'gen'

        })
        .catch(_error => {

        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.manager_id = '0'
      screenData.karigar_id = '0'
      screenData.status = '0'
      screenData.series_id = '0'
      screenData.issue_pending = true
      pagedata.genreport = 'no'

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    function getDetails() {
      initTabulator()
      gettotalData()
    }
    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    onMounted(() => {
      checkPermission()
      reInitOnResizeWindow()
      getSeries()
      getEmployees()
      if(userDetails.value.user_type == 1) {
        getManagers()
      }
      screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      screenData.receive_date = moment().format('YYYY-MM-DD')
    })

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onResetFilter,
      onPrint,
      getDetails
    }
  }
})
</script>
