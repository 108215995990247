<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Polish Rate Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
                multiple
              >
              <!-- <option value="0">Select Series</option> -->
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                multiple
                @change="kapanChange"
              >
              <!-- <option value="0">Select Kapan</option> -->
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <!-- <option value="">Select Packets</option> -->
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Report Type</label
              ><input v-if="screenData.report_type != 3"
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.lot_detail"
              /></div>
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Kapan Wise</option>
              <option value="2">Size Wise</option>
              <option value="3">Lot Wise</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Start Date</label>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label mt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label mt-3"
                >Select Party</label
              >
              <TomSelect
                v-model="screenData.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Party</option>
              <option v-for="party in pagedata.parties" :key="party.id" :value="party.id">{{party.company_name ? party.company_name : party.first_name }}</option>
              </TomSelect>
            </div>
          </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Shape</label
              >
              <TomSelect
                v-model="screenData.shape_group"
                :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">All</option>
              <option value="FANCY">FANCY</option>
              <option v-for="shape in pagedata.shapes" :key="shape.id" :value="shape.id">{{shape.shape_name}}</option>
              </TomSelect>
            </div>
          </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="printData.report_type == 1 || printData.report_type == 2">
          <div class="mt-10 flex">

            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              v-print="'#generate-pdf'"
            >
            Print
            </button> -->
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>
        <div class="col-span-2 xl:col-span-1" v-if="printData.report_type">
          <div class="flex mt-10">

            <div class="flex mt-5 sm:mt-0">

              <div class="dropdown w-1/2 sm:w-auto">
                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <a id="tabulator-export-xlsx" href="javascript:;" :aria-disabled="pagedata.excel_loading == true" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                          </a>
                      </div>
                  </div>
              </div>
              </div>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div v-if="printData.report_type == 3">
          <div class="overflow-x-auto scrollbar-hidden">
            <div class="grid grid-cols-12 gap-4 mt-3">
              <div class="col-span-12 sm:col-span-4 lg:col-span-4 intro-y mt-2">
                  <div class="report-box zoom-in">
                    <div class="box p-2">
                      <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Issue Packets</div>
                        <div class="text-xl font-medium leading-8">{{ pagedata.lot_total.total_issue_packets }}</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-span-12 sm:col-span-4 lg:col-span-4 intro-y mt-2">
                <div class="report-box zoom-in">
                  <div class="box p-2">
                    <div class="flex items-center justify-between">
                      <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                      <div class="text-base text-gray-600">Issue Weight</div>
                      <div class="text-xl font-medium leading-8">{{ pagedata.lot_total.total_issue_weight ? $h.myFloat(pagedata.lot_total.total_issue_weight) + ' ct' : '0.00 ct' }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-4 lg:col-span-4 intro-y mt-2">
                <div class="report-box zoom-in">
                  <div class="box p-2">
                    <div class="flex items-center justify-between">
                      <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                      <div class="text-base text-gray-600">Total Majuri</div>
                      <div class="text-xl font-medium leading-8">{{ pagedata.lot_total.total_majuri ? $h.myFloat(pagedata.lot_total.total_majuri) : '0' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tabulator"
              ref="tableRef"
              class="mt-5"
            ></div>
          </div>
        </div>
        <div v-if="printData.report_type == 1 || printData.report_type == 2">
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2" v-if="printData.lot_detail == false">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party Name</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><span v-if="printData.report_type == 1">Kapan Code</span><span v-else>Size</span></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="printData.report_type == 2">Stone</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="printData.report_type == 2">From</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="printData.report_type == 2">To</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Total Majuri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody>
                  <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                    <tr class="" v-for="(single_item,ind) in item.kapan_details" :key="ind">
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? (item.company_name ? item.company_name : item.first_name + ' '+ item.last_name) : '' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.kapan_code }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2">{{ single_item.stone_type }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2">{{ single_item.from }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2">{{ single_item.to }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.issue_packets ? single_item.issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : '0' }}</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="item.kapan_details.length > 0">
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_issue_packets ? item.total_issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_issue_weight ? $h.myFloat(item.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_majuri ? $h.myFloat(item.total_majuri) : '0' }}</td>
                    </tr>

                  </tbody>
                </table>
                <table class="mt-3 w-full border-2" v-else>
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party Name</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><span v-if="printData.report_type == 1">Kapan Code</span><span v-else>Size</span></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="printData.report_type == 2">Stone</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="printData.report_type == 2">From</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="printData.report_type == 2">To</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap w-48">Issue Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap w-48">Issue Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap w-48">Total Majuri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_packets ? pagedata.total.total_issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '0' }}</td>
                    </tr>
                  </tbody>
                  <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                    <tr v-for="(single_item,ind) in item.kapan_details" :key="ind">
                      <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ ind == 0 ? index + 1 : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ ind == 0 ? (item.company_name ? item.company_name : item.first_name + ' '+ item.last_name) : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ single_item.kapan_code }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r border-b" v-if="printData.report_type == 2">{{ single_item.stone_type }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r border-b" v-if="printData.report_type == 2">{{ single_item.from }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r border-b" v-if="printData.report_type == 2">{{ single_item.to }}</td>
                      <td class="border-b text-xs lg:text-md text-center" colspan="3">
                        <table class="w-full">
                          <thead>
                            <tr class="border-b bg-gray-300 dark:bg-dark-1 hidden">
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap w-48">Issue Packets</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap w-48">Issue Weight (Ct)</th>
                              <th class="py-2 text-xs lg:text-md whitespace-nowrap w-48">Total Majuri</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b" v-for="(packet,index) in single_item.packet_details" :key="index">
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-48">{{ packet.packet_code }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-48">{{ packet.issue_weight }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-48">{{ packet.total_majuri ? packet.total_majuri : 0 }}</td>
                            </tr>
                            <tr class="border-b bg-gray-100 dark:bg-dark-1">
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-48">{{ single_item.issue_packets }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-48">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '' }}</td>
                              <td class="py-2 border-b text-xs lg:text-md text-center border-r w-48">{{ single_item.total_majuri ? $h.myFloat(single_item.total_majuri) : 0 }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="item.kapan_details.length > 0">
                      <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="printData.report_type == 2"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_issue_packets ? item.total_issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_issue_weight ? $h.myFloat(item.total_issue_weight) : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.total_majuri ? $h.myFloat(item.total_majuri) : '0' }}</td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      stones: [],
      shapes: [],
      parties: [],
      report_details : [],
      total : [],
      lot_total : [],
      loading: false,
    })
    const screenData = reactive({
      series_id:[],
      kapan_id:[],
      stone_id:'0',
      packets:[],
      process_id:32,
      party_id:'0',
      shape_group:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: '1',
      lot_detail: false

    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      packets:[],
      process_id:32,
      party_id:'0',
      shape_group:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: 0,
      lot_detail: false
    })

    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }

    const generateReport = () => {

      printData.series_id = screenData.series_id,
      printData.kapan_id = screenData.kapan_id,
      printData.stone_id = screenData.stone_id,
      printData.packets = screenData.packets,
      printData.process_id = screenData.process_id,
      printData.party_id = screenData.party_id,
      printData.shape_group = screenData.shape_group,
      printData.issue_date =  screenData.issue_date,
      printData.receive_date = screenData.receive_date,
      printData.report_type =  screenData.report_type
      printData.lot_detail =  screenData.lot_detail

      let params = {
        series_id : (screenData.series_id.length > 0) ? screenData.series_id : '',
        kapan_id : (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
        packets : (screenData.packets.length > 0) ? screenData.packets : '',
        process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
        party_id : screenData.party_id != '0' ? window.btoa(screenData.party_id) : '',
        stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
        shape_group : screenData.shape_group != '0' ? window.btoa(screenData.shape_group) : '',
        issue_date : window.btoa(screenData.issue_date),
        receive_date : window.btoa(screenData.receive_date),
        report_type : window.btoa(screenData.report_type),
        lot_detail : screenData.lot_detail
      }

      if(printData.report_type == 3) {

        setTimeout(() => {
          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + '/api/get_polish_party_lot_rates',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams:params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: false,
            tooltipsHeader:true,
            headerSort: false,
            placeholder: 'No matching records found',
            columns: [
              // For HTML table
              // For HTML table
              {
                title: 'Packet No',
                field: 'packet_code',
                vertAlign: 'middle',
                print: false,
                minWidth:160,
                download: true,
                formatter(cell) {
                  return cell.getData().packet_code
                }
              },
              {
                title: 'Polish Party',
                field: 'company_name',
                vertAlign: 'middle',
                print: false,
                minWidth:160,
                download: true,
                formatter(cell) {
                  return cell.getData().party_first_name + ' '+ (cell.getData().party_last_name ? cell.getData().party_last_name : '')
                }
              },

              {
                title: 'Weight',
                field: 'mk_weight',
                vertAlign: 'middle',
                print: false,
                download: true,
              },
              {
                title: 'Ex. Weight',
                field: 'ex_weight',
                print: false,
                download: true
              },
              {
                title: 'Iss. Weight',
                field: 'issue_weight',
                print: false,
                download: true
              },
              {
                title: 'Shape',
                field: 'shape_name',
                vertAlign: 'middle',
                print: false,
                download: true
              },
              {
                title: 'Color',
                field: 'color_name',
                vertAlign: 'middle',
                print: false,
                download: true
              },
              {
                title: 'Purity',
                field: 'purity_name',
                vertAlign: 'middle',
                print: false,
                download: true
              },
              {
                title: 'Majuri',
                field: 'total_majuri',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return cell.getData().total_majuri ? cell.getData().total_majuri : 0
                }
              },

            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        let promise = axios({
          url: "api/get_polish_party_lot_rates_total",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            pagedata.lot_total = data.total
          })
        }, 40);
      } else {
        pagedata.loading  = true
        let params = {
          series_id : (screenData.series_id.length > 0) ? screenData.series_id : '',
            kapan_id : (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
            packets : (screenData.packets.length > 0) ? screenData.packets : '',
          process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
          party_id : screenData.party_id != '0' ? window.btoa(screenData.party_id) : '',
          stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
          shape_group : screenData.shape_group != '0' ? window.btoa(screenData.shape_group) : '',
          issue_date : window.btoa(screenData.issue_date),
          receive_date : window.btoa(screenData.receive_date),
          report_type : window.btoa(screenData.report_type),
          lot_detail : screenData.lot_detail
        }
        let promise = axios({
          url: "api/get_polish_pary_rates",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            pagedata.report_details = data.party_details
            pagedata.total = data.total
            pagedata.loading  = false
          })
          .catch(_error => {
            pagedata.loading  = false

          });
      }
    }
    const getSeries = () => {
      let params = {
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items
          screenData.kapan_id = []

        })
        .catch(_error => {
          screenData.kapan_id = []

        });
    }
    const getKapans = () => {
      let params = {
        series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
        process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/get_process_packet_kapan_multiple",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = []
          screenData.packets = []

        })
        .catch(_error => {
          screenData.packets = []
          screenData.kapan_id = []

        });
    }
    const getPackets = () => {
      if(screenData.kapan_id.length > 0) {
        let params = {
          series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
          kapan_id: (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
          process_id : screenData.process_id,
        }
        let promise = axios({
          url: "api/get_process_packet_packets_multiple",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            pagedata.packets = data.items
            screenData.packets = []

          })
          .catch(_error => {
            screenData.packets = []

          });
        } else {
        pagedata.packets = []
        screenData.packets = []
      }
    }
    const getParties = () => {
      let params = {
        // process: pagedata.process,
      }
      let promise = axios({
        url: "api/dp_account_polish_party",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.parties = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = []
      screenData.kapan_id = []
      screenData.packets = []
      screenData.party_id = '0'
      screenData.stone_id = '0'
      screenData.shape_group = '0'
      screenData.report_type = '0'
      screenData.lot_detail = false

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      if(printData.report_type == 2) {

        let filename = "polish-lot-rate.xlsx";
        let params = {
          series_id : (printData.series_id.length > 0) ? printData.series_id : '',
          kapan_id : (printData.kapan_id.length > 0) ? printData.kapan_id : '',
          packets : (printData.packets.length > 0) ? printData.packets : '',
          process_id : printData.process_id != '0' ? window.btoa(printData.process_id) : '',
          party_id : printData.party_id != '0' ? window.btoa(printData.party_id) : '',
          stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
          shape_group : printData.shape_group != '0' ? window.btoa(printData.shape_group) : '',
          issue_date : window.btoa(printData.issue_date),
          receive_date : window.btoa(printData.receive_date),
          report_type : window.btoa(printData.report_type),
          lot_detail : printData.lot_detail
        }
        let promise = axios({
            url: "/api/download_polish_majuri_report",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            },
            responseType: "blob",
        });

        return promise
            .then((result) => result.data)
            .then((data) => {
                FileDownload(data, filename);
            })
            .catch((_error) => {
                return [];
            });
        } else {
          let report_type = 'get_polish_party_rates'

        let params = {
            report_type: report_type,
            filters: printData,
            items: {
                'items': pagedata.report_details,
                'total': pagedata.total
            }
        }
        let promise = axios({
            url: "api/add_report_details",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
          .then(result => result.data)
          .then(data => {

              const report_id = data.report_id

              export_exls(report_id)

          })
          .catch(_error => {

        });
        }
      }
      function export_exls(id) {
      let filename = "Polish-Rate-Summary.xlsx";
      const params = {
          id: window.btoa(id)
      };
      let promise = axios({
          url: "/api/download_polish_rate_report_all",
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
        .then((result) => result.data)
        .then((data) => {
            FileDownload(data, filename);
        })
        .catch((_error) => {
            return [];
        });
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      // getPackets()
    }
    function seriesChange() {
      getParameters()
    }
    function kapanChange() {
      getPackets()
    }
    const getShapes = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_shape",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.shapes = data.items

        })
        .catch(_error => {

        });
    }
    function printReportDetails(){
      let report_type = ''
      if(printData.report_type == 3) {
        report_type = 'get_polish_party_lot_rates'
      } else {
        report_type = 'get_polish_party_rates'
      }

      let params = {
        report_type: report_type,
        filters: printData,
        items: {'items': pagedata.report_details,'total': pagedata.total }
      }
      let promise = axios({
        url: "api/add_report_details",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id

          if(report_type == 'get_polish_party_lot_rates') {

          } else {
            let url = BASE_URL + '/polish-party-rates-report?id=' + window.btoa(report_id)
            window.open(url, '_blank');
          }

        })
        .catch(_error => {

        });
      // add_report_details
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      getParties()
      getDPStoneType()
      getShapes()
      getParameters()
      reInitOnResizeWindow()

    })

    return {
      tableRef,
      pagedata,
      printData,
      screenData,userDetails,
      printReportDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      generateReport
    }
  }
})
</script>
