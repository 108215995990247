<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Stock Manager Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Kapan</label>
                 <TomSelect
                v-model="screenData.selectedkapan"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                @change="getTenderItems"
              >
              <option value="">Select Kapan</option>
              <option v-for="kapan in screenData.kapan_ar" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
                </div>

               <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Date From</label
                  >
                  <input
                   type= "date"
                    id="update-profile-form-13"

                    class="form-control"
                    placeholder=""
                    v-model="screenData.selecteddatefrom"

                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Date To</label
                  >
                  <input
                   type= "date"
                    id="update-profile-form-13"
                    class="form-control"
                    placeholder=""
                    :min="screenData.selecteddatefrom"
                    v-model="screenData.selecteddateto"

                  />
                </div>

              </div>
              <div class="col-span-12 xl:col-span-6">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Process</label
                  >
                <TomSelect
                v-model="screenData.selectedprocess"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                }"
                class="w-full"
                @change="getKarigar()"
              >
              <option value="">Select Process</option>
              <option value="7">LS SIGN</option>
              <option value="8">4P SIGN</option>
              <option value="13">STN</option>
              <option value="16">DOPPING</option>
              <option value="17">GALAXY OPERATOR (SCAN)</option>

              </TomSelect>
                </div>

                <div class="mt-3" v-if="screenData.selectedprocess != ''">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Karigar</label
                  >
                  <TomSelect
                  v-model="screenData.selectedKarigar"
                  :options="{
                    placeholder: 'Select Karigar',
                  }"
                  class="w-full">
                  <option value="">Select Karigar </option>
                  <option v-for="karigar_item in screenData.karigar_ar" :key="karigar_item.id" :value="karigar_item.id">{{karigar_item.first_name}} {{karigar_item.last_name}} {{karigar_item.emp_code}}</option>
                  </TomSelect>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Status</label
                  >
              <TomSelect
                v-model="screenData.selectedStatus"
                :options="{
                  placeholder: 'Select Status',
                }"
                class="w-full"
              >
              <option value="">Select Status</option>
              <option value="1">In Process</option>
              <option value="2">Received</option>
            </TomSelect>
          </div>
        </div>

        <div class="col-span-12 xl:col-span-12 mt-5">
          <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="generateReport"
            >
              Generate
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">

           <div class="grid grid-cols-12 gap-6 mt-5 p-5" v-if="(screenData.selectedprocess != '' || screenData.selectedKarigar != '') && screenData.genreport == 'gen'">
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-xl font-medium leading-8">Weight Issued</div>
                  </div>
                  <div class="text-xl text-theme-1 font-medium leading-8 mt-2">{{screenData.total_issue_weight}}</div>

                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-xl font-medium leading-8">Weight Received</div>
                  </div>
                  <div class="text-xl text-theme-6 font-medium leading-8 mt-2">{{screenData.total_received_weight}}</div>

                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <div class="text-xl font-medium leading-8">In Progress</div>
                  </div>
                  <div class="text-xl text-theme-9 font-medium leading-8 mt-2">{{screenData.total_in_process_weight}}</div>

                </div>
              </div>
            </div>

          </div>

          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5"
          ></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      genreport : "no",
      kapan_ar:[],
      karigar_ar:[],
      buyers:[],
      selectedkapan:'',
      selectedprocess:'',
      selectedKarigar:'',
      selectedStatus:'',
      tenders:[],
      selectedTenderItems:[],
      tenderItems:[],
      selecteddatefrom:'',
      selecteddateto:'',
      selectedkapantype:'',
      kapan_sub_type:'',
      kapan_purity:'',
      total_issue_weight:0,
      total_received_weight:0,
      total_in_process_weight:0,
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_stock_manager_report',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          kapan_id:window.btoa(screenData.selectedkapan),
          process:screenData.selectedprocess,
          karigar:screenData.selectedKarigar,
          status:screenData.selectedStatus,
          issue_date_from:screenData.selecteddatefrom,
          issue_date_to:screenData.selecteddateto,
          },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Transaction No',

            columnMinWidth:"10%",
            columnMaxWidth:"10%",
            field: 'transaction_code',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,

          },

          {
            title: 'Kapan Code',
            columnMinWidth:"10%",
            columnMaxWidth:"10%",
            field: 'kapan_code',
            vertAlign: 'middle',
            print: false,
            download: true,


            // formatter(cell) {

            //   return `<div>
            //     <div class="font-medium whitespace-nowrap">${
            //       cell.getData().tender_name
            //     }</div>

            //   </div>`
            // }
          },
          {
            title: 'Manager/Worker',
            field: 'karigar_first_name',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().issue_type == 1){
              if(cell.getData().process_id == 2 || cell.getData().process_id == 3){
                    return  `<div class="">`+cell.getData().karigar_manager_first_name+`<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;
              }if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                    return  `<div class="">`+cell.getData().planner_first_name +`<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;
              }else{
                return  `<div class="">`+cell.getData().karigar_first_name +`<br>
                <p class="text-theme-9 font-12 font-bold">`+cell.getData().process_name+`</p>
              </div>` ;

              }
              }else if(cell.getData().issue_type == 2){
                 return  `<div class="">`+cell.getData().manager_first_name +`<br>
                <p class="text-theme-1 font-12 font-bold">`+cell.getData().manager_type_name+`</p>
              </div>` ;

              }
            }
          },
          {
            title: 'Pieces',
            columnMinWidth:"5%",
            columnMaxWidth:"5%",
            field: 'issue_pieces',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Weight',
            minWidth: 100,
            field: 'issue_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().receive_weight != null){
                return cell.getData().issue_weight + " Ct<br>" + cell.getData().receive_weight + " Ct";
              }else{
                return cell.getData().issue_weight + " Ct<br>" + " N/A";
              }
            }
          },
          {
            title: 'Date',
            columnMinWidth:"20%",
            columnMaxWidth:"20%",
            field: 'created_at',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().receive_date != null){
                return cell.getData().created_at + "<br>" + cell.getData().receive_date + "";
              }else{
                return cell.getData().created_at + "<br>" + " N/A";
              }
            }
          },
          {
            title: 'Status',
            field: 'status',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
            if(cell.getData().manager_id == null || cell.getData().manager_id == ''){
                if(cell.getData().status == "1"){
                return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                  In Process
                </div>`
                }else{
                  return `<div class="flex items-center lg:justify-center text-theme-6">
                  Received
                </div>`
                }
              }
            }
          }


          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_kapan",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.kapan_ar = data.items;
        })
        .catch(_error => {

        });
    }

    const getKarigar = () =>{

      const params = {
        process: screenData.selectedprocess
      };
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.karigar_ar = data.items;
        })
        .catch(_error => {

        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      initTabulator()
      tabulator.value.setFilter()
    }
    //
    const generateReport = () => {
      onFilter()
      tabulator.value.setFilter()
      screenData.genreport = 'gen';

      if(screenData.selectedprocess != '' || screenData.selectedKarigar != ''){
        const params = {
          kapan_id:window.btoa(screenData.selectedkapan),
          process:screenData.selectedprocess,
          karigar:screenData.selectedKarigar,
          status:screenData.selectedStatus,
          issue_date_from:screenData.selecteddatefrom,
          issue_date_to:screenData.selecteddateto,
        };
        let promise = axios({
          url: "api/get_stock_manager_report_total_weights",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            screenData.total_issue_weight = data.issue_weight;
            screenData.total_received_weight = data.issue_received;
            screenData.total_in_process_weight = data.issue_in_process;
          })
          .catch(_error => {

          });
      }
    }

    // On reset filter
    const onResetFilter = () => {

      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      checkPermission()
      reInitOnResizeWindow()
      getSeries()
      initTabulator()
      // screenData.selecteddatefrom = moment().startOf('month').format('YYYY-MM-DD')
      screenData.selecteddateto = moment().format('YYYY-MM-DD')
      //generateReport()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      getKarigar,
      generateReport
    }
  }
})
</script>
