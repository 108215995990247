<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto text-t">Packet Plan Rates</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 sm:col-span-3 md:col-span-2">
          <label for="update-profile-form-6" class="form-label"
            >Select Series</label
          >
          <TomSelect
            v-model="screenData.series_id"
            :options="{
              placeholder: 'Select Series',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Series'
                }
              }
            }"
            class="w-full"
            @change="getSeriesPacketDetails"
          >
          <option value="0">Select Series</option>
          <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
          </TomSelect>
        </div>
        <div class="col-span-12 sm:col-span-3 md:col-span-2">
          <label for="update-profile-form-6" class="form-label"
            >Select Kapan</label
          >
          <TomSelect
        v-model="screenData.kapan_id"
        :options="{
          placeholder: 'Select Kapan',
          loadingClass: 'loading',
          plugins: {
            dropdown_header: {
              title: 'Kapan'
            }
          }
        }"
        class="w-full"
        @change="getPacketDetails"
      >
      <option value="0">Select Kapan</option>
      <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
      </TomSelect>
        </div>
        <div class="col-span-12 sm:col-span-3 md:col-span-2">
          <label for="update-profile-form-9" class="form-label"
            >Select Packet</label
          >
          <TomSelect
        v-model="screenData.packet_id"
        :options="{
          placeholder: 'Select Packet',
          loadingClass: 'loading',
          plugins: {
            dropdown_header: {
              title: 'Select Packet'
            }
          }
        }"
        class="w-full"
        multiple
      >
      <option value="">Select Packets</option>
      <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
      </TomSelect>
        </div>
        <div class="col-span-12 sm:col-span-3 md:col-span-3 mt-5">
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-16 mt-2 mr-2 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-16 mt-2 mt-2 sm:ml-1"
            @click="getPlans"
          >
            Generate
          </button>
          <!-- <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-16 mt-2 sm:ml-1"
            v-print="'#report-details'"
          >
            Print
          </button> -->
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-16 mt-2 sm:ml-1" @click="printReportDetails"
          >
            Print
          </button>
        </div>
        <!-- <div class="col-span-12 sm:col-span-4 md:col-span-3 mt-3" v-if="pagedata.show_total == 'Yes'">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending" @change="getPlans"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="screenData.issue_pending"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
                @change="getPlans"
              />
            </div>

        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3 mt-3" v-if="pagedata.show_total == 'Yes'">
          <div>
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
              @change="getPlans"
            />
          </div>
        </div> -->
      </div>

      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box" id="report-details">
          <div class="p-3">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4" @click="getunPlannedPacks('planner')">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold text-lg"
                    >Planner</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.total_packets ? pagedata.total.total_packets : '0' }} {{ pagedata.total.total_weight ? ' (' + $h.myFloat(pagedata.total.total_weight) + ' ct )' : '( 0.00 ct )' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >P. Done Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_piece ? pagedata.total.planner_piece : '0' }} {{ pagedata.total.planner_rough_kapan_weight ? ' (' + $h.myFloat(pagedata.total.planner_rough_kapan_weight) + ' ct )' : '( 0.00 ct )' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Pieces :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_rough_pieces ? pagedata.total.planner_rough_pieces : '0' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est MK Weight :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_rough_weight ? $h.myFloat(pagedata.total.planner_rough_weight) + ' ct' : '0.00 ct' }} {{ pagedata.total.planner_rough_weight ? ' ( ' + parseFloat(pagedata.total.planner_rough_weight * 100 / pagedata.total.planner_rough_kapan_weight).toFixed(2) + ' %)' : '0.00 ct ( 0 %)' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Polish Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_polish_weight ? $h.myFloat(pagedata.total.planner_polish_weight) + ' ct' : '0.00 ct' }}  {{ pagedata.total.planner_polish_weight ? ' ( ' + parseFloat(pagedata.total.planner_polish_weight * 100 / pagedata.total.planner_rough_kapan_weight).toFixed(2) + ' %)' : '0.00 ct ( 0 %)' }}</label>
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Planning Value : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_rate ? $h.myFloat(pagedata.total.planner_rate) + ' $': '0 $' }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Per Carat : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_rate ? $h.myFloat(pagedata.total.planner_rate / pagedata.total.planner_polish_weight) + ' $': '0 $' }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Rough Per Carat : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.planner_rate ? $h.myFloat(pagedata.total.planner_rate / pagedata.total.planner_rough_kapan_weight) + ' $': '0 $' }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4" @click="getunPlannedPacks('checker')">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold text-lg"
                    >Checker</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_issue_pieces ? pagedata.total.checker_issue_pieces : '0' }} {{ pagedata.total.checker_issue_weight ? ' (' + $h.myFloat(pagedata.total.checker_issue_weight) + ' ct )' : '( 0.00 ct )' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >C. Done Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_piece ? pagedata.total.checker_piece : '0' }} {{ pagedata.total.checker_rough_kapan_weight ? ' (' + $h.myFloat(pagedata.total.checker_rough_kapan_weight) + ' ct )' : '( 0.00 ct )' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Pieces :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_rough_pieces ? pagedata.total.checker_rough_pieces : '0' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est MK Weight :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_rough_weight ? $h.myFloat(pagedata.total.checker_rough_weight) + ' ct' : '0.00 ct' }} {{ pagedata.total.checker_rough_weight ? ' ( ' + parseFloat(pagedata.total.checker_rough_weight * 100 / pagedata.total.checker_rough_kapan_weight).toFixed(2) + ' %)' : '0.00 ct ( 0 %)' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Polish Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_polish_weight ? $h.myFloat(pagedata.total.checker_polish_weight) + ' ct' : '0.00 ct' }}  {{ pagedata.total.checker_polish_weight ? ' ( ' + parseFloat(pagedata.total.checker_polish_weight * 100 / pagedata.total.checker_rough_kapan_weight).toFixed(2) + ' %)' : '0.00 ct ( 0 %)' }}</label>
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Planning Value : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_rate ? $h.myFloat(pagedata.total.checker_rate) + ' $': '0 $' }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Per Carat : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_rate ? $h.myFloat(pagedata.total.checker_rate / pagedata.total.checker_polish_weight) + ' $': '0 $' }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Rough Per Carat : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.checker_rate ? $h.myFloat(pagedata.total.checker_rate / pagedata.total.checker_rough_kapan_weight) + ' $': '0 $' }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4" @click="getunPlannedPacks('mc_checker')">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold text-lg"
                    >Main Checker</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_issue_pieces ? pagedata.total.main_checker_issue_pieces : '0' }} {{ pagedata.total.main_checker_issue_weight ? ' (' + $h.myFloat(pagedata.total.main_checker_issue_weight) + ' ct )' : '( 0.00 ct )' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >C. Done Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_piece ? pagedata.total.main_checker_piece : '0' }} {{ pagedata.total.main_checker_rough_kapan_weight ? ' (' + $h.myFloat(pagedata.total.main_checker_rough_kapan_weight) + ' ct )' : '( 0.00 ct )' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Pieces :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_rough_pieces ? pagedata.total.main_checker_rough_pieces : '0' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est MK Weight :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_rough_weight ? $h.myFloat(pagedata.total.main_checker_rough_weight) + ' ct' : '0.00 ct' }} {{ pagedata.total.main_checker_rough_weight ? ' ( ' + parseFloat(pagedata.total.main_checker_rough_weight * 100 / pagedata.total.main_checker_rough_kapan_weight).toFixed(2) + ' %)' : '0.00 ct ( 0 %)' }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Polish Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_polish_weight ? $h.myFloat(pagedata.total.main_checker_polish_weight) + ' ct' : '0.00 ct' }}  {{ pagedata.total.main_checker_polish_weight ? ' ( ' + parseFloat(pagedata.total.main_checker_polish_weight * 100 / pagedata.total.main_checker_rough_kapan_weight).toFixed(2) + ' %)' : '0.00 ct ( 0 %)' }}</label>
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Planning Value : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_rate ? $h.myFloat(pagedata.total.main_checker_rate) + ' $': '0 $' }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Per Carat : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_rate ? $h.myFloat(pagedata.total.main_checker_rate / pagedata.total.main_checker_polish_weight) + ' $': '0 $' }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Est Rough Per Carat : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ pagedata.total.main_checker_rate ? $h.myFloat(pagedata.total.main_checker_rate / pagedata.total.main_checker_rough_kapan_weight) + ' $': '0 $' }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-10"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Closing Modal -->
  <div id="packet-plan-details-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-3 px-3">
            <div class="px-5">
              <h2 class="font-medium text-base mr-auto">Packet : {{ pagedata.packet_number }}</h2>
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12" v-if="pagedata.plans.planner">
                <div class="intro-y my-2">
                  <div class="py-3">
                    <h2 class="font-medium text-base mr-auto">Planner Name : {{ pagedata.planner_name }}</h2>
                  </div>
                  <div class="overflow-x-auto scrollbar-hidden">
                    <div
                      id="plannertabulator"
                      ref="plannertableRef"
                      class="mt-5"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-span-12" v-if="pagedata.plans.checker">
                <div class="intro-y my-2">
                  <div class="py-3">
                    <h2 class="font-medium text-base mr-auto">Checker Name : {{ pagedata.checker_name }}</h2>
                  </div>
                  <div class="overflow-x-auto scrollbar-hidden">
                    <div
                      id="checkertabulator"
                      ref="checkertableRef"
                      class="mt-5"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-span-12" v-if="pagedata.plans.main_checker">
                <div class="intro-y my-2">
                  <div class="py-3">
                    <h2 class="font-medium text-base mr-auto">Main Checker Name : {{ pagedata.mainchecker_name }}</h2>
                  </div>
                  <div class="overflow-x-auto scrollbar-hidden">
                    <div
                      id="maincheckertabulator"
                      ref="maincheckertableRef"
                      class="mt-5"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 pt-36 text-md font-bold text-center" v-if="!pagedata.plans.planner && !pagedata.plans.checker && !pagedata.plans.main_checker">Plans Not Found</div>
            </div>
          </div>
          </div>
        </div>
    </div>
</div>
  <!-- END: Closing Modal -->
    <!-- BEGIN: Closing Modal -->
  <div id="packet-unplan-details-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-3 px-3">
            <div class="px-5">
              <h2 class="font-medium text-2xl mr-auto">Available Packets</h2>
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <div class="intro-y my-2">
                  <div class="overflow-x-auto scrollbar-hidden">
                    <div
                      id="unPlannedtabulator"
                      ref="unPlannedtableRef"
                      class="mt-5"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
    </div>
</div>
  <!-- END: Closing Modal -->
  </div>
</template>

<script>
import { defineComponent, computed, reactive,onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import store from '@/store'
import cash from 'cash-dom'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      show_total : 'No',
      total: [],
      kapans: [],
      serieses: [],
      packets: [],
      type : '',
      packet_number : '',
      planner_name : '',
      checker_name : '',
      mainchecker_name : '',
      plans: {
        planner: false,
        checker: false,
        main_checker: false,
      },
      packet_id : [],
      kapan_id:'0',
      series_id:'0',
    })
    const tableRef = ref()
    const tabulator = ref()
    const unPlannedtableRef = ref()
    const unPlannedtabulator = ref()
    const plannertableRef = ref()
    const plannertabulator = ref()
    const checkertableRef = ref()
    const checkertabulator = ref()
    const maincheckertableRef = ref()
    const maincheckertabulator = ref()
    const screenData = reactive({
      packet_id : [],
      kapan_id:'0',
      series_id:'0',
      // issue_pending: true,
      // issue_date:'',
      // receive_date:'',
    })
    const getPlanRates = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/get_packet_plan_rates',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          kapan_id: screenData.kapan_id,
          series_id: screenData.series_id,
          packets: screenData.packet_id,
          // issue_date : screenData.issue_date,
          // receive_date : screenData.receive_date,
          // issue_pending : screenData.issue_pending == true ? 'true' : 'false',
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:true,
        placeholder: 'No matching records found',
        columns: [

          {
            title: 'Packet',
            columns: [
              {
                title: '',
                field: 'packet_code',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                    return `<div>
                      <div class="whitespace-nowrap">${
                        // cell.getData().packet_code
                        cell.getData().packet_code.split('_').slice(2).join('_')
                      }
                      </div>

                    </div>`
                },
                cellClick:function(e, cell){
                    pagedata.plans.planner = false
                    pagedata.plans.checker = false
                    pagedata.plans.main_checker = false
                    if(cell.getData().planning_file != null) {
                      pagedata.plans.planner = true
                      pagedata.planner_name = cell.getData().planner_name + ' ' + cell.getData().planner_last_name
                      setTimeout(() => {
                        plannerPrepare(cell.getData().id, cell.getData().last_planner)
                      }, 10);
                    }
                    if(cell.getData().c_change) {
                      pagedata.plans.checker = true
                      pagedata.checker_name = cell.getData().checker_name + ' ' + cell.getData().checker_last_name
                      setTimeout(() => {
                      checkerPrepare(cell.getData().id, cell.getData().c_last_planner)
                      }, 10);
                    }
                    if(cell.getData().mc_change) {
                      pagedata.plans.main_checker = true
                      pagedata.mainchecker_name = cell.getData().mainchecker_name + ' ' + cell.getData().mainchecker_last_name
                      setTimeout(() => {
                      maincheckerPrepare(cell.getData().id, cell.getData().mc_last_planner)
                      }, 10);
                    }
                    pagedata.packet_number = cell.getData().packet_code
                    cash('#packet-plan-details-modal').modal('show')
                }

              }
            ]

          },
          {
            title: 'Weight',
            columns: [
              {
                title: '',
                minWidth: 50,
                field: 'weight',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
              }
            ]

          },
          {
            title: 'Process',
            hozAlign: 'center',
            vertAlign: 'middle',
            columns: [
              {
                title: 'Manager',
                minWidth: 130,
                field: 'process_weight',
                hozAlign: 'left',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().process_id) {
                      if( cell.getData().process_id == 29 || cell.getData().process_id == 1 || cell.getData().process_id == 28 || cell.getData().process_id == 3){
                        return `<div>
                          <div class="whitespace-nowrap">${
                            cell.getData().process_code
                          }<br>${
                            cell.getData().manager_karigar_name + ' ' + cell.getData().manager_karigar_last_name
                          }
                          </div>

                        </div>`
                      }else if( cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                        return `<div>
                          <div class="whitespace-nowrap">${
                            cell.getData().process_code
                          }<br>${
                            cell.getData().planner_employee_first_name + ' ' + cell.getData().planner_employee_last_name
                          }
                          </div>

                        </div>`
                      }else if( cell.getData().process_id == 32){
                        return `<div>
                          <div class="whitespace-nowrap">${
                            cell.getData().process_code
                          }<br>${
                            cell.getData().company_name
                          }
                          </div>

                        </div>`
                      }else{
                        return `<div>
                          <div class="whitespace-nowrap">${
                            cell.getData().process_code
                          }<br>${
                            cell.getData().employee_name + ' ' + cell.getData().employee_last_name
                          }
                          </div>

                        </div>`
                      }
                    }else {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().manager_first_name  + ' ' + cell.getData().manager_last_name
                    }
                    </div>

                    </div>`
                  }
                },
              }
            ]

          },
          {
            title: 'Planner',
            hozAlign: 'center',
            vertAlign: 'middle',
            columns: [
              {
                title: 'Est. MW <br>Est. PW',
                field: 'makeable_polish',
                minWidth: 120,
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      (cell.getData().planner_rough_weight ? parseFloat(cell.getData().planner_rough_weight).toFixed(4) : '-') + (cell.getData().planner_rough_weight && cell.getData().weight ? ' (' + parseFloat(cell.getData().planner_rough_weight * 100 / cell.getData().weight).toFixed(2) + ' %)' : '')
                    }<br>${
                      (cell.getData().planner_polish_weight ? parseFloat(cell.getData().planner_polish_weight).toFixed(4) : '-') + (cell.getData().planner_polish_weight && cell.getData().weight ? ' (' + parseFloat(cell.getData().planner_polish_weight * 100 / cell.getData().weight).toFixed(2) + ' %)' : '')
                    }
                    </div>

                  </div>`
                }
              },
              {
                title: 'Piece',
                field: 'planner_piece ',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().planner_piece
                    }
                    </div>

                  </div>`
                }

              },
              {
                title: 'Cmp Rate <br>Repo Rate',
                field: 'rates',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      (cell.getData().planner_rate ? parseFloat(cell.getData().planner_rate).toFixed(2) + ' $' : '-')
                    }<br>${
                      (cell.getData().planner_repo_rate > 0 ? parseFloat(cell.getData().planner_repo_rate).toFixed(2) + ' $' : '-')
                    }
                    </div>

                  </div>`
                }
              },
              {
                title: 'Back<br>(Disc.)',
                field: 'back',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().planner_repo_rate > 0 ? (parseFloat((cell.getData().planner_repo_rate - cell.getData().planner_rate) * 100 / cell.getData().planner_repo_rate).toFixed(2) < 0 ? '+ ' + parseFloat((cell.getData().planner_rate - cell.getData().planner_repo_rate) * 100 / cell.getData().planner_repo_rate).toFixed(2)  + ' % ' : parseFloat((cell.getData().planner_repo_rate - cell.getData().planner_rate) * 100 / cell.getData().planner_repo_rate).toFixed(2)  + ' % ') : '-'
                    }
                    </div>

                  </div>`
                }
              },
            ]
          },
          {
            title: 'Checker',
            hozAlign: 'center',
            vertAlign: 'middle',
            columns: [
              {
                title: 'Est. MW <br>Est. PW',
                field: 'makeable_polish',
                minWidth: 120,
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      (cell.getData().checker_rough_weight ? parseFloat(cell.getData().checker_rough_weight).toFixed(4) : '-') + (cell.getData().checker_rough_weight && cell.getData().weight ? ' (' + parseFloat(cell.getData().checker_rough_weight * 100 / cell.getData().weight).toFixed(2) + ' %)' : '')
                    }<br>${
                      (cell.getData().checker_polish_weight ? parseFloat(cell.getData().checker_polish_weight).toFixed(4) : '-') + (cell.getData().checker_polish_weight && cell.getData().weight ? ' (' + parseFloat(cell.getData().checker_polish_weight * 100 / cell.getData().weight).toFixed(2) + ' %)' : '')
                    }
                    </div>

                  </div>`
                }
              },
              {
                title: 'Piece',
                field: 'checker_piece ',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().checker_piece
                    }
                    </div>

                  </div>`
                }
              },
              {
                title: 'Cmp Rate <br>Repo Rate',
                field: 'rates',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().checker_rate && cell.getData().checker_rate > cell.getData().planner_rate) {
                    return `<div>
                      <div class="whitespace-nowrap text-white bg-primary-4">${
                        (cell.getData().checker_rate ? parseFloat(cell.getData().checker_rate).toFixed(2) + ' $' : '-')
                      }<br>${
                        (cell.getData().checker_repo_rate > 0 ? parseFloat(cell.getData().checker_repo_rate).toFixed(2) + ' $' : '-')
                      }
                      </div>

                    </div>`

                  } else if(cell.getData().checker_rate && cell.getData().checker_rate < cell.getData().planner_rate) {
                    return `<div>
                      <div class="whitespace-nowrap text-white bg-primary-3">${
                        (cell.getData().checker_rate ? parseFloat(cell.getData().checker_rate).toFixed(2) + ' $' : '-')
                      }<br>${
                        (cell.getData().checker_repo_rate > 0 ? parseFloat(cell.getData().checker_repo_rate).toFixed(2) + ' $' : '-')
                      }
                      </div>

                    </div>`

                  } else {
                    return `<div>
                      <div class="whitespace-nowrap">${
                        (cell.getData().checker_rate ? parseFloat(cell.getData().checker_rate).toFixed(2) + ' $' : '-')
                      }<br>${
                        (cell.getData().checker_repo_rate > 0 ? parseFloat(cell.getData().checker_repo_rate).toFixed(2) + ' $' : '-')
                      }
                      </div>

                    </div>`
                  }
                }
              },
              {
                title: 'Back<br>(Disc.)',
                field: 'back',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().checker_repo_rate > 0 ? ( parseFloat((cell.getData().checker_repo_rate - cell.getData().checker_rate) * 100 / cell.getData().checker_repo_rate).toFixed(2) < 0 ? '+ ' + parseFloat((cell.getData().checker_rate - cell.getData().checker_repo_rate) * 100 / cell.getData().checker_repo_rate).toFixed(2)  + ' % ' : parseFloat((cell.getData().checker_repo_rate - cell.getData().checker_rate) * 100 / cell.getData().checker_repo_rate).toFixed(2)  + ' % ') : '-'
                    }
                    </div>

                  </div>`
                }
              },
            ]
          },
          {
            title: 'Main Checker',
            hozAlign: 'center',
            vertAlign: 'middle',
            columns: [
              {
                title: 'Est. MW <br>Est. PW',
                field: 'makeable_polish',
                minWidth: 100,
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      (cell.getData().main_checker_rough_weight ? parseFloat(cell.getData().main_checker_rough_weight).toFixed(2) : '-') + (cell.getData().main_checker_rough_weight && cell.getData().weight ? ' (' + parseFloat(cell.getData().main_checker_rough_weight * 100 / cell.getData().weight).toFixed(2) + ' %)' : '')
                    }<br>${
                      (cell.getData().main_checker_polish_weight ? parseFloat(cell.getData().main_checker_polish_weight).toFixed(2) : '-') + (cell.getData().main_checker_polish_weight && cell.getData().weight ? ' (' + parseFloat(cell.getData().main_checker_polish_weight * 100 / cell.getData().weight).toFixed(2) + ' %)' : '')
                    }
                    </div>

                  </div>`
                }
              },
              {
                title: 'Piece',
                field: 'main_checker_piece ',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().main_checker_piece
                    }
                    </div>

                  </div>`
                }
              },
              {
                title: 'Cmp Rate <br>Repo Rate',
                field: 'rates',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().main_checker_rate && cell.getData().main_checker_rate > cell.getData().planner_rate) {
                    return `<div>
                      <div class="whitespace-nowrap text-white bg-primary-4">${
                        (cell.getData().main_checker_rate ? parseFloat(cell.getData().main_checker_rate).toFixed(2) + ' $' : '-')
                      }<br>${
                        (cell.getData().main_checker_repo_rate > 0 ? parseFloat(cell.getData().main_checker_repo_rate).toFixed(2) + ' $' : '-')
                      }
                      </div>

                    </div>`

                  } else if(cell.getData().main_checker_rate && cell.getData().main_checker_rate < cell.getData().planner_rate) {
                    return `<div>
                      <div class="whitespace-nowrap text-white bg-primary-3">${
                        (cell.getData().main_checker_rate ? parseFloat(cell.getData().main_checker_rate).toFixed(2) + ' $' : '-')
                      }<br>${
                        (cell.getData().main_checker_repo_rate > 0 ? parseFloat(cell.getData().main_checker_repo_rate).toFixed(2) + ' $' : '-')
                      }
                      </div>

                    </div>`

                  } else {
                    return `<div>
                      <div class="whitespace-nowrap">${
                        (cell.getData().main_checker_rate ? parseFloat(cell.getData().main_checker_rate).toFixed(2) + ' $' : '-')
                      }<br>${
                        (cell.getData().main_checker_repo_rate > 0 ? parseFloat(cell.getData().main_checker_repo_rate).toFixed(2) + ' $' : '-')
                      }
                      </div>

                    </div>`
                  }
                }
              },
              {
                title: 'Back<br>(Disc.)',
                field: 'back',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().main_checker_repo_rate > 0 ? (parseFloat((cell.getData().main_checker_repo_rate - cell.getData().main_checker_rate) * 100 / cell.getData().main_checker_repo_rate).toFixed(2) < 0 ? '+ ' + parseFloat((cell.getData().main_checker_rate - cell.getData().main_checker_repo_rate) * 100 / cell.getData().main_checker_repo_rate).toFixed(2)  + ' % ' : parseFloat((cell.getData().main_checker_repo_rate - cell.getData().main_checker_rate) * 100 / cell.getData().main_checker_repo_rate).toFixed(2)  + ' % ') : '-'
                    }
                    </div>

                  </div>`
                }
              },
            ]
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const getUnPlanPackets = () => {
      unPlannedtabulator.value = new Tabulator(unPlannedtableRef.value, {
        ajaxURL: BASE_URL + '/api/get_packet_plan_available_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          kapan_id: screenData.kapan_id,
          series_id: screenData.series_id,
          packets: screenData.packet_id,
          type: pagedata.type,
          // issue_date : screenData.issue_date,
          // receive_date : screenData.receive_date,
          // issue_pending : screenData.issue_pending,
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        dataTree:true,
        dataTreeStartExpanded:true,
        placeholder: 'No matching records found',
        columns: [

          {
            title: 'Packet',
            columns: [
              {
                title: '',
                field: 'packet_code',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                    return `<div>
                      <div class="whitespace-nowrap">${
                        // cell.getData().packet_code
                        cell.getData().packet_code
                      }
                      </div>

                    </div>`
                },
                cellClick:function(e, cell){
                    pagedata.plans.planner = false
                    pagedata.plans.checker = false
                    pagedata.plans.main_checker = false
                    if(cell.getData().planning_file != null) {
                      pagedata.plans.planner = true
                      pagedata.planner_name = cell.getData().planner_name + ' ' + cell.getData().planner_last_name
                      setTimeout(() => {
                        plannerPrepare(cell.getData().id, cell.getData().last_planner)
                      }, 10);
                    }
                    if(cell.getData().c_change) {
                      pagedata.plans.checker = true
                      pagedata.checker_name = cell.getData().checker_name + ' ' + cell.getData().checker_last_name
                      setTimeout(() => {
                      checkerPrepare(cell.getData().id, cell.getData().c_last_planner)
                      }, 10);
                    }
                    if(cell.getData().mc_change) {
                      pagedata.plans.main_checker = true
                      pagedata.mainchecker_name = cell.getData().mainchecker_name + ' ' + cell.getData().mainchecker_last_name
                      setTimeout(() => {
                      maincheckerPrepare(cell.getData().id, cell.getData().mc_last_planner)
                      }, 10);
                    }
                    pagedata.packet_number = cell.getData().packet_code
                    cash('#packet-plan-details-modal').modal('show')
                }

              }
            ]

          },
          {
            title: 'Weight',
            columns: [
              {
                title: '',
                minWidth: 50,
                field: 'weight',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                    return `<div>
                      <div class="whitespace-nowrap">${
                        // cell.getData().packet_code
                        cell.getData().weight ? cell.getData().weight + ' Ct' : '0.00 Ct'
                      }
                      </div>

                    </div>`
                },
              }
            ]

          },
          {
            title: 'Process',
            hozAlign: 'center',
            vertAlign: 'middle',
            columns: [
              {
                title: 'Manager',
                minWidth: 130,
                field: 'process_weight',
                hozAlign: 'left',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().process_id) {
                        if( cell.getData().process_id == 29 || cell.getData().process_id == 1 || cell.getData().process_id == 28 || cell.getData().process_id == 3){
                          return `<div>
                            <div class="whitespace-nowrap">${
                              cell.getData().process_code
                            }<br>${
                              cell.getData().manager_karigar_name + ' ' + cell.getData().manager_karigar_last_name
                            }
                            </div>

                          </div>`
                        }else if( cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20){
                          return `<div>
                            <div class="whitespace-nowrap">${
                              cell.getData().process_code
                            }<br>${
                             cell.getData().planner_employee_first_name + ' ' + cell.getData().planner_employee_last_name
                            }
                            </div>

                          </div>`
                        }else if( cell.getData().process_id == 32){
                        return `<div>
                          <div class="whitespace-nowrap">${
                            cell.getData().process_code
                          }<br>${
                            cell.getData().company_name
                          }
                          </div>

                        </div>`
                      }else{
                          return `<div>
                            <div class="whitespace-nowrap">${
                              cell.getData().process_code
                            }<br>${
                              cell.getData().employee_name + ' ' + cell.getData().employee_last_name
                            }
                            </div>

                          </div>`
                        }
                      }else {
                    return `<div>
                      <div class="whitespace-nowrap">${
                        cell.getData().manager_first_name  + ' ' + cell.getData().manager_last_name
                      }
                      </div>

                    </div>`
                  }
                },
              }
            ]

          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function getunPlannedPacks(type) {
      pagedata.type = type
      getUnPlanPackets()
      cash('#packet-unplan-details-modal').modal('show')
    }
    const plannerPrepare = (id, user) => {
      plannertabulator.value = new Tabulator(plannertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: id, planning_type: 10,p_id: user},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'RW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const checkerPrepare = (id, user) => {
      checkertabulator.value = new Tabulator(checkertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: id, planning_type: 11,p_id: user},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'RW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const maincheckerPrepare = (id, user) => {
      maincheckertabulator.value = new Tabulator(maincheckertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: id, planning_type: 12,p_id: user},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'RW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const getKapanDetails = () => {
      let _this = this;
      const params = {
        series_id: ''
      };
      let api = ''
      if(screenData.series_id) {
        api = "api/dp_rough_kapan_by_series"
        params.series_id = window.btoa(screenData.series_id)
      } else {
        api = "api/dp_rough_kapan"
      }
      let promise = axios({
        url: api,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.kapans = data.items;
        })
        .catch(_error => {

        });
    }
    const getPlanRatesTotal = () => {
      pagedata.show_total = 'No'
      pagedata.total = []
      let _this = this;
      const params = {
        kapan_id: screenData.kapan_id,
        series_id: screenData.series_id,
        packets: screenData.packet_id,
        // issue_date : screenData.issue_date,
        // receive_date : screenData.receive_date,
        // issue_pending : screenData.issue_pending,
      };
      if(screenData.kapan_id != '0' || screenData.series_id != '0') {
        let promise = axios({
          url: "api/get_packet_plan_rates_total",
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            pagedata.total = data.total
            pagedata.show_total = 'Yes'
          })
          .catch(_error => {

          });
      }
    }
    const getRoughDetails = () => {
      let promise = axios({
        url: "api/dp_rough_series_desc",
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items
        })
        .catch(_error => {

        });
    }
    const getPackets = () => {
      let _this = this;
      const params = {
        id: ''
      };
      let api = ''
      if(screenData.kapan_id) {
        params.id = window.btoa(screenData.kapan_id)
        api = "api/get_packet_by_kapan"
      } else {
        params.id = window.btoa(screenData.series_id)
        api = "api/get_packet_by_series"
      }
      let promise = axios({
        url: api,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.packets = data.items
        })
        .catch(_error => {

        });
    }
    function getSeriesPacketDetails() {
      getKapanDetails()
      getPackets()
      if(screenData.series_id) {
        setTimeout(() => {
          if(screenData.kapan_id) {
            screenData.kapan_id = ''
          }
          if(screenData.packet_id.length > 0) {
            screenData.packet_id = []
          }
        }, 20);
      }
    }
    function getPacketDetails() {
      getPackets()
      if(screenData.kapan_id) {
        setTimeout(() => {
        if(screenData.packet_id.length > 0) {
          screenData.packet_id = []
        }
        }, 20);
      }
    }
    function printReportDetails(){
      let url = BASE_URL + '/packet-plan-report?series_id=' + pagedata.series_id + '&kapan_id=' + pagedata.kapan_id +'&packets=' + pagedata.packet_id
      window.open(url, '_blank');
    }
    function getPlans() {
      if(screenData.series_id != '0' || screenData.kapan_id != '0') {
        pagedata.series_id = screenData.series_id
        pagedata.kapan_id = screenData.kapan_id
        pagedata.packet_id = screenData.packet_id
        getPlanRates()
        getPlanRatesTotal()
      }
    }
    const onResetFilter = () => {
      // screenData.issue_pending = true
      // screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      // screenData.receive_date = moment().format('YYYY-MM-DD')
      screenData.packet_id = []
      pagedata.total = []
      pagedata.show_total = 'No'
      pagedata.packets = []
      screenData.kapan_id = '0'
      screenData.series_id = '0'

    }

    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      // screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      // screenData.receive_date = moment().format('YYYY-MM-DD')
      checkPermission()
      getRoughDetails()
      getKapanDetails()

    })

    return {
      pagedata,
      screenData,
      tableRef,
      unPlannedtableRef,
      plannertableRef,
      checkertableRef,
      maincheckertableRef,
      userDetails,
      printReportDetails,
      onResetFilter,
      getPacketDetails,
      getSeriesPacketDetails,
      getPlans,
      getunPlannedPacks
    }
  }
})
</script>
