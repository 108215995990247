<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Chabka Lot Stock Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                @change="kapanChange"
              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="0">Select Packets</option>
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div v-if="screenData.issue_pending">
            <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
                disabled
              >
              <option value="0">Issue</option>
              <option value="1">In Porcess</option>
              <option value="2">Received</option>
              </TomSelect>
            </div>
          </div>
          <div v-else>
            <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Issue</option>
              <option value="1">In Porcess</option>
              <option value="2">Received</option>
              </TomSelect>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3" v-if="userDetails.user_type == 1 || userDetails.user_type == 27">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Manager</label
              >
              <TomSelect
                v-model="screenData.issue_id"
                :options="{
                  placeholder: 'Select Karigar',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Karigar'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Manager</option>
              <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.user_id">{{manager.first_name}} {{manager.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
              <label for="update-profile-form-6" class="form-label py-1 "
                >Select Karigar</label
              >
              <TomSelect
                v-model="screenData.karigar_id"
                :options="{
                  placeholder: 'Select Karigar',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Karigar'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Karigar</option>
              <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{karigar.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
          <div>
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
              >Start Date</label
            ><input
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox" v-model="screenData.issue_pending" @change="changChange"
            /></div>
            <input
              type= "date"
              id="update-profile-form-13"
              :disabled="screenData.issue_pending"
              class="form-control"
              placeholder=""
              v-model="screenData.issue_date"
            />
          </div>

        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>

      </div>
      <div class="scrollbar-hidden" id="report-details" v-if="pagedata.loading == false">
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Total</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '0' }} Pkts</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.total_packets_weight ? $h.myFloat(pagedata.total.total_packets_weight) + ' ct' : '0.00 ct' }} </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-6">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Processing</div>
                  <div class="text-xl font-medium leading-8">{{ pagedata.total.processing_packets ? pagedata.total.processing_packets : '0' }} Pkts</div>
                </div>
                <div class="flex items-start justify-between mt-2 text-right">
                  <div class="text-gray-600 mt-1 text-sm"><div class="text-base text-gray-600">Iss.Wt</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.processing_weight ? $h.myFloat(pagedata.total.processing_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-1">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Received</div>
                  <div class="text-xl font-medium leading-8">{{ (pagedata.total.received_packets ? pagedata.total.received_packets : 0) }} Pkts</div>
                </div>
                <div class="flex items-start justify-between text-right">
                  <div class="text-gray-600 text-sm"><div class="text-base text-gray-600">Iss.Wteight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.received_issue_weight ? $h.myFloat(pagedata.total.received_issue_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
                <div class="flex items-start justify-between text-right">
                  <div class="text-gray-600 text-sm"><div class="text-base text-gray-600">Rec.Weight</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.received_receive_weight ? $h.myFloat(pagedata.total.received_receive_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
              <div class="box p-4 pb-1">
                <div class="flex items-center justify-between">
                  <div class="text-xl font-medium leading-8">Majuri</div>
                  <div class="text-xl font-medium leading-8">{{ (pagedata.total.total_majuri ? pagedata.total.total_majuri : 0) }}</div>
                </div>
                <div class="flex items-start justify-between text-right">
                  <div class="text-gray-600 text-sm"><div class="text-base text-gray-600">Ghat</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.ghat_weight ? $h.myFloat(pagedata.total.ghat_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
                <div class="flex items-start justify-between text-right">
                  <div class="text-gray-600 text-sm"><div class="text-base text-gray-600">Assort</div></div>
                  <div>
                  <div class="text-base text-center text-gray-600 mt-1">{{ pagedata.total.assortment_weight ? $h.myFloat(pagedata.total.assortment_weight) + ' ct' : '0.00 ct' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import { generate } from '@vue/compiler-core'

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      employees: [],
      managers: [],
      process: 30,
      todayDate: '',
      total: [],
      loading:true
    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      packets:[],
      issue_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      packets:[],
      issue_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date:moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: true
    })

    const initTabulator = () => {

      pagedata.loading = false

      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.packets = screenData.packets
      printData.issue_id = screenData.issue_id
      printData.karigar_id = screenData.karigar_id
      printData.status = screenData.status
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending

      let params = {
        series_id :screenData.series_id != '0' ? screenData.series_id : '',
        kapan_id :screenData.kapan_id != '0' ? screenData.kapan_id : '',
        packets : screenData.packets,
        issue_id : screenData.issue_id != '0' ? screenData.issue_id : '',
        karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
        status : screenData.status != '0' ? screenData.status : '',
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date,
        process_id : pagedata.process,
        issue_pending : screenData.issue_pending
      }

      setTimeout(() => {

        if(printData.issue_pending == true) {
          tabulator.value = new Tabulator(tableRef.value, {
            ajaxURL: BASE_URL + '/api/get_process_lot_records',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams: params,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: false,
            tooltipsHeader:true,
            headerSort: false,
            placeholder: 'No matching records found',
            columns: [
              // For HTML table
              // For HTML table
              {
                title: 'Packet No',
                field: 'packet_code',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().packet_code) {

                    if(cell.getData().status == 2){
                    return cell.getData().packet_code
                    // return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mx-1"></i>`+cell.getData().packet_code
                    }else{
                      return cell.getData().packet_code
                    }
                  } else {
                    return '';
                  }
                }
              },
              {
                title: 'Karigar',
                field: 'karigan_name',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                    return (cell.getData().first_name ? cell.getData().first_name : '') + ' ' + (cell.getData().last_name ? cell.getData().last_name : '')
                }
              },
              {
                title: 'Issue Weight',
                field: 'issue_weight',
                print: false,
                download: true
              },
              {
                title: 'Status',
                field: 'status',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {

                  if(cell.getData().status == 2){
                  return `<div class="flex items-center lg:justify-center text-theme-9">
                    Received
                  </div>`
                  }else{
                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    In Process
                  </div>`
                  }
                }
              },

            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        } else {

          if(printData.status == 1) {
            tabulator.value = new Tabulator(tableRef.value, {
              ajaxURL: BASE_URL + '/api/get_process_lot_records',
              ajaxConfig:{
                  method:"GET", //set request type to Position
                  headers: {
                      "Content-type": 'application/json; charset=utf-8', //set specific content type
                      "Authorization": "Bearer " + localStorage.getItem("token")
                  },
              },
              ajaxParams: params,
              ajaxFiltering: true,
              ajaxSorting: true,
              printAsHtml: true,
              printStyled: true,
              pagination: 'remote',
              paginationSize: 20,
              paginationSizeSelector: [20, 30, 40],
              layout: 'fitColumns',
              responsiveLayout: false,
              tooltipsHeader:true,
              headerSort: false,
              placeholder: 'No matching records found',
              columns: [
                // For HTML table
                // For HTML table
                {
                title: 'Packet No',
                field: 'packet_code',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().packet_code) {

                    if(cell.getData().status == 2){
                    return cell.getData().packet_code
                    // return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mx-1"></i>`+cell.getData().packet_code
                    }else{
                      return cell.getData().packet_code
                    }
                  } else {
                    return '';
                  }
                }
              },
              {
                title: 'Karigar',
                field: 'karigan_name',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                    return (cell.getData().first_name ? cell.getData().first_name : '') + ' ' + (cell.getData().last_name ? cell.getData().last_name : '')
                }
              },
                {
                  title: 'Issue Weight',
                  field: 'issue_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Status',
                  field: 'status',
                  vertAlign: 'middle',
                  print: false,
                  download: false,
                  formatter(cell) {

                    if(cell.getData().status == 2){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                    }else{
                      return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                    }
                  }
                },

              ],
              renderComplete() {
                feather.replace({
                  'stroke-width': 1.5
                })
              }
            })
          } else {
            tabulator.value = new Tabulator(tableRef.value, {
              ajaxURL: BASE_URL + '/api/get_process_lot_records',
              ajaxConfig:{
                  method:"GET", //set request type to Position
                  headers: {
                      "Content-type": 'application/json; charset=utf-8', //set specific content type
                      "Authorization": "Bearer " + localStorage.getItem("token")
                  },
              },
              ajaxParams:{
                series_id :screenData.series_id != '0' ? screenData.series_id : '',
                kapan_id :screenData.kapan_id != '0' ? screenData.kapan_id : '',
                packets : screenData.packets,
                issue_id : screenData.issue_id != '0' ? screenData.issue_id : '',
                karigar_id : screenData.karigar_id != '0' ? screenData.karigar_id : '',
                status : screenData.status,
                issue_date : screenData.issue_date,
                receive_date : screenData.receive_date,
                process_id : pagedata.process,
                issue_pending : screenData.issue_pending
              },
              ajaxFiltering: true,
              ajaxSorting: true,
              printAsHtml: true,
              printStyled: true,
              pagination: 'remote',
              paginationSize: 20,
              paginationSizeSelector: [20, 30, 40],
              layout: 'fitColumns',
              responsiveLayout: false,
              tooltipsHeader:true,
              headerSort: false,
              dataTree:true,
              dataTreeStartExpanded:false,
              placeholder: 'No matching records found',
              columns: [
                // For HTML table
                // For HTML table
                {
                title: 'Packet No',
                field: 'packet_code',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                  if(cell.getData().packet_code) {

                    if(cell.getData().status == 2){
                    return cell.getData().packet_code
                    // return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mx-1"></i>`+cell.getData().packet_code
                    }else{
                      return cell.getData().packet_code
                    }
                  } else {
                    return '';
                  }
                }
              },
              {
                title: 'Karigar',
                field: 'karigan_name',
                vertAlign: 'middle',
                print: false,
                download: true,
                formatter(cell) {
                    return (cell.getData().first_name ? cell.getData().first_name : '') + ' ' + (cell.getData().last_name ? cell.getData().last_name : '')
                }
              },
                {
                  title: 'Total Iss. Weight',
                  minWidth:120,
                  field: 'total_issue_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Total Rec. Weight',
                  minWidth:120,
                  field: 'total_receive_weight',
                  print: false,
                  download: true
                },
                {
                  title: 'Lose Weight',
                  field: 'total_lose_weight',
                  print: false,
                  download: true,
                  minWidth:100,
                  formatter(cell) {
                    return (cell.getData().total_lose_weight ? cell.getData().total_lose_weight : 0)
                  }
                },
                {
                  title: 'Assort. Weight',
                  field: 'total_assortment_weight',
                  print: false,
                  download: true,
                  minWidth:100,
                  formatter(cell) {
                    return (cell.getData().total_assortment_weight ? cell.getData().total_assortment_weight : 0)
                  }
                },
                {
                  title: 'Pending Weight',
                  field: 'issue_weight',
                  print: false,
                  download: true,
                  formatter(cell) {
                    return (cell.getData().status == 1 ? cell.getData().issue_weight : '-')
                  }
                },
                {
                  title: 'Total Majuri',
                  field: 'total_majuri',
                  print: false,
                  download: true,
                  formatter(cell) {
                    return (cell.getData().total_majuri ? cell.getData().total_majuri : '-')
                  }
                },
                {
                  title: 'Status',
                  field: 'status',
                  vertAlign: 'middle',
                  print: false,
                  download: false,
                  formatter(cell) {

                    if(cell.getData().status == 2){
                    return `<div class="flex items-center lg:justify-center text-theme-9">
                      Received
                    </div>`
                    }else{
                      return `<div class="flex items-center lg:justify-center text-theme-6">
                      In Process
                    </div>`
                    }
                  }
                },

              ],
              renderComplete() {
                feather.replace({
                  'stroke-width': 1.5
                })
              }
            })
          }

        }
      }, 100);

    }
    const LslotTotal = () => {
      let params = {
        series_id :screenData.series_id == '0' ? '' : window.btoa(screenData.series_id),
        kapan_id :screenData.kapan_id == '0' ? '' : window.btoa(screenData.kapan_id),
        packets : screenData.packets,
        karigar_id : screenData.karigar_id == '0' ? '' : window.btoa(screenData.karigar_id),
        issue_id : screenData.issue_id == '0' ? '' : window.btoa(screenData.issue_id),
        process_id : window.btoa(pagedata.process),
        status : screenData.status == '0' ? '' :window.btoa(screenData.status),
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date,
        issue_pending : screenData.issue_pending
      }
      let promise = axios({
        url: "api/get_process_list_total",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.total = data.total

        })
        .catch(_error => {

        });
    }
    const getSeries = () => {
      let params = {
        process_id : pagedata.process,
        issue_id : window.btoa(screenData.issue_id)
      }
      let promise = axios({
        url: "api/get_process_packet_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: window.btoa(screenData.series_id),
        issue_id: window.btoa(screenData.issue_id),
        process_id : pagedata.process
      }
      let promise = axios({
        url: "api/get_process_packet_kapan",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getPackets = () => {
      let params = {
        series_id: window.btoa(screenData.series_id),
        kapan_id: window.btoa(screenData.kapan_id),
        issue_id: window.btoa(screenData.issue_id),
        process_id : pagedata.process
      }
      let promise = axios({
        url: "api/get_process_packet_packets",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.packets = []
          pagedata.packets = data.items

        })
        .catch(_error => {
          screenData.packets = []
          pagedata.packets = []
        });
    }
    const getEmployees = () => {
      let params = {
        process: pagedata.process,
        issue_id: window.btoa(screenData.issue_id),
      }
      let promise = axios({
        url: "api/dp_karigar_by_user",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    const getManagers = () => {
      let params = {
        process: pagedata.process,
      }
      let promise = axios({
        url: "api/dp_manager_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.managers = data.items

        })
        .catch(_error => {

        });
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.karigar_id = '0'
      screenData.issue_id = '0'
      screenData.status = '0'
      screenData.issue_pending = true
      screenData.packets = []

    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function seriesChange() {

      getKapans()
      screenData.kapan_id = '0'
    }
    function kapanChange() {

      if(screenData.kapan_id == '0') {
        screenData.packets = []
        pagedata.packets = []
      } else {
        getPackets()
      }
    }

    function generateReport() {
      initTabulator()
      LslotTotal()
    }

    onMounted(() => {
      checkPermission()
      getSeries()
      getEmployees()
      getManagers()
      getKapans()
    })

    return {
      tableRef,
      pagedata,
      printData,
      screenData,userDetails,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      seriesChange,
      kapanChange,
      generateReport
    }
  }
})
</script>
