<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Final Polish Transfer</h2>
    </div>
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-4 pb-6">
            <div class="flex items-center justify-between">
              <div class="text-xl font-medium leading-8">Certificate Lot</div>
              <div class="text-xl font-medium leading-8">{{ total_arr.certy_packets ? total_arr.certy_packets : '0' }} Pkts</div>
            </div>
            <div class="flex items-start justify-between text-right">
              <div class="text-gray-600 text-sm"></div>
              <div class="text-base text-center text-gray-600 mt-1">{{ total_arr.certy_weight ? $h.myFloat(total_arr.certy_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-4 pb-6">
            <div class="flex items-center justify-between">
              <div class="text-xl font-medium leading-8">Non Certificate <br> Lot</div>
              <div>
                <div class="text-xl font-medium leading-8">{{ total_arr.non_certy_packets ? $h.myFloat(total_arr.non_certy_packets) : 0 }} Pkts</div>
                <div class="text-base text-center text-gray-600 mt-1">{{ total_arr.non_certy_weight ? $h.myFloat(total_arr.non_certy_weight) + ' ct' : '0.00 ct' }}</div>

              </div>
            </div>
            <!-- <div class="flex items-start justify-between text-right">
              <div class="text-gray-600 text-sm"></div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-4 pb-6">
            <div class="flex items-center justify-between">
              <div class="text-xl font-medium leading-8">Natural Lot</div>
              <div class="text-xl font-medium leading-8">{{ total_arr.natural_packets ? total_arr.natural_packets : '0' }} Pkts</div>
            </div>
            <div class="flex items-start justify-between text-right">
              <div class="text-gray-600 text-sm"></div>
              <div class="text-base text-center text-gray-600 mt-1">{{ total_arr.natural_weight ? $h.myFloat(total_arr.natural_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-4 pb-6">
            <div class="flex items-center justify-between">
              <div class="text-xl font-medium leading-8">CVD Lot</div>
              <div class="text-xl font-medium leading-8">{{ total_arr.cvd_packets ? $h.myFloat(total_arr.cvd_packets) + ' ct' : '0.00 ct' }}</div>
            </div>
            <div class="flex items-start justify-between text-right">
              <div class="text-gray-600 text-sm"></div>
              <div class="text-base text-center text-gray-600 mt-1">{{ total_arr.cvd_weight ? $h.myFloat(total_arr.cvd_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Issue Details</h2>

          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-6 md:col-span-4 xl:col-span-3">
                <div class="py-1">
                  <label for="update-profile-form-8" class="form-label"
                    >Lot Range Type</label
                  >
                  <select id="update-profile-form-8" class="form-select" v-model="form_data.range_type">
                    <option value="1" selected>Date Wise</option>
                    <option value="2">Series/ Kapan Wise</option>
                  </select>
                </div>

              </div>
              <div class="col-span-6 md:col-span-4 xl:col-span-3">

                <div class="py-1">
                  <label for="update-profile-form-7" class="form-label"
                      >Select Series</label>
                  <TomSelect
                    v-model="form_data.series_id"
                    :options="{
                    placeholder: 'Select Series',
                    }"
                    multiple
                    @change="changeSeries"
                    class="w-full">
                    <option value="">Select Series</option>
                    <option v-for="series in series_arr" :key="series.id" :value="series.id">{{series.series_code}}</option>
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 xl:col-span-3">

                <div class="py-1">
                  <label for="update-profile-form-7" class="form-label"
                      >Select Kapan</label>
                  <TomSelect
                    v-model="form_data.kapan_id"
                    :options="{
                    placeholder: 'Select Kapan',
                    }"
                    multiple
                    class="w-full">
                    <option value="">Select Kapan</option>
                    <option v-for="kapan in kapan_arr" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 xl:col-span-3">
                <div class="py-1">
                  <label for="update-profile-form-7" class="form-label"
                      >Select Manager</label>
                    <TomSelect
                    v-model="form_data.manager_id"
                    :options="{
                    placeholder: 'Select Manager',
                    }"
                    class="w-full">
                    <option value="">Select Manager</option>
                    <option v-for="manager_item in managers_arr" :key="manager_item.id" :value="manager_item.id">{{manager_item.first_name}} {{manager_item.last_name}}</option>
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 xl:col-span-3">
                <div class="py-1">
                  <label for="update-profile-form-7" class="form-label"
                      >Start Date</label>
                      <input
                        type= "date"
                        id="update-profile-form-13"
                        class="form-control" :disabled="form_data.range_type != 1"
                        placeholder=""
                        v-model="form_data.s_date"
                      />
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 xl:col-span-3">
                <div class="my-1">
                  <label for="update-profile-form-7" class="form-label"
                      >End Date</label>
                      <input
                        type= "date"
                        id="update-profile-form-13"
                        class="form-control" :disabled="form_data.range_type != 1"
                        placeholder=""
                        v-model="form_data.e_date"
                      />
                </div>
              </div>
              <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <button class="btn btn-secondary px-3 py-2 mt-8 w-full" @click="reset_details">
                  Reset
                </button>
              </div>
              <div class="col-span-3 md:col-span-2 xl:col-span-2">
                <button class="btn btn-primary py-2 mt-8" @click="get_lot_details" :disabled="data_loading == true || form_status == true">
                  Add Lot <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="data_loading == false"/>
                </button>
              </div>
              <div class="col-span-12 xl:col-span-12">
                <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Selected Lot No</h2>
                </div>
                <div class="border mt-5 p-0 shadow-xl">
                  <div class="overflow-x-auto tableFixHead">
                    <table class="w-full text-center text-xs">
                      <thead>
                        <tr class="bg-theme-14">
                          <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                          <th class="border-b py-2 px-2 dark:border-dark-5">Series</th>
                          <th class="border-b py-2 px-2 dark:border-dark-5">Kapan</th>
                          <th class="border-b py-2 px-2 dark:border-dark-5">Packets</th>
                          <th class="border-b py-2 px-2 dark:border-dark-5">Weight ((Carat))</th>
                          <th class="border-b py-2 px-2 dark:border-dark-5">View Lot</th>
                          <!-- <th class="border-b py-2 px-2 dark:border-dark-5">Action</th> -->
                        </tr>
                        <tr class="bg-gray-200">
                          <td class="border-b py-2 px-2">Total</td>
                          <td class="border-b py-2 px-2"></td>
                          <td class="border-b py-2 px-2"></td>
                          <td class="border-b py-2 px-2">{{ total_packets }}</td>
                          <td class="border-b py-2 px-2">{{ $h.myFloat(total_weight) }}</td>
                          <td class="border-b py-2 px-2"></td>
                          <!-- <td class="border-b py-2 px-2">-</td> -->
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in fields" :key="index">
                          <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                          <td class="border-b py-2 px-2">{{ field.series_code }}</td>
                          <td class="border-b py-2 px-2">{{ field.kapan_code }}</td>
                          <td class="border-b py-2 px-2">{{ field.packet }}</td>
                          <td class="border-b py-2 px-2">{{ field.weight }}</td>
                          <td class="border-b py-2 px-2"><button class="btn btn-primary py-2 px-2" @click="get_lot_details_modal(field.kapan_id)">View</button></td>
                          <!-- <td class="border-b py-2 px-2">
                            <button type="button" class="text-theme-6 flex items-center" @click="removeField(field.id)" >
                              <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                            </button>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="data_loading == true" class="text-md font-medium mr-auto text-center my-8">
                    Loading
                  </div>
                  <div v-if="fields.length == 0 && data_loading == false" class="text-md font-medium mr-auto text-center my-8">
                    Records Not Found
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto" @click="submitRoughForm" :disabled="form_status == true || data_loading == true">
                Save
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="form_status == false"/>
              </button>

            </div>

        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
     <!--- BEGIN: Product Purchases Model --->
     <div id="kapan_lot_modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Series/ Kapan List</h2>
              <div>
                <a data-dismiss="modal" href="javascript:;">
                  <XIcon class="w-8 h-8 text-gray-500" />
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body py-5 px-5">


            <div class="border mt-2 p-0 shadow-xl">

              <div class="overflow-x-auto tableFixHead">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Lot No</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Weight</th>
                    </tr>
                    <tr class="bg-gray-200">
                      <td class="border-b py-2 px-2">Total</td>
                          <td class="border-b py-2 px-2">{{ sub_total_packets }}</td>
                          <td class="border-b py-2 px-2">{{ $h.myFloat(sub_total_weight) }}</td>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in sub_fields" :key="index">
                          <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                          <td class="border-b py-2 px-2">{{ field.packet_code }}</td>
                          <td class="border-b py-2 px-2">{{ field.weight}}</td>
                          <!-- <td class="border-b py-2 px-2">
                            <button type="button" class="text-theme-6 flex items-center" @click="removeField(field.id)" >
                              <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                            </button>
                          </td> -->
                        </tr>
                      </tbody>
                </table>
              </div>
              <div v-if="sub_fields.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--- END: Product Purchases Model --->
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import moment from 'moment'
import { swal } from 'sweetalert2/dist/sweetalert2'
import cash from 'cash-dom'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tender_update_id : '',
      transaction_no: 'Auto',

      stone_type: '',
      issue_type: 1,
      issue_block: 3,

      process:'',
      process_ar:[],
      karigar:'',
      process_party:'',
      scan_lot_no:'',
      by_lot_no:'',
      karigar_ar:[],
      kapan_ar:[],
      party_ar:[],
      series_ar:[],

      selected_series:'',
      selected_managers:'',
      vendors:[],
      stones:[],
      status: '1',
      scanload:false,
      scanload_by_no:false,
      total_scan_pack:0,
      fields: [],
      sub_fields: [],

      form_data: {
        series_id: [],
        kapan_id: [],
        manager_id: "",
        s_date: "",
        e_date:"",
        range_type: "1",
      },
      final_data: {
        series_id: [],
        kapan_id: [],
        manager_id: "",
        s_date: "",
        e_date:"",
        range_type: "1",
      },

      kapan_arr: [],
      series_arr: [],
      managers_arr:[],
      form_status:false,
      data_loading:false,
      total_arr: [],

      total_weight: 0,
      total_packets: 0,
      sub_total_weight: 0,
      sub_total_packets: 0,

      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {

  },
  mounted(){
  this.getManagers();
  this.getSeriesDetails();
  // this.getKapanDetails();
  this.get_total_by_category_packet_details();

  },

  methods: {
    changeSeries(){
      this.form_data.kapan_id = []
      this.getKapanDetails()
    },
    async getManagers() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/get_polish_ready_managers",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.managers_arr = data.items;

        })
        .catch(_error => {

        });
    },
    async getSeriesDetails() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/get_polish_done_series_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.series_arr = data.items;

        })
        .catch(_error => {
          this.series_arr = [];

        });
    },
    async getKapanDetails() {
      let _this = this;
      const params = {
        "series_id": this.form_data.series_id.length == 0 ? "" : this.form_data.series_id
      };
      let promise = axios({
        url: "api/get_polish_done_kapan_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.kapan_arr = data.items;

        })
        .catch(_error => {
          this.kapan_arr = [];

        });
    },
    async get_lot_details() {

      if(this.form_data.range_type == 1 && (this.form_data.s_date == "" || this.form_data.e_date == "")) {
        this.$swal("Warning!", "Select Date Range First!", "warning");
        return
      }
      if(this.form_data.range_type == 2 && (this.form_data.series_id.length == 0 && this.form_data.kapan_id.length == 0)) {
        this.$swal("Warning!", "Select Series/Kapan First!", "warning");
        return
      }

      this.final_data.series_id = this.form_data.series_id
      this.final_data.kapan_id = this.form_data.kapan_id
      this.final_data.s_date = this.form_data.s_date
      this.final_data.e_date = this.form_data.e_date
      this.final_data.manager_id = this.form_data.manager_id
      this.final_data.range_type = this.form_data.range_type


      this.data_loading = true
      this.fields = []
      this.total_packets = 0;
      this.total_weight = 0

      let params = {
        "series_id": this.form_data.series_id.length == 0 ? "" : this.form_data.series_id,
        "kapan_id": this.form_data.kapan_id.length == 0 ? "" : this.form_data.kapan_id,
        "s_date": this.form_data.s_date == "" ? "" : window.btoa(this.form_data.s_date),
        "e_date": this.form_data.e_date == "" ? "" : window.btoa(this.form_data.e_date),
        "manager_id": this.form_data.manager_id == "" ? "" : window.btoa(this.form_data.manager_id),
        "range_type": this.form_data.range_type == "" ? "" : window.btoa(this.form_data.range_type),
      }

      let api_url = "api/get_scanned_polish_transfer_details"

      let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
        .then((result) => result.data)
        .then((data) => {
          if(data.packet_details.length == 0){
            // this.$swal("Warning!", "Select Series/Kapan First!", "warning");
            console.warn(data)
              this.$swal("Warning", data.error_msg, "warning");
              this.data_loading = false
          } else {
              this.fields = data.packet_details
              this.total_packets = data.total_weight.packets;
              this.total_weight = data.total_weight.weight
              this.data_loading = false

          }
        })
        .catch((_error) => {
          this.fields = []
          this.data_loading = false
          return
        });
    },
    async get_lot_details_modal(id) {

      if(this.form_data.range_type == 1 && (this.form_data.s_date == "" || this.form_data.e_date == "")) {
        this.$swal("Warning", "Select Date Range First!", "warning");
        return
      }
      if(this.form_data.range_type == 2 && (this.form_data.series_id.length == 0 && this.form_data.kapan_id.length == 0)) {
        this.$swal("Warning", "Select Series/Kapan First!", "warning");
        return
      }

      this.sub_fields = []
      this.sub_total_packets = 0;
      this.sub_total_weight = 0

      let params = {
        "kapan_id": window.btoa(id),
        "s_date": this.final_data.s_date == "" ? "" : window.btoa(this.final_data.s_date),
        "e_date": this.final_data.e_date == "" ? "" : window.btoa(this.final_data.e_date),
        "manager_id": this.final_data.manager_id == "" ? "" : window.btoa(this.final_data.manager_id),
        "range_type": this.final_data.range_type == "" ? "" : window.btoa(this.final_data.range_type),
      }

      let api_url = "api/get_scanned_polish_transfer_details_kapan"

      cash('#kapan_lot_modal').modal('show')

      let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
        .then((result) => result.data)
        .then((data) => {
          if(data.packet_details == 'No'){
              this.$swal("Warning!", data.error_msg, "warning");
            }else {
              this.sub_fields = data.packet_details
              this.sub_total_packets = data.total_weight.packets;
              this.sub_total_weight = data.total_weight.weight

            }
          })
          .catch((_error) => {
          this.sub_fields = []
          return
        });
    },
    async get_total_by_category_packet_details() {

      let params = {

      }

      let api_url = "api/get_total_by_category_packet_details"

      let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
        .then((result) => result.data)
        .then((data) => {
          this.total_arr = data.items
        })
        .catch((_error) => {
          this.total_arr = []
          return
        });
    },

    async submitRoughForm(){
      this.form_status = true;
      //let tag_ar = [this.vueSelectForm.multiple];
      var total_scanned_pack = this.fields.length;
      if(this.total_packets < 1 ){
          this.scan_lot_no = "";
          this.$swal("Error!", "Packet List can not be empty, add packet!", "error");
          this.form_status = false;
          return;
      }

      if(this.form_data.manager_id == ''){
        this.$swal("Warning!", "Select Manager", "warning");
        this.form_status = false;
        return;
      }

      this.$swal({
        title: "Are you sure?",
        text: "You Want to Transfer Selected Packets!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){

            let data = {
              "series_id": this.final_data.series_id.length == 0 ? "" : this.final_data.series_id,
              "kapan_id": this.final_data.kapan_id.length == 0 ? "" : this.final_data.kapan_id,
              "s_date": this.final_data.s_date == "" ? "" : window.btoa(this.final_data.s_date),
              "e_date": this.final_data.e_date == "" ? "" : window.btoa(this.final_data.e_date),
              "manager_id": this.form_data.manager_id == "" ? "" : window.btoa(this.form_data.manager_id),
              "range_type": this.final_data.range_type == "" ? "" : window.btoa(this.final_data.range_type),
            }
            let url_post = "api/final_packet_done_transfer";
            let promise = axios({
              url: url_post,
              method: "post",
              data: data,
              baseURL: BASE_URL,
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
              config: { headers: { Accept: "application/json" } },
            });
            return promise
              .then((result) => result.data)
              .then((data) => {

                this.$swal("Success",data.message,"success");
                this.fields = []
                this.total_packets = 0
                this.total_weight = 0
                this.form_data.series_id = []
                this.form_data.kapan_id = []
                this.form_data.s_date = ""
                this.form_data.e_date = ""

                this.get_total_by_category_packet_details()

                this.form_status = false;
              })
              .catch((_error) => {
                this.form_status = false;
                return [];
              });

          } else {
            this.form_status = false;
          }
      })
    },
    reset_details() {
      this.form_data.series_id = []
      this.form_data.kapan_id = []
      this.form_data.s_date = ""
      this.form_data.e_date = ""
    }
  },

})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 700px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>

