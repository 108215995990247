<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Transaction View</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Transaction Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
              <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#new-order-modal-receive"
              class="btn btn-primary shadow-md mr-2"
              v-if="cr_status != 2 && issue_type == 1 && process_id != 18 && process_id != 19 && process_id != 20 && process_id != 32">
              Take Return
              </a>

              <button
              class="btn btn-primary shadow-md mr-2"
              v-if="cr_status != 2 && issue_type == 1 && process_id != 18 && process_id != 19 && process_id != 20 && process_id != 32"
              @click="takeFullReturn">
              Take Full Return
              </button>

               <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#new-order-modal-receive"
              class="btn btn-primary shadow-md mr-2"
              v-if="cr_status != 2 && issue_type == 3">
              Take Return
              </a>

              <button
              class="btn btn-primary shadow-md mr-2"
              v-if="cr_status != 2 && issue_type == 3"
              @click="takeFullReturn">
              Take Full Return
              </button>
            </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Transaction No. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label"> {{ transaction_code }}</label>
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold">
                    Bunch Type : &nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">
                      <span v-if="bunch_type == 1">Kapan</span>
                      <span v-if="bunch_type == 2">Series</span>
                      <span v-if="bunch_type == 3">Packets</span>
                    </label>
                  </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue For : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <span v-if="issue_type == 1">Process</span>
                    <span v-if="issue_type == 2">Department Transfer</span>
                    <span v-if="issue_type == 3">Outside Party</span></label>
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Packets : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"><span>{{ issue_pieces }}</span></label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"><span>{{  $h.myFloat(issue_weight) }}</span></label>
                </div>
              </div>
              <div class="col-span-4 xl:col-span-4">

                 <div class="" v-if='issue_type == 1 || issue_type == 3'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Process Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ process_name }}</label>
                </div>
                <div class="mt-3" v-if='issue_type == 1'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Worker Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                   <span v-if="(process_id == 2 || process_id == 29 || process_id == 27 || process_id == 28 || process_id == 3 || process_id == 31)"> {{ karigar_manager_first_name }} {{ karigar_manager_last_name }} </span>
                   <span v-else-if="(process_id == 32)"> {{ pp_first_name }} {{ pp_last_name }} </span>
                   <span v-else-if="(process_id == 18 || process_id == 19 || process_id == 20)"> {{ planner_first_name }} {{ planner_last_name }} </span>
                   <span v-else-if="(process_id != 2 && process_id != 29 && process_id != 3 && process_id != 18 && process_id != 19 && process_id != 31)"> {{ karigar_first_name }} {{ karigar_last_name }} </span>
                  </label>
                </div>
                <div class="mt-3" v-if='issue_type == 3'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Party Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                   <span> {{ prop_first_name }} {{ prop_last_name }} ({{prop_company_name}}) </span>
                  </label>
                </div>
                <div class="mt-3" v-if='issue_type == 2'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Manager Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                   <span> {{ manager_first_name }} {{ manager_last_name }} </span>
                  </label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Date Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ created_at }}</label>
                </div>
                <div class="mt-3" v-if="issue_type == 1">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Return Date Time : &nbsp;</label>
                    <label for="update-profile-form-6" class="form-label"> {{ receive_date }}</label>
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Status : &nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">
                    <span v-if="cr_status == 1 && issue_type == 1" class="text-theme-9 font-bold">In Process</span>
                    <span v-if="cr_status == 2 && issue_type == 1" class="text-theme-6 font-bold">Returned</span>
                    <span v-if="cr_status == 1 && issue_type == 3" class="text-theme-9 font-bold">In Process (Party)</span>
                    <span v-if="cr_status == 2 && issue_type == 3" class="text-theme-6 font-bold">Returned</span>
                    <span v-if="cr_status == 3 && issue_type == 1" class="text-theme-7 font-bold">Merged</span>
                    <span v-if="trans_status == 1 && issue_type == 2" class="text-theme-7 font-bold">On Hold</span>
                    <span v-if="trans_status == 3 && issue_type == 2" class="text-theme-6 font-bold">Cancelled</span>
                    <span v-if="trans_status == 2 && issue_type == 2" class="text-theme-9 font-bold">Accepted</span>
                    </label>
                </div>
              </div>
              <div class="col-span-4 xl:col-span-4" v-if='planner_type == 11 || planner_type == 12'>
                  <div class="">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Rough Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"><span>{{ $h.myFloat(issue_weight) }}</span></label>
                </div>
                 <div class="mt-3" v-if='planning_rough_weight'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Planning MW Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(planning_rough_weight) }} ( {{ $h.myFloat(planning_rough_weight *100 / issue_weight) }} % ) </label>
                </div>
                 <div class="mt-3" v-if='planning_polish_weight'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Planning PW Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(planning_polish_weight) }} ( {{ $h.myFloat(planning_polish_weight *100 / issue_weight) }} % )</label>
                </div>
                 <div class="mt-3" v-if='checker_rough_weight && user_details.user_type == 12'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Checker MW Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(checking_rough_weight) }} ( {{ $h.myFloat(checking_rough_weight *100 / issue_weight) }} % ) </label>
                </div>
                 <div class="mt-3" v-if='checker_polish_weight && user_details.user_type == 12'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Checker PW Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(checking_polish_weight) }} ( {{ $h.myFloat(checking_polish_weight *100 / issue_weight) }} % )</label>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div class="intro-y box mt-10">
        <div class="col-span-12 xl:col-span-12">
            <div
              class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5"
            >
            <h2 class="font-medium text-base mr-auto pl-5">Selected Lot No</h2>
            </div>
            <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12">
              <div class="border" v-if="manager_type != 25">
                <div class="grid grid-cols-12 sticky top-0 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                    <p>Sr. No</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                    <p>Lot No</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center" v-if="process_id == 40">
                    <p>Stock No</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                    <p>Weight(Ct)</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center" v-if='planner_type != 11 && planner_type != 12'>
                    <p>Purity</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center" v-if='planner_type != 11 && planner_type != 12'>
                    <p>Ex. Weight</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center" v-if='planner_type != 11 && planner_type != 12'>
                    <p>Color</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                    <p>Est. MW</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                    <p>Est. PW</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center" v-if='planner_type == 11 || planner_type == 12'>
                    <p>Cmp. Rate</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center" v-if='planner_type == 11 || planner_type == 12'>
                    <p>Repo Rate</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center" v-if='planner_type == 11 || planner_type == 12'>
                    <p>Back</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1  text-center" v-if='planner_type != 11 && planner_type != 12'>
                    <p>Status</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                    <p></p>
                  </div>
                </div>

                <div class="border" v-for="(field,index) in fields" :key="index">
                  <div class="grid grid-cols-12">
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                      <label class="p-2 mt-2">{{index+1}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center">
                      <label class="p-2 mt-3" v-if="process_id == 32 || process_id == 33 || process_id == 42">{{field.lot_no }}</label>
                      <label class="p-2 mt-3" v-else>{{field.pack_no}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center" v-if="process_id == 40">
                      <label class="p-2 mt-3">{{field.ref_no }}</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                      <label class="p-2 mt-3" v-if="process_id == 32 || process_id == 33 || process_id == 42">{{field.cr_wt ? $h.myFloat(field.cr_wt) : '0'}} Ct</label>
                      <label class="p-2 mt-3" v-else>{{field.weight ? $h.myFloat(field.weight) : '0'}} Ct</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center" v-if='planner_type != 11 && planner_type != 12'>
                      <label class="p-2 mt-2">{{field.purity}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center" v-if='planner_type != 11 && planner_type != 12'>
                      <label class="p-2 mt-2">{{field.ex_weight}} Ct</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if='planner_type != 11 && planner_type != 12'>
                        <label class="p-2 mt-2">{{field.color}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                        <label class="p-2 mt-2">{{field.planning_mw ? $h.myFloat(field.planning_mw) : '0'}} {{field.planning_mw ? ' (' + parseFloat(field.planning_mw * 100 / field.weight).toFixed(2) + ' %) '  : '0'}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                        <label class="p-2 mt-2">{{field.planning_pw ? $h.myFloat(field.planning_pw) : '0'}} {{field.planning_pw ? ' (' + parseFloat(field.planning_pw * 100 / field.weight).toFixed(2) + ' %) '  : '0'}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                        <label class="py-2 mt-2">{{field.total_companey_rate ? $h.myFloat(field.total_companey_rate) : '0'}} $</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                        <label class="py-2 mt-2">{{field.total_repo_rate ? field.total_repo_rate : '0'}} $</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if='planner_type == 11 || planner_type == 12'>
                        <label class="py-2 mt-2">{{field.total_back_discount}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if='planner_type != 11 && planner_type != 12'>
                      <label class="p-2 mt-3 text-theme-9" v-if="field.sub_trans_status == 1">In Process</label>
                        <label class="p-2 mt-3 text-theme-6" v-if="field.sub_trans_status == 2">Returned</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2" v-if="field.status == 1">
                        <button class="btn btn-primary" @click="showPlans(index)">Plans</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border" v-if="manager_type == 25">
                <div class="grid grid-cols-12 sticky top-0 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                    <p>Sr. No</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                    <p>Kapan No</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                    <p>Pieces</p>
                  </div>
                  <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                    <p>Weight(Ct)</p>
                  </div>
                </div>

                <div class="border" v-for="(field,index) in fields" :key="index">
                  <div class="grid grid-cols-12">
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 p-2">
                      <label class="p-2 mt-2">{{index+1}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                      <label class="p-2 mt-2">{{ field.pack_no}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 text-center">
                      <label class="p-2 mt-3">{{field.kapan_packets ? field.kapan_packets : '0'}}</label>
                    </div>
                    <div class="col-span-6 md:col-span-1 xl:col-span-1 text-center">
                      <label class="p-2 mt-3">{{field.weight ? $h.myFloat(field.weight) : '0'}} Ct</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>




        <!-- END: Input -->
      </div>
    </div>

    <div id="new-order-modal-receive" ref="new-order-modal-receive" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Take Return</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">

              <div class="col-span-12 xl:col-span-12">
                <div class="border">
              <div class="grid grid-cols-12 sticky top-0 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">

              <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                <p>Sr. No</p>
              </div>
               <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                <p>Lot No</p>
              </div>
              <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                <p>Weight(Carat)</p>
              </div>
              <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                <p>Purity</p>
              </div>
              <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                <p>Ex. Weight</p>
              </div>
              <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                <p>Color</p>
              </div>
              <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                <p>Receive Weight</p>
              </div>
              <div class="col-span-6 md:col-span-2 font-bold xl:col-span-1">
                <p></p>
              </div>
            </div>

          <div class="border" v-for="field in fields" :key="field.id">

            <div class="grid grid-cols-12">

              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-3">{{field.id}}</label>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-3">{{field.pack_no}}</label>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-3">{{field.weight}}</label>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-3">{{field.purity}}</label>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-3" v-if="process_id == 32">{{field.ex_pw}}</label>
                <label class="p-2 mt-3" v-else>{{field.ex_weight}}</label>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                  <label class="p-2 mt-3">{{field.color}}</label>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <input class="form-control" v-model="field.receive_weight" :disabled="field.sub_trans_status == 2"/>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                  <label class="p-2 mt-3 text-theme-9" v-if="field.sub_trans_status == 1">In Process</label>
                  <label class="p-2 mt-3 text-theme-6" v-if="field.sub_trans_status == 2">Returned</label>
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitReturnRough">
              Save Details
            </button>
          </div>
        </div>
      </div>
    </div>


    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">
                  </div>
                </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
      id="failed-notification-content"
      class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Something went wrong!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
    <!-- BEGIN: Closing Modal -->
    <div id="packet-plan-details-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
          <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
              <div class="modal-body py-3 px-3">
                <h2 class="font-medium text-base mr-auto mt-2">Packet : {{ pagedata.packet_number }}</h2>
                  <div class="my-2" v-if="pagedata.plans.planner">
                    <div class="py-3">
                      <h2 class="font-medium text-base mr-auto">Planner Name : {{ pagedata.planner_name }}</h2>
                    </div>
                    <div class="overflow-x-auto scrollbar-hidden">
                      <div
                        id="plannertabulator"
                        ref="plannertableRef"
                        class="mt-2"
                      ></div>
                    </div>
                  </div>
                  <div class="my-2" v-if="pagedata.plans.checker">
                    <div class="py-3">
                      <h2 class="font-medium text-base mr-auto">Checker Name : {{ pagedata.checker_name }}</h2>
                    </div>
                    <div class="overflow-x-auto scrollbar-hidden">
                      <div
                        id="checkertabulator"
                        ref="checkertableRef"
                        class="mt-2"
                      ></div>
                    </div>
                  </div>
                  <div class="my-2" v-if="pagedata.plans.main_checker">
                    <div class="py-3">
                      <h2 class="font-medium text-base mr-auto">Main Checker Name : {{ pagedata.mainchecker_name }}</h2>
                    </div>
                    <div class="overflow-x-auto scrollbar-hidden">
                      <div
                        id="maincheckertabulator"
                        ref="maincheckertableRef"
                        class="mt-2"
                      ></div>
                    </div>
                  </div>
                  <div class="text-md font-bold text-center" v-if="!pagedata.plans.planner && !pagedata.plans.checker && !pagedata.plans.main_checker && pagedata.packet_id">Plans Not Found</div>
              </div>
          </div>
      </div>
    </div>
    <!-- END: Closing Modal -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
import datetime from 'vuejs-datetimepicker';
import feather from 'feather-icons'
import { useVuelidate } from '@vuelidate/core'

// const router = useRouter();
// const route = useRoute();

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    trans_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tabulator:[],
      tabulatorkapan:[],
      user_details: this.$store.state.user.currentUser,
      programmaticallyModal:true,
       bunch_code :'',
       trans_status:'',
        bunch_type :'',
        created_at :'',
        transaction_code :'',
        series_code :'',
        kapan_code :'',
        process_id:'',
        karigar_first_name :'',
        karigar_last_name:'',
        karigar_manager_first_name:'',
        karigar_manager_last_name:'',
        planner_first_name:'',
        planner_last_name:'',
        manager_first_name:'',
        manager_last_name:'',
        pp_first_name:'',
        pp_last_name:'',
        prop_first_name:'',
        prop_last_name:'',
        prop_company_name:'',
        process_name:'',
        issue_weight:'',
        receive_weight:'',
        receive_date:'',
        cr_status:'',
        issue_type:'',
        issue_pieces:'',
        karigar_id:'',
        planner_id:'',
        planner_type:'',
        manager_id:'',
        manager_type:'',
        kapan_id:'',
        series_id:'',
        planning_done_pieces:'',
        planning_rough_weight:'',
        planning_polish_weight:'',
        fields:[],
        receivefields:[],


      cleavers:[],
      assorts:[],

      status: '1',

      receiveForm:{
        receive_weight:''
      },

      assortForm:{
        assort_type:'',
        assort_id:'',
        assort_name:'',
        total_pcs:'',
        weight:'',
        weight_per:'',
        result_weight:'',
        result_per:''
      },

      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },

  computed: {

  },
  mounted(){
  this.getRoughDetails(this.trans_id);
  this.getAssortType();
  },

    methods: {
    async getRoughDetails(trans_id) {
      let _this = this;
      const params = {
        trans_id: trans_id
      };
      let promise = axios({
        url: "api/get_packet_issue_trans",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          console.log(data.item_details);
          let rough_detail = data.item_details;

          this.bunch_code = rough_detail.bunch_code;
          this.bunch_type = rough_detail.bunch_type;
          this.created_at = rough_detail.created_at;
          this.receive_date = rough_detail.receive_date;
          this.transaction_code= rough_detail.transaction_code;
          this.series_code= rough_detail.series_code;
          this.series_id= rough_detail.series_id;
          this.kapan_code= rough_detail.kapan_code;
          this.kapan_id= rough_detail.kapan_id;
          this.karigar_first_name = rough_detail.karigar_first_name;
          this.karigar_last_name = rough_detail.karigar_last_name;
          this.manager_first_name = rough_detail.manager_first_name;
          this.manager_last_name = rough_detail.manager_last_name;
          this.karigar_manager_last_name = rough_detail.karigar_manager_last_name;
          this.karigar_manager_first_name = rough_detail.karigar_manager_first_name;
          this.planner_first_name = rough_detail.planner_first_name;
          this.planner_last_name = rough_detail.planner_last_name;
          this.pp_first_name = rough_detail.pp_first_name;
          this.pp_last_name = rough_detail.pp_last_name;
          this.prop_company_name = rough_detail.prop_company_name;
          this.prop_last_name = rough_detail.prop_last_name;
          this.prop_first_name = rough_detail.prop_first_name;
          this.process_name = rough_detail.process_name;
          this.process_id = rough_detail.process_id;
          this.issue_weight = rough_detail.issue_weight;
          this.issue_pieces = rough_detail.issue_pieces;
          this.receive_weight = rough_detail.receive_weight;
          this.cr_status = rough_detail.status;
          this.issue_type = rough_detail.issue_type;
          this.karigar_id = rough_detail.karigar_id;
          this.manager_id = rough_detail.manager_id;
          this.manager_type = rough_detail.manager_type;
          this.planner_id = rough_detail.planner_id;
          this.planner_type = rough_detail.planner_type;
          this.trans_status = rough_detail.trans_status;
          this.fields = rough_detail.packet_details;
          this.planning_done_pieces = rough_detail.planning_done_pieces;
          this.planning_rough_weight = rough_detail.planning_rough_weight;
          this.planning_polish_weight = rough_detail.planning_polish_weight;
          console.log(this.fields);

        })
        .catch(_error => {

        });
    },

    async getAssortType() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_assortment",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.assorts = data.items;
        })
        .catch(_error => {

        });
    },

    async submitReturnRough(){
      let _this = this;
      const data = {
        trans_id: this.trans_id,
        packet_details: this.fields,
      };

      // if(this.receiveForm.receive_weight > this.issue_weight){
      //   this.$swal("Warning!", "Can not return more weight than issue rough weight", "warning");
      //   return;
      // }
      //  if(this.receiveForm.receive_weight == 0){
      //   this.$swal("Warning!", "Can not return zero weight!", "warning");
      //   return;
      // }
      this.$swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/return_transaction_packet",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              _this.$router.go();
              _this.getRoughDetails();
              this.$swal("Success!", "Item Returned to Manager.", "success");
            })
            .catch(_error => {
              _this.$router.go();
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          //this.$swal("Success!", "Item Returned to Manager.", "success");
          this.$router.go();
        }
      });
    },

    takeFullReturn(){
      let _this = this;
      const data = {
        trans_id: this.trans_id,
        packet_details: this.fields,
      };

      this.$swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/return_transaction_packet_full",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if(data.status == "error"){
                this.$swal("Error!", data.message, "error");
                return;
              }else{
                 _this.$router.go();
                _this.getRoughDetails();
                this.$swal("Success!", "Item Returned to Manager.", "success");

              }

            })
            .catch(_error => {

              _this.$router.go();
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          //this.$swal("Success!", "Item Returned to Manager.", "success");
          this.$router.go();
        }
      });

    }
  },
  //Himashu Code Vue 3
  setup() {
    const pagedata = reactive({
      packet_number : '',
      planner_name : '',
      checker_name : '',
      mainchecker_name : '',
      plans: {
        planner: false,
        checker: false,
        main_checker: false,
      },
    })
    const plannertableRef = ref()
    const plannertabulator = ref()
    const checkertableRef = ref()
    const checkertabulator = ref()
    const maincheckertableRef = ref()
    const maincheckertabulator = ref()

    function showPlans(index) {
      pagedata.plans.planner = false
      pagedata.plans.checker = false
      pagedata.plans.main_checker = false
      if(this.fields[index].planning_file != null) {
        pagedata.plans.planner = true
        pagedata.planner_name = this.fields[index].planner_name
        setTimeout(() => {
          plannerPrepare(this.fields[index].packet_id, this.fields[index].last_planner)
        }, 10);
      }
      if(this.fields[index].c_change) {
        pagedata.plans.checker = true
        pagedata.checker_name = this.fields[index].checker_name
        setTimeout(() => {
        checkerPrepare(this.fields[index].packet_id, this.fields[index].c_last_planner)
        }, 10);
      }
      if(this.fields[index].mc_change) {
        pagedata.plans.main_checker = true
        pagedata.mainchecker_name = this.fields[index].mainchecker_name
        setTimeout(() => {
        maincheckerPrepare(this.fields[index].packet_id, this.fields[index].mc_last_planner)
        }, 10);
      }
      pagedata.packet_number = this.fields[index].pack_no
      cash('#packet-plan-details-modal').modal('show')
    }
    const plannerPrepare = (id, user) => {
      plannertabulator.value = new Tabulator(plannertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: id, planning_type: 10,p_id: user},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'MW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const checkerPrepare = (id, user) => {
      checkertabulator.value = new Tabulator(checkertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: id, planning_type: 11,p_id: user},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'MW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    const maincheckerPrepare = (id, user) => {
      maincheckertabulator.value = new Tabulator(maincheckertableRef.value, {
        ajaxURL: BASE_URL + '/api/planner_plan_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{packet_id: id, planning_type: 12,p_id: user},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Part Name',
            field: 'part_no',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              if(cell.getData().main_piece == 1){
              return cell.getData().part_no + `<label class="label lg:justify-center text-theme-9">
                 - Crown
              </label>`
              }else{
                return cell.getData().part_no
              }
            }
          },
          {
            title: 'Grd',
            field: 'grading',
            vertAlign: 'middle',
            print: false,
            download: true,


          },
          {
            title: 'MW',
            field: 'rough_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'PW',
            field: 'polish_weight',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },{
            title: 'Clarity',
            field: 'clarity',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Shape',
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Cut',
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Yield(%)',
            field: 'yield_per',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Value',
            field: 'value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().value > 0 ? cell.getData().value + ' $' : '-'
            }
          },
          {
            title: 'Repo Rate',
            field: 'rap_value',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().rap_value > 0 ? cell.getData().rap_value + ' $' : '-'
            }
          },
          {
            title: 'Back',
            field: 'discount_back',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().discount_back > 0 ? cell.getData().discount_back + ' %' : '-'
            }
          }

        ],
        renderComplete() {

        }
      })
    }
    return {
      pagedata,
      plannertableRef,
      checkertableRef,
      maincheckertableRef,
      showPlans
    }
  }

})
</script>
