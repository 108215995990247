<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Certy Stock / Selling Report</h2>
        <div class="flex items-center justify-end">

            <button class="btn btn-primary p-1 px-2 mx-3 text-right" @click="screenData.filter_show = !screenData.filter_show">
                <span v-if="screenData.filter_show == true">
                    <ChevronsUpIcon class="h-4 w-4" /></span>
                <span v-if="screenData.filter_show == false">
                    <ChevronsDownIcon class="h-4 w-4" /></span>
            </button>
            <div class="flex mt-5 sm:mt-0">

                <div class="dropdown w-1/2 sm:w-auto">
                    <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export
                        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                    </button>
                    <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                            </a>
                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsxAll">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX All
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5" v-if="screenData.filter_show == true">
        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3">
                <div>
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-1">Report Type</label><input class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="filter_data.lot_details" /></div>
                    <TomSelect v-model="filter_data.report_type" :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Series'
                      }
                    }
                  }" class="w-full">
                        <option value="1">Summary</option>
                        <option value="3">Series Wise</option>
                        <option value="2">Kapan Wise</option>
                        <option value="4">Party Wise</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Series</label>
                    <TomSelect v-model="filter_data.series_id" :options="{
                    placeholder: 'Select Series',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Series'
                      }
                    }
                  }" class="w-full" @change="seriesChange" multiple>
                        <option value="">Select Series</option>
                        <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filter_data.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Kapan'
                      }
                    }
                  }" class="w-full" multiple @change="kapanChange">
                        <option value="">Select Kapan</option>
                        <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Packets</label>
                    <TomSelect v-model="filter_data.packets" :options="{
                    placeholder: 'Select Packet',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Packets'
                      }
                    }
                  }" class="w-full" multiple>
                        <option value="">Select Packets</option>
                        <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Packet No</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.packet_code" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Packet No" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Stock No</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Stock No" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Certificate</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.certificate_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Certificate No" />
                </div>
            </div>
            <!-- <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div>
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-1">Select Start Date</label><input class="show-code form-check-switch mr-0 ml-3" type="checkbox" v-model="filter_data.issue_pending" /></div>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="filter_data.issue_pending" v-model="filter_data.s_date" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label pt-1">Select End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="filter_data.issue_pending" v-model="filter_data.e_date" />
                </div>
            </div> -->
            <div class="col-span-6 md:col-span-3 lg:col-span-2">

                <div class="py-1">
                    <label for="update-profile-form-7" class="form-label">Select Party</label>
                    <div class="" id="seller_id">

                        <TomSelect v-model="filter_data.party_id" :options="{
  placeholder: 'Select Party',
  }" class="w-full">
                            <option value="0">Select Party</option>
                            <option v-for="(item,index) in screenData.selling_parties" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                        </TomSelect>
                    </div>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">

                <div class="py-1">
                    <label for="update-profile-form-7" class="form-label">Select Broker</label>
                    <div class="" id="broker_id">

                        <TomSelect v-model="filter_data.broker_id" :options="{
  placeholder: 'Select Broker ',
  }" class="w-full">
                            <option value="0">Select Broker</option>
                            <option v-for="(item,index) in screenData.selling_broker" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                        </TomSelect>
                    </div>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Branch</label>
                    <TomSelect v-model="filter_data.branch_id" :options="{
                        placeholder: 'Select Branch',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Branch'
                          }
                        }
                      }" class="w-full">
                        <option value="0">All</option>
                        <option value="1">Home Branch</option>
                        <option v-for="item in screenData.branch_arr" :key="item.id" :value="item.id">{{ item.branch_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Stone</label>
                    <TomSelect v-model="filter_data.stone" :options="{
                        placeholder: 'Select Stone',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Stone'
                          }
                        }
                      }" class="w-full">
                        <option value="0">Select Stone</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Shape</label>
                    <TomSelect v-model="filter_data.shape" :options="{
                        placeholder: 'Select Shape',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Stone'
                          }
                        }
                      }" class="w-full">
                        <option value="0">Select Shape</option>
                        <option v-for="(item,index) in screenData.shape_arr" :key="index" :value="item.id">{{ item.shape_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Color</label>
                    <TomSelect v-model="filter_data.color" :options="{
                        placeholder: 'Select Color',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Color'
                          }
                        }
                      }" class="w-full">
                        <option value="0">Select Color</option>
                        <option v-for="(item,index) in screenData.color_arr" :key="index" :value="item.id">{{ item.shade_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Clarity</label>
                    <TomSelect v-model="filter_data.clarity" :options="{
                        placeholder: 'Select Clarity',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Clarity'
                          }
                        }
                      }" class="w-full">
                        <option value="0">Select Clarity</option>
                        <option v-for="(item,index) in screenData.clarity_arr" :key="index" :value="item.id">{{ item.purity_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select LAB</label>
                    <TomSelect v-model="filter_data.lab" :options="{
                        placeholder: 'Select LAB',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'LAB'
                          }
                        }
                      }" class="w-full">
                        <option value="0">Select LAB</option>
                        <option value="IGI">IGI</option>
                        <option value="GIA">GIA</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Weight Range</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.weight_range" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="0.001-0.10" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-4">
                <div class="py-1">

                    <div class="mt-5 sm:mt-7">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary" @click="initTabulator" :disabled="screenData.loading == true">
                            Go
                            <LoadingIcon v-if="screenData.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="col-span-6 md:col-span-3">
                    <div class="dropdown w-1/2 sm:w-auto">
                        <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                            <FileTextIcon class="w-4 h-4 mr-2" /> Export
                            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                        </button>
                        <div class="dropdown-menu w-40">
                            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="export_excel('csv')">
                                    <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                                </a>
                                <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="export_excel('xlsx')">
                                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                </a>

                            </div>
                        </div>
                    </div>
                </div> -->
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" v-if="screenData.loading">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Latest Records</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="overflow-x-auto scrollbar-hidden" v-if="screenData.generate == 'gen'">

                <div id="generate-pdf">
                    <div class="preview shadow-md">
                        <div class="overflow-x-auto" v-if="screenData.report_items.length > 0">
                            <template v-if="excel_filter.report_type == 1">
                                <table class="table w-full text-center border">
                                    <thead>
                                        <tr class="bg-gray-200 border">
                                            <th>Type</th>
                                            <th>Total Packets</th>
                                            <th>Total Weight</th>
                                            <th>Average Repo</th>
                                            <th>Average Rate</th>
                                            <th>Average Back</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, index) in screenData.report_items" :key="index">
                                            <!-- <tr class="border">
                                                <th>LAB Pending</th>
                                                <th>{{ item.pending_total_packets ? item.pending_total_packets : '-' }}</th>
                                                <th>{{ item.pending_total_weight ? item.pending_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.pending_average_repo ? item.pending_average_repo : '-' }}</th>
                                                <th>{{ item.pending_average_rate ? item.pending_average_rate : '-' }}</th>
                                                <th>{{ item.pending_average_back ? item.pending_average_back : '-' }}</th>
                                            </tr> -->
                                            <tr class="border">
                                                <th>LAB Process</th>
                                                <th>{{ item.process_total_packets ? item.process_total_packets : '-' }}</th>
                                                <th>{{ item.process_total_weight ? item.process_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.process_average_repo ? item.process_average_repo : '-' }}</th>
                                                <th>{{ item.process_average_rate ? item.process_average_rate : '-' }}</th>
                                                <th>{{ item.process_average_back ? item.process_average_back : '-' }}</th>
                                            </tr>
                                            <tr class="border">
                                                <th>Stock</th>
                                                <th>{{ item.stock_total_packets ? item.stock_total_packets : '-' }}</th>
                                                <th>{{ item.stock_total_weight ? item.stock_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.stock_average_repo ? item.stock_average_repo : '-' }}</th>
                                                <th>{{ item.stock_average_rate ? item.stock_average_rate : '-' }}</th>
                                                <th>{{ item.stock_average_back ? item.stock_average_back : '-' }}</th>
                                            </tr>
                                            <tr class="border">
                                                <th>Sell</th>
                                                <th>{{ item.sell_total_packets ? item.sell_total_packets : '-' }}</th>
                                                <th>{{ item.sell_total_weight ? item.sell_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.sell_average_repo ? item.sell_average_repo : '-' }}</th>
                                                <th>{{ item.sell_average_rate ? item.sell_average_rate : '-' }}</th>
                                                <th>{{ item.sell_average_back ? item.sell_average_back : '-' }}</th>
                                            </tr>
                                            <tr class="bg-gray-100 border">
                                                <th>Total</th>
                                                <th>{{ item.main_total_packets ? item.main_total_packets : '-' }}</th>
                                                <th>{{ item.main_total_weight ? item.main_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.main_average_repo ? item.main_average_repo : '-' }}</th>
                                                <th>{{ item.main_average_rate ? item.main_average_rate : '-' }}</th>
                                                <th>{{ item.main_average_back ? item.main_average_back : '-' }}</th>
                                            </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </template>
                            <template v-else-if="excel_filter.report_type == 2 || excel_filter.report_type == 3">
                                <table class="table w-full text-center border">
                                    <thead>
                                        <tr class="bg-gray-200 border">
                                            <th class="w-8 text-left">Sr.</th>
                                            <th class="text-left">{{ excel_filter.report_type == 2 ? 'Kapan' : 'Series' }}</th>
                                            <th>Type</th>
                                            <th>Total Packets</th>
                                            <th>Total Weight</th>
                                            <th>Average Repo</th>
                                            <th>Average Rate</th>
                                            <th>Average Back</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, index) in screenData.report_items" :key="index">
                                          <!-- <tr class="border">
                                                <th rowspan="5" class="text-left border w-12">{{ index + 1 }}</th>
                                                <th rowspan="4" class="text-left border">{{ item.kapan_code }}</th>
                                                <th>LAB Pending</th>
                                                <th>{{ item.pending_total_packets ? item.pending_total_packets : '-' }}</th>
                                                <th>{{ item.pending_total_weight ? item.pending_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.pending_average_repo ? item.pending_average_repo : '-' }}</th>
                                                <th>{{ item.pending_average_rate ? item.pending_average_rate : '-' }}</th>
                                                <th>{{ item.pending_average_back ? item.pending_average_back : '-' }}</th>
                                            </tr> -->
                                            <tr class="border">
                                                <th rowspan="4" class="text-left border w-12">{{ index + 1 }}</th>
                                                <th rowspan="3" class="text-left border">{{ item.kapan_code }}</th>
                                                <th>LAB Process</th>
                                                <th>{{ item.process_total_packets ? item.process_total_packets : '-' }}</th>
                                                <th>{{ item.process_total_weight ? item.process_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.process_average_repo ? item.process_average_repo : '-' }}</th>
                                                <th>{{ item.process_average_rate ? item.process_average_rate : '-' }}</th>
                                                <th>{{ item.process_average_back ? item.process_average_back : '-' }}</th>
                                            </tr>
                                            <tr class="border">
                                                <th>Stock</th>
                                                <th>{{ item.stock_total_packets ? item.stock_total_packets : '-' }}</th>
                                                <th>{{ item.stock_total_weight ? item.stock_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.stock_average_repo ? item.stock_average_repo : '-' }}</th>
                                                <th>{{ item.stock_average_rate ? item.stock_average_rate : '-' }}</th>
                                                <th>{{ item.stock_average_back ? item.stock_average_back : '-' }}</th>
                                            </tr>
                                            <tr class="border">
                                                <th>Sell</th>
                                                <th>{{ item.sell_total_packets ? item.sell_total_packets : '-' }}</th>
                                                <th>{{ item.sell_total_weight ? item.sell_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.sell_average_repo ? item.sell_average_repo : '-' }}</th>
                                                <th>{{ item.sell_average_rate ? item.sell_average_rate : '-' }}</th>
                                                <th>{{ item.sell_average_back ? item.sell_average_back : '-' }}</th>
                                            </tr>
                                            <tr class="bg-gray-100 border">
                                                <th class="text-left"></th>
                                                <th class="text-left">Total</th>
                                                <th>{{ item.main_total_packets ? item.main_total_packets : '-' }}</th>
                                                <th>{{ item.main_total_weight ? item.main_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.main_average_repo ? item.main_average_repo : '-' }}</th>
                                                <th>{{ item.main_average_rate ? item.main_average_rate : '-' }}</th>
                                                <th>{{ item.main_average_back ? item.main_average_back : '-' }}</th>
                                            </tr>
                                        </template>
                                    </tbody>

                                </table>

                            </template>
                            <template v-else-if="excel_filter.report_type == 4">
                                <table class="table w-full text-center border">
                                    <thead>
                                        <tr class="bg-gray-200 border">
                                            <th class="w-8">Sr.</th>
                                            <th class="text-left">Party</th>
                                            <th>Total Packets</th>
                                            <th>Total Weight</th>
                                            <th>Average Repo</th>
                                            <th>Average Rate</th>
                                            <th>Average Back</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, index) in screenData.report_items" :key="index">
                                            <tr class="border">
                                                <th class="text-left w-12">{{ index + 1 }}</th>
                                                <th class="text-left">{{ (item.first_name ? item.first_name : '-') + ' ' + (item.last_name ? item.last_name : '') }}</th>
                                                <th>{{ item.sell_total_packets ? item.sell_total_packets : '-' }}</th>
                                                <th>{{ item.sell_total_weight ? item.sell_total_weight + ' Ct' : '-' }}</th>
                                                <th>{{ item.sell_average_repo ? item.sell_average_repo : '-' }}</th>
                                                <th>{{ item.sell_average_rate ? item.sell_average_rate : '-' }}</th>
                                                <th>{{ item.sell_average_back ? item.sell_average_back : '-' }}</th>
                                            </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </template>
                        </div>
                        <div v-else class="text-center font-bold">Records not Found</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden mt-5" v-if="excel_filter.lot_details == true">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    computed
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import store from '@/store'
import moment from 'moment'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import FileDownload from "js-file-download";

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const userDetails = computed(() => store.state.user.currentUser)
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const screenData = reactive({
            report_items: [],
            total: [],

            generate: 'no',
            loading: false,

            branch_arr: [],
            color_arr: [],
            clarity_arr: [],
            shape_arr: [],
            selling_parties: [],
            selling_broker: [],

            filter_show: true

        })
        const pagedata = reactive({
            serieses: [],
            kapans: [],
            packets: [],
        })

        const filter_data = reactive({
            "issue_pending": false,
            "lot_details": false,
            "stock_no": "",
            "s_date": moment().startOf('month').format('YYYY-MM-DD'),
            "e_date": moment().format('YYYY-MM-DD'),
            "certificate_no": "",
            "lab": "0",
            "report_type": "1",
            "shape": "0",
            "stone": "0",
            "color": "0",
            "clarity": "0",
            "party_id": "0",
            "broker_id": "0",
            "branch_id": "0",
            "weight_range": "",
            "series_id": [],
            "kapan_id": [],
            "packet_id": [],
            "packet_code": '',
        })

        const excel_filter = reactive({
            "issue_pending": false,
            "lot_details": false,
            "stock_no": "",
            "s_date": moment().startOf('month').format('YYYY-MM-DD'),
            "e_date": moment().format('YYYY-MM-DD'),
            "certificate_no": "",
            "lab": "0",
            "shape": "0",
            "stone": "0",
            "color": "0",
            "report_type": "1",
            "clarity": "0",
            "party_id": "0",
            "broker_id": "0",
            "branch_id": "0",
            "weight_range": "",
            "series_id": [],
            "kapan_id": [],
            "packet_id": [],
            "packet_code": '',
        })

        const getSeries = () => {
            let params = {}
            let promise = axios({
                url: "api/dp_rough_series",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.serieses = data.items

                })
                .catch(_error => {

                });
        }
        const getKapans = () => {
            let params = {
                series_id: (filter_data.series_id.length > 0) ? filter_data.series_id : '',
            }
            let promise = axios({
                url: "api/get_packet_kapan_multiple",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    pagedata.kapans = data.items

                })
                .catch(_error => {

                });
        }
        const getPackets = () => {
            if (filter_data.kapan_id.length > 0) {
                let params = {
                    series_id: (filter_data.series_id.length > 0) ? filter_data.series_id : '',
                    kapan_id: (filter_data.kapan_id.length > 0) ? filter_data.kapan_id : '',
                }
                let promise = axios({
                    url: "api/get_packet_packets_multiple",
                    method: "post",
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        pagedata.packets = data.items

                    })
                    .catch(_error => {

                    });
            } else {
                pagedata.packets = []
                screenData.packets = []
            }
        }

        const initTabulator = () => {

            excel_filter.issue_pending = filter_data.issue_pending
            excel_filter.lot_details = filter_data.lot_details
            excel_filter.stock_no = filter_data.stock_no
            excel_filter.s_date = filter_data.s_date
            excel_filter.e_date = filter_data.e_date
            excel_filter.certificate_no = filter_data.certificate_no
            excel_filter.lab = filter_data.lab
            excel_filter.shape = filter_data.shape
            excel_filter.report_type = filter_data.report_type
            excel_filter.stone = filter_data.stone
            excel_filter.color = filter_data.color
            excel_filter.clarity = filter_data.clarity
            excel_filter.party_id = filter_data.party_id
            excel_filter.broker_id = filter_data.broker_id
            excel_filter.branch_id = filter_data.branch_id
            excel_filter.weight_range = filter_data.weight_range
            excel_filter.series_id = filter_data.series_id
            excel_filter.kapan_id = filter_data.kapan_id
            excel_filter.packet_id = filter_data.packet_id
            excel_filter.packet_code = filter_data.packet_code

            let params = {
                issue_pending: excel_filter.issue_pending,
                lot_details: excel_filter.lot_details,
                stock_no: excel_filter.stock_no,
                report_type: excel_filter.report_type,
                s_date: window.btoa(excel_filter.s_date),
                e_date: window.btoa(excel_filter.e_date),
                certificate_no: excel_filter.certificate_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                clarity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                party_id: excel_filter.party_id != '0' ? window.btoa(excel_filter.party_id) : '',
                broker_id: excel_filter.broker_id != '0' ? window.btoa(excel_filter.broker_id) : '',
                branch_id: excel_filter.branch_id != '0' ? window.btoa(excel_filter.branch_id) : '',
                weight_range: excel_filter.weight_range,
                packet_code: excel_filter.packet_code,
                series_id: excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id: excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id: excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            screenData.loading = true
            if (excel_filter.lot_details == true) {

                setTimeout(() => {
                    tabulator.value = new Tabulator(tableRef.value, {
                        ajaxURL: BASE_URL + `/api/get_certy_full_stock_list`,
                        ajaxConfig: {
                            method: "GET", //set request type to Position
                            headers: {
                                "Content-type": 'application/json; charset=utf-8', //set specific content type
                                "Authorization": "Bearer " + localStorage.getItem("token")
                            },
                        },
                        ajaxParams: params,
                        ajaxFiltering: true,
                        ajaxSorting: true,
                        printAsHtml: true,
                        printStyled: true,
                        layout: 'fitColumns',
                        responsiveLayout: false,
                        tooltipsHeader: true,
                        headerSort: false,
                        placeholder: 'No matching records found',
                        columns: [

                            // For HTML table
                            {
                              title: 'Stock No',
                              field: 'stock_code',
                              vertAlign: 'middle',
                              print: false,
                              minWidth:160,
                              download: true,
                              formatter(cell) {
                                if(cell.getData().active_status == 1){
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>`+cell.getData().lab_code
                                }else{
                                  return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>`+cell.getData().lab_code
                                }
                              }
                            },
                            {
                                title: 'Packet No',
                                field: 'packet_code',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 150,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Shape',
                                field: 'shape_name',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 80,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Weight',
                                field: 'lab_weight',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Return Date',
                                field: 'lab_return_date',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Color',
                                field: 'shade_name',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Purity',
                                field: 'purity_name',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Repo (%)',
                                field: 'rapo',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Sell Date',
                                field: 'sell_date',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Due',
                                field: 'payment_terms',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 50,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Due Date',
                                field: 'due_date',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Certy',
                                field: 'certificate_no',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'LAB',
                                field: 'new_lab',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Weight',
                                field: 'lab_weight',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Stone',
                                field: 'stone_type',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'RAP Price',
                                field: 'repo_rate',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'RAP Total',
                                field: 'rape_weight_price',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'EXP Back (%)',
                                field: 'exp_back',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea",
                            },
                            {
                                title: 'Cr Back (%)',
                                field: 'cr_back',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea",
                            },
                            {
                                title: 'Sell Back (%)',
                                field: 'sell_back',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'CNBC Rate',
                                field: 'cnbc_rate',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Less(%)',
                                field: 'sell_less',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Total Amount',
                                field: 'total_amount',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Brokerage (%)',
                                field: 'broker_per',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Brokerage',
                                field: 'brokerage_amount',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Branch',
                                field: 'branch_name',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Party',
                                field: 'party_name',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            },
                            {
                                title: 'Broker',
                                field: 'broker_name',
                                hozAlign: 'left',
                                vertAlign: 'middle',
                                print: false,
                                minWidth: 100,
                                download: true,
                                formatter: "textarea"
                            }
                        ],
                        renderComplete() {
                            feather.replace({
                                'stroke-width': 1.5
                            })
                        }
                    })
                }, 100);
            }

            screenData.loading = true
            const url = "/api/get_certy_full_stock_total"
            let promise = axios({
                url: url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.report_items = data.items;
                    screenData.filter_show = false
                    screenData.loading = false
                    screenData.generate = 'gen'
                })
                .catch(_error => {
                    screenData.report_items = [];
                    screenData.loading = false
                });
        }

        const get_seller_party = async () => {
            const params = {};
            const url = "/api/dp_account_selling_party"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.selling_parties = data.items;
                })
                .catch(_error => {
                    screenData.selling_parties = [];
                });
        }

        const get_selling_broker = async () => {
            const params = {};
            const url = "/api/dp_account_selling_broker"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.selling_broker = data.items;
                })
                .catch(_error => {
                    screenData.selling_broker = [];
                });
        }

        const get_branch_details = () => {
            const params = {};
            let promise = axios({
                url: "/api/dp_account_branch_office",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    screenData.branch_arr = data.items

                })
                .catch(_error => {
                    screenData.branch_arr = []

                });
        }

        const get_colors_details = () => {
            let promise = axios({
                url: "/api/dp_color",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.color_arr = data.items;
                })
                .catch(_error => {

                });
        }

        const get_shape_details = () => {
            let promise = axios({
                url: "/api/dp_shape",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.shape_arr = data.items;
                })
                .catch(_error => {

                });
        }

        const get_purity_details = () => {
            let promise = axios({
                url: "/api/dp_purity",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.clarity_arr = data.items;
                })
                .catch(_error => {

                });
        }

        const export_excel = (type) => {

            let stone_name = ""
            if (excel_filter.stone == 1) {
                stone_name = "NATURAL"
            } else if (excel_filter.stone == 2) {
                stone_name = "CVD"

            } else if (excel_filter.stone == 3) {
                stone_name = "HPHT"

            } else if (excel_filter.stone == 4) {
                stone_name = "D_CVD"

            } else if (excel_filter.stone == 5) {
                stone_name = "D_HPHT"

            }
            // let filename = 'DKStock.xlsx';
            let filename = 'DKStock' + stone_name + moment().format('Mth DD, YYYY') + '.' + type;
            let params = {
                report_items: screenData.report_items,
                type: type,
                stone_type: excel_filter.stone
            }
            let promise = axios({
                url: "/api/dawnload_selling_stock",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                })
                .catch((_error) => {
                    return [];
                });
        }

        const onResetFilter = () => {
            filter_data.issue_pending = false
            filter_data.stock_no = ""
            filter_data.s_date = moment().startOf('month').format('YYYY-MM-DD')
            filter_data.e_date = moment().format('YYYY-MM-DD')
            filter_data.certificate_no = ""
            filter_data.lab = "0"
            filter_data.shape = "0"
            filter_data.color = "0"
            filter_data.clarity = "0"
            filter_data.party_id = "0"
            filter_data.broker_id = "0"
            filter_data.branch_id = "0"
            filter_data.weight_range = ""
        }

        function printReportDetails() {
            let report_type = 'certy-stock-selling-report'
            let params = {}
            if(excel_filter.lot_details == true) {
              params = {
                  report_type: report_type,
                  filters: excel_filter,
                  items: {
                      'items': screenData.report_items,
                      // 'packet_items': tabulator.value.getData(),
                  }
              }
            } else {
              params = {
                  report_type: report_type,
                  filters: excel_filter,
                  items: {
                      'items': screenData.report_items,
                  }
              }
            }
            let promise = axios({
                url: "/api/add_report_details",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    const report_id = data.report_id
                    let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
                    window.open(url, '_blank');
                })
                .catch(_error => {

                });

            // add_report_details
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'certy-selling.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'certy-selling.json')
        }

        const onExportXlsx = () => {
            let filename = "sell_certy_stock_list.xlsx";
            let params = {
                issue_pending: excel_filter.issue_pending,
                stock_no: excel_filter.stock_no,
                report_type: excel_filter.report_type,
                s_date: window.btoa(excel_filter.s_date),
                e_date: window.btoa(excel_filter.e_date),
                certificate_no: excel_filter.certificate_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                clarity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                party_id: excel_filter.party_id != '0' ? window.btoa(excel_filter.party_id) : '',
                broker_id: excel_filter.broker_id != '0' ? window.btoa(excel_filter.broker_id) : '',
                branch_id: excel_filter.branch_id != '0' ? window.btoa(excel_filter.branch_id) : '',
                weight_range: excel_filter.weight_range,
                packet_code: excel_filter.packet_code,
                series_id: excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id: excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id: excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            let promise = axios({
                url: "/api/download_sell_certy_stock",
                method: "get",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                    // this.status = "success";

                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                })
                .catch((_error) => {
                    // this.status = "fail";
                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                    return [];
                });
        }
        const onExportXlsxAll = () => {
            let filename = "sell_certy_stock_list_all.xlsx";
            let params = {
                issue_pending: excel_filter.issue_pending,
                stock_no: excel_filter.stock_no,
                report_type: excel_filter.report_type,
                s_date: window.btoa(excel_filter.s_date),
                e_date: window.btoa(excel_filter.e_date),
                certificate_no: excel_filter.certificate_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                clarity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                party_id: excel_filter.party_id != '0' ? window.btoa(excel_filter.party_id) : '',
                broker_id: excel_filter.broker_id != '0' ? window.btoa(excel_filter.broker_id) : '',
                branch_id: excel_filter.branch_id != '0' ? window.btoa(excel_filter.branch_id) : '',
                weight_range: excel_filter.weight_range,
                packet_code: excel_filter.packet_code,
                series_id: excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id: excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id: excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            let promise = axios({
                url: "/api/download_sell_certy_stock_all",
                method: "get",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                    // this.status = "success";

                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                })
                .catch((_error) => {
                    // this.status = "fail";
                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                    return [];
                });
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'certy-selling.html', {
                style: true
            })
        }

        function seriesChange() {
            getParameters()
            filter_data.kapan_id = []
            filter_data.packets = []
        }

        function kapanChange() {
            getPackets()
            filter_data.packets = []
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }
        const getParameters = () => {
            getKapans()
            getPackets()
        }

        onMounted(() => {
            getSeries()
            getKapans()
            get_branch_details()
            get_seller_party()
            get_selling_broker()
            get_colors_details()
            get_shape_details()
            get_purity_details()
        })

        return {
            excel_filter,
            filter_data,
            screenData,
            initTabulator,
            userDetails,
            onResetFilter,
            export_excel,
            printReportDetails,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportXlsxAll,
            onExportHtml,
            onPrint,
            seriesChange,
            kapanChange,
            getParameters,
            pagedata,
            getKapans,
            getPackets,
            tableRef,
        }

    }
})
</script>
