<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Process</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      

      </div>
    </div>

    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Process Details</h2>
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-6 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Process Name<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Process name"
                    
                    v-model="process_name"
                  />
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Code<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Code"
                    
                    v-model="process_code"
                  />
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Order</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""
                    maxlength="4"
                    v-model="order"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Process Type<span class="text-theme-6">*</span></label
                  >
                   <TomSelect
                    v-model="process_type"
                    :options="{
                      placeholder: 'Select Process Type',
                      
                    }"
                    class="w-full"
                
              >
              <option value="">Select Process Type</option>
              <option value="1">In-House</option>
              <option value="2">Outsourced</option>
              
              </TomSelect>
                  
                </div>

                

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Majuri Type<span class="text-theme-6">*</span></label
                  >
                   <TomSelect
                    v-model="majuri_type"
                    :options="{
                      placeholder: 'Select Majuri Type',
                      
                    }"
                    class="w-full"
                
              >
              <option value="">Select Majuri</option>
              <option value="1">Fixed Pagar</option>
              <option value="2">Majuri by Rough Weight (Per Ct)</option>
              <option value="3">Majuri by Rough Size (Fixed Per Piece)</option>
              <option value="4">Majuri Per Ct or Per Piece</option>
              <option value="5">Majuri Per Piece(Fix Per Karigar)</option>
              <option value="6">Customized</option>
              
              </TomSelect>
                  
                </div>
                <div class="mt-3" v-if="majuri_type == 7">
                  <label for="update-profile-form-9" class="form-label"
                    >Fixed Salary</label>
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    v-model="fixed_majuri"
                  />
                </div>
              </div>

              <div class="col-span-6 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >First Late (Natural)</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Days"
                    
                    v-model="fl_days_n"
                  />
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Second Late (Natural)</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Days"
                    
                    v-model="sl_days_n"
                  />
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >First Late (CVD)</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Days"
                    
                    v-model="fl_days_cvd"
                  />
                </div>

                 <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Second Late (CVD)</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Days"
                    
                    v-model="sl_days_cvd"
                  />
                </div>
                

              </div>
            </div>

            <div class="p-5" v-if="majuri_type == 2 || majuri_type == 3 || majuri_type == 4">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <h4 class="font-medium text-base mr-auto">Majuri Details</h4>
               <div class="border">
               <div class="grid grid-cols-12 sticky top-0 z-20 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
             
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Sr. No</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Name</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>From</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>To</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Stone</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2"  v-if="majuri_type == 4">
                  <p>Type</p>
                </div>

                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Majuri(INR)</p>
                </div>
             
               </div>
       
               <div class="border" v-for="majuri_item in majuri_list" :key="majuri_item.id">
            
                <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-2">{{majuri_item.id}}</label>
               </div> 
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="majuri_item.name" type="text"
                
                    class="form-control" placeholder="Name" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="majuri_item.from" type="text"
                
                    class="form-control" placeholder="From" @keypress="$h.preventInvalidInput" onpaste="return false;">
                
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="majuri_item.to" type="text"
                    class="form-control" placeholder="To" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <select v-model="majuri_item.stone_type" class="form-control">
                  <option value="1" selected>Natural</option>
                  <option value="2">CVD</option>
                  <option value="all">Both</option>
                </select>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2" v-if="majuri_type == 4">
                <select v-model="majuri_item.weight_type" class="form-control">
                  <option value="2" selected>Per Pc</option>
                  <option value="1">Per Ct</option>
                </select>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="majuri_item.majuri" type="text"
                
                    class="form-control" placeholder="Majuri" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>
               
              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3">
              <button type="button" class="text-theme-6 flex items-center" >
                <Trash2Icon class="w-4 h-4 mr-1" @click="removeField(majuri_item.id)"/> Delete
              </button>
              </div>
                </div>
            </div>
          </div>
         
          <button type="button" @click="AddField" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
            New item
          </button>
         </div>
             
            </div>
          
          </div>
            
            <div class="flex justify-end mt-4">
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>
             
            </div>


         
        </div>
         </form>
        </div>
         <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Process Created Successfuly!</div>
            <div class="text-gray-600 mt-1">
                User added to user list.
            </div>
        </div>
         </div>
         <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Process Updated Successfuly!</div>
        </div>
         </div>
        <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>

         <div id="failed-user-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Process Creation Error!</div>
            <div class="text-gray-600 mt-1">
                Something went wrong. Kindly Refreash the page and try again.
            </div>
        </div>
         </div>
    </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
  },
  props: {
    process_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id : '',
      process_name: '',
      process_code: '',
      process_group: '',
      order:'',
      total_items:1,
      majuri_type:"",
      process_type:"",
      majuri_list:[{ id:1 ,from: '',to: '',name: '',stone_type: 1,weight_type:1,majuri: ''}],
      fixed_majuri:'',
      process_ar:[],
      status: '',
      fl_days_n: '',
      sl_days_n: '',
      fl_days_cvd: '',
      sl_days_cvd: '',
      
      loginVal: '',
      url:'',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  mounted(){
      if(this.process_id != ''){
        this.id = this.process_id;
        console.log(this.process_id);
        this.getprocessDetails(this.process_id);
        
      }
  
  },
  computed: {
    
  },

  methods: {
    
    formSubmit() {
      this.status = 'processing'
      console.log("HI");
    },
    AddField() {
      this.total_items++;
      this.majuri_list.push({ id:this.total_items,from: '',to: '',name: '',stone_type: '1',weight_type:'1',majuri: '' });
      console.log(this.total_items);
    },
    removeField(id) {
     
       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.majuri_list.length;
              while(i--){
              if(this.majuri_list[i]['id'] == id ){ 
                  this.majuri_list.splice(i,1);
              }
            }
          }
      });
    },
  
    onUserFormSubmit() {
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      if (!this.process_name) {
        this.errors.push("Process Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (!this.process_code) {
        this.errors.push("Code required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (this.majuri_type == '') {
        this.errors.push("Type required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (this.process_type == '') {
        this.errors.push("Type required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      
   
      if (!this.errors.length) {
        const params = {
        "process_name":  this.process_name,
        "process_code":  this.process_code,
        "process_group":  this.process_group,
        "process_type":  this.process_type,
        "process_order":  this.order,
        "majuri_type":  this.majuri_type,
        "majuri_per_piece":  this.fixed_majuri,
        "majuri_list":  this.majuri_list,
        "fl_days_n": this.fl_days_n,
        "sl_days_n": this.sl_days_n,
        "fl_days_cvd": this.fl_days_cvd,
        "sl_days_cvd": this.sl_days_cvd,
        "id" : this.id
        };

        console.log(params);
        if (this.process_id == "") {
          this.url = "api/add_process";
        } else {
          this.url = "api/edit_process";
        }
        console.log(this.url);
        
        axios({
          url: this.url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {

            if(this.process_id != ''){
        Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/process-master");
        }, 500)
            }else{
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/process-master");
        }, 500)
            }
                    
          })
          .catch(response => {
            Toastify({
              node: cash('#failed-user-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          });
      }
    },
    getprocessDetails(id) {
      let _this = this;
      const params = {
        ud: id
      };
      let promise = axios({
        url: "api/details_process",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.item_details);
          let process_details = data.item_details;

          this.process_name = process_details.process_name;
          this.process_type = "" + process_details.process_type;
          this.process_code = process_details.process_code;
          this.order = process_details.process_order;
          this.majuri_type = "" + process_details.majuri_type;
          if(process_details.majuri_list != null){
            this.majuri_list = process_details.majuri_list;
          }else{
            //this.majuri_list = process_details.majuri_list;
          }
          this.fl_days_n = process_details.fl_days_n;
          this.sl_days_n = process_details.sl_days_n;
          this.fl_days_cvd = process_details.fl_days_cvd;
          this.sl_days_cvd = process_details.sl_days_cvd;
          
          //this.status = user_details.active_status;
          
          if(process_details.majuri_per_piece == null){
              this.fixed_majuri = "" + 0;
          }else{
            this.fixed_majuri = process_details.majuri_per_piece;
          }
        })
        .catch(_error => {
          
        });
    },
    
    changeStatus(){
      let _this = this;
      console.log(this.user_id);
      console.log(this.status);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to change user status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id,
            user_status : window.btoa(this.status)
          };
          let promise = axios({
            url: "api/change_status_account_operator",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "Process Status Changed.", "success");
          //this.$router.go();
          _this.getuserDetails(this.user_id);
        }
      });
    }
  },
  
})
</script>

