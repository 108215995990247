<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Packet Issue Radiation</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="scan_lot_no" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="screenData.scan_lot_no"
                    :class="{'form-control':true,'loading':screenData.scanload}"

                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Scanned Packet Details</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <label for="scan_lot_no" class="form-label mt-2 mr-2"
                    >Select Party</label
                  >
              <TomSelect
                v-model="screenData.selected_party"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full mr-5"
                style="width:200px"
              >
              <option value="">Select Party</option>
              <option v-for="party in screenData.party_ar" :key="party.id" :value="party.id">{{party.first_name}} {{party.last_name}}</option>
              </TomSelect>
          <button
           class="btn btn-primary shadow-md mr-2" @click="issuePolishSubmit" :disabled="screenData.return_progress">
           Issue Packets
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress"/>
          </button>
      </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">

              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                </div>

              </div>
            <div class="col-span-4 xl:col-span-4">
               <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Pcs Weight. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}}  </label>
                </div>
            </div>
              <div class="col-span-12 ">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 500px"
              :columnDefs="columnDefs.value"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @grid-ready="onGridReady"
              :overlayNoRowsTemplate="overlayNoRowsTemplate"
            >
            </ag-grid-vue>
              </div>

            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted,inject } from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default defineComponent({
  components: {
   AgGridVue,
   ShapeRenderer
 },
  setup() {
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      scanload: false,
      party_ar:[],
      process_id: 42,
      return_progress:false,
      scan_lot_no:'',
      total_scan_pack_wt:0,
      selected_party:'',
      total_scan_pack:0,
      shapes:['ROUND','PRINCE',"EMERALD","CUSHION","RADIANT","MARQUISE","PEAR","OVEL","HEART","ROSE","CHOKI","TRIANGLE","CHAKRI","ROSE-PAN",
      "ROSE-TRIANGLE","ROSE-OVEL","ROSE-EMERALD","ROSE-MARQUISE","ROSE-HEART","ROSE-CUSHION"],
      colors:['D','E','F','G','H','I','J','K','L','M'],
      purity:['IF','VVS1','VVS2','VS1','VS2','SI1','SI2','SI3','I1','I1-','I2','I2-','I3','I3-']

    })

    const onGridReady = (params) => {
     gridApi.value = params.api;
    };

   const rowData = reactive({
    value:[]
   });

   const overlayNoRowsTemplate = 'Scan packets for Polish Issue!'

   const columnDefs = reactive({
     value: [
          { headerName: "#",field: 'sr_no',maxWidth: 60, },
          { headerName: "Packet No",field: 'lot_no',minWidth: 160 },
          { headerName: "Cr Wt",field: 'cr_wt' },
          { headerName: "Shape",
            field: 'shape',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: screenData.shapes },
            },
            { headerName: "Color",field: 'color',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: screenData.colors },
             },
          { headerName: "Purity",field: 'purity',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: screenData.purity },
            },
          { headerName: "Exp PW",field: 'ex_pw'},
     ],
   });


   const defaultColDef = {
     sortable: true,
     filter: true,
     flex: 1
   };

   const getParty = () => {
      let params = {
        process: screenData.process_id
      }
      let promise = axios({
        url: "api/dp_party_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.party_ar = data.items

        })
        .catch(_error => {

        });
    }

    //Edit user
    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
      if(codelength > 11){
        screenData.scanload = true;
        //Check already in array
        var i = rowData.value.length;
              while(i--){
              if(rowData.value[i]['barcode'] == screenData.scan_lot_no || rowData.value[i]['lot_no'] == screenData.scan_lot_no ){
                screenData.scan_lot_no = "";
                swal("Warning!", "Already in list, Scan other packet!", "warning");
                screenData.scanload = false;
                return;
              }
        }

        //get lot details
        const data = {
        barcode: screenData.scan_lot_no,
        };
        let url_post = '';
          if(screenData.scan_lot_no != ''){
          url_post = "api/get_packet_details_for_radiation_issue"
        }else{
          url_post = "api/get_packet_details_for_radiation_issue"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          if(data.packet_details == 'No'){
              swal("Warning!", data.error_msg, "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot_no"]').focus();
          }else if(data.packet_details == 'process'){
              swal("Warning!", "Scanned barcode is already in process", "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot_no"]').focus();
          }else {
            let pack_details = data.packet_details;
            let plan_details = data.last_plan;
            let last_history = data.last_history;
            if(plan_details == null){
              swal("Warning!", "Packet does not have main piece plan", "warning");
              screenData.scanload = false;
              return;
            }
              screenData.total_scan_pack = screenData.total_scan_pack + 1;
              console.log('Push data');
              rowData.value.push(
              {
                "sr_no":screenData.total_scan_pack,
                "lot_no":pack_details.packet_code,
                "barcode": pack_details.barcode_no,
                "packet_id": pack_details.id,
                "last_history_id": last_history.id,
                "cr_wt" : pack_details.cr_weight,
                "shape" : pack_details.shape_name,
                "purity" : pack_details.purity_name,
                "color" : pack_details.color_name,
                "ex_pw" : plan_details.polish_weight,
              });

              screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.cr_weight);

              //gridApi.value.setRowData([]);
              gridApi.value.setRowData(rowData.value);
              screenData.scan_lot_no = "";
              screenData.scanload = false;
              //console.log(gridApi.value.refreshCells());
              focusLstRow();
              console.log(rowData.value);
              //document.querySelector('[id="scan_lot"]').focus();
          }
          })
          .catch((_error) => {
            screenData.scanload = false;
            return [];
          });
      }
    }

    const focusLstRow = () => {
      // gridApi.value.ensureIndexVisible(rowData.value.length - 1);
      // gridApi.value.setFocusedCell(rowData.value.length - 1,'cr_wt');
      // gridApi.value.startEditingCell(
      // {
      //   rowIndex: rowData.value.length - 1,
      //   colKey: 'cr_wt',
      // }
      // );
      document.querySelector('[id="scan_lot_no"]').focus();
    }


    const issuePolishSubmit = () => {

      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }
      if(screenData.selected_party == ''){
        swal("Error!", "Kindly select party to Issue", "error");
        return;
      }
      const data = {
        packet_details: rowData.value,
        process_id: screenData.process_id,
        manager_id: '',
        selected_party : screenData.selected_party
      };

      console.log(data);

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to issue packets for Radiation!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Issue",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/issue_packets_for_radiation",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                swal("Success!", "Item Issued to Party.", "success");
                rowData.value = []
                screenData.selected_party = ''
                screenData.total_scan_pack_wt = 0
                screenData.total_scan_pack = 0
                document.querySelector('[id="scan_lot_no"]').focus();
                //screenData.return_progress = false;
                // router.go();
              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;

        }
      });
    }

    onMounted(() => {
     rowData.value = []
     getParty()
     setTimeout(() => {
      document.querySelector('[id="scan_lot_no"]').focus();
     }, 300);
    })


    return {
      filter,
      screenData,
      scanAndAddPack,
      onGridReady,
      issuePolishSubmit,
      overlayNoRowsTemplate,
      getRowId,
      columnDefs,
      rowData,
      defaultColDef,
      // cellWasClicked: (event) => { // Example of consuming Grid Event
      //   console.log("cell was clicked", event);
      // },
      deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }

  }
})
</script>

