<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish - Purchase (Certy)</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Issue Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <!-- <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1" v-if="page_data.stone_disable == true">
                                <label for="update-profile-form-7" class="form-label">Select Stone <span class="text-primary-3"> *</span></label>
                                <div class="" id="stone_type">

                                    <TomSelect v-model="form_data.stone_type" disabled :options="{
                          placeholder: 'Select Stone',
                          }" class="w-full">
                                        <option value="0">Select Stone</option>
                                        <option value="1">NATURAL</option>
                                        <option value="2">CVD</option>
                                    </TomSelect>
                                </div>
                            </div>
                            <div class="py-1" v-else>
                                <label for="update-profile-form-7" class="form-label">Select Stone <span class="text-primary-3"> *</span></label>
                                <div class="" id="stone_type">

                                    <TomSelect v-model="form_data.stone_type" :options="{
                          placeholder: 'Select Stone',
                          }" class="w-full">
                                        <option value="0">Select Stone</option>
                                        <option value="1">NATURAL</option>
                                        <option value="2">CVD</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Party <span class="text-primary-3"> *</span></label>
                                <div class="" id="party_id">

                                    <TomSelect v-model="form_data.party_id" :options="{
                          placeholder: 'Select Party',
                          }" class="w-full">
                                        <option value="0">Select Party</option>
                                        <option v-for="(item,index) in page_data.purchase_parties" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Branch</label>
                                <div class="" id="broker_id">

                                    <TomSelect v-model="form_data.branch_id" :options="{
                          placeholder: 'Select Broker ',
                          }" class="w-full">
                                        <option value="">Select Branch</option>
                                        <option value="1">Home Branch</option>
                                        <option v-for="(item,index) in page_data.branch_arr" :key="index" :value="item.id">{{ item.branch_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Broker</label>
                                <div class="" id="broker_id">

                                    <TomSelect v-model="form_data.broker_id" :options="{
                          placeholder: 'Select Broker ',
                          }" class="w-full">
                                        <option value="0">Select Broker</option>
                                        <option v-for="(item,index) in page_data.selling_broker" :key="index" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Sell Type</label>
                                <div class="" id="payment_type">

                                    <TomSelect v-model="form_data.payment_type" :options="{
                          placeholder: 'Select Sell Type ',
                          }" class="w-full">
                                        <option value="">Sell Type</option>
                                        <option value="1">Cash</option>
                                        <option value="2">Bill</option>
                                    </TomSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Purchase Date <span class="text-primary-3"> *</span></label>
                                <div class="" id="purchase_date">

                                    <input v-model="form_data.purchase_date" type="date" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Payment Terms</label>
                                <div class="" id="payment_terms">

                                    <input v-model="form_data.payment_terms" type="number" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Pieces</label>
                                <div class="" id="total_pcs">

                                    <input v-model="form_data.total_pcs" disabled type="text" autocomplete="off" placeholder="Total Pieces" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Weight</label>
                                <div class="" id="total_wt">

                                    <input v-model="form_data.total_wt" disabled type="text" placeholder="Total Weight" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Total Amount</label>
                                <div class="" id="total_amount">

                                    <input v-model="form_data.total_amount" disabled type="text" autocomplete="off" placeholder="Total Amount" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Brokerage</label>
                                <div class="" id="brokerage_amount">

                                    <input v-model="form_data.brokerage_amount" disabled type="text" placeholder="Brokerage" autocomplete="off" class="form-control" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="flex items-center justify-between">
                        <div>
                        </div>
                        <div>

                            <button type="button" @click="cancel_sell" class="btn btn-outline-secondary w-32 mr-1">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary w-32" :disabled="page_data.form_status" @click="sell_stock">
                                Save Details
                                <LoadingIcon v-if="page_data.form_status" icon="oval" color="white" class="w-4 h-4 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
        <!-- </div>
        <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5 mb-10">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="p-5">
                    <div class="flex justify-between flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mx-5">Final Purchase Details</h2>

                        <div class="flex items-center">
                          <span class="whitespace-nowrap mr-3 font-bold">Select Status </span>
                          <TomSelect v-model="form_data.purchase_type" :options="{
                              placeholder: 'Select Status'
                            }" class="w-40" name="LAB" id="pos-form-2" @change="updateStocksCode">
                                        <option value="1">LAB Pending</option>
                                        <option value="2">LAB Done</option>
                                    </TomSelect>
                        </div>

                    </div>
                    <!-- <div class="border mt-5 p-0 shadow-md mt-5">
                          <div class="overflow-x-auto"> -->
                    <table class="border mt-3 w-full">
                        <thead>
                            <tr class="border-b">
                                <th class="py-2" rowspan="2">Sr No</th>
                                <th class="py-2">Stock No <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Packet No <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Weight (Ct) <span class="text-primary-3"> *</span></th>
                                <th class="py-2">LAB <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Stone <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Shape <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Color <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Clarity <span class="text-primary-3"> *</span></th>
                              </tr>
                              <tr>
                                <th class="py-2">Repo <span class="text-primary-3"> *</span></th>
                                <th class="py-2">Back (%)</th>
                                <th class="py-2">Currency</th>
                                <th class="py-2">CNBC Rate</th>
                                <th class="py-2">Less</th>
                                <th class="py-2">Amount</th>
                                <th class="py-2">Brokerage (%)</th>
                                <th class="py-2">Brokerage</th>
                                <th class="py-2"></th>
                            </tr>
                        </thead>
                        <tbody class="border" v-for="(plan,index) in form_data.bunch_details" :key="index">
                            <tr>
                                <td class="w-8" rowspan="2">
                                    <div class="font-bold text-center">{{ index + 1 }}</div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'certy_stock_no_'+ index">
                                        <input v-model="plan.certy_stock_no" type="text" autocomplete="off" :disabled="form_data.purchase_type == 1" class="form-control" placeholder="Stock No" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'certy_packet_no_'+ index">
                                        <input v-model="plan.certy_packet_no" type="text" autocomplete="off" class="form-control" placeholder="Packet No" />

                                    </div>
                                </td>
                                <td class="w-28">
                                    <div class="" :id="'weight_'+ index">
                                        <input v-model="plan.weight" type="number" autocomplete="off" class="form-control" placeholder="Weight" @change="calculate_weight()" />

                                    </div>
                                </td>
                                <td :id="'lab_' + index" class="p-0 m-0 w-28">
                                    <TomSelect v-model="plan.lab" :options="{
                              placeholder: 'Select Material'
                            }" class="w-full" name="LAB" id="pos-form-2">
                                        <option value="0">Select LAB</option>
                                        <option value="IGI">IGI</option>
                                        <option value="GIA">GIA</option>
                                    </TomSelect>
                                </td>

                                <td :id="'stone_type_' + index" class="p-0 m-0 w-28">
                                    <TomSelect v-model="plan.stone_type" :options="{
                          placeholder: 'Select Watt'
                        }" class="w-full" name="Stone Type" id="pos-form-2" @change="set_stone_name(plan)">
                                        <option value="0">Select Stone</option>
                                        <option value="1">NATURAL</option>
                                        <option value="2">CVD</option>
                                        <option value="3">HPHT</option>
                                        <option value="4">D_CVD</option>
                                        <option value="5">D_HPHT</option>
                                    </TomSelect>
                                </td>
                                <td :id="'shape_' + index" class="p-0 m-0 w-28">
                                    <TomSelect v-model="plan.shape" :options="{
                          placeholder: 'Select Watt'
                        }" class="w-full" name="Shape" id="pos-form-2" @change="set_shape_name(plan)">
                                        <option value="0">Select Shape</option>
                                        <option v-for="item in page_data.shape_arr" :key="item.id" :value="item.id">{{ item.shape_name}}</option>
                                    </TomSelect>
                                </td>
                                <td :id="'color_' + index" class="p-0 m-0 w-28">
                                    <TomSelect v-model="plan.color" :options="{
                          placeholder: 'Select Watt'
                        }" class="w-full" name="Color" id="pos-form-2" @change="set_color_name(plan)">
                                        <option value="0">Select Color</option>
                                        <option v-for="item in page_data.color_arr" :key="item.id" :value="item.id">{{ item.shade_name}}</option>
                                    </TomSelect>
                                </td>
                                <td :id="'clarity_' + index" class="p-0 m-0 w-28">
                                    <TomSelect v-model="plan.clarity" :options="{
                          placeholder: 'Select Clarity'
                        }" class="w-full" name="Clarity" id="pos-form-2" @change="set_clarity_name(plan)">
                                        <option value="0">Select Clarity</option>
                                        <option v-for="item in page_data.clarity_arr" :key="item.id" :value="item.id">{{ item.purity_name}}</option>
                                    </TomSelect>
                                </td>
                              </tr>
                              <tr>
                              <td class="w-36">
                                  <div class="" :id="'repo_rate_'+ index">
                                      <input v-model="plan.repo_rate" @change="calculate_sell_rate(plan)" placeholder="Repo Rate" type="number" autocomplete="off" class="form-control" />

                                  </div>
                              </td>
                                <td class="w-36">
                                    <div class="" :id="'sell_back_'+ index">
                                        <input v-model="plan.sell_back" @change="calculate_sell_rate(plan)" placeholder="Purchase Back (%)" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'currency_'+ index">
                                        <input v-model="plan.currency" placeholder="Currency" type="text" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'cnbc_rate_'+ index">
                                        <input v-model="plan.cnbc_rate" @change="calculate_sell_rate(plan)" placeholder="CNBC Rate" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'sell_less_'+ index">
                                        <input v-model="plan.sell_less" @change="calculate_sell_rate(plan)" placeholder="Purchase Less" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'total_amount_'+ index">
                                        <input v-model="plan.total_amount" disabled @change="calculate_broker_rate(plan)" placeholder="Total Amount" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'broker_per_'+ index">
                                        <input v-model="plan.broker_per" @change="calculate_broker_rate(plan)" placeholder="Brokerage (%)" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-36">
                                    <div class="" :id="'brokerage_amount_'+ index">
                                        <input v-model="plan.brokerage_amount" disabled placeholder="Brokerage" type="number" autocomplete="off" class="form-control" />

                                    </div>
                                </td>
                                <td class="w-8">
                                    <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="remove_bunch(plan.id)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div>
                        <button type="button" @click="add_new_request_field" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">Add Stone</button>
                    </div>
                </div>
            </div>

            <!-- END: Input -->
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent,
    inject,
    onMounted,
    reactive,
    watch
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'
import {
    integer
} from '@vuelidate/validators'
import router from '@/router'

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    setup() {
        const swal = inject('$swal')
        const page_data = reactive({
            form_status: false,
            purchase_parties: [],
            selling_broker: [],
            charni_items: [],
            shape_arr: [],
            color_arr: [],
            clarity_arr: [],
            stone_natural: false,
            scan_lot_no: '',
            scan_load: false,
            stone_disable: false

        })
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const form_data = reactive({

            purchase_type: "1",
            party_id: "0",
            stone_type: "0",
            broker_id: "0",
            branch_id: "1",
            total_pcs: 1,
            total_wt: "",
            total_amount: "",
            brokerage_amount: "",
            purchase_date: "",
            payment_terms: "",
            payment_type: "",
            ref_no: "",
            sell_items: 1,
            bunch_details: [{
                id: 1,
                certy_stock_id: "",
                certy_stock_no: "",
                certy_packet_no: "",
                pack_no: "",
                barcode: "",
                certificate_no: "",
                lab: "0",
                shape_name: "",
                shape: "0",
                color: "0",
                stone_type: "0",
                stone_name: "",
                color_name: "",
                clarity: "0",
                clarity_name: "",
                weight: "",
                repo_rate: "",
                exp_back: "",
                cr_back: "",
                sell_back: "",
                currency: "INR",
                cnbc_rate: "",
                sell_less: "",
                total_amount: "",
                broker_per: "",
                brokerage_amount: "",
            }]
        })

        // Add Product Field
        const add_new_request_field = () => {

            form_data.sell_items += 1;
            form_data.bunch_details.push({
                id: form_data.sell_items,
                certy_stock_id: "",
                certy_stock_no: "",
                certy_packet_no: "",
                pack_no: "",
                barcode: "",
                certificate_no: "",
                lab: "0",
                shape_name: "",
                shape: "0",
                color: "0",
                stone_type: "0",
                stone_name: "",
                color_name: "",
                clarity: "0",
                clarity_name: "",
                weight: "",
                repo_rate: "",
                exp_back: "",
                cr_back: "",
                sell_back: "",
                currency: "INR",
                cnbc_rate: "",
                sell_less: "",
                total_amount: "",
                broker_per: "",
                brokerage_amount: "",
            });

            form_data.total_pcs = (form_data.total_pcs ? parseFloat(form_data.total_pcs) : 0) + 1
            page_data.stone_disable = true

        }

        // Get Seller Party
        const get_seller_party = async () => {
            const params = {};
            const url = "/api/dp_account_purchase_party"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.purchase_parties = data.items;
                })
                .catch(_error => {
                    page_data.purchase_parties = [];
                });
        }

        // Get Shape Details
        const get_shape_details = async () => {

            let promise_url = "/api/dp_shape";
            let params = {

            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.shape_arr = data.items
                })
                .catch(_error => {
                    page_data.shape_arr = []

                });
        }

        // Get Shape Details
        const get_color_details = async () => {

            let promise_url = "/api/dp_color";
            let params = {

            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.color_arr = data.items
                })
                .catch(_error => {
                    page_data.color_arr = []

                });
        }

        // Get Shape Details
        const get_clarity_details = async () => {

            let promise_url = "/api/dp_purity";
            let params = {

            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.clarity_arr = data.items
                })
                .catch(_error => {
                    page_data.clarity_arr = []

                });
        }

        // Get Seller Party
        const get_selling_broker = async () => {
            const params = {};
            const url = "/api/dp_account_purchase_broker"
            let promise = axios({
                url: url,
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.selling_broker = data.items;
                })
                .catch(_error => {
                    page_data.selling_broker = [];
                });
        }

        // Remove Bunch Details
        const remove_bunch = (id) => {
            swal({
                title: "Are you sure?",
                text: "You are about to Delete Item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.bunch_details.length;
                    while (i--) {
                        if (form_data.bunch_details[i]["id"] == id) {

                            form_data.total_pcs -= 1
                            form_data.total_wt -= form_data.bunch_details[i]["weight"]

                            form_data.bunch_details.splice(i, 1);
                        }
                    }
                }
            });
        }

        // Calculate Amount
        const calculate_sell_rate = async (item) => {
            if (item.repo_rate > 0 && item.weight > 0) {

                if (item.sell_back > 0) {

                    item.total_amount = $h.myFloat(parseFloat(item.repo_rate * item.weight) - parseFloat((item.repo_rate * item.weight * item.sell_back) / 100));
                } else {
                    item.total_amount = $h.myFloat(item.repo_rate * item.weight);
                }
                if (item.cnbc_rate > 0) {
                    item.total_amount = $h.myFloat(item.total_amount * item.cnbc_rate)
                }

                if (item.sell_less > 0) {

                    item.total_amount = $h.myFloat(parseFloat(item.total_amount) - parseFloat((item.total_amount * item.sell_less) / 100));
                    calculate_broker_rate(item)
                } else {
                    item.total_amount = $h.myFloat(item.total_amount);
                    calculate_broker_rate(item)
                }

            } else {
                item.total_amount = "";
                calculate_broker_rate(item)
            }

            let total_amount = 0;

            form_data.bunch_details.forEach(element => {
                total_amount = $h.myFloat(parseFloat(total_amount) + parseFloat(item.total_amount > 0 ? element.total_amount : 0))
            });

            form_data.total_amount = total_amount;
        }
        // Calculate Broker Rate
        const calculate_broker_rate = async (item) => {
            if (item.total_amount > 0) {

                if (item.broker_per > 0) {

                    item.brokerage_amount = $h.myFloat((item.total_amount * item.broker_per) / 100);
                } else {

                    item.brokerage_amount = 0;
                }
            } else {
                item.brokerage_amount = 0;
            }

            let brokerage_amount = 0;

            form_data.bunch_details.forEach(element => {
                brokerage_amount = $h.myFloat(parseFloat(brokerage_amount) + parseFloat(item.brokerage_amount > 0 ? item.brokerage_amount : 0))
            });

            form_data.brokerage_amount = brokerage_amount;
        }
        // Sell Stock To Party
        const sell_stock = async () => {

            const sell_items = form_data.bunch_details

            console.log(sell_items)

            // return;
            if (sell_items.length > 0) {

                // Validate Fields

                let is_empty = false

                // if (form_data.stone_type == "0" || form_data.stone_type == null) {
                //     $('#stone_type').addClass('border border-theme-6')
                //     is_empty = true

                // } else {
                //     $('#stone_type').removeClass('border border-theme-6')
                // }
                if (form_data.party_id == "0" || form_data.party_id == null) {
                    $('#party_id').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#party_id').removeClass('border border-theme-6')
                }
                if (form_data.total_pcs == "" || form_data.total_pcs == null) {
                    $('#total_pcs').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_pcs').removeClass('border border-theme-6')
                }
                if (form_data.total_wt == "" || form_data.total_wt == null) {
                    $('#total_wt').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_wt').removeClass('border border-theme-6')
                }
                if (form_data.total_amount == "" || form_data.total_amount == null) {
                    $('#total_amount').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#total_amount').removeClass('border border-theme-6')
                }
                if (form_data.purchase_date == "" || form_data.purchase_date == null) {
                    $('#purchase_date').addClass('border border-theme-6')
                    is_empty = true

                } else {
                    $('#purchase_date').removeClass('border border-theme-6')
                }
                // if (form_data.payment_terms == "" || form_data.payment_terms == null) {
                //     $('#payment_terms').addClass('border border-theme-6')
                //     is_empty = true

                // } else {
                //     $('#payment_terms').removeClass('border border-theme-6')
                // }
                // if (form_data.payment_type == "" || form_data.payment_type == null) {
                //     $('#payment_type').addClass('border border-theme-6')
                //     is_empty = true

                // } else {
                //     $('#payment_type').removeClass('border border-theme-6')
                // }

                for (let i = 0; i < sell_items.length; i++) {


                  if(form_data.purchase_type ==2) {

                    if (sell_items[i].certy_stock_no == '' || sell_items[i].certy_stock_no == null) {
                        $('#certy_stock_no_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#certy_stock_no_' + i).removeClass('border border-theme-6');
                    }
                  }
                    if (sell_items[i].certy_packet_no == '' || sell_items[i].certy_packet_no == null) {
                        $('#certy_packet_no_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#certy_packet_no_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].weight == '' || sell_items[i].weight == null) {
                        $('#weight_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#weight_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].lab == '0' || sell_items[i].lab == null) {
                        $('#lab_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#lab_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].stone_type == '0' || sell_items[i].stone_type == null) {
                        $('#stone_type_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#stone_type_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].shape == '0' || sell_items[i].shape == null) {
                        $('#shape_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#shape_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].color == '0' || sell_items[i].color == null) {
                        $('#color_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#color_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].clarity == '0' || sell_items[i].clarity == null) {
                        $('#clarity_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#clarity_' + i).removeClass('border border-theme-6');
                    }
                    if (sell_items[i].repo_rate == '' || sell_items[i].repo_rate == null) {
                        $('#repo_rate_' + i).addClass('border border-theme-6');
                        is_empty = true;
                    } else {
                        $('#repo_rate_' + i).removeClass('border border-theme-6');
                    }
                    // if (sell_items[i].sell_back == '' || sell_items[i].sell_back == null) {
                    //     $('#sell_back_' + i).addClass('border border-theme-6');
                    //     is_empty = true;
                    // } else {
                    //     $('#sell_back_' + i).removeClass('border border-theme-6');
                    // }
                }

                if (is_empty == true) {
                    swal({
                        title: "Warning!",
                        icon: "warning",
                        text: "Fields Are Empty!",
                        type: "warning",
                        confirmButtonColor: "#064e3b",
                    })
                    return
                }

                page_data.form_status = true

                const url = "/api/purchase_certy_stock"
                let promise = axios({
                    url: url,
                    method: "POST",
                    data: form_data,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {

                        if (data.error == "YES") {
                            page_data.form_status = false
                            swal("Error!", data.message, "error");

                            return;
                        }
                        swal.fire({
                            icon: 'success',
                            title: "Success!",
                            text: data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                        page_data.form_status = false
                        let transaction_id = data.transaction_details.id
                        router.push('/app/app-final-polish/polish-purchase/' + window.btoa(transaction_id));
                    })
                    .catch(_error => {
                        page_data.form_status = false
                        swal("Error!", "Something went Wrong", "error");

                        return;
                    });

            } else {

                page_data.form_status = false
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are empty!",
                    type: "warning",
                })
                return
            }
        }
        const getBranchDetails = async () => {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "/api/transfer_branches",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.branch_arr = data.items;

                })
                .catch(_error => {
                    page_data.branch_arr = [];

                });
        }

        function cancel_sell() {
            router.go(-1)
        }

        const set_stone_name = (item) => {

            if (item.stone_type == 1) {
                item.stone_name = "NATURAL";
            } else if (item.stone_type == 2) {

                item.stone_name = "CVD";
            } else if (item.stone_type == 3) {
                item.stone_name = "HPHT";

            } else if (item.stone_type == 4) {

              item.stone_name = "D_CVD";
            } else if (item.stone_type == 5) {
              item.stone_name = "D_HPHT";

            }
          }
          const set_shape_name = (item) => {

              page_data.shape_arr.forEach(element => {
                  if (element.id == item.shape) {
                      item.shape_name = element.shape_name;
                  }
              });
          }
          const set_color_name = (item) => {

              page_data.color_arr.forEach(element => {
                  if (element.id == item.color) {
                      item.color_name = element.shade_name;
                  }
              });
          }
          const set_clarity_name = (item) => {

              page_data.clarity_arr.forEach(element => {
                  if (element.id == item.clarity) {
                      item.clarity_name = element.clarity_name;
                  }
              });
          }
          const calculate_weight = () => {
              let total_weight = 0;
              let total_pcs = 0;
              form_data.bunch_details.forEach(element => {
                  total_weight = $h.myFloat($h.myFloat(element.weight) + $h.myFloat(total_weight))
              });
              total_pcs = form_data.bunch_details.length;

              form_data.total_pcs = total_pcs
              form_data.total_wt = total_weight
          }

          const updateStocksCode = () => {
            if(form_data.purchase_type == 1) {
              form_data.bunch_details.forEach(element => {
                element.certy_stock_no = ""
              });
            }
          }

          // On Mount
          onMounted(() => {
              get_seller_party();
              getBranchDetails();
              get_selling_broker();
              get_shape_details()
              get_color_details()
              get_clarity_details()
          })

          return {
              page_data,
              form_data,
              remove_bunch,
              sell_stock,
              calculate_sell_rate,
              calculate_broker_rate,
              cancel_sell,
              add_new_request_field,
              set_stone_name,
              set_shape_name,
              set_color_name,
              set_clarity_name,
              calculate_weight,
              updateStocksCode
          }
    }

})
</script>

<style scoped>
/* .border {
      margin: 0 20px;
      padding: 3px;
    } */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
