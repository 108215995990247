<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Website Natural Stock</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">
        <div id="single-file-upload" class="p-5">
            <div class="preview">
              <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: page_data.base_url + '/api/ufsn',
                  thumbnailWidth: 150,
                  maxFilesize: 2,
                  maxFiles: 1,
                  headers: { Authorization: 'Bearer ' + page_data.token }
                }"
                class="dropzone mb-4"
              >
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload Excel file with latest natural stock. (.xlsx, .xls)
                </div>
              </Dropzone>
              <span class="mt-5">Latest stock upadted on : {{page_data.last_sync_date}}</span>
            </div>
            
          </div>
    </div>

    <div class="intro-y box p-5 mt-5">
          
      <div id="responsive-table" class="p-5" v-if="page_data.load_price">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="bars" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">Getting Latest Stock</div>
      </div>
              </div>
            </div>
      </div>
      <div id="responsive-table" class="p-5" v-else>
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                       <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Report No
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Certificate
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        360
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Lab
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Stock No
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Shape
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Weight
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Color
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Clarity
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Cut
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Polish
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Sym
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Flr
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Back
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Measurement
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Depth%
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Table%
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Repo
                      </th>
                       <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        PPC
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Final Dollar
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        BGM
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Eyeclean
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Stone
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(arrayresult ,key ,id) in page_data.stock" :key="key" :id="id" >
                      
                      <!-- toFixed(2) -->
                      <td class="border-b whitespace-nowrap">{{key+1}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['certi_report_no']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['certi_report_no']}}</td>
                      <td class="border-b whitespace-nowrap"><a :href="arrayresult['video_link']" target="_blank">View Video</a></td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['lab_name']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['stock_no']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['shape_str']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['weight']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['color_str']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['clarity_str']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['cut']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['polish']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['symmetry']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['fl_color']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['discount']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['measurements']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['depth']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['table']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['price_per_ct']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['price_per_ct']}}</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['total_price']}}</td>
                      <td class="border-b whitespace-nowrap">-</td>
                      <td class="border-b whitespace-nowrap">{{arrayresult['eye_clean']}}</td>
                      <td class="border-b whitespace-nowrap">Natural</td>
                      
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            
      </div>
     

    <div>
    </div>
      
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, provide,reactive, onMounted } from 'vue'
import { apiUrl, BASE_URL } from "@/constants/config";

export default defineComponent({
  setup() {
    const dropzoneSingleRef = ref()
   
    const page_data = reactive({
      base_url : BASE_URL,
      last_sync_date : '',
      rate_round : [],
      stock : [],
      load_price:false,
      price_cut:'ROUND',
      rate_fancy : [],
      token: localStorage.getItem("token"),
      showRoundPrice:true,
      showFancyPrice:false,
    })

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    const getOldRates = () => {
      page_data.load_price = true;
      const params = {
       
      };
      
      let promise = axios({
        url: "api/gms",
        method: "GET",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
        
        page_data.last_sync_date = data.last_sycn;
        page_data.stock = data.stock;
        page_data.load_price = false;
        console.log(page_data.load_price);
        
        })
        .catch(_error => {
          
        });
    }
    const changeTab = (type) => {
      if(type == 1){
        page_data.showRoundPrice = true;
        page_data.showFancyPrice = false;
      }else {
        page_data.showRoundPrice = false;
        page_data.showFancyPrice = true;
      }
      
    }
  
  
    onMounted(() => {
      const elDropzoneSingleRef = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('success', () => {
        alert('Stock Added Succesfully.')
      })
      elDropzoneSingleRef.dropzone.on('error', () => {
        alert('Try Again there is some error!')
      })
      getOldRates();
    })
   return {
      page_data,
      changeTab,
      getOldRates
    }
  }
})
</script>

