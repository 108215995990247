<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Non Certy Pending Kapan-Stock</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="series_code">Series No</option>
              <option value="kapan_code">Kapan No</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
          <div class="mt-2 xl:mt-0 xl:ml-3 ml-0">
            <TomSelect
              v-model="screenData.stone_id"
              :options="{
                placeholder: 'Select Stone',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stone'
                  }
                }
              }"
              class="w-40"
              @change="initTabulator"
            >
            <option value="0">Select Stone</option>
            <option v-for="stone in screenData.stones" :key="stone.id" :value="stone.id">{{stone.stone_code}}</option>
            </TomSelect>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>

                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
                 <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'series_code',
      type: 'like',
      value: ''
    })
    const screenData = reactive({
      stones: [],
      stone_id: '0'
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_ready_series',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          stone_id : screenData.stone_id != '0' ? screenData.stone_id : ''
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Series No',
            field: 'series_code',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Kapan No',
            field: 'kapan_code',
            vertAlign: 'middle',
            print: false,
            download: true,

          },

          {
            title: 'Stone Type',
            field: 'stone_type',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Available Pcs',
            field: 'total_pcs',
            vertAlign: 'middle',
            print: false,
            download: true,
          },{
            title: 'Available Weight (Ct)',
            field: 'total_ct',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return $h.myFloat(cell.getData().total_ct)
            }
          },
          {
            title: 'Merged Pcs',
            field: 'merged_total_pcs',
            vertAlign: 'middle',
            print: false,
            download: true,
          },{
            title: 'Merged Weight (Ct)',
            field: 'merged_total_ct',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return $h.myFloat(cell.getData().merged_total_ct)
            }
          },

          // {
          //   title: 'Status',
          //   field: 'active_status',
          //   hozAlign: 'left',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,
          //   formatter(cell) {

          //     if(cell.getData().active_status == "Active"){
          //     return `<div class="flex items-center lg:justify-center text-theme-9">
          //       Active
          //     </div>`
          //     }else{
          //       return `<div class="flex items-center lg:justify-center text-theme-6">
          //       Not Merged
          //     </div>`
          //     }

          //   }
          // },
          // {
          //   title: '',
          //   field: '',

          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   headerSort:false,
          //   print: false,
          //   download: false,
          //   formatter(cell) {
          //     const data_id = cell.getData().id;
          //     const a = cash(`<div class="flex lg:justify-center items-center">
          //       <span class="flex items-center" >
          //         <i data-feather="edit" class="w-4 h-4 mr-1"></i> Merge Stock
          //       </span>
          //     </div>`)

          //     cash(a).on('click', function(data_id) {
          //       // On click actions
          //       router.push("/app/app-polish-ready/ready/serieswisestock/merge-series/" + window.btoa(cell.getData().id));
          //     })

          //     return a[0]
          //   }
          // },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

 const getStoneDetails = () => {
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.stones = data.items;
        })
        .catch(_error => {

        });
    }
    //Edit user
    const editUser = (id) => {
      console.log(id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'series_code'
      filter.type = 'like'
      filter.value = '',
      screenData.stone_id = '0'
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }



    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'buyer_list.xlsx', {
        sheetName: 'Buyers'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      getStoneDetails()
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      editUser,
      screenData,
      initTabulator
    }

  }
})
</script>

