<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Final Polish Comparison Report (Certy Stock)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="getKapans"
                multiple
              >
              <option value="">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                multiple
                @change="kapanChange"
              >
              <option value="">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Packets</option>
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Lab Karigar</label
              >
              <TomSelect
                v-model="screenData.karigar_id"
                :options="{
                  placeholder: 'Select Karigar',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Karigar'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Karigar</option>
              <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{karigar.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="screenData.issue_pending"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Shape</label
              >
              <TomSelect
                v-model="screenData.shape_group"
                :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">All</option>
              <option value="ROUND">ROUND</option>
              <option value="FANCY">FANCY</option>
              </TomSelect>
            </div>
          </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-3">
              <label for="update-profile-form-6" class="form-label"
                >Select Stone</label
              >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option value="1">NATURAL</option>
              <option value="2">CVD</option>
              <option value="3">HPHT</option>
              <option value="4">D_CVD</option>
              <option value="5">D_HPHT</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5" :disabled="(screenData.issue_pending == true && (screenData.series_id.length == 0 && screenData.kapan_id.length == 0))"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-2 flex items-center justify-center">
          <div class="mt-10 flex">

            <div class="flex mt-5 sm:mt-0">

              <div class="dropdown w-1/2 sm:w-auto">
                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <a id="tabulator-export-xlsx" href="javascript:;" :aria-disabled="pagedata.excel_loading == true" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                          </a>
                      </div>
                  </div>
              </div>
              </div>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-2 flex items-center justify-start">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden" v-if="pagedata.report_details.length > 0">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2 text-center">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r-2" colspan="7">FINAL POLISH</th>
                      <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r-2" colspan="6">LAB ISSUE</th>
                      <th class="py-2 px-2 text-xs lg:text-md whitespace-nowrap border-r-2" colspan="9">LAB RECEIVE</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">NO.</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r-2">Packet No</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">LAB</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">SHAPE</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">COLOR</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PURITY</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">WEIGHT (CT)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">AVG Rate($)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">RETURN DATE</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">LAB</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">SHAPE</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">COLOR</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PURITY</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">WEIGHT (CT)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE DATE</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">LAB</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">SHAPE</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">COLOR</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PURITY</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">WEIGHT (CT)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">GHAT (CT)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">AVG Rate($)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">AVG Back(%)</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">RETURN DATE</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Lab Party</th>
                    </tr>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.final_packets }}</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r-2"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.final_weight ? pagedata.total.final_weight : 0 }} Ct</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.avg_rate ? pagedata.total.avg_rate : 0 }} </th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.lab_issue_weight ? pagedata.total.lab_issue_weight : 0 }} Ct</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.lab_receive_weight ? pagedata.total.lab_receive_weight : 0 }} Ct</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.lab_receive_weight ? $h.myFloat(pagedata.total.lab_receive_weight - pagedata.total.lab_issue_weight) : 0 }} Ct</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.lab_avg_rate ? pagedata.total.lab_avg_rate : 0 }}</th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">{{ pagedata.total.avg_back ? pagedata.total.avg_back : 0 }} </th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                    </tr>
                  </thead>
                  <tbody v-for="(item,index) in pagedata.report_details" :key="index">
                    <tr>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.lab_code }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.packet_code }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.final_lab ? item.final_lab : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.final_shape_name ? item.final_shape_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.final_color_name ? item.final_color_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.final_purity_name ? item.final_purity_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.final_weight ? item.final_weight : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.po_value ? item.po_value : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.final_polish_date ? item.final_polish_date : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.issue_lab ? item.issue_lab : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.issue_shape_name ? item.issue_shape_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.issue_color_type == 'bg-red','text-theme-9' : item.issue_color_type == 'bg-green'}">{{ item.issue_color_name ? item.issue_color_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.issue_purity_type == 'bg-red','text-theme-9' : item.issue_purity_type == 'bg-green'}">{{ item.issue_purity_name ? item.issue_purity_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.issue_weight_type == 'bg-red','text-theme-9' : item.issue_weight_type == 'bg-green'}">{{ item.issue_weight ? item.issue_weight : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.issue_lab_date ? item.issue_lab_date : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.receive_lab ? item.receive_lab : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.receive_shape_name ? item.receive_shape_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.receive_color_type == 'bg-red','text-theme-9' : item.receive_color_type == 'bg-green'}">{{ item.receive_color_name ? item.receive_color_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.receive_purity_type == 'bg-red','text-theme-9' : item.receive_purity_type == 'bg-green'}">{{ item.receive_purity_name ? item.receive_purity_name : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.receive_weight_type == 'bg-red','text-theme-9' : item.receive_weight_type == 'bg-green'}">{{ item.receive_weight ? item.receive_weight : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :class="{'text-theme-6' : item.ghat_theme == 'bg-red','text-theme-9' : item.ghat_theme == 'bg-green'}">{{ item.ghat_wt ? (item.ghat_wt > 0 ? '+ ' + item.ghat_wt : item.ghat_wt) : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.lab_value ? item.lab_value : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.discount ? item.discount : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.return_lab_date ? item.return_lab_date : "-" }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ item.first_name + ' ' + item.last_name }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import { helper as $h} from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      stones: [],
      shapes: [],
      employees: [],

      report_details : [],
      total : [],

      loading: false,
      excel_loading: false,
    })
    const screenData = reactive({
      series_id:[],
      kapan_id:[],
      stone_id:'0',
      packets:[],
      process_id:40,
      karigar_id:'0',
      shape_group:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: false,

    })
    const printData = reactive({
      series_id:[],
      kapan_id:[],
      stone_id:'0',
      packets:[],
      process_id:40,
      karigar_id:'0',
      shape_group:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending: false,

    })

    const generateReport = () => {
      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.stone_id = screenData.stone_id
      printData.packets = screenData.packets
      printData.process_id = screenData.process_id
      printData.karigar_id = screenData.karigar_id
      printData.shape_group = screenData.shape_group
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending
      pagedata.loading  = true
        let params = {
          series_id : screenData.series_id.length != 0 ? screenData.series_id : '',
            kapan_id : screenData.kapan_id.length != 0 ? screenData.kapan_id : '',
            packets : screenData.packets.length != 0 ? screenData.packets : '',
            process_id : screenData.process_id != '0' ? window.btoa(screenData.process_id) : '',
            karigar_id : screenData.karigar_id != '0' ? window.btoa(screenData.karigar_id) : '',
            stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
            shape_group : screenData.shape_group != '0' ? window.btoa(screenData.shape_group) : '',
            issue_date : window.btoa(screenData.issue_date),
            receive_date : window.btoa(screenData.receive_date),
            issue_pending : screenData.issue_pending,
            }
            let promise = axios({
            url: "api/certy_comparison_report",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              pagedata.report_details = data.items
              pagedata.total = data.total
              pagedata.loading  = false
            })
            .catch(_error => {
              pagedata.loading  = false
            });
        }
    const getSeries = () => {
      let params = {
        process_id : window.btoa(screenData.process_id),
      }
      let promise = axios({
        url: "api/get_certy_series_list",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
        process_id : window.btoa(screenData.process_id),
      }
      let promise = axios({
        url: "api/get_certy_mul_kapan_list",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.kapans = data.items
          screenData.kapan_id = []

        })
        .catch(_error => {
          pagedata.kapans = []
          screenData.kapan_id = []

        });
    }
    const getPackets = () => {
      if(screenData.kapan_id.length > 0) {
        let params = {
          series_id: (screenData.series_id.length > 0) ? screenData.series_id : '',
          kapan_id: (screenData.kapan_id.length > 0) ? screenData.kapan_id : '',
          process_id : window.btoa(screenData.process_id),
        }
        let promise = axios({
          url: "api/get_certy_mul_packet_list",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            pagedata.packets = data.items
            screenData.packets = []

          })
          .catch(_error => {

            pagedata.packets = []
            screenData.packets = []

          });
        }  else {
        pagedata.packets = []
        screenData.packets = []
      }
    }
    const getEmployees = () => {
      let params = {
        process: screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_party_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = []
      screenData.kapan_id = []
      screenData.packets = []
      screenData.karigar_id = '0'
      screenData.process_id = 31
      screenData.issue_pending = true
      screenData.stone_id = '0'
      screenData.shape_group = '0'
      pagedata.issue_pending = false

    }

    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function kapanChange() {
      if(screenData.kapan_id.length != 0) {
        getPackets()
      } else {
        screenData.packets = []
        pagedata.packets = []
      }
    }
    function printReportDetails(){
        let report_type = 'lab-comparison-report'
        let params = {
          report_type: report_type,
          filters: printData,
          items: {'items': pagedata.report_details,'total': pagedata.total }
        }
        let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            const report_id = data.report_id
            let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
              window.open(url, '_blank');


          })
          .catch(_error => {

          });

      // add_report_details
    }

    const onExportXlsx = () => {
            let filename = "certy-comparison.xlsx";
            let params = {
              series_id : printData.series_id.length != 0 ? printData.series_id : '',
              kapan_id : printData.kapan_id.length != 0 ? printData.kapan_id : '',
              packets : printData.packets.length != 0 ? printData.packets : '',
              process_id : printData.process_id != '0' ? window.btoa(printData.process_id) : '',
              karigar_id : printData.karigar_id != '0' ? window.btoa(printData.karigar_id) : '',
              stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
              shape_group : printData.shape_group != '0' ? window.btoa(printData.shape_group) : '',
              issue_date : window.btoa(printData.issue_date),
              receive_date : window.btoa(printData.receive_date),
              issue_pending : printData.issue_pending,
            }
            let promise = axios({
                url: "/api/download_certy_comparison_report",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                    // this.status = "success";

                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                })
                .catch((_error) => {
                    // this.status = "fail";
                    // setTimeout(() => {
                    //     this.status = "default";
                    // }, 3000);
                    return [];
                });
        }
    onMounted(() => {
      checkPermission()
      getSeries()
      getEmployees()
      getKapans()
      reInitOnResizeWindow()

    })

    return {
      pagedata,
      screenData,userDetails,
      onResetFilter,
      getKapans,
      kapanChange,
      generateReport,printReportDetails,onExportXlsx
    }
  }
})
</script>
