<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Purchase</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Purchase Details</h2>
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Purchase No.</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    v-model="lot_no"
                    disabled
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Vendor</label
                  >
                  <TomSelect
                v-model="rough_vendor"
                :options="{
                  placeholder: 'Select Rough Vendor',
                  plugins: {
                    dropdown_header: {
                      title: 'Vendors'
                    }
                  }
                }"
                class="w-full"
                
              >
              <option>Select Vendor</option>
              <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.id">{{vendor.company_name}}</option>
              </TomSelect>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                    >Stone Type</label
                  >
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Stone Type',
                }"
                class="w-full" v-model="stone_type">
                <option>Select Type</option>
                    <option v-for="stone in stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
                  
                  </TomSelect>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Price / Ct</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Price in USD"
                    v-model="price"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    @change="calculateTotal"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="rough_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    @change="calculateTotal"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Total Price</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Total Price in USD"
                    v-model="totalprice"
                    readonly
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                    >Brokerage Type</label
                  >
                  <select id="update-profile-form-8" class="form-select" v-model="brokerage_type">
                    <option value="1">% (Percentage)</option>
                    <option value="2">Fixed (Amount)</option>
                    
                  </select>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-11" class="form-label"
                    >Brokerage Value</label
                  >
                  <input
                    id="update-profile-form-11"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="brokerage_value"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-12" class="form-label"
                    >Billing Period</label
                  >
                  <input
                    id="update-profile-form-11"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="billing_period"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Makeable</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    v-model="est_makeable"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Est. Polish Output</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder="Value in %"
                    v-model="est_polish"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="total_pcs"
                    @keypress="$h.preventInvalidInputInt" onpaste="return false;"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto" @click="submitRoughForm" :disabled="form_status">
                Save
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!form_status"/>
              </button>
             
            </div>
         
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Purchase saved successfully!</div>
                  <div class="text-gray-600 mt-1">
                  </div>
                </div>
              </div>

              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Purchase Can not Edit. Series found on Purchse Entry !</div>
                  <div class="text-gray-600 mt-1">
                    
                  </div>
                </div>
              </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form_status:false,
      tender_update_id : '',
      tender_name : '',
      rough_vendor : '',
      totalprice:0,
      lot_no: 'Auto',
      price:"",
      stone_type: '',
      brokerage_type: '',
      brokerage_value: '',
      billing_period: '',
      est_makeable: '',
      est_polish: '',
      rough_weight: '',
      total_pcs: '',
      vendors:[],
      stones:[],
      status: '1',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
    if(this.rough_id != ''){
      this.getRoughDetails(this.rough_id);
    }
    this.getRoughVendor();
    this.getDPStoneType();
  },

  methods: {
    async getRoughDetails(rough_id) {
      let _this = this;
      const params = {
        rough_id: rough_id
      };
      let promise = axios({
        url: "api/get_rough_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let rough_detail = data.item_details;
          this.tender_name = rough_detail.tender_name;
          this.lot_no = rough_detail.lot_no;
          this.price = rough_detail.price;
          this.stone_type = "" + rough_detail.stone_type;
          this.brokerage_type = "" + rough_detail.brokerage_type;
          this.brokerage_value = rough_detail.brokerage_value;
          this.billing_period = rough_detail.billing_period;
          this.est_makeable = rough_detail.est_makeable;
          this.est_polish = rough_detail.est_polish;
          this.rough_weight = rough_detail.rough_weight;
          this.total_pcs = rough_detail.total_pcs;
          this.rough_vendor = ""+ rough_detail.vendor_id;
          _this.calculateTotal();

        })
        .catch(_error => {
          
        });
    },

    async getRoughVendor() {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/dp_account_vendor",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.vendors = data.items;
        })
        .catch(_error => {
        });
    },
    async getDPStoneType() {
      let _this = this;
      const params = {
      };
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.stones = data.items;
        })
        .catch(_error => {
          
        });
    },
    
    async submitRoughForm(){
      
      if(this.rough_vendor == ''){
         this.$swal("Warning!", "Select Vendor", "warning");
        return;
       }

       if(this.stone_type == ''){
         this.$swal("Warning!", "Select Stone Type", "warning");
         return;
       }

       if(this.price == '' || this.price <= 0){
        this.$swal("Warning!", "Enter Purchase Price", "warning");
        return;
       }

       if(this.rough_weight == '' || this.rough_weight <= 0){
         this.$swal("Warning!", "Enter Purchase Rough Weight", "warning");
        return;
      }

     this.form_status = true;
      //let tag_ar = [this.vueSelectForm.multiple];
      const data = {
        vendor_id: this.rough_vendor,
        stone_type: this.stone_type,
        price: this.price,
        brokerage_type: this.brokerage_type,
        brokerage_value: this.brokerage_value,
        billing_period: this.billing_period,
        est_makeable: this.est_makeable,
        est_polish: this.est_polish,
        rough_weight: this.rough_weight,
        total_pcs: this.total_pcs,
        id:this.rough_id
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/edit_rough_lot"
      }else{
         url_post = "api/add_rough_lot"
      }
  
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.form_status = false;
          this.$router.push("/purchase/rough-master");
        }, 500)
        })
        .catch((_error) => {
          Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.form_status = false;
          this.$router.push("/purchase/rough-master");
        }, 500)
          return [];
        });
    },
    calculateTotal(){
      if(this.rough_weight != '' && this.price != ''){
        this.totalprice = this.$h.myFloat((this.rough_weight * this.price),4);
      }else{
        this.totalprice = 0;
      }
    }
  },
  
})
</script>
