<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Full Detail Report</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

          </div>
      </div>
      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box p-5 mt-5">

                  <span tabindex="1"></span>
                  <div class="grid grid-cols-12 gap-x-5">
                      <div class="col-span-12 sm:col-span-4 md:col-span-3">
                          <div class="">
                              <label for="update-profile-form-8" class="form-label py-1">Select Series/Kapan</label>
                              <select id="select-type" ref="selectType" tabindex="2" class="form-select" v-model="screenData.issue_block" @change="changeBlocktype">
                                  <option value="1">Kapan</option>
                                  <option value="2" selected>Series</option>
                              </select>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-4 md:col-span-3">
                          <div v-if="screenData.issue_block == 2">
                              <label for="update-profile-form-6" class="form-label py-1">Select Series</label>
                              <TomSelect v-model="screenData.selectedSeries" :options="{
  placeholder: 'Select Series',
  loadingClass: 'loading',
  plugins: {
  dropdown_header: {
  title: 'Series'
  }
  }
  }" class="w-full">
                                  <option value="0">Select Series</option>
                                  <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
                              </TomSelect>
                          </div>

                          <div v-if="screenData.issue_block == 1">
                              <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                              <TomSelect v-model="screenData.selectedKapan" :options="{
  placeholder: 'Select Kapan',
  loadingClass: 'loading',
  plugins: {
  dropdown_header: {
  title: 'Series'
  }
  }
  }" class="w-full">
                                  <option value="0">Select Kapan</option>
                                  <option v-for="kapans in screenData.kapans_ar" :key="kapans.id" :value="kapans.id">{{kapans.kapan_code}}</option>
                              </TomSelect>
                          </div>
                      </div>
                      <div class="col-span-3">
                          <div class="mt-10 flex">
                              <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full sm:w-20 mr-3" tabindex="5" @click="generateReport()">
                                  Generate
                              </button>
                              <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1 mr-3" tabindex="6" @click="onResetFilter">
                                  Reset
                              </button>
                              <a id="tabulator-export-csv" href="javascript:;" class="btn btn-secondaryflex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="printReportDetails()">
                                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                </a>
                            </div>
                      </div>

                  </div>
              </div>
              <div class="intro-y box mt-5" v-if="printData.issue_block == 2">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Series Details</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

                      </div>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 xl:col-span-4">
                              <div class="">
                                  <label for="update-profile-form-6" class="form-label font-bold">Purchase No : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ seriesForm.lot_no }}</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series No. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ seriesForm.series_code }}</label>
                              </div>

                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Cleaver Name : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ seriesForm.cleaver_name }}</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Cleaver Issue Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ seriesForm.cleaver_issue_date }}</label>
                              </div>
                              <!-- <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Est. Makeable : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ Number(seriesForm.exp_mackable) }} &nbsp;(%)</label>
                              </div> -->

                          </div>
                          <div class="col-span-12 xl:col-span-4">

                              <div>
                                  <label for="update-profile-form-6" class="form-label font-bold">Series Weight (Original): &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.series_weight) }} (Ct) / {{ $h.myFloat(seriesForm.series_weight - seriesForm.series_lose_weight - seriesForm.total_rejection)}} (Ct)</label>
                              </div>

                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series Weight (Received): &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.total_received)}} (Ct)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series Total Rejection (Ct) (%) : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.total_rejection) }} (Ct) ({{ $h.myFloat(seriesForm.total_rejection_per)}} %)</label>
                              </div>
                              <div class="mt-3" v-if="seriesForm.total_rejection">
                                  <label for="update-profile-form-6" class="form-label font-bold">Lot Total Rejection (Ct) (%) : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.total_lot_rejection) }} (Ct) ({{ $h.myFloat(seriesForm.total_lot_rejection_per)}} %)</label>
                              </div>
                              <!-- <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series Weight (in Process): &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.series_weight_in_process) }} (Ct)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series Weight (in Hand): &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.in_hand_weight) }} (Ct) [PA : {{ $h.myFloat(seriesForm.pa_rough_weight) }}] (Ct)</label>
                              </div> -->
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Galaxy Weight: &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ seriesForm.lot_orinnal_weight ? $h.myFloat(seriesForm.lot_orinnal_weight) + ' (Ct)' : ''  }}</label>
                              </div>
                              <div class="mt-3" v-if="seriesForm.total_cr_weight">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Polish Weight: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.total_cr_weight) }} (Ct)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Series Lose Weight: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.series_lose_weight) }} (Ct)</label>
                              </div>
                              <div class="mt-3" v-if="seriesForm.series_lot_lose_weight">
                                  <label for="update-profile-form-6" class="form-label font-bold">Lot Lose Weight: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.series_lot_lose_weight) }} (Ct)</label>
                              </div>
                              <div class="mt-3" v-if="seriesForm.total_fail_weight">
                                  <label for="update-profile-form-6" class="form-label font-bold">Fail Stock: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.total_fail_pieces) }} {{ seriesForm.total_fail_weight ? ' / ' + $h.myFloat(seriesForm.total_fail_weight) +  '(Ct)' : '' }}</label>
                              </div>
                              <div class="mt-3" v-if="seriesForm.total_lost_weight">
                                  <label for="update-profile-form-6" class="form-label font-bold">Lost Stock: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(seriesForm.total_lost_pieces) }} {{ seriesForm.total_lost_weight ? ' / ' + $h.myFloat(seriesForm.total_lost_weight) +  '(Ct)' : '' }}</label>
                              </div>

                          </div>
                          <div class="col-span-4 xl:col-span-4">
                              <div id="graph1" ref="graph1_wrap" style="width:100%; height:240px;"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5" v-if="printData.issue_block == 1">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Kapan Details </h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                      </div>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 xl:col-span-4">
                              <div>
                                  <label for="update-profile-form-6" class="form-label font-bold">Series No. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ kapanForm.series_code }}</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Kapan No. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_code }}</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Kapan Name (Type) : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_name }}</label>
                              </div>

                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Pcs : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ kapanForm.kapan_total_pcs }}</label>
                              </div>
                          </div>
                          <div class="col-span-12 xl:col-span-4">
                              <div class="">
                                  <label for="update-profile-form-6" class="form-label font-bold">Kapan Weight (Original) : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.kapan_weight) }} (Ct)</label>
                              </div>
                              <!-- <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                      >Kapan Weight (Available) : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.cr_avl_weight) }} (Ct)</label>
                  </div> -->
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Galaxy Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lot_weight) }} (Ct)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Polish Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.lot_current_weight) }} (Ct)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Lose Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lot_lose) }} (Ct)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Total Assort Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lot_assort) }} (Ct)</label>
                              </div>
                              <div class="mt-3" v-if="kapanForm.total_fail_weight">
                                  <label for="update-profile-form-6" class="form-label font-bold">Fail Stock: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_fail_pieces) }} {{ kapanForm.total_fail_weight ? ' / ' + $h.myFloat(kapanForm.total_fail_weight) +  '(Ct)' : '' }}</label>
                              </div>
                              <div class="mt-3" v-if="kapanForm.total_lost_weight">
                                  <label for="update-profile-form-6" class="form-label font-bold">Lost Stock: &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(kapanForm.total_lost_pieces) }} {{ kapanForm.total_lost_weight ? ' / ' + $h.myFloat(kapanForm.total_lost_weight) +  '(Ct)' : '' }}</label>
                              </div>

                              <!-- <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Est. Makeable : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ kapanForm.exp_mackable }} &nbsp;(%)</label>
                              </div>
                              <div class="mt-3">
                                  <label for="update-profile-form-6" class="form-label font-bold">Est. Polish : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ kapanForm.exp_polish }} &nbsp;(%)</label>
                              </div> -->

                          </div>
                          <div class="col-span-4 xl:col-span-4">
                              <div id="graph1" ref="graph2_wrap" style="width:100%; height:240px;"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5" v-if="screenData.final_polish_kapan_wise.length > 0">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Kapan Final Stock</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12">
                              <table class="text-center border mt-3 w-full">
                                  <thead>
                                      <tr class="bg-gray-200 border">
                                          <th class="p-2">KAPAN NAME</th>
                                          <th class="p-2">KAPAN WEIGHT</th>
                                          <th class="p-2">CERTY PCS</th>
                                          <th class="p-2">CERTY WT(CT)</th>
                                          <!-- <th class="p-2">CERTY (%)</th> -->
                                          <th class="p-2">NON-CERTY PCS</th>
                                          <th class="p-2">NON-CERTY WT(CT)</th>
                                          <!-- <th class="p-2">NON-CERTY (%)</th> -->
                                          <th class="p-2">TOTAL PCS</th>
                                          <th class="p-2">TOTAL WT(CT)</th>
                                          <th class="p-2">TOTAL (%)</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="border cursor-pointer" v-for="(item, index) in screenData.final_polish_kapan_wise" :key="index">
                                      <th class="p-2 w-40 border-r" @click="get_summary_details_by_kapan(item.id)">{{ item.kapan_code }}</th>
                                      <th class="p-2 w-40 border-r">{{ item.total_lot_weight }} Ct</th>
                                      <th class="p-2 w-40">{{ item.certi_pcs ? $h.myFloat(item.certi_pcs) : '0' }}</th>
                                      <th class="p-2 w-40">{{ item.certi_wt ? $h.myFloat(item.certi_wt) + ' Ct' : '0' }}</th>
                                      <!-- <th class="p-2 w-40 border-r">{{ item.certi_per ? $h.myFloat(item.certi_per) + ' %' : '0' }}</th> -->
                                      <th class="p-2 w-40">{{ item.non_certi_pcs ? $h.myFloat(item.non_certi_pcs) : '0' }}</th>
                                      <th class="p-2 w-40">{{ item.non_certi_wt ? $h.myFloat(item.non_certi_wt) + ' Ct' : '0' }}</th>
                                      <!-- <th class="p-2 w-40 border-r">{{ item.non_certi_per ? $h.myFloat(item.non_certi_per) + ' %' : '0' }}</th> -->
                                      <th class="p-2 w-40">{{ item.total_pcs ? $h.myFloat(item.total_pcs) : '0' }}</th>
                                      <th class="p-2 w-40">{{ item.total_wt ? $h.myFloat(item.total_wt) + ' Ct' : '0' }}</th>
                                      <th class="p-2 w-40">{{ item.total_per ? $h.myFloat(item.total_per) + ' %' : '0' }}</th>
                                    </tr>
                                  </tbody>
                                  <tr class="border cursor-pointer bg-gray-200">
                                    <th class="p-2 w-40 border-r"></th>
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.total_lot_weight ? $h.myFloat(screenData.final_polish_kapan_total.total_lot_weight) : '0' }}</th>
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.certi_pcs ? $h.myFloat(screenData.final_polish_kapan_total.certi_pcs) : '0' }}</th>
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.certi_wt ? $h.myFloat(screenData.final_polish_kapan_total.certi_wt) + ' Ct' : '0' }}</th>
                                    <!-- <th class="p-2 w-40 border-r">{{ screenData.final_polish_kapan_total.certi_per ? $h.myFloat(screenData.final_polish_kapan_total.certi_per) + ' %' : '0' }}</th> -->
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.non_certi_pcs ? $h.myFloat(screenData.final_polish_kapan_total.non_certi_pcs) : '0' }}</th>
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.non_certi_wt ? $h.myFloat(screenData.final_polish_kapan_total.non_certi_wt) + ' Ct' : '0' }}</th>
                                    <!-- <th class="p-2 w-40 border-r">{{ screenData.final_polish_kapan_total.non_certi_per ? $h.myFloat(screenData.final_polish_kapan_total.non_certi_per) + ' %' : '0' }}</th> -->
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.total_pcs ? $h.myFloat(screenData.final_polish_kapan_total.total_pcs) : '0' }}</th>
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.total_wt ? $h.myFloat(screenData.final_polish_kapan_total.total_wt) + ' Ct' : '0' }}</th>
                                    <th class="p-2 w-40">{{ screenData.final_polish_kapan_total.total_per ? $h.myFloat(screenData.final_polish_kapan_total.total_per) + ' %' : '0' }}</th>
                                  </tr>

                              </table>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5" v-if="screenData.charni_sub_header.length > 0">
                  <div class="intro-y mt-5">
                    <div class="grid grid-cols-12 py-3 border-b gap-2" v-if="screenData.final_kapan_details.length > 0">
                      <div class="col-span-6 md:col-span-3 xl:col-span-2 w-full py-1 m-0">
                          <h2 class="font-medium text-base mr-auto ml-5">Final Polish Details (In House)</h2>
                        </div>
                        <div class="col-span-6 md:col-span-3 xl:col-span-2 border w-full py-1 m-0" v-for="(item,index) in screenData.final_kapan_details" :key="index">
                            <div class="px-2">

                                <table class="w-full">

                                    <tr>
                                      <th class="w-40 text-left">
                                            {{ item.kapan_code }} ({{ $h.myFloat(item.total_final_polish ? item.total_final_polish : 0) }} Ct)
                                        </th>
                                        <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                      <div class="border-b">
                          <div class="overflow-x-auto scrollbar-hidden">

                              <div id="generate-pdf" class="p-5">
                                  <div class="preview">
                                      <div class="overflow-x-auto">
                                          <table class="w-full text-sm" v-if="screenData.charni_sub_header.length > 0">
                                              <thead>
                                                  <tr>

                                                      <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap" v-for="header in screenData.charni_sub_header" :key="header">
                                                          {{header}}
                                                      </th>

                                                  </tr>
                                              </thead>
                                              <tbody>

                                                  <tr class="dark:border-dark-5 whitespace-nowrap" v-for="record_itm in screenData.stage_summary_details" :key="record_itm">
                                                      <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record, inx) in record_itm" :key="inx">
                                                          <span v-if="inx == 0">

                                                              {{ record }}
                                                          </span>
                                                          <span v-else>

                                                              {{record > 0 ? $h.myFloat(record) : '0'}}
                                                          </span>
                                                      </td>
                                                  </tr>
                                              </tbody>

                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="intro-y mt-5">

                    <div class="grid grid-cols-12 py-3 gap-2 border-b" v-if="screenData.rtp_kapan_details.length > 0">
                        <div class="col-span-6 md:col-span-3 xl:col-span-2 w-full py-1 m-0">
                          <h2 class="font-medium text-base mr-auto ml-5">Final Polish Details (Rough To Polish)</h2>
                        </div>
                        <div class="col-span-6 md:col-span-3 xl:col-span-2 border w-full py-1 m-0" v-for="(item,index) in screenData.rtp_kapan_details" :key="index">
                            <div class="px-2">

                                <table class="w-full">

                                    <tr>
                                        <th class="w-40 text-left">
                                            {{ item.kapan_code }} ({{ $h.myFloat(item.total_final_polish ? item.total_final_polish : 0) }} Ct) ({{ item.party_name }})
                                        </th>
                                        <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                      <div>
                          <div class="overflow-x-auto scrollbar-hidden">

                              <div id="generate-pdf" class="p-5">
                                  <div class="preview">
                                      <div class="overflow-x-auto">
                                          <table class="text-sm" v-if="screenData.rtp_charni_sub_header.length > 0">
                                              <thead>
                                                  <tr>

                                                      <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap w-40" v-for="header in screenData.rtp_charni_sub_header" :key="header">
                                                          {{header}}
                                                      </th>

                                                  </tr>
                                              </thead>
                                              <tbody>

                                                  <tr class="dark:border-dark-5 whitespace-nowrap">
                                                      <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record, inx) in screenData.rtp_stage_received_details" :key="inx">
                                                          <span v-if="inx == 0">

                                                              {{ record }}
                                                          </span>
                                                          <span v-else>

                                                              {{record > 0 ? $h.myFloat(record) : '0'}}
                                                          </span>
                                                      </td>
                                                  </tr>
                                                  <tr class="dark:border-dark-5 whitespace-nowrap">
                                                      <td class="border-b border-l border-r text-center whitespace-nowrap font-bold" v-for="(record, inx) in screenData.rtp_stage_summary_details" :key="inx">
                                                          <span v-if="inx == 0">

                                                              {{ record }}
                                                          </span>
                                                          <span v-else>

                                                              {{record > 0 ? $h.myFloat(record) : '0'}}
                                                          </span>
                                                      </td>
                                                  </tr>
                                              </tbody>

                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Final Stock</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 lg:col-span-5" v-if="screenData.final_stock">
                              <h2 class="font-medium text-base mr-auto">TOTAL STOCK</h2>
                              <table class="text-center border mt-3 w-full">
                                  <thead>
                                      <tr class="bg-gray-200 border">
                                          <th class="p-2">KAPAN TYPE</th>
                                          <th class="p-2">STOCK TYPE</th>
                                          <th class="p-2">TOTAL PCS</th>
                                          <th class="p-2">TOTAL WEIGHT</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="border cursor-pointer">
                                      <th class="p-2 w-40 border-r" rowspan="2">FINAL POLISH</th>
                                      <th class="p-2 w-40">CERTI</th>
                                      <th class="p-2 w-40">{{ screenData.final_stock.total_certy_packets ? screenData.final_stock.total_certy_packets : '-' }}</th>
                                      <th class="p-2 w-40">{{ screenData.final_stock.total_certy_weight ? $h.myFloat(screenData.final_stock.total_certy_weight) + ' Ct' : '-' }}</th>
                                    </tr>
                                      <tr class="border cursor-pointer">
                                        <th class="p-2 w-40">NON CERTY</th>
                                        <th class="p-2 w-40">{{ screenData.final_stock.total_loose_packets ? screenData.final_stock.total_loose_packets : '-' }}</th>
                                        <th class="p-2 w-40">{{ screenData.final_stock.total_loose_weight ? $h.myFloat(screenData.final_stock.total_loose_weight) + ' Ct' : '-' }}</th>
                                      </tr>
                                      <tr class="border cursor-pointer">
                                      <th class="p-2 w-40 border-r" rowspan="2">ROUGH TO POLISH</th>
                                      <th class="p-2 w-40">CERTI</th>
                                      <th class="p-2 w-40">{{ screenData.final_stock.rtp_certy_packets ? screenData.final_stock.rtp_certy_packets : '-' }}</th>
                                      <th class="p-2 w-40">{{ screenData.final_stock.rtp_certy_weight ? $h.myFloat(screenData.final_stock.rtp_certy_weight) + ' Ct' : '-' }}</th>
                                    </tr>
                                      <tr class="border cursor-pointer">
                                        <th class="p-2 w-40">NON CERTY</th>
                                        <th class="p-2 w-40">{{ screenData.final_stock.rtp_loose_packets ? screenData.final_stock.rtp_loose_packets : '-' }}</th>
                                        <th class="p-2 w-40">{{ screenData.final_stock.rtp_loose_weight ? $h.myFloat(screenData.final_stock.rtp_loose_weight) + ' Ct' : '-' }}</th>
                                    </tr>
                                      <tr class="bg-gray-200 border">
                                        <th class="p-2 w-40 border-r" colspan="2">TOTAL</th>
                                        <th class="p-2 w-40">{{ screenData.final_stock.total_packets ? screenData.final_stock.total_packets : '-' }}</th>
                                        <th class="p-2 w-40">{{ screenData.final_stock.total_weight ? $h.myFloat(screenData.final_stock.total_weight) + ' Ct' : '-' }}</th>
                                    </tr>
                                  </tbody>

                              </table>
                          </div>
                          <div class="col-span-12 lg:col-span-7" v-if="screenData.certy_details.length > 0">
                            <h2 class="font-medium text-base mr-auto">CERTIFIED STOCK</h2>
                              <table class="table w-full text-center border mt-3">
                                  <thead>
                                      <tr class="bg-gray-200 border">
                                          <th>TYPE</th>
                                          <th>TOTAL PACKETS</th>
                                          <th>Total Weight</th>
                                          <th>AVERAGE REPO</th>
                                          <th>AVERAGE RATE</th>
                                          <th>AVERAGE BACK</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <template v-for="(item, index) in screenData.certy_details" :key="index">
                                          <tr class="border cursor-pointer" @click="get_certified_current_stocks(1)">
                                              <th>STOCK(WHITE )</th>
                                              <th>{{ item.stock_white_total_packets ? item.stock_white_total_packets : '-' }}</th>
                                              <th>{{ item.stock_white_total_weight ? $h.myFloat(item.stock_white_total_weight) + ' Ct' : '-' }}</th>
                                              <th>{{ item.stock_white_average_repo ? $h.myFloat(item.stock_white_average_repo) : '-' }}</th>
                                              <th>{{ item.stock_white_average_rate ? $h.myFloat(item.stock_white_average_rate) : '-' }}</th>
                                              <th>{{ item.stock_white_average_back ? $h.myFloat(item.stock_white_average_back) : '-' }}</th>
                                          </tr>
                                          <tr class="border cursor-pointer" @click="get_certified_current_stocks(2)">
                                              <th>STOCK(FANCY COLOR)</th>
                                              <th>{{ item.stock_fancy_total_packets ? item.stock_fancy_total_packets : '-' }}</th>
                                              <th>{{ item.stock_fancy_total_weight ? $h.myFloat(item.stock_fancy_total_weight) + ' Ct' : '-' }}</th>
                                              <th>{{ item.stock_fancy_average_repo ? $h.myFloat(item.stock_fancy_average_repo) : '-' }}</th>
                                              <th>{{ item.stock_fancy_average_rate ? $h.myFloat(item.stock_fancy_average_rate) : '-' }}</th>
                                              <th>{{ item.stock_fancy_average_back ? $h.myFloat(item.stock_fancy_average_back) : '-' }}</th>
                                          </tr>
                                          <tr class="border cursor-pointer" @click="get_certified_sell_stocks">
                                              <th>TOTAL SELL</th>
                                              <th>{{ item.sell_total_packets ? item.sell_total_packets : '-' }}</th>
                                              <th>{{ item.sell_total_weight ? $h.myFloat(item.sell_total_weight) + ' Ct' : '-' }}</th>
                                              <th>{{ item.sell_average_repo ? $h.myFloat(item.sell_average_repo) : '-' }}</th>
                                              <th>{{ item.sell_average_rate ? $h.myFloat(item.sell_average_rate) : '-' }}</th>
                                              <th>{{ item.sell_average_back ? $h.myFloat(item.sell_average_back) : '-' }}</th>
                                          </tr>
                                          <tr class="bg-gray-200 border cursor-pointer">
                                              <th>TOTAL STOCK</th>
                                              <th>{{ item.main_total_packets ? item.main_total_packets : '-' }}</th>
                                              <th>{{ item.main_total_weight ? $h.myFloat(item.main_total_weight) + ' Ct' : '-' }}</th>
                                              <th>{{ item.main_average_repo ? $h.myFloat(item.main_average_repo) : '-' }}</th>
                                              <th>{{ item.main_average_rate ? $h.myFloat(item.main_average_rate) : '-' }}</th>
                                              <th>{{ item.main_average_back ? $h.myFloat(item.main_average_back) : '-' }}</th>
                                          </tr>
                                      </template>
                                  </tbody>

                              </table>
                          </div>
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 mt-3" v-if="screenData.n_certy_details.length > 0">
                              <h2 class="font-medium text-base mr-auto">NON-CERTY STOCK</h2>
                              <table class="table w-full text-center border">
                                  <thead>
                                      <tr class="bg-gray-200 border">
                                          <th class="border">COLOR</th>
                                          <th class="border">SHAPE</th>
                                          <th class="border" colspan="2">Final Polish (In House)</th>
                                          <th class="border" colspan="2">RoughToPolish Stock</th>
                                          <th class="border" colspan="3">Merged Stock (In House)</th>
                                      </tr>
                                      <tr class="bg-gray-200 border">
                                          <th class="border-r"></th>
                                          <th class="border-r"></th>
                                          <th>Polish PCS</th>
                                          <th class="border-r">Polish WT</th>
                                          <th>RTP PCS</th>
                                          <th>RTP WT</th>
                                          <th>Merge PCS</th>
                                          <th>Merge WT</th>
                                          <th class="border-r">DIFF WT</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <template v-for="(item, index) in screenData.n_certy_details" :key="index">
                                          <tr class="border">
                                              <th class="border" rowspan="3">{{ item.segment_name }}</th>
                                              <th class="border">Round</th>
                                              <th class="border">{{ item.final_stock_round_total_packets ? item.final_stock_round_total_packets : '-' }}</th>
                                              <th class="border">{{ item.final_stock_round_total_weight ? $h.myFloat(item.final_stock_round_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.rtp_round_total_packets ? item.rtp_round_total_packets : '-' }}</th>
                                              <th class="border">{{ item.rtp_round_total_weight ? $h.myFloat(item.rtp_round_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.final_round_total_packets ? item.final_round_total_packets : '-' }}</th>
                                              <th class="border">{{ item.final_round_total_weight ? $h.myFloat(item.final_round_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.diff_stock_round_total_weight ? $h.myFloat(item.diff_stock_round_total_weight) + ' Ct' : '-' }}</th>
                                          </tr>
                                          <tr class="border">
                                              <th class="border">FANCY</th>
                                              <th class="border">{{ item.final_stock_fancy_total_packets ? item.final_stock_fancy_total_packets : '-' }}</th>
                                              <th class="border">{{ item.final_stock_fancy_total_weight ? $h.myFloat(item.final_stock_fancy_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.rtp_fancy_total_packets ? item.rtp_fancy_total_packets : '-' }}</th>
                                              <th class="border">{{ item.rtp_fancy_total_weight ? $h.myFloat(item.rtp_fancy_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.final_fancy_total_packets ? item.final_fancy_total_packets : '-' }}</th>
                                              <th class="border">{{ item.final_fancy_total_weight ? $h.myFloat(item.final_fancy_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.diff_stock_fancy_total_weight ? item.diff_stock_fancy_total_weight + ' Ct' : '-' }}</th>
                                          </tr>
                                          <tr class="border bg-gray-200">
                                              <th class="border">TOTAL</th>
                                              <th class="border">{{ item.final_stock_main_total_packets ? item.final_stock_main_total_packets : '-' }}</th>
                                              <th class="border">{{ item.final_stock_main_total_weight ? $h.myFloat(item.final_stock_main_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.rtp_main_total_packets ? item.rtp_main_total_packets : '-' }}</th>
                                              <th class="border">{{ item.rtp_main_total_weight ? $h.myFloat(item.rtp_main_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.final_main_total_packets ? item.final_main_total_packets : '-' }}</th>
                                              <th class="border">{{ item.final_main_total_weight ? $h.myFloat(item.final_main_total_weight) + ' Ct' : '-' }}</th>
                                              <th class="border">{{ item.diff_stock_main_total_weight ? $h.myFloat(item.diff_stock_main_total_weight) + ' Ct' : '-' }}</th>
                                          </tr>
                                        </template>
                                        <tr class="border bg-gray-200">
                                            <th class="border" colspan="2">TOTAL</th>
                                            <th class="border">{{ screenData.n_certy_total.final_stock_main_total_packets ? screenData.n_certy_total.final_stock_main_total_packets : '-' }}</th>
                                            <th class="border">{{ screenData.n_certy_total.final_stock_main_total_weight ? $h.myFloat(screenData.n_certy_total.final_stock_main_total_weight) + ' Ct' : '-' }}</th>
                                            <th class="border">{{ screenData.n_certy_total.rtp_main_total_packets ? screenData.n_certy_total.rtp_main_total_packets : '-' }}</th>
                                            <th class="border">{{ screenData.n_certy_total.rtp_main_total_weight ? $h.myFloat(screenData.n_certy_total.rtp_main_total_weight) + ' Ct' : '-' }}</th>
                                            <th class="border">{{ screenData.n_certy_total.final_main_total_packets ? screenData.n_certy_total.final_main_total_packets : '-' }}</th>
                                            <th class="border">{{ screenData.n_certy_total.final_main_total_weight ? $h.myFloat(screenData.n_certy_total.final_main_total_weight) + ' Ct' : '-' }}</th>
                                            <th class="border">{{ screenData.n_certy_total.diff_stock_main_total_weight ? $h.myFloat(screenData.n_certy_total.diff_stock_main_total_weight) + ' Ct' : '-' }}</th>
                                        </tr>
                                  </tbody>

                              </table>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Process Majuri Details</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                          <div class="flex mt-5 sm:mt-0">

                              <div class="dropdown w-1/2 sm:w-auto">
                                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                                  </button>
                                  <div class="dropdown-menu w-40">
                                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                          <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onProcessMajuriExportCsv">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                                          </a>
                                          <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onProcessMajuriExportJson">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                                          </a>
                                          <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onProcessMajuriExportXlsx">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                          </a>
                                          <!-- <a
                                  id="tabulator-export-xlsx"
                                  href="javascript:;"
                                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  @click="onProcessMajuriExportPrint"
                                >
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export Print
                                </a> -->

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 ">
                              <div id="TabulatorProcessLose" ref="TabulatorRefProcessLose" class="mt-2"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Assortment Details</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                          <div class="flex mt-5 sm:mt-0">

                              <div class="dropdown w-1/2 sm:w-auto">
                                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                                  </button>
                                  <div class="dropdown-menu w-40">
                                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                          <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onAssortExportCsv">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                                          </a>
                                          <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onAssortExportJson">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                                          </a>
                                          <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onAssortExportXlsx">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                          </a>

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 ">
                              <div id="tabulatorAssort" ref="tableRefAssort" class="mt-2"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Stock Details</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                          <div class="flex mt-5 sm:mt-0">

                              <div class="dropdown w-1/2 sm:w-auto">
                                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                                  </button>
                                  <div class="dropdown-menu w-40">
                                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                          <!-- <a
                                  id="tabulator-export-csv"
                                  href="javascript:;"
                                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  @click="onStockExportCsv"
                                >
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                                </a>
                                <a
                                  id="tabulator-export-json"
                                  href="javascript:;"
                                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  @click="onStockExportJson"
                                >
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                                </a> -->
                                          <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onStockExportXlsx">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                          </a>

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 ">
                              <div id="tabulatorStock" ref="tableRefStock" class="mt-2"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box mt-5">

                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Summary Details (Loose Stock)</h2>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

                      </div>
                  </div>
                  <div id="responsive-table" class="p-5" v-if="screenData.load_price">
                      <div class="preview">
                          <div class="overflow-x-auto">
                              <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                  <LoadingIcon icon="bars" class="w-8 h-8" />
                                  <div class="text-center text-xs mt-2">Getting Latest Records</div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div v-else>
                    <div v-if="screenData.genreport == 'gen'">
                      <div class="overflow-x-auto m-0 p-5 mt-5" v-for="(report_itm,report_index) in screenData.report_items.color_arr" :key="report_index">

                          <table class="text-xs text-center w-full">

                              <thead>
                                  <tr class="border bg-gray-200 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in report_itm.heading1_arr" :key="shape_index" :colspan="report_itm.heading1_size_arr[shape_index]">{{ shape_item }}</th>
                                  </tr>
                                  <tr class="border bg-gray-100 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in report_itm.heading2_arr" :key="size_index" :colspan="report_itm.size_arr_number[size_index]">{{ size_item }}</th>
                                  </tr>
                                  <tr class="border bg-gray-100 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in report_itm.heading3_arr" :key="size_index">{{ size_item }}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr class="border" v-for="(size_value_item,size_value_index) in report_itm.size_value_arr" :key="size_value_index">
                                      <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                                  </tr>
                                  <tr class="border">
                                      <th class="border py-2" v-for="(item,inx) in report_itm.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                                  </tr>
                              </tbody>
                          </table>

                      </div>

                  </div>
                  </div>
              </div>

          </div>
      </div>

      <!-- Begin: Stock Details: Lot wise -->
      <div id="current-stock-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body py-3 px-3">
                    <div class="flex justify-content-between">
                      <div>
                        <h2 class="font-medium text-lg mr-auto">Current Stock</h2>
                          <div v-if="screenData.stock_modal_item.process_id">
                              <div v-if="screenData.stock_modal_item.process_id == 2 || screenData.stock_modal_item.process_id == 29 || screenData.stock_modal_item.process_id == 3 || screenData.stock_modal_item.process_id == 26 || screenData.stock_modal_item.process_id == 28 || screenData.stock_modal_item.process_id == 31">
                                  {{ (screenData.stock_modal_item.operator_first_name ? screenData.stock_modal_item.operator_first_name : '-') + ' '  + (screenData.stock_modal_item.operator_last_name ? screenData.stock_modal_item.operator_last_name : '') }}
                              </div>
                              <div v-else-if="screenData.stock_modal_item.process_id == 18 || screenData.stock_modal_item.process_id == 19 || screenData.stock_modal_item.process_id == 20">
                                  {{ (screenData.stock_modal_item.planner_first_name ? screenData.stock_modal_item.planner_first_name : "-") + ' '  + (screenData.stock_modal_item.planner_last_name ? screenData.stock_modal_item.planner_last_name : "") + (screenData.stock_modal_item.planner_first_name ? ` (` + screenData.stock_modal_item.emp_code + `) ` : "") }}
                              </div>
                              <div v-else-if="screenData.stock_modal_item.process_id == 32">
                                  {{ (screenData.stock_modal_item.polish_party_first_name ? screenData.stock_modal_item.polish_party_first_name : '-') + ' '  + (screenData.stock_modal_item.polish_party_last_name ? screenData.stock_modal_item.polish_party_last_name : '') }}
                              </div>
                              <div v-else-if="[37, 38, 40, 41,42,].includes(screenData.stock_modal_item.process_id)">
                                  {{ (screenData.stock_modal_item.process_party_first_name ? screenData.stock_modal_item.process_party_first_name : '-') + ' '  + (screenData.stock_modal_item.process_party_last_name ? screenData.stock_modal_item.process_party_last_name : '') }}
                              </div>
                              <div v-else>
                                  {{ (screenData.stock_modal_item.employee_first_name ? screenData.stock_modal_item.employee_first_name : '-') + ' '  + (screenData.stock_modal_item.employee_last_name ? screenData.stock_modal_item.employee_last_name : '') }}
                              </div>
                          </div>
                          <div v-else-if="screenData.stock_modal_item.branch_id">
                              {{ (screenData.stock_modal_item.manager_first_name ? screenData.stock_modal_item.manager_first_name : '-') + ' '  + (screenData.stock_modal_item.manager_last_name ? screenData.stock_modal_item.manager_last_name : '') }}
                          </div>
                          <div v-else>
                              {{ (screenData.stock_modal_item.manager_first_name ? screenData.stock_modal_item.manager_first_name : '-') + ' '  + (screenData.stock_modal_item.manager_last_name ? screenData.stock_modal_item.manager_last_name : '') }}
                          </div>
                      </div>

                      <div class="flex mx-2 mt-2">
                          <div class="flex mt-5 sm:mt-0">

                              <div class="dropdown w-1/2 sm:w-auto">
                                  <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                                      <FileTextIcon class="w-4 h-4 mr-2" /> Export
                                      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                                  </button>
                                  <div class="dropdown-menu w-40">
                                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                          <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onStockLotExportXlsx(screenData.stock_modal_item)">
                                              <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                                          </a>

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                      <div class="px-5">
                          <div class="grid grid-cols-12 gap-x-5">
                            <div class="flex justify-content-between items-center p-5 border-b border-gray-200 dark:border-dark-5 w-full">
                              <h2 class="font-medium text-base mr-auto"></h2>
                            </div>
                            <div class="col-span-12">
                                <div class="intro-y my-2">
                                    <div class="overflow-x-auto scrollbar-hidden">
                                        <div id="packetStockTabulator" ref="packetStockTableRef" class="mt-5"></div>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- End: Stock Details: Lot wise -->

      <!-- Begin: Stock Details: Lot wise -->
      <div id="certified-current-stock-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body py-3 px-3">
                      <div class="px-5">
                          <h2 class="font-medium text-lg mr-auto">Certified Current Stock</h2>
                          <div class="grid grid-cols-12 gap-x-5">
                              <div class="col-span-12">
                                  <div class="intro-y my-2">
                                      <div class="overflow-x-auto scrollbar-hidden">
                                          <div id="certifiedStockTabulator" ref="certifiedStockTableRef" class="mt-5"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- End: Stock Details: Lot wise -->

      <!-- Begin: Stock Details: Lot wise -->
      <div id="certified-sell-stock-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body py-3 px-3">
                      <div class="px-5">
                          <h2 class="font-medium text-lg mr-auto">Certified Sell Stock</h2>
                          <div class="grid grid-cols-12 gap-x-5">
                              <div class="col-span-12">
                                  <div class="intro-y my-2">
                                      <div class="overflow-x-auto scrollbar-hidden">
                                          <div id="certifiedSellTabulator" ref="certifiedSellTableRef" class="mt-5"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- End: Stock Details: Lot wise -->

      <!-- Begin: Stock Details: Lot wise -->
      <div id="kapan-loose-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body py-3 px-3">
                      <div class="px-5">
                          <h2 class="font-medium text-lg mr-auto">Loose Kapan Stock</h2>
                          <div id="responsive-table" class="p-5" v-if="screenData.load_price_loose">
                      <div class="preview">
                          <div class="overflow-x-auto">
                              <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                  <LoadingIcon icon="bars" class="w-8 h-8" />
                                  <div class="text-center text-xs mt-2">Getting Latest Records</div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div v-else>
                    <div v-if="screenData.genreport_loose == 'gen'">
                      <div class="overflow-x-auto m-0 p-5 mt-5" v-for="(report_itm,report_index) in screenData.report_items_loose.color_arr" :key="report_index">

                          <table class="text-xs text-center w-full">

                              <thead>
                                  <tr class="border bg-gray-200 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in report_itm.heading1_arr" :key="shape_index" :colspan="report_itm.heading1_size_arr[shape_index]">{{ shape_item }}</th>
                                  </tr>
                                  <tr class="border bg-gray-100 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in report_itm.heading2_arr" :key="size_index" :colspan="report_itm.size_arr_number[size_index]">{{ size_item }}</th>
                                  </tr>
                                  <tr class="border bg-gray-100 dark:bg-dark-1">
                                      <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in report_itm.heading3_arr" :key="size_index">{{ size_item }}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr class="border" v-for="(size_value_item,size_value_index) in report_itm.size_value_arr" :key="size_value_index">
                                      <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                                  </tr>
                                  <tr class="border">
                                      <th class="border py-2" v-for="(item,inx) in report_itm.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                                  </tr>
                              </tbody>
                          </table>

                      </div>

                  </div>
                  </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- End: Stock Details: Lot wise -->
  </div>
  </template>

  <script>
  import {
      defineComponent,
      computed,
      ref,
      provide,
      reactive,
      onMounted,
      toRefs
  } from 'vue'
  import xlsx from 'xlsx'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import {
      useRouter,
      useRoute
  } from 'vue-router'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import {
      useStore
  } from '@/store'
  import moment from 'moment'
  import {
      helper as $h
  } from '@/utils/helper'
  import * as echarts from 'echarts/lib/echarts.js'
  import FileDownload from "js-file-download";

  import "echarts/lib/chart/line";
  import "echarts/lib/chart/bar";
  import "echarts/lib/chart/pie";
  import "echarts/lib/component/tooltip";
  import "echarts/lib/component/toolbox";
  import "echarts/lib/component/title";
  import "echarts/lib/component/legend";
  import "echarts/lib/component/markLine";
  import "echarts/lib/component/markPoint";
  import "echarts/lib/component/dataZoom";
  import "echarts/lib/component/dataZoomInside";
  import "echarts/lib/component/dataZoomSlider";

  export default defineComponent({
      setup() {
          const router = useRouter()
          const route = useRoute()
          const tableRef = ref()
          const tabulator = ref()

          const store = useStore()

          const TabulatorProcessLose = ref()
          const TabulatorRefProcessLose = ref()
          const packetStockTabulator = ref()
          const packetStockTableRef = ref()
          const certifiedStockTabulator = ref()
          const certifiedStockTableRef = ref()
          const certifiedSellTabulator = ref()
          const certifiedSellTableRef = ref()
          const tabulatorAssort = ref()
          const tableRefAssort = ref()
          const tabulatorStock = ref()
          const tableRefStock = ref()

          const userDetails = computed(() => store.state.user.currentUser)

          const filter = reactive({
              tender_id: '',
              tender_buyers: [],
              tender_items: []
          })

          const screenData = reactive({
              genreport: "no",
              genreport_loose: "no",
              issue_block: 2,
              kapans_ar: [],
              series_ar: [],
              charni_sub_header: [],
              final_polish_kapan_wise: [],
              final_polish_kapan_total: [],
              stage_summary_details: [],
              kapan_total_arr: [],
              final_kapan_details: [],
              rtp_kapan_details: [],
              rtp_charni_sub_header: [],
              rtp_stage_summary_details: [],
              rtp_stage_received_details: [],
              polish_detail_arr: [],
              report_details_polish_arr_total: [],
              polish_details_total_ar: [],
              blocking_detail_arr: [],
              report_details_blocking_arr_total: [],
              blocking_details_total_ar: [],
              makeable_detail_arr: [],
              report_details_makeable_arr_total: [],
              makeable_details_total_ar: [],
              planning_detail_arr: [],
              report_details_planning_arr_total: [],
              planning_details_total_ar: [],
              report_headers: [],
              report_sub_headers: [],
              footer_total: [],
              selectedKapan: '0',
              selectedSeries: '0',
              load_price: false,
              load_price_loose: false,
              certy_details: [],
              report_items: [],
              report_items_loose: [],
              n_certy_details: [],
              n_certy_total: [],
              final_stock: [],
              stock_modal_item: []

          })

          const kapanForm = reactive({
              lot_no: '',
              series_code: '',
              kapan_code: '',
              kapan_name: '',
              kapan_type: '',
              kapan_sub_type: '',
              kapan_purity: '',
              kapan_weight: 0,
              cr_avl_weight: 0,
              total_lose: 0,
              total_lot_lose: 0,
              total_lot_assort: 0,
              total_lot_weight: 0,
              total_lot_weight_series: 0,
              lot_current_weight: 0,
              unissued_kapan_weight: 0,
              kapan_total_pcs: '',
              cleaver_id: '',
              cleaver_name: '',
              cleaver_issue_date: '',
              exp_mackable: '',
              exp_polish: '',
              kapan_id: '',
              packet_status: '',
              total_lot_weight: '',
              series_weight: '',
              series_code: '',
              series_lose_weight: '',
              series_pa_rough: '',
              series_pa_bunch_weight: '',
              series_cr_avl_weight: '',
              series_total_kapan_weight: '',
              series_total_rejection: '',
              series_weight_in_process: '',
              series_in_hand: '',
              total_fail_weight: '',
              total_lost_weight: '',
              total_fail_pieces: '',
              total_lost_pieces: '',
          })
          const graph1_wrap = ref()
          const graph2_wrap = ref()
          const seriesForm = reactive({

              series_code: '',
              rough_id: '',
              series_weight: '',
              series_avl_weight: '',
              series_lose_weight: '',
              series_lot_lose_weight: '',
              issued_rough_weight: '',
              total_cr_weight: '',
              lot_orinnal_weight: '',
              pa_rough_weight: '',
              total_received: '',
              pa_rough_bunch_weight: '',
              in_hand_weight: '',
              unissued_rough_weight: '',
              unissued_pa_rough_weight: '',
              pa_bunch_weight: '',
              in_hand_weight: '',
              kapan_total_pcs: '',
              cleaver_issue_date: '',
              exp_mackable: '',
              series_weight_in_process: '',
              lot_no: '',
              cleaver_name: '',
              cleaver_id: '',
              packet_status: '',
              kapan_weight: '',
              total_rejection: '',
              total_lot_rejection: '',
              total_fail_weight: '',
              total_lost_weight: '',
              total_fail_pieces: '',
              total_lost_pieces: '',
              total_rejection_per: '',
          })
          const printData = reactive({
              kapan_id: '0',
              series_id: '0',
              issue_block: 2,
          })

          const getKapans = () => {
              let _this = this;
              const params = {

              };
              let promise = axios({
                  url: "api/dp_rough_kapan",
                  method: "POST",
                  params: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.kapans_ar = data.items;

                  })
                  .catch(_error => {

                  });
          }

          const getSeries = () => {
              let _this = this;
              const params = {

              };
              let promise = axios({
                  url: "api/dp_rough_series",
                  method: "POST",
                  params: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.series_ar = data.items;

                  })
                  .catch(_error => {

                  });
          }

          const generateReport = () => {

              if (screenData.issue_block == 1) {
                  screenData.selectedSeries = '';
              } else {
                  screenData.selectedKapan = '';
              }
              printData.kapan_id = screenData.selectedKapan
              printData.series_id = screenData.selectedSeries
              printData.issue_block = screenData.issue_block
              get_main_details()
              process_lose_report()
              if (printData.issue_block == 2 && printData.series_id != '0') {

                  assort_details()
                }
                get_kapan_wise_data()
                stock_details()
              final_stock()
              certy_stock()
              non_certy_stock()
              get_summary_details()
              stage_details()

          }

          //
          const get_summary_details = () => {
              printData.series_id = screenData.selectedSeries
              printData.kapan_id = screenData.selectedKapan

              if (printData.issue_block == 1) {
                  printData.series_id = '';
              } else {
                  printData.kapan_id = '';
              }
              screenData.load_price = true;
              screenData.genreport = 'no';
              screenData.report_items = [];
              const params = {
                  series_id: printData.series_id == '0' ? '' : printData.series_id,
                  kapan_id: printData.kapan_id == '0' ? '' : printData.kapan_id,
                  issue_block: printData.issue_block,
              };
              let promise = axios({
                  url: "api/get_loose_stock_details",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.report_items = data.items;
                      screenData.genreport = 'gen';
                      screenData.load_price = false;
                  })
                  .catch(_error => {
                      screenData.genreport = 'no';
                      screenData.load_price = false;
                  });
          }
          const get_summary_details_by_kapan = (id) => {
              printData.series_id = screenData.selectedSeries
              printData.kapan_id = screenData.selectedKapan

              if (printData.issue_block == 1) {
                  printData.series_id = '';
              } else {
                  printData.kapan_id = '';
              }

              cash('#kapan-loose-modal').modal('show');
              screenData.load_price_loose = true;
              screenData.genreport_loose = 'no';
              screenData.report_items_loose = [];
              const params = {
                  kapan_id: id
              };
              let promise = axios({
                  url: "api/get_loose_stock_detail_by_kapan",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.report_items_loose = data.items;
                      screenData.genreport_loose = 'gen';
                      screenData.load_price_loose = false;
                  })
                  .catch(_error => {
                      screenData.genreport_loose = 'no';
                      screenData.load_price_loose = false;
                  });
          }

          const get_main_details = () => {

              if (screenData.issue_block == 2) {

                  const params = {
                      series_id: window.btoa(screenData.selectedSeries)
                  };
                  let promise = axios({
                      url: "api/get_full_detail_series",
                      method: "POST",
                      params: params,
                      baseURL: BASE_URL,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token")
                      },
                      config: {
                          headers: {
                              Accept: "application/json"
                          }
                      }
                  });
                  return promise
                      .then(result => result.data)
                      .then(data => {

                          let rough_detail = data.item_details;
                          seriesForm.series_code = rough_detail.series_code;
                          seriesForm.rough_id = rough_detail.rough_lot_id;
                          //seriesForm.kapan_name = rough_detail.kapan_name;
                          seriesForm.series_weight = $h.myFloat(rough_detail.series_weight);
                          seriesForm.series_avl_weight = rough_detail.cr_avl_weight;
                          seriesForm.series_lose_weight = rough_detail.total_lose;
                          seriesForm.total_cr_weight = rough_detail.total_cr_weight;
                          seriesForm.total_lot_rejection = rough_detail.total_lot_rejection;
                          seriesForm.lot_orinnal_weight = rough_detail.lot_orinnal_weight;
                          seriesForm.series_lot_lose_weight = $h.myFloat(seriesForm.lot_orinnal_weight - seriesForm.total_cr_weight - seriesForm.total_lot_rejection);
                          seriesForm.issued_rough_weight = rough_detail.total_kapan_weight;
                          seriesForm.pa_rough_weight = rough_detail.mn_pa_rough;
                          seriesForm.total_received = rough_detail.total_received;
                          seriesForm.pa_rough_bunch_weight = rough_detail.mn_pa_bunch_weight;
                          seriesForm.in_hand_weight = rough_detail.in_hand;
                          seriesForm.unissued_rough_weight = rough_detail.cr_avl_weight - rough_detail.total_kapan_weight;
                          seriesForm.unissued_pa_rough_weight = $h.myFloat(rough_detail.pa_rough_weight - rough_detail.pa_rough_bunch_weight);
                          seriesForm.pa_bunch_weight = rough_detail.unissued_pa_rough_weight;
                          seriesForm.in_hand_weight = $h.myFloat((rough_detail.in_hand), 4);
                          seriesForm.kapan_total_pcs = rough_detail.kapan_total_pcs;
                          seriesForm.cleaver_issue_date = rough_detail.cleaver_issue_date;
                          seriesForm.exp_mackable = rough_detail.exp_mackable;
                          seriesForm.series_weight_in_process = rough_detail.weight_in_process;
                          seriesForm.lot_no = rough_detail.lot_no;
                          seriesForm.cleaver_name = rough_detail.first_name + " " + rough_detail.last_name;
                          seriesForm.cleaver_id = rough_detail.cleaver_id;
                          seriesForm.packet_status = rough_detail.pack_created;
                          seriesForm.kapan_weight = $h.myFloat($h.myFloat(rough_detail.in_hand) - $h.myFloat(rough_detail.mn_pa_rough));

                          seriesForm.total_rejection = rough_detail.total_rejection;
                          seriesForm.total_fail_weight = rough_detail.total_fail_weight;
                          seriesForm.total_lost_weight = rough_detail.total_lost_weight;
                          seriesForm.total_fail_pieces = rough_detail.total_fail_pieces;
                          seriesForm.total_lost_pieces = rough_detail.total_lost_pieces;

                          pie_series()
                          // console.log(this.unissued_rough_weight);
                          // let extra_buffer = 0;
                          // let remaining_rough = 0;
                          // let extra_remaning_buffer = 0;
                          // if (this.kapanForm.series_weight > 0) {
                          //     remaining_rough = this.$h.myFloat(rough_detail.in_hand) - this.$h.myFloat(rough_detail.mn_pa_rough);
                          //     this.unissued_rough_weight = remaining_rough;
                          //     extra_remaning_buffer = this.$h.myFloat(remaining_rough / 100);
                          //     this.unissued_rough_weight_buff = this.$h.myFloat(this.$h.myFloat(remaining_rough + extra_remaning_buffer));
                          //     console.log(this.unissued_rough_weight_buff);
                          // }

                          if (seriesForm.total_rejection > 0) {
                              seriesForm.total_rejection_per = Number(parseFloat(seriesForm.total_rejection) * 100) / Number(parseFloat(seriesForm.series_weight));
                          }

                          // if (rough_detail.assortment_details != "Fresh" && rough_detail.assortment_details != null) {
                          //     this.assorts = rough_detail.assortment_details;
                          // } else {
                          //     //
                          // }
                          // _this.pieWeight();
                      })
                      .catch(_error => {

                      });

              } else {
                  const params = {
                      kapan_id: window.btoa(screenData.selectedKapan)
                  };
                  let promise = axios({
                      url: "api/get_kapan_details",
                      method: "POST",
                      params: params,
                      baseURL: BASE_URL,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token")
                      },
                      config: {
                          headers: {
                              Accept: "application/json"
                          }
                      }
                  });
                  return promise
                      .then(result => result.data)
                      .then(data => {

                          let rough_detail = data.item_details;
                          kapanForm.kapan_code = rough_detail.kapan_code;
                          // this.rough_id = rough_detail.rough_lot_id;
                          kapanForm.kapan_name = rough_detail.kapan_name;
                          kapanForm.kapan_purity = rough_detail.kapan_purity;
                          kapanForm.kapan_type = rough_detail.kapan_type;
                          kapanForm.kapan_sub_type = rough_detail.kapan_sub_type;
                          kapanForm.kapan_weight = rough_detail.kapan_weight;
                          kapanForm.cr_avl_weight = $h.myFloat(rough_detail.cr_avl_weight);
                          kapanForm.total_lose = rough_detail.total_lose;
                          kapanForm.total_lot_lose = rough_detail.total_lot_lose;
                          kapanForm.total_lot_assort = rough_detail.total_lot_assort;
                          kapanForm.total_lot_weight = rough_detail.total_lot_weight;
                          kapanForm.lot_current_weight = rough_detail.lot_current_weight;
                          kapanForm.kapan_total_pcs = rough_detail.kapan_total_pcs;
                          kapanForm.cleaver_issue_date = rough_detail.cleaver_issue_date;
                          kapanForm.exp_mackable = rough_detail.exp_mackable;
                          kapanForm.exp_polish = rough_detail.exp_polish;
                          kapanForm.lot_no = rough_detail.lot_no;
                          kapanForm.cleaver_name = rough_detail.first_name + " " + rough_detail.last_name;
                          kapanForm.packet_status = rough_detail.pack_created;
                          kapanForm.total_lot_weight_series = rough_detail.total_lot_weight_series;

                          kapanForm.unissued_kapan_weight = kapanForm.cr_avl_weight - kapanForm.total_lot_weight;

                          //Series Details

                          kapanForm.series_weight = $h.myFloat(rough_detail.series_weight);
                          kapanForm.series_code = rough_detail.series_code;
                          kapanForm.series_lose_weight = $h.myFloat(rough_detail.series_lose_weight);
                          kapanForm.series_pa_rough = $h.myFloat(rough_detail.series_pa_rough);
                          kapanForm.series_pa_bunch_weight = $h.myFloat(rough_detail.series_pa_bunch_weight);
                          kapanForm.series_cr_avl_weight = $h.myFloat(rough_detail.series_cr_avl_weight);
                          kapanForm.series_total_kapan_weight = $h.myFloat(rough_detail.series_total_kapan_weight);
                          kapanForm.series_total_rejection = $h.myFloat(rough_detail.series_total_rejection);
                          kapanForm.series_weight_in_process = $h.myFloat(rough_detail.series_weight_in_process);

                          kapanForm.total_fail_weight = rough_detail.total_fail_weight;
                          kapanForm.total_lost_weight = rough_detail.total_lost_weight;
                          kapanForm.total_fail_pieces = rough_detail.total_fail_pieces;
                          kapanForm.total_lost_pieces = rough_detail.total_lost_pieces;

                          kapanForm.series_in_hand = $h.myFloat($h.myFloat(rough_detail.series_cr_avl_weight) - $h.myFloat(rough_detail.total_kapan_lot_weight) - $h.myFloat(rough_detail.series_weight_in_process));

                          pie_kapan()

                          // let remaining_rough = 0;
                          // let extra_remaning_buffer = 0;
                          // if(this.series_cr_avl_weight > 0){
                          //   remaining_rough = this.$h.myFloat(rough_detail.series_cr_avl_weight) - this.$h.myFloat(rough_detail.series_pa_rough);
                          //   extra_remaning_buffer = this.$h.myFloat(remaining_rough/100);
                          //   this.unissued_rough_weight_buff = this.$h.myFloat(this.$h.myFloat(remaining_rough + extra_remaning_buffer) - this.$h.myFloat(rough_detail.total_kapan_lot_weight) - this.$h.myFloat(rough_detail.series_weight_in_process) + this.$h.myFloat(rough_detail.total_lot_weight_series));
                          //   console.log("Unissued kapan" + this.unissued_rough_weight_buff);
                          // }

                          // if(this.packet_status == 'yes'){
                          //   this.fields = rough_detail.packet_details;
                          //   console.log(this.fields);
                          //   _this.totalPackweight();
                          // }
                          // if(rough_detail.pack_created != 'yes'){
                          //   _this.createEmptySlot();
                          // }
                          // _this.pieWeight();

                      })
                      .catch(_error => {

                      });
              }
          }

          const process_lose_report = () => {

              const params = {
                  series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                  kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                  issue_block: screenData.issue_block,
              };
              TabulatorProcessLose.value = new Tabulator(TabulatorRefProcessLose.value, {
                  ajaxURL: BASE_URL + '/api/fdsc_process_with_majuri',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  // pagination: 'remote',
                  // paginationSize: 20,
                  // paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  dataTree: true,
                  dataTreeStartExpanded: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'PROCESS',
                          minWidth: 150,
                          responsive: 0,
                          field: 'process_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'ISSUE PCS',
                          minWidth: 100,
                          responsive: 0,
                          field: 'issue_pieces',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().issue_pieces ? cell.getData().issue_pieces : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().issue_pieces ? cell.getData().issue_pieces : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      {
                          title: 'ISSUE WT(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'issue_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().issue_weight ? cell.getData().issue_weight : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().issue_weight ? cell.getData().issue_weight : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      {
                          title: 'REC. PCS',
                          minWidth: 100,
                          responsive: 0,
                          field: 'receive_pieces',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().receive_pieces ? cell.getData().receive_pieces : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().receive_pieces ? cell.getData().receive_pieces : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      {
                          title: 'REC. WT(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'receive_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().receive_weight ? cell.getData().receive_weight : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().receive_weight ? cell.getData().receive_weight : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      {
                          title: 'PENDING PCS',
                          minWidth: 100,
                          responsive: 0,
                          field: 'pending_pieces',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                                  <div class="whitespace-nowrap font-bold">${
                                  cell.getData().pending_pieces ? cell.getData().pending_pieces : '0'
                                  }
                                  </div>

                                  </div>`
                          },
                      },
                      {
                          title: 'PENDING WT(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'pending_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                                  <div class="whitespace-nowrap font-bold">${
                                  cell.getData().pending_weight ? cell.getData().pending_weight : '0'
                                  }
                                  </div>

                                  </div>`
                          },
                      },
                      {
                          title: 'GHAT(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'ghat_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().ghat_weight ? cell.getData().ghat_weight : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().ghat_weight ? cell.getData().ghat_weight : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      {
                          title: 'Assort(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'ghat_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().total_assortment ? cell.getData().total_assortment : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().total_assortment ? cell.getData().total_assortment : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      {
                          title: 'Total Majuri',
                          minWidth: 100,
                          responsive: 0,
                          field: 'total_majuri',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          // bottomCalcParams:{
                          //     precision:1,
                          // },
                          // topCalcParams:{
                          //     precision:1,
                          // },
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().total_majuri ? cell.getData().total_majuri : '0'
                              }
                              </div>

                              </div>`
                          },
                          // formatterExport(cell) {

                          //     return `<div>
                          //     <div class="whitespace-nowrap">${
                          //     cell.getData().total_majuri ? cell.getData().total_majuri : '0'
                          //     }
                          //     </div>

                          //     </div>`
                          // },
                      },
                      // {
                      //     title: 'FAIL PCS',
                      //     minWidth: 100,
                      //     responsive: 0,
                      //     field: 'fail_pieces',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     download: true,
                      //     formatter(cell) {

                      //         return `<div>
                      //         <div class="whitespace-nowrap">${
                      //         cell.getData().fail_pieces ? cell.getData().fail_pieces : '0'
                      //         }
                      //         </div>

                      //         </div>`
                      //     },
                      // },
                      // {
                      //     title: 'FAIL WT(Ct)',
                      //     minWidth: 100,
                      //     responsive: 0,
                      //     field: 'fail_weight',
                      //     vertAlign: 'middle',
                      //     print: false,
                      //     download: true,
                      //     formatter(cell) {

                      //         return `<div>
                      //         <div class="whitespace-nowrap">${
                      //         cell.getData().fail_weight ? cell.getData().fail_weight : '0'
                      //         }
                      //         </div>

                      //         </div>`
                      //     },
                      // },

                      // For print format

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }

          const get_current_stocks = (item) => {

              //
              console.log(item)

              if (item.branch_id) {

                  const params = {
                      series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                      kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                      issue_block: screenData.issue_block,
                      manager_id: (item.manager_id ? item.manager_id : ""),
                      process_id: (item.process_id ? item.process_id : ""),
                      karigar_id: (item.karigar_id ? item.karigar_id : ""),
                      branch_id: (item.branch_id ? item.branch_id : ""),
                  };
                  packetStockTabulator.value = new Tabulator(packetStockTableRef.value, {
                      ajaxURL: BASE_URL + '/api/fdsc_current_stock_lot_detail',
                      ajaxConfig: {
                          method: "GET", //set request type to Position
                          headers: {
                              "Content-type": 'application/json; charset=utf-8', //set specific content type
                              "Authorization": "Bearer " + localStorage.getItem("token")
                          },
                      },
                      ajaxParams: params,
                      ajaxFiltering: true,
                      ajaxSorting: true,
                      printAsHtml: true,
                      printStyled: true,
                      pagination: 'remote',
                      paginationSize: 20,
                      paginationSizeSelector: [20, 30, 40],
                      layout: 'fitColumns',
                      responsiveLayout: false,
                      tooltipsHeader: true,
                      headerSort: false,
                      dataTree: true,
                      dataTreeStartExpanded: false,
                      placeholder: 'No matching records found',
                      columns: [

                          // For HTML table
                          {
                              title: 'PacketNo',
                              minWidth: 150,
                              responsive: 0,
                              field: 'packet_code',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Stock No',
                              minWidth: 150,
                              responsive: 0,
                              field: 'lab_code',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Weight(Ct)',
                              minWidth: 100,
                              responsive: 0,
                              field: 'cr_weight',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                              formatter(cell) {

                                  return `<div>
                                <div class="whitespace-nowrap">${
                                cell.getData().cr_weight ? cell.getData().cr_weight : '0'
                                }
                                </div>

                                </div>`
                              },
                          },
                          {
                              title: 'Shape',
                              minWidth: 100,
                              responsive: 0,
                              field: 'shape_name',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Color',
                              minWidth: 100,
                              responsive: 0,
                              field: 'color_name',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Purity',
                              minWidth: 100,
                              responsive: 0,
                              field: 'purity_name',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Status',
                              minWidth: 200,
                              field: 'active_status',
                              hozAlign: 'center',
                              vertAlign: 'middle',
                              print: false,
                              download: false,
                              formatter(cell) {

                                  if (cell.getData().active_status == null) {
                                      return `<div class="flex items-center lg:justify-center text-theme-9">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'ACTIVE'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 2) {
                                      return `<div class="flex items-center lg:justify-center text-theme-1">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'ROUGH TO POLISH'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 3) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'FAILED'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 4) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'LOST'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 5) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'Blocked'
                              }
                            </div>`
                                  }
                              }
                          },

                          // For print format

                      ],
                      renderComplete() {
                          feather.replace({
                              'stroke-width': 1.5
                          })
                      }
                  })

              } else {

                  const params = {
                      series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                      kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                      issue_block: screenData.issue_block,
                      manager_id: (item.manager_id ? item.manager_id : ""),
                      process_id: (item.process_id ? item.process_id : ""),
                      karigar_id: (item.karigar_id ? item.karigar_id : ""),
                      branch_id: (item.branch_id ? item.branch_id : ""),
                  };
                  packetStockTabulator.value = new Tabulator(packetStockTableRef.value, {
                      ajaxURL: BASE_URL + '/api/fdsc_current_stock_lot_detail',
                      ajaxConfig: {
                          method: "GET", //set request type to Position
                          headers: {
                              "Content-type": 'application/json; charset=utf-8', //set specific content type
                              "Authorization": "Bearer " + localStorage.getItem("token")
                          },
                      },
                      ajaxParams: params,
                      ajaxFiltering: true,
                      ajaxSorting: true,
                      printAsHtml: true,
                      printStyled: true,
                      pagination: 'remote',
                      paginationSize: 20,
                      paginationSizeSelector: [20, 30, 40],
                      layout: 'fitColumns',
                      responsiveLayout: false,
                      tooltipsHeader: true,
                      headerSort: false,
                      dataTree: true,
                      dataTreeStartExpanded: false,
                      placeholder: 'No matching records found',
                      columns: [

                          // For HTML table
                          {
                              title: 'PacketNo',
                              minWidth: 150,
                              responsive: 0,
                              field: 'packet_code',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Weight(Ct)',
                              minWidth: 100,
                              responsive: 0,
                              field: 'cr_weight',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                              formatter(cell) {

                                  return `<div>
                                <div class="whitespace-nowrap">${
                                cell.getData().cr_weight ? cell.getData().cr_weight : '0'
                                }
                                </div>

                                </div>`
                              },
                          },
                          {
                              title: 'Shape',
                              minWidth: 100,
                              responsive: 0,
                              field: 'shape_name',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Color',
                              minWidth: 100,
                              responsive: 0,
                              field: 'color_name',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Purity',
                              minWidth: 100,
                              responsive: 0,
                              field: 'purity_name',
                              vertAlign: 'middle',
                              print: false,
                              download: true,
                          },
                          {
                              title: 'Status',
                              minWidth: 200,
                              field: 'active_status',
                              hozAlign: 'center',
                              vertAlign: 'middle',
                              print: false,
                              download: false,
                              formatter(cell) {

                                  if (cell.getData().active_status == null) {
                                      return `<div class="flex items-center lg:justify-center text-theme-9">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'ACTIVE'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 2) {
                                      return `<div class="flex items-center lg:justify-center text-theme-1">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'ROUGH TO POLISH'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 3) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'FAILED'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 4) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'LOST'
                              }
                            </div>`
                                  } else if (cell.getData().active_status == 5) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                              <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                                'Blocked'
                              }
                            </div>`
                                  }
                              }
                          },

                          // For print format

                      ],
                      renderComplete() {
                          feather.replace({
                              'stroke-width': 1.5
                          })
                      }
                  })
              }

          }

          const get_certified_current_stocks = (type) => {

              //
              cash('#certified-current-stock-modal').modal('show');
              const params = {
                  series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                  kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                  issue_block: screenData.issue_block,
                  stock_type: type
              };
              certifiedStockTabulator.value = new Tabulator(certifiedStockTableRef.value, {
                  ajaxURL: BASE_URL + '/api/fdsc_final_stock_certified_stock',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  dataTree: true,
                  dataTreeStartExpanded: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'PacketNo',
                          minWidth: 150,
                          responsive: 0,
                          field: 'packet_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Stock No',
                          minWidth: 150,
                          responsive: 0,
                          field: 'lab_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'LAB',
                          minWidth: 150,
                          responsive: 0,
                          field: 'lab',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Report No',
                          minWidth: 150,
                          responsive: 0,
                          field: 'report_no',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Branch',
                          minWidth: 150,
                          responsive: 0,
                          field: 'branch_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Weight(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'cr_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().cr_weight ? cell.getData().cr_weight : '0'
                              }
                              </div>

                              </div>`
                          },
                      },
                      {
                          title: 'Shape',
                          minWidth: 100,
                          responsive: 0,
                          field: 'shape_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Color',
                          minWidth: 100,
                          responsive: 0,
                          field: 'color_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Purity',
                          minWidth: 100,
                          responsive: 0,
                          field: 'purity_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },

                      // For print format

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }

          const stage_details = () => {

              let params = {
                  series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                  kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                  issue_block: screenData.issue_block,
              }
              const url = "/api/fdsc_stage_details"
              let promise = axios({
                  url: url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.charni_sub_header = data.charni_sub_header;
                      screenData.stage_summary_details = data.stage_summary_details;
                      screenData.kapan_total_arr = data.kapan_total_arr;
                      screenData.final_kapan_details = data.final_kapan_details;
                      screenData.rtp_kapan_details = data.rtp_kapan_details;
                      screenData.rtp_charni_sub_header = data.rtp_charni_sub_header;
                      screenData.rtp_stage_summary_details = data.rtp_stage_summary_details;
                      screenData.rtp_stage_received_details = data.rtp_stage_received_details;
                  })
                  .catch(_error => {
                      screenData.charni_sub_header = [];
                      screenData.stage_summary_details = [];
                  });
          }

          const get_kapan_wise_data = () => {

              let params = {
                  series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                  kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                  issue_block: screenData.issue_block,
              }
              const url = "/api/get_kapan_wise_final_list"
              let promise = axios({
                  url: url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                    screenData.final_polish_kapan_wise = data.items;
                    screenData.final_polish_kapan_total = data.total;
                  })
                  .catch(_error => {
                    screenData.final_polish_kapan_wise = [];
                    screenData.final_polish_kapan_total = [];
                  });
          }

          const get_certified_sell_stocks = () => {

              //
              cash('#certified-sell-stock-modal').modal('show');
              const params = {
                  series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                  kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                  issue_block: screenData.issue_block,
              };
              certifiedSellTabulator.value = new Tabulator(certifiedSellTableRef.value, {
                  ajaxURL: BASE_URL + '/api/fdsc_final_stock_certified_sell',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  dataTree: true,
                  dataTreeStartExpanded: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'PacketNo',
                          minWidth: 150,
                          responsive: 0,
                          field: 'packet_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Stock No',
                          minWidth: 150,
                          responsive: 0,
                          field: 'lab_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'LAB',
                          minWidth: 150,
                          responsive: 0,
                          field: 'lab',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Report No',
                          minWidth: 150,
                          responsive: 0,
                          field: 'report_no',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Branch',
                          minWidth: 150,
                          responsive: 0,
                          field: 'branch_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Weight(Ct)',
                          minWidth: 100,
                          responsive: 0,
                          field: 'cr_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              return `<div>
                              <div class="whitespace-nowrap">${
                              cell.getData().cr_weight ? cell.getData().cr_weight : '0'
                              }
                              </div>

                              </div>`
                          },
                      },
                      {
                          title: 'Shape',
                          minWidth: 100,
                          responsive: 0,
                          field: 'shape_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Color',
                          minWidth: 100,
                          responsive: 0,
                          field: 'color_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Purity',
                          minWidth: 100,
                          responsive: 0,
                          field: 'purity_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },

                      // For print format

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }

          const assort_details = () => {
              tabulatorAssort.value = new Tabulator(tableRefAssort.value, {
                  ajaxURL: BASE_URL + '/api/bunch_assort_list_items',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: {
                      series_id: window.btoa(printData.series_id)
                  },
                  dataTree: true,
                  //dataTreeStartExpanded:true,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [
                      // For HTML table
                      {
                          title: 'Assort Type',
                          minWidth: 200,
                          responsive: 0,
                          field: 'assortment_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Assort No',
                          field: 'assort_no',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Series No',
                          field: 'series_no',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Weight',
                          field: 'total_weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          // bottomCalcParams:{
                          //     precision:1,
                          // },
                          // topCalcParams:{
                          //     precision:1,
                          // },
                          formatter(cell) {
                              return Number(cell.getData().total_weight);
                          }
                      },
                      {
                          title: 'Weight(%)',
                          field: 'weight_per',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      //     {
                      //         title: 'Added At',
                      //         field: 'created_at',
                      //         hozAlign: 'left',
                      //         vertAlign: 'middle',
                      //         print: false,
                      //         download: true
                      //     },
                      //     {
                      //         title: '',
                      //         minWidth: 80,
                      //         field: '',
                      //         responsive: 1,
                      //         hozAlign: 'center',
                      //         vertAlign: 'middle',
                      //         headerSort: false,
                      //         print: false,
                      //         download: false,
                      //         formatter(cell) {
                      //             if (cell.getData().assort_no != '-') {
                      //                 const data_id = cell.getData().id;
                      //                 const a = cash(`<div class="flex lg:justify-center items-center">
                      //   <button class="btn btn-primary btn-sm flex items-center mr-3">
                      //     Edit
                      //   </button>
                      // </div>`)

                      //                 cash(a).on('click', function (data_id) {
                      //                     // On click actions
                      //                     _this.openEditAssort(cell.getData());

                      //                 })

                      //                 return a[0]

                      //             }

                      //         }
                      //     },
                  ],
                  renderComplete() {

                  }
              })
          }

          const stock_details = () => {
              tabulatorStock.value = new Tabulator(tableRefStock.value, {
                  ajaxURL: BASE_URL + '/api/fdsc_current_stock_summary',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: {
                      series_id: printData.series_id != '0' ? printData.series_id : '',
                      kapan_id: printData.kapan_id != '0' ? printData.kapan_id : '',
                      issue_block: printData.issue_block
                  },
                  dataTree: true,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [
                      // For HTML table
                      {
                          title: 'Manager/ Worker/ Party',
                          minWidth: 200,
                          responsive: 0,
                          field: 'owner_name',
                          vertAlign: 'middle',
                          print: false,
                          download: true,

                          formatter(cell) {

                              if (cell.getData().process_id) {

                                  if (cell.getData().process_id == 2 || cell.getData().process_id == 29 || cell.getData().process_id == 3 || cell.getData().process_id == 26 || cell.getData().process_id == 28 || cell.getData().process_id == 31) {
                                      return `<div class="">` + (cell.getData().operator_first_name ? cell.getData().operator_first_name : '-') + ' ' + (cell.getData().operator_last_name ? cell.getData().operator_last_name : '') + `<br>
                            </div>`;
                                  } else if (cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20) {
                                      return `<div class="">` + (cell.getData().planner_first_name ? cell.getData().planner_first_name : "-") + ' ' + (cell.getData().planner_last_name ? cell.getData().planner_last_name : "") + (cell.getData().planner_first_name ? ` (` + cell.getData().emp_code + `) ` : "") + `
                            </div>`;
                                  } else if (cell.getData().process_id == 32) {
                                      return `<div class="">` + (cell.getData().polish_party_first_name ? cell.getData().polish_party_first_name : '-') + ' ' + (cell.getData().polish_party_last_name ? cell.getData().polish_party_last_name : '') + `<br>
                                        </div>`;
                                  } else if ([37, 38, 40, 41, 42, ].includes(cell.getData().process_id)) {
                                      return `<div class="">` + (cell.getData().process_party_first_name ? cell.getData().process_party_first_name : '-') + ' ' + (cell.getData().process_party_last_name ? cell.getData().process_party_last_name : '') + `<br>
                                      </div>`;
                                  } else {
                                      return `<div class="">` + (cell.getData().employee_first_name ? cell.getData().employee_first_name : '-') + ' ' + (cell.getData().employee_last_name ? cell.getData().employee_last_name : '') + `<br>
                            </div>`;

                                  }
                              } else if (cell.getData().branch_id) {
                                  return `<div class="">` + (cell.getData().manager_first_name ? cell.getData().manager_first_name : '-') + ' ' + (cell.getData().manager_last_name ? cell.getData().manager_last_name : '') + `<br>
                            </div>`;
                              } else {
                                  return `<div class="">` + (cell.getData().manager_first_name ? cell.getData().manager_first_name : '-') + ' ' + (cell.getData().manager_last_name ? cell.getData().manager_last_name : '') + `<br>
                              </div>`;

                              }
                          },
                          formatterExcel(cell) {

                              if (cell.getData().process_id) {

                                  if (cell.getData().process_id == 2 || cell.getData().process_id == 29 || cell.getData().process_id == 3 || cell.getData().process_id == 26 || cell.getData().process_id == 28 || cell.getData().process_id == 31) {
                                      return `<div class="">` + (cell.getData().operator_first_name ? cell.getData().operator_first_name : '-') + ' ' + (cell.getData().operator_last_name ? cell.getData().operator_last_name : '') + `<br>
                            </div>`;
                                  } else if (cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id == 20) {
                                      return `<div class="">` + (cell.getData().planner_first_name ? cell.getData().planner_first_name : "-") + ' ' + (cell.getData().planner_last_name ? cell.getData().planner_last_name : "") + (cell.getData().planner_first_name ? ` (` + cell.getData().emp_code + `) ` : "") + `
                            </div>`;
                                  } else if (cell.getData().process_id == 32) {
                                      return `<div class="">` + (cell.getData().polish_party_first_name ? cell.getData().polish_party_first_name : '-') + ' ' + (cell.getData().polish_party_last_name ? cell.getData().polish_party_last_name : '') + `<br>
                                        </div>`;
                                  } else if ([37, 38, 40, 41, 42, ].includes(cell.getData().process_id)) {
                                      return `<div class="">` + (cell.getData().process_party_first_name ? cell.getData().process_party_first_name : '-') + ' ' + (cell.getData().process_party_last_name ? cell.getData().process_party_last_name : '') + `<br>
                                      </div>`;
                                  } else {
                                      return `<div class="">` + (cell.getData().employee_first_name ? cell.getData().employee_first_name : '-') + ' ' + (cell.getData().employee_last_name ? cell.getData().employee_last_name : '') + `<br>
                            </div>`;

                                  }
                              } else if (cell.getData().branch_id) {
                                  return `<div class="">` + (cell.getData().manager_first_name ? cell.getData().manager_first_name : '-') + ' ' + (cell.getData().manager_last_name ? cell.getData().manager_last_name : '') + `<br>
                            </div>`;
                              } else {
                                  return `<div class="">` + (cell.getData().manager_first_name ? cell.getData().manager_first_name : '-') + ' ' + (cell.getData().manager_last_name ? cell.getData().manager_last_name : '') + `<br>
                              </div>`;

                              }
                          },
                          cellClick: function (e, cell) {

                              get_current_stocks(cell.getData())
                              screenData.stock_modal_item = cell.getData()
                              cash('#current-stock-modal').modal('show');

                          },
                      },
                      {
                          title: 'Process/ Role',
                          field: 'assort_no',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {

                              if (cell.getData().process_id) {

                                  return `<div class=""><p class="text-theme-9 font-12 font-bold">` + (cell.getData().process_code ? cell.getData().process_code : "-") + `</p>
                            </div>`;

                              } else if (cell.getData().branch_id) {
                                  return cell.getData().branch_name ? (cell.getData().branch_name + ' Branch') : 'Home Branch'
                              } else {
                                  return `<div class=""><p class="font-12 font-bold">` + (cell.getData().role_name ? cell.getData().role_name : "-") + `</p>
                            </div>`;

                              }
                          },
                          formatterExport(cell) {

                              if (cell.getData().process_id) {

                                  return `<div class=""><p class="text-theme-9 font-12 font-bold">` + (cell.getData().process_code ? cell.getData().process_code : "-") + `</p>
                            </div>`;

                              } else if (cell.getData().branch_id) {
                                  return cell.getData().branch_name ? (cell.getData().branch_name + ' Branch') : 'Home Branch'
                              } else {
                                  return `<div class=""><p class="font-12 font-bold">` + (cell.getData().role_name ? cell.getData().role_name : "-") + `</p>
                            </div>`;

                              }
                          }
                      },
                      {
                          title: 'Available Stock',
                          field: 'available_pieces',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          formatter(cell) {

                              return (cell.getData().available_pieces ? cell.getData().available_pieces : 0)
                          }
                      },
                      {
                          title: 'Available Weight(Ct)',
                          field: 'available_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          // bottomCalcParams:{
                          //     precision:1,
                          // },
                          // topCalcParams:{
                          //     precision:1,
                          // },
                          formatter(cell) {

                              return (cell.getData().available_weight ? $h.myFloat(cell.getData().available_weight) : 0)
                          }
                      },
                      {
                          title: 'Certy Stock',
                          field: 'certy_pieces',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          formatter(cell) {

                              return (cell.getData().certy_pieces ? cell.getData().certy_pieces : 0)
                          }
                      },
                      {
                          title: 'Certy Weight(Ct)',
                          field: 'certy_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          // bottomCalcParams:{
                          //     precision:1,
                          // },
                          // topCalcParams:{
                          //     precision:1,
                          // },
                          formatter(cell) {

                              return (cell.getData().certy_weight ? $h.myFloat(cell.getData().certy_weight) : 0)
                          }
                      },
                      {
                          title: 'Failed Packets',
                          field: 'failed_pieces',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          formatter(cell) {

                              return (cell.getData().failed_pieces ? cell.getData().failed_pieces : 0)
                          }
                      },
                      {
                          title: 'Failed Weight(Ct)',
                          field: 'failed_weight',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          // topCalc:"sum",
                          // bottomCalc:"sum",
                          // bottomCalcParams:{
                          //     precision:1,
                          // },
                          // topCalcParams:{
                          //     precision:1,
                          // },
                          formatter(cell) {

                              return (cell.getData().failed_weight ? $h.myFloat(cell.getData().failed_weight) : 0)
                          }
                      },
                  ],
                  renderComplete() {

                  }
              })
          }

          const certy_stock = () => {

              let params = {
                  series_id: printData.series_id != '0' ? printData.series_id : '',
                  kapan_id: printData.kapan_id != '0' ? printData.kapan_id : '',
                  issue_block: printData.issue_block
              }
              const url = "/api/fdsc_final_stock_certified_details"
              let promise = axios({
                  url: url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.certy_details = data.items;

                      console.log(screenData.certy_details)
                  })
                  .catch(_error => {
                      screenData.certy_details = [];
                  });
          }
          const non_certy_stock = () => {

              let params = {
                  series_id: printData.series_id != '0' && printData.series_id != '' ? printData.series_id : '',
                  kapan_id: printData.kapan_id != '0' && printData.kapan_id != '' ? printData.kapan_id : '',
                  issue_block: printData.issue_block,
              }
              const url = "/api/fdsc_final_stock_loose_details"
              let promise = axios({
                  url: url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.n_certy_details = data.items;
                      screenData.n_certy_total = data.total;

                      console.log(screenData.n_certy_total)
                      // screenData.filter_show = false
                      // screenData.loading = false
                      // screenData.generate = 'gen'
                  })
                  .catch(_error => {
                      screenData.n_certy_details = [];
                      screenData.n_certy_total = [];
                      // screenData.loading = false
                  });
          }
          const final_stock = () => {

              let params = {
                  series_id: printData.series_id != '0' && printData.series_id != '' ? printData.series_id : '',
                  kapan_id: printData.kapan_id != '0' && printData.kapan_id != '' ? printData.kapan_id : '',
                  issue_block: printData.issue_block,
              }
              const url = "/api/get_final_stock_all"
              let promise = axios({
                  url: url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      screenData.final_stock = data.items;
                      // screenData.filter_show = false
                      // screenData.loading = false
                      // screenData.generate = 'gen'
                  })
                  .catch(_error => {
                      screenData.final_stock = [];
                      // screenData.loading = false
                  });
          }

          const pie_series = () => {

              let myChart = echarts.init(graph1_wrap.value);
              myChart.showLoading({
                  text: "Gathering Information",
                  color: "#145388"
              });
              let option = {
                  tooltip: {
                      trigger: 'item',
                      formatter: '<b>{b}</b> <br/><b>{c}({d}%)</b>'
                  },
                  legend: {
                      top: '20%',
                      left: 'right',
                      orient: 'vertical',
                  },
                  series: [{
                      name: 'Series Rough',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                      label: {
                          show: false,
                          position: 'center'
                      },
                      emphasis: {
                          label: {
                              show: true,
                              fontSize: '10',
                              fontWeight: 'bold'
                          }
                      },
                      labelLine: {
                          show: false
                      },
                      data: [{
                              value: $h.myFloat(seriesForm.series_weight - seriesForm.series_lose_weight - seriesForm.total_rejection - (seriesForm.total_cr_weight ? seriesForm.total_cr_weight : 0) - (seriesForm.series_lot_lose_weight ? seriesForm.series_lot_lose_weight : 0) - (seriesForm.total_lot_rejection ? seriesForm.total_lot_rejection : 0)),
                              name: 'Series Weight',
                              itemStyle: {
                                  color: "#34baeb"
                              }
                          },
                          {
                              value: seriesForm.total_cr_weight,
                              name: 'Kapan Weight',
                              itemStyle: {
                                  color: "#34eb7a"
                              }
                          },
                          {
                              value: $h.myFloat(parseFloat(seriesForm.series_lose_weight) + parseFloat(seriesForm.series_lot_lose_weight ? seriesForm.series_lot_lose_weight : 0)),
                              name: 'Ghat',
                              itemStyle: {
                                  color: "#ff0000"
                              }
                          },
                          {
                              value: $h.myFloat(parseFloat(seriesForm.total_rejection) + parseFloat(seriesForm.total_lot_rejection ? seriesForm.total_lot_rejection : 0)),
                              name: 'Rejection',
                              itemStyle: {
                                  color: "#f5b942"
                              }
                          },
                          {
                              value: $h.myFloat(seriesForm.total_fail_weight ? seriesForm.total_fail_weight : 0),
                              name: 'Fail (Ct)',
                              itemStyle: {
                                  color: "#262891"
                              }
                          },
                          {
                              value: $h.myFloat(seriesForm.total_lost_weight ? seriesForm.total_lost_weight : 0),
                              name: 'Lost (Ct)',
                              itemStyle: {
                                  color: "#2a8070"
                              }
                          }
                      ]
                  }]
              };
              myChart.hideLoading();
              myChart.setOption(option, true);
              myChart.resize();
          }
          const pie_kapan = () => {

              let myChart = echarts.init(graph2_wrap.value);
              myChart.showLoading({
                  text: "Gathering Information",
                  color: "#145388"
              });
              let option = {
                  tooltip: {
                      trigger: 'item'
                  },
                  legend: {
                      top: '20%',
                      left: 'right',
                      orient: 'vertical',
                  },
                  series: [{
                      name: 'Rough Allotment',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                      label: {
                          show: false,

                          position: 'center'
                      },
                      emphasis: {
                          label: {
                              show: true,
                              fontSize: '10',
                              fontWeight: 'bold'
                          }
                      },
                      labelLine: {
                          show: false
                      },
                      data: [{
                              value: parseFloat(kapanForm.unissued_kapan_weight).toFixed(4),
                              name: 'Unsorted',
                              itemStyle: {
                                  color: "#34baeb"
                              }
                          },
                          {
                              value: kapanForm.lot_current_weight,
                              name: 'Lot Available',
                              itemStyle: {
                                  color: "#34eb7a"
                              }
                          },
                          {
                              value: kapanForm.total_lot_lose,
                              name: 'Lot Ghat',
                              itemStyle: {
                                  color: "#ff0000"
                              }
                          },
                          {
                              value: kapanForm.total_lot_assort,
                              name: 'Lot Rejection',
                              itemStyle: {
                                  color: "#f5b942"
                              }
                          },
                          {
                              value: $h.myFloat(kapanForm.total_fail_weight ? kapanForm.total_fail_weight : 0),
                              name: 'Fail (Ct)',
                              itemStyle: {
                                  color: "#262891"
                              }
                          },
                          {
                              value: $h.myFloat(kapanForm.total_lost_weight ? kapanForm.total_lost_weight : 0),
                              name: 'Lost (Ct)',
                              itemStyle: {
                                  color: "#2a8070"
                              }
                          }

                      ]
                  }]
              };
              myChart.hideLoading();
              myChart.setOption(option, true);
              myChart.resize();
          }

          const focusFirst = () => {
              document.querySelector('[tabindex="2"]').focus();
          }
          const focusLast = () => {
              document.querySelector('[tabindex="6"]').focus();
          }

          function printReportDetails() {
              let report_type = 'full-detail-summary'
              let filename = "current_stock.xlsx";

              let params = {
                  report_type: report_type,
                  filters: printData,
                  items: {
                    'filters': printData,
                      'kapan_details': kapanForm,
                      'series_details': seriesForm,
                      'final_polish_kapan_wise': screenData.final_polish_kapan_wise,
                      'final_polish_kapan_total': screenData.final_polish_kapan_total,
                      'charni_sub_header': screenData.charni_sub_header,
                      'final_kapan_details': screenData.final_kapan_details,
                      'charni_sub_header': screenData.charni_sub_header,
                      'stage_summary_details': screenData.stage_summary_details,
                      'rtp_kapan_details': screenData.rtp_kapan_details,
                      'rtp_charni_sub_header': screenData.rtp_charni_sub_header,
                      'rtp_stage_summary_details': screenData.rtp_stage_summary_details,
                      'rtp_stage_received_details': screenData.rtp_stage_received_details,
                      'final_stock': screenData.final_stock,
                      'certy_details': screenData.certy_details,
                      'n_certy_details': screenData.n_certy_details,
                      'n_certy_total': screenData.n_certy_total,
                      'majuri_details': TabulatorProcessLose.value.getData(),
                      'assort_details': tabulatorAssort.value.getData(),
                      'stock_details': tabulatorStock.value.getData(),
                      'report_items': screenData.report_items,
                      // 'certy_details': screenData.certy_details,
                      // 'n_certy_details': screenData.n_certy_details,
                      // 'n_certy_total': screenData.n_certy_total,
                      // 'stock_details': tabulatorStock.value.getData(),
                      // 'stage_details': {
                      //     "polish_detail_arr": screenData.polish_detail_arr,
                      //     "polish_details_total_ar": screenData.polish_details_total_ar,
                      //     "blocking_detail_arr": screenData.blocking_detail_arr,
                      //     "blocking_details_total_ar": screenData.blocking_details_total_ar,
                      //     "makeable_detail_arr": screenData.makeable_detail_arr,
                      //     "makeable_details_total_ar": screenData.makeable_details_total_ar,
                      //     "planning_detail_arr": screenData.planning_detail_arr,
                      //     "planning_details_total_ar": screenData.planning_details_total_ar,
                      //     "report_headers": screenData.report_headers,
                      //     "report_sub_headers": screenData.report_sub_headers
                      // }
                  }
              }
              let promise = axios({
                  url: "api/export_full_report_details",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
                  responseType: "blob",
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    FileDownload(data, filename);

                  })
                  .catch(_error => {

                  });
              // add_report_details
          }

          const onResetFilter = () => {
              screenData.selectedSeries = '0'
              screenData.selectedKapan = '0'
          }

          const changeBlocktype = () => {
              screenData.selectedKapan = '0'
              screenData.selectedSeries = '0'
          }

          const onProcessMajuriExportCsv = () => {
              if (screenData.issue_block == 1) {

                  TabulatorProcessLose.value.download('csv', kapanForm.kapan_code + '-process-majuri.csv')
              } else {

                  TabulatorProcessLose.value.download('csv', seriesForm.series_code + '-process-majuri.csv')
              }
          }
          const onProcessMajuriExportJson = () => {
              if (screenData.issue_block == 1) {

                  TabulatorProcessLose.value.download('csv', kapanForm.kapan_code + '-process-majuri.json')
              } else {

                  TabulatorProcessLose.value.download('csv', seriesForm.series_code + '-process-majuri.json')
              }
          }
          const onProcessMajuriExportXlsx = () => {
              const win = window
              win.XLSX = xlsx
              if (screenData.issue_block == 1) {
                  TabulatorProcessLose.value.download('xlsx', kapanForm.kapan_code + '-process-majuri.xlsx', {
                      sheetName: 'Process Majuri'
                  })
              } else {

                  TabulatorProcessLose.value.download('xlsx', seriesForm.series_code + '-process-majuri.xlsx', {
                      sheetName: 'Process Majuri'
                  })
              }
          }
          const onProcessMajuriExportPrint = () => {
              const win = window
              win.XLSX = xlsx
              if (screenData.issue_block == 1) {
                  TabulatorProcessLose.value.download("pdf", kapanForm.kapan_code + '-process-majuri.pdf', {
                      orientation: "portrait", //set page orientation to portrait
                      title: "Process Majuri " + "( " + kapanForm.kapan_code + ")", //add title to report
                      // jsPDF:{
                      //     unit:"in", //set units to inches
                      // },
                      autoTable: { //advanced table styling
                          styles: {
                              fillColor: [100, 255, 255]
                          },
                          columnStyles: {
                              id: {
                                  fillColor: 255
                              }
                          },
                          margin: {
                              top: 60
                          },
                      },
                      documentProcessing: function (doc) {
                          //carry out an action on the doc object
                      }
                  });
                  // TabulatorProcessLose.value.download('xlsx', kapanForm.kapan_code + '-process-majuri.xlsx', {
                  //   sheetName: 'Process Majuri'
                  // })
              } else {

                  TabulatorProcessLose.value.download("pdf", seriesForm.series_code + '-process-majuri.pdf', {
                      orientation: "portrait", //set page orientation to portrait
                      title: "Process Majuri " + "( " + seriesForm.series_code + ")", //add title to report
                      // jsPDF:{
                      //     unit:"in", //set units to inches
                      // },
                      autoTable: { //advanced table styling
                          styles: {
                              fillColor: [100, 255, 255]
                          },
                          columnStyles: {
                              id: {
                                  fillColor: 255
                              }
                          },
                          margin: {
                              top: 60
                          },
                      },
                      documentProcessing: function (doc) {
                          //carry out an action on the doc object
                      }
                  });
              }
          }

          const onAssortExportCsv = () => {
              if (screenData.issue_block == 1) {

                  tabulatorAssort.value.download('csv', kapanForm.kapan_code + '-assort-details.csv')
              } else {

                  tabulatorAssort.value.download('csv', seriesForm.series_code + '-assort-details.csv')
              }
          }
          const onAssortExportJson = () => {
              if (screenData.issue_block == 1) {

                  tabulatorAssort.value.download('csv', kapanForm.kapan_code + '-assort-details.json')
              } else {

                  tabulatorAssort.value.download('csv', seriesForm.series_code + '-assort-details.json')
              }
          }
          const onAssortExportXlsx = () => {
              const win = window
              win.XLSX = xlsx
              if (screenData.issue_block == 1) {
                  tabulatorAssort.value.download('xlsx', kapanForm.kapan_code + '-assort-details.xlsx', {
                      sheetName: 'Assort Details'
                  })
              } else {

                  tabulatorAssort.value.download('xlsx', seriesForm.series_code + '-assort-details.xlsx', {
                      sheetName: 'Assort Details'
                  })
              }
          }

          const onStockExportCsv = () => {
              if (screenData.issue_block == 1) {

                  tabulatorStock.value.download('csv', kapanForm.kapan_code + '-assort-details.csv')
              } else {

                  tabulatorStock.value.download('csv', seriesForm.series_code + '-assort-details.csv')
              }
          }
          const onStockExportJson = () => {
              if (screenData.issue_block == 1) {

                  tabulatorStock.value.download('csv', kapanForm.kapan_code + '-assort-details.json')
              } else {

                  tabulatorStock.value.download('csv', seriesForm.series_code + '-assort-details.json')
              }
          }
          const onStockExportXlsx = () => {
              let filename = "stock_summary.xlsx";
              let params = {
                  series_id: printData.series_id != '0' && printData.series_id != '' ? printData.series_id : '',
                  kapan_id: printData.kapan_id != '0' && printData.kapan_id != '' ? printData.kapan_id : '',
                  issue_block: printData.issue_block,
              }
              let promise = axios({
                  url: "/api/export_current_stock_summary",
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
                  responseType: "blob",
              });

              return promise
                  .then((result) => result.data)
                  .then((data) => {
                      FileDownload(data, filename);
                  })
                  .catch((_error) => {
                      return [];
                  });
          }
          const onStockLotExportXlsx = (item) => {
              let filename = "current_stock.xlsx";
              let params = {
                series_id: screenData.selectedSeries == '0' ? '' : screenData.selectedSeries,
                kapan_id: screenData.selectedKapan == '0' ? '' : screenData.selectedKapan,
                issue_block: screenData.issue_block,
                manager_id: (item.manager_id ? item.manager_id : ""),
                process_id: (item.process_id ? item.process_id : ""),
                karigar_id: (item.karigar_id ? item.karigar_id : ""),
                branch_id: (item.branch_id ? item.branch_id : ""),
                user_items: item,
              }
              let promise = axios({
                  url: "/api/export_current_stock_lot_detail",
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
                  responseType: "blob",
              });

              return promise
                  .then((result) => result.data)
                  .then((data) => {
                      FileDownload(data, filename);
                  })
                  .catch((_error) => {
                      return [];
                  });
          }

          onMounted(() => {
              getKapans()
              getSeries()
              focusFirst()
          })

          return {
              tableRef,
              filter,
              printData,
              screenData,
              userDetails,
              onResetFilter,
              generateReport,
              changeBlocktype,
              focusFirst,
              focusLast,
              printReportDetails,
              kapanForm,
              seriesForm,
              graph1_wrap,
              graph2_wrap,
              TabulatorProcessLose,
              TabulatorRefProcessLose,
              packetStockTabulator,
              packetStockTableRef,
              certifiedStockTabulator,
              certifiedStockTableRef,
              certifiedSellTabulator,
              certifiedSellTableRef,
              tabulatorAssort,
              tableRefAssort,
              tabulatorStock,
              tableRefStock,
              get_certified_current_stocks,
              get_certified_sell_stocks,
              onProcessMajuriExportCsv,
              onProcessMajuriExportJson,
              onProcessMajuriExportXlsx,
              onProcessMajuriExportPrint,
              onAssortExportCsv,
              onAssortExportJson,
              onAssortExportXlsx,
              onStockExportCsv,
              onStockExportJson,
              onStockExportXlsx,
              onStockLotExportXlsx,
              get_summary_details_by_kapan

          }
      }
  })
  </script>
  <!-- get_full_process_lose_details -->
