<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto text-t">Ghutan PA Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-4">
          <div class="flex items-center">
            <label for="update-profile-form-6" class="font-medium mr-3"
              >Select Series</label
            >
            <TomSelect
          v-model="pagedata.series_id"
          :options="{
            placeholder: 'Select Series',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Series'
              }
            }
          }"
          class="w-60"
        >
        <option value="">Select Series</option>
        <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
        </TomSelect>
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="getSummaryReport"
            >
            Generate
            </button>

          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div>

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-20"
            @click="onResetFilter"
          >
            Reset
          </button>

          </div>
        </div>
        <div class="col-span-2 md:col-span-1" v-if="pagedata.generate == true">

          <div>

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-20"
            @click="printReportDetails"
          >
            Print
          </button>
          </div>
        </div>
      </div>
      <div id="responsive-table" class="p-5" v-if="pagedata.loading">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Records</div>
          </div>
          </div>
        </div>
      </div>
      <div class="mt-10" v-else>
        <div class="p-5" v-if="pagedata.generate == true">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-4 xl:col-span-4">
                  <div>
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Sr No. :&nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ pagedata.series_details.series_code }}</label
                    >
                  </div>

                  <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                      >Series Weight : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ pagedata.series_details.series_weight }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
        <!-- BEgin: Process Report Start-->
        <div class="grid grid-cols-12 gap-x-5" v-if="pagedata.generate == true">
          <div class="overflow-x-auto col-span-12 lg:col-span-8 md:col-span-8 sm:col-span-10">
            <div class="intro-y col-span-12 overflow-auto">
              <table class="mt-2 w-full border-2">
                <thead>
                  <tr class="border-b bg-gray-400 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Details</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">Persentage (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">GHUTELO MAL ( ઘુટેલો માલ )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.receive_weight ? $h.myFloat(pagedata.transection_data.receive_weight) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.transection_data.receive_weight ? $h.myFloat(pagedata.transection_data.receive_weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ pagedata.transection_data.receive_weight ? ' &nbsp; %' : '' }}</th>
                  </tr>
                  <tr class="border-b" v-for="(assort,ind) in pagedata.rough_assortments" :key="ind">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ assort.name }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ assort.assort_weight != null ? $h.myFloat(assort.assort_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ assort.assort_weight != null ? $h.myFloat(assort.assort_weight * 100 / pagedata.series_details.series_weight) : '-' }}   {{ pagedata.transection_data.receive_weight ? ' &nbsp; %' : '' }} </td>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">TOTAL GHAT ( ટૉટલ ધટ )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.total_ghat ? $h.myFloat(pagedata.total_ghat) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.total_ghat ? $h.myFloat(pagedata.total_ghat * 100 / pagedata.series_details.series_weight) : '-'  }} &nbsp; %</th>
                  </tr>
                  <tr class="border-b" v-for="(charni,index) in pagedata.charni_data" :key="index">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ charni.name }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ charni.issue_weight == null ? '-' : $h.myFloat(charni.issue_weight) + ' Ct' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</td>
                  </tr>
                  <tr class="border-b">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">TOTAL CHARNI WEIGHT ( ટૉટલ ચારણી વજન )</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.issue_weight ? $h.myFloat(pagedata.transection_data.issue_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</td>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r text-left">TOTAL MAJURI ( ટૉટલ મજુરી )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.total_majuri ? $h.myFloat(pagedata.transection_data.total_majuri) : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</th>
                  </tr>
                  <!-- <tr class="border-b">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">LAL GHAT</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r"></td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center"></td>
                  </tr> -->
                  <tr class="border-b">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">LS WEIGHT ( LS વજન )</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.ls_data.issue_weight ? $h.myFloat(pagedata.ls_data.issue_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</td>
                  </tr>
                  <tr class="border-b">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">LS GHAT ( LS ધટ )</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.ls_data.issue_weight ? $h.myFloat(pagedata.ls_data.issue_weight - pagedata.ls_data.receive_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.ls_data.receive_weight ? $h.myFloat((pagedata.ls_data.issue_weight -pagedata.ls_data.receive_weight) * 100 / pagedata.series_details.series_weight) : '-' }}  {{ pagedata.ls_data.receive_weight ? ' &nbsp; %' : '' }}</td>
                  </tr>
                  <tr class="border-b">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">PA WEIGHT ( PA વજન )</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.rough_bunch.bunch_weight ? $h.myFloat(pagedata.rough_bunch.bunch_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</td>
                  </tr>
                  <tr class="border-b">
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap border-r">PA GHAT ( PA ધટ )</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.rough_bunch.bunch_lose ? $h.myFloat(pagedata.rough_bunch.bunch_lose) + ' Ct' : '-' }}</td>
                    <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</td>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">LS MAJURI ( LS મજુરી )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">-</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">-</th>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">GHUTAN WEIGHT ( ઘુટન વજન )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.issue_weight ? $h.myFloat(pagedata.transection_data.issue_weight) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.transection_data.issue_weight ? $h.myFloat(pagedata.transection_data.issue_weight * 100 / pagedata.series_details.series_weight) : '-' }} &nbsp; %</th>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">GHUTAN GHAT ( ઘુટન ધટ )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.transection_data.receive_weight ? $h.myFloat(pagedata.transection_data.issue_weight -pagedata.transection_data.receive_weight) + ' Ct' : '-'}}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.transection_data.receive_weight ? $h.myFloat((pagedata.transection_data.issue_weight - pagedata.transection_data.receive_weight) * 100 / pagedata.series_details.series_weight) : '-' }} &nbsp; %</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, reactive,onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import store from '@/store'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      series_details: [],
      rough_assortments: [],
      transection_data: [],
      rough_bunch: [],
      ls_data: [],
      charni_data: [],
      total_ghat: 0,
      generate: false,
      loading: false,
      total_charni_weight: '',
      series_id: '',
      print_series: '',
      processes : [2,27]
    })
    const getRoughDetails = () => {

      let promise = axios({
        url: "api/get_ghutan_summarty_series",
        method: "post",
        baseURL: BASE_URL,
        data: { processes: pagedata.processes },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.series
          // pagedata.series_id = stringify(pagedata.serieses[0].id)
        })
        .catch(_error => {
        });
    }
    const getSummaryReport = () => {
pagedata.loading = true
      pagedata.print_series = pagedata.series_id
      let params = {
        series_id : pagedata.series_id
      }
      let promise = axios({
        url: 'api/get_ghutan_summarty',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.series_details = data.series_details
          pagedata.rough_assortments = data.rough_assortments
          pagedata.charni_data = data.charni_data
          pagedata.transection_data = data.transection_data
          pagedata.ls_data = data.ls_data
          pagedata.total_ghat = data.total_ghat
          pagedata.total_charni_weight = data.total_charni_weight
          pagedata.rough_bunch = data.rough_bunch
          pagedata.generate = true
        pagedata.loading = false
        })
        .catch(_error => {
pagedata.generate = true
        pagedata.loading = false
        });
    }
    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function printReportDetails(){
      let report_type = 'ghutan-series-summary'

      let params = {
        series_id : pagedata.print_series == '' ? '' : window.btoa(pagedata.print_series),
        print_report_name: report_type,

      }
      let promise = axios({
        url: "api/set_ghutan_summarty",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id
          const report_name = data.report_name

          let url = BASE_URL + '/' + report_name + '?id=' + window.btoa(report_id)
          window.open(url, '_blank');

        })
        .catch(_error => {

        });
      // add_report_details
    }

    onMounted(() => {
      checkPermission()
      getRoughDetails()

    })
    const onResetFilter = () => {
      pagedata.series_id = ''
    }

    return {
      pagedata,
      getSummaryReport,
      onResetFilter,
      printReportDetails
    }
  }
})
</script>
