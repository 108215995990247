<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet Late Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Series</label
              >
              <TomSelect
                v-model="screenData.series_id"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                @change="seriesChange"
              >
              <option value="0">Select Series</option>
              <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Kapan</label
              >
              <TomSelect
                v-model="screenData.kapan_id"
                :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }"
                class="w-full"
                @change="kapanChange"
              >
              <option value="0">Select Kapan</option>
              <option v-for="kapan in pagedata.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Packets</label
              >
              <TomSelect
                v-model="screenData.packets"
                :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Packets</option>
              <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Process</label
              >
              <TomSelect
                v-model="screenData.process_id"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="kapan in pagedata.processes" :key="kapan.id" :value="kapan.id">{{kapan.process_code}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                >Report Type</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.in_details"
              /></div>
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Porcess Wise</option>
              <option value="2">Employee Wise</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                  :disabled="screenData.issue_pending == true"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"
              :disabled="screenData.issue_pending == true"
              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1"
              >Select Stone</label
            >
              <TomSelect
                v-model="screenData.stone_id"
                :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Stone</option>
              <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                >Due</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.due" @change="dueChanged"
              />
              <label for="update-profile-form-6" class="form-label py-1 ml-3"
                >Over Due</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="screenData.over_due" @change="overDueChanged"
              /></div>
              <div v-if="screenData.due">
              <input
                type= "text"
                id="update-profile-form-13"
                class="form-control"
                placeholder="Due Days"
                v-model="screenData.due_days"
              />
              </div>
              <div v-if="screenData.over_due">
              <input
                type= "text"
                id="update-profile-form-13"
                class="form-control"
                placeholder="Over Due Days"
                v-model="screenData.over_due_days"
              />
              </div>
            </div>

        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_details.length > 0">
          <div class="mt-7 flex pt-3">

            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button> -->
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="export_excel_temp"
              :disabled="pagedata.export_loading == true"
            >
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-4" :hidden="pagedata.export_loading == false"/>
            Export
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_details.length > 0">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              @click="printReportDetails"
            >
            Print
            </button>
            <!-- <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              v-print="'#generate-pdf'"
            >
            Print
            </button> -->
          </div>
        </div>

      </div>
      <div id="generate-pdf" class="mt-5">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2" v-if="printData.report_type == 1 && printData.in_details == false">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Stock</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Weight (Ct)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class=" border-b" @click="showModalDetails(emp_item)" v-for="(emp_item,index) in pagedata.report_details" :key="index">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ index + 1 }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.process_code }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.issue_packets ? emp_item.issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.issue_weight ? $h.myFloat(emp_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.f_issue_packets ? emp_item.f_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.f_issue_weight ? $h.myFloat(emp_item.f_issue_weight) : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.s_issue_packets ? emp_item.s_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.s_issue_weight ? $h.myFloat(emp_item.s_issue_weight) : '-'}}</th>
                    </tr>
                  </tbody>
                </table>
                <table class="mt-3 w-full border-2" v-if="printData.report_type == 1 && printData.in_details == true">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapans</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Stock</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Weight (Ct)</th>
                    </tr>
                  </thead>
                  <tbody v-for="(emp_item,index) in pagedata.report_details" :key="index">
                    <tr v-for="(kapan_item,kapan_index) in emp_item.kapan_details" :key="kapan_index">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ kapan_index == 0 ? (index + 1) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ kapan_index == 0 ? (emp_item.process_code) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.kapan_code }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.issue_packets ? kapan_item.issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.issue_weight ? $h.myFloat(kapan_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.f_issue_packets ? kapan_item.f_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.f_issue_packets ? $h.myFloat(kapan_item.f_issue_packets ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.s_issue_packets ? kapan_item.s_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(kapan_item)">{{ kapan_item.s_issue_weight ? $h.myFloat(kapan_item.s_issue_weight ): '-'}}</th>
                    </tr>
                    <tr class="bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border">Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.total_kapans}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.issue_packets ? emp_item.issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.issue_weight ? $h.myFloat(emp_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.f_issue_packets ? emp_item.f_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.f_issue_weight ? $h.myFloat(emp_item.f_issue_weight) : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.s_issue_packets ? emp_item.s_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.s_issue_weight ? $h.myFloat(emp_item.s_issue_weight) : '-'}}</th>
                    </tr>
                  </tbody>
                </table>
                <table class="mt-3 w-full border-2" v-if="printData.report_type == 2 && printData.in_details == true">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Employee</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapan</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Stock</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Weight (Ct)</th>
                    </tr>
                  </thead>
                  <tbody v-for="(man_item,index) in pagedata.report_details" :key="index">
                    <tr v-for="(proc_item,proc_index) in man_item.kapan_details" :key="proc_index">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ proc_index == 0 ? (index + 1) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ proc_index == 0 ? (man_item.employee_company_name ? man_item.employee_company_name : (man_item.employee_first_name + ' ' + (man_item.employee_last_name ? man_item.employee_last_name : '')) ) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ proc_index == 0 ? (proc_item.process_code) : '' }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.kapan_code ? proc_item.kapan_code : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.issue_packets ? proc_item.issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.issue_weight ? $h.myFloat(proc_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.f_issue_packets ? proc_item.f_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.f_issue_weight ? $h.myFloat(proc_item.f_issue_weight) : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.s_issue_packets ? proc_item.s_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b" @click="showModalDetails(proc_item)">{{ proc_item.s_issue_weight ? $h.myFloat(proc_item.s_issue_weight) : '-'}}</th>
                    </tr>
                    <tr class="bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border">Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.total_kapans}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.issue_packets ? man_item.issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.issue_weight ? $h.myFloat(man_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.f_issue_packets ? man_item.f_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.f_issue_weight ? $h.myFloat(man_item.f_issue_weight) : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.s_issue_packets ? man_item.s_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ man_item.s_issue_weight ? $h.myFloat(man_item.s_issue_weight) : '-'}}</th>
                    </tr>
                  </tbody>
                </table>
                <table class="mt-3 w-full border-2" v-if="printData.report_type == 2 && printData.in_details == false">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Employee</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Process</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Stock</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Current Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Due Weight (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Over Due Weight (Ct)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class=" border-b" @click="showModalDetails(emp_item)" v-for="(emp_item,index) in pagedata.report_details" :key="index">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ index + 1 }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ [32,37,38].includes(emp_item.process_id) ? (emp_item.company_name ? emp_item.polish_party_company_name : (emp_item.polish_party_first_name + (emp_item.polish_party_last_name ? emp_item.polish_party_last_name : ''))) :
                        ([2,29,27,28,31].includes(emp_item.process_id) ? (emp_item.operator_first_name + (emp_item.operator_last_name ? emp_item.operator_last_name : '')) : [18,19,20].includes(emp_item.process_id) ? (emp_item.planner_first_name + (emp_item.planner_last_name ? emp_item.planner_last_name : '')) : (emp_item.employe_first_name + (emp_item.employe_last_name ? emp_item.employe_last_name : ''))) }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">{{ emp_item.process_code }}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.issue_packets ? emp_item.issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.issue_weight ? $h.myFloat(emp_item.issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.f_issue_packets ? emp_item.f_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.f_issue_weight ? $h.myFloat(emp_item.f_issue_weight ): '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.s_issue_packets ? emp_item.s_issue_packets : '-'}}</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r border-b">{{ emp_item.s_issue_weight ? $h.myFloat(emp_item.s_issue_weight ): '-'}}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="late-packets-pending-view"
       class="modal"
       tabindex="-1"
       aria-hidden="true"
     >
       <div class="modal-dialog modal-xl">
         <div class="modal-content">
           <a data-dismiss="modal" href="javascript:;">
               <XIcon class="w-8 h-8 text-gray-500" />
           </a>
           <div class="modal-body p-10">
             <!-- BEGIN: Vertical Form -->
             <div class="intro-y">
              <h2 class="text-lg font-medium mr-auto my-2">Lot Details</h2>
              <div class="grid grid-cols-12 gap-x-2" v-if="modalData.in_details == false">
                <div class="col-span-6 md:col-span-4">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-1"
                        >Select Series</label
                      >
                      <TomSelect
                        v-model="modalData.series_id"
                        :options="{
                          placeholder: 'Select Series',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Series'
                            }
                          }
                        }"
                        class="w-full"
                        @change="getModalKapans('update')"
                      >
                      <option value="0">Select Series</option>
                      <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
                      </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-1"
                        >Select Kapan</label
                      >
                      <TomSelect
                        v-model="modalData.kapan_id"
                        :options="{
                          placeholder: 'Select Kapan',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Kapan'
                            }
                          }
                        }"
                        class="w-full"
                        @change="getModalPackets('update')"
                      >
                      <option value="0">Select Kapan</option>
                      <option v-for="kapan in pagedata.modal_kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                      </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-1"
                        >Select Packets</label
                      >
                      <TomSelect
                        v-model="modalData.packets"
                        :options="{
                          placeholder: 'Select Packet',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Packets'
                            }
                          }
                        }"
                        class="w-full"
                        multiple
                      >
                      <option value="">Select Packets</option>
                      <option v-for="packet in pagedata.modal_packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                      </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4">
                    <div>
                      <label for="update-profile-form-6" class="form-label py-1"
                      >Select Stone</label
                    >
                      <TomSelect
                        v-model="modalData.stone_id"
                        :options="{
                          placeholder: 'Select Stone',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Stone'
                            }
                          }
                        }"
                        class="w-full"
                      >
                      <option value="0">Select Stone</option>
                      <option v-for="stone in pagedata.stones" :key="stone.id" :value="stone.id">{{stone.stone_name}}</option>
                      </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4">
                    <div>
                      <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                        >Due</label
                      ><input
                        class="show-code form-check-switch mr-0 ml-3"
                        type="checkbox" v-model="modalData.due" @change="modaldueChanged"
                      />
                      <label for="update-profile-form-6" class="form-label py-1 ml-3"
                        >Over Due</label
                      ><input
                        class="show-code form-check-switch mr-0 ml-3"
                        type="checkbox" v-model="modalData.over_due" @change="modaloverDueChanged"
                      /></div>
                      <div v-if="modalData.due">
                      <input
                        type= "text"
                        id="update-profile-form-13"
                        class="form-control"
                        placeholder="Due Days"
                        v-model="modalData.due_days"
                      />
                      </div>
                      <div v-if="modalData.over_due">
                      <input
                        type= "text"
                        id="update-profile-form-13"
                        class="form-control"
                        placeholder="Over Due Days"
                        v-model="modalData.over_due_days"
                      />
                      </div>
                    </div>

                </div>
                <div class="col-span-2 md:col-span-2">
                  <div class="mt-7 flex pt-3">

                    <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      tabindex="5"
                      @click="modalGenerateReport()"
                    >
                    Generate
                    </button>
                  </div>
                </div>
                <div class="col-span-2 md:col-span-2" v-if="pagedata.modal_items.length > 0">
                  <div class="mt-7 flex pt-3">

                    <!-- <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      @click="printReportDetails"
                    >
                    Print
                    </button> -->
                    <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      v-print="'#modal-print-lot-packets'"
                    >
                    Print
                    </button>
                  </div>
                </div>

              </div>
              <div class="grid grid-cols-12 gap-x-2" v-if="modalData.in_details == true">
                <div class="col-span-6 md:col-span-4">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-1"
                        >Select Packets</label
                      >
                      <TomSelect
                        v-model="modalData.packets"
                        :options="{
                          placeholder: 'Select Packet',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Packets'
                            }
                          }
                        }"
                        class="w-full"
                        multiple
                      >
                      <option value="">Select Packets</option>
                      <option v-for="packet in pagedata.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                      </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4">
                    <div>
                      <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                        >Due</label
                      ><input
                        class="show-code form-check-switch mr-0 ml-3"
                        type="checkbox" v-model="modalData.due" @change="dueChanged"
                      />
                      <label for="update-profile-form-6" class="form-label py-1 ml-3"
                        >Over Due</label
                      ><input
                        class="show-code form-check-switch mr-0 ml-3"
                        type="checkbox" v-model="modalData.over_due" @change="overDueChanged"
                      /></div>
                      <div v-if="modalData.due">
                      <input
                        type= "text"
                        id="update-profile-form-13"
                        class="form-control"
                        placeholder="Due Days"
                        v-model="modalData.due_days"
                      />
                      </div>
                      <div v-if="modalData.over_due">
                      <input
                        type= "text"
                        id="update-profile-form-13"
                        class="form-control"
                        placeholder="Over Due Days"
                        v-model="modalData.over_due_days"
                      />
                      </div>
                    </div>

                </div>
                <div class="col-span-2 md:col-span-2">
                  <div class="mt-7 flex pt-3">

                    <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      tabindex="5"
                      @click="modalGenerateReport()"
                    >
                    Generate
                    </button>
                  </div>
                </div>
                <div class="col-span-2 md:col-span-2" v-if="pagedata.modal_items.length > 0">
                  <div class="mt-7 flex pt-3">

                    <!-- <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      @click="printReportDetails"
                    >
                    Print
                    </button> -->
                    <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      v-print="'#modal-print-lot-packets'"
                    >
                    Print
                    </button>
                  </div>
                </div>

              </div>
              <div class="mt-5" id="modal-print-lot-packets">
                <div id="responsive-table" class="p-5" v-if="pagedata.modal_loading">
                  <div class="preview">
                    <div class="overflow-x-auto">
                      <div
                        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                      >
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Latest Price</div>
                    </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <table class="mt-3 w-full border-2">
                    <thead>
                      <tr class="border-b bg-gray-200 dark:bg-dark-1">
                        <th class="py-2 text-xs lg:text-md border-r">#</th>
                        <th class="py-2 text-xs lg:text-md border-r">Series / Packet</th>
                        <th class="py-2 text-xs lg:text-md border-r">Issue Pieces</th>
                        <th class="py-2 text-xs lg:text-md border-r">Issue Weight (Ct)</th>
                        <th class="py-2 text-xs lg:text-md border-r">Manager</th>
                        <th class="py-2 text-xs lg:text-md border-r">Employee</th>
                        <th class="py-2 text-xs lg:text-md border-r">Issue Date</th>
                        <th class="py-2 text-xs lg:text-md border-r">Days</th>
                        <th class="py-2 text-xs lg:text-md border-r">Due Days</th>
                        <th class="py-2 text-xs lg:text-md border-r">Over Due Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class=" border-b" v-for="(emp_item,index) in pagedata.modal_items" :key="index">
                        <th class="py-2 text-xs lg:text-md border-r">{{ index + 1 }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.packet_code }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.issue_pieces ? emp_item.issue_pieces : '1' }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.issue_weight }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.manager_first_name + ' ' + (emp_item.manager_last_name ? emp_item.manager_last_name : '') }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ [32,37,38].includes(emp_item.process_id) ? (emp_item.company_name ? emp_item.polish_party_company_name : (emp_item.polish_party_first_name + (emp_item.polish_party_last_name ? emp_item.polish_party_last_name : ''))) :
                        ([2,29,27,28,31].includes(emp_item.process_id) ? (emp_item.operator_first_name + (emp_item.operator_last_name ? emp_item.operator_last_name : '')) :
                        [18,19,20].includes(emp_item.process_id) ? (emp_item.planner_first_name + (emp_item.planner_last_name ? emp_item.planner_last_name : '')) :
                        (emp_item.employe_first_name + (emp_item.employe_last_name ? emp_item.employe_last_name : ''))) }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.issue_date ? emp_item.issue_date : '' }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.issue_days ? emp_item.issue_days : '' }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.due_days ? emp_item.due_days : '' }}</th>
                        <th class="py-2 text-xs lg:text-md border-r">{{ emp_item.over_due_days ? emp_item.over_due_days : '' }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
             </div>
             <!-- END: Vertical Form -->
           </div>
         </div>
       </div>
     </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'
import { stringify } from 'json5'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      kapans: [],
      packets: [],
      modal_packets: [],
      modal_kapans: [],
      processes: [],
      stones: [],
      report_details : [],
      total : [],
      loading: false,
      export_loading: false,
      modal_loading: false,
      modal_items: []

    })
    const screenData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      process_id:'0',
      packets:[],
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: '1',
      issue_pending: true,
      in_details: false,
      over_due: false,
      due: true,
      due_days: '',
      over_due_days: ''
    })
    const modalData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      process_id:'0',
      employee_id:'0',
      packets:[],
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: '1',
      issue_pending: true,
      in_details: false,
      over_due: false,
      due: true,
      data_type: '',
      over_due_days: '',
      due_days: ''
    })
    const printData = reactive({
      series_id:'0',
      kapan_id:'0',
      stone_id:'0',
      process_id:'0',
      packets:[],
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      report_type: '0',
      issue_pending: true,
      in_details: false,
      over_due: false,
      due: true,
      due_days: '',
      over_due_days: ''
    })

    const getProcessType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_process_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.processes = data.items

        })
        .catch(_error => {

        });
    }
    const getDPStoneType = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.stones = data.items

        })
        .catch(_error => {

        });
    }



    const generateReport = () => {
      pagedata.loading = true

      printData.series_id = screenData.series_id
      printData.kapan_id = screenData.kapan_id
      printData.stone_id = screenData.stone_id
      printData.process_id = screenData.process_id
      printData.packets = screenData.packets
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending
      printData.in_details = screenData.in_details
      printData.report_type = screenData.report_type
      printData.due = screenData.due
      printData.over_due = screenData.over_due
      printData.over_due_days = screenData.over_due_days
      printData.due_days = screenData.due_days

      let params = {
        series_id : screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        kapan_id : screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
        packets : screenData.packets != '0' ? screenData.packets : '',
        stone_id : screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : '',
        process_id : screenData.process_id!= '0' ? window.btoa(screenData.process_id) : '',
        issue_date : window.btoa(screenData.issue_date),
        receive_date : window.btoa(screenData.receive_date),
        report_type : window.btoa(screenData.report_type),
        issue_pending: screenData.issue_pending,
        in_details: screenData.in_details,
        due: screenData.due,
        over_due: screenData.over_due,
        over_due_days: screenData.over_due_days,
        due_days: screenData.due_days,
      }
      let promise = axios({
        url: "api/get_report_details_manager",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.report_details = data.items
          pagedata.total = data.total
          pagedata.loading  = false
        })
        .catch(_error => {

        });
    }
    const getSeries = () => {
      let params = {
        // process_id : screenData.process_id,
      }
      let promise = axios({
        url: "api/dp_rough_series",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.items

        })
        .catch(_error => {

        });
    }
    const getKapans = () => {
      let params = {
        series_id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
        // process_id : screenData.process_id,
      }
      let api_url = ''

      if(screenData.series_id != "0") {
        api_url = "api/dp_rough_kapan_by_series"
      } else {
        api_url = "api/dp_rough_kapan"
      }
      let promise = axios({
        url: api_url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          // screenData.kapan_id = "0"
          pagedata.kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getPackets = () => {

      if(screenData.series_id != "0" || screenData.kapan_id != "0") {

        let api_url = ""
        let params = ''
        if(screenData.kapan_id != "0") {
          params = {
            id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
          }
          api_url = 'api/get_packet_by_kapan'
        } else if(screenData.series_id != "0") {
          api_url = 'api/get_packet_by_series'
          params = {
            id: screenData.series_id != '0' ? window.btoa(screenData.series_id) : '',
          }
        }
        let promise = axios({
          url: api_url,
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            // screenData.packets = []
            pagedata.packets = data.items

          })
          .catch(_error => {

          });
      }
    }
    const getModalKapans = (item) => {
      let params = {
        series_id: modalData.series_id != '0' ? window.btoa(modalData.series_id) : '',
        // process_id : screenData.process_id,
      }
      let api_url = ''

      if(modalData.series_id != "0") {
        api_url = "api/dp_rough_kapan_by_series"
      } else {
        api_url = "api/dp_rough_kapan"
      }
      let promise = axios({
        url: api_url,
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if(item == "new") {

          } else {

            modalData.kapan_id = "0"
          }
          pagedata.modal_kapans = data.items

        })
        .catch(_error => {

        });
    }
    const getModalPackets = (item) => {

      if(modalData.series_id != "0" || modalData.kapan_id != "0") {

        let api_url = ""
        let params = ''
        if(modalData.kapan_id != "0") {
          params = {
            id: modalData.kapan_id != '0' ? window.btoa(modalData.kapan_id) : '',
          }
          api_url = 'api/get_packet_by_kapan'
        } else if(modalData.series_id != "0") {
          api_url = 'api/get_packet_by_series'
          params = {
            id: modalData.series_id != '0' ? window.btoa(modalData.series_id) : '',
          }
        }
        let promise = axios({
          url: api_url,
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            if(item == "new") {

            } else {

              modalData.packets = []
            }
            pagedata.modal_packets = data.items

          })
          .catch(_error => {

          });
      }
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.series_id = '0'
      screenData.kapan_id = '0'
      screenData.packets = []
      screenData.stone_id = '0'
      screenData.process_id = '0'
      screenData.report_type = '1'
      screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      screenData.receive_date = moment().format('YYYY-MM-DD')
      screenData.issue_pending = true
      screenData.in_details = true

    }
    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    function getParameters() {
      getKapans()
      getPackets()
    }
    function seriesChange() {
      getParameters()
      screenData.kapan_id = ''
      screenData.packets = []
    }
    function kapanChange() {
      getPackets()
      screenData.packets = []
    }
    function showModalDetails(item) {

      modalData.series_id = printData.series_id
      modalData.kapan_id = printData.kapan_id
      modalData.stone_id = printData.stone_id
      modalData.packets = printData.packets
      modalData.issue_date = printData.issue_date
      modalData.receive_date = printData.receive_date
      modalData.report_type = printData.report_type
      modalData.issue_pending = printData.issue_pending
      modalData.in_details = printData.in_details
      modalData.over_due = printData.over_due
      modalData.due = printData.due
      modalData.over_due_days = printData.over_due_days
      modalData.due_days = printData.due_days

      modalData.process_id = item.process_id
      if(modalData.report_type == 2) {
        modalData.employee_id = item.employee_id
      } else {
        modalData.employee_id = ''
      }
      if(modalData.in_details == true) {

        if(item.series_main_id) {
          modalData.series_id = item.series_main_id
          modalData.data_type = 'series'
        } else if(item.kapan_id) {
          modalData.kapan_id = item.kapan_id
          modalData.data_type = 'kapan'
        }
        getModalPackets('new')
      } else {
        getModalPackets('new')
        getModalKapans('new')

      }

      modalGenerateReport()
    }
    function modalGenerateReport() {
      pagedata.modal_loading  = true
      let params = {
        series_id : modalData.series_id != '0' ? window.btoa(modalData.series_id) : '',
        kapan_id : modalData.kapan_id != '0' ? window.btoa(modalData.kapan_id) : '',
        packets : modalData.packets != '0' ? modalData.packets : '',
        stone_id : modalData.stone_id != '0' ? window.btoa(modalData.stone_id) : '',
        employee_id : modalData.employee_id!= '0' ? window.btoa(modalData.employee_id) : '',
        process_id : modalData.process_id!= '0' ? window.btoa(modalData.process_id) : '',
        start_date : window.btoa(modalData.issue_date),
        end_date : window.btoa(modalData.receive_date),
        report_type : window.btoa(modalData.report_type),
        issue_pending: modalData.issue_pending,
        in_details: modalData.in_details,
        due: modalData.due,
        over_due: modalData.over_due,
        over_due_days: modalData.over_due_days,
        data_type: modalData.data_type,
        due_days: modalData.due_days,
      }
      let promise = axios({
        url: "api/get_report_lot_details_manager",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.modal_items = data.items
          pagedata.modal_loading  = false
          cash('#late-packets-pending-view').modal('show')
        })
        .catch(_error => {
          pagedata.modal_loading  = false

        });
    }
    onMounted(() => {
      checkPermission()
      getSeries()
      getDPStoneType()
      getParameters()
      getProcessType()
      // reInitOnResizeWindow()

    })



    function export_excel_temp(){

    // pagedata.export_loading = true
    let filename = "Late-Stocks.xlsx";
    let params = {
      series_id : printData.series_id != '0' ? window.btoa(printData.series_id) : '',
      kapan_id : printData.kapan_id != '0' ? window.btoa(printData.kapan_id) : '',
      packets : printData.packets != '0' ? printData.packets : '',
      stone_id : printData.stone_id != '0' ? window.btoa(printData.stone_id) : '',
      process_id : printData.process_id!= '0' ? window.btoa(printData.process_id) : '',
      manager_id : printData.manager_id!= '0' ? window.btoa(printData.manager_id) : '',
      issue_date : window.btoa(printData.issue_date),
      receive_date : window.btoa(printData.receive_date),
      report_type : window.btoa(printData.report_type),
      issue_pending: printData.issue_pending,
      in_details: printData.in_details,
      due: printData.due,
      over_due: printData.over_due,
      over_due_days: printData.over_due_days,
      due_days: printData.due_days,
    }
    let promise = axios({
        url: "/api/export_late_stocks_sub",
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
            headers: {
                Accept: "application/json"
            }
        },
        responseType: "blob",
    });

    return promise
        .then((result) => result.data)
        .then((data) => {
          pagedata.export_loading = false
            FileDownload(data, filename);
        })
        .catch((_error) => {
          pagedata.export_loading = true
            return [];
        });
    }

    function printReportDetails(){
      let report_type = 'print-late-stock-report-sub'


      let params = {
        filters: printData,
        items: {'items': pagedata.report_details }
      }
      let promise = axios({
        url: "api/add_report_details",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/' + report_type + '?id=' + window.btoa(report_id)
            window.open(url, '_blank');

        })
        .catch(_error => {

        });
      // add_report_details
    }
    function dueChanged() {
      if(screenData.due == true) {
        screenData.over_due = false
      }
    }
    function overDueChanged() {
      if(screenData.over_due == true) {
        screenData.due = false
      }
    }
    function modaldueChanged() {
      if(modalData.due == true) {
        modalData.over_due = false
      }
    }
    function modaloverDueChanged() {
      if(modalData.over_due == true) {
        modalData.due = false
      }
    }

    return {
      tableRef,
      pagedata,
      screenData,userDetails,
      printData,
      modalData,
      showModalDetails,
      getModalKapans,
      getModalPackets,
      onResetFilter,
      onPrint,
      seriesChange,
      kapanChange,
      generateReport,
      modalGenerateReport,
      printReportDetails,overDueChanged,dueChanged,modaloverDueChanged,modaldueChanged,
      export_excel_temp
    }
  }
})
</script>
