<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Certy Stock (Home Branch)</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                        <div class="text-base text-gray-600">Avail <br> Packets</div>
                        <div class="text-xl font-medium leading-8">{{ screenData.total.total_packets }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
            <div class="report-box zoom-in">
                <div class="box p-2">
                    <div class="flex items-center justify-between">
                        <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                        <div class="text-base text-gray-600">Avail <br> Weight</div>
                        <div class="text-xl font-medium leading-8">{{ screenData.total.total_weight ? $h.myFloat(screenData.total.total_weight) + ' ct' : '0.00 ct' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average  <br> Repo</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_repo ? screenData.total.average_repo : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average <br> Rate</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_rate ? screenData.total.average_rate : '0' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-2 intro-y mt-2">
                <div class="report-box zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <div class="text-base text-gray-600">Average <br> Back</div>
                            <div class="text-xl font-medium leading-8">{{ screenData.total.average_back ? screenData.total.average_back : '0 %' }}</div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Series</label>
                    <TomSelect v-model="filter_data.series_id" :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }" class="w-full" @change="seriesChange" multiple>
                        <option value="">Select Series</option>
                        <option v-for="series in screenData.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filter_data.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full" multiple @change="kapanChange">
                        <option value="">Select Kapan</option>
                        <option v-for="kapan in screenData.kapans" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Packets</label>
                    <TomSelect v-model="filter_data.packets" :options="{
                  placeholder: 'Select Packet',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packets'
                    }
                  }
                }" class="w-full" multiple>
                        <option value="">Select Packets</option>
                        <option v-for="packet in screenData.packets" :key="packet.id" :value="packet.id">{{packet.packet_code}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Packet No</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.packet_code" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Packet No" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Stock No</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Stock No" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Stone</label>
                    <TomSelect v-model="filter_data.stone" :options="{
                      placeholder: 'Select Stone',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Stone'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Stone</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Shape</label>
                    <TomSelect v-model="filter_data.shape" :options="{
                      placeholder: 'Select Shape',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Stone'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Shape</option>
                        <option v-for="(item,index) in screenData.shapes" :key="index" :value="item.id">{{ item.shape_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Color</label>
                    <TomSelect v-model="filter_data.color" :options="{
                      placeholder: 'Select Color',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Color'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Color</option>
                        <option v-for="(item,index) in screenData.colors" :key="index" :value="item.id">{{ item.shade_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select Clarity</label>
                    <TomSelect v-model="filter_data.clarity" :options="{
                      placeholder: 'Select Clarity',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Clarity'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select Clarity</option>
                        <option v-for="(item,index) in screenData.purities" :key="index" :value="item.id">{{ item.purity_name }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <label for="update-profile-form-7" class="form-label">Select LAB</label>
                    <TomSelect v-model="filter_data.lab" :options="{
                      placeholder: 'Select LAB',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'LAB'
                        }
                      }
                    }" class="w-full">
                        <option value="0">Select LAB</option>
                        <option value="IGI">IGI</option>
                        <option value="GIA">GIA</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">
                    <label for="update-profile-form-6" class="form-label">Weight Range</label>
                    <input id="tabulator-html-filter-value" v-model="filter_data.weight_range" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="0.001-0.10" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 lg:col-span-2">
                <div class="py-1">

                    <div class="mt-5 sm:mt-7 flex justify-start">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary" @click="initTabulator">
                            Go
                        </button>
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                            Reset
                        </button>
                        <div class="dropdown w-1/2 sm:w-auto sm:mt-0 sm:ml-1">
                            <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                            </button>
                            <div class="dropdown-menu w-40">
                                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                    <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX All
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
              <div class="mt-5 sm:mt-7 py-1">

                <input
                id="scan_lot"
                  class="xl:w-60 w-40"
                  type="text"
                  :class="{'form-control':true}"
                  placeholder="Stock No / Scan Barcode"
                  v-model="screenData.scan_lot_no"
                  @change="scanAndAddPack"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
              <div class="mt-7 py-1">

                <router-link :to="{name: 'add-receive-packet-lab-update'}" class="btn btn-primary py-2">Update Stocks</router-link>
              </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-5 mt-5">
            <div class="col-span-6 xl:col-span-3 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in screenData.arr_list" :key="index">
                <div class="flex justify-between">
                    <div class="font-medium text-base">{{ add }}</div>
                    <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)">
                        <Trash2Icon class="w-4 h-4 mr-1" /></button>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-between mt-2">
            <div class="text-right" v-if="screenData.arr_list.length > 0">
                <button type="button" class="btn btn-primary shadow-md mr-2 text-right" @click="assignMultiPacketsToNonCerty">Transfer Non Certy</button>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-2"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <div class="p-0 m-0 hidden">
        <button id="print_btn" type="button" class="btn btn-primary w-full ml-2" v-print="'#print-lab-barcode'">
            Print
        </button>
        <div id="print-lab-barcode" class="absolute top-0 left-0 p-0 m-0">
            <table class="p-0 m-0 w-full text-black" border="collapse">
                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.lab_code }}</th>
                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.pack_code }}</th>
                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_params.cr_weight }} Ct</th>
                </tr>
                <tr class="text-center m-0 p-0">
                    <th colspan="7" class="text-center">
                        <VueBarcode :value="print_params.barcode" :options="{ displayValue: true,height:25,width:2 }"></VueBarcode>
                    </th>
                </tr>
                <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th class="border m-auto border-black">SHP</th>
                    <th class="border m-auto border-black">CL</th>
                    <th class="border m-auto border-black">PRT</th>
                    <th class="border m-auto border-black">CT</th>
                    <th class="border m-auto border-black">PL</th>
                    <th class="border m-auto border-black">SYM</th>
                    <th class="border m-auto border-black">Fl</th>
                </tr>
                <tr style="font-size: 9px;line-height: 7px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                    <th class="border m-auto border-black">{{ print_params.shape ? print_params.shape : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.color ? print_params.color : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.purity ? print_params.purity : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.cut ? print_params.cut : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.polish ? print_params.polish : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.symmetry ? print_params.symmetry : '-' }}</th>
                    <th class="border m-auto border-black">{{ print_params.fluerocent ? print_params.fluerocent : '-' }}</th>
                </tr>
            </table>
            <p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 4px;font-weight: bold;">DK & Sons</p>
        </div>
    </div>

    <!-- BEGIN: Modal Content -->
    <div id="update-lab-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm LAB
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            You Wan't To Update LAB! <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Lab</label>
                        <input id="modal-form-1" type="text" v-model="screenData.new_lab" class="form-control" placeholder="Lab Certy" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the New LAB !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-28" :disabled="screenData.submit_loading" @click="submitRoughNewPolishWeight">
                        Update
                        <LoadingIcon v-if="screenData.submit_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import VueBarcode from '@chenfengyuan/vue-barcode';
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL,
    BASE_URL_SELLING,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import FileDownload from "js-file-download";

export default defineComponent({
    components: {
        VueBarcode
    },
    setup() {
        const router = useRouter()
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')
        const filter = reactive({
            field: 'packet_code',
            type: 'like',
            value: ''
        })
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))

        const print_params = reactive({
            barcode: '046000000000',
            color: '',
            cut: '',
            purity: '',
            polish: '',
            shape: '',
            symmetry: '',
            fluerocent: '',
            pack_code: '',
            lab_code: '',
            weight: '',
            cr_weight: ''

        })

        const screenData = reactive({
            scan_lot_no: '',
            arr_list: [],
            addornot: false,
            colors: [],
            shapes: [],
            stones: [],
            purities: [],
            total: [],
            new_lab: '',
            packet_id: '',
            submit_loading: false,
            serieses: [],
            kapans: [],
            packets: [],
        })

        const filter_data = reactive({
            "stock_no": "",
            "weight_range": "",
            "lab": "0",
            "shape": "0",
            "stone": "0",
            "color": "0",
            "clarity": "0",
            "series_id": [],
            "kapan_id": [],
            "packet_id": [],
            "packet_code": '',
        })

        const excel_filter = reactive({
          "stock_no": "",
          "weight_range": "",
            "lab": "0",
            "shape": "0",
            "stone": "0",
            "color": "0",
            "clarity": "0",
            "series_id": [],
            "kapan_id": [],
            "packet_id": [],
            "packet_code": '',
        })


        const initTabulator = () => {

          excel_filter.stock_no = filter_data.stock_no
          excel_filter.lab = filter_data.lab
          excel_filter.shape = filter_data.shape
          excel_filter.stone = filter_data.stone
          excel_filter.color = filter_data.color
          excel_filter.clarity = filter_data.clarity
          excel_filter.series_id = filter_data.series_id
          excel_filter.weight_range = filter_data.weight_range
          excel_filter.kapan_id = filter_data.kapan_id
          excel_filter.packet_id = filter_data.packet_id
          excel_filter.packet_code = filter_data.packet_code

          let params = {
                stock_no: excel_filter.stock_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                purity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                packet_code: excel_filter.packet_code,
                weight_range: excel_filter.weight_range,
                series_id : excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id : excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id : excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + `/api/lab_done_packets_list`,
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [{
                        title: 'Print',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="p-0 m-0">
                <button class="py-1 px-1">
                  <i data-feather="printer" class="w-5 h-5"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {

                                    print_params.barcode = cell.getData().lab_barcode
                                    print_params.color = cell.getData().color
                                    print_params.cut = cell.getData().cut
                                    print_params.purity = cell.getData().purity
                                    print_params.polish = cell.getData().polish
                                    print_params.shape = cell.getData().shape
                                    print_params.symmetry = cell.getData().symmetry
                                    print_params.fluerocent = cell.getData().fluerocent
                                    print_params.pack_code = cell.getData().pack_code
                                    print_params.lab_code = cell.getData().lab_code
                                    print_params.weight = cell.getData().weight
                                    print_params.cr_weight = cell.getData().cr_weight
                                    setTimeout(() => {
                                        $("#print_btn").click();
                                    }, 100);
                                })

                                return a[0]
                            }
                        }
                    },
                    {
                        title: 'Edit',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="p-0 m-0">
                <button class="py-1 px-1">
                  <i data-feather="edit" class="w-5 h-5"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {

                                    cash('#update-lab-modal').modal('show')
                                    screenData.new_lab = cell.getData().lab
                                    screenData.packet_id = cell.getData().lab_id
                                })

                                return a[0]
                            }
                        }
                    },
                    {
                        title: 'Non Certy',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == 1) {

                                const a = cash(`<div class="p-0 m-0">
                <button class="py-1 px-1">
                  <i data-feather="send" class="w-5 h-5"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {

                                    assignSinglePacketToNonCerty(cell.getData().lab_id)
                                })

                                return a[0]
                            }
                        }
                    },

                    // For HTML table
                    {
                        title: 'Stock No',
                        field: 'lab_code',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        minWidth: 100,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().status == 1) {
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>` + cell.getData().lab_code
                            } else {
                                return `<i data-feather="package" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().lab_code
                            }
                        }
                    },

                    {
                        title: 'Packet No',
                        field: 'packet_code',
                        minWidth: 150,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Lab',
                        field: 'lab',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Report No',
                        field: 'report_no',
                        minWidth: 150,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'S No',
                        field: 's_no',
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Shape',
                        field: 'shape',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Weight',
                        field: 'final_weight',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Color',
                        field: 'color',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Clarity ',
                        field: 'purity',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Discount ',
                        field: 'discount',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Cut ',
                        field: 'cut',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Polish ',
                        field: 'polish',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Sym ',
                        field: 'symmetry',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Fl ',
                        field: 'fluerocent',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Measurement ',
                        field: 'measurement',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Table ',
                        field: 'table',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'T Depth ',
                        field: 't_depth',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Comment ',
                        field: 'comment',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Repo ($) ',
                        field: 'rapo',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Repo ($)/Ct ',
                        field: 'rapo_per_ct',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Total Price ($) ',
                        field: 'total_price',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true
                    },
                    {
                        title: 'Certificate Link ',
                        field: 'certificate_link',
                        vertAlign: 'middle',
                        minWidth: 250,
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function (e, cell) {
                            newTabUrl(cell.getData().certificate_link);
                        }
                    },
                    {
                        title: 'Video Link ',
                        field: 'video_link',
                        vertAlign: 'middle',
                        minWidth: 250,
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function (e, cell) {
                            newTabUrl(cell.getData().video_link);
                        }
                    },
                    {
                        title: 'Image Link ',
                        field: 'image_link',
                        vertAlign: 'middle',
                        minWidth: 250,
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function (e, cell) {
                            newTabUrl(cell.getData().image_link);
                        }
                    },
                    {
                        title: 'BGM',
                        field: 'bgm',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Eye Clean',
                        field: 'eye_clean',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Milky',
                        field: 'milky',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Crown Height',
                        field: 'crown_height',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Crown Angle',
                        field: 'crown_angle',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Pavilion Depth',
                        field: 'pavilion_depth',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Pavilion Angle',
                        field: 'pavilion_angle',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Culet Size',
                        field: 'culet_size',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Girdle (%)',
                        field: 'girdle_percent',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Girdle Name',
                        field: 'girdle_name',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Old Date',
                        field: 'old_date',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Current Date',
                        field: 'current_date',
                        minWidth: 100,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Days',
                        field: 'days',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })

            let promise = axios({
                url: "/api/lab_done_packets_list_total",
                method: "POST",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.total = data.total;
                })
                .catch(_error => {

                });
        }

        const getSeries = () => {
          let params = {
          }
          let promise = axios({
            url: "api/dp_rough_series",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              screenData.serieses = data.items

            })
            .catch(_error => {

            });
        }
        const getKapans = () => {
          let params = {
            series_id: (filter_data.series_id.length > 0) ? filter_data.series_id : '',
          }
          let promise = axios({
            url: "api/get_packet_kapan_multiple",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              screenData.kapans = data.items

            })
            .catch(_error => {

            });
        }
        const getPackets = () => {
          if(filter_data.kapan_id.length > 0) {
            let params = {
              series_id: (filter_data.series_id.length > 0) ? filter_data.series_id : '',
              kapan_id: (filter_data.kapan_id.length > 0) ? filter_data.kapan_id : '',
            }
            let promise = axios({
              url: "api/get_packet_packets_multiple",
              method: "post",
              baseURL: BASE_URL,
              data: params,
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {

                screenData.packets = data.items

              })
              .catch(_error => {

              });
            }  else {
            pagedata.packets = []
            screenData.packets = []
          }
        }
        const getColorsDetails = () => {
            let promise = axios({
                url: "api/dp_color",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.colors = data.items;
                })
                .catch(_error => {

                });
        }
        const getStoneDetails = () => {
            let promise = axios({
                url: "api/dp_stone",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.stones = data.items;
                })
                .catch(_error => {

                });
        }
        const getShapeDetails = () => {
            let promise = axios({
                url: "api/dp_shape",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.shapes = data.items;
                })
                .catch(_error => {

                });
        }
        const getPurityDetails = () => {
            let promise = axios({
                url: "api/dp_purity",
                method: "POST",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.purities = data.items;
                })
                .catch(_error => {

                });
        }
        const total_packets = () => {
            let params = {
                kpn_id: screenData.kapan_id != '0' ? window.btoa(screenData.kapan_id) : '',
                color_id: screenData.color_id != '0' ? window.btoa(screenData.color_id) : '',
                shape_id: screenData.shape_id != '0' ? window.btoa(screenData.shape_id) : '',
                purity_id: screenData.purity_id != '0' ? window.btoa(screenData.purity_id) : '',
                stone_id: screenData.stone_id != '0' ? window.btoa(screenData.stone_id) : ''
            }
            let promise = axios({
                url: "/lab_done_packets_list_total",
                method: "POST",
                baseURL: BASE_URL_SELLING,
                data: params,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.total = data.total;
                })
                .catch(_error => {

                });
        }
        function removeItem(id) {
          console.warn(id)
          screenData.arr_list.splice(id, 1);
        }

        const submitRoughNewPolishWeight = () => {

            if (screenData.lab_code == '') {
                swal("Error!", "Lab is Empty!", "error");
                return;
            }

            screenData.submit_loading = true
            const params = {
                packet_id: window.btoa(screenData.packet_id),
                lab_code: window.btoa(screenData.new_lab),
            };
            let promise = axios({
                url: "api/update_lab_certy_packet_lab",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    let message = data.message
                    if (data.error == 'YES') {
                        swal("Error!", message, "error");
                    } else {
                        // swal("Success!", message, "success").fire({
                        //   timer: 1500
                        // })

                        swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: message,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        cash('#update-lab-modal').modal('hide')
                        initTabulator()
                        total_packets()
                    }
                    screenData.submit_loading = false

                })
                .catch(_error => {
                    screenData.submit_loading = false
                });
        }

        function seriesChange() {
          getParameters()
          filter_data.kapan_id = []
          filter_data.packets = []
        }
        function kapanChange() {
          getPackets()
          filter_data.packets = []
        }

        const getParameters= () => {
          getKapans()
          getPackets()
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }
        function newTabUrl(url) {
            window.open(url, '_blank');
        }

        // On reset filter
        const onResetFilter = () => {
            filter_data.stock_no = ""
            filter_data.lab = "0"
            filter_data.shape = "0"
            filter_data.stone = "0"
            filter_data.color = "0"
            filter_data.clarity = "0"
            filter_data.series_id = []
            filter_data.kapan_id = []
            filter_data.packet_id = []
            filter_data.packet_code = ""
        }

        const assignMultiPacketsToNonCerty = () => {

            swal({
                title: "Are you sure?",
                text: "You Want to Transfer Packets To Non Certy Stock!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const data = {
                        "packet_list": screenData.arr_list
                    };
                    let new_url = "api/update_multiple_lab_certy_packet_to_non_certy"

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });

                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == 'YES') {
                                swal("Warning!", data.message, "warning");
                                screenData.scan_lot_no = '';
                                document.querySelector('[id="scan_lot"]').focus();

                                return;
                            }

                            swal("Success!", data.message, "success");

                            document.querySelector('[id="scan_lot"]').focus();
                            initTabulator()
                            total_packets()
                            screenData.arr_list = [];
                            return

                        })
                        .catch(_error => {
                            if (data.error == "YES") {
                                this.$swal("Error!", "Something went Wrong", "error");

                                return;
                            }

                        });
                }
            })
        }
        const assignSinglePacketToNonCerty = (id) => {

            swal({
                title: "Are you sure?",
                text: "You Want to Transfer Packets To Non Certy Stock!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const data = {
                        "packet_id": window.btoa(id)
                    };
                    let new_url = "api/update_lab_certy_packet_to_non_certy"

                    let promise = axios({
                        url: new_url,
                        method: "POST",
                        data: data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });

                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == 'YES') {
                                swal("Warning!", data.message, "warning");
                                screenData.scan_lot_no = '';
                                document.querySelector('[id="scan_lot"]').focus();

                                return;
                            }

                            swal("Success!", data.message, "success");

                            document.querySelector('[id="scan_lot"]').focus();
                            screenData.arr_list = [];
                            initTabulator()
                            total_packets()
                            return

                        })
                        .catch(_error => {
                            if (data.error == "YES") {
                                this.$swal("Error!", "Something went Wrong", "error");

                                return;
                            }

                        });
                }
            })
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'certy-stock.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'certy-stock.json')
        }

        const onExportXlsx = () => {
            // const win = window
            // win.XLSX = xlsx
            // tabulator.value.download('xlsx', 'certy-stock.xlsx', {
            //     sheetName: 'Products'
            // })
            let filename = "certy_stock_list.xlsx";
            let params = {
                stock_no: excel_filter.stock_no,
                lab: excel_filter.lab != '0' ? window.btoa(excel_filter.lab) : '',
                shape: excel_filter.shape != '0' ? window.btoa(excel_filter.shape) : '',
                color: excel_filter.color != '0' ? window.btoa(excel_filter.color) : '',
                stone: excel_filter.stone != '0' ? window.btoa(excel_filter.stone) : '',
                purity: excel_filter.clarity != '0' ? window.btoa(excel_filter.clarity) : '',
                packet_code: excel_filter.packet_code,
                weight_range: excel_filter.weight_range,
                series_id : excel_filter.series_id.length != 0 ? excel_filter.series_id : '',
                kapan_id : excel_filter.kapan_id.length != 0 ? excel_filter.kapan_id : '',
                packet_id : excel_filter.packet_id.length != 0 ? excel_filter.packet_id : '',
            }
            let promise = axios({
                url: "/api/download_certy_stock",
                method: "get",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                    this.status = "success";

                    setTimeout(() => {
                        this.status = "default";
                    }, 3000);
                })
                .catch((_error) => {
                    this.status = "fail";
                    setTimeout(() => {
                        this.status = "default";
                    }, 3000);
                    return [];
                });

        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'certy-stock.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        const scanAndAddPack = () => {
            if (screenData.scan_lot_no != '') {
                if (screenData.arr_list.length <= 0) {
                    checkadd()
                } else {
                    for (let i = 0; i < screenData.arr_list.length; i++) {
                        if (screenData.arr_list[i] == screenData.scan_lot_no) {
                            // pagedata.checklist.splice(pagedata.addlist[i].id,1)
                            screenData.arr_list.splice(i, 1);
                            screenData.addornot = true
                            screenData.scan_lot_no = ''
                            break
                        } else {
                            screenData.addornot = false
                        }

                    }
                    if (screenData.addornot == false) {
                        checkadd()
                    }
                }
            }
        }

        function checkadd() {
            const data = {
                packet_code: screenData.scan_lot_no ? window.btoa(screenData.scan_lot_no) : '',
            };
            let promise = axios({
                url: "/scan_certy_lab_packet",
                method: "post",
                data: data,
                baseURL: BASE_URL_SELLING,
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {
                    console.log(data);
                    if (data.error == 'YES') {
                        swal("Warning!", data.message, "warning");
                        screenData.scan_lot_no = '';
                        document.querySelector('[id="scan_lot"]').focus();
                    } else {
                        screenData.arr_list.push(data.items.lab_code)
                        screenData.scan_lot_no = '';
                        document.querySelector('[id="scan_lot"]').focus();
                    }
                })
                .catch((_error) => {
                    screenData.scan_lot_no = '';
                    document.querySelector('[id="scan_lot"]').focus();
                });
        }

        function getPacketsandTotal() {
            initTabulator()
            total_packets()
        }

        onMounted(() => {
            initTabulator()
            getSeries()
            getKapans()
            getColorsDetails()
            getShapeDetails()
            getStoneDetails()
            getPurityDetails()
            reInitOnResizeWindow()
            document.querySelector('[id="scan_lot"]').focus();
        })

        return {
            tableRef,
            filter,
            filter_data,
            excel_filter,
            screenData,
            print_params,
            scanAndAddPack,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser,
            initTabulator,
            getPacketsandTotal,
            assignMultiPacketsToNonCerty,
            submitRoughNewPolishWeight,
            seriesChange,
            kapanChange,
            getParameters,
            getKapans,
            getPackets,
            removeItem
        }

    }
})
</script>
