<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Final Polish - Selling Details</h2>
          <div>
            <button class="btn btn-primary py-2" v-if="pagedata.report_items.active_status == 1" @click="edit_sold_stock">Edit Selling</button>
          </div>
      </div>
      <div class="intro-y box p-5 mt-5">

          <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
              <div class="preview">
                  <div class="overflow-x-auto">
                      <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                          <LoadingIcon icon="bars" class="w-8 h-8" />
                          <div class="text-center text-xs mt-2">Getting Records</div>
                      </div>
                  </div>
              </div>
          </div>

          <div v-else-if="pagedata.generate == 'gen'">
              <div class="p-5">
                  <div class="grid grid-cols-12 gap-x-2 pb-10">
                      <div class="col-span-12 sm:col-span-6">

                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                              <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.transaction_code ? pagedata.report_items.transaction_code : '-' }}</label>
                          </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Stock Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? "Non Certy Stock" : 'Certy Stock' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stone_type ? pagedata.report_items.stone_type : '-' }}</label>
                          </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Branch :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.branch_name ? pagedata.report_items.branch_name : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Party :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.party_first_name + ' ' + pagedata.report_items.party_last_name }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Broker :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.broker_first_name ? (pagedata.report_items.broker_first_name + ' ' + pagedata.report_items.broker_last_name) : "-" }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.issue_date ? pagedata.report_items.issue_date : '-' }}</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                          <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_pcs : 0 }}</label>
                        </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_wt : pagedata.report_items.total_wt }} Ct</label>
                          </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                          <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_amount : pagedata.report_items.total_amount }}</label>
                        </div>

                      </div>
                      <div class="col-span-12 sm:col-span-6">
                          <div class="mt-3">
                              <label for="update-profile-form-6" class="form-label font-bold">Total Brokerage :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.brokerage_amount : pagedata.report_items.brokerage_amount }}</label>
                          </div>

                      </div>
                  </div>


                </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
      <div class="intro-y box p-5 mt-5" v-if="pagedata.generate == 'gen'">
          <div v-if="pagedata.generate == 'gen'">

            <div class="flex flex-col sm:flex-row items-center py-2 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Final Selling Stock</h2>
                <div>
                <button class="btn btn-primary py-2 btn-sm" v-if="pagedata.report_items.active_status == 1" :disabled="pagedata.return_selected == false" @click="return_sold_stock">Return Stock</button>
              </div>
            </div>
            <div class="mt-5" v-if="pagedata.report_items.stock_type == 1">

                    <div class="border">
                        <div class="overflow-x-auto">
                            <table class="w-full text-center text-xs">
                                <thead>
                                    <tr class="bg-theme-14">
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stone</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Shape</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Color</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">MAX Rate</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Rate</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Sell Less(%)</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Amount</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                                        <th class="border-b border-r py-2 px-2 dark:border-dark-5">Status</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center" v-for="(field,index) in pagedata.report_items.selling_bunch_details" :key="index">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}">
                                      <td class="border-b border-r py-2 px-2">
                                      <div class="flex items-center m-auto">
                                        <input type="checkbox" class="form-check-input" v-model="field.selected" v-if="field.active_status == 1" @change="update_return_status">
                                        <input type="checkbox" class="form-check-input" v-model="field.selected" v-else disabled @change="update_return_status">
                                      </div>
                                    </td>
                                        <td class="border-b border-r py-2 px-2">{{ field.stone_type == "1" ? "NATURAL" : (field.stone_type == "2" ? "CVD" : (field.stone_type == "3" ? "HPHT" : (field.stone_type == "4" ? "D_CVD" : (field.stone_type == "5" ? "D_HPHT" : "" ) ) ) ) }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.shape_name }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : '-' }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.charni_name ? field.charni_name : '-' }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.size_type }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ (field.total_pcs ? field.total_pcs : 0) }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ $h.myFloat(field.total_wt) }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.stock_rate ? field.stock_rate : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.max_rate ? field.max_rate : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.sell_rate ? field.sell_rate : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.brokerage_per ? field.brokerage_per : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">
                                          <span v-if="field.active_status == 1" class="text-theme-9">Sold</span>
                                          <span v-if="field.active_status == 4" class="text-theme-6">Return Requested</span>
                                          <span v-if="field.active_status == 3" class="text-theme-6">Returned</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

            </div>
            <div v-if="pagedata.report_items.stock_type == 2">
                    <div class="border">
                        <div class="overflow-x-auto">
                            <table class="w-full text-center text-xs">
                                <thead>
                                    <tr class="bg-theme-14">
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stock No</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Certificate No</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">LAB</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stone</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Shape</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Color</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo Rate</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Exp. Back(%)</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Cr. Back(%)</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sell Back(%)</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Currency</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">CNBC Rate</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Amount</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                                        <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Status</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center" v-for="(field,index) in pagedata.report_items.selling_certy_details" :key="index">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}">
                                      <td class="border-b border-r py-2 px-2">
                                      <div class="flex items-center m-auto">
                                        <input type="checkbox" class="form-check-input" v-model="field.selected" v-if="field.active_status == 1" @change="update_return_status">
                                        <input type="checkbox" class="form-check-input" v-model="field.selected" v-else disabled @change="update_return_status">
                                      </div>
                                    </td>
                                        <td class="border-b border-r py-2 px-2">{{ field.pack_no ? field.pack_no : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.certificate_no ? field.certificate_no : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.weight ? field.weight : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.lab ? field.lab : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.stone_name ? field.stone_name : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.shape_name ? field.shape_name : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.clarity_name ? field.clarity_name : "-" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.repo_rate ? field.repo_rate : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.exp_back ? field.exp_back : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.cr_back ? field.cr_back : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.sell_back ? field.sell_back : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.currency ? field.currency : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.cnbc_rate ? field.cnbc_rate : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.broker_per ? field.broker_per : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "0" }}</td>
                                        <td class="border-b border-r py-2 px-2">
                                          <span v-if="field.active_status == 1" class="text-theme-9">Sold</span>
                                          <span v-if="field.active_status == 4" class="text-theme-6">Return Requested</span>
                                          <span v-if="field.active_status == 3" class="text-theme-6">Returned</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

            </div>
          </div>
        </div>

  </div>
  </template>

  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import {
    BASE_URL,
    BASE_URL_SELLING,BUILD_ENV,Auth_Token
} from '@/constants/config.js'
  import moment from 'moment';
  import { helper as $h} from '@/utils/helper';
  import {
      useRoute,
      useRouter
  } from 'vue-router';

  export default defineComponent({
      setup() {

          const swal = inject('$swal');
          const route = useRoute()
          const router = useRouter()
          const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
          const pagedata = reactive({

              report_items: [],
              generate: "no",
              loading: false,
              total_added_ct: 0,
              total_added_pieces: 0,
              total_packets: 0,
              total_weight: 0,
              return_selected: false
          })

          const generateReport = () => {
              const params = {
                  trans_id: route.params.trans_id
              };

              pagedata.loading = true

              let promise = axios({
                  url: "/api/get_single_selling_transaction_details",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.error == "YES") {
                          swal("Warning!", data.message, "warning");
                          pagedata.generate = 'no';
                          pagedata.loading = false

                          return;
                      }

                      pagedata.report_items = data.item;
                      pagedata.generate = 'gen';
                      pagedata.loading = false

                  })
                  .catch(_error => {

                      pagedata.generate = 'no';
                      pagedata.loading = false
                  });
          }
          function printReportDetails() {
              // let report_type = 'final-polish-stock-compatison'

              // let params = {
              //     report_type: report_type,
              //     filters: [],
              //     items: pagedata.report_items
              // }
              // let promise = axios({
              //     url: "api/add_report_details",
              //     method: "post",
              //     baseURL: BASE_URL_SELLING,
              //     data: params,
              //     headers: {
              //         Authorization: "Bearer " + AUTH_TOKEN
              //     },
              //     config: {
              //         headers: {
              //             Accept: "application/json"
              //         }
              //     }
              // });
              // return promise
              //     .then(result => result.data)
              //     .then(data => {

              //         const report_id = data.report_id

              //         let url = BASE_URL_SELLING + '/' + data.report_type + '?id=' + window.btoa(report_id)
              //         window.open(url, '_blank');

              //     })
              //     .catch(_error => {

              //     });
              // add_report_details
          }

          const return_sold_stock = () => {
            const params = {
              sell_id: route.params.trans_id,
              stock_list: (pagedata.report_items.stock_type == 1 ? pagedata.report_items.selling_bunch_details : pagedata.report_items.selling_certy_details)
            };

            let text = 'You are about to Update Sell Return!'
            let btn = 'Confirm & Return'

            swal({
                title: "Are you sure?",
                text: text,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: btn,
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let api_url = "/sell_return_loose_stock"

                    let promise = axios({
                        url: api_url,
                        method: "post",
                        data: params,
                        baseURL: BASE_URL_SELLING,
                        headers: {
                            Authorization: "Bearer " + AUTH_TOKEN
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                })
                                generateReport()
                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                })
                            }

                        })
                        .catch(_error => {
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });
        }
        const update_return_status = () => {

          pagedata.return_selected = false

          if(pagedata.report_items.stock_type == 1) {


            pagedata.report_items.selling_bunch_details.forEach(element => {
              if(element.selected == true) {
                pagedata.return_selected = true;
              }
            });

          } else if(pagedata.report_items.stock_type == 2) {
            pagedata.report_items.selling_certy_details.forEach(element => {
              if(element.selected == true) {
                pagedata.return_selected = true;
              }
            });

          }
          array.forEach(element => {

          });
        }

        const edit_sold_stock = () => {
          if(pagedata.report_items.stock_type  == 1) {
            router.push('/app/app-final-polish/sell-transfer/non-certy/' + route.params.trans_id)
          } else {
            router.push('/app/app-final-polish/sell-transfer/certy/' + route.params.trans_id)
          }
        }

          onMounted(() => {
              generateReport()
          })

          return {
              pagedata,
              printReportDetails,
              return_sold_stock,
              update_return_status,
              edit_sold_stock
          }
      }
  })
  </script>
