<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto text-t">PA LS (Summary) Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-4">
          <div class="flex items-center">
            <label for="update-profile-form-6" class="font-medium mr-5"
              >Select Series</label
            >
            <TomSelect
          v-model="pagedata.series_id"
          :options="{
            placeholder: 'Select Series',
            loadingClass: 'loading',
            plugins: {
              dropdown_header: {
                title: 'Series'
              }
            }
          }"
          class="w-60"
        >
        <option value="">Select Series</option>
        <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
        </TomSelect>
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="getSummaryReport"
            >
            Generate
            </button>

          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div>

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-20"
            @click="onResetFilter"
          >
            Reset
          </button>

          </div>
        </div>
        <div class="col-span-2 md:col-span-1" v-if="pagedata.generate == true">

          <div>

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-20"
            @click="printReportDetails"
          >
            Print
          </button>
          </div>
        </div>
      </div>
      <!-- BEgin: Process Report Start-->
      <div id="responsive-table" class="p-5" v-if="pagedata.loading">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Records</div>
          </div>
          </div>
        </div>
      </div>
      <div class="mt-10" v-else>

        <div class="p-5" v-if="pagedata.generate == true">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-4 xl:col-span-4">
                  <div>
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Sr No. :&nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ pagedata.series_details.series_code }}</label
                    >
                  </div>

                  <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                      >Series Weight : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ pagedata.series_details.series_weight ? $h.myFloat(pagedata.series_details.series_weight) : '0' }} Ct</label
                    >
                  </div>

                  <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                      >PA Weight : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label">{{ pagedata.series_details.bunch_weight ? $h.myFloat(pagedata.series_details.bunch_weight) : '0' }} Ct</label
                    >
                  </div>
                </div>
              </div>
            </div>
        <!-- BEgin: Process Report Start-->
        <div class="grid grid-cols-12 gap-x-5" v-if="pagedata.generate == true">
          <div class="overflow-x-auto col-span-12 lg:col-span-8 md:col-span-8 sm:col-span-10">
            <div class="intro-y col-span-12 overflow-auto">
              <table class="mt-2 w-full border-2">
                <thead>
                  <tr class="border-b bg-gray-400 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">Details</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">Persentage (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">OK WEIGHT ( ok વજન )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.series_details.done_weight ? $h.myFloat(pagedata.series_details.done_weight) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.series_details.done_weight ? $h.myFloat(pagedata.series_details.done_weight * 100 / pagedata.series_details.bunch_weight ) +  ' &nbsp; %' : '-' }} </th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">PA LOSS ( PA ધટ )</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.series_details.pa_lose ? $h.myFloat(pagedata.series_details.pa_lose) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.series_details.pa_lose ? $h.myFloat(pagedata.series_details.pa_lose * 100 / pagedata.series_details.bunch_weight ) +  ' &nbsp; %' : '-' }} </th>
                  </tr>
                  <tr class="border-b">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">PADIYA</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ pagedata.series_details.bunch_padiya ? parseFloat(pagedata.series_details.bunch_padiya).toFixed(2) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ pagedata.series_details.bunch_padiya ? $h.myFloat(pagedata.series_details.bunch_padiya * 100 / pagedata.series_details.bunch_weight ) +  ' &nbsp; %' : '-' }} </th>
                  </tr>
                  <tr class="border-b" v-for="(rejection, ind) in pagedata.rejections" :key="ind">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-left border-r">{{ rejection.assortment_name }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ rejection.weight ? $h.myFloat(rejection.weight) + ' Ct' : '-' }}</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap text-center">{{ rejection.weight ? $h.myFloat(rejection.weight * 100 / pagedata.series_details.bunch_weight) : '-'}} &nbsp; % </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, reactive,onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import store from '@/store'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      serieses: [],
      series_id: '',
      series_details: [],
      rejections: [],
      processes : [27],
      generate: false,
      loading: false,
      print_series: ''
    })
    const getRoughDetails = () => {
      let promise = axios({
        url: "api/get_ls_summarty_series",
        method: "post",
        baseURL: BASE_URL,
        data: { processes: pagedata.processes },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.series
          // pagedata.series_id = stringify(pagedata.serieses[0].id)
        })
        .catch(_error => {

        });
    }
    const getSummaryReport = () => {
      pagedata.loading = true
      pagedata.print_series = pagedata.series_id

      let params = {
        series_id : pagedata.series_id == '0' ? '' : window.btoa(pagedata.series_id),

      }
      let promise = axios({
        url: 'api/get_ls_pa_summarty',
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {
        pagedata.series_details = data.series
        pagedata.rejections = data.rejections
        pagedata.generate = true
        pagedata.loading = false
      })
      .catch(_error => {
        pagedata.loading = false
          pagedata.generate = false
        });
    }
    function printReportDetails(){
      let report_type = 'pa-ls-series-summary'

      let params = {
        series_id : pagedata.print_series == '0' ? '' : window.btoa(pagedata.print_series),
        print_report_name: report_type,

      }
      let promise = axios({
        url: "api/set_ls_pa_summarty",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id
          const report_name = data.report_name

          let url = BASE_URL + '/' + report_name + '?id=' + window.btoa(report_id)
          window.open(url, '_blank');

        })
        .catch(_error => {

        });
      // add_report_details
    }
    const onResetFilter = () => {
      pagedata.series_id = ''
    }
    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      checkPermission()
      getRoughDetails()

    })

    return {
      pagedata,
      getSummaryReport,
      printReportDetails,
      onResetFilter
    }
  }
})
</script>
