<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Packet Issue For Lab</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="scan_lot_no" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="screenData.scan_lot_no"
                    :class="{'form-control':true,'loading':screenData.scanload}"

                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Scanned Packet Details</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <label for="scan_lot_no" class="form-label mt-2 mr-2"
                    >Select Party</label
                  >
              <TomSelect
                v-model="screenData.selected_party"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full mr-5"
                style="width:200px"
              >
              <option value="">Select Party</option>
              <option v-for="party in screenData.party_ar" :key="party.id" :value="party.id">{{party.company_name}}</option>
              </TomSelect>
          <button
           class="btn btn-primary shadow-md mr-2" @click="issueLabSubmit" :disabled="screenData.return_progress">
           Issue Packets
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress"/>
          </button>
      </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">

              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                </div>

              </div>
            <div class="col-span-4 xl:col-span-4">
               <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Pcs Weight. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}}  </label>
                </div>
            </div>
            <div class="col-span-4 xl:col-span-4 text-right">
               <div>
                <button
           class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect" :disabled="screenData.delet_row">
           Remove Selected Row
          </button>
                </div>
            </div>
              <div class="col-span-12 ">
                <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 500px"
              :columnDefs="columnDefs.value"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              :rowMultiSelectWithClick="true"
              animateRows="true"
              @grid-ready="onGridReady"
              @selection-changed="onGridRowSelect"
              :overlayNoRowsTemplate="overlayNoRowsTemplate"
            >
            </ag-grid-vue>
              </div>

            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted,inject } from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { stringify } from 'json5';

export default defineComponent({
  components: {
   AgGridVue,
   ShapeRenderer
 },
  setup() {
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      scanload: false,
      party_ar:[],
      color_arr:[],
      purity_arr:[],
      shape_arr:[],

      return_progress:false,
      scan_lot_no:'',
      lab_type :'',
      total_scan_pack_wt:0,
      selected_party:'',
      total_scan_pack:0

    })

    const onGridReady = (params) => {
     gridApi.value = params.api;
    };

   const rowData = reactive({
    value:[]
   });

   const overlayNoRowsTemplate = 'Scan packets for Lab Issue!'

   const columnDefs = reactive({
     value: [
          { headerName: "#",field: 'sr_no',maxWidth: 60, },
          { headerName: "Packet No",field: 'lot_no',minWidth: 140 },
          { headerName: "Stock No",field: 'ref_no',editable: true ,minWidth: 140,
            valueSetter: (params) => {
              if (params.newValue == '') {
                params.data.ref_no = ''
                swal('Warning!',"Please Fill The Stock No!","warning")
                // alert('Value cannot be empty');
                return false;
              } else {
                // var valueChanged = params.data.ref_no !== params.newValue;
                // if (valueChanged) {
                  let length = rowData.value.length

                  for(let i = 0;i < length;i++) {
                    if(rowData.value[i]['ref_no'] == params.newValue) {
                      swal('Warning!',"Stock No is Already Exist At " + (i + 1) +" Row!","warning")
                      return false;
                    }
                  }
                  params.data.ref_no = params.newValue;
                // }
                setTimeout(focusScan,50);

                // setTimeout(focusScan,50);
                // return valueChanged;

              }
            },
          },
          { headerName: "Weight (Ct)",field: 'cr_wt',minWidth: 130 },
          { headerName: "Color",field: 'color_name',editable: true ,minWidth: 140,
            valueSetter: (params) => {
              if (params.newValue == '') {
                // params.data.color_name = ''
                swal('Warning!',"Color Can't be Empty!","warning")
                return false;
              } else {

                  if(screenData.color_arr.includes(params.newValue)) {

                    params.data.color_name = params.newValue;
                    return;
                  }
                  swal('Warning!',"Invalid Color!","warning")
                // setTimeout(focusScan,50);
                // return valueChanged;

              }
            },
          },
          { headerName: "Purity",field: 'purity_name',editable: true ,minWidth: 140,
            valueSetter: (params) => {
              if (params.newValue == '') {
                // params.data.purity_name = ''
                swal('Warning!',"Purity Can't be Empty!","warning")
                return false;
              } else {

                  if(screenData.purity_arr.includes(params.newValue)) {

                    params.data.purity_name = params.newValue;
                    return;
                  }
                  swal('Warning!',"Invalid Purity!","warning")
                // setTimeout(focusScan,50);
                // return valueChanged;

              }
            },
          },
          { headerName: "Shape",field: 'shape_name',editable: true ,minWidth: 140,
            valueSetter: (params) => {
              if (params.newValue == '') {
                // params.data.shape_name = ''
                swal('Warning!',"Shape Can't be Empty!","warning")
                return false;
              } else {

                  if(screenData.shape_arr.includes(params.newValue)) {

                    params.data.shape_name = params.newValue;
                    return;
                  }
                  swal('Warning!',"Invalid Shape!","warning")
                setTimeout(focusScan,50);
                // return valueChanged;

              }
            },
          },
          { headerName: "Stone",field: 'stone',editable: true ,minWidth: 100,
            valueSetter: (params) => {
              if (params.newValue == '') {
                // params.data.stone = ''
                // swal('Warning!',"Please Fill The Stone!","warning")
                swal('Warning!',"Stone Can't be Empty!","warning")
                // alert('Value cannot be empty');
                return false;
              } else {
                // var valueChanged = params.data.stone !== params.newValue;
                // if (valueChanged) {
                  let length = rowData.value.length

                  if(!["NATURAL","CVD","HPHT","D_CVD","D_HPHT"].includes((params.newValue).toUpperCase())) {
                    swal('Warning!',"Enter Valid Stone Type!","warning")
                    return false;
                  }
                  if((params.data.stone == "CVD" || params.data.stone == "HPHT") && (params.newValue == "HPHT" || params.newValue == "CVD")) {

                    params.data.stone = params.newValue;
                  } else if((params.data.stone == "D_CVD" || params.data.stone == "D_HPHT") && (params.newValue == "D_HPHT" || params.newValue == "D_CVD")) {

                    params.data.stone = params.newValue;
                  } else {
                    swal('Warning!',"Invalid Stone Editable!","warning")

                  }
                // }

                setTimeout(focusScan,50);
                // return valueChanged;

              }
            },
          },
          // { headerName: "Stone",field: 'stone',minWidth: 160,editable: true },
          { headerName: "Lab",field: 'lab',minWidth: 130 },
     ],
   });


   const defaultColDef = {
     sortable: true,
     filter: true,
     flex: 1
   };
   const get_shape_details = () => {
    let params = {

}
let promise = axios({
  url: "api/dp_shape",
  method: "post",
  baseURL: BASE_URL,
  data: params,
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  config: { headers: { Accept: "application/json" } }
});
return promise
  .then(result => result.data)
  .then(data => {

    data.items.forEach(element => {
      screenData.shape_arr.push(element.shape_name)
    });

    // screenData.shape_arr = data.items

  })
  .catch(_error => {
    screenData.shape_arr = []

  });
}
const get_purity_details = () => {
  let params = {

  }
  let promise = axios({
    url: "api/dp_purity",
    method: "post",
    baseURL: BASE_URL,
    data: params,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    config: { headers: { Accept: "application/json" } }
  });
  return promise
  .then(result => result.data)

  .then(data => {
    data.items.forEach(element => {
      screenData.purity_arr.push(element.purity_name)
    });

  })
  .catch(_error => {
    screenData.purity_arr = []

  });
   }
   const get_color_details = () => {
    let params = {

}
let promise = axios({
  url: "api/dp_color",
  method: "post",
  baseURL: BASE_URL,
  data: params,
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  config: { headers: { Accept: "application/json" } }
});
return promise
  .then(result => result.data)
  .then(data => {

    data.items.forEach(element => {
      screenData.color_arr.push(element.shade_name)
    });

  })
  .catch(_error => {
    screenData.color_arr = []

  });
   }

   const getParty = () => {
      let params = {

      }
      let promise = axios({
        url: "api/dp_lab_process_party",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          screenData.party_ar = data.items

        })
        .catch(_error => {

        });
    }

    //Edit user
    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
      if(codelength > 4){
        screenData.scanload = true;
        //Check already in array
        var i = rowData.value.length;
              while(i--){
              if(rowData.value[i]['barcode'] == screenData.scan_lot_no ){
                screenData.scan_lot_no = "";
                swal("Warning!", "Already in list, Scan other packet!", "warning");
                screenData.scanload = false;
                return;
              }
        }

        //get lot details
        const data = {
        barcode: screenData.scan_lot_no,
        lab_type: screenData.lab_type,
        };
        let url_post = '';
          if(screenData.scan_lot_no != ''){
          url_post = "api/get_lab_scanned_packet_details"
        }else{
          url_post = "api/get_lab_scanned_packet_details"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
            console.log(data);
            if(data.packet_details == 'No'){
                swal("Warning!", data.error_msg, "warning");
                screenData.scanload = false;
                document.querySelector('[id="scan_lot_no"]').focus();
            }else if(data.packet_details == 'process'){
                swal("Warning!", "Scanned barcode is already in process", "warning");
                screenData.scanload = false;
                document.querySelector('[id="scan_lot_no"]').focus();
            }else {
              let pack_details = data.packet_details;

              // if(screenData.lab_type == '') {

              //   screenData.lab_type = pack_details.lab
              // }
              // console.log('Hii data');
              // let party_length = screenData.party_ar.length

              // for(let i = 0; i< party_length; i++) {
              //   if(screenData.party_ar[i].first_name == screenData.lab_type) {

              //     screenData.selected_party = stringify(screenData.party_ar[i].id)
              //     console.log(screenData.selected_party)
              //     break;
              //   }
              // }
              screenData.total_scan_pack = screenData.total_scan_pack + 1;
              console.log('Push data');
              let stone_name = ''

              if(pack_details.stone == 1) {
                stone_name = 'NATURAL';
              } else if(pack_details.stone == 2) {
                stone_name = 'CVD';
              } else if(pack_details.stone == 3) {
                stone_name = 'HPHT';
              } else if(pack_details.stone == 4) {
                stone_name = 'D_CVD';
              } else if(pack_details.stone == 5) {
                stone_name = 'D_HPHT';
              }
                rowData.value.push(
                {
                  "sr_no":screenData.total_scan_pack,
                  "lot_no":pack_details.packet_code,
                  "pack_no":pack_details.packet_code,
                  "weight": pack_details.f_pw,
                  "cr_wt": pack_details.f_pw,
                  "lab": pack_details.lab,
                  "shape_name": pack_details.shape_name,
                  "barcode": pack_details.barcode_no,
                  "packet_id": pack_details.packet_id,
                  "stone": stone_name,
                  "color_name" : pack_details.color_name,
                  "purity_name" : pack_details.purity_name,
                  "ref_no" : "",
                  "lab_barcode" : pack_details.lab_barcode
                });

                screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.f_pw);

                gridApi.value.setRowData(rowData.value);
                screenData.scan_lot_no = "";
                screenData.scanload = false;
                if(rowData.value.length == 1){
                  setTimeout(focusLstRow,50);
                }else{
                  focusLstRow();
                }
                console.log(rowData.value);
            }
          })
          .catch((_error) => {
            screenData.scanload = false;
            return [];
          });
      }
    }

    const focusLstRow = () => {
      gridApi.value.ensureIndexVisible(rowData.value.length - 1);

      if(rowData.value[rowData.value.length - 1]['ref_no'] == '' || rowData.value[rowData.value.length - 1]['ref_no'] == null) {

        gridApi.value.setFocusedCell(rowData.value.length - 1,'ref_no');
      } else {
        setTimeout(focusScan,50);
      }
      gridApi.value.startEditingCell(
        {
          rowIndex: rowData.value.length - 1,
          colKey: 'ref_no',
        }
        );
    }

    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if(gridApi.value.getSelectedRows().length == 0){
        screenData.delet_row = true;
      }else{
        screenData.delet_row = false;
      }
    }

    const issueLabSubmit = () => {

      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }
      if(screenData.selected_party == ''){
        swal("Error!", "Kindly select party to Issue", "error");
        return;
      }

      let length = rowData.value.length

      let number_row = ''
      for(let i = 0;i < length;i++) {
        if(rowData.value[i]['ref_no'] == '' || rowData.value[i]['ref_no'] == null) {
          if(number_row == '') {
            number_row = rowData.value[i]['lot_no']
          } else {
            number_row = number_row + ', ' + rowData.value[i]['lot_no']
          }
        }
      }

      console.warn(number_row)
      if(number_row != '') {
        swal("Error!", number_row + " Reference Number are empty", "error");
        return;
      }


      const data = {
        packet_details: rowData.value,
        process_id: 40,
        manager_id: '',
        selected_party : screenData.selected_party
      };

      console.log(data);

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to issue packets for Lab!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Issue",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/issue_packets_for_lab",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                swal("Success!", "Item Issued to Lab.", "success");
                //screenData.return_progress = false;
                // router.go();
                rowData.value = []
                screenData.lab_type = ''
                screenData.total_scan_pack_wt = 0
                screenData.total_scan_pack = 0

              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;

        }
      });
    }

    const removeGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows());
      const sel = gridApi.value.getSelectedRows();
      console.log(sel);

      let remove_rows = [];
      var i = sel.length;
      while(i--){
        remove_rows.push(sel[i]['barcode']);
      }
      gridApi.value.applyTransaction({remove: sel});
      console.log('Removeing row');
      console.log(remove_rows);
      var j = rowData.value.length;
        while(j--){
              if(remove_rows.includes(rowData.value[j]['barcode'])){
                rowData.value.splice(j,1);
              }
        }

      setTimeout(reCaclulateTotal,50);
    }

    const focusScan = () => {

document.querySelector('[id="scan_lot_no"]').focus();
}

    onMounted(() => {

    document.querySelector('[id="scan_lot_no"]').focus();
     rowData.value = []
     getParty()
     get_shape_details()
    get_purity_details()
    get_color_details()
    })


    return {
      filter,
      screenData,
      columnDefs,
      rowData,
      defaultColDef,
      overlayNoRowsTemplate,
      scanAndAddPack,
      onGridReady,
      issueLabSubmit,
      getRowId,
      removeGridRowSelect,
      onGridRowSelect,
      deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }

  }
})
</script>

