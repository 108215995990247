<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Manager Stock Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-6 xl:col-span-3">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Manager</label
              >
              <TomSelect
                v-model="screenData.manager_id"
                :options="{
                  placeholder: 'Select Managers',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
                multiple
              >
              <option value="">Select Managers</option>
              <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.id">{{manager.first_name + ' ' + manager.last_name}}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-1"
              >Select Start Date</label
            >
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                placeholder=""
                v-model="screenData.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-4 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Report Type</label
              >
              <TomSelect
                v-model="screenData.report_type"
                :options="{
                  placeholder: 'Select Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Summary</option>
              <option value="1">In Details</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
        <div class="mt-7 flex py-1">

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
            @click="onResetFilter"
          >
            Reset
          </button>
        </div>
      </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex py-1">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.report_type">
          <div class="mt-7 flex py-1">

          <button
            id="tabulator-html-filter-go"
            type="button"
            class="btn btn-primary w-full ml-2"
            @click="printReportDetails"
          >
          Print
          </button>
          </div>
        </div>

      </div>
      <div id="generate-pdf">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="mt-3 w-full border-2" v-if="pagedata.report_type == 1">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Manager</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Role</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="10">Lot Stock Details</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="4">Series Stock Details</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Old Pkt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Old Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Pkt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Pkt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Failed Pkt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Failed Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Available Pkt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Available Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Old Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Wt</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Available Wt</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.old_packets ? pagedata.total.old_packets : '0' }}</th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.old_weight ? $h.myFloat(pagedata.total.old_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.issue_packets ? pagedata.total.issue_packets : '0' }}</th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.receive_packets ? pagedata.total.receive_packets : '0' }}</th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.receive_weight ? $h.myFloat(pagedata.total.receive_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.failed_packets ? pagedata.total.failed_packets : '0' }}</th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.failed_weight ? $h.myFloat(pagedata.total.failed_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.available_packets ? pagedata.total.available_packets : '0' }}</th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.available_weight ? $h.myFloat(pagedata.total.available_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.series_old_weight ? $h.myFloat(pagedata.total.series_old_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.series_issue_weight ? $h.myFloat(pagedata.total.series_issue_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.series_receive_weight ? $h.myFloat(pagedata.total.series_receive_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.available_series_weight ? $h.myFloat(pagedata.total.available_series_weight) : '0' }} Ct </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in pagedata.report_details" :key="index">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.company_name ? item.company_name : (item.first_name + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.role_id ? (item.role_id == 21 ? 'Manual Writer (Lotiy)' : item.role_name) : 'Polish Party' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.old_packets ? item.old_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.old_weight ? $h.myFloat(item.old_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.issue_packets ? item.issue_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.receive_packets ? item.receive_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.receive_weight ? $h.myFloat(item.receive_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.failed_packets ? item.failed_packets : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.failed_weight ? $h.myFloat(item.failed_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (item.available_packets ? item.available_packets : 0) + (item.available_process_packets ? item.available_process_packets : 0) }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat((item.available_weight ? $h.myFloat(item.available_weight) : 0) + (item.available_process_weight ? $h.myFloat(item.available_process_weight) : 0)) }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.series_old_weight ? $h.myFloat(item.series_old_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.series_issue_weight ? $h.myFloat(item.series_issue_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.series_receive_weight ? $h.myFloat(item.series_receive_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.available_series_weight ? $h.myFloat(item.available_series_weight) : '0' }} Ct </td>
                    </tr>

                  </tbody>
                </table>
                <table class="mt-3 w-full border-2" v-else>
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Manager</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Role</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Old Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Failed Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Available Weight</th>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.old_weight ? $h.myFloat(pagedata.total.old_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.receive_weight ? $h.myFloat(pagedata.total.receive_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.failed_weight ? $h.myFloat(pagedata.total.failed_weight) : '0' }} Ct </th>
                      <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.available_weight ? $h.myFloat(pagedata.total.available_weight) : '0' }} Ct </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in pagedata.report_details" :key="index">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.company_name ? item.company_name : (item.first_name + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.role_id ? (item.role_id == 21 ? 'Manual Writer (Lotiy)' : item.role_name) : 'Polish Party' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(item.old_weight ? $h.myFloat(item.old_weight) : 0) + (item.series_old_weight ? $h.myFloat(item.series_old_weight) : 0) }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(item.issue_weight ? $h.myFloat(item.issue_weight) : 0) + (item.series_issue_weight ? $h.myFloat(item.series_issue_weight) : 0) }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(item.receive_weight ? $h.myFloat(item.receive_weight) : 0) + (item.series_receive_weight ? $h.myFloat(item.series_receive_weight) : 0) }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.failed_weight ? $h.myFloat(item.failed_weight) : '0' }} Ct </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat((item.available_weight ? $h.myFloat(item.available_weight) : 0) + (item.available_process_weight ? $h.myFloat(item.available_process_weight) : 0) + (item.available_series_weight ? $h.myFloat(item.available_series_weight) : 0)) }} Ct </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed,reactive,onMounted} from 'vue'
import feather from 'feather-icons'
import { useRouter } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      managers: [],
      report_details : [],
      total : [],
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      manager_id: '',
      report_type: '1',
      loading: false

    })
    const screenData = reactive({
      manager_id:'',
      report_type:'1',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),

    })

    const generateReport = () => {


      pagedata.manager_id = screenData.manager_id,
      pagedata.issue_date = screenData.issue_date,
      pagedata.receive_date = screenData.receive_date,
      pagedata.report_type = screenData.report_type,

      pagedata.loading  = true

      let params = {
          manager_id : screenData.manager_id,
          issue_date : screenData.issue_date,
          receive_date : screenData.receive_date,
          report_type : screenData.report_type,
      }
          let promise = axios({
            url: "api/get_old_stock_details",
            method: "post",
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              pagedata.report_details = data.items
              pagedata.total = data.total
              pagedata.loading  = false
            })
            .catch(_error => {
              pagedata.loading  = false
            });
    }
    const getManagers = () => {
      let params = {
      }
      let promise = axios({
        url: "api/dp_account_operator",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.managers = data.items

        })
        .catch(_error => {

        });
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // On reset filter
    const onResetFilter = () => {

      screenData.manager_id = ''
      screenData.report_type = '1'
      screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      screenData.receive_date =moment().format('YYYY-MM-DD')
    }

    function printReportDetails(){
      let report_type = 'get_old_stock_details'

      let params = {
          report_type: report_type,
          filters: {
              manager_id: pagedata.manager_id,
              report_type: pagedata.report_type,
              issue_date: pagedata.issue_date,
              receive_date: pagedata.receive_date,
          },
          items: {'items': pagedata.report_details,'total': pagedata.total }
      }
      let promise = axios({
          url: "api/add_report_details",
          method: "post",
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
      });
      return promise
          .then(result => result.data)
          .then(data => {

          const report_id = data.report_id

          let url = BASE_URL + '/get-old-stock-details?id=' + window.btoa(report_id)
          window.open(url, '_blank');

          })
          .catch(_error => {

          });
      // add_report_details
      }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    onMounted(() => {
      checkPermission()
      getManagers()
      reInitOnResizeWindow()
      // generateReport()

    })

    return {
      pagedata,
      screenData,
      userDetails,
      onResetFilter,
      generateReport,
      printReportDetails
    }
  }
})
</script>
