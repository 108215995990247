<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Tender</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-if='auction_status == "no" && status == 1'
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="startAuction">
           Start Auction
          </button>
          <span
          v-else-if='auction_status == "yes" && status == 1'
          type="button"
           class="btn btn-danger shadow-md mr-2">
          Auction is Live!
          </span>

          <router-link
          v-if='(auction_status == "yes") || (auction_status == "no" && status == 2 && (winner_an == "" || winner_an == null))'
          :to="{ name: 'selectfinalbuyer' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           Select Winner
          </router-link>

          <router-link
          v-else-if='auction_status == "no" && status == 2 && winner_an == 1'
          :to="{ name: 'viewwinners' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           View Winners
          </router-link>
          <router-link
          
          :to="{ name: 'listinvitedbuyers' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           Invited Buyers
          </router-link>
          
        
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Tender Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ tender_name }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Email : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_email }}</label
                  >
                  
                </div>
                <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Mobile No : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_number }}</label
                  >
                  
                  
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Site Address : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ address }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Start Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ start_date_display }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >End Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ end_date_display }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid Start Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_start_date_display }}</label
                  >
                  
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid End Date & Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_end_date_display }}</label
                  >
                  
                </div>
              </div>
            </div>
            
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Details</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
               <button
          
          type="button"
           class="btn btn-primary btn-sm shadow-md mr-2" @click="printAllGroup">
           Print Groups Barcode
          </button>

            </div>
        </div>
        <div class="border">
        <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;">
             
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Sr. No</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Group</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Cut</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Details</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Stone</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Carats</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
            <p>Expected Price($)/Carat</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
            <p>Total Price($)</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 ">
               <p>Barcode</p>
              </div>
        </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.lot_no" type="text"
                    class="form-control" placeholder="Sr. no" readonly>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.group" type="text"
                    class="form-control" placeholder="Sr. no" readonly>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <input v-model="field.cut" type="text"
                    class="form-control" placeholder="Cut" readonly>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.details" type="text"
                    class="form-control" placeholder="Details" readonly>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
              <input v-model="field.piece" type="text"
                    class="form-control" placeholder="Piece" readonly>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
              <input v-model="field.carats" type="text"
                    class="form-control" placeholder="Carats" readonly>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
             <input v-model="field.expect_price" type="text"
                    class="form-control" placeholder="Expected Price" readonly>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 pt-2">
              <span class="pl-1 pt-3">{{ (field.expect_price * field.carats).toFixed(2) }}</span>
              </div>
              <div class="col-span-2 xl:col-span-1 pt-3 pl-3 text-center">
              <button type="button" class="text-theme-6 flex items-center" >
                <PrinterIcon class="w-4 h-4 mr-1"/> Print
              </button>
              </div>
              <div class="col-span-9 md:col-span-9 xl:col-span-9 pt-2" v-if ="(field.attachment == 'yes')">
                <input v-model="field.attachment_link" type="text" readonly
                :disabled ="(auction_status == 'yes' || status == 2)"
                    class="form-control" placeholder="File Link" >
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total weight(Carat) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_rough_carat.toFixed(2)}}</p>
              </div>

          </div>
           <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Expected/Carat ($) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_expected_amount.toFixed(2)}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Expected Price ($) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_expected_amount_overall.toFixed(2)}}</p>
              </div>

          </div>
       
        </div>
        <div>
        <div class="p-5">
            <div class="flex justify-end mt-4">
           
              <a href="" class="text-theme-6 flex items-center" v-if ="tender_update_id != ''">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Tender
              </a>
            </div>
        </div>
          </div>
          
        </div>
        
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    tender_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      total_weight:0,
      total_expected_price:0,
      total_rough_carat:0,
      total_expected_amount:0,
      total_expected_amount_overall:0,
      tender_update_id : '',
      tender_name : '',
      contact_number: '',
      contact_email: '',
      address: '',
      start_date: '',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      start_date_display: '',
      end_date_display: '',
      bid_start_date_display: '',
      bid_end_date_display: '',
      total_items:1,
      fields: [],
      auction_status : 'no',
      status: 'default',
      winner_an:'',
   
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  
  this.getTenderDetails(this.tender_id);
  },

  methods: {
    printAllGroup(){
      let url =  'https://bluemoonerp.com/label_print/print-all-group-label/' + window.atob(this.tender_id);
      window.open(url, '_blank');
    },
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id
      };
      let promise = axios({
        url: "api/get_tender_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.tender_detail);
          let tender_detail = data.tender_detail;
          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.rough_details_arr;
          this.contact_number= tender_detail.contact_number;
          this.contact_email= tender_detail.contact_email;
          this.address = tender_detail.address;
          this.start_date = tender_detail.start_time;
          this.status = tender_detail.status;
          this.end_date = tender_detail.end_time;
          this.bid_start_date = tender_detail.bid_start_time;
          this.bid_end_date = tender_detail.bid_end_time;
          this.auction_status = tender_detail.live_for_bid;
          this.winner_an = tender_detail.winner_an;
          
          this.start_date_display = tender_detail.start_time_display;
          this.end_date_display = tender_detail.end_time_display;
          this.bid_start_date_display = tender_detail.bid_start_time_display;
          this.bid_end_date_display = tender_detail.bid_end_time_display;

            _this.calculateTotal();
        })
        .catch(_error => {
          
        });
    },
    printGroupBarcode(){

    },
    startAuction(){
       this.$swal({
        title: "Are you sure?",
        text: "You are about to start Auction for Tender!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Make it Live!",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            td: this.tender_id
          };
          let promise = axios({
            url: "api/update_tender_live",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "Tender is Live", "success");
          this.$router.push("/app/tender/list");
        }
      });
    },
    calculateTotal(){
      this.total_rough_carat = 0;
      this.total_expected_amount = 0;
      this.total_expected_amount_overall = 0;
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                  this.total_rough_carat = this.total_rough_carat + parseFloat(this.fields[i]['carats']);
              }

              if(this.fields[i]['expect_price'] != '' && !isNaN(this.fields[i]['expect_price'])){
                  this.total_expected_amount = this.total_expected_amount + parseFloat(this.fields[i]['expect_price']);
              }

              if(this.fields[i]['expect_price'] != '' && !isNaN(this.fields[i]['expect_price']) && this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats'])){
                
                  this.total_expected_amount_overall = this.total_expected_amount_overall + (parseFloat(this.fields[i]['carats']) * parseFloat(this.fields[i]['expect_price']));
              }
            }
    }
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>