<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">My Profile</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse"
      >
        <div class="intro-y box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img
                alt="User"
                class="rounded-full"
                :src="require(`@/assets/images/user.png`)"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                {{ userForm.fistname }} {{ userForm.lastname }}
                <!-- <span class="font-medium mt-3" v-if="currentUser.user_type == 10 || currentUser.user_type == 11 ||currentUser.user_type == 12 "> ({{ currentUser.more_details.emp_code }})</span> -->
              </div>
              <!-- <div class="text-gray-600">{{ currentUser.more_details.role_name }}</div> -->
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <a
              class="flex items-center text-theme-1 dark:text-theme-10 font-medium"
              href=""
            >
              <ActivityIcon class="w-4 h-4 mr-2" /> Personal Information
            </a>

            <a class="flex items-center mt-5" href="">
              <LockIcon class="w-4 h-4 mr-2" /> Change Password
            </a>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Personal Information</h2>
          </div>
          <div class="p-5">
            <div class="">
              <label for="change-password-form-2" class="form-label"
                >First Name</label
              >
              <input
                id="company_name"
                type="text"
                v-model="userForm.fistname"
                readonly
                class="form-control"
                placeholder="Input text"
              />
            </div>
            <div class="mt-3">
              <label for="change-password-form-2" class="form-label"
                >Last Name</label
              >
              <input
                id="last_name"
                type="text"
                v-model="userForm.lastname"
                readonly
                class="form-control"
                placeholder="Input text"
              />
            </div>
            <div class="mt-3">
              <label for="change-password-form-3" class="form-label"
                >Company Email (Username)</label
              >
              <input
                id="company_email"
                type="text"
                readonly
                v-model="userForm.email"
                class="form-control"
                placeholder="Input text"
              />
            </div>
            <div class="mt-3">
              <label for="change-password-form-3" class="form-label"
                >Phone No.</label
              >
              <input
                id="company_mobile"
                type="text"
                readonly
                v-model="userForm.mobileno"
                class="form-control"
                placeholder="Input text"
              />
            </div>

            <p class="text-theme-6 mt-5">
              In case information is incorrect kindly contact Admin for updating
              the information.
            </p>
          </div>
        </div>
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Change Password</h2>
          </div>
          <div class="p-5">
            <div class="">
              <label for="change-password-form-2" class="form-label"
                >New Password</label
              >
              <input
                id="change-password-form-2"
                type="password"
                class="form-control"
                minlength="6"
                maxlength="20"
                v-model="newpassword"
                placeholder="New Password"
              />
            </div>
            <div class="mt-3">
              <label for="change-password-form-3" class="form-label"
                >Confirm New Password</label
              >
              <input
                id="change-password-form-3"
                type="password"
                minlength="6"
                maxlength="20"
                v-model="confirm_new_password"
                class="form-control"
                placeholder="Confirm New Password"
              />
            </div>
            <button
              type="button"
              class="btn btn-primary mt-4"
              @click="onPasswordChangeSubmit"
            >
              Change Password
            </button>
          </div>
        </div>
        <!-- END: Change Password -->

        <div
          id="success-notification-content"
          class="toastify-content hidden flex"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Password Updated Successfuly!</div>
          </div>
        </div>

        <div
          id="failed-notification-content"
          class="toastify-content hidden flex"
        >
          <InfoIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
              Please Check Password & Confirm Password (min 6 char required) and
              try again
            </div>
          </div>
        </div>

        <div id="failed-server-content" class="toastify-content hidden flex">
          <InfoIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Server Error!</div>
            <div class="text-gray-600 mt-1">
              Something went wrong! Please Try again
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'

export default {
  data() {
    return {
      flag: false,
      userid: '',
      errors: [],
      name: '',
      age: 0,
      userForm: {
        fistname: '',
        lastname: '',
        whatsapp_no: '',
        middlename: '',
        company_name: '',
        mobileno: '',
        email: ''
      },
      newpassword: '',
      confirm_new_password: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      userPolicies: 'userPolicies'
    })
  },
  created() {
    this.userForm.userid = this.currentUser.id
    this.userForm.fistname = this.currentUser.first_name
    if (this.currentUser.last_name != null) {
      this.userForm.lastname = this.currentUser.last_name
    }
    this.userForm.email = this.currentUser.email
    this.userForm.mobileno = this.currentUser.mobile_no
  },
  mounted() {},
  methods: {
    onPasswordChangeSubmit() {
      this.errors = []
      console.log('here')
      if (this.newpassword == '' || this.newpassword.length < 6) {
        this.errors.push('Please Enter New Password.')

        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return
      } else {
        if (this.newpassword != this.confirm_new_password) {
          this.errors.push('Please Enter Correct Confirm Password.')

          Toastify({
            node: cash('#failed-notification-content')
              .clone()
              .removeClass('hidden')[0],
            duration: 5000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
          return
        }
      }
      if (!this.errors.length) {
        const data = {
          new_password: window.btoa(this.newpassword)
        }

        this.$swal({
          title: 'Are you sure?',
          text: 'You are about to Change Password!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm & Change Password',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancel',

          preConfirm: (login) => {
            let promise = axios({
              url: 'api/change_password',
              method: 'post',
              data: data,
              baseURL: BASE_URL,
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: { headers: { Accept: 'application/json' } }
            })
            return promise
              .then((result) => result.data.message)
              .then((data) => {
                this.newpassword = ''
                this.confirm_new_password = ''
                Toastify({
                  node: cash('#success-notification-content')
                    .clone()
                    .removeClass('hidden')[0],
                  duration: 5000,
                  newWindow: true,
                  close: true,
                  gravity: 'top',
                  position: 'right',
                  stopOnFocus: true
                }).showToast()
                this.$router.go()
              })
              .catch((_error) => {
                Toastify({
                  node: cash('#failed-server-content')
                    .clone()
                    .removeClass('hidden')[0],
                  duration: 5000,
                  newWindow: true,
                  close: true,
                  gravity: 'top',
                  position: 'right',
                  stopOnFocus: true
                }).showToast()
                return []
              })
          },
          allowOutsideClick: () => !$Swal.isLoading()
        }).then((result) => {
          if (result.value) {
            //this.$swal("Success!", "User Password Updated.", "success");
          }
        })
      }
    }
  }
}
</script>