<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Final Polish - Purchase Details</h2>
          <div>
            <button class="btn btn-primary py-2" v-if="pagedata.report_items.stock_type == 1" @click="update_purchase_stock">Edit Purchase</button>
          </div>
      </div>
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Records</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="pagedata.generate == 'gen'">
            <div class="p-5">
                <div class="grid grid-cols-12 gap-x-2 pb-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.transaction_code ? pagedata.report_items.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stock Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? "Non Certy Stock" : 'Certy Stock' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stone_type ? pagedata.report_items.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Branch :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.branch_name ? pagedata.report_items.branch_name : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Party :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.party_first_name + ' ' + pagedata.report_items.party_last_name }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Broker :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.broker_first_name ? (pagedata.report_items.broker_first_name + ' ' + pagedata.report_items.broker_last_name) : "-" }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Issue Date :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.issue_date ? pagedata.report_items.issue_date : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Packets :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_pcs : pagedata.report_items.total_pcs }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_wt : pagedata.report_items.total_wt }} Ct</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.total_amount : pagedata.report_items.total_amount }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Total Brokerage :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.stock_type == 1 ? pagedata.report_items.brokerage_amount : pagedata.report_items.brokerage_amount }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6" v-if="pagedata.report_items.certy_type">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Purchase Type :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.certy_type == 1 ? "LAB Pending" : (pagedata.report_items.certy_type == 2 ? "LAB Done" : "-") }}</label>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <div class="intro-y box p-5 mt-5" v-if="pagedata.generate == 'gen'">
        <div v-if="pagedata.generate == 'gen'">

            <div class="flex flex-col sm:flex-row items-center py-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Final Purchase Stock</h2>

                <div v-if="pagedata.report_items.stock_type == 2 && pagedata.report_items.lab_status == null">
                  <button
                @click="inputFileClick" :disabled="pagedata.excel_loading == true"
                class="btn btn-primary shadow-md mr-2">
                Excel/ CSV Receive <LoadingIcon v-if="pagedata.excel_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
                </div>
                <div class="hidden">
                    <input type="file" name="newExcelFile" id="newExcelFile" @change="getExcelData" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </div>
            </div>
            <div class="mt-5" v-if="pagedata.report_items.stock_type == 1">

                <div class="border">
                    <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                            <thead>
                                <tr class="bg-theme-14">
                                    <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stone</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Shape</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Color</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock Rate</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">MAX Rate</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Rate</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Amount</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                                </tr>
                            </thead>
                            <tbody class="text-center" v-for="(field,index) in pagedata.report_items.purchase_bunch_details" :key="index">
                                <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}">
                                    <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.stone_type == "1" ? "NATURAL" : (field.stone_type == "2" ? "CVD" : (field.stone_type == "3" ? "HPHT" : (field.stone_type == "4" ? "D_CVD" : (field.stone_type == "5" ? "D_HPHT" : "" ) ) ) ) }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ (field.shape ? field.shape : '-') }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : '-' }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.charni_name ? field.charni_name : '-' }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ "Size" + field.size_type }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.pieces }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ $h.myFloat(field.weight) }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.stock_rate ? field.stock_rate : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.max_rate ? field.max_rate : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.sell_rate ? field.sell_rate : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.brokerage_per ? field.brokerage_per : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "-" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div v-if="pagedata.report_items.stock_type == 2">
                <div class="border">
                    <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                            <thead>
                                <tr class="bg-theme-14">
                                    <th class="border-b whitespace-nowrap py-2 px-2 dark:border-dark-5">Sr. No</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" v-if="pagedata.report_items.certy_type == 2">Stock No</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Packet No</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">LAB</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Stone</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Shape</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Color</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Repo Rate</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Back(%)</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Currency</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">CNBC Rate</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Less(%)</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Amount</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage(%)</th>
                                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brokerage</th>
                                </tr>
                            </thead>
                            <tbody class="text-center" v-for="(field,index) in pagedata.report_items.purchase_certy_details" :key="index">
                                <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}">
                                    <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                    <td class="border-b border-r py-2 px-2" v-if="pagedata.report_items.certy_type == 2">{{ field.certy_stock_no ? field.certy_stock_no : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.certy_packet_no ? field.certy_packet_no : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.weight ? field.weight : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.lab ? field.lab : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.stone_name ? field.stone_name : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.shape_name ? field.shape_name : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.color_name ? field.color_name : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.clarity_name ? field.clarity_name : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.repo_rate ? field.repo_rate : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.sell_back ? field.sell_back : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.currency ? field.currency : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.cnbc_rate ? field.cnbc_rate : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.sell_less ? field.sell_less : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.total_amount ? field.total_amount : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.broker_per ? field.broker_per : "-" }}</td>
                                    <td class="border-b border-r py-2 px-2">{{ field.brokerage_amount ? field.brokerage_amount : "-" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
</template>


<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
    BASE_URL,
    BUILD_ENV,
    Auth_Token
} from '@/constants/config.js'
import moment from 'moment';
import {
    helper as $h
} from '@/utils/helper';
import {
    useRoute,
    useRouter
} from 'vue-router';

export default defineComponent({
    setup() {

        const swal = inject('$swal');
        const route = useRoute()
        const router = useRouter()
        const AUTH_TOKEN = (BUILD_ENV == "factory" ? localStorage.getItem("server_auth") : localStorage.getItem("token"))
        const pagedata = reactive({

            report_items: [],
            generate: "no",
            loading: false,
            total_added_ct: 0,
            total_added_pieces: 0,
            total_packets: 0,
            total_weight: 0,
            lab_file: "",
            excel_loading: false,
        })

        const generateReport = () => {
            const params = {
                trans_id: route.params.trans_id
            };

            pagedata.loading = true

            let promise = axios({
                url: "/api/get_single_purchase_transaction_details",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        swal("Warning!", data.message, "warning");
                        pagedata.generate = 'no';
                        pagedata.loading = false

                        return;
                    }

                    pagedata.report_items = data.item;
                    pagedata.generate = 'gen';
                    pagedata.loading = false

                })
                .catch(_error => {

                    pagedata.generate = 'no';
                    pagedata.loading = false
                });
        }

        function printReportDetails() {
            // let report_type = 'final-polish-stock-compatison'

            // let params = {
            //     report_type: report_type,
            //     filters: [],
            //     items: pagedata.report_items
            // }
            // let promise = axios({
            //     url: "api/add_report_details",
            //     method: "post",
            //     baseURL: BASE_URL,
            //     data: params,
            //     headers: {
            //         Authorization: "Bearer " + localStorage.getItem("token")
            //     },
            //     config: {
            //         headers: {
            //             Accept: "application/json"
            //         }
            //     }
            // });
            // return promise
            //     .then(result => result.data)
            //     .then(data => {

            //         const report_id = data.report_id

            //         let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
            //         window.open(url, '_blank');

            //     })
            //     .catch(_error => {

            //     });
            // add_report_details
        }

        const getExcelData = (e) => {
            console.log(e.target.files[0]);
            pagedata.lab_file = e.target.files[0];

            e.preventDefault();

            if (pagedata.lab_file) {

                pagedata.excel_loading = true

                let formData = new FormData();
                formData.append('id', route.params.trans_id);
                formData.append('excel', pagedata.lab_file);

                let promise = axios({
                    url: "/api/purchase_upload_certy_packets",
                    method: "POST",
                    data: formData,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json",
                            'content-type': 'multipart/form-data'
                        }
                    },
                });
                return promise
                    .then(result => result.data)
                    .then(data => {
                        if (data.error == "YES") {
                            swal('Error!', data.message, "error");
                            pagedata.excel_loading = false
                            return
                          }

                          swal('Success!', data.message, "success");
                          generateReport()


                        pagedata.lab_file = ''
                        document.querySelector('[id="newExcelFile"]').value = '';
                        pagedata.excel_loading = false
                        focusScan()

                    })
                    .catch(_error => {

                        pagedata.excel_loading = false
                    });
            }

        }

        const inputFileClick = () => {
        document.getElementById("newExcelFile").click()
      }

      const update_purchase_stock = () => {
        router.push('/app/app-final-polish/polish-purchase/non-certy/' + route.params.trans_id)
      }

        onMounted(() => {
            generateReport()
        })

        return {
            pagedata,
            printReportDetails,
            inputFileClick,
            getExcelData,
            update_purchase_stock
        }
    }
})
</script>
