<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Planner Account</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      <button
          v-if="account_id != ''"
          type="button"
           class="btn btn-primary shadow-md mr-2" @click="resetUser">
           <KeyIcon class="w-4 h-4 mr-1" />
           Reset & Resend Password
      </button>
      <button
          v-if="account_id != '' && status == 1"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-danger ' : status == 1}" @click="changeStatus">
           <LockIcon class="w-4 h-4 mr-1" /> Deactivate
      </button>
      <button
          v-if="account_id != '' && status == 0"
          type="button"
          :class="{'btn shadow-md mr-2': true,
          'btn-success ' : status == 0}" @click="changeStatus">
           <UnlockIcon class="w-4 h-4 mr-1" /> Activate
      </button>
      <button
          v-if="account_id != ''"
          type="button"
           class="btn btn-danger shadow-md mr-2" @click="deleteUser">
           <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
      </button>

      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Planner Details</h2>
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >First Name<span class="text-theme-6">*</span></label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="First name"
                    readonly
                    v-model="first_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Middle Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Middle name"
                  readonly
                    v-model="middle_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Last Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                    readonly
                    v-model="last_name"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Personal Email</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    :disabled ="user_id != ''"
                    readonly
                    v-model="contact_email"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Mobile No</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="+00 00000 00000"
                    readonly
                    v-model="contact_mobile_no"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Prcess</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="process"
                    readonly
                  />
                </div>
               
               
              </div>
              


            </div>
            
           
         
        </div>
         </form>
        </div>
         <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Created Successfuly!</div>
            <div class="text-gray-600 mt-1">
                User added to user list.
            </div>
        </div>
         </div>
         <div id="success-notification-content-update" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Updated Successfuly!</div>
        </div>
         </div>
        <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Validation Error!</div>
            <div class="text-gray-600 mt-1">
                Please Check the data. Kindly fill all the <span class="text-theme-6">*</span> marked fields.
            </div>
        </div>

         <div id="failed-user-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">User Creation Error!</div>
            <div class="text-gray-600 mt-1">
                Something went wrong. Kindly Refreash the page and try again.
            </div>
        </div>
         </div>
    </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'


export default defineComponent({
  components: {
    DarkModeSwitcher,
    
  },
  props: {
    user_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      account_id : '',
      
      first_name:"",
      last_name:"",
      middle_name:"",
      contact_email:"",
      contact_mobile_no:"",
      process:[],
      status: '',
      email: '',
      password: '',
      loginVal: '',
      url:'',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
    mounted(){
      if(this.user_id != ''){
        this.account_id = this.user_id;
        this.getuserDetails(this.user_id);
      }
  
  },
  computed: {
    
  },

  methods: {
    
    formSubmit() {
      this.status = 'processing'
      console.log("HI");

    },
    onUserFormSubmit() {
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      if (!this.first_name) {
        this.errors.push("First Name required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      if (!this.contact_email) {
        this.errors.push("Email required.");
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        return;
      }
      
   
      if (!this.errors.length) {
        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name:this.middle_name,
          contact_email :this.contact_email,
          contact_mobile_no : this.contact_mobile_no,
          process:this.process,
          ud:this.user_id
        };
        if (this.user_id == "") {
          this.url = "api/add_account_planner";
        } else {
          this.url = "api/edit_account_planner";
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {

            if(this.user_id != ''){
        Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/planner-master");
        }, 500)
            }else{
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push("/app/accounts/planner-master");
        }, 500)
            }
                    
          })
          .catch(response => {
            Toastify({
              node: cash('#failed-user-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          });
      }
    },
    getuserDetails(user_id) {
      let _this = this;
      const params = {
        ud: user_id
      };
      let promise = axios({
        url: "api/details_account_planner",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data.user_details);
          let user_details = data.user_details;

          this.first_name = user_details.first_name;
          this.last_name = user_details.last_name;
          this.middle_name = user_details.middle_name;
          this.contact_email = user_details.contact_person_email;
          this.contact_mobile_no = user_details.contact_person_mobile;
          this.status = user_details.active_status;
    

        })
        .catch(_error => {
          
        });
    },
    deleteUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a User!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/delete_account_planner",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Deleted.", "success");
          this.$router.push("/app/accounts/planner-master");
        }
      });
    },
    resetUser(){

      console.log(this.user_id);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to reset User Password!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reset",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id
          };
          let promise = axios({
            url: "api/reset_buyer_user",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "New Password Reset & Resend to User.", "success");
        }
      });
    },
    changeStatus(){
      let _this = this;
      console.log(this.user_id);
      console.log(this.status);

      this.$swal({
        title: "Are you sure?",
        text: "You are about to change user status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            user_id: this.user_id,
            user_status : window.btoa(this.status)
          };
          let promise = axios({
            url: "api/change_status_account_planner",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", "User Status Changed.", "success");
          //this.$router.go();
          _this.getuserDetails(this.user_id);
        }
      });
    }
  },
  
})
</script>

