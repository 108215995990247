<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Certy Reset Requests</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

              <button @click="click_new_request" class="btn btn-primary shadow-md mr-3">New Request</button>

          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
          <!-- <div class="border-b pb-5"> -->

          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                  >Field</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filters.field"
                  class="form-select"
                >
                <option value="request_code">Request Code</option>
                </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filters.type"
                class="form-select w-fullo"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2">Value</label>
                <input
                  id="tabulator-html-filter-value"
                  v-model="filters.value"
                  type="text"
                  class="form-control sm:w-40"
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Start Date</label>
                      <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" @change="getTransactionRecords" />
                  </div>

              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">End Date</label>
                      <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" @change="getTransactionRecords" />
                  </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3">
                  <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label">Request No</label>
                      <TomSelect v-model="filters.trans_id" multiple :options="{
                    placeholder: 'Select Select Branch Transfer Request',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Request Code'
                      }
                    }
                  }" class="w-full">
                          <option value="">Select Request</option>
                          <option v-for="(item,index) in pagedata.trans_items" :key="index" :value="item.id">{{ item.request_code }}</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-10">

                      <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                          Reset
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-10">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()" :disabled="pagedata.loading == true">
                          Generate
                          <LoadingIcon icon="bars" class="w-8 h-8" v-if="pagedata.loading == true" />
                      </button>
                  </div>
              </div>
          </div>
          <!-- </div> -->
          <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
  </div>
  </template>

  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject,
      computed
  } from 'vue'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import Tabulator from 'tabulator-tables'
  import feather from 'feather-icons'
  import cash from 'cash-dom';
  import moment from 'moment';
  import {
      useRoute,
      useRouter
  } from 'vue-router';
import store from '@/store';

  export default defineComponent({
      setup() {

          const swal = inject('$swal');
          const userDetails = computed(() => store.state.user.currentUser)

          const tableRef = ref()
          const tabulator = ref()
          const route = useRoute()
          const router = useRouter()
          const pagedata = reactive({

              trans_items: [],
              report_items: [],
              generate: "no",
              loading: false,
          })

          const filters = reactive({
              s_date: moment().startOf('month').format('YYYY-MM-DD'),
              e_date: moment().format('YYYY-MM-DD'),
              stock_type: "0",
              trans_id: [],
              field: 'request_code',
              type: 'like',
              value: ''
          })
          const initTabulator = () => {

              const params = {
                  s_date: filters.s_date,
                  e_date: filters.e_date,
                  field: filters.field,
                  type: filters.type,
                  value: filters.value,
                  trans_id: filters.trans_id.length == 0 ? "" : filters.trans_id
              };
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + '/api/get_request_transaction_list',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'Request Code',
                          field: 'request_code',
                          minWidth: 100,
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          cellClick: function (e, cell) {
                              //e - the click event object
                              //cell - cell component
                              console.log(cell.getData().id);
                              router.push('/app/app-final-polish/reset-stock/certy/' + window.btoa(cell.getData().id))
                          },
                          formatter(cell) {
                              if (cell.getData().btn_type == 1) {
                                  return `<i data-feather="arrow-up-circle" class="w-6 h-6 font-bold text-theme-4 mr-1"></i>` + cell.getData().request_code
                              } else {
                                  return `<i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>` + cell.getData().request_code
                              }
                          }
                      },
                      {
                          title: 'Total Pcs',
                          minWidth: 100,
                          field: 'total_pcs',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,

                      },
                      {
                          title: 'Weight (Ct)',
                          minWidth: 100,
                          field: 'total_ct',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: true,

                      },
                      {
                          title: 'Branch',
                          field: 'branch_name',
                          minWidth: 100,
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: true,

                      },
                      {
                          title: 'Manager',
                          field: 'first_name',
                          minWidth: 100,
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {
                            return cell.getData().first_name +' ' + cell.getData().last_name
                          }

                      },
                      {
                          title: 'Request Date',
                          field: 'issue_date',
                          minWidth: 100,
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Accepted At/ <br> Rejected At',
                          field: 'update_date',
                          minWidth: 100,
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter: "textarea"
                      },
                      {
                          title: 'Status',
                          field: 'status',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              if (cell.getData().status == 1) {
                                  return `<div class="flex items-center lg:justify-center text-theme-1">
                      On Hold
                    </div>`
                              } else if (cell.getData().status == 3) {
                                  return `<div class="flex items-center lg:justify-center text-theme-6">
                      Cancelled
                    </div>`
                              } else if (cell.getData().status == 2) {
                                  return `<div class="flex items-center lg:justify-center text-theme-9">
                    Accepted
                  </div>`
                              } else {
                                  return `<div class="flex items-center lg:justify-center text-theme-6">
                    Unknown !
                  </div>`
                              }
                          }
                      },
                      {
                          title: 'Accept',
                          width: 100,
                          field: '',
                          responsive: 1,
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          headerSort: false,
                          print: false,
                          download: false,
                          formatter(cell) {
                            if(cell.getData().status == 1 && (userDetails.value.user_type == 1 || userDetails.value.user_type == 20)) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                      </button>
                    </div>`)
                                  cash(a).on('click', function () {
                                      editTransactionStatus(cell.getData().id, 'accept');
                                  })
                                  return a[0]
                            } else {

                            }
                          }
                      },
                      {
                          title: 'Reject',
                          width: 70,
                          field: '',
                          responsive: 1,
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          headerSort: false,
                          print: false,
                          download: false,
                          formatter(cell) {
                            if(cell.getData().status == 1) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-danger btn-sm flex items-center mr-3 p-1">
                        <i data-feather="trash-2" class="w-5 h-5 mr-1"></i>
                      </button>
                    </div>`)
                                  cash(a).on('click', function () {
                                      editTransactionStatus(cell.getData().id, 'reject');
                                  })
                                  return a[0]
                            } else {

                            }
                          }
                      },

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }

          const onResetFilter = () => {
              filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
              filters.e_date = moment().format('YYYY-MM-DD')
              filters.trans_id = []
              filters.field = 'request_code'
              filters.type = "like"
              filters.value = ""
          }

          const getTransactionRecords = () => {
              const params = {
                  s_date: filters.s_date,
                  e_date: filters.e_date,
              };

              let promise = axios({
                  url: "api/get_request_transaction_items",
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filters.trans_id = "0"
                      pagedata.trans_items = data.items;

                  })
                  .catch(_error => {
                      filters.trans_id = "0"
                      pagedata.trans_items = [];
                  });
          }
          const click_new_request = () => {
              router.push('/app/app-final-polish/reset-stock/certy/create');
          }

          //Edit user
          const editTransactionStatus = (id, action) => {

              const params = {
                  "id": window.btoa(id),
                  'action': window.btoa(action)
              };

              let text = ""
              let btn = ""

              if(action == "accept") {
                text = 'Select Stock Records will be Removed, <br> You are about to Accept Request!'
                btn = 'Confirm & Accept'
              } else {
                text = 'You are about to Reject Request!'
                btn = 'Confirm & Reject'
              }

              swal({
                  title: "Are you sure?",
                  text: text,
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: btn,
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  preConfirm: login => {

                      let api_url = "api/update_request_action"

                      let promise = axios({
                          url: api_url,
                          method: "post",
                          data: params,
                          baseURL: BASE_URL,
                          headers: {
                              Authorization: "Bearer " + localStorage.getItem("token")
                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {

                              if (data.error == "NO") {
                                  swal.fire({
                                      icon: "success",
                                      title: "Success!",
                                      text: data.message,
                                  })
                                  initTabulator()
                              } else {
                                  swal({
                                      title: "Warning!",
                                      icon: "warning",
                                      text: data.message,
                                      type: "warning",
                                  })
                              }

                          })
                          .catch(_error => {
                              return [];
                          });
                  },
                  allowOutsideClick: () => !swal.isLoading()
              }).then(result => {
                  if (result.value) {
                      //swal("Success!", "User Password Updated.", "success");
                  }
              });
          }

          onMounted(() => {
              getTransactionRecords();
              initTabulator()
          })

          return {
              filters,
              pagedata,
              tableRef,
              onResetFilter,
              initTabulator,
              getTransactionRecords,
              click_new_request
          }
      }
  })
  </script>
