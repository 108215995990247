<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">{{ final_data.id == '' ? 'Merge Stock (Non Certy)' : 'Update Stock (Non Certy)' }}</h2>
    </div>

    <div class="grid grid-cols-12">
        <div class="intro-y col-span-12 mt-5 lg:col-span-12" v-if="final_data.id == ''">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Issue Details</h2>

                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
                            <div class="py-1">
                                <label for="update-profile-form-8" class="form-label">Lot Range Type</label>
                                <select id="update-profile-form-8" class="form-select" v-model="form_data.range_type">
                                    <option value="1" selected>Date Wise</option>
                                    <option value="2">Kapan Wise</option>
                                </select>
                            </div>

                        </div> -->
                        <!-- <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Series</label>
                                <TomSelect v-model="form_data.series_id" :options="{
                    placeholder: 'Select Series',
                    }" multiple @change="changeSeries" class="w-full">
                                    <option value="">Select Series</option>
                                    <option v-for="series in series_arr" :key="series.id" :value="series.id">{{series.series_code}}</option>
                                </TomSelect>
                            </div>
                        </div> -->
                        <div class="col-span-6 md:col-span-4 xl:col-span-3">

                            <div class="py-1" v-if="data_loading == true || final_stock == true">
                                <label for="update-profile-form-7" class="form-label">Select Kapan</label>
                                <TomSelect v-model="form_data.kapan_id" disabled :options="{
                    placeholder: 'Select Kapan',
                    }" class="w-full" @change="kapan_change">
                                    <option value="0">Select Kapan</option>
                                    <option v-for="kapan in kapan_arr" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                                </TomSelect>
                            </div>
                            <div class="py-1" v-else>
                                <label for="update-profile-form-7" class="form-label">Select Kapan</label>
                                <TomSelect v-model="form_data.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    }" class="w-full" @change="kapan_change">
                                    <option value="0">Select Kapan</option>
                                    <option v-for="kapan in kapan_arr" :key="kapan.id" :value="kapan.id">{{kapan.kapan_code}}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Start Date</label>
                                <input type="date" id="update-profile-form-13" class="form-control" :disabled="form_data.range_type != 1" placeholder="" v-model="form_data.s_date" />
                            </div>
                        </div>
                        <div class="col-span-6 md:col-span-3 xl:col-span-2">
                            <div class="my-1">
                                <label for="update-profile-form-7" class="form-label">End Date</label>
                                <input type="date" id="update-profile-form-13" class="form-control" :disabled="form_data.range_type != 1" placeholder="" v-model="form_data.e_date" />
                            </div>
                        </div> -->
                        <div class="col-span-6 md:col-span-3 xl:col-span-2">

                            <div class="py-1" v-if="data_loading == true || final_stock == true">
                                <label for="update-profile-form-7" class="form-label">Select Stone Type</label>
                                <TomSelect v-model="form_data.stone_type" disabled :options="{
                    placeholder: 'Select Stone',
                    }" class="w-full">
                                    <option value="">Select Stone</option>
                                    <option value="1" v-if="kapan_stone == 1">Natural</option>
                                    <option value="2" v-if="kapan_stone == 2">CVD</option>
                                    <option value="3" v-if="kapan_stone == 2">HPHT</option>
                                    <option value="4" v-if="kapan_stone == 4">D_CVD</option>
                                    <option value="5" v-if="kapan_stone == 4">D_HPHT</option>
                                </TomSelect>
                            </div>
                            <div class="py-1" v-else>
                                <label for="update-profile-form-7" class="form-label">Select Stone Type</label>
                                <TomSelect v-model="form_data.stone_type" :options="{
                    placeholder: 'Select Stone',
                    }" class="w-full">
                                    <option value="">Select Stone</option>
                                    <option value="1" v-if="kapan_stone == 1">Natural</option>
                                    <option value="2" v-if="kapan_stone == 2">CVD</option>
                                    <option value="3" v-if="kapan_stone == 2">HPHT</option>
                                    <option value="4" v-if="kapan_stone == 4">D_CVD</option>
                                    <option value="5" v-if="kapan_stone == 4">D_HPHT</option>
                                </TomSelect>
                            </div>
                        </div>
                        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">

                            <div class="py-1">
                                <label for="update-profile-form-7" class="form-label">Select Shape Type</label>
                                <TomSelect v-model="form_data.shape" :options="{
                    placeholder: 'Select Shape',
                    }" class="w-full">
                                    <option value="">Select Shape</option>
                                    <option value="1">ROUND</option>
                                    <option value="2">FANCY</option>
                                </TomSelect>
                            </div>
                        </div> -->
                        <div class="col-span-3 md:col-span-2 xl:col-span-1">
                            <button class="btn btn-secondary px-3 py-2 mt-8 w-full" @click="reset_details">
                                Reset
                            </button>
                        </div>
                        <div class="col-span-3 md:col-span-2 xl:col-span-2">
                            <button class="btn btn-primary py-2 mt-8" @click="get_lot_details" :disabled="data_loading == true || form_status == true">
                                Get Ready Stock
                                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="data_loading == false" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>

        <div class="intro-y col-span-12 mt-5 lg:col-span-12" v-if="final_data.id != ''">
            <div class="intro-y box">

                <div class="grid grid-cols-12 gap-x-2 border-b p-10">
                    <div class="col-span-12 sm:col-span-6">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ transaction_head.transaction_code ? transaction_head.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ transaction_head.stone_type ? transaction_head.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Shape. :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ transaction_head.shape_type ? transaction_head.shape_type : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3" v-if="transaction_head.s_date">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish Start Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_head.s_date }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3" v-if="transaction_head.e_date">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish End Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_head.e_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3" v-if="transaction_head.e_date">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_head.merge_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3" v-if="transaction_head.series_details.length">
                            <label for="update-profile-form-6" class="form-label font-bold">Series : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in transaction_head.series_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <div class="mt-3" v-if="transaction_head.kapan_details.length">
                            <label for="update-profile-form-6" class="form-label font-bold">Kapans : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in transaction_head.kapan_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <!-- </div>
    <div class="grid grid-cols-12 mt-5"> -->
        <div class="intro-y col-span-12 lg:col-span-12 mt-5" v-if="final_stock == true">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Series/ Kapan Details</h2>
                    <div class="mt-3 mr-3">
                        <label for="update-profile-form-6" class="form-label font-bold">Total Ready Stone : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone }}</label>

                    </div>
                    <div class="mt-3">
                        <label for="update-profile-form-6" class="form-label font-bold">Total Ready Stone (ct) : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_ct }}</label>
                    </div>
                </div>
                <div>
                    <div class="p-5">
                        <div class="grid grid-cols-12 p-1 text-center gap-2" v-if="kapan_details_arr.length > 0">
                            <div class="col-span-6 md:col-span-4 xl:col-span-3 border w-full py-1 m-0" v-for="(item,index) in kapan_details_arr" :key="index">
                                <div class="px-2">

                                    <table class="w-full">

                                        <tr>
                                            <td class="w-40 text-left">
                                                {{ item.kapan_code }}
                                            </td>
                                            <td class="w-36 text-center">
                                                {{ item.pieces }}
                                            </td>
                                            <td class="w-36 text-right">
                                                {{ $h.myFloat(item.weight) }} Ct
                                            </td>
                                            <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- END: Input -->
        </div>
        <div v-if="data_loading == true" class="intro-y col-span-12 lg:col-span-12 mt-5">
          <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
        </div>
        <div class="intro-y col-span-12 lg:col-span-12 mt-5" v-else>
            <!-- BEGIN: Input -->
            <div class="intro-y box" v-if="final_stock == true">

                <div class="p-5">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto ml-5">Stock Details</h2>
                        <div class="ml-5 flex">

                            <div class="py-1 flex items-center">
                                <label for="update-profile-form-7" class="form-label px-2">Select Color</label>
                                <TomSelect v-model="form_data.color_size" :options="{
                            placeholder: 'Select Color',
                            }" class="w-36">
                                    <option value="">Select Color</option>
                                    <option v-for="(item,index) in size_purity_arr" :key="index" :value="item.color_size">{{ item.color_name }}</option>
                                  </TomSelect>
                                </div>
                            <div class="py-1 flex items-center">
                                <label for="update-profile-form-7" class="form-label px-2">Shape Type</label>
                                <TomSelect v-model="form_data.shape_type" :options="{
                                  placeholder: 'Select Shape',
                                }" class="w-36">
                                    <option value="">Select Shape</option>
                                    <option v-for="(item,index) in shape_arr" :key="index" :value="item.id">{{ item.shape_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                    </div>
                    <div class="border mt-5">
                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-center text-xs">
                                <thead>
                                    <template v-for="(size_item,index) in size_purity_arr" :key="index">
                                        <template v-if="size_item.color_size == form_data.color_size">
                                            <tr class="bg-theme-14" v-if="size_item.purity_items.length > 0">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2">Purity</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">All</th>
                                                <template v-for="(item,inx) in size_item.purity_items" :key="inx">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">{{ item.purity }}</th>
                                                </template>
                                            </tr>
                                            <tr class="bg-theme-14" v-if="size_item.purity_items.length > 0">
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="2"></th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">Total</th>
                                                <template v-for="(item,inx) in size_item.purity_items" :key="inx">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5" colspan="3">{{ item.segment_name }}</th>
                                                </template>
                                            </tr>
                                            <tr class="bg-theme-14">
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Sr. No</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Charni</th>
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Stock(Ct)</th>
                                                <th class="border-b py-2 px-2 dark:border-dark-5">Pcs</th>
                                                <th class="border-b border-r py-2 px-2 dark:border-dark-5">Rate</th>
                                                <template v-for="(item,inx) in size_item.purity_items" :key="inx">
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Stock(Ct) </th>
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">PCS </th>
                                                    <th class="border-b border-r py-2 px-2 dark:border-dark-5">Rate </th>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-for="(size_item,shape_index) in fields" :key="shape_index">
                                        <template v-if="size_item.color_size == form_data.color_size && size_item.shape_type == form_data.shape_type">

                                            <tr class="bg-gray-200 text-center">
                                                <td class="border-b py-2 px-2 border-r" colspan="2">Total</td>
                                                <td class="border-b py-2 px-2">{{ size_item.total.charni_total_weight ? $h.myFloat(size_item.total.charni_total_weight) : "-" }}</td>
                                                <td class="border-b py-2 px-2">{{ size_item.total.charni_total_pieces ? size_item.total.charni_total_pieces : "-" }}</td>
                                                <td class="border-b py-2 px-2 border-r">{{ size_item.total.charni_total_bhav ? size_item.total.charni_total_bhav : "-" }}</td>
                                                <template v-for="(item,inx) in size_item.total.total_by_sizes" :key="inx">
                                                    <td class="border-b py-2 px-2">{{ item.size_weight ? $h.myFloat(item.size_weight) : "-" }}</td>
                                                    <td class="border-b py-2 px-2">{{ item.size_piece ? item.size_piece : "-" }}</td>
                                                    <td class="border-b py-2 px-2 border-r">{{ item.size_rate ? item.size_rate : "-" }}</td>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                </thead>
                                <tbody class="text-center" v-for="(size_item,shape_index) in fields" :key="shape_index">
                                    <template v-if="size_item.color_size == form_data.color_size && size_item.shape_type == form_data.shape_type">
                                        <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(field,index) in size_item['charni_data']" :key="index">
                                            <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                                            <td class="border-b py-2 px-2 border-r">{{ field.charni_name }}</td>
                                            <td class="border-b py-2 px-2">{{ field.charni_total_weight ? $h.myFloat(field.charni_total_weight) : "-" }}</td>
                                            <td class="border-b py-2 px-2">{{ field.charni_total_pieces ? field.charni_total_pieces : "-" }}</td>
                                            <td class="border-b py-2 px-2 border-r">{{ field.charni_total_bhav ? field.charni_total_bhav : "-" }}</td>
                                            <template v-for="(item,inx) in field.sub_charni_arr" :key="inx">
                                                <td class="border-b py-2 px-2">
                                                    <input v-model="item.size_weight" type="text" class="form-control w-24" placeholder="weight" @keypress="$h.preventInvalidInput" @input="calculateTotal(field)">
                                                </td>
                                                <td class="border-b py-2 px-2">
                                                    <input v-model="item.size_piece" type="text" class="form-control w-24" placeholder="pieces" @keypress="$h.preventInvalidInput" @input="calculateTotalPieces(field)">
                                                </td>
                                                <td class="border-b py-2 px-2 border-r">
                                                    <input v-model="item.stock_rate" type="text" class="form-control w-24" placeholder="Rate" @keypress="$h.preventInvalidInput" @input="calculateTotal(field)">
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="data_loading == true" class="text-md font-medium mr-auto text-center my-8">
                            Loading
                        </div>
                        <div v-if="fields.length == 0 && data_loading == false" class="text-md font-medium mr-auto text-center my-8">
                            Records Not Found
                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-2 xl:col-span-7">

                            </div>
                            <div class="col-span-2 xl:col-span-3 text-right">
                                <p class="font-bold">Total Added Carat (Ct) : </p>
                            </div>
                            <div class="col-span-2 xl:col-span-2 text-right" :class="{'text-theme-6': ((total_added_ct > total_ready_stone_ct) || (total_added_pieces > total_ready_stone))}">
                                <p>{{total_added_pieces}} ({{ total_added_ct ? $h.myFloat(total_added_ct) + " Ct" : "0 Ct" }})</p>
                            </div>

                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-2 xl:col-span-7">

                            </div>
                            <div class="col-span-2 xl:col-span-3 text-right">
                                <p class="font-bold">Est Stock Bhav : </p>
                            </div>
                            <div class="col-span-2 xl:col-span-2 text-right">
                                <p>{{total_added_bhav}}</p>
                            </div>

                        </div>
                        <div class="grid grid-cols-12 mt-3 pr-5">
                            <div class="col-span-2 xl:col-span-7">
                            </div>
                            <div class="col-span-2 xl:col-span-3 text-right">
                                <p class="font-bold">Total Avaialbe(Ct) : </p>
                            </div>
                            <div class="col-span-2 xl:col-span-2 text-right">
                                <p>{{total_ready_stone}} ({{ total_ready_stone_ct ? $h.myFloat(total_ready_stone_ct) + " Ct" : "0 Ct" }})</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-5 py-3 pb-7">
                      <div>
                          <div class="flex justify-end w-full">

                              <button class="btn btn-primary w-20" :disabled="form_status == true || data_loading == true" @click="onUserFormSubmit">
                                  Save
                                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="form_status == false" />
                              </button>

                          </div>
                      </div>
                  </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    defineComponent,
    onMounted
} from 'vue'
import {
    mapGetters,
    mapActions
} from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime
    },
    props: {},

    data() {
        return {
            total_rough_carat: 0,
            kapan_details_arr: [],
            total_ready_stone: 0,
            total_ready_stone_ct: 0,
            total_ready_stone_certi: 0,
            total_ready_stone_ct_certi: 0,
            total_ready_stone_failed: 0,
            total_ready_stone_ct_failed: 0,
            total_ready_stone_lost: 0,
            total_ready_stone_ct_lost: 0,
            total_added_ct: 0,
            total_added_bhav: 0,
            total_added_pieces: 0,

            status: 'default',
            loginVal: '',
            req: axios.create({
                baseUrl: BASE_URL
            }),

            form_data: {
                series_id: "0",
                kapan_id: "0",
                stone_type: "",
                shape_type: "1",
                s_date: "",
                e_date: "",
                range_type: "2",
                id: "",
                color_size: ""
            },
            final_data: {
                series_id: "0",
                kapan_id: "0",
                stone_type: "",
                shape_type: "1",
                s_date: "",
                e_date: "",
                range_type: "2",
                id: "",
                color_size: ""
            },
            kapan_stone: '',
            kapan_arr: [],
            series_arr: [],
            size_purity_arr: [],
            shape_arr: [],
            form_status: false,
            data_loading: false,
            final_stock: false,
            fields: [],
            total: [],
            transaction_head: [],
        }
    },
    computed: {

    },
    mounted() {

        if (this.$route.params.id) {
            this.final_data.id = this.$route.params.id
            this.form_data.id = this.$route.params.id
            this.get_lot_details_update()

        }
        this.getKapanDetails();
    },

    methods: {
        alertVal() {
            alert(this.dob)
        },
        changeSeries() {
            this.form_data.kapan_id = "0"
            this.getKapanDetails()
        },
        kapan_change() {

          this.kapan_arr.forEach(element => {
              if (element.id == this.form_data.kapan_id) {
                  this.form_data.stone_type = String(element.rough_lot_type)
                  this.kapan_stone = String(element.rough_lot_type)
              }
          });
        },
        onUserFormSubmit() {
            //console.log(this.fields);

            // if (this.total_ready_stone < this.total_added_pieces) {
            //     this.$swal("Warning!", "Added Stone grater then Total Ready Stone!", "warning");
            //     return
            // }
            // let less_limit = parseFloat(parseFloat(this.total_ready_stone_ct) - parseFloat(parseFloat(this.total_ready_stone_ct) * 1 / 100));

            // let max_limit = parseFloat(parseFloat(this.total_ready_stone_ct) + parseFloat(parseFloat(this.total_ready_stone_ct) * 20 / 100));
            // console.warn(max_limit)
            // if (max_limit < this.total_added_ct) {
            //     this.$swal("Warning!", "Added Stone Weight grater then Total Ready Stone Weight Limit!", "warning");
            //     return
            // }
            // if (less_limit > this.total_added_ct) {
            //     this.$swal("Warning!", "Added Stone Weight less then Total Ready Stone Weight Limit!", "warning");
            //     return
            // }

            //console.log(JSON.stringify(this.gridForm));
            this.errors = [];

            if (!this.errors.length) {

                this.$swal({
                    title: "Are you sure?",
                    text: "You Want to Merge Stock!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Accept",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        const data = {
                            "total_added_ct": this.total_added_ct,
                            "total_ready_stone_ct": this.total_ready_stone_ct,
                            "total_added_pieces": this.total_added_pieces,
                            "kapan_items": this.kapan_details_arr,
                            "color_arr": this.fields,
                            "id": this.final_data.id,
                            "series_id": this.final_data.series_id == "0" ? "" : this.final_data.series_id,
                            "kapan_id": this.final_data.kapan_id == "0" ? "" : this.final_data.kapan_id,
                            "stone": this.final_data.stone_type == "" ? "" : window.btoa(this.final_data.stone_type),
                            "shape": this.final_data.shape_type == "" ? "" : window.btoa(this.final_data.shape_type),
                            "s_date": this.final_data.s_date == "" ? "" : window.btoa(this.final_data.s_date),
                            "e_date": this.final_data.e_date == "" ? "" : window.btoa(this.final_data.e_date),
                            "range_type": this.final_data.range_type == "" ? "" : window.btoa(this.final_data.range_type),

                        };
                        let new_url = "api/merge_lot_details"

                        this.form_status = true

                        let promise = axios({
                            url: new_url,
                            method: "POST",
                            data: data,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            }
                        });

                        return promise
                            .then(result => result.data)
                            .then(data => {

                              this.form_status = false
                                if (data.error == "YES") {
                                    this.$swal("Error!", data.message, "error");

                                    return;
                                }

                                if (this.final_data.id != "") {

                                    this.$router.push('/app/app-polish-ready/ready/merge/' + this.final_data.id);
                                } else {

                                    let merge_transaction = data.merge_transaction
                                    if (merge_transaction) {
                                        this.$router.push('/app/app-polish-ready/ready/merge/' + window.btoa(merge_transaction.id));
                                    }
                                }

                                this.$swal.fire({
                                    icon: 'success',
                                    title: "Success!",
                                    text: data.message,
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                                this.form_status = false

                            })
                            .catch(_error => {
                                this.form_status = false
                                this.$swal("Error!", "Something went Wrong", "error");
                                if (data.error == "YES") {

                                    return;
                                }

                            });
                    }
                })
            }
        },
        calculateTotal(item) {

            item.charni_total_weight = 0
            item.charni_total_bhav = 0
            item.sub_charni_arr.forEach(element => {
                element.size_rate = parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0)
                item.charni_total_weight = $h.myFloat(parseFloat(item.charni_total_weight) + (element.size_weight ? parseFloat(element.size_weight) : 0))
                item.charni_total_bhav = $h.myFloat(parseFloat(item.charni_total_bhav) + (element.size_rate ? parseFloat(element.size_rate) : 0))

            });

            this.calculateSizeTotal()

        },
        calculateTotalPieces(item) {
        item.charni_total_pieces = 0
        item.sub_charni_arr.forEach(element => {
            item.charni_total_pieces = $h.myFloat(parseFloat(item.charni_total_pieces) + (element.size_piece ? parseFloat(element.size_piece) : 0))

        });
        this.total_added_pieces = 0
        this.fields.forEach(element_f => {

            let t_total_pcs = 0

            element_f.total.total_by_sizes.forEach(element_s => {

                let s_total_pcs = 0

                element_f.charni_data.forEach(element_g => {

                    element_g.sub_charni_arr.forEach(element => {

                        if (element.size_type == element_s.size_type) {

                            s_total_pcs = $h.myFloat(s_total_pcs + (element.size_piece ? parseFloat(element.size_piece) : 0))
                            t_total_pcs = $h.myFloat(t_total_pcs + (element.size_piece ? parseFloat(element.size_piece) : 0))
                        }
                    });

                });

                element_s.size_piece = s_total_pcs
            });

            element_f.total.charni_total_pieces = t_total_pcs
            this.total_added_pieces += t_total_pcs
        });
        },
        calculateSizeTotal() {

        this.total_added_ct = 0

        this.fields.forEach(element_f => {

            let t_total_wt = 0
            let t_total_rate = 0

            element_f.total.total_by_sizes.forEach(element_s => {

                let s_total_wt = 0
                let s_total_rate = 0

                element_f.charni_data.forEach(element_g => {

                    element_g.sub_charni_arr.forEach(element => {

                        if (element.size_type == element_s.size_type) {

                            s_total_wt = $h.myFloat(s_total_wt + (element.size_weight ? parseFloat(element.size_weight) : 0))
                            s_total_rate = $h.myFloat(s_total_rate + parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0))
                            t_total_wt = $h.myFloat(t_total_wt + (element.size_weight ? parseFloat(element.size_weight) : 0))
                            t_total_rate = $h.myFloat(t_total_rate + parseFloat(element.size_weight > 0 ? parseFloat(element.size_weight) : 0) * parseFloat(element.stock_rate > 0 ? parseFloat(element.stock_rate) : 0))
                        }
                    });

                });

                element_s.size_weight = s_total_wt
                element_s.size_rate = $h.myFloat(s_total_rate / s_total_wt)
            });

            element_f.total.charni_total_weight = t_total_wt
            this.total_added_ct = $h.myFloat(this.total_added_ct + t_total_wt)
            element_f.total.charni_total_bhav = $h.myFloat(t_total_rate / t_total_wt)
        });

        },
        async getSeriesDetails() {
            let _this = this;
            const params = {

            };
            let promise = axios({
                url: "api/get_polish_transfer_done_series_details",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.series_arr = data.items;

                })
                .catch(_error => {
                    this.series_arr = [];

                });
        },
        async getKapanDetails() {
            let _this = this;
            const params = {
                // series_id: this.form_data.series_id,
            };
            let promise = axios({
                url: "api/get_polish_transfer_done_kapan_details",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.kapan_arr = data.items;
                    this.form_data.kapan_id = "0";

                })
                .catch(_error => {
                    this.kapan_arr = [];

                });
        },
        async getSizePurityDetails() {
            let _this = this;
            const params = {
                stone_type: this.final_data.stone_type
            };
            let promise = axios({
                url: "api/list_all_merge_segment_all_color",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    this.size_purity_arr = data.items;
                    this.shape_arr = data.shape_items;
                    this.form_data.color_size = String(this.size_purity_arr[0].color_size)
                  })
                  .catch(_error => {
                    this.size_purity_arr = [];
                    this.shape_arr = [];
                    this.form_data.color_size = ""

                });
        },
        async get_lot_details() {

            if (this.form_data.range_type == 1 && (this.form_data.s_date == "" || this.form_data.e_date == "")) {
                this.$swal("Warning!", "Select Date Range First!", "warning");
                return
            }
            if (this.form_data.range_type == 2 && (this.form_data.kapan_id == "0")) {
                this.$swal("Warning!", "Select Series First!", "warning");
                return
            }
            if (this.form_data.stone_type == "" || this.form_data.stone_type == "0") {
                this.$swal("Warning!", "Select Stone Type First!", "warning");
                return
            }
            // if (this.form_data.shape == "" || this.form_data.shape == "0") {
            //     this.$swal("Warning!", "Select Shape Type First!", "warning");
            //     return
            // }
            this.final_data.series_id = this.form_data.series_id
            this.final_data.kapan_id = this.form_data.kapan_id
            this.final_data.s_date = this.form_data.s_date
            this.final_data.e_date = this.form_data.e_date
            this.final_data.stone_type = this.form_data.stone_type
            this.final_data.shape_type = this.form_data.shape_type
            this.final_data.range_type = this.form_data.range_type

            this.getSizePurityDetails()

            this.data_loading = true
            this.fields = []
            this.total_ready_stone = 0;
            this.total_ready_stone_ct = 0

            let params = {
                "series_id": this.form_data.series_id == "0" ? "" : this.form_data.series_id,
                "kapan_id": this.form_data.kapan_id == "0" ? "" : this.form_data.kapan_id,
                "stone": this.form_data.stone_type == "" ? "" : window.btoa(this.form_data.stone_type),
                "shape": this.form_data.shape_type == "" ? "" : window.btoa(this.form_data.shape_type),
                "s_date": this.form_data.s_date == "" ? "" : window.btoa(this.form_data.s_date),
                "e_date": this.form_data.e_date == "" ? "" : window.btoa(this.form_data.e_date),
                "range_type": this.form_data.range_type == "" ? "" : window.btoa(this.form_data.range_type),
            }

            let api_url = "api/get_lot_details_merge_pending"

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                    if (data.error == "YES") {
                        this.kapan_details_arr = []
                        this.fields = []
                        this.total_ready_stone = 0
                        this.total_ready_stone_ct = 0
                        this.data_loading = false
                        this.final_stock = false

                        this.$swal("Warning", data.message, "warning");

                        return
                    } else {

                      this.kapan_details_arr = data.kapan_details
                      this.fields = data.color_arr
                      this.total_ready_stone = data.total_weights.packets
                      this.total_ready_stone_ct = data.total_weights.weight
                      this.data_loading = false
                      this.final_stock = true
                    }

                })
                .catch((_error) => {
                    this.kapan_details_arr = []
                    this.fields = []
                    this.total_ready_stone = 0
                    this.total_ready_stone_ct = 0
                    this.data_loading = false
                });
        },
        async get_lot_details_update() {

            this.data_loading = true
            this.fields = []
            this.total_ready_stone = 0;
            this.total_ready_stone_ct = 0

            let params = {
                "id": this.final_data.id
            }

            let api_url = "api/get_lot_details_merge_pending_update"

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                    if (data.error == "YES") {
                        if (data.total_weights) {
                            this.total_ready_stone = data.total_weights.packets
                            this.total_ready_stone_ct = data.total_weights.weight
                        }
                        if (data.kapan_details) {
                            this.kapan_details_arr = data.kapan_details
                        }
                        if (data.transaction_item) {
                            this.transaction_head = data.transaction_item
                        }
                        if (data.packet_details) {
                            this.fields = data.packet_details
                        }
                        this.data_loading = false
                        this.final_stock = false

                        this.$swal("Warning", data.message, "warning");

                        return
                    }

                    this.kapan_details_arr = data.kapan_details
                    this.fields = data.packet_details
                    this.transaction_head = data.transaction_item
                    this.total_ready_stone = data.total_weights.packets
                    this.total_ready_stone_ct = data.total_weights.weight
                    this.data_loading = false

                    this.fields.forEach(element => {
                        this.calculateTotal(element)
                        this.calculateTotalPieces(element)
                    });
                })
                .catch((_error) => {
                    this.kapan_details_arr = []
                    this.fields = []
                    this.transaction_head = []
                    this.total_ready_stone = 0
                    this.total_ready_stone_ct = 0
                    this.data_loading = false
                });

        },
        reset_details() {
          this.form_data.series_id = "0"
          this.form_data.kapan_id = "0"
          this.form_data.s_date = ""
          this.form_data.stone_type = ""
          this.form_data.shape_type = "1"
          this.form_data.e_date = ""
          this.total_ready_stone_ct = ""
          this.total_ready_stone = ""
          this.final_data.series_id = "0"
          this.final_data.kapan_id = "0"
          this.final_data.s_date = ""
          this.final_data.stone_type = ""
          this.final_data.shape_type = "1"
          this.final_data.e_date = ""
          this.fields = []
          this.final_stock = false
        },
    },

})
</script>

<style scoped>
/* .border {
  margin: 0 20px;
  padding: 3px;
} */
#tj-datetime-input input {
    border: 0;
}

.datetime-picker input:active {
    border: 0;
}

.datetime-picker input:focus {
    border: 0;
}

.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 500px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
