<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Stock Comparison Report</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <!-- <div class="border-b pb-5"> -->

        <div class="grid grid-cols-12 gap-x-2">

            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Start Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.s_date" @change="getTransactionRecords" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" v-model="filters.e_date" @change="getTransactionRecords" />
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Stone</label>
                    <TomSelect v-model="filters.stone_type" :options="{
                  placeholder: 'Select Stone',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stone'
                    }
                  }
                }" class="w-full" @change="getTransactionRecords">
                        <option value="0">All</option>
                        <option value="1">Natural</option>
                        <option value="2">CVD</option>
                        <option value="3">HPHT</option>
                        <option value="4">D_CVD</option>
                        <option value="5">D_HPHT</option>
                        <!-- <option value="3">PRINCE</option> -->
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Select Shape</label>
                    <TomSelect v-model="filters.shape" :options="{
                  placeholder: 'Select Shape',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Shape'
                    }
                  }
                }" class="w-full" @change="getTransactionRecords">
                        <option value="0">All</option>
                        <option value="1">ROUND</option>
                        <option value="2">FANCY</option>
                        <!-- <option value="3">PRINCE</option> -->
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Merge Code</label>
                    <TomSelect v-model="filters.merge_id" :options="{
                  placeholder: 'Select Select Merge Code',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Transaction Code'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Merge Code</option>
                        <option v-for="(merge,index) in pagedata.merge_items" :key="index" :value="merge.id">{{ merge.merge_code }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="generateReport()" :disabled="pagedata.loading == true">
                        Generate
                        <LoadingIcon icon="bars" class="w-8 h-8" v-if="pagedata.loading == true" />
                    </button>
                </div>
            </div>
        </div>
        <!-- </div> -->

    </div>
    <div class="intro-y box p-5 mt-5">

        <div id="responsive-table" class="p-5" v-if="pagedata.loading == true">
            <div class="preview">
                <div class="overflow-x-auto">
                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Records</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="p-5" v-if="pagedata.generate == 'gen'">
                <div class="grid grid-cols-12 gap-x-2">
                    <div class="col-span-12 sm:col-span-5">

                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Code. :&nbsp;</label>
                            <label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.transaction_code ? pagedata.report_items.filters_arr.transaction_code : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-5">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Stone Type : &nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.stone_type ? pagedata.report_items.filters_arr.stone_type : '-' }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-5">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Shape. :&nbsp;</label><label for="update-profile-form-6" class="form-label">{{ pagedata.report_items.filters_arr.shape_type ? pagedata.report_items.filters_arr.shape_type : '-' }}</label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-5">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Merge Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ pagedata.report_items.filters_arr.merge_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-5" v-if="pagedata.report_items.filters_arr.s_date">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish Start Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ pagedata.report_items.filters_arr.s_date }}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-5" v-if="pagedata.report_items.filters_arr.series_details.length > 0">
                        <div class="mt-3">
                            <label for="update-profile-form-4" class="form-label font-bold">Series : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in pagedata.report_items.filters_arr.series_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-5" v-if="pagedata.report_items.filters_arr.kapan_details.length > 0">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Kapans : &nbsp;</label><label for="update-profile-form-6" class="form-label"> <label v-for="(itm,index) in pagedata.report_items.filters_arr.kapan_details" :key="index">{{ index == 0 ? "" : ", " }} {{ itm }}</label></label>
                        </div>

                    </div>
                    <div class="col-span-12 sm:col-span-4" v-if="pagedata.report_items.filters_arr.e_date">
                        <div class="mt-3">
                            <label for="update-profile-form-6" class="form-label font-bold">Final Polish End Date : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ pagedata.report_items.filters_arr.e_date}}</label>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-2  md:col-span-1">
                        <button class="btn btn-primary w-full shadow-md mr-2" @click="printReportDetails()">Print </button>
                    </div>
                    <div class="col-span-12 sm:col-span-2  md:col-span-2">
                        <a href="javascript:;" data-toggle="modal" data-target="#new-filter-modal" class="btn btn-primary shadow-md mr-2">Series/ Kapan </a>
                    </div>
                </div>

            </div>
            <div v-if="pagedata.generate == 'gen'">
                <div class="border mt-5 p-0 shadow-md">

                    <div class="overflow-x-auto m-0 p-0">

                        <table class="text-xs text-center w-full">

                            <thead>
                                <tr class="border bg-gray-200 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(shape_item,shape_index) in pagedata.report_items.heading1_arr" :key="shape_index" :colspan="pagedata.report_items.heading1_size_arr[shape_index]">{{ shape_item }}</th>
                                </tr>
                                <tr class="border bg-gray-100 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5 whitespace-nowrap" v-for="(size_item,size_index) in pagedata.report_items.heading2_arr" :key="size_index" :colspan="pagedata.report_items.size_arr_number[size_index]">{{ size_item }}</th>
                                </tr>
                                <tr class="border bg-gray-100 dark:bg-dark-1">
                                    <th class="border py-2 px-1 dark:border-dark-5" v-for="(size_item,size_index) in pagedata.report_items.heading3_arr" :key="size_index">{{ size_item }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border" v-for="(size_value_item,size_value_index) in pagedata.report_items.size_value_arr" :key="size_value_index">
                                    <td class="border py-2" v-for="(item,inx) in size_value_item" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</td>
                                </tr>
                                <tr class="border">
                                    <th class="border py-2" v-for="(item,inx) in pagedata.report_items.total_value_arr" :key="inx">{{ inx == 0 ? item : $h.myFloat(item) }}</th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Charni modal-->

    <div id="new-filter-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Series/ Kapan List</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body overflow-x-auto">
                    <div class="py-5">
                        <h2 class="text-lg font-medium mr-auto">Series/ Kapan List</h2>
                        <div class="grid grid-cols-12 p-1 text-center gap-2" v-if="pagedata.report_items.kapan_detail_arr">
                            <div class="col-span-6 border w-full py-1 m-0" v-for="(item,index) in pagedata.report_items.kapan_detail_arr" :key="index">
                                <div class="px-2">

                                    <table class="w-full">

                                        <tr>
                                            <td class="w-40 text-left">
                                                {{ item.kapan_code }}
                                            </td>
                                            <td class="w-36 text-center">
                                                {{ item.pieces }}
                                            </td>
                                            <td class="w-36 text-right">
                                                {{ $h.myFloat(item.weight) }} Ct
                                            </td>
                                            <!-- {{ item.kapan_code }} | {{ item.pieces }} | {{ $h.myFloat(item.weight) }} Ct -->
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
    BASE_URL
} from '@/constants/config.js'
import cash from 'cash-dom';
import moment from 'moment';

export default defineComponent({
    setup() {

        const swal = inject('$swal');

        const pagedata = reactive({

            merge_items: [],
            report_items: [],
            generate: "no",
            loading: false,
        })

        const filters = reactive({
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            shape: "0",
            stone_type: "0",
            merge_id: "0",
        })

        const generateReport = () => {

            if (filters.merge_id == "0") {
                swal("Warning!", "Select Merge Code First", "warning");
                return;
            }

            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                shape: filters.shape == "0" ? "" : filters.shape,
                stone_type: filters.stone_type == "0" ? "" : filters.stone_type,
                merge_id: filters.merge_id == "0" ? "" : filters.merge_id
            };

            pagedata.loading = true

            let promise = axios({
                url: "api/get_mrege_transaction_report_details",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "YES") {
                        swal("Warning!", data.message, "warning");
                        pagedata.generate = 'no';
                        pagedata.loading = false

                        return;
                    }

                    pagedata.report_items = data.items;
                    pagedata.generate = 'gen';
                    pagedata.loading = false

                })
                .catch(_error => {

                    pagedata.generate = 'no';
                    pagedata.loading = false
                });
        }

        const onResetFilter = () => {
            filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            filters.e_date = moment().format('YYYY-MM-DD')
            filters.shape = "0"
            filters.stone_type = "0"
            filters.merge_id = "0"
        }

        const getTransactionRecords = () => {
            const params = {
                s_date: filters.s_date,
                e_date: filters.e_date,
                shape: filters.shape == "0" ? "" : filters.shape,
                stone_type: filters.stone_type == "0" ? "" : filters.stone_type,
            };

            let promise = axios({
                url: "api/get_merge_transaction_items",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filters.merge_id = "0"
                    pagedata.merge_items = data.items;

                })
                .catch(_error => {
                    filters.merge_id = "0"
                    pagedata.merge_items = [];
                });
        }

        function printReportDetails() {
            let report_type = 'final-polish-stock-compatison'

            let params = {
                report_type: report_type,
                filters: filters,
                items: pagedata.report_items
            }
            let promise = axios({
                url: "api/add_report_details",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    const report_id = data.report_id

                    let url = BASE_URL + '/' + data.report_type + '?id=' + window.btoa(report_id)
                    window.open(url, '_blank');

                })
                .catch(_error => {

                });
            // add_report_details
        }

        onMounted(() => {
            getTransactionRecords();
            // generateReport()
        })

        return {
            filters,
            pagedata,
            onResetFilter,
            generateReport,
            getTransactionRecords,
            printReportDetails
        }
    }
})
</script>
