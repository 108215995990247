<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="flex flex-wrap justify-between items-center">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">Dashboard</h2>
            </div>
            <div class="flex flex-wrap justify-end items-center">
              <div>
                <label for="update-profile-form-6" class="form-label"
                  >Select Start Date</label
                >
                <input
                  type= "date"
                  id="update-profile-form-13"

                  class="form-control"
                  placeholder=""
                  v-model="pagedata.start_date"
                  @change="getTendersDetails"
                />
              </div>
              <div class="ml-3">
                <label for="update-profile-form-6" class="form-label"
                  >Select End Date</label
                >
                <input
                  type= "date"
                  id="update-profile-form-13"

                  class="form-control"
                  placeholder=""
                  v-model="pagedata.end_date"
                  @change="getTendersDetails"
                />
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <ActivityIcon class="report-box__icon text-theme-10" />
                    <div class="ml-auto">

                    </div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{dashboard_details.total_series}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Series Issued</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CheckCircleIcon class="report-box__icon text-theme-11" />
                    <div class="ml-auto">

                    </div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{dashboard_details.available_series}}</div>
                  <div class="text-base text-gray-600 mt-1">Available Series</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <InboxIcon class="report-box__icon text-theme-12" />
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="12% Higher than last month"
                      >
                        12% <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{dashboard_details.total_tenders}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Tenders</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <MailIcon class="report-box__icon text-theme-9" />
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="22% Higher than last month"
                      >
                        22% <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{dashboard_details.total_bids}}</div>
                  <div class="text-base text-gray-600 mt-1">Total Bids</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-12 mt-8">
          <div class="intro-y box">
            <div
              class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Series Details</h2>
              <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                <div class="mt-3 flex items-center">
                  <label for="update-profile-form-8" class="form-label mr-3"
                    >Rough Series</label
                  >
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Series',

                }"
                class="w-60" v-model="dashboard_details.series_id">
                    <option v-for="series_item in dashboard_details.series_ar" :key="series_item.id" :value="series_item.id">{{series_item.series_code}}</option>
                  </TomSelect>
                </div>
              </div>
            </div>

            <div class="p-5">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 xl:col-span-4 md:col-span-4">

                  <div>
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Series Weight (Original): &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ Number(dashboard_details.series_main_weight) }} (Ct) </label
                    >
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Series Weight (Available): &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ Number(dashboard_details.series_main_weight - dashboard_details.series_weight_in_process - dashboard_details.series_total_ghat - dashboard_details.series_total_rejection - dashboard_details.series_total_kapan_weight) }} (Ct)</label
                    >
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Series Weight (in Process): &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ Number(dashboard_details.series_weight_in_process) }} (Ct)</label
                    >
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Total Ghat (Ct) (%) : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ Number(dashboard_details.series_total_ghat) }} (Ct) </label
                    >
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Total Kapan Weight: &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ Number(dashboard_details.series_total_kapan_weight) }} (Ct)</label
                    >
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Total Rejection (Ct) (%) : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ Number(dashboard_details.series_total_rejection) }} (Ct) </label
                    >
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label font-bold"
                      >Series Last Process : &nbsp;</label
                    ><label for="update-profile-form-6" class="form-label"> {{ dashboard_details.last_process }}</label
                    >
                  </div>


                </div>
                <div class="col-span-6 xl:col-span-6 md:col-span-6">
                  <div id="graph1" ref="graph1_wrap" style="width:100%; height:280px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->

        <div class="col-span-12 lg:col-span-12">
          <!-- BEGIN: HTML Table Data -->
          <div class="intro-y box p-5 mt-5">
            <div class="py-3">
              <h2 class="font-medium text-base mr-auto">Series List</h2>
            </div>
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Field</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="lot_no">Series No</option>

                  </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Type</label
                  >
                  <select
                    id="tabulator-html-filter-type"
                    v-model="filter.type"
                    class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="like" selected>like</option>
                    <option value="=">=</option>
                    <option value="<">&lt;</option>
                    <option value="<=">&lt;=</option>
                    <option value=">">></option>
                    <option value=">=">>=</option>
                    <option value="!=">!=</option>
                  </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Value</label
                  >
                  <input
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="onResetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <!-- <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button> -->
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-csv"
                        href="javascript:;"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportCsv"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                      </a>

                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:;"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-json"
                        href="javascript:;"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportJson"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5"
              ></div>
            </div>
          </div>
          <!-- END: HTML Table Data -->
        </div>


      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted, watch } from 'vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

import * as echarts from 'echarts/lib/echarts.js'

import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/dataZoomInside";
import "echarts/lib/component/dataZoomSlider";

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const pagedata = reactive({
      start_date: '',
      end_date: '',
    })
    const userDetails = computed(() => store.state.user.currentUser)


    const dashboard_details = reactive({
      available_series: '-',
      total_series: '-',
      series_ar: [],
      series_id: '',
      series_main_weight: '',
      series_weight_in_process: '',
      series_total_kapan_weight: '',
      series_total_ghat: '',
      series_total_rejection: '',
      last_process: '',
      transection_processes: ''
    })
    const graph_details = ref([])
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })
    const graph1_wrap = ref()
    const recent_bids = reactive({
      bids:[]
    })

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    const getTendersDetails = () =>{
      if(pagedata.start_date > pagedata.end_date) {
        pagedata.start_date = pagedata.end_date
      }
      const params = {
        start_date : pagedata.start_date,
        end_date : pagedata.end_date
      };
      let promise = axios({
        url: "api/get_menual_manager_dashboard_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          dashboard_details.total_series = data.total_series;
          dashboard_details.available_series = data.available_series;
          dashboard_details.series_ar = data.items;
          dashboard_details.series_id = dashboard_details.series_ar[0].id
        })
        .catch(_error => {

        });

    }
    const getSeriesDetails = () =>{
     const params = {
        series_id: dashboard_details.series_id
      };
      let promise = axios({
        url: "api/get_menual_manager_series_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          dashboard_details.series_main_weight = data.series_details.series_main_weight
          dashboard_details.series_weight_in_process = data.series_details.series_weight_in_process
          dashboard_details.series_total_kapan_weight = data.series_details.series_total_kapan_weight
          dashboard_details.series_total_ghat = data.series_details.series_total_ghat
          dashboard_details.series_total_rejection = data.series_details.series_total_rejection
          dashboard_details.last_process = data.last_process
          dashboard_details.transection_processes = data.transection_processes
          let graph_colors = ['#34baeb','#FF6600','#34eb7a','#f5b942','#ff0000','#bf5f40','#f708c9','#7415ea','#5ba46d','#788779','#877886','#5ba49e','#99d42b']
          graph_details.value = []
          graph_details.value[0] = { value: dashboard_details.series_weight_in_process, name: 'In Process',itemStyle:{ color : graph_colors[0] } }
          graph_details.value[1] = { value: dashboard_details.series_total_kapan_weight, name: 'Kapan Weight',itemStyle:{ color : graph_colors[1]} }
          graph_details.value[2] = { value: dashboard_details.series_main_weight - dashboard_details.series_weight_in_process - dashboard_details.series_total_ghat - dashboard_details.series_total_rejection - dashboard_details.series_total_kapan_weight, name: 'Available Weight' ,itemStyle:{ color : graph_colors[2] }}
          // graph_details.value[3] = { value: dashboard_details.series_total_ghat, name: 'Ghat' ,itemStyle:{color : graph_colors[3] }}
          graph_details.value[3] = { value: dashboard_details.series_total_rejection, name: 'Rejection' ,itemStyle:{color : graph_colors[3] }}
          if(dashboard_details.transection_processes.length > 0) {
            for(let i = 0; i < dashboard_details.transection_processes.length; i++) {
              let graph_index = graph_details.value.length
              graph_details.value[graph_index] = { value: dashboard_details.transection_processes[i].total_lose_weight, name: dashboard_details.transection_processes[i].process_code ,itemStyle:{color : graph_colors[graph_index] }}
            }
          }
          console.warn(graph_details.value)
          pieWeight()
        })
        .catch(_error => {

        });

    }
    const checkPermission = () => {
      console.log(userDetails.value.id);
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_series_by_manager',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
         columns: [
          // For HTML table
          {
            title: 'Series No',

            field: 'series_code',
            vertAlign: 'middle',
            print: false,
            download: true,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              //router.push("/purchase/rough-view/kapan-detail/" + window.btoa(cell.getData().id));
              router.push("/purchase/rough-view/series-detail/" + window.btoa(cell.getData().id));
          }
          },
          {
            title: 'Manager Name',

            field: 'first_name',
            hozAlign: 'ledt',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return cell.getData().first_name + " "+ cell.getData().last_name;

            }
          },
          {
            title: 'Weight(Orignal)',

            field: 'series_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().series_weight);
            }
          },
          {
            title: 'Weight(Received)',

            field: 'total_received',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().total_received);
            }
          },
          {
            title: 'Weight(In Hand)',
            field: 'in_hand',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().in_hand);
            }
          },
          {
            title: 'Weight(in Process)',
            field: 'weight_in_process',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().weight_in_process);
            }
          },
          {
            title: 'Kapan Wt',
            field: 'total_kapan_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return Number(cell.getData().total_kapan_weight);
            }
          },
          {
            title: 'Est. Makeable(%)',
            field: 'exp_mackable',
            hozAlign: 'ledt',
            vertAlign: 'middle',
            print: false,
            download: true
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    //Edit user
    const editUser = (id) => {
      console.log(id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'first_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }



    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'buyer_list.xlsx', {
        sheetName: 'Buyers'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }
    function pieWeight() {
      let myChart = echarts.init(graph1_wrap.value);
      myChart.showLoading({
          text: "Gathering Information",
          color: "#145388"
        });
        let option = {
        tooltip: {
          trigger: 'item',
          formatter: '<b>{b}</b> <br/><b>{c}({d}%)</b>'
        },
        legend: {
          top: '20%',
          left: 'right',
          orient: 'vertical',
        },
        series: [
          {
            name: 'Series Rough',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: graph_details.value
            // [
            //   { value: dashboard_details.series_weight_in_process, name: 'In Process',itemStyle:{
            //     color:"#34baeb"
            //   } },
            //   { value: dashboard_details.series_total_kapan_weight, name: 'Kapan Weight',itemStyle:{
            //     color:"#FF6600"
            //   } },
            //   { value: dashboard_details.series_main_weight - dashboard_details.series_weight_in_process - dashboard_details.series_total_ghat - dashboard_details.series_total_rejection - dashboard_details.series_total_kapan_weight, name: 'Available Weight' ,itemStyle:{
            //     color:"#34eb7a"
            //   }},
            //   { value: dashboard_details.series_total_ghat, name: 'Ghat' ,itemStyle:{
            //     color:"#ff0000"
            //   }},
            //   { value: dashboard_details.series_total_rejection, name: 'Rejection' ,itemStyle:{
            //     color:"#f5b942"
            //   }}
            // ]
          }
        ]
      };
      myChart.hideLoading();
        myChart.setOption(option, true);
        myChart.resize();
    }
    // Print
    const onPrint = () => {
      tabulator.value.print()
    }
    onMounted(() => {
      checkPermission()
      // getSeriesDetails()
      pagedata.end_date = new Date();
      pagedata.end_date = pagedata.end_date.toISOString().substr(0, 10);
      let date = new Date();
      pagedata.start_date = new Date(date.setMonth(date.getMonth() - 1)).toISOString().substr(0, 10);
      getTendersDetails()
      initTabulator()
      reInitOnResizeWindow()
      // getRecentBids()
      // setInterval(
      //   getRecentBids
      //   ,30000)
    })

    watch(() => dashboard_details.series_id, (currentValue) => {
      getSeriesDetails()
    })
    return {
      pagedata,
      salesReportFilter,
      dashboard_details,
      userDetails,
      recent_bids,
      tableRef,
      filter,
      graph1_wrap,
      graph_details,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      getTendersDetails,
      prevImportantNotes,
      nextImportantNotes,

    }
  }
})
</script>
<style scoped>
.border {
  margin: 0;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>
