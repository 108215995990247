<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Galaxy Transfer Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

          <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Series</label
                  >
                 <TomSelect
                v-model="screenData.selectedSeries"
                :options="{
                  placeholder: 'Select Series',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Series'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="">Select Series</option>
              <option v-for="series in screenData.series_ar" :key="series.id" :value="series.id">{{series.series_code}}</option>
              </TomSelect>
                </div>

               <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Date From</label
                  >
                  <input
                   type= "date"
                    id="update-profile-form-13"
                    class="form-control"
                    placeholder=""
                    v-model="screenData.selecteddatefrom"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Select Date To</label
                  >
                  <input
                   type= "date"
                    id="update-profile-form-13"
                    class="form-control"
                    placeholder=""
                    :min="screenData.selecteddatefrom"
                    v-model="screenData.selecteddateto"
                  />
                </div>

              </div>
              <div class="col-span-12 xl:col-span-6">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Select Kapan Type</label
                  >
                   <TomSelect
                    v-model="screenData.selectedkapantype"
                    :options="{
                      placeholder: 'Select Kapan Type',
                      loadingClass: 'loading',
                    }"
                    class="w-full">
                    <option value="">Select Kapan Type</option>
                    <option value="1">Manual</option>
                    <option value="2">Galaxy</option>

                    </TomSelect>
                </div>

                <div class="mt-3" v-if="screenData.selectedkapantype == 2">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Purity</label
                  >
                  <TomSelect
                v-model="screenData.kapan_purity"
                :options="{
                  placeholder: 'Select Purity',
                }"
                class="w-full"
              >
             <option value="">Select Purity </option>
             <option value="AAA">AAA</option>
             <option value="A">A</option>
             <option value="B">B</option>
             <option value="FANCY">FANCY</option>
              </TomSelect>
                </div>

                <div class="mt-3" v-if="screenData.selectedkapantype == 1">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Type</label
                  >
                  <TomSelect
                v-model="screenData.kapan_sub_type"
                :options="{
                  placeholder: 'Select Type',
                }"
                class="w-full"

              >
             <option value="">Select Type</option>
             <option value="Round">Round</option>
             <option value="Prince">Prince</option>
             <option value="SM">SM</option>
             <option value="4P">4P</option>
              </TomSelect>
                </div>

              </div>

              <div class="col-span-12 xl:col-span-12 mt-5">

                <div>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="generateReport"
            >
              Generate
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
              </div>
            </div>


      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
           <h2 class="text-lg font-medium mr-auto mt-5">Galaxy Transfer Report</h2>
          </div>

        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button> -->

        </div>
      </div>

      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide,reactive,onMounted ,toRefs} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { useStore } from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const store = useStore()

    const userDetails = computed(() => store.state.user.currentUser)

    const filter = reactive({
      tender_id: '',
      tender_buyers: [],
      tender_items: []
    })

    const screenData = reactive({
      selectedbuyers : [],
      genreport : "no",
      series_ar:[],
      buyers:[],
      selectedSeries:'',
      tenders:[],
      selectedTenderItems:[],
      tenderItems:[],
      selecteddatefrom:'',
      selecteddateto:'',
      selectedkapantype:'',
      kapan_sub_type:'',
      kapan_purity:''
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_galaxy_transfer_kapan_report',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{
          series_id:window.btoa(screenData.selectedSeries),
          kapan_type:screenData.selectedkapantype,
          purity:screenData.kapan_purity,
          kapan_sub_type:screenData.kapan_sub_type,
          issue_date_from:screenData.selecteddatefrom,
          issue_date_to:screenData.selecteddateto,
          },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [


          // For HTML table
          {
            title: 'Transaction No',

            columnMinWidth:"10%",
            columnMaxWidth:"10%",
            field: 'transaction_code',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,

          },

          {
            title: 'Series Name',
            columnMinWidth:"10%",
            columnMaxWidth:"10%",
            field: 'series_code',
             hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Kapan Code',
            columnMinWidth:"10%",
            columnMaxWidth:"10%",
            field: 'kapan_code',
            vertAlign: 'middle',
            print: false,
            download: true,


            // formatter(cell) {

            //   return `<div>
            //     <div class="font-medium whitespace-nowrap">${
            //       cell.getData().tender_name
            //     }</div>

            //   </div>`
            // }
          },
          {
            title: 'Kapan Type',
            columnMinWidth:"10%",
            columnMaxWidth:"10%",
            field: 'kapan_name',
            vertAlign: 'middle',
            print: false,
            download: true,


            // formatter(cell) {

            //   return `<div>
            //     <div class="font-medium whitespace-nowrap">${
            //       cell.getData().tender_name
            //     }</div>

            //   </div>`
            // }
          },
          {
            title: 'Pieces',

            columnMinWidth:"5%",
            columnMaxWidth:"5%",
            field: 'issue_pieces',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Issue Weight',

            columnMinWidth:"15%",
            columnMaxWidth:"15%",
            field: 'issue_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter:"money", formatterParams:{

              symbol:" Ct",
              symbolAfter:"p",
              precision:true,
          }
          },


          {
            title: 'Issue Date',
            columnMinWidth:"20%",
            columnMaxWidth:"20%",
            field: 'created_at',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {

              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().created_at
                }
                </div>

              </div>`
            }
          },



          // For print format

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

     const getSeries = () => {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          screenData.series_ar = data.items;

        })
        .catch(_error => {

        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      initTabulator()
      tabulator.value.setFilter(screenData.selectedTenders, screenData.selectedTenderItems, screenData.selectedbuyers)
    }
    //
    const generateReport = () => {
      initTabulator()
      tabulator.value.setFilter(screenData.selectedTenders, screenData.selectedTenderItems, screenData.selectedbuyers)
    }

    // On reset filter
    const onResetFilter = () => {

      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'tender_list.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'tender_list.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'tender_list.xlsx', {
        sheetName: 'Tenders'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }


    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      checkPermission()
      reInitOnResizeWindow()
      getSeries()
      // screenData.selecteddatefrom = moment().startOf('month').format('YYYY-MM-DD')
      screenData.selecteddateto = moment().format('YYYY-MM-DD')
      //generateReport()
    })

    return {
      tableRef,
      filter,
      screenData,userDetails,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,

      generateReport
    }
  }
})
</script>
