<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Transaction View</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Transaction Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

             <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal-receive"
          class="btn btn-primary shadow-md mr-2"
          v-if="cr_status != 2 && cr_status != 3 && issue_type == 1 && process_id != 18 && process_id != 19 && process_id != 20 && process_id != 2 && process_id != 26"
          >Take Return</a
        >
            </div>
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Transaction No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ transaction_code }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Bunch Type : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> 
                    <span v-if="bunch_type == 1">Kapan</span>
                    <span v-if="bunch_type == 2">Series</span>
                    </label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    > <span v-if="bunch_type == 1">Kapan No :</span>
                    <span v-if="bunch_type == 2">Series No :</span>&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> 
                    <span v-if="bunch_type == 1">{{ kapan_code }}</span>
                    <span v-if="bunch_type == 2">{{ series_code }}</span>
                    <span v-if="bunch_type == 2 && process_id == 27"> ( {{ bunch_code }} )</span>
                    </label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(issue_weight) }} (Ct)</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Pieces : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ issue_piece }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Receive Weight [Ghat]: &nbsp;</label
                  >
                  <label for="update-profile-form-6" class="form-label" v-if="process_id != 26 && process_id != 2"> {{ $h.myFloat(receive_weight) }} (Ct) [ {{ $h.myFloat($h.myFloat(issue_weight) - $h.myFloat(receive_weight))}} (Ct) ] </label>
                  <label for="update-profile-form-6" class="form-label" v-if="process_id == 26 || process_id == 2"> {{ $h.myFloat(total_return_weight_details) }} (Ct) [ {{ $h.myFloat(total_return_ghat_details)}} (Ct) ] </label>
                </div>

                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Receive Pieces : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ receive_pieces }}</label
                  >
                </div>

                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue For : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"><span v-if="issue_type == 1">Process</span>
                    <span v-if="issue_type == 2">Department Transfer</span></label
                  >
                </div>

              </div>
              <div class="col-span-4 xl:col-span-4">

                 <div class="" v-if='issue_type == 1'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Process Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ process_name }}</label
                  ><label for="update-profile-form-6" class="form-label" v-if="sawing_type == 1"> (Sawing)</label
                  ><label for="update-profile-form-6" class="form-label" v-if="sawing_type == 2"> (Crytal Sawing)</label
                  >
                </div>
                 <div class="mt-3" v-if='issue_type == 1'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Worker Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> 
                   
                   <span v-if="(process_id == 2 || process_id == 3 || process_id == 26 || process_id == 27 || process_id == 28)"> {{ karigar_manager_first_name }} {{ karigar_manager_last_name }} </span>
                   <span v-if="(process_id == 18 || process_id == 19 || process_id == 20)"> {{ planner_first_name }} {{ planner_last_name }} </span>
                   <span v-if="(process_id != 2 && process_id != 3 && process_id != 18 && process_id != 19 && process_id != 27 && process_id != 28)"> {{ karigar_first_name }} {{ karigar_last_name }} </span>
                     </label
                  >
                </div>
                <div class="mt-3" v-if='issue_type == 2'>
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Manager Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> 
                   
                   <span > {{ manager_first_name }} {{ manager_last_name }} </span>
                     </label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Date Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ created_at }}</label
                  >
                </div>
                 <div class="mt-3" v-if="issue_type == 1">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Return Date Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ receive_date }}</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Status : &nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">
                    <span v-if="cr_status == 1 && issue_type == 1" class="text-theme-9 font-bold">In Process</span>
                    <span v-if="cr_status == 2 && issue_type == 1" class="text-theme-6 font-bold">Returned</span>
                    <span v-if="cr_status == 3 && issue_type == 1" class="text-theme-7 font-bold">Merged</span>
                    <span v-if="trans_status == 1 && issue_type == 2" class="text-theme-7 font-bold">On Hold</span>
                    <span v-if="trans_status == 3 && issue_type == 2" class="text-theme-6 font-bold">Cancelled</span>
                    <span v-if="trans_status == 2 && issue_type == 2" class="text-theme-9 font-bold">Accepted</span>
                    </label>
                </div>

                
              </div>
            
            </div>
            <div class="p-5" v-if="(process_details.majuri_type == 2 || process_details.majuri_type == 3) && process_id != 2 && process_id != 26">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12">
                  <h4 class="font-medium text-base mr-auto">More Details</h4>
                <div class="border">
                <div class="grid grid-cols-12 sticky top-0 z-20 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
              
                  <div class="col-span-6 md:col-span-1 xl:col-span-1">
                    <p>Sr. No</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    <p>Range Name</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    <p>From</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    <p>To</p>
                  </div>
                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    <p>Classification</p>
                  </div>

                  <div class="col-span-6 md:col-span-2 xl:col-span-2">
                    <p>Issue Weight</p>
                  </div>
              
                </div>
        
                <div class="border" v-for="majuri_item in majuri_sepration" :key="majuri_item.id">
              
                  <div class="grid grid-cols-12">

                <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                  <label class="p-2 mt-2">{{majuri_item.id}}</label>
                </div> 
                <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                  <label class="p-2 mt-2 font-bold">{{majuri_item.name}}</label>
                  
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                  <label class="p-2 mt-2">{{majuri_item.from}}</label>
                  
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                  <label class="p-2 mt-2">{{majuri_item.to}}</label>
                
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                  <label class="p-2 mt-2">{{majuri_item.classification}}</label>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <label class="p-2 mt-2">{{majuri_item.issue_weight}}</label>
                  
                </div>
                
                
                  </div>
              </div>
            </div>
          </div>
              </div>
            </div>

            <div class="p-5" v-if="(process_details.majuri_type == 2 || process_details.majuri_type == 3) && issue_type == 1 && (process_id == 2 || process_id == 26)">
            <div
            class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Ghutan Issue Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

             <!-- <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal-receive-ghutan"
          class="btn btn-primary shadow-md mr-2"
          v-if="process_id == 2"
          >Take Return</a
        > -->
            </div>
          </div>
            <div class="grid grid-cols-12 gap-x-5">
              
              <div class="col-span-12">
                
               <div class="border">
              <div class="grid grid-cols-12 sticky top-0 z-50 pb-2" style="background-color:#fff;">
             
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">Sr. No</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">Size(Charni)</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p class="p-2 mt-2 font-bold">Weight(Issue)</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">Purity</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">Piece</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">R Piece</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p class="p-2 mt-2 font-bold">R Weight</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">Ghat</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">R Total</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p class="p-2 mt-2 font-bold">Status</p>
              </div>
             
               </div>

              <div class="border" v-for="field in ghutan_sepration" :key="field.id">
            
              <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
                <label class="p-2 mt-2 mb-2">{{field.id}}</label>
               </div> 
               <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
               <label class="p-2 mt-2 mb-2"> {{field.size}}</label>
                
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2 mt-3">
                <label class="p-2 mt-2 mb-2"> {{$h.myFloat(field.issue_weight)}}</label>
              </div>
              
              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
              <label class="p-2 mt-2 mb-2"> {{field.purity}} </label>
               </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
                <label class="p-2 mt-2 mb-2">{{field.piece}}</label>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
                <label class="p-2 mt-2 mb-2">{{field.total_return_pieces}}</label>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 mt-3">
                <label class="p-2 mt-2 mb-2">{{$h.myFloat(field.total_return_weight)}}</label>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
                <label class="p-2 mt-2 mb-2">{{$h.myFloat(field.total_ghat)}}</label>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3">
                <label class="p-2 mt-2 mb-2">{{$h.myFloat($h.myFloat(field.total_return_weight) + $h.myFloat(field.total_ghat))}}</label>
              </div>

              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3" v-if="field.sub_trans_status == 2">
              <label class="p-2 mt-2  mb-2 text-theme-6">Received</label>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3" v-if="field.sub_trans_status == 1">
              <label class="p-2 mt-2 mb-2 text-theme-9">Issued</label>
              </div>
             
            </div>
              </div>

              <div class="grid grid-cols-12 top-0 z-50 pb-2 mt-2" style="background-color:#fff;">
             
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="p-2 mt-2 font-bold">Total</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  
                </div>
                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p class="p-2 mt-2 font-bold">{{$h.myFloat(totalWeight_details)}}</p>
                </div>
               
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  
                </div>
                 <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="p-2 mt-2 font-bold">{{totalPcs_details}}</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="p-2 mt-2 font-bold">{{total_return_pcs_details}}</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p class="p-2 mt-2 font-bold">{{$h.myFloat(total_return_weight_details)}}</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="p-2 mt-2 font-bold">{{$h.myFloat(total_return_ghat_details)}}</p>
                </div>
                
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="p-2 mt-2 font-bold">{{$h.myFloat(total_return_weight_details + total_return_ghat_details)}}</p>
                </div>
             
              </div>
           </div>
          </div>
            
         
            </div>
          
            </div>
          </div>

          
        </div>

      </div>
    </div>

    <div class="intro-y box mt-10" v-if="return_show == true">
            <div class="p-5">
            <div
            class="flex flex-col sm:flex-row items-center pb-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Receive Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
             <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal-receive-ghutan"
          class="btn btn-primary shadow-md mr-2"
          v-if="(process_id == 2 || process_id == 26) && cr_status != 2"
          >Take Return</a
        ></div>
          </div>
          <div class="col-span-12 ">
                <div
          id="tabulatorkapan"
          ref="tableRefKapan"
          class="mt-5"
        ></div>
              </div>
            </div>
        </div>

 <div id="new-order-modal-receive" ref="new-order-modal-receive" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Take Return</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            
              <div class="col-span-12 xl:col-span-12">
                <div class="">
                  <label for="update-profile-form-9" class="form-label"
                    >Rough Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="receiveForm.receive_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Return Pieces</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="receiveForm.receive_pieces"
                    @keypress="$h.preventInvalidInputInt" onpaste="return false;"
                  />
                </div>
              </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitReturnRough">
              Save Details
            </button>
          </div>
        </div>
      </div>
  </div>
  
  <div id="new-order-modal-receive-ghutan" ref="new-order-modal-receive-ghutan" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Take Return</h2>
          </div>
          <div class="modal-body">
            
            <div class="grid grid-cols-12 gap-x-5">
              
              <div class="col-span-12">
                
               <div class="border">
               <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
             
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Sr. No</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Size</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Piece</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Weight(Issue)</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Purity</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Return Piece</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Return Weight</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Ghat</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Total</p>
              </div>
             
               </div>
               <div class="border" v-for="field in ghutan_sepration" :key="field.id">
            
                <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-2">{{field.id}}</label>
               </div> 
               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
               <label class="p-2 mt-2"> {{field.size}}</label>
                
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
              <label class="p-2 mt-2"> {{field.piece}} </label>
               </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-2"> {{$h.myFloat(field.issue_weight)}}</label>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
              <label class="p-2 mt-2"> {{field.purity}} </label>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-1">
                
              <input v-model="field.return_piece" type="text"
                    class="form-control" placeholder="No of Pcs" @keypress="$h.preventInvalidInputInt" @change="totalPackweight(2)" onpaste="return false;" :disabled="field.sub_trans_status == 2">
              </div>
              
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.return_weight" type="text"
                    class="form-control" placeholder="Weight(Carat)" @keypress="$h.preventInvalidInput" @change="totalPackweight(2)" onpaste="return false;" :disabled="field.sub_trans_status == 2">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.return_ghat" type="text"
                    class="form-control" placeholder="Weight(Carat)" @keypress="$h.preventInvalidInput" @change="totalPackweight(2)" onpaste="return false;" :disabled="field.sub_trans_status == 2">
              </div>

              <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3" v-if="$h.myFloat($h.myFloat(field.return_weight) + $h.myFloat(field.return_ghat)) <= $h.myFloat(field.issue_weight)">
                        <label class="p-2 mt-2  mb-2 text-theme-9">{{$h.myFloat($h.myFloat(field.return_weight) + $h.myFloat(field.return_ghat)) }}</label>
              </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1 mt-3" v-else>
                      <label class="p-2 mt-2 mb-2 text-theme-6">{{$h.myFloat($h.myFloat(field.return_weight) + $h.myFloat(field.return_ghat))}}</label>
                </div>
              </div>
              </div>
              <div class="grid grid-cols-12 top-0 z-50 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">
             
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="font-bold">Total</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>-</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="font-bold">{{totalPcs}}</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p class="font-bold">{{$h.myFloat(totalWeight)}}</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>-</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="font-bold">{{total_return_pcs}}</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p class="font-bold">{{$h.myFloat(total_return_weight)}}</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p class="font-bold">{{$h.myFloat(total_return_ghat)}}</p>
                </div>
                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p class="font-bold">{{$h.myFloat(total_return_weight + total_return_ghat)}}</p>
                </div>
             
               </div>
      
              </div>
            </div>
            </div>
          
            
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitReturnRough">
              Save Details
            </button>
          </div>
        </div>
      </div>
  </div>
  

  <div class="intro-y box mt-10" v-if="assort_show == true">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Assort Details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
            <!-- <button
              class="btn btn-primary shadow-md mr-2"
              @click="updateAssortment()"
              >Update Assortment</button
            > -->
            
            <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#new-order-modal-assort"
                class="btn btn-primary shadow-md mr-2"
                v-if="cr_status == 2"
                >Add Assortment</a
              >
            </div>
          </div>
         
          
        <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <div
                  id="tableRef"
                  ref="tableRef"
                  class="mt-5"
                ></div>
              </div>
            </div>
        </div>
    </div>

    <div id="new-order-modal-assort" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Assortment</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            
              <div class="col-span-12 xl:col-span-12">
                
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Assort Type</label
                  >
                  <TomSelect
                v-model="assortForm.assort_type"
                :options="{
                  placeholder: 'Select Assort Type',
                  
                }"
                class="w-full"
                
              >
             <option value="">Select Assort Type</option>
            <option v-for="assort in assorts" :key="assort.id" :value="assort.id">{{assort.assortment_name}}</option>
              </TomSelect>
                </div>

                 <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Weight</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="assortForm.weight"
                    @change="calculatePer"
                  />
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label
                  >
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="assortForm.total_pcs"
                  />
                </div>
              </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1">
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormAssort" :disabled="assortForm.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!assortForm.form_status"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">
                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
              
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import Tabulator from 'tabulator-tables'
import datetime from 'vuejs-datetimepicker';
import feather from 'feather-icons'
import { useVuelidate } from '@vuelidate/core'
import { helper as $h } from '@/utils/helper'
// const router = useRouter();
// const route = useRoute();

export default defineComponent({
  components: {
    
    datetime
  },
  props: {
    trans_id: {
      type: String,
      default: ""
    }
  },
  
    data() {
      return {
        tabulatorreturn:[],
        tabulatorkapan:[],
        majuri_sepration:[],
        ghutan_sepration:[],
        process_details:{
          process_name:'',
          majuri_type:'',
          majuri_list:[],
          majuri_fix_price:''
        },
        bunch_code :'',
        return_show: true,
        assort_show: true,
          bunch_type :'',
          created_at :'',
          total_assortment :0,
          transaction_code :'',
          series_code :'',
          kapan_code :'',
          bunch_code :'',
          process_id:'',
          sawing_type:'',
          karigar_first_name :'',
          karigar_last_name:'',
          karigar_manager_first_name:'',
          karigar_manager_last_name:'',
          planner_first_name:'',
          planner_last_name:'',
          manager_first_name:'',
          manager_last_name:'',
          process_name:'',
          issue_weight:'',
          issue_piece:'',
          receive_weight:'',
          receive_pieces:'',
          receive_date:'',
          cr_status:'',
          trans_status:'',
          issue_type:'',
          karigar_id:'',
          manager_id:'',
          kapan_id:'',
          series_id:'',
          issue_by:'',
        assorts:[],
        
        status: '1',

        receiveForm:{
          receive_weight:'',
          receive_pieces:''
        },
        assortForm:{
          form_status:false,
          assort_type:'',
          assort_id:'',
          assort_name:'',
          total_pcs:'',
          weight:'',
          weight_per:'',
          result_weight:'',
          result_per:''
        },
        loginVal: '',
        totalWeight:0,
        totalPcs:0,
        total_return_weight:0,
        total_return_ghat:0,
        total_return_pcs:0,
        totalWeight_details:0,
        totalPcs_details:0,
        total_return_weight_details:0,
        total_return_ghat_details:0,
        total_return_pcs_details:0,
        
        req: axios.create({
          baseUrl: BASE_URL
        })
      }
    },
 
    computed: {
       ...mapGetters({
      currentUser: "currentUser",
    }),
    },
    mounted(){
    this.getRoughDetails(this.trans_id);
    this.getAssortType();
    this.totalPackweight(1);
    },

    methods: {
    async getRoughDetails(trans_id) {
      let _this = this;
      const params = {
        trans_id: trans_id
      };
      let promise = axios({
        url: "api/get_kapan_issue_trans",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let rough_detail = data.item_details;
          this.bunch_code = rough_detail.bunch_code;
          this.bunch_type = rough_detail.bunch_type;
          this.created_at = rough_detail.created_at;
          this.receive_date = rough_detail.receive_date;
          this.transaction_code= rough_detail.transaction_code;
          this.series_code= rough_detail.series_code;
          this.series_id= rough_detail.series_id;
          this.kapan_code= rough_detail.kapan_code;
          this.bunch_code= rough_detail.bunch_code;
          this.kapan_id= rough_detail.kapan_id;
          this.karigar_first_name = rough_detail.karigar_first_name;
          this.karigar_last_name = rough_detail.karigar_last_name;
          this.manager_first_name = rough_detail.manager_first_name;
          this.manager_last_name = rough_detail.manager_last_name;
          this.karigar_manager_last_name = rough_detail.karigar_manager_last_name;
          this.karigar_manager_first_name = rough_detail.karigar_manager_first_name;
          this.planner_first_name = rough_detail.planner_first_name;
          this.planner_last_name = rough_detail.planner_last_name;
          this.process_name = rough_detail.process_name;
          this.process_id = rough_detail.process_id;
          this.sawing_type = rough_detail.sawing_type;
          this.issue_weight = rough_detail.issue_weight;
          this.issue_piece = rough_detail.issue_pieces;
          this.receive_pieces = rough_detail.receive_pieces;
          this.receive_weight = rough_detail.receive_weight;
          this.total_assortment = rough_detail.total_assortment;
          this.cr_status = rough_detail.status;
          this.issue_by = rough_detail.issue_by;
          this.trans_status = rough_detail.trans_status;
          this.issue_type = rough_detail.issue_type;
          this.karigar_id = rough_detail.karigar_id;
          this.manager_id = rough_detail.manager_id;
          this.manager_id = rough_detail.manager_id;
          this.majuri_sepration = rough_detail.majuri_sepration;
          this.ghutan_sepration = rough_detail.ghutan_issue_details;
          this.process_details.majuri_type = rough_detail.majuri_type;
          if(rough_detail.process_id == 2){
           // _this.assortPrepare();
          }
          if(this.issue_type == 1){
            _this.kapanPrepare();
          }else{
            this.return_show = false;
          }
          if((this.issue_type == 1 && this.cr_status == 2) || (this.issue_type == 2 && this.trans_status == 2)){
            _this.assortPrepare();
          }else{
            this.assort_show = false;
          }
          _this.totalPackweight(1);

        })
        .catch(_error => {
          
        });
    },

    async getAssortType() {
      let _this = this;
      const params = {
        
      };
      let promise = axios({
        url: "api/dp_assortment",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          this.assorts = data.items;

        })
        .catch(_error => {
          
        });
    },
  
    submitReturnRough(){
      let _this = this;
      if(this.process_id != 2 && this.process_id != 26){
        if( this.$h.myFloat(this.receiveForm.receive_weight) > this.$h.myFloat(this.issue_weight)){
          this.$swal("Warning!", "Can not return more weight than issue rough weight", "warning");
          return;
        }
        if(this.receiveForm.receive_weight == 0){
          this.$swal("Warning!", "Can not return zero weight!", "warning");
          return;
        }
      }

      if(this.process_id == 2 || this.process_id == 26){
        //Form validation
        var ghutan_return_total = 0;
        var ghutan_return_pieces = 0;
        if(this.ghutan_sepration != null){

        }
        var i = this.ghutan_sepration.length;
        while(i--){

            var return_piece = 0;
            var return_weight = 0;
            var return_ghat = 0;
            var total_ghat = 0;
            var total_return_weight = 0;
            var total_piece = 0;
            var issue_weight = 0;
            var total_return_pieces = 0;

            if(this.ghutan_sepration[i]['sub_trans_status'] != 2){
            
            if(this.ghutan_sepration[i]['total_ghat'] != '' && this.ghutan_sepration[i]['total_ghat'] != null){
              total_ghat = this.$h.myFloat(this.ghutan_sepration[i]['total_ghat']);
            }

            if(this.ghutan_sepration[i]['total_return_weight'] != '' && this.ghutan_sepration[i]['total_return_weight'] != null){
              total_return_weight = this.$h.myFloat(this.ghutan_sepration[i]['total_return_weight']);
              
            }
             if(this.ghutan_sepration[i]['total_return_pieces'] != '' && this.ghutan_sepration[i]['total_return_pieces'] != null){
              total_return_pieces = this.$h.myInt(this.ghutan_sepration[i]['total_return_pieces']);
            }

            if(this.ghutan_sepration[i]['piece'] != '' && this.ghutan_sepration[i]['piece'] != null){
              total_piece = this.$h.myInt(this.ghutan_sepration[i]['piece']);
            }

            if(this.ghutan_sepration[i]['issue_weight'] != '' && this.ghutan_sepration[i]['issue_weight'] != null){
              issue_weight =this.$h.myFloat(this.ghutan_sepration[i]['issue_weight']);
            }

            if(this.ghutan_sepration[i]['return_piece'] != '' && this.ghutan_sepration[i]['return_piece'] != null){
              return_piece = this.$h.myInt(this.ghutan_sepration[i]['return_piece']);
            }
            if(this.ghutan_sepration[i]['return_weight'] != '' && this.ghutan_sepration[i]['return_weight'] != null){
              return_weight = this.$h.myFloat(this.ghutan_sepration[i]['return_weight']);
            }
            if(this.ghutan_sepration[i]['return_ghat'] != '' && this.ghutan_sepration[i]['return_ghat'] != null){
              return_ghat = this.$h.myFloat(this.ghutan_sepration[i]['return_ghat']);
            }
            console.log("-------------------------------------");
            console.log("return_piece " + return_piece);
            console.log("return_weight " + return_weight);
            console.log("return_ghat " + return_ghat);
            console.log("issue_weight " + this.issue_weight);
            console.log("issue_weight part " + issue_weight);
            console.log("total_return_weight " + total_return_weight);
            console.log("total_ghat " +total_ghat);
            console.log("total_ghat " +total_ghat);
            console.log("-------------------------------------");
              if(return_piece != 0 || return_weight != 0){
                if(return_piece != 0 && return_weight != 0){
                    if(this.$h.myInt(return_piece) > this.$h.myInt(total_piece - total_return_pieces)){
                        this.$swal("Warning!", "Process Return Piece is heigher than issued", "warning");
                        return;
                    }
                    
                    if(this.$h.myFloat(return_weight + return_ghat) > this.$h.myFloat(issue_weight - total_return_weight - total_ghat)){
                        this.$swal("Warning!", "Process Return weight is heigher than issued", "warning");
                        return;
                    }
                    else{
                        ghutan_return_total += this.$h.myFloat(return_weight + return_ghat);
                        ghutan_return_pieces += this.$h.myInt(return_piece);
                    }
                }
                else{
                  this.$swal("Warning!", "Process Return details has empty", "warning");
                  return;
                }
              }
            }
        }
        //console.log(this.issue_weight);
        console.log(ghutan_return_total);
        this.receiveForm.receive_weight = this.$h.myFloat(ghutan_return_total);
        this.receiveForm.receive_pieces = this.$h.myInt(ghutan_return_pieces);
        if(this.receiveForm.receive_weight == 0){
          this.$swal("Warning!", "Can not return zero weight!", "warning");
          return;
        }
      }
      
      const data = {
        trans_id: this.trans_id,
        rough_weight: this.receiveForm.receive_weight,
        rough_pieces: this.receiveForm.receive_pieces,
        process:this.process_id,
        ghutan_return: this.ghutan_sepration
      };
      
      this.$swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          
          let promise = axios({
            url: "api/return_transaction",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == 'error'){
                this.$swal("Error!", data.msg, "error");
              } else{
                this.$router.go();
              } 
              // this.$swal("Success!", "Item Returned to Manager.", "success");
              // _this.getRoughDetails(this.trans_id);
              // _this.totalPackweight(1);
              // this.assort_show = true;
              
            })
            .catch(_error => {
              this.$swal("Error!", "Something went wrong!", "error");
              this.$router.go();
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      });
    },

    totalPackweight(type){
      let total_weight = 0;
      let total_pcs = 0;
      let return_pcs = 0;
      let return_weight = 0;
      let return_ghat = 0;

      var i = this.ghutan_sepration.length;
        while(i--){
          
          if(this.ghutan_sepration[i]['issue_weight'] != '' && this.ghutan_sepration[i]['issue_weight'] != undefined){
              total_weight += this.$h.myFloat(this.ghutan_sepration[i]['issue_weight']);
          }
          
          if(this.ghutan_sepration[i]['piece'] != '' && this.ghutan_sepration[i]['piece'] != undefined){
              total_pcs += parseInt(this.ghutan_sepration[i]['piece']);
              
          }
          if(this.ghutan_sepration[i]['return_piece'] != '' && this.ghutan_sepration[i]['return_piece'] != undefined){
              return_pcs += parseInt(this.ghutan_sepration[i]['return_piece']);
              if(this.ghutan_sepration[i]['return_piece'] == this.ghutan_sepration[i]['piece'])
              {
                if(this.ghutan_sepration[i]['return_weight'] != '' && this.ghutan_sepration[i]['return_weight'] != undefined){
                    var cr_return_ghat = this.$h.myFloat(this.$h.myFloat(this.ghutan_sepration[i]['issue_weight']) - this.$h.myFloat(this.ghutan_sepration[i]['return_weight']));
                    if(this.ghutan_sepration[i]['return_ghat'] != '' && this.ghutan_sepration[i]['return_ghat'] != undefined){
                        
                    }else{
                      if(cr_return_ghat >= 0){
                        this.ghutan_sepration[i]['return_ghat'] = cr_return_ghat;
                      }
                    }
                }
              }
          }
          if(this.ghutan_sepration[i]['return_weight'] != '' && this.ghutan_sepration[i]['return_weight'] != undefined){
              return_weight += this.$h.myFloat(this.ghutan_sepration[i]['return_weight']);
          }
          if(this.ghutan_sepration[i]['return_ghat'] != '' && this.ghutan_sepration[i]['return_ghat'] != undefined){
              return_ghat += this.$h.myFloat(this.ghutan_sepration[i]['return_ghat']);
          }
        }
        if(!isNaN(total_weight)){
          this.totalWeight = this.$h.myFloat(total_weight);
        }if(!isNaN(total_pcs)){
          this.totalPcs = Number(parseInt(total_pcs));
        }if(!isNaN(return_pcs)){
          this.total_return_pcs = Number(parseInt(return_pcs));
        }if(!isNaN(return_weight)){
          this.total_return_weight = this.$h.myFloat(return_weight);
        }if(!isNaN(return_ghat)){
          this.total_return_ghat = this.$h.myFloat(return_ghat);
        }
        if(type == 1){
          if(!isNaN(total_weight)){
          this.totalWeight_details = this.$h.myFloat(total_weight);
        }if(!isNaN(total_pcs)){
          this.totalPcs_details = Number(parseInt(total_pcs));
        }if(!isNaN(return_pcs)){
          this.total_return_pcs_details = Number(parseInt(return_pcs));
        }if(!isNaN(return_weight)){
          this.total_return_weight_details = this.$h.myFloat(return_weight);
        }if(!isNaN(return_ghat)){
          this.total_return_ghat_details = this.$h.myFloat(return_ghat);
        }
        }
    },
 
    kapanPrepare(){
      let _this = this;
      this.tabulatorkapan = new Tabulator(this.$refs['tableRefKapan'], {
        ajaxURL: BASE_URL + '/api/list_ghutan_return',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{tran_no : this.trans_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Transaction No',
            
            
            field: 'transaction_code',
            vertAlign: 'middle',
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              router.push("/app/app-process/kapan/issuetransaction/" + window.btoa(cell.getData().id));
          }
          },
          {
            title: 'Kapan/Series No',
            field: 'kapan_code',
            formatter(cell) {
              if(cell.getData().bunch_type == 1){
              return cell.getData().kapan_code
              }else{
                return cell.getData().series_code
              }
            }
          },{
            title: 'Process',
            field: 'process_name',
          },
          {
            title: 'Worker',
            field: 'karigar_first_name',
          },{
            title: 'Receive Weight',
            field: 'receive_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {              
              return cell.getData().receive_weight + " Ct";
            }
          },{
            title: 'Return Date',
            field: 'created_at',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            field: 'status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().status == "1"){
              return `<div class="flex items-center lg:justify-center text-theme-9">
                In progress
              </div>`
              }else{
                return `<div class="flex items-center lg:justify-center text-theme-6">
                Received
              </div>`
              }
            }
          },
          
          
        ],
        renderComplete() {
         
        }
      })
    },

    async submitFormAssort(){
      let _this = this;

      if(this.assortForm.weight < 0 ){
        this.$swal("Error!", "Assortment Weight can not be greater than in hand weight", "error");
        return;
      }

      this.assortForm.form_status = true;
      const data = {
        trans_id : this.trans_id,
        series_id: this.series_id,
        kapan_id: this.kapan_id,
        bunch_type: this.bunch_type,
        assort_type: this.assortForm.assort_type,
        total_pcs: this.assortForm.total_pcs,
        weight: this.assortForm.weight,
        weight_per: this.assortForm.weight_per,
        result_weight: this.assortForm.result_weight,
        result_per: this.assortForm.result_per,
        id:this.assortForm.assort_id,
      };
      let url_post = '';
      if(this.assortForm.assort_id != ''){
        url_post = "api/edit_rough_assort_transaction"
      }else{
         url_post = "api/add_rough_assort_transaction"
      }
  
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         Toastify({
          node: cash('#success-notification-content-update')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.go();
        }, 500)
        })
        .catch((_error) => {
          this.assortForm.form_status = false;
          return [];
        });
    },

    assortPrepare(){
      this.tabulator = new Tabulator(this.$refs['tableRef'], {
        ajaxURL: BASE_URL + '/api/list_rough_assort_by_transaction',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{trans_id:this.trans_id},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Assort No',
            field: 'assort_no',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Assort Type',
            minWidth: 200,
            responsive: 0,
            field: 'assortment_name',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Series No',
            field: 'series_no',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Weight',
            field: 'weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Weight(%)',
            field: 'weight_per',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().active_status == "Active"){
              return `<div class="flex items-center lg:justify-center text-theme-9">
                Active
              </div>`
              }else{
                return `<div class="flex items-center lg:justify-center text-theme-6">
                Inactive
              </div>`
              }
            }
          },
        ],
        renderComplete() {
         
        }
      })
    },
    
  },
  
})
</script>
