<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto text-t">PA LS (Series) Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Series</label
            >
            <TomSelect
              v-model="screenData.series_id"
              :options="{
                placeholder: 'Select Series',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Series'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Series</option>
            <option v-for="series in pagedata.serieses" :key="series.id" :value="series.id">{{series.series_code}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3" v-if="userDetails.user_type == 1 || userDetails.user_type == 27">
          <div>
            <label for="update-profile-form-9" class="form-label py-1"
              >Select Manager</label
            >
            <TomSelect
              v-model="screenData.manager_id"
              :options="{
                placeholder: 'Select PA LS Manager',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'LS Managers'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select manager</option>
            <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.user_id">{{manager.first_name}}</option>
            </TomSelect>
          </div>


        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-9" class="form-label py-1"
              >Select Karigar</label
            >
            <TomSelect
              v-model="screenData.karigar_id"
              :options="{
                placeholder: 'Select PA LS karigar',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'LS Karigar'
                  }
                }
              }"
              class="w-full"
            >
            <option value="0">Select Karigar</option>
            <option v-for="karigar in pagedata.employees" :key="karigar.id" :value="karigar.id">{{karigar.first_name}} {{ karigar.last_name }}</option>
            </TomSelect>
          </div>


        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div v-if="screenData.issue_pending">
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full" disabled
              >
              <option value="0">Issue</option>
              <option value="1">In Process</option>
              <option value="2">Received</option>
              </TomSelect>
            </div>
          <div v-else>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="screenData.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Issue</option>
              <option value="1">In Process</option>
              <option value="2">Received</option>
              </TomSelect>
            </div>


        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
        <div>
          <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
            >Select Start Date</label
          ><input
            class="show-code form-check-switch mr-0 ml-3"
            type="checkbox" v-model="screenData.issue_pending"
          /></div>
          <input
            type= "date"
            id="update-profile-form-13"
            :disabled="screenData.issue_pending"
            class="form-control"
            placeholder=""
            v-model="screenData.issue_date"
          />
        </div>

        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="screenData.issue_pending"
              v-model="screenData.receive_date"
            />
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div class="mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="getTransactionDetails"
            >
            Generate
            </button>

          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

        <div class="mt-10">

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-20"
            @click="onResetFilter"
          >
            Reset
          </button>

        </div>
        </div>
        <div class="col-span-2 md:col-span-1" v-if="pagedata.transactions.length > 0">

        <div class="mt-10">

          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-primary w-full sm:w-20"
            @click="printReportDetails"
          >
            Print
          </button>
        </div>
        </div>
      </div>


      <!-- BEgin: Process Report Start-->
      <div id="responsive-table" class="p-5" v-if="pagedata.loading">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Records</div>
          </div>
          </div>
        </div>
      </div>

      <div class="mt-10" v-else>

        <div v-if="pagedata.genreport == 'gen'">
          <!-- BEgin: Process Report Start-->
          <div class="scrollbar-hidden" id="report-details" v-if="pagedata.transactions.length > 0">
            <div class="intro-y col-span-12 overflow-auto" id="report-details">
              <table class="mt-3 w-full border-2 shadow-md">
              <thead>
                <tr class="border-b bg-gray-500 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Total</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Wt</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Pieces</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Piece</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Ghat</th>
                  </tr>
              </thead>
                <tbody>
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <th class="py-2 border-b text-xs lg:text-md text-center"></th>
                    <th class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_weight ? $h.myFloat(pagedata.total.total_issue_weight) : '0' }} Ct</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_issue_pieces ? pagedata.total.total_issue_pieces : '-' }}</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_receive_weight ? $h.myFloat(pagedata.total.total_receive_weight) : '0' }} Ct</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_receive_pieces ? pagedata.total.total_receive_pieces : '-' }}</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center">{{ pagedata.total.total_loss_weight ? $h.myFloat(pagedata.total.total_loss_weight) : '0' }} Ct</th>
                  </tr>

                </tbody>
              </table>
              <table class="mt-3 w-full border-2" v-for="(item,index) in pagedata.transactions" :key="index">
                <tbody>
                  <tr class="border-b bg-gray-400 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ index + 1 }}</td>
                    <th class="py-2 border-b text-xs lg:text-md text-center" colspan="1">Date</th>
                    <td class="py-2 border-b text-xs lg:text-md text-center" colspan="1">{{ item.date }}</td>
                    <th class="py-2 border-b text-xs lg:text-md text-center" colspan="1"></th>
                    <td class="py-2 border-b text-xs lg:text-md text-center" colspan="1"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <!-- <td class="py-2 border-b text-xs lg:text-md text-center"></td> -->
                  </tr>
                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Sr.</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Bunch Code</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">I Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">I Piece</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Weight</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Piece</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Lose</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                    <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th> -->
                  </tr>
                  <tr class="border-b" v-for="(single_item,ind) in item.item_details" :key="ind">
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ ind + 1 }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.bunch_code }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0' }} Ct</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.issue_pieces ? single_item.issue_pieces : '0' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.receive_weight ? $h.myFloat(single_item.receive_weight) : '0' }} Ct</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.receive_pieces ? single_item.receive_pieces : '0' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ single_item.loss_weight ? $h.myFloat(single_item.loss_weight) : '0' }} Ct</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"><div class="text-theme-1 text-center" v-if="single_item.status == 1">In Process</div><div v-else class="text-theme-6 text-center">Received</div></td>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.item_issue_weight ? $h.myFloat(item.item_issue_weight) : '0' }} Ct</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.item_issue_pieces ? item.item_issue_pieces : '0' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.item_receive_weight ? $h.myFloat(item.item_receive_weight) : '0' }} Ct</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{ item.item_receive_pieces ?  item.item_receive_pieces : '0' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center">{{  item.item_receive_ghat ? $h.myFloat(item.item_receive_ghat) : '0' }} Ct</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center"></td>
                    <!-- <td class="py-2 border-b text-xs lg:text-md text-center"></td> -->
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div class="text-md font-medium mr-auto text-t text-center py-2" v-else>Records Not Found</div>
        </div>

      </div>
      <!-- END: HTML Table Data -->
      </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive,onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import store from '@/store'
import moment from 'moment'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      transactions: [],
      total: [],
      keys: [],
      managers: [],
      employees: [],
      serieses: [],
      process: 27,
      genreport: 'no',
      loading: false
    })
    const screenData = reactive({
      manager_id : '0',
      series_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending :true
    })
    const printData = reactive({
      manager_id : '0',
      series_id:'0',
      karigar_id:'0',
      status:'0',
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      issue_pending :true
    })

    const getRoughDetails = () => {
      let promise = axios({
        url: "api/get_ghutan_summarty_series",
        method: "post",
        baseURL: BASE_URL,
        data: { processes: [pagedata.process] },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.serieses = data.series

        })
        .catch(_error => {

        });
    }
    const getEmployees = () => {
      let promise = axios({
        url: "api/dp_karigar_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: { process: pagedata.process },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.employees = data.items

        })
        .catch(_error => {

        });
    }
    const getManagers = () => {
      let promise = axios({
        url: "api/dp_manager_by_process",
        method: "post",
        baseURL: BASE_URL,
        data: { process: pagedata.process },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.managers = data.items

        })
        .catch(_error => {

        });
    }
    const getTransactionDetails = () => {

      printData.manager_id = screenData.manager_id
      printData.series_id = screenData.series_id
      printData.karigar_id = screenData.karigar_id
      printData.status = screenData.status
      printData.issue_date = screenData.issue_date
      printData.receive_date = screenData.receive_date
      printData.issue_pending = screenData.issue_pending
      pagedata.loading = true
      let params = {
        manager_id : screenData.manager_id == '0' ? '' : window.btoa(screenData.manager_id),
        karigar_id : screenData.karigar_id == '0' ? '' : window.btoa(screenData.karigar_id),
        series_id : screenData.series_id == '0' ? '' : window.btoa(screenData.series_id),
        status : window.btoa(screenData.status),
        issue_date : screenData.issue_date,
        receive_date : screenData.receive_date,
        issue_pending : screenData.issue_pending,
        process_id: window.btoa(pagedata.process)
      }
      let promise = axios({
        url: "api/get_ls_pa_records",
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.transactions = data.lists
          pagedata.total = data.total
          pagedata.genreport = 'gen'
          pagedata.loading = false
        })
        .catch(_error => {

        });
    }
    function printReportDetails(){
      let report_type = 'pa-ls-series'

      let params = {
        manager_id : printData.manager_id == '0' ? '' : window.btoa(printData.manager_id),
        karigar_id : printData.karigar_id == '0' ? '' : window.btoa(printData.karigar_id),
        series_id : printData.series_id == '0' ? '' : window.btoa(printData.series_id),
        status : window.btoa(printData.status),
        issue_date : printData.issue_date,
        receive_date : printData.receive_date,
        issue_pending : printData.issue_pending,
        process_id: window.btoa(pagedata.process),
        print_report_name: report_type,
        filter_details: printData,

      }
      let promise = axios({
        url: "api/set_ls_pa_records",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id
          const report_name = data.report_name

          let url = BASE_URL + '/' + report_name + '?id=' + window.btoa(report_id)
          window.open(url, '_blank');

        })
        .catch(_error => {

        });
      // add_report_details
    }

    const onResetFilter = () => {
      screenData.manager_id = '0'
      screenData.karigar_id = '0'
      screenData.status = '0'
      screenData.series_id = '0'
      screenData.issue_pending = true
      pagedata.genreport = 'no'
      pagedata.transactions = []
    }

    const checkPermission = () => {
      if(userDetails.value.id == 8){
        router.push("/app/app-masters");
      }
    }

    onMounted(() => {
      checkPermission()
      getRoughDetails()
      getEmployees()
      if(userDetails.value.user_type == 1) {
        getManagers()
      }
      screenData.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      screenData.receive_date = moment().format('YYYY-MM-DD')

    })

    return {
      pagedata,
      screenData,
      userDetails,
      getTransactionDetails,
      onResetFilter,
      printReportDetails
    }
  }
})
</script>
