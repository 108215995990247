<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Receive Lot/Packet</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="update-profile-form-6"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="scan_lot_no"
                    :class="{'form-control':true,'loading':scanload}"
                    :disabled = "scanload"
                    @change="scanAndAddPack"
                    autocomplete="off"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5" v-if="showreturndetails">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Packet No :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.packet_no}}</label
                  >
                </div>
                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Current Process :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.process_name}}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Lot weight :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.issue_weight}} Ct</label
                  >
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue To :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{  return_item.issue_to_name}}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue At :&nbsp; </label
                  >
                  <label for="update-profile-form-6 " class="form-label font-bold"
                    > {{ return_item.issue_date}}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4">

                 <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Return Weight :&nbsp;</label
                  >
                 <input
                    id="update-profile-form-6"
                    type="text"
                    placeholder="Weight in Ct"
                    v-model="return_item.return_weight"
                    :class="{'form-control':true,'loading':scanload}"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                    @change="calculateGhat"
                  />

                </div>

                 <div class="mt-2">

                  <label for="update-profile-form-6" class="form-label"
                    >Process Ghat :&nbsp;</label
                  >
                 <input
                    id="update-profile-form-6"
                    type="text"
                    placeholder="Weight in Ct"
                    v-model="return_item.total_ghat"
                    :class="{'form-control':true}"
                    readonly
                  />
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label"
                    >Rejection :&nbsp;</label
                  >
                 <input
                    id="update-profile-form-6"
                    type="text"
                    placeholder="Weight in Ct"
                    v-model="return_item.total_assortment_weight"
                    :class="{'form-control':true}"
                    readonly

                  />

                </div>


              </div>
              <div class="col-span-6 xl:col-span-4">

              </div>
            </div>

          <div
            class="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Assort Details</h2>
             <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">

            <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#new-order-modal-assort"
                class="btn btn-primary shadow-md mr-2"
                >Add Assortment</a
              >
            </div>
          </div>

        <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <div
                  id="tableRef"
                  ref="tableRef"
                  class="mt-2"
                ></div>
              </div>
            </div>
        </div>
         <div class="flex mt-4">
          <input
                    id="checkbox-switch-1"
                    class="form-check-input"
                    type="checkbox"
                    v-model="return_item.reject_pc"
                  />
                  <label class="form-check-label" for="checkbox-switch-1"
                    >Failed / Reject</label
                  >

                  <input
                    id="checkbox-switch-2"
                    class="form-check-input ml-5"
                    type="checkbox"
                    v-model="return_item.lost_pc"
                  />
                  <label class="form-check-label" for="checkbox-switch-2"
                    >Lost</label
                  >


         </div>

            <div class="flex mt-4">

              <button type="button" class="btn btn-primary mr-auto mt-3" v-if="enable_return" @click="submitReturnRough">
                Take Return
              </button>




        </div>
        </div>
        </div>
        <!-- END: Input -->

      </div>

    </div>
    <div id="new-order-modal-assort" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Assortment</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">

              <div class="col-span-12 xl:col-span-12">

                <div class="">
                  <label for="update-profile-form-7" class="form-label">Select Assort Type</label>
                  <TomSelect
                  v-model="assortForm.assort_id"
                  :options="{
                  placeholder: 'Select Assort Type'}"
                  class="w-full"
                  >
                  <option value="">Select Assort Type</option>
                  <option v-for="assort in assorts" :key="assort.id" :value="assort.id">{{assort.assortment_name}}</option>
                  </TomSelect>
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label">Weight</label>
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Carat"
                    v-model="assortForm.weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"/>
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-13" class="form-label"
                    >Total Pcs</label>
                  <input
                    id="update-profile-form-13"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="assortForm.total_pcs"
                    @keypress="$h.preventInvalidInputInt" onpaste="return false;"
                  />
                </div>
              </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1">
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitFormAssort" :disabled="assortForm.form_status">
              Save Details
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!assortForm.form_status"/>
            </button>
          </div>
        </div>
      </div>
    </div>
     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Packet Issue History</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
              <div
              id="tabulatorHisroty"
              ref="tabulatorHisroty"
              class="mt-2"
            ></div>
            </div>
            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapMutations, mapActions } from "vuex";
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tender_update_id : '',
      tabulatorHistory:[],
      tabulator:[],
      assorts:[],
      transaction_no: 'Auto',
      scanload:false,
      enable_return:false,
      scan_lot_no:'',
      status: '1',
      return_item:
      {
        packet_no:'',
        packet_id:'',
        process_id:'',
        trans_id:'',
        trans_type:'',
        sub_trans_id:'',
        last_packet_history_id:'',
        issue_weight:0,
        issue_date:'',
        process_name:'',
        issue_to_name:'',
        return_weight:'',
        total_ghat:0,
        total_assortment_weight:0,
        reject_pc:false,
        lost_pc:false,
      },
      assortForm:{
          form_status:false,
          assort_type:'',
          assort_id:'',
          assort_name:'',
          total_pcs:'',
          weight:'',
          weight_per:'',
          result_weight:'',
          result_per:''
        },
        totalassortments : 1,

        assortData:[

        ],
        return_progress:false,
        showreturndetails : false,
        loginVal: '',
        req: axios.create({
          baseUrl: BASE_URL
        })
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      userPolicies: "userPolicies",
    })
  },
  mounted(){
    this.getAssortType();
    //this.assortPrepare();
  },

  methods: {
    async getAssortType() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_assortment",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.assorts = data.items;
        })
        .catch(_error => {

        });
    },
    calculateGhat(){
      let _this = this;
      let total_assortment_weight_return = _this.$h.myFloat(_this.$h.myFloat(this.return_item.return_weight) + _this.$h.myFloat(this.return_item.total_assortment_weight));
      this.return_item.total_ghat = _this.$h.myFloat(this.return_item.issue_weight - total_assortment_weight_return);
    },

    async scanAndAddPack(){
      let _this = this;
      console.log(this.scan_lot_no);

      let codelength = this.scan_lot_no.trim().length;
      if(codelength > 0){
        this.scanload = true;

        //get lot details
        const data = {
        barcode: this.scan_lot_no,
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_scanned_packet_details_for_return"
        }else{
          url_post = "api/get_scanned_packet_details_for_return"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
          console.log(data);
          this.showreturndetails = true;
          let last_history_details = data.last_issue;
          let packet_details = data.packet_details;
          if(packet_details == "No"){
            _this.historyPrepare();
            this.scanload = false;
             this.enable_return = false;
            this.showreturndetails = false;
            return;
          }

          if(last_history_details.process_id != null){
            if(last_history_details.process_id == 18 || last_history_details.process_id == 19 || last_history_details.process_id == 20){
                  this.return_item.packet_no = packet_details.packet_code;
                  this.return_item.packet_id = last_history_details.packet_id;
                  this.return_item.process_id = last_history_details.process_id;
                  this.return_item.trans_id = last_history_details.transaction_id;
                  this.return_item.trans_type = last_history_details.transaction_type;
                  this.return_item.sub_trans_id = last_history_details.sub_trans_id;
                  this.return_item.last_packet_history_id = last_history_details.id;
                  this.return_item.issue_weight = _this.$h.myFloat(last_history_details.issue_weight);
                  this.return_item.issue_date = last_history_details.created_at;
                  this.return_item.process_name = last_history_details.process_name;

                  if((last_history_details.transaction_id == null || last_history_details.transaction_id == '') && ( last_history_details.process_id == null)){
                  this.return_item.issue_to_name =  last_history_details.manager_first_name;
                  }else if(last_history_details.process_id != '' && last_history_details.process_id != null){
                    if( last_history_details.process_id == 29 || last_history_details.process_id == 1  || last_history_details.process_id == 28 || last_history_details.process_id == 3){
                      this.return_item.issue_to_name = last_history_details.karigar_manager_first_name;
                    }if( last_history_details.process_id == 18 || last_history_details.process_id == 19  || last_history_details.process_id == 20){
                      this.return_item.issue_to_name = last_history_details.planner_first_name;
                      this.return_item.return_weight = _this.$h.myFloat(last_history_details.issue_weight);
                    }else{
                      this.return_item.issue_to_name =  last_history_details.karigar_first_name;
                    }
                  }else{
                    this.return_item.issue_to_name =  last_history_details.manager_first_name;
                  }
                  this.enable_return = true;
                  this.showreturndetails = true;
            }
            if(last_history_details.process_id != 18 || last_history_details.process_id != 19 || last_history_details.process_id != 20 ){

              if(data.last_issue.issue_by == this.currentUser.id){
                  this.return_item.packet_no = packet_details.packet_code;
                  this.return_item.packet_id = last_history_details.packet_id;
                  this.return_item.process_id = last_history_details.process_id;
                  this.return_item.trans_id = last_history_details.transaction_id;
                  this.return_item.trans_type = last_history_details.transaction_type;
                  this.return_item.sub_trans_id = last_history_details.sub_trans_id;
                  this.return_item.last_packet_history_id = last_history_details.id;
                  this.return_item.issue_weight = _this.$h.myFloat(last_history_details.issue_weight);
                  this.return_item.issue_date = last_history_details.created_at;
                  this.return_item.process_name = last_history_details.process_name;

                  if((last_history_details.transaction_id == null || last_history_details.transaction_id == '') && ( last_history_details.process_id == null)){
                  this.return_item.issue_to_name =  last_history_details.manager_first_name;
                  }else if(last_history_details.process_id != '' && last_history_details.process_id != null){
                    if( last_history_details.process_id == 29 || last_history_details.process_id == 1  || last_history_details.process_id == 28 || last_history_details.process_id == 3){
                      this.return_item.issue_to_name = last_history_details.karigar_manager_first_name;
                    }if( last_history_details.process_id == 18 || last_history_details.process_id == 19  || last_history_details.process_id == 20){
                      this.return_item.issue_to_name = last_history_details.planner_first_name;
                      this.return_item.return_weight = _this.$h.myFloat(last_history_details.issue_weight);
                    }else{
                      this.return_item.issue_to_name =  last_history_details.karigar_first_name;
                    }
                  }else{
                    this.return_item.issue_to_name =  last_history_details.manager_first_name;
                  }

                  this.enable_return = true;
                  this.showreturndetails = true;

              }
              else{
                this.return_item.packet_no = '';
                this.return_item.packet_id = '';
                this.return_item.process_id = '';
                this.return_item.sub_trans_id = '';
                this.return_item.last_packet_history_id ='';
                this.return_item.issue_weight = '';
                this.return_item.issue_date = '';
                this.return_item.process_name = '';
                this.return_item.issue_to_name = '';
                this.enable_return = false;
                this.showreturndetails = false;
              }
            }
          }else{
            this.return_item.packet_no = '';
            this.return_item.packet_id = '';
            this.return_item.process_id = '';
            this.return_item.sub_trans_id = '';
            this.return_item.last_packet_history_id ='';
            this.return_item.issue_weight = '';
            this.return_item.issue_date = '';
            this.return_item.process_name = '';
            this.return_item.issue_to_name = '';
            this.enable_return = false;
            this.showreturndetails = false;
          }

          _this.historyPrepare();
          this.scanload = false;
          })
          .catch((_error) => {
            this.scanload = false;

            return [];
          });

      }

    },

    historyPrepare(){
      let _this = this;
      this.tabulatorHistory = new Tabulator(this.$refs['tabulatorHisroty'], {
        ajaxURL: BASE_URL + '/api/list_packet_history',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams:{barcode:this.scan_lot_no},
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Transaction No',
            field: 'transaction_code_kapan',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              //e - the click event object
              //cell - cell component
              if(cell.getData().transaction_type == 1){
                return cell.getData().transaction_code_kapan;
              }else if(cell.getData().transaction_type == 2){
                return cell.getData().transaction_code_packet;
              }else if(cell.getData().transaction_type == 3){
                return cell.getData().transaction_code_packet_received;
              }else{
                return "-";
              }
          }
          },
          {
            title: 'Process',
            minWidth: 100,
            field: 'process_name',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              //e - the click event object
              //cell - cell component
              if(cell.getData().return_record == 1){
                return "Received";
              }else if((cell.getData().transaction_id == null || cell.getData().transaction_id == '') && ( cell.getData().process_id == null)){
                return "Created";
              }else if(cell.getData().process_id != '' && cell.getData().process_id != null){
                return cell.getData().process_name;
              }else{
                return "Transfered";
              }
          }

          },
          {
            title: 'Issue To',
            minWidth: 100,
            field: 'process_name',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              //e - the click event object
              //cell - cell component

              if((cell.getData().transaction_id == null || cell.getData().transaction_id == '') && ( cell.getData().process_id == null)){
                return cell.getData().manager_first_name;
              }
              else if(cell.getData().process_id != '' && cell.getData().process_id != null){
                if(cell.getData().karigar_type == 4){
                  return cell.getData().prop_first_name +`(`+ cell.getData().prop_company_name  +`)`;
                }
                else if(cell.getData().process_id == 29 || cell.getData().process_id == 1 || cell.getData().process_id == 27 || cell.getData().process_id == 28 || cell.getData().process_id == 3 || cell.getData().process_id == 31){
                  return cell.getData().karigar_manager_first_name;
                }else if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id ==  20){
                  return cell.getData().planner_first_name;
                }else if(cell.getData().process_id == 32){
                  return cell.getData().pp_first_name;
                }else if([37,38,40,41,42].includes(cell.getData().process_id)){
                    return cell.getData().prop_first_name +`(`+cell.getData().prop_company_name +`)`;
                  }else{
                  return cell.getData().karigar_first_name;
                }
              }else{
                return cell.getData().manager_first_name;
              }
          }

          },
          {
            title: 'Weight(I)',
            minWidth: 100,
            field: 'issue_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              //return `<div>`+ Number(cell.getData().kapan_weight) + ` Ct<br>` + Number(cell.getData().total_lot_weight) + `Ct </div>`;
              return _this.$h.myFloat(cell.getData().issue_weight);
            }
          },{
            title: 'Weight(R)',
            field: 'received_weight',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return _this.$h.myFloat(cell.getData().received_weight);
            }
          },
          {
            title: 'Issue Date',
            minWidth: 100,
            field: 'created_at',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Return Date',
            minWidth: 100,
            field: 'return_date',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: '',
            minWidth: 80,
            field: '',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;
              // const a = cash(`<div class="flex lg:justify-center items-center">
              //   <span class="flex items-center" >
              //     <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
              //   </span>
              // </div>`)

              const a = '';


              cash(a).on('click', function(data_id) {
                // On click actions
                router.push("/purchase/update-rough-purchase/" + window.btoa(cell.getData().id));
              })

              return a[0]
            }
          },


        ],
        renderComplete() {

        }
      })
    },

    async submitReturnRough(){
      let _this = this;
      const data = {
        packet_details: this.return_item,
        assortment_details: this.assortData,
      };

      if( _this.$h.myFloat(this.return_item.return_weight) > _this.$h.myFloat(this.return_item.issue_weight)){
        this.$swal("Warning!", "Can not return more weight than issue rough weight", "warning");
        return;
      }
       if(_this.$h.myFloat(this.return_item.return_weight) == 0){
        this.$swal("Warning!", "Can not return zero weight!", "warning");
        return;
      }

      let total_assortment_weight_return = _this.$h.myFloat(_this.$h.myFloat(this.return_item.return_weight) + _this.$h.myFloat(this.return_item.total_assortment_weight));

      this.return_item.total_ghat = _this.$h.myFloat(this.return_item.issue_weight - total_assortment_weight_return);
      console.log("Ghat:" + this.return_item.total_ghat);
      if( total_assortment_weight_return > _this.$h.myFloat(this.return_item.issue_weight)){
        this.$swal("Warning!", "Total return weight and assortment weight is more than original issue weight!", "warning");
        return;
      }

      this.return_progress = true;
      this.$swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/return_transaction_packet_single",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                this.$swal.fire({
                  icon:'success',
                  title:"Success!",
                  text:data.message,
                  showConfirmButton: false,
                  timer:1500
                })
                this.scanAndAddPack()
                // this.$router.go();
              }else if(data.status == "error"){
                this.$swal("Error!", data.message , "error");
              }
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Success!", data.message, "success");
                this.scanAndAddPack()
        }
      });
    },
    async submitFormAssort(){
      let _this = this;
      let assort_id = this.assortForm.assort_id;
      console.log(assort_id);

      if( this.return_item.return_weight == ''){
        this.$swal("Warning!", "Please Enter Return weight first", "warning");
        return;
      }
      if( _this.$h.myFloat(this.return_item.return_weight) <= 0){
        this.$swal("Warning!", "Please Enter Return weight more than zero", "warning");
        return;
      }

      let total_assortment_weight_return = _this.$h.myFloat(_this.$h.myFloat(this.return_item.return_weight) + _this.$h.myFloat(this.return_item.total_assortment_weight) + _this.$h.myFloat(this.assortForm.weight));
      console.log(total_assortment_weight_return);
      if( total_assortment_weight_return > _this.$h.myFloat(this.return_item.issue_weight)){
        this.$swal("Warning!", "Total return weight and assortment weight is more than original issue weight!", "warning");
        return;
      }

      if( this.assortForm.assort_id == ''){
        this.$swal("Warning!", "Please Select Assortment Type", "warning");
        return;
      }
       if(this.assortForm.weight == ''){
        this.$swal("Warning!", "Please Enter Assortment Weight", "warning");
        return;
      }
      if(_this.$h.myFloat(this.assortForm.weight) <= 0){
        this.$swal("Warning!", "Assortment Weight can not be Zero", "warning");
        return;
      }

      //get assort details and add to array
      const params = {
        id:assort_id
      };
      let promise = axios({
        url: "api/details_assortment",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let assort_detail = data.item_details;
          this.assortData.push({
          id:this.totalassortments,
          assort_type: assort_detail.assortment_type,
          assort_type_name: assort_detail.assortment_type_name,
          assort_id:assort_detail.id,
          assort_name: assort_detail.assortment_name,
          total_pcs: this.assortForm.total_pcs,
          weight:this.assortForm.weight,
          weight_per:'',
          result_weight:'',
          result_per:''
        })
        _this.assortPrepare();
        _this.totalPackweight();
        _this.calculateGhat();

        this.assortForm.total_pcs = '';
        this.assortForm.weight = '';
        this.assortForm.assort_id = '';
        this.totalassortments++;

        cash('#new-order-modal-assort').modal('hide');
        })
        .catch(_error => {
          return [];
        });
    },

    totalPackweight(){
      let total_weight = 0;
      let _this = this;
      var i = this.assortData.length;
        while(i--){
          if(this.assortData[i]['weight'] != ''){
              total_weight += _this.$h.myFloat(this.assortData[i]['weight']);
          }
        }
        if(!isNaN(total_weight)){
          this.return_item.total_assortment_weight = total_weight;
        }
    },

    removeAssortmentField(id) {
      let _this = this;
        this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.assortData.length;
              while(i--){
              if(this.assortData[i]['id'] == id ){
                  this.assortData.splice(i,1);
              }
            }
             _this.assortPrepare();
            _this.totalPackweight();
          }
      });
    },

    assortPrepare(){
      let _this = this;
      this.tabulator = new Tabulator(this.$refs['tableRef'], {
       data:this.assortData,
        printAsHtml: true,
        printStyled: true,
        pagination: 'local',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader:true,
        headerSort: false,
        placeholder: 'Start Adding Assortment using +Add Assortment Button',
        columns: [
          // For HTML table
          {
            title: 'Assortment',
            field: 'assort_name',
            vertAlign: 'middle',
            print: false,
            download: true,
          },
          {
            title: 'Assort Type',
            minWidth: 200,
            responsive: 0,
            field: 'assort_type_name',
            vertAlign: 'middle',
            print: false,
            download: true,
          },

          {
            title: 'Weight',
            field: 'weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },

          {
            title: '',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {


                const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-danger btn-sm flex items-center mr-3">
                      Remove
                    </button>
                  </div>`)
                  cash(a).on('click', function() {
                    _this.removeAssortmentField(cell.getData().id);
                  })
                  return a[0]


            }
          },
        ],
        renderComplete() {

        }
      })
    },
  },

})
</script>
