<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Issue Series</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Issue Details</h2>

          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-8" class="form-label"
                    >Issue Series/Bunch</label>
                  <select id="update-profile-form-8" class="form-select" v-model="issue_block" @change="changeBlocktype">
                    <option value="1" selected>Kapan</option>
                    <option value="2" selected>Series</option>
                  </select>
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-8" class="form-label"
                    >Issue to</label
                  >
                  <select id="update-profile-form-8" class="form-select" v-model="issue_type">
                    <option value="1" selected :disabled="total_process == 0">Process</option>
                    <option value="2">Department Manager</option>
                  </select>
                </div>

                <div class="mt-3" v-if="issue_type == 1">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Process</label
                  >
                <TomSelect
                v-model="process"
                :options="{
                  placeholder: 'Select Process '
                }" class="w-full"
                @change="changeProcess">
                <option value="">Select Process</option>
                <option v-for="proces_item in process_ar" :key="proces_item.id" :value="proces_item.id">{{proces_item.process_name}}</option>
                </TomSelect>
                </div>

              <div class="mt-3" v-if="process == 1">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Sawing Type</label
                  >
                  <TomSelect
                v-model="sawing_type"
                :options="{
                  placeholder: 'Select Sawing Type',
                }"
                class="w-full"
              >
              <option value="">Select Sawing Type</option>
              <option value="1">Sawing</option>
              <option value="2">Crystal Sawing</option>
              </TomSelect>
            </div>

                <div class="mt-3" v-if="issue_block == 1">
                  <label for="update-profile-form-8" class="form-label"
                    >Rough Kapan</label
                  >
                  <TomSelect id="update-profile-form-8"
                  :options="{
                  placeholder: 'Select Kapan',
                }"
                class="w-full" v-model="selected_kapan"
                @change="changeKapan">
                <option>Select Kapan</option>
                    <option v-for="kapana_item in kapan_ar" :key="kapana_item.id" :value="kapana_item.id">{{kapana_item.kapan_code}}</option>
                  </TomSelect>
                </div>

                <div class="mt-3" v-if="issue_block == 1 && selected_kapan_type == 1">
                  <label for="update-profile-form-8" class="form-label"
                    >Lot Type</label
                  >
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Lot Type',
                }"
                @change="checkKapanIssue"
                class="w-full" v-model="selected_kapan_lot_type">
                    <option value="All">All</option>
                    <option value="Prince">Prince</option>
                    <option value="SM">SM</option>
                    <option value="4P">4P</option>
                  </TomSelect>
                </div>



                <div class="mt-3" v-if="issue_block == 2">
                  <label for="update-profile-form-8" class="form-label"
                    >Rough Series</label
                  >
                  <TomSelect id="update-profile-form-8" :options="{
                  placeholder: 'Select Series',

                }"
                class="w-full" v-model="selected_series"
                @change="changeSeries">
                <option>Select Series</option>
                    <option v-for="series_item in series_ar" :key="series_item.id" :value="series_item.id">{{series_item.series_code}}</option>
                  </TomSelect>
                </div>


            <div class="mt-3" v-if="(process == 27 && issue_block == 2) || (process == 34 && issue_block == 2)">
                  <label for="update-profile-form-7" class="form-label"
                    >Select PA Bunch</label
                  >
                  <TomSelect
                v-model="selected_pa_bunch"
                :options="{
                  placeholder: 'Select Sawing Type',
                }"
                class="w-full"
                @change="checkBunchIssue"
              >
              <option value="">Select Bunch</option>
              <option v-for="bunch_item in pa_bunch_ar" :key="bunch_item.id" :value="bunch_item.id">{{bunch_item.bunch_code}}</option>
              </TomSelect>
            </div>

              </div>
              <div class="col-span-12 xl:col-span-6">


                <div class="">
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Weight(Ct)</label>
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Weight in Ct"
                    :readonly="issue_block==1"
                    v-model="issue_weight"
                    @keypress="$h.preventInvalidInput" onpaste="return false;"
                  />
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Pieces</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Pieces"
                   v-model="issue_pieces"
                   @keypress="$h.preventInvalidInputInt" onpaste="return false;"

                  />
                </div>
            <div class="mt-3" v-if="issue_type == 1">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Worker</label
                  >
                  <TomSelect
                v-model="karigar"
                :options="{
                  placeholder: 'Select Worker'
                }"
                class="w-full">
              <option value="">Select Worker</option>
              <option v-for="karigar_item in karigar_ar" :key="karigar_item.id" :value="karigar_item.id">{{karigar_item.first_name}} {{karigar_item.last_name}} {{karigar_item.emp_code}}</option>
              </TomSelect>
                </div>

                <div class="mt-3" v-if="issue_type == 2">
                  <label for="update-profile-form-7" class="form-label"
                    >Select Manager</label
                  >
                  <TomSelect
                  v-model="selected_managers"
                  :options="{
                  placeholder: 'Select Manager',
                }"
                class="w-full"
              >
              <option value="">Select Manager</option>
              <option v-for="manager_item in managers_arr" :key="manager_item.id" :value="manager_item.id">{{manager_item.first_name}} ({{manager_item.role_name}})</option>
              </TomSelect>
                </div>

              </div>
            </div>

          <div class="p-5" v-if="(process_details.majuri_type == 2 || process_details.majuri_type == 3) && issue_type == 1  && process != 2 && process != 26">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <h4 class="font-medium text-base mr-auto">Majuri Details</h4>
               <div class="border">
               <div class="grid grid-cols-12  top-0 z-20 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">

                <div class="col-span-6 md:col-span-1 xl:col-span-1">
                  <p>Sr. No</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Range Name</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>From</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>To</p>
                </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Classification</p>
                </div>

                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                  <p>Issue Weight</p>
                </div>
               </div>

               <div class="border" v-for="majuri_item in majuri_sepration" :key="majuri_item.id">

                <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-2">{{majuri_item.id}}</label>
               </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                 <label class="p-2 mt-2 font-bold">{{majuri_item.name}}</label>

              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-2">{{majuri_item.from}}</label>

              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-2">{{majuri_item.to}}</label>

              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2">
                <label class="p-2 mt-2">{{majuri_item.classification}}</label>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                 <input v-model="majuri_item.issue_weight" type="text"

                    class="form-control" placeholder="Weight(Ct)" @keypress="$h.preventInvalidInput" onpaste="return false;">
              </div>


                </div>
            </div>
          </div>
         </div>
            </div>

          </div>


           <div class="p-5" v-if="(process_details.majuri_type == 2 || process_details.majuri_type == 3) && issue_type == 1 && (process == 2 || process == 26)">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <h4 class="font-medium text-base mr-auto">Process Issue Details</h4>
               <div class="border">
              <div class="grid grid-cols-12 top-0 z-50 pl-5 pr-5 pb-2 mt-5" style="background-color:#fff;">

              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Sr. No</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Piece</p>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Weight(Issue)</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Purity</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Size(Charni)</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p></p>
              </div>

               </div>


              <div class="border" v-for="field in ghutan_sepration" :key="field.id">

                <div class="grid grid-cols-12">

               <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2">
                <label class="p-2 mt-2">{{field.id}}</label>
               </div>
                 <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.piece" type="text"
                    class="form-control" placeholder="Total Piece" @keypress="$h.preventInvalidInputInt" @change="totalPackweight()" onpaste="return false;">
              </div>
                <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.issue_weight" type="text"
                    class="form-control" placeholder="Weight(Carat)" @keypress="$h.preventInvalidInput" @change="totalPackweight()" onpaste="return false;">
              </div>

              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.purity" type="text"
                    class="form-control" placeholder="Purity">
              </div>

               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <select class="form-control" v-model="field.size">
                  <option value="">Select Size</option>
                  <option v-for="size_item in majuri_sepration" :key="size_item.id" :value="size_item.name">{{size_item.name}}</option>
                </select>

              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3">
              <button type="button" class="text-theme-6 flex items-center" @click="removeField(field.id)">
                <Trash2Icon class="w-4 h-4 mr-1"/> Delete
              </button>
              </div>

            </div>
              </div>

              <div class="grid grid-cols-12 top-0 z-50 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

              <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1">
                <p>Total</p>
              </div>
              <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                <p>{{totalPcs}}</p>
              </div>
               <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2">
                <p>{{totalWeight}}</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p></p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p></p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p></p>
              </div>

               </div>

              <button type="button" @click="AddField" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                New Entry
              </button>
           </div>
            </div>
        </div>

          </div>


            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto" @click="submitRoughForm" :disabled="form_status">
                Save
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!form_status"/>
              </button>
            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      form_status:false,
      tender_update_id : '',
      transaction_no: 'Auto',
      stone_type: '',
      total_items:1,
      issue_type: 1,
      issue_block: 2,
      issue_weight:'',
      pa_bunch_ar:[],
      selected_pa_bunch:'',
      selected_kapan_lot_type:'All',
      selected_kapan_type:'',
      issue_pieces:'',
      totalPcs:0,
      totalWeight:0,
      max_issue_weight:'',
      process:'',
      sawing_type:'',
      process_ar:[],

      total_process : 0,
      karigar:'',
      karigar_ar:[],
      kapan_ar:[],
      series_ar:[],
      selected_kapan:'',
      selected_series:'',
      managers_arr:[],
      selected_managers:'',
      vendors:[],
      stones:[],
      status: '1',
      ghutan_sepration:[
        {
          id:1,
          size: '',
          issue_weight: '',
          purity: '',
          piece: '',
          total_return_weight: 0 ,
          total_return_pieces: 0 ,
          total_ghat: 0 ,
          sub_trans:'',
          sub_trans_status:1,
        }],
      majuri_sepration:[],
      process_details:{
        process_name:'',
        majuri_type:'',
        majuri_list:[],
        majuri_fix_price:''
      },

      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {

  },
  mounted(){

  this.getRoughDetails(this.rough_id);
  this.getRoughVendor();
  this.getProcess();
  this.getKapans();
  this.getManagers();
  this.getSeries();
  this.getDPStoneType();
  },

  methods: {
     async getProcess() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_process_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.process_ar = data.items;
          this.total_process =1;
          this.issue_type = 1;
          console.log("All process "+this.process_ar.length);

        })
        .catch(_error => {
          this.total_process = 0;
          this.issue_type = 2;
        });
    },
    changeProcess(){
      let _this = this;
      _this.getKarigar();
      if(this.selected_series != ''){
       _this.checkKapanIssue();
      }
      if(this.process == 27){
        _this.getSeriesPaBunch();
      }else{
        this.selected_pa_bunch = '';
        this.pa_bunch_ar = [];
      }
    },
    changeSeries(){
      let _this = this;
      _this.checkKapanIssue();
      if(this.process == 27 || this.process == 34){
        _this.getSeriesPaBunch();
      }else{
        this.selected_pa_bunch = '';
        this.pa_bunch_ar = [];
      }
    },
    AddField() {
      this.total_items++;
      this.ghutan_sepration.push(
        {
          id:this.total_items,size: '',
          issue_weight: '',
          purity: '',
          piece: '',
          total_return_weight: 0 ,
          total_return_pieces: 0 ,
          total_ghat: 0 ,
          sub_trans:'',
          sub_trans_status:1
          });
    },
    removeField(id) {
       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.ghutan_sepration.length;
              while(i--){
              if(this.ghutan_sepration[i]['id'] == id ){
                  this.ghutan_sepration.splice(i,1);
              }
            }
          }
      });
    },
    totalPackweight(){
      let total_weight = 0;
      let total_pcs = 0;

      var i = this.ghutan_sepration.length;
        while(i--){
          if(this.ghutan_sepration[i]['issue_weight'] != ''){
              total_weight += Number((parseFloat(this.ghutan_sepration[i]['issue_weight'])*100/100).toFixed(4));
          }
          if(this.ghutan_sepration[i]['piece'] != ''){
            this.ghutan_sepration[i]['piece'] = parseInt(this.ghutan_sepration[i]['piece'])
              total_pcs += parseInt(this.ghutan_sepration[i]['piece']);
          }
        }
        if(!isNaN(total_weight)){
          this.totalWeight = Number(parseFloat(total_weight).toFixed(4));
        }if(!isNaN(total_pcs)){
          this.totalPcs = total_pcs;
        }
    },
    async getRoughDetails(rough_id) {
      let _this = this;
      const params = {
        rough_id: rough_id
      };
      let promise = axios({
        url: "api/get_rough_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          let rough_detail = data.item_details;
          this.tender_name = rough_detail.tender_name;
          this.lot_no = rough_detail.lot_no;
          this.price= rough_detail.price;
          this.stone_type= rough_detail.stone_type;
          this.brokerage_type = rough_detail.brokerage_type;
          this.brokerage_value = rough_detail.brokerage_value;
          this.billing_period = rough_detail.billing_period;
          this.est_makeable = rough_detail.est_makeable;
          this.rough_weight = rough_detail.rough_weight;
          this.total_pcs = rough_detail.total_pcs;
          this.rough_vendor = rough_detail.vendor_id;

        })
        .catch(_error => {

        });
    },
    async getRoughVendor() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_account_vendor",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.vendors = data.items;

        })
        .catch(_error => {

        });
    },
    async getDPStoneType() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_stone",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.stones = data.items;

        })
        .catch(_error => {

        });
    },
    async getKapans() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_kapan_for_issue",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.kapan_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getSeries() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_rough_series_for_issue",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.series_ar = data.items;

        })
        .catch(_error => {

        });
    },
    async getSeriesPaBunch() {
      let _this = this;
      if(this.selected_series == ""){
        return;
      }
      const params = {
        series_id:this.selected_series
      };
      let promise = axios({
        url: "api/dp_pa_bunch_for_issue",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.pa_bunch_ar = data.items;
        })
        .catch(_error => {

        });
    },

    async getManagers() {
      let _this = this;
      const params = {

      };
      let promise = axios({
        url: "api/dp_managers_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.managers_arr = data.items;

        })
        .catch(_error => {

        });
    },
    async getKarigar() {
      let _this = this;
      const params = {
        process: this.process

      };
      let promise = axios({
        url: "api/dp_karigar_by_user",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          this.karigar_ar = data.items;
          this.process_details.majuri_type = data.process_details.majuri_type;
          console.log(this.process_details.majuri_type);
          if(this.process_details.majuri_type == 2 || this.process_details.majuri_type == 3){
            this.majuri_sepration = data.process_details.majuri_list;
          }

        })
        .catch(_error => {

        });
    },
    async changeKapan(){
      this.selected_kapan_lot_type = "All";
      let _this = this;
      _this.checkKapanIssue();
    },
    async checkKapanIssue(){
      const data = {
        kapan_id: this.selected_kapan,
        selected_kapan_type:this.selected_kapan_type,
        kapan_lot_type: this.selected_kapan_lot_type,
        series_id: this.selected_series,
        bunch_type: this.issue_block,
        process_id: this.process,
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/check_issue_kapan"
      }else{
         url_post = "api/check_issue_kapan"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         console.log(data);
         if(data.items == 'no'){
            this.selected_kapan = '';
            this.selected_series = '';
            this.issue_weight = '';
            this.issue_pieces = '';
            this.max_issue_weight = '';
            this.$swal("Warning!", "Selected Item Already issued for : " + data.message, "warning");
         }
         else if(data.items == 'yes'){
          if(this.issue_block == 2){
            if(this.process == 3){
              this.issue_weight = this.$h.myFloat(data.pa_weight);
              this.issue_pieces = data.pcs;
              this.max_issue_weight = this.$h.myFloat(data.pa_weight);
            }else{
              console.log(data.weight);
              this.issue_weight = this.$h.myFloat(data.weight);
              this.issue_pieces = data.pcs;
              this.max_issue_weight = this.$h.myFloat(data.weight);
            }
          }else if(this.issue_block == 1){
            if(this.process == 3){
              this.selected_kapan_type = data.kapan_type;
              this.issue_weight = this.$h.myFloat(data.pa_weight);
              this.issue_pieces = data.pcs;
              this.max_issue_weight = this.$h.myFloat(data.pa_weight);
            }else{
              console.log(data.weight);
              this.selected_kapan_type = data.kapan_type;
              this.issue_weight = this.$h.myFloat(data.weight);
              this.issue_pieces = data.pcs;
              this.max_issue_weight = this.$h.myFloat(data.weight);
            }

          }


         }
        })
        .catch((_error) => {
          return [];
        });

    },
    async checkBunchIssue(){
      const data = {
        bunch_id: this.selected_pa_bunch,
        process_id: this.process,
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/check_issue_bunch"
      }else{
         url_post = "api/check_issue_bunch"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         console.log(data);
         if(data.items == 'no'){
            this.selected_kapan = '';
            this.selected_pa_bunch = '';
            this.issue_weight = '';
           this.issue_pieces = '';
            this.$swal("Warning!", "Selected Bunch Already issued for LS", "warning");
         }else if(data.items == 'yes'){
           this.issue_weight = data.weight;
           this.issue_pieces = data.pcs;
           this.max_issue_weight = data.weight;
         }
        })
        .catch((_error) => {
          return [];
        });

    },

    async submitRoughForm(){
      this.form_status = true;
      //let tag_ar = [this.vueSelectForm.multiple];
      if(this.issue_block == 1){
        this.selected_series = '';
        if(this.selected_kapan == ''){
        this.$swal("Warning!", "Please Select Kapan", "warning");
        this.form_status = false;
        return;
        }
        if(this.selected_kapan_type != 1){
          this.selected_kapan_lot_type = '';
        }
      }
      if(this.issue_block == 2){
        this.selected_kapan = '';
        this.selected_kapan_lot_type = '';
        if(this.selected_series == ''){
          this.$swal("Warning!", "Please Select Series", "warning");
          this.form_status = false;
          return;
        }
      }
      if(this.selected_kapan == '' && this.selected_series == ''){
        this.$swal("Warning!", "Selected Kapan or series ", "warning");
        this.form_status = false;
        return;
      }

      if(this.max_issue_weight == 0){
        this.$swal("Warning!", "Selected Item can not be issue, You don't have any weight on table. ", "warning");
        this.form_status = false;
        return;
      }

      if(this.issue_weight == '' || this.issue_weight == 0 || (Number(parseFloat(this.issue_weight).toFixed(4)) > Number(parseFloat(this.max_issue_weight).toFixed(4)))){
        this.$swal("Warning!", "Invalid Issue Weight ", "warning");
        this.form_status = false;
        return;
      }

      if(this.issue_type == 1){
        if(this.process == ''){
        this.$swal("Warning!", "Select Process", "warning");
        this.form_status = false;
        return;
        }
        if(this.karigar == ''){
        this.$swal("Warning!", "Select Karigar", "warning");
        this.form_status = false;
        return;
        }
      }
      if(this.issue_type == 2){
        this.process = '';

        if(this.selected_managers == ''){
          this.$swal("Warning!", "Select Manager", "warning");
          this.form_status = false;
          return;
        }
      }
      if(this.process == 1){
        if(this.sawing_type == ''){
        this.$swal("Warning!", "Select Sawing Type", "warning");
        this.form_status = false;
        return;
        }
      }
      if((this.process == 27 && this.issue_block == 2) || (this.process == 34 && this.issue_block == 2)){
        if(this.selected_pa_bunch == ''){
        this.$swal("Warning!", "Select Bunch for LS", "warning");
        this.form_status = false;
        return;
        }
      }

      if((this.process == 2 || this.process == 26) && this.issue_block == 2){
        //check total weight of ghutan issue match with issue weight
        var ghutan_issue_total = 0;
        var i = this.ghutan_sepration.length;
        while(i--){
                if(this.ghutan_sepration[i]['issue_weight'] != null && this.ghutan_sepration[i]['issue_weight'] != '' && this.ghutan_sepration[i]['size'] != ''){
                  ghutan_issue_total += this.$h.myFloat(this.ghutan_sepration[i]['issue_weight']);
                }else{
                  this.$swal("Warning!", "Process Issue details is empty", "warning");
                  this.form_status = false;
                  return;
                }
        }

        console.log(ghutan_issue_total);
        if(this.$h.myFloat(this.issue_weight) != this.$h.myFloat(ghutan_issue_total)){
           this.$swal("Warning!", "Total Issue weight and Process Issue weight not match", "warning");
           this.form_status = false;
          return;
        }
      }
      this.form_status = true;
      const data = {
        kapan_id: this.selected_kapan,
        kapan_lot_type: this.selected_kapan_lot_type,
        issue_type: this.issue_type,
        bunch_type: this.issue_block,
        series_id: this.selected_series,
        pa_bunch_id: this.selected_pa_bunch,
        issue_weight : this.issue_weight,
        issue_pieces : this.issue_pieces,
        karigar: this.karigar,
        process_id: this.process,
        sawing_type: this.sawing_type,
        majuri_sepration:this.majuri_sepration,
        ghutan_issue_details: this.ghutan_sepration,
        majuri_type:this.process_details.majuri_type,
        manager_id: this.selected_managers,
        id:this.rough_id
      };
      let url_post = '';
      if(this.rough_id != ''){
        url_post = "api/edit_kapan_issue_trans"
      }else{
         url_post = "api/add_kapan_issue_trans"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
         if(data.status == 'success'){

            this.$swal("Success!", "Item is issued for selected process", "success");
            this.$router.push("/app/app-process/kapan/issue");
            //this.form_status = false;
         }else if(data.status == 'fail'){
            this.$swal("Failed!", data.message, "warning");
            this.form_status = false;
         }
        })
        .catch((_error) => {
          this.form_status = false;
          return [];
        });
    },
    changeIssueto(){
      if(this.issue_type == 2){
        this.issue_block = ""+ 1;
      }
    },
    changeBlocktype(){
        //this.issue_type = ""+ 1;
        this.issue_weight = '';
        this.issue_pieces = '';
        this.selected_kapan = '';
        this.selected_series = '';
    }
  },

})
</script>
