<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Series Ready Stock</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Series Details</h2>
            
          </div>
         <form @submit.prevent="onUserFormSubmit">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Series Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ series_code }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Stone Type : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ stone_type }}</label
                  >
                  
                </div>
                <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Ready Stone : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone }}</label
                  >
                  
                  
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Ready Stone (ct) : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_ct }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Certi Pcs : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_certi }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Certi Cts : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_ct_certi }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Fail Pcs / Ct: &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_failed }} / {{total_ready_stone_ct_failed}}</label
                  >
                  
                </div>
                
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Total Lost Pcs / Ct: &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ total_ready_stone_lost }} / {{total_ready_stone_ct_lost}}</label
                  >
                  
                </div>
              </div>
            </div>
            
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Details</h2>
        </div>
        <div class="border">
        <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;">
             
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>Sr. No</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Charni Name</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Cr Stock</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Av Stock</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Add Stock(ct)</p>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>Pcs</p>
              </div>
              
            </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              
              <div class="col-span-6 md:col-span-1 xl:col-span-1">
                <p>{{field.lot_no}}</p>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>{{field.charni_name}}</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p v-if="stone_type == 'CVD'">{{field.cr_stock_cvd}}</p>
                <p v-else>{{field.cr_stock}}</p>
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <p>{{field.total_ct}}</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.new_stock_ct" type="text"
                    class="form-control" placeholder="weight in ct" @keypress="$h.preventInvalidInput" @change="calculateTotal">
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
                <input v-model="field.new_stock_pc" type="text"
                    class="form-control" placeholder="Pcs" @keypress="$h.preventInvalidInput">
              </div>
            
            </div>
          </div>
          
           <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
                
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Added Carat (Ct) : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_added_ct}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-2 xl:col-span-7">
              </div>
               <div class="col-span-2 xl:col-span-3 text-right">
                <p class="font-bold">Total Avaialbe : </p>
              </div>
              <div class="col-span-2 xl:col-span-2 text-right">
                <p>{{total_ready_stone_ct}}</p>
              </div>
          </div>
       
        </div>
        <div>
        <div class="p-5">
            <div class="flex justify-end mt-4">
             
              <button type="submit" class="btn btn-primary w-20 mr-auto" >
                Save
              </button>
            
            </div>
        </div>
          </div>
          </form>
        </div>
        
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    series_id: {
      type: String,
      default: ""
    }
  },
  
  data() {
    return {
      tender_update_id : '',
      total_rough_carat:0,
      total_expected_amount:0,
      total_expected_amount_overall:0,
      series_code : '',
      stone_type: '',
      total_ready_stone : 0,
      total_ready_stone_ct : 0,
      total_ready_stone_certi : 0,
      total_ready_stone_ct_certi : 0,
      total_ready_stone_failed : 0,
      total_ready_stone_ct_failed : 0,
      total_ready_stone_lost : 0,
      total_ready_stone_ct_lost : 0,
      total_added_ct: 0,

      fields: [],
      delete_items : [],
      url:'',
      status: 'default',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  
  if(this.series_id != ''){
    console.log(this.series_id);
    this.getSeriesDetails(this.series_id);
    this.getCurrentStock(this.series_id);
  }
  },

  methods: {
    alertVal () {
      alert(this.dob)
    },
    onUserFormSubmit() {
      //console.log(this.fields);
      
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      
      if (!this.errors.length) {
        const data = {
         
          stock_details:this.fields,
          series_details: this.series_id,
        
        };

        if(this.tender_id != ''){
          this.url = "api/update_ready_stock"
        }else{
          this.url = "api/update_ready_stock"
        }
        axios({
          url: this.url,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        })
          .then(response => {
            this.$notify(
              "success",
              "Record Added",
              "New Stock Added Successfully",
              {
                duration: 2000,
                permanent: false
              },
              setTimeout(() => {
                this.$router.push("/app/app-polish-ready/ready/cr-stock");
              }, 500)
            );
          })
          .catch(response => {
           
          });
      }
    },
    AddField() {
      this.total_items++;
      this.fields.push({ id:this.total_items,lot_no: '',group:'',cut: '',details: '',piece: '',carats: '',expect_price: '',attachment:'',attachment_link:'' });
      console.log(this.total_items);
    },
    removeField(id) {
     
       this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields.splice(i,1);
              }
            }
            this.delete_items.push(id);
          }
      });
    },
    addLinkField(id){
      console.log(id);
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields[i]['attachment'] = 'yes';
              }
            }
    },
    removeLinkField(id){
      console.log(id + "here");
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['id'] == id ){ 
                  this.fields[i]['attachment'] = '';
                  this.fields[i]['attachment_link'] = '';
              }
            }
    },

    deleteTender(){

      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete Tender!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Agree & Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const params = {
            tender_id: this.tender_id
          };
          let promise = axios({
            url: "api/delete_tender",
            method: "POST",
            params: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              this.$notify(
                  "success",
                  "Record Deleted",
                  "Tender Deleted Successfully",
                  {
                    duration: 2000,
                    permanent: false
                  },
                  setTimeout(() => {
                    this.$router.push("/app/tender/list");
                  }, 500)
                );

            })
            .catch(_error => {
              
            });
          
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          
        }
      });

    },
    getSeriesDetails(series_id) {
      let _this = this;
      const params = {
        series_id: series_id
      };
      let promise = axios({
        url: "api/get_series_ready_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let item_details = data.item_details;
          this.series_code = item_details.series_code;
          this.stone_type = item_details.stone_type;
          this.total_ready_stone = item_details.total_pcs;
          this.total_ready_stone_ct = item_details.total_ct;

        })
        .catch(_error => {
          
        });
    },

    getCurrentStock(series_id) {
      let _this = this;
      const params = {
        series_id: series_id
      };
      let promise = axios({
        url: "api/get_cr_stock_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let item_details = data.item_details;
         this.fields = item_details;
         console.log(this.fields);

        })
        .catch(_error => {
          
        });
    },
    
    clearOtherDate(){
      this.end_date = '';
      this.bid_start_date = '';
      this.bid_end_date = '';
    }
    ,clearBidDate(){
      this.bid_start_date = '';
      this.bid_end_date = '';
    },clearBidEndDate(){
      this.bid_end_date = '';
    },
    calculateTotal(){
      this.total_added_ct = 0;
      var i = this.fields.length;
              while(i--){
              if(this.fields[i]['new_stock_ct'] != '' && !isNaN(this.fields[i]['new_stock_ct'])){
                  this.total_added_ct = this.total_added_ct + parseFloat(this.fields[i]['new_stock_ct']);
              }

            }
    }
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>