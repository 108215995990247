<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet History</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">


          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group">

                  <input
                    id="update-profile-form-6"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="scan_lot_no"
                    :class="{'form-control':true,'loading':scanload}"
                    :disabled = "scanload"
                    @change="scanAndAddPack"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>
              </div>
              <div class="col-span-3">
                <label for="update-profile-form-6" class="form-label"
                  >Select Process</label
                >
                <TomSelect
                  v-model="process_id"
                  :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }"
                  class="w-full"
                  @change="historyPrepare"
                  multiple
                >
                <option value="">Select Process</option>
                <option v-for="process in processes" :key="process.id" :value="process.id">{{process.process_code}}</option>
                </TomSelect>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="genarate == true">
                <div class="mt-7 flex">

                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full ml-2"
                    @click="printReportDetails"
                  >
                  Print
                  </button>
                  <!-- <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full ml-2"
                    @click="printReportDetails"
                    v-print="'#generate-pdf'"
                  >
                  Print
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
      <div class="intro-y col-span-12 lg:col-span-12 mt-3">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Packet Issue History</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 ">
                <h3 class="text-lg font-medium mr-auto" v-if="packet_details.packet_code">Packet Number :- {{ packet_details.packet_code }}</h3>
              <div
              id="tabulatorHisroty"
              ref="tabulatorHisroty"
              class="mt-2"
            ></div>
            </div>
            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      tabulatorHistory:[],
      tabulator:[],
      processes:[],
      scanload:false,
      scan_lot_no:'',
      process_id:'',
      status: '1',
      req: axios.create({
        baseUrl: BASE_URL
      }),
      packet_details: [],
      genarate : false
    }
  },
  mounted() {
    this.getprocesses();
  },
  methods: {
    async scanAndAddPack(){
      let _this = this;
      console.log(this.scan_lot_no);
      this.genarate = false

      let codelength = this.scan_lot_no.trim().length;
      if(codelength > 0){
        this.scanload = true;
        //get lot details
        const data = {
        barcode: this.scan_lot_no,
        };
        let url_post = '';
          if(this.scan_lot_no != ''){
          url_post = "api/get_scanned_packet_details_for_return"
        }else{
          url_post = "api/get_scanned_packet_details_for_return"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
            this.packet_details = data.packet_details
            this.genarate = true
            _this.historyPrepare();
            this.scanload = false;
          })
          .catch((_error) => {
            this.scanload = false;
            this.genarate = false


            return [];
          });

      }

    },
    async getprocesses(){
      let promise = axios({
        url: 'api/dp_process',
        method: "post",
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
      });
      return promise.then((result) => result.data)
      .then((data) => {
        this.processes = data.items
      })
    },

    historyPrepare(){
      let _this = this;
      if(this.scan_lot_no) {
        this.tabulatorHistory = new Tabulator(this.$refs['tabulatorHisroty'], {
          ajaxURL: BASE_URL + '/api/list_packet_process_history',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams:{barcode:this.scan_lot_no, process_id: this.process_id},
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: false,
          tooltipsHeader:true,
          headerSort: false,
          placeholder: 'No matching records found',
          columns: [
            // For HTML table
            {
              title: 'Transaction No',
              field: 'transaction_code_kapan',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //e - the click event object
                //cell - cell component
                if(cell.getData().transaction_type == 1){
                  return cell.getData().transaction_code_kapan;
                }else if(cell.getData().transaction_type == 2){
                  return cell.getData().transaction_code_packet;
                }else if(cell.getData().transaction_type == 3){
                  return cell.getData().transaction_code_packet_received;
                }else{
                  return "-";
                }
            }
            },
            {
              title: 'Process',
              minWidth: 100,
              field: 'process_name',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //e - the click event object
                //cell - cell component
                if(cell.getData().return_record == 1){
                  return "<div class='text-theme-6'>Received</div>";
                }else if((cell.getData().transaction_id == null || cell.getData().transaction_id == '') && ( cell.getData().process_id == null)){
                  return "<div class='text-theme-1'>Created</div>";
                }else if(cell.getData().process_id != '' && cell.getData().process_id != null){
                  return  `<div class='text-theme-9'>${cell.getData().process_name}</div>`;
                }else{
                  return "<div class='text-theme-1'>Transfered</div>";
                }
            }

            },
            {
              title: 'Issue To',
              minWidth: 100,
              field: 'process_name',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //e - the click event object
                //cell - cell component
                if((cell.getData().transaction_id == null || cell.getData().transaction_id == '') && ( cell.getData().process_id == null)){
                  return cell.getData().manager_first_name;
                }
                else if(cell.getData().process_id != '' && cell.getData().process_id != null){
                  if(cell.getData().karigar_type == 4){
                    return cell.getData().prop_first_name +`(`+ cell.getData().prop_company_name  +`)`;
                  }
                  else if(cell.getData().process_id == 29 || cell.getData().process_id == 1 || cell.getData().process_id == 27 || cell.getData().process_id == 28 || cell.getData().process_id == 3 || cell.getData().process_id == 31){
                    return cell.getData().karigar_manager_first_name;
                  }else if(cell.getData().process_id == 18 || cell.getData().process_id == 19 || cell.getData().process_id ==  20){
                    return cell.getData().planner_first_name;
                  }else if(cell.getData().process_id == 32){
                    return cell.getData().pp_first_name;
                  }else if([37,38,40,41,42].includes(cell.getData().process_id)){
                    return cell.getData().prop_first_name +`(`+cell.getData().prop_company_name +`)`;
                  }else{
                    return cell.getData().karigar_first_name;
                  }
                }else{
                  return cell.getData().manager_first_name;
                }
            }

            },
            {
              title: 'Weight(I)',
              minWidth: 100,
              field: 'issue_weight',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                //return `<div>`+ Number(cell.getData().kapan_weight) + ` Ct<br>` + Number(cell.getData().total_lot_weight) + `Ct </div>`;
                return _this.$h.myFloat(cell.getData().issue_weight);
              }
            },{
              title: 'Weight(R)',
              field: 'received_weight',
              vertAlign: 'middle',
              print: false,
              download: true,
              formatter(cell) {
                return _this.$h.myFloat(cell.getData().received_weight);
              }
            },
            {
              title: 'Issue Date',
              minWidth: 100,
              field: 'created_at',
              vertAlign: 'middle',
              print: false,
              download: true,
            },
            {
              title: 'Return Date',
              minWidth: 100,
              field: 'return_date',
              vertAlign: 'middle',
              print: false,
              download: true,
            },
            {
              title: '',
              minWidth: 80,
              field: '',
              responsive: 1,
              hozAlign: 'center',
              vertAlign: 'middle',
              headerSort:false,
              print: false,
              download: false,
              formatter(cell) {
                const data_id = cell.getData().id;
                // const a = cash(`<div class="flex lg:justify-center items-center">
                //   <span class="flex items-center" >
                //     <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                //   </span>
                // </div>`)

                const a = '';


                cash(a).on('click', function(data_id) {
                  // On click actions
                  router.push("/purchase/update-rough-purchase/" + window.btoa(cell.getData().id));
                })

                return a[0]
              }
            },


          ],
          renderComplete() {

          }
        })
      }
    },
    printReportDetails(){
      let report_type = 'packet-history-details'

      let params = {
        barcode:this.scan_lot_no,
        process_id: this.process_id,
        print_report: 'print',
        print_report_name: report_type
      }
      let promise = axios({
        url: "api/list_packet_process_history",
        method: "get",
        baseURL: BASE_URL,
        params: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          const report_id = data.report_id
          const report_name = data.report_name

          let url = BASE_URL + '/' + report_name + '?id=' + window.btoa(report_id)
          window.open(url, '_blank');

        })
        .catch(_error => {

        });
      // add_report_details
    }

  },

})
</script>
